import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { YM_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../context"

export const PrintProxyAcknowledgementOp = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const { query } = useRouter()
  const { facilityIds, year, month } = query as any
  const currentData = dayjs(`${year}-${month}`).isValid()
    ? dayjs(`${year}-${month}`)
    : dayjs()
  const [values, setValues] = useState({
    facilityIds: facilityIds || null,
    date: currentData || null,
  })
  return (
    <AccordionCard
      defaultActiveKey={["proxy-acknowledgement"]}
      items={[
        {
          key: "proxy-acknowledgement",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"136px"} />
                <SelectInput
                  options={facilities || []}
                  placeholder={"--"}
                  name={"facility"}
                  value={values?.facilityIds || null}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      facilityIds: val,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year Month")}
                  width={"136px"}
                />
                <DatePicker
                  date={values?.date || null}
                  onDateChange={(val) => setValues({ ...values, date: val })}
                  picker={"month"}
                  format={YM_FORMAT}
                  style={{
                    width: "200px",
                  }}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Update Display")}
                  shape={"round"}
                  onClick={() => {
                    updateParams(
                      {
                        year: values?.date
                          ? dayjs(values?.date).year()
                          : dayjs().year(),
                        month: values?.date
                          ? dayjs(values?.date).month() + 1
                          : dayjs().month() + 1,
                        facility: values?.facilityIds || facilities?.[0]?.id,
                      },
                      "/national-health-insurance-billing",
                    )
                  }}
                />
                <Button
                  btnText={t("Search")}
                  shape={"round"}
                  type={"primary"}
                  iconType={"search"}
                  onClick={() =>
                    updateParams(
                      {
                        year: dayjs(values?.date).year(),
                        month: dayjs(values?.date).month() + 1,
                        facilityIds: values?.facilityIds,
                      },
                      "/national-health-insurance-billing/prints/print-proxy-acknowledgement",
                    )
                  }
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
