import {
  AccordionCard,
  ActionBtns,
  Button,
  CALENDAR_HOURS_MINUTES,
  CheckBoxGroup,
  DatePicker,
  LabelTextWithLeftArrow,
  LabelTextWithLeftBorder,
  OpFlexContainer,
  OperationSearchContent,
  RadioGroup,
  SelectInput,
  TimeRangeContainer,
  theme,
} from "@project/common"
import type { RadioChangeEvent } from "antd"
import { Divider } from "antd"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { WEEK_DAYS as ORIGINAL_WEEK_DAYS } from "../../../constants"
import { useBusinessDayManagement } from "../../../context"
import { AuthContext } from "../../../context/AuthContext"

export const BusinessDayRegistrationOperationOptions = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const {
    operationOptionsValues,
    handleOperationInputChange,
    onDisplayChangeBtnClicked,
    handleOperationInputHourChange,
    onApply,
  } = useBusinessDayManagement()
  const isExist = facilities?.find(
    (f) => f?.value === operationOptionsValues?.params?.facility_id,
  )

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const WEEK_DAYS = getOptionsWithTranslatedLabel(ORIGINAL_WEEK_DAYS)

  return (
    <AccordionCard
      accordion={true}
      defaultActiveKey={["1"]}
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftBorder
                  label={t("Display settings")}
                  width={"138px"}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"138px"} />
                <SelectInput
                  name={"facility"}
                  options={facilities}
                  value={
                    isExist ? operationOptionsValues?.params?.facility_id : null
                  }
                  placeholder={"--"}
                  onChange={(value) =>
                    handleOperationInputChange({
                      type: "params",
                      key: "facility_id",
                      value,
                    })
                  }
                />
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year and month")}
                  width={"138px"}
                />
                <DatePicker
                  picker={"month"}
                  date={
                    dayjs(operationOptionsValues?.params?.date).isValid()
                      ? operationOptionsValues?.params?.date
                      : null
                  }
                  onDateChange={(date) => {
                    handleOperationInputChange({
                      type: "params",
                      key: "date",
                      value: date,
                    })
                  }}
                  format={"YYYY年MM月"}
                />
                <Button
                  btnText={t("Display Change")}
                  size={"large"}
                  shape={"round"}
                  borderColor={theme.colors.primary}
                  onClick={onDisplayChangeBtnClicked}
                />
              </div>

              <div className={"search__content"}>
                <LabelTextWithLeftBorder
                  label={t("Bulk setting")}
                  width={"138px"}
                />
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Regular holiday")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={WEEK_DAYS?.map((val) => ({
                    ...val,
                    disabled:
                      operationOptionsValues?.settings?.business_days?.includes(
                        val?.value,
                      ),
                  }))}
                  value={operationOptionsValues?.settings?.holidays}
                  onChange={(list: CheckboxValueType[]) => {
                    handleOperationInputChange({
                      type: "settings",
                      key: "holidays",
                      value: list,
                    })
                  }}
                />
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />

              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Business day")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={WEEK_DAYS?.map((val) => ({
                    ...val,
                    disabled:
                      operationOptionsValues?.settings?.holidays?.includes(
                        val?.value,
                      ),
                  }))}
                  value={operationOptionsValues?.settings?.business_days}
                  onChange={(list: CheckboxValueType[]) => {
                    handleOperationInputChange({
                      type: "settings",
                      key: "business_days",
                      value: list,
                    })
                  }}
                />
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Meal ")} width={"138px"} />
                <RadioGroup
                  value={operationOptionsValues?.settings?.meal}
                  options={[
                    {
                      label: t("none"),
                      value: "0",
                    },
                    {
                      label: t("can be"),
                      value: "1",
                    },
                  ]}
                  onChange={(e: RadioChangeEvent) => {
                    handleOperationInputChange({
                      type: "settings",
                      key: "meal",
                      value: e.target.value,
                    })
                  }}
                />
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />
              <div className={"search__content"}>
                <OpFlexContainer>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Business Hours")}
                      width={"138px"}
                    />
                    <TimeRangeContainer>
                      <div className={"time_range start"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            value={
                              operationOptionsValues?.settings?.business_hours1
                                ?.from?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours1",
                                range: "from",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.business_hours1?.from?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.business_hours1
                                ?.from?.hours
                            }
                            value={
                              operationOptionsValues?.settings?.business_hours1
                                ?.from?.minutes
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours1",
                                range: "from",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                        <span>{"~"}</span>
                        <div className={"to__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_hr"}
                            placeholder={"--"}
                            value={
                              operationOptionsValues?.settings?.business_hours1
                                ?.to?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours1",
                                range: "to",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.business_hours1?.to?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.business_hours1
                                ?.to?.hours
                            }
                            value={
                              operationOptionsValues?.settings?.business_hours1
                                ?.to?.minutes
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours1",
                                range: "to",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Business Hours") + "2"}
                      width={"138px"}
                    />
                    <TimeRangeContainer>
                      <div className={"time_range end"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            value={
                              operationOptionsValues?.settings?.business_hours2
                                ?.from?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours2",
                                range: "from",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.business_hours2?.from?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.business_hours2
                                ?.from?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            value={
                              operationOptionsValues?.settings?.business_hours2
                                ?.from?.minutes
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours2",
                                range: "from",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                        <span>{"~"}</span>
                        <div className={"to__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            value={
                              operationOptionsValues?.settings?.business_hours2
                                ?.to?.hours
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours2",
                                range: "to",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.business_hours2?.to?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.business_hours2
                                ?.to?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            value={
                              operationOptionsValues?.settings?.business_hours2
                                ?.to?.minutes
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "business_hours2",
                                range: "to",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Business Hours") + "3"}
                  width={"138px"}
                />
                <TimeRangeContainer>
                  <div className={"time_range start"}>
                    <div className={"from__time"}>
                      <SelectInput
                        width={"58px"}
                        name={"form_time_hr"}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        value={
                          operationOptionsValues?.settings?.business_hours3
                            ?.from?.hours
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "business_hours3",
                            range: "from",
                            unit: "hours",
                            value,
                            extra: {
                              minutes:
                                operationOptionsValues?.settings
                                  ?.business_hours3?.from?.minutes || "00",
                            },
                          })
                        }}
                      />
                      <span>{":"}</span>
                      <SelectInput
                        width={"58px"}
                        name={"form_time_min"}
                        placeholder={"--"}
                        disabled={
                          !operationOptionsValues?.settings?.business_hours3
                            ?.from?.hours
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        value={
                          operationOptionsValues?.settings?.business_hours3
                            ?.from?.minutes
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "business_hours3",
                            range: "from",
                            unit: "minutes",
                            value,
                          })
                        }}
                      />
                    </div>
                    <span>{"~"}</span>
                    <div className={"to__time"}>
                      <SelectInput
                        width={"58px"}
                        name={"to_time_hr"}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        value={
                          operationOptionsValues?.settings?.business_hours3?.to
                            ?.hours
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "business_hours3",
                            range: "to",
                            unit: "hours",
                            value,
                            extra: {
                              minutes:
                                operationOptionsValues?.settings
                                  ?.business_hours2?.to?.minutes || "00",
                            },
                          })
                        }}
                      />
                      <span>{":"}</span>
                      <SelectInput
                        width={"58px"}
                        name={"to_time_min"}
                        placeholder={"--"}
                        disabled={
                          !operationOptionsValues?.settings?.business_hours3?.to
                            ?.hours
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        value={
                          operationOptionsValues?.settings?.business_hours3?.to
                            ?.minutes
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "business_hours3",
                            range: "to",
                            unit: "minutes",
                            value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </TimeRangeContainer>
              </div>
              <Divider
                dashed
                style={{
                  margin: 0,
                }}
              />

              <div className={"search__content"}>
                <OpFlexContainer>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Service Hours")}
                      width={"138px"}
                    />
                    <TimeRangeContainer>
                      <div className={"time_range start"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            value={
                              operationOptionsValues?.settings?.service_hours1
                                ?.from?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours1",
                                range: "from",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.service_hours1?.from?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.service_hours1
                                ?.from?.hours
                            }
                            value={
                              operationOptionsValues?.settings?.service_hours1
                                ?.from?.minutes
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours1",
                                range: "from",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                        <span>{"~"}</span>
                        <div className={"to__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            value={
                              operationOptionsValues?.settings?.service_hours1
                                ?.to?.hours
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours1",
                                range: "to",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.service_hours1?.to?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.service_hours1
                                ?.to?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            value={
                              operationOptionsValues?.settings?.service_hours1
                                ?.to?.minutes
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours1",
                                range: "to",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>

                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Service Hours") + "2"}
                      width={"138px"}
                    />
                    <TimeRangeContainer>
                      <div className={"time_range end"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            value={
                              operationOptionsValues?.settings?.service_hours2
                                ?.from?.hours
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours2",
                                range: "from",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.service_hours2?.from?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.service_hours2
                                ?.from?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            value={
                              operationOptionsValues?.settings?.service_hours2
                                ?.from?.minutes
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours2",
                                range: "from",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                        <span>{"~"}</span>
                        <div className={"to__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            value={
                              operationOptionsValues?.settings?.service_hours2
                                ?.to?.hours
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours2",
                                range: "to",
                                unit: "hours",
                                value,
                                extra: {
                                  minutes:
                                    operationOptionsValues?.settings
                                      ?.service_hours2?.to?.minutes || "00",
                                },
                              })
                            }}
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"to_time_min"}
                            placeholder={"--"}
                            disabled={
                              !operationOptionsValues?.settings?.service_hours2
                                ?.to?.hours
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            value={
                              operationOptionsValues?.settings?.service_hours2
                                ?.to?.minutes
                            }
                            onChange={(value) => {
                              handleOperationInputHourChange({
                                type: "settings",
                                key: "service_hours2",
                                range: "to",
                                unit: "minutes",
                                value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Service Hours") + "3"}
                  width={"138px"}
                />
                <TimeRangeContainer>
                  <div className={"time_range start"}>
                    <div className={"from__time"}>
                      <SelectInput
                        width={"58px"}
                        name={"form_time_hr"}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        value={
                          operationOptionsValues?.settings?.service_hours3?.from
                            ?.hours
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "service_hours3",
                            range: "from",
                            unit: "hours",
                            value,
                            extra: {
                              minutes:
                                operationOptionsValues?.settings?.service_hours3
                                  ?.from?.minutes || "00",
                            },
                          })
                        }}
                      />
                      <span>{":"}</span>
                      <SelectInput
                        width={"58px"}
                        name={"form_time_min"}
                        placeholder={"--"}
                        disabled={
                          !operationOptionsValues?.settings?.service_hours3
                            ?.from?.hours
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        value={
                          operationOptionsValues?.settings?.service_hours3?.from
                            ?.minutes
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "service_hours3",
                            range: "from",
                            unit: "minutes",
                            value,
                          })
                        }}
                      />
                    </div>
                    <span>{"~"}</span>
                    <div className={"to__time"}>
                      <SelectInput
                        width={"58px"}
                        name={"to_time_hr"}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        value={
                          operationOptionsValues?.settings?.service_hours3?.to
                            ?.hour
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "service_hours3",
                            range: "to",
                            unit: "hours",
                            value,
                            extra: {
                              minutes:
                                operationOptionsValues?.settings?.service_hours3
                                  ?.to?.minutes || "00",
                            },
                          })
                        }}
                      />
                      <span>{":"}</span>
                      <SelectInput
                        width={"58px"}
                        name={"to_time_min"}
                        placeholder={"--"}
                        disabled={
                          !operationOptionsValues?.settings?.service_hours3?.to
                            ?.hours
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        value={
                          operationOptionsValues?.settings?.service_hours3?.to
                            ?.minutes
                        }
                        onChange={(value) => {
                          handleOperationInputHourChange({
                            type: "settings",
                            key: "service_hours3",
                            range: "to",
                            unit: "minutes",
                            value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </TimeRangeContainer>
              </div>

              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Apply")}
                  size={"large"}
                  shape={"round"}
                  borderColor={theme.colors.primary}
                  onClick={onApply}
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
