import { useState } from "react"

import { Button, CheckBox, theme } from "@project/common"
import { Col, Row, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { AssessmentSelectedItem } from "../../types"
import { AssessmentSettingsModal } from "./AssessmentSettingsModal"

//modal information
const displayOffTitle = 'Do you want to hide  "{{item_name}}"'
const displayOffConfirmMessage =
  'This item will no longer be displayed on the recording screen and will be moved to the "Hidden Item List".You can also make it "visible" again in the "hidden item list".'
const displayOnTitle = "Do you want to display the item?"
const displayOnConfirmMessage =
  "This item  will be displayed on the recording screen."
export const AssessmentSettingsCategoryItem = ({
  item,
  settingId,
  categoryId,
}: {
  item: any
  settingId: number
  categoryId: number
}): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] =
    useState<AssessmentSelectedItem | null>(null)
  return (
    <>
      <Row className={"grid__row__content"}>
        <Col
          span={8}
          className={"with__background row__cell"}
          style={{ justifyContent: "center" }}
        >
          {t(item?.title)}
        </Col>
        <Col span={8} className={"border_x row__cell"}>
          {item?.editable ? (
            <Button
              btnText={t("Edit")}
              shape={"round"}
              onClick={() => {
                setSelectedItem({
                  itemId: item?.id,
                  title: item?.title || "",
                  categoryId,
                })
                setOpen(true)
              }}
            />
          ) : (
            <Typography.Text
              style={{
                color: theme.colors.border,
              }}
            >
              {t("*Unable to edit")}
            </Typography.Text>
          )}
        </Col>
        <Col span={8} className={"row__cell"}>
          {item?.editable ? (
            <CheckBox
              label={t("Display")}
              checked={item?.show_display}
              onChange={({ target: { checked } }) => {
                setSelectedItem({
                  itemId: item?.id,
                  key: "show_display",
                  value: checked,
                  title: item?.title,
                  modalType: "confirm",
                  categoryId,
                  show_display: item?.show_display,
                })
                setOpen(true)
              }}
            />
          ) : null}
        </Col>
      </Row>
      {open && (
        <AssessmentSettingsModal
          modalType={selectedItem?.modalType ? "confirm" : "normal"}
          confirmMessage={
            selectedItem?.value
              ? t(displayOnConfirmMessage)
              : t(displayOffConfirmMessage)
          }
          label={t("Item Name")}
          title={
            selectedItem?.modalType
              ? selectedItem?.value
                ? t(displayOnTitle)
                : t(displayOffTitle, {
                    item_name: selectedItem?.title,
                  })
              : selectedItem?.itemId
                ? `${t("Edit")} ${selectedItem?.title}`
                : t("Creating a new item")
          }
          open={open}
          selectedItem={selectedItem}
          settingId={settingId}
          onCancel={() => {
            setSelectedItem(null)
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
