import { keys } from "@project/common"
import { UsedFacilityDataType } from "../../../types/nhif.types"

export const disablePrintBtn = (
  selectedRow: Record<string, UsedFacilityDataType[]>,
) => {
  if (keys(selectedRow).length === 0) {
    return true
  }

  return !keys(selectedRow).some((item) => selectedRow[item].length > 0)
}
