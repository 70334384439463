import React from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import StatsSection from "../StatsSection"
import { ColumnsType } from "antd/es/table"

// common | components
import {
  Card,
  Table,
  MonthPagination,
  useUpdateSearchParams,
} from "@project/common"

// styles
import { TreatmentImprovTableWrap } from "../TreatmentImproveTable.styles"

// services
import { TreatmentImprovementTableResponse } from "../../../services"

// utils
import {
  getStaticDataSrc,
  getTreatmentImproveDynamicColumn,
} from "../utils/treatmentImproveTableutils"

// types
import {
  TreatmentImproveDataType,
  TreatmentImprovementOperation,
} from "../../../types/TreatmentImproveTable.types"

const TreatmentImproveTableV2 = ({
  query,
  dataResponse,
  loadingTreatmentImproveData,
}: {
  loadingTreatmentImproveData: boolean
  query: Partial<TreatmentImprovementOperation>
  dataResponse: TreatmentImprovementTableResponse
}) => {
  const fiscal_year = query?.fiscal_year
  const { query: queryString } = useRouter()

  const [updateParams] = useUpdateSearchParams()

  const Columns: ColumnsType<TreatmentImproveDataType> = [
    {
      title: t("Facility name"),
      align: "center",
      dataIndex: "facility_title",
      key: "facility_title",
      width: 120,
      fixed: "left",
    },
    ...getTreatmentImproveDynamicColumn(dataResponse),
  ]

  return (
    <TreatmentImprovTableWrap>
      <Card
        title={t(
          "{{year}} fiscal year’s Treatment improvement addition amount result table",
          { year: query.fiscal_year },
        )}
        extra={
          <MonthPagination
            change={"year"}
            currentMonth={dayjs().month() + 1}
            showDate={false}
            currentYear={
              fiscal_year ? dayjs(fiscal_year).year() : dayjs().year()
            }
            onYearChange={(year) => {
              updateParams(
                { ...queryString, fiscal_year: year },
                "/treatment-improvement-addition-amount-result-table",
              )
            }}
            prevText={t("Last Year")}
            currentText={t("This Year")}
            nextText={t("Following Year")}
          />
        }
      >
        <StatsSection
          treatmentImproveData={dataResponse}
          isLoading={loadingTreatmentImproveData}
        />

        <Table
          columns={Columns}
          dataSource={getStaticDataSrc(+fiscal_year)}
          scroll={{ x: "max-content" }}
          loading={loadingTreatmentImproveData}
        />
      </Card>
    </TreatmentImprovTableWrap>
  )
}

export default TreatmentImproveTableV2
