import { getFullEraDate } from "@project/common"
import { useTranslation } from "react-i18next"
import {
  ActualTableContent,
  ReiwaBillingAmountContent,
} from "./DisabledUserOutpatientBenefitsPrintContent.style"

export const ReiwaDateTable = ({
  year,
  month,
}: {
  year: number
  month: number
}): JSX.Element => {
  const { t } = useTranslation()
  const era = getFullEraDate(year, month)
  return (
    <ReiwaBillingAmountContent>
      <ActualTableContent>
        <tbody id={"duobp__table-body"}>
          <tr>
            <td className={"align-center"}>{era?.substring(0, 2)}</td>
            <td className={"align-center"}>{era?.substring(2, 4)}</td>
            <td className={"align-center"}>{t("Year")}</td>
            <td className={"align-center"}>{`${month}`?.padStart(2, "0")}</td>
            <td className={"align-center"}>{t("Monthly")}</td>
          </tr>
        </tbody>
      </ActualTableContent>
    </ReiwaBillingAmountContent>
  )
}
