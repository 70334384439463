import { t } from "i18next"
import {
  SupportTagProps,
  TreatmentImproveDataType,
} from "../../../types/TreatmentImproveTable.types"
import { CoreCost, SystemStatusCell } from "../common"
import { TreatmentImprovementTableResponse } from "../../../services"
import { ColumnsType } from "antd/es/table"
import { at, sum } from "lodash"

export const getStaticDataSrc = (
  currentYear: number,
): TreatmentImproveDataType[] => [
  {
    facility_title: t("System status management settings"),
    attrib_key: "is_status_management_registered",
  },

  {
    facility_title: t(
      "Total amount of Welfare/nursing care staff treatment improvement addition",
    ),
    attrib_key: "total_addition",
  },

  {
    facility_title: t(`${currentYear}年04月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-04`,
  },
  {
    facility_title: t(`${currentYear}年05月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-05`,
  },
  {
    facility_title: t(`${currentYear}年06月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-06`,
  },
  {
    facility_title: t(`${currentYear}年07月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-07`,
  },
  {
    facility_title: t(`${currentYear}年08月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-08`,
  },
  {
    facility_title: t(`${currentYear}年09月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-09`,
  },
  {
    facility_title: t(`${currentYear}年10月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-10`,
  },
  {
    facility_title: t(`${currentYear}年11月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-11`,
  },
  {
    facility_title: t(`${currentYear}年12月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear}-12`,
  },
  {
    facility_title: t(`${currentYear + 1}年01月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear + 1}-01`,
  },
  {
    facility_title: t(`${currentYear + 1}年02月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear + 1}-02`,
  },
  {
    facility_title: t(`${currentYear + 1}年03月`),
    attrib_key: "monthly_data",
    year_month: `${currentYear + 1}-03`,
  },
]

export const getTreatmentImproveDynamicColumn = (
  dataResponse: TreatmentImprovementTableResponse,
): ColumnsType<TreatmentImproveDataType> => {
  if (!dataResponse?.year) {
    return []
  }
  return dataResponse?.facility_wise_data.map((facility) => {
    return {
      title: t(facility.facility_name),
      align: "center",
      dataIndex: `facility_${facility.facility_id}`,
      key: `facility_${facility.facility_id}`,
      width: 381,
      render: (_, rec) => {
        const key = rec?.attrib_key

        switch (key) {
          case "is_status_management_registered": {
            return (
              <SystemStatusCell
                type={facility.service_type}
                isRegistered={facility[key]}
                nursingStaffType={
                  (+facility.registered_addition_type ||
                    0) as SupportTagProps["nursingStaffType"]
                }
              />
            )
          }

          case "year_month": {
            return <div>{t("Total amount")}</div>
          }

          case "monthly_data": {
            const currentMonthData = facility["monthly_data"].find(
              (item) => item.year_month === rec?.year_month,
            )
            const valuesToSum = at(currentMonthData, [
              "treatment_support_addition",
              "special_support_addition",
              "base_up_addition",
            ])
            const totalSum = sum(valuesToSum)

            return (
              <div>
                {totalSum}
                {"円"}
              </div>
            )
          }

          default: {
            return <CoreCost cost={facility[key]} />
          }
        }
      },
    }
  })
}
