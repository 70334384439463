import React from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Form, FormProps } from "antd"

// components | common
import {
  Box,
  Button,
  ActionBtns,
  DatePicker,
  SelectInput,
  filterObject,
  AccordionCard,
  CheckBoxGroup,
  useUpdateSearchParams,
  omit,
} from "@project/common"
import AlertBoxV2 from "../../../common/AlertBoxV2"
import PrintControlPrintView from "./PrintControlPrintView"
import { OperationFields } from "../../../../ServiceProvisionRecord/common/OperationFields"

// styles
import { PrintControlContainer } from "./styles"

// context
import { useAuthContext } from "../../../../../context"

// types
import { CustomerInvoicePrintControlOperation } from "../../../../../types/nhif.types"

// constants
import {
  FULL_DATE_FORMAT,
  FULL_DATE_FORMAT_EN,
  PRINT_CONFIGURATION_OPTIONS,
  CUSTOMER_INVOICE_DISPLAY_SETTINGS,
} from "../../../../../constants"

export const CustomerInvoicePrintControl = () => {
  const { query } = useRouter()

  const { facilities } = useAuthContext()
  const selectedFacilityIds = (query.facilityIds as string)?.split(",")

  const [form] = Form.useForm<CustomerInvoicePrintControlOperation>()
  const dateValue = Form.useWatch("date", form)

  const [updateParams] = useUpdateSearchParams()

  const memoizedQuery: CustomerInvoicePrintControlOperation =
    React.useMemo(() => {
      const date = dayjs(query?.date as string).isValid()
        ? dayjs(query?.date as string)
        : dayjs()

      const print_config = query?.print_config
        ? query?.print_config.toString()
        : "1"

      const facility_to_output = query?.facility_to_output
        ? query?.facility_to_output.toString().split(",")
        : []

      return {
        date,
        print_config,
        facility_to_output,
      }
    }, [query])

  const onFinish: FormProps<CustomerInvoicePrintControlOperation>["onFinish"] =
    (values) => {
      updateParams(
        filterObject({
          ...query,
          ...values,
          date: values.date.format(FULL_DATE_FORMAT_EN),
          facility_to_output: values.facility_to_output.join(","),
        }),
        `/national-health-insurance-billing/prints/customer-invoice/print-control`,
      )
      // TODO: Make api call here
    }

  return (
    <PrintControlContainer>
      <AccordionCard
        defaultActiveKey={["print-control-container"]}
        items={[
          {
            key: "print-control-container",
            label: t("Operation Options"),
            children: (
              <Box display={"flex"} direction={"column"} gap={16}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  name={"control-hooks"}
                  initialValues={memoizedQuery}
                >
                  <Box display={"flex"} direction={"column"} gap={"16px"}>
                    <OperationFields
                      gap={"10px 24px"}
                      label={"Date of issue"}
                      labelWidth={"150px"}
                      align={"center"}
                      direction={{
                        "0px": "column",
                        "550px": "row",
                      }}
                    >
                      <Box
                        miw={135}
                        className={"input-field"}
                        display={"flex"}
                        direction={"column"}
                        gap={16}
                      >
                        <Form.Item name={"date"} id={"date"}>
                          <DatePicker
                            name={"date"}
                            format={FULL_DATE_FORMAT}
                            value={dateValue}
                            date={dateValue}
                            onDateChange={(value) =>
                              form.setFieldsValue({
                                date: value,
                              })
                            }
                          />
                        </Form.Item>
                      </Box>
                    </OperationFields>

                    <OperationFields
                      gap={"10px 24px"}
                      labelWidth={"150px"}
                      label={"Print configuration"}
                      align={{
                        "0px": "flex-start",
                        "550px": "center",
                      }}
                      direction={{
                        "0px": "column",
                        "550px": "row",
                      }}
                    >
                      <Box
                        w={300}
                        className={"input-field"}
                        display={"flex"}
                        direction={"column"}
                        gap={16}
                      >
                        <Form.Item name={"print_config"} id={"print_config"}>
                          <SelectInput
                            name={"print_config"}
                            width={"300px"}
                            options={PRINT_CONFIGURATION_OPTIONS}
                            placeholder={"---"}
                          />
                        </Form.Item>
                      </Box>
                    </OperationFields>

                    <OperationFields
                      gap={"10px 24px"}
                      labelWidth={"150px"}
                      label={"Facility to output"}
                      align={"flex-start"}
                      direction={{
                        "0px": "column",
                        "550px": "row",
                      }}
                    >
                      <Form.Item
                        name={"facility_to_output"}
                        id={"facility_to_output"}
                        className={"facility-to-output"}
                      >
                        <CheckBoxGroup
                          name={"facility_to_output"}
                          items={CUSTOMER_INVOICE_DISPLAY_SETTINGS}
                        />
                      </Form.Item>
                    </OperationFields>
                  </Box>

                  <Box display={"flex"} gap={12} mt={16} mb={8}>
                    <Button
                      shape={"round"}
                      btnText={t("Update display")}
                      htmlType={"submit"}
                    />
                  </Box>
                </Form>
              </Box>
            ),
          },
        ]}
      />

      <ActionBtns justify={"flex-start"} className={"action-btns"}>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          onClick={() =>
            updateParams(
              {
                ...omit(query, ["print_config", "date", "facility_to_output"]),
              },
              "/national-health-insurance-billing/prints/customer-invoice",
            )
          }
        />
        <Button
          shape={"round"}
          type={"primary"}
          iconType={"print"}
          btnText={t("Print")}
          onClick={() => window.print()}
        />
      </ActionBtns>

      <AlertBoxV2
        theme={"info"}
        variant={"WITH_OUT_COLLAPSE"}
        heading={t(
          "The billed amounts for the following facilities are totaled and output below.",
        )}
        description={facilities
          .map((facility) =>
            selectedFacilityIds.includes(facility.value)
              ? facility?.facility_name
              : "",
          )
          .filter((item) => item)
          .join(", ")}
        className={"alert-box-v2"}
      />

      <PrintControlPrintView />
    </PrintControlContainer>
  )
}
