import { DailyTransportDataType, SelectedTransportIds } from "../types"
import { commonAttribs } from "./generateNewTMBulkEditDriverStaff"
import { resetUser } from "./resetUser"

export const getResetValues = (
  originalValues: DailyTransportDataType["data"],
  selectedTransportIds: SelectedTransportIds,
): DailyTransportDataType => {
  const dropOffUsers = originalValues.drop_users.map((item) =>
    selectedTransportIds.drop_users.includes(item.id) ? resetUser(item) : item,
  )
  const pickUpUsers = originalValues.pickup_users.map((item) =>
    selectedTransportIds.pickup_users.includes(item.id)
      ? resetUser(item)
      : item,
  )
  const dropOffStaffs = originalValues.drop_staffs.map((item) =>
    selectedTransportIds.drop_staffs.includes(item.id)
      ? {
          ...item,
          ...commonAttribs,
        }
      : item,
  )
  const pickUpStaffs = originalValues.pickup_staffs.map((item) =>
    selectedTransportIds.pickup_staffs.includes(item.id)
      ? {
          ...item,
          ...commonAttribs,
        }
      : item,
  )

  return {
    data: {
      pickup_users: pickUpUsers,
      pickup_staffs: pickUpStaffs,
      drop_users: dropOffUsers,
      drop_staffs: dropOffStaffs,
    },
  }
}
