import { useRouter } from "next/router"
import React, { useContext, useMemo, useState } from "react"

// packages
import _ from "lodash"
import { t } from "i18next"
import { useMutation, useQuery } from "react-query"

// commons
import {
  keys,
  filterObject,
  useNotification,
  dynamicLangString,
} from "@project/common"

// contexts
import { AuthContext } from "./AuthContext"

// utils
import { queryStrToNumArray } from "../utils"
import { transformDriverStaffs } from "../containers"

// services
import {
  PartialNullish,
  UserDailyTransport,
  DailyTransportParam,
  PickupOrDropOffStaff,
  getUserDailyTransport,
  UserDailyTransportData,
  mutateUserDailyTransport,
} from "../services/user-transport-mgmt.services"
import { getAllStaff } from "../services"
import { fetchAllShuttleMaster } from "../services/shuttleMaster"

// types
import {
  OptionalPartial,
  TMBulkFormValue,
  DeletedItemsStore,
  DriverStaffTableType,
  DailyTransportDataType,
  TMBulkEditFormFilterType,
  TMBulkEditFormContextProps,
  SelectedTransportIds,
} from "../containers/TransportMgmtBulkEditInternal/types"
import { ErrorResponse } from "../types/variousAdditionsManagementOfMeetingMinutes.types"

/**
 *
 * @returns driver_staff_id, bulk editing driver is a bit tricky
 *  as we cannot just override all driver_ids for all items with bulk edit
 *
 * That is why we are first prioritizing first item/row to update
 * its driver_id (but if `newDriverStaffId` is already selected we don't wanna update it with `newDriverStaffId`)
 * we are simply returning `oldDriverStaffId` in such cases.
 */
function getDriverStaff({
  newDriverStaffId,
  oldDriverStaffId,
}: {
  newDriverStaffId?: number
  oldDriverStaffId?: number
  freshDailyTransportData: DailyTransportDataType
  staffType: "pickup_staffs" | "drop_staffs"
  index?: number
}) {
  if (newDriverStaffId) {
    return newDriverStaffId
  }

  return oldDriverStaffId
}

const TMBulkEditFormContext = React.createContext<
  TMBulkEditFormContextProps | undefined
>(undefined)

const TMBulkEditProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTransportIds, setSelectedTransportIds] =
    useState<SelectedTransportIds>({
      drop_staffs: [],
      drop_users: [],
      pickup_staffs: [],
      pickup_users: [],
    })
  // tm stands for transport management here.
  const [tmBulkValue, setTMBulkValue] = useState<Partial<any> | null>({
    pickup_data: {
      is_yes: "1",
      staff_data: {
        alcohol_check: "1",
        damage_to_device: "1",
        power: "1",
        visual_check: "1",
      },
    },
    dropOff_data: {
      is_yes: "1",
      staff_data: {
        alcohol_check: "1",
        damage_to_device: "1",
        power: "1",
        visual_check: "1",
      },
    },
  })
  const [dailyTransportData, setDailyTransportData] =
    useState<UserDailyTransportData | null>(null)
  const [initialTransportData, setInitialTransportData] = useState<string>(null)
  const [deletedItemsStore, setDeletedItemsStore] = useState<DeletedItemsStore>(
    {
      pickup_staffs: [],
      drop_staffs: [],
    },
  )

  const { query, back } = useRouter()

  // const queryClient = useQueryClient()
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const { showToast } = useNotification()

  const memoizedQuery: TMBulkEditFormFilterType = useMemo(() => {
    const queryConst = query as OptionalPartial<
      Record<keyof TMBulkEditFormFilterType, string | string[]>,
      "date"
    >

    const facility_ids = queryStrToNumArray(queryConst?.facility_ids)
    const service_type = queryStrToNumArray(queryConst?.service_type)
    const list_display = queryStrToNumArray(queryConst?.list_display)
    const date = queryConst.date.toString()

    return {
      facility_ids,
      service_type,
      list_display,
      date,
    }
  }, [])

  // 1. Getting daily user transport data. [QUERY]
  const { isLoading: loadingDailyTransportData } = useQuery(
    ["user-daily-transport-data", query],
    {
      queryFn: () => {
        const queryConst = query as Partial<
          Record<
            keyof OptionalPartial<
              Record<keyof TMBulkEditFormFilterType, string | string[]>,
              "date"
            >,
            string | string[]
          >
        >
        const param: DailyTransportParam = {
          date: queryConst.date.toString(),
          facilityIds: queryConst?.facility_ids as string,
          pickup_type: queryConst?.list_display as string,
          service: queryConst?.service_type as string,
        }
        return getUserDailyTransport(filterObject(param))
      },
      refetchOnWindowFocus: false,
      select: (data) => {
        const reFormattedData = { ...data }
        for (const key in data.data) {
          reFormattedData.data[key] = data.data[key].map((item) => {
            return {
              ...item,
              id:
                key === "pickup_users" || key === "drop_users"
                  ? item.user_id
                  : item.id,
              key:
                key === "pickup_users" || key === "drop_users"
                  ? item.user_id
                  : item.id,
            }
          })
        }
        return reFormattedData
      },
      onSuccess: async (resp) => {
        const data = resp.data
        setSelectedTransportIds({
          drop_staffs: _.map(data.drop_staffs, (item) => item.id),
          drop_users: _.map(data.drop_users, (item) => item.id),
          pickup_staffs: _.map(data.pickup_staffs, (item) => item.id),
          pickup_users: _.map(data.pickup_users, (item) => item.id),
        })
        setDailyTransportData(resp)
        setInitialTransportData(JSON.stringify(resp))
        // queryClient.setQueryData(["daily-transport-data"], data)
      },
    },
  )

  // 2. Getting all Vehicles  [QUERY]
  const { data: allVehicles, isLoading: loadingAllVehicles } = useQuery(
    ["all-vehicles"],
    {
      queryFn: () => fetchAllShuttleMaster({ page: 1, pageSize: "Infinity" }),
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.data.map((car) => ({
          value: car.id,
          label: car.car_name,
        }))
      },
    },
  )
  // 3. Getting all the instructors/staffs/[in this case drivers] [QUERY]
  const { data: allDriverStaffs, isLoading: loadingDriverStaffs } = useQuery(
    ["all-driver-staffs"],
    {
      queryFn: () => getAllStaff(),
      refetchOnWindowFocus: false,
      select: (data) => transformDriverStaffs(data),
    },
  )
  // 4. Pick up and drop off data mutation [MUTATION]
  const {
    mutate: mutateUserDailyTransportRec,
    isLoading: mutatingUserTransportRec,
  } = useMutation({
    mutationFn: mutateUserDailyTransport,
    onSuccess: () => {
      const message = dynamicLangString([
        "Users Daily Transport",
        "Updated Successfully",
      ])
      showToast({ type: "success", message, placement: "topRight" })
      back()
    },
    onError: (error: ErrorResponse) => {
      const errorMsg = error?.data?.error.message
      showToast({
        type: "error",
        message: errorMsg
          ? t(errorMsg)
          : t("Something went wrong. Please contact administrator"),
        placement: "topRight",
      })
    },
  })

  // 1. Handle Transport management bulk edit reflect/Action
  const handleTMBulkEditReflect = (
    bulkValues: Partial<TMBulkFormValue>,
    freshDailyTransportData: DailyTransportDataType,
  ) => {
    const clonedDTData = _.cloneDeep(freshDailyTransportData)

    keys(clonedDTData?.data).forEach((_key: keyof UserDailyTransport) => {
      if (
        (_key === "drop_users" || _key === "drop_staffs") &&
        bulkValues?.dropOff_data?.is_yes === "1"
      ) {
        clonedDTData.data[_key] = clonedDTData?.data[_key].map(
          (item, index) => {
            if (_key === "drop_users") {
              // make changes to the row item value only if the rowId is included in the selectedIds list.
              if (selectedTransportIds.drop_users.includes(item.id)) {
                return {
                  ...item,
                  ...bulkValues?.dropOff_data?.user_data,
                }
              }
              return item
            }

            if (selectedTransportIds.drop_staffs.includes(item.id)) {
              const staffNewBulkData = bulkValues?.dropOff_data?.staff_data
              return {
                ...item,
                ...staffNewBulkData,
                // during bulk edit form submission we only update driver_staff_id of the very first row only.
                driver_staff_id: getDriverStaff({
                  freshDailyTransportData,
                  staffType: "drop_staffs",
                  newDriverStaffId: staffNewBulkData?.driver_staff_id,
                  oldDriverStaffId: item?.driver_staff_id,
                  index,
                }),
              }
            }
            return item
          },
        )
        return
      }

      if (bulkValues?.pickup_data?.is_yes === "1") {
        clonedDTData.data[_key] = clonedDTData?.data[_key].map(
          (item, index) => {
            if (_key === "pickup_users") {
              // make changes to the row item value only if the rowId is included in the selectedIds list.
              if (selectedTransportIds.pickup_users.includes(item.id)) {
                return { ...item, ...bulkValues?.pickup_data?.user_data }
              }
              return item
            }
            if (selectedTransportIds.pickup_staffs.includes(item.id)) {
              const staffNewBulkData = bulkValues?.pickup_data?.staff_data
              return {
                ...item,
                ...staffNewBulkData,
                // during bulk edit form submission we only update driver_staff_id of the very first row only.
                driver_staff_id: getDriverStaff({
                  freshDailyTransportData,
                  index,
                  staffType: "pickup_staffs",
                  newDriverStaffId: staffNewBulkData?.driver_staff_id,
                  oldDriverStaffId: item?.driver_staff_id,
                }),
              }
            }
            return item
          },
        )
      }
    })
    setDailyTransportData(clonedDTData)
    setTMBulkValue(bulkValues)
  }

  // 2. This function will store deleted row/items from staff table (dropOff | pickup)
  const storeDeletedItems =
    <TStaffTableType extends DriverStaffTableType>(
      tableType: TStaffTableType,
    ) =>
    (
      rowData: TStaffTableType extends "pickup_staffs"
        ? PartialNullish<PickupOrDropOffStaff>
        : PartialNullish<PickupOrDropOffStaff>[],
    ) => {
      setDeletedItemsStore((prev) => ({
        ...prev,
        [tableType]: [...prev[tableType], rowData],
      }))
    }

  // 3. reset values
  const handleReset = (values: DailyTransportDataType) => {
    setDailyTransportData((prev) => ({
      data: {
        ...prev.data,
        ...values.data,
      },
    }))
  }

  // 1. To get name of the selected staff
  const getSelectedStaffName = (id?: number) => {
    return allDriverStaffs?.find((item) => item.id === id)?.label
  }
  // 2. To get name of the selected facility
  const getSelectedFacilityName = (id?: number) =>
    facilitiesCtx?.find((item) => item?.id == id)?.facility_name
  // 3. To get Yes Or No string for binary values
  const getYesOrNo = (binary?: "1" | "2") => (binary === "1" ? "OK" : "NG")

  return (
    <TMBulkEditFormContext.Provider
      value={{
        getYesOrNo,
        tmBulkValue,
        handleReset,
        memoizedQuery,
        deletedItemsStore,
        storeDeletedItems,
        dailyTransportData,
        selectedTransportIds,
        initialTransportData,
        getSelectedStaffName,
        setSelectedTransportIds,
        getSelectedFacilityName,
        handleTMBulkEditReflect,
        mutatingUserTransportRec,
        allVehicles: allVehicles,
        mutateUserDailyTransportRec,
        allDriverStaffs: allDriverStaffs,
        loadingDriverStaffs: loadingDriverStaffs,
        loadingForm: loadingDailyTransportData || loadingAllVehicles,
      }}
    >
      {children}
    </TMBulkEditFormContext.Provider>
  )
}

const useTMBulkEdit = () => {
  const context = React.useContext(TMBulkEditFormContext)
  if (!context) {
    throw new Error(
      "useServiceProvisionDetail must be used within a ServiceProvisionDetailProvider",
    )
  }
  return context
}

export { TMBulkEditProvider, useTMBulkEdit }
