import {
  ATTENDANCE_STATUS_TYPE,
  Button,
  CALENDAR_HOURS_MINUTES,
  CheckBox,
  ConfirmationModal,
  LabelTextWithLeftArrow,
  RadioGroup,
  SelectInput,
  USER_ATTENDANCE_VALUES,
} from "@project/common"
import { Flex, Typography } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { bodyTemperatureOptions } from "../../../../utils"
import { OperationOptionForm } from "../DailyListCommon/OperationOptions"

export const BulkEditOperationOptions = ({
  setParams,
  params,
  handleResetOrReflection,
}: {
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  handleResetOrReflection: (mode: "reset" | "reflect", values: any) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const initialReflectableValues = {
    attendance_type: USER_ATTENDANCE_VALUES.ATTENDANCE,
    start_time_hr: null,
    start_time_min: null,
    end_time_hr: null,
    end_time_min: null,
    start_time_flg: false,
    end_time_flg: false,
    temperature_first: null,
    temperature_second: null,
  }
  const [isOpen, setIsOpen] = useState(false)
  const [reflectableValues, setReflectableValues] = useState(
    initialReflectableValues,
  )

  return (
    <>
      <OperationOptionForm
        params={params}
        setParams={(values) => {
          setParams(values)
        }}
        url={`/user-attendance-calendar/daily-list/bulk-edit`}
      >
        <>
          <div className={"search__content"}>
            <LabelTextWithLeftArrow
              label={t("Adult support")}
              width={"150px"}
            />
          </div>
          <div className={"search__content"}>
            <LabelTextWithLeftArrow
              label={t("Attendance")}
              width={"150px"}
              className={"no_wrap"}
            />
            <RadioGroup
              options={ATTENDANCE_STATUS_TYPE || []}
              value={reflectableValues?.attendance_type}
              onChange={({ target: { value } }) => {
                setReflectableValues((prev) => ({
                  ...prev,
                  attendance_type: value,
                }))
              }}
              name={"attendance_type"}
            />
          </div>
          <div className={"search__content"}>
            <LabelTextWithLeftArrow label={t("Start time")} width={"150px"} />
            <Flex align={"center"} gap={16} wrap={"wrap"}>
              <Flex align={"center"} gap={10}>
                <SelectInput
                  name={"start_time_hr"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  value={reflectableValues?.start_time_hr || null}
                  options={CALENDAR_HOURS_MINUTES?.hours || []}
                  disabled={reflectableValues?.start_time_flg}
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      start_time_hr: val,
                      start_time_min: val
                        ? reflectableValues?.start_time_min || "00"
                        : null,
                    }))
                  }
                />
                <Typography.Text>{":"}</Typography.Text>
                <SelectInput
                  name={"start_time_min"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  disabled={
                    !reflectableValues?.start_time_hr ||
                    reflectableValues?.start_time_flg
                  }
                  value={reflectableValues?.start_time_min || null}
                  options={CALENDAR_HOURS_MINUTES?.minutes || []}
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      start_time_min: val,
                    }))
                  }
                />
              </Flex>
              <CheckBox
                label={t(
                  "Reflect the desired pick-up time in the room entry time",
                )}
                value={reflectableValues?.start_time_flg}
                onChange={({ target: { checked } }) =>
                  setReflectableValues((prev) => ({
                    ...prev,
                    start_time_flg: checked,
                  }))
                }
              />
            </Flex>
          </div>
          <div className={"search__content"}>
            <LabelTextWithLeftArrow label={t("End time")} width={"150px"} />
            <Flex align={"center"} gap={16} wrap={"wrap"}>
              <Flex align={"center"} gap={10}>
                <SelectInput
                  name={"end_time_hr"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  options={CALENDAR_HOURS_MINUTES?.hours || []}
                  value={reflectableValues?.end_time_hr || null}
                  disabled={reflectableValues?.end_time_flg}
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      end_time_hr: val,
                      end_time_min: val
                        ? reflectableValues?.end_time_min || "00"
                        : null,
                    }))
                  }
                />
                <Typography.Text>{":"}</Typography.Text>
                <SelectInput
                  name={"end_time_min"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  value={reflectableValues?.end_time_min || null}
                  options={CALENDAR_HOURS_MINUTES?.minutes || []}
                  disabled={
                    !reflectableValues?.end_time_hr ||
                    reflectableValues?.end_time_flg
                  }
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      end_time_min: val,
                    }))
                  }
                />
              </Flex>
              <CheckBox
                label={t(
                  "Reflect the desired drop-off time in the leaving time",
                )}
                value={reflectableValues?.end_time_flg}
                onChange={({ target: { checked } }) =>
                  setReflectableValues((prev) => ({
                    ...prev,
                    end_time_flg: checked,
                  }))
                }
              />
            </Flex>
          </div>
          <div className={"search__content"}>
            <LabelTextWithLeftArrow
              label={t("Body temperature upon entry")}
              width={"150px"}
            />
            <Flex align={"center"} gap={16} wrap={"wrap"}>
              <Flex align={"center"} gap={10}>
                <SelectInput
                  name={"temperature_first"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  options={bodyTemperatureOptions(31, 42)}
                  value={reflectableValues?.temperature_first || null}
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      temperature_first: val,
                      temperature_second: val
                        ? reflectableValues?.temperature_second || "0"
                        : null,
                    }))
                  }
                />
                <Typography.Text>{":"}</Typography.Text>
                <SelectInput
                  name={"dropoff_time_min"}
                  width={"60px"}
                  shortSelector
                  placeholder={"--"}
                  value={reflectableValues?.temperature_second || null}
                  options={bodyTemperatureOptions(0, 9)}
                  disabled={!reflectableValues?.temperature_first}
                  onChange={(val) =>
                    setReflectableValues((prev) => ({
                      ...prev,
                      temperature_second: val,
                    }))
                  }
                />
              </Flex>
              <Typography.Text>{"°C"}</Typography.Text>
            </Flex>
          </div>
          <Flex wrap={"wrap"} gap={16} className={"search__content"}>
            <Button
              btnText={t(" Reset")}
              shape={"round"}
              onClick={() => {
                setReflectableValues(initialReflectableValues)
                handleResetOrReflection("reset", {
                  initialReflectableValues,
                })
              }}
            />
            <Button
              btnText={t("Reflect")}
              shape={"round"}
              type={"primary"}
              onClick={() => setIsOpen(true)}
            />
            <Typography.Text type={"danger"}>
              {t(
                `※If there is a user whose record you do not want to overwrite, remove the check and press "Reflect"`,
              )}
            </Typography.Text>
          </Flex>
        </>
      </OperationOptionForm>
      {isOpen && (
        <ConfirmationModal
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          title={t("Do you want to overwrite the record?")}
          onOk={() => {
            handleResetOrReflection("reflect", reflectableValues)
            setIsOpen(false)
          }}
          msg={t(
            "If there is currently recorded information, it will be overwritten.Are you sure?",
          )}
          isOkButtonText={t("Ok")}
        />
      )}
    </>
  )
}
