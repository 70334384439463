import { API, removeBlankAttributes } from "@project/common"

export const getAllReceiptUsers = ({
  year,
  month,
  facility,
  include_usage_breakdown = "",
}) =>
  API.get(
    `/nhif/common-list?year=${year}&month=${month}&facilityIds=${facility}&include_usage_breakdown=${include_usage_breakdown}`,
  )

export const postTemporaryData = async ({
  year,
  month,
  service_typeA,
  service_typeB,
  service_typeC,
}): Promise<any> => {
  const data = {
    type_a: service_typeA,
    type_b: service_typeB,
    type_c: service_typeC,
  }
  const value = removeBlankAttributes(data)
  return API.post(`/nhif/receipt-provision-details/${year}/${month}`, value)
}

export const getTemporaryData = async ({ year, month }): Promise<any> => {
  return API.get(`/nhif/receipt-provision-details/${year}/${month}`)
}

export const getPhoneticOrder = async ({
  year,
  month,
  phonetic_order,
}: any): Promise<any> => {
  return API.get(
    `/nhif/receipt-provision-details/${year}/${month}?phonetic_order=${phonetic_order}`,
  )
}
