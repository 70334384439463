import {
  PRINT_CONFIGURATION_VALUES,
  theme,
  USER_RECEIPT_DISPLAY_SETTING_VALUES,
} from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface IBreakdownStyleWrapper {
  invoicePerPage?: number
}

const BreakdownStyleWrapper = styled.div<IBreakdownStyleWrapper>`
  width: 100%;
  min-height: ${(props) =>
    props.invoicePerPage == 2
      ? "48vh"
      : props.invoicePerPage == 3
        ? "30vh"
        : "auto"};
  page-break-inside: avoid;
  &.receipt-wrap {
    border: 1px solid ${theme.colors.border};
    padding: 20px;
    margin-bottom: 20px;
    &:not(:last-child) {
      page-break-after: ${(props) =>
        props.invoicePerPage == 1 ? "always" : "auto"};
    }
    @media print {
      padding: 0;
      border: none;
    }
  }
  .invoice-total {
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    max-width: 400px;
    padding: 2px 40px;
    border: 1px solid #000;
    .amount-figure {
      font-weight: bolder;
      font-size: 18pt;
    }
  }
  .break-down-table {
    width: 100%;
    table {
      margin-bottom: 5px;
      width: 100%;
      border-collapse: collapse;
      td {
        padding: 2px;
        font-size: 10pt;
        border: 1px solid #000;
      }
    }
    .price-cell {
      width: 10vw;
      text-align: right;
    }
    .center-align {
      text-align: center;
    }
    .no-border {
      border: none !important;
    }
    .height-5px {
      height: 5px;
    }
  }
`

const NonEnvelopWrapper = styled.div`
  .receipt-title {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  .receipt-head-info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .head-first {
      max-width: 45%;
      .user-info {
        font-weight: bold;
        font-size: 14pt;
      }
      .blank-25-height {
        height: 25px;
      }
    }
    .head-second {
      max-width: 55%;
    }
  }
`

const EnvelopWrapper = styled.div`
  width: 100%;
  .top-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .envelope-window {
    padding: 0 5mm;
    max-width: 45%;
    @media print {
      width: 80mm;
    }
  }
  .envelope-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    min-width: 55%;
    .envelope-receipt-title {
      font-size: large;
      font-weight: bold;
      border: 1px solid #000000;
      padding: 1px 20px;
      width: 100%;
      text-align: center;
    }
  }
`

type ActualCosts = {
  name: string
  price: number
}

interface IPrintWithBreakdown {
  params: {
    display_setting: Array<string>
    print_configuration: string
    date: string
  }
  data: any
  isCopy?: boolean
  era?: string
  asPartialTemplate?: boolean
  provisionText?: string
}

const PrintWithBreakdown = ({
  params,
  data,
  isCopy = false,
  era = "",
  asPartialTemplate = false,
  provisionText = "",
}: IPrintWithBreakdown) => {
  const { t } = useTranslation()
  const renderRecipientName = (
    <>
      {/* show both parent and child name if filtered */}
      {params?.display_setting?.includes(
        USER_RECEIPT_DISPLAY_SETTING_VALUES.ADD_CHILD_NAME_TO_RECIPIENT_NAME,
      ) ? (
        <>
          {data?.parent_name}
          {" (" + data?.user_name + ")"}
        </>
      ) : // display child name instead of parent name if filtered
      params?.display_setting?.includes(
          USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_RECIPIENT_NAME_TO_CHILD_NAME,
        ) ? (
        data?.user_name
      ) : (
        // display parent name by default
        data?.parent_name
      )}
      {" 様"}
    </>
  )
  const renderReceivedText = (applyBreakLine = false) => (
    <div className={"small-font"} style={{ whiteSpace: "pre" }}>
      {`但`}
      {params?.display_setting?.includes(
        USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_WORD_DISABILITY,
      )
        ? provisionText?.replace("障害", "障がい")
        : provisionText}
      {applyBreakLine && <br />}
      {params?.display_setting?.includes(
        USER_RECEIPT_DISPLAY_SETTING_VALUES.LEAVE_RECEIPT_DATE_BLANK,
      ) ? (
        <span style={{ whiteSpace: "pre" }}>
          {t(`令和      年      月      日 上記正に領収いたしました。`)}
        </span>
      ) : (
        t(`{{era}}{{date}} 上記正に領収いたしました。`, {
          era,
          date: dayjs(params?.date).format("MM月DD日"),
        })
      )}
    </div>
  )

  const renderReceiptTitle = isCopy ? t("Receipt (copy)") : t("Receipt")

  const renderAddress = (
    <table className={"address-container"}>
      <tr>
        <td className={"small-font"}>{t("Address")}</td>
        <td className={"small-font"}>
          {data.parent_zip_code}
          <br />
          {data.facility_address}
        </td>
      </tr>
      <tr>
        <td className={"small-font"}>{t("Phone number")}</td>
        <td className={"small-font"}>{data?.facility_phone || "-"}</td>
      </tr>
      <tr>
        <td className={"small-font"}>{t("name")}</td>
        <td className={"small-font"}>{data?.facility_name || "-"}</td>
      </tr>
    </table>
  )

  const renderEnvelopeStyle = (
    <EnvelopWrapper>
      <div className={"top-content"}>
        <div className={"envelope-window"}>
          <div className={"small-font"}>{data?.parent_zip_code}</div>
          <div className={"small-font"}>{data?.parent_address}</div>
          <br />
          <div>{renderRecipientName}</div>
        </div>
        <div className={"envelope-right"}>
          <div className={"envelope-receipt-title"}>{renderReceiptTitle}</div>
          <div className={"small-font"}>
            {`( ${data?.city_number}: ${data?.provision_city})`}
          </div>
          {renderAddress}
        </div>
      </div>
      <div className={"small-font"}>{renderReceivedText(true)}</div>
    </EnvelopWrapper>
  )
  const renderNonEnvelopeStyle = (
    <NonEnvelopWrapper>
      <div className={"receipt-title"}>{renderReceiptTitle}</div>
      <div className={"receipt-head-info-container"}>
        <div className={"head-first"}>
          <div className={"user-info"}>{renderRecipientName}</div>
          <div className={"small-font"}>
            {`( ${data?.city_number}: ${data?.provision_city})`}
          </div>
          <div className={"blank-25-height"}></div>
          <div className={"small-font"}>{renderReceivedText()}</div>
        </div>
        <div className={"head-second"}>{renderAddress}</div>
      </div>
    </NonEnvelopWrapper>
  )

  const getUsedActualCosts = (): {
    breakdown: Array<ActualCosts>
    total: number
  } => {
    let allActualCosts = []
    let totalActualCost = 0
    const actualCostTracking: { [key: string]: ActualCosts } = {}
    try {
      allActualCosts = JSON.parse(data?.actual_cost_burden_data)
    } catch (e) {
      allActualCosts = []
    }
    allActualCosts.forEach((ac) => {
      if (ac?.actual_cost_id) {
        const stringKey = ac?.actual_cost_id.toString()
        if (actualCostTracking?.[stringKey]) {
          actualCostTracking[stringKey].price += ac?.cost || 0
        } else {
          actualCostTracking[stringKey] = {
            name: ac?.actual_cost_name,
            price: ac?.cost || 0,
          }
        }
        totalActualCost += ac?.cost || 0
      }
    })

    return {
      breakdown:
        Object.values(actualCostTracking)?.filter(
          (ac) =>
            (ac.price == 0 &&
              !params?.display_setting?.includes(
                USER_RECEIPT_DISPLAY_SETTING_VALUES.HIDE_0_ACTUAL_COST,
              )) ||
            ac.price > 0,
        ) || [],
      total: totalActualCost,
    }
  }

  const renderBreakdownTable = () => {
    const burdenRow = (burdenAmount = data?.receipt_user_burden_amount) => (
      <tr>
        <td>
          {params?.display_setting?.includes(
            USER_RECEIPT_DISPLAY_SETTING_VALUES?.CHANGE_WORD_DISABILITY,
          )
            ? t("Disabled user day care benefit fee user burden amount")
            : t("Handicapped user day care benefit fee user burden amount")}
        </td>
        <td className={"price-cell"}>{burdenAmount?.toLocaleString()}</td>
      </tr>
    )

    const actualCostData = getUsedActualCosts()

    const actualCostRows = actualCostData.breakdown.map((ac) => (
      <tr key={ac.name}>
        <td>{ac.name}</td>
        <td className={"price-cell"}>{ac.price.toLocaleString()}</td>
      </tr>
    ))

    if (
      params?.display_setting?.includes(
        USER_RECEIPT_DISPLAY_SETTING_VALUES.DISPLAY_SUBSIDY,
      )
    ) {
      return (
        <div className={"break-down-table"}>
          <table>
            {burdenRow(data?.burden_max_month_without_subsidy)}
            <tr>
              <td>{t("Municipal subisidy invoice amount")}</td>
              <td className={"price-cell"}>{data?.subsidy_amount}</td>
            </tr>
            <tr>
              <td className={"no-border height-5px"}></td>
              <td className={"no-border height-5px"}></td>
            </tr>
            <tr>
              <td className={"center-align"}>
                {params?.display_setting?.includes(
                  USER_RECEIPT_DISPLAY_SETTING_VALUES?.CHANGE_WORD_DISABILITY,
                )
                  ? t("User charges for benefits for disable user")
                  : t("User charges for benefits for disabled user")}
              </td>
              <td className={"price-cell"}>
                {data?.receipt_user_burden_amount?.toLocaleString()}
              </td>
            </tr>
          </table>
          <table>
            {actualCostRows}
            <tr>
              <td className={"no-border height-5px"}></td>
              <td className={"no-border height-5px"}></td>
            </tr>
            <tr>
              <td className={"center-align"}>{t("Actual cost total")}</td>
              <td className={"price-cell"}>
                {actualCostData.total.toLocaleString()}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className={"center-align"}>{t("Grand total")}</td>
              <td className={"price-cell"}>
                {parseInt(data.billed_amount).toLocaleString()}
              </td>
            </tr>
          </table>
        </div>
      )
    }
    return (
      <div className={"break-down-table"}>
        <table>
          {burdenRow()}
          {actualCostRows}
          <tr>
            <td className={"center-align"}>{t("Total")}</td>
            <td className={"price-cell"}>
              {parseInt(data?.billed_amount).toLocaleString()}
            </td>
          </tr>
        </table>
      </div>
    )
  }

  const getInvoicePerPage = () => {
    if (params.print_configuration == PRINT_CONFIGURATION_VALUES.TWO_PER_SHEET)
      return 2
    if (
      params.print_configuration == PRINT_CONFIGURATION_VALUES.THREE_PER_SHEET
    )
      return 3
    return 1
  }

  return (
    <BreakdownStyleWrapper
      className={`${!asPartialTemplate && "receipt-wrap"}`}
      invoicePerPage={getInvoicePerPage()}
    >
      <div className={"receipt-header"}>
        {params?.display_setting?.includes(
          USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_ENVELOPE_LAYOUT,
        )
          ? renderEnvelopeStyle
          : renderNonEnvelopeStyle}

        <div className={"invoice-total"}>
          <div>{t("Amount of money")}</div>
          <div className={"amount-figure"}>{`￥${parseInt(
            data?.billed_amount,
          ).toLocaleString()} -`}</div>
        </div>
        {renderBreakdownTable()}
      </div>
    </BreakdownStyleWrapper>
  )
}

export default PrintWithBreakdown
