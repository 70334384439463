import {
  InputField,
  Table,
  theme,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Typography } from "antd"
import React, { useState } from "react"
import dayjs, { Dayjs } from "dayjs"
import { esbsdGrandTotal, esbsdRowsTotal } from "./helper"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { createId } from "@paralleldrive/cuid2"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { useMutation, useQuery } from "react-query"
import { I_EmploymentBusinessSaleDivision } from "../../../types"
import {
  getBusinessSaleDivisionByFacility,
  updateBusinessSaleDivisionByFacility,
} from "../../../services"
import { CTAbtn } from "../WagesRevenueCommon/CTAbtn"
import {
  EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB,
  EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB_INITIAL_COLS,
} from "./constants"
import { TabTopInfoContent } from "../WagesRevenueCommon/TabTopInfoContent"
import { useAuthContext } from "../../../context"

export const EmploymentSupportBusinessSalesDivisionTabEdit = ({
  selectedCol,
  setSelectedCol,
  params,
}: {
  selectedCol: I_EmploymentBusinessSaleDivision
  setSelectedCol: (values: I_EmploymentBusinessSaleDivision) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useAuthContext()
  const [values, setValues] = useState({
    year: params?.date ? dayjs(params?.date).year() : selectedCol?.year,
    month: params?.date ? dayjs(params?.date).month() + 1 : selectedCol?.month,
    facility_id: selectedCol?.facility_id || null,
  })
  const [newColumns, setNewColumns] = useState<any>([])
  // API call section start
  const { isLoading, isFetching } = useQuery<any>({
    queryKey: ["employmentBusinessSaleDivision", values],
    queryFn: () =>
      getBusinessSaleDivisionByFacility({
        year: values?.year || dayjs().year(),
        month: values?.month || dayjs().month() + 1,
        facility_id: values?.facility_id || selectedCol?.facility_id || 0,
      }),
    onSuccess: (data: any) => {
      const resData = data?.msg?.map((v) => ({
        ...v,
        id: v?.id || createId(),
      }))
      setNewColumns(resData || [])
    },
    refetchOnWindowFocus: false,
  })
  const { isLoading: isMutating, mutate } = useMutation({
    mutationFn: updateBusinessSaleDivisionByFacility,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
      setSelectedCol(null)
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  //API call section end
  const handleColEdit = ({
    id,
    key,
    value,
  }: {
    id: string
    key: string
    value: string | number
  }) => {
    const cols = newColumns?.map((col) => {
      if (col?.tempId === id) {
        return {
          ...col,
          [key]: value,
        }
      }
      return col
    })
    setNewColumns(cols)
  }
  const getNewColumns = () => {
    return newColumns?.map((item: any, index) => ({
      key: index + 1,
      title: (
        <Flex align={"center"} gap={8}>
          <InputField
            value={item?.work_title || null}
            height={"40px"}
            placeholder={t("○○  Work")}
            onChange={({ target: { value } }) => {
              handleColEdit({
                id: item?.tempId,
                key: "work_title",
                value: value,
              })
            }}
          />
          <div
            onClick={() => {
              setNewColumns([
                ...newColumns?.slice(0, index),
                ...newColumns?.slice(index + 1),
              ])
            }}
          >
            <MinusCircleOutlined
              style={{
                color: theme.colors?.error,
              }}
            />
          </div>
        </Flex>
      ),
      render: (row) => {
        if (row?.key === "total") {
          return <Flex>{esbsdGrandTotal(item)}</Flex>
        }
        return (
          <InputField
            value={item[row?.key]}
            height={"40px"}
            type={"number"}
            onChange={({ target: { value } }) => {
              if (!isNaN(+value)) {
                handleColEdit({
                  id: item?.tempId,
                  key: row?.key,
                  value: +value,
                })
              }
            }}
          />
        )
      },
      width: 200,
    }))
  }

  const columns: ColumnsType<any> = [
    {
      title: t("Accounting Subject"),
      dataIndex: "subject",
      key: "subject",
      width: 200,
      align: "center",
      render: (text) => <AccountSubjectContent label={text} />,
    },
    {
      title: t("Total"),
      key: "amount",
      align: "center",
      width: 125,
      render: (row) => {
        const totalData = esbsdRowsTotal(newColumns || [])
        if (row?.key === "total") {
          return <Flex>{esbsdGrandTotal(totalData) || ""}</Flex>
        }
        return <Flex>{totalData?.[row?.key] || 0}</Flex>
      },
    },
    ...getNewColumns(),
    {
      key: "add",
      title: () => (
        <div
          onClick={() =>
            setNewColumns([
              ...newColumns,
              {
                ...EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB_INITIAL_COLS,
                tempId: createId(),
                facility_id: selectedCol?.facility_id || 0,
                facility_name: selectedCol?.facility_name || "",
                work_title: "",
                year: values?.year || dayjs().year(),
                month: values?.month || dayjs().month() + 1,
              },
            ])
          }
        >
          <PlusCircleOutlined
            style={{
              color: theme.colors.action,
              cursor: "pointer",
            }}
          />
        </div>
      ),
      align: "center",
      width: newColumns?.length ? 15 : 10,
      fixed: "right",
      onHeaderCell: () => {
        return {
          style: {
            padding: "0px",
            width: "10px !important",
          },
        }
      },
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 32,
          }
        }
        if (index >= 1) {
          return {
            rowSpan: 0,
          }
        }
        return {}
      },
    },
  ]
  const handleSave = () => {
    mutate({
      year: values?.year || dayjs().year(),
      month: values?.month || dayjs().month() + 1,
      facility_id: selectedCol?.facility_id,
      values: newColumns,
    })
  }

  return (
    <Flex vertical gap={16}>
      <TabTopContent
        tab={t("Revenue")}
        onChange={(date: Dayjs) => {
          setValues({
            ...values,
            year: date?.year(),
            month: date?.month() + 1,
          })
          updateParams(
            {
              tab: "employment",
              date: dayjs(date).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      <CTAbtn
        isLoading={isMutating}
        onClick={(action) => {
          if (action === "cancel") {
            setSelectedCol(null)
            return
          }
          handleSave()
        }}
      />
      <TabTopInfoContent
        facilities={facilities || []}
        facility={
          String(values?.facility_id) ||
          String(selectedCol?.facility_id) ||
          null
        }
        onChange={(fid: any) =>
          setValues({
            ...values,
            facility_id: fid,
          })
        }
      />

      <Table
        columns={columns}
        dataSource={EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB || []}
        headerBorderRadius={0}
        loading={isLoading || isFetching}
        title={() => (
          <Flex
            vertical
            align={"center"}
            style={{ background: "#B1D5EA", padding: "8px" }}
            gap={8}
          >
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("Employment Support Business Sales Division")}
            </Typography.Title>
            <Flex>
              <Typography.Text>
                {dayjs(params?.date).format("YYYY/MM/DD")}
              </Typography.Text>
              {"-"}
              <Typography.Text>
                {dayjs(params?.date)
                  .endOf("month")
                  .format("YYYY/MM/DD")}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        scroll={{ x: true }}
      />
      <CTAbtn
        isLoading={isMutating}
        onClick={(action) => {
          if (action === "cancel") {
            setSelectedCol(null)
            return
          }
          handleSave()
        }}
      />
    </Flex>
  )
}
