import {
  Button,
  Card,
  Modal,
  Table,
  dynamicLangString,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Typography, notification } from "antd"
import type { ColumnsType } from "antd/es/table"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import styled from "styled-components"
import { PAGE_SIZE } from "../../../constants"
import { AuthContext } from "../../../context"
import {
  fetchWagesList,
  updateConfirmPaySlip,
} from "../../../services/wagesManagement"
import {
  WageManagementPermission,
  hasPermissionForFacility,
} from "../../../utils/PermissionKeys"
import { WageManagementOperation } from "../WageManagementOperation/WageManagementOperation"
const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px 30px 0px;
`
export const WageManagementContent = (): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { facilities, permissions, isOwner } = useContext(AuthContext)
  const [updateParams] = useUpdateSearchParams()
  const { page: currentPage, facilityIds } = router?.query as any
  const [confirmId, setConfirmId] = useState(null)
  const [isConfirm, setIsConfirm] = useState(false)
  const [searchEnabled, setSearchEnabled] = useState(false)
  const listContent = useRef<any>(null)
  const selectedFacilities = facilityIds?.split(",")
  const [params, setParams] = useState<any>({
    page: currentPage ? parseInt(currentPage as string, 10) : 1,
    facilityIds: selectedFacilities || [],
    user_id: null,
  })

  const hasPaidLeaveSettingAccess = (facilityId) =>
    isOwner ||
    hasPermissionForFacility(
      permissions,
      WageManagementPermission,
      "write",
      facilityId,
    )

  const {
    isLoading,
    isFetching,
    data: response,
    refetch,
  } = useQuery(
    ["fetch-wages-list", { ...params, request_type: "contractedOnly" }],
    {
      queryFn: () =>
        fetchWagesList({
          ...params,
        }),
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: searchEnabled || !!params?.page,
      select: (res) => {
        return {
          ...res,
          data: res?.data?.map((item) => ({
            ...item,
            facility_type: facilities?.find((v) => v?.id === item?.facility_id)
              ?.facility_type,
          })),
        }
      },
      onSuccess: () => {
        setSearchEnabled(false)
      },
      onError: () => {
        setSearchEnabled(false)
      },
    },
  )

  const { mutate: confirmPaySlip, isLoading: isConfirming } = useMutation(
    updateConfirmPaySlip,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Confirm Pay Slip",
            "Updated Successfully",
          ]),
        })
        queryClient.invalidateQueries(["update-confirm-pay-slip"])
        setIsConfirm(false)
        setSearchEnabled(false)
        setConfirmId(null)
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
        setIsConfirm(false)
        setConfirmId(null)
      },
    },
  )

  const getServiceTypeAColumns = (align: any) => {
    const facilityWithTypeA = response?.data?.every(
      (v) => v?.facility_type !== 1,
    )
    if (facilityWithTypeA) {
      return []
    }
    return [
      {
        key: "leave_information",
        title: (
          <span style={{ whiteSpace: "nowrap" }}>{t("Leave Information")}</span>
        ),
        align,
        render: (row) => {
          if (row?.facility_type != 1) {
            return null
          }
          return (
            <Flex vertical>
              <Flex gap={2} wrap={"nowrap"}>
                <Typography.Text style={{ whiteSpace: "nowrap" }}>
                  {t("Leave Remaining")}
                  {":"}
                </Typography.Text>
                <Typography.Text
                  type={"success"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {row?.total_paid_leave_left}
                  {t("Days ")}
                </Typography.Text>
              </Flex>
              <Flex gap={2} wrap={"nowrap"}>
                <Typography.Text style={{ whiteSpace: "nowrap" }}>
                  {t("Leave Taken")}
                  {":"}
                </Typography.Text>
                <Typography.Text
                  type={"danger"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {row?.used_paid_leave || 0}
                  {t("Days ")}
                </Typography.Text>
              </Flex>
            </Flex>
          )
        },
      },
      {
        key: "paid_leave",
        title: t("Paid leave setting"),
        align: align,
        render: (row) => {
          const hasAccess = hasPaidLeaveSettingAccess(row?.facility_id)
          if (row?.facility_type != 1) {
            return null
          }
          return (
            <Flex justify={"center"}>
              <Button
                btnText={t("Setting")}
                width={"130px"}
                borderColor={theme.colors["action-light"]}
                btnBg={`linear-gradient(0deg,${theme.colors.rgba.blue},${theme.colors.rgba.blue})`}
                onClick={() =>
                  router.push(`/wage-management/leave-settings/${row?.id}`)
                }
                disabled={!hasAccess}
              />
            </Flex>
          )
        },
      },
    ]
  }

  const columns: ColumnsType<any> = [
    {
      key: "user_name",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("User Name")}</span>,
      align: "center",
      width: 200,
      render: (row) => {
        return (
          <Flex align={"center"} gap={8}>
            {!row?.facility_id && (
              <img src={"./assets/icons/danger.svg"} alt={"icon"} />
            )}
            <span> {row?.username}</span>
          </Flex>
        )
      },
    },
    ...getServiceTypeAColumns("center"),
    {
      key: "payslip",
      title: t("Payslip confirmation alert"),
      align: "center",
      width: 200,
      render: (row) => (
        <Flex justify={"center"}>
          {row?.is_confirmed ? (
            <Button
              btnText={t("Confirmed")}
              btnBg={theme.colors["action-light"]}
              textColor={theme.colors.white}
              width={"130px"}
            />
          ) : (
            <Button
              btnText={t("Confirmation Required")}
              width={"130px"}
              borderColor={theme.colors["action-light"]}
              btnBg={`linear-gradient(0deg,${theme.colors.rgba.blue},${theme.colors.rgba.blue})`}
              onClick={() => {
                setConfirmId(row?.id)
                setIsConfirm(true)
              }}
            />
          )}
        </Flex>
      ),
    },
    {
      key: "payslip_list",
      title: t("View payslip list"),
      align: "center",
      width: 200,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Confirm Payslip")}
            width={"135px"}
            borderColor={theme.colors["action-light"]}
            btnBg={`linear-gradient(0deg,${theme.colors.rgba.blue},${theme.colors.rgba.blue})`}
            onClick={() =>
              router.push(
                `/wage-management/payslip-list/${row?.id}?facility=${row?.facility_id}`,
              )
            }
          />
        </Flex>
      ),
    },
  ]

  const onSearch = (data: any) => {
    updateParams(
      {
        facilityIds: data?.facilityIds?.toString(),
        user_id: data?.user_id,
        page: params?.page || 1,
      },
      `/wage-management`,
    )
    scrollToSelectedElement(listContent)
    setParams({ ...data })
    setSearchEnabled(true)
  }
  const resetHandler = () => {
    setParams(() => {
      return {
        ...params,
        facilityIds: [],
        user_id: null,
      }
    })
    updateParams({}, `/wage-management`)
    setSearchEnabled(true)
  }
  const handlePaginationChange = (page: number) => {
    setParams((prev) => ({ ...prev, page }))
    scrollToSelectedElement(listContent)
    router.push(
      {
        pathname: `/wage-management`,
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }
  const onCancel = () => {
    setIsConfirm(false)
    setConfirmId(null)
  }

  const onOk = () => {
    confirmPaySlip(confirmId)
  }

  return (
    <>
      <Flex vertical gap={16}>
        <WageManagementOperation
          params={params}
          onSearch={onSearch}
          resetHandler={resetHandler}
        />
        <Card
          title={t("{{facility_name}} Work condition management list", {
            facility_name: "",
          })}
          boxShadow={"4px 4px 16px 0px #3333331F"}
        >
          <div ref={listContent}>
            <Table
              columns={columns}
              dataSource={response?.data || []}
              showPaginationOf={"both"}
              pagination={false}
              pageSize={PAGE_SIZE}
              total={response?.count || 0}
              current={params?.page || 1}
              onChange={handlePaginationChange}
              scroll={{ x: 800 }}
              loading={isLoading || isFetching || isConfirming}
              tableAttributesExtra={
                <Flex gap={8} align={"center"}>
                  <img src={"./assets/icons/danger.svg"} alt={"icon"} />
                  <span>{t("Users without full facility contracts")}</span>
                </Flex>
              }
            ></Table>
          </div>
        </Card>
      </Flex>

      <Modal
        open={isConfirm}
        onCancel={() => onCancel()}
        title={t("Confirmation")}
        hederPadding={"15px 30px"}
        width={456}
        footer={null}
        bodyPadding={"20px"}
      >
        <ModalWrapper>
          <h1>{t("Are u sure u want to Confirm ?")}</h1>
        </ModalWrapper>

        <Flex wrap={"wrap"} gap={16}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={() => onCancel()}
            disabled={isConfirming}
          />
          <Button
            btnText={t("Confirm")}
            type={"primary"}
            shape={"round"}
            onClick={() => onOk()}
            disabled={isConfirming}
          />
        </Flex>
      </Modal>
    </>
  )
}
