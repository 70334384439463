import { useRouter } from "next/router"
import React, { useMemo, useRef } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { useMutation, useQuery } from "react-query"

// commons  | components
import {
  filterObject,
  useNotification,
  dynamicLangString,
  useUpdateSearchParams,
  scrollToSelectedElement,
} from "@project/common"
import { TransportMgmtDriverListTable } from "./TransportMgmtDriverListTable"
import { TransportMgmtDriverListOperation } from "./TransportMgmtDriverListOperation"

// styles
import { TMDriverListContainer } from "./TransportMgmtDriverList.styles"

// utils
import { queryStrToNumArray } from "./utils/queryStrToNumArray"

// types
import { TransportMgmtDriverFilterType } from "../../types/TMDriverList.types"
import { ErrorResponse } from "../../types/variousAdditionsManagementOfMeetingMinutes.types"

// constants
import {
  DailyTransportParam,
  getUserDailyTransport,
  mutateUserDailyTransport,
} from "../../services/user-transport-mgmt.services"

// !## Main Component
const TransportMgmtDriverList = () => {
  const { query } = useRouter()
  const listRef = useRef<HTMLDivElement>(null)

  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()

  // !## query params
  const memoizedQuery: TransportMgmtDriverFilterType = useMemo(() => {
    const queryConst = query as Partial<
      Record<keyof TransportMgmtDriverFilterType, string | string[]>
    >

    const facility_ids = queryStrToNumArray(queryConst?.facility_ids)
    const service_type = queryStrToNumArray(queryConst?.service_type)
    const list_display = queryStrToNumArray(queryConst?.list_display)
    const date = queryConst?.date
      ? queryConst.date.toString()
      : dayjs().format("YYYY-MM-DD")
    const driver_staff_id = queryConst?.driver_staff_id
      ? +queryConst?.driver_staff_id
      : null

    return {
      facility_ids,
      service_type,
      list_display,
      date,
      driver_staff_id,
    }
  }, [query])

  // !## All API Calls
  // 1. Getting daily user transport data. [QUERY]
  const { data: dailyTransportData, isLoading: loadingDailyTransportData } =
    useQuery(["daily-transport-lists-driver", query], {
      queryFn: () => {
        const queryConst = query as Partial<
          Record<keyof TransportMgmtDriverFilterType, string | string[]>
        >

        const param: DailyTransportParam = {
          date: queryConst?.date.toString(),
          facilityIds: queryConst?.facility_ids as string,
          pickup_type: queryConst?.list_display as string,
          service: queryConst?.service_type as string,
          staff_id: queryConst?.driver_staff_id as string,
        }
        return getUserDailyTransport(filterObject(param))
      },
      refetchOnWindowFocus: false,
      select: (data) => {
        const reFormattedData = { ...data }
        for (const key in data.data) {
          reFormattedData.data[key] = data.data[key].map((item) => {
            return {
              ...item,
              key: item.id,
            }
          })
        }
        return reFormattedData
      },
    })
  // 2. Pick up and drop off data mutation [MUTATION]
  const {
    isLoading: mutatingUserTransportRec,
    mutateAsync: asyncMutateTransportRec,
  } = useMutation({
    mutationFn: mutateUserDailyTransport,
    onSuccess: () => {
      const message = dynamicLangString([
        "Users Daily Transport",
        "Updated Successfully",
      ])
      showToast({ type: "success", message, placement: "topRight" })

      updateParams(
        query,
        `/user-transport-management/${memoizedQuery.date}/drivers`,
      )
    },
    onError: (error: ErrorResponse) => {
      const errorMsg = error?.data?.error.message
      showToast({
        type: "error",
        message: errorMsg
          ? t(errorMsg)
          : t("Something went wrong. Please contact administrator"),
        placement: "topRight",
      })
    },
  })

  // !## All Event handlers
  const handleScrollToList = () => {
    scrollToSelectedElement(listRef)
  }

  return (
    <TMDriverListContainer display={"flex"} direction={"column"} gap={"lg"}>
      <TransportMgmtDriverListOperation
        handleScrollToList={handleScrollToList}
        filterInitValues={memoizedQuery}
      />

      <TransportMgmtDriverListTable
        ref={listRef}
        date={query.date as string}
        asyncMutateTransportRec={asyncMutateTransportRec}
        mutatingUserTransportRec={mutatingUserTransportRec}
        loadingDailyTransportData={loadingDailyTransportData}
        respData={{
          data: {
            drop_staffs: dailyTransportData?.data?.drop_staffs,
            pickup_staffs: dailyTransportData?.data?.pickup_staffs,
          },
        }}
      />
    </TMDriverListContainer>
  )
}

export * from "./utils"
export { TransportMgmtDriverList }
export * from "../../types/TMDriverList.types"
export * from "./TransportMgmtDriverListOperation"
