import { WageRevenueTabResponse } from "../../../types"
const firstColumnSharedCellSingle = (index: number) => {
  //Increase/decrease in service activities-start
  if (index === 0) {
    return {
      rowSpan: 17,
    }
  }
  if (index >= 1 && index <= 16) {
    return {
      rowSpan: 0,
    }
  }
  //Increase/decrease in service activities-end

  //INCREASE/DECREASE in Non-Service Activities-start
  if (index === 17) {
    return {
      rowSpan: 16,
    }
  }
  if (index >= 18 && index <= 32) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 33 || index === 53) {
    return {
      colSpan: 3,
    }
  }
  //Special earning meter (8)-start
  if (index === 34) {
    return {
      rowSpan: 19,
    }
  }
  if (index >= 35 && index <= 52) {
    return {
      rowSpan: 0,
    }
  }
  //Special earning meter (8)-end

  //Net Increase/Decrease in Activities Carried Forward - start
  if (index === 54) {
    return {
      rowSpan: 7,
    }
  }
  if (index >= 55 && index <= 60) {
    return {
      rowSpan: 0,
    }
  }
  //Net Increase/Decrease in Activities Carried Forward - end
  return {}
}

const firstColumnSharedCellMultiple = (index: number) => {
  //Increase/decrease in service activities-start
  if (index === 0) {
    return {
      rowSpan: 17,
    }
  }
  if (index >= 1 && index <= 16) {
    return {
      rowSpan: 0,
    }
  }

  //Increase/decrease in service activities-end
  //INCREASE/DECREASE in Non-Service Activities-start
  if (index === 17) {
    return {
      rowSpan: 16,
    }
  }
  if (index >= 18 && index <= 32) {
    return {
      rowSpan: 0,
    }
  }

  //Special earning meter (8)-start
  if (index === 34) {
    return {
      rowSpan: 24,
    }
  }
  if (index >= 35 && index <= 57) {
    return {
      rowSpan: 0,
    }
  }

  //Special earning meter (8)-end
  //Net Increase/Decrease in Activities Carried Forward - start
  if (index === 59) {
    return {
      rowSpan: 7,
    }
  }
  if (index >= 60) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 33 || index === 58) {
    return {
      colSpan: 3,
    }
  }
  //Net Increase/Decrease in Activities Carried Forward - end

  return {}
}
export const firstColumnSharedCell = (index: number, isMultiple?: boolean) => {
  if (isMultiple) return firstColumnSharedCellMultiple(index)
  return firstColumnSharedCellSingle(index)
}

//second column -start
const secondColumnSharedCellMultiple = (index: number) => {
  if (index === 0) {
    return {
      rowSpan: 5,
    }
  }
  if (index >= 1 && index <= 4) {
    return {
      rowSpan: 0,
    }
  }
  //service activities - cost
  if (index === 5) {
    return {
      rowSpan: 11,
    }
  }
  if (index >= 6 && index <= 15) {
    return {
      rowSpan: 0,
    }
  }

  //non-service activities - profit
  if (index === 17) {
    return {
      rowSpan: 8,
    }
  }
  if (index >= 18 && index <= 24) {
    return {
      rowSpan: 0,
    }
  }
  //non-service activities - cost
  if (index === 25) {
    return {
      rowSpan: 7,
    }
  }
  if (index >= 26 && index <= 31) {
    return {
      rowSpan: 0,
    }
  }

  //Special earning meter
  if (index === 34) {
    return {
      rowSpan: 11,
    }
  }
  if (index >= 35 && index <= 44) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 45) {
    return {
      rowSpan: 12,
    }
  }
  if (index >= 46 && index <= 56) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 16 || index === 32 || index === 57 || index >= 59) {
    return {
      colSpan: 2,
    }
  }
  if (index === 33 || index === 58) {
    return {
      colSpan: 0,
    }
  }

  return {}
}
const secondColumnSharedCellSingle = (index: number) => {
  if (index === 0) {
    return {
      rowSpan: 5,
    }
  }
  if (index >= 1 && index <= 4) {
    return {
      rowSpan: 0,
    }
  }

  //service activities - cost
  if (index === 5) {
    return {
      rowSpan: 11,
    }
  }
  if (index >= 6 && index <= 15) {
    return {
      rowSpan: 0,
    }
  }

  //non-service activities - profit
  if (index === 17) {
    return {
      rowSpan: 8,
    }
  }
  if (index >= 18 && index <= 24) {
    return {
      rowSpan: 0,
    }
  }
  //non-service activities - cost
  if (index === 25) {
    return {
      rowSpan: 7,
    }
  }
  if (index >= 26 && index <= 31) {
    return {
      rowSpan: 0,
    }
  }
  //Special earning meter - profit
  if (index === 34) {
    return {
      rowSpan: 7,
    }
  }
  if (index >= 35 && index <= 40) {
    return {
      rowSpan: 0,
    }
  }
  //Special earning meter -cost
  if (index === 41) {
    return {
      rowSpan: 11,
    }
  }
  if (index >= 42 && index <= 51) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 16 || index === 32 || index >= 54) {
    return {
      colSpan: 2,
    }
  }
  if (index === 33 || index === 53) {
    return {
      colSpan: 0,
    }
  }

  return {}
}
export const secondColumnSharedCell = (index: number, isMultiple?: boolean) => {
  if (isMultiple) return secondColumnSharedCellMultiple(index)
  return secondColumnSharedCellSingle(index)
}
//second column -end
export const AccountSubjectColumnSharedCell = (index, isMultiple?: boolean) => {
  if (isMultiple) {
    if (
      index === 33 ||
      index === 58 ||
      index === 16 ||
      index === 32 ||
      index === 57 ||
      index >= 59
    ) {
      return {
        colSpan: 0,
      }
    }
    return {}
  }
  if (
    index === 33 ||
    index === 53 ||
    index === 16 ||
    index === 32 ||
    index >= 54
  ) {
    return {
      colSpan: 0,
    }
  }
  return {}
}
export const getFacilityWiseRowsValues = (
  key: string,
  row: WageRevenueTabResponse,
) => {
  return row ? row[key] ?? "" : ""
}
export const getTotal = (item: WageRevenueTabResponse) => {
  const total_1 =
    item?.income_from_employment_support_business +
    item?.welfare_service_income +
    item?.donation_income +
    item?.other_income

  const total_2 =
    item?.personnel_expense +
    item?.business_expense +
    item?.office_expense +
    item?.employment_support_business_expenses +
    item?.user_cost_redaction +
    item?.depreciation_amortization +
    item?.reversal_special_reserve +
    item?.uncollectible_expense +
    item?.uncollectible_allowance +
    item?.other_expenses

  const total_4 =
    item?.borrowed_money_interest_subsidy +
    item?.received_interest_dividend_revenue +
    item?.securities_valuation_profit +
    item?.gain_securities_sale +
    item?.investment_securities_evaluation_profit +
    item?.investment_securities_gain_gains +
    item?.other_service_activity_non_income_revenue

  const total_5 =
    item?.interest_expense +
    item?.securities_valuation_loss +
    item?.sale_of_securities +
    item?.investment_securities_evaluation_loss +
    item?.investment_securities_sale_loss +
    item?.other_service_activity_foreign_expenses

  const total_3 = total_1 - total_2

  const total_6 = total_4 - total_5

  const total_7 = total_3 + total_6

  const total_8 =
    item?.facility_maintenance_subsidy_revenue +
      item?.facility_maintenance_donation_revenue +
      item?.long_term_operating_fund_borrowing +
      item?.fixed_assets_received +
      item?.property_gain +
      item?.out_of_business_payment_income_revenue || 0

  const total_9 =
    item?.basic_gold_paid_amount +
      item?.asset_evaluation_loss +
      item?.fixed_asset_sale_disposal_loss +
      item?.special_reserve_depreciation_removal +
      item?.special_reserve_fund +
      item?.disaster_loss +
      item?.other_extraordinary_losses || 0

  const total_10 = total_8 - total_9

  const total_11 = total_7 + total_10
  return {
    total_1,
    total_2,
    total_4,
    total_5,
    total_3,
    total_6,
    total_7,
    total_8,
    total_9,
    total_10,
    total_11,
  }
}
export const sumOfMultipleFacilityRows = (item: WageRevenueTabResponse) => {
  const meter_1_total =
    item?.employement_support_business_income +
    item?.disability_welfare_service +
    item?.expenses_donation_revenue +
    item?.other_income
  const meter_2_total =
    item?.labor_cost +
    item?.expenses +
    item?.administrative_expenses +
    item?.employment_support_project_cost +
    item?.user_burden_light_reduction +
    item?.depreciation_expenses +
    item?.special_reserve_depreciation +
    item?.unable_to_collect +
    item?.incapacitated_gold_reinforcement +
    item?.other_expenses

  const meter_3_total = meter_1_total - meter_2_total
  const meter_4_total =
    item?.borrowed_money_interest_subsidy +
    item?.received_interest_dividend_revenue +
    item?.securities_valuation_profit +
    item?.gain_securities_sale +
    item?.investment_securities_evaluation_profit +
    item?.investment_securities_gain_gains +
    item?.other_service_activity_non_income_revenue

  const meter_5_total =
    item?.interest_expense +
    item?.securities_valuation_loss +
    item?.sale_of_securities +
    item?.investment_securities_evaluation_loss +
    item?.investment_securities_sale_loss +
    item?.other_service_activity_foreign_expenses
  const meter_6_total = meter_4_total - meter_5_total || 0
  const meter_7_total = meter_3_total + meter_6_total
  const meter_8_total =
    item?.facility_maintenance_subsidy_revenue +
    item?.facility_maintenance_donation_revenue +
    item?.long_term_operating_fund_borrowing +
    item?.fixed_assets_received +
    item?.property_gain +
    item?.out_of_business_payment_income_revenue +
    item?.introductory_business_income +
    item?.out_of_business_fixed_assets_transfer_revenue +
    item?.fixed_asset_transfer_income_inter_establishments +
    item?.other_special_revenue

  const meter_9_total =
    item?.basic_gold_paid_amount +
    item?.asset_evaluation_loss +
    item?.fixed_asset_sale_disposal_loss +
    item?.special_reserve_depreciation_removal +
    item?.special_reserve_fund +
    item?.disaster_loss +
    item?.out_of_business_payment_payment_cost +
    item?.presentation_expenses_between_office +
    item?.out_of_business_fixed_assets_transfer_cost +
    item?.fixed_asset_transfer_expenses_between_establishment +
    item?.other_extraordinary_losses

  const meter_10_total = meter_8_total - meter_9_total
  const meter_11_total = meter_7_total + meter_10_total

  const meter_13_total = meter_11_total + item?.pre_year_carrying_activity

  const meter_17_total =
    meter_13_total +
    item?.basic_money_collapse +
    item?.other_reserve_depreciation -
    item?.other_reserve_reserve

  return {
    meter_1_total,
    meter_2_total,
    meter_3_total,
    meter_4_total,
    meter_5_total,
    meter_6_total,
    meter_7_total,
    meter_8_total,
    meter_9_total,
    meter_10_total,
    meter_11_total,
    meter_13_total,
    meter_17_total,
  }
}

export const FACILITY_ROW_INITIAL_VALUES = {
  employement_support_business_income: 0,
  disability_welfare_service: 0,
  expenses_donation_revenue: 0,
  other_income: 0,
  labor_cost: 0,
  expenses: 0,
  administrative_expenses: 0,
  employment_support_project_cost: 0,
  user_burden_light_reduction: 0,
  depreciation_expenses: 0,
  special_reserve_depreciation: 0,
  unable_to_collect: 0,
  incapacitated_gold_reinforcement: 0,
  other_expenses: 0,
  borrowed_money_interest_subsidy: 0,
  received_interest_dividend_revenue: 0,
  securities_valuation_profit: 0,
  gain_securities_sale: 0,
  investment_securities_evaluation_profit: 0,
  investment_securities_gain_gains: 0,
  other_service_activity_non_income_revenue: 0,
  interest_expense: 0,
  securities_valuation_loss: 0,
  sale_of_securities: 0,
  investment_securities_evaluation_loss: 0,
  investment_securities_sale_loss: 0,
  other_service_activity_foreign_expenses: 0,
  facility_maintenance_subsidy_revenue: 0,
  facility_maintenance_donation_revenue: 0,
  long_term_operating_fund_borrowing: 0,
  fixed_assets_received: 0,
  property_gain: 0,
  out_of_business_payment_income_revenue: 0,
  introductory_business_income: 0,
  out_of_business_fixed_assets_transfer_revenue: 0,
  fixed_asset_transfer_income_inter_establishments: 0,
  other_special_revenue: 0,
  basic_gold_paid_amount: 0,
  asset_evaluation_loss: 0,
  fixed_asset_sale_disposal_loss: 0,
  special_reserve_depreciation_removal: 0,
  special_reserve_fund: 0,
  disaster_loss: 0,
  out_of_business_payment_payment_cost: 0,
  presentation_expenses_between_office: 0,
  out_of_business_fixed_assets_transfer_cost: 0,
  fixed_asset_transfer_expenses_between_establishment: 0,
  other_extraordinary_losses: 0,
  special_cost_meter: 0,
  welfare_service_income: 0,
  income_from_employment_support_business: 0,
  donation_income: 0,
  business_expense: 0,
  personnel_expense: 0,
  office_expense: 0,
  employment_support_business_expenses: 0,
  user_cost_redaction: 0,
  depreciation_amortization: 0,
  reversal_special_reserve: 0,
  uncollectible_expense: 0,
  uncollectible_allowance: 0,
  pre_year_carrying_activity: 0,
  basic_money_collapse: 0,
  other_reserve_depreciation: 0,
  other_reserve_reserve: 0,
  is_internal_transaction: false,
}
export const sumOfAllMultipleFacilityCols = (
  columns: WageRevenueTabResponse[],
) => {
  const items = columns?.reduce(
    (acc, item) => ({
      employement_support_business_income:
        acc.employement_support_business_income +
        item.employement_support_business_income,
      disability_welfare_service:
        acc.disability_welfare_service + item.disability_welfare_service,
      expenses_donation_revenue:
        acc.expenses_donation_revenue + item.expenses_donation_revenue,
      other_income: acc.other_income + item.other_income,
      labor_cost: acc.labor_cost + item.labor_cost,
      expenses: acc.expenses + item.expenses,
      administrative_expenses:
        acc.administrative_expenses + item.administrative_expenses,
      employment_support_project_cost:
        acc.employment_support_project_cost +
        item.employment_support_project_cost,
      user_burden_light_reduction:
        acc.user_burden_light_reduction + item.user_burden_light_reduction,
      depreciation_expenses:
        acc.depreciation_expenses + item.depreciation_expenses,
      special_reserve_depreciation:
        acc.special_reserve_depreciation + item.special_reserve_depreciation,
      unable_to_collect: acc.unable_to_collect + item.unable_to_collect,
      incapacitated_gold_reinforcement:
        acc.incapacitated_gold_reinforcement +
        item.incapacitated_gold_reinforcement,
      other_expenses: acc.other_expenses + item.other_expenses,
      borrowed_money_interest_subsidy:
        acc.borrowed_money_interest_subsidy +
        item.borrowed_money_interest_subsidy,
      received_interest_dividend_revenue:
        acc.received_interest_dividend_revenue +
        item.received_interest_dividend_revenue,
      securities_valuation_profit:
        acc.securities_valuation_profit + item.securities_valuation_profit,
      gain_securities_sale:
        acc.gain_securities_sale + item.gain_securities_sale,
      investment_securities_evaluation_profit:
        acc.investment_securities_evaluation_profit +
        item.investment_securities_evaluation_profit,
      investment_securities_gain_gains:
        acc.investment_securities_gain_gains +
        item.investment_securities_gain_gains,
      other_service_activity_non_income_revenue:
        acc.other_service_activity_non_income_revenue +
        item.other_service_activity_non_income_revenue,
      interest_expense: acc.interest_expense + item.interest_expense,
      securities_valuation_loss:
        acc.securities_valuation_loss + item.securities_valuation_loss,
      sale_of_securities: acc.sale_of_securities + item.sale_of_securities,
      investment_securities_evaluation_loss:
        acc.investment_securities_evaluation_loss +
        item.investment_securities_evaluation_loss,
      investment_securities_sale_loss:
        acc.investment_securities_sale_loss +
        item.investment_securities_sale_loss,
      other_service_activity_foreign_expenses:
        acc.other_service_activity_foreign_expenses +
        item.other_service_activity_foreign_expenses,
      facility_maintenance_subsidy_revenue:
        acc.facility_maintenance_subsidy_revenue +
        item.facility_maintenance_subsidy_revenue,
      facility_maintenance_donation_revenue:
        acc.facility_maintenance_donation_revenue +
        item.facility_maintenance_donation_revenue,
      long_term_operating_fund_borrowing:
        acc.long_term_operating_fund_borrowing +
        item.long_term_operating_fund_borrowing,
      fixed_assets_received:
        acc.fixed_assets_received + item.fixed_assets_received,
      property_gain: acc.property_gain + item.property_gain,
      out_of_business_payment_income_revenue:
        acc.out_of_business_payment_income_revenue +
        item.out_of_business_payment_income_revenue,
      introductory_business_income:
        acc.introductory_business_income + item.introductory_business_income,
      out_of_business_fixed_assets_transfer_revenue:
        acc.out_of_business_fixed_assets_transfer_revenue +
        item.out_of_business_fixed_assets_transfer_revenue,
      fixed_asset_transfer_income_inter_establishments:
        acc.fixed_asset_transfer_income_inter_establishments +
        item.fixed_asset_transfer_income_inter_establishments,
      other_special_revenue:
        acc.other_special_revenue + item.other_special_revenue,
      basic_gold_paid_amount:
        acc.basic_gold_paid_amount + item.basic_gold_paid_amount,
      asset_evaluation_loss:
        acc.asset_evaluation_loss + item.asset_evaluation_loss,
      fixed_asset_sale_disposal_loss:
        acc.fixed_asset_sale_disposal_loss +
        item.fixed_asset_sale_disposal_loss,
      special_reserve_depreciation_removal:
        acc.special_reserve_depreciation_removal +
        item.special_reserve_depreciation_removal,
      special_reserve_fund:
        acc.special_reserve_fund + item.special_reserve_fund,
      disaster_loss: acc.disaster_loss + item.disaster_loss,
      out_of_business_payment_payment_cost:
        acc.out_of_business_payment_payment_cost +
        item.out_of_business_payment_payment_cost,
      presentation_expenses_between_office:
        acc.presentation_expenses_between_office +
        item.presentation_expenses_between_office,
      out_of_business_fixed_assets_transfer_cost:
        acc.out_of_business_fixed_assets_transfer_cost +
        item.out_of_business_fixed_assets_transfer_cost,
      fixed_asset_transfer_expenses_between_establishment:
        acc.fixed_asset_transfer_expenses_between_establishment +
        item.fixed_asset_transfer_expenses_between_establishment,
      other_extraordinary_losses:
        acc.other_extraordinary_losses + item.other_extraordinary_losses,
      special_cost_meter: acc.special_cost_meter + item.special_cost_meter,
      welfare_service_income:
        acc.welfare_service_income + item.welfare_service_income,
      income_from_employment_support_business:
        acc.income_from_employment_support_business +
        item.income_from_employment_support_business,
      donation_income: acc.donation_income + item.donation_income,
      business_expense: acc.business_expense + item.business_expense,
      personnel_expense: acc.personnel_expense + item.personnel_expense,
      office_expense: acc.office_expense + item.office_expense,
      employment_support_business_expenses:
        acc.employment_support_business_expenses +
        item.employment_support_business_expenses,
      user_cost_redaction: acc.user_cost_redaction + item.user_cost_redaction,
      depreciation_amortization:
        acc.depreciation_amortization + item.depreciation_amortization,
      reversal_special_reserve:
        acc.reversal_special_reserve + item.reversal_special_reserve,
      uncollectible_expense:
        acc.uncollectible_expense + item.uncollectible_expense,
      uncollectible_allowance:
        acc.uncollectible_allowance + item.uncollectible_allowance,
      pre_year_carrying_activity:
        acc.pre_year_carrying_activity + item.pre_year_carrying_activity,
      basic_money_collapse:
        acc.basic_money_collapse + item.basic_money_collapse,
      other_reserve_depreciation:
        acc.other_reserve_depreciation + item.other_reserve_depreciation,
      other_reserve_reserve:
        acc.other_reserve_reserve + item.other_reserve_reserve,
    }),
    { ...FACILITY_ROW_INITIAL_VALUES },
  )
  return items
}
