import { theme } from "@project/common"
import styled from "styled-components"

export const ShiftStyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  .break__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .break-settings__content {
    .top__content {
      display: flex;
      align-items: center;
      column-gap: 1em;
      margin-bottom: 1.1em;

      .ant-checkbox-wrapper {
        min-width: fit-content !important;
      }
      @media screen and (max-width: ${theme.breakpoints.xs}) {
        flex-wrap: wrap;
        row-gap: 1em;
      }
    }
    p {
      white-space: normal !important;
    }
  }
  .work_time__content {
    display: flex;
    align-items: center;
    row-gap: 0.5em;
    column-gap: 0.5em;
    flex-wrap: wrap;
    .work_time__hours {
      display: flex;
      align-items: center;
      row-gap: 0.5em;
      column-gap: 0.5em;
      &--from,
      &--to {
        display: flex;
        align-items: center;
        row-gap: 0.5em;
        column-gap: 0.3em;
      }
      @media screen and (max-width: ${theme.breakpoints.xxs}) {
        flex-wrap: wrap;
      }
    }
    .work_time__break {
      display: flex;
      align-items: center;
      row-gap: 0.5em;
      column-gap: 1em;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        flex-wrap: wrap;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
    }
  }
  .delete_action_container {
    display: flex;
    justify-content: space-between;

    .joint-facility-button-wrapper {
      .delete-btn {
        background: #e00;
        color: white;
        border: none;
      }
    }
  }
`
