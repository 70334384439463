import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as Yup from "yup"
import dayjs from "dayjs"
import {
  BtnContainer,
  ModalBodyContainer,
  PageTitle,
  QualificationWrapper,
} from "./Qualification.style"
import { Typography } from "antd"
import {
  Button,
  ConfirmationModal,
  DatePicker,
  InputField,
  Modal,
  SelectInput,
  anchorSelInputAreaAsPopupContainer,
  getPastYearList,
  theme,
} from "@project/common"
import { StaffOtherInfoContent } from "./StaffOtherInfoContent"
import { useFetchQualifications } from "../../../hooks/useFetchData"
import _ from "lodash"

interface FormValues {
  qualification_id: string
  eligibility: string
  acquired_date: null | Date
  expire_date: null | Date
  year: null | number
}
interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY年MM月DD日"

const initialValues = {
  qualification_id: "",
  eligibility: "",
  acquired_date: null,
  expire_date: null,
  year: null,
}
const Qualification: React.FC<IProps> = ({ data, onSave, onDelete }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      year: Yup.number().typeError(t("Required")).nullable(),
      qualification_id: Yup.number()
        .typeError(t("Required"))
        .required(t("Required")),
    }),
    onSubmit: (values: FormValues) => {
      if (operation === "create") {
        onSave(
          {
            ...values,
            tempId: new Date().getTime(),
            is_functional_training: false,
            acquired_date: dayjs(values?.acquired_date),
            expire_date: dayjs(values?.expire_date),
            year: values?.year || null,
          },
          operation,
        )
      } else {
        onSave(
          {
            tempId: selectedId,
            ...values,
            acquired_date: dayjs(values?.acquired_date),
            expire_date: dayjs(values?.expire_date),
            year: values?.year,
          },
          operation,
        )
      }
      setOperation("create")
      handleReset()
    },
  })
  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })
    setIsModalOpen(false)
  }
  const { qualifications } = useFetchQualifications()

  const filterQualification = (selectedQualificationId: number) => {
    if (operation === "create") {
      return qualifications?.data?.filter(
        (val) => data?.every((d) => d?.qualification_id !== val?.id),
      )
    }
    return qualifications?.data?.filter(
      (val) =>
        data?.every(
          (d) =>
            d?.qualification_id === selectedQualificationId ||
            d?.qualification_id !== val?.id,
        ),
    )
  }
  const isValid = () =>
    Object.keys(formik?.values)?.some((key) => formik?.values[key])

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  qualification_id: row?.qualification_id,
                  eligibility: row?.eligibility,
                  acquired_date: dayjs(row?.acquired_date),
                  expire_date: dayjs(row?.expire_date),
                  year: row?.year,
                },
              })

              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.colors.action, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Year")}</span>,
      key: "year",
      dataIndex: "year",
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Qualification name")}</span>
      ),
      key: "qualification_id",
      dataIndex: "qualification_id",
      render: (id) => (
        <span>
          {qualifications?.data?.find((val) => val.value === id)?.label}
        </span>
      ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Eligibility requirements / Others")}
        </span>
      ),
      key: "eligibility",
      dataIndex: "eligibility",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Acquired date")}</span>,
      key: "acquired_date",
      dataIndex: "acquired_date",
      render: (row) => (
        <span>{dayjs(row)?.isValid() ? dayjs(row).format(FORMAT) : ""}</span>
      ),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Expire date")}</span>,
      key: "expire_date",
      dataIndex: "expire_date",
      render: (row) => (
        <span>{dayjs(row)?.isValid() ? dayjs(row).format(FORMAT) : ""}</span>
      ),
    },
  ]

  return (
    <>
      <QualificationWrapper>
        <StaffOtherInfoContent
          title={
            <PageTitle>
              <Typography.Paragraph>
                {t("Possession qualification list")}
              </Typography.Paragraph>
              <Typography.Paragraph id={"msg"}>
                {t(
                  " ※If you have multiple qualifications, please repeat the registration procedure.",
                )}
              </Typography.Paragraph>
            </PageTitle>
          }
          columns={columns}
          dataSource={data ? _.orderBy(data, "year", "desc") : []}
          btnText={t("Register your qualification")}
          emptyText={t("No qualification is registered")}
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
      </QualificationWrapper>
      {isModalOpen && (
        <Modal
          title={t("Register your qualification")}
          closeIcon={<span>{t("Close")}</span>}
          open={isModalOpen}
          onCancel={() => {
            handleReset()
            setOperation("create")
            setIsModalOpen(false)
          }}
          width={1120}
          maskClosable={false}
          destroyOnClose
          centered
          footer={null}
          wrapClassName={"instructor__occu_info-modal"}
        >
          <form onSubmit={formik.handleSubmit}>
            <ModalBodyContainer>
              <div className={"body_content"}>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Year")}</div>
                  <div className={"form_input"}>
                    <SelectInput
                      options={getPastYearList(true)}
                      height={"38px"}
                      id={"year"}
                      name={"year"}
                      value={formik?.values?.year}
                      onChange={(val) => formik?.setFieldValue("year", val)}
                      getPopupContainer={anchorSelInputAreaAsPopupContainer}
                    />
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Qualification name")}</div>
                  <div className={"form_input"}>
                    <SelectInput
                      options={filterQualification(
                        Number(formik?.values?.qualification_id || 0),
                      )}
                      height={"38px"}
                      id={"qualification_id"}
                      name={"qualification_id"}
                      value={formik?.values?.qualification_id}
                      onChange={(val) =>
                        formik?.setFieldValue("qualification_id", val)
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={anchorSelInputAreaAsPopupContainer}
                    />
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>
                    {t("Eligibility requirements / Others")}
                  </div>
                  <div className={"form_input"}>
                    <InputField
                      bg={theme.colors.white}
                      padding={"8px 12px"}
                      borderRadius={"0px"}
                      name={"eligibility"}
                      value={formik?.values?.eligibility}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Acquired date")}</div>
                  <div className={"form_input"}>
                    <DatePicker
                      name={"acquired_date"}
                      format={FORMAT}
                      inputReadOnly
                      date={
                        dayjs(formik?.values?.acquired_date)?.isValid()
                          ? dayjs(formik?.values?.acquired_date)
                          : undefined
                      }
                      size={"small"}
                      onDateChange={(e) =>
                        formik.setFieldValue("acquired_date", dayjs(e))
                      }
                      placement={"bottomLeft"}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Expire date")}</div>
                  <div className={"form_input"}>
                    <DatePicker
                      name={"expire_date"}
                      format={FORMAT}
                      inputReadOnly
                      disabledDate={(current) =>
                        current.isBefore(dayjs(formik.values?.acquired_date))
                      }
                      date={
                        dayjs(formik?.values?.expire_date)?.isValid()
                          ? dayjs(formik?.values?.expire_date)
                          : undefined
                      }
                      onDateChange={(e) => {
                        formik.setFieldValue("expire_date", dayjs(e))
                      }}
                      placement={"bottomLeft"}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                  </div>
                </div>
              </div>
            </ModalBodyContainer>
            <BtnContainer>
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                }}
              >
                <Button
                  btnText={t("Cancel")}
                  borderColor={theme.colors.action}
                  shape={"round"}
                  onClick={() => handleReset()}
                />
                <Button
                  btnText={t("Save")}
                  shape={"round"}
                  disabled={!isValid()}
                  type={"primary"}
                  htmlType={"submit"}
                />
              </div>
              {operation === "edit" && (
                <Button
                  btnText={t("Delete")}
                  borderColor={theme.colors.error}
                  btnBg={theme.colors.error}
                  textColor={theme.colors.white}
                  shape={"round"}
                  onClick={() => setOpenDeleteModal(!openDeleteModal)}
                />
              )}
            </BtnContainer>
          </form>
        </Modal>
      )}
      {openDeleteModal && (
        <ConfirmationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
export { Qualification }
