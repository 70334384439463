import { theme } from "@project/common"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  button {
    width: fit-content;
  }
  .contract_facility {
    margin-top: 14px;
    color: ${theme.colors.error};
  }
  .print-configuration {
    align-items: flex-start !important;
    margin-top: 10px;
    svg {
      margin-top: 7px;
    }
  }
  .facility-output {
    align-items: flex-start !important;
    svg {
      margin-top: 7px;
    }
  }
`

export const DataListWrapper = styled.div`
  padding: 24px 30px;
  border: 1px solid #d26d10;
  color: ${theme.colors.info};
  border-radius: 12px;
  margin-bottom: 16px;

  .content {
    .img-title {
      display: flex;
      align-items: center;
      gap: 8px;
      .title {
        font-size: 18px;
        font-weight: 700;
        color: #d26d10;
      }
    }
    .sub-title {
      font-size: 16px;
      font-weight: 400;
      color: #d26d10;
    }
  }
`
export const MiniContainer = styled.div`
  display: flex;
  @media screen and (max-width: 630px) {
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
    }

    .faculty-datepicker > div {
      width: 100%;
    }
  }
  > div:first-child {
    min-width: 170px;
  }
  .dotted-line {
    border-top: 1px dotted #d2d1d1;
    width: 100%;
  }
`

export const Wrapper = styled.div`
  .btn-wrapper {
    display: flex;
    gap: 10px;
    margin: 20px 0px;
  }
  .select-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
export const Content = styled.div<any>`
  @media print {
    @page {
      margin: 24px;
    }
  }
  .ant-card-body {
    padding: 10px !important;
  }
  .content {
    border: 1px solid #d2d1d1;
    margin: 5px 5px 20px 5px;

    @media print {
      border: none;
      ${({ id }) =>
        id == 2
          ? `page-break-after: auto;
           margin-top:20px;
          margin-bottom:150px;
          `
          : id == 3
            ? `page-break-after: avoid;
            margin-bottom:50px;`
            : `page-break-after: always;`}
    }

    .first-content {
      padding: 20px;
      .first-div {
        width: 100%;
        .receipt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .zip-address {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .copy {
            border: 1px solid #000000;
            padding: 4px;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            width: 50%;
          }
        }

        .title {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .amount {
        display: flex;
        justify-content: center;
        width: 50%;
        align-items: center;
        column-gap: 20px;
        row-gap: 10px;
        flex-wrap: wrap;
        margin-top: 10px;

        .title {
          font-size: 14px;
          font-weight: 400;
        }
        .cost {
          font-size: 20px;
          font-weight: 700;
          color: #191919;
        }
      }
      .user-name,
      .address {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .divider {
      border: 1px solid #000000;
      margin: 0px 20px 20px 20px;
    }
    .second-content {
      .first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
      }
      .second {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px;
        flex-wrap: wrap;
        gap: 10px;
        .table {
          table {
            border-collapse: collapse;
            width: 180px;
          }

          td,
          th {
            border: 1px solid #000000;
            text-align: center;
            padding: 8px;
          }
          tr,
          td {
            padding: 40px;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .address {
            display: flex;
            gap: 30px;
            .title {
              min-width: 100px;
            }
          }
        }
      }
    }
  }

  .main {
    width: 100%;
    .parent-username {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .parent-child-name {
    font-size: 16px;
    font-weight: 700;
  }
`
