import React from "react"
import { Tabs } from "@project/common"
import { Wrapper } from "./BusinessDayManagementFrom.style"
import { useTranslation } from "react-i18next"
import { MonthlySettings } from "./MonthlySettings"
import { YearlySettings } from "./YearlySettings"
import { useBusinessDayManagement } from "../../../context"
import { B_ACTIVE } from "../../../types"
export const BusinessDayManagementFrom = (): JSX.Element => {
  const { t } = useTranslation()
  const { activeTab, setActiveTab } = useBusinessDayManagement()
  return (
    <Wrapper>
      <Tabs
        activeKey={activeTab}
        padding={"0"}
        onChange={(activeKey: B_ACTIVE) => setActiveTab(activeKey)}
        items={[
          {
            key: "bulk_setting_for_year",
            label: t("Bulk setting for year"),
            children: <YearlySettings />,
          },
          {
            key: "bulk_setting_for_month",
            label: t("Bulk setting for month"),
            children: <MonthlySettings />,
          },
        ]}
      />
    </Wrapper>
  )
}
