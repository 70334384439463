import { theme } from "@project/common"
import styled from "styled-components"

export const Wrapper = styled.div`
  table {
    tr {
      th {
        max-width: 100px;
      }
    }
  }
`
export const TopContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  row-gap: 0.5rem;
  p {
    ${theme.typography["body-regular"]};
  }
  a {
    color: ${theme.colors.action};
    text-decoration: underline;
  }
  & div {
    display: flex;
    gap: 0.5rem;
    height: fit-content;
  }
  @media (max-width: ${theme.breakpoints?.sm}) {
    margin-bottom: 8px;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 0.8rem;
  }
`
export const ColContent = styled.div<{ isSet?: boolean; disabled?: boolean }>`
  background: ${(props) =>
    props.isSet ? theme.colors["action-400"] : "transparent"};
  color: ${theme.colors.text};
  white-space: nowrap !important;
  border: ${(props) =>
    props.isSet
      ? `1px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.action}`};
  width: fit-content;
  border-radius: 5px;
  padding: 5px 8px;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  a.register__link {
    text-decoration: none !important;
    color: ${theme.colors.text};
    text-align: center;
    white-space: nowrap !important;
  }
`
