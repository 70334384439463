import { theme } from "@project/common"
import { Flex } from "antd"
import { useTranslation } from "react-i18next"
import { AlertBox } from "../../components"

export const AssessmentSettingsAlert = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AlertBox
      heading={t("About the settings on this screen")}
      isCollapseAble={false}
      width={"100%"}
    >
      <Flex
        vertical
        style={{
          marginTop: "-8px",
          color: theme.colors.error,
          fontWeight: 500,
        }}
      >
        <div>
          {t(
            'The settings set here will be reflected on all assessment sheets, including those that have already been created.When changing a category name or item name, if you do not want it to be reflected in the created sheet, do not "Edit" the relevant part, create a new category / item with "Add", and then delete the unnecessary category / item.Please make the item "hidden".Even if you set it to "Hide", the areas where content has been entered in the created sheet will remain.',
          )}
        </div>
      </Flex>
    </AlertBox>
  )
}
