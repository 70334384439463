import React from "react"
import { DailyListModalProps } from "../../../../types"
import { Modal, theme } from "@project/common"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { FULL_DATE_FORMATE } from "../../../../constants"
import { DayListEditForm } from "./DayListEditForm"
import { DayListAbsenceForm } from "./DayListAbsenceForm"

interface DayListModalProps extends DailyListModalProps {
  onCancel: () => void
  onReset: () => void
  onSuccess?: () => void
  handleSave?: () => void
  userAttendanceData?: any
  staffList?: Array<any>
}
export const DayListModal = ({
  isOpen,
  onCancel,
  onSuccess,
  handleSave,
  staffList,
  userAttendanceData = {},
  ...rest
}: DayListModalProps): JSX.Element => {
  const { t } = useTranslation()
  const renderRespectiveForm = (
    operation: "ADD" | "EDIT" | "ABSENCE",
    userAttendanceData?: any,
  ) => {
    switch (operation) {
      case "ADD":
        return <h1>{"add form"}</h1>
      case "EDIT":
        return (
          <DayListEditForm
            userAttendanceData={userAttendanceData || {}}
            onCancel={onCancel}
            onSuccess={onSuccess}
            staffList={staffList}
          />
        )
      case "ABSENCE":
        return (
          <DayListAbsenceForm
            userAttendanceData={userAttendanceData || {}}
            handleSave={handleSave}
            staffList={staffList}
          />
        )
      default:
        return null
    }
  }
  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      title={
        rest?.operation === "ABSENCE"
          ? t("Confirm whether to calculate the addition for absence")
          : t("{{date}} attendance record", {
              date: dayjs(userAttendanceData?.date).format(FULL_DATE_FORMATE),
            })
      }
      width={900}
      footer={null}
      bodyPadding={"0px"}
      headerBg={rest?.operation === "ABSENCE" ? theme.colors.white : ""}
      titleColor={rest?.operation === "ABSENCE" ? theme.colors.text : ""}
      headerBorderBottom={
        rest?.operation === "ABSENCE" ? `2px solid ${theme.colors.border} ` : ""
      }
      borderRadius={rest?.operation === "ABSENCE" ? "8px" : "0px"}
    >
      {renderRespectiveForm(rest?.operation, userAttendanceData)}
    </Modal>
  )
}
