import { API, removeBlankAttributes } from "@project/common"
import {
  MONTHLY_SCHEDULE_PAYLOAD,
  SCHEDULE_INITIAL_OPERATION_PARAM,
} from "../types/AttendanceSchedule.types"
import dayjs from "dayjs"
export const createOrUpdateAttendanceStaffShiftSchedules = async (
  values: MONTHLY_SCHEDULE_PAYLOAD[],
) => {
  return API.post("/staff-schedules", values)
}
export const getMonthlyAttendanceStaffShiftSchedule = async (
  queries: SCHEDULE_INITIAL_OPERATION_PARAM,
) => {
  const p = {
    facility: queries?.facility_id,
    staff_id: queries?.staff_id,
    year: dayjs(queries?.date).year(),
    month: dayjs(queries?.date).month() + 1,
  }
  const params = removeBlankAttributes(p)
  return API.get("/staff-schedules/monthly", {
    params,
  })
}
