import { theme } from "@project/common"
import styled from "styled-components"
export const PlanFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const FormInputContainer = styled.div`
  .user__name_selector,
  .create_count {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
    span {
      white-space: nowrap;
      font-size: 14px;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  }
`

export const StartTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints.xxs}) {
    flex-direction: column;
  }
  .start_time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`
export const DetailsWrapper = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  background: #eef4f8;
  margin-bottom: 15px;
  #content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin: 10px 0px;
  }
  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 16px;
    font-weight: 400;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`
export const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .ant-card {
    box-shadow: 4px 4px 16px 0px rgba(51, 51, 51, 0.12);
  }

  @media print {
    .card-header {
      margin: 0px 25px;
      .ant-card-head {
        border-bottom: 0px !important;
        .ant-card-head-wrapper {
          border-bottom: 0px !important;
        }
      }
      .print-card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
      }
      .print-card > div {
        width: 100%;
      }
    }
    .business-diary {
      display: ${(props) => (props.businessDiary ? "block" : "none")};
    }
  }
`

export const MainWrapper = styled.div`
  margin-bottom: 80px;
  .content-title {
    color: var(--Sub-Text, #565555);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`
export const CardWrapper = styled.div`
  .print-card {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      justify-content: center !important;
      gap: 16px;
    }
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
  .first-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      justify-content: flex-start;
      gap: 15px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    gap: 15px;
  }
`
export const SecondContentWrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    .table-data {
      background-color: rgb(243, 243, 243);
    }
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .second-content-label {
    padding: 12px;
    background-color: rgb(243, 243, 243);
    height: 50px;
    color: #191919;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }
  .first-td {
    width: 160px;
    .first-td-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: var(--191919, #191919);
      text-align: center;
      font-style: normal;
      line-height: normal;
      h1 {
        color: #191919;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
      }
      p {
        color: #191919;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        h1,
        p {
          font-size: 11px !important;
          font-weight: 400;
        }
      }
    }
  }
  .second-td {
    width: 902px;
  }
  .third-td {
    width: 200px;
    .third-td-content {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
    }
  }
  .second-content-tr {
    border-radius: 10px !important;
    border: 1px solid #dddddd;
  }
`
