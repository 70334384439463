import React, { useEffect, useState } from "react"

// packages
import { InputProps } from "antd"

// common
import { InputField } from "@project/common"

export const FastInput = ({
  val,
  error,
  onValChange,
  delay = 500,
  size = "large",
  ...props
}: {
  val: string
  delay?: number
  error?: string
  onValChange: (val: string) => void
} & Omit<InputProps, "onChange" | "value" | "height" | "width">) => {
  const [value, setValue] = useState(val)

  const handleChange = (event) => {
    const { value } = event.target
    setValue(value)
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onValChange(value)
    }, delay)

    return () => clearTimeout(delayDebounce)
  }, [value])

  return (
    <InputField
      size={size}
      error={error}
      value={value}
      width={"100%"}
      onChange={handleChange}
      {...props}
    />
  )
}
