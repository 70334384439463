import { theme } from "@project/common"
import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const AmountPerUseFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .search__container {
    display: flex;
    row-gap: 8px;
    column-gap: 16px;
    flex-wrap: wrap;
    .search__content {
      display: flex;
      row-gap: 8px;
      column-gap: 16px;
      flex-wrap: wrap;
      .username {
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          width: 100% !important;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  }
`
