import React from "react"
import { useTranslation } from "react-i18next"

const EmailNotificationTable = ({ response }) => {
  const { t } = useTranslation()
  const emailSettings = []
  if (response?.attend_flag) {
    emailSettings.push(t("Attend"))
  }
  if (response?.leave_flag) {
    emailSettings.push(t("leave"))
  }
  if (emailSettings.length == 0) {
    emailSettings.push("-")
  }
  return (
    <>
      <table>
        <tr className={"third-table"}>
          <td className={"label"}>{t("Email Address")}</td>
          <td className={"value"}>{response?.email_address || "-"}</td>
        </tr>
        <tr className={"third-table"}>
          <td className={"label"}>{t("Email address(If multiple)")}</td>
          <td className={"value"}>{response?.email_address_2 || "-"}</td>
        </tr>
        <tr className={"third-table"}>
          <td className={"label"}>{t("Email notification settings")}</td>
          <td className={"value"}>{emailSettings.join(", ")}</td>
        </tr>
      </table>
    </>
  )
}

export default EmailNotificationTable
