import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../../context"
import dayjs, { Dayjs } from "dayjs"
import { YM_FORMAT } from "../../../constants"

const WagesRevenueOperationOptions = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    facility_id:
      searchParams?.tab === "overall_revenue"
        ? searchParams?.facility_id || ""
        : null,
    date: searchParams?.date ? dayjs(searchParams?.date) : "",
    tab: searchParams?.tab,
  })
  useEffect(() => {
    setValues({
      facility_id:
        searchParams?.tab === "overall_revenue"
          ? searchParams?.facility_id || ""
          : null,
      date: searchParams?.date ? dayjs(searchParams?.date) : "",
      tab: searchParams?.tab,
    })
  }, [searchParams])
  const onDisplayChange = () => {
    setSearchParams(values)
    updateParams(
      {
        panel: values?.tab,
        facility_id: values?.facility_id,
        date:
          values?.tab === "overall_revenue"
            ? dayjs(values?.date)?.format("YYYY")
            : dayjs(values?.date)?.format("YYYY-MM"),
      },
      "/wages-revenue",
    )
  }

  return (
    <AccordionCard
      items={[
        {
          key: "wages_revenue",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              {values?.tab === "overall_revenue" && (
                <>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Facility")}
                      width={"136px"}
                    />
                    <SelectInput
                      options={facilities || []}
                      width={"208px"}
                      name={"facility"}
                      placeholder={"--"}
                      value={values?.facility_id || null}
                      onChange={(val) =>
                        setValues((prev) => ({
                          ...prev,
                          facility_id: val,
                        }))
                      }
                    />
                  </div>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow label={t("Year")} width={"136px"} />
                    <DatePicker
                      name={"year"}
                      picker={"year"}
                      format={"YYYY"}
                      date={values?.date ? dayjs(values?.date) : null}
                      onDateChange={(date: Dayjs) =>
                        setValues((prev) => ({
                          ...prev,
                          date: date,
                        }))
                      }
                      style={{
                        width: "208px",
                      }}
                    />
                  </div>
                </>
              )}

              {values?.tab !== "overall_revenue" && (
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow label={t("Year")} width={"136px"} />
                  <DatePicker
                    name={"year"}
                    picker={"month"}
                    format={YM_FORMAT}
                    date={values?.date ? dayjs(values?.date) : null}
                    onDateChange={(date: Dayjs) =>
                      setValues((prev) => ({
                        ...prev,
                        date: date,
                      }))
                    }
                    style={{
                      width: "208px",
                    }}
                  />
                </div>
              )}

              <div className={"search__content"}>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  onClick={onDisplayChange}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}

export default WagesRevenueOperationOptions
