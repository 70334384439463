import { theme } from "@project/common"
import styled from "styled-components"

export const UserServiceProvisionResultOperationContainer = styled.div`
  margin-bottom: 20px;
`

export const UserServiceProvisionResultButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  .output-number {
    border: 1px solid ${theme.colors.border};
    padding-inline: 10px;
    height: 36px;
    display: flex;
    align-items: center;
    strong {
      font-size: large;
    }
  }
`

export const UserServiceProvisionResultTableContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  .check-uncheck-button-container {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    button {
      padding: 4px 16px;
      min-height: 24px !important;
      border-color: ${theme.colors.border};
      &.bg-white {
        background: #fff;
      }
      span {
        font-size: 12px !important;
      }
    }
  }

  .info-with-pagination {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .service-record-table {
    margin-bottom: 10px;
    width: 100%;
    overflow-x: auto;
  }
`

export const CircularSelection = styled.div`
  height: 16px;
  width: 16px;
  border: 1px solid #000;
  border-radius: 50%;
  margin: auto;
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px;
  justify-content: center;
`
