import { Calendar, Card } from "@project/common"
import { Skeleton, Space } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useBusinessDayManagement } from "../../../context"
import { ActionSection } from "./commonSection/ActionSection"
import { DataContent } from "./commonSection/DataContent"
import { SectionThree } from "./commonSection/SeactionThree"
import { SectionOne } from "./commonSection/SectionOne"
import { SectionTwo } from "./commonSection/SectionTwo"
import { Title } from "./commonSection/Title"
export const MonthlySettings = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility_name } = router?.query as any
  const {
    monthlyBusinessHours,
    monthlyServiceHours,
    handleDataAddition,
    handleCalendarInputChange,
    handleDataRemove,
    monthlyCalendarData,
    handleCalendarOtherInputChange,
    onPagination,
    operationOptionsValues,
    isDataLoading,
    isAdding,
    originalData,
    toggleHolidayAndMeal,
    facilityType,
  } = useBusinessDayManagement()
  const isSet = originalData?.monthly?.findIndex(
    (item) => item?.business_end_hour1 !== null,
  )

  return (
    <>
      {isDataLoading ? (
        <Space
          direction={"vertical"}
          style={{ width: "100%", padding: "16px" }}
          size={16}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Space>
      ) : (
        <Card
          boxShadow={"none"}
          borderStyle={"none"}
          title={
            <Title
              title={t(`{{facility_name}} {{date}}の営業日`, {
                facility_name: facility_name,
                date: dayjs(operationOptionsValues?.params?.date).format(
                  "YYYY年MM月",
                ),
              })}
              isSet={isSet >= 0 ? true : false}
            />
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "32px",
            }}
          >
            <SectionOne
              currentMonth={
                dayjs(operationOptionsValues?.params?.date)?.month() + 1
              }
              currentYear={dayjs(operationOptionsValues?.params?.date)?.year()}
              change={"month"}
              handleMonthChange={(year: any, month: any) => {
                onPagination(dayjs([year, month - 1]))
              }}
              handleYearChange={(year: any) => {
                onPagination(dayjs([year, 0]))
              }}
            >
              <Calendar
                value={operationOptionsValues?.params?.date}
                cellRender={(date: Dayjs) => {
                  const dataOf = dayjs(date).format("D")
                  const isHoliday =
                    monthlyBusinessHours?.[dataOf]?.[0]?.holiday === "1"
                      ? true
                      : false
                  const hasMeal = monthlyBusinessHours?.[dataOf]?.[0]?.meal
                  return {
                    isHoliday: false,
                    content: (
                      <DataContent
                        dataOf={dataOf}
                        hasMeal={hasMeal}
                        isHoliday={isHoliday}
                        businessHours={monthlyBusinessHours[dataOf]}
                        serviceHours={monthlyServiceHours[dataOf]}
                        handleDataAddition={handleDataAddition}
                        handleDataRemove={handleDataRemove}
                        handleCalendarInputChange={handleCalendarInputChange}
                        toggleHolidayAndMeal={(type) =>
                          toggleHolidayAndMeal({
                            key: dataOf,
                            value:
                              type === "H"
                                ? !isHoliday
                                : hasMeal === "0"
                                  ? true
                                  : false,
                            type: type === "H" ? "holiday" : "meal",
                          })
                        }
                        facilityType={facilityType}
                      />
                    ),
                  }
                }}
              />
            </SectionOne>
            <SectionTwo
              tab={"monthly"}
              date={dayjs()}
              handleCalendarOtherInputChange={handleCalendarOtherInputChange}
              defaultValue={{
                time_setting: monthlyCalendarData?.time_setting,
                day_hours: monthlyCalendarData?.day_hours,
                day_minutes: monthlyCalendarData?.day_minutes,
                week_hours: monthlyCalendarData?.week_hours,
                week_minutes: monthlyCalendarData?.week_minutes,
                modified_working: monthlyCalendarData?.modified_working,
                occupations: monthlyCalendarData?.occupations || [],
                override_facilities:
                  monthlyCalendarData?.override_facilities || [],
                modified_working_start_day:
                  monthlyCalendarData?.modified_working_start_day,
                modified_working_end_day:
                  monthlyCalendarData?.modified_working_end_day,
                modified_working_hours:
                  monthlyCalendarData?.modified_working_hours,
                modified_working_minutes:
                  monthlyCalendarData?.modified_working_minutes,
              }}
            />
            <SectionThree
              defaultData={monthlyCalendarData?.override_facilities || []}
              handleCalendarOtherInputChange={handleCalendarOtherInputChange}
              date={operationOptionsValues?.params?.date}
            />

            <ActionSection
              disabled={isDataLoading}
              loading={isAdding}
              data={originalData?.monthly || []}
              deleteBtnText={t("Delete Month Settings")}
            />
          </div>
        </Card>
      )}
    </>
  )
}
