import { Card } from "@project/common"
import { Spin } from "antd"
import dayjs from "dayjs"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DailyWorkRelated } from "./DailyWorkRelated/DailyWorkRelated"
import { Container } from "./FacilityComponent.style"
import { OperationManagement } from "./OperationManagement/OperationManagement"
import { RelatedInformation } from "./RelatedInformation/RelatedInformation"
import { getAllTopPage } from "../../../services/topPage"
interface IProps {
  facilityID?: number
  selectedFacility?: any
}
const FacilityComponent: React.FC<IProps> = ({
  facilityID,
  selectedFacility,
}) => {
  const { t } = useTranslation()
  const { data, isLoading, isFetching } = useQuery<any, Error>({
    queryKey: ["top_page", facilityID],
    queryFn: () => getAllTopPage(facilityID),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: ({ data }) => data,
  })
  const dayOfMonthFromFacility = Number(
    selectedFacility?.pay_slip_alert_date || 0,
  )

  const todayDate = Number(dayjs()?.date() || 0)
  const getTodayUserAttendanceLink = () => {
    return `/user-attendance-calendar/daily-list?facilityId=${facilityID}&date=${dayjs().format(
      "YYYY-MM-DD",
    )}`
  }

  const getTodayStaffAttendanceLink = () => {
    return `/attendance-record/day-list?facility_id=${facilityID}&date=${dayjs().format(
      "YYYY-MM-DD",
    )}`
  }

  const getRegisteredUsersLink = () => {
    return `/users?contract_year_month=${dayjs().format(
      "YYYY-MM",
    )}&contract_facility=${facilityID}`
  }

  const getUsersNeedingMonitoringLink = () => {
    return `/user-who-needs-monitoring?facility=${facilityID}`
  }

  const getUserWageLink = () => {
    return `/wage-management?facilityIds=${facilityID}`
  }

  const getUsersNeedingCertificateRenewalLink = () => {
    return `/list-of-users?facility=${facilityID}`
  }

  const getUserRequestLink = () => {
    return `/user-attendance-management?facility_id=${facilityID}&panel=request-list`
  }

  return (
    <Container>
      <div className={"revenue-management"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/revenew.svg"} alt={"icon"} />
              <span>{t("Revenue management")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div className={"content-wrapper"}>
              <div className={"content"}>
                <span className={"title"}>
                  {t("Sales as of {{dateFormat}}", {
                    dateFormat: dayjs().format("YYYY年MM月"),
                  })}
                </span>
                <div className={"amount"}>
                  <h2>{data?.revenue?.salesAmount || 0}</h2>
                  <span>{"円"}</span>
                </div>
              </div>
              <div className={"content"}>
                <span className={"title"}>{t("Usage rate")}</span>
                <div className={"amount"}>
                  <h2>{data?.revenue?.usageRate || 0}</h2>
                  <span>{"%"}</span>
                </div>
              </div>
              <div className={"content"}>
                <span className={"title"}>
                  {t("Sales forecast for {{dateFormat}}", {
                    dateFormat: dayjs().format("YYYY年MM月"),
                  })}
                </span>
                <div className={"amount"}>
                  <h2>{data?.revenue?.salesForecastAmount || 0}</h2>
                  <span>{"円"}</span>
                </div>
              </div>
            </div>
          </Spin>
        </Card>
      </div>
      <div className={"wage-sales-management"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/revenew.svg"} alt={"icon"} />
              <span>{t("Wage Revenue management")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div className={"content-wrapper"}>
              <div className={"content"}>
                <span className={"title"}>
                  {t("Sales as of {{dateFormat}}", {
                    dateFormat: dayjs().format("YYYY年MM月"),
                  })}
                </span>
                <div className={"amount"}>
                  <h2>{data?.wage?.amount || 0}</h2>
                  <span>{"円"}</span>
                </div>
              </div>
              <div className={"content"}>
                <span className={"title"}>
                  {t("Sales forecast for {{dateFormat}}", {
                    dateFormat: dayjs().format("YYYY年MM月"),
                  })}
                </span>
                <div className={"amount"}>
                  <h2>{data?.wage?.amount || 0}</h2>
                  <span>{"円"}</span>
                </div>
              </div>
            </div>
          </Spin>
        </Card>
      </div>
      <div className={"card-row attendance-users"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-check.svg"} alt={"icon"} />
              <span>{t("Attendance management")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <Link href={getUserRequestLink()}>
              <div className={"title-span link-title"}>
                <h2 className={"title inline-title"}>
                  {data?.attendanceManagementCount || 0}
                </h2>
                <span>{"名"}</span>
              </div>
            </Link>
          </Spin>
        </Card>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/multi-user.svg"} alt={"icon"} />
              <span>{t("Number of users today")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <Link href={getTodayUserAttendanceLink()}>
              <div className={"title-span link-title"}>
                <h2 className={"title inline-title"}>
                  {data?.usersTodayCount || 0}
                </h2>
                <span>{"名"}</span>
              </div>
            </Link>
          </Spin>
        </Card>
      </div>
      <div className={"card-row capacity-commuter"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-plus.svg"} alt={"icon"} />
              <span>{t("Capacity")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div className={"title-span"}>
              <div className={"inline-title"}>
                <h2 className={"title inline-title"}>{data?.capacity || 0}</h2>
                <span>{"名"}</span>
              </div>
            </div>
          </Spin>
        </Card>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-only.svg"} alt={"icon"} />
              <span>{t("Commuter")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <Link href={getTodayStaffAttendanceLink()}>
              <div className={"title-span link-title"}>
                <h2 className={"title inline-title"}>
                  {data?.commuterCount || 0}
                </h2>
                <span>{"名"}</span>
              </div>
            </Link>
          </Spin>
        </Card>
      </div>
      <div className={"card-row people-certificate"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-plus-border.svg"} alt={"icon"} />
              <span>{t("Number of registered people")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <Link href={getRegisteredUsersLink()}>
              <div className={"title-span link-title"}>
                <h2 className={"title inline-title"}>
                  {data?.registeredCount || 0}
                </h2>
                <span>{"名"}</span>
              </div>
            </Link>
          </Spin>
        </Card>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-alert.svg"} alt={"icon"} />
              <span>
                {t("Users who need to renew their beneficiary certificate")}
              </span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div
              className={"title-span"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link href={getUsersNeedingCertificateRenewalLink()}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  className={"link-title"}
                >
                  <h2 className={"title inline-title"}>
                    {data?.usersRenewBeneficiaryCount || 0}
                  </h2>
                  <span>{"名"}</span>
                </div>
              </Link>
              <div
                style={{ color: "#E00000", fontSize: "14px", fontWeight: 400 }}
              >
                {t("It's been less than a month. Please arrange an update")}
              </div>
            </div>
          </Spin>
        </Card>
      </div>
      <div className={"card-row payslip-support"}>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-alert.svg"} alt={"icon"} />
              <span>{t("Users whose pay slip need to be approved")}</span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div
              className={"title-span"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link href={getUserWageLink()}>
                <div
                  className={"link-title"}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <h2 className={"title inline-title"}>
                    {data?.userPaySlipUnApprovedCount || 0}
                  </h2>
                  <span>{"名"}</span>
                </div>
              </Link>
              {data?.userPaySlipUnApprovedCount > 0 && (
                <div
                  style={{
                    color: "#E00000",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {dayOfMonthFromFacility >= todayDate
                    ? t("Please approve pay slip by {{selectedValue}} day", {
                        selectedValue: dayOfMonthFromFacility,
                      })
                    : t("There are users whose pay slip is not confirmed！")}
                </div>
              )}
            </div>
          </Spin>
        </Card>
        <Card
          title={
            <div className={"title-icon"}>
              <img src={"./assets/icons/user-alert.svg"} alt={"icon"} />
              <span>
                {t("Users who need to update their individual support plan")}
              </span>
            </div>
          }
        >
          <Spin spinning={isFetching || isLoading}>
            <div
              className={"title-span"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link href={getUsersNeedingMonitoringLink()}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  className={"link-title"}
                >
                  <h2 className={"title inline-title"}>
                    {data?.usersUpdatePlanCount || 0}
                  </h2>
                  <span>{"名"}</span>
                </div>
              </Link>
              <div
                style={{ color: "#E00000", fontSize: "14px", fontWeight: 400 }}
              >
                {t("It's been less than a month. Please arrange an update")}
              </div>
            </div>
          </Spin>
        </Card>
      </div>

      {/*  Hiding this application status section for now
      <ApplicationStatus
        applicationStatusDate={data?.applicationStatusYTD}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
      /> */}
      <DailyWorkRelated facilityID={facilityID} />
      <OperationManagement facilityID={facilityID} />
      <RelatedInformation facilityID={facilityID} />
    </Container>
  )
}

export { FacilityComponent }
