import dayjs from "dayjs"
import { IRevenueManagementSearchParams } from "../../types/RevenueManagement.types"
import { RevenueManagementOperation } from "./RevenueManagementOperation"
import { useRouter } from "next/router"
import { useContext, useState } from "react"
import {
  Button,
  Card,
  MonthPagination,
  TWELVE_DIFFERENT_COLORS,
  Table,
  getWeekNameByDate,
  useUpdateSearchParams,
} from "@project/common"
import { t } from "i18next"
import {
  EmploymentTag,
  FacilityAchievementCell,
  FacilityRevenueTotalCell,
  RevenueManagemenetGraphCumulativeTable,
  RevenueManagementAttendanceTable,
  RevenueManagementContentainer,
  RevenueManagementGraphContainer,
  RevenueManagementGraphTopSection,
} from "./RevenueManagement.styles"
import { AuthContext } from "../../context"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts"

export const RevenueManagementContent = () => {
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const {
    facilityIds: queryFacilityIds,
    year: queryYear,
    month: queryMonth,
  } = router?.query

  const { facilities } = useContext(AuthContext)

  const getInitialSearchParams = () => {
    const today = dayjs()
    const queryDate = dayjs([
      +queryYear?.toString(),
      +queryMonth?.toString() - 1,
    ])
    return {
      facilityIds: queryFacilityIds?.toString()?.split(",") || [],
      year: queryDate.isValid() ? queryDate.year() : today.year(),
      month: queryDate.isValid() ? queryDate.month() + 1 : today.month() + 1,
    }
  }

  const [searchParams, setSearchParams] =
    useState<IRevenueManagementSearchParams>(getInitialSearchParams())

  const handleSearchParamChange = (params) => {
    updateParams(params, "/revenue-management")
    setSearchParams(params)
  }

  const getRevenueColumns = () => {
    const columns: any = [
      {
        key: "day",
        title: t("Day"),
        dataIndex: "day",
        className: "revenue-day",
        align: "center",
        onCell: (row) => {
          if (row?.combineDayColumns) {
            return {
              colSpan: 2,
            }
          }
          return {}
        },
      },
      {
        key: "dayWeek",
        title: t("Week Day"),
        dataIndex: "dayWeek",
        className: "revenue-day-week",
        align: "center",
        onCell: (row) => {
          if (row?.combineDayColumns) {
            return {
              colSpan: 0,
            }
          }
          return {}
        },
      },
    ]

    facilities?.forEach((facility) => {
      if (
        searchParams?.facilityIds?.length == 0 ||
        searchParams?.facilityIds?.includes(facility?.value)
      ) {
        columns.push({
          key: `facility` + facility?.value,
          title: facility?.label,
          align: "center",
          render: (row) =>
            row?.renderTotalCell
              ? renderDummyFacilityTotal(facility?.service)
              : row?.renderAmountCell
                ? renderDummyFacilityAmount(facility?.service)
                : row?.renderAchievementCell
                  ? renderDummyAchievement()
                  : renderDummyDayData(row),
        })
      }
    })

    return columns
  }

  // dummy render contents
  const renderDummyFacilityTotal = (service) => {
    return (
      <FacilityRevenueTotalCell>
        <EmploymentTag service={service}>
          {service == 1
            ? t("Type A")
            : service == 2
              ? t("Type B")
              : t("Employment Transition Support")}
        </EmploymentTag>
        {service == 3 ? (
          t("No")
        ) : (
          <>
            {t("Attendance")}
            {" : 10 "}
            {t("Absence")}
            {" : 2"}
          </>
        )}
      </FacilityRevenueTotalCell>
    )
  }

  const renderDummyFacilityAmount = (service) => {
    return <>{service == 3 ? "0円" : "105,046円"}</>
  }

  const renderDummyAchievement = () => {
    return (
      <FacilityAchievementCell>
        <div className={"achievement-content"}>
          <div className={"achievement-header"}>{t("Day used")}</div>
          <div className={"achievement-body"}>
            {"出勤 :0 "}
            <br />
            {"欠勤Ⅰ :0 "} <br />
            {"欠勤Ⅰ(加算なし) :0 "} <br />
            {"予定 :0"}
          </div>
        </div>

        <div className={"achievement-content"}>
          <div className={"achievement-header"}>
            {t("Average number of daily users/rate")}
          </div>
          <div className={"achievement-body"}>{"2.57人/25.71%"}</div>
        </div>

        <div className={"achievement-content"}>
          <div className={"achievement-header"}>
            {t("3-month average number of users/rate")}
          </div>
          <div className={"achievement-body"}>{"1.23人/12.27%"}</div>
        </div>
      </FacilityAchievementCell>
    )
  }

  const renderDummyDayData = (data) => {
    if (data?.is_holiday) {
      return t("Holiday")
    }
    return (
      <div className={"revenue-day-data"}>
        {t("Attendance")} {" : 1"}
        <br />
        {t("Absence")} {" : 1"}
      </div>
    )
  }
  // end of dummy render

  const getRevenueData = () => {
    const data: any = [
      {
        day: t("Total"),
        combineDayColumns: true,
        renderTotalCell: true,
      },
      {
        day: t("Amount of money"),
        combineDayColumns: true,
        renderAmountCell: true,
      },
      {
        day: t("Achievement"),
        combineDayColumns: true,
        renderAchievementCell: true,
      },
    ]

    const totalDaysInMonth = dayjs([
      searchParams.year,
      searchParams.month - 1,
    ]).daysInMonth()

    for (let i = 1; i <= totalDaysInMonth; i++) {
      const date = dayjs([searchParams.year, searchParams.month - 1, i])
      const weekDay = date.day()
      data.push({
        day: i?.toString(),
        dayWeek: t(getWeekNameByDate(weekDay)),
        is_holiday: weekDay == 0 || weekDay == 6, // dummy
      })
    }

    return data
  }

  const navigateToGraph = () => {
    const query = []
    if (searchParams.facilityIds?.length > 0) {
      query.push(`facilityIds=${searchParams.facilityIds.join(",")}`)
    }
    if (searchParams.year) {
      query.push(`year=${searchParams.year}`)
    }
    if (searchParams.month) {
      query.push(`month=${searchParams.month}`)
    }
    router.push(`/revenue-management/graph?${query?.join("&")}`)
  }

  return (
    <>
      <RevenueManagementOperation
        searchParams={searchParams}
        onDisplayChange={handleSearchParamChange}
        facilities={facilities}
      />
      <Card
        title={t("{{yearMonth}}'s revenue status", {
          yearMonth: `${searchParams.year}年${searchParams.month
            .toString()
            .padStart(2, "0")}月`,
        })}
        extra={
          <MonthPagination
            currentMonth={searchParams.month - 1}
            currentYear={searchParams.year}
            currentText={t("This month")}
            showDate={false}
            onMonthChange={(year, month, date) => {
              handleSearchParamChange({
                ...searchParams,
                year: date.year(),
                month: date.month() + 1,
              })
            }}
          />
        }
      >
        <RevenueManagementContentainer>
          <div className={"top-container"}>
            <div>
              <span className={"total-label"}>
                {t("{{yearMonth}}'s Total Revenue", {
                  yearMonth: `${searchParams.year}年${searchParams.month
                    .toString()
                    .padStart(2, "0")}月`,
                })}{" "}
                {": "}
              </span>
              <span className={"total-value"}>{"6,335,678円"}</span>
            </div>
            <Button
              btnText={t("Display graph")}
              shape={"round"}
              onClick={navigateToGraph}
            />
          </div>
          <div className={"revenue-table"}>
            <Table
              columns={getRevenueColumns()}
              dataSource={getRevenueData()}
              rowClassName={(row: any) =>
                row?.renderAmountCell
                  ? "amount-row"
                  : row?.is_holiday
                    ? "holiday-row"
                    : ""
              }
            />
          </div>
        </RevenueManagementContentainer>
      </Card>
    </>
  )
}

export const RevenueManagementGraphContent = () => {
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const {
    facilityIds: queryFacilityIds,
    year: queryYear,
    month: queryMonth,
  } = router?.query

  const { facilities } = useContext(AuthContext)

  const getInitialSearchParams = () => {
    const today = dayjs()
    const queryDate = dayjs([
      +queryYear?.toString(),
      +queryMonth?.toString() - 1,
    ])
    return {
      facilityIds: queryFacilityIds?.toString()?.split(",") || [],
      year: queryDate.isValid() ? queryDate.year() : today.year(),
      month: queryDate.isValid() ? queryDate.month() + 1 : today.month() + 1,
    }
  }

  const [searchParams, setSearchParams] =
    useState<IRevenueManagementSearchParams>(getInitialSearchParams())

  const handleSearchParamChange = (params) => {
    updateParams(params, "/revenue-management/graph")
    setSearchParams(params)
  }

  const navigateBack = () => {
    const query = []
    if (searchParams.facilityIds?.length > 0) {
      query.push(`facilityIds=${searchParams.facilityIds.join(",")}`)
    }
    if (searchParams.year) {
      query.push(`year=${searchParams.year}`)
    }
    if (searchParams.month) {
      query.push(`month=${searchParams.month}`)
    }
    router.push(`/revenue-management?${query?.join("&")}`)
  }

  const getDummyGraphData = () => {
    const data = []
    let startMonth = searchParams.month
    let startYear = searchParams.year

    // dummy data of last 6 months
    for (let i = 0; i < 6; i++) {
      const date = dayjs([startYear, startMonth - 1]).format("YYYY年MM月")
      const monthData: any = {
        year: startYear,
        month: startMonth,
        name: date,
      }

      facilities?.forEach((facility) => {
        if (
          searchParams?.facilityIds?.length == 0 ||
          searchParams?.facilityIds?.includes(facility?.value)
        ) {
          monthData[facility?.value] = +facility?.value * 100 * Math.pow(-1, i)
        }
      })

      data.unshift(monthData)

      startMonth--
      if (startMonth < 1) {
        startMonth = 12
        startYear--
      }
    }
    return data
  }

  const getDummyFacilityForLegend = () => {
    const legend: any = []
    facilities?.forEach((facility, index) => {
      if (
        searchParams?.facilityIds?.length == 0 ||
        searchParams?.facilityIds?.includes(facility?.value)
      ) {
        legend.push({
          label: facility?.label,
          value: facility?.value,
          color: TWELVE_DIFFERENT_COLORS?.[index],
        })
      }
    })
    return legend
  }

  return (
    <>
      <RevenueManagementOperation
        searchParams={searchParams}
        onDisplayChange={handleSearchParamChange}
        facilities={facilities}
      />

      <RevenueManagementGraphTopSection>
        <Button
          btnText={`<< ${t("Back")}`}
          shape={"round"}
          onClick={navigateBack}
        />
      </RevenueManagementGraphTopSection>
      <Card title={t("Sales status")}>
        <RevenueManagemenetGraphCumulativeTable>
          <table>
            <thead>
              <th>{t("Cumulative daily sales")}</th>
              <th>{t("Expected monthly sales")}</th>
              <th>{t("Achievement rate")}</th>
            </thead>
            <tbody>
              <td>{"00,000,000円"}</td>
              <td>{"00,000,000円"}</td>
              <td>{"000%"}</td>
            </tbody>
          </table>
        </RevenueManagemenetGraphCumulativeTable>
        <RevenueManagementAttendanceTable>
          <table>
            <thead>
              <th>{t("Number of days attended")}</th>
              <th>{t("Absence 1")}</th>
              <th>{t("Absence 1 (No addition)")}</th>
              <th>{t("Scheduled")}</th>
            </thead>
            <tbody>
              <td>{"243"}</td>
              <td>{"13"}</td>
              <td>{"4"}</td>
              <td>{"0"}</td>
            </tbody>
          </table>
        </RevenueManagementAttendanceTable>
        <RevenueManagementAttendanceTable>
          <table>
            <thead>
              <th>{t("Average number of daily users/rate")}</th>
              <th>{t("3-month average number of users/rate")}</th>
            </thead>
            <tbody>
              <td>{"12.2人／121.5%"}</td>
              <td>{"11.8人／118.3%"}</td>
            </tbody>
          </table>
        </RevenueManagementAttendanceTable>
        <RevenueManagementGraphContainer
          show_legend={searchParams?.facilityIds?.length != 1}
        >
          <div className={"graph-title"}>
            {t("Transition of annual revenue and achievement rate")}
          </div>
          <div className={"graph-content"}>
            <div className={"graph"}>
              <ResponsiveContainer height={500} minWidth={800} width={"100%"}>
                <LineChart
                  data={getDummyGraphData()}
                  margin={{
                    top: 5,
                    right: 20,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray={"5 5"} />
                  <XAxis dataKey={"name"} />
                  <YAxis />
                  {getDummyFacilityForLegend()?.map((facility) => (
                    <Line
                      key={facility.value}
                      dataKey={facility.value}
                      stroke={facility.color}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
            {getDummyFacilityForLegend()?.length != 1 && (
              <div className={"graph_legend"}>
                {getDummyFacilityForLegend()?.map((facility, index) => (
                  <div className={"each_info"} key={index}>
                    <div
                      className={"color_legend"}
                      style={{ background: facility?.color }}
                    ></div>
                    {facility?.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </RevenueManagementGraphContainer>
      </Card>
    </>
  )
}
