import {
  ActionBtns,
  Button,
  Card,
  DayPagination,
  getWeekNameByDate,
  theme,
  usePrint,
} from "@project/common"
import dayjs from "dayjs"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Typography } from "antd"
import { DayListContentTable } from "./DayListContentTable"
import {
  ListOperationParams,
  UserAttendanceStats,
  UserDayAttendance,
} from "../../../types"
import { useRouter } from "next/router"
import { DayListContentSUmmary } from "./DayListContentSUmmary"
import { DayListContentPrintModalContent } from "./DayListContentPrintModalContent"

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  .indicator {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
    &--box {
      height: 28px;
      width: 28px;
      border-radius: 5px;
      background-color: ${theme.colors.pink};
    }
    span {
      font-size: 16px;
    }
  }
`

export const DayListContent = ({
  params,
  attendanceStats,
  isLoading = false,
  attendance_list = [],
  staff_list = [],
  refetchAttendanceList,
  handleDateChange,
}: {
  params?: ListOperationParams
  attendanceStats: UserAttendanceStats
  isLoading?: boolean
  attendance_list?: Array<UserDayAttendance>
  refetchAttendanceList: () => void
  staff_list?: Array<any>
  handleDateChange: (val) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const printContentRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const { handlePrint, isPrinting } = usePrint({
    contentRef: printContentRef,
    pageStyle: `margin:0.5cm 0.8cm;`,
  })
  const title = t(
    "{{year}}年{{month}}月{{day}}日（{{dayOfWeek}})'s  user attendance table",
    {
      year: dayjs(params?.date).year(),
      month: `0${dayjs(params?.date).month() + 1}`.slice(-2),
      day: `0${dayjs(params?.date).date()}`.slice(-2),
      dayOfWeek: t(getWeekNameByDate(dayjs(params?.date).day())),
    },
  )

  const navigateToAbsenceRecordPage = () => {
    const query = []
    if (params?.date) {
      query.push(
        `from_date=${dayjs(params?.date).format("YYYY-MM-DD")}&to_date=${dayjs(
          params?.date,
        ).format("YYYY-MM-DD")}`,
      )
    }
    if (Array.isArray(params?.facilityId) && params?.facilityId.length > 0) {
      query.push(`facilityIds=${params?.facilityId?.join(",")}`)
    }
    if (
      Array.isArray(params?.user_service_id) &&
      params?.user_service_id.length > 0
    ) {
      query.push(`service=${params?.user_service_id?.join(",")}`)
    }
    router.push(`/user-attendance-calendar/absence-record?${query?.join("&")}`)
  }

  const getBulkAttendanceEditLink = () => {
    const link = `/user-attendance-calendar/daily-list/bulk-edit`
    const query = []
    if (params.date) {
      query.push(`date=${params.date.format("YYYY-MM-DD")}`)
    }
    if (params.facilityId.length) {
      query.push(`facility_ids=${params.facilityId.join(",")}`)
    }
    return link + "?" + query.join("&")
  }

  return (
    <>
      <Card
        title={title}
        extra={
          <DayPagination
            currentDay={params?.date.format("YYYY-MM-DD")}
            onDayChange={handleDateChange}
          />
        }
      >
        <WrapperContent>
          <ActionBtns justify={"flex-start"}>
            <Button
              btnText={t("Print")}
              shape={"round"}
              iconType={"print-gray"}
              btnBg={theme.colors.disabled}
              borderColor={theme.colors.border}
              onClick={handlePrint}
            />
            <Button
              btnText={t("Record Absence")}
              shape={"round"}
              onClick={navigateToAbsenceRecordPage}
            />
            <Button
              btnText={t("Bulk Edit")}
              shape={"round"}
              onClick={() => router?.push(getBulkAttendanceEditLink())}
            />
          </ActionBtns>
          <DayListContentSUmmary attendanceStats={attendanceStats} />
          <div className={"indicator"}>
            <div className={"indicator--box"} />
            <Typography.Text>{t("vacation schedule")}</Typography.Text>
          </div>
          <DayListContentTable
            params={params}
            isLoading={isLoading}
            attendance_list={attendance_list}
            refetchAttendanceList={refetchAttendanceList}
            staff_list={staff_list}
          />
        </WrapperContent>
      </Card>
      {isPrinting && (
        <div style={{ display: "none" }}>
          <DayListContentPrintModalContent
            ref={printContentRef}
            attendanceStats={attendanceStats}
            attendance_list={attendance_list}
            title={title}
          />
        </div>
      )}
    </>
  )
}
