import {
  Button,
  Card,
  CheckBox,
  DatePicker,
  Grid,
  InputField,
  Modal,
  theme,
} from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"

export const PaidLeaveSettingsForm = ({
  isCreateLoading,
  formik,
  setIsGrantModalOpen,
  isGrantModal,
  handleOk,
  onCancel,
  userId,
  totalPaidLeaveLeft,
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <>
      <Card
        title={t("Paid Leave Setting")}
        boxShadow={"4px 4px 16px 0px #3333331F"}
      >
        <form>
          <div>
            <Grid
              labelContent={t("Granting paid leave")}
              background
              padding={"14px"}
              required
              labelSpan={5}
            >
              <InputField
                name={"paidLeave"}
                placeholder={t("付与日数を入力")}
                width={"283px"}
                height={"40px"}
                value={formik.values.paidLeave}
                onChange={(e) => {
                  const inputValue = e.target.value
                  if (inputValue === "") {
                    formik.setFieldValue("paidLeave", null)
                  } else {
                    const parsedValue = parseInt(inputValue)
                    if (!isNaN(parsedValue)) {
                      formik.setFieldValue(
                        "paidLeave",
                        parsedValue === 0 ? null : parsedValue,
                      )
                    }
                  }
                }}
                error={
                  formik.touched?.paidLeave && formik.errors?.paidLeave
                    ? t(`${formik.errors?.paidLeave}`)
                    : ""
                }
                onBlur={formik.handleBlur}
                subInfoMl={"0px"}
              />
            </Grid>
            <Grid
              labelContent={t("Scheduled Grant date")}
              background
              padding={"14px"}
              required
              labelSpan={5}
            >
              <DatePicker
                name={"grantDate"}
                date={
                  dayjs(formik.values.grantDate)?.isValid()
                    ? dayjs(formik.values.grantDate)
                    : null
                }
                onDateChange={(v) =>
                  formik.setFieldValue(
                    "grantDate",
                    dayjs(v).format("YYYY-MM-DD"),
                  )
                }
                format={"YYYY年MM月DD日"}
                error={
                  formik.touched?.grantDate && formik.errors?.grantDate
                    ? t(`${formik.errors?.grantDate}`)
                    : ""
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
          </div>
          <br />
          <Flex wrap={"wrap"} gap={16}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={() => router.push(`/wage-management?user_id=${userId}`)}
              disabled={isCreateLoading}
            />
            <Button
              btnText={t("Save")}
              type={"primary"}
              shape={"round"}
              onClick={() => formik.handleSubmit()}
              disabled={isCreateLoading}
              isLoading={isCreateLoading}
            />
          </Flex>
        </form>
      </Card>
      {isGrantModal && (
        <Modal
          open={isGrantModal}
          onCancel={() => onCancel()}
          title={t("Confirmation")}
          hederPadding={"15px 30px"}
          width={656}
          onOk={handleOk}
          footer={null}
        >
          <Flex vertical gap={24}>
            <Flex vertical gap={8}>
              <div>
                {t(
                  "You are granting {{number_of_paid_leave_granting}} days of paid leave days, is that OK?",
                  {
                    number_of_paid_leave_granting: formik.values.paidLeave,
                  },
                )}
              </div>
              <div
                style={{
                  color: theme.colors.info,
                }}
              >
                {t(
                  "*Prior paid leave days of this user was {{previously_leave_days}} days.",
                  {
                    previously_leave_days: totalPaidLeaveLeft,
                  },
                )}
              </div>
              <CheckBox
                name={"isShowModel"}
                label={t("Don't show this message again for this user")}
                value={formik.values?.isShowModel}
                checked={formik.values?.isShowModel}
                onChange={(e) =>
                  formik.setFieldValue("isShowModel", e.target.checked)
                }
              />
            </Flex>
            <Flex gap={16} wrap={"wrap"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => setIsGrantModalOpen(false)}
                disabled={isCreateLoading}
                loading={isCreateLoading}
              />
              <Button
                btnText={t("Ok")}
                shape={"round"}
                type={"primary"}
                onClick={() => handleOk()}
                disabled={isCreateLoading}
                loading={isCreateLoading}
              />
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}
