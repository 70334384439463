import { Flex } from "antd"
import React, { useMemo, useRef, useState } from "react"
import { ActualCostBurdenRecordOperation } from "./ActualCostBurdenRecordOperation"
import { ActualCostBurdenRecordTable } from "./ActualCostBurdenRecordTable"
import {
  getLocalstorageItem,
  scrollToSelectedElement,
  setItemToLocalstorage,
  useUpdateSearchParams,
} from "@project/common"
import dayjs from "dayjs"
import { FULL_DATE_FORMAT_EN } from "../../constants"
import { useQuery } from "react-query"
import { getAllActualCostBurdenRecord } from "../../services"
import { useAuthContext } from "../../context"
import { useRouter } from "next/router"
import {
  ActualCostBurdenPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"

export const ActualCostBurdenRecord = (): JSX.Element => {
  const { facilities, isOwner, permissions } = useAuthContext()
  const rotuer = useRouter()
  const { facility, date } = rotuer?.query as any
  const storeFacility = getLocalstorageItem("acbr_facility", "facility_ids")
  const [params, setParams] = useState({
    date: date ? dayjs(date) : dayjs(),
    facility: facility || storeFacility || facilities?.[0]?.value || null,
  })

  const contentRef = useRef<HTMLDivElement>(null)

  const facilityName = facilities.find((item) => item?.id == params?.facility)
    ?.facility_name

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        ActualCostBurdenPermission,
        "write",
        params?.facility,
      ),
    [isOwner, permissions, params],
  )

  const [updateParams] = useUpdateSearchParams()
  const { data, isLoading } = useQuery({
    queryKey: ["actual-cost-record-list", params],
    queryFn: () =>
      getAllActualCostBurdenRecord({
        facilityIds: params?.facility,
        year: dayjs(params?.date)?.year(),
        month: dayjs(params?.date)?.month() + 1,
        page: null,
        pageSize: null,
      }),
    refetchOnWindowFocus: false,
    select: (res) => res?.data,
  })
  return (
    <Flex vertical gap={16}>
      <ActualCostBurdenRecordOperation
        params={params}
        setParams={(values) => {
          setItemToLocalstorage("acbr_facility", {
            facility_ids: values?.facility,
          })
          scrollToSelectedElement(contentRef)
          setParams(values)
          updateParams(
            {
              facility: values?.facility,
              date: dayjs(values?.date)?.format(FULL_DATE_FORMAT_EN),
            },
            "/actual-cost-burden-record",
          )
        }}
      />
      <div ref={contentRef}>
        <ActualCostBurdenRecordTable
          isLoading={isLoading}
          facilityName={facilityName}
          params={params}
          setParams={(values) => {
            setParams(values)
            updateParams(
              {
                facility: values?.facility,
                date: dayjs(values?.date)?.format(FULL_DATE_FORMAT_EN),
              },
              "/actual-cost-burden-record",
            )
          }}
          dataSource={data || []}
          hasWriteAccess={hasWriteAccess}
        />
      </div>
    </Flex>
  )
}
