import { theme } from "@project/common"
import styled from "styled-components"
export const StaffMasterContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const StaffMasterSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.default};
  .flex-start {
    align-items: flex-start !important;
    .mt {
      padding-top: 0.5em;
    }
  }
`
export const StaffMasterListContent = styled.div``
