import {
  Button,
  CALENDAR_HOURS_MINUTES,
  CheckBox,
  InputField,
  SelectInput,
  theme,
} from "@project/common"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  STAFF_CHANGE_VALUES,
  STAFF__INITIAL_ATTENDANCE,
} from "../../../types/StaffAttendance.types"
const StyledWrapper = styled.div`
  padding: 3px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .cell__main__container {
    background-color: ${theme.colors["t-head"]};
    padding: 5px 2px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    &--content {
      background-color: ${theme.colors["t-head"]};
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .hour_txt {
        ${theme.typography["body-medium"]};
        color: ${theme.colors.text};
      }
      .info_text {
        ${theme.typography["body-medium"]};
        color: ${theme.colors.success};
        cursor: pointer;
      }
      .hour__picker__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 2px;
        row-gap: 8px;
        .hour__picker {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .break_time {
          display: flex;
          flex-direction: column;
        }
      }
      .ap__btns {
        display: flex;
        /* justify-content: center;
        align-items: center; */
        flex-direction: column;
        gap: 5px;
      }
    }
    .action__btns {
      display: flex;
      justify-content: center;
      button {
        padding: 2px !important;
        span {
          &:first-child {
            margin-inline-end: 3px !important;
          }
          font-size: 12px !important;
        }
      }
    }
  }
`
interface CellContentProps {
  data: STAFF__INITIAL_ATTENDANCE[] | []
  handleDataChange?: (values: STAFF_CHANGE_VALUES) => void
  dateOf: string
  addAndRemoveWorkingHours?: (action: "A" | "R", dataOf: string) => void
}
export const CellContent = ({
  data,
  handleDataChange,
  dateOf,
  addAndRemoveWorkingHours,
}: CellContentProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <StyledWrapper>
      <div>
        <CheckBox
          label={t("Break time")}
          checked={data?.[0]?.rest_minits_flg}
          onChange={({ target: { checked } }) => {
            handleDataChange({
              dateOfMonth: dateOf,
              key: "rest_minits_flg",
              value: checked,
              tempId: data?.[0]?.tempId,
            })
          }}
        />
      </div>
      <div>
        <InputField
          subInfo={t("Hrs")}
          height={"40px"}
          width={"80px"}
          subInfoMt={"0px"}
          disabled={!data?.[0]?.rest_minits_flg}
          value={data?.[0]?.rest_hours || null}
          onChange={({ target: { value } }) =>
            handleDataChange({
              dateOfMonth: dateOf,
              key: "rest_hours",
              value: value,
              tempId: data?.[0]?.tempId,
            })
          }
        />
      </div>
      <div className={"cell__main__container"}>
        {data?.map((timeData, index) => {
          const start_time =
            index === 0
              ? timeData?.attendance_start_time
              : timeData?.attendance_start_time2
          const end_time =
            index === 0
              ? timeData?.attendance_end_time
              : timeData?.attendance_end_time2
          const startTimeKey =
            index === 0 ? `attendance_start_time` : "attendance_start_time2"
          const endTimeKey =
            index === 0 ? `attendance_end_time` : "attendance_end_time2"
          const typeKey = index === 0 ? `attendance_type` : `attendance_type2`
          return (
            <Fragment key={index}>
              <div className={"cell__main__container--content"}>
                <div className={"hour_txt"}>
                  {t("Working hours") + ` ${index + 1}`}
                </div>
                {timeData[typeKey] === 1 ? (
                  <>
                    <div
                      className={"info_text"}
                      onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                        e.stopPropagation()
                        handleDataChange({
                          dateOfMonth: dateOf,
                          key: typeKey,
                          value: null,
                          tempId: timeData?.tempId,
                        })
                      }}
                    >
                      {t("Attendance at work")}
                    </div>
                    <div className={"hour__picker__container"}>
                      <div className={"hour__picker"}>
                        <SelectInput
                          name={"start_time_hr"}
                          width={"58px"}
                          shortSelector
                          options={CALENDAR_HOURS_MINUTES?.hours || []}
                          value={start_time?.split(":")?.[0] || null}
                          onChange={(value) => {
                            const time = `${value}:${
                              start_time?.split(":")?.[1] || "00"
                            }`
                            handleDataChange({
                              dateOfMonth: dateOf,
                              key: startTimeKey,
                              value: `${time}`,
                              tempId: timeData?.tempId,
                            })
                          }}
                        />
                        <strong>{":"}</strong>
                        <SelectInput
                          name={"start_time_min"}
                          width={"58px"}
                          shortSelector
                          options={CALENDAR_HOURS_MINUTES?.minutes || []}
                          value={start_time?.split(":")?.[1] || null}
                          onChange={(value) => {
                            const time = `${start_time?.split(
                              ":",
                            )?.[0]}:${value}`
                            handleDataChange({
                              dateOfMonth: dateOf,
                              key: startTimeKey,
                              value: `${time}`,
                              tempId: timeData?.tempId,
                            })
                          }}
                        />
                      </div>
                      <strong>{"~"}</strong>
                      <div className={"hour__picker"}>
                        <SelectInput
                          name={"start_time2_hr"}
                          width={"58px"}
                          shortSelector
                          options={CALENDAR_HOURS_MINUTES?.hours || []}
                          value={end_time?.split(":")?.[0] || null}
                          onChange={(value) => {
                            const time = `${value}:${
                              end_time?.split(":")?.[1] || "00"
                            }`

                            handleDataChange({
                              dateOfMonth: dateOf,
                              key: endTimeKey,
                              value: `${time}`,
                              tempId: timeData?.tempId,
                            })
                          }}
                        />
                        <strong>{":"}</strong>
                        <SelectInput
                          name={"start_time2_min"}
                          width={"58px"}
                          shortSelector
                          options={CALENDAR_HOURS_MINUTES?.minutes || []}
                          value={end_time?.split(":")?.[1] || null}
                          onChange={(value) => {
                            const time = `${end_time?.split(":")?.[0]}:${value}`
                            handleDataChange({
                              dateOfMonth: dateOf,
                              key: endTimeKey,
                              value: `${time}`,
                              tempId: timeData?.tempId,
                            })
                          }}
                        />
                      </div>
                      <div className={"break_time"}>
                        <div>{t("Break time")}</div>
                        <InputField
                          height={"40px"}
                          subInfo={t("minutes")}
                          subInfoMt={"0px"}
                          value={
                            index === 0
                              ? timeData?.attendance_rest_minits || null
                              : timeData?.attendance_rest_minits2 || null
                          }
                          onChange={({ target: { value } }) =>
                            handleDataChange({
                              dateOfMonth: dateOf,
                              key:
                                index === 0
                                  ? `attendance_rest_minits`
                                  : `attendance_rest_minits2`,
                              value: value,
                              tempId: timeData?.tempId,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : timeData[`${typeKey}`] === 2 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: theme.colors.error,
                        cursor: "pointer",
                        width: "fit-content",
                      }}
                      onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                        e.stopPropagation()
                        handleDataChange({
                          dateOfMonth: dateOf,
                          key: typeKey,
                          value: null,
                          tempId: timeData?.tempId,
                        })
                      }}
                    >
                      {t("Absent")}
                    </div>
                  </div>
                ) : timeData?.hasAttendance_shift ? (
                  <div className={"ap__btns"}>
                    <Button
                      btnText={t("Present")}
                      btnBg={"white"}
                      borderColor={theme.colors.action}
                      shape={"round"}
                      onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                        e.stopPropagation()
                        handleDataChange({
                          dateOfMonth: dateOf,
                          key: typeKey,
                          value: 1,
                          tempId: timeData?.tempId,
                        })
                      }}
                    />
                    <Button
                      btnText={t("Absent")}
                      btnBg={"white"}
                      borderColor={theme.colors.error}
                      shape={"round"}
                      onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                        e.stopPropagation()
                        handleDataChange({
                          dateOfMonth: dateOf,
                          key: typeKey,
                          value: 2,
                          tempId: timeData?.tempId,
                          removeHrs2: timeData?.tempId === 1 ? true : false,
                        })
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Fragment>
          )
        })}
        {data?.[0]?.attendance_type === 1 && (
          <div className={"action__btns"}>
            {data?.length >= 2 ? (
              <Button
                btnText={t("Remove working hours")}
                btnBg={"white"}
                borderColor={theme.colors.border}
                iconType={"delete"}
                shape={"round"}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation()
                  addAndRemoveWorkingHours("R", dateOf)
                }}
              />
            ) : (
              <Button
                btnText={t("Add working hours")}
                btnBg={"white"}
                borderColor={theme.colors.border}
                iconType={"plus"}
                shape={"round"}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation()
                  addAndRemoveWorkingHours("A", dateOf)
                }}
              />
            )}
          </div>
        )}
      </div>
    </StyledWrapper>
  )
}
