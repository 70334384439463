import {
  ActionBtns,
  Button,
  Card,
  Grid,
  InputField,
  PREFECTURE_OPTIONS,
  allowOnlyNumberAndHyphen,
  dynamicLangString,
  scrollToFirstErrorField,
  theme,
} from "@project/common"

import { Popconfirm, Skeleton, notification } from "antd"
import { useFormik } from "formik"
import japanPostalCode from "japan-postal-code"
import Link from "next/link"
import router from "next/router"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import {
  createProvisionalCity,
  deleteProvisionalCity,
  getProvisionalCity,
  updateProvisionalCity,
} from "../../../services/provisionalCity"
import {
  ProvisionCityFormSchema,
  ProvisionCityFormValues,
} from "../../../types"
import { convertToHalfWidth } from "@project/common/src/constants"

const StyledProvisionCityForm = styled.div`
  #provision__zip_code__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.colors.link};
      text-decoration: underline;
    }
  }
`
const ContractReportContent = styled.div`
  margin-bottom: 1em;
  padding: 1em 0 1em 0;
  border: 1px solid ${theme.colors.border};
  border-left: 0;
  border-right: 0;
  h5 {
    font-size: 16px;
    font-weight: 500px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
interface ProvisionCityFormProps {
  id?: string | string[]
  title?: string
}

export const ProvisionCityForm = ({
  id,
  title,
}: ProvisionCityFormProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    data: provisionCity,
    isLoading: provisionCityLoading,
    isFetching: provisionCityFetching,
  } = useQuery<any, Error>(
    ["provisionalCity", id],
    () => id && getProvisionalCity(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return data
      },
    },
  )
  const initialValues: ProvisionCityFormValues = provisionCity || {
    provision_city_name: "",
    pic: "",
    mayor_name: "",
    municipality_number: null,
    subsidy_municipality_number: null,
    phone: "",
    email: "",
    address: "",
    zip_code: null,
  }

  const { mutate: mutateProvisionalCity, isLoading: isCreateLoading } =
    useMutation(id ? updateProvisionalCity : createProvisionalCity, {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Provision city",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Provision city",
              "Created Successfully",
            ]),
          })
        }
        router.push("/provisional-cities")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ProvisionCityFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateProvisionalCity(values)
    },
  })
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const setAddressFromPostCode = async () => {
    japanPostalCode.get(formik?.values?.zip_code, function (address) {
      formik.setFieldValue("address", address?.city)
      const prefectureNumber =
        PREFECTURE_OPTIONS.find((item) => {
          return item.label == address?.prefecture
        })?.label || null
      formik.setFieldValue("prefecture", prefectureNumber)
    })
  }

  const { isLoading: isDeleting, mutate: handleDelete } = useMutation(
    deleteProvisionalCity,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Provision city",
            "Deleted Successfully",
          ]),
        })
        router.push("/provisional-cities")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  return (
    <StyledProvisionCityForm>
      <Card title={title}>
        {provisionCityFetching ? (
          <>
            <Skeleton active />
            <br />
            <Skeleton active />
            <br />
            <br />
            <Skeleton active />
            <br />
            <Skeleton active />
          </>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Grid
                  labelContent={t("Provision city name")}
                  className={"row__header"}
                  background
                  required
                >
                  <InputField
                    name={"provision_city_name"}
                    id={"provision_city_name"}
                    placeholder={t("Example：Kawasaki")}
                    padding={"8px"}
                    borderRadius={"4px"}
                    info={t("(within 50 char)")}
                    maxLength={50}
                    value={formik.values?.provision_city_name}
                    error={
                      formik.touched?.provision_city_name &&
                      formik.errors?.provision_city_name
                        ? t(`${formik.errors?.provision_city_name}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid
                  labelContent={t("City number")}
                  className={"row__header"}
                  background
                  required
                >
                  <InputField
                    name={"municipality_number"}
                    id={"municipality_number"}
                    placeholder={t("例：123456")}
                    padding={"8px"}
                    borderRadius={"4px"}
                    maxLength={6}
                    width={"320px"}
                    value={formik.values?.municipality_number}
                    error={
                      formik.touched?.municipality_number &&
                      formik.errors?.municipality_number
                        ? t(`${formik.errors?.municipality_number}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    subInfo={t("(Within 6 numbers)")}
                  />
                </Grid>
                <Grid
                  labelContent={t("Grant municipality number")}
                  className={"row__header"}
                  background
                >
                  <InputField
                    name={"subsidy_municipality_number"}
                    id={"subsidy_municipality_number"}
                    placeholder={t("例：123456")}
                    padding={"8px"}
                    borderRadius={"4px"}
                    maxLength={6}
                    width={"320px"}
                    value={formik.values?.subsidy_municipality_number}
                    error={
                      formik.touched?.subsidy_municipality_number &&
                      formik.errors?.subsidy_municipality_number
                        ? t(`${formik.errors?.subsidy_municipality_number}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    subInfo={t("(Within 6 numbers)")}
                  />
                </Grid>
                <ContractReportContent>
                  <h5>{t("Contract report information")}</h5>
                </ContractReportContent>
                <Grid
                  labelContent={t("Mayer Name")}
                  className={"row__header"}
                  background
                >
                  <InputField
                    name={"mayor_name"}
                    id={"mayor_name"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    info={t("(within 100 char)")}
                    placeholder={"例：山田　太郎"}
                    maxLength={100}
                    value={formik.values?.mayor_name}
                    error={
                      formik.touched?.mayor_name && formik.errors?.mayor_name
                        ? t(`${formik.errors?.mayor_name}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid
                  labelContent={t("PIC ")}
                  className={"row__header"}
                  background
                >
                  <InputField
                    name={"pic"}
                    id={"pic"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    placeholder={"例：山田　太郎"}
                    info={t("(within 100 char)")}
                    maxLength={100}
                    value={formik.values?.pic}
                    error={
                      formik.touched?.pic && formik.errors?.pic
                        ? t(`${formik.errors?.pic}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid
                  labelContent={t("Phone number")}
                  className={"row__header"}
                  background
                >
                  <div id={"phone_number__content"}>
                    <InputField
                      name={"phone"}
                      id={"phone"}
                      placeholder={t("Example：012-345-6789")}
                      padding={"8px"}
                      borderRadius={"4px"}
                      width={"320px"}
                      maxLength={30}
                      value={formik.values?.phone}
                      error={
                        formik.touched?.phone && formik.errors?.phone
                          ? t(`${formik.errors?.phone}`)
                          : ""
                      }
                      onChange={({ target: { value } }) => {
                        formik.setFieldValue(
                          "phone",
                          allowOnlyNumberAndHyphen(value),
                        )
                      }}
                      onBlur={formik.handleBlur}
                      subInfo={t("(within ３０alphanumerics)")}
                    />
                  </div>
                </Grid>

                <Grid
                  labelContent={t("Email")}
                  className={"row__header"}
                  background
                >
                  <div id={"email__content"}>
                    <InputField
                      name={"email"}
                      id={"email"}
                      padding={"8px"}
                      placeholder={"例: easyy@email.com"}
                      borderRadius={"4px"}
                      width={"320px"}
                      maxLength={48}
                      value={formik.values?.email}
                      error={
                        formik.touched?.email && formik.errors?.email
                          ? t(`${formik.errors?.email}`)
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "email",
                          convertToHalfWidth(e.target.value),
                        )
                      }
                      onBlur={formik.handleBlur}
                      subInfo={t("(半角英数字48文字以内)")}
                    />
                  </div>
                </Grid>

                <Grid
                  labelContent={t("Po box")}
                  className={"row__header"}
                  background
                >
                  <div id={"provision__zip_code__content"}>
                    <div>
                      <InputField
                        name={"zip_code"}
                        id={"zip_code"}
                        placeholder={"例：060-0001"}
                        padding={"8px"}
                        borderRadius={"4px"}
                        width={"320px"}
                        maxLength={8}
                        value={formik.values?.zip_code}
                        error={
                          formik.touched?.zip_code && formik.errors?.zip_code
                            ? t(`${formik.errors?.zip_code}`)
                            : ""
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value
                          const regex = /^[0-9-]*$/
                          if (regex.test(inputValue)) {
                            if (inputValue === "") {
                              formik.setFieldValue("zip_code", null)
                            } else {
                              formik.setFieldValue("zip_code", inputValue)
                            }
                          }
                        }}
                        onBlur={formik.handleBlur}
                        subInfo={t("(within 8 characters)")}
                      />
                      <Link
                        href={"https://www.post.japanpost.jp/zipcode/"}
                        id={"provision__zip_code__content--msg"}
                      >
                        <a target={"_blank"}>
                          {t(
                            "*If you are unsure of your postal code, please check the postal code on the Japan Post Group website.",
                          )}
                        </a>
                      </Link>
                    </div>
                    <Button
                      btnText={t("Autofill address")}
                      shape={"round"}
                      onClick={setAddressFromPostCode}
                      disabled={!formik.values.zip_code}
                    />
                  </div>
                </Grid>

                <Grid
                  labelContent={t("Municipality/Address")}
                  className={"row__header"}
                  background
                >
                  <InputField
                    name={"address"}
                    id={"address"}
                    placeholder={"例：東京都渋谷区〇〇1丁目2−3"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    info={t("(within 200 char)")}
                    maxLength={200}
                    value={formik.values?.address}
                    error={
                      formik.touched?.address && formik.errors?.address
                        ? t(`${formik.errors?.address}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </div>
              <br />
              <ButtonWrapper>
                <ActionBtns justify={"none"}>
                  <Button
                    btnText={t("Cancel")}
                    type={"default"}
                    shape={"round"}
                    onClick={() => router.back()}
                    disabled={provisionCityLoading || isCreateLoading}
                  />
                  <Button
                    btnText={t("Save")}
                    shape={"round"}
                    type={"primary"}
                    htmlType={"submit"}
                    isLoading={provisionCityLoading || isCreateLoading}
                    disabled={provisionCityLoading || isCreateLoading}
                  />
                </ActionBtns>
                {id && (
                  <div className={"delete-button"}>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      onConfirm={() => handleDelete(id)}
                      okText={t("OK")}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <Button
                        btnText={t("Delete")}
                        type={"primary"}
                        shape={"round"}
                        btnBg={theme.colors["error"]}
                        color={theme.colors["white"]}
                        borderColor={"none"}
                        isLoading={isDeleting}
                        disabled={isDeleting}
                      />
                    </Popconfirm>
                  </div>
                )}
              </ButtonWrapper>
            </form>
          </>
        )}
      </Card>
    </StyledProvisionCityForm>
  )
}
