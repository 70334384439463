import { InputAreaField, SelectInput } from "@project/common"
import { Flex, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { FuriganaAlphabetsOption } from "../../../../constants"
import { filterWithHiragana } from "../../../../utils"

export const AbsentNoteWithRecorder = ({
  values,
  setValues,
  staffOptions,
}: {
  values: {
    furigana: string
    saveStaffId: string
    absentNote: string
  }
  setValues: (val: any) => void
  staffOptions: any[]
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <Flex gap={24} align={"center"} wrap={"wrap"}>
        <Typography.Text> {t("[Recorder]")}</Typography.Text>
        <Flex gap={16} align={"center"}>
          <SelectInput
            placeholder={"--"}
            name={"recorder_furigana"}
            options={FuriganaAlphabetsOption}
            value={values?.furigana || null}
            onChange={(val) =>
              setValues({
                ...values,
                furigana: val,
              })
            }
            width={"78px"}
            height={"42px"}
            shortSelector
          />
          <SelectInput
            placeholder={"--"}
            name={"recorder"}
            options={
              values?.furigana
                ? filterWithHiragana({
                    options: staffOptions,
                    furigana: values?.furigana,
                  })
                : staffOptions
            }
            value={values?.saveStaffId || null}
            height={"42px"}
            onChange={(val) =>
              setValues({
                ...values,
                saveStaffId: val,
              })
            }
          />
        </Flex>
      </Flex>
      <div className={"absent-note"}>
        <InputAreaField
          value={values?.absentNote || null}
          height={"42px"}
          onChange={({ target: { value } }) =>
            setValues({
              ...values,
              absentNote: value,
            })
          }
          rows={5}
        />
      </div>
    </>
  )
}
