import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
} from "@project/common"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../context"
import dayjs, { Dayjs } from "dayjs"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { ListOperationParams } from "../../../types"

export const DayListOperation = ({
  params,
  setParams,
}: {
  params: ListOperationParams
  setParams: React.Dispatch<React.SetStateAction<ListOperationParams>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const [temParams, setTempParams] = useState({
    facilityId: params?.facilityId || [],
    user_service_id: params?.user_service_id || [],
    date: params?.date || null,
  })

  useEffect(() => {
    setTempParams({
      facilityId: params?.facilityId || [],
      user_service_id: params?.user_service_id || [],
      date: params?.date || null,
    })
  }, [params])

  return (
    <AccordionCard
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Display facility")}
                    width={"138px"}
                  />
                </div>
                <FacilityCheckbox
                  options={facilities}
                  value={temParams?.facilityId}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setTempParams({
                        ...temParams,
                        facilityId: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setTempParams({
                        ...temParams,
                        facilityId: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setTempParams({
                      ...temParams,
                      facilityId: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Use service")}
                    width={"138px"}
                  />
                </div>
                <CheckBoxGroup
                  items={FACILITY_TYPES || []}
                  value={temParams?.user_service_id}
                  onChange={(list: CheckboxValueType[]) => {
                    setTempParams({
                      ...temParams,
                      user_service_id: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Date")} width={"138px"} />
                <DatePicker
                  onDateChange={(date: Dayjs) => {
                    setTempParams({
                      ...temParams,
                      date: date,
                    })
                  }}
                  placeholder={"--"}
                  date={
                    dayjs(temParams?.date).isValid()
                      ? dayjs(temParams?.date)
                      : null
                  }
                />
              </div>
              <div className={"search__content btns"} style={{ gap: "10px" }}>
                <Button
                  btnText={t("Search")}
                  type={"primary"}
                  size={"large"}
                  shape={"round"}
                  iconType={"search"}
                  onClick={() => setParams(temParams)}
                />
                <Button
                  btnText={t("Reset search")}
                  size={"large"}
                  shape={"round"}
                  onClick={() =>
                    setParams({
                      facilityId: [],
                      user_service_id: [],
                      date: dayjs(),
                    })
                  }
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
