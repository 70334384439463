export const EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB_INITIAL_COLS = {
  user_wage: 0,
  user_fee: 0,
  salary_cost: 0,
  provision_bonuse: 0,
  retirement_benefit_costs: 0,
  statutory_welfare_cost: 0,
  welfare_cost: 0,
  travel_cost: 0,
  equipment_cost: 0,
  supplies_cost: 0,
  printing_cost: 0,
  utility_cost: 0,
  fuel_cost: 0,
  repair_cost: 0,
  lease_cost: 0,
  order_activity_cost: 0,
  conference_fee: 0,
  non_life_insurance_premium: 0,
  rent: 0,
  educational_expenses: 0,
  public_dues: 0,
  depreciation_expense: 0,
  special_reserves: 0,
  uncollectible_reserves: 0,
  uncollectible_amount: 0,
  miscellaneous_expenses: 0,
}

export const EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB = [
  {
    key: "user_wage",
    subject: "User Wage",
  },
  {
    key: "user_fee",
    subject: "User Fee",
  },
  {
    key: "salary_cost",
    subject: "Salary of employment support business instructors",
  },
  {
    key: "provision_bonuse",
    subject:
      " Employment support business instructor, bonus bonus rewards, etc.",
  },
  {
    key: "retirement_benefit_costs",
    subject: "Employment support business instructor, retirement benefit cost",
  },
  {
    key: "statutory_welfare_cost",
    subject: "Legal welfare expenses",
  },
  {
    key: "welfare_cost",
    subject: "Welfare expense",
  },
  {
    key: "travel_cost",
    subject: "travel expenses",
  },
  {
    key: "equipment_cost",
    subject: "Equipment costs",
  },
  {
    key: "equipment_cost",
    subject: "supplies expense",
  },
  {
    key: "printing_cost",
    subject: "Printed book expenses",
  },
  {
    key: "utility_cost",
    subject: "water supply expense",
  },
  {
    key: "fuel_cost",
    subject: "Fuel cost",
  },
  {
    key: "repair_cost",
    subject: "repair costs",
  },
  {
    key: "lease_cost",
    subject: "Lease cost",
  },
  {
    key: "order_activity_cost",
    subject: "Order activity expenses",
  },
  {
    key: "conference_fee",
    subject: "Meeting expenses",
  },
  {
    key: "non_life_insurance_premium",
    subject: "Non -life insurance premium",
  },
  {
    key: "rent",
    subject: "Rent",
  },
  {
    key: "educational_expenses",
    subject: "Books and educational expenses",
  },
  {
    key: "public_dues",
    subject: "Taxation Public Division",
  },
  {
    key: "depreciation_expense",
    subject: "Depreciation expenses",
  },
  {
    key: "special_reserves",
    subject:
      "Special reserve deprecation such as national treasury subsidies (deduction items)",
  },
  {
    key: "uncollectible_reserves",
    subject: "Unable to collect unable to pay",
  },
  {
    key: "uncollectible_amount",
    subject: "Unable to collect ",
  },
  {
    key: "miscellaneous_expenses",
    subject: "Miscellaneous expenses",
  },
  {
    key: "total",
    subject: "Total employment support business sales expenses",
  },
]
