import React, { useEffect, useState } from "react"

// commons
import { InputAreaField, InputField, Props } from "@project/common"

// types
import { TextAreaProps, InputProps } from "antd/es/input"

const DebouncedTextArea = ({
  value,
  onInputChange,
  ...rest
}: TextAreaProps & {
  onInputChange: (val: string) => void
}) => {
  const [currentValue, setCurrentValue] = useState<string>(
    value ? value.toString() : "",
  )

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onInputChange(currentValue)
    }, 500)

    return () => clearTimeout(delayDebounce)
  }, [currentValue])

  useEffect(() => {
    if (value) {
      setCurrentValue(value.toString())
    }
  }, [value])

  return (
    <InputAreaField
      value={currentValue}
      onChange={(e) => setCurrentValue(e.target.value)}
      {...rest}
    />
  )
}

const DebouncedInputField = ({
  value,
  onInputChange,
  ...rest
}: InputProps & {
  onInputChange: (val: string) => void
} & Props) => {
  const [currentValue, setCurrentValue] = useState<string>(
    value ? value.toString() : "",
  )

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onInputChange(currentValue)
    }, 500)

    return () => clearTimeout(delayDebounce)
  }, [currentValue])

  useEffect(() => {
    if (value) {
      setCurrentValue(value.toString())
    }
  }, [value])

  return (
    <InputField
      value={currentValue}
      onChange={(e) => setCurrentValue(e.target.value)}
      {...rest}
    />
  )
}

DebouncedTextArea.displayName = "DebouncedTextArea"
DebouncedInputField.displayName = "DebouncedInputField"

export { DebouncedTextArea, DebouncedInputField }
