import { Typography } from "antd"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { FUCInfo } from "../../../../components"
import { ACHIEVEMENT_GOAL_TYPE, EVALUATION_TYPES } from "../../../../constants"
import { PrintPageWrapper } from "./MonitoringDetailPrint.style"

interface IProps {
  monitoring: any
  params?: any
  isPrinting?: boolean
}

export const MonitoringDetailPrintPage = React.forwardRef(
  ({ monitoring, params }: IProps, ref: any) => {
    const { t } = useTranslation()
    const renderSignature = (url) => (
      <>
        {url ? <img src={url} alt={"signature"} id={"signature__img"} /> : ""}
      </>
    )
    return (
      <PrintPageWrapper ref={ref}>
        <div className={"summary__container"}>
          <FUCInfo
            facility_name={monitoring?.facility?.facility_name || ""}
            use_service_id={monitoring?.service_type}
            created_datetime={monitoring?.created_datetime || dayjs()}
          />
          <div className={"monitoring_detail_summary"}>
            <table id={"monitoring_detail_summary--table"}>
              <tbody>
                <tr>
                  <td>{t("Created no. times")}</td>
                  <td>
                    {monitoring?.create_count} {t("回目")}
                  </td>
                  <td>{t("Recorder")}</td>
                  <td>{monitoring?.staff?.staff_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={"monitoring_detail_items"}>
          <table id={"monitoring_detail_items--table"}>
            <thead>
              <tr>
                <th>{t("Items (personal needs, etc.)")}</th>
                <th>{t("Specific goals")}</th>
                <th>{t("Goal achievement")}</th>
                <th>{t("Evaluation")}</th>
                <th>{t("Contents of support/Points to consider")}</th>
              </tr>
            </thead>
            <tbody>
              {monitoring?.user_monitoring_item?.map((goal, idx) => {
                const achievement_goals = Array.isArray(
                  goal?.user_monitoring_content?.achievement_goals,
                )
                  ? goal?.user_monitoring_content?.achievement_goals
                  : JSON.parse(goal?.user_monitoring_content?.study)

                const support_and_considerations = Array.isArray(
                  goal?.user_monitoring_content?.study,
                )
                  ? goal?.user_monitoring_content?.study
                  : JSON.parse(goal?.user_monitoring_content?.study)

                return (
                  <tr key={idx}>
                    <td>{goal?.title}</td>
                    <td>
                      {Array.isArray(achievement_goals)
                        ? achievement_goals?.map(
                            (val, i) =>
                              val && (
                                <div key={i} className={"list__item"}>
                                  <div className={"dot"}></div>
                                  <div style={{ whiteSpace: "pre-line" }}>
                                    {` ${val}`}
                                  </div>
                                </div>
                              ),
                          )
                        : "-"}
                    </td>
                    <td>
                      <Typography.Text>
                        {
                          ACHIEVEMENT_GOAL_TYPE?.find(
                            (v) =>
                              v?.value ===
                              goal?.user_monitoring_content
                                ?.achievement_goal_type,
                          )?.label
                        }
                      </Typography.Text>

                      {goal?.user_monitoring_content?.achievement_goal_type ===
                        4 && (
                        <Typography.Text>
                          {
                            goal?.user_monitoring_content
                              ?.achievement_goal_type_text
                          }
                        </Typography.Text>
                      )}
                    </td>
                    <td>
                      <Typography.Text>
                        {
                          EVALUATION_TYPES?.find(
                            (v) =>
                              v?.value ===
                              goal?.user_monitoring_content?.evaluation_type,
                          )?.label
                        }
                      </Typography.Text>
                      {goal?.user_monitoring_content?.evaluation_type === 4 && (
                        <Typography.Text>
                          {goal?.user_monitoring_content?.evaluation_type_text}
                        </Typography.Text>
                      )}
                    </td>
                    <td>
                      {support_and_considerations?.map(
                        (val, i) =>
                          val && (
                            <div key={i} className={"list__item"}>
                              <div className={"dot"}></div>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {` ${val}`}
                              </div>
                            </div>
                          ),
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className={"monitoring_detail_goals"}>
          <table id={"monitoring_detail_goals--table"}>
            <tbody>
              <tr>
                <td>{t("Long term goal")}</td>
                <td>{monitoring?.long_term || "--"}</td>
              </tr>
              <tr>
                <td>{t("Consideration for long-term goals")}</td>
                <td>{monitoring?.long_term_goal_study}</td>
              </tr>
              <tr>
                <td>{t("Short term goal")}</td>
                <td>{monitoring?.short_term || "--"}</td>
              </tr>
              <tr>
                <td>{t("Consideration for short term goal")}</td>
                <td>{monitoring?.short_term_goal_study}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={"monitoring_detail_hopes"}>
          <table id={"monitoring_detail_hopes--table"}>
            <tbody>
              <tr>
                <td>{t("Self hope")}</td>
                <td>{monitoring?.user_hope || "--"}</td>
              </tr>
              <tr>
                <td>{t("Family hope")}</td>
                <td>{monitoring?.family_hope}</td>
              </tr>
              <tr>
                <td>{t("Relatives hope")}</td>
                <td>{monitoring?.concern_hope || "--"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={"monitoring_detail__print_footer"}>
          <div className={"mf_left"}>
            {params?.display_content?.includes("2") && (
              <Typography.Paragraph
                style={{
                  margin: 0,
                }}
              >
                {params?.statementText}
              </Typography.Paragraph>
            )}
            {params?.display_content?.includes("1") && (
              <>
                {params?.consent_date?.includes("2") ? (
                  <>
                    <div className={"content__container"}>
                      <div className={"bordered_content"}>
                        <Typography.Paragraph
                          style={{
                            margin: 0,
                          }}
                        >
                          {t("Explanation date baka")}
                        </Typography.Paragraph>
                        {renderSignature(
                          monitoring?.explanation_date_digital_image,
                        )}
                      </div>
                    </div>
                    <div className={"content__container"}>
                      <div className={"bordered_content"}>
                        <Typography.Paragraph
                          style={{
                            margin: 0,
                          }}
                        >
                          {t("Consent date")}
                        </Typography.Paragraph>
                        {renderSignature(
                          monitoring?.consent_date_digital_image,
                        )}
                      </div>
                    </div>
                  </>
                ) : params?.consent_date?.includes("1") ? (
                  <>
                    <Typography.Text>{`${t(
                      "Explanation date",
                    )} ${dayjs().format("YYYY年MM月DD日")}\n`}</Typography.Text>
                    <Typography.Text>{`${t("Consent date")} ${dayjs().format(
                      "YYYY年MM月DD日",
                    )}\n`}</Typography.Text>
                  </>
                ) : (
                  <div>
                    <Typography.Text>{`${t(
                      "Date of consent to the explanation",
                    )} ${dayjs().format("YYYY年MM月DD日")}\n`}</Typography.Text>
                  </div>
                )}
              </>
            )}
            {params?.display_content?.includes("3") && (
              <div className={"content__container"}>
                <div className={"bordered_content"}>
                  <Typography.Paragraph
                    style={{
                      margin: 0,
                    }}
                  >
                    {t("User name")}
                  </Typography.Paragraph>
                  {renderSignature(monitoring?.user_name_digital_image)}
                </div>
                {params?.display_content?.includes("4") && (
                  <Typography.Text>{t("Mark")}</Typography.Text>
                )}
              </div>
            )}
          </div>
          <div className={"mf_right"}>
            <div className={"ads_office"}>
              <Typography.Text>
                {monitoring?.facility?.facility_name || ""}
              </Typography.Text>
              <Typography.Text>
                {monitoring?.user_info?.Company?.company_name}
              </Typography.Text>
            </div>
            <div className={"ads_manager"}>
              <div className={"txt"}>
                <Typography.Text>
                  {t("Adult development support manager")}
                </Typography.Text>
                {renderSignature(monitoring?.manager_digital_signature_image)}
              </div>
              {params?.display_content?.includes("6") && (
                <Typography.Text>{t("Mark")}</Typography.Text>
              )}
            </div>
          </div>
        </div>
      </PrintPageWrapper>
    )
  },
)
