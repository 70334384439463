// packages
import { Collapse, Flex } from "antd"

// styles
import styled from "styled-components"
import { theme } from "@project/common"

// types
import { NotiType } from "../../types/notification.types"
type NotiTypeTagProps = {
  $type: NotiType
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export const NotificationCollapsible = styled(Collapse)`
  .url-section {
    padding: 1rem 0rem;
    a {
      color: ${theme.colors.blue7};
    }
  }
  &.ant-collapse {
    border: none;
    border-radius: 0;
    background-color: ${theme.colors.white};
    .ant-collapse-item {
      border-color: ${theme.colors.border};
      padding-bottom: 0;
      transition: padding 0.3s ease-in-out;
      .ant-collapse-header {
        padding: 16px 0;
        margin-bottom: 0;
        transition: margin 0.3s ease-in-out;
      }
      &.ant-collapse-item-active {
        padding-bottom: 16px;
        .ant-collapse-header {
          margin-bottom: 8px;
        }
      }
      .ant-collapse-content {
        background-color: ${theme.colors.background};
        border-top: none;
        .ant-collapse-content-box {
          padding: 16px;
          & > div {
            color: ${theme.colors["sub-text"]};
            font-size: 14px !important;
          }
        }
      }
      &:last-child {
        border-radius: 0;
      }
    }
  }
`

export const NotiLabelWrap = styled.div`
  display: grid;
  gap: 8px;
  .title-text {
    font-size: 16px;
    font-weight: 500;
    color: ${theme.colors.text};
    margin-bottom: 0;
  }
  .date-text {
    color: ${theme.colors["sub-text"]};
    font-size: 14px;
  }
`

export const NotiTypeTag = styled.div<NotiTypeTagProps>`
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  display: grid;
  place-items: center;
  color: ${theme.colors.white};
  text-transform: capitalize;
  background-color: ${({ $type }) => {
    switch ($type) {
      case "important":
        return theme.colors.error
      case "notification":
        return theme.colors.orange
      case "update":
        return theme.colors.blue8
    }
  }};
`

export const NotiOperationFormWrap = styled.form`
  .date-inputs {
    flex: 1;
  }
  @media screen and (max-width: 440px) {
    .date-inputs {
      gap: 4px !important;
      & > div {
        width: auto !important;
        flex: 1;
      }
    }
  }

  @media screen and (max-width: 330px) {
    .date-inputs {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

export const EndMessageWrap = styled(Flex)`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  .end-message {
    font-weight: 400;
    font-size: 16px;
    color: ${theme.colors["sub-text"]};
  }
`
