import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { useQuery } from "react-query"
import { Flex, Spin, Typography } from "antd"
import { FormikTouched, setNestedObjectValues, useFormik } from "formik"
import { createId } from "@paralleldrive/cuid2"
// components
import {
  Box,
  Card,
  Grid,
  theme,
  Button,
  CheckBox,
  DatePicker,
  InputField,
  TimePicker,
  SelectInput,
  FileUploader,
  ErrorMassage,
  InputAreaField,
  FACILITY_SERVICE_TYPES,
  scrollToFirstErrorField,
} from "@project/common"

// styled components
import {
  MMStyledForm,
  MMFreeItemContainer,
  ParticipantSelContainer,
  AbsentCheckBoxContainer,
  UserFieldExtraInfoContainer,
} from "./MinutesMgmtForm.styles"

// utils
import { extractFileName } from "../utils/extractFileName"
import { japaneseAlphaRegex } from "../../../utils/validation"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { getFuriFilteredOptions } from "../../../utils/getFuriFilteredOptions"

// constants
import { AuthContext } from "../../../context"
import { ADDITION_NAME_TITLE_OPTIONS } from "@project/common/src/constants/settings"

// types
import {
  MMFormData,
  MMValidationSchema,
  MinutesMgmtFormDataProps,
} from "../../../types/variousAdditionsManagementOfMeetingMinutes.types"

// services
import { fetchInstructors, getOneUser } from "../../../services"
import { getAllUsers } from "../../../services/user"
import { fetchAllConstulationSupportOffice } from "../../../services/consultationSupportOffice"

// initial values for formik
export const initialValues: MMFormData = {
  participants: [
    { id: createId(), name: null, value: null, index: 0, furigana: null },
  ],
  free_items: [{ id: createId(), title: null, value: null }],
  attachment_files: [{ id: createId(), file: null }],
  username_id: null,
  addition_type: null,
  save_staff_id: null,
  event_date: dayjs(),
  corporate_name_1: null,
  corporate_person_name_1: null,
  corporate_name_2: null,
  corporate_person_name_2: null,
  consultation_support_person_name: null,
  consultation_support_id: null,
  minutes_title: null,
  event_end_time: null,
  event_start_time: null,
  is_reason_for_absence: false,
  reason_for_absence: null,
}

// ! ## MAIN COMPONENT
export const MinutesMgmtForm: React.FC<MinutesMgmtFormDataProps> = (props) => {
  const { push } = useRouter()
  const [uNameFuriAlpha, setUNameFuriAlpha] = useState<string | null | 0>(null)
  const [recorderFuriAlpha, setRecorderFuriAlpha] = useState<string | null | 0>(
    null,
  )

  const { facilities: facilitiesCtx } = useContext(AuthContext)

  // ! ## API query for fetching list of minute management
  // 1. All users
  const { data: allUsers, isLoading: loadingAllUsers } = useQuery("all-users", {
    queryFn: getAllUsers,
    select: (data) => {
      return data?.data?.map((user) => ({
        label: user?.username,
        value: user?.id,
        id: user?.id,
        facility_id: user?.facility_id,
        furigana: user?.furigana,
        Recipient_id_number: user?.certificate_number || "",
        service_used: t(FACILITY_SERVICE_TYPES[user?.service_type]),
      }))
    },
  })

  // 2. All free items
  const { data: allStaffs, isLoading: loadingAllStaffs } = useQuery(
    "all-staffs",
    {
      queryFn: () => fetchInstructors({}),
      select: (data) => {
        return data?.data?.map((staff) => ({
          label: staff?.staff_name,
          value: staff?.id,
          id: staff?.id,
          furigana: staff?.staff_name_furiganaame,
        }))
      },
    },
  )
  // 3. Construction support office names
  const { data: allConStaff, isLoading: loadingAllConStaff } = useQuery(
    "all-construction-support-staff",
    {
      queryFn: () => fetchAllConstulationSupportOffice({}),
      select: (data) => {
        return data?.data?.map((staff) => ({
          label: staff?.consultation_support_name,
          value: staff?.id + "",
        }))
      },
    },
  )

  // ! ## Formik form control
  const {
    values,
    errors,
    touched,
    isValid,
    setTouched,
    submitCount,
    handleChange,
    validateForm,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik<MMFormData>({
    initialValues:
      props.formType === "EDIT" ? props.initialValues : initialValues,
    validationSchema: MMValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (props.formType === "REGISTER") {
        props.handleRegistration(values, "CREATE")
      }

      if (props.formType === "EDIT") {
        props.handleUpdate(values, "CREATE")
      }
    },
  })

  // All furigana alphabet specific inputs will be handled by this function
  const handleUserNameFuriChange = (
    fieldName: "USER_NAME" | "RECORDER",
    val: string | null,
  ) => {
    if (fieldName === "USER_NAME") {
      setFieldValue("username_id", null)
      setUNameFuriAlpha(val)
      return
    }
    if (fieldName === "RECORDER") {
      setFieldValue("save_staff_id", null)
      setRecorderFuriAlpha(val)
    }
  }

  // ! ## Event action handlers [FUNCTIONS]
  // 1. Participant increment [PARTICIPANTS]
  const handleParticipantIncrement = () => {
    const uniqueId = createId()
    const _newParticipant = {
      id: uniqueId,
      name: null,
      value: null,
      index: values.participants.length,
      furigana: null,
    }

    setFieldValue("participants", [...values.participants, _newParticipant])
  }

  // 2. Participant decrement [PARTICIPANTS]
  const handleParticipantDecrement = (id: string) => {
    const _participants = values.participants.filter(
      (participant) => participant.id !== id,
    )

    setFieldValue("participants", _participants)
  }

  // 3. Free item increment [FREE ITEMS]
  const handleFreeItemIncrement = () => {
    const _newFreeItem = {
      id: createId(),
      title: null,
      value: null,
    }

    setFieldValue("free_items", [...values.free_items, _newFreeItem])
  }
  // 4. Free item decrement [FREE ITEMS]
  const handleFreeItemDecrement = (id: string) => {
    const _free_items = values.free_items.filter((item) => item.id !== id)

    setFieldValue("free_items", _free_items)
  }
  // 5. Attachment file increment [ATTACHMENT FILES]
  const handleAttachmentFileIncrement = () => {
    const _newAttachmentFile = {
      id: createId(),
      file: null,
    }
    setFieldValue("attachment_files", [
      ...values.attachment_files,
      _newAttachmentFile,
    ])
  }
  // 6. Attachment file decrement [ATTACHMENT FILES]
  const handleAttachmentFileDecrement = (id: string) => {
    const _attachment_files = values.attachment_files.filter(
      (file) => file.id !== id,
    )

    setFieldValue("attachment_files", _attachment_files)
  }
  // 7. submit form as draft [REGISTER/EDIT]
  const handleSubmitAsDraft = () => {
    // We are mimicking the formik submit function here: validatingForm > then > if no error > submitting form as `draft`
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (props.formType === "REGISTER") {
          props.handleRegistration(values, "DRAFT")
        }

        if (props.formType === "EDIT") {
          props.handleUpdate(values, "DRAFT")
        }
      }
      setTouched(setNestedObjectValues<FormikTouched<MMFormData>>(errors, true))
      scrollToFirstErrorField(errors)
    })
  }

  // ! ## Utility functions
  // 1. Get options for participants [Options being filtered by furigana and dyamic]
  const getParticipantOptions = (index, furigana?: string) => {
    const data = values?.participants
      ?.filter((_, idx) => idx !== index)
      ?.map((v) => v?.value)
    const filteredData = allStaffs?.filter((v) => !data?.includes(v?.value))
    const regex = japaneseAlphaRegex(furigana)

    if (furigana) {
      return filteredData?.filter(
        (staff) => staff?.furigana?.match(regex) || staff?.label?.match(regex),
      )
    }
    return filteredData
  }

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  const selectedUser = allUsers?.find(
    (item) => item?.value === values?.username_id,
  )

  const {
    isLoading: isFetching,
    data: individualUserInformation,
    refetch,
  } = useQuery({
    queryKey: ["individualUserInformation"],
    queryFn: () => getOneUser(values?.username_id),
    retry: true,
    enabled: values?.username_id !== undefined && values?.username_id !== null,
    cacheTime: 0,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: (response: any) => response?.info,
  })

  useEffect(() => {
    refetch()
  }, [values?.username_id])

  return (
    <div>
      <Spin
        spinning={
          (props.formType === "EDIT" && props.loadingCurrentMMData) ||
          (props.formType === "REGISTER" && props.registering) ||
          (props.formType === "EDIT" && props.updating)
        }
        size={"large"}
        tip={
          (props.formType === "EDIT" &&
            props.loadingCurrentMMData &&
            "Loading...") ||
          (props.formType === "REGISTER" &&
            props.registering &&
            "Registering...") ||
          (props.formType === "EDIT" && props.updating && "Updating...")
        }
      >
        <Card
          title={t(
            "Various additions ・Management of meeting minutes Information",
          )}
        >
          <MMStyledForm onSubmit={handleSubmit}>
            <div className={"form__control"}>
              {/* ## 1. Addition name/title */}
              <Grid
                labelContent={t("Addition name/title")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <div className={"MM_addition-inputs"}>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <SelectInput
                      allowClear
                      width={"auto"}
                      minWidth={"228px"}
                      placeholder={"--"}
                      name={"addition_type"}
                      popupMatchSelectWidth={false}
                      value={values?.addition_type}
                      options={ADDITION_NAME_TITLE_OPTIONS}
                      onChange={(val) => setFieldValue("addition_type", val)}
                      error={touched?.addition_type && errors?.addition_type}
                      onBlur={() => {
                        setFieldTouched("addition_type", true)
                      }}
                    />
                  </div>
                  <InputField
                    padding={"8px"}
                    id={"minutes_title"}
                    borderRadius={"4px"}
                    name={"minutes_title"}
                    onChange={handleChange}
                    value={values?.minutes_title}
                    disabled={!values?.addition_type}
                    placeholder={t("Enter name/title")}
                  />
                </div>
              </Grid>

              {/* 2. User name */}
              <Grid
                required
                background
                labelSpan={6}
                labelContent={t("Username ")}
                className={"row__header user-name__header"}
              >
                <div
                  className={"MM_username-inputs MM_furi-and-select-container"}
                >
                  <SelectInput
                    width={"78px"}
                    maxWidth={"78px"}
                    name={"furigana"}
                    placeholder={"--"}
                    value={uNameFuriAlpha}
                    className={"MM_furigana-select"}
                    options={FuriganaAlphabetsOption}
                    onChange={(val) =>
                      handleUserNameFuriChange("USER_NAME", val)
                    }
                  />
                  <div>
                    <SelectInput
                      allowClear
                      width={"auto"}
                      minWidth={"208px"}
                      name={"username_id"}
                      placeholder={t("---")}
                      loading={loadingAllUsers}
                      disabled={loadingAllUsers}
                      value={values?.username_id}
                      className={"MM_user-select min-w-210"}
                      onBlur={() => setFieldTouched("username_id", true)}
                      error={touched?.username_id && errors?.username_id}
                      onChange={(val) => setFieldValue("username_id", val)}
                      options={getFuriFilteredOptions(allUsers, uNameFuriAlpha)}
                    />
                  </div>
                  {values?.username_id && (
                    <AbsentCheckBoxContainer className={"person_absent"}>
                      <div className={"wrapper"}>
                        <CheckBox
                          label={t("Person absent")}
                          checked={values.is_reason_for_absence}
                          onChange={(e) => {
                            setFieldValue(
                              "is_reason_for_absence",
                              e.target.checked,
                            )
                          }}
                        />
                      </div>
                    </AbsentCheckBoxContainer>
                  )}
                </div>
                {/* TODO: This section needs to be reworked later on after we have specification confirmed. [Refactor later] */}
                {values?.is_reason_for_absence && (
                  <InputAreaField // reason for absence
                    resize
                    rows={4}
                    placeholder={t(
                      "Field to write reason for absence Example: Absent because the student is in class",
                    )}
                    name={"reason_for_absence"}
                    className={"MM_reason-for-absence-input"}
                    value={values?.reason_for_absence}
                    onChange={(e) =>
                      setFieldValue("reason_for_absence", e.target.value)
                    }
                    disabled={!values?.is_reason_for_absence}
                  />
                )}
                {values?.username_id && (
                  <UserFieldExtraInfoContainer className={"MM_user-field-info"}>
                    <Flex align={"center"} gap={"10px"}>
                      <Typography.Text className={"MM_user-field-info__text"}>
                        {t("Facilities used")}
                        {": "}
                      </Typography.Text>
                      <SelectInput
                        width={"auto"}
                        minWidth={"150px"}
                        placeholder={"--"}
                        options={facilitiesCtx?.filter(
                          (facility) =>
                            +facility.value === selectedUser?.facility_id,
                        )}
                        value={
                          selectedUser?.facility_id
                            ? String(selectedUser?.facility_id)
                            : null
                        }
                        name={"facilities_used"}
                        popupMatchSelectWidth={false}
                      />
                    </Flex>
                    <Flex align={"center"} gap={"10px"}>
                      <Typography.Text className={"MM_user-field-info__text"}>
                        {t("Recipient ID number")}
                        {": "}
                      </Typography.Text>
                      <span>{selectedUser?.Recipient_id_number}</span>
                    </Flex>
                    <Flex align={"center"} gap={"10px"}>
                      <Typography.Text className={"MM_user-field-info__text"}>
                        {t("Service used")}
                        {": "}
                      </Typography.Text>
                      <span>
                        {
                          allUsers?.find(
                            (item) => item?.value === values?.username_id,
                          )?.service_used
                        }
                      </span>
                    </Flex>
                    <Flex align={"center"} gap={"10px"}>
                      <Typography.Text className={"MM_user-field-info__text"}>
                        {t("Number of days used")}
                        {": "}
                      </Typography.Text>
                      <span>
                        {!isFetching &&
                          individualUserInformation?.attendance_count_this_month}
                      </span>
                    </Flex>
                  </UserFieldExtraInfoContainer>
                )}
              </Grid>

              <Typography.Text className={"MM_progress-title"}>
                {t("Progress")}
              </Typography.Text>

              {/* 3. Progress > Recorder */}
              <Grid
                required
                background
                labelSpan={6}
                className={"row__header"}
                labelContent={t("Recorder")}
              >
                <div className={"MM_furi-and-select-container flex-wrap"}>
                  <SelectInput
                    width={"78px"}
                    placeholder={"--"}
                    value={recorderFuriAlpha}
                    name={"recorder_furigana"}
                    className={"MM_furigana-select"}
                    options={FuriganaAlphabetsOption}
                    onChange={(val) =>
                      handleUserNameFuriChange("RECORDER", val)
                    }
                  />
                  <div>
                    <SelectInput
                      width={"auto"}
                      minWidth={"208px"}
                      placeholder={"--"}
                      name={"save_staff_id"}
                      disabled={loadingAllStaffs}
                      value={values?.save_staff_id}
                      onChange={(val) => setFieldValue("save_staff_id", val)}
                      onBlur={() => setFieldTouched("save_staff_id", true)}
                      options={getFuriFilteredOptions(
                        allStaffs,
                        recorderFuriAlpha,
                      )}
                      error={
                        touched?.save_staff_id && errors?.save_staff_id
                          ? errors?.save_staff_id
                          : null
                      }
                    />
                  </div>
                </div>
              </Grid>

              {/* 4. progress > Implemented date and time */}
              <Grid
                required
                background
                labelSpan={6}
                className={"row__header"}
                labelContent={t("Implemented date & time")}
              >
                <div className={"MM_event-date-time-inputs"}>
                  <DatePicker
                    placeholder={"--"}
                    name={"event_date"}
                    id={"event_end_time"}
                    format={"YYYY年MM月DD日"}
                    date={values?.event_date}
                    className={"date-picker-class"}
                    style={{ width: 160, height: 40 }}
                    error={touched?.event_date && errors?.event_date}
                    onBlur={() => setFieldTouched("event_date", true)}
                    onDateChange={(date) => setFieldValue("event_date", date)}
                  />
                  <div className={"time-range-wrapper"}>
                    <Flex align={"flex-start"} wrap={"wrap"} gap={10}>
                      <TimePicker
                        value={values?.event_start_time}
                        onTimeChange={(val) => {
                          setFieldValue("event_start_time", val)
                        }}
                        handleBlur={() =>
                          setFieldTouched("event_start_time", true)
                        }
                        error={
                          touched?.event_start_time &&
                          errors?.event_start_time &&
                          errors.event_start_time
                        }
                      />
                      <Box
                        component={"span"}
                        mt={8}
                        className={"time-separator"}
                      >
                        {"~"}
                      </Box>
                      <TimePicker
                        value={values?.event_end_time}
                        onTimeChange={(val) => {
                          setFieldValue("event_end_time", val)
                        }}
                        handleBlur={() =>
                          setFieldTouched("event_end_time", true)
                        }
                        error={
                          touched?.event_end_time &&
                          errors?.event_end_time &&
                          errors.event_end_time
                        }
                      />
                    </Flex>
                  </div>
                </div>
              </Grid>

              {/* 5. Progress > participants [incrementable] */}
              <Grid
                required
                background
                labelSpan={6}
                className={"row__header"}
                labelContent={t("Participants")}
              >
                <ParticipantSelContainer
                  className={"MM_participants-container"}
                >
                  {values?.participants.map((participant, index) => {
                    return (
                      <div
                        className={"participants-wrapper"}
                        key={participant.id}
                      >
                        <div className={"MM_furi-and-select-container"}>
                          <SelectInput
                            width={"78px"}
                            minWidth={"78px"}
                            maxWidth={"78px"}
                            placeholder={"--"}
                            value={participant.furigana}
                            className={"furigana-select"}
                            name={"participants_furigana"}
                            options={FuriganaAlphabetsOption}
                            onChange={(val) => {
                              setFieldValue(
                                `participants[${index}].furigana`,
                                val,
                              )
                              setFieldValue(
                                `participants[${index}].value`,
                                null,
                              )
                            }}
                          />
                          <div>
                            <SelectInput
                              width={"auto"}
                              minWidth={"210px"}
                              placeholder={"---"}
                              id={"participants"}
                              name={"participants"}
                              value={participant.value}
                              options={getParticipantOptions(
                                index,
                                participant.furigana,
                              )}
                              onChange={(val) => {
                                setFieldValue(
                                  `participants[${index}].value`,
                                  val,
                                )
                              }}
                              onBlur={() => {
                                setFieldTouched(`participants[${index}].value`)
                              }}
                              error={
                                touched?.participants?.[index]?.value &&
                                //@ts-ignore
                                errors?.participants?.[index]?.value
                                  ? //@ts-ignore
                                    errors?.participants?.[index]?.value
                                  : null
                              }
                            />
                          </div>
                        </div>

                        <div className={"participant-btn-group"}>
                          {index === values.participants.length - 1 &&
                            values.participants.length <= 20 && (
                              <Button
                                icon={"add"}
                                shape={"round"}
                                type={"default"}
                                iconType={"add"}
                                btnText={t("Addition")}
                                className={"add-item-button"}
                                onClick={handleParticipantIncrement}
                              />
                            )}

                          {/* if there is only one item then we don't show delete button  */}
                          {/* but if there is more than one items then we show delete button */}
                          {values.participants.length > 1 && (
                            <Button
                              shape={"round"}
                              type={"default"}
                              btnText={t("Delete item")}
                              icon={"close-circle-outlined"}
                              className={"button-with-icon delete-item-button"}
                              style={{
                                borderColor: theme.colors.red3,
                                color: theme.colors.red3,
                              }}
                              onClick={() =>
                                handleParticipantDecrement(participant.id)
                              }
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </ParticipantSelContainer>
              </Grid>

              {/* 6. Progress > Related Organization name */}
              <Grid
                background
                labelSpan={6}
                className={"row__header"}
                labelContent={t("Related Organization name")}
              >
                <div className={"flex-y"}>
                  <div className={"flex-x"}>
                    <InputField
                      height={"40px"}
                      maxLength={100}
                      placeholder={"--"}
                      onChange={handleChange}
                      name={"corporate_name_1"}
                      value={values?.corporate_name_1}
                      className={"corporate_name_text_field"}
                    />
                    <InputField
                      height={"40px"}
                      maxLength={100}
                      placeholder={"--"}
                      onChange={handleChange}
                      name={"corporate_person_name_1"}
                      value={values?.corporate_person_name_1}
                    />
                  </div>
                  <div className={"flex-x"}>
                    <InputField
                      height={"40px"}
                      maxLength={100}
                      placeholder={"--"}
                      onChange={handleChange}
                      name={"corporate_name_2"}
                      value={values?.corporate_name_2}
                      className={"corporate_name_text_field"}
                    />
                    <InputField
                      maxLength={100}
                      height={"40px"}
                      placeholder={"--"}
                      onChange={handleChange}
                      name={"corporate_person_name_2"}
                      value={values?.corporate_person_name_2}
                    />
                  </div>
                </div>
              </Grid>

              {/* 7. Progress > Consultation support office name */}
              <Grid
                background
                labelSpan={6}
                className={"row__header"}
                labelContent={t("Consultation support office name ")}
              >
                <div className={"MM_multi-select flex-wrap"}>
                  <div>
                    <SelectInput
                      width={"auto"}
                      minWidth={"78px"}
                      placeholder={"--"}
                      options={allConStaff}
                      loading={loadingAllConStaff}
                      popupMatchSelectWidth={false}
                      disabled={loadingAllConStaff}
                      name={"consultation_support_id"}
                      value={values?.consultation_support_id}
                      onChange={(val) => {
                        setFieldValue("consultation_support_id", val)
                      }}
                    />
                  </div>

                  <div
                    style={{
                      flex: 1,
                      minWidth: "150px",
                    }}
                  >
                    <InputField
                      width={"100%"}
                      height={"40px"}
                      maxLength={100}
                      placeholder={"--"}
                      onChange={handleChange}
                      name={"consultation_support_person_name"}
                      value={values?.consultation_support_person_name}
                    />
                  </div>
                </div>
              </Grid>

              {/* 8. Progress > Free items [incrementable] */}
              {values?.free_items.map((freeItem, index) => {
                return (
                  <Grid
                    key={freeItem.id}
                    labelContent={
                      <>
                        <InputField
                          size={"large"}
                          placeholder={"--"}
                          name={"free-item"}
                          className={"free-items__note"}
                          value={
                            values.free_items[index]?.title
                              ? values.free_items[index]?.title
                              : ""
                          }
                          onChange={(e) =>
                            setFieldValue(
                              `free_items[${index}].title`,
                              e.target.value,
                            )
                          }
                          onBlur={() => {
                            setFieldTouched(`free_items[${index}].title`, true)
                          }}
                          error={
                            touched?.free_items?.[index]?.title &&
                            //@ts-ignore
                            errors?.free_items?.[index]?.title
                              ? //@ts-ignore
                                errors?.free_items?.[index]?.title
                              : null
                          }
                        />
                      </>
                    }
                    background
                    labelSpan={6}
                    className={"row__header free-items__header"}
                  >
                    <MMFreeItemContainer className={"MM_free-item-container"}>
                      <InputAreaField
                        resize
                        rows={4}
                        placeholder={"--"}
                        name={"free-item"}
                        value={
                          values.free_items[index]?.value
                            ? values.free_items[index]?.value
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue(
                            `free_items[${index}].value`,
                            e.target.value,
                          )
                        }
                      />
                      <div className={"free-item-btn-group"}>
                        {
                          // if it is the last item then we show the add button
                          index === values.free_items.length - 1 &&
                            values.free_items.length <= 20 && (
                              <Button
                                shape={"round"}
                                type={"default"}
                                iconType={"add"}
                                btnText={t("Addition")}
                                onClick={handleFreeItemIncrement}
                                className={
                                  "add-item-button free-item-add-button"
                                }
                              />
                            )
                        }
                        {
                          // if there is more than one item then we show delete button
                          values.free_items.length > 1 && (
                            <Button
                              shape={"round"}
                              type={"default"}
                              iconType={"delete"}
                              btnText={t("Delete item")}
                              onClick={() =>
                                handleFreeItemDecrement(freeItem.id)
                              }
                              className={
                                "delete-item-button free-item-delete-button"
                              }
                              style={{
                                borderColor: theme.colors.red3,
                                color: theme.colors.red3,
                              }}
                            />
                          )
                        }
                      </div>
                    </MMFreeItemContainer>
                  </Grid>
                )
              })}

              {/* 9. Progress > Attach file */}
              {values?.attachment_files.map((file, index) => {
                return (
                  <Grid
                    key={file?.id}
                    background
                    labelSpan={6}
                    className={"row__header attach-file__header"}
                    labelContent={
                      t("Attach file") +
                      (values.attachment_files.length > 1
                        ? ` ${index + 1}`
                        : "")
                    }
                  >
                    <div>
                      <FileUploader
                        currentImage={file?.file}
                        fileName={
                          props.formType === "EDIT" &&
                          extractFileName(file.file)
                        }
                        isLoading={(value) => value}
                        onChange={(file) => {
                          setFieldTouched(
                            `attachment_files[${index}].file`,
                            true,
                          )
                          setFieldValue(`attachment_files[${index}].file`, file)
                        }}
                        inputType={"file"}
                      />
                      <ErrorMassage
                        message={
                          touched?.attachment_files?.[index]?.file &&
                          // TODO: Fix this error later
                          //@ts-ignore
                          errors?.attachment_files?.[index]?.file
                            ? //@ts-ignore
                              errors?.attachment_files?.[index]?.file
                            : null
                        }
                      />
                    </div>
                    {
                      // if it is the last item then we show the add button
                      values.attachment_files.length > 1 && (
                        <Button // delete button
                          shape={"round"}
                          type={"default"}
                          iconType={"delete"}
                          btnText={t("Delete item")}
                          className={"delete-item-button"}
                          style={{
                            borderColor: theme.colors.red3,
                            color: theme.colors.red3,
                            marginTop: "10px",
                          }}
                          onClick={() => handleAttachmentFileDecrement(file.id)}
                        />
                      )
                    }
                  </Grid>
                )
              })}
            </div>

            <div className={"add-file-btn"}>
              <Button
                size={"large"}
                shape={"round"}
                type={"default"}
                iconType={"add"}
                btnText={t("Add File")}
                onClick={handleAttachmentFileIncrement}
                className={"increment-file-button elevate"}
                disabled={values?.attachment_files.length < 4 ? false : true}
              />
            </div>

            <div className={"form__control-action-btns"}>
              <Button
                size={"large"}
                shape={"round"}
                type={"default"}
                btnText={t("Cancel")}
                className={"cancel-button"}
                onClick={() =>
                  push("/various-additions-management-of-meeting-minutes")
                }
              />
              <Button
                size={"large"}
                shape={"round"}
                type={"primary"}
                btnText={t("Save ")}
                onClick={handleSubmit}
                className={"save-button"}
                isLoading={
                  props.formType === "EDIT" ? props.updating : props.registering
                }
              />
              {/* save as draft */}
              <Button
                size={"large"}
                shape={"round"}
                btnText={t("Save as Draft")}
                className={"save-as-draft-button elevate"}
                onClick={handleSubmitAsDraft}
                isLoading={
                  props.formType === "EDIT" ? props.updating : props.registering
                }
              />
            </div>
          </MMStyledForm>
        </Card>
      </Spin>
    </div>
  )
}
