import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BreakTitle } from "../StaffAttendanceComponent.styles"
import {
  InputField,
  RadioGroup,
  STAFF_ABSENT,
  SelectInput,
  allowOnlyNumber,
  theme,
} from "@project/common"
import { CALANDER_HOURS_MINUTES } from "@project/common/src/constants/settings"

const WorkInformation2Wrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .attendance-information {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
    .label {
      padding: 20px;
      background-color: rgb(243, 243, 243);
      width: 25%;
      text-transform: capitalize;
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border-radius: 5px;
        padding: 10px;
      }

      .label_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .required {
          border-radius: 10px;
          width: 40px;
          height: 24px;
          background-color: ${theme.colors.error};
          color: ${theme.colors.white};
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .value {
      padding: 20px;
      @media screen and (max-width: 540px) {
        border: none !important;
        padding: 10px 0px 10px 0px;
      }
    }
    .break_time_content {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .attendance-information:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
  .value_content {
    display: flex;
    gap: 20px;
    align-items: center;
    .ant-radio-group {
      @media screen and (max-width: 540px) {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
      }
    }
    .first_select,
    .second_select {
      display: flex;
      gap: 10px;
      align-items: center;
      .ant-select {
        @media screen and (max-width: 767px) {
          width: 98px !important;
        }
        @media screen and (max-width: 280px) {
          width: 70px !important;
        }
      }
    }
  }
`
interface IProps {
  formik: any
}
const WorkInformation2: React.FC<IProps> = ({ formik }) => {
  const { t } = useTranslation()
  const ATTENDANCE_DATA = [
    {
      label: t("Attend"),
      value: 1,
    },
    {
      label: t("Absence"),
      value: 2,
    },
  ]
  return (
    <>
      <BreakTitle>
        <h2 className={"break_line"}>
          {t(`Work information {{number}}`, {
            number: "2",
          })}
        </h2>
      </BreakTitle>
      <WorkInformation2Wrapper>
        <table>
          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Attendance")}</td>
            <td className={"value"}>
              <div className={"value_content"}>
                <RadioGroup
                  options={ATTENDANCE_DATA}
                  value={formik.values.attendance_type2}
                  name={"meal_provision_flag"}
                  onChange={(e) => {
                    formik.setFieldValue("attendance_type2", e.target.value)
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className={"attendance-information"}>
            <td className={"label"}>
              <div className={"label_content"}>
                <p>{t("In time")}</p>
              </div>
            </td>
            <td className={"value"}>
              <div className={"value_content"}>
                <div className={"first_select"}>
                  <SelectInput
                    width={"96px"}
                    name={"user-name"}
                    options={CALANDER_HOURS_MINUTES.hours}
                    placeholder={""}
                    value={
                      formik?.values?.attendance_start_time2?.split(":")[0] ||
                      null
                    }
                    onChange={(val) =>
                      formik.setFieldValue(
                        "attendance_start_time2",
                        `${val}:${
                          formik?.values?.attendance_start_time2?.split(
                            ":",
                          )[1] || "00"
                        }`,
                      )
                    }
                    disabled={
                      !formik.values.attendance_type2 ||
                      formik.values.attendance_type2 == STAFF_ABSENT
                    }
                  />
                  <span>{t("時")}</span>
                </div>
                <div className={"second_select"}>
                  <SelectInput
                    width={"96px"}
                    name={"user-name"}
                    options={CALANDER_HOURS_MINUTES.minutes}
                    placeholder={""}
                    value={
                      formik?.values?.attendance_start_time2?.split(":")[1] ||
                      null
                    }
                    onChange={(val) =>
                      formik.setFieldValue(
                        "attendance_start_time2",
                        `${formik?.values?.attendance_start_time2?.split(
                          ":",
                        )[0]}:${val}`,
                      )
                    }
                    disabled={
                      !formik.values.attendance_type2 ||
                      formik.values.attendance_type2 == STAFF_ABSENT
                    }
                  />
                  <span>{t("分")}</span>
                </div>
              </div>
            </td>
          </tr>

          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Out time")}</td>
            <td className={"value"}>
              <div className={"value_content"}>
                <div className={"first_select"}>
                  <SelectInput
                    width={"96px"}
                    name={"user-name"}
                    options={CALANDER_HOURS_MINUTES.hours}
                    placeholder={""}
                    value={
                      formik?.values?.attendance_end_time2?.split(":")[0] ||
                      null
                    }
                    onChange={(val) =>
                      formik.setFieldValue(
                        "attendance_end_time2",
                        `${val}:${
                          formik?.values?.attendance_end_time2?.split(":")[1] ||
                          "00"
                        }`,
                      )
                    }
                    disabled={
                      !formik.values.attendance_type2 ||
                      formik.values.attendance_type2 == STAFF_ABSENT
                    }
                  />
                  <span>{t("時")}</span>
                </div>
                <div className={"second_select"}>
                  <SelectInput
                    width={"96px"}
                    name={"user-name"}
                    options={CALANDER_HOURS_MINUTES.minutes}
                    placeholder={""}
                    value={
                      formik?.values?.attendance_end_time2?.split(":")[1] ||
                      null
                    }
                    onChange={(val) =>
                      formik.setFieldValue(
                        "attendance_end_time2",
                        `${formik?.values?.attendance_end_time2?.split(
                          ":",
                        )[0]}:${val}`,
                      )
                    }
                    disabled={
                      !formik.values.attendance_type2 ||
                      formik.values.attendance_type2 == STAFF_ABSENT
                    }
                  />
                  <span>{t("分")}</span>
                </div>
              </div>
            </td>
          </tr>

          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Break time")}</td>
            <td className={"value"}>
              <div className={"break_time_content"}>
                <div>
                  <InputField
                    name={"hr"}
                    id={"hr"}
                    width={"100px"}
                    placeholder={""}
                    padding={"8px"}
                    borderRadius={"4px"}
                    value={formik.values.attendance_rest_minits2}
                    onChange={(e) => {
                      const value = allowOnlyNumber(e.target.value)
                      formik.setFieldValue("attendance_rest_minits2", value)
                    }}
                  />
                </div>
                <span>{t("分")}</span>
              </div>
            </td>
          </tr>
        </table>
      </WorkInformation2Wrapper>
    </>
  )
}

export { WorkInformation2 }
