import {
  AccordionCard,
  ActionBtns,
  Button,
  Card,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  LinkButton,
  OperationSearchContent,
  StyledColGap,
  Table,
  getLocalstorageItem,
  scrollToSelectedElement,
  setItemToLocalstorage,
  useUpdateSearchParams,
} from "@project/common"
import { useRouter } from "next/router"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import { PAGE_SIZE } from "../../constants"
import { useQuery } from "react-query"
import { fetchAllShiftMaster } from "../../services/shiftMaster"

const ShiftMasterContent = ({
  facilities = [],
  hasWriteAccess = false,
}: {
  facilities: Array<any>
  hasWriteAccess: boolean
}) => {
  const { t } = useTranslation()

  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { facilityIds, page } = router?.query as any
  const listRef = useRef<HTMLDivElement>(null)
  const storeData = getLocalstorageItem("shift_master_key")
  const defaultFacilities =
    facilityIds?.split(",") || storeData?.facility_ids?.split(",") || []

  const [checkedFacility, setCheckedFacility] = useState(
    defaultFacilities || [],
  )

  const [shiftMasterQueryParams, setShiftMasterQueryParams] = useState({
    page: +page || 1,
    facilityIds: defaultFacilities,
  })
  const buildWorkingHours = (times: any): string => {
    return (
      `${times.start_time}~${times.end_time}` +
      (times.start_time2.length > 2 && times.end_time2.length > 2
        ? `, ${times.start_time2}~${times.end_time2}`
        : ` `)
    )
  }
  //fetch all shift master
  const {
    isLoading: isTableLoading,
    isFetching: isFetching,
    data: shiftMasterTableList,
  } = useQuery<any, Error>({
    queryKey: ["shift_master_list", shiftMasterQueryParams],
    queryFn: () =>
      fetchAllShiftMaster({
        page: shiftMasterQueryParams?.page,
        pageSize: PAGE_SIZE,
        facilityIds: shiftMasterQueryParams.facilityIds,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) => {
      const dataArr = response?.data?.map((data) => {
        return {
          key: data.id,
          id: data.id,
          edit: data.id,
          shift_hour_name: data.attendance_shift_name,
          shift_display_name: data.attendance_shift_display_name,
          attendance_shift_facilities: data?.attendance_shift_facilities,
          using_facility: "---",
          working_hours: buildWorkingHours({
            start_time: data.attendance_start_time,
            end_time: data.attendance_end_time,
            start_time2: data.attendance_start_time_2,
            end_time2: data.attendance_end_time_2,
          }),
          remarks: data.remarks,
          break_settings: data?.use_rest_time_flg || false,
          number_of_employees_include_flg:
            data?.number_of_employees_include_flg,
        }
      })
      const data = {
        data: dataArr,
        count: response?.count,
      }
      return data
    },
  })

  const columns: ColumnsType<any[]> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 70,
      render: (_, row: any) => {
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() => {
              router.push(`/shift-master/edit/${row.id}`)
            }}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Shift hour name"),
      dataIndex: "shift_hour_name",
      align: "center",
      key: "shift_hour_name",
      width: 180,
    },
    {
      title: <span className={"col-title"}>{t("Shift display name")}</span>,
      dataIndex: "shift_display_name",
      key: "shift_display_name",
      align: "center",
      width: 140,
    },

    {
      title: <span className={"col-title"}>{t("Using facility")}</span>,
      dataIndex: "attendance_shift_facilities",
      key: "attendance_shift_facilities",
      align: "center",
      width: 180,
      render: (row: any) => {
        return row?.map((item: any, index) => {
          const dd = facilities.find(
            (facility: any) => facility.id == item.facility_id,
          )
          const comma =
            (dd?.facility_name_short || dd?.facility_name) &&
            index !== row?.length - 1
              ? ","
              : ""
          return `${dd?.facility_name_short || dd?.facility_name || ""}` + comma
        })
      },
    },
    {
      title: <span className={"col-title"}>{t("Working hours")}</span>,
      dataIndex: "working_hours",
      align: "center",
      key: "working_hours",
      width: 180,
      render: (row: any, _: any) => {
        return _?.number_of_employees_include_flg === 0 ? (
          <span>{row}</span>
        ) : (
          "-"
        )
      },
    },
    {
      title: t("Remarks"),
      align: "center",
      dataIndex: "remarks",
      key: "remarks",
      width: 180,
    },
    {
      title: <span className={"col-title"}>{t("Break settings")}</span>,
      dataIndex: "break_settings",
      key: "break_settings",
      align: "center",
      width: 110,
      render: (break_settings) => {
        return break_settings ? <span>{"〇"}</span> : "-"
      },
    },
  ]

  //func call when search/reset search btn clicked
  const handleOperationAction = (action: "search" | "reset") => {
    if (action === "search") {
      setShiftMasterQueryParams((prev) => {
        return {
          ...prev,
          page: 1,
          facilityIds: checkedFacility,
        }
      })
      updateParams(
        {
          facilityIds: checkedFacility?.join(","),
        },
        "/shift-master",
      )
      setItemToLocalstorage("shift_master_key", {
        facility_ids: checkedFacility?.toString() || null,
      })
    }
    if (action === "reset") {
      setShiftMasterQueryParams((prev) => {
        return {
          ...prev,
          page: 1,
          targetIds: [],
          facilityIds: [],
        }
      })
      setCheckedFacility([])
      updateParams({}, "/shift-master")
      setItemToLocalstorage("shift_master_key", {
        facility_ids: null,
      })
    }
    scrollToSelectedElement(listRef)
  }

  return (
    <StyledColGap>
      <AccordionCard
        defaultActiveKey={["shift-master"]}
        items={[
          {
            key: "shift-master",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <div className={"mt-sm "}>
                    <LabelTextWithLeftArrow
                      label={t("Facility name")}
                      width={"136px"}
                    />
                  </div>
                  <FacilityCheckbox
                    options={facilities || []}
                    value={checkedFacility}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilities?.map((v) => v?.value)
                        setCheckedFacility(ids)
                      }
                      if (type === "uncheck") {
                        setCheckedFacility([])
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setCheckedFacility(list)
                    }}
                  />
                </div>

                <ActionBtns justify={"flex-start"}>
                  <Button
                    btnText={t("Reset search")}
                    shape={"round"}
                    onClick={() => handleOperationAction("reset")}
                  />
                  <Button
                    btnText={t("Search")}
                    iconType={"search"}
                    type={"primary"}
                    shape={"round"}
                    onClick={() => handleOperationAction("search")}
                  />
                </ActionBtns>
              </OperationSearchContent>
            ),
          },
        ]}
      />
      <div ref={listRef}>
        <Card title={t("List")}>
          <Table
            columns={columns}
            dataSource={shiftMasterTableList?.data}
            showPaginationOf={"both"}
            current={shiftMasterQueryParams?.page}
            total={shiftMasterTableList?.count}
            pageSize={PAGE_SIZE}
            onChange={(val) => {
              updateParams(
                {
                  page: val,
                  facilityIds: checkedFacility?.join(","),
                },
                "/shift-master",
              )
              setShiftMasterQueryParams((prev) => {
                return {
                  ...prev,
                  page: val,
                }
              })
            }}
            loading={isFetching || isTableLoading}
            scroll={{ x: "max-content" }}
          />
        </Card>
      </div>
    </StyledColGap>
  )
}

export { ShiftMasterContent }
