import {
  AUTO_EMAIL_SENDING_OPTIONS,
  ActionBtns,
  Button,
  Card,
  Grid,
  InputAreaField,
  InputField,
  scrollToFirstErrorField,
} from "@project/common"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import {
  AutoEmailSendingFormField,
  AutoEmailSendingFormSchema,
  AutoEmailSendingResponse,
} from "../../../types"
import { useRouter } from "next/router"
import styled from "styled-components"
interface AutoEmailSendingFormProps {
  defaultValues?: AutoEmailSendingResponse | any
  onSubmit: (values: AutoEmailSendingFormField) => void
  isLoading: boolean
}

const Wrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`

const AutoEmailSendingForm = ({
  defaultValues,
  onSubmit,
  isLoading,
}: AutoEmailSendingFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const initialValues = {
    mail_type: null,
    mail_address: "",
    mail_sender: "",
    mail_subject: "",
    mail_body: "",
    mail_body_add: "",
    mail_footer: "",
  }
  const {
    values,
    handleSubmit,
    errors,
    submitCount,
    isValid,
    touched,
    handleBlur,
    handleChange,
  } = useFormik<AutoEmailSendingFormField>({
    initialValues: defaultValues || initialValues,
    validationSchema: AutoEmailSendingFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  const mailTypeOption = AUTO_EMAIL_SENDING_OPTIONS.find(
    (item) => item.value === values.mail_type,
  )

  return (
    <Card title={t("Add form")}>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div>
            <Grid
              labelContent={t("Form title")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <span>{t(mailTypeOption.label)}</span>
            </Grid>
            <Grid
              labelContent={t("Sender email")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputField
                name={"mail_address"}
                id={"mail_address"}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.mail_address}
                error={
                  touched?.mail_address && errors?.mail_address
                    ? errors?.mail_address
                    : ""
                }
                info={t("(Within 50 char)")}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Sender name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputField
                name={"mail_sender"}
                id={"mail_sender"}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.mail_sender}
                error={
                  touched?.mail_sender && errors?.mail_sender
                    ? errors?.mail_sender
                    : ""
                }
                info={t("(Within 60 char)")}
                maxLength={60}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Subject")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputField
                name={"mail_subject"}
                id={"mail_subject"}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.mail_subject}
                error={
                  touched?.mail_subject && errors?.mail_subject
                    ? errors?.mail_subject
                    : ""
                }
                info={t("(Within 60 char)")}
                maxLength={60}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Email Body")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputAreaField
                name={"mail_body"}
                id={"mail_body"}
                padding={"8px"}
                rows={3.5}
                borderRadius={"4px"}
                value={values?.mail_body}
                placeholder={"--"}
                error={
                  touched?.mail_body && errors?.mail_body
                    ? errors?.mail_body
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("End of email body")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"mail_body_add"}
                id={"mail_body_add"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={"例：〇〇時間に入室しました"}
                value={values?.mail_body_add}
                error={
                  touched?.mail_body_add && errors?.mail_body_add
                    ? errors?.mail_body_add
                    : ""
                }
                width={"400px"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Email footer")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputAreaField
                name={"mail_footer"}
                id={"mail_footer"}
                padding={"8px"}
                borderRadius={"4px"}
                rows={3.5}
                placeholder={"--"}
                value={values?.mail_footer}
                error={
                  touched?.mail_footer && errors?.mail_footer
                    ? errors?.mail_footer
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </div>
          <ActionBtns justify={"flex-start"}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={() => router.push("/auto-email-sending")}
              disabled={isLoading}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              type={"primary"}
              htmlType={"submit"}
              isLoading={isLoading}
            />
          </ActionBtns>
        </form>
      </Wrapper>
    </Card>
  )
}

export { AutoEmailSendingForm }
