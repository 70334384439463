import { theme } from "@project/common"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-form-item {
    margin-bottom: 0px !important;
  }
`
export const DataListWrapper = styled.div`
  padding: 24px 30px;
  border: 1px solid ${theme.colors.red};
  color: ${theme.colors.info};
  border-radius: 12px;
  margin-bottom: 16px;
  .content {
    .img-title {
      display: flex;
      align-items: center;
      gap: 8px;
      .title {
        font-size: 18px;
        font-weight: 700;
        color: ${theme.colors.error};
      }
    }
    .sub-title {
      font-size: 16px;
      font-weight: 400;
      color: ${theme.colors.error};
    }
  }
`
