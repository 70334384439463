import React, { useRef, useState } from "react"
import { MonthlyUseStatusChartTable } from "./MonthlyUseStatusChartTable/MonthlyUseStatusChartTable"
import { Flex } from "antd"
import { MonthlyUseStatusChartOperation } from "./MonthlyUseStatusChartOperation"
import { scrollToSelectedElement, useUpdateSearchParams } from "@project/common"
import { MonthlyUseStatusChartOperationType } from "../../types/monthlyUseStatusChart.types"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useAuthContext } from "../../context"

export const MonthlyUseStatusChart = (): JSX.Element => {
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { year, facility, service } = router?.query as any
  const { facilities } = useAuthContext()

  const contentRef = useRef<HTMLDivElement>(null)
  const [params, setParams] = useState<{
    facility: any
    year: any

    service: any[]
  }>({
    facility: facility || facilities[0]?.id.toString(),
    year: year ? year : dayjs().year(),

    service: service ? service?.split(",")?.map((v) => +v) : [1, 2, 3],
  })

  return (
    <Flex vertical gap={16}>
      <MonthlyUseStatusChartOperation
        params={params}
        setParams={(values: MonthlyUseStatusChartOperationType) => {
          setParams(values)

          updateParams(
            {
              ...values,
              year: values?.year,

              service: values?.service?.toString(),
            },
            "/monthly-use-status-chart",
          )
          scrollToSelectedElement(contentRef)
        }}
      />
      <div ref={contentRef}>
        <MonthlyUseStatusChartTable params={params} />
      </div>
    </Flex>
  )
}
