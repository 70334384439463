import { theme } from "@project/common"
import styled from "styled-components"
export const PlanFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const FormInputContainer = styled.div`
  .user__name_selector,
  .create_count {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
    span {
      white-space: nowrap;
      font-size: 14px;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  }
`
export const PlanGoalContainer = styled.div`
  .pickup_container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .content {
      display: flex;
      align-items: center;
      row-gap: 8px;
      column-gap: 8px;
      flex-wrap: wrap;
      .opts {
        display: flex;
        align-items: center;
        row-gap: 8px;
        column-gap: 16px;
      }
    }
    .place__input {
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        width: 100%;
      }
    }
  }
`
