import styled from "styled-components"
import { Button, theme } from "@project/common"

export const RemoveBtn = styled(Button)`
  color: ${theme.colors.error};
  span {
    text-decoration: underline;
  }
`
export const TitleWithBorder = styled.h3`
  width: 100%;
  min-height: 36px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding-bottom: 4px;
  line-height: 23.17px;
  border-bottom: 1px solid ${theme.colors.border};
`
