import React from "react"
import { useRouter } from "next/router"

// packages
import _ from "lodash"
import cuid from "cuid"
import * as Yup from "yup"
import { t } from "i18next"
import { useFormik } from "formik"
import { ColumnsType } from "antd/es/table"
import { useMutation, useQuery } from "react-query"
import { Button as AntButton, Flex, Space } from "antd"

// commons | components
import {
  Box,
  omit,
  Grid,
  Table,
  Button,
  SelectInput,
  filterObject,
  useNotification,
  dynamicLangString,
  useUpdateSearchParams,
} from "@project/common"
import { AccountSubjectCell } from "../../common/AccountSubjectCell"

// styles
import { FacilityWorkTableWrapper } from "../styles"

// services
import { getFacilityWorkData, mutateFacilityWork } from "../../../../services"

// utils
import { sanitizeWorkValues } from "../utils/sanitizeWorkValues"
import { generateFacilityWork } from "../utils/generateFacilityWork"
import { calculateExpenseAttribs } from "../utils/calculatePossibleAttibs"
import { getDynamicFacilityWorkColumns } from "../utils/getDynamicFacilityWorkCols"

// assets
import BlueAddIcon from "../../../../public/assets/icons/BlueAddIcon"

// contexts
import { useAuthContext } from "../../../../context"

// types
import {
  FacilityWorkTableType,
  ProjectCostQueryParam,
  StaticManufacturingCostDataType as StaticColumnsType,
} from "../../../../types/projectCostManagement.types"

// constants
import { staticRowsData } from "../constants"

export const WorkEditInternal = ({
  memoizedQuery,
}: {
  memoizedQuery: ProjectCostQueryParam
}) => {
  const { query } = useRouter()

  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useAuthContext()

  const { showToast } = useNotification()

  //cancel

  function onCancel() {
    updateParams(
      omit(filterObject({ ...query, current_content: "COST_MGMT" }), [
        "facility_id",
      ]),
      "/wages-revenue",
    )
  }
  // ! ## API Query
  const {
    data: facilityWork,
    isLoading: loadingFacilityWork,
    refetch,
  } = useQuery({
    queryKey: ["facility-work", memoizedQuery.facility_id, memoizedQuery.date],
    queryFn: () =>
      getFacilityWorkData({
        facility_id: +memoizedQuery.facility_id,
        month: memoizedQuery.date.month() + 1,
        year: memoizedQuery.date.year(),
      }),
    onError: () =>
      showToast({
        message: t("Couldn't get facility work data. Try again later."),
        type: "error",
      }),
  })

  const { mutate: updateFacilityWork, isLoading: updatingFacilityWork } =
    useMutation({
      mutationKey: [
        "mutate-facility-work",
        memoizedQuery.facility_id,
        memoizedQuery.date,
      ],
      mutationFn: (payload: Partial<FacilityWorkTableType>[]) =>
        mutateFacilityWork(
          {
            facility_id: +memoizedQuery.facility_id,
            month: memoizedQuery.date.month() + 1,
            year: memoizedQuery.date.year(),
          },
          payload,
        ),
      onSuccess: () => {
        showToast({
          message: dynamicLangString([t("Updated Successfully")]),
          type: "success",
        })
        refetch()
        onCancel()
      },

      onError: () =>
        showToast({
          message: t("Something went wrong. Try again later."),
          type: "error",
        }),
    })

  // ! ## Formik form handlers
  const formik = useFormik({
    initialValues: {
      facilityWorkData: facilityWork?.data,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      facilityWorkData: Yup.array().of(
        Yup.object().shape({
          work_title: Yup.string().required(t("Required")),
        }),
      ),
    }),
    onSubmit: (val) => {
      const sanitizedValues = sanitizeWorkValues(val.facilityWorkData)
      updateFacilityWork(sanitizedValues)
    },
  })

  const { values, setFieldValue, handleSubmit } = formik

  /**
   * `calcFacilityWork` consists of work facility data with all calculated attributes such as : `expenses`, `Total | grand_total` etc.
   */
  const calcFacilityWork = React.useMemo(
    () => calculateExpenseAttribs(values.facilityWorkData),
    [values.facilityWorkData, facilityWork],
  )

  // ! ## All Event handlers
  // 1. This function adds new columns on call.
  function handleColAddition() {
    const uniqueKey = cuid()
    const newWork = [
      ...values.facilityWorkData,
      generateFacilityWork(uniqueKey),
    ]
    setFieldValue(`facilityWorkData`, newWork)
  }

  // 2. This function removes specific columns on call.
  function handleColRemove(id: number | string) {
    const newWorks = [...values.facilityWorkData].filter(
      (item) => item.id !== id,
    )

    setFieldValue(`facilityWorkData`, newWorks)
  }

  // ! ## Antd columns
  const columns: ColumnsType<StaticColumnsType> = [
    {
      title: t("Accounting Subject"),
      dataIndex: "subject",
      key: "subject",
      render: (val) => <AccountSubjectCell label={val} />,
      width: 300,
      fixed: "left",
    },

    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
      className: "total-cell",
      render: (__, rec) => {
        if (rec.isCategory) {
          return null
        }

        const key = rec.key
        const total: number = _.sumBy(calcFacilityWork, (item) => item[key])

        return total?.toLocaleString()
      },
    },

    ...getDynamicFacilityWorkColumns(formik, handleColRemove, calcFacilityWork),

    {
      title: () => (
        <Flex justify={"center"}>
          <AntButton
            type={"text"}
            icon={<BlueAddIcon />}
            size={"small"}
            onClick={() => handleColAddition()}
          ></AntButton>
        </Flex>
      ),
      width: 36,
      key: "add",
      dataIndex: "add",
      className: "add-columns-col",
      onCell: (_, index) => ({
        colSpan: 1,
        rowSpan: index === 0 ? 41 : 0,
      }),
    },
  ]

  return (
    <Box pt={24}>
      <Space size={12}>
        <Button
          shape={"round"}
          disabled={updatingFacilityWork || loadingFacilityWork}
          btnText={t("Cancel")}
          type={"outlined"}
          onClick={() => onCancel()}
        />
        <Button
          isLoading={updatingFacilityWork}
          disabled={loadingFacilityWork}
          shape={"round"}
          btnText={t("Save")}
          type={"primary"}
          onClick={() => handleSubmit()}
        />
      </Space>

      <Box mt={24}>
        <Grid labelContent={t("Corporate name")} background labelSpan={6}>
          <p>{t("Corporate name")}</p>
        </Grid>
        <Grid labelContent={t("Facility name")} background labelSpan={6}>
          <SelectInput
            options={facilities}
            name={"facility_id"}
            disabled={updatingFacilityWork}
            size={"large"}
            value={query.facility_id}
            onChange={(id) =>
              updateParams({ ...query, facility_id: id }, "/wages-revenue")
            }
          />
        </Grid>
      </Box>

      <FacilityWorkTableWrapper my={24}>
        <Table
          loading={loadingFacilityWork || updatingFacilityWork}
          columns={columns}
          dataSource={staticRowsData}
          rowClassName={(rec) =>
            rec.isCategory ? "category_row" : "regular_row"
          }
          scroll={{
            x: "max-content",
          }}
        />
      </FacilityWorkTableWrapper>

      <Space size={12}>
        <Button
          shape={"round"}
          btnText={t("Cancel")}
          type={"outlined"}
          onClick={() => onCancel()}
        />
        <Button
          isLoading={updatingFacilityWork}
          disabled={loadingFacilityWork}
          shape={"round"}
          btnText={t("Save")}
          type={"primary"}
          onClick={() => handleSubmit()}
        />
      </Space>
    </Box>
  )
}
