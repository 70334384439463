import {
  AccordionCard,
  ActionBtns,
  Button,
  Card,
  CheckBoxGroup,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  LinkButton,
  OperationSearchContent,
  StyledColGap,
  Table,
  scrollToSelectedElement,
  setItemToLocalstorage,
  useUpdateSearchParams,
  EMPLOYMENT_STYLE,
} from "@project/common"
import { useRouter } from "next/router"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import type { ColumnsType } from "antd/es/table"
import { PAGE_SIZE } from "../../constants"
import { useQuery } from "react-query"
import { fetchAllShiftUserMaster } from "../../services/shiftUserMaster"

const ShiftUserMasterContent = ({
  hasWriteAccess = false,
  facilities = [],
}: {
  hasWriteAccess: boolean
  facilities: Array<any>
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { facilityIds, page, target } = router?.query as any
  const listRef = useRef<HTMLDivElement>(null)
  const defaultFacilities = facilityIds?.split(",") || []
  const defaultTargets = target?.split(",") || []
  const [checkedFacility, setCheckedFacility] = useState(
    defaultFacilities || [],
  )

  const [employmentType, setEmploymentType] = useState(defaultTargets || [])
  const [shiftUserMasterQueryParams, setShiftUserMasterQueryParams] = useState({
    page: +page || 1,
    facilityIds: defaultFacilities,
    employment_type: employmentType,
  })

  const buildWorkingHours = (times: any): string => {
    return `${times.start_time}~${times.end_time}`
  }
  //fetch all shift user master
  const {
    isLoading: isTableLoading,
    isFetching: isFetching,
    data: shiftUserMasterTableList,
  } = useQuery<any, Error>({
    queryKey: ["shift_user_master_list", shiftUserMasterQueryParams],
    queryFn: () =>
      fetchAllShiftUserMaster({
        page: shiftUserMasterQueryParams?.page,
        pageSize: PAGE_SIZE,
        employment_type_id: shiftUserMasterQueryParams.employment_type,
        facilityIds: shiftUserMasterQueryParams.facilityIds,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) => {
      const dataArr = response?.data?.map((data) => {
        return {
          key: data.id,
          id: data.id,
          edit: data.id,
          attendance_rest_mins: data?.attendance_rest_mins,
          employment_type_id: data.employment_type_id,
          attendance_shift_name: data.attendance_shift_name,
          attendance_user_shift_facilities:
            data?.attendance_user_shift_facilities,
          working_hours: buildWorkingHours({
            start_time: data.attendance_start_time,
            end_time: data.attendance_end_time,
          }),
          remarks: data.remarks,
          is_deleted: data?.is_deleted,
        }
      })
      const data = {
        data: dataArr,
        count: response?.count,
      }
      return data
    },
  })

  const columns: ColumnsType<any[]> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 70,
      render: (_, row: any) => {
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() => {
              router.push(`/shift-user-master/edit/${row.id}`)
            }}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Shift hour name"),
      dataIndex: "attendance_shift_name",
      align: "center",
      key: "attendance_shift_name",
      width: 180,
    },
    {
      title: <span className={"col-title"}>{t("Using_facility")}</span>,
      dataIndex: "attendance_user_shift_facilities",
      key: "attendance_user_shift_facilities",
      align: "center",
      width: 140,
      render: (row: any) => {
        return row?.map((item: any, index) => {
          const dd = facilities.find(
            (facility: any) => facility.id == item.facility_id,
          )
          const comma =
            (dd?.facility_name_short || dd?.facility_name) &&
            index !== row?.length - 1
              ? ","
              : ""
          return `${dd?.facility_name_short || dd?.facility_name || ""}` + comma
        })
      },
    },
    {
      title: <span className={"col-title"}>{t("Employment style")}</span>,
      dataIndex: "employment_type_id",
      key: "employment_type_id",
      align: "center",
      render: (row: any) => {
        const data = EMPLOYMENT_STYLE.find((d) => d?.value == row)
        return (
          <>
            <span>{data?.label}</span>
          </>
        )
      },
    },

    {
      title: <span className={"col-title"}>{t("Working hours")}</span>,
      dataIndex: "working_hours",
      align: "center",
      key: "working_hours",
      width: 180,
    },
    {
      title: t("Remarks"),
      align: "center",
      dataIndex: "remarks",
      key: "remarks",
      width: 180,
    },
  ]

  const handleOperationAction = (action: "search" | "reset") => {
    if (action === "search") {
      setShiftUserMasterQueryParams((prev) => {
        return {
          ...prev,
          page: 1,
          employment_type: employmentType,
          facilityIds: checkedFacility,
        }
      })
      updateParams(
        {
          facilityIds: checkedFacility?.join(","),
          employment_type: employmentType?.join(","),
        },
        "/shift-user-master",
      )
      setItemToLocalstorage("shift_user_master_key", {
        facility_ids: checkedFacility?.toString() || null,
        employment_type: employmentType?.toString() || null,
      })
    }
    if (action === "reset") {
      setShiftUserMasterQueryParams((prev) => {
        return {
          ...prev,
          page: 1,
          employment_type: [],
          facilityIds: [],
        }
      })
      setCheckedFacility([])
      setEmploymentType([])
      updateParams({}, "/shift-user-master")
    }
    scrollToSelectedElement(listRef)
  }

  return (
    <StyledColGap>
      <AccordionCard
        defaultActiveKey={["shift-user-master"]}
        items={[
          {
            key: "shift-user-master",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <div className={"mt-sm "}>
                    <LabelTextWithLeftArrow
                      label={t("Facility_name")}
                      width={"136px"}
                    />
                  </div>
                  <FacilityCheckbox
                    options={facilities || []}
                    value={checkedFacility}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilities?.map((v) => v?.value)
                        setCheckedFacility(ids)
                      }
                      if (type === "uncheck") {
                        setCheckedFacility([])
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setCheckedFacility(list)
                    }}
                  />
                </div>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Employment style")}
                    width={"136px"}
                  />
                  <CheckBoxGroup
                    items={EMPLOYMENT_STYLE}
                    value={employmentType}
                    onChange={(list: CheckboxValueType[]) => {
                      setEmploymentType(list)
                    }}
                  />
                </div>
                <ActionBtns justify={"flex-start"}>
                  <Button
                    btnText={t("Reset search")}
                    shape={"round"}
                    onClick={() => handleOperationAction("reset")}
                  />
                  <Button
                    btnText={t("Search")}
                    iconType={"search"}
                    type={"primary"}
                    shape={"round"}
                    onClick={() => handleOperationAction("search")}
                  />
                </ActionBtns>
              </OperationSearchContent>
            ),
          },
        ]}
      />
      <div ref={listRef}>
        <Card title={t("List")}>
          <Table
            columns={columns}
            dataSource={shiftUserMasterTableList?.data}
            showPaginationOf={"both"}
            current={shiftUserMasterQueryParams?.page}
            total={shiftUserMasterTableList?.count}
            pageSize={PAGE_SIZE}
            onChange={(val) => {
              updateParams(
                {
                  page: val,
                  facilityIds: checkedFacility?.join(","),
                  employment_type: employmentType?.join(","),
                },
                "/shift-user-master",
              )
              setShiftUserMasterQueryParams((prev) => {
                return {
                  ...prev,
                  page: val,
                }
              })
            }}
            loading={isFetching || isTableLoading}
            scroll={{ x: "max-content" }}
          />
        </Card>
      </div>
    </StyledColGap>
  )
}

export { ShiftUserMasterContent }
