import { ColumnsType } from "antd/lib/table"
import { actualCostItem, ActualExpensesRec } from "./getDailyListings"

export const generateDynamicExpenses = (
  dailyActualExpenses: ActualExpensesRec,
): ColumnsType<any> => {
  return dailyActualExpenses.allActualCostIds.map((item) => ({
    title: item.title,
    dataIndex: item.id,
    key: item.id,
    align: "center",
    render: (_, rec) => (rec[item.id] as actualCostItem)?.price,
  }))
}
