import React from "react"
import dayjs, { Dayjs } from "dayjs"
import { Flex, notification } from "antd"
import { useMutation } from "react-query"
import { StyledContent } from "./StaffMonthlyAttendanceRecordContent.style"
import { Button, Calendar, STAFF_ABSENT } from "@project/common"
import { useTranslation } from "react-i18next"
import { CellContent } from "./CellContent"
import {
  STAFF_ATTENDANCE_DATA,
  STAFF_CHANGE_VALUES,
} from "../../../types/StaffAttendance.types"

import { createOrUpdateAttendanceStaffResult } from "../../../services"
import { useRouter } from "next/router"

interface StaffMonthlyProps {
  data?: STAFF_ATTENDANCE_DATA
  handleDataChange?: (values: STAFF_CHANGE_VALUES) => void
  addAndRemoveWorkingHours?: (action: "A" | "R", dataOf: string) => void
  params?: any
}

export const StaffMonthlyAttendanceRecordContent = ({
  data,
  handleDataChange,
  addAndRemoveWorkingHours,
  params,
}: StaffMonthlyProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility, year, month } = router.query as any
  //create/update attendance record data
  const { isLoading, mutate: postAttendanceStaffResult } = useMutation({
    mutationFn: createOrUpdateAttendanceStaffResult,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })
      router.push(
        `/attendance-record?facility=${facility}&year=${year}&month=${month}`,
      )
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  return (
    <StyledContent>
      <div>
        <Button
          btnText={t("Print")}
          iconType={"business-print"}
          shape={"round"}
        />
      </div>
      <Calendar
        value={params?.date || dayjs()}
        cellRender={(date: Dayjs) => {
          const dateOf = date?.date()
          const currentData: any = data ? data[`${dateOf}`] : []
          const showCalendarContent =
            currentData?.[0]?.id || currentData?.[0]?.is_scheduled
              ? true
              : false
          return {
            isHoliday: currentData?.[0]?.holiday_flag === "1" ? true : false,
            content: showCalendarContent ? (
              <CellContent
                data={currentData}
                handleDataChange={handleDataChange}
                dateOf={`${dateOf}`}
                addAndRemoveWorkingHours={addAndRemoveWorkingHours}
              />
            ) : (
              <div />
            ),
          }
        }}
      />
      <Flex gap={16} wrap={"wrap"}>
        <Button btnText={t("Cancel")} shape={"round"} disabled={isLoading} />
        <Button
          btnText={t("Save")}
          shape={"round"}
          type={"primary"}
          isLoading={isLoading}
          onClick={() => {
            const calendarData = { ...data }
            const payload = []
            Object.values(calendarData)?.map((value) => {
              let obj = {}
              value?.map((v) => {
                obj = {
                  ...v,
                  attendance_rest_minits: +v?.attendance_rest_minits || null,
                  rest_hours: +v?.rest_hours || +value[0]?.rest_hours || null,
                  attendance_start_time2:
                    v?.attendance_type === STAFF_ABSENT
                      ? ""
                      : v?.attendance_start_time2,
                  attendance_end_time2:
                    v?.attendance_type === STAFF_ABSENT
                      ? ""
                      : v?.attendance_end_time2,
                  attendance_rest_minits2:
                    v?.attendance_type === STAFF_ABSENT
                      ? null
                      : +v?.attendance_rest_minits2 || null,
                  attendance_type2:
                    v?.attendance_type === STAFF_ABSENT
                      ? null
                      : v?.attendance_type2,
                  rest_minits_flg:
                    v?.rest_minits_flg || +value[0]?.rest_minits_flg || false,
                  date: v?.date
                    ? dayjs(v?.date).format("YYYY-MM-DD")
                    : dayjs(value[0]?.date).format("YYYY-MM-DD"),
                }
              })
              payload.push(obj)
            })
            const finalPayload = payload?.filter(
              (data) => data?.id || data?.is_scheduled,
            )
            postAttendanceStaffResult(finalPayload)
          }}
        />
      </Flex>
    </StyledContent>
  )
}
