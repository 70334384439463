import { I_EMPLOYMENT_BUSINESS_STATEMENT } from "../../../types"

export const EMPLOYMENT_BUSINESS_STATEMENT_INITIAL_DATA: I_EMPLOYMENT_BUSINESS_STATEMENT =
  {
    term_war_material_inventory: 0,
    fine_year_material_purchase_height: 0,
    involved_in_terms_of_term: 0,
    user_wage: 0,
    user_fee: 0,
    salary_of_employment_support_business_instructors: 0,
    instructor_bonus_reward: 0,
    instructor_retirement_benefit_cost: 0,
    legal_welfare_expenses: 0,
    former_outsourcing_cost: 0,
    welfare_expense: 0,
    travel_expense: 0,
    equipment_cost: 0,
    supplies_expense: 0,
    printed_book_expense: 0,
    water_supply_expense: 0,
    fuel_cost: 0,
    repair_cost: 0,
    communication_transportation_cost: 0,
    order_activity_expense: 0,
    meeting_expense: 0,
    non_life_insurance_premium: 0,
    rent: 0,
    books_and_educational_expenses: 0,
    taxation_public_division: 0,
    depreciation_expense: 0,
    special_reserve_depreciation: 0,
    uncollectible_reserves: 0,
    uncollectible_amount: 0,
    miscellaneous_expenses: 0,
    expense: 0,
    manufacturing_cost: 0,
    table_based_product_wholesaler: 0,
    terminated_goods_wholesaler: 0,
  }
export const EMPLOYMENT_SUPPORT_STATEMENT_TAB_ROWS = [
  {
    key: "",
    title: "Material cost",
    sn: "I",
  },
  {
    key: "term_war_material_inventory",
    title: "Term War material inventory",
    sn: "1",
  },
  {
    key: "fine_year_material_purchase_height",
    title: "Fine -year material purchase height",
    sn: "2",
  },
  {
    key: "material_cost_meter_total",
    title: "Meter",
    sn: "",
  },
  {
    key: "involved_in_terms_of_term",
    title: "Involved in terms of term at the end of the term",
    sn: "3",
  },
  {
    key: "material_cost_total",
    title: "Form material cost",
    sn: "",
  },
  {
    key: "",
    title: "Labor expenses",
    sn: "Ⅱ",
  },
  {
    key: "user_wage",
    title: "User Wage",
    sn: "1",
  },
  {
    key: "user_fee",
    title: "User Fee",
    sn: "2",
  },
  {
    key: "salary_of_employment_support_business_instructors",
    title: "Salary of employment support business instructors",
    sn: "3",
  },
  {
    key: "instructor_bonus_reward",
    title: "Employment support business instructor, bonus bonus rewards, etc.",
    sn: "4",
  },
  {
    key: "instructor_retirement_benefit_cost",
    title: "Employment support business instructor, retirement benefit cost",
    sn: "5",
  },
  {
    key: "legal_welfare_expenses",
    title: "Legal welfare expenses",
    sn: "5",
  },
  {
    key: "labor_expenses_total",
    title: "Former labor cost",
    sn: "",
  },
  {
    key: "",
    title: "Outsourcing cost (Of which internal outsourcing costs)",
    sn: "Ⅲ",
  },
  {
    key: "former_outsourcing_cost",
    title: "Former outsourcing cost",
    sn: "1",
  },
  {
    key: "",
    title: "expenses",
    sn: "Ⅳ",
  },

  {
    key: "welfare_expense",
    title: "Welfare expense",
    sn: "1",
  },
  {
    key: "travel_expense",
    title: "travel expenses",
    sn: "2",
  },
  {
    key: "equipment_cost",
    title: "Equipment costs",
    sn: "3",
  },
  {
    key: "supplies_expense",
    title: "supplies expense",
    sn: "4",
  },
  {
    key: "printed_book_expense",
    title: "Printed book expenses",
    sn: "5",
  },
  {
    key: "water_supply_expense",
    title: "Water supply expenses",
    sn: "6",
  },
  {
    key: "fuel_cost",
    title: "Fuel cost",
    sn: "7",
  },
  {
    key: "repair_cost",
    title: "repair costs",
    sn: "8",
  },
  {
    key: "communication_transportation_cost",
    title: "Communication transportation cost",
    sn: "9",
  },
  {
    key: "order_activity_expense",
    title: "Order activity expenses",
    sn: "10",
  },
  {
    key: "meeting_expense",
    title: "Meeting expenses",
    sn: "11",
  },
  {
    key: "non_life_insurance_premium",
    title: "Non -life insurance premium",
    sn: "12",
  },
  {
    key: "rent",
    title: "Rent",
    sn: "13",
  },
  {
    key: "books_and_educational_expenses",
    title: "Books and educational expenses",
    sn: "14",
  },
  {
    key: "taxation_public_division",
    title: "Taxation Public Division",
    sn: "15",
  },
  {
    key: "depreciation_expense",
    title: "Depreciation expenses",
    sn: "16",
  },

  {
    key: "special_reserve_depreciation",
    title:
      "Special reserve deprecation such as national treasury subsidies (deduction items)",
    sn: "17",
  },
  {
    key: "uncollectible_reserves",
    title: "Unable to collect unable to pay",
    sn: "18",
  },
  {
    key: "uncollectible_amount",
    title: "Unable to collect",
    sn: "19",
  },
  {
    key: "miscellaneous_expenses",
    title: "Miscellaneous expenses",
    sn: "20",
  },
  {
    key: "expenses_total",
    title: "Expense",
    sn: "",
  },
  {
    key: "manufacturing_cost",
    title: "Total project expenses for employment support",
    sn: "",
  },
  {
    key: "table_based_product_wholesaler",
    title: "Term Table -based product wholesaler",
    sn: "",
  },
  {
    key: "grand_total",
    title: "total",
    sn: "",
  },
  {
    key: "terminated_goods_wholesaler",
    title: "Terminated goods wholesaler",
    sn: "",
  },
  {
    key: "project_expenses_total",
    title: "Employment support project expenses",
    sn: "",
  },
]
