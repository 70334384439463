import { keys } from "@project/common"
import {
  UsedFacilityDataRecord,
  UsedFacilityRecordData,
} from "../../../types/nhif.types"

export const formatToUsedFacilityDatatype = (
  response?: UsedFacilityRecordData,
): UsedFacilityDataRecord => {
  if (!response.data) {
    return {}
  }

  const data = response.data?.city_wise
  return keys(data).reduce((curr, key) => {
    return {
      ...curr,
      [key]: data[key].map((item) => {
        return {
          ...item,
          id: item.user_id,
          key: item.user_id,
        }
      }),
    }
  }, {})
}
