import React from "react"

// packages
import _ from "lodash"
import { t } from "i18next"
import { Table } from "antd"

// components | common
import { FACILITY_SERVICE_TYPES, keys } from "@project/common"

import {
  BulletTitleStyled,
  InvoiceTableStyled,
  PaymentAddressTable,
  InlinePairTextStyled,
  ServiceTypeTableStyled,
} from "../styles"

// types
import {
  ActualCostData,
  InvoiceBreakDown,
} from "../../../../../../types/nhif.types"

// utils
import {
  actualCostItem,
  AllActualCostIds,
} from "../../../../utils/getDailyListings"

// types
interface InlinePairTextProps {
  boldText: string
  lightText: string
}

interface ServiceTypeTableProps {
  serviceType: number
  totalCost: number
  userBurdenAmt: number
}

interface BulletTitleProps {
  children: React.ReactNode
}

interface PaymentAddressProps {
  facilityName: string
  accountNumber: number
  bankNumber: number
  branchNumber: number
  holderName: string
}

interface InvoiceTableProps {
  changeDisabilityText: boolean
  displayBillAmt: boolean
  decidedUserFee: number
  subsidyClaim: number
  actualCost: ActualCostData
}

export const InlinePairText = ({
  boldText,
  lightText,
}: InlinePairTextProps) => {
  return (
    <InlinePairTextStyled className={"inline-pair-text"}>
      <span className={"bold"}>{t(boldText)}</span>
      <span className={"light"}>{t(lightText)}</span>
    </InlinePairTextStyled>
  )
}

const reFormatForInvoiceTable = (actualCost: ActualCostData) => {
  const groupedByCostIdList = _.groupBy(
    actualCost,
    ({ actual_cost_id }) => actual_cost_id,
  )
  return keys(groupedByCostIdList).reduce(
    (acc, key) => {
      return [
        ...acc,
        {
          name: groupedByCostIdList[key]?.[0].name,
          totalSum: _.sumBy(groupedByCostIdList[key], "price"),
        },
      ]
    },
    [] as { name: string; totalSum: number }[],
  )
}

export const InvoiceTable = ({
  displayBillAmt,
  decidedUserFee,
  subsidyClaim,
  actualCost,
  changeDisabilityText,
}: InvoiceTableProps) => {
  const calculatedActualCost = reFormatForInvoiceTable(actualCost)

  const benefitsForDisabledChild = decidedUserFee - subsidyClaim

  const total =
    _.sumBy(calculatedActualCost, "totalSum") + benefitsForDisabledChild

  return (
    <InvoiceTableStyled className={"invoice-table"}>
      <table>
        <thead className={"invoice-table-thead"}>
          <tr>
            <th className={"invoice-table-cell"}>{t("Items")}</th>
            <th className={"invoice-table-cell"}>{t("Amount")}</th>
          </tr>
        </thead>

        {/* Display the decided user burden amount and local government subsidy amount billed. */}
        {displayBillAmt && (
          <tbody className={"invoice-table-tbody extra-bill-listing-tbody"}>
            <tr>
              <td className={"label invoice-table-cell"}>
                {t("Decided user fee")}
              </td>
              <td className={"value invoice-table-cell"}>{decidedUserFee}</td>
            </tr>
            <tr>
              <td className={"label invoice-table-cell"}>
                {t("Local government subsidy claims")}
              </td>
              <td className={"value invoice-table-cell"}>
                {"-"}
                {subsidyClaim}
              </td>
            </tr>
          </tbody>
        )}

        <tbody className={"invoice-table-tbody"}>
          <tr>
            <td className={"label invoice-table-cell"}>
              {t("{{disabilityTxt}}　利用者負担額", {
                disabilityTxt: changeDisabilityText
                  ? "障がい児通所給付費"
                  : "障害児通所給付費",
              })}
            </td>
            <td className={"value invoice-table-cell"}>
              {benefitsForDisabledChild}
            </td>
          </tr>
          {calculatedActualCost.map((item) => {
            return (
              <tr key={item.name}>
                <td className={"label invoice-table-cell"}>{t(item.name)}</td>
                <td className={"value invoice-table-cell"}>
                  {item.totalSum.toLocaleString()}
                </td>
              </tr>
            )
          })}
        </tbody>

        <tfoot className={"invoice-table-tfoot"}>
          <tr>
            <td className={"label invoice-table-cell"}>{t("Total")}</td>
            <td className={"value invoice-table-cell"}>
              {"¥" + total.toLocaleString()}
            </td>
          </tr>
        </tfoot>
      </table>
    </InvoiceTableStyled>
  )
}

export const ServiceTypeTable = ({
  serviceType,
  totalCost,
  userBurdenAmt,
}: ServiceTypeTableProps) => {
  return (
    <ServiceTypeTableStyled>
      <table>
        <thead className={"service-type-table-thead"}>
          <tr>
            <th className={"service-type-table-cell"}>{t("Service type")}</th>
            <th className={"service-type-table-cell"}>{t("Total cost")}</th>
            <th className={"service-type-table-cell"}>
              {t("Determined user burden amount")}
            </th>
          </tr>
        </thead>
        <tbody className={"service-type-table-tbody"}>
          <tr>
            <td className={"label service-type-table-cell"}>
              {t(FACILITY_SERVICE_TYPES[serviceType])}
            </td>
            <td className={"value service-type-table-cell"}>
              {totalCost?.toLocaleString()}
            </td>
            <td className={"value service-type-table-cell"}>
              {userBurdenAmt?.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </ServiceTypeTableStyled>
  )
}

export const BulletTitle = ({ children }: BulletTitleProps) => (
  <BulletTitleStyled display={"flex"} align={"center"}>
    <div className={"title"}>
      <span>{"■"}</span>
      <span>{children}</span>
    </div>
  </BulletTitleStyled>
)

export const PaymentAddress = ({
  facilityName,
  accountNumber,
  bankNumber,
  branchNumber,
  holderName,
}: PaymentAddressProps) => {
  return (
    <PaymentAddressTable>
      <table className={"payment-address-table"}>
        <tr>
          <th className={"payment-address-table-cell label"}>
            {t("Financial institute / Account Number")}
          </th>
          <td className={"payment-address-table-cell value"}>
            {`${facilityName} / ${accountNumber}`}
          </td>
        </tr>
        <tr>
          <th className={"payment-address-table-cell label"}>
            {t("Bank number")}
          </th>
          <td className={"payment-address-table-cell label"}>{bankNumber}</td>
        </tr>
        <tr>
          <th className={"payment-address-table-cell label"}>
            {t("Branch number")}
          </th>
          <td className={"payment-address-table-cell label"}>{branchNumber}</td>
        </tr>
        <tr>
          <th className={"payment-address-table-cell label"}>
            {t("Account holder name")}
          </th>
          <td className={"payment-address-table-cell label"}>{holderName}</td>
        </tr>
      </table>
    </PaymentAddressTable>
  )
}

export const ActualCostTotalSummaryFooter = ({
  actualCostIds,
  dataSource,
}: {
  actualCostIds: AllActualCostIds[]
  dataSource: readonly Partial<InvoiceBreakDown>[]
}) => {
  return actualCostIds?.map((item, idx) => {
    let total = 0

    dataSource.forEach((exp) => {
      if ((exp[item.id] as actualCostItem)?.price) {
        total += (exp[item.id] as actualCostItem)?.price
      }
    })

    return (
      <Table.Summary.Cell index={2} key={idx} align={"center"}>
        {total}
      </Table.Summary.Cell>
    )
  })
}
