import { FACILITY_SERVICE_TYPES_V2, getFullEraDate } from "@project/common"
import { Typography } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { calculateSubTotal, generateRows } from "./DUOBPUtilsFn"
import {
  ActualTableContent,
  AddressTitle,
  BoxTitle,
  CityBillingCompanyContent,
  ContentBox,
  DateTitle,
  ReiwaBillingAmountContent,
} from "./DisabledUserOutpatientBenefitsPrintContent.style"
import { ReiwaDateTable } from "./ReiwaDateTable"

import styled from "styled-components"

const BillingAddressWrapper = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 50px;
`

export const BillTableContent = ({
  data,
}: {
  loading: boolean
  data: any
}): JSX.Element => {
  const { t } = useTranslation()

  const subTotalData = calculateSubTotal(data?.Data || [])
  return (
    <ContentBox>
      <BoxTitle>
        <h5>{t("Bill for nursing care benefits, training benefits, etc.")}</h5>
      </BoxTitle>
      <DateTitle align={"right"}>
        {getFullEraDate(dayjs().year(), 11)}
        {dayjs().format("MM月DD日")}
      </DateTitle>
      <CityBillingCompanyContent>
        <BillingAddressWrapper>
          <Typography.Text className={"citys"}>
            {t("(Billing address)")}
          </Typography.Text>

          <Typography.Text className={"city"}>
            {t("Dear {{name}}", {
              name: data?.CityName,
            })}
          </Typography.Text>
        </BillingAddressWrapper>

        <ActualTableContent>
          <tbody id={"duobp__table-body"}>
            <tr>
              <td rowSpan={5} className={"w-20"}>
                <span id={"rotate-190"}> {t("Billing company")}</span>
              </td>
              <td className={"align-center h-30"}>
                {t("Designated office number")}
              </td>
              <td>{data?.office_number || ""}</td>
            </tr>
            <tr>
              <td className={"align-center h-30"}>{t("Address(location)")}</td>
              <td>
                {data?.PostCode || ""}
                <br />
                {data?.Address}
              </td>
            </tr>
            <tr>
              <td className={"align-center h-30"}>{t("Telephone Number")}</td>
              <td>{data?.TelNo || ""}</td>
            </tr>
            <tr>
              <td className={"align-center h-30"}>{t("Official name")}</td>
              <td>{data?.FacilityName || ""}</td>
            </tr>
            <tr>
              <td className={"align-center h-30"}>{t("Job/Name")}</td>
              <td>{`${data?.FacilityInvoiceTitle || ""} ${
                data?.FacilityInvoiceName || ""
              }`}</td>
            </tr>
          </tbody>
        </ActualTableContent>
      </CityBillingCompanyContent>
      <div>
        <AddressTitle>{t("We will charge you as follows.")}</AddressTitle>
        <ReiwaDateTable
          year={data?.Year || dayjs().year()}
          month={data?.Month || dayjs().month() + 1}
        />
        <ReiwaBillingAmountContent>
          <ActualTableContent>
            <tbody id={"duobp__table-body"}>
              <tr>
                <td>{t("Billing amount")}</td>
                <td>{(+data?.InvoiceAmount || 0)?.toLocaleString() ?? ""}</td>
              </tr>
            </tbody>
          </ActualTableContent>
        </ReiwaBillingAmountContent>
        <ActualTableContent>
          <thead id={"duobp__table-head"}>
            <tr>
              <th className={"align-center w-200"} colSpan={2}>
                {t("Classification")}
              </th>
              <th className={"align-center h-30"}>{t("Number")}</th>
              <th className={"align-center h-30"}>{t("Number of Units")}</th>
              <th className={"align-center h-30"}>{t("Total Cost")}</th>
              <th className={"align-center h-30"}>
                {t("Benefits Billed Amount")}
              </th>
              <th className={"align-center h-30"}>{t("User Fee")}</th>
              <th className={"align-center h-30"}>
                {t("Local Government Subsidy Amount")}
              </th>
            </tr>
          </thead>
          <tbody id={"duobp__table-body"}>
            {generateRows(5, [])?.map((row, index) => (
              <tr key={index}>
                {index === 0 && (
                  <td className={"align-center h-30"} rowSpan={5}>
                    <span id={"rotate-190"}>
                      {t("Nursing care benefit expenses")}
                    </span>
                  </td>
                )}
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
              </tr>
            ))}

            {generateRows(4, data?.Data || []).map((row, index) => (
              <tr key={index}>
                {index === 0 && (
                  <td className={"align-center h-30"} rowSpan={4}>
                    <span id={"rotate-190"}>
                      {t("Training, etc. benefit expenses")}
                    </span>
                  </td>
                )}
                <td className={"align-center h-30"}>
                  {t(FACILITY_SERVICE_TYPES_V2[row.ServiceType]) || ""}
                </td>
                <td className={"align-center h-30"}>{row?.Number || ""}</td>
                <td className={"align-center h-30"}>
                  {index === 0
                    ? row?.Unit?.toLocaleString() ?? ""
                    : row?.Unit?.toLocaleString()}
                </td>
                <td className={"align-center h-30"}>
                  {index === 0
                    ? row?.Amount?.toLocaleString() ?? ""
                    : row?.Amount?.toLocaleString()}
                </td>
                <td className={"align-center h-30"}>
                  {index === 0
                    ? row?.ClaimedAmountOfBenefit?.toLocaleString() ?? ""
                    : ""}
                </td>
                <td className={"align-center h-30"}>
                  {index === 0 ? row?.UserBurden?.toLocaleString() ?? "" : ""}
                </td>
                <td className={"align-center h-30"}>
                  {index === 0 ? row?.Subsidy?.toLocaleString() ?? "" : ""}
                </td>
              </tr>
            ))}

            {generateRows(2, [])?.map((row, index) => (
              <tr key={index}>
                {index === 0 && (
                  <td className={"align-center h-30"} rowSpan={2}>
                    <span id={"rotate-190"}>
                      {t("Support benefit cost Regional consultation")}
                    </span>
                  </td>
                )}
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
                <td className={"align-center h-30"}>{""}</td>
              </tr>
            ))}
            {/* sub-total row */}
            <tr>
              <td className={"align-center h-30"} colSpan={2}>
                {t("subtotal")}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.Number || ""}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.Unit ? subTotalData?.Unit?.toLocaleString() : ""}
              </td>
              <td className={"align-center h-30"}>
                {" "}
                {subTotalData?.Amount
                  ? subTotalData?.Amount?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.ClaimedAmountOfBenefit
                  ? subTotalData?.ClaimedAmountOfBenefit?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {" "}
                {subTotalData?.UserBurden
                  ? subTotalData?.UserBurden?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {" "}
                {subTotalData?.Subsidy
                  ? subTotalData?.Subsidy?.toLocaleString()
                  : ""}
              </td>
            </tr>
            <tr>
              <td className={"align-center h-30"} colSpan={2}>
                {t("Special benefits for people with specific disabilities")}
              </td>
              <td className={"align-center h-30"}>{""}</td>
              <td className={"align-center h-30"}>{""}</td>
              <td className={"align-center h-30"}>{""}</td>
              <td className={"align-center h-30"}>{""}</td>
              <td className={"align-center h-30"}>{""}</td>
              <td className={"align-center h-30"}>{""}</td>
            </tr>
            {/* //total row */}
            <tr>
              <td className={"align-center h-30"} colSpan={2}>
                {t("total")}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.Number || ""}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.Unit ? subTotalData?.Unit?.toLocaleString() : ""}
              </td>
              <td className={"align-center h-30"}>
                {" "}
                {subTotalData?.Amount
                  ? subTotalData?.Amount?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.ClaimedAmountOfBenefit
                  ? subTotalData?.ClaimedAmountOfBenefit?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {" "}
                {subTotalData?.UserBurden
                  ? subTotalData?.UserBurden?.toLocaleString()
                  : ""}
              </td>
              <td className={"align-center h-30"}>
                {subTotalData?.Subsidy?.toLocaleString() ?? ""}
              </td>
            </tr>
          </tbody>
        </ActualTableContent>
      </div>
    </ContentBox>
  )
}
