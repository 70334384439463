import React from "react"
import { FUCInfo } from "../../../components"
import dayjs from "dayjs"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { theme } from "@project/common"

const SummaryOtherInfo = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.border};
  & > .label {
    flex: 1;
    background-color: ${theme.colors.background};
    padding: 10px;
    border-right: 1px solid ${theme.colors.border};
  }
  & > .value {
    flex: 0.5;
    padding: 10px;
  }
`
export const MonitoringSummary = ({
  summaryData,
}: {
  summaryData: any
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <FUCInfo
        facility_name={summaryData?.facility?.facility_name || ""}
        use_service_id={summaryData?.service_type}
        created_datetime={summaryData?.created_datetime || dayjs()}
      />
      <SummaryOtherInfo>
        <div className={"label"}>{t("Created no. times")}</div>
        <div className={"value"}>
          {summaryData?.create_count} {t("回目")}
        </div>
        <div className={"label"}>{t("Recorder")}</div>
        <div className={"value"}>{summaryData?.staff?.staff_name || ""}</div>
      </SummaryOtherInfo>
    </div>
  )
}
