import dayjs from "dayjs"
import { TFunction } from "i18next"

interface IWorkStyleExcelData {
  type: "schedule" | "record"
  data: any
  year: number | string
  month: number | string
  t: TFunction<"translation", undefined>
}

interface IWorkStyleHead {
  year: number | string
  month: number | string
  t: TFunction<"translation", undefined>
}

interface IWorkStyleShiftHours {
  shifts: Array<any>
  t: TFunction<"translation", undefined>
}

export const PrepareStaffAttendanceWorkStyleExcelData = (
  props: IWorkStyleExcelData,
) => {
  const { type, data, year, month, t } = props
  const dataSource = []
  const holidays = []
  // first staff data
  const staffInfos = data?.staff_infos

  staffInfos?.forEach((staff) => {
    const staffRow: any = {
      occupations: Array.isArray(staff?.occupations)
        ? staff?.occupations
            ?.map((occupation) => {
              if (occupation?.id) {
                return occupation?.occupation_name
              }
            })
            .join(",")
        : "",
      work_style: t(staff?.work_style_type),
      staff_name: staff?.staff_name,
      month_total: staff?.monthly_total,
      average_hours_per_week: staff?.average_hours_per_week,
      qualifications: Array.isArray(staff?.staff_qualifications)
        ? staff?.staff_qualifications?.map((staffqualification, index) =>
            index == 0
              ? staffqualification?.qualification?.qualification_name
              : `, ${staffqualification?.qualification?.qualification_name}`,
          )
        : "",
      continuation_years: staff?.continuation_years
        ? staff?.continuation_years
        : "",
      number_of_people_after_full_time_conversion:
        staff?.number_of_people_after_full_time_conversion,
    }
    if (type == "schedule") {
      // day-wise schedule
      staff?.attendance_schedules?.forEach((daySchedule) => {
        const dateKey = daySchedule?.date?.toString()?.split("T")?.[0]
        staffRow[dateKey] =
          daySchedule?.holiday_flag == "1"
            ? "休"
            : daySchedule?.work_duration || ""

        // keeping track of holidays
        if (daySchedule?.holiday_flag == "1" && !holidays.includes(dateKey)) {
          holidays.push(dateKey)
        }
      })
    } else if (type == "record") {
      // day-wise record
      staff?.attendance_records?.forEach((dayRecord) => {
        const dateKey = dayRecord?.date?.toString()?.split("T")?.[0]
        staffRow[dateKey] =
          dayRecord?.holiday_flag == "1" ? "休" : dayRecord?.work_duration || ""

        // keeping track of holidays
        if (dayRecord?.holiday_flag == "1" && !holidays.includes(dateKey)) {
          holidays.push(dateKey)
        }
      })
    }
    dataSource.push(staffRow)
  })

  // day work duration
  const dayWorkDuration: any = {
    occupations: t("Total work performance (hours)"),
    groupOccupationWorkAndNameColumns: true,
  }

  data?.day_work_duration?.forEach((dayDuration) => {
    const dateKey = dayDuration?.date?.toString()?.split("T")?.[0]
    dayWorkDuration[dateKey] = holidays?.includes(dateKey)
      ? ""
      : dayDuration?.work_duration
  })

  dataSource.push(dayWorkDuration)

  // full time employee row
  const fullTimeEmployeeRow: any = {
    occupations: t(
      "Number of hours a full-time employee should work in the office/facility in a week",
    ),
    groupOccupationWorkAndNameColumns: true,
    groupAllDates: true,
    groupMonthlyTotalAvergaeHoursAndConversionColumns: true,
    groupQualificationsAndContinuationYearColumns: true,
  }

  // value on first day
  const firstDay = dayjs(year + "-" + month + "-01").format("YYYY-MM-MM")
  fullTimeEmployeeRow[firstDay] =
    (data?.week_hours || 40)?.toString() + t("Hrs")
  dataSource.push(fullTimeEmployeeRow)

  // shift row
  const dayShiftRow: any = {
    occupations: t("Service hours"),
    groupOccupationWorkAndNameColumns: true,
    average_hours_per_week: "",
    number_of_people_after_full_time_conversion: "",
  }
  data?.day_shift_info?.forEach((dayShift) => {
    const dateKey = dayShift?.date?.toString()?.split("T")?.[0]
    dayShiftRow[dateKey] = holidays?.includes(dateKey)
      ? ""
      : dayShift?.attendance_shifts
          ?.map((shift) => shift?.attendance_shift_display_name)
          ?.join(",")
  })
  dataSource.push(dayShiftRow)

  // number of users row
  // currently blank
  const usersRow: any = {
    occupations: t("No. of users"),
    groupOccupationWorkAndNameColumns: true,
    average_hours_per_week: "",
    number_of_people_after_full_time_conversion: "",
  }
  dataSource.push(usersRow)

  return dataSource
}

export const ColumnsForStaffAttendanceWorkStyleExcel = (
  props: IWorkStyleHead,
) => {
  const { t, year, month } = props
  const daysInMonth = dayjs(year + "-" + month + "-1").daysInMonth()

  const columnsBeforeDates = [
    {
      title: t("Occupation"),
      key: "occupation",
      dataIndex: "occupations",
      align: "center",
      className: "occupation-column",
      onCell: (obj) => {
        if (obj?.groupOccupationWorkAndNameColumns)
          return {
            colSpan: 3,
          }
        return {}
      },
    },
    {
      title: t("Work style"),
      key: "work_style",
      dataIndex: "work_style",
      align: "center",
      className: "work-style-column",
      onCell: (obj) => {
        if (obj?.groupOccupationWorkAndNameColumns)
          return {
            colSpan: 0,
          }
        return {}
      },
    },
    {
      title: t("Name"),
      key: "name",
      dataIndex: "staff_name",
      className: "staff-name-column",
      align: "center",
      onCell: (obj) => {
        if (obj?.groupOccupationWorkAndNameColumns)
          return {
            colSpan: 0,
          }
        return {}
      },
    },
  ]
  const columnsAfterDates = [
    {
      title: t("Month total"),
      key: "month_total",
      dataIndex: "month_total",
      className: "month-total-column",
      align: "center",
      onCell: (obj) => {
        if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
          return {
            colSpan: 3,
          }
        }
        return {}
      },
    },
    {
      title: t("Average working hours per week"),
      key: "average_hours_per_week",
      dataIndex: "average_hours_per_week",
      align: "center",
      className: "average-hours-column",
      onCell: (obj) => {
        if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Number of people after full-time conversion"),
      key: "number_of_people_after_full_time_conversion",
      dataIndex: "number_of_people_after_full_time_conversion",
      align: "center",
      className: "people-conversion-column",
      onCell: (obj) => {
        if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Presence or absence of qualifications and kinds"),
      key: "qualifications",
      dataIndex: "qualifications",
      align: "center",
      className: "qualifications-column",
      onCell: (obj) => {
        if (obj?.groupQualificationsAndContinuationYearColumns) {
          return {
            colSpan: 2,
          }
        }
        return {}
      },
    },
    {
      title: t("Continuation work number of years"),
      key: "continuation_years",
      dataIndex: "continuation_years",
      align: "center",
      className: "continuation-year-column",
      onCell: (obj) => {
        if (obj?.groupQualificationsAndContinuationYearColumns) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
  ]
  const columnsForWeek = []

  const firstDay = dayjs(year + "-" + month + "-1")

  const getFirstDayOfWeek = () => {
    const fDay = firstDay.day()
    // because starting from monday
    if (fDay == 0) return 6
    return fDay - 1
  }

  const howManyDaysOnFirstWeek = () => {
    return 7 - getFirstDayOfWeek()
  }

  const getDay = (day: number) => {
    switch (day) {
      case 0:
        return t("Mon")
      case 1:
        return t("Tue")
      case 2:
        return t("Wed")
      case 3:
        return t("Thu")
      case 4:
        return t("Fri")
      case 5:
        return t("Sat")
      case 6:
        return t("Sun")
      default:
        return ""
    }
  }

  const maxWeeks = Math.ceil((daysInMonth - howManyDaysOnFirstWeek()) / 7 + 1)
  const numberOfWeeks = Array.from({ length: maxWeeks }, (_, i) => i + 1)
  let daysAccounted = 0
  numberOfWeeks.forEach((weekNo) => {
    const currentDayOfWeek = weekNo == 1 ? getFirstDayOfWeek() : 0
    const weekColumn = {
      title: t("Week") + weekNo,
      className: "week-header",
      children: [],
    }
    for (let dayOfWeek = currentDayOfWeek; dayOfWeek < 7; dayOfWeek++) {
      if (daysAccounted >= daysInMonth) break
      daysAccounted++
      const thisDate = dayjs(year + "-" + month + "-" + daysAccounted).format(
        "YYYY-MM-DD",
      )
      const weekDayColumn = {
        title: daysAccounted?.toString(),
        align: "center",
        children: [
          {
            title: getDay(dayOfWeek)?.toString(),
            align: "center",
            dataIndex: thisDate,
            className: "each-date-day",
            onCell: (obj) => {
              if (obj?.groupAllDates) {
                return {
                  colSpan: firstDay?.isSame(thisDate) ? daysInMonth : 0,
                }
              }
              return {}
            },
          },
        ],
      }
      weekColumn.children.push(weekDayColumn)
    }
    columnsForWeek.push(weekColumn)
  })

  return []
    .concat(columnsBeforeDates)
    .concat(columnsForWeek)
    .concat(columnsAfterDates)
}

export const ShiftHoursColumnForStaffAttendanceWorkStyleExcel = ({
  t,
  shifts,
}: IWorkStyleShiftHours) => {
  const combinedShiftInfo = shifts
    .map(
      (shift) =>
        shift?.attendance_shift_display_name +
        " = " +
        shift?.attendance_start_time +
        "〜" +
        shift?.attendance_end_time,
    )
    .join(",")
  return [
    {
      title: t("Shift hours info"),
      dataIndex: "",
    },
    {
      title: combinedShiftInfo,
      dataIndex: "",
    },
  ]
}
