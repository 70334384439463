import Link from "next/link"

// packages
import { t } from "i18next"

// types
import { ColumnsType } from "antd/es/table"
import { SPTableColumns } from "../../../types/serviceRecordProvision"

export const SP_COLUMNS: ColumnsType<SPTableColumns> = [
  {
    title: t("Details"),
    dataIndex: "detailLink",
    key: "id",
    align: "center",
    width: 100,
    render: (text) => {
      return (
        <Link href={text}>
          <a>{t("Detail")}</a>
        </Link>
      )
    },
  },
  {
    title: t("Recipient number"),
    dataIndex: "recipient_number",
    key: "recipient_number",
    align: "center",
    width: 169.25,
  },
  {
    title: t("User name"),
    dataIndex: "user_name",
    key: "user_name",
    align: "center",
    width: 169.25,
  },
  {
    title: t("Benefit payment amount"),
    dataIndex: "benefit_payment_amount",
    key: "benefit_payment_amount",
    align: "center",
    width: 169.25,
    render: (val) => {
      return val ? val.toLocaleString() + "日" : "-"
    },
  },
  {
    title: t("Total contract benefit amount"),
    dataIndex: "total_contract_amount",
    key: "total_contract_amount",
    align: "center",
    width: 169.25,
    render: (val) => (val ? val.toLocaleString() + "日" : "-"),
  },
  {
    title: t("Gender"),
    dataIndex: "gender",
    key: "gender",
    align: "center",
    width: 169.25,
  },
  {
    title: t("Age"),
    dataIndex: "age",
    key: "age",
    align: "center",
    width: 169.25,
    render: (text) => (text ? text + "歳" : "-"),
  },
  {
    title: t("Applicable period"),
    dataIndex: "applicable_period",
    key: "applicable_period",
    align: "center",
    width: 169.25,
  },
]
