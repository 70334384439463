import {
  Breadcrumb,
  Button,
  Card,
  PageTitle,
  Skeleton,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageHead } from "../../../components"
import { MonitoringDetailContent } from "./MonitoringDetailContent"
import { MonitoringDetailOperation } from "./MonitoringDetailOperation"
import { AuthContext } from "../../../context"
import {
  hasPermissionForFacility,
  UserManagementPermission,
} from "../../../utils/PermissionKeys"

export const MonitoringDetail = ({
  monitoring,
  loading,
}: {
  monitoring: any
  loading: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { permissions, isOwner } = useContext(AuthContext)
  const router = useRouter()
  const { id } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const scrollContent = useRef<HTMLDivElement>(null)
  const [operationData, setOperationData] = useState({
    display_content: ["1", "2"],
    consent_date: [],
    parent_sign: [],
  })
  const title =
    t(`{{username}}`, {
      username: monitoring?.user_info?.username,
    }) + t("monitoring details1")

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        UserManagementPermission,
        "write",
        monitoring?.facility_id,
      ),
    [permissions, isOwner, monitoring],
  )

  return (
    <>
      <PageHead>{t("Detail form")}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: (
              <Link href={"/monitoring-list"}>{t("Monitoring List")}</Link>
            ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
        extra={
          <Button
            btnText={t("Edit Monitoring")}
            textColor={theme.colors.text}
            shape={"round"}
            iconType={"blue-pencil"}
            btnBg={"#EAF9FF"}
            disabled={!hasEditAccess}
            onClick={() =>
              router.push(
                `/monitoring-list/edit/${router?.query?.id}?user_id=${monitoring?.user_id}`,
              )
            }
          />
        }
      />
      <MonitoringDetailOperation
        onDisplayChange={(values: any) => {
          setOperationData(values)
          updateParams(
            {
              display_content: values?.display_content?.toString(),
              consent_date: values?.consent_date?.toString(),
              parent_sign: values?.parent_sign?.toString(),
              print_settings: values?.print_settings,
            },
            `/monitoring-list/detail/${id}`,
          )
          scrollToSelectedElement(scrollContent)
        }}
      />
      {loading ? (
        <>
          <br />
          <Card>
            <Skeleton length={5} gutter={20} />
          </Card>
        </>
      ) : (
        <MonitoringDetailContent
          monitoring={monitoring || {}}
          title={title}
          params={operationData}
          scrollContent={scrollContent}
          hasEditAccess={hasEditAccess}
        />
      )}
    </>
  )
}
