import { Card, MonthPagination, Table } from "@project/common"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import Link from "next/link"
import { useContext, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import {
  ColContent,
  TopContent,
  Wrapper,
} from "./BusinessDayManagementListContent.style"

import { AuthContext } from "../../../context/AuthContext"
import { fetchMonthlyBusinessDays } from "../../../services"
import {
  BusinessListPermission,
  hasPermissionForFacility,
} from "../../../utils/PermissionKeys"

dayjs.extend(arraySupport)
export const BusinessDayManagementListContent = () => {
  const { t } = useTranslation()
  const { facilities, isOwner, permissions } = useContext(AuthContext)
  const [year, setYear] = useState(new Date().getFullYear())
  const [dataSources, setDataSources] = useState([])
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["business-list", year],
    queryFn: () => fetchMonthlyBusinessDays(year),
    refetchOnWindowFocus: false,
  })

  const columns: ColumnsType<any[]> = [
    {
      title: t("Month"),
      dataIndex: "month",
      key: "month",
      align: "center",
      width: 40,
      render: (data) => <span style={{ whiteSpace: "nowrap" }}>{data}</span>,
    },
  ]
  const tableHeader = () => {
    facilities?.map(
      (facility) =>
        columns?.push({
          title: (
            <span>
              {facility?.facility_name_short || facility?.facility_name}
            </span>
          ),
          dataIndex: facility?.id.toString(),
          key: facility?.id.toString(),
          render: ({
            isSet,
            facilityId,
            year,
            month,
            facilityName,
            hasEditPermission,
          }) => (
            <>
              {!hasEditPermission ? (
                <ColContent
                  isSet={isSet ? true : false}
                  disabled={!hasEditPermission}
                >
                  {isSet ? t("Set already") : t("No setting")}
                </ColContent>
              ) : (
                <ColContent isSet={isSet} disabled={!hasEditPermission}>
                  <Link
                    passHref
                    href={{
                      pathname: `business-day-management/registration`,
                      query: {
                        facilityId: facilityId,
                        year,
                        month,
                        facility_name: `${facilityName}`,
                        is_set: isSet ? true : false,
                        tab: "bulk_setting_for_month",
                      },
                    }}
                  >
                    <a className={"register__link"}>
                      {isSet ? t("Set already") : t("No setting")}
                    </a>
                  </Link>{" "}
                </ColContent>
              )}
            </>
          ),
        }),
    )
  }
  tableHeader()
  const updateBusinessDaysData = () => {
    // creating for 12 months
    const data = []
    for (let i = 1; i <= 12; i++) {
      const tempData: any = {
        id: i,
        month: t(`${i.toString()}月`),
        print: t("Print this"),
      }

      facilities.map((facility) => {
        const hasEditPermission =
          isOwner ||
          hasPermissionForFacility(
            permissions,
            BusinessListPermission,
            "write",
            facility.id,
          )

        tempData[facility.id.toString()] = {
          facilityId: facility.id,
          year: year,
          month: i,
          facilityName: facility.facility_name_short || facility.facility_name,
          hasEditPermission,
          isSet: response?.data[facility.id]?.find((d) => d?.month === i)
            ?.has_setting,
        }
      })
      data.push(tempData)
    }
    setDataSources(data)
  }
  useEffect(() => {
    updateBusinessDaysData()
  }, [facilities, response])

  return (
    <Wrapper>
      <Card
        title={t("Business day registration list {{year}}", {
          year,
        })}
      >
        <TopContent>
          <p>
            <Trans i18nKey={"businessList"}>
              {
                "For months with no monthly activity settings, activities will be displayed based on the "
              }
              <a href={"/facility-information"} target={"_blank"}>
                {"regular holiday settings"}
              </a>
              {"."}
            </Trans>
            <br />
            {t(
              'When setting, please click the "With setting" or "Without setting" button',
            )}
          </p>
          <MonthPagination
            prevText={t("Previous year")}
            showDate={false}
            currentText={t("This year")}
            nextText={t("Following year")}
            currentMonth={dayjs([year]).month()}
            currentYear={year}
            change={"year"}
            onYearChange={(value) => setYear(value)}
          />
        </TopContent>
        <Table
          columns={columns}
          scroll={{ x: 1200 }}
          dataSource={dataSources}
          cellPaddingBlock={8}
          loading={isLoading || isFetching}
        />
      </Card>
    </Wrapper>
  )
}
