import {
  AccordionCard,
  Breadcrumb,
  Button,
  Card,
  MonthPagination,
  PageTitle,
  Tabs,
} from "@project/common"
import { notification } from "antd"
import { Excel } from "antd-table-saveas-excel"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  AttendanceScheduleOperationOption,
  AttendanceWorkStyleTable,
} from "../../components"
import { AuthContext } from "../../context"
import { getStaffAttendanceMonthlyAttendanceWorkStyle } from "../../services/staffAttendanceRecord"
import { getStaffAttendanceMonthlyScheduleWorkStyle } from "../../services/staffAttendanceSchedule"
import {
  ColumnsForStaffAttendanceWorkStyleExcel,
  PrepareStaffAttendanceWorkStyleExcelData,
  ShiftHoursColumnForStaffAttendanceWorkStyleExcel,
} from "../../utils/staff-attendance"
import {
  AttendanceSchedulePermission,
  hasPermissionForFacility,
  StaffAttendanceRecordPermission,
} from "../../utils/PermissionKeys"
import {
  StaffAttendanceMonthContent,
  WorkStyleActionButtons,
} from "../StaffAttendanceSchedule/staffAttendanceSchedule.style"

const WorkStyleRecord = () => {
  const { t } = useTranslation()
  const WORK_STYLE_LOCAL_STORAGE_KEY = "WORK_STYLE_SEARCH_PARAMS"
  type WORK_STYLE_TABS = "schedule" | "record"

  const router = useRouter()
  const { facility_id, year, month } = router?.query
  const { facilities, isOwner, permissions } = useContext(AuthContext)

  const getInitialSearchParams = () => {
    let initialFacilityId = facility_id?.toString()
    let initialYear = year?.toString()
    let initialMonth = month?.toString()

    const prevSearchParams = localStorage.getItem(WORK_STYLE_LOCAL_STORAGE_KEY)

    if (prevSearchParams) {
      let parsedPrevSearchParams: any = {}
      try {
        parsedPrevSearchParams = JSON.parse(prevSearchParams)
      } catch (e) {
        parsedPrevSearchParams = {}
      }
      if (parsedPrevSearchParams?.facility_id)
        initialFacilityId = parsedPrevSearchParams?.facility_id
      if (parsedPrevSearchParams?.year && !initialYear)
        initialYear = parsedPrevSearchParams?.year
      if (parsedPrevSearchParams?.month && !initialMonth)
        initialMonth = parsedPrevSearchParams?.month
    }

    const isValidDate =
      initialYear &&
      initialMonth &&
      dayjs(initialYear + "-" + initialMonth + "-1").isValid()
    return {
      facility_id: +initialFacilityId || +facilities?.[0]?.id,
      year: isValidDate ? initialYear : dayjs().format("YYYY"),
      month: isValidDate ? initialMonth : dayjs().format("MM"),
    }
  }

  const [searchParams, setSearchParams] = useState(getInitialSearchParams())
  const getInitialActiveTab = (): WORK_STYLE_TABS => {
    let active: WORK_STYLE_TABS = "schedule"
    const queryTab = router?.query?.tab?.toString()
    if (queryTab) {
      if (queryTab == "record") {
        active = "record"
      }
    }
    return active
  }
  const [activeTab, setActiveTab] = useState<WORK_STYLE_TABS>(
    getInitialActiveTab(),
  )

  const [generatingCSV, setIsGeneratingCSV] = useState({
    schedule: false,
    record: false,
  })

  useEffect(() => {
    localStorage.setItem(
      WORK_STYLE_LOCAL_STORAGE_KEY,
      JSON.stringify(searchParams),
    )
    router.push(
      {
        pathname: "/attendance-schedule/work-style",
        query: { ...searchParams, tab: activeTab },
      },
      undefined,
      { shallow: true },
    )
  }, [searchParams])

  const hasAccessForSchedule = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AttendanceSchedulePermission,
        "both",
        searchParams.facility_id,
      ),
    [isOwner, permissions, searchParams],
  )

  const hasAccessForRecord = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        StaffAttendanceRecordPermission,
        "both",
        searchParams.facility_id,
      ),
    [isOwner, permissions, searchParams],
  )

  useEffect(() => {
    if (!hasAccessForSchedule) {
      if (activeTab == "schedule") {
        setActiveTab("record")
        return
      }
    }
    if (!hasAccessForRecord) {
      if (activeTab == "record") {
        setActiveTab("schedule")
      }
    }
  }, [hasAccessForSchedule, hasAccessForRecord])

  const workStyleTitle = useMemo(() => {
    return t("{{facilityName}} {{year}}年{{month}}月's working style table", {
      facilityName: facilities?.find(
        (facility) => facility?.id == searchParams?.facility_id,
      )?.facility_name,
      year: searchParams?.year,
      month: searchParams?.month?.toString()?.padStart(2, "0"),
    })
  }, [searchParams])

  const handleMonthPagination = (_, __, date) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        year: date.year(),
        month: date.month() + 1,
      }
    })
  }

  const breadCrumb = [
    {
      title: <Link href={"/"}>{t("Home")}</Link>,
    },
    {
      title: t("Working style list"),
    },
  ]

  const getDownloadFileName = (downloadType) => {
    const type =
      downloadType == "record" ? t("Record table") : t("Schedule table")
    return `${searchParams.year}年${searchParams.month
      ?.toString()
      ?.padStart(2, "0")}月勤務形態一覧表(${type}).xlsx`
  }

  const getAPIDataForExcel = async (type) => {
    const params = {
      year: searchParams.year,
      month: searchParams.month,
      facility: searchParams.facility_id,
    }
    if (type == "schedule") {
      const { data } = await getStaffAttendanceMonthlyScheduleWorkStyle(params)
      return data
    } else if (type == "record") {
      const { data } =
        await getStaffAttendanceMonthlyAttendanceWorkStyle(params)
      return data
    }
    return {}
  }

  const handleCSVDownload = async (type: "schedule" | "record") => {
    setIsGeneratingCSV((prev) => ({ ...prev, [type]: true }))
    try {
      const apiData = await getAPIDataForExcel(type)
      const columnHeaders = ColumnsForStaffAttendanceWorkStyleExcel({
        year: searchParams.year,
        month: searchParams.month,
        t,
      })
      const dataSource = PrepareStaffAttendanceWorkStyleExcelData({
        year: searchParams.year,
        month: searchParams.month,
        type,
        data: apiData,
        t,
      })

      const excel = new Excel()
      excel.setTHeadStyle({
        fontSize: 10,
        h: "center",
        v: "center",
        background: "FFFFFFFF",
        wrapText: false,
      })
      excel.setTBodyStyle({
        fontSize: 10,
        v: "center",
        h: "center",
        border: true,
      })

      const shitHours = ShiftHoursColumnForStaffAttendanceWorkStyleExcel({
        t,
        shifts: Array.isArray(apiData?.used_schedules)
          ? apiData?.used_schedules
          : [],
      })

      excel
        .addSheet(type == "record" ? t("Record table") : t("Schedule table"))
        .addColumns(columnHeaders)
        .addDataSource(dataSource)
        .addColumns(shitHours)
        .saveAs(getDownloadFileName(type))
    } catch (e) {
      notification.error({
        message: t("Something went wrong. Please contact administrator."),
      })
    } finally {
      setIsGeneratingCSV((prev) => ({ ...prev, [type]: false }))
    }
  }

  const renderWorkStyleActionButtons = () => {
    return (
      <WorkStyleActionButtons>
        <Button
          btnText={t("Output Excel file (scheduled)")}
          iconType={"csv"}
          shape={"round"}
          onClick={() => handleCSVDownload("schedule")}
          isLoading={generatingCSV.schedule}
          disabled={!hasAccessForSchedule}
        />
        <Button
          btnText={t("Output Excel file (record)")}
          iconType={"csv"}
          shape={"round"}
          onClick={() => handleCSVDownload("record")}
          isLoading={generatingCSV.record}
          disabled={!hasAccessForRecord}
        />
        <Button
          btnText={t("Attendance schedule table")}
          iconType={"calendar"}
          shape={"round"}
          onClick={() => {
            router.push(
              `/attendance-schedule?facility_id=${searchParams.facility_id}&year=${searchParams.year}&month=${searchParams.month}`,
            )
          }}
          disabled={!hasAccessForSchedule}
        />
        <Button
          btnText={t("Attendance record table")}
          iconType={"paper"}
          shape={"round"}
          onClick={() => {
            router.push(
              `/attendance-record?facility_id=${searchParams.facility_id}&year=${searchParams.year}&month=${searchParams.month}`,
            )
          }}
          disabled={!hasAccessForRecord}
        />
      </WorkStyleActionButtons>
    )
  }

  return (
    <>
      <Breadcrumb items={breadCrumb} />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Work style table")}
      />
      <StaffAttendanceMonthContent>
        <AccordionCard
          accordion={true}
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <AttendanceScheduleOperationOption
                  initialState={searchParams}
                  handleDisplayChange={setSearchParams}
                  facilityOptions={facilities}
                />
              ),
            },
          ]}
        />
        <Card
          title={workStyleTitle}
          extra={
            <MonthPagination
              showDate={false}
              currentYear={+searchParams?.year}
              currentMonth={+searchParams?.month - 1}
              onMonthChange={handleMonthPagination}
            />
          }
        >
          <>
            {renderWorkStyleActionButtons()}
            <Tabs
              activeKey={activeTab}
              padding={"0"}
              onChange={(activeKey: WORK_STYLE_TABS) => setActiveTab(activeKey)}
              destroyInactiveTabPane={true}
              items={[
                {
                  key: "schedule",
                  label: t("Schedule table"),
                  disabled: !hasAccessForSchedule,
                  children: hasAccessForSchedule && (
                    <AttendanceWorkStyleTable
                      year={searchParams?.year}
                      month={searchParams?.month}
                      facility={searchParams?.facility_id}
                      type={"schedule"}
                      is_work_style={true}
                      hide_work_hours={true}
                    />
                  ),
                },
                {
                  key: "record",
                  label: t("Record table"),
                  disabled: !hasAccessForRecord,
                  children: hasAccessForRecord && (
                    <AttendanceWorkStyleTable
                      year={searchParams?.year}
                      month={searchParams?.month}
                      facility={searchParams?.facility_id}
                      type={"record"}
                      is_work_style={true}
                      hide_work_hours={true}
                    />
                  ),
                },
              ]}
            />
            {renderWorkStyleActionButtons()}
          </>
        </Card>
      </StaffAttendanceMonthContent>
    </>
  )
}

export { WorkStyleRecord }
