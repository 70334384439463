import React, { useContext } from "react"

// packages
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { t } from "i18next"
import { useRouter } from "next/router"
import { useQuery } from "react-query"

// common components/utils
import {
  AccordionCard,
  Button,
  Card,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  InputField,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  StyledColGap,
  Table,
  removeBlankAttributes,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { MMColumns } from "../common/TableColumn"

// styles

// context
import { AuthContext } from "../../../context/AuthContext"

// types
import { TMinutesMgmtOperationContent } from "../../../types/variousAdditionsManagementOfMeetingMinutes.types"

// services
import { getManyMinutesMgmt } from "../../../services/minuteManagement"

// constants
import { ADDITION_NAME_TITLE_OPTIONS } from "@project/common/src/constants/settings"
import { PAGE_SIZE } from "../../../constants"

// ! ## Main component
const MinutesMgmtList = () => {
  const { Text } = Typography

  const { query, push } = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const listRef = React.useRef<HTMLDivElement>(null)
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  // query params
  const facility_ids = query?.facilities?.toString().split(",") || []
  const keyword = query?.keyword?.toString() || ""
  const start_date = query?.start_date?.toString()
    ? dayjs(query?.start_date?.toString()) || null
    : null
  const end_date = query?.end_date?.toString()
    ? dayjs(query?.end_date?.toString()) || null
    : null
  const use_service = query?.use_service?.toString().split(",") || []

  // ## API queries
  const { data: MMLists, isLoading: loadingMMLists } = useQuery(
    ["minutes-management-lists", query],
    {
      queryFn: () =>
        getManyMinutesMgmt({
          facility_ids: query?.facilities?.toString(),
          keyword: query?.keyword?.toString(),
          start_date_from: query?.start_date?.toString(),
          start_date_to: query?.end_date?.toString(),
          use_service: query?.use_service?.toString(),
          pageSize: PAGE_SIZE + "",
          page: query?.page?.toString() || "1",
        }),
      select: (data) => {
        return {
          count: data?.count,
          data: data?.data?.map((item) => {
            const name = ADDITION_NAME_TITLE_OPTIONS?.find(
              (d) => d?.value == item.addition_type,
            )?.label
            return {
              id: item.id + "",
              facility: item.facility_id,
              implementation_date: item.event_date,
              name_or_title: `${name || ""}${
                item.minutes_title ? `/${item.minutes_title}` : ""
              }`,
              status: item.draft_save_flag === true ? "Draft2" : "created",
              use_service: item.service_type,
            }
          }),
        }
      },
    },
  )

  // ## Formik form control
  const formik = useFormik<TMinutesMgmtOperationContent>({
    initialValues: {
      facility_ids,
      keyword,
      start_date,
      end_date,
      use_service: use_service.map((item) => Number(item)),
    },
    onSubmit: (values) => {
      const params = removeBlankAttributes({
        ...values,
        facilities: values.facility_ids?.toString(),
        use_service: values.use_service?.toString(),
        start_date: values.start_date?.format("YYYY-MM-DD"),
        end_date: values.end_date?.format("YYYY-MM-DD"),
        page: query?.page?.toString() || "1",
      })
      updateParams(
        { ...params },
        "/various-additions-management-of-meeting-minutes",
      )
      scrollToSelectedElement(listRef)
    },

    onReset: () => {
      updateParams({}, "/various-additions-management-of-meeting-minutes")
      scrollToSelectedElement(listRef)
    },
  })

  const { setFieldValue, values, handleChange, resetForm, handleSubmit } =
    formik

  // ## event action function
  // 1. Handle pagination change
  const onPaginationChange = (page: number) => {
    updateParams(
      { ...query, page },
      "/various-additions-management-of-meeting-minutes",
    )
    scrollToSelectedElement(listRef)
  }
  // 2. Handle checkAll and uncheckAll

  return (
    <StyledColGap>
      {/* Form operation section starts here */}
      <AccordionCard
        defaultActiveKey={["minutes-management"]}
        items={[
          {
            key: "minutes-management",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility  ")}
                    width={"90px"}
                  />
                  <FacilityCheckbox
                    options={facilitiesCtx || []}
                    onChange={(val) => {
                      setFieldValue("facility_ids", val)
                    }}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilitiesCtx?.map((v) => v?.value)
                        setFieldValue("facility_ids", ids)
                      }
                      if (type === "uncheck") {
                        setFieldValue("facility_ids", [])
                      }
                    }}
                    value={values.facility_ids}
                  />
                </div>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Keyword")}
                    className={"MM_label-with-arrow"}
                    width={"90px"}
                  />
                  <InputField
                    size={"large"}
                    name={"keyword"}
                    value={values.keyword}
                    onChange={handleChange}
                    className={"MM_keyword-input"}
                    placeholder={t("Title or description")}
                  />
                </div>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Event date")}
                    className={"MM_label-with-arrow"}
                    width={"90px"}
                  />
                  <Flex
                    gap={16}
                    align={"center"}
                    wrap={"wrap"}
                    className={"no_row_gap"}
                  >
                    <DatePicker
                      name={"start_date"}
                      date={
                        dayjs(values?.start_date).isValid()
                          ? dayjs(values?.start_date)
                          : null
                      }
                      onDateChange={(val) => {
                        setFieldValue("start_date", val)
                      }}
                      disabledDate={(current) => {
                        return (
                          current &&
                          current > dayjs(values?.end_date).subtract(1, "day")
                        )
                      }}
                    />
                    <div className={"date-range-picker"}>{"~"}</div>
                    <DatePicker
                      name={"end_date"}
                      date={
                        dayjs(values?.end_date).isValid()
                          ? dayjs(values?.end_date)
                          : null
                      }
                      onDateChange={(val) => {
                        setFieldValue("end_date", val)
                      }}
                      disabledDate={(current) => {
                        return (
                          current &&
                          current < dayjs(values?.start_date).add(1, "day")
                        )
                      }}
                    />
                  </Flex>
                </div>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    className={"MM_label-with-arrow"}
                    label={t("Use service")}
                    width={"90px"}
                  />
                  <CheckBoxGroup
                    items={FACILITY_TYPES || []}
                    name={"use_service"}
                    onChange={(val) => {
                      setFieldValue("use_service", val)
                    }}
                    value={values.use_service}
                  />
                </div>
                <div className={"search__content"}>
                  <Button
                    shape={"round"}
                    btnText={t("Reset search")}
                    onClick={() => resetForm()}
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    btnText={t("Search")}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
      {/* Form operation section ends here */}

      {/* MM list starts here */}
      <div ref={listRef}>
        <Card title={t("Various additions/minutes")}>
          <Flex
            gap={"16px"}
            align={"center"}
            style={{
              marginBottom: "20px",
            }}
            wrap={"wrap"}
          >
            <Button
              type={"primary"}
              shape={"round"}
              iconType={"print"}
              btnText={t("Batch printing of displayed records")}
              onClick={() => {
                push(
                  "/various-additions-management-of-meeting-minutes/bulk-print",
                )
              }}
            />

            <Text
              style={{
                fontSize: "14px",
                color: theme.colors["sub-text"],
              }}
            >
              {t(
                "※Please press the left button after narrowing down the items you want to print all at once using the search options.",
              )}
            </Text>
          </Flex>
          <Table
            pageSize={PAGE_SIZE}
            total={MMLists?.count}
            loading={loadingMMLists}
            showPaginationOf={"both"}
            dataSource={MMLists?.data}
            scroll={{ x: "max-content" }}
            columns={MMColumns(facilitiesCtx)}
            current={query?.page ? +query?.page : 1}
            onChange={(val) => onPaginationChange(val)}
          />
        </Card>
      </div>
    </StyledColGap>
  )
}

export { MinutesMgmtList }
