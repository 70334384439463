import React from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Form } from "antd"
import { FormProps } from "antd/lib"

// commons | components
import {
  Box,
  Button,
  DatePicker,
  SelectInput,
  filterObject,
  AccordionCard,
  useUpdateSearchParams,
} from "@project/common"
import { SubsidyBillingAmtList } from "./SubsidyBillingAmtList"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// styles
import { UserFeesContainer } from "./styles"

// contexts
import { useAuthContext } from "../../context"

// types
import { UserFeesOperation } from "./types"

// constants
import { MONTH_DATE_FORMAT } from "../../constants"

export const UserFeesInternal = () => {
  const today = dayjs()
  const { query } = useRouter()

  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()

  const [form] = Form.useForm<UserFeesOperation>()
  const yearMonthValue = Form.useWatch("year_month", form)

  const memoizedQuery: UserFeesOperation = React.useMemo(() => {
    const facility_id = query?.facility_id ? query.facility_id.toString() : null
    const year_month = query?.year_month
      ? query?.year_month?.toString()
      : today.format("YYYY-MM")

    return {
      facility_id,
      year_month,
    }
  }, [query])

  // 1. filter operation form submission event
  const onFinish: FormProps<UserFeesOperation>["onFinish"] = (values) => {
    updateParams(filterObject(values), `user-fees`)
    // TODO: Make api call here
  }

  // 2. reset operation form
  const onReset = () => {
    form.setFieldsValue({
      facility_id: null,
      year_month: today.format("YYYY-MM"),
    })
    updateParams({}, `user-fees`)
  }
  return (
    <UserFeesContainer>
      <AccordionCard
        defaultActiveKey={["user-fees"]}
        items={[
          {
            key: "user-fees",
            label: t("Operation Options"),
            children: (
              <Form
                form={form}
                onFinish={onFinish}
                name={"control-hooks"}
                initialValues={memoizedQuery}
              >
                <Box display={"flex"} direction={"column"} gap={"16px"}>
                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    label={"Facility"}
                    labelWidth={"150px"}
                    align={{ "0px": "flex-start", "550px": "center" }}
                    direction={{
                      "0px": "column",
                      "550px": "row",
                    }}
                  >
                    <Box w={282} maw={282} miw={135} className={"input-field"}>
                      <Form.Item name={"facility_id"} id={"facility_id"}>
                        <SelectInput
                          name={"facility_id"}
                          options={facilities}
                          placeholder={"---"}
                        />
                      </Form.Item>
                    </Box>
                  </OperationFields>

                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    label={"Year Month"}
                    labelWidth={"150px"}
                    align={{ "0px": "flex-start", "550px": "center" }}
                    direction={{
                      "0px": "column",
                      "550px": "row",
                    }}
                  >
                    <Box w={197} miw={197} maw={197}>
                      <Form.Item name={"year_month"} id={"year_month"}>
                        <DatePicker
                          picker={"month"}
                          date={dayjs(yearMonthValue)}
                          onDateChange={(date) =>
                            form.setFieldValue(
                              "year_month",
                              date.format("YYYY-MM"),
                            )
                          }
                          format={MONTH_DATE_FORMAT}
                        />
                      </Form.Item>
                    </Box>
                  </OperationFields>
                </Box>

                <Box display={"flex"} gap={12} mt={16} mb={8}>
                  <Button
                    shape={"round"}
                    btnText={t("Reset Search")}
                    onClick={() => onReset()}
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    btnText={t("Search")}
                    htmlType={"submit"}
                  />
                </Box>
              </Form>
            ),
          },
        ]}
      />

      <SubsidyBillingAmtList memoizedQuery={memoizedQuery} />
    </UserFeesContainer>
  )
}
