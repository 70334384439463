/* stylelint-disable property-no-vendor-prefix */
import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"
export const DUOBPContainer = styled.div`
  border: 1px solid #d2d1d1;
  box-shadow: 4px 4px 16px 0px #3333331f;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`
export const ContentBox = styled.div`
  border: 1px solid #d2d1d1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    min-width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
  @media only print {
    padding: 5px 10px !important;
    row-gap: 8px !important;
  }
`
export const BoxTitle = styled.div`
  text-align: center;
  h5 {
    font-size: 20px;
    color: ${theme.colors.text};
  }
`
export const TableContainer = styled.div`
  .title {
    font-size: 14px;
    text-align: left;
    color: ${theme.colors.text};
    margin-bottom: 4px;
  }
`
export const ActualTableContent = styled.table<{ layout?: string }>`
  border-collapse: collapse;
  width: 100%;
  table-layout: ${({ layout }) => layout || "auto"};
  thead#duobp__table-head {
    tr > th {
      border: 1px solid ${theme.colors.text};
      padding: 5px;
      font-weight: normal !important;
    }
  }
  tbody#duobp__table-body {
    tr {
      td {
        border: 1px solid ${theme.colors.text};
        padding: 5px;
        font-size: 12px !important;
        position: relative;
        min-width: 20px;
        min-height: 20px;
        span#rotate-190 {
          -ms-writing-mode: tb-rl;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          transform: rotate(360deg);
          font-size: 12px !important;
          /* white-space: nowrap; */
        }
      }
      td.b-2 {
        border: 2px solid ${theme.colors.text};
      }
      td.h-50 {
        height: 50px !important;
      }
      td.p-10 {
        padding: 10px !important;
      }
      td.p-20 {
        padding: 20px !important;
      }
      td.w-20 {
        width: 10px !important;
      }
      td.w-50-h-50 {
        width: 10px !important;
        height: 15px !important;
        text-align: center;
      }
      td.w-40 {
        width: 40px !important;
      }
      td.w-50 {
        width: 15px !important;
      }
      td.w-80 {
        width: 80px !important;
      }
      td.w-100 {
        width: 100px !important;
      }
      td.w-150 {
        width: 150px !important;
      }
      td.min-w-185 {
        min-width: 185px !important;
      }
      td.w-200 {
        width: 200px !important;
      }
      td.w-300 {
        width: 300px !important;
      }
      td.w-min-100 {
        min-width: 100px !important;
        @media screen and (max-width: ${theme.breakpoints.md}) {
          min-width: auto !important;
        }
      }

      td.h-30 {
        height: 30px !important;
      }
      td.align-right {
        text-align: right !important;
      }
      td.align-center {
        text-align: center !important;
      }
      td.p-0 {
        padding: 0 !important;
      }
      td.text-no-wrap {
        white-space: nowrap !important;
      }
    }
    @media only print {
      tr {
        td {
          padding: 3px !important;
          font-size: 7pt !important;
          min-width: auto !important;
          min-height: 12px !important;
        }
        td.h-50 {
          height: 20px !important;
        }
        td.p-10 {
          padding: 2px !important;
        }
        td.p-20 {
          padding: 2px !important;
        }

        td.w-50-h-50 {
          height: auto !important;
          text-align: center;
        }

        td.w-50 {
          width: auto !important;
        }
        td.w-80 {
          width: auto !important;
        }
        td.h-30 {
          height: 15px !important;
        }
        td.w-200 {
          width: 150px !important;
        }
        td.min-w-185 {
          min-width: 70px !important;
        }
      }
    }
  }
`

//bill table content style
export const DateTitle = styled.div<{ align?: string }>`
  font-size: 14px;
  color: ${theme.colors.text};
  margin-bottom: 4px;
  text-align: ${({ align }) => align || "left"};
  @media only print {
    font-size: 7pt;
    margin-bottom: 2px;
  }
`

export const AddressTitle = styled.div``
export const CityBillingCompanyContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .city {
    flex: 0.6;
  }
  table {
    flex: 1;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ReiwaBillingAmountContent = styled.div`
  width: 300px;
  margin-bottom: 5px;
  @media only print {
    margin-bottom: 2px;
  }
`

//statement table content style
export const FlexContainer = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  @media print {
    gap: 8px !important;
  }
`

export const StatementTopTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  .text {
    text-align: center;
  }

  @media only print {
    h5,
    .text {
      font-size: 6pt;
    }
  }
`
export const BusinessPlaceNameContent = styled.div`
  width: 100%;
  .c_name {
    padding: 5px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px !important;
    }
  }
  .region_grade {
    display: flex;
    &-left {
      flex: 1;
      border-right: 1px solid ${theme.colors.text};
      padding: 5px;
      font-size: 12px;
    }
    &-right {
      flex: 1;
      padding: 5px;
      font-size: 12px;
    }
  }
  @media only print {
    .c_name,
    .region_grade {
      font-size: 7pt !important;
    }
    .c_name {
      flex-direction: column !important;
      gap: 2px !important;
    }
  }
`
export const StyledWidthContent = styled.div<{ $value?: string }>`
  width: ${({ $value }) => $value || "auto"};
`
