import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  InputField,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  RadioGroup,
} from "@project/common"
import { Typography } from "antd"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  CONSENT_DATE_FIELDS,
  PLAN_DISPLAY_CONTENT,
  PRINT_SETTINGS,
} from "../../../constants"
import { PlanOperationData } from "../../../types"
const PlanDetailOperationWrapper = styled.div`
  #facility__content__container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .input_field {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
  }
  #content {
    margin-top: 10px;
    .input__content {
      margin-top: 5px;
    }
    .ant-typography {
      font-weight: 500;
      font-size: 14px;
    }
  }
`

interface PlanDetailOperationProps {
  onDisplayChange: (values: PlanOperationData) => void
}
export const PlanDetailOperation = ({
  onDisplayChange,
}: PlanDetailOperationProps): JSX.Element => {
  const { t } = useTranslation()

  const [statementText, setStatementText] = useState({
    consentStatement: t(
      "I have received an explanation of the above individual support plan, and have agreed to its contents.",
    ),
    consentStatementForChild: t(
      "I have received an explanation of the above individual support plan, and have agreed to its contents.1",
    ),
  })
  const [tempOperationData, setTempOperationData] = useState({
    display_content: ["1", "2", "6", "7"],
    consent_date: [],
    parent_sign: [],
    print_settings: "1",
  })

  return (
    <PlanDetailOperationWrapper>
      <AccordionCard
        accordion={true}
        defaultActiveKey={["plan_detail"]}
        items={[
          {
            key: "plan_detail",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <LabelTextWithLeftArrow
                    label={t("Display contents")}
                    width={"155px"}
                  />
                  <div id={"facility__content__container"}>
                    <CheckBoxGroup
                      items={PLAN_DISPLAY_CONTENT || []}
                      value={tempOperationData?.display_content || []}
                      onChange={(list: any[]) => {
                        setTempOperationData({
                          ...tempOperationData,
                          display_content: list,
                        })
                      }}
                    />
                    {tempOperationData?.display_content?.includes("3") && (
                      <div id={"content"}>
                        <Typography.Text>
                          {t("Consent Statement")}
                        </Typography.Text>
                        <InputField
                          height={"40px"}
                          value={statementText?.consentStatement}
                          onChange={(event) =>
                            setStatementText({
                              ...statementText,
                              consentStatement: event.target.value,
                            })
                          }
                        />
                      </div>
                    )}

                    {tempOperationData?.display_content?.includes("4") && (
                      <div id={"content"}>
                        <Typography.Text>
                          {t("Consent Statement for Service manager")}
                        </Typography.Text>
                        <InputField
                          height={"40px"}
                          value={statementText?.consentStatementForChild}
                          onChange={(event) =>
                            setStatementText({
                              ...statementText,
                              consentStatementForChild: event.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                {tempOperationData?.display_content?.includes("1") && (
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Consent date")}
                      width={"138px"}
                    />
                    <CheckBoxGroup
                      items={CONSENT_DATE_FIELDS}
                      value={tempOperationData?.consent_date || []}
                      onChange={(list: CheckboxValueType[]) => {
                        setTempOperationData({
                          ...tempOperationData,
                          consent_date: list,
                        })
                      }}
                    />
                  </div>
                )}

                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Print setting")}
                    width={"138px"}
                  />
                  <RadioGroup
                    options={PRINT_SETTINGS}
                    value={tempOperationData?.print_settings}
                    onChange={({ target: { value } }) => {
                      setTempOperationData({
                        ...tempOperationData,
                        print_settings: value,
                      })
                    }}
                  />
                </div>
                <div className={"search__content"}>
                  <Button
                    btnText={t("Display Change")}
                    shape={"round"}
                    onClick={() =>
                      onDisplayChange({ ...tempOperationData, statementText })
                    }
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </PlanDetailOperationWrapper>
  )
}
