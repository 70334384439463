import styled from "styled-components"
import { Box, Card, theme } from "@project/common"

export const PrintControlContainer = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }

  .action-btns {
    margin-block: 28px;
  }

  @media print {
    * {
      font-size: 10pt;
    }
    .alert-box-v2 {
      display: none;
    }
  }
`

export const ServiceTypeTableStyled = styled.div`
  overflow: auto;
  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;

    tr > th,
    tr > td {
      border: 1px solid ${theme.colors.text};
      padding: 2px 8px;
      text-align: center;
      font-weight: 400;
    }

    thead tr th {
      background: ${theme.colors.gray7};
    }
  }
`

export const InvoiceTableStyled = styled.div`
  overflow: auto;
  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;

    tr > th.invoice-table-cell,
    tr > td.invoice-table-cell {
      border: 1px solid ${theme.colors.text};
      padding: 2px 8px;
    }

    tr > td.value {
      text-align: center;
    }

    thead tr th,
    tfoot tr td {
      font-weight: 400;
      background: ${theme.colors.gray7};
    }

    tbody.extra-bill-listing-tbody {
      position: relative;
      &:after {
        line-height: 0.8em;
        content: " ";
        color: red;
        display: block;
        height: 0.2em;
      }
    }

    tfoot.invoice-table-tfoot tr td.label {
      text-align: center;
    }

    tfoot.invoice-table-tfoot:before {
      line-height: 0.8em;
      content: " ";
      color: red;
      display: block;
      height: 0.2em;
    }
  }
`

export const PaymentAddressTable = styled.div`
  overflow: auto;
  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;

    th.payment-address-table-cell.label {
      background-color: ${theme.colors.gray7};
      width: 300px;
    }

    tr th.payment-address-table-cell,
    tr > td.payment-address-table-cell {
      border: 1px solid ${theme.colors.text};
      padding: 2px 8px;
      text-align: center;
      font-weight: 400;
    }
  }
`

export const BillingBreakDownTable = styled.div`
  .ant-table-container {
    border-start-start-radius: 0px;
    border-start-end-radius: 0px !important;
    border-color: ${theme.colors.text} !important;
    table {
      thead.ant-table-thead tr {
        th.ant-table-cell {
          background: ${theme.colors.gray7};
          border-color: ${theme.colors.text} !important;
        }
        th:first-child {
          border-start-start-radius: 0px !important;
        }
        th:last-child {
          border-start-end-radius: 0px !important;
        }
      }
      tbody.ant-table-tbody tr td.ant-table-cell {
        border-color: ${theme.colors.text} !important;
      }
    }
  }
`

export const PrintControlPrintViewCard = styled(Card)`
  margin-block-start: 28px;
  color: ${theme.colors.text};
  font-size: 10.5pt;

  .individual-print-sheet {
    border: 1px solid ${theme.colors.border};
    padding: 20px 10px;
    .invoice-head-info {
      .parent-info-envelop-mode {
        padding: 0 15px;
        width: 90mm;
        height: 100px;
        .parent-span-tag.envelop-mode {
          font-size: 1.2rem;
          margin-top: 20px;
        }
      }
    }

    @media print {
      padding: 15mm 10mm 0;
      .invoice-head-info {
        .parent-info-envelop-mode {
          padding: 0 5mm;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .title {
      font-size: 20px;
      color: ${theme.colors.text};
      font-weight: 700;
      text-align: center;
      &.envelop-mode {
        border: 1px solid ${theme.colors.border};
        flex: 1;
      }
    }

    .date {
      text-align: right;
      &.envelop-mode {
        margin-top: -67px;
      }
    }

    .details-box {
      .left-hand-side {
        .parent-span-tag.envelop-mode {
          font-size: 1.2rem;
        }
        .sub-text {
          margin-top: 8px;
        }
      }
      .right-hand-side {
        .inline-pair-text {
          margin-bottom: 4px;
        }
      }
    }

    .invoice-billed-amt {
      padding: 10px 20px;
      border: 1px solid black;
      color: ${theme.colors.text};
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      display: block;
      margin-inline: auto;
      width: max-content;
      max-width: 100%;
      margin-block: 20px;
    }

    .invoice-breakdown-table,
    .actual-expense-table {
      .pft-container table {
        tbody.pft-tbody {
          tr.pft-measure-row {
            display: none;
          }
        }
        tfoot.pft-summary tr {
          td.pft-cell {
            padding: 2px 7px;
          }
          &.summary-billed-amt-total-row,
          &.summary-expense-total-row {
            td.pft-cell {
              background: ${theme.colors.border};
            }
          }
        }
      }
    }
  }
  @media print {
    margin-block-start: 0;
    .individual-print-sheet {
      border: none;
    }
  }

  &.print-config-1:not(:last-child) {
    page-break-after: always;
  }
  &.print-config-2:nth-of-type(2):not(:last-child) {
    page-break-after: always;
  }
  &.print-config-3:nth-of-type(3):not(:last-child) {
    page-break-after: always;
  }
`

export const InlinePairTextStyled = styled.div`
  .bold {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    margin-right: 8px;
    min-width: 60px;
  }
  .light {
    font-size: 14px;
  }
`

export const BulletTitleStyled = styled(Box)``

export const RemarksStyled = styled.div`
  width: 100%;
  min-height: 100px;
  border: 1px solid ${theme.colors.text};
  padding: 2px 4px;
  font-size: 14px;
`
