// packages
import styled from "styled-components"

// commons
import { theme } from "@project/common"

export const UserTransportMgmtContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const UserTransportMgmtOperationContent = styled.div`
  display: grid;
  gap: 16px;
  .TM_facility-inputs {
    display: grid;
    gap: 16px;
  }
  .operation-action-btns {
    margin-top: 8px;
  }
`

export const UserTransportMgmtTableWrap = styled.div`
  .ant-table-container .ant-table-content table {
    thead > tr > th.ant-table-cell {
      padding: 14px 6px;
    }

    tbody > tr.ant-table-row {
      td.ant-table-cell {
        font-size: 10.5pt !important;
        a {
          font-size: inherit !important;
        }
      }
      td.holiday-cell {
        background-color: ${theme.colors.red7};
        .holiday-text {
          color: ${theme.colors.text};
          font-size: inherit !important;
        }
      }
    }
  }
`
