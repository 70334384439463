import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"
export const MonthlyUseStatusChartWrapper = styled(Flex)`
  .print_title {
    margin: 0;
    display: none !important;
    @media print {
      display: block !important;
    }
  }
  .action__btns {
    @media print {
      display: none !important;
    }
  }
`
export const StyledTableWrapper = styled.div`
  table#monthly-use-status-chart {
    width: 100%;
    border-collapse: collapse !important;
    thead {
      th {
        border: 1px solid ${theme.colors.text};
        text-align: center;
        padding: 4px;
        font-weight: 400;
        font-size: 12px;
      }
      #nowrap {
        white-space: nowrap !important;
        /* padding: 5px 30px !important; */
      }
    }
    tbody {
      tr {
        page-break-inside: avoid !important;
        td {
          border: 1px solid ${theme.colors.text};
          padding: 8px;
          text-align: center;
          font-weight: 400;
          font-size: 12px;
        }
        #date__cell {
          width: 33px;
          text-align: left;
        }
        #text__info-cell {
          text-align: left;
          white-space: nowrap;
        }
        .total_cell {
          height: 60px !important;
          padding: 25px 5px;
        }
        #row_collapse_cell {
          position: relative;
          &::before {
            content: " ";
            background-color: ${theme.colors.text};
            height: 100%;
            width: 1px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: rotate(7deg) translate(0px);
          }
        }
        @media print and (orientation: portrait) {
          #row_collapse_cell::before {
            transform: rotate(3deg) translate(0px) !important;
          }
        }
        #empty__cell {
          background-color: ${theme?.colors?.border};
        }
      }
      &:before {
        content: "@";
        display: block;
        line-height: 3px;
        text-indent: -99999px;
      }
    }
    @media print {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      thead {
        th {
          border: 1px solid ${theme.colors.text};
          text-align: center;
          padding: 2px;
          font-weight: 500;
        }
        #nowrap {
          white-space: pre-wrap !important;
        }
      }
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.colors.text};
            padding: 2px;
            text-align: center;
            font-size: 10px !important;
          }
          #date__cell {
            width: 20px !important;
            text-align: center;
          }
          #text__info-cell {
            text-align: left;
            white-space: pre-wrap !important;
          }
          .total_cell {
            height: 60px !important;
            padding: 4px !important;
          }
        }
      }
    }
  }
  @media only screen {
    width: 100%;
    overflow-x: auto;
  }
`
