import { t } from "i18next"
import * as yup from "yup"
type ProgressName = {
  progress_name: string | null
}
export interface ProgressManagementFormFields {
  progress_name: ProgressName[] | any
}
export const ProgressManagementFormFieldsSchema = yup.object().shape({
  progress_name: yup
    .array()
    .of(
      yup.object().shape({
        progress_name: yup.string().required(t("Please enter")),
      }),
    )
    .min(1, t("Required")),
})
