import {
  ActionBtns,
  Button,
  Card,
  PageTitle,
  ReportCard,
  USER_ATTENDANCE_VALUES,
  dynamicLangString,
  scrollToFirstErrorField,
  theme,
} from "@project/common"
import { Popconfirm, Skeleton, Spin, notification } from "antd"
import dayjs from "dayjs"
import { FieldArray, FormikProvider, useFormik } from "formik"
import _ from "lodash"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useMediaQuery } from "react-responsive"
import { AuthContext } from "../../../context"
import {
  createDailyBusinessReport,
  deleteDailyBusinessReport,
  getAllWork,
  getDailyBusinessReport,
  getDateInfoDetail,
  updateDailyBusinessReport,
} from "../../../services"
import { DailyBusinessReportFormSchema } from "../../../types"
import { Facilities } from "../../../types/globalFacilities"
import {
  ButtonContainer,
  CardWrapper,
  MainWrapper,
  PlanFormWrapper,
  SecondContentWrapper,
  Wrapper,
} from "./DailyBusinessReportListForm.style"
import { FirstTable } from "./FirstTable"
import { MobileResponsiveView } from "./MobileView"
import { SecondTable } from "./SecondTable/SecondTable"
interface DailyBusinessReportFormProps {
  id?: string | string[]
  facility_id?: string | string[]
  date?: string | string[]
}
export const DailyBusinessReportListForm = ({
  id,
  facility_id,
  date,
}: DailyBusinessReportFormProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const isMobileScreen = useMediaQuery({ query: "(max-width: 540px)" })
  const [params, setParams] = useState<any>({
    facility_id: null,
    date: null,
  })
  const [filteredGetDailyBusiness, setFilteredGetDailyBusiness] = useState<any>(
    [],
  )
  const initialValues = {
    facility_id:
      (filteredGetDailyBusiness?.facility_id &&
        String(filteredGetDailyBusiness?.facility_id)) ||
      facility_id ||
      String(_.head(facilities as Facilities)?.value),
    morning: filteredGetDailyBusiness?.morning || "",
    afternoon: filteredGetDailyBusiness?.afternoon || "",
    works:
      filteredGetDailyBusiness?.daily_business_support_works?.map(
        (work) => work.work_id,
      ) || [],
    others: filteredGetDailyBusiness?.others || "",
    staff_id: filteredGetDailyBusiness?.staff_id || null,
    date:
      date || filteredGetDailyBusiness?.date
        ? dayjs(filteredGetDailyBusiness?.date).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
    accompanying_staff: filteredGetDailyBusiness?.accompanying_staff || null,
    daily_business_support_report:
      filteredGetDailyBusiness?.daily_business_support_report || [],
  }

  const { mutate: mutateDailyBusinessReport, isLoading: isCreateLoading } =
    useMutation(id ? updateDailyBusinessReport : createDailyBusinessReport, {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Daily Business Report",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Daily Business Report",
              "Created Successfully",
            ]),
          })
        }
        router.push("/daily-business-report-list")
      },
      onError: (res?: any) => {
        const msg = res?.data?.error?.message || res?.data?.error
        notification.error({
          message:
            msg && i18n.exists(msg)
              ? t(msg)
              : t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const { mutate: deleteDailyBusiness, isLoading: isDeleting } = useMutation(
    deleteDailyBusinessReport,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Daily Business Report",
            "Deleted Successfully",
          ]),
        })

        router.push("/daily-business-report-list")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error
        notification.error({
          message:
            msg && i18n.exists(msg)
              ? t(msg)
              : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: DailyBusinessReportFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        ...values,
        id: id,
        facility_id: +values?.facility_id,
        date: dayjs(values?.date).format("YYYY-MM-DD"),
        works: values?.works?.map((work: number) => {
          return {
            company_id: +localStorage.getItem("companyId"),
            work_id: +work,
          }
        }),
        daily_business_support_report:
          values?.daily_business_support_report?.map((d) => {
            return {
              facility_id: +d?.facility_id || +values?.facility_id,
              user_id: d?.user_id,
              company_id: d?.company_id,
              staff_id: d?.staff_id,
              other: d?.other,
              condition_of_user: d?.condition_of_user,
              support_record: d?.support_record,
              work_id: Array.isArray(d?.work_id) ? 0 : d?.work_id,
              interview: d?.interview ? true : false,
              person_in_charge: d?.person_in_charge,
              start_time_hr: d?.start_time_hr,
              start_time_min: d?.start_time_min,
              end_time_hr: d?.end_time_hr,
              end_time_min: d?.end_time_min,
              interview_record: d?.interview_record,
              absence_reason: d?.absence_reason,
              absent_flag: d?.is_absent,
              absent_recorder_staff_id: d?.absent_recorder_staff_id || null,
              daily_business_support_report_works: d?.work_id?.map(
                (work_id) => ({
                  company_id: d?.company_id,
                  work_id: work_id,
                }),
              ),
            }
          }),
      }
      mutateDailyBusinessReport(data)
    },
  })

  const {
    data: getOneDailyBusiness,
    isLoading: dailyReportByIdLoading,
    isFetching: dailyBusinessReportByIdFetching,
  } = useQuery<any, Error>(
    ["daily-business-report-list", id],
    () =>
      id &&
      getDailyBusinessReport({
        queryKey: ["daily-business-report-list", id],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return data
      },
    },
  )
  const {
    data: dateInfo,
    isLoading: dateInfoLoading,
    isFetching: dateInfoFetching,
  } = useQuery<any>(
    ["get-date-info-detail", formik.values?.date, formik.values?.facility_id],
    () =>
      getDateInfoDetail({
        date: formik.values?.date,
        facilityIds: +formik.values?.facility_id,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => data,
    },
  )
  const { data: allWorkData } = useQuery<any>(
    ["get-all-work"],
    () => getAllWork(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 2 * (60 * 1000),
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.work_name, value: +d?.id }
        }),
    },
  )

  const findAttendanceInfo = (info: Record<string, any>) => {
    if (info)
      return {
        absence_reason: info?.absence_reason || "",
        absent_recorder_staff_id: info?.save_staff_id || null,
        is_absent:
          info?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
          info?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
            ? true
            : false,
        start_time: info?.start_time || "",
        end_time: info?.end_time || "",
        attendance_type: info?.attendance_type || "",
        service_type: info?.service_type,
        username: info?.user?.username,
      }
    return {
      absence_reason: "",
      absent_recorder_staff_id: null,
      is_absent: false,
      service_type: null,
      username: "",
      start_time: "",
      end_time: "",
      attendance_type: null,
    }
  }

  useEffect(() => {
    if (id) {
      const returnData = dateInfo?.attendanceInfo
        ?.map((attendingUser) => {
          const user = getOneDailyBusiness?.daily_business_support_report?.find(
            (support) => support?.user_id == attendingUser?.user_id,
          ) || {
            // basic infos
            company_id: attendingUser?.company_id,
            user_id: attendingUser?.user_id,
            facility_id: attendingUser?.facility_id,
          }
          return {
            ...user,
            ...findAttendanceInfo(attendingUser),
            // previously work id was single selection
            // now its multiple
            // for new data refer to daily_business_support_report_works
            // for old data use word_id in array
            work_id:
              Array.isArray(user?.daily_business_support_report_works) &&
              user?.daily_business_support_report_works?.length > 0
                ? user?.daily_business_support_report_works?.map(
                    (report_work) => report_work?.work_id,
                  )
                : user?.work_id
                  ? [user.work_id]
                  : [],
          }
        })
        .filter(Boolean)
      const data = {
        ...getOneDailyBusiness,
        facility_id: params.facility_id || getOneDailyBusiness?.facility_id,
        date: params.date || getOneDailyBusiness?.date,
        daily_business_support_report: returnData,
      }
      setFilteredGetDailyBusiness(data)
    } else {
      formik.setFieldValue(
        "daily_business_support_report",
        dateInfo?.attendanceInfo?.map((info) => {
          const user =
            getOneDailyBusiness?.daily_business_support_report?.find(
              (a_info) => a_info?.user_id === info?.user_id,
            ) || {}
          return {
            ...info,
            ...user,
            staff_id: user?.staff_id || info?.save_staff_id || null,
            is_absent:
              info?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
              info?.attendance_type ===
                USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
                ? true
                : false,
            username: info?.user?.username || user?.username || "",
            service_type: info?.user?.service_type || user?.service_type || "",
            absent_recorder_staff_id: info?.save_staff_id || null,
            work_id: [],
          }
        }),
      )
    }
  }, [getOneDailyBusiness, dateInfo?.attendanceInfo])

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const Buttons = (
    <div style={{ margin: "8px 0px" }}>
      <ActionBtns>
        <ButtonContainer>
          <div className={"first-buttons"}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              type={"default"}
              onClick={() => router.push("/daily-business-report-list")}
              otherType={"draft"}
              btnBg={"#fff"}
              disabled={dailyReportByIdLoading || isCreateLoading || isDeleting}
            />
            <Button
              btnText={id ? t("Update") : t("Save")}
              shape={"round"}
              type={"primary"}
              onClick={formik.handleSubmit}
              disabled={dailyReportByIdLoading || isCreateLoading || isDeleting}
              isLoading={isCreateLoading}
            />
          </div>
        </ButtonContainer>
      </ActionBtns>
    </div>
  )
  return (
    <>
      <PageTitle
        extra={Buttons}
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Daily business report ＆ Daily support record  edit")}
      />
      <MainWrapper>
        <PlanFormWrapper>
          {dailyBusinessReportByIdFetching ? (
            <>
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
            </>
          ) : (
            <>
              <Wrapper>
                <Card className={"card-header"} title={t("Form")}>
                  <CardWrapper>
                    {dateInfoLoading ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "16px",
                          width: "100%",
                        }}
                      >
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                      </div>
                    ) : (
                      <>
                        <ReportCard
                          titleName={t("Attended : ")}
                          count={
                            dateInfo?.attendanceInfo?.filter(
                              (dateItem) =>
                                dateItem?.attendance_type ===
                                USER_ATTENDANCE_VALUES.ATTENDANCE,
                            ).length
                          }
                          peopleCount={dateInfo?.attendanceInfo?.length}
                          bgColor={"#deecff"}
                          borderColor={"#E8D3FF"}
                        />
                        <ReportCard
                          titleName={t("Handled absence:")}
                          count={
                            dateInfo?.attendanceInfo?.filter(
                              (dateItem) =>
                                dateItem?.attendance_type ===
                                USER_ATTENDANCE_VALUES.ABSENCE,
                            ).length
                          }
                          peopleCount={dateInfo?.attendanceInfo?.length}
                          bgColor={"#DEECFF"}
                          borderColor={"#DEECFF"}
                        />
                        <ReportCard
                          titleName={t("Off-site support:")}
                          count={
                            dateInfo?.attendanceInfo?.filter(
                              (dateItem) =>
                                dateItem?.attendance_type ===
                                USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT,
                            ).length
                          }
                          peopleCount={dateInfo?.attendanceInfo?.length}
                          bgColor={"#C6CFFF"}
                          borderColor={"#C6CFFF"}
                        />
                        <ReportCard
                          titleName={t("Interview:")}
                          count={
                            dateInfo?.dailyBusinessSupportReport?.filter(
                              (val) => val?.interview,
                            )?.length || 0
                          }
                          peopleCount={dateInfo?.attendanceInfo?.length}
                        />
                      </>
                    )}
                  </CardWrapper>

                  <FirstTable
                    formik={formik}
                    allWorkData={allWorkData}
                    setParams={(values) => {
                      setParams({
                        ...params,
                        ...values,
                      })
                    }}
                  />
                </Card>
                <Card
                  className={"card-header"}
                  title={t("Daily support report")}
                >
                  <Spin
                    spinning={
                      dailyBusinessReportByIdFetching ||
                      dateInfoLoading ||
                      dateInfoFetching
                    }
                  >
                    {isMobileScreen ? (
                      <>
                        <FormikProvider value={formik}>
                          <FieldArray
                            name={"daily_business_support_report"}
                            render={() =>
                              formik.values?.daily_business_support_report?.map(
                                (data: any, index: number) => {
                                  return (
                                    <>
                                      <MobileResponsiveView
                                        formik={formik}
                                        data={data}
                                        index={index}
                                      />
                                    </>
                                  )
                                },
                              )
                            }
                          />
                        </FormikProvider>
                      </>
                    ) : (
                      <>
                        <SecondContentWrapper>
                          <table>
                            <tr className={"second-content-tr"}>
                              <td className={"second-content-label"}>
                                {t("Username ")}
                              </td>
                              <td className={"second-content-label"}>
                                {t("Content")}
                              </td>
                              <td className={"second-content-label"}>
                                {t("Recorder")}
                              </td>
                            </tr>
                            <>
                              <FormikProvider value={formik}>
                                <FieldArray
                                  name={"daily_business_support_report"}
                                  render={() =>
                                    formik.values?.daily_business_support_report?.map(
                                      (data: any, index: number) => {
                                        return (
                                          <>
                                            <tr key={index}>
                                              <SecondTable
                                                allWorkData={allWorkData}
                                                formik={formik}
                                                data={data}
                                                index={index}
                                              />
                                            </tr>
                                          </>
                                        )
                                      },
                                    )
                                  }
                                />
                              </FormikProvider>
                            </>
                          </table>
                        </SecondContentWrapper>
                      </>
                    )}
                  </Spin>
                </Card>
              </Wrapper>
              <div style={{ margin: "8px 0px" }}>
                <ActionBtns>
                  <ButtonContainer>
                    <div className={"btn-wrapper"}>
                      <div className={"first-buttons"}>
                        <Button
                          btnText={t("Cancel")}
                          shape={"round"}
                          type={"default"}
                          onClick={() =>
                            router.push("/daily-business-report-list")
                          }
                          otherType={"draft"}
                          btnBg={"#fff"}
                          disabled={
                            dailyReportByIdLoading ||
                            isCreateLoading ||
                            isDeleting
                          }
                        />
                        <Button
                          btnText={id ? t("Update") : t("Save")}
                          shape={"round"}
                          type={"primary"}
                          onClick={formik.handleSubmit}
                          disabled={
                            dailyReportByIdLoading ||
                            isCreateLoading ||
                            isDeleting
                          }
                          isLoading={isCreateLoading}
                        />
                      </div>
                      {id && (
                        <Popconfirm
                          title={t("Deleting.Is that OK?")}
                          okText={t("OK")}
                          cancelText={t("Cancel")}
                          okButtonProps={{ size: "middle" }}
                          cancelButtonProps={{ size: "middle" }}
                          onConfirm={() => deleteDailyBusiness(id)}
                        >
                          <div className={"delete-btn"}>
                            <Button
                              btnText={t("Delete")}
                              shape={"round"}
                              type={"primary"}
                              disabled={
                                dailyReportByIdLoading ||
                                isCreateLoading ||
                                isDeleting
                              }
                              isLoading={isDeleting}
                              btnBg={theme.colors.error}
                              borderColor={theme.colors.error}
                              textColor={theme.colors.white}
                              hoverBorderColor={theme.colors.error}
                            />
                          </div>
                        </Popconfirm>
                      )}
                    </div>
                  </ButtonContainer>
                </ActionBtns>
              </div>
            </>
          )}
        </PlanFormWrapper>
      </MainWrapper>
    </>
  )
}
