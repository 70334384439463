import {
  Card,
  FACILITY_TYPES,
  Table,
  Tag,
  getLocalstorageItem,
  removeBlankAttributes,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { ColumnsType } from "antd/es/table"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MonitoringSearch } from "./MonitoringSearch/MonitoringSearch"

import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { PAGE_SIZE } from "../../constants"
import { fetchAllMonitoring } from "../../services"

import { Typography } from "antd"
import Image from "next/image"
import Link from "next/link"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  table > tbody {
    .no-certificate-row {
      background-color: ${theme.colors.lightPink};
    }
  }
`
const TableLegends = styled.div`
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 16px;
  div {
    display: flex;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
    border-radius: 5px;
  }
  .indicator {
    width: 20px;
    height: 20px;
    background: #e000001a;
  }
`
export const StatusContainer = styled.div`
  position: relative;
  .sign__container {
    border: 1px solid #76d6f7;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 27px;
    top: 15px;
  }
`

const MonitoringListContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    user_id,
    display,
    draft_save_flg,
    period_start_date,
    period_end_date,
    facility_ids,
  } = router.query as any
  const listRef = useRef<HTMLDivElement>(null)
  const [updateParams] = useUpdateSearchParams()
  const storedData = getLocalstorageItem("monitoring_list_key", "facility_ids")
  const [currentPage, setCurrentPage] = useState(+router?.query?.page || 1)

  const selectedFIds = facility_ids
    ? facility_ids?.split(",")?.filter((f) => !isNaN(f) && f)
    : storedData?.split(",")?.filter((f) => !isNaN(f) && f)

  const [queryParam, setQueryParam] = useState({
    user_id: user_id?.toString() ?? "",
    display_setting: display?.toString() ?? "",
    draft_save_flg: draft_save_flg?.toString() ?? "",
    period_start_date: period_start_date?.toString() ?? "",
    period_end_date: period_end_date?.toString() ?? "",
    facility_ids: selectedFIds ?? "",
  })

  const {
    isLoading,
    isFetching,
    data: monitoringData,
  } = useQuery(["monitoring-list", currentPage, queryParam], {
    queryFn: () =>
      fetchAllMonitoring({
        ...queryParam,
        page: currentPage,
        pageSize: PAGE_SIZE,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
  })

  const columns: ColumnsType<any[]> = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Details")}</span>,
      key: "Details",
      align: "center",
      width: 120,
      render: (data: any) => {
        const { id } = data
        return (
          <Link href={`/monitoring-list/detail/${id}`}>
            <a style={{ textDecoration: "underline", color: "#0782C8" }}>
              {t("Detail")}
            </a>
          </Link>
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("User name")}</span>,
      key: "user_name",
      align: "center",
      render: (row) => row?.user_info?.username || "",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Facility name")}</span>,
      align: "center",
      key: "facility",
      render: (row) => row?.facility?.facility_name || "",
    },
    {
      title: t("Service use"),
      align: "center",
      key: "service_type",
      width: 172,
      render: (data) => {
        const usedService = FACILITY_TYPES?.find(
          (v) => v?.value === data?.service_type,
        )
        return (
          usedService && (
            <Tag
              title={t(usedService?.label)}
              padding={"4px"}
              style={{
                width: usedService?.value === 3 ? "165px" : "",
                whiteSpace: "pre-wrap",
              }}
              color={
                usedService?.value === 1
                  ? "warning"
                  : usedService?.value === 2
                    ? "success"
                    : "processing"
              }
            />
          )
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("No. created")}</span>,
      key: "create_count",
      align: "center",
      dataIndex: "create_count",
    },

    {
      title: t("Created"),
      key: "report_create_date",
      align: "center",
      dataIndex: "report_create_date",
      width: 190,
      render: (row) => <span>{dayjs(row).format("YYYY年MM月DD日")}</span>,
    },
    {
      title: t("Status2"),
      key: "status",
      align: "center",
      width: 180,
      render: (row) => {
        return (
          <StatusContainer>
            <Tag
              title={!row?.draft_save_flag ? t("Created2") : t("Draft")}
              color={
                !row?.draft_save_flag
                  ? theme.colors.info
                  : theme.colors["text-gray"]
              }
              padding={"4px"}
            />
            {row?.user_name_digital_image && (
              <div className={"sign__container"}>
                <Image
                  src={"/assets/icons/solid-edit.svg"}
                  width={15}
                  height={15}
                  alt={""}
                />
              </div>
            )}
          </StatusContainer>
        )
      },
    },
  ]
  return (
    <Wrapper>
      <MonitoringSearch
        queryParam={queryParam}
        handleSearch={(values: any) => {
          updateParams(removeBlankAttributes(values), "/monitoring-list")
          setQueryParam(values)
          scrollToSelectedElement(listRef)
        }}
      />
      <Card title={t("Monitoring list")}>
        <div ref={listRef}>
          <TableLegends>
            <Typography.Paragraph>
              <div className={"indicator"}></div>
              <span>
                {t("Users who do not have a registered recipient certificate")}
              </span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <div>
                <Image
                  src={"/assets/icons/solid-edit.svg"}
                  width={20}
                  height={20}
                  alt={""}
                />
              </div>
              {t("Digital signed by parents")}
            </Typography.Paragraph>
          </TableLegends>
          <Table
            columns={columns}
            noHeaderBorder
            headerCellPaddingBlock={"15px"}
            cellPaddingBlock={12}
            loading={isLoading || isFetching}
            onChange={(val) => setCurrentPage(val)}
            total={monitoringData?.count}
            showPaginationOf={"both"}
            scroll={{ x: 900 }}
            dataSource={monitoringData?.data}
            rowClassName={(row: any) =>
              !row?.certificate_id && "no-certificate-row"
            }
          />
        </div>
      </Card>
    </Wrapper>
  )
}

export { MonitoringListContent }
