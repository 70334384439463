import {
  AccordionCard,
  Breadcrumb,
  Button,
  Card,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationOptionContainer,
  PageTitle,
  SelectInput,
  Table,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  setItemToLocalstorage,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { AuthContext } from "../../context/AuthContext"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { fetchUserStatusListData } from "../../services/userStatusList"
import { UserStatusListTableDataType } from "../../types/userStatusList.types"
import { filterWithHiragana } from "../../utils"
import {
  CREATE_STATUS_OPTIONS,
  CREATE_STATUS_TYPE_OPTIONS,
  FuriganaAlphabetsOption,
} from "../../utils/common-options"
import {
  ButtonWrapper,
  TMiniContainer,
  TableContainer,
  UserStatusListContent,
  UserStatusListListContent,
} from "./UserStatusList.style"

const UserStatusList = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useContext(AuthContext)
  const listContent = useRef<any>(null)
  const [page, setPage] = useState(+router.query?.page || 1)
  const [startChar, setStartChar] = useState(null)
  const [checkedFacility, setCheckedFacility] = useState<CheckboxValueType[]>(
    [],
  )
  const [searchParams, setSearchParams] = useState<any>({
    facility_ids: checkedFacility,
    user_id: +router.query?.user_id,
    create_status: "1",
    create_status_type: null,
  })
  const [searchEnabled, setSearchEnabled] = useState(true)
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>(
    ["userStatusListData"],
    () =>
      fetchUserStatusListData({
        queryKey: [
          "userStatusListData",
          page,
          pageSize,
          {
            ...searchParams,
            create_status: +searchParams?.create_status,
            facility_ids: Array.isArray(searchParams?.facility_ids)
              ? searchParams?.facility_ids?.toString()
              : searchParams?.facility_ids,
          },
        ],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: searchEnabled,
      onSuccess: () => {
        setSearchEnabled(false)
      },
    },
  )

  const { userData: allUser } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setPage(1)
    updateParams(queries, "/user-status-list")
    setItemToLocalstorage("user-status-list", {
      facility: values?.facility || null,
    })
    scrollToSelectedElement(listContent)
  }

  const onSearch = () => {
    setSearchEnabled(true)
    const values = {
      facility_ids: checkedFacility?.toString(),
      user_id: searchParams?.user_id,
      create_status: searchParams?.create_status,
      create_status_type: searchParams?.create_status_type,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(params)
    handleSearchAndResetParams(params)
  }

  const onSearchReset = () => {
    setCheckedFacility([])
    setPage(1)
    setSearchEnabled(true)
    setSearchParams({
      facility_ids: null,
      user_id: null,
      create_status: "1",
      create_status_type: null,
    })
    setSearchEnabled(true)
    handleSearchAndResetParams({}, "reset")
  }
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    router.push(
      {
        pathname: "/user-status-list",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }
  const columns: ColumnsType<UserStatusListTableDataType[]> = [
    {
      title: t("User name"),
      dataIndex: "user_id",
      align: "center",
      key: "id",
      width: 90,
      render: (_, record: any) => {
        return (
          <div>
            <Link href={`/users/profile/${record.user_id}`}>
              <a style={{ textDecoration: "underline", color: "#0782C8" }}>
                {record?.user_name}
              </a>
            </Link>
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Facility name")}</span>,
      dataIndex: "facility_name",
      key: "facility_name",
      align: "center",
      width: 90,
    },
    {
      title: t("No. of created"),
      key: "",
      align: "center",
      width: 50,
      dataIndex: "created_count",
      render: (_, row: any) => {
        return <div>{row?.total_created}</div>
      },
    },
    {
      title: t("Assessment"),
      dataIndex: "assessment",
      key: "",
      align: "center",
      width: 95,
      render: (_, record: any) => {
        const data = record?.assessment_id
        if (data) {
          return (
            <div className={"created"}>
              <Button
                btnText={t(
                  `${
                    record?.assessment_is_draft ? t("Draft") : t("Created")
                  } : ${
                    record?.assessment_date
                      ? dayjs(record?.assessment_date).format("YYYY年MM月DD日")
                      : ""
                  }`,
                )}
                borderColor={
                  record?.assessment_is_draft ? "#4CB476" : "#5C8197"
                }
                btnBg={record?.assessment_is_draft ? "#4CB476" : "#5C8197"}
                onClick={() =>
                  router.push(`/assessment/details/${record?.assessment_id}`)
                }
                style={{
                  padding: "5px",
                  // whiteSpace: "nowrap",
                }}
              />
            </div>
          )
        }
        return (
          <div className={"not-created"}>
            <Button
              btnText={t("Not created")}
              onClick={() =>
                router.push(`/assessment/add?user_id=${record?.user_id}`)
              }
            />
          </div>
        )
      },
    },
    {
      title: t("Individual support plan (draft)"),
      dataIndex: "support_plan_draft_id",
      key: "",
      align: "center",
      width: 110,
      render: (_, record: any) => {
        const data = record?.individual_support_plan_draft
        if (data) {
          return (
            <div className={"created"}>
              <Button
                btnText={t(
                  `${
                    record?.individual_support_plan_draft_is_draft
                      ? t("Draft")
                      : t("Created")
                  } : ${
                    record?.support_plan_draft_date
                      ? dayjs(record?.support_plan_draft_date).format(
                          "YYYY年MM月DD日",
                        )
                      : ""
                  }`,
                )}
                btnBg={
                  record?.individual_support_plan_draft_is_draft
                    ? "#4CB476"
                    : "#5C8197"
                }
                borderColor={
                  record?.individual_support_plan_draft_is_draft
                    ? "#4CB476"
                    : "#5C8197"
                }
                onClick={() =>
                  router.push(
                    `/draft-individual-support-plan/detail/${record?.support_plan_draft_id}`,
                  )
                }
                style={{
                  padding: "5px",
                  // whiteSpace: "nowrap",
                }}
              />
              {!record?.individual_draft_digital_signature_image && (
                <img
                  src={"./assets/icons/solid-edit.svg"}
                  alt={"icon"}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "2px",
                    height: "12px",
                    width: "12px",
                    objectFit: "contain",
                    border: "1px solid #76d6f7",
                    borderRadius: "100%",
                  }}
                />
              )}
            </div>
          )
        }
        return (
          <div className={"not-created"}>
            <Button
              btnText={t("Not created")}
              onClick={() =>
                router.push(
                  `/draft-individual-support-plan/add?user_id=${record?.user_id}&create_count=${record?.created_count}`,
                )
              }
            />
          </div>
        )
      },
    },
    {
      title: t("Support meeting record"),
      dataIndex: "",
      key: "",
      align: "center",
      width: 110,
      render: (_, record: any) => {
        const data = record?.addition_meeting_id
        if (data) {
          return (
            <div className={"created"}>
              <Button
                btnText={t(
                  `${
                    record?.addition_meeting_is_draft
                      ? t("Draft")
                      : t("Created")
                  } : ${
                    record?.addition_meeting_date
                      ? dayjs(record?.addition_meeting_date).format(
                          "YYYY年MM月DD日",
                        )
                      : ""
                  }`,
                )}
                btnBg={
                  record?.addition_meeting_is_draft ? "#4CB476" : "#5C8197"
                }
                borderColor={
                  record?.addition_meeting_is_draft ? "#4CB476" : "#5C8197"
                }
                onClick={() =>
                  router.push(`/minutes/detail/${record?.addition_meeting_id}`)
                }
                style={{
                  padding: "5px",
                  // whiteSpace: "nowrap",
                }}
              />
            </div>
          )
        }
        return (
          <div className={"not-created"}>
            <Button
              btnText={t("Not created")}
              onClick={() =>
                router.push(
                  `/minutes/create?c_id=${record?.user_id}&created_count=${record?.created_count}`,
                )
              }
            />
          </div>
        )
      },
    },
    {
      title: t("Individual support plan"),
      dataIndex: "support_plan_id",
      key: "",
      align: "center",
      width: 110,
      render: (_, record: any) => {
        const data = record?.individual_support_plan
        if (data) {
          return (
            <div className={"created"}>
              <Button
                btnText={t(
                  `${
                    record?.individual_support_plan_is_draft
                      ? t("Draft")
                      : t("Created")
                  } : ${
                    record?.individual_support_plan_is_draft
                      ? dayjs(record?.draft_support_plan_create_date).format(
                          "YYYY年MM月DD日",
                        )
                      : dayjs(record?.support_plan_create_date).format(
                          "YYYY年MM月DD日",
                        )
                  }`,
                )}
                btnBg={
                  record?.individual_support_plan_is_draft
                    ? "#4CB476"
                    : "#5C8197"
                }
                borderColor={
                  record?.individual_support_plan_is_draft
                    ? "#4CB476"
                    : "#5C8197"
                }
                onClick={() =>
                  router.push(
                    `/individual-support-plan/detail/${record?.support_plan_id}`,
                  )
                }
                style={{
                  padding: "5px",
                  // whiteSpace: "nowrap",
                }}
              />
              {!record?.individual_digital_signature_image && (
                <img
                  src={"./assets/icons/solid-edit.svg"}
                  alt={"icon"}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "2px",
                    height: "12px",
                    width: "12px",
                    objectFit: "contain",
                    border: "1px solid #76d6f7",
                    borderRadius: "100%",
                  }}
                />
              )}
            </div>
          )
        }
        return (
          <div className={"not-created"}>
            <Button
              btnText={t("Not created")}
              onClick={() =>
                router.push(
                  `/individual-support-plan/add?user_id=${record?.user_id}&create_count=${record?.created_count}`,
                )
              }
            />
          </div>
        )
      },
    },
    {
      title: t("Monitoring"),
      dataIndex: "monitoring_id",
      key: "",
      align: "center",
      width: 110,
      render: (_, record: any) => {
        const data = record?.monitoring
        if (data) {
          return (
            <div className={"created"}>
              <Button
                btnText={t(
                  `${t("Created")} : ${
                    record?.monitoring_date
                      ? dayjs(record?.monitoring_date).format("YYYY年MM月DD日")
                      : ""
                  }`,
                )}
                btnBg={record?.monitoring_is_draft ? "#4CB476" : "#5C8197"}
                borderColor={
                  record?.monitoring_is_draft ? "#4CB476" : "#5C8197"
                }
                onClick={() =>
                  router.push(
                    `/monitoring-list/detail/${record?.monitoring_id}`,
                  )
                }
                style={{
                  padding: "5px",
                  // whiteSpace: "nowrap",
                }}
              />
              {!record?.individual_digital_signature_image && (
                <img
                  src={"./assets/icons/solid-edit.svg"}
                  alt={"icon"}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "2px",
                    height: "12px",
                    width: "12px",
                    objectFit: "contain",
                    border: "1px solid #76d6f7",
                    borderRadius: "100%",
                  }}
                />
              )}
            </div>
          )
        }
        return (
          <div className={"not-created"}>
            <Button
              btnText={t("Not created")}
              onClick={() =>
                router.push(
                  `/monitoring-list/add?c_id=${record?.user_id}&created_count=${record?.created_count}`,
                )
              }
            />
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: t("User Status list"),
          },
        ]}
      />
      <PageTitle
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        title={t("User Status list")}
      />
      <UserStatusListContent>
        <AccordionCard
          accordion={true}
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <OperationOptionContainer>
                  <div className={"field__content flex-start"}>
                    <div className={"mt input_label"}>
                      <LabelTextWithLeftArrow
                        label={t("Facility")}
                        width={"139px"}
                      />
                    </div>
                    <FacilityCheckbox
                      options={facilities}
                      value={checkedFacility}
                      handleCheckUncheckAll={(type: "check" | "uncheck") => {
                        if (type === "check") {
                          const ids = facilities?.map((v) => v?.value)
                          setCheckedFacility(ids)
                        }
                        if (type === "uncheck") {
                          setCheckedFacility([])
                        }
                      }}
                      onChange={(list: CheckboxValueType[]) => {
                        setCheckedFacility(list)
                      }}
                    />
                  </div>
                  <div className={"search__content input_main"}>
                    <div className={"input_label"}>
                      <LabelTextWithLeftArrow
                        label={t("User name")}
                        width={"139px"}
                      />
                    </div>
                    <div className={"user-select"}>
                      <div className={"first-child"}>
                        <SelectInput
                          width={"108px"}
                          name={"startChar"}
                          className={"start_char"}
                          placeholder={"---"}
                          options={FuriganaAlphabetsOption}
                          value={startChar}
                          onChange={(v) => {
                            setStartChar(v)
                            setSearchParams({ ...searchParams, user_id: null })
                          }}
                        />
                      </div>
                      <div className={"second-child"}>
                        <SelectInput
                          name={"user_id"}
                          size={"large"}
                          placeholder={"--"}
                          className={"user_name_field"}
                          width={"307px"}
                          options={filterWithHiragana({
                            options: allUser,
                            furigana: startChar,
                          })}
                          value={
                            searchParams?.user_id ? searchParams?.user_id : null
                          }
                          onChange={(v) => {
                            setSearchParams({ ...searchParams, user_id: v })
                            setStartChar(null)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"search__content"}>
                    <div className={"input_label"}>
                      <LabelTextWithLeftArrow
                        label={t("Create status")}
                        width={"139px"}
                      />
                    </div>
                    <div className={"status_wrapper"}>
                      <SelectInput
                        name={"create_status_type"}
                        options={CREATE_STATUS_TYPE_OPTIONS}
                        placeholder={"--"}
                        width={"439px"}
                        value={searchParams?.create_status_type}
                        onChange={(v) =>
                          setSearchParams({
                            ...searchParams,
                            create_status_type: v,
                          })
                        }
                      />
                      {searchParams?.create_status_type && (
                        <div className={"status"}>
                          <SelectInput
                            name={"create_status"}
                            size={"large"}
                            options={CREATE_STATUS_OPTIONS}
                            placeholder={"--"}
                            value={searchParams?.create_status}
                            onChange={(v) =>
                              setSearchParams({
                                ...searchParams,
                                create_status: v,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"search__content buttons"}>
                    <Button
                      btnText={t("Reset search")}
                      size={"large"}
                      shape={"round"}
                      onClick={() => onSearchReset()}
                    />
                    <Button
                      btnText={t("Search")}
                      type={"primary"}
                      size={"large"}
                      shape={"round"}
                      iconType={"search"}
                      onClick={() => onSearch()}
                    />
                  </div>
                </OperationOptionContainer>
              ),
            },
          ]}
        />

        <Card title={t("List")}>
          <TableContainer>
            <TMiniContainer>
              <img src={"./assets/icons/pink.svg"} alt={"icon"} />
              <span>
                {t(
                  "children_who_do_not_have_a_registered_recipient_certificate",
                )}
              </span>
            </TMiniContainer>
            <TMiniContainer>
              <img src={"./assets/icons/pencil_blue.svg"} alt={"icon"} />
              <span>{t("digital_signed_by_parents")}</span>
            </TMiniContainer>
          </TableContainer>
          <ButtonWrapper>
            <Button
              shape={"round"}
              btnText={t("Go to assessment list")}
              iconType={"down-arrow"}
              className={"down-btn"}
              btnBg={theme.colors["action-400"]}
              onClick={() => router.push("/assessment")}
            />

            <Button
              shape={"round"}
              iconType={"down-arrow"}
              btnText={t("Go to Individual support plan (draft) list")}
              className={"facility-contact"}
              btnBg={theme.colors["action-400"]}
              onClick={() => router.push("/draft-individual-support-plan")}
            />

            <Button
              shape={"round"}
              iconType={"down-arrow"}
              className={"down-btn"}
              btnText={t("Go to Support meeting record list")}
              btnBg={theme.colors["action-400"]}
              onClick={() => router.push("/minutes")}
            />
            <Button
              shape={"round"}
              iconType={"down-arrow"}
              className={"down-btn"}
              btnText={t("Go to Individual support plan list")}
              btnBg={theme.colors["action-400"]}
              onClick={() => router.push("/individual-support-plan")}
            />
            <Button
              shape={"round"}
              iconType={"down-arrow"}
              className={"down-btn"}
              btnText={t("Go to monitoring list")}
              btnBg={theme.colors["action-400"]}
              onClick={() => router.push("/monitoring-list")}
            />
          </ButtonWrapper>
          <UserStatusListListContent ref={listContent}>
            <div>
              <Table
                current={page}
                total={response?.count || 0}
                pageSize={pageSize}
                columns={columns}
                dataSource={response?.data}
                showPaginationOf={"both"}
                scroll={{ x: "max-content" }}
                onChange={handlePaginationChange}
                loading={isFetching || isLoading}
                rowClassName={(row: any) =>
                  !row?.certificate_id && "no-certificate-row"
                }
              />
            </div>
          </UserStatusListListContent>
        </Card>
      </UserStatusListContent>
    </>
  )
}

export { UserStatusList }
