import React from "react"

// packages
import { Flex } from "antd"
import { t } from "i18next"

// commons | components
import {
  Button,
  InputField,
  SelectInput,
  CALENDAR_HOURS_MINUTES,
} from "@project/common"

// styles
import { WorkHourTdCellWrap } from "../AttendanceBulkEditInternal.styles"

// types
import {
  WorkHourTdCellProps,
  WorkingHours,
} from "../../../../types/AttendanceBulkEdit.types"

export const WorkHourTdCell: React.FC<WorkHourTdCellProps> = ({
  formik,
  uniqueKey,
  responseWorkHour,
}) => {
  const [currentWorkHour, setCurrentWorkHour] =
    React.useState<Partial<WorkingHours> | null>(null)

  const { setFieldValue } = formik

  // ! ## Side effects
  // 1. Delayed debounce for setting formik field value
  React.useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (currentWorkHour) {
        setFieldValue(
          `individualRecord.${uniqueKey}.working_hours`,
          currentWorkHour,
        )
      }
    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [currentWorkHour])
  // 2. Set current work hour from response work hour
  React.useEffect(() => {
    if (responseWorkHour) {
      setCurrentWorkHour(responseWorkHour)
    }
  }, [responseWorkHour])

  // returning if no current work hour
  if (!currentWorkHour) {
    return null
  }

  return (
    <WorkHourTdCellWrap justify={"center"}>
      {/* Sub cell for working hour column [work_info_1] starts here */}
      <Flex className={"work_info_1-sub-cell sub-cell"} align={"center"}>
        {
          <>
            <div className={"working-hours-1-label working-hours-label"}>
              {t("Working hour 1")}{" "}
            </div>

            <Flex align={"center"} className={"hour-inputs"}>
              {/* Action buttons starts here */}
              {(currentWorkHour?.work_info_1?.isAbsent === null ||
                currentWorkHour?.work_info_1?.isAbsent === undefined) && (
                <Flex
                  align={"center"}
                  gap={"8px"}
                  className={"working-hour-action-btns"}
                >
                  <Button
                    btnText={t("attendance at work")}
                    shape={"round"}
                    type={"default"}
                    onClick={() => {
                      const newWorkInfo = {
                        ...currentWorkHour?.work_info_1,
                        isAbsent: false,
                      }
                      setCurrentWorkHour({
                        ...currentWorkHour,
                        work_info_1: newWorkInfo,
                      })
                    }}
                  />
                  <Button
                    btnText={t("absenteeism")}
                    shape={"round"}
                    type={"default"}
                    onClick={() => {
                      const newWorkInfo = {
                        ...currentWorkHour?.work_info_1,
                        isAbsent: true,
                      }

                      setCurrentWorkHour({
                        ...currentWorkHour,
                        work_info_1: newWorkInfo,
                      })
                    }}
                  />
                </Flex>
              )}
              {/* Action buttons ends here */}

              {/* Working hours input fields starts here */}
              {currentWorkHour?.work_info_1?.isAbsent === false && (
                <Flex
                  align={"center"}
                  gap={"6px"}
                  className={"work_time__hours"}
                >
                  <>
                    <Flex
                      align={"center"}
                      className={"time-group work_time__hours--from"}
                      gap={"4px"}
                    >
                      <SelectInput
                        width={"60px"}
                        height={"40px"}
                        placeholder={"--"}
                        name={`individualRecord.${uniqueKey}.working_hours.work_info_1.time_range.start.hour`}
                        value={
                          currentWorkHour?.work_info_1?.time_range?.start?.hour
                        }
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        onChange={(val) => {
                          const newWorkInfo: WorkingHours = {
                            ...currentWorkHour,
                            work_info_1: {
                              ...currentWorkHour?.work_info_1,
                              time_range: {
                                ...currentWorkHour?.work_info_1?.time_range,
                                start: {
                                  ...currentWorkHour?.work_info_1?.time_range
                                    ?.start,
                                  hour: val,
                                },
                              },
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                      {":"}
                      <SelectInput
                        width={"60px"}
                        height={"40px"}
                        placeholder={"--"}
                        name={"work_time_from_min"}
                        value={
                          currentWorkHour?.work_info_1?.time_range?.start
                            ?.minute
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        onChange={(val) => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_1: {
                              ...currentWorkHour?.work_info_1,
                              time_range: {
                                ...currentWorkHour?.work_info_1?.time_range,
                                start: {
                                  ...currentWorkHour?.work_info_1?.time_range
                                    ?.start,
                                  minute: val,
                                },
                              },
                            },
                          }

                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                    </Flex>

                    <span className={"time-separator"}>{"~"}</span>

                    <Flex
                      align={"center"}
                      gap={"4px"}
                      className={"time-group work_time__hours--to"}
                    >
                      <SelectInput
                        width={"60px"}
                        height={"40px"}
                        placeholder={"--"}
                        name={"work_time_to_hour"}
                        value={
                          currentWorkHour?.work_info_1?.time_range?.end?.hour
                        }
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        onChange={(val) => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_1: {
                              ...currentWorkHour?.work_info_1,
                              time_range: {
                                ...currentWorkHour?.work_info_1?.time_range,
                                end: {
                                  ...currentWorkHour?.work_info_1?.time_range
                                    ?.end,
                                  hour: val,
                                },
                              },
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                      {":"}
                      <SelectInput
                        width={"60px"}
                        height={"40px"}
                        placeholder={"--"}
                        name={"work_time_to_min"}
                        value={
                          currentWorkHour?.work_info_1?.time_range?.end?.minute
                        }
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        onChange={(val) => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_1: {
                              ...currentWorkHour?.work_info_1,
                              time_range: {
                                ...currentWorkHour?.work_info_1?.time_range,
                                end: {
                                  ...currentWorkHour?.work_info_1?.time_range
                                    ?.end,
                                  minute: val,
                                },
                              },
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                    </Flex>
                  </>
                </Flex>
              )}
              {/* Working hours input fields ends here */}
            </Flex>

            {currentWorkHour?.work_info_1?.isAbsent === false && (
              <Flex align={"center"} className={"break-time-input"}>
                <label
                  className={"break-time-label"}
                  htmlFor={`${uniqueKey}-break-time-input-work-info-1`}
                >
                  {t("Break time")}
                  {": "}
                </label>
                <div className={"input-field-wrap"}>
                  <InputField
                    name={"break_time"}
                    width={"68px"}
                    height={"40px"}
                    fixedWidth
                    id={`${uniqueKey}-break-time-input-work-info-1`}
                    value={currentWorkHour?.work_info_1?.break_time}
                    onChange={(e) => {
                      const newWorkInfo = {
                        ...currentWorkHour,
                        work_info_1: {
                          ...currentWorkHour?.work_info_1,
                          break_time: e.target.value,
                        },
                      }
                      setCurrentWorkHour(newWorkInfo)
                    }}
                  />
                </div>
                <span>{t("mins")}</span>
              </Flex>
            )}
          </>
        }

        {/* Absenteeism button/indicator starts here*/}
        {currentWorkHour?.work_info_1?.isAbsent === true && (
          <Button
            onClick={() => {
              const newWorkInfo = {
                ...currentWorkHour,
                work_info_1: {
                  ...currentWorkHour?.work_info_1,
                  isAbsent: null,
                },
              }
              setCurrentWorkHour(newWorkInfo)
            }}
            type={"text"}
            className={"absenteeism-indicator"}
            btnText={t("Absent")}
          />
        )}
        {/* Absenteeism button/indicator ends here*/}
      </Flex>
      {/* Sub cell for working hour column [work_info_1] ends here */}

      {/* ## Sub cell for working hour column [work_info_2] starts here*/}
      <Flex className={"work_info_2-sub-cell sub-cell"} align={"center"}>
        <div className={"working-hours-1-label working-hours-label"}>
          {t("Working hour 2")}{" "}
        </div>

        {!currentWorkHour?.work_info_1?.isAbsent ? (
          <>
            {!currentWorkHour?.work_info_2?.hasRecWorkInfo2 && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  btnText={t("Record work information 2")}
                  shape={"round"}
                  type={"default"}
                  onClick={() => {
                    const newWorkInfo = {
                      ...currentWorkHour,
                      work_info_2: {
                        ...currentWorkHour?.work_info_2,
                        hasRecWorkInfo2: true,
                      },
                    }
                    setCurrentWorkHour(newWorkInfo)
                  }}
                />
              </div>
            )}

            {currentWorkHour?.work_info_2?.hasRecWorkInfo2 && (
              <>
                <Flex align={"center"} className={"hour-inputs"}>
                  {/* Action buttons starts here */}
                  {(currentWorkHour?.work_info_2?.isAbsent === null ||
                    currentWorkHour?.work_info_2?.isAbsent === undefined) && (
                    <Flex
                      align={"center"}
                      gap={"8px"}
                      className={"working-hour-action-btns"}
                    >
                      <Button
                        btnText={t("attendance at work")}
                        shape={"round"}
                        type={"default"}
                        onClick={() => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_2: {
                              ...currentWorkHour?.work_info_2,
                              isAbsent: false,
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                      <Button
                        btnText={t("absenteeism")}
                        shape={"round"}
                        type={"default"}
                        onClick={() => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_2: {
                              ...currentWorkHour?.work_info_2,
                              isAbsent: true,
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                    </Flex>
                  )}
                  {/* Action buttons ends here */}

                  {/* Working hours input fields starts here */}
                  {currentWorkHour?.work_info_2?.isAbsent === false && (
                    <Flex
                      align={"center"}
                      gap={"6px"}
                      className={"work_time__hours"}
                    >
                      <>
                        <Flex
                          align={"center"}
                          className={"time-group work_time__hours--from"}
                          gap={"4px"}
                        >
                          <SelectInput
                            width={"60px"}
                            height={"40px"}
                            placeholder={"--"}
                            name={`individualRecord.${uniqueKey}.working_hours.work_info_2.time_range.start.hour`}
                            value={
                              currentWorkHour?.work_info_2?.time_range?.start
                                ?.hour
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(val) => {
                              const newWorkInfo = {
                                ...currentWorkHour,
                                work_info_2: {
                                  ...currentWorkHour?.work_info_2,
                                  time_range: {
                                    ...currentWorkHour?.work_info_2?.time_range,
                                    start: {
                                      ...currentWorkHour?.work_info_2
                                        ?.time_range?.start,
                                      hour: val,
                                    },
                                  },
                                },
                              }
                              setCurrentWorkHour(newWorkInfo)
                            }}
                          />
                          {":"}
                          <SelectInput
                            width={"60px"}
                            height={"40px"}
                            placeholder={"--"}
                            name={"work_time_from_min"}
                            value={
                              currentWorkHour?.work_info_2?.time_range?.start
                                ?.minute
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            onChange={(val) => {
                              const newWorkInfo = {
                                ...currentWorkHour,
                                work_info_2: {
                                  ...currentWorkHour?.work_info_2,
                                  time_range: {
                                    ...currentWorkHour?.work_info_2?.time_range,
                                    start: {
                                      ...currentWorkHour?.work_info_2
                                        ?.time_range?.start,
                                      minute: val,
                                    },
                                  },
                                },
                              }
                              setCurrentWorkHour(newWorkInfo)
                            }}
                          />
                        </Flex>

                        <span className={"time-separator"}>{"~"}</span>

                        <Flex
                          align={"center"}
                          gap={"4px"}
                          className={"time-group work_time__hours--to"}
                        >
                          <SelectInput
                            width={"60px"}
                            height={"40px"}
                            placeholder={"--"}
                            name={"work_time_to_hour"}
                            value={
                              currentWorkHour?.work_info_2?.time_range?.end
                                ?.hour
                            }
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            onChange={(val) => {
                              const newWorkInfo = {
                                ...currentWorkHour,
                                work_info_2: {
                                  ...currentWorkHour?.work_info_2,
                                  time_range: {
                                    ...currentWorkHour?.work_info_2?.time_range,
                                    end: {
                                      ...currentWorkHour?.work_info_2
                                        ?.time_range?.end,
                                      hour: val,
                                    },
                                  },
                                },
                              }
                              setCurrentWorkHour(newWorkInfo)
                            }}
                          />
                          {":"}
                          <SelectInput
                            width={"60px"}
                            height={"40px"}
                            placeholder={"--"}
                            name={"work_time_to_min"}
                            value={
                              currentWorkHour?.work_info_2?.time_range?.end
                                ?.minute
                            }
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            onChange={(val) => {
                              const newWorkInfo = {
                                ...currentWorkHour,
                                work_info_2: {
                                  ...currentWorkHour?.work_info_2,
                                  time_range: {
                                    ...currentWorkHour?.work_info_2?.time_range,
                                    end: {
                                      ...currentWorkHour?.work_info_2
                                        ?.time_range?.end,
                                      minute: val,
                                    },
                                  },
                                },
                              }
                              setCurrentWorkHour(newWorkInfo)
                            }}
                          />
                        </Flex>
                      </>
                    </Flex>
                  )}
                  {/* Working hours input fields ends here */}
                </Flex>

                {currentWorkHour?.work_info_2?.isAbsent === false && (
                  <Flex align={"center"} className={"break-time-input"}>
                    <label
                      className={"break-time-label"}
                      htmlFor={`${uniqueKey}-break-time-input-work-info-2`}
                    >
                      {t("Break time")}
                      {": "}
                    </label>
                    <div className={"input-field-wrap"}>
                      <InputField
                        name={"break_time"}
                        width={"68px"}
                        size={"large"}
                        id={`${uniqueKey}-break-time-input-work-info-2`}
                        value={currentWorkHour?.work_info_2?.break_time}
                        onChange={(e) => {
                          const newWorkInfo = {
                            ...currentWorkHour,
                            work_info_2: {
                              ...currentWorkHour?.work_info_2,
                              break_time: e.target.value,
                            },
                          }
                          setCurrentWorkHour(newWorkInfo)
                        }}
                      />
                    </div>
                    <span>{t("mins")}</span>
                  </Flex>
                )}
              </>
            )}

            {/* Absenteeism button/indicator starts here*/}
            {currentWorkHour?.work_info_2?.isAbsent && (
              <Button
                onClick={() => {
                  const newWorkInfo = {
                    ...currentWorkHour,
                    work_info_2: {
                      ...currentWorkHour?.work_info_2,
                      isAbsent: null,
                    },
                  }
                  setCurrentWorkHour(newWorkInfo)
                }}
                type={"text"}
                className={"absenteeism-indicator"}
                btnText={t("Absent")}
              />
            )}
            {/* Absenteeism button/indicator ends here*/}
          </>
        ) : null}
      </Flex>
      {/* Sub cell for working hour column [work_info_2] ends here*/}
    </WorkHourTdCellWrap>
  )
}
