import { Grid, theme } from "@project/common"
import { Collapse, Flex, Spin, Typography } from "antd"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border: 1px solid ${theme.colors.error};
  border-radius: 12px;
  padding: 16px;
  .info__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .title_info {
      display: flex;
      gap: 5px;
      span {
        font-weight: bold;
        font-size: 16px;
        color: ${theme.colors.error};
      }
    }
    .sub__info {
      font-weight: bold;
      color: ${theme.colors.error};
    }
  }
  .daily-list-info {
    .ant-collapse-header {
      padding: 0 !important;
    }
    .ant-collapse-content-box {
      padding: 4px 0 !important;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .ant-row:not(:last-child) {
      border-bottom: 1px solid #d2d1d1 !important;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .info__container {
      width: 100%;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    .info__container {
      width: 70%;
    }
  }
`
const DailyListAlert = ({
  facilitiesWithConfirmedBilling,
  isLoading,
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Spin spinning={isLoading}>
      <Wrapper>
        <div className={"info__container"}>
          <div className={"title_info"}>
            <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
            <Typography.Text>
              {t("Property with confirmed billing information exists")}
            </Typography.Text>
          </div>
          <div className={"sub__info"}>
            {t(
              `When editing, registering or deleting, please check with the person in charge of billing.
Confirmed billing information and possible discrepancies`,
            )}
          </div>
        </div>
        <Collapse defaultActiveKey={["1"]} ghost className={"daily-list-info"}>
          <Collapse.Panel
            header={
              <span style={{ color: theme.colors.error }}>
                {t(
                  "Display facilities whose billing information has been confirmed",
                )}
              </span>
            }
            key={"1"}
          >
            {facilitiesWithConfirmedBilling?.data?.map((d, i) => {
              const formattedDate = dayjs(`${d?.year}-${d?.month}-01`).format(
                "YYYY年MM月",
              )
              return (
                <>
                  <Grid
                    labelContent={t(d?.facility_name) || "東松山"}
                    background
                    padding={"10px"}
                  >
                    <Flex gap={8} wrap={"wrap"}>
                      <Typography.Text key={i}>{formattedDate}</Typography.Text>
                    </Flex>
                  </Grid>
                </>
              )
            })}
          </Collapse.Panel>
        </Collapse>
      </Wrapper>
    </Spin>
  )
}

export default DailyListAlert
