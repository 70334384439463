import React from "react"
import { MonthPagination, theme } from "@project/common"
import styled from "styled-components"
import { Flex, Skeleton } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"

const TabTopContentWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${theme.colors.border};
  padding-bottom: 14px;
  flex-wrap: wrap;
`

interface TabTopContentProps {
  tab: string
  isLoading?: boolean
  onChange: (date: Dayjs | null) => void
  change?: "month" | "year"
}
export const TabTopContent = ({
  tab,
  isLoading,
  onChange,
  change = "month",
}: TabTopContentProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { date, panel } = router?.query as any
  return (
    <TabTopContentWrapper>
      {isLoading ? <Skeleton.Button active /> : <div>{tab}</div>}
      <MonthPagination
        prevText={
          panel === "overall_revenue" ? t("previous year") : t("Last month")
        }
        currentText={
          panel === "overall_revenue" ? t("current year") : t("This Month")
        }
        nextText={
          panel === "overall_revenue" ? t("next year") : t("Next month")
        }
        currentMonth={date ? dayjs(date).month() : dayjs().month()}
        currentYear={date ? dayjs(date).year() : dayjs().year()}
        showDate={false}
        width={"80px"}
        change={change}
        onMonthChange={(_, __, date) => {
          onChange(date)
        }}
        onYearChange={(year) => onChange(dayjs([year, 0, 1]))}
      />
    </TabTopContentWrapper>
  )
}
