import { Button, usePrint } from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useAuthContext } from "../../../../../context"
import { getNhifPrintAddressToUsers } from "../../../../../services"
import { scrollToSelectedElement } from "../../../../../utils/scrollToSelectedElement"
import { AcknowledgementPrintAbleContent } from "./AcknowledgementPrintAbleContent"
import { AcknowledgementPrintPageContentOp } from "./AcknowledgementPrintPageContentOp"

export const AcknowledgementPrintPageContent = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const { query, push } = useRouter()
  const { facilityIds, year, month, display_content } = query as any
  const currentDate = dayjs(`${year}-${month}`).isValid()
    ? dayjs(`${year}-${month}`)
    : dayjs()
  const refContent = useRef<null | HTMLDivElement>(null)
  const { handlePrint } = usePrint({
    contentRef: refContent,
    pageStyle: `margin:6mm`,
  })
  const { isLoading, data: response } = useQuery({
    queryKey: [
      "proxy-acknowledgement-print",
      facilityIds,
      year,
      month,
      display_content,
    ],
    queryFn: () =>
      getNhifPrintAddressToUsers({
        facilityIds: facilityIds || facilities?.[0]?.id,
        year: year || dayjs().year(),
        month: month || dayjs().month() + 1,
        phonetic_order: display_content?.split(",")?.includes("6") ? 1 : null,
      }),
    select: (res) => res?.data?.default || [],
  })

  return (
    <Flex vertical gap={16}>
      <AcknowledgementPrintPageContentOp
        scrollTo={() => {
          scrollToSelectedElement(refContent)
        }}
      />
      <Flex gap={16} wrap={"wrap"}>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          disabled={isLoading}
          onClick={() =>
            push(
              `/national-health-insurance-billing/prints/print-proxy-acknowledgement?facilityIds=${facilityIds}&year=${currentDate.year()}&month=${
                currentDate.month() + 1
              }`,
            )
          }
        />
        <Button
          btnText={t("Print")}
          iconType={"print"}
          type={"primary"}
          disabled={isLoading}
          onClick={handlePrint}
        />
      </Flex>

      <div ref={refContent}>
        <AcknowledgementPrintAbleContent
          isLoading={isLoading}
          dataSource={response || []}
        />
      </div>
    </Flex>
  )
}
