import { useRouter } from "next/router"
import React, { useState } from "react"

// packages
import { Divider } from "antd"
import { t } from "i18next"

// commons | components
import {
  AccordionCard,
  ActionBtns,
  Box,
  Button,
  filterObject,
  RadioGroup,
  useUpdateSearchParams,
} from "@project/common"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"
import { ToPrintSection } from "./ToPrintSection"

// styles
import { OnlyPrintContainer } from "./UserTransportMgmtPrint.styles"

// constants
import { DONT_DO_LIST } from "@project/common/src/constants"

// types
import { BinaryType, DisplaySetting } from "./types"
import dayjs from "dayjs"

export const UserTransportMgmtPrint = () => {
  const { query } = useRouter()

  // ! ## query params
  const memoizedQuery: DisplaySetting = React.useMemo(() => {
    const queryConst = query as Partial<
      Record<keyof DisplaySetting, string | string[]>
    >
    const show_driver_entry = queryConst?.show_driver_entry
      ? (queryConst?.show_driver_entry?.toString() as BinaryType)
      : "1"
    const show_place = queryConst?.show_place
      ? (queryConst?.show_place?.toString() as BinaryType)
      : "1"
    const show_vOrD = queryConst?.show_vOrD
      ? (queryConst?.show_vOrD?.toString() as BinaryType)
      : "1"

    return {
      show_driver_entry,
      show_place,
      show_vOrD,
    }
  }, [query])

  const [displaySettings, setDisplaySettings] =
    useState<DisplaySetting>(memoizedQuery)

  const [updateParams] = useUpdateSearchParams()

  const handleInputChange = (
    key: keyof typeof displaySettings,
    value: "1" | "0",
  ) => {
    setDisplaySettings({
      ...displaySettings,
      [key]: value,
    })
  }

  const handleChangeDisplay = () => {
    const date = query.date
    updateParams(
      { ...query, ...displaySettings },
      `/user-transport-management/${date}/printing`,
    )
  }

  const handleReset = () => {
    const date = query.date
    const resetSettings: DisplaySetting = {
      show_driver_entry: "1",
      show_place: "1",
      show_vOrD: "1",
    }
    setDisplaySettings(resetSettings)
    updateParams(
      { ...query, ...resetSettings },
      `/user-transport-management/${date}/printing`,
    )
  }

  return (
    <>
      <AccordionCard
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <>
                {/* Form that display operations */}
                <Box display={"flex"} direction={"column"} gap={16}>
                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    labelWidth={"150px"}
                    align={"flex-start"}
                    label={"Driver's Entry"}
                  >
                    <div className={"TM_driver-entry-inputs"}>
                      <RadioGroup
                        name={"show_driver_entry"}
                        value={displaySettings.show_driver_entry}
                        onChange={(e) =>
                          handleInputChange("show_driver_entry", e.target.value)
                        }
                        options={DONT_DO_LIST}
                      />
                    </div>
                  </OperationFields>

                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    labelWidth={"150px"}
                    align={"flex-start"}
                    label={"Show place"}
                  >
                    <div className={"TM_driver-entry-inputs"}>
                      <RadioGroup
                        name={"show_place"}
                        value={displaySettings.show_place}
                        onChange={(e) =>
                          handleInputChange("show_place", e.target.value)
                        }
                        options={DONT_DO_LIST}
                      />
                    </div>
                  </OperationFields>

                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    labelWidth={"150px"}
                    align={"flex-start"}
                    label={"Show Vehicle / Driver"}
                  >
                    <div className={"TM_driver-entry-inputs"}>
                      <RadioGroup
                        name={"show_vOrD"}
                        value={displaySettings.show_vOrD}
                        onChange={(e) =>
                          handleInputChange("show_vOrD", e.target.value)
                        }
                        options={DONT_DO_LIST}
                      />
                    </div>
                  </OperationFields>

                  <ActionBtns
                    className={"operation-action-btns"}
                    justify={"flex-start"}
                  >
                    <Button
                      shape={"round"}
                      type={"default"}
                      btnText={t("Reset Search")}
                      onClick={() => handleReset()}
                    />
                    <Button
                      shape={"round"}
                      type={"primary"}
                      iconType={"search"}
                      btnText={t("Search")}
                      onClick={() => handleChangeDisplay()}
                    />
                  </ActionBtns>
                </Box>

                <Divider />

                <ActionBtns
                  className={"operation-print-btns"}
                  justify={"flex-start"}
                >
                  <Button
                    shape={"round"}
                    type={"default"}
                    btnText={t("Back")}
                    onClick={() =>
                      updateParams(
                        filterObject({
                          facility_ids: query?.facility_ids,
                          use_service: query?.service_type,
                          list_display: query?.list_display,
                          driver_id: query?.driver_staff_id,
                          date: query?.date,
                        }),
                        `/user-transport-management/${query.date as string}`,
                      )
                    }
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    iconType={"print"}
                    btnText={t("Print")}
                    onClick={() => {
                      const originalTitle = document.title
                      document.title = t(
                        "Transportation Management on {{engDate}}",
                        {
                          engDate: dayjs(query.date as string)
                            .locale("en")
                            .format("dddd, MMMM DD, YYYY"),
                          jaDate: dayjs(query.date as string)
                            .locale("ja")
                            .format("YYYY年MM月DD日 dd"),
                        },
                      )
                      window.print()
                      document.title = originalTitle
                    }}
                  />
                </ActionBtns>

                <ToPrintSection displaySettings={memoizedQuery} />
              </>
            ),
          },
        ]}
      />

      {/* This component here is what gets displayed in print mode but not in normal screen mode. */}
      <OnlyPrintContainer>
        <ToPrintSection displaySettings={memoizedQuery} />
      </OnlyPrintContainer>
    </>
  )
}
