import { Skeleton } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import {
  ActualTableContent,
  BoxTitle,
  ContentBox,
  TableContainer,
} from "./DisabledUserOutpatientBenefitsPrintContent.style"
import { useRouter } from "next/router"
import { getFullEraDate } from "@project/common"

export const DUOBPBasicInfo = ({
  loading,
  data,
  printSettingValues,
}: {
  loading: boolean
  data: any
  printSettingValues?: any[]
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { year_month } = router.query as any
  const date = year_month ? dayjs(year_month) : dayjs()

  const getbasicDataTotal = (data: any[], key: string) => {
    return data?.reduce(
      (total, item) => total + parseInt(item[key] ? item[key] : 0),
      0,
    )
  }
  return (
    <ContentBox>
      {printSettingValues?.includes("1") && (
        <span id={"form__num"}>{t("(Form 1)")}</span>
      )}
      <BoxTitle>
        <h5>{t("Benefit Expense Claim Summary")}</h5>
      </BoxTitle>
      <p>
        {t("Target billing month: {{enDate}}", {
          enDate: `${dayjs(date)?.locale("en")?.format("MMMM, YYYY")}`,
          jaDate:
            getFullEraDate(dayjs(date).year(), dayjs(date).month()) +
            `${dayjs(date).month() + 1}月分`,
        })}
      </p>
      {loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          <TableContainer>
            <p className={"title"}>{t("Office information")}</p>
            <ActualTableContent>
              <tbody id={"duobp__table-body"}>
                <tr>
                  <td className={"w-200"}>{t("Office Number")}</td>
                  <td className={"w-full"}>{data?.OfficeNumber || ""}</td>
                </tr>
                <tr>
                  <td className={"w-200"}>{t("Office Name")}</td>
                  <td className={"w-full"}>{data?.FacilityName || ""}</td>
                </tr>
              </tbody>
            </ActualTableContent>
          </TableContainer>
          <ActualTableContent>
            <tbody id={"duobp__table-body"}>
              <tr>
                <td className={"w-200 p-10"}>{t("Total billing amount")}</td>
                <td className={"w-full p-10"}>
                  {data?.BillingAmount?.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </ActualTableContent>
          <TableContainer>
            <p className={"title"}>{t("Billing details information")}</p>
            <ActualTableContent>
              <thead id={"duobp__table-head"}>
                <tr>
                  <th>{t("No.")}</th>
                  <th>{t("Billing address")}</th>
                  <th>{t("Number")}</th>
                  <th>{t("Total Cost")}</th>
                  <th>{t("Benefit Billed Amount")}</th>
                  <th>{t("User Fee")}</th>
                  <th>{t("Local Government Subsidy Amount")}</th>
                </tr>
              </thead>
              <tbody id={"duobp__table-body"}>
                {data?.ProvisionCityData?.map((cityData, index) => (
                  <tr key={index}>
                    <td className={"align-right w-80 p-10"}>{index + 1}</td>
                    <td className={"align-right p-10"}>
                      {cityData?.CityName || ""}
                    </td>
                    <td className={"align-right p-10"}>
                      {cityData?.Number || ""}
                    </td>
                    <td className={"align-right p-10"}>
                      {cityData?.Amount?.toLocaleString()}
                    </td>
                    <td className={"align-right p-10"}>
                      {cityData?.ClaimedAmountOfBenefit?.toLocaleString()}
                    </td>
                    <td className={"align-right p-10"}>
                      {cityData?.UserBurden?.toLocaleString()}
                    </td>
                    <td className={"align-right p-10"}>
                      {cityData?.Subsidy?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className={"align-center"}>
                    {t("Total")}
                  </td>
                  <td className={"align-right"}>
                    {getbasicDataTotal(
                      data?.ProvisionCityData,
                      "Number",
                    )?.toLocaleString()}
                  </td>
                  <td className={"align-right"}>
                    {getbasicDataTotal(
                      data?.ProvisionCityData,
                      "Amount",
                    )?.toLocaleString()}
                  </td>
                  <td className={"align-right"}>
                    {getbasicDataTotal(
                      data?.ProvisionCityData,
                      "ClaimedAmountOfBenefit",
                    )?.toLocaleString()}
                  </td>
                  <td className={"align-right"}>
                    {getbasicDataTotal(
                      data?.ProvisionCityData,
                      "UserBurden",
                    )?.toLocaleString()}
                  </td>
                  <td className={"align-right"}>
                    {getbasicDataTotal(
                      data?.ProvisionCityData,
                      "Subsidy",
                    )?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </ActualTableContent>
          </TableContainer>
        </>
      )}
    </ContentBox>
  )
}
