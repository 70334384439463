import {
  EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB,
  EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB_INITIAL_COLS,
} from "./constants"

export const esbsdRowsTotal = (cols: any[]) => {
  const totalData = cols?.reduce(
    (acc, curr) => ({
      user_wage: acc?.user_wage + curr?.user_wage,
      user_fee: acc?.user_fee + curr?.user_fee,
      salary_cost: acc?.salary_cost + curr?.salary_cost,
      provision_bonuse: acc?.provision_bonuse + curr?.provision_bonuse,
      retirement_benefit_costs:
        acc?.retirement_benefit_costs + curr?.retirement_benefit_costs,
      statutory_welfare_cost:
        acc?.statutory_welfare_cost + curr?.statutory_welfare_cost,
      welfare_cost: acc?.welfare_cost + curr?.welfare_cost,
      travel_cost: acc?.travel_cost + curr?.travel_cost,
      equipment_cost: acc?.equipment_cost + curr?.equipment_cost,
      supplies_cost: acc?.supplies_cost + curr?.supplies_cost,
      printing_cost: acc?.printing_cost + curr?.printing_cost,
      utility_cost: acc?.utility_cost + curr?.utility_cost,
      fuel_cost: acc?.fuel_cost + curr?.fuel_cost,
      repair_cost: acc?.repair_cost + curr?.repair_cost,
      lease_cost: acc?.lease_cost + curr?.lease_cost,
      order_activity_cost: acc?.order_activity_cost + curr?.order_activity_cost,
      conference_fee: acc?.conference_fee + curr?.conference_fee,
      non_life_insurance_premium:
        acc?.non_life_insurance_premium + curr?.non_life_insurance_premium,
      rent: acc?.rent + curr?.rent,
      educational_expenses:
        acc?.educational_expenses + curr?.educational_expenses,
      public_dues: acc?.public_dues + curr?.public_dues,
      depreciation_expense:
        acc?.depreciation_expense + curr?.depreciation_expense,
      special_reserves: acc?.special_reserves + curr?.special_reserves,
      uncollectible_reserves:
        acc?.uncollectible_reserves + curr?.uncollectible_reserves,
      uncollectible_amount:
        acc?.uncollectible_amount + curr?.uncollectible_amount,
      miscellaneous_expenses:
        acc?.miscellaneous_expenses + curr?.miscellaneous_expenses,
    }),
    {
      ...EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB_INITIAL_COLS,
    },
  )
  return totalData
}
export const esbsdGrandTotal = (item: any) => {
  let grandTotal = 0
  EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB?.map((v) => {
    grandTotal += item?.[v?.key] || 0
  })
  return grandTotal
}
