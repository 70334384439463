import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "antd"
import { useFormik } from "formik"
import * as yup from "yup"
import {
  Button,
  FULL_MONTHS_NAME,
  InputAreaField,
  Tag,
  theme,
  useNotification,
} from "@project/common"
import {
  APPROVED_STATUS,
  FULL_DATE_FORMAT,
  REJECTED_STATUS,
} from "../../../constants"
import {
  ActionCellStyled,
  LeavePopoverStyled,
  PopoverContent,
  PopoverTitle,
} from "./ActionConfirmPopupModal.style"
import { useMutation } from "react-query"
import { updateFacilityUserRecordRequestStatus } from "../../../services"

type ActionButtonsProps = {
  disabled?: boolean
  requestedDate?: string | null | Dayjs
  variant: "SINGLE_ROW_ACTION" | "BULK_ACTION"
  id: React.Key[] | string
  refetch?: () => void
  request_status?: number
  rejected_reason?: string
}

export const ActionConfirmPopupModal = ({
  disabled,
  id = [],
  refetch,
  request_status,
  ...props
}: ActionButtonsProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [status, setStatus] = React.useState(null)
  const { showToast } = useNotification()
  const dayjsReqDate = dayjs(props.requestedDate).isValid()
    ? dayjs(props.requestedDate)
    : dayjs()

  const hide = () => {
    setOpen(false)
    setStatus(null)
    setFieldValue("reason", "")
  }

  //update request list status
  const { mutate, isLoading } = useMutation({
    mutationFn: updateFacilityUserRecordRequestStatus,
    onSuccess: () => {
      hide()
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
      refetch()
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const { values, handleSubmit, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        reason: "",
      },
      validationSchema: yup.object().shape({
        reason:
          status === 2
            ? yup.string().required(t("Required"))
            : yup.string().nonNullable().notRequired(),
      }),

      onSubmit: (values) => {
        if (typeof id == "string") {
          mutate([
            {
              id: +id,
              status: status,
              rejected_reason: values?.reason,
            },
          ])
        } else {
          const data = id?.map((v) => ({
            id: Number(v),
            status: status,
            rejected_reason: values?.reason,
          }))
          mutate(data)
        }
      },
    })
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  return (
    <ActionCellStyled
      gap={8}
      align={"center"}
      justify={"center"}
      className={"leave-listing-action-cell"}
    >
      {request_status === APPROVED_STATUS ? (
        <>
          <Tag title={t("Approved")} color={"success"} />
        </>
      ) : request_status === REJECTED_STATUS ? (
        <Flex vertical>
          <Tag title={t("Rejected")} color={"error"} />
        </Flex>
      ) : (
        <LeavePopoverStyled
          content={
            <PopoverContent mt={16}>
              <form onSubmit={handleSubmit}>
                {status === REJECTED_STATUS && (
                  <div>
                    <label className={"label"} htmlFor={"reason"}>
                      <span>{t("Reason")}</span>
                      {status === REJECTED_STATUS && (
                        <span className={"required-star"}>{"*"}</span>
                      )}
                    </label>
                    <InputAreaField
                      id={"reason"}
                      rows={3}
                      placeholder={"---"}
                      name={"reason"}
                      onChange={handleChange}
                      value={values?.reason || null}
                      error={status === 2 ? errors?.reason : ""}
                    />
                  </div>
                )}

                <Flex
                  className={"leave-reject-action-btns"}
                  justify={"flex-end"}
                  gap={4}
                  wrap={"wrap"}
                >
                  <Button
                    btnText={t("Cancel")}
                    shape={"round"}
                    type={"grayed"}
                    onClick={hide}
                    disabled={isLoading}
                  />
                  {status === REJECTED_STATUS && (
                    <Button
                      btnText={t("Reject")}
                      shape={"round"}
                      type={"error"}
                      htmlType={"submit"}
                      isLoading={isLoading}
                    />
                  )}
                  {status === APPROVED_STATUS && (
                    <Button
                      btnText={t("Approve")}
                      shape={"round"}
                      htmlType={"submit"}
                      isLoading={isLoading}
                      type={"success"}
                    />
                  )}
                </Flex>
              </form>
            </PopoverContent>
          }
          title={
            <PopoverTitle gap={8} align={"flex-start"}>
              <img className={"alert-icon"} src={"/assets/icons/alert.svg"} />
              <div className={"popover-title"}>
                {props.variant === "BULK_ACTION"
                  ? t(
                      `Are you certain you wish to ${
                        status === 1 ? "approve?" : "decline?"
                      }`,
                    )
                  : t(
                      `Are you certain you wish to ${
                        status === 1 ? "approve" : "decline"
                      } the registered time of {{enDate}}?`,
                      {
                        enDate: `${
                          FULL_MONTHS_NAME[dayjsReqDate?.month()]
                        } ${dayjsReqDate?.date()}, ${dayjsReqDate?.year()}`,
                        jaDate: dayjsReqDate?.format(FULL_DATE_FORMAT),
                      },
                    )}
              </div>
            </PopoverTitle>
          }
          trigger={"click"}
          open={open}
          onOpenChange={(newOpen: boolean) => handleOpenChange(newOpen)}
          className={"leave-reject-popover"}
          rootClassName={"leave-request-root-container"}
          arrow={false}
          placement={"leftBottom"}
        >
          <Flex align={"center"} gap={4}>
            <Button
              btnText={t("Approve")}
              shape={"round"}
              onClick={() => setStatus(APPROVED_STATUS)}
              disabled={disabled}
              btnBg={theme.colors.success}
              borderColor={theme.colors.success}
              hoverBorderColor={theme.colors.success}
              textColor={theme.colors.white}
              style={{
                pointerEvents: disabled ? "none" : "auto",
              }}
            />

            <Button
              btnText={t("Reject")}
              shape={"round"}
              type={"link"}
              disabled={disabled}
              textColor={theme.colors.error}
              hoverBorderColor={"none"}
              onClick={() => setStatus(REJECTED_STATUS)}
              style={{
                textDecoration: "underline",
                pointerEvents: disabled ? "none" : "auto",
              }}
            />
          </Flex>
        </LeavePopoverStyled>
      )}
    </ActionCellStyled>
  )
}
