// packages
import styled from "styled-components"

// commons
import { Box, theme } from "@project/common"

// TM as in Transport management
export const TMDriverListContainer = styled(Box)`
  h3.table-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    line-height: 23.17px;
    padding-bottom: 4px;
    height: 36px;
    border-bottom: 1px solid ${theme.colors.border};
  }
`
