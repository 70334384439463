import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationOptionContainer,
  SelectInput,
} from "@project/common"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { YM_FORMAT } from "../../constants"

const Wrapper = styled.div`
  .date_picker_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 5px;
    align-items: center;

    @media screen and (width: 540px) {
      display: block;

      justify-content: flex-start;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .search-div {
    @media screen and (max-width: 539px) {
      display: flex;
      flex-direction: column;
      width: 100% !important;
    }
    .divider {
      @media screen and (max-width: 540px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`
interface IProps {
  onSearch: any
  FACILITY_OPTIONS: any[]
  selectedFacility: string | number | string[]
  setSelectedFacility: any
  setDate: any
  date: any
  resetHandler: any
  isView?: boolean
}
const Accordion: React.FC<IProps> = ({
  onSearch,
  FACILITY_OPTIONS,
  selectedFacility,
  setSelectedFacility,
  setDate,
  date,
  resetHandler,
  isView = false,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AccordionCard
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <OperationOptionContainer>
                <div className={"field__content flex-start fields"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Facility Name ")}
                      width={"138px"}
                    />
                  </div>
                  <SelectInput
                    width={"282px"}
                    name={"facility"}
                    value={selectedFacility}
                    options={FACILITY_OPTIONS}
                    placeholder={"--"}
                    onChange={(value) => setSelectedFacility(value)}
                  />
                </div>
                <div className={"search__content fields search-div"}>
                  <LabelTextWithLeftArrow label={t("Date")} width={"138px"} />
                  <div className={"date_picker_content"}>
                    {!isView ? (
                      <DatePicker
                        picker={"month"}
                        style={{
                          width: "158px",
                        }}
                        onDateChange={(v) => setDate(v)}
                        date={dayjs(date)?.isValid() ? dayjs(date) : null}
                        format={YM_FORMAT}
                      />
                    ) : (
                      <DatePicker
                        style={{
                          width: "158px",
                        }}
                        onDateChange={(v) =>
                          setDate({
                            ...date,
                            from_date: dayjs(v).format("YYYY-MM-DD"),
                          })
                        }
                        date={
                          dayjs(date?.from_date)?.isValid()
                            ? dayjs(date?.from_date)
                            : null
                        }
                        format={"YYYY年MM月DD日"}
                      />
                    )}
                  </div>
                </div>
                <div className={"search__content buttons"}>
                  <Button
                    btnText={t("Search")}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    iconType={"search"}
                    htmlType={"submit"}
                    onClick={() => onSearch()}
                  />
                  <Button
                    btnText={t("Reset Search")}
                    size={"large"}
                    shape={"round"}
                    onClick={resetHandler}
                  />
                </div>
              </OperationOptionContainer>
            ),
          },
        ]}
      />
    </Wrapper>
  )
}

export default Accordion
