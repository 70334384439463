import { getFullEraDate, GRADES } from "@project/common"
import { Skeleton, Typography } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { DUOPFotterTable } from "./DUOPFotterTable"
import {
  ActualTableContent,
  BusinessPlaceNameContent,
  ContentBox,
  FlexContainer,
  StatementTopTextContent,
  StyledWidthContent,
} from "./DisabledUserOutpatientBenefitsPrintContent.style"
import { ServiceCodeTypeTable } from "./ServiceCodeTypeTable"
import { ServiceContentsTable } from "./ServiceContentsTable"
import { ServiceTypeTableContent } from "./ServiceTypeTableContent"

const StatementTableContent = ({
  loading,
  data,
  ...rest
}: {
  loading: boolean
  data: any
  totalPage: number
  currentPage: number
  printSetting?: any[]
  serviceType?: number
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        pageBreakBefore: "always",
      }}
    >
      <ContentBox>
        {rest?.printSetting?.includes("1") && (
          <span id={"form__num"}>{t("(Form 2)")}</span>
        )}
        <StatementTopTextContent>
          <Typography.Title level={5}>
            {t("Statement of nursing care benefits, training benefits, etc")}
          </Typography.Title>
          <Typography.Text className={"text"}>
            {t("duop_statement_top_text")}
          </Typography.Text>
        </StatementTopTextContent>
        {loading ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <>
            <FlexContainer gap={16}>
              <div className={"left_content"}>
                <ActualTableContent>
                  <tbody id={"duobp__table-body"}>
                    <tr>
                      <td className={"w-200"}>{t("City number")}</td>
                      <td className={"w-200"}>{data?.a_01 || ""}</td>
                    </tr>
                    <tr>
                      <td className={"w-200"}>
                        {t("Subsidy municipality number")}
                      </td>
                      <td className={"w-200"}>{data?.a_02 || ""}</td>
                    </tr>
                  </tbody>
                </ActualTableContent>
              </div>
              <div className={"right_content"}>
                <ActualTableContent>
                  <tbody id={"duobp__table-body"}>
                    <tr>
                      <td className={"w-50-h-50  text-no-wrap"}>
                        {t(
                          getFullEraDate(
                            data?.b_01 || dayjs().year(),
                            data?.b_02 || dayjs().month() + 1,
                          )?.substring(0, 2),
                        )}
                      </td>
                      <td className={"w-50-h-50"}>
                        {t(
                          getFullEraDate(
                            data?.b_01 || dayjs().year(),
                            data?.b_02 || dayjs().month() + 1,
                          )?.substring(2, 4),
                        )}
                      </td>
                      <td className={"w-50-h-50"}>{t("Year")}</td>
                      <td className={"w-50-h-50"}>
                        {data?.b_02 || dayjs().month() + 1}
                      </td>
                      <td className={"w-50-h-50 text-no-wrap"}>
                        {t("Monthly")}
                      </td>
                    </tr>
                  </tbody>
                </ActualTableContent>
              </div>
            </FlexContainer>
            <FlexContainer gap={16}>
              <div className={"left_content"}>
                <ActualTableContent>
                  <tbody id={"duobp__table-body"}>
                    <tr>
                      <td className={"w-200"}>{t("Beneficiary ID number")}</td>
                      <td className={"w-200"}>{data?.a_03 || ""}</td>
                    </tr>
                    <tr>
                      <td className={"w-200"}>
                        {t(
                          "Name of person with a disability for whom payment is determined",
                        )}
                      </td>
                      <td className={"w-200"}>{data?.a_04 || ""}</td>
                    </tr>
                    <tr>
                      <td className={"w-200"}>
                        {t(
                          "Name of User with a disability related to payment decision",
                        )}
                      </td>
                      <td className={"w-200"}>{""}</td>
                    </tr>
                  </tbody>
                </ActualTableContent>
              </div>
              <div className={"left_content"}>
                <ActualTableContent>
                  <tbody id={"duobp__table-body"}>
                    <tr>
                      <td className={"w-20"} rowSpan={4}>
                        <span id={"rotate-190"}>{t("Billing company")}</span>
                      </td>
                      <td className={"w-200"}>
                        {t("Designated office number")}
                      </td>
                      <td className={"w-200"} colSpan={2}>
                        {data?.a_06 || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={"w-200"} rowSpan={2}>
                        {t(
                          "Name of business operator and its place of business",
                        )}
                      </td>
                      <td className={"p-0"} colSpan={2}>
                        <BusinessPlaceNameContent>
                          <div className={"c_name"}>
                            <span>{t(data?.a_07_zip || "")}</span>
                            <span>{t(data?.a_07_address || "")}</span>
                            <span>{t(data?.a_07_name || "")}</span>
                          </div>
                        </BusinessPlaceNameContent>
                      </td>
                    </tr>
                    <tr>
                      <td className={"w-100"}>
                        {t("Regional classification")}
                      </td>
                      <td className={"w-100"}>
                        {" "}
                        {GRADES?.find((v) => v?.value === data?.c_01)?.label ||
                          ""}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className={"p-0"}>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              borderRight: "1px solid black",
                              padding: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {t(
                              "Continuous employment support type A employer burden reduction/exemption measures implemented",
                            )}
                          </div>
                          <div
                            style={{
                              width: "190px",
                              padding: "5px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {data?.c_24 || ""}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </ActualTableContent>
              </div>
            </FlexContainer>
            <StyledWidthContent $value={"90%"}>
              <ActualTableContent>
                <tbody id={"duobp__table-body"}>
                  <tr>
                    <td className={"align-center"}>
                      {t("Maximum monthly payment by users ①")}
                    </td>
                    <td className={"min-w-185 align-center"}>
                      {data?.c_02 || ""}
                    </td>
                    <td className={"align-center"}>
                      {t(
                        "Persons eligible for continuous employment support type A reduction/exemption",
                      )}
                    </td>
                    <td className={"min-w-185 align-center"}>
                      {data?.c_25 || t("None")}
                    </td>
                  </tr>
                </tbody>
              </ActualTableContent>
            </StyledWidthContent>
            <ActualTableContent>
              <tbody id={"duobp__table-body"}>
                <tr>
                  <td className={"w-min-200 align-center"} rowSpan={2}>
                    {t("User burden upper limit management office")}
                  </td>
                  <td className={"w-min-200 align-center"}>
                    {t("Designated office number")}
                  </td>
                  <td className={"w-min-200 align-center"}>{""}</td>
                  <td className={"w-min-200 align-center"}>
                    {t("Management results")}
                  </td>
                  <td className={"w-min-200 align-center"}>{""}</td>
                  <td className={"w-min-200 align-center"}>
                    {t("Management result amount")}
                  </td>
                  <td className={"w-min-200 align-center"}>{""}</td>
                </tr>
                <tr>
                  <td className={"w-min-200 align-center"}>
                    {t("Business name")}
                  </td>
                  <td className={"w-min-200 align-center"} colSpan={5}>
                    {""}
                  </td>
                </tr>
              </tbody>
            </ActualTableContent>
            <ServiceTypeTableContent data={data} />
            <ServiceContentsTable data={data} />
            <ServiceCodeTypeTable data={data} serviceType={rest?.serviceType} />
            {rest?.printSetting?.includes("3") && (
              <DUOPFotterTable
                totalPage={rest?.totalPage}
                currentPage={rest?.currentPage}
              />
            )}
          </>
        )}
      </ContentBox>
    </div>
  )
}

export default StatementTableContent
