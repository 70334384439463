export const getUserInvoiceTotal = (data) =>
  data?.reduce((a, b) => {
    return a + b?.invoice_total
  }, 0)
export const getActualCostTotal = (costs) =>
  costs?.reduce((a, b) => {
    return a + b?.actual_cost_price
  }, 0)
export const listSerialize = (user_list) =>
  user_list?.map((item) => {
    const cost_data = {}
    item?.actual_costs?.map((actual_cost) => {
      cost_data[actual_cost?.actual_cost_name] = actual_cost?.actual_cost_price
    })
    return {
      ...cost_data,
      actual_cost_total: getActualCostTotal(item?.actual_costs),
      facility_id: item?.facility?.id,
      invoice_total: item?.invoice_total,
    }
  })

export const getCostTotalData = (data, item) =>
  data?.reduce(
    (a, b) => {
      return {
        [item?.actual_cost_name]:
          a[`${item?.actual_cost_name}`] + b[`${item?.actual_cost_name}`],
      }
    },
    {
      [item?.actual_cost_name]: 0,
    },
  )

export const getGrandTotalData = (data: any[], key: string) =>
  data?.reduce((a, b) => {
    return a + b[key]
  }, 0)
