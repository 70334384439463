import React, { useMemo, useState } from "react"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"
import { useMutation, useQuery } from "react-query"
import { Form, FormProps, Space, Spin } from "antd"

// commons | components
import {
  Box,
  Card,
  Grid,
  omit,
  Button,
  SelectInput,
  FACILITY_TYPES,
  useNotification,
  dynamicLangString,
  keys,
  SERVICE_TYPE_VALUES,
} from "@project/common"
import { NumberedTitle } from "./components/NumberedTitle"
import { AdditionForm } from "./components/AdditionForm"
import { BasicInfoForm } from "./components/BasicInfoForm"
import { SubtractionForm } from "./components/SubtractionForm"

// services
import {
  SystemStatusMgmtData,
  updateSystemStatusService,
  getSystemStatusMgmtService,
  SystemStatusMgmtDataType,
} from "../../services/system-status-mgmt.service"

// types
import { QueryParams } from "./types"

// constants
import { useAuthContext } from "../../context"
import { DEFAULT_ZERO_KEYS } from "./constants"
import {
  hasPermissionForFacility,
  SystemStatusManagementPermission,
} from "../../utils/PermissionKeys"

// Some attributes need to have default value i.e. "0" this function will check if they already have value if not then we assign "0"
function setInitialVal(
  values?: SystemStatusMgmtDataType,
): SystemStatusMgmtDataType | null {
  if (!values?.data) {
    return null
  }
  const { data: formData } = values
  return {
    ...values,
    data: keys(formData).reduce<SystemStatusMgmtData>(
      (acc, key) => ({
        ...acc,
        staff_arrangement: formData?.staff_arrangement
          ? formData?.staff_arrangement
          : "1",
        compensation_system: formData?.compensation_system
          ? formData?.compensation_system
          : "1",
        [key]: !DEFAULT_ZERO_KEYS.includes(key)
          ? formData[key]
          : formData[key] || "0",
      }),
      formData,
    ),
  }
}

export const SystemStatusMgmtForm = ({
  memoizedQuery,
}: {
  memoizedQuery: QueryParams
}) => {
  const { showToast } = useNotification()
  const { facilities, isOwner, permissions } = useAuthContext()

  const [staffArrangement, setStaffArrangement] = useState("1")

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        SystemStatusManagementPermission,
        "write",
        memoizedQuery?.facility_id,
      ),
    [isOwner, permissions, memoizedQuery],
  )

  const { push } = useRouter()

  const [form] = Form.useForm<SystemStatusMgmtData>()

  const currentFacilityName = facilities.find(
    (facility) => facility.id == memoizedQuery?.facility_id,
  )?.facility_name

  const newURLSearchParam = new URLSearchParams({
    month: (memoizedQuery.year_month.month() + 1).toString(),
    year: memoizedQuery.year_month.year().toString(),
    facilityId: memoizedQuery?.facility_id + "",
    facility_name: currentFacilityName,
  })

  // 1. Fetching all system status data of current {{facility}}
  const {
    data: systemStatusData,
    isLoading: systemStatusDataLoading,
    isFetching,
  } = useQuery(
    ["system-status-data", memoizedQuery.year_month],
    () =>
      getSystemStatusMgmtService(
        omit(
          {
            ...memoizedQuery,
            month: memoizedQuery.year_month.month() + 1,
            year: memoizedQuery.year_month.year(),
          },
          ["year_month"],
        ),
      ),
    {
      refetchOnWindowFocus: false,
      select: (data) => setInitialVal(data),
      onSuccess: (data) => {
        form.setFieldsValue({ ...data.data })
        setStaffArrangement(data?.data?.staff_arrangement)
      },
      onError: () => {
        showToast({
          message: dynamicLangString([
            t("Error while fetching system status data."),
          ]),
          type: "error",
        })
      },
    },
  )

  // 2. Update system status of current {{facility}}
  const { mutate: updateSystemStatus, isLoading: updatingSystemStatus } =
    useMutation({
      mutationFn: (payload: SystemStatusMgmtData) =>
        updateSystemStatusService({
          params: omit(
            {
              ...memoizedQuery,
              month: memoizedQuery.year_month.month() + 1,
              year: memoizedQuery.year_month.year(),
              target_wage_achievement_addition:
                payload.service_type_id === SERVICE_TYPE_VALUES.TYPE_2 &&
                payload?.staff_arrangement != "1"
                  ? "0"
                  : payload?.staff_arrangement,
            },
            ["year_month"],
          ),
          payload,
        }),
      onSuccess: () => {
        showToast({
          message: dynamicLangString([
            t("System status management"),
            t("Updated Successfully"),
          ]),
          type: "success",
        })
        push("/facility-information")
      },

      onError: () =>
        showToast({
          message: dynamicLangString([
            t("Something went wrong,"),
            t("Please try again."),
          ]),
          type: "error",
        }),
    })

  // 1. filter operation form submission event
  const onFinish: FormProps<SystemStatusMgmtData>["onFinish"] = (values) => {
    // Sanitizing form value before sending for the mutation
    const sanitizedValue: SystemStatusMgmtData = {
      ...values,
      avarage_previous_user: +values.avarage_previous_user,
      capacity: +values.capacity,
      facility_id: memoizedQuery.facility_id,
      month: memoizedQuery.year_month.month() + 1,
      year: memoizedQuery.year_month.year(),
      burden_reduction_load: +values.burden_reduction_load,
      burden_reduction_load_unit: values.burden_reduction_load_unit || "YEN",
      provisional_number_of_user: +values.provisional_number_of_user,
    }

    if (!values.is_burden_reduction) {
      sanitizedValue["burden_reduction_load"] = null
      sanitizedValue["burden_reduction_load_unit"] = null
    }
    if (values?.compensation_system == "2") {
      sanitizedValue["average_monthly_wage"] = null
    }

    updateSystemStatus(sanitizedValue)
  }

  const initalValue = systemStatusData?.data

  return (
    <Spin spinning={systemStatusDataLoading || isFetching}>
      <Form
        form={form}
        onFinish={onFinish}
        name={"control-hooks"}
        initialValues={initalValue}
        scrollToFirstError
      >
        <Card
          title={t("{{facility}} {{date}} System status management", {
            date: memoizedQuery.year_month.format("YYYY年MM月"),
            facility: facilities.find(
              (item) => item.id == memoizedQuery.facility_id,
            )?.facility_name,
          })}
        >
          <Box className={"facility-type-form"}>
            <NumberedTitle number={"1"}>{t("Set facility type")}</NumberedTitle>

            <Grid labelContent={t("Type")} background required labelSpan={6}>
              <Space size={16} wrap>
                <Box w={208} miw={208} maw={208}>
                  <Form.Item
                    name={"service_type_id"}
                    id={"service_type_id"}
                    rules={[{ required: true, message: t("Required") }]}
                  >
                    <SelectInput
                      name={"service_type_id"}
                      options={FACILITY_TYPES}
                      placeholder={"---"}
                      popupMatchSelectWidth={false}
                      disabled={systemStatusData?.has_contract}
                    />
                  </Form.Item>
                </Box>
                <Button
                  btnText={t("Click here to set business and service hours")}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => {
                    window.open(
                      `/business-day-management/registration?${newURLSearchParam}`,
                      "_blank",
                    )
                  }}
                />
              </Space>
            </Grid>
          </Box>

          {/* 2. Set the status of the facility */}
          <BasicInfoForm
            form={form}
            setStaffArrangement={(v) => setStaffArrangement(v)}
          />

          {/* 3. Addition: */}
          <AdditionForm
            form={form}
            memoizedQuery={memoizedQuery}
            staffArrangement={staffArrangement}
          />

          {/* 4. Subtraction: */}
          <SubtractionForm form={form} systemStatusData={systemStatusData} />

          <Box display={"flex"} gap={12} mt={24}>
            <Button
              shape={"round"}
              btnText={t("Cancel")}
              disabled={updatingSystemStatus}
              onClick={() => push("/facility-information")}
            />
            <Button
              shape={"round"}
              type={"primary"}
              btnText={t("Save")}
              htmlType={"submit"}
              disabled={!hasEditAccess || updatingSystemStatus}
              isLoading={updatingSystemStatus}
            />
          </Box>
        </Card>
      </Form>
    </Spin>
  )
}
