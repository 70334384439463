import { Flex, Typography } from "antd"
import React, { useEffect } from "react"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { useTranslation } from "react-i18next"
import { Button, Table, useUpdateSearchParams } from "@project/common"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { ColumnsType } from "antd/lib/table"
import dayjs from "dayjs"
import { EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB } from "./constants"
import { useQuery } from "react-query"
import { getAllFacilityBusinessSaleDivision } from "../../../services"
import { esbsdGrandTotal, esbsdRowsTotal } from "./helper"
import { useRouter } from "next/router"
import { useWagesRevenueContext } from "../../../context"

export const EmploymentSupportBusinessSalesDivisionTabList = ({
  setSelectedCol,
  params,
  setSearchParams,
  hasEditAccess,
}: {
  setSelectedCol: (values: any) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { panel } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const { downloadAbleDataOf, setDownloadAbleDataOf } = useWagesRevenueContext()
  const { isLoading, isFetching, data } = useQuery<any>({
    queryKey: ["employment-support-business-sales-division-tab", params],
    queryFn: () =>
      getAllFacilityBusinessSaleDivision({
        year: 2024,
        month: 4,
      }),
    select: (res) => {
      return {
        ...res,
        msg: res?.msg?.map((v) => ({
          ...v,
          user_wage: 500,
          user_fee: 200,
        })),
      }
    },
    refetchOnWindowFocus: false,
  })
  const dataSource = EMPLOYMENT_SUPPORT_BUSINESS_SALES_DIVISION_TAB || []
  const getDynamicFacilityColumns = (mode: "download" | "view") => {
    return (
      data?.msg?.map((item) => {
        return {
          key: item?.id,
          title:
            mode === "download"
              ? item?.facility_name
              : () => {
                  return (
                    <Flex vertical gap={8} align={"center"}>
                      <Typography.Text>
                        {item?.facility_name || ""}
                      </Typography.Text>
                      <Button
                        btnText={t("edit")}
                        iconType={"blue-pencil"}
                        shape={"round"}
                        onClick={() => setSelectedCol(item)}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        disabled={!hasEditAccess(item?.facility_id)}
                      />
                    </Flex>
                  )
                },
          align: "center",
          width: 100,
          dataIndex: item?.id,
          render: (_, row) => {
            if (row?.key === "total") {
              return esbsdGrandTotal(item)
            }
            return item?.[row?.key] || 0
          },
        }
      }) || []
    )
  }
  const getMainColumns = (mode: "download" | "view") => {
    const columns: ColumnsType<any> = [
      {
        title: t("Accounting Subject"),
        dataIndex: "subject",
        key: "subject",
        width: 200,
        align: "center",
        render: (text) =>
          mode === "download" ? text : <AccountSubjectContent label={text} />,
      },
      {
        title: t("Total"),
        key: "amount",
        dataIndex: "amount",
        align: "center",
        width: 125,
        render: (_, row) => {
          const totalData = esbsdRowsTotal(data?.msg || [])
          if (row?.key === "total") {
            return esbsdGrandTotal(totalData)
          }
          return totalData?.[row?.key]
        },
      },
      ...getDynamicFacilityColumns(mode),
    ]
    return columns
  }
  useEffect(() => {
    setDownloadAbleDataOf({
      ...downloadAbleDataOf,
      tab: t("（３）Employment Support Business Sales Division"),
      [panel]: {
        columns: getMainColumns("download"),
        dataSource,
      },
    })
  }, [panel, data])
  return (
    <Flex vertical gap={16}>
      <TabTopContent
        tab={t("Revenue")}
        onChange={(value) => {
          setSearchParams({
            ...params,
            date: dayjs(value).format("YYYY-MM"),
          })
          updateParams(
            {
              panel: "employment",
              date: dayjs(value).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      <Table
        columns={getMainColumns("view")}
        dataSource={dataSource || []}
        headerBorderRadius={0}
        loading={isLoading || isFetching}
        title={() => (
          <Flex
            vertical
            align={"center"}
            style={{ background: "#B1D5EA", padding: "8px" }}
            gap={8}
          >
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("Employment Support Business Sales Division")}
            </Typography.Title>
            <Flex>
              <Typography.Text>
                {dayjs(params?.date).format("YYYY/MM/DD")}
              </Typography.Text>
              {"-"}
              <Typography.Text>
                {dayjs(params?.date)
                  .endOf("month")
                  .format("YYYY/MM/DD")}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        scroll={{ x: true }}
      />
    </Flex>
  )
}
