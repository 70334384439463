import { Flex, Typography } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ColumnsType } from "antd/lib/table"
import { createId } from "@paralleldrive/cuid2"
import {
  InputField,
  Table,
  theme,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import {
  ESBSSharedCell,
  ebssRowsTotal,
  esbsGrandTotal,
  getColsFacilityColsTotal,
  handleFirstColSpaning,
  handleSecondColSpaning,
} from "./helpers"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { I_EMPLOYMENT_BUSINESS_STATEMENT } from "../../../types"
import {
  EMPLOYMENT_SUPPORT_STATEMENT_TAB_ROWS,
  EMPLOYMENT_BUSINESS_STATEMENT_INITIAL_DATA,
} from "./constant"
import dayjs, { Dayjs } from "dayjs"
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useMutation, useQuery } from "react-query"
import {
  createEmploymentSupportBusinessStatement,
  getEmploymentSupportBusinessStatementByFacility,
} from "../../../services"
import { CTAbtn } from "../WagesRevenueCommon/CTAbtn"
import { TabTopInfoContent } from "../WagesRevenueCommon/TabTopInfoContent"
import { useAuthContext } from "../../../context"

export const EmploymentSupportBusinessStatementTabEdit = ({
  setSelectedColData,
  selectedCol,
  params,
}: {
  selectedCol: I_EMPLOYMENT_BUSINESS_STATEMENT
  setSelectedColData: (value: null) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    year: params?.date ? dayjs(params?.date).year() : selectedCol?.year,
    month: params?.date ? dayjs(params?.date).month() + 1 : selectedCol?.month,
    facility_id: selectedCol?.facility_id || null,
  })
  const [newColumns, setNewColumns] = useState<
    I_EMPLOYMENT_BUSINESS_STATEMENT[]
  >([])
  //API calls here -start
  const { isLoading, isFetching } = useQuery({
    queryKey: ["getAllFacilityEmploymentSupportBusinessStatement", values],
    queryFn: () =>
      getEmploymentSupportBusinessStatementByFacility({
        year: values?.year || dayjs().year(),
        month: values?.month || dayjs().month() + 1,
        facility_id: values?.facility_id || selectedCol?.facility_id || 0,
      }),
    onSuccess: (res: any) => {
      setNewColumns(
        res?.msg?.map((col) => ({ ...col, tempId: col?.id || createId() })) ||
          [],
      )
    },
    refetchOnWindowFocus: false,
  })
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: createEmploymentSupportBusinessStatement,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Created Successfully"),
      })
      setSelectedColData(null)
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  //API calls here -end

  const handleColEdit = ({
    id,
    key,
    value,
  }: {
    id: string
    key: string
    value: string | number
  }) => {
    const cols = newColumns?.map((col) => {
      if (col?.tempId === id) {
        return {
          ...col,
          [key]: value,
        }
      }
      return col
    })
    setNewColumns(cols)
  }
  const getNewColumns = () => {
    return newColumns?.map((item: any, index) => ({
      key: index + 1,
      title: (
        <Flex align={"center"} gap={8}>
          <InputField
            value={item?.work_title || null}
            height={"40px"}
            placeholder={t("○○  Work")}
            onChange={({ target: { value } }) => {
              handleColEdit({
                id: item?.tempId,
                key: "work_title",
                value: value,
              })
            }}
          />
          <div
            onClick={() => {
              setNewColumns([
                ...newColumns?.slice(0, index),
                ...newColumns?.slice(index + 1),
              ])
            }}
          >
            <MinusCircleOutlined
              style={{
                color: theme.colors?.error,
              }}
            />
          </div>
        </Flex>
      ),
      render: (row) => {
        if (
          row?.key === "material_cost_meter_total" ||
          row?.key === "material_cost_total" ||
          row?.key === "labor_expenses_total" ||
          row?.key === "expenses_total" ||
          row?.key === "grand_total" ||
          row?.key === "project_expenses_total" ||
          row?.key === "manufacturing_cost" ||
          row?.key === "table_based_product_wholesaler" ||
          row?.key === "terminated_goods_wholesaler"
        ) {
          return (
            <Flex style={{ marginLeft: "8px" }}>
              {ebssRowsTotal(item)?.[row?.key]}
            </Flex>
          )
        }
        return (
          <InputField
            value={item[row?.key]}
            height={"40px"}
            type={"number"}
            onChange={({ target: { value } }) => {
              if (!isNaN(+value)) {
                handleColEdit({
                  id: item?.tempId,
                  key: row?.key,
                  value: +value,
                })
              }
            }}
          />
        )
      },
      width: 200,
      onCell: ESBSSharedCell,
    }))
  }

  const columns: ColumnsType<any> = [
    {
      key: 1,
      align: "center",
      title: t("Accounting"),
      width: 20,
      colSpan: 2,
      render: (row, _, index) => {
        const renderChildren = () => {
          if (
            index === 0 ||
            index === 3 ||
            index === 5 ||
            index === 6 ||
            index === 13 ||
            index === 14 ||
            index === 16
          ) {
            return (
              <div>
                {index === 0 || index === 6 || index === 14 || index === 16 ? (
                  <Typography.Text strong style={{ marginRight: "8px" }}>
                    {row?.sn}
                  </Typography.Text>
                ) : (
                  ""
                )}
                <Typography.Text>{t(row?.title)}</Typography.Text>
              </div>
            )
          }
          if (index >= 37) {
            return <Typography.Text>{t(row?.title)}</Typography.Text>
          }
          return (
            <Typography.Text style={{ whiteSpace: "nowrap" }}>
              {row?.sn}
            </Typography.Text>
          )
        }
        return {
          props: {
            style: {
              background:
                index === 0 || index === 6 || index === 14 || index === 16
                  ? theme.colors.background
                  : index >= 37
                    ? "#EDEDED"
                    : "white",
              textAlign: "left",
            },
          },
          children: renderChildren(),
        }
      },
      onCell: (_, index) =>
        handleFirstColSpaning(index, newColumns?.length || 0),
    },
    {
      key: 2,
      align: "center",
      title: t("sn"),
      colSpan: 0,
      width: 200,
      render: (row) => (
        <AccountSubjectContent label={row?.title || ""} minWidth={"250px"} />
      ),
      onCell: handleSecondColSpaning,
    },
    {
      key: 2,
      title: t("Total"),
      width: 200,
      render: (row: any) => {
        if (
          row?.key === "material_cost_meter_total" ||
          row?.key === "material_cost_total" ||
          row?.key === "labor_expenses_total" ||
          row?.key === "expenses_total" ||
          row?.key === "grand_total" ||
          row?.key === "project_expenses_total" ||
          row?.key === "manufacturing_cost" ||
          row?.key === "table_based_product_wholesaler" ||
          row?.key === "terminated_goods_wholesaler"
        ) {
          const totalData = esbsGrandTotal(newColumns || [], row)
          return <Flex>{totalData?.[row?.key] || "0"}</Flex>
        }
        const total = getColsFacilityColsTotal(newColumns || [])
        return <Typography.Text>{total[row?.key]}</Typography.Text>
      },
      onCell: ESBSSharedCell,
    },
    ...getNewColumns(),
    {
      key: "add",
      title: () => (
        <div
          onClick={() =>
            setNewColumns([
              ...newColumns,
              {
                ...EMPLOYMENT_BUSINESS_STATEMENT_INITIAL_DATA,
                tempId: createId(),
                facility_id: selectedCol?.facility_id || 0,
                facility_name: selectedCol?.facility_name || "",
                work_title: "",
                year: values?.year || dayjs().year(),
                month: values?.month || dayjs().month() + 1,
              },
            ])
          }
        >
          <PlusCircleOutlined
            style={{
              color: theme.colors.action,
              cursor: "pointer",
            }}
          />
        </div>
      ),
      align: "center",
      width: newColumns?.length ? 15 : 10,
      className: "adder_col",
      fixed: "right",
      onHeaderCell: () => {
        return {
          style: {
            padding: "0px",
          },
        }
      },
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 43,
          }
        }
        if (index >= 1) {
          return {
            rowSpan: 0,
          }
        }

        return {}
      },
    },
  ]

  const handleCatBtnClick = (action: "cancel" | "save") => {
    if (action === "cancel") {
      setSelectedColData(null)
      return
    }
    mutate({
      facility_id: selectedCol?.facility_id,
      year: values?.year || dayjs().year(),
      month: values?.month || dayjs().month() + 1,
      values: newColumns || [],
    })
  }
  return (
    <Flex
      vertical
      gap={16}
      style={{
        pointerEvents: isMutating ? "none" : "auto",
      }}
    >
      <TabTopContent
        tab={t("Revenue")}
        onChange={(date: Dayjs) => {
          setValues({
            ...values,
            year: date?.year(),
            month: date?.month() + 1,
          })
          updateParams(
            {
              tab: "support",
              date: dayjs(date).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      <CTAbtn isLoading={isMutating} onClick={handleCatBtnClick} />
      <TabTopInfoContent
        facilities={facilities || []}
        facility={
          String(values?.facility_id) ||
          String(selectedCol?.facility_id) ||
          null
        }
        onChange={(fid: any) =>
          setValues({
            ...values,
            facility_id: fid,
          })
        }
      />
      <Table
        columns={columns}
        dataSource={EMPLOYMENT_SUPPORT_STATEMENT_TAB_ROWS || []}
        loading={isLoading || isFetching}
        title={() => (
          <Flex
            vertical
            align={"center"}
            style={{ background: "#B1D5EA", padding: "8px" }}
            gap={8}
          >
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("Employment support business statement")}
            </Typography.Title>
            <Flex>
              <Typography.Text>
                {dayjs(params?.date).format("YYYY/MM/DD")}
              </Typography.Text>
              {"-"}
              <Typography.Text>
                {dayjs(params?.date)
                  .endOf("month")
                  .format("YYYY/MM/DD")}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        rowClassName={(_, index) => {
          if (index >= 37) {
            return "table_row_cell_gray"
          }
        }}
        scroll={{ x: true }}
      />
      <CTAbtn isLoading={isMutating} onClick={handleCatBtnClick} />
    </Flex>
  )
}
