import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import { Flex } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FuriganaAlphabetsOption, YEAR_DATE_FORMAT } from "../../constants"
import { filterWithHiragana } from "../../utils"

import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { useAuthContext } from "../../context"
import { getFacilityUsers } from "../../services/user"
import { UserAttendanceManagementOperationTypes } from "../../types"

export const UserAttendanceManagementOperationOptions = ({
  scrollTo,
}: {
  scrollTo: () => void
  panel?: string
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility_id, date, user_id } = router.query as any
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()

  const getInitialFacilityId = (explicitFacilityId = null) => {
    const initialFacility = explicitFacilityId || facility_id
    for (let i = 0; i < facilities.length; i++) {
      if (facilities[i]?.value == initialFacility) {
        return initialFacility
      }
    }
    return facilities?.[0]?.value
  }

  const [values, setValues] = useState<UserAttendanceManagementOperationTypes>({
    facility_id: getInitialFacilityId(),
    furigana: "",
    user_id: user_id || null,
    date: date ? dayjs(date) : dayjs(),
  })

  useEffect(() => {
    const facilityShouldBe = getInitialFacilityId(values.facility_id)
    if (facilityShouldBe != values.facility_id) {
      setValues((prev) => ({ ...prev, facility_id: facilityShouldBe }))
      updateParams(
        {
          ...router.query,
          facility_id: facilityShouldBe,
          user_id: values.user_id,
          date: dayjs(values.date).format("YYYY-MM"),
          to_date: dayjs(values.to_date).format("YYYY-MM"),
          page: 1,
        },
        "/user-attendance-management",
      )
    }
  }, [facilities])

  const {
    data: facilityUsers,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["get-All-Users"],
    queryFn: () =>
      getFacilityUsers({
        page: 1,
        pageSize: "Infinity",
        year: dayjs().year(),
        month: dayjs().month() + 1,
        facilityIds: getInitialFacilityId(values.facility_id),
      }),
    cacheTime: 2 * (60 * 1000),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    select: (res) => {
      return res?.data.map((user) => ({
        label: user.username,
        value: String(user.id),
        furigana: user.furigana,
      }))
    },
  })

  useEffect(() => {
    refetch()
  }, [values.facility_id])

  return (
    <AccordionCard
      defaultActiveKey={"user-attendance-management"}
      items={[
        {
          key: "user-attendance-management",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("facility_name")}
                  width={"136px"}
                />
                <SelectInput
                  name={"facility"}
                  placeholder={"--"}
                  width={"282px"}
                  minWidth={"282px"}
                  value={values.facility_id || null}
                  options={facilities || []}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      furigana: null,
                      user_id: null,
                      facility_id: val,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User Name ")}
                  width={"136px"}
                />
                <Flex gap={8} wrap={"wrap"} style={{ width: "100%" }}>
                  <SelectInput
                    name={"furigana"}
                    options={FuriganaAlphabetsOption || []}
                    value={values?.furigana || null}
                    placeholder={"--"}
                    width={"78px"}
                    shortSelector
                    onChange={(value) =>
                      setValues({
                        ...values,
                        furigana: value,
                        user_id: null,
                      })
                    }
                  />
                  <SelectInput
                    name={"user_id"}
                    allowClear
                    options={
                      filterWithHiragana({
                        options: facilityUsers || [],
                        furigana: values?.furigana || "",
                      }) || []
                    }
                    placeholder={"--"}
                    value={values?.user_id || null}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        user_id: val,
                      })
                    }
                    showMessage={false}
                    loading={isLoading || isFetching}
                  />
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year Month")}
                  width={"136px"}
                />

                <DatePicker
                  picker={"month"}
                  date={dayjs(values?.date) || null}
                  onDateChange={(date: Dayjs) =>
                    setValues({
                      ...values,
                      date: dayjs(date),
                    })
                  }
                  format={YEAR_DATE_FORMAT}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Search")}
                  iconType={"search"}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => {
                    scrollTo()
                    updateParams(
                      {
                        ...router.query,
                        facility_id: values.facility_id,
                        user_id: values.user_id,
                        date: dayjs(values.date).format("YYYY-MM"),
                        to_date: dayjs(values.to_date).format("YYYY-MM"),
                        page: 1,
                      },
                      "/user-attendance-management",
                    )
                  }}
                />
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    const data = {
                      facility_id: getInitialFacilityId(),
                      user_id: null,
                      page: 1,
                    }
                    setValues({
                      ...data,
                      date: dayjs(),
                      furigana: "",
                    })
                    scrollTo()
                    updateParams(
                      {
                        ...data,
                        date: dayjs().format("YYYY-MM"),
                      },
                      "/user-attendance-management",
                    )
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
