import {
  ActionBtns,
  Button,
  Card,
  Grid,
  InputField,
  theme,
  useNotification,
} from "@project/common"
import { FieldArray, FormikProvider, useFormik } from "formik"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import { StyleFieldArray } from "./ProgressManagementForm.style"
import {
  ProgressManagementFormFields,
  ProgressManagementFormFieldsSchema,
} from "../../types/ProgressManagement.types"
import { Flex, Popconfirm } from "antd"
import { useMutation } from "react-query"
import { deleteOneProgress } from "../../services"
interface ProgressManagementFormProps {
  isLoading?: boolean
  onSubmit?: (values: ProgressManagementFormFields) => void
  defaultData?: any
  action?: "add" | "edit"
  hasDeleteAccess?: boolean
}

const ProgressManagementForm = ({
  onSubmit,
  isLoading,
  defaultData,
  action,
  hasDeleteAccess,
}: ProgressManagementFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { showToast } = useNotification()
  const initialValues = {
    progress_name: [
      {
        progress_name: "",
      },
    ],
  }
  const formik = useFormik<ProgressManagementFormFields>({
    initialValues: defaultData || initialValues,
    validationSchema: ProgressManagementFormFieldsSchema,
    onSubmit: (formValues) => onSubmit(formValues),
  })

  // delete progress
  const { isLoading: isDeleting, mutate: handleProgressDelete } = useMutation({
    mutationFn: deleteOneProgress,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Progress management  deleted successfully"),
      })
      router.push("/progress-management")
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  return (
    <Card title={t("Progress management items Information")}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid
              labelContent={t("Progress Name")}
              required
              labelSpan={7}
              background
              className={"row__header"}
              labelAlignItems={"center"}
            >
              <FieldArray
                name={"progress_name"}
                render={(arrayHelpers) => (
                  <StyleFieldArray>
                    {formik.values.progress_name?.map(
                      (progress_name, index) => (
                        <div className={"input_container"} key={index}>
                          <div>
                            <InputField
                              name={`progress_name[${index}].progress_name`}
                              id={`progress_name[${index}].progress_name`}
                              value={progress_name?.progress_name}
                              height={"50px"}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              width={"500px"}
                              maxLength={50}
                              info={t("(within 50 char)")}
                              error={
                                formik.touched.progress_name &&
                                formik.errors.progress_name &&
                                formik.errors.progress_name[index]
                                  ?.progress_name
                              }
                            />
                          </div>

                          <div className={"btn_content"}>
                            {formik?.values.progress_name?.length - 1 ===
                            index ? (
                              <>
                                {formik?.values.progress_name?.length <= 19 ? (
                                  <Button
                                    btnText={t("Add")}
                                    shape={"round"}
                                    maxHeight={"35px"}
                                    onClick={() =>
                                      arrayHelpers.push({ progress_name: "" })
                                    }
                                    btnBg={theme.colors.disabled}
                                    borderColor={theme.colors.border}
                                    textColor={theme.colors.text}
                                  />
                                ) : (
                                  <Button
                                    btnText={t("Delete")}
                                    type={"text"}
                                    onClick={() => arrayHelpers.remove(index)}
                                    textColor={theme.colors.error}
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <Button
                                btnText={t("Delete")}
                                type={"text"}
                                onClick={() => arrayHelpers.remove(index)}
                                textColor={theme.colors.error}
                                style={{
                                  textDecoration: "underline",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ),
                    )}
                  </StyleFieldArray>
                )}
              />
            </Grid>
          </div>
          <Flex
            justify={"space-between"}
            style={{
              marginTop: "16px",
            }}
          >
            <ActionBtns justify={"flex-start"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => router.push("/progress-management")}
                disabled={isLoading}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isLoading}
              />
            </ActionBtns>
            {action === "edit" && hasDeleteAccess && (
              <Popconfirm
                title={t("Deleting.Is that OK?")}
                onConfirm={() =>
                  handleProgressDelete(+router?.query?.id as number)
                }
                okText={t("OK")}
                cancelText={t("Cancel")}
                okButtonProps={{ size: "middle", loading: isDeleting }}
                cancelButtonProps={{ size: "middle", disabled: isDeleting }}
              >
                <Button
                  btnText={t("Delete")}
                  shape={"round"}
                  disabled={isLoading}
                  type={"error"}
                />
              </Popconfirm>
            )}
          </Flex>
        </form>
      </FormikProvider>
    </Card>
  )
}

export { ProgressManagementForm }
