// packages
import { t } from "i18next"
import Link from "next/link"

// types
import { ColumnsType } from "antd/es/table"
import {
  GenerateFacilityColumnsFn,
  UserTransportMgmtTableColumnDataType,
} from "../../../types/UserTransportMgmt.types"

/**
 *
 * @param facilities All selected facilities in operation filter section.
 * @returns dynamic column schema for table
 * @description The column is added or removed as per the selected facilities.
 */
export const generateFacilityColumns: GenerateFacilityColumnsFn = (
  facilities,
  yearMonth,
) => {
  const DYNAMIC_FAC_COLUMNS: ColumnsType<UserTransportMgmtTableColumnDataType> =
    facilities?.map((item) => {
      return {
        title: item.facility_name,
        dataIndex: item.id.toString(),
        key: item.id.toString(),
        align: "center",
        onCell: (val) => {
          return {
            className: val?.[item.id.toString()]?.facility_transport_class,
          }
        },
        render: (val, record) => {
          if (val?.is_holiday) {
            return <p className={"holiday-text"}>{t("Holiday")}</p>
          }
          return (
            <Link
              href={`/user-transport-management/${yearMonth.format(
                "YYYY-MM",
              )}-${record.day
                .toString()
                .padStart(2, "0")}?facility_ids=${item.id.toString()}`}
              className={"link-text"}
            >
              {t("Pick: {{pickup}}名 Drop:{{dropOff}}名 (Users:{{users}})", {
                pickup: val?.pickup,
                dropOff: val?.dropoff,
                users: val?.users,
              })}
            </Link>
          )
        },
      }
    })

  return [
    {
      title: t("Day"),
      dataIndex: "day",
      key: "day",
      width: 120,
      align: "center",
      fixed: "left",
    },
    {
      title: t("Day of week"),
      dataIndex: "week_day",
      key: "week_day",
      align: "center",
      width: 120,
    },
    ...DYNAMIC_FAC_COLUMNS,
  ]
}
