import {
  ADDITIONAL_ITEM_VALUES,
  Breadcrumb,
  FACILITY_SERVICE_TYPES,
  PageTitle,
  USER_ATTENDANCE_VALUES,
  breakTimeInString,
  getOthersCheckBoxItems,
  getSumOfAllBreakTimes,
  getUserAttendanceTypesByService,
  getWeekNameByDate,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { PageHead } from "../../../components"
import { useFetchFacilitiesWithConfirmedBilling } from "../../../hooks/useFetchData"
import { fetchInstructors, getUserAttendanceDailyList } from "../../../services"
import { ListOperationParams } from "../../../types"
import DailyListAlert from "./DailyListAlert"
import DailyListUnscheduledAlert from "./DailyListUnscheduledAlert"
import { DayListContent } from "./DayListContent"
import { DayListOperation } from "./DayListOperation"
export const DailyList = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    facilityId: queryFacility,
    user_service_id: queryService,
    date: queryDate,
  } = router.query as any
  const [params, setParams] = useState<ListOperationParams>({
    facilityId: queryFacility ? queryFacility?.toString()?.split(",") : [],
    user_service_id: queryService
      ? queryService
          ?.toString()
          ?.split(",")
          ?.map((ser) => +ser)
      : [],
    date:
      queryDate && dayjs(queryDate?.toString()).isValid()
        ? dayjs(queryDate?.toString())
        : dayjs(),
  })
  const [updateParams] = useUpdateSearchParams()
  const constRef = useRef<HTMLDivElement>(null)
  const title = t(
    "{{year}}年{{month}}月{{day}}日（{{dayOfWeek}})'s user attendance list",
    {
      year: dayjs(params.date).year(),
      month: `0${dayjs(params.date).month() + 1}`.slice(-2),
      day: `0${dayjs(params.date).date()}`.slice(-2),
      dayOfWeek: t(getWeekNameByDate(dayjs(params.date).day())),
    },
  )

  const {
    data: userDayAttendance,
    isLoading: isAttendanceDataLoading,
    refetch: refetchAttendanceList,
    isRefetching: isReloadingAttendanceData,
  } = useQuery(
    ["user-day-attendance", params],
    () =>
      getUserAttendanceDailyList({
        facilityIds: params?.facilityId?.join(","),
        service: params?.user_service_id?.join(","),
        date: params?.date?.format("YYYY-MM-DD"),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: ({ data }) => {
        return {
          ...data,
          attendance_list:
            data?.attendance_list?.map((attendance) => {
              const otherOptions = getOthersCheckBoxItems(
                attendance?.service_type,
                attendance?.system_status_management,
              )
              const otherOptionValues = otherOptions?.map((opt) => opt.value)
              const medicalCollaborationAddition = Array.isArray(
                attendance?.additional_items,
              )
                ? attendance?.additional_items?.find(
                    (item) =>
                      item?.additional_item ==
                      ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
                  )
                : {
                    additional_item_value: null,
                    additional_item_value2: 0,
                  }

              const otherAdditions = Array.isArray(attendance?.additional_items)
                ? attendance?.additional_items
                    ?.filter((item) =>
                      otherOptionValues.includes(item?.additional_item),
                    )
                    ?.map((item) => item?.additional_item)
                : []

              return {
                ...attendance,
                total_break_time:
                  attendance?.attendance_type &&
                  attendance?.attendance_type !=
                    USER_ATTENDANCE_VALUES.UNREGISTERED &&
                  attendance?.attendance_type !=
                    USER_ATTENDANCE_VALUES.ABSENCE &&
                  attendance?.attendance_type !=
                    USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
                    ? breakTimeInString(
                        getSumOfAllBreakTimes(attendance, queryDate),
                        "LONG_HAND",
                      )
                    : "",
                date: dayjs(attendance?.date)?.format("YYYY-MM-DD"),
                furigana: attendance?.user?.furigana,
                username: attendance?.user?.username,
                facility_name: attendance?.facility_name,
                service_type_label: t(
                  FACILITY_SERVICE_TYPES?.[attendance?.service_type],
                ),
                service_provision_status: t(
                  getUserAttendanceTypesByService(
                    attendance?.service_type,
                  )?.find(
                    (attendanceType) =>
                      attendanceType?.value == attendance?.attendance_type,
                  )?.label || "",
                ),
                attendance_type: attendance?.attendance_type,
                start_time: attendance?.start_time,
                end_time: attendance?.end_time,
                user_attendance_actual_cost:
                  attendance?.user_attendance_actual_cost || [],
                meal_id: attendance?.meal_id,
                scheduled_meal_id: attendance?.scheduled_meal_id,
                meal_name: attendance?.scheduled_meal?.name,
                used_meal_name: attendance?.used_meal?.name,
                remark: attendance?.remarks,
                output_remarks: attendance?.remarks_output,
                absent_count_for_month: attendance?.absent_count_for_month || 0,
                others: otherAdditions,
                medical_collaboration_system:
                  medicalCollaborationAddition?.additional_item_value,
                guidance_flag:
                  medicalCollaborationAddition?.additional_item_value2,
              }
            }) || [],
          attendance_stat: {
            attendance: (data?.attendance_stat?.present_count || 0) + "人",
            absence: (data?.attendance_stat?.absent_count || 0) + "人",
            absence_no_addition:
              (data?.attendance_stat?.absent_no_addition_count || 0) + "人",
            unregistered:
              (data?.attendance_stat?.unregistered_count || 0) + "人",
            experience: (data?.attendance_stat?.experience_count || 0) + "人",
            total_application: (data?.attendance_stat?.total_count || 0) + "人",
          },
        }
      },
      onSuccess: (response) => {
        const attendanceListWithoutSchedule = response?.attendance_list?.filter(
          (att) => !att?.is_scheduled,
        )
        if (attendanceListWithoutSchedule.length > 0) {
          setUnscheduledUsers(
            attendanceListWithoutSchedule.map((att) => att?.user),
          )
        } else {
          setUnscheduledUsers([])
        }
      },
    },
  )

  const { data: staffList, isLoading: isStaffLoading } = useQuery(
    ["staff-list", params],
    () =>
      fetchInstructors({
        facility: params?.facilityId?.join(","),
        date_staff_is_active: params?.date?.format("YYYY-MM-DD"),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: ({ data }) => data,
    },
  )

  const handleParamsChange = (values: ListOperationParams) => {
    const urlData = {
      facilityId: values?.facilityId?.toString(),
      user_service_id: values?.user_service_id?.toString(),
      date: dayjs(values?.date)?.isValid()
        ? dayjs(values?.date).format("YYYY-MM-DD")
        : null,
    }
    updateParams(urlData, "/user-attendance-calendar/daily-list")
    scrollToSelectedElement(constRef)
    setParams(values)
  }

  const year = dayjs(queryDate).year()
  const month = dayjs(queryDate).month() + 1
  const { facilitiesWithConfirmedBilling, isLoading } =
    useFetchFacilitiesWithConfirmedBilling({
      year,
      month,
      facilityIds: queryFacility,
    })

  const [unscheduledUsers, setUnscheduledUsers] = useState([])

  return (
    <>
      <PageHead>{t("User attendance calender")}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: (
              <Link href={"/user-attendance-calendar"}>
                {t("User Attendance Calendar")}
              </Link>
            ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
        }}
      >
        {facilitiesWithConfirmedBilling?.data?.length ? (
          <DailyListAlert
            facilitiesWithConfirmedBilling={facilitiesWithConfirmedBilling}
            isLoading={isLoading}
          />
        ) : null}
        {unscheduledUsers.length > 0 && (
          <DailyListUnscheduledAlert userList={unscheduledUsers} />
        )}
        <DayListOperation params={params} setParams={handleParamsChange} />
        <div ref={constRef}>
          <DayListContent
            params={params}
            attendanceStats={userDayAttendance?.attendance_stat}
            isLoading={
              isAttendanceDataLoading ||
              isStaffLoading ||
              isReloadingAttendanceData
            }
            attendance_list={userDayAttendance?.attendance_list}
            refetchAttendanceList={refetchAttendanceList}
            staff_list={staffList}
            handleDateChange={(date) =>
              handleParamsChange({ ...params, date: dayjs(date) })
            }
          />
        </div>
      </div>
    </>
  )
}
