import React, { useCallback } from "react"

// packages
import _ from "lodash"
import { Table } from "antd"
import { t } from "i18next"

// components
import { ActualCostTotalSummaryFooter } from "./CustomPrintTables"

// utils
import { AllActualCostIds } from "../../../../utils/getDailyListings"

// types
import {
  InvoiceBreakDown,
  SummaryRowsProps,
} from "../../../../../../types/nhif.types"

const SummaryRows = ({
  pageData,
  customerInvoice,
  mergeActualCost = false,
  allActualCostIds,
  monthlyItems = [],
}: SummaryRowsProps) => {
  const total = _.sumBy(pageData, "unit") + _.sumBy(monthlyItems, "unit")
  const billedBreakDownAmt = _.min([
    customerInvoice?.user_burden_monthly,
    total,
  ])
  const billedAmt = billedBreakDownAmt + customerInvoice.invoice_amount

  const facility_name = pageData.find((item) => item.item_facility_name)
    ?.item_facility_name

  const CachedActualCostSummary = useCallback(
    ({
      actualCostIds,
      dataSource,
    }: {
      actualCostIds: AllActualCostIds[]
      dataSource: readonly Partial<InvoiceBreakDown>[]
    }) => {
      return (
        <ActualCostTotalSummaryFooter
          actualCostIds={actualCostIds}
          dataSource={dataSource}
        />
      )
    },
    [],
  )

  return (
    <>
      {monthlyItems.map((item, idx) => (
        <Table.Summary.Row
          className={"summary-total-row"}
          key={item.service_code_name + idx}
        >
          <Table.Summary.Cell index={0} colSpan={3} align={"center"}>
            {item.service_code_name}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align={"center"}>
            <div>{item.unit.toLocaleString()}</div>
          </Table.Summary.Cell>
          {mergeActualCost && (
            <CachedActualCostSummary
              actualCostIds={allActualCostIds}
              dataSource={pageData}
            />
          )}
          <Table.Summary.Cell index={2} align={"center"}>
            <div>{facility_name}</div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
        </Table.Summary.Row>
      ))}

      <Table.Summary.Row className={"summary-total-row"}>
        <Table.Summary.Cell index={0} colSpan={3} align={"center"}>
          {t("Total①")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align={"center"}>
          <div>{total.toLocaleString()}</div>
        </Table.Summary.Cell>
        {mergeActualCost && (
          <CachedActualCostSummary
            actualCostIds={allActualCostIds}
            dataSource={pageData}
          />
        )}
        <Table.Summary.Cell index={2} align={"center"}>
          <div>{facility_name}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
      </Table.Summary.Row>

      <Table.Summary.Row className={"summary-max-user-burden-row"}>
        <Table.Summary.Cell index={0} colSpan={3} align={"center"}>
          {t("Maximum User Burden Monthly ②")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align={"center"}>
          <div>{customerInvoice?.user_burden_monthly.toLocaleString()}</div>
        </Table.Summary.Cell>
        {mergeActualCost && (
          <PlaceholderCell actualCostIds={allActualCostIds} />
        )}
        <Table.Summary.Cell index={2} align={"center"}>
          <div>{facility_name}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
      </Table.Summary.Row>

      <Table.Summary.Row className={"summary-amt-breakdown-row"}>
        <Table.Summary.Cell index={0} colSpan={3} align={"center"}>
          {t("Billed amount breakdown (smaller amount of ①②)")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align={"center"}>
          <div>{billedBreakDownAmt.toLocaleString()}</div>
        </Table.Summary.Cell>
        {mergeActualCost && (
          <PlaceholderCell actualCostIds={allActualCostIds} />
        )}
        <Table.Summary.Cell index={2} align={"center"}>
          <div>{facility_name}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
      </Table.Summary.Row>

      <Table.Summary.Row className={"summary-billed-amt-total-row"}>
        <Table.Summary.Cell index={0} colSpan={3} align={"center"}>
          {t("Billed Amount")}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align={"center"}>
          <div>{billedAmt.toLocaleString()}</div>
        </Table.Summary.Cell>
        {mergeActualCost && (
          <CachedActualCostSummary
            actualCostIds={allActualCostIds}
            dataSource={pageData}
          />
        )}
        <Table.Summary.Cell index={2} align={"center"}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )
}

const PlaceholderCell = ({
  actualCostIds,
}: {
  actualCostIds: AllActualCostIds[]
}) => {
  return actualCostIds.map((_, idx) => (
    <Table.Summary.Cell
      index={2}
      key={idx}
      align={"center"}
    ></Table.Summary.Cell>
  ))
}
export default SummaryRows
