import React, { memo, useContext } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Divider } from "antd"
import { useFormik } from "formik"

// commons | packages
import {
  Box,
  Button,
  ActionBtns,
  DatePicker,
  filterObject,
  AccordionCard,
  CheckBoxGroup,
  FACILITY_TYPES,
  useUpdateSearchParams,
} from "@project/common"
import { TMBulkForm } from "./TMBulkForm"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// context
import { AuthContext } from "../../context"
import { useTMBulkEdit } from "../../context/TMBulkEditFormContext"

// types
import {
  TMBulkEditFormFilterType,
  TMBulkFormFilterOperationProps,
} from "./types"

// constants
import { LIST_DISPLAY } from "../UserTransportManagementContainer/constant"

export const TMBulkFormFilterOperation = memo(TMBulkFormFilterOperationInternal)

function TMBulkFormFilterOperationInternal({
  handleReset,
  handleScrollToList,
  handleTMBulkEditReflect,
}: TMBulkFormFilterOperationProps) {
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const { memoizedQuery } = useTMBulkEdit()
  const [updateParams] = useUpdateSearchParams()

  const formik = useFormik<Partial<TMBulkEditFormFilterType>>({
    initialValues: memoizedQuery,
    onSubmit: (values) => {
      const params = filterObject({
        ...values,
        facility_ids: values?.facility_ids?.join(","),
        list_display: values?.list_display?.join(","),
        service_type: values?.service_type?.join(","),
      })

      updateParams(
        { ...params },
        `/user-transport-management/${values?.date}/bulk-edit-form`,
      )
      handleScrollToList()
    },
  })
  const { setFieldValue, values, handleSubmit } = formik

  // 1. handle facility check and uncheck
  const handleCheckAll = () => {
    setFieldValue(
      "facility_ids",
      facilitiesCtx.map((facility) => facility.id),
    )
  }
  const handleUncheckAll = () => {
    setFieldValue("facility_ids", [])
  }

  return (
    <AccordionCard
      defaultActiveKey={["transport-mgmt-driver-list-operation"]}
      items={[
        {
          key: "transport-mgmt-driver-list-operation",
          label: t("Operation Options"),
          children: (
            <>
              {/* Form that handles filter operations */}
              <Box display={"flex"} direction={"column"} gap={16}>
                <OperationFields
                  wrap={"wrap"}
                  gap={"10px 24px"}
                  labelWidth={"150px"}
                  align={"flex-start"}
                  label={"Displaying facility"}
                >
                  <div className={"TM_facility-inputs"}>
                    <Box
                      className={"TM_facility-btn-group flex"}
                      wrap={"wrap"}
                      gap={12}
                      display={"flex"}
                      mb={16}
                    >
                      <Button
                        shape={"round"}
                        type={"default"}
                        iconType={"check-all"}
                        btnText={t("Check all")}
                        onClick={handleCheckAll}
                      />
                      <Button
                        shape={"round"}
                        type={"default"}
                        btnText={t("Uncheck all")}
                        onClick={handleUncheckAll}
                        className={"TM_uncheck-all-btn"}
                      />
                    </Box>

                    <CheckBoxGroup
                      items={facilitiesCtx}
                      name={"facility_ids"}
                      onChange={(val) => {
                        setFieldValue("facility_ids", val)
                      }}
                      value={values.facility_ids?.map((item) =>
                        item.toString(),
                      )}
                    />
                  </div>
                </OperationFields>

                <OperationFields
                  wrap={"wrap"}
                  align={"center"}
                  gap={"10px 24px"}
                  label={"Use Service"}
                  labelWidth={"150px"}
                >
                  <div className={"TM_service-type-input"}>
                    <CheckBoxGroup
                      items={FACILITY_TYPES}
                      onChange={(val) => setFieldValue("service_type", val)}
                      value={values?.service_type}
                    />
                  </div>
                </OperationFields>

                <OperationFields
                  wrap={"wrap"}
                  align={"center"}
                  gap={"10px 24px"}
                  label={"List display"}
                  labelWidth={"150px"}
                >
                  <Box
                    className={"TM_display-type-input"}
                    miw={{
                      xs: 300,
                      xxs: "100%",
                    }}
                  >
                    <CheckBoxGroup
                      items={LIST_DISPLAY}
                      value={values?.list_display}
                      onChange={(val) => {
                        setFieldValue("list_display", val)
                      }}
                    />
                  </Box>
                </OperationFields>

                <OperationFields
                  wrap={"wrap"}
                  align={"center"}
                  gap={"10px 24px"}
                  label={"Date"}
                  labelWidth={"150px"}
                >
                  <div className={"TM_Date-inputs"}>
                    <DatePicker
                      name={"date"}
                      picker={"date"}
                      format={"YYYY年MM月DD日"}
                      date={dayjs(values.date)}
                      onDateChange={(value) => {
                        setFieldValue("date", value.format("YYYY-MM-DD"))
                      }}
                    />
                  </div>
                </OperationFields>

                <ActionBtns
                  className={"operation-action-btns"}
                  justify={"flex-start"}
                >
                  <Button
                    shape={"round"}
                    type={"default"}
                    btnText={t("Display Change")}
                    onClick={() => handleSubmit()}
                  />
                </ActionBtns>
              </Box>

              <Divider />

              {/* Form that Handles bulk edit actions */}
              <TMBulkForm
                handleReset={handleReset}
                handleTMBulkEditReflect={handleTMBulkEditReflect}
              />
            </>
          ),
        },
      ]}
    />
  )
}
