import { theme } from "@project/common"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Communication from "../../../../public/assets/icons/circle-pencil.svg"
import RecipientCertificate from "../../../../public/assets/icons/recipient-certificate.svg"
import ProvisionRecord from "../../../../public/assets/icons/register.svg"
import Revenew from "../../../../public/assets/icons/revenew-border.svg"
import ProgramRecord from "../../../../public/assets/icons/walking-man.svg"
const Wrapper = styled.div`
  .header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    @media (max-width: 480px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .card {
      border-radius: 12px;
      height: 125px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px 6px 0px 6px;
      border: 1px solid #d2d1d1;
      background-color: ${theme.colors.white};
      box-shadow: 0px 0px 15px 0px #0000000a;
      span {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
      }

      @media screen and (max-width: ${theme.breakpoints.xmd}) {
        padding-block: 10px;
        justify-content: center;
      }
    }
  }
`

interface IProps {
  facilityID: number
}
const DailyWorkRelated: React.FC<IProps> = ({ facilityID }) => {
  const { t } = useTranslation()
  const dailyWorkArray = [
    {
      title: t("Daily report"),
      icon: <RecipientCertificate />,
      route: `/daily-business-report-list?facility_id=${facilityID}`,
    },
    {
      title: t("Communication with Parents"),
      icon: <Communication />,
      route: "/",
    },
    {
      title: t("Service provision record/Care record・Life record"),
      icon: <ProvisionRecord />,
      route: "/",
    },
    {
      title: t("Program record"),
      icon: <ProgramRecord />,
      route: "/",
    },
    {
      title: t("Revenue management"),
      icon: <Revenew />,
      route: "/",
    },
  ]
  return (
    <Wrapper>
      <div className={"header"}>{t("Daily work related")}</div>
      <div className={"container"}>
        {dailyWorkArray?.map((d, i) => {
          return (
            <Link href={`${d?.route}`} key={i}>
              <a>
                <div key={i} className={"card"}>
                  {d?.icon}
                  <span>{d?.title}</span>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}

export { DailyWorkRelated }
