import {
  Button,
  SelectInput,
  Table,
  Tag,
  USER_ATTENDANCE_VALUES,
  dynamicLangString,
  theme,
} from "@project/common"
import { Flex, Typography, notification } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { AuthContext } from "../../../context"
import { quickUserAttendance } from "../../../services"
import { UserDayAttendance } from "../../../types"
import { DayListModal } from "./DayListModal/DayListModal"
const Wrapper = styled.div`
  .ant-table-title {
    padding: 0 !important;
    overflow: hidden;
    .theader {
      text-align: center;
      font-size: 16px;
      background-color: ${theme.colors.disabled};
      padding: 8px 16px;
    }
  }

  .ant-table-container {
    .ant-table-content {
      table {
        thead {
          tr {
            .use_facility {
              min-width: 100px;
            }
            .service_provision_status {
              min-width: 200px;
            }
            .entry_time {
              min-width: 100px;
            }
            .output_remarks {
              min-width: 180px;
            }
            .remark {
              min-width: 223px;
            }
          }
        }
      }
    }
  }

  #list_edit_cion {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors["t-head"]};
      height: 30px;
      width: 30px;
    }
  }
  #list_username_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: -5px !important;
    a {
      text-decoration: none !important;
    }
    span {
      color: #af8900;
      border: 1px solid #cda001;
      padding: 0 2px !important;
      width: fit-content;
    }
  }
  #list__time--content {
    & > div {
      letter-spacing: 2px;
      color: ${theme.colors.success};
    }
  }

  #list__remark {
    .see__more {
      color: ${theme.colors.action};
      text-decoration: underline;
    }
  }
  #dayListContentTable_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px 10px;
    .selector_content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  tr.unscheduled-attendance {
    background: ${theme.colors.border3};
  }
`
export const DayListContentTable = ({
  params,
  isLoading,
  attendance_list,
  refetchAttendanceList,
  staff_list,
}: {
  params?: any
  isLoading?: boolean
  attendance_list: Array<UserDayAttendance>
  refetchAttendanceList: () => void
  staff_list: Array<any>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const router = useRouter()

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    operation: null,
    facility_type_number: null, // one of FACILITY_TYPES
    facility_id: null,
    userAttendanceData: {},
    handleSave: null,
    staffList: [],
  })

  const columns: ColumnsType<any> = [
    {
      title: t("Edit"),
      key: "edit",
      align: "center",
      render: (row) => (
        <div id={"list_edit_cion"}>
          {row?.is_scheduled ? (
            <div
              onClick={() => {
                setModalProps({
                  ...modalProps,
                  isOpen: true,
                  operation: "EDIT",
                  staffList: staff_list,
                  userAttendanceData: {
                    ...row,
                    attendance_type:
                      !row?.attendance_type ||
                      row?.attendance_type == "0" ||
                      row?.attendance_type == ""
                        ? USER_ATTENDANCE_VALUES.ATTENDANCE
                        : row?.attendance_type,
                  },
                })
              }}
            >
              <Image
                src={"/assets/icons/edit-icon-blue.svg"}
                alt={"icon"}
                width={20}
                height={20}
              />
            </div>
          ) : (
            <img src={"../assets/icons/danger.svg"} alt={"icon"} />
          )}
        </div>
      ),
    },
    {
      title: t(""),
      key: "edit",
      align: "center",
      width: 10,
      render: (_, __, index) => index + 1,
    },

    {
      title: t("User name"),
      key: "user_name",
      render: (row) => (
        <div id={"list_username_content"}>
          <Link href={`/users/profile/${row.user_id}`}>{row?.furigana}</Link>
          <Link href={`/users/profile/${row.user_id}`}>{row?.username}</Link>
          <Tag title={row?.service_type_label} color={"#FFFDF3"} />
        </div>
      ),
    },
    {
      title: t("Use facility"),
      key: "use_facility",
      className: "use_facility",
      render: (row) => <span>{row?.facility_name}</span>,
    },
    {
      title: t("Service Provision status"),
      key: "service_provision_status",
      className: "service_provision_status",
      align: "center",
      render: (row) => <span>{t(row?.service_provision_status)}</span>,
    },
    {
      title: (
        <div>
          <div>{t("Entry time")}</div>
          <div>{t("Out time")}</div>
        </div>
      ),
      key: "time",
      className: "entry_time",
      align: "center",
      render: (row) => {
        // show attendance buttons if not attended
        if (!row?.attendance_type || row?.attendance_type == "0") {
          return (
            <Flex vertical gap={8}>
              <Button
                btnText={t("Attend")}
                btnBg={theme.colors.success}
                textColor={theme.colors.white}
                borderColor={theme.colors.success}
                style={{
                  whiteSpace: "nowrap",
                }}
                onClick={() =>
                  handleQuickAttendanceUpdate({
                    type: "checkin",
                    attendanceData: row,
                  })
                }
                disabled={!row?.is_scheduled}
              />
              <Button
                btnText={t("Absent")}
                btnBg={theme.colors.error}
                textColor={theme.colors.white}
                borderColor={theme.colors.error}
                style={{
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setModalProps({
                    ...modalProps,
                    isOpen: true,
                    operation: "ABSENCE",
                    userAttendanceData: row,
                    staffList: staff_list,
                    handleSave: (absentData) => {
                      handleQuickAttendanceUpdate({
                        type: "absent",
                        attendanceData: { ...row, ...absentData },
                      })
                    },
                  })
                }}
                disabled={!row?.is_scheduled}
              />
            </Flex>
          )
        }
        // if absent show absent label
        if (
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
        ) {
          return (
            <Button
              btnText={"欠勤"}
              borderColor={theme.colors.error}
              btnBg={theme.colors["pink-light"]}
              textColor={theme.colors.error}
            />
          )
        }

        return (
          <div id={""}>
            <div id={"list__time--content"}>
              <div>{row?.start_time}</div>
              <div>
                {row?.end_time || (
                  <Button
                    btnText={t("Checkout")}
                    btnBg={theme.colors.success}
                    width={"80px"}
                    textColor={theme.colors.white}
                    borderColor={theme.colors.success}
                    onClick={() =>
                      handleQuickAttendanceUpdate({
                        type: "checkout",
                        attendanceData: row,
                      })
                    }
                    disabled={!row?.is_scheduled}
                  />
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t("Break Time"),
      key: "total_break_time",
      className: "entry_time total_break_time",
      align: "center",
      width: "100px",
      render: (row) => (
        <span
          style={{
            color: theme.colors.success,
          }}
        >
          {row?.total_break_time}
        </span>
      ),
    },
    {
      title: t("Actual Cost"),
      key: "actual_cost",
      align: "center",
      width: 100,
      render: (row) =>
        row?.user_attendance_actual_cost?.map((actualCost, index) => {
          const key = index + "" + row?.username
          if (actualCost?.used_flag) {
            return (
              <div
                key={key}
                style={{
                  color: theme.colors.success,
                  fontWeight: 700,
                  marginBottom: "5px",
                }}
              >
                {actualCost?.actual_cost_name}
              </div>
            )
          }
          return (
            <Flex vertical gap={8} key={key} style={{ marginBottom: "5px" }}>
              <Button
                btnText={actualCost?.actual_cost_name}
                btnBg={theme.colors["info-light"]}
                textColor={theme.colors.info}
                borderColor={theme.colors.info}
                onClick={() =>
                  handleQuickAttendanceUpdate({
                    type: "save_actual_cost",
                    attendanceData: row,
                    usedActualCostId: actualCost?.actual_cost_id,
                  })
                }
                disabled={!row?.is_scheduled}
              />
            </Flex>
          )
        }),
    },
    {
      title: t("Meal"),
      key: "meal",
      align: "center",
      render: (row) => {
        if (row?.used_meal_name) {
          return (
            <div
              style={{
                color: theme.colors.success,
                fontWeight: 700,
              }}
            >
              {row?.used_meal_name}
            </div>
          )
        } else if (row?.scheduled_meal_id) {
          return (
            <Button
              btnText={row?.meal_name}
              btnBg={theme.colors["info-light"]}
              textColor={theme.colors.info}
              borderColor={theme.colors.info}
              onClick={() => {
                handleQuickAttendanceUpdate({
                  type: "save_meal",
                  attendanceData: row,
                })
              }}
              disabled={!row?.is_scheduled}
            />
          )
        }
      },
    },
    {
      title: t("Remark"),
      key: "Remark",
      className: "remark",
      align: "center",
      render: (row) => (
        <div id={"list__remark"}>
          <div>{row?.remark}</div>
        </div>
      ),
    },
    {
      title: t("Output remarks"),
      key: "output_remarks",
      className: "output_remarks",
      align: "center",
      render: (row) => (
        <div id={"list__remark"}>
          <div>{row?.output_remarks}</div>
        </div>
      ),
    },
  ]

  const handleUserAttendanceSuccess = () => {
    notification.success({
      message: dynamicLangString([
        "User attendance table",
        "Updated Successfully",
      ]),
    })
    setModalProps((prev) => ({ ...prev, isOpen: false }))
    refetchAttendanceList()
  }

  const { mutate, isLoading: updatingAttendance } = useMutation({
    mutationFn: quickUserAttendance,
    onSuccess: () => {
      handleUserAttendanceSuccess()
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const handleQuickAttendanceUpdate = ({
    type,
    attendanceData,
    usedActualCostId,
  }: {
    type: "checkin" | "checkout" | "absent" | "save_actual_cost" | "save_meal"
    attendanceData: any
    usedActualCostId?: number
  }) => {
    const quickAttendanceObject: any = {
      facility_id: attendanceData?.facility_id,
      user_id: attendanceData?.user_id,
      date: attendanceData?.date,
      action: type,
    }
    const currentDate = new Date()

    switch (type) {
      case "checkin":
        quickAttendanceObject.start_time = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
        break
      case "checkout":
        quickAttendanceObject.end_time = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
        break
      case "save_actual_cost":
        quickAttendanceObject.actual_cost_id = usedActualCostId
        break
      case "save_meal":
        quickAttendanceObject.meal_id = attendanceData?.scheduled_meal_id
        break
      case "absent":
        quickAttendanceObject.absence_no_addition =
          attendanceData?.attendance_type ==
          USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
        quickAttendanceObject.absence_reason = attendanceData?.absence_reason
        quickAttendanceObject.save_staff_id = attendanceData?.save_staff_id
    }

    mutate(quickAttendanceObject)
  }

  return (
    <>
      <Wrapper>
        <Table
          title={() => (
            <div className={"theader"}>{t("List of Adult attending")}</div>
          )}
          columns={columns || []}
          dataSource={attendance_list}
          scroll={{ x: "max-content" }}
          loading={isLoading || updatingAttendance}
          rowClassName={(row) => !row?.is_scheduled && "unscheduled-attendance"}
        />
        <div id={"dayListContentTable_footer"}>
          <div className={"selector_content"}>
            <SelectInput
              name={"user"}
              placeholder={"--"}
              options={facilities || []}
              onChange={(val) => {
                setModalProps({
                  ...modalProps,
                  facility_id: val,
                })
              }}
              popupMatchSelectWidth={false}
            />
            <Typography.Text>{t("To")}</Typography.Text>
            <Button
              iconType={"plus-black"}
              btnText={t("Add user")}
              shape={"round"}
              borderColor={theme.colors.border}
              onClick={() => {
                if (modalProps?.facility_id) {
                  router.push(
                    `/user-attendance-calendar/daily-list/add/${modalProps?.facility_id}?date=${
                      params?.date
                        ? dayjs(params?.date).format("YYYY-MM-DD")
                        : dayjs().format("YYYY-MM-DD")
                    }`,
                  )
                }
              }}
            />
          </div>
          <Button
            btnText={t("Go to delete form")}
            shape={"round"}
            onClick={() =>
              router.push(
                `/user-attendance-calendar/daily-list/delete-form?facility_ids=${router?.query?.facilityId}&date=${router?.query?.date}`,
              )
            }
          />
        </div>
      </Wrapper>
      {modalProps?.isOpen && (
        <DayListModal
          {...modalProps}
          onCancel={() => {
            setModalProps({
              ...modalProps,
              isOpen: false,
            })
          }}
          onReset={() => null}
          onSuccess={handleUserAttendanceSuccess}
        />
      )}
    </>
  )
}
