import {
  AccordionCard,
  Button,
  CheckBox,
  CheckBoxGroup,
  DatePicker,
  InputField,
  LOCATIONS as ORIGINAL_LOCATIONS,
  LabelTextWithLeftArrow,
  LabelTextWithLeftBorder,
  OperationSearchContent,
  RadioGroup,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { WEEK_DAYS, YM_FORMAT } from "../../../../constants"
import { Flex, Typography, Popconfirm } from "antd"
import { useApplicationManagement } from "../../../../context/ApplicationManagementContext"
import dayjs, { Dayjs } from "dayjs"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { CALANDER_HOURS_MINUTES } from "@project/common/src/constants"
import { useQuery } from "react-query"
import { fetchAllShiftUserMaster } from "../../../../services"

const ApplicationManagementEditOperation = ({
  onDisplayChange,
}: {
  onDisplayChange: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const {
    onReflect,
    contractFacilities,
    user_id,
    setParams,
    params,
    mealData,
    onResetForm,
  } = useApplicationManagement()

  const { data: shiftData } = useQuery(
    ["attendance-shift", contractFacilities],
    () =>
      fetchAllShiftUserMaster({
        page: 1,
        pageSize: "Infinity",
        facilityIds: [contractFacilities?.[1]?.value],
      }),
    {
      keepPreviousData: false,
      select: ({ data }) => {
        let options = [{ label: "--", value: "0" }]
        options = options.concat(
          data?.map((shift) => ({
            label: shift?.attendance_shift_name,
            value: shift?.id?.toString(),
          })),
        )
        return options
      },
      enabled: !!contractFacilities?.[1]?.value,
    },
  )

  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    date: dayjs([params?.year, params?.month - 1]).isValid()
      ? dayjs([params?.year, params?.month - 1])
      : dayjs(),
    facility: contractFacilities?.[1]?.value || null,
    day_of_week: [],
    meal_flag: null,
    goto_meet_flag: false,
    goto_meet_place: null,
    goto_meet_place_other: null,
    goto_meet_time: null,
    take_flag: false,
    take_place: null,
    take_place_other: null,
    take_time: null,
    remark_flag: "1",
    remarks: "",
    shift_id: "",
    is_applied: true,
  })

  const handleResetForm = () => {
    onResetForm()
  }
  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      date: dayjs([params?.year, params?.month - 1]).isValid()
        ? dayjs([params?.year, params?.month - 1])
        : dayjs(),
      facility: contractFacilities?.[1]?.value || null,
    }))
  }, [contractFacilities, params])

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const LOCATIONS = getOptionsWithTranslatedLabel(ORIGINAL_LOCATIONS)

  return (
    <AccordionCard
      activeKey={"application_management_operation"}
      items={[
        {
          key: "application_management_operation",
          label: t("Operation options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftBorder label={t("Display change")} />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year month")}
                  width={"136px"}
                />
                <DatePicker
                  date={values?.date || null}
                  onDateChange={(date: Dayjs) => {
                    setValues({
                      ...values,
                      date: date,
                    })
                  }}
                  format={YM_FORMAT}
                  picker={"month"}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  type={"primary"}
                  onClick={() => {
                    setParams({
                      ...params,
                      year: dayjs(values?.date).year(),
                      month: dayjs(values?.date).month() + 1,
                    })
                    updateParams(
                      {
                        year: dayjs(values?.date).year(),
                        month: dayjs(values?.date).month() + 1,
                      },
                      `/users/profile/${user_id}/application-management/edit`,
                    )
                    onDisplayChange()
                  }}
                />
              </div>

              <div className={"search__content"}>
                <LabelTextWithLeftBorder label={t("Bulk setting")} />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"136px"} />
                <SelectInput
                  name={"facility"}
                  options={contractFacilities || []}
                  placeholder={"--"}
                  value={values?.facility || null}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      facility: val,
                    })
                  }}
                  popupMatchSelectWidth={false}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Day of week")}
                  width={"136px"}
                />
                <Typography.Text>{t("Every week")}</Typography.Text>
                <CheckBoxGroup
                  items={getOptionsWithTranslatedLabel(WEEK_DAYS || [])}
                  name={"day_of_week"}
                  value={values?.day_of_week || null}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      day_of_week: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Meal")} width={"136px"} />
                <SelectInput
                  name={"meal_flag"}
                  options={[{ label: "--", value: null }].concat(
                    mealData || [],
                  )}
                  value={values?.meal_flag || null}
                  placeholder={"--"}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      meal_flag: value,
                    })
                  }}
                  popupMatchSelectWidth={false}
                />
              </div>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow label={t("Pick up")} width={"136px"} />
                <Flex vertical gap={16} wrap={"wrap"}>
                  <Flex align={"center"} gap={24} wrap={"wrap"}>
                    <CheckBox
                      label={t("Pickup")}
                      value={values?.goto_meet_flag}
                      onChange={({ target: { checked } }) => {
                        setValues({
                          ...values,
                          goto_meet_flag: checked,
                        })
                      }}
                    />
                    <Flex align={"center"} gap={8}>
                      <Typography.Text style={{ whiteSpace: "nowrap" }}>
                        {t("Pick up place")}
                      </Typography.Text>
                      <SelectInput
                        disabled={!values?.goto_meet_flag}
                        name={"place"}
                        width={"86px"}
                        placeholder={"--"}
                        value={values?.goto_meet_place || null}
                        options={LOCATIONS || []}
                        popupMatchSelectWidth={false}
                        filterSort={() => null}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            goto_meet_place: value,
                          })
                        }}
                      />
                      {values?.goto_meet_place === 2 && (
                        <InputField
                          height={"40px"}
                          placeholder={"--"}
                          value={values?.goto_meet_place_other || null}
                          onChange={({ target: { value } }) => {
                            setValues({
                              ...values,
                              goto_meet_place_other: value,
                            })
                          }}
                        />
                      )}
                    </Flex>
                    <Flex align={"center"} gap={8} wrap={"wrap"}>
                      <Typography.Text>
                        {t("Pick up desired time")}
                      </Typography.Text>
                      <Flex gap={8}>
                        <Flex align={"center"} gap={8}>
                          <SelectInput
                            name={"time_hr"}
                            width={"64px"}
                            placeholder={"--"}
                            shortSelector
                            disabled={!values?.goto_meet_flag}
                            value={
                              values?.goto_meet_time?.split(":")?.[0] || null
                            }
                            options={CALANDER_HOURS_MINUTES?.hours || []}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                goto_meet_time: value ? `${value}:00` : "",
                              })
                            }}
                          />
                          <Typography.Text>{":"}</Typography.Text>
                        </Flex>
                        <SelectInput
                          name={"time_min"}
                          width={"64px"}
                          shortSelector
                          placeholder={"--"}
                          disabled={
                            !values?.goto_meet_flag ||
                            !values?.goto_meet_time?.split(":")?.[0]
                          }
                          value={
                            values?.goto_meet_time?.split(":")?.[1] || null
                          }
                          options={CALANDER_HOURS_MINUTES?.minutes || []}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              goto_meet_time: `${values?.goto_meet_time?.split(
                                ":",
                              )?.[0]}:${value}`,
                            })
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex align={"center"} gap={24} wrap={"wrap"}>
                    <CheckBox
                      label={t("Dropoff")}
                      value={values?.take_flag}
                      onChange={({ target: { checked } }) =>
                        setValues({
                          ...values,
                          take_flag: checked,
                        })
                      }
                    />
                    <Flex align={"center"} gap={8}>
                      <Typography.Text style={{ whiteSpace: "nowrap" }}>
                        {t("Drop of place")}
                      </Typography.Text>
                      <SelectInput
                        name={"place"}
                        width={"86px"}
                        placeholder={"--"}
                        value={values?.take_place || null}
                        options={LOCATIONS || []}
                        disabled={!values?.take_flag}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            take_place: value,
                          })
                        }}
                      />
                      {values?.take_place === 2 && (
                        <InputField
                          height={"40px"}
                          placeholder={"--"}
                          value={values?.take_place_other || null}
                          onChange={({ target: { value } }) => {
                            setValues({
                              ...values,
                              take_place_other: value,
                            })
                          }}
                        />
                      )}
                    </Flex>
                    <Flex align={"center"} gap={8} wrap={"wrap"}>
                      <Typography.Text>
                        {t("Drop of desired time")}
                      </Typography.Text>
                      <Flex gap={8}>
                        <Flex align={"center"} gap={8}>
                          <SelectInput
                            name={"time_hr"}
                            width={"64px"}
                            placeholder={"--"}
                            shortSelector
                            value={values?.take_time?.split(":")?.[0] || null}
                            options={CALANDER_HOURS_MINUTES?.hours || []}
                            disabled={!values?.take_flag}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                take_time: value ? `${value}:00` : "",
                              })
                            }}
                          />
                          <Typography.Text>{":"}</Typography.Text>
                        </Flex>
                        <SelectInput
                          name={"time_min"}
                          width={"64px"}
                          shortSelector
                          placeholder={"--"}
                          value={values?.take_time?.split(":")?.[1] || null}
                          options={CALANDER_HOURS_MINUTES?.minutes || []}
                          disabled={
                            !values?.take_flag ||
                            !values?.take_time?.split(":")?.[0]
                          }
                          onChange={(value) => {
                            setValues({
                              ...values,
                              take_time: `${values?.take_time?.split(
                                ":",
                              )?.[0]}:${value}`,
                            })
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Update Remarks")}
                  width={"136px"}
                />
                <RadioGroup
                  options={[
                    {
                      label: t("Do not update"),
                      value: "1",
                    },
                    {
                      label: t("Update"),
                      value: "2",
                    },
                  ]}
                  value={values?.remark_flag || null}
                  onChange={({ target: { value } }) => {
                    setValues({
                      ...values,
                      remark_flag: value,
                    })
                  }}
                />
                <Flex>
                  <InputField
                    placeholder={"--"}
                    width={"190px"}
                    height={"40px"}
                    disabled={values?.remark_flag === "2" ? false : true}
                    value={values?.remarks || null}
                    onChange={({ target: { value } }) => {
                      setValues({
                        ...values,
                        remarks: value,
                      })
                    }}
                  />
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Shift")} />
                <SelectInput
                  name={"shift_id"}
                  options={shiftData || []}
                  value={values?.shift_id || null}
                  placeholder={"--"}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      shift_id: value,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Reflect")}
                  shape={"round"}
                  onClick={() => {
                    onReflect(values)
                    onDisplayChange()
                  }}
                />
                <Popconfirm
                  title={t(
                    "Are you sure to reset? All setting will be removed",
                  )}
                  onConfirm={handleResetForm}
                  cancelButtonProps={{ size: "middle" }}
                  okButtonProps={{ size: "middle" }}
                >
                  <Button btnText={t("Reset form")} shape={"round"} />
                </Popconfirm>
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}

export default ApplicationManagementEditOperation
