import React, { useState } from "react"
import { Flex, Skeleton, type TableProps } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { createId } from "@paralleldrive/cuid2"
import {
  InputField,
  Table,
  theme,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { ACTIVITY_STATEMENT_ROWS } from "./constant"
import { WRBusinessActivityStatement } from "../../../types"
import {
  getColsTotal,
  getRowsTotal,
  grandTotal,
  handleRowSpan,
} from "./helpers"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { TabTopInfoContent } from "../WagesRevenueCommon/TabTopInfoContent"
import { useAuthContext } from "../../../context"
import { useMutation, useQuery } from "react-query"
import {
  createBusinessActivityStatement,
  getBusinessActivityStatementByFacility,
} from "../../../services"
import dayjs, { Dayjs } from "dayjs"
import { CTAbtn } from "../WagesRevenueCommon/CTAbtn"
const StyledWrapper = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  .adder_col {
    width: 10px !important;
    padding: 0 !important;
  }
`
export const BusinessActivityStatementTabEditContent = ({
  selectedCol,
  setSelectedCol,
  params,
}: {
  selectedCol: WRBusinessActivityStatement | null
  setSelectedCol: (item: null) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [values, setValues] = useState({
    year: params?.date ? dayjs(params?.date)?.year() : selectedCol?.year,
    month: params?.date ? dayjs(params?.date)?.month() + 1 : selectedCol?.month,
    facility_id: selectedCol?.facility_id || null,
  })

  const { showToast } = useNotification()
  const handleColEdit = ({
    id,
    key,
    value,
  }: {
    id: number
    key: string
    value: string | number
  }) => {
    const newColumns = dynamicColumns?.map((col) => {
      if (col?.tempId === id) {
        return {
          ...col,
          [key]: value,
        }
      }
      return col
    })
    setDynamicColumns(newColumns)
  }

  //create/update data API call -start
  const { isLoading } = useQuery({
    queryKey: ["createBusinessActivityStatement", values],
    queryFn: () =>
      getBusinessActivityStatementByFacility({
        facility_id: values?.facility_id || selectedCol?.facility_id,
        year: values?.year || selectedCol?.year,
        month: values?.month || selectedCol?.month,
      }),
    onSuccess: (res: any) => {
      const resData = res?.msg?.map((v) => ({
        ...v,
        tempId: v?.id,
      }))
      setDynamicColumns(resData || [])
    },
    refetchOnWindowFocus: false,
  })
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: createBusinessActivityStatement,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Created Successfully"),
      })
      setSelectedCol(null)
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("An error has occurred. Please contact administrator."),
      })
    },
  })
  //create/update data API call -end

  const getDynamicColumns = () => {
    return dynamicColumns?.map((item, index) => ({
      key: index + 1,
      title: (
        <Flex align={"center"} gap={8}>
          <InputField
            value={item?.work_title || null}
            height={"40px"}
            placeholder={t("○○  Work")}
            onChange={({ target: { value } }) => {
              handleColEdit({
                id: item?.tempId,
                key: "work_title",
                value: value,
              })
            }}
          />
          <div
            onClick={() => {
              setDynamicColumns([
                ...dynamicColumns?.slice(0, index),
                ...dynamicColumns?.slice(index + 1),
              ])
            }}
          >
            <MinusCircleOutlined
              style={{
                color: theme.colors?.error,
              }}
            />
          </div>
        </Flex>
      ),
      render: (row) => {
        if (row?.key === "total_1" || row?.key === "total_2") {
          const { total_1, total_2 } = getRowsTotal(item)
          return (
            <Flex
              style={{
                paddingLeft: "8px",
              }}
            >
              {row?.key === "total_1" ? total_1 : total_2}
            </Flex>
          )
        }
        return (
          <InputField
            value={item[row?.key]}
            height={"40px"}
            type={"number"}
            onChange={({ target: { value } }) => {
              if (!isNaN(+value)) {
                handleColEdit({
                  id: item?.tempId,
                  key: row?.key,
                  value: +value,
                })
              }
            }}
          />
        )
      },
      width: 120,
    }))
  }
  const columns: TableProps<any>["columns"] = [
    {
      key: 1,
      title: t("Item list"),
      colSpan: 2,
      align: "center",
      render: (row, _, index) =>
        index === 11 ? (
          <AccountSubjectContent label={row?.item_list || ""} />
        ) : (
          <span style={{ whiteSpace: "nowrap" }}>{t(row?.type)}</span> || ""
        ),
      width: 70,
      onCell: handleRowSpan,
    },
    {
      key: 2,
      title: t(" "),
      colSpan: 0,
      align: "center",
      render: (row) => (
        <AccountSubjectContent label={t(row?.item_list) || ""} />
      ),
      width: 300,
      onCell: (_, index) => {
        if (index === 11) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      key: "total",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Total")}</span>,
      align: "center",
      render: (row) => {
        const colsTotal = getColsTotal(dynamicColumns || [])
        const { total_first, total_second } = grandTotal(dynamicColumns || [])
        if (row?.key === "total_1") {
          return <Flex>{total_first}</Flex>
        }
        if (row?.key === "total_2") {
          return <Flex>{total_second}</Flex>
        }
        return <Flex>{colsTotal[row?.key] || "0"}</Flex>
      },
      width: 120,
    },
    ...getDynamicColumns(),
    {
      key: "add",
      title: () => (
        <div
          onClick={() =>
            setDynamicColumns([
              ...dynamicColumns,
              {
                tempId: createId(),
                facility_id: selectedCol?.facility_id || 0,
                facility_name: selectedCol?.facility_name || "",
                work_title: "",
                year: selectedCol?.year,
                month: selectedCol?.month,
                business_income: 0,
                business_activity_earning: 0,
                business_sales_cost: 0,
                involved_product_inventory: 0,
                manufacturing_cost: 0,
                project_purchase_height: 0,
                final_product_inventory: 0,
                deduction: 0,
                jurisdiction_expense: 0,
                business_activity_expense: 0,
              },
            ])
          }
        >
          <PlusCircleOutlined
            style={{
              color: theme.colors.action,
              cursor: "pointer",
            }}
          />
        </div>
      ),
      align: "center",
      width: 20,
      className: "adder_col",
      fixed: "right",
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 12,
          }
        }
        if (index >= 1) {
          return {
            rowSpan: 0,
          }
        }

        return {}
      },
    },
  ]

  const handleCatBtnClick = (action: "cancel" | "save") => {
    if (action === "cancel") {
      setSelectedCol(null)
      return
    }
    mutate({
      facility_id: values?.facility_id || selectedCol?.facility_id,
      year: selectedCol?.year,
      month: selectedCol?.month,
      values: dynamicColumns || [],
    })
  }
  return (
    <StyledWrapper $disabled={isLoading}>
      <TabTopContent
        tab={t("Revenue")}
        onChange={(date: Dayjs) => {
          setValues({
            ...values,
            year: date?.year(),
            month: date?.month() + 1,
          })
          updateParams(
            {
              tab: "business",
              date: dayjs(date).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      <CTAbtn isLoading={isMutating} onClick={handleCatBtnClick} />
      <TabTopInfoContent
        facilities={facilities || []}
        facility={
          String(values?.facility_id) ||
          String(selectedCol?.facility_id) ||
          null
        }
        onChange={(fid: any) =>
          setValues({
            ...values,
            facility_id: fid,
          })
        }
      />
      {isLoading ? (
        <Flex vertical gap={4}>
          {[...Array(6)].map((_, index) => (
            <Flex gap={8} key={index}>
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Table
          columns={columns}
          dataSource={ACTIVITY_STATEMENT_ROWS || []}
          scroll={{ x: 800 }}
        />
      )}

      <CTAbtn isLoading={isMutating} onClick={handleCatBtnClick} />
    </StyledWrapper>
  )
}
