import { AxiosResponse } from "axios"
import { ROUTE_OPTIONS } from "../constant"

export const transformDriverStaffs = (
  data?: AxiosResponse<any, any>,
): {
  id: number
  value: number
  label: string
  furigana: string
}[] => {
  return data?.data.map((item) => ({
    id: item?.id,
    value: item?.id,
    label: item?.staff_name || "",
    furigana: item?.staff_name_furiganaame || "",
  }))
}
export const GetDistinctRouteOptionsForFacility = (
  staffs: any[],
  record: Record<string, any>,
) => {
  const found_info = staffs
    ?.filter((item) => item?.facility_id === record?.facility_id)
    ?.map((v) => v?.route)
  const routerOptions =
    found_info?.length > 1
      ? ROUTE_OPTIONS?.filter((item) => !found_info?.includes(item?.value))
      : ROUTE_OPTIONS
  return routerOptions
}
