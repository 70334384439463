import {
  Button,
  InputAreaField,
  SelectInput,
  USER_ATTENDANCE_VALUES,
  defaultAbsentNote,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"

import { japaneseAlphaRegex } from "../../../../utils/validation"
const Wrapper = styled.div`
  .absence_modal_content {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    padding: 12px;
    .absence-record-example {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-left: 5px;
      margin-top: 9px;
    }
    .absence-count-month {
      border: 1px solid ${theme.colors.error};
      padding: 10px;
      text-align: center;
      font-weight: 700;
      color: ${theme.colors.error};
    }
    .absence-info {
      font-size: 12px;
    }
    .reason_text {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .absence_modal_footer {
    /* position: absolute; */
    border-top: 1px solid red;
    padding: 12px;
  }
`
export const DayListAbsenceForm = ({
  userAttendanceData = {},
  handleSave,
  staffList = [],
}: {
  userAttendanceData?: any
  handleSave: (val) => void
  staffList?: Array<any>
}) => {
  const { t } = useTranslation()
  const defaultAbsentNoteWithNextScheduled =
    defaultAbsentNote +
    `${
      userAttendanceData?.next_scheduled_date
        ? dayjs(userAttendanceData?.next_scheduled_date).format("MM月DD日")
        : ""
    }`
  const [absentNote, setAbsentNote] = useState(
    userAttendanceData?.absence_reason ?? defaultAbsentNoteWithNextScheduled,
  )
  const [furigana, setFurigana] = useState(null)
  const [saveStaffId, setSaveStaffId] = useState(null)

  const staffOptions = useMemo(() => {
    const regex = japaneseAlphaRegex(furigana)
    const staffOpt = staffList.map((staff) => ({
      value: staff?.id,
      label: staff?.staff_name,
      furigana: staff?.staff_name_furiganaame,
    }))

    if (furigana) {
      return staffOpt?.filter(
        (staff) => staff?.furigana?.match(regex) || staff?.label?.match(regex),
      )
    }
    return staffOpt
  }, [furigana])

  const handleAbsentUser = (charge) => {
    if (typeof handleSave == "function") {
      handleSave({
        attendance_type: charge
          ? USER_ATTENDANCE_VALUES.ABSENCE
          : USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
        absence_reason: absentNote,
        save_staff_id: saveStaffId,
      })
    }
  }

  return (
    <Wrapper>
      <div className={"absence_modal_content"}>
        <div>
          {t(
            "Additions for absenteeism can be calculated when there is a notice of cancellation on the day before, the day before, or on the day of cancellation.",
          )}
        </div>
        <div>
          {t(
            "It is necessary to provide consultation assistance over the phone, etc., and to record the content of the consultation assistance.",
          )}
        </div>
        <div>{t("Record example of consultation assistance")}</div>
        <ol className={"absence-record-example"}>
          {[
            t("Date of absence"),
            t("who called"),
            t("Respondent who received contact"),
            t("Reason for absence"),
            t("Appearance of the person on the day"),
            t("Scheduled date of the next visit"),
          ]?.map((v, index) => (
            <li key={index}>
              <span
                style={{
                  marginRight: "6px",
                }}
              >
                {index + 1} {"."}
              </span>
              <span>{v}</span>
            </li>
          ))}
        </ol>
        <div className={"absence-count-month"}>
          {t("absentDaysInMonth", {
            month: dayjs(userAttendanceData?.date).month() + 1,
            days: userAttendanceData?.absent_count_for_month,
            // show count to be updated on modal
          })}
        </div>
        <div className={"reason_text"}>
          {t(
            `If you want to calculate the addition for absence, please enter the reason from the reason entry column below and press "Reflect".`,
          )}
        </div>
        <div className={"absence-info"}>
          {t(
            "*It is possible to leave a reason even if the absence fee is not calculated.",
          )}
        </div>
        <Flex gap={24} align={"center"} wrap={"wrap"}>
          <Typography.Text> {t("[Recorder]")}</Typography.Text>
          <Flex gap={16} align={"center"}>
            <SelectInput
              placeholder={"--"}
              name={"recorder_furigana"}
              options={FuriganaAlphabetsOption}
              value={furigana}
              onChange={(val) => {
                setFurigana(val)
                setSaveStaffId(null)
              }}
              width={"78px"}
              height={"42px"}
              shortSelector
            />
            <SelectInput
              placeholder={"--"}
              name={"recorder"}
              options={staffOptions}
              value={saveStaffId}
              height={"42px"}
              onChange={(val) => setSaveStaffId(val)}
            />
          </Flex>
        </Flex>
        <div className={"absent-note"}>
          <InputAreaField
            onChange={(e) => setAbsentNote(e?.target?.value)}
            value={absentNote}
            rows={5}
          />
        </div>
      </div>

      <Flex
        className={"absence_modal_footer"}
        justify={"flex-end"}
        gap={16}
        wrap={"wrap"}
      >
        <Button
          btnText={t("Calculate Absence Charges")}
          shape={"round"}
          borderColor={theme.colors.border}
          btnBg={theme.colors.background}
          onClick={() => handleAbsentUser(true)}
        />
        <Button
          btnText={t("Do not calculate extra for absence")}
          shape={"round"}
          borderColor={theme.colors.border}
          btnBg={theme.colors.background}
          onClick={() => handleAbsentUser(false)}
        />
      </Flex>
    </Wrapper>
  )
}
