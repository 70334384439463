import { INHIFNumberedSection } from "../../types/nhif.types"
import { NumberedSectionContainer } from "./NHIFForm.styles"

export const NHIFNumberedSection = (props: INHIFNumberedSection) => {
  const { number, title, children, id = "", disabled = false } = props
  return (
    <NumberedSectionContainer id={id}>
      {disabled && <div className={"disable-overlay"}></div>}
      <div className={"heading"}>
        <div className={"number-title"}>{number}</div>
        <div className={"title"}>{title}</div>
      </div>
      <div className={"child-content"}>{children}</div>
    </NumberedSectionContainer>
  )
}
