import {
  AccordionCard,
  ActionBtns,
  Button,
  Card,
  DatePicker,
  LabelTextWithLeftArrow,
  MonthPagination,
  SelectInput,
  Table,
  removeBlankAttributes,
  theme,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { PAGE_SIZE, YM_FORMAT } from "../../../constants"
import { AuthContext } from "../../../context"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import { getMealsInformation } from "../../../services"
import { filterWithHiragana } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { AmountPerUseFilterContainer, Wrapper } from "./AmountPerUser.style"
dayjs.extend(arraySupport)
const AmountPerUse = ({ today }): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const listContent = useRef<any>(null)
  const { page } = router?.query as any
  const [startChar, setStartChar] = useState(null)
  const [username, setUserName] = useState<string>(null)
  const [yearMonth, setYearMonth] = useState<any>(today)
  const [facilityId, setFacilityId] = useState<any>(null)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [currentPage, setCurrentPage] = useState(+page || 1)
  const { facilities } = useContext(AuthContext)
  const emptyList = [{ label: "--", value: 0 }]
  const facilitiesOpt = facilities.map((facility) => {
    return {
      label: facility?.facility_name,
      value: facility?.id,
    }
  })

  const facilityOptions = [...emptyList, ...facilitiesOpt]

  const [queries, setQueries] = useState<any>({
    date: yearMonth,
    userId: username,
    facilityId: facilityId,
  })
  const { userData: allUsers } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })
  const { data: mealInformationData, isLoading } = useQuery<any>(
    ["meal-information-masters", currentPage, queries],
    {
      queryFn: getMealsInformation,
    },
  )

  const columns: ColumnsType<any[]> = [
    {
      title: t("Detail"),
      key: "detail",
      align: "center",
      render: (row) => {
        const selectedDate = dayjs(yearMonth).isValid()
          ? dayjs(yearMonth).format("YYYY-MM")
          : dayjs().format("YYYY-MM")
        return (
          <Link
            href={`/meal-master/detail/${row?.id}?date=${selectedDate}&tab=amount-per-use`}
            passHref
          >
            {t("Detail")}
          </Link>
        )
      },
    },
    {
      title: t("Username "),
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: t("Expense"),
      dataIndex: "expenses",
      key: "expenses",
      align: "center",
      render: (data) => (
        <span>
          {data ? new Intl.NumberFormat().format(data) : "0"} {t("円")}
        </span>
      ),
    },
  ]

  const onSearch = () => {
    const values = {
      userId: username,
      date: yearMonth,
      facilityId: facilityId,
    }
    setCurrentPage(1)
    const params: any = removeBlankAttributes(values)

    const paramsObj = {
      year: dayjs(params?.date).format("YYYY"),
      month: dayjs(params?.date).format("MM"),
      user_id: params?.userId,
      facility_id: params?.facilityId,
    }

    const filterParamsObj: any = removeBlankAttributes(paramsObj)

    setQueries(params)
    const baseUrl = "meal-master?tab=amount-per-use"
    const queryParams = new URLSearchParams(filterParamsObj).toString()
    const url = `${baseUrl}&${queryParams}`
    router.push(url, undefined, { shallow: true })
    setShouldScroll(true)
  }

  useEffect(() => {
    if (shouldScroll && listContent.current) {
      listContent.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
      setShouldScroll(false)
    }
  }, [shouldScroll])

  const resetHandler = () => {
    setYearMonth(today)
    setUserName(null)
    setFacilityId(0)
    setCurrentPage(1)
    setQueries({
      date: today,
      userId: null,
    })
    router.push("/meal-master?tab=amount-per-use", undefined, { shallow: true })
    setShouldScroll(true)
  }

  return (
    <Wrapper>
      <AccordionCard
        defaultActiveKey={"amount_per_use"}
        customBorder={`1px solid ${theme.colors.border}`}
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <AmountPerUseFilterContainer>
                <div className={"search__container"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility  ")}
                    width={"110px"}
                  />
                  <SelectInput
                    name={"facility"}
                    options={facilityOptions}
                    value={facilityId}
                    onChange={(val) => {
                      setFacilityId(val)
                    }}
                  />
                </div>
                <div className={"search__container"}>
                  <LabelTextWithLeftArrow
                    label={t("Username ")}
                    width={"110px"}
                  />
                  <div className={"search__content"}>
                    <SelectInput
                      name={"user_name_hiragana"}
                      options={FuriganaAlphabetsOption}
                      onChange={(val) => {
                        setStartChar(val)
                        setUserName(null)
                      }}
                      width={"86px"}
                      placeholder={"--"}
                      value={startChar}
                    />
                    <SelectInput
                      name={"user_name"}
                      width={"282px"}
                      className={"username"}
                      options={filterWithHiragana({
                        options: allUsers,
                        furigana: startChar,
                      })}
                      placeholder={"--"}
                      value={username}
                      onChange={(val) => {
                        setUserName(val)
                        setStartChar(null)
                      }}
                    />
                  </div>
                </div>
                <div className={"search__container"}>
                  <LabelTextWithLeftArrow
                    label={t("Year & month")}
                    width={"110px"}
                  />
                  <DatePicker
                    style={{
                      width: "158px",
                    }}
                    picker={"month"}
                    date={dayjs(yearMonth)}
                    onDateChange={(date) => {
                      const data = dayjs(date).format("YYYY-MM")
                      setYearMonth(data)
                    }}
                    placeholder={"--"}
                    format={"YYYY年MM月"}
                  />
                </div>
                <ActionBtns justify={"flex-start"}>
                  <Button
                    btnText={t("Reset Search")}
                    shape={"round"}
                    onClick={() => {
                      resetHandler()
                    }}
                  />

                  <Button
                    btnText={t("Search")}
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    onClick={() => {
                      onSearch()
                    }}
                  />
                </ActionBtns>
              </AmountPerUseFilterContainer>
            ),
          },
        ]}
      />
      <div ref={listContent}>
        <Card
          title={t("yearMonthCostList", {
            yearMonth: dayjs(queries.date).format(YM_FORMAT),
          })}
          borderStyle={`1px solid ${theme.colors.border}`}
          boxShadow={"unset"}
          extra={
            <MonthPagination
              currentMonth={dayjs(queries?.date)?.month()}
              currentYear={dayjs(queries?.date)?.year()}
              showDate={false}
              change={"month"}
              width={"80px"}
              onMonthChange={(_, __, date) => {
                setQueries({
                  ...queries,
                  date: date,
                })
                setYearMonth(date)
              }}
            />
          }
        >
          <Table
            columns={columns}
            cellPaddingBlock={10}
            dataSource={mealInformationData?.data || []}
            showPaginationOf={"both"}
            pageSize={PAGE_SIZE}
            current={currentPage}
            total={mealInformationData?.count}
            scroll={{
              x: 500,
            }}
            loading={isLoading}
            onChange={(val) => {
              setCurrentPage(val)
            }}
          />
        </Card>
      </div>
    </Wrapper>
  )
}

export { AmountPerUse }
