import { styled } from "styled-components"
import {
  IEmploymentTag,
  IGraphProps,
} from "../../types/RevenueManagement.types"
import { theme } from "@project/common"

export const RevenueManagementOperationContainer = styled.div`
  margin-bottom: 20px;
`

export const EmploymentTag = styled.div<IEmploymentTag>`
  padding: 4px 8px;
  border-radius: 6px;
  background: ${(props) =>
    props.service == 1
      ? "#FFFDF3"
      : props.service == 2
        ? "#F2FFF7"
        : "#EFF9FF"};
  border: ${(props) =>
    props.service == 1
      ? "1px solid #CDA001"
      : props.service == 2
        ? "1px solid #4CB476"
        : "1px solid #0782C8"};
  color: ${(props) =>
    props.service == 1
      ? "#AF8900"
      : props.service == 2
        ? "#4CB476"
        : "#0782C8"};
`

export const FacilityRevenueTotalCell = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`

export const FacilityAchievementCell = styled.div`
  .achievement-content {
    margin-bottom: 5px;
    .achievement-header {
      padding: 4px 8px;
      color: #fff;
      border-radius: 6px;
      background: ${theme.colors.gray3};
    }
    .achievement-body {
      text-align: left;
      padding: 10px 0;
    }
  }
`

export const RevenueManagementContentainer = styled.div`
  .top-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .total-value {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .revenue-table {
    width: 100%;
    overflow-x: auto;
    .revenue-day,
    .revenue-day-week {
      width: 70px;
    }
    .revenue-day-data {
      text-align: left;
    }
    tr.amount-row > td:not(:first-child) {
      background: #fcf0e9;
    }
    tr.holiday-row > td {
      background: #fce6e6;
      &:nth-child(n + 3) {
        color: #e00000;
      }
    }
  }
`

export const RevenueManagementGraphTopSection = styled.div`
  margin-bottom: 20px;
`

export const RevenueManagemenetGraphCumulativeTable = styled.div`
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    th {
      padding: 12px;
      background: #e4eaa1b2;
      border: 1px solid ${theme.colors.border};
    }
    td {
      text-align: center;
      font-size: 20px;
      padding: 15px;
      border: 1px solid ${theme.colors.border};
    }
  }
`

export const RevenueManagementAttendanceTable = styled.div`
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    th {
      padding: 12px;
      background: #e0f2fe;
      border: 1px solid ${theme.colors.border};
    }
    td {
      text-align: center;
      padding: 15px;
      border: 1px solid ${theme.colors.border};
    }
  }
`

export const RevenueManagementGraphContainer = styled.div<IGraphProps>`
  width: 100%;
  margin-top: 10px;
  .graph-title {
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid ${theme.colors.border};
  }
  .graph-content {
    margin: 20px 0;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: auto;
    .graph {
      overflow-x: auto;
      min-width: 500px;
      width: ${(props) => (!props.show_legend ? "100%" : "70%")};
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        min-width: 100%;
        width: 100%;
      }
    }
    .graph_legend {
      min-width: 200px;
      height: auto;
      border: 1px solid ${theme.colors.border};
      padding: 10px;
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      .each_info {
        display: flex;
        align-items: center;
        .color_legend {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
`
