import { theme } from "@project/common"
import styled from "styled-components"

export const ServiceTypeWrapper = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    .button {
      .ant-btn {
        background-color: ${theme.colors.blue2};
        border: 1px solid ${theme.colors.action};
      }
    }
  }
  .ant-divider {
    border-block-start: 1px solid #d2d1d1;
  }
`
