import { Button, Card, removeBlankAttributes, theme } from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { AlertBox } from "../../../../components"
import {
  CheckedButtonContainer,
  Counter,
  QuoteTitle,
  UserUsingFacility,
} from "./style"
import { TableList } from "./TableList"
import PostTemporaryForm from "./PostTemporaryForm"

export const ReceiptUserTableList = ({
  FACILITIES_LABEL,
  allReceiptUserData,
  isFetching,
  listContent,
}) => {
  const { query } = useRouter()
  const router = useRouter() as any

  const date = `${query?.year}-${query?.month}`
  const currentDate = dayjs(date).format("YYYY年MM月")

  const [dataSource, setDataSource] = useState<Record<string, any[]>>(
    allReceiptUserData?.data?.city_wise || {},
  )

  useEffect(() => {
    setDataSource(allReceiptUserData?.data?.city_wise)
  }, [allReceiptUserData])

  const handleCheckAndUnCheckAll = (action: "ALL" | "NONE") => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => {
        return {
          ...data,
          isChecked: action === "ALL" ? true : false,
        }
      })
    })
    setDataSource(newData)
  }
  const handleCheckRecipientCertificates = () => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => {
        if (data?.receiving_certificate_number) {
          return {
            ...data,
            isChecked: true,
          }
        }
        return {
          ...data,
        }
      })
    })
    setDataSource(newData)
  }

  const handleSingleCheckAndUncheck = ({
    userId,
    checked,
    tableRows,
    selectedKey,
  }: {
    userId: number
    checked: boolean
    tableRows?: "ALL" | "NONE"
    selectedKey?: string
  }) => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => {
        if (tableRows && selectedKey === key) {
          return {
            ...data,
            isChecked: tableRows === "ALL" ? true : false,
          }
        }
        if (data?.user_id === userId) {
          return {
            ...data,
            isChecked: checked,
          }
        }
        return data
      })
    })
    setDataSource(newData)
  }

  useEffect(() => {
    const selectedData = router.query?.selected
      ?.split(",")
      ?.map((d) => Number(d))
    const newData = {}
    Object.keys(dataSource).forEach((key) => {
      newData[key] = dataSource[key]?.map((source) => ({
        ...source,
        isChecked: selectedData?.includes(source?.user_id),
      }))
    })
    setDataSource(newData)
  }, [router.query.selected])

  const gotoPrintPage = () => {
    const checkedIds = []
    Object?.keys(dataSource)?.map((key) => {
      return dataSource[key]?.map((data) => {
        if (data?.isChecked) {
          checkedIds.push(data?.user_id)
        }
      })
    })
    router.push({
      pathname: `/national-health-insurance-billing/prints/receipt-user`,
      query: removeBlankAttributes({
        selected: checkedIds?.join(","),
        facilityIds: router?.query?.facilityIds,
        year: dayjs().year(),
        month: dayjs().month() + 1,
        mode: "print",
      }),
    })
  }

  const totalBillingAmount = () => {
    const allRecords = Object.values(dataSource).flat()

    const totalBillingAmount = allRecords.reduce((acc, record: any) => {
      return record.isChecked ? acc + +record?.billed_amount : acc
    }, 0)
    return totalBillingAmount
  }
  const printLength = Object.values(dataSource)
    .flat()
    ?.find((d) => d?.isChecked)?.isChecked

  return (
    <Card
      title={t("{{date}} シュシュ本庄 Print receipts for users", {
        date: currentDate,
      })}
    >
      <AlertBox
        isCollapseAble={false}
        heading={t(
          `The billed amounts for the following facilities are totaled and output below.`,
        )}
        subHeading={FACILITIES_LABEL}
        width={"100%"}
        color={theme.colors.infoDark}
        isArray={true}
      />
      <br />
      <PostTemporaryForm />
      {Object.keys(dataSource).length ? (
        <CheckedButtonContainer>
          <Flex align={"center"} gap={16} wrap={"wrap"}>
            <Button
              shape={"round"}
              btnBg={theme?.colors.white}
              borderColor={"none"}
              iconType={"check-all"}
              btnText={t("Check all")}
              className={"size-sm"}
              onClick={() => handleCheckAndUnCheckAll("ALL")}
              disabled={isFetching}
            />
            <Button
              shape={"round"}
              btnBg={theme?.colors.white}
              borderColor={"none"}
              btnText={t(
                "Check only for User with valid recipient certificates",
              )}
              onClick={() => handleCheckRecipientCertificates()}
              disabled={isFetching}
            />
            <Button
              shape={"round"}
              btnBg={theme?.colors.white}
              borderColor={"none"}
              btnText={t("Clear all")}
              className={"size-sm"}
              onClick={() => handleCheckAndUnCheckAll("NONE")}
              disabled={isFetching}
            />
          </Flex>
        </CheckedButtonContainer>
      ) : null}

      {dataSource &&
        Object?.keys(dataSource)?.map((key) => {
          const data = dataSource[key]

          return (
            <UserUsingFacility key={key} ref={listContent}>
              <QuoteTitle>{`${
                data[0]?.provision_city || "本庄市"
              } (${key})`}</QuoteTitle>
              <TableList
                dataSource={data || []}
                loading={isFetching}
                handleSingleCheckAndUncheck={(values) =>
                  handleSingleCheckAndUncheck({
                    ...values,
                    selectedKey: key,
                  })
                }
                isAllChecked={data?.every((each) => each?.isChecked)}
              />
            </UserUsingFacility>
          )
        })}

      {Object.keys(dataSource).length ? (
        <Flex align={"center"} gap={16} wrap={"wrap"}>
          <Button
            shape={"round"}
            iconType={"print"}
            type={"primary"}
            btnText={t("Print")}
            onClick={gotoPrintPage}
            disabled={!printLength}
          />
          <Counter>
            {t("Invoice amount to users total {{amount}}円", {
              amount: totalBillingAmount(),
            })}
          </Counter>
        </Flex>
      ) : (
        <div>{t("No Data Found")}</div>
      )}
    </Card>
  )
}
