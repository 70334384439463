import { API } from "@project/common"

export type UserDailyTransportData = {
  data?: UserDailyTransport
}

export type PartialNullish<T extends Record<string, any>> = Partial<{
  [K in keyof T]: T[K] | null
}>

export type UserTransportCountData = {
  data?: Array<UserTransportCount>
}
export interface UserDailyTransport {
  pickup_users: PartialNullish<DropOrPickUser>[]
  pickup_staffs: PartialNullish<PickupOrDropOffStaff>[]
  drop_users: PartialNullish<DropOrPickUser>[]
  drop_staffs: PartialNullish<PickupOrDropOffStaff>[]
}

export interface UserTransportCount {
  facility_id: number
  transportation_count: Array<DailyUserTransportCount>
}

export interface DailyUserTransportCount {
  date: string
  drop_count: number
  facility_id: number
  holiday_flag: string
  pickup_count: number
  total_count: number
}

export interface DriverStaff {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  staff_name: string
  staff_name_furiganaame: string
  joining_date: any
  retirement_date: any
  staff_qualification: any
  staff_experience: any
  staff_facility: any
}

export interface EscortStaff {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  staff_name: string
  staff_name_furiganaame: string
  joining_date: any
  retirement_date: any
  staff_qualification: any
  staff_experience: any
  staff_facility: any
}

export interface Car {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  car_name: string
  max_number_of_people: number
  color_id: number
  car_inspection_date: any
  car_number_01: string
  car_number_02: string
  car_number_03: string
  car_number_04: string
}

export interface Facility {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  business_number: string
  facility_name: string
  facility_name_furigana: string
  facility_type: number
  grade: string
  physical_disability_support_flag: boolean
  intellectual_disability_support_flag: boolean
  mental_disability_support_flag: boolean
  development_disability_support_flag: boolean
  brain_disfunction_support_flag: boolean
  disabled_child_support_flag: boolean
  incurable_disease_support_flag: boolean
  meal_provision_flag: boolean
  capacity: number
  support_visits_nurseries_flag: boolean
  mail_address: string
  show_my_page_info: boolean
  contact_po_box: string
  address: string
  contact_phone: string
  fax: string
  bcp_evacuation: string
  bcp_pref_id: number
  remaining_address: string
  bcp_phone: string
  hospital_name: string
  hospital_po_box: string
  hospital_pref_id: number
  municipality_address: string
  hospital_phone: string
  note: string
  pay_slip_alert_date: string
  facility_wage_setting: any
  corporate_name: string
  representative_name: string
  administrator_name: string
  invoice_title: string
  invoice_full_name: string
  financial_institution_name: string
  financial_institution_name_kana: string
  financial_institution_branch_name_kana: string
  account_type: number
  account_holder_name: string
  account_holder_name_kana: string
  consign_number: string
  consign_name_kana: string
  branch_no: string
  bank_no: string
  is_draft: boolean
}

export interface DropOrPickUser {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  date: string
  user_id: number
  pickup_type: string
  pickup_time: string
  arrival_time: string
  route: any
  is_absent: boolean
  use_transportation_flag: boolean
  desired_time: string
  place: number
  place_other_name: string
  facility: string
  user_name: string
  user_name_furigana: string
  remarks: string
  driver_staff: DriverStaff
  escort_staff: EscortStaff
  car: Car
  same_premises: boolean
  allowQuickUpdate?: boolean
}

export interface PickupOrDropOffStaff {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  car_id: number
  date: string
  pickup_type: string
  route: number
  driver_staff_id: number
  escort_staff_id: number
  start_time: string
  end_time: string
  power: string
  damage_to_device: string
  alcohol_check: string
  visual_check: string
  facility: Facility
  driver_staff: DriverStaff
  escort_staff: EscortStaff
  car: Car
}

export type DailyTransportParam = {
  date: string
  facilityIds?: string
  service?: string
  pickup_type?: string
  staff_id?: string
  user_id?: string
}

export type UserTransportCountParam = {
  facilityIds?: string
  year: number | string
  month: number | string
}

type GetUserDailyTransportFn = (
  params: DailyTransportParam,
) => Promise<UserDailyTransportData>

export interface ITransportData {
  data?: any
}

export type MutateUserDailyTransportFn = (
  payload: PartialNullish<UserDailyTransport>,
) => Promise<unknown>

export type GetUserTransportCountFn = (
  params: UserTransportCountParam,
) => Promise<UserTransportCountData>

export const getUserDailyTransport: GetUserDailyTransportFn = (params) =>
  API.get(`transportation-users/daily`, {
    params: params,
  })

export const mutateUserDailyTransport: MutateUserDailyTransportFn = (payload) =>
  API.post("transportation-users", payload)

export const getTransportationUsersCount: GetUserTransportCountFn = (params) =>
  API.get("transportation-users/count", { params })
