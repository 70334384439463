import { styled } from "styled-components"

export const Container = styled.div`
  .input_container {
    display: flex;
    align-items: center;
    gap: 8px;
    .error_container {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      width: 100%;
      #error {
        align-self: flex-start;
        color: #e00000;
      }
    }
  }
  .required {
    color: #e00000;
  }
  .service_type_btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-table-content {
    overflow: auto;
    table {
      thead {
        tr {
          th {
            &:first-child {
              min-width: 150px;
            }
            &:nth-child(2) {
              min-width: 150px;
            }
            &:nth-child(3) {
              min-width: 150px;
            }
            &:nth-child(4) {
              min-width: 200px;
            }
            &:last-child {
              min-width: 200px;
            }
          }
        }
      }
    }
  }
`
