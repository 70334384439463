import { theme } from "@project/common"
import styled from "styled-components"

export const UserStatusListContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .user-select {
    display: flex;
    gap: 10px;
    width: 100%;
    /* .first-child {
      width: 25%;
    }
    .second-child {
      width: 75%;
    } */
    .first-child {
      @media screen and (max-width: 540px) {
        width: 25%;
      }
    }
    .second-child {
      @media screen and (max-width: 540px) {
        width: 75%;
      }
    }
  }
  .status_wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    @media screen and (max-width: 540px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .status {
      width: 100%;
    }
  }
  .input_main {
    @media screen and (max-width: 430px) {
      flex-direction: column;
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
  }
  .created {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn {
      color: #fff;
    }
  }
  .not-created {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn {
      border: 1px solid #d2d1d1d1;
    }
  }
  .input_label {
    display: flex;
    margin-bottom: 5px;
  }
`
export const UserStatusListListContent = styled.div`
  display: flex;
  flex-direction: column;
  .col-title {
    white-space: nowrap;
  }
  gap: ${theme.space.default};
  .flex-start {
    align-items: flex-start !important;
    .mt {
      padding-top: 0.5em;
    }
  }

  table > tbody {
    tr {
      td {
        padding-left: 0.2em !important;
        padding-right: 0.2em !important;
        & > div {
          button {
            span {
              font-size: 10px !important;
            }
          }
        }
      }
    }
    .no-certificate-row {
      background-color: ${theme.colors.lightPink};
    }
  }
`
export const TableContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 18px;
  flex-wrap: wrap;
`
export const TMiniContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0px;
  flex-wrap: wrap;
`
