import {
  AccordionCard,
  ActionBtns,
  Button,
  CheckBoxGroup,
  DatePicker,
  FacilityCheckbox,
  InputField,
  LabelTextWithLeftArrow,
  OpFlexContainer,
  OperationSearchContent,
  WORK_TYPES,
} from "@project/common"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../context"
import { useFetchOccupation } from "../../hooks/useFetchData"
import { StaffMasterOperationOptions } from "../../types"

export const StaffOperationOption = ({
  params,
  onSearch,
  onSearchReset,
}: {
  params?: StaffMasterOperationOptions
  onSearch: (values: StaffMasterOperationOptions) => void
  onSearchReset: (values: StaffMasterOperationOptions) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const { occupations } = useFetchOccupation()
  const [values, setValues] = useState({
    keyword: params?.keyword || "",
    facility: params?.facility || [],
    occupation: params?.occupation || [],
    joining_date_from: params?.joining_date_from || null,
    joining_date_to: params?.joining_date_to || null,
    retirement_date_from: params?.retirement_date_from || null,
    retirement_date_to: params?.retirement_date_to || null,
    work_style: params?.work_style || [],
  })

  useEffect(() => {
    setValues({
      keyword: params?.keyword,
      facility: params?.facility,
      occupation: params?.occupation,
      joining_date_from: params?.joining_date?.split("_")[0] || null,
      joining_date_to: params?.joining_date?.split("_")[1] || null,
      retirement_date_from: params?.retirement_date?.split("_")[0] || null,
      retirement_date_to: params?.retirement_date?.split("_")[1] || null,
      work_style: params?.work_style || null,
    })
  }, [params])

  return (
    <AccordionCard
      accordion={true}
      defaultActiveKey={["staff_operation_options"]}
      items={[
        {
          key: "staff_operation_options",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Staff name")}
                  width={"150px"}
                />
                <InputField
                  size={"large"}
                  placeholder={t("Search from now")}
                  width={"300px"}
                  borderRadius={"5px"}
                  value={values?.keyword || ""}
                  onChange={({ target: { value } }) =>
                    setValues({
                      ...values,
                      keyword: value,
                    })
                  }
                />
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Affiliated facility")}
                    width={"138px"}
                  />
                </div>
                <FacilityCheckbox
                  options={facilities || []}
                  value={values?.facility}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setValues({
                        ...values,
                        facility: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        facility: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      facility: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt-sm"}>
                  <LabelTextWithLeftArrow
                    label={t("Occupation")}
                    width={"138px"}
                  />
                </div>
                <CheckBoxGroup
                  items={occupations || []}
                  value={values?.occupation || null}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      occupation: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <OpFlexContainer>
                  <div className={"search__content-date"}>
                    <LabelTextWithLeftArrow
                      label={t("Join date")}
                      width={"138px"}
                    />
                    <div className={"date-content"}>
                      <DatePicker
                        clear={true}
                        date={
                          dayjs(values?.joining_date_from).isValid()
                            ? dayjs(values?.joining_date_from)
                            : null
                        }
                        onDateChange={(date: Dayjs) => {
                          setValues({
                            ...values,
                            joining_date_from: date,
                          })
                        }}
                        format={"YYYY年MM月DD日"}
                      />
                      {"~"}
                      <DatePicker
                        clear={true}
                        date={
                          dayjs(values?.joining_date_to).isValid()
                            ? dayjs(values?.joining_date_to)
                            : null
                        }
                        onDateChange={(date: Dayjs) => {
                          setValues({
                            ...values,
                            joining_date_to: date,
                          })
                        }}
                        format={"YYYY年MM月DD日"}
                      />
                    </div>
                  </div>
                  <div className={"search__content-date"}>
                    <LabelTextWithLeftArrow
                      label={t("Retirement date")}
                      width={"138px"}
                    />
                    <div className={"date-content"}>
                      <DatePicker
                        clear={true}
                        date={
                          dayjs(values?.retirement_date_from).isValid()
                            ? dayjs(values?.retirement_date_from)
                            : null
                        }
                        onDateChange={(date: Dayjs) => {
                          setValues({
                            ...values,
                            retirement_date_from: date,
                          })
                        }}
                        format={"YYYY年MM月DD日"}
                      />
                      {"~"}
                      <DatePicker
                        clear={true}
                        date={
                          dayjs(values?.retirement_date_to).isValid()
                            ? dayjs(values?.retirement_date_to)
                            : null
                        }
                        onDateChange={(date: Dayjs) => {
                          setValues({
                            ...values,
                            retirement_date_to: date,
                          })
                        }}
                        format={"YYYY年MM月DD日"}
                      />
                    </div>
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Work style")}
                  width={"138px"}
                />

                <CheckBoxGroup
                  items={WORK_TYPES || []}
                  value={values?.work_style || null}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      work_style: list,
                    })
                  }}
                />
              </div>
              <ActionBtns justify={"flex-start"}>
                <Button
                  btnText={t("Reset search")}
                  size={"large"}
                  shape={"round"}
                  onClick={() => onSearchReset(values)}
                />
                <Button
                  btnText={t("Search")}
                  type={"primary"}
                  size={"large"}
                  shape={"round"}
                  iconType={"search"}
                  onClick={() => onSearch(values)}
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
