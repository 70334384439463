export const TAB_ACTIVITIES = [
  "Increase/decrease in service activities",
  "INCREASE/DECREASE in Non-Service Activities",
  "Special Increase/Decrease",
  "Net Increase/Decrease in Activities Carried Forward ...",
]

const CARRIED_ACTIVITY_ROWS = [
  //   -- Net Increase/Decrease in Activities Carried Forward ...
  {
    subject: "Pre -year -carrying activity increase / decrease (12)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "pre_year_carrying_activity",
  },
  {
    subject: "Current carryover activity increase / decrease (13) = (11)+(12)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "total_13",
  },
  {
    subject: "Basic money collapse (14)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "basic_money_collapse",
  },
  {
    subject: "Other reserve deprecation (15)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "other_reserve_depreciation",
  },
  {
    subject: "Other reserve reserves (16)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "other_reserve_reserve",
  },
  {
    subject:
      "Next-phase carryover activity increase / decrease (17) = (13)+(14)+(15)-(16)",
    type: "Cost",
    activity: TAB_ACTIVITIES[3],
    key: "total_17",
  },
]
export const SINGLE_FACILITY_ROWS = [
  // Increase/decrease in service activities
  // profit
  {
    activity: TAB_ACTIVITIES[0],
    type: "Profit",
    subject: "Income from employment support business",
    key: "employement_support_business_income",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Profit",
    subject: "Income from welfare services for persons with disabilities",
    key: "disability_welfare_service",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Profit",
    subject: "Income from donations for ordinary expenses",
    key: "expenses_donation_revenue",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Profit",
    subject: "Other Income",
    key: "other_income",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Profit",
    subject: "Total service activity revenue(１)",
    key: "total_1",
  },
  // Increase/decrease in service activities
  // cost
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Personnel Expenses",
    key: "personnel_expense",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Business Expenses",
    key: "business_expense",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Office Expenses",
    key: "office_expense",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "EMPLOYMENT Support Business Expenses",
    key: "employment_support_business_expenses",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "User Cost Redaction",
    key: "user_cost_redaction",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Deprecation and Amortization",
    key: "depreciation_amortization",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Reversal of Special Reserve for Government Subsidies",
    key: "reversal_special_reserve",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "UNCOLLECTIBLE EXPENSES",
    key: "uncollectible_expense",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Provision for Allowance for Uncollectible Accounts",
    key: "uncollectible_allowance",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Other Expenses",
    key: "other_expenses",
  },
  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Service activity expenses meter (2)",
    key: "total_2",
  },

  {
    activity: TAB_ACTIVITIES[0],
    type: "Cost",
    subject: "Service activity increase decrease (3) = (1) - (2)",
    key: "total_3",
  },
  // INCREASE/DECREASE in Non-Service Activities
  // Profit
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Borrowed money interest subsidy",
    key: "borrowed_money_interest_subsidy",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Received interest dividend revenue",
    key: "received_interest_dividend_revenue",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Securities valuation profit",
    key: "securities_valuation_profit",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Gain a securities sale",
    key: "gain_securities_sale",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Investment securities evaluation profit",
    key: "investment_securities_evaluation_profit",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Investment securities gain gains",
    key: "investment_securities_gain_gains",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Other service activity non -income revenue",
    key: "other_service_activity_non_income_revenue",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Profit",
    subject: "Service activity non -profit meter (4)",
    key: "total_4",
  },
  // Cost
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Interest expense",
    key: "interest_expense",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Securities valuation loss",
    key: "securities_valuation_loss",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Sale of securities",
    key: "sale_of_securities",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Investment securities evaluation loss",
    key: "investment_securities_evaluation_loss",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Investment -securities sale loss",
    key: "investment_securities_sale_loss",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Other service activity foreign expenses",
    key: "other_service_activity_foreign_expenses",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Service activity expense meter (5)",
    key: "total_5",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Service activity increase / decrease difference (6) = (4)-(5)",
    key: "total_6",
  },
  {
    activity: TAB_ACTIVITIES[1],
    type: "Cost",
    subject: "Changing decrease difference (7) = (3) + (6)",
    key: "total_7",
  },
  //  Special Increase/Decrease
  //  profit
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Facility maintenance subsidy revenue",
    key: "facility_maintenance_subsidy_revenue",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Facility maintenance, donation revenue",
    key: "facility_maintenance_donation_revenue",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject:
      "Long -term operating fund borrowing former money reduction revenue revenue",
    key: "long_term_operating_fund_borrowing",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Fixed assets received",
    key: "fixed_assets_received",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Property gain gain",
    key: "property_gain",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Other special revenue",
    key: "out_of_business_payment_income_revenue",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Special earning meter (8)",
    key: "total_8",
  },
  //cost
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Interest expense",
    key: "interest_expense_2",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Securities valuation loss",
    key: "securities_valuation_loss_2",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Sale of securities",
    key: "sale_of_securities_2",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Basic gold paid amount",
    key: "basic_gold_paid_amount",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Asset evaluation loss",
    key: "asset_evaluation_loss",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Fixed asset sale / disposal loss",
    key: "fixed_asset_sale_disposal_loss",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject:
      "Special reserve deprecation such as national treasury subsidies (removal, etc.)",
    key: "special_reserve_depreciation_removal",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Special reserve fund for national treasury subsidies",
    key: "special_reserve_fund",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Disaster loss",
    key: "disaster_loss",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Other extraordinary losses",
    key: "other_extraordinary_losses",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "Special cost meter (9)",
    key: "total_9",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Cost",
    subject: "EXTRAORDINARY INCREASE/DECREASE (10) = (8)-(9)",
    key: "total_10",
  },
  {
    activity: TAB_ACTIVITIES[2],
    type: "Profit",
    subject: "Net Increase (decrease) for the year (11) = (7)+(10)",
    key: "total_11",
  },
  ...CARRIED_ACTIVITY_ROWS,
]

export const MULTIPLE_FACILITY_ROWS = [
  // "Increase/decrease in service activities",
  //Profit
  {
    subject: "Employment support business income",
    type: "Profit",
    activity: TAB_ACTIVITIES[0],
    key: "employement_support_business_income",
  },
  {
    subject: "Business revenue such as disability welfare services",
    type: "Profit",
    activity: TAB_ACTIVITIES[0],
    key: "disability_welfare_service",
  },

  {
    subject: "Content expenses donation revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[0],
    key: "expenses_donation_revenue",
  },
  {
    subject: "Other Income",
    type: "Profit",
    activity: TAB_ACTIVITIES[0],
    key: "other_income",
  },
  {
    subject: "Service activity earning meter (1)",
    type: "Profit",
    activity: TAB_ACTIVITIES[0],
    key: "total_1",
  },
  // "Increase/decrease in service activities",
  //Cost
  {
    subject: "Labor cost",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "labor_cost",
  },
  {
    subject: "Expenses",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "expenses",
  },
  {
    subject: "Administrative expenses",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "administrative_expenses",
  },
  {
    subject: "Employment support project cost",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "employment_support_project_cost",
  },
  {
    subject: "User burden light reduction",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "user_burden_light_reduction",
  },
  {
    subject: "Depreciation expenses",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "depreciation_expenses",
  },
  {
    subject:
      "Special reserve depreciation amount such as national treasure subsidies",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "special_reserve_depreciation",
  },
  {
    subject: "Unable to collect",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "unable_to_collect",
  },
  {
    subject: "Incapacitated gold reinforcement",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "incapacitated_gold_reinforcement",
  },
  {
    subject: "Other Expenses",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "other_expenses",
  },
  {
    subject: "Service activity expenses meter (2)",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "total_2",
  },
  {
    subject: "Service activity increase / decrease amount (3) = (1)-(2)",
    type: "Cost",
    activity: TAB_ACTIVITIES[0],
    key: "total_3",
  },
  // "INCREASE/DECREASE in Non-Service Activities",
  //Profit
  {
    subject: "Borrowed money interest subsidy",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "borrowed_money_interest_subsidy",
  },
  {
    subject: "Received interest dividend revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "received_interest_dividend_revenue",
  },
  {
    subject: "Securities valuation profit",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "securities_valuation_profit",
  },
  {
    subject: "Gain a securities sale",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "gain_securities_sale",
  },
  {
    subject: "Investment securities evaluation profit",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "investment_securities_evaluation_profit",
  },
  {
    subject: "Investment securities gain gains",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "investment_securities_gain_gains",
  },
  {
    subject: "Other service activity non -income revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "other_service_activity_non_income_revenue",
  },
  {
    subject: "Service activity non -profit meter (4)",
    type: "Profit",
    activity: TAB_ACTIVITIES[1],
    key: "total_4",
  },
  // "INCREASE/DECREASE in Non-Service Activities",
  //Cost
  {
    subject: "Interest expense",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "interest_expense",
  },
  {
    subject: "Securities valuation loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "securities_valuation_loss",
  },
  {
    subject: "Sale of securities",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "sale_of_securities",
  },
  {
    subject: "Investment securities evaluation loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "investment_securities_evaluation_loss",
  },

  {
    subject: "Investment -securities sale loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "investment_securities_sale_loss",
  },
  {
    subject: "Other service activity foreign expenses",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "other_service_activity_foreign_expenses",
  },
  {
    subject: "Service activity expense meter (5)",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "total_5",
  },
  {
    subject: "Service activity increase / decrease difference (6) = (4)-(5)",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "total_6",
  },
  {
    subject: "Changing decrease difference (7) = (3) + (6)",
    type: "Cost",
    activity: TAB_ACTIVITIES[1],
    key: "total_7",
  },
  //"Special Increase/Decrease",
  //Profit
  {
    subject: "Facility maintenance subsidy revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "facility_maintenance_subsidy_revenue",
  },
  {
    subject: "Facility maintenance, donation revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "facility_maintenance_donation_revenue",
  },
  {
    subject:
      "Long -term operating fund borrowing former money reduction revenue revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "long_term_operating_fund_borrowing",
  },
  {
    subject: "Fixed assets received",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "fixed_assets_received",
  },
  {
    subject: "Property gain gain",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "property_gain",
  },
  {
    subject: "Out -of -business payment income revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "out_of_business_payment_income_revenue",
  },
  {
    subject: "Introductory income between businesses",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "introductory_business_income",
  },
  {
    subject: "Out -of -business fixed assets transfer revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "out_of_business_fixed_assets_transfer_revenue",
  },
  {
    subject: "Fixed asset transfer income inter -establishments",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "fixed_asset_transfer_income_inter_establishments",
  },
  {
    subject: "Other special revenue",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "other_special_revenue",
  },
  {
    subject: "Special earning meter (8)",
    type: "Profit",
    activity: TAB_ACTIVITIES[2],
    key: "total_8",
  },
  //"Special Increase/Decrease",
  //Cost
  {
    subject: "Basic gold paid amount",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "basic_gold_paid_amount",
  },
  {
    subject: "Asset evaluation loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "asset_evaluation_loss",
  },
  {
    subject: "Fixed asset sale / disposal loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "fixed_asset_sale_disposal_loss",
  },
  {
    subject:
      "Special reserve deprecation such as national treasury subsidies (removal, etc.)",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "special_reserve_depreciation_removal",
  },
  {
    subject: "Special reserve fund for national treasury subsidies",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "special_reserve_fund",
  },
  {
    subject: "Disaster loss",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "disaster_loss",
  },
  {
    subject: "Out -of -business payment payment cost",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "out_of_business_payment_payment_cost",
  },
  {
    subject: "Presentation expenses between offices",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "presentation_expenses_between_office",
  },
  {
    subject: "Out -of -business fixed assets transfer cost",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "out_of_business_fixed_assets_transfer_cost",
  },
  {
    subject: "Fixed asset transfer expenses between establishments",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "fixed_asset_transfer_expenses_between_establishment",
  },
  {
    subject: "Other extraordinary losses",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "other_extraordinary_losses",
  },
  {
    subject: "Special cost meter (9)",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "total_9",
  },
  {
    subject: "Special increase / decrease amount (10) = (8) - (9)",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "total_10",
  },
  {
    subject: "Final year activity increase / decrease amount (11) = (7)+(10)",
    type: "Cost",
    activity: TAB_ACTIVITIES[2],
    key: "total_11",
  },
  ...CARRIED_ACTIVITY_ROWS,
]
