import { theme } from "@project/common"
import { styled } from "styled-components"

export const NHIFOperationContainer = styled.div`
  margin-bottom: 20px;
`
export const NHIFScrollSection = styled.div`
  padding: 10px 0 26px 0;
  border-bottom: 1px solid ${theme.colors.border};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    padding: 0 10px;
    line-height: 22px;
    &.bd-rt {
      border-right: 1px solid ${theme.colors.border};
    }
    a {
      color: ${theme.colors.link};
      text-decoration: underline;
    }
  }
`

export const NHIFTableContainer = styled.div`
  margin-bottom: 20px;
  .top-table-contents {
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    div.action-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .small-button {
        padding: 4px 8px;
        min-height: 30px !important;
        width: auto;
      }
      .bg-border {
        background: #d2d1d1;
      }
    }
    div.individual-support-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
      div.individual-support-action-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }
  .user-list-table {
    width: 100%;
    overflow-x: auto;
  }
  .output-count {
    margin: 10px 0;
    padding: 8px;
    border: 1px solid ${theme.colors.border};
    width: max-content;
    strong {
      font-size: large;
    }
  }
`

export const IndividualSupportSubtractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const NHIFConfirmBillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  .alert-text {
    font-weight: bold;
    color: ${theme.colors.error};
  }
`

export const NumberedSectionContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  div.heading {
    padding: 10px;
    background: ${theme.colors.background};
    display: flex;
    gap: 10px;
    align-items: center;
    .number-title {
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background: ${theme.colors.blue};
    }
  }
  div.child-content {
    padding: 10px;
    border: 1px solid ${theme.colors.border};
    border-top: none;
  }
  .disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d9d94d;
    cursor: default;
    z-index: 9;
  }
`

export const NHIFSection1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .alert-text {
    font-weight: bold;
    color: ${theme.colors.error};
  }
  .municipal-component-button {
    max-width: 600px;
    background: #fff;
    justify-content: flex-start;
    position: relative;
    &:after {
      content: ">";
      position: absolute;
      top: 5px;
      right: 16px;
    }
  }
  .maximum-amount-people-count {
    border: 1px solid ${theme.colors.border};
    padding: 5px;
    max-width: 600px;
  }
`

export const NHIFSection2 = styled.div`
  .alert-text {
    font-weight: bold;
    color: ${theme.colors.error};
  }
  div,
  button {
    margin-bottom: 20px;
  }
  .csv-button {
    background: ${theme.colors.blue2};
  }
`

export const NHIFLinkSection = styled.div`
  position: relative;
  padding: 0 10px;
  border: 1px solid ${theme.colors.border};
  display: grid;
  grid-template-columns: repeat(2, 50%);
  .bd-rt {
    border-right: 1px solid ${theme.colors.border};
  }
  .section1,
  .section2 {
    padding: 0 10px;
  }
  ul {
    list-style-position: inside;
    li {
      padding: 10px 0px;
      a {
        color: ${theme.colors.link};
        text-decoration: underline;
      }
      .subtext {
        display: block;
      }
      &::marker {
        color: ${theme.colors.link};
      }
    }
  }
  .disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d9d94d;
    cursor: default;
    z-index: 9;
  }
  .non-displayed-disable-overlay {
    display: none;
  }
`

export const NHIFSpinContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

export const NHIFConfirmedBillingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
`

export const NHIFAlertSection = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid ${theme.colors.error};
  border-radius: 12px;
  margin-bottom: 12px;
  .alert-title {
    text-decoration: underline;
    font-weight: bold;
    color: ${theme.colors.error};
  }
  .alert-table {
    margin-top: 5px;
    table {
      width: 100%;
      border-collapse: collapse;
      td {
        &:first-child {
          background: ${theme.colors.background};
        }
        padding: 4px;
        border: 1px solid ${theme.colors.border};
      }
    }
  }
  .alert-links {
    a {
      text-decoration: underline !important;
      color: ${theme.colors.link};
    }
  }
  ul.alert-list {
    li {
      margin-left: 20px;
    }
  }
`
