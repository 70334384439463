import React, { useState } from "react"

// packages
import { t } from "i18next"
import { Form } from "antd"
import { FormInstance } from "antd/lib"

// common | component
import { NumberedTitle } from "./NumberedTitle"
import { Box, Grid, RadioGroup, SERVICE_TYPE_VALUES } from "@project/common"

// types
import {
  SystemStatusMgmtData,
  SystemStatusMgmtDataType,
} from "../../../services/system-status-mgmt.service"

// constants
import { YES_NO_LIST } from "@project/common/src/constants"
import { SERVICE_MANAGER_OPTION, SERVICE_PROV_STAFF_OPTION } from "../constants"
import { styled } from "styled-components"

const Red = styled.div`
  color: #e00000;
  font-size: 14px;
  font-weight: 400;
`

export const SubtractionForm = ({
  form,
  systemStatusData,
}: {
  form: FormInstance<SystemStatusMgmtData>
  systemStatusData: SystemStatusMgmtDataType
}) => {
  const CONFIRMATION_OPTIONS = [...YES_NO_LIST].reverse()
  const serviceTypeValue = Form.useWatch("service_type_id", form) as 1 | 2 | 3
  const capacityValue = Form.useWatch("capacity", form) || 0
  const [isStaffManualChange, setIsStaffManualChange] = useState(false)
  const [isManagerManualChange, setIsManagerManualChange] = useState(false)
  const [isExcessCapacityManualChange, setIsExcessCapacityManualChange] =
    useState(false)

  const lackOfServiceProvisionStaffSubtraction = Form.useWatch(
    "lack_of_service_provision_staff_subtraction",
    form,
  )

  const lackOfServiceManagerSubtraction = Form.useWatch(
    "lack_of_service_manager_subtraction",
    form,
  )

  const excessCapacitySubtraction = Form.useWatch(
    "excess_capacity_subtraction",
    form,
  )

  const usagePeriodSubtraction = Form.useWatch("usage_period_subtraction", form)

  const compensesionSystem = Form.useWatch("compensation_system", form)

  if (compensesionSystem === "1") {
    form.setFieldValue("short_time_use_subtraction", "0")
  }

  let rateValue = (
    (systemStatusData?.average_user / capacityValue) *
    100
  ).toFixed(2)

  if (capacityValue <= 0 || !capacityValue) {
    rateValue = "0.00"
  }

  return (
    <Box mt={16} className={"subtraction-form"}>
      <NumberedTitle mb={8}>{t("Subtraction:")}</NumberedTitle>
      <Grid
        labelContent={t("Service provision staff lack subtraction")}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"lack_of_service_provision_staff_subtraction"}
            id={"lack_of_service_provision_staff_subtraction"}
          >
            <RadioGroup
              onChange={() => {
                setIsStaffManualChange(true)
              }}
              options={SERVICE_PROV_STAFF_OPTION}
            />
          </Form.Item>
          {!isStaffManualChange &&
            lackOfServiceProvisionStaffSubtraction &&
            lackOfServiceProvisionStaffSubtraction !== "0" && (
              <Red>
                {t(
                  "*The calculation is automatically applied. Please review the staffing or change it manually.",
                )}
              </Red>
            )}
        </Box>
      </Grid>

      <Grid
        labelContent={t("Service manager lack subtraction")}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"lack_of_service_manager_subtraction"}
            id={"lack_of_service_manager_subtraction"}
          >
            <RadioGroup
              onChange={() => {
                setIsManagerManualChange(true)
              }}
              options={SERVICE_MANAGER_OPTION}
            />
          </Form.Item>
          {!isManagerManualChange &&
            lackOfServiceManagerSubtraction &&
            lackOfServiceManagerSubtraction !== "0" && (
              <Red>
                {t(
                  "*The calculation is automatically applied. Please review the staffing or change it manually.",
                )}
              </Red>
            )}
        </Box>
      </Grid>

      {/* Service type Employment type A | 1*/}
      {serviceTypeValue === 1 && (
        <>
          <Grid
            labelContent={t(
              "In case of A-type employment support facilities established by local government",
            )}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"employment_support_facilities_subtraction"}
              id={"employment_support_facilities_subtraction"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      {/* Service type Employment type B | 2*/}
      {serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_2 && (
        <>
          <Grid
            labelContent={t(
              "In case of B-type employment support facilities established by local government",
            )}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"employment_support_facilities_subtraction"}
              id={"employment_support_facilities_subtraction"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      {/* Service type Employment type C | 3*/}
      {serviceTypeValue === 3 && (
        <>
          <Grid
            labelContent={t(
              "In case of transition employment support facilities established by local government",
            )}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"employment_support_facilities_subtraction"}
              id={"employment_support_facilities_subtraction"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      <Grid
        labelContent={t("Excess capacity subtraction")}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"excess_capacity_subtraction"}
            id={"excess_capacity_subtraction"}
          >
            <RadioGroup
              options={CONFIRMATION_OPTIONS}
              onChange={() => setIsExcessCapacityManualChange(true)}
            />
          </Form.Item>
          <div>
            {t("Usage rate for the past 3 months：{{rate}} %", {
              rate: rateValue,
            })}
          </div>
          {!isExcessCapacityManualChange &&
            excessCapacitySubtraction &&
            excessCapacitySubtraction === "1" && (
              <Red>
                {t(
                  "*The usage rate for the past 3 months has exceeded {{rate}} %, so it is calculated automatically. If you do not want it calculated, please change it manually.",
                  {
                    rate: rateValue,
                  },
                )}
              </Red>
            )}
        </Box>
      </Grid>

      {/* Service type Employment type A | 1*/}
      {serviceTypeValue === 1 && (
        <>
          <Grid
            labelContent={t("Self-evaluation unpublished subtraction")}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"self_evaluation_unpublished_subtraction"}
              id={"self_evaluation_unpublished_subtraction"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      {/* Service type Employment type B | 2*/}
      {serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_2 && (
        <>
          <Grid
            labelContent={t("Short-time use subtraction")}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"short_time_use_subtraction"}
              id={"short_time_use_subtraction"}
            >
              <RadioGroup
                options={CONFIRMATION_OPTIONS}
                disabled={compensesionSystem === "1"}
              />
            </Form.Item>
          </Grid>
        </>
      )}

      {/* Service type Employment type C | 3*/}
      {serviceTypeValue === 3 && (
        <>
          <Grid
            labelContent={t("Exceeding standard usage period subtraction")}
            background
            labelSpan={6}
          >
            <div>
              <Box display={"flex"} direction={"column"} gap={16} mb={16}>
                <Form.Item
                  name={"usage_period_subtraction"}
                  id={"usage_period_subtraction"}
                >
                  <RadioGroup options={CONFIRMATION_OPTIONS} />
                </Form.Item>
              </Box>
              <div>
                {t(
                  "Average usage period for users who have exceeded 1 year since the start of usage : {{MM}} Months",
                  {
                    MM: systemStatusData?.average_usage_period?.toFixed(2),
                  },
                )}
              </div>
              {usagePeriodSubtraction && usagePeriodSubtraction === "1" && (
                <Red>
                  {t(
                    "*Since the average usage period of users exceeds the standard usage period of six months, a subtraction for exceeding the standard period will be applied.",
                  )}
                </Red>
              )}
            </div>
          </Grid>
        </>
      )}

      <Grid
        labelContent={t(
          "Non-implementation of abuse prevention measures subtraction",
        )}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"abuse_prevention_measures_subtraction"}
            id={"abuse_prevention_measures_subtraction"}
          >
            <RadioGroup options={CONFIRMATION_OPTIONS} />
          </Form.Item>
        </Box>
      </Grid>

      <Grid
        labelContent={t(
          "Physical restraint abolition non-implementation subtraction",
        )}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"physical_restraint_abolition_subtraction"}
            id={"physical_restraint_abolition_subtraction"}
          >
            <RadioGroup options={CONFIRMATION_OPTIONS} />
          </Form.Item>
        </Box>
      </Grid>

      <Grid
        labelContent={t(
          "Business continuity plans not established subtraction",
        )}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"business_plan_not_established_subtraction"}
            id={"business_plan_not_established_subtraction"}
          >
            <RadioGroup options={CONFIRMATION_OPTIONS} />
          </Form.Item>
        </Box>
      </Grid>

      <Grid
        labelContent={t("Information disclosure unreported subtraction")}
        background
        labelSpan={6}
      >
        <Box display={"flex"} direction={"column"} gap={16}>
          <Form.Item
            name={"information_disclosure_unreported_subtraction"}
            id={"information_disclosure_unreported_subtraction"}
          >
            <RadioGroup options={CONFIRMATION_OPTIONS} />
          </Form.Item>
        </Box>
      </Grid>
    </Box>
  )
}
