import { Flex, Popconfirm } from "antd"
import dayjs from "dayjs"
import { FormikProvider, useFormik } from "formik"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { createId } from "@paralleldrive/cuid2"
import { Button, Card, scrollToFirstErrorField, theme } from "@project/common"
import { FULL_DATE_FORMAT_EN } from "../../../constants"
import { useAuthContext } from "../../../context"
import { getAssessment, getAssessmentSettings } from "../../../services"
import {
  AssessmentFormValues,
  AssessmentFormValuesValidationSchema,
} from "../../../types"
import { AssessmentEntityItemFields } from "../common/AssessmentEntityItemFields"
import { AssessmentDetailLoader } from "../common/AssessmentLoader"
import { AssessmentAuthorFiled } from "./AssessmentAuthorFiled"
import { AssessmentGeneralInfoFields } from "./AssessmentGeneralInfoFields"
import { AssessmentWorkSettingsFields } from "./AssessmentWorkSettingsFields"

export const AssessmentFormContent = ({
  mode,
  isMutating,
  handleMutation,
  allowDelete = false,
  isDeleting,
  handleDelete,
}: {
  isMutating?: boolean
  handleMutation: (values: AssessmentFormValues) => void
  mode: "add" | "edit"
  allowDelete?: boolean
  isDeleting?: boolean
  handleDelete?: (id: string | number) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id, copy_id, user_id } = router?.query as any
  const assessmentId = id || copy_id || null
  const [assessmentSettingsItems, setAssessmentSettingsItems] = useState([])
  const [assessmentSettingLoaded, setAssessmentSettingLoaded] = useState(false)
  const { companyId } = useAuthContext()
  //form
  const formik = useFormik({
    initialValues: {
      creation_date: dayjs(),
      user_id: user_id || null,
      facility_id: null,
      service_type: null,
      user_hiragana: "",
      staff_id: null,
      status: false,
      creation_number: 1,
    },
    validationSchema: AssessmentFormValuesValidationSchema,
    onSubmit: (values) => {
      const assessment_entity_item = []
      const assessment_employment_item = []
      assessmentSettingsItems?.map((assessment) => {
        assessment?.assessment_category?.map((category) => {
          category?.assessment_item?.map((item) => {
            const employment_item = item?.assessment_employment_item?.length
              ? item?.assessment_employment_item?.map(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ({ tempId, ...item }) => ({
                    ...item,
                    start_date: dayjs(item?.start_date).isValid()
                      ? dayjs(item?.start_date).format(FULL_DATE_FORMAT_EN)
                      : null,
                    end_date: dayjs(item?.end_date).isValid()
                      ? dayjs(item?.end_date).format(FULL_DATE_FORMAT_EN)
                      : null,
                  }),
                )
              : []
            assessment_entity_item.push({
              company_id: +companyId || item?.company_id,
              assessment_setting_id: assessment?.id,
              assessment_category_id: category?.id,
              assessment_item_id: item?.id,
              assessment_id: +assessmentId || undefined,
              answer: item?.answer || item?.value || undefined,
              remarks: item?.remarks || "",
            })
            assessment_employment_item.push(...employment_item)
          })
        })
      })
      const data = {
        ...values,
        status: true, // default to publish
        user_id: +values?.user_id,
        staff_id: +values?.staff_id,
        facility_id: +values?.facility_id,
        creation_date: dayjs(values?.creation_date).format(FULL_DATE_FORMAT_EN),
        assessment_employment_item,
        assessment_entity_item,
        creation_number: values?.creation_number,
      }
      handleMutation(data)
    },
  })

  //fetch the assessment setting
  const { isLoading, data: assessmentData } = useQuery({
    queryKey: ["assessmentSetting"],
    queryFn: () => getAssessmentSettings(),
    onSuccess: (res) => {
      const data = res?.data?.map((assessment) => ({
        ...assessment,
        assessment_category: assessment?.assessment_category?.map(
          (category) => ({
            ...category,
            assessment_item:
              category?.title === "Career"
                ? category?.assessment_item?.map((item) => ({
                    ...item,
                    assessment_employment_item:
                      item?.title ===
                      "Employment history/training history, etc."
                        ? [
                            {
                              tempId: createId(),
                              company_id: +companyId,
                              start_date: dayjs(),
                              assessment_id: assessmentId || undefined,
                              end_date: dayjs(),
                              company_name: "",
                              detail: "",
                            },
                          ]
                        : [],
                  }))
                : category?.assessment_item,
          }),
        ),
      }))
      setAssessmentSettingsItems(data || res?.data || [])
      setAssessmentSettingLoaded(true)
    },
    select: (res) => res,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  })
  //fetch single assessment detail if mode=='edit'
  const { isLoading: detailLoading } = useQuery({
    queryKey: ["assessment-detail", assessmentId],
    queryFn: () => getAssessment(assessmentId),
    select: (res) => {
      return res?.data || {}
    },
    onSuccess: (response) => {
      const data = {
        user_hiragana: "",
        status: false,
        creation_date: response?.creation_date
          ? dayjs(response?.creation_date)
          : dayjs(),
        facility_id: String(response?.facility_id) || null,
        service_type: response?.service_type || null,
        user_id: copy_id ? null : response?.user_id?.toString() || null, // if copy no need to select user since multiple is prevent for same
        staff_id: response?.staff_id || null,
        creation_number: response?.creation_number || null,
      }
      const itemWithDetailData = assessmentData?.data?.map((assessment) => ({
        ...assessment,
        assessment_category: assessment?.assessment_category?.map(
          (category) => ({
            ...category,
            assessment_item: category?.assessment_item?.map((item) => {
              const entityItem = response?.assessment_entity_item?.find(
                (entity) => entity?.assessment_item_id === item?.id,
              )
              return {
                ...item,
                answer: entityItem?.answer || undefined,
                remarks: entityItem?.remarks || "",
                assessment_employment_item:
                  item?.title === "Employment history/training history, etc."
                    ? response?.assessment_employment_item?.map((v) => ({
                        ...v,
                        tempId: v?.id,
                      })) || []
                    : [],
              }
            }),
          }),
        ),
      }))
      setAssessmentSettingsItems(itemWithDetailData || [])
      formik.setValues(data)
    },
    // enabled only after assessment setting is loaded
    enabled:
      ((mode === "edit" && !!id && !!assessmentData) ||
        (!!copy_id && !!assessmentData)) &&
      assessmentSettingLoaded,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const handleDataValueChange = (values: {
    value: string
    key: string
    id: number
    category_id: number
    item_id: number
    entry_id?: number
  }) => {
    const newUpdatedAssessmentItems = assessmentSettingsItems?.map((item) => {
      if (item?.id === values?.id) {
        return {
          ...item,
          assessment_category: item?.assessment_category?.map((category) =>
            category?.id === values?.category_id
              ? {
                  ...category,
                  assessment_item: category?.assessment_item?.map((item) =>
                    item?.id === values?.item_id
                      ? values?.entry_id
                        ? {
                            ...item,
                            assessment_employment_item:
                              item?.assessment_employment_item?.map((entity) =>
                                entity?.tempId === values?.entry_id
                                  ? {
                                      ...entity,
                                      [values?.key]: values?.value,
                                    }
                                  : entity,
                              ),
                          }
                        : {
                            ...item,
                            [values?.key]: values?.value,
                          }
                      : item,
                  ),
                }
              : category,
          ),
        }
      }
      return item
    })
    setAssessmentSettingsItems(newUpdatedAssessmentItems)
  }
  const handleAssessmentEntryItemAddition = ({
    content = {},
    ...values
  }: {
    id: number
    category_id: number
    item_id: number
    entry_id?: number
    content?: Record<string, any>
    operation?: "add" | "delete"
  }) => {
    const newData = assessmentSettingsItems?.map((assessment) =>
      assessment?.id === values?.id
        ? {
            ...assessment,
            assessment_category: assessment?.assessment_category?.map(
              (category) =>
                category?.id === values?.category_id
                  ? {
                      ...category,
                      assessment_item: category?.assessment_item?.map((item) =>
                        item?.id === values?.item_id
                          ? {
                              ...item,
                              assessment_employment_item:
                                values?.operation === "delete"
                                  ? item?.assessment_employment_item?.filter(
                                      (v) => v?.tempId !== values?.entry_id,
                                    )
                                  : [
                                      ...item?.assessment_employment_item,
                                      { ...content },
                                    ],
                            }
                          : {
                              ...item,
                              assessment_employment_item: [{ f: "ck" }],
                            },
                      ),
                    }
                  : { ...category, hello: "wolrd" },
            ),
          }
        : { ...assessment, assessment: "hello" },
    )
    setAssessmentSettingsItems(newData)
  }
  const ctaBtns = () => (
    <Flex wrap={"wrap"} justify={"space-between"} gap={10}>
      <Flex wrap={"wrap"} gap={16}>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          borderColor={theme.colors.border}
          hoverBtnBg={theme.colors.border}
          hoverTextColor={theme.colors["text-gray"]}
          textColor={theme.colors["text-gray"]}
          hoverBorderColor={theme.colors.border}
          width={"140px"}
          disabled={isMutating}
          onClick={() => router.push("/assessment")}
        />
        <Button
          btnText={t("Save")}
          shape={"round"}
          type={"primary"}
          width={"92px"}
          htmlType={"submit"}
          isLoading={isMutating}
          disabled={isLoading || detailLoading}
        />
      </Flex>
      {allowDelete && (
        <Popconfirm
          title={t("Deleting.Is that OK?")}
          onConfirm={() => handleDelete(id)}
          okText={t("OK")}
          cancelText={t("Cancel")}
          okButtonProps={{ size: "middle" }}
          cancelButtonProps={{ size: "middle" }}
        >
          <Button
            btnText={t("Delete")}
            shape={"round"}
            disabled={isMutating || isDeleting || isLoading || detailLoading}
            isLoading={isDeleting}
            iconType={"delete"}
          />
        </Popconfirm>
      )}
    </Flex>
  )

  return (
    <Card title={id ? t("Edit assessment") : t("Create assessment")}>
      {isLoading || detailLoading ? (
        <AssessmentDetailLoader showSummaryLoader={false} />
      ) : (
        <FormikProvider value={formik}>
          <Flex
            component={"form"}
            onSubmit={formik.handleSubmit}
            vertical
            gap={16}
          >
            {ctaBtns()}
            <AssessmentGeneralInfoFields mode={mode} />
            {assessmentSettingsItems?.map((assessment) => {
              if (assessment?.title?.toLowerCase() === "work") {
                return (
                  <AssessmentWorkSettingsFields
                    assessment_setting_id={assessment?.id}
                    assessmentId={+id || assessment?.id}
                    key={assessment?.id}
                    categories={assessment?.assessment_category || []}
                    handleDataValueChange={handleDataValueChange}
                    handleAssessmentEntryItemAddition={
                      handleAssessmentEntryItemAddition
                    }
                  />
                )
              }
              if (
                assessment?.title?.toLowerCase() === "support" ||
                assessment?.title?.toLowerCase() === "aptitude/skills"
              ) {
                return (
                  <AssessmentEntityItemFields
                    key={+id || assessment?.id}
                    entityItems={assessment?.assessment_category || []}
                    title={t(assessment?.title) || ""}
                    mode={mode}
                    assessmentId={+id}
                    assessment_setting_id={assessment?.id}
                    handleAssessmentEntryItemChanged={handleDataValueChange}
                  />
                )
              }
              if (assessment?.title?.toLowerCase() === "other") {
                return (
                  <AssessmentWorkSettingsFields
                    assessment_setting_id={assessment?.id}
                    assessmentId={+id || assessment?.id}
                    key={assessment?.id}
                    categories={assessment?.assessment_category || []}
                    handleDataValueChange={handleDataValueChange}
                    handleAssessmentEntryItemAddition={
                      handleAssessmentEntryItemAddition
                    }
                  />
                )
              }
            })}

            <AssessmentAuthorFiled />
            {ctaBtns()}
          </Flex>
        </FormikProvider>
      )}
    </Card>
  )
}
