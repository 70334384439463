import { API } from "@project/common"
import { PAGE_SIZE } from "../constants"
export interface IReturnDailyBusinessReportList {
  count?: number
  data?: any
}

export interface IDailyBusinessReportListParam {
  page?: string | number
  facilityIds?: string
  user_id?: number
  request_type?: string
  pageSize?: number
}

const BASE_ENDPOINT = "user-wage"

export const fetchWagesList = async (
  queries,
): Promise<IReturnDailyBusinessReportList> => {
  const params = {
    ...queries,
    facilityIds: queries.facilityIds?.toString(),
    pageSize: PAGE_SIZE,
  } as IDailyBusinessReportListParam

  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}

export const updateConfirmPaySlip = (id) => {
  return API.patch(`/${BASE_ENDPOINT}/confirm-pay-slip/${id}`)
}
