import { API } from "@project/common"

export interface IReturnAutoEmailSending {
  count?: number
  data?: any
}

interface IAutoEmailSendingParam {
  page?: number
  pageSize?: number
  facility?: number
}

export const fetchAutoEmailSending = async ({
  queryKey,
}): Promise<IReturnAutoEmailSending> => {
  const params = {} as IAutoEmailSendingParam
  params.page = queryKey[1]
  params.facility = queryKey[2] || undefined
  return API.get(`/facility-mail-settings`, { params })
}

export const getOneAutoEmailSending = async (id: number) => {
  return API.get(`/facility-mail-settings/${id}`)
}

export const updateAutoEmailSending = async (values) => {
  const id = values.id
  delete values.id
  delete values.mail_sender
  return API.put(`/facility-mail-settings/${id}`, values)
}
export const deleteAutoEmailSending = async (id: number): Promise<any> => {
  return API.delete(`/facility-mail-settings/${id}`)
}
