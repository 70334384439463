import styled from "styled-components"
export const AssessmentSettingsListWrapper = styled.div`
  position: relative;
  z-index: 0;
  .assessment-settings-list-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(250, 250, 250, 0.8);
    border-radius: 12px;
    pointer-events: none;
    z-index: 1;
  }
`
