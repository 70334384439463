import {
  AccordionCard,
  Breadcrumb,
  Button,
  Card,
  MonthPagination,
  PageTitle,
} from "@project/common"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import {
  LoaderContainer,
  StaffAttendanceMonthContent,
} from "./staffAttendanceSchedule.style"
import {
  AttendanceScheduleOperationOption,
  AttendanceWorkStyleTable,
  StaffMonthlyEditContainer,
  StaffOccupationList,
} from "../../components"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import { AuthContext } from "../../context/AuthContext"
import { useQuery } from "react-query"
import {
  fetchAllShiftMaster,
  fetchInstructors,
  getOccupations,
} from "../../services"
import { Spin } from "antd"
import {
  AttendanceSchedulePermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"

const ATTENDANCE_SCHEDULE_LOCAL_STORAGE_KEY =
  "ATTENDANCE_SCHEDULE_SEARCH_PARAMS"

const StaffAttendanceSchedule = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities, isOwner, permissions } = useContext(AuthContext)
  const router = useRouter()
  const { facility_id, year, month } = router?.query
  const breadCrumb = [
    {
      title: <Link href={"/"}>{t("Home")}</Link>,
    },
    {
      title: t("Attendance schedule"),
    },
  ]

  const { data: occupations, isLoading: isOccupationLoading } = useQuery(
    "occupations",
    getOccupations,
    {
      select: (response) => {
        return response?.data
      },

      refetchOnWindowFocus: false,
    },
  )

  const getInitialSearchParams = () => {
    let initialFacilityId = facility_id?.toString()
    let initialYear = year?.toString()
    let initialMonth = month?.toString()

    const prevSearchParams = localStorage.getItem(
      ATTENDANCE_SCHEDULE_LOCAL_STORAGE_KEY,
    )

    if (prevSearchParams) {
      let parsedPrevSearchParams: any = {}
      try {
        parsedPrevSearchParams = JSON.parse(prevSearchParams)
      } catch (e) {
        parsedPrevSearchParams = {}
      }
      if (parsedPrevSearchParams?.facility_id)
        initialFacilityId = parsedPrevSearchParams?.facility_id
      if (parsedPrevSearchParams?.year && !initialYear)
        initialYear = parsedPrevSearchParams?.year
      if (parsedPrevSearchParams?.month && !initialMonth)
        initialMonth = parsedPrevSearchParams?.month
    }

    const isValidDate =
      initialYear &&
      initialMonth &&
      dayjs(initialYear + "-" + initialMonth + "-1").isValid()
    return {
      facility_id: initialFacilityId || facilities?.[0]?.id,
      year: isValidDate ? initialYear : dayjs().format("YYYY"),
      month: isValidDate ? initialMonth : dayjs().format("MM"),
    }
  }

  const [searchParams, setSearchParams] = useState(getInitialSearchParams())

  useEffect(() => {
    localStorage.setItem(
      ATTENDANCE_SCHEDULE_LOCAL_STORAGE_KEY,
      JSON.stringify(searchParams),
    )
    router.push(
      {
        pathname: "/attendance-schedule",
        query: searchParams,
      },
      undefined,
      { shallow: true },
    )
  }, [searchParams])

  const handleMonthPagination = (_, __, date) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        year: date.year(),
        month: date.month() + 1,
      }
    })
  }

  const attendanceScheduleTitle = useMemo(() => {
    return t("{{facilityName}} {{year}}年{{month}}月's Attendance Schedule", {
      facilityName: facilities?.find(
        (facility) => facility?.id == searchParams?.facility_id,
      )?.facility_name,
      year: searchParams?.year,
      month: searchParams?.month?.toString()?.padStart(2, "0"),
    })
  }, [searchParams])

  const { data: staffList, isLoading: isStaffListLoading } = useQuery({
    queryKey: ["staff-master", searchParams],
    queryFn: () =>
      fetchInstructors({
        pageSize: "Infinity",
        facility: searchParams.facility_id?.toString(),
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) =>
      response?.data?.map((staffOption) => ({
        label: staffOption?.staff_name,
        value: staffOption?.id,
        furigana: staffOption?.staff_name_furiganaame,
      })),
  })

  const { data: facilityShifts, isLoading: isFacilityShiftLoading } = useQuery({
    queryKey: ["shift_master_list_attendance_schedule", searchParams],
    queryFn: () =>
      fetchAllShiftMaster({
        page: 1,
        pageSize: "Infinity",
        facilityIds: [searchParams?.facility_id],
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) =>
      response?.data?.map((shift) => ({
        ...shift,
        label: shift?.attendance_shift_display_name,
        value: shift?.id,
      })),
  })

  const hasEditAccess = useMemo(() => {
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AttendanceSchedulePermission,
        "write",
        searchParams.facility_id,
      )
    )
  }, [isOwner, permissions, searchParams])

  return (
    <>
      <Breadcrumb items={breadCrumb} />
      <PageTitle
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Attendance schedule")}
      />
      <StaffAttendanceMonthContent>
        <AccordionCard
          accordion={true}
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <AttendanceScheduleOperationOption
                  initialState={searchParams}
                  handleDisplayChange={setSearchParams}
                  facilityOptions={facilities}
                />
              ),
            },
          ]}
        />
        <Card
          title={attendanceScheduleTitle}
          extra={
            <MonthPagination
              showDate={false}
              currentYear={+searchParams?.year}
              currentMonth={+searchParams?.month - 1}
              onMonthChange={handleMonthPagination}
            />
          }
        >
          <Button
            type={"default"}
            className={"working_style_list"}
            btnText={t("Working style list")}
            shape={"round"}
            onClick={() => {
              router.push(
                `/work-style?facility_id=${searchParams.facility_id}&year=${searchParams.year}&month=${searchParams.month}&tab=schedule`,
              )
            }}
          />
          {isStaffListLoading ||
          isOccupationLoading ||
          isFacilityShiftLoading ? (
            <LoaderContainer>
              <Spin size={"large"} />
            </LoaderContainer>
          ) : (
            <>
              <StaffMonthlyEditContainer
                staffList={staffList}
                editButtontitle={t("Edit monthly work schedule")}
                editLink={"/attendance-schedule/monthly-schedule"}
                editFacilityId={searchParams?.facility_id}
                editYear={searchParams?.year}
                editMonth={searchParams?.month}
                hasEditAccess={hasEditAccess}
              />
              <AttendanceWorkStyleTable
                year={searchParams?.year}
                month={searchParams?.month}
                facility={searchParams?.facility_id}
                type={"schedule"}
                facilityShifts={facilityShifts}
                hasEditAccess={hasEditAccess}
              />
              {occupations?.length > 0 && (
                <StaffOccupationList occupations={occupations} />
              )}
            </>
          )}
        </Card>
      </StaffAttendanceMonthContent>
    </>
  )
}

export { StaffAttendanceSchedule }
