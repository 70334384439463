import { theme } from "@project/common"
import styled from "styled-components"
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  table > tbody {
    .no-certificate-row {
      background-color: ${theme.colors.lightPink} !important;
    }
  }
  .use_service {
    min-width: 200px;
  }
  .date_css {
    min-width: 200px;
  }
  .details_th {
    min-width: 70px;
  }
  .btn_th {
    min-width: 140px;
  }
  #sign_indicator {
    border: 1px solid #76d6f7;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 15px;
  }
`
export const TableTopContent = styled.div`
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
    border-radius: 5px;
  }
  .indicator {
    width: 20px;
    height: 20px;
    background: #e000001a;
  }
`
