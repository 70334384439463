import { ActionBtns, Breadcrumb, Button, Card, theme } from "@project/common"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import Link from "next/link"
import { Divisor } from "../../../utils/shared-style"
import { Image, Spin } from "antd"
import { EyeOutlined } from "@ant-design/icons"
import { CardWrapper, TitleButton } from "./UserInformation.styles"
import UserInformationTable from "./UserInformationTable"
import BeneficiaryCertificateTable from "./BeneficiaryCertificateTable"
import EmergencyContactTable from "./EmergencyContactTable"
import EmploymentContactTable from "./EmploymentContractTable"
import PrimaryCareTable from "./PrimaryCareTable"
import OtherInformationTable from "./OtherInformationTable"
import AccountInformationTable from "./AccountInformationTable"
import EmailNotificationTable from "./EmailNotificationTable"
import { useQuery } from "react-query"
import { fetchOneUser } from "../../../services/user"
import { AuthContext } from "../../../context"
import {
  hasPermissionForFacility,
  UserManagementPermission,
} from "../../../utils/PermissionKeys"

interface IProps {
  id: number | string
}
const UserInformation: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isOwner, permissions } = useContext(AuthContext)
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(["fetch-one-user", id], () => fetchOneUser(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
  })

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      (!isLoading &&
        !isFetching &&
        (!response?.active_contract?.facility_id ||
          hasPermissionForFacility(
            permissions,
            UserManagementPermission,
            "write",
            response?.active_contract?.facility_id,
          ))),
    [isOwner, permissions, response],
  )

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: <Link href={"/users"}>{t("User management")}</Link>,
          },
          {
            title: t(`{{username}}’s information`, {
              username: response?.username,
            }),
          },
        ]}
      />
      <TitleButton className={"title-button"}>
        <Button
          btnText={t("Edit User Information")}
          shape={"round"}
          onClick={() => router.push(`/users/edit/${id}`)}
          btnBg={theme.colors["action-400"]}
          disabled={!hasWriteAccess}
        />
      </TitleButton>
      <Spin spinning={isFetching || isLoading}>
        <CardWrapper>
          <Card title={t("User Information")} id={"user-information"}>
            <div className={"first-content"}>
              <UserInformationTable response={response} />
            </div>

            <div className={"second-content"}>
              <span className={"title"}>{t("Account information")}</span>
              <Divisor />
              <AccountInformationTable response={response} />
            </div>

            <div className={"third-content"}>
              <span className={"title"}>{t("Email notification setting")}</span>
              <Divisor />
              <EmailNotificationTable response={response} />
            </div>

            <div className={"fourth-content"}>
              <div className={"title-button"}>
                <span className={"title"}>
                  {t("Beneficiary certificate information")}
                </span>
                <Button
                  btnText={t("Register a Beneficiary Certificate")}
                  shape={"round"}
                  className={"btn"}
                  onClick={() =>
                    router.push(
                      `/users/profile/${id}/recipient-certificate-form`,
                    )
                  }
                />
              </div>
              <Divisor className={"divider"} style={{ marginTop: "2px" }} />
              <BeneficiaryCertificateTable response={response} />
            </div>

            <div className={"fifth-content"}>
              <span className={"title"}>{t("Emergency Contact List")}</span>
              <Divisor />
              <EmergencyContactTable response={response} />
            </div>

            <div className={"sixth-content"}>
              <span className={"title"}>{t("Employment contract")}</span>
              <Divisor />
              <EmploymentContactTable response={response} />
            </div>

            <div className={"seventh-content"}>
              <span className={"title"}>{t("Primary Care Doctor")}</span>
              <Divisor />
              <PrimaryCareTable response={response} />
            </div>

            <div className={"eighth-content"}>
              <span className={"title"}>{t("Other Information")}</span>
              <Divisor />
              <OtherInformationTable response={response} />
            </div>

            <ActionBtns>
              <div className={"button"}>
                <Button
                  btnText={t("<< Go Back")}
                  shape={"round"}
                  onClick={() => router.push(`/users/profile/${id}`)}
                />
              </div>
            </ActionBtns>
          </Card>
          <Card title={t("Image")} id={"image-information"}>
            {!response?.image ? (
              <Image
                src={"/no-user-image.gif"}
                width={"100%"}
                height={"100%"}
                preview={false}
              />
            ) : (
              <div>
                <Image
                  src={response?.image}
                  width={"100%"}
                  height={"100%"}
                  preview={
                    response?.image != "" && {
                      mask: (
                        <span>
                          <EyeOutlined />
                          &nbsp;
                          {t("Preview")}
                        </span>
                      ),
                    }
                  }
                  onError={(event: any) => {
                    ;(event.target as HTMLImageElement).setAttribute(
                      "src",
                      "/no-user-image.gif",
                    )
                  }}
                />
              </div>
            )}
          </Card>
        </CardWrapper>
      </Spin>
    </>
  )
}

export { UserInformation }
