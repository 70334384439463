import React from "react"

// packages
import cuid from "cuid"
import { Flex } from "antd"
import { t } from "i18next"
import { FormikProps } from "formik"
import styled from "styled-components"

// commons | component
import { Box, Button, theme } from "@project/common"
import { SelectTime } from "../../../../TransportMgmtBulkEditInternal/common/SelectTime"

// types
import { AnyObject } from "antd/es/_util/type"
import { BreakTime } from "../../../../../types"

export const BreakTimeFieldsContainer = styled(Box)`
  .delete-button {
    padding: 4px 6px;
    color: ${theme.colors.error};
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.error} !important;
      border: 1px solid transparent !important;
      filter: brightness(110%);
    }
  }
  .footer-action-btn {
    width: 100%;
    justify-content: space-between;
    .alert-text {
      color: ${theme.colors.error};
      font-size: 12px;
    }
  }
`
const BreakTimeFields = ({ formik }: { formik: FormikProps<AnyObject> }) => {
  const { setFieldValue, values } = formik

  const handleDelete = (id: string) => {
    const newBreakTimeList = values.break_time_list.filter(
      (item) => item.id !== id,
    )
    setFieldValue("break_time_list", newBreakTimeList)
  }

  const handleAddition = () => {
    const newBreakTime: BreakTime = {
      id: cuid(),
      start_time: "",
      end_time: "",
    }
    const newBreakTimeList = [...values.break_time_list, newBreakTime]
    setFieldValue("break_time_list", newBreakTimeList)
  }

  return (
    <BreakTimeFieldsContainer display={"grid"} gap={16} mt={8}>
      <Box display={"flex"} direction={"column"} gap={16}>
        {values?.break_time_list?.map((item, index) => {
          return (
            <Flex align={"center"} gap={"8px 16px"} wrap={"wrap"} key={item.id}>
              <SelectTime
                value={item.start_time}
                onTimeChange={(val) => {
                  setFieldValue(`break_time_list[${index}][start_time]`, val)
                  setFieldValue(`break_time_list[${index}][end_time]`, "")
                }}
              />
              {"~"}
              <SelectTime
                disabled={!item.start_time}
                value={item.end_time}
                onTimeChange={(val) =>
                  setFieldValue(`break_time_list[${index}][end_time]`, val)
                }
                item={item}
                isEndTime={true}
              />
              {index !== 0 && (
                <Button
                  className={"delete-button"}
                  btnText={t("Delete")}
                  type={"link"}
                  onClick={() => handleDelete(item.id)}
                />
              )}
            </Flex>
          )
        })}
      </Box>
      <Flex align={"center"} className={"footer-action-btn"}>
        <Button
          btnText={t("Add")}
          shape={"round"}
          type={"grayed"}
          className={"add-button"}
          disabled={values.break_time_list?.length === 5}
          onClick={handleAddition}
        />

        {values.break_time_list?.length === 5 && (
          <p className={"alert-text"}>
            {t("*Cannot add more than five break time")}
          </p>
        )}
      </Flex>
    </BreakTimeFieldsContainer>
  )
}

export default BreakTimeFields
