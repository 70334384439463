import {
  Breadcrumb,
  Button,
  Card,
  DayPagination,
  IndicatorBox,
  PageTitle,
  USER_ATTENDANCE_VALUES,
  scrollToSelectedElement,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Skeleton, Typography } from "antd"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { PageHead } from "../../../../components"
import { FULL_DATE_FORMAT_EN } from "../../../../constants"
import { bulkCreateOrUpdateUserAttendance } from "../../../../services"
import { AttendanceStatsSummary } from "../DailyListCommon/AttendanceStatsSummary"
import { useFetchDailyAttendanceCalenderData } from "../DailyListCommon/useFetchDailyAttendanceCalenderData"
import DailyListUnscheduledAlert from "../DailyListUnscheduledAlert"
import { AttendanceDailyListBulkEditTable } from "./AttendanceDailyListBulkEditTable"
import { BulkEditOperationOptions } from "./BulkEditOperationOptions"

export const DailyListBulkEdit = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility_ids, use_service, date } = router?.query as any
  const contentRef = useRef<HTMLDivElement>(null)
  const [updateParams] = useUpdateSearchParams()
  const [params, setParams] = useState({
    facility_ids: facility_ids ? facility_ids?.split(",") : [],
    user_service_id: use_service ? use_service?.split(",") : [],
    date: date ? dayjs(date) : dayjs(),
  })
  const [unscheduledUsers, setUnscheduledUsers] = useState([])
  const {
    userDayAttendance,
    isAttendanceDataLoading,
    refetchAttendanceList,
    isFetching,
  } = useFetchDailyAttendanceCalenderData({ params })
  const { showToast } = useNotification()
  const [dataSource, setDataSource] = useState(
    userDayAttendance?.attendance_list || [],
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    userDayAttendance?.attendance_list?.map((v) => v?.tempId) || [],
  )

  useEffect(() => {
    const attendanceListWithoutSchedule =
      userDayAttendance?.attendance_list?.filter((att) => !att?.is_scheduled)
    if (attendanceListWithoutSchedule?.length > 0) {
      setUnscheduledUsers(attendanceListWithoutSchedule.map((att) => att?.user))
    } else {
      setUnscheduledUsers([])
    }
    setDataSource(userDayAttendance?.attendance_list || [])
    setSelectedRowKeys(
      userDayAttendance?.attendance_list?.map((v) => v?.tempId) || [],
    )
  }, [userDayAttendance])

  const calendarTitle = t(
    "{{year}}年{{month}}月{{day}}日 User-Attendance calendar",
    {
      year: dayjs(params?.date).year(),
      month: `0${dayjs(params?.date).month() + 1}`.slice(-2),
      day: `0${dayjs(params?.date).date()}`.slice(-2),
    },
  )
  const title = t(
    "{{year}}年{{month}}月{{day}}日 User-Batch edit of attendance records",
    {
      year: dayjs(params?.date).year(),
      month: `0${dayjs(params?.date).month() + 1}`.slice(-2),
      day: `0${dayjs(params?.date).date()}`.slice(-2),
    },
  )
  useEffect(() => {
    if (dataSource.length == 0) {
      // because it is resetting current data after api throws some error while trying to update
      setDataSource(userDayAttendance?.attendance_list || [])
      setSelectedRowKeys(
        userDayAttendance?.attendance_list?.map((v) => v?.tempId),
      )
    }
  }, [userDayAttendance])

  const handleResetOrReflection = (mode: "reset" | "reflect", values: any) => {
    if (mode === "reset") {
      setDataSource(userDayAttendance?.attendance_list || [])
      return
    }
    const data =
      selectedRowKeys?.length > 0
        ? dataSource?.map((source) =>
            selectedRowKeys?.includes(source?.tempId) && source?.is_scheduled
              ? {
                  ...source,
                  attendance_type: values?.attendance_type?.toString(),
                  start_time:
                    values?.start_time_flg && source?.pickup?.desired_time
                      ? source?.pickup?.desired_time
                      : !values?.start_time_flg &&
                          values?.start_time_hr &&
                          values?.start_time_min
                        ? `${values?.start_time_hr}:${values?.start_time_min}`
                        : source?.start_time,
                  end_time:
                    values?.end_time_flg && source?.drop?.desired_time
                      ? source?.drop?.desired_time
                      : !values?.end_time_flg &&
                          values?.end_time_hr &&
                          values?.end_time_min
                        ? `${values?.end_time_hr}:${values?.end_time_min}`
                        : source?.end_time,
                  temperature:
                    values?.temperature_first && values?.temperature_second
                      ? `${values?.temperature_first}.${values?.temperature_second}`
                      : source?.temperature,
                }
              : source,
          )
        : dataSource
    setDataSource(data)
  }
  const onCellDataChange = ({
    id,
    key,
    value,
    extra = {},
  }: {
    id: number
    key: string
    value: any
    extra?: any
  }) => {
    const data = dataSource?.map((source) =>
      source?.tempId === id ? { ...source, [key]: value, ...extra } : source,
    )
    setDataSource(data)
  }

  //bulkCreateOrUpdateUserAttendance
  const { isLoading, mutate } = useMutation({
    mutationFn: bulkCreateOrUpdateUserAttendance,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
      router.push(getDayAttendanceLink())
    },
    onError: (error: any) => {
      showToast({
        type: "error",
        message: t(
          error?.data?.error?.message ||
            "Something went wrong. Please contact administrator",
        ),
      })
    },
  })
  const handleSave = () => {
    const payload = dataSource
      ?.filter(
        (source) =>
          selectedRowKeys?.includes(source?.tempId) && source?.is_scheduled,
      )
      ?.map((source) => {
        const linkAttendanceAndTransportTime =
          source?.system_status_management?.link_transport_with_attendance

        // following values contain facility_id in payload which causes permission issue
        if (typeof source?.system_status_management != "undefined") {
          delete source.system_status_management
        }
        if (typeof source?.user_daily_record_request != "undefined") {
          delete source.user_daily_record_request
        }

        return {
          ...source,
          facility_id: source?.facility_id,
          user_id: source?.user_id,
          date: source?.date,
          save_staff_id: source?.save_staff_id,
          attendance_type: source?.attendance_type || "0",
          absence_reason: source?.absence_reason || "",
          service_type: source?.service_type,
          start_time: source?.start_time,
          end_time: source?.end_time,
          remarks: source?.remarks,
          remarks_output: source?.remarks_output,
          temperature: source?.temperature,
          meal_id: source?.meal_id,
          medical_collaboration_system: source?.medical_collaboration_system,
          guidance_flag: source?.guidance_flag ? 1 : 0,
          others: source?.others,
          digital_signature_image: source?.digital_signature_image,
          digital_signature_date_image: source?.digital_signature_date_image,
          user_attendance_actual_cost: source?.user_attendance_actual_cost?.map(
            (cost) => ({
              ...cost,
              used_flag: source?.used_user_attendance_actual_cost
                ?.map((v) => +v)
                ?.includes(cost?.actual_cost_id),
            }),
          ),
          update_additional_items: true,
          community_life_support_base:
            source?.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
              ? source?.community_life_support_base
              : false,
          pickup: {
            id: source?.pickup?.id,
            place: source?.pickup?.place,
            place_other_name: source?.pickup?.place_other_name,
            route: source?.pickup?.route,
            same_premises: source?.pickup?.same_premises,
            use_transportation_flag: source?.pickup?.use_transportation_flag,
            pickup_time: source?.pickup?.pickup_time,
            arrival_time: linkAttendanceAndTransportTime
              ? source?.start_time
              : source?.pickup?.arrival_time,
            desired_time: source?.pickup?.desired_time,
            remarks: source?.pickup?.remarks,
          },
          drop: {
            id: source?.drop?.id,
            place: source?.drop?.place,
            place_other_name: source?.drop?.place_other_name,
            route: source?.drop?.route,
            same_premises: source?.drop?.same_premises,
            use_transportation_flag: source?.drop?.use_transportation_flag,
            pickup_time: linkAttendanceAndTransportTime
              ? source?.end_time
              : source?.drop?.pickup_time,
            arrival_time: source?.drop?.arrival_time,
            desired_time: source?.drop?.desired_time,
            remarks: source?.drop?.remarks,
          },
        }
      })
    mutate(payload)
  }

  const getMonthlyAttendanceCalendarLink = () => {
    const link = `/user-attendance-calendar`

    const query = []

    if (params.date) {
      query.push(`year=${params.date.year()}`)
      query.push(`month=${params.date.month() + 1}`)
    }

    if (Array.isArray(params.facility_ids) && params.facility_ids.length > 0) {
      query.push(`facilityId=${params.facility_ids[0]}`)
    }

    return link + "?" + query.join("&")
  }

  const getDayAttendanceLink = () => {
    const link = `/user-attendance-calendar/daily-list`
    const query = []
    if (params.date) {
      query.push(`date=${params.date.format("YYYY-MM-DD")}`)
    }

    if (Array.isArray(params.facility_ids) && params.facility_ids.length > 0) {
      query.push(`facilityId=${params.facility_ids.join("&")}`)
    }

    return link + `?` + query.join("&")
  }

  return (
    <>
      <PageHead>{t("User attendance calender")}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },

          {
            title: (
              <Link href={getMonthlyAttendanceCalendarLink()}>
                {t("User Attendance Calendar")}
              </Link>
            ),
          },
          {
            title: <Link href={getDayAttendanceLink()}>{calendarTitle}</Link>,
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
      />

      {unscheduledUsers.length > 0 && (
        <DailyListUnscheduledAlert userList={unscheduledUsers} />
      )}

      <Flex vertical gap={16} style={{ marginTop: "10px" }}>
        <BulkEditOperationOptions
          params={params}
          setParams={(values) => {
            setParams(values)
            scrollToSelectedElement(contentRef)
          }}
          handleResetOrReflection={handleResetOrReflection}
        />
        <div ref={contentRef}>
          <Card
            title={title}
            extra={
              <DayPagination
                currentDay={params.date ? params.date.format("YYYY-MM-DD") : ""}
                onDayChange={(day) => {
                  setParams({
                    ...params,
                    date: dayjs(day),
                  })
                  updateParams(
                    {
                      facility_ids: params?.facility_ids?.toString(),
                      user_service_id: params?.user_service_id?.toString,
                      date: dayjs(day).format(FULL_DATE_FORMAT_EN),
                    },
                    "/user-attendance-calendar/daily-list/bulk-edit",
                  )
                }}
              />
            }
          >
            {isAttendanceDataLoading || isFetching ? (
              <Flex vertical gap={16}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Flex>
            ) : (
              <Flex vertical gap={16}>
                <AttendanceStatsSummary
                  attendanceStats={userDayAttendance?.attendance_stat}
                />
                <IndicatorBox>
                  <div className={"box"} />
                  <Typography.Text className={"text"}>
                    {t("Experience")}
                  </Typography.Text>
                </IndicatorBox>
                <AttendanceDailyListBulkEditTable
                  isLoading={isAttendanceDataLoading}
                  refetch={refetchAttendanceList}
                  data={dataSource || []}
                  onCellDataChange={onCellDataChange}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  params={params}
                />
                <Flex wrap={"wrap"} gap={16}>
                  <Button
                    btnText={t("Cancel")}
                    shape={"round"}
                    onClick={() => router.push(getDayAttendanceLink())}
                    disabled={isAttendanceDataLoading || isLoading}
                  />
                  <Button
                    btnText={t("Save")}
                    shape={"round"}
                    type={"primary"}
                    disabled={selectedRowKeys?.length < 1}
                    onClick={handleSave}
                    isLoading={isLoading}
                  />
                </Flex>
              </Flex>
            )}
          </Card>
        </div>
      </Flex>
    </>
  )
}
