import { Button, SERVICE_TYPE_VALUES } from "@project/common"
import { AdditionalItemsFormScreen } from "./AdditionalItemsFormScreen"
import React from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import styled from "styled-components"
import { Spin } from "antd"

const Wrapper = styled.div`
  margin-top: 40px;
  .division {
    margin-bottom: 16px !important;
  }
  .buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    button {
      border-radius: 100px;
    }
    .save {
      color: #fff;
    }
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`

const AdditionalItemsListScreen: React.FC<any> = ({
  formik,
  isLoading,
  isFetching,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const facilityType =
    formik.values.facility_type == SERVICE_TYPE_VALUES?.TYPE_1
      ? t("Employment Type A")
      : formik.values.facility_type == SERVICE_TYPE_VALUES?.TYPE_2
        ? t("Employment Type B")
        : t("Employment Transition Support")

  return (
    <Spin spinning={isLoading || isFetching}>
      <Wrapper>
        <Header>{facilityType}</Header>
        <Divisor className={"division"} />
        <AdditionalItemsFormScreen formik={formik} />
        <div className={"buttons"}>
          <Button
            btnText={t("Cancel")}
            btnBg={"#F6F7F8"}
            className={"cancel"}
            onClick={() => router.back()}
            disabled={isLoading || isFetching}
          />
          <Button
            btnText={t("Save")}
            btnBg={"#0782C8"}
            className={"save"}
            onClick={formik.handleSubmit}
            isLoading={isLoading || isFetching}
            disabled={isLoading || isFetching}
          />
        </div>
      </Wrapper>
    </Spin>
  )
}

export { AdditionalItemsListScreen }
