import { Flex, Popover } from "antd"
import styled from "styled-components"
import { Box, theme } from "@project/common"

export const ActionCellStyled = styled(Flex)``

export const LeavePopoverStyled = styled(Popover)`
  .root-container {
    width: 381px;
    max-width: 381px;
    inset: auto auto 28px -186.352px;
    .ant-popover-content {
      .ant-popover-inner {
        border: 1px solid ${theme.colors.border};
        border-radius: 12px;
        padding: 16px 24px;
      }
    }
  }
  &.leave-reject-popover {
    button {
      width: max-content;
      word-break: keep-all;
    }
  }
`

export const PopoverContent = styled(Box)`
  .label {
    .required-star {
      color: ${theme.colors.error};
    }
  }
  .leave-reject-action-btns {
    margin-top: 16px;
    button {
      padding-inline: 20px !important;
    }
  }
`

export const PopoverTitle = styled(Flex)`
  .alert-icon {
    min-width: 20px;
    min-height: 20px;
    margin-top: 4px;
  }
  .popover-title {
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.text};
  }
`
