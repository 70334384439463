import React from "react"

// styles
import { VehicleAndDriverInfoTable } from "../UserTransportMgmtPrint.styles"
import { t } from "i18next"

export interface IVehicleDriverInfo {
  driverName?: string
  vehicleName?: string
  vehicleNumber?: string
}

export const VehicleAndDriverInfoEntry = ({
  driverName = "",
  vehicleName = "",
  vehicleNumber = "",
}: IVehicleDriverInfo) => {
  return (
    <VehicleAndDriverInfoTable>
      <tbody>
        <tr>
          <td colSpan={2}>
            {t("Driver's name")} {": "} {driverName}
          </td>
        </tr>
        <tr>
          <td>
            {t("Vehicle name")} {": "} {vehicleName}
          </td>
          <td>
            {t("Vehicle number")} {": "} {vehicleNumber}
          </td>
        </tr>
      </tbody>
    </VehicleAndDriverInfoTable>
  )
}
