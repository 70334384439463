import { Card, theme } from "@project/common"
import styled from "styled-components"

export const PrintCustomerInvoiceContainer = styled.div`
  .back-btn {
    margin-block: 28px;
  }
  .size-sm {
    height: 30px !important;
    min-height: 30px !important;
    padding-block: 0px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const CustomerInvoiceListCard = styled(Card)`
  .info-text {
    color: ${theme.colors.text};
    font-weight: 500;
    margin-block: 16px;
  }

  .save-temporarily-btn {
    margin-block: 16px;
  }

  .individual-table {
    margin-block: 16px;
  }

  .quote-title {
    margin-bottom: 9px;
  }
`
