import React from "react"

const UserIcon = () => {
  return (
    <svg
      width={"81"}
      height={"81"}
      viewBox={"0 0 81 81"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <rect
        x={"1"}
        y={"1"}
        width={"79"}
        height={"79"}
        rx={"39.5"}
        fill={"white"}
      />
      <rect
        x={"1"}
        y={"1"}
        width={"79"}
        height={"79"}
        rx={"39.5"}
        stroke={"#E2F4FF"}
      />
      <path
        d={
          "M40.2693 40.4586C45.7543 40.4586 50.2008 36.0121 50.2008 30.5271C50.2008 25.0422 45.7543 20.5957 40.2693 20.5957C34.7843 20.5957 30.3379 25.0422 30.3379 30.5271C30.3379 36.0121 34.7843 40.4586 40.2693 40.4586Z"
        }
        fill={"white"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M56.8221 60.2971C56.8221 51.1618 49.4259 43.7656 40.2907 43.7656C31.1554 43.7656 23.7383 51.1618 23.7383 60.2971"
        }
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export default UserIcon
