import { theme } from "@project/common"
import { Card, Flex, Progress, Typography } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

export const SummaryContent = styled.div`
  #paid_leave_summary_card {
    border: 1px solid ${theme.colors.action};
    border-radius: 12px;
    box-shadow: 0px 0px 15px 0px #0000000a;
    width: 256px;
    height: 107px;
  }
  .w111 {
    width: 111px !important;
  }
`
export const PaidLeaveSettingsSummary = ({ response }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SummaryContent className={"summary"}>
      <Flex gap={16} wrap={"wrap"}>
        <Card id={"paid_leave_summary_card"}>
          <Flex vertical align={"center"}>
            <Typography.Text
              style={{
                fontSize: "22px",
                fontWeight: 700,
                color: theme.colors.action,
              }}
            >
              {response?.total_paid_leave_left || 0}
              {t("Day")}
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: "16px",
              }}
            >
              {t("Total paid leave left")}
            </Typography.Text>
          </Flex>
        </Card>
        <Card id={"paid_leave_summary_card"}>
          <Flex vertical align={"center"}>
            <Typography.Text
              style={{
                fontSize: "22px",
                fontWeight: 700,
                color: theme.colors.action,
              }}
            >
              {response?.total_applied_leave || 0}
              {t("Day")}
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: "16px",
              }}
            >
              {t("Total Applied Leave")}
            </Typography.Text>
          </Flex>
        </Card>
        <Card
          id={"paid_leave_summary_card"}
          className={"w111"}
          bodyStyle={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Progress
            type={"circle"}
            percent={
              (response?.total_paid_leave_granted -
                response?.total_paid_leave_left /
                  response?.total_paid_leave_granted) *
              100
            }
            size={90}
            strokeColor={theme.colors.info}
            trailColor={"#D5D1D1"}
            format={() => (
              <Flex align={"center"} justify={"center"}>
                <Typography.Text
                  style={{
                    fontSize: "11px",
                    fontWeight: 700,
                    color: theme.colors.text,
                  }}
                >
                  {(response?.total_paid_leave_granted || 0) -
                    (response?.total_paid_leave_left || 0)}
                  {t("Day")}
                  {"/"}
                  {response?.total_paid_leave_granted || 0}
                  {t("Day")}
                </Typography.Text>
              </Flex>
            )}
          />
        </Card>
      </Flex>
    </SummaryContent>
  )
}
