import {
  AccordionCard,
  Button,
  Card,
  CheckBox,
  DatePicker,
  FACILITY_SERVICE_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  PageTitle,
  SelectInput,
  Table,
  Tag,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import { Flex } from "antd"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { AuthContext } from "../../context/AuthContext"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { fetchMinutesOfMeetingOfPic } from "../../services/minutesOfMeetingOfPic"
import { MinutesOfMeetingOfPicColumn } from "../../types"
import { filterWithHiragana } from "../../utils"
import { FuriganaAlphabetsOption } from "../../utils/common-options"
import {
  MinutesOfMeetingOfPicContent,
  TMiniContainer,
  TableContainer,
} from "./MinutesOfMeetingOfPic.style"
const MinutesOfMeetingOfPic = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility } = router.query as any
  const listContent = useRef<any>(null)
  const [updateParams] = useUpdateSearchParams()
  const [page, setPage] = useState(+router.query?.page || 1)
  const { facilities } = useContext(AuthContext)
  const selectedFIds = facility
    ? facility?.split(",")?.filter((f) => !isNaN(f) && f)
    : []
  const defaultSelectedFacility = [facilities[0] ? facilities[0]?.id : 0]
  const [checkedFacility, setCheckedFacility] = useState<CheckboxValueType[]>(
    selectedFIds || defaultSelectedFacility || [],
  )
  const [startChar, setStartChar] = useState(null)

  const [searchParams, setSearchParams] = useState<any>({
    user_id: "",
    facility: checkedFacility,
    start_date: "",
    end_date: "",
    created: false,
    saved_as_draft: false,
  })

  const [searchEnabled, setSearchEnabled] = useState(true)
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>(
    ["MinutesOfMeetingOfPic"],
    () =>
      fetchMinutesOfMeetingOfPic({
        queryKey: [
          "MinutesOfMeetingOfPic",
          page,
          pageSize,
          {
            ...searchParams,
            facility: Array.isArray(searchParams?.facility)
              ? searchParams?.facility?.toString()
              : searchParams?.facility,
          },
        ],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: searchEnabled,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return {
              ...data,
              start_date: dayjs(data?.event_date).format("YYYY-MM-DD"),
            }
          }),
          count: response?.count,
        }
      },
      onSuccess: () => {
        setSearchEnabled(false)
      },
    },
  )

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setPage(1)
    updateParams(queries, "/minutes")
    scrollToSelectedElement(listContent)
  }

  const onSearch = () => {
    const values = {
      facility: checkedFacility?.toString(),
      start_date: searchParams?.start_date
        ? dayjs(searchParams?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: searchParams?.end_date
        ? dayjs(searchParams?.end_date).format("YYYY-MM-DD")
        : "",
      created: searchParams?.created,
      saved_as_draft: searchParams?.saved_as_draft,
      user_id: searchParams?.user_id,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(params)
    handleSearchAndResetParams(params)
    setSearchEnabled(true)
  }

  const onSearchReset = () => {
    setCheckedFacility([])
    setPage(1)
    setSearchEnabled(true)
    setSearchParams({
      start_date: "",
      end_date: "",
      facility: [],
      created: false,
      saved_as_draft: false,
      user_id: "",
    })
    handleSearchAndResetParams({}, "reset")
  }

  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    router.push(
      {
        pathname: "/minutes",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }
  const { userData: allUsers } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })
  const columns: any = [
    {
      title: t("Details"),
      dataIndex: "details",
      key: "details",
      align: "center",
      width: 100,
      render: (_, data: MinutesOfMeetingOfPicColumn) => {
        return (
          <Link href={`/minutes/detail/${data?.id}`} passHref>
            <a>{t("Detail")}</a>
          </Link>
        )
      },
    },
    {
      title: t("User name"),
      dataIndex: "username",
      key: "username",
      align: "center",
      width: 160,
      render: (_, data: MinutesOfMeetingOfPicColumn) => {
        return (
          <Link href={`/users/profile/${data.User.id}`}>
            <a>{data.User.username}</a>
          </Link>
        )
      },
      sorter: (a, b) => {
        return a?.User?.username?.length - b?.User?.username?.length
      },
    },
    {
      title: t("Facility name"),
      align: "center",
      dataIndex: "facility_name",
      key: "facility_name",
      width: 145,
      render: (_, value: MinutesOfMeetingOfPicColumn) => {
        return (
          facilities?.find((item) => item.id == value?.facility_id)
            ?.facility_name || "-"
        )
      },
    },
    {
      title: t("Use service"),
      align: "center",
      dataIndex: "service_type",
      key: "service_type",
      width: 145,
      render: (value) => {
        const data = t(FACILITY_SERVICE_TYPES[value])
        return (
          <Tag
            title={t(data)}
            defaultBg={
              value == 1 ? "#FFFDF3" : value == 2 ? "#F2FFF7" : "#EFF9FF"
            }
            textColor={
              value == 1 ? "#AF8900" : value == 2 ? "#4CB476" : "#0782C8"
            }
            borderRadius={"4px"}
            borderColor={
              value == 1 ? "#CDA001" : value == 2 ? "#4CB476" : "#0782C8"
            }
            padding={"4px"}
          />
        )
      },
    },
    {
      title: t("No. of created"),
      align: "center",
      dataIndex: "create_count",
      key: "create_count",
      width: 145,
    },

    {
      title: t("Event date"),
      align: "center",
      dataIndex: "start_date",
      key: "",
      width: 145,
      render: (data) => {
        return dayjs(data).format("YYYY年MM月DD日")
      },
      sorter: (a, b) => {
        return (
          dayjs(a.start_date, "YYYY年MM月DD日").unix() -
          dayjs(b.start_date, "YYYY年MM月DD日").unix()
        )
      },
    },
    {
      title: t("Create status"),
      align: "center",
      dataIndex: "draft_save_flag",
      key: "draft_save_flag",
      width: 145,
      render: (_, value) => {
        const data = value?.draft_save_flg ? "Draft" : "Created"
        return (
          <Tag
            title={t(data)}
            defaultBg={data == "Created" && "#E26827"}
            textColor={data == "Created" && "#FFF"}
            borderRadius={"4px"}
            padding={"8px"}
          />
        )
      },
    },
    {
      title: t("Copy"),
      align: "center",
      dataIndex: "",
      key: "",
      width: 140,
      render: (_, row_value) => {
        return (
          <Button
            btnText={t("Copy")}
            iconType={"copy"}
            type={"default"}
            otherType={"draft"}
            shape={"round"}
            btnBg={"#F3F3F3"}
            borderColor={"#D2D1D1"}
            color={"#A9A9A9"}
            onClick={() => {
              router.push(`/minutes/create?cp_id=${row_value?.id}`)
            }}
          />
        )
      },
    },
  ]

  return (
    <>
      <PageTitle
        title={t(
          "List of Minutes of Service Personnel Meetings and Support Meetings",
        )}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        extra={
          <Button
            btnText={t(
              "Create Minutes for New Service Personnel Meetings (Assistance Meetings)",
            )}
            type={"primary"}
            shape={"round"}
            size={"large"}
            onClick={() => router.push("/minutes/add")}
          />
        }
      />
      <MinutesOfMeetingOfPicContent>
        <AccordionCard
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <OperationSearchContent>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("User name")}
                      width={"138px"}
                    />
                    <Flex wrap={"wrap"} gap={8} className={"w-full"}>
                      <SelectInput
                        name={"username"}
                        width={"80px"}
                        shortSelector
                        placeholder={"---"}
                        options={FuriganaAlphabetsOption}
                        value={startChar}
                        onChange={setStartChar}
                      />
                      <SelectInput
                        name={"user_id"}
                        size={"large"}
                        placeholder={"--"}
                        width={"307px"}
                        options={filterWithHiragana({
                          options: allUsers,
                          furigana: startChar,
                        })}
                        value={searchParams.user_id}
                        onChange={(v) =>
                          setSearchParams({ ...searchParams, user_id: v })
                        }
                      />
                    </Flex>
                  </div>
                  <div className={"field__content flex-start"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Facility")}
                        width={"138px"}
                      />
                    </div>
                    <FacilityCheckbox
                      options={facilities}
                      value={checkedFacility}
                      handleCheckUncheckAll={(type: "check" | "uncheck") => {
                        if (type === "check") {
                          const ids = facilities?.map((v) => v?.value)
                          setCheckedFacility(ids)
                        }
                        if (type === "uncheck") {
                          setCheckedFacility([])
                        }
                      }}
                      onChange={(list: CheckboxValueType[]) => {
                        setCheckedFacility(list)
                      }}
                    />
                  </div>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Event date")}
                      width={"138px"}
                    />
                    <div className={"search__content-date"}>
                      <DatePicker
                        style={{
                          width: "158px",
                        }}
                        date={
                          dayjs(searchParams?.start_date).isValid()
                            ? dayjs(searchParams?.start_date)
                            : null
                        }
                        onDateChange={(date) => {
                          setSearchParams({
                            ...searchParams,
                            start_date: dayjs(date),
                          })
                        }}
                        format={"YYYY年MM月DD日"}
                      />
                      <span>{"~"}</span>
                      <DatePicker
                        style={{
                          width: "158px",
                        }}
                        onDateChange={(val) =>
                          setSearchParams({
                            ...searchParams,
                            end_date: dayjs(val),
                          })
                        }
                        date={
                          dayjs(searchParams?.end_date).isValid()
                            ? dayjs(searchParams?.end_date)
                            : null
                        }
                        format={"YYYY年MM月DD日"}
                      />
                    </div>
                  </div>
                  <div className={"search__content flex-start"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Create status")}
                        width={"138px"}
                      />
                    </div>
                    <CheckBox
                      name={"created"}
                      label={t("Created")}
                      value={searchParams?.created}
                      checked={searchParams?.created}
                      onChange={({ target: { checked } }) =>
                        setSearchParams({
                          ...searchParams,
                          created: checked,
                        })
                      }
                    />
                    <CheckBox
                      name={"saved_as_draft"}
                      label={t("Saved as draft")}
                      value={searchParams?.saved_as_draft}
                      checked={searchParams?.saved_as_draft}
                      onChange={({ target: { checked } }) =>
                        setSearchParams({
                          ...searchParams,
                          saved_as_draft: checked,
                        })
                      }
                    />
                  </div>
                  <div className={"search__content btns create_status_buttons"}>
                    <Button
                      btnText={t("Reset search")}
                      shape={"round"}
                      onClick={onSearchReset}
                    />
                    <Button
                      btnText={t("Search")}
                      iconType={"search"}
                      type={"primary"}
                      shape={"round"}
                      onClick={onSearch}
                    />
                  </div>
                </OperationSearchContent>
              ),
            },
          ]}
        />
        <Card
          title={t(
            "List of Minutes of Service Personnel Meetings and Support Meetings",
          )}
        >
          <TableContainer>
            <TMiniContainer>
              <img src={"./assets/icons/pink.svg"} alt={"icon"} />
              <span>
                {t("Users who do not have a registered recipient certificate")}
              </span>
            </TMiniContainer>
          </TableContainer>

          <div ref={listContent}>
            <Table
              current={+page}
              columns={columns}
              pageSize={pageSize}
              total={response?.count}
              dataSource={response?.data}
              showPaginationOf={"both"}
              scroll={{ x: 1300 }}
              onChange={handlePaginationChange}
              cellPaddingBlock={12}
              loading={isFetching || isLoading}
              rowClassName={(row: any) =>
                !row?.certificate_id && "no-certificate-row"
              }
            />
          </div>
        </Card>
      </MinutesOfMeetingOfPicContent>
    </>
  )
}

export { MinutesOfMeetingOfPic }
