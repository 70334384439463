import { SubsidyBillingAmtDataType } from "./types"

export const subsidyBillingMockData: SubsidyBillingAmtDataType[] = [
  {
    id: 1,
    key: 1,
    parents_name: "Mohan samsher rana",
    user_name: "山田太郎（やまだ　じろう）",
    certificate_number: 1234510041,
    total_cost: 107038,
    user_burden: 898,
  },
  {
    id: 2,
    key: 2,
    parents_name: "Chandra samsher rana",
    user_name: "山田太郎（やまだ　じろう）",
    certificate_number: 1266510041,
    total_cost: 107038,
    user_burden: 4600,
  },
  {
    id: 3,
    key: 3,
    parents_name: "Zero shamsher rana",
    user_name: "山田太郎（やまだ　じろう）",
    certificate_number: 9234500041,
    total_cost: 107038,
    user_burden: 9000,
  },
]
