import React, { useState } from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Flex, Space } from "antd"
import { ColumnsType } from "antd/es/table"

// commons
import { Button, Card, Table, WithSorter } from "@project/common"

// types
import { SubsidyBillingAmtDataType, UserFeesOperation } from "./types"

// constants
import { subsidyBillingMockData } from "./constants"
import { useAuthContext } from "../../context"

export const SubsidyBillingAmtList = ({
  memoizedQuery,
}: {
  memoizedQuery: UserFeesOperation
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRows, setSelectedRows] = useState<(number | string)[]>([])

  const { query } = useRouter()
  const { facilities } = useAuthContext()

  const currentDate = query?.year_month
    ? dayjs(query?.year_month.toString()).format("YYYY年MM月")
    : dayjs().format("YYYY年MM月")

  const TABLE_COLUMNS: ColumnsType<SubsidyBillingAmtDataType> = [
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Parent's name (furigana)`}
            columnKey={"parents_name"}
          />
        )
      },
      key: "parents_name",
      dataIndex: "parents_name",
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) => a.parents_name.localeCompare(b.parents_name),
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"User’s name (furigana)"}
            columnKey={"user_name"}
          />
        )
      },
      key: "user_name",
      dataIndex: "user_name",
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"Beneficiary certificate number"}
            columnKey={"certificate_number"}
          />
        )
      },
      key: "certificate_number",
      dataIndex: "certificate_number",
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) => a.certificate_number - b.certificate_number,
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"Total"}
            columnKey={"total_cost"}
          />
        )
      },
      key: "total_cost",
      dataIndex: "total_cost",
      render: (val: SubsidyBillingAmtDataType["total_cost"]) =>
        `${val.toLocaleString()}円`,
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) => a.total_cost - b.total_cost,
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={"User burden"}
            columnKey={"user_burden"}
          />
        )
      },
      key: "user_burden",
      dataIndex: "user_burden",
      render: (val: SubsidyBillingAmtDataType["user_burden"]) =>
        `${val.toLocaleString()}円`,
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) => a.user_burden - b.user_burden,
    },
  ]

  // 1. Select all rows
  const onSelectAll = () => {
    const allIds = subsidyBillingMockData.map((item) => item.id)
    setSelectedRows(allIds)
  }

  // 2. unSelect all rows
  const onUnSelectAll = () => {
    setSelectedRows([])
  }

  return (
    <Card
      title={t(
        "{{date}}’s Municipality Subsidy Billing Amount Information List",
        {
          date: currentDate,
        },
      )}
    >
      <Flex gap={24} align={"center"} wrap={"wrap"}>
        <Space size={12}>
          <Button
            type={"grayed"}
            shape={"round"}
            iconType={"check-all"}
            btnText={t("Check all")}
            height={"30px"}
            className={"check-all-btn"}
            onClick={() => onSelectAll()}
          />
          <Button
            type={"grayed"}
            shape={"round"}
            btnText={t("Clear all")}
            height={"30px"}
            onClick={() => onUnSelectAll()}
          />
        </Space>

        <Button
          type={"primary"}
          shape={"round"}
          iconType={"print"}
          btnText={t("Print")}
        />
      </Flex>

      <h4 className={"table-title"}>
        {t("{{JointFacilityName}} {{JointFacilityBusinessName}}", {
          JointFacilityName: facilities?.find(
            (fac) => fac.value === memoizedQuery.facility_id,
          )?.facility_name,
        })}
      </h4>

      <Table
        rowSelection={{
          type: "checkbox",
          columnTitle: t("Check"),
          selectedRowKeys: selectedRows,
          onSelect: (_, __, selectedRows) => {
            const selectedIds = selectedRows.map((row) => row.id)
            setSelectedRows(selectedIds)
          },
          columnWidth: 80,
        }}
        columns={TABLE_COLUMNS}
        scroll={{ x: "max-content" }}
        dataSource={subsidyBillingMockData}
      />
    </Card>
  )
}
