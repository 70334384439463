import { CircleButton, Grid, theme } from "@project/common"
import { Flex } from "antd"
import React from "react"

export const AssessmentSettingsSortableItem = ({
  label,
  onSort,
  isFirstItem,
  isLastItem,
  totalItems,
  hasWriteAccess,
}: {
  label
  onSort: (dir: "up" | "down") => void
  isFirstItem?: boolean
  isLastItem?: boolean
  totalItems: number
  hasWriteAccess: boolean
}): JSX.Element => {
  return (
    <Grid
      background
      labelContent={label}
      padding={"5px 10px"}
      labelSpan={6}
      justifyContent={"center"}
    >
      <Flex justify={"flex-end"}>
        {isFirstItem ? (
          <CircleButton
            shape={"circle"}
            Icon={
              <img src={"./assets/icons/sort-down-arrow.svg"} alt={"icon"} />
            }
            width={"40px"}
            height={"40px"}
            disabled={!hasWriteAccess || totalItems === 1}
            onClick={() => onSort("down")}
            borderColor={theme.colors.action}
          />
        ) : isLastItem ? (
          <CircleButton
            shape={"circle"}
            Icon={<img src={"./assets/icons/sort-up-arrow.svg"} alt={"icon"} />}
            width={"40px"}
            height={"40px"}
            onClick={() => onSort("up")}
            borderColor={theme.colors.action}
            disabled={!hasWriteAccess}
          />
        ) : (
          <Flex gap={8}>
            <CircleButton
              shape={"circle"}
              Icon={
                <img src={"./assets/icons/sort-up-arrow.svg"} alt={"icon"} />
              }
              width={"40px"}
              height={"40px"}
              onClick={() => onSort("up")}
              borderColor={theme.colors.action}
              disabled={!hasWriteAccess}
            />
            <CircleButton
              shape={"circle"}
              Icon={
                <img src={"./assets/icons/sort-down-arrow.svg"} alt={"icon"} />
              }
              width={"40px"}
              height={"40px"}
              onClick={() => onSort("down")}
              borderColor={theme.colors.action}
              disabled={!hasWriteAccess}
            />
          </Flex>
        )}
      </Flex>
    </Grid>
  )
}
