import React, { useState } from "react"
import { EmploymentSupportBusinessSalesDivisionTabList } from "./EmploymentSupportBusinessSalesDivisionTabList"
import { EmploymentSupportBusinessSalesDivisionTabEdit } from "./EmploymentSupportBusinessSalesDivisionTabEdit"

export const EmploymentSupportBusinessSalesDivisionTabContent = ({
  ...props
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const [selectedCols, setSelectedCols] = useState<any>(null)
  if (selectedCols) {
    return (
      <EmploymentSupportBusinessSalesDivisionTabEdit
        selectedCol={selectedCols}
        setSelectedCol={setSelectedCols}
        {...props}
      />
    )
  }
  return (
    <EmploymentSupportBusinessSalesDivisionTabList
      setSelectedCol={setSelectedCols}
      {...props}
    />
  )
}
