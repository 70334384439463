import { t } from "i18next"

// types
import { ColumnsType } from "antd/es/table"
import { BinaryType, PickupPrintTableDataType } from "../types"
import {
  PICK_DROP_INT_VALUES,
  PICK_DROP_OPTIONS_INT_VAL,
} from "../../../constants"

// utils function
const spanWholeCols = (record: PickupPrintTableDataType) => {
  return record.is_absent || !record.use_transportation_flag
}

export const genericPrintTableColumn = (
  type: "pickup" | "dropOff",
  showPlace: BinaryType,
): ColumnsType<PickupPrintTableDataType> => {
  const column = [
    {
      title: t("Username"),
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      width: 114,
    },
    {
      title: t("Name of facility"),
      dataIndex: "facility",
      align: "center",
      key: "facility",
      width: 114,
    },
    {
      title:
        type === "pickup" ? t("Desired pick up time") : t("Desired drop time"),
      dataIndex: "desired_time",
      align: "center",
      width: 114,
      key: "desired_time",
      render: (val, rec) => {
        if (rec.is_absent) {
          return t("absent")
        }
        if (!rec.use_transportation_flag) {
          return t("No transportation")
        }

        return val
      },
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: showPlace === "1" ? 8 : 7,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: type === "pickup" ? t("Pick up time") : t("Dropped time"),
      dataIndex: "pickup_time",
      align: "center",
      width: 114,
      key: "pickup_time",
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Arrival time"),
      dataIndex: "arrival_time",
      align: "center",
      width: 114,
      key: "arrival_time",
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Place"),
      dataIndex: "place",
      align: "center",
      width: 114,
      key: "place",
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
      render: (place, row) => {
        return (
          <>
            {
              PICK_DROP_OPTIONS_INT_VAL?.find((val) => val.value == place)
                ?.label
            }
            {place == PICK_DROP_INT_VALUES.OTHERS &&
              " " + row?.place_other_name}
          </>
        )
      },
    },
    {
      title: t("Driver"),
      dataIndex: "driver_staff",
      align: "center",
      key: "driver_staff",
      width: 114,
      render: (val: PickupPrintTableDataType["driver_staff"]) => val.staff_name,
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Conductor"),
      dataIndex: "escort_staff",
      align: "center",
      key: "escort_staff",
      width: 114,
      render: (val: PickupPrintTableDataType["escort_staff"]) => val.staff_name,
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Vehicle"),
      dataIndex: "car",
      align: "center",
      key: "car",
      width: 114,
      render: (val: PickupPrintTableDataType["car"]) => val.car_name,
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Route"),
      dataIndex: "route",
      align: "center",
      key: "route",
      width: 114,
      render: (val: PickupPrintTableDataType["route"]) => val,
      onCell: (rec) =>
        spanWholeCols(rec)
          ? {
              colSpan: 0,
            }
          : {
              colSpan: 1,
            },
    },
    {
      title: t("Remarks"),
      dataIndex: "remarks",
      align: "center",
      key: "remarks",
      width: 120,
    },
  ]
  return column.filter((col) =>
    col.dataIndex !== "place" ? true : showPlace === "1" ? true : false,
  ) as ColumnsType<PickupPrintTableDataType>
}
