import { ACCOUNT_TYPE_OPTIONS } from "@project/common"
import React from "react"
import { useTranslation } from "react-i18next"

const AccountInformationTable = ({ response }) => {
  const { t } = useTranslation()
  const accountType = ACCOUNT_TYPE_OPTIONS.find(
    (d) => d?.value == response?.account_type,
  )?.label
  return (
    <>
      <table>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Financial institution name kana")}</td>
          <td className={"value"}>
            {response?.financial_institution_name_kana || "-"}
          </td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Branch name Kana")}</td>
          <td className={"value"}>{response?.branch_name_kana || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Financial institute code")}</td>
          <td className={"value"}>
            {response?.financial_institute_code || "-"}
          </td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Branch code")}</td>
          <td className={"value"}>{response?.branch_code || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Account type")}</td>
          <td className={"value"}>{accountType || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Phone number")}</td>
          <td className={"value"}>{response?.phone || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Account number")}</td>
          <td className={"value"}>{response?.account_number || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Account holder name")}</td>
          <td className={"value"}>{response?.account_holder_name || "-"}</td>
        </tr>
        <tr className={"second-table"}>
          <td className={"label"}>{t("Customer code")}</td>
          <td className={"value"}>{response?.customer_code || "-"}</td>
        </tr>
      </table>
    </>
  )
}

export default AccountInformationTable
