import { Button, Card, LinkButton, Table, theme } from "@project/common"
import type { ColumnsType } from "antd/es/table"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PAGE_SIZE } from "../../constants"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { getAllMealMaster } from "../../services"

const MealMasterListContainer = ({
  hasWriteAccess = false,
}: {
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { page } = router?.query as any
  const [currentPage, setCurrentPage] = useState(+page || 1)
  const { data: mealData, isLoading } = useQuery<any>({
    queryKey: ["meal-master", currentPage],
    queryFn: () => getAllMealMaster({ page: currentPage, pageSize: PAGE_SIZE }),
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  })

  const columns: ColumnsType<any[]> = [
    {
      title: t("Edit"),
      key: "edit",
      align: "center",
      width: "287px",
      render: (row) => (
        <LinkButton
          disabled={!hasWriteAccess}
          onClick={() => {
            router.push(`/meal-master/edit/${row?.id}`)
          }}
        >
          {t("Edit")}
        </LinkButton>
      ),
    },
    {
      title: t("Meal name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("Cost"),
      key: "cost",
      align: "center",
      dataIndex: "cost",
      render: (data) => (
        <span>
          {new Intl.NumberFormat().format(data)} {t("円")}
        </span>
      ),
    },
  ]
  return (
    <div>
      <Card
        title={t("List")}
        bordered={false}
        className={"meal-master-card"}
        style={{ border: "none" }}
        extra={
          <Button
            btnText={t("Add Meal")}
            shape={"round"}
            iconType={"plus-circle"}
            btnBg={theme.colors.action}
            textColor={theme.colors.white}
            onClick={() => router.push("/meal-master/add")}
            disabled={!hasWriteAccess}
          />
        }
      >
        <Table
          columns={columns}
          dataSource={mealData?.data || []}
          current={currentPage}
          total={mealData?.count}
          pageSize={PAGE_SIZE}
          showPaginationOf={"both"}
          noHeaderBorder
          headerCellPaddingBlock={"12px"}
          cellPaddingBlock={10}
          scroll={{
            x: 500,
          }}
          onChange={(val) => {
            setCurrentPage(val)
          }}
          loading={isLoading}
        />
      </Card>
    </div>
  )
}

export { MealMasterListContainer }
