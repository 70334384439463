import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  LabelTextWithLeftArrow,
  OperationSearchContent,
} from "@project/common"
import {
  CONSENT_DATE_FIELDS,
  PLAN_DISPLAY_CONTENT_MONITORING,
} from "../../../constants"

const MonitoringDetailOperationWrapper = styled.div`
  #facility__content__container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
`

interface MonitoringDetailOperationProps {
  onDisplayChange: (values: any) => void
}
export const MonitoringDetailOperation = ({
  onDisplayChange,
}: MonitoringDetailOperationProps): JSX.Element => {
  const { t } = useTranslation()

  const statementText = t(
    "I have received an explanation of the above monitoring table and agree to its contents.",
  )
  const [tempOperationData, setTempOperationData] = useState({
    display_content: ["1", "2"],
    consent_date: [],
    parent_sign: [],
  })

  return (
    <MonitoringDetailOperationWrapper>
      <AccordionCard
        accordion={true}
        defaultActiveKey={["plan_detail"]}
        items={[
          {
            key: "plan_detail",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <LabelTextWithLeftArrow
                    label={t("Display contents")}
                    width={"138px"}
                  />
                  <div id={"facility__content__container"}>
                    <CheckBoxGroup
                      items={PLAN_DISPLAY_CONTENT_MONITORING || []}
                      value={tempOperationData?.display_content || []}
                      onChange={(list: any[]) => {
                        setTempOperationData({
                          ...tempOperationData,
                          display_content: list,
                        })
                      }}
                    />
                  </div>
                </div>
                {tempOperationData?.display_content?.includes("1") && (
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Consent date")}
                      width={"138px"}
                    />
                    <CheckBoxGroup
                      items={CONSENT_DATE_FIELDS}
                      value={tempOperationData?.consent_date || []}
                      onChange={(list: CheckboxValueType[]) => {
                        setTempOperationData({
                          ...tempOperationData,
                          consent_date: list,
                        })
                      }}
                    />
                  </div>
                )}

                <div className={"search__content"}>
                  <Button
                    btnText={t("Display Change")}
                    shape={"round"}
                    onClick={() =>
                      onDisplayChange({ ...tempOperationData, statementText })
                    }
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </MonitoringDetailOperationWrapper>
  )
}
