import { Button, InputField, Modal, theme } from "@project/common"
import { Flex, Typography, notification } from "antd"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { UseAssessmentSettings } from "../../context"
import { useMutation } from "react-query"
import { createOrUpdateAssessmentSetting } from "../../services"
import { AssessmentSelectedItem } from "../../types"
import { getAssessmentPayload } from "../../serialize"

export const AssessmentSettingsModal = ({
  open,
  onCancel,
  selectedItem,
  title,
  label,
  modalType = "confirm",
  confirmMessage,
  settingId,
}: {
  open: boolean
  onCancel: () => void
  selectedItem: AssessmentSelectedItem | null
  title: string
  label: string
  modalType: "confirm" | "normal"
  confirmMessage?: string
  settingId: number
}): JSX.Element => {
  const { t } = useTranslation()
  const { assessmentData, refetch } = UseAssessmentSettings()
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: createOrUpdateAssessmentSetting,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })
      onCancel()
      refetch()
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const handleSaveChange = ({
    key,
    value,
  }: {
    key: string
    value: boolean | string
  }) => {
    const categories = assessmentData?.find((asmt) => asmt?.id === settingId)
    if (selectedItem?.addItem) {
      if (selectedItem?.type === "category") {
        const newData = [
          ...categories?.assessment_category,
          {
            assessment_setting_id: settingId,
            id: new Date().getTime(),
            title: value,
            show_display: true,
            assessment_item: [],
            order: categories?.assessment_category?.length + 1,
          },
        ]
        const payload = getAssessmentPayload(newData)
        mutate({ values: payload, settingId })
      } else {
        const data = categories?.assessment_category?.map((category) =>
          category?.id === selectedItem.categoryId
            ? {
                ...category,
                assessment_item: [
                  ...category?.assessment_item,
                  {
                    id: new Date().getTime(),
                    title: value || "",
                    show_display: true,
                    order: category?.assessment_item?.length + 1,
                  },
                ],
              }
            : category,
        )
        const payload = getAssessmentPayload(data)
        mutate({ values: payload, settingId: settingId })
      }
    } else {
      const newData = categories?.assessment_category?.map((category) =>
        category?.id === selectedItem?.categoryId
          ? {
              ...category,
              [key]: !selectedItem?.itemId ? value : category[key],
              assessment_item: category?.assessment_item?.map((item) =>
                item?.id === selectedItem?.itemId
                  ? {
                      ...item,
                      [key]: value,
                    }
                  : item,
              ),
            }
          : { ...category },
      )
      const payload = getAssessmentPayload(newData)
      mutate({
        settingId: settingId,
        values: payload,
      })
    }
  }
  const { handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      title: selectedItem?.title || "",
    },
    onSubmit: (formValues) => {
      handleSaveChange({
        key: "title",
        value: formValues?.title,
      })
    },
  })
  return (
    <Modal
      maskClosable={false}
      title={
        modalType === "confirm" ? (
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
            }}
          >
            {title}
          </div>
        ) : (
          title
        )
      }
      headerBg={"white"}
      titleColor={theme.colors.text}
      headerBorderBottom={`1px solid ${theme.colors.border}`}
      borderRadius={"12px"}
      bodyPadding={"10px"}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      {modalType === "confirm" ? (
        <Flex vertical gap={4}>
          <Typography.Text
            style={{
              fontWeight: 400,
              color: theme.colors.gray3,
              fontSize: "14px",
            }}
          >
            {confirmMessage || ""}
          </Typography.Text>
          <br />
          <Flex justify={"flex-end"} gap={12} wrap={"wrap"}>
            <Button
              btnText={t(
                !selectedItem?.show_display
                  ? "You Want to Show"
                  : "You_Want_to_Hide",
              )}
              shape={"round"}
              onClick={() =>
                handleSaveChange({
                  key: "show_display",
                  value: selectedItem?.value,
                })
              }
              btnBg={theme.colors.error}
              textColor={theme.colors.white}
              borderColor={theme.colors.error}
              isLoading={isMutating}
              hoverBorderColor={theme.colors.error}
            />
            <Button btnText={t("Cancel")} shape={"round"} onClick={onCancel} />
          </Flex>
        </Flex>
      ) : (
        <form onSubmit={handleSubmit}>
          <InputField
            label={label}
            height={"40px"}
            placeholder={t("Please enter")}
            info={t("(100文字以内)")}
            value={values?.title || null}
            name={"title"}
            onChange={handleChange}
            maxLength={100}
          />
          <Flex justify={"flex-end"} gap={8} wrap={"wrap"}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={onCancel}
              disabled={isMutating}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              type={"primary"}
              htmlType={"submit"}
              isLoading={isMutating}
            />
          </Flex>
        </form>
      )}
    </Modal>
  )
}
