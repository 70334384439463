import { FormikErrors } from "formik"

export const setOperationInitialFormikValues = <T>(
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<T>>,
  values: T,
  keys: Array<keyof T> extends string[] ? Array<keyof T> : never,
) => {
  keys.forEach((key) => {
    if (values[key] === undefined || values[key] === null) {
      return
    }
    setFieldValue(key, values[key])
  })

  return values
}
