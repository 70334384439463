import React from "react"
import { BusinessDayManagementFrom } from "./BusinessDayManagementFrom"
import { BusinessDayRegistrationOperationOptions } from "./BusinessDayRegistrationOperationOptions"
import { useBusinessDayManagement } from "../../../context"

export const BusinessDayRegistration = (): JSX.Element => {
  const { listRef } = useBusinessDayManagement()
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
      }}
    >
      <BusinessDayRegistrationOperationOptions />
      <div ref={listRef}>
        <BusinessDayManagementFrom />
      </div>
    </div>
  )
}
