import React, { useRef } from "react"

// packages
import dayjs from "dayjs"
import { Flex, Popconfirm, notification } from "antd"
import { t } from "i18next"
import { FormikProps, useFormik } from "formik"

// commons | components
import {
  Card,
  Table,
  Button,
  CheckBox,
  ActionBtns,
  InputField,
  DayPagination,
  InputAreaField,
  STAFF_ABSENT,
  STAFF_PRESENT,
} from "@project/common"
import { WorkHourTdCell } from "./common"
import { AttendanceBulkEditTableWrap } from "./AttendanceBulkEditInternal.styles"

// types
import {
  WorkingHours,
  AttendanceBulkEditOperation,
  AttendanceBulkEditTableData,
  AttendanceBulkEditTableDataProps,
} from "../../../types/AttendanceBulkEdit.types"
import { ColumnsType } from "antd/es/table"

// constants
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import {
  createOrUpdateAttendanceStaffResult,
  getDailyAttendanceStaffResult,
} from "../../../services"
import { createId } from "@paralleldrive/cuid2"
import { STAFF__INITIAL_ATTENDANCE } from "../../../types/StaffAttendance.types"

// ! ## Main component
const AttendanceBulkEditTable = ({
  operationFormik,
  pageTitle,
  activeQuery,
}: {
  operationFormik: FormikProps<Partial<AttendanceBulkEditOperation>>
  pageTitle: string
  activeQuery: Partial<AttendanceBulkEditOperation>
}) => {
  const router = useRouter()
  const { push } = router
  const isLatestActionDelete = useRef(false)
  const {
    handleSubmit: handleOperationSubmit,
    setFieldValue: setOperationFieldValue,
  } = operationFormik

  // ! ## Formik form control
  const editFormik = useFormik<AttendanceBulkEditTableData>({
    initialValues: {
      individualRecord: {},
    },
    onSubmit: (values) => {
      const attendanceData = Object.values(values.individualRecord)

      const payload: Array<STAFF__INITIAL_ATTENDANCE> = attendanceData?.map(
        (data) => {
          const work1 = data?.working_hours?.work_info_1
          const work2 = data?.working_hours?.work_info_2
          return {
            id: data?.id,
            facility_id: data?.facility_id,
            staff_id: data?.staff_id,
            date: data?.date,
            remarks: data?.remarks,
            rest_minits_flg: data?.vacation_time?.vacation_flag,
            rest_hours: +data?.vacation_time?.vacation_time,
            attendance_type:
              work1?.isAbsent == null && work1?.isAbsent == undefined
                ? 0
                : work1?.isAbsent
                  ? STAFF_ABSENT
                  : STAFF_PRESENT,
            attendance_start_time: work1?.time_range?.start?.hour
              ? work1?.time_range?.start?.hour +
                ":" +
                (work1?.time_range?.start?.minute || "00")
              : null,
            attendance_end_time: work1?.time_range?.end?.hour
              ? work1?.time_range?.end?.hour +
                ":" +
                (work1?.time_range?.end?.minute || "00")
              : null,
            attendance_rest_minits: +work1?.break_time,
            attendance_type2:
              work2?.isAbsent == null && work2?.isAbsent == undefined
                ? 0
                : work2?.isAbsent
                  ? STAFF_ABSENT
                  : STAFF_PRESENT,
            attendance_start_time2: work2?.time_range?.start?.hour
              ? work2?.time_range?.start?.hour +
                ":" +
                (work2?.time_range?.start?.minute || "00")
              : null,
            attendance_end_time2: work2?.time_range?.end?.hour
              ? work2?.time_range?.end?.hour +
                ":" +
                (work2?.time_range?.end?.minute || "00")
              : null,
            attendance_rest_minits2: +work2?.break_time,
            is_delete: false,
          }
        },
      )

      postAttendanceStaffResult(payload)
    },
  })

  const { values, setFieldValue, handleSubmit } = editFormik

  const { isLoading: isUpdating, mutate: postAttendanceStaffResult } =
    useMutation({
      mutationFn: createOrUpdateAttendanceStaffResult,
      onSuccess: () => {
        notification.success({
          message: t("Updated Successfully"),
        })
        if (isLatestActionDelete.current) {
          isLatestActionDelete.current = false
          refetchAttendance()
        } else {
          router.push(
            `/attendance-record/day-list?facility_id=${
              activeQuery.facility_id
            }&date=${activeQuery.date?.format("YYYY-MM-DD")}`,
          )
        }
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const { isLoading, refetch: refetchAttendance } = useQuery({
    queryKey: ["attendance-record-daily", activeQuery],
    queryFn: () =>
      getDailyAttendanceStaffResult({
        date: activeQuery?.date?.format("YYYY-MM-DD"),
        facilityIds: activeQuery?.facility_id,
      }),
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const formattedData = {}
      res?.data?.attendance_data?.staff_results?.forEach((v, i) => {
        const uniqueKey = createId()

        const attendanceStartTime = v?.attendance_start_time
          ? v?.attendance_start_time?.split(":")
          : []
        const attendanceEndTime = v?.attendance_end_time
          ? v?.attendance_end_time?.split(":")
          : []

        const attendanceStartTime2 = v?.attendance_start_time2
          ? v?.attendance_start_time2?.split(":")
          : []
        const attendanceEndTime2 = v?.attendance_end_time2
          ? v?.attendance_end_time2?.split(":")
          : []

        const record = {
          key: uniqueKey,
          id: v?.id,
          facility_id: v?.facility_id || null,
          facility_name: v?.facility?.facility_name || "",
          staff_id: v?.staff_id || null,
          staff_name: v?.staff?.staff_name || "",
          date: v?.date
            ? v?.date?.toString()?.split("T")?.[0]
            : activeQuery.date?.format("YYYY-MM-DD"),
          remarks: v?.remarks || null,
          sn: i + 1,
          working_hours: {
            work_info_1: {
              isAbsent:
                !v?.id || !v?.attendance_type
                  ? null
                  : v?.attendance_type == STAFF_ABSENT,
              time_range: {
                start: {
                  hour: attendanceStartTime?.[0],
                  minute: attendanceStartTime?.[1],
                },
                end: {
                  hour: attendanceEndTime?.[0],
                  minute: attendanceEndTime?.[1],
                },
              },
              break_time: v?.attendance_rest_minits,
            },
            work_info_2: {
              isAbsent:
                !v?.id || !v?.attendance_type2
                  ? null
                  : v?.attendance_type2 == STAFF_ABSENT,
              time_range: {
                start: {
                  hour: attendanceStartTime2?.[0],
                  minute: attendanceStartTime2?.[1],
                },
                end: {
                  hour: attendanceEndTime2?.[0],
                  minute: attendanceEndTime2?.[1],
                },
              },
              break_time: v?.attendance_rest_minits,
              hasRecWorkInfo2: !!v?.attendance_type2,
            },
          },
          vacation_time: {
            vacation_flag: v?.rest_minits_flg,
            vacation_time: v?.rest_hours,
          },
        }
        formattedData[uniqueKey] = record
      })
      setFieldValue("individualRecord", formattedData)
    },
  })

  // ! ## Table columns
  const COLUMNS: ColumnsType<AttendanceBulkEditTableDataProps> = [
    {
      title: t("Day"),
      dataIndex: "sn",
      key: "day",
      width: 50,
      align: "center",
    },
    {
      title: t("Staff name"),
      dataIndex: "staff_name",
      key: "staff_name",
      width: 150,
      align: "center",
    },
    {
      title: t("Working hours"),
      dataIndex: "working_hours",
      key: "working_hours",
      align: "center",
      width: 695,
      render: (val: WorkingHours, record) => {
        return (
          <WorkHourTdCell
            formik={editFormik}
            responseWorkHour={val}
            uniqueKey={record.key}
          />
        )
      },
    },
    {
      title: t("Vacation time"),
      dataIndex: "vacation_time",
      key: "vacation_time",
      align: "center",
      width: 180,
      render: (
        val: AttendanceBulkEditTableDataProps["vacation_time"],
        record,
      ) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <CheckBox
              label={t("Set break time")}
              checked={val?.vacation_flag}
              style={{
                whiteSpace: "nowrap",
              }}
              onChange={(e) => {
                editFormik.setFieldValue(
                  `individualRecord.${record.key}.vacation_time.vacation_flag`,
                  e.target.checked,
                )
                if (!e.target.checked) {
                  editFormik.setFieldValue(
                    `individualRecord.${record.key}.vacation_time.vacation_time`,
                    "0",
                  )
                }
              }}
            />
            <Flex align={"center"} gap={"8px"}>
              <InputField
                value={val?.vacation_time}
                size={"large"}
                // width={"134px"}
                disabled={!val?.vacation_flag}
                onChange={(e) => {
                  editFormik.setFieldValue(
                    `individualRecord.${record.key}.vacation_time.vacation_time`,
                    e.target.value,
                  )
                }}
              />
              <span style={{ whiteSpace: "nowrap" }}>{t("Hr")}</span>
            </Flex>
          </div>
        )
      },
    },
    {
      title: t("Remarks"),
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      width: 150,
      render: (val: string, record) => {
        return (
          <InputAreaField
            value={val}
            rows={5}
            onChange={(e) => {
              editFormik.setFieldValue(
                `individualRecord.${record?.key}.remarks`,
                e.target.value,
              )
            }}
          />
        )
      },
    },
    {
      title: t("Delete"),
      key: "delete",
      align: "center",
      width: 80,
      render: (record) => {
        return (
          <div>
            <Popconfirm
              title={t("Deleting.Is that OK?")}
              okText={t("OK")}
              cancelText={t("Cancel")}
              okButtonProps={{ size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
              onConfirm={() => handleAttendanceDelete(record)}
            >
              <Button
                type={"link"}
                btnText={t("Delete")}
                className={"delete-btn"}
              />
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  const handleAttendanceDelete = (attendance) => {
    isLatestActionDelete.current = true
    const staffAttendanceToDelete: STAFF__INITIAL_ATTENDANCE = {
      id: attendance?.id,
      facility_id: attendance?.facility_id,
      staff_id: attendance?.staff_id,
      date: attendance?.date?.split("T")?.[0],
      is_delete: true,
    }
    postAttendanceStaffResult([staffAttendanceToDelete])
  }

  return (
    <AttendanceBulkEditTableWrap>
      <Card
        title={pageTitle}
        extra={
          <DayPagination
            currentDay={
              activeQuery.date ? activeQuery.date.format("YYYY-MM-DD") : ""
            }
            onDayChange={(day) => {
              setOperationFieldValue("date", dayjs(day))
              handleOperationSubmit()
            }}
          />
        }
        footer={
          <ActionBtns justify={"flex-start"}>
            <Button
              shape={"round"}
              btnText={t("Cancel")}
              onClick={() => {
                push(
                  `/attendance-record/day-list?date=${activeQuery.date?.format(
                    "YYYY-MM-DD",
                  )}&facility_id=${activeQuery.facility_id}`,
                )
              }}
            />
            <Button
              shape={"round"}
              type={"primary"}
              btnText={t("Save")}
              onClick={() => handleSubmit()}
              isLoading={isLoading || isUpdating}
            />
          </ActionBtns>
        }
      >
        <Table
          columns={COLUMNS}
          dataSource={Object.values(values.individualRecord)}
          scroll={{ x: "max-content" }}
          loading={isLoading || isUpdating}
        />
      </Card>
    </AttendanceBulkEditTableWrap>
  )
}

export default AttendanceBulkEditTable
