import { Button } from "@project/common"
import { Flex } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

export const CTAbtn = ({
  onClick,
  isLoading,
}: {
  onClick: (action: "cancel" | "save") => void
  isLoading: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Flex gap={8} wrap={"wrap"}>
      <Button
        btnText={t("Cancel")}
        shape={"round"}
        onClick={() => onClick("cancel")}
        disabled={isLoading}
      />
      <Button
        btnText={t("Save")}
        shape={"round"}
        type={"primary"}
        isLoading={isLoading}
        onClick={() => onClick("save")}
      />
    </Flex>
  )
}
