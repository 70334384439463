import styled from "styled-components"
export const MealMasterFormWrapper = styled.div`
  .meal__cost_input {
    display: flex;
    align-items: center;
    row-gap: 0.5em;
    column-gap: 0.8em;
    flex-wrap: wrap;
  }
`
