import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"

export const CheckedButtonContainer = styled.div`
  margin: 14px 0px;
`

export const DataListWrapper = styled.div`
  padding: 24px 30px;
  border: 1px solid ${theme.colors.error};
  color: ${theme.colors.info};
  border-radius: 12px;
  margin-bottom: 16px;

  .ant-collapse-expand-icon {
    .down-arrow-container {
      display: grid;
      transform: rotate(0deg);
      transition: all 150ms ease-in;
      transform-origin: center;
    }
    .down-arrow-container.active {
      transform: rotate(180deg);
    }
  }

  .billing-amount-data-list {
    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0;
      color: ${theme.colors.info};
      .ant-collapse-header-text {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      padding: 0;
      color: ${theme.colors.info};
    }
  }
`

export const Counter = styled(Flex)`
  border: 1px solid ${theme.colors.border};
  padding: 13px 10px;
  color: ${theme.colors.text};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  height: 36px;
  align-items: center;
`

export const QuoteTitle = styled.div`
  color: ${theme.colors.text};
  font-weight: 500;
  padding: 4px 16px;
  border-left: 2px solid ${theme.colors.action};
  display: inline-block;
`

export const UserUsingFacility = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  margin-bottom: 16px;
  width: 100%;
`
