import React from "react"
// packages
import { t } from "i18next"

// styles
import { ApprovalTable } from "../UserTransportMgmtPrint.styles"

export const Approval = () => {
  return (
    <ApprovalTable>
      <thead>
        <tr>
          <th rowSpan={2} className={"with-bg"}>
            {t("Approve column")}
          </th>
          <th>{t("Service manager")}</th>
          <th>{t("Facility manager")}</th>
          <th>{t("CEO")}</th>
        </tr>
        <tr>
          <th>{t("㊞")}</th>
          <th>{t("㊞")}</th>
          <th>{t("㊞")}</th>
        </tr>
        <tr>
          <th className={"with-bg"}>{t("Instructions")}</th>
          <th colSpan={4}></th>
        </tr>
      </thead>
    </ApprovalTable>
  )
}
