import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../context"
import { Dayjs } from "dayjs"
import { YM_FORMAT } from "../../constants"

export const ActualCostBurdenRecordOperation = ({
  params,
  setParams,
}: {
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [values, setValues] = useState({
    facility: params?.facility,
    date: params?.date,
  })
  useEffect(() => {
    setValues(params)
  }, [params])
  return (
    <AccordionCard
      defaultActiveKey={["actual-cost-burden-record"]}
      items={[
        {
          key: "actual-cost-burden-record",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Facility  ")}
                  width={"136px"}
                />
                <SelectInput
                  name={"facility"}
                  options={facilities || []}
                  value={values?.facility || null}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      facility: value,
                    })
                  }
                  placeholder={"--"}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year Month")}
                  width={"136px"}
                />
                <DatePicker
                  date={values?.date || null}
                  onDateChange={(date: Dayjs) =>
                    setValues({
                      ...values,
                      date,
                    })
                  }
                  format={YM_FORMAT}
                  picker={"month"}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  onClick={() =>
                    setParams({
                      ...values,
                    })
                  }
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
