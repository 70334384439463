import React, { useContext } from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { useFormik } from "formik"
import { Trans } from "react-i18next"
import { useQuery } from "react-query"

// components/commons
import {
  filterObject,
  removeBlankAttributes,
  useUpdateSearchParams,
  scrollToSelectedElement,
} from "@project/common"
import TreatmentImproveTableV2 from "./common/TreatmentImproveTableV2"
import { TreatmentImproveOperation } from "./TreatmentImproveOperation"

// styles
import { NoticeBox } from "./TreatmentImproveTable.styles"

// context
import { AuthContext } from "../../context"

// services
import { getTreatmentImprovementList } from "../../services"

// types
import { TreatmentImprovementOperation } from "../../types/TreatmentImproveTable.types"

export const TreatmentImprovementTable = () => {
  const { query } = useRouter()
  const tableRef = React.useRef<HTMLDivElement>(null)
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const [updateParams] = useUpdateSearchParams()

  const memoizedQuery: Partial<TreatmentImprovementOperation> =
    React.useMemo(() => {
      const queryConst = query as Partial<
        Record<keyof TreatmentImprovementOperation, string | string[]>
      >
      const facility_ids = queryConst?.facility_ids
        ? queryConst?.facility_ids?.toString()?.split(",")
        : facilitiesCtx?.map((facility) => facility?.id + "")

      const fiscal_year = queryConst?.fiscal_year
        ? queryConst?.fiscal_year?.toString()
        : dayjs().year() + ""

      return {
        facility_ids,
        fiscal_year,
      }
    }, [query])

  const {
    data: treatmentImprovementData,
    isLoading: loadingTreatmentImproveData,
  } = useQuery({
    queryKey: ["treatment-improvement-list", memoizedQuery],
    queryFn: () =>
      getTreatmentImprovementList(
        filterObject({
          year: memoizedQuery.fiscal_year,
          facility_ids: memoizedQuery.facility_ids
            ? memoizedQuery.facility_ids.join(",")
            : null,
        }),
      ),
    select: (resp) => resp.data,
    refetchOnWindowFocus: false,
  })

  const formik = useFormik<Partial<TreatmentImprovementOperation>>({
    initialValues: memoizedQuery,
    enableReinitialize: true,
    onSubmit: (values) => {
      const params = removeBlankAttributes({
        ...values,
        facility_ids: values.facility_ids?.join(","),
      })

      updateParams(
        { ...params },
        `/treatment-improvement-addition-amount-result-table`,
      )
      scrollToSelectedElement(tableRef)
    },
  })

  return (
    <div>
      <TreatmentImproveOperation formik={formik} />

      <NoticeBox>
        <Trans>
          <p>
            {t(
              "It is displayed based on the confirmed information in the NHI billing form/CSV output.",
            )}
          </p>
          {t(
            "If you want to check the monthly breakdown, you can check and change it from the link in the total amount column for each year and month.",
          )}
        </Trans>
      </NoticeBox>

      <div ref={tableRef}>
        <TreatmentImproveTableV2
          dataResponse={treatmentImprovementData}
          query={memoizedQuery}
          loadingTreatmentImproveData={loadingTreatmentImproveData}
        />
      </div>
    </div>
  )
}
