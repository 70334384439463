import { Card, Grid } from "@project/common"
import { Skeleton, Space, Typography } from "antd"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
import {
  DetailInfoContent,
  PlanDetailContentWrapper,
  PlanDetailWrapperContainer,
} from "./PlanDetailContent.style"

import { PlanOperationData } from "../../../types"
import { PlanContentFooter } from "./PlanContentFooter"
import { PlanContentGoals } from "./PlanContentGoals"
import { PlanDetailActionBtns } from "./PlanDetailActionBtns"
import { PlanDetailSummary } from "./PlanDetailSummary"
import PrintPage from "./PrintPage/PrintPage"
React.useLayoutEffect = React.useEffect
interface PlanDetailContentProps {
  details?: any
  isLoading?: boolean
  data?: {
    planType: any
    id: any
    create_count: any
    user_id: any
  }
  operationData: PlanOperationData
  scrollRef?: any
  hasEditAccess: boolean
}

export const PlanDetailContent = ({
  details,
  isLoading,
  operationData,
  data: { planType, id, create_count, user_id },
  scrollRef,
  hasEditAccess,
}: PlanDetailContentProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()

  const name = details?.userSupport?.user_info?.username
  const url =
    planType === "draft"
      ? `/draft-individual-support-plan/edit/${id}?user_id=${user_id}&create_count=${create_count}`
      : `/individual-support-plan/edit/${id}?user_id=${user_id}&create_count=${create_count}`

  const cardTitle = t(
    planType === "draft"
      ? "{{user_name}}'s Individual support plan (draft) details"
      : "{{user_name}}'s individual support plan details",
    {
      user_name: name,
    },
  )
  //handle print start
  const contentRef = useRef<HTMLDivElement>(null)
  const [isPrinting, setIsPrinting] = useState(false)
  const promiseResolveRef = useRef(null)
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
    pageStyle: `
      @page{
        size: ${
          operationData?.print_settings?.includes("1")
            ? "landscape"
            : "portrait"
        };
        margin:5mm;
      }
    `,
  })
  //handle print end

  return (
    <>
      <PlanDetailWrapperContainer>
        <Card
          boxShadow={"2px 2px 6px 0px rgba(0, 0, 0, 0.10)"}
          bordered
          title={cardTitle}
        >
          {isLoading ? (
            <Space direction={"vertical"} style={{ width: "100%" }} size={16}>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          ) : (
            <PlanDetailContentWrapper>
              <PlanDetailActionBtns
                isLoading={isLoading}
                planType={planType}
                hideEditBtn
                onClick={(btnType) => {
                  if (btnType === "back") {
                    router.back()
                  }
                  if (btnType === "print") {
                    handlePrint()
                  }
                  if (btnType === "edit") {
                    router.push(url)
                  }
                }}
                hasEditAccess={hasEditAccess}
                hasPDF={false}
              />

              <PlanDetailSummary defaultData={details?.userSupport} />
              {!details?.userSupport?.user_family_hope &&
              !details?.userSupport?.support_policy &&
              !details?.userSupport?.long_term_goal &&
              !details?.userSupport?.short_term_goal &&
              !details?.userSupport?.goto_meet_flag &&
              !details?.userSupport?.take_flag ? null : (
                <DetailInfoContent>
                  <Grid
                    labelContent={t("Intention of the person/family")}
                    background
                    labelSpan={6}
                  >
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {details?.userSupport?.user_family_hope}
                    </Typography.Text>
                  </Grid>
                  <Grid
                    labelContent={t("Comprehensive support policy")}
                    background
                    labelSpan={6}
                  >
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {details?.userSupport?.support_policy}
                    </Typography.Text>
                  </Grid>
                  <Grid
                    labelContent={t("Long term goal")}
                    background
                    labelSpan={6}
                  >
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {details?.userSupport?.long_term_goal}
                    </Typography.Text>
                  </Grid>
                  <Grid
                    labelContent={t("Short term goal")}
                    background
                    labelSpan={6}
                  >
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {details?.userSupport?.short_term_goal}
                    </Typography.Text>
                  </Grid>
                  <Grid
                    labelContent={t("Transportation")}
                    background
                    labelSpan={6}
                  >
                    <div id={"transportation_info"}>
                      <Typography.Text>
                        {t("Pick up")}
                        {": "}
                        {details?.userSupport?.goto_meet_flag
                          ? details?.userSupport?.goto_meet_place
                          : t("None")}
                      </Typography.Text>
                      <Typography.Text>
                        {t("Sending")}
                        {": "}
                        {details?.userSupport?.take_flag
                          ? details?.userSupport?.take_place
                          : t("None")}
                      </Typography.Text>
                    </div>
                  </Grid>
                  <Grid labelContent={t("Remarks")} background labelSpan={6}>
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {details?.userSupport?.remarks}
                    </Typography.Text>
                  </Grid>
                </DetailInfoContent>
              )}
              <div className={"content__table"}>
                <PlanContentGoals
                  defaultData={details?.userSupportItems || []}
                />
              </div>
              <div ref={scrollRef} id={"plan__content__footer"}>
                <PlanContentFooter
                  defaultData={details}
                  operationData={operationData}
                  planType={planType}
                  planId={id}
                />
              </div>

              <PlanDetailActionBtns
                isLoading={isLoading}
                planType={planType}
                hideInfo
                onClick={(btnType) => {
                  if (btnType === "back") {
                    router.back()
                  }
                  if (btnType === "print") {
                    handlePrint()
                  }
                  if (btnType === "edit") {
                    router.push(url)
                  }
                }}
                hasEditAccess={hasEditAccess}
                hasPDF={false}
              />
            </PlanDetailContentWrapper>
          )}
        </Card>
      </PlanDetailWrapperContainer>
      {isPrinting && (
        <div style={{ display: "none" }}>
          <PrintPage
            title={cardTitle}
            data={details?.userSupport}
            ref={contentRef}
            goals={details?.userSupportItems || []}
            operationData={operationData}
          />
        </div>
      )}
    </>
  )
}
