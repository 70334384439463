import { Button, theme } from "@project/common"
import React from "react"
import { PlanType } from "../../../types"
import { useTranslation } from "react-i18next"

export const PlanDetailActionBtns = ({
  isLoading,
  onClick,
  planType,
  hideEditBtn = false,
  hasEditAccess,
  hasPDF = true,
}: {
  planType: PlanType
  onClick: (type: "back" | "print" | "pdf" | "edit") => void
  isLoading?: boolean
  hideEditBtn?: boolean
  hideInfo?: boolean
  hasEditAccess: boolean
  hasPDF?: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <div
        style={{
          display: "flex",
          columnGap: "16px",
          rowGap: "16px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "16px",
            rowGap: "16px",
            flexWrap: "wrap",
          }}
        >
          <Button
            btnText={t("<< Back")}
            shape={"round"}
            disabled={isLoading}
            onClick={() => onClick("back")}
            maxHeight={"36px"}
          />
          {hasPDF && (
            <Button
              btnText={t(t("Download PDF"))}
              shape={"round"}
              type={"primary"}
              disabled={isLoading}
              iconType={"pdf"}
              onClick={() => onClick("pdf")}
              maxHeight={"36px"}
            />
          )}
          <Button
            btnText={t("Print")}
            type={"primary"}
            shape={"round"}
            iconType={"print"}
            disabled={isLoading}
            onClick={() => onClick("print")}
            maxHeight={"36px"}
          />{" "}
        </div>
        {!hideEditBtn && (
          <Button
            btnText={
              planType === "draft"
                ? t("Edit individual support plan (draft)")
                : t("Edit individual support plan")
            }
            textColor={theme.colors.text}
            shape={"round"}
            iconType={"blue-pencil"}
            disabled={!hasEditAccess || isLoading}
            onClick={() => onClick("edit")}
            btnBg={"#EAF9FF"}
            maxHeight={"36px"}
          />
        )}
      </div>
    </div>
  )
}
