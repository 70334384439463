import { I_EMPLOYMENT_BUSINESS_STATEMENT } from "../../../types"
import { EMPLOYMENT_BUSINESS_STATEMENT_INITIAL_DATA } from "./constant"

export const getColsFacilityColsTotal = (
  columns: I_EMPLOYMENT_BUSINESS_STATEMENT[],
) => {
  return columns?.reduce(
    (acc, curr) => {
      return {
        term_war_material_inventory:
          acc?.term_war_material_inventory +
            curr?.term_war_material_inventory || 0,
        fine_year_material_purchase_height:
          acc?.fine_year_material_purchase_height +
            curr?.fine_year_material_purchase_height || 0,
        involved_in_terms_of_term:
          acc?.involved_in_terms_of_term + curr?.involved_in_terms_of_term || 0,
        user_wage: acc?.user_wage + curr?.user_wage || 0,
        user_fee: acc?.user_fee + curr?.user_fee || 0,
        salary_of_employment_support_business_instructors:
          acc?.salary_of_employment_support_business_instructors +
            curr?.salary_of_employment_support_business_instructors || 0,
        instructor_bonus_reward:
          acc?.instructor_bonus_reward + curr?.instructor_bonus_reward || 0,
        instructor_retirement_benefit_cost:
          acc?.instructor_retirement_benefit_cost +
            curr?.instructor_retirement_benefit_cost || 0,
        legal_welfare_expenses:
          acc?.legal_welfare_expenses + curr?.legal_welfare_expenses || 0,
        former_outsourcing_cost:
          acc?.former_outsourcing_cost + curr?.former_outsourcing_cost || 0,
        welfare_expense: acc?.welfare_expense + curr?.welfare_expense || 0,
        travel_expense: acc?.travel_expense + curr?.travel_expense || 0,
        equipment_cost: acc?.equipment_cost + curr?.equipment_cost || 0,
        supplies_expense: acc?.supplies_expense + curr?.supplies_expense || 0,
        printed_book_expense:
          acc?.printed_book_expense + curr?.printed_book_expense || 0,
        water_supply_expense:
          acc?.water_supply_expense + curr?.water_supply_expense || 0,
        fuel_cost: acc?.fuel_cost + curr?.fuel_cost || 0,
        repair_cost: acc?.repair_cost + curr?.repair_cost || 0,
        communication_transportation_cost:
          acc?.communication_transportation_cost +
            curr?.communication_transportation_cost || 0,
        order_activity_expense:
          acc?.order_activity_expense + curr?.order_activity_expense || 0,
        meeting_expense: acc?.meeting_expense + curr?.meeting_expense || 0,
        non_life_insurance_premium:
          acc?.non_life_insurance_premium + curr?.non_life_insurance_premium ||
          0,
        rent: acc?.rent + curr?.rent || 0,
        books_and_educational_expenses:
          acc?.books_and_educational_expenses +
            curr?.books_and_educational_expenses || 0,
        taxation_public_division:
          acc?.taxation_public_division + curr?.taxation_public_division || 0,
        depreciation_expense:
          acc?.depreciation_expense + curr?.depreciation_expense || 0,
        special_reserve_depreciation:
          acc?.special_reserve_depreciation +
            curr?.special_reserve_depreciation || 0,
        uncollectible_reserves:
          acc?.uncollectible_reserves + curr?.uncollectible_reserves || 0,
        uncollectible_amount:
          acc?.uncollectible_amount + curr?.uncollectible_amount || 0,
        miscellaneous_expenses:
          acc?.miscellaneous_expenses + curr?.miscellaneous_expenses || 0,
        expense: acc?.expense + curr?.expense || 0,
        manufacturing_cost:
          acc?.manufacturing_cost + curr?.manufacturing_cost || 0,
        table_based_product_wholesaler:
          acc?.table_based_product_wholesaler +
            curr?.table_based_product_wholesaler || 0,
        terminated_goods_wholesaler:
          acc?.terminated_goods_wholesaler +
            curr?.terminated_goods_wholesaler || 0,
      }
    },
    { ...EMPLOYMENT_BUSINESS_STATEMENT_INITIAL_DATA },
  )
}
//ebss- Employment support business statement
export const ebssRowsTotal = (item: I_EMPLOYMENT_BUSINESS_STATEMENT) => {
  const material_cost_meter_total =
    item?.term_war_material_inventory + item?.fine_year_material_purchase_height

  const material_cost_total =
    material_cost_meter_total - item?.involved_in_terms_of_term || 0

  const labor_expenses_total =
    item?.user_wage +
    item?.user_fee +
    item?.salary_of_employment_support_business_instructors +
    item?.instructor_bonus_reward +
    item?.instructor_retirement_benefit_cost +
    item?.legal_welfare_expenses

  const expenses_total =
    item?.welfare_expense +
    item?.travel_expense +
    item?.equipment_cost +
    item?.supplies_expense +
    item?.printed_book_expense +
    item?.fuel_cost +
    item?.repair_cost +
    item?.communication_transportation_cost +
    item?.order_activity_expense +
    item?.meeting_expense +
    item?.non_life_insurance_premium +
    item?.rent +
    item?.books_and_educational_expenses +
    item?.taxation_public_division +
    item?.depreciation_expense +
    item?.special_reserve_depreciation +
    item?.uncollectible_reserves +
    item?.uncollectible_amount +
    item?.miscellaneous_expenses
  const manufacturing_cost =
    material_cost_total +
    labor_expenses_total +
    item?.former_outsourcing_cost +
    expenses_total
  const grand_total =
    manufacturing_cost - item?.table_based_product_wholesaler || 0
  const project_expenses_total =
    grand_total - item?.terminated_goods_wholesaler || 0
  return {
    material_cost_meter_total,
    material_cost_total,
    labor_expenses_total,
    expenses_total,
    project_expenses_total,
    manufacturing_cost,
    grand_total,
  }
}
export const ESBSSharedCell = (_, index) => {
  if (index === 0 || index === 6 || index === 14 || index === 16) {
    return {
      colSpan: 0,
    }
  }
  return {}
}
export const handleFirstColSpaning = (index: number, dataLength: number) => {
  if (index === 0 || index === 6 || index === 14 || index === 16) {
    return {
      colSpan: dataLength + 3 || 3,
    }
  }
  if (
    index === 3 ||
    index === 5 ||
    index === 6 ||
    index === 13 ||
    index >= 37
  ) {
    return {
      colSpan: 2,
    }
  }
  return {}
}

export const handleSecondColSpaning = (_, index) => {
  if (
    index === 0 ||
    index === 3 ||
    index === 5 ||
    index === 6 ||
    index === 13 ||
    index === 14 ||
    index === 16 ||
    index >= 37
  ) {
    return {
      colSpan: 0,
    }
  }

  return {}
}
export const esbsGrandTotal = (
  data: I_EMPLOYMENT_BUSINESS_STATEMENT[],
  row: any,
) => {
  const totalData = {
    material_cost_meter_total: 0,
    material_cost_total: 0,
    labor_expenses_total: 0,
    expenses_total: 0,
    project_expenses_total: 0,
  }
  data?.map((v) => {
    const d = ebssRowsTotal(v)
    totalData[row?.key] = totalData[row?.key] + d[row?.key]
  })
  return totalData
}
