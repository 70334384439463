import {
  AccordionCard,
  Button,
  CheckBox,
  CheckBoxGroup,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import { Flex, Typography } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CREATE_STATUS, FuriganaAlphabetsOption } from "../../constants"
import { useAuthContext } from "../../context"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { AssessmentOperationOptions } from "../../types"
import { filterWithHiragana } from "../../utils"

export const AssessmentOperation = ({
  params,
  setParams,
}: {
  params: AssessmentOperationOptions
  setParams: React.Dispatch<React.SetStateAction<AssessmentOperationOptions>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [values, setValues] = useState({
    user_id: params?.user_id || null,
    facilityIds: params?.facilityIds || [],
    from_date: params?.from_date || null,
    to_date: params?.to_date || null,
    display_setting: params?.display_setting || null,
    status: params?.status || [],
    furigana: "",
  })
  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })
  useEffect(() => {
    setValues({
      furigana: "",
      ...params,
    })
  }, [params])

  return (
    <AccordionCard
      defaultActiveKey={["AccordionCard"]}
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User Name")}
                  width={"136px"}
                />
                <Flex gap={8} wrap={"wrap"} className={"w-full"}>
                  <SelectInput
                    name={"furigana"}
                    placeholder={"--"}
                    width={"80px"}
                    shortSelector
                    value={values?.furigana || null}
                    options={FuriganaAlphabetsOption || []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        furigana: val,
                        user_id: null,
                      })
                    }
                  />
                  <SelectInput
                    name={"user_id"}
                    placeholder={"--"}
                    options={
                      filterWithHiragana({
                        options: userData,
                        furigana: values?.furigana || "",
                      }) || []
                    }
                    value={values?.user_id || null}
                    width={"308px"}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        user_id: val,
                      })
                    }}
                  />
                </Flex>
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility")}
                    width={"136px"}
                  />
                </div>

                <FacilityCheckbox
                  value={values?.facilityIds || []}
                  options={(facilities as any) || []}
                  onChange={(list: CheckboxValueType[]) =>
                    setValues({
                      ...values,
                      facilityIds: list,
                    })
                  }
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setValues({
                        ...values,
                        facilityIds: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        facilityIds: [],
                      })
                    }
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Period")} width={"136px"} />
                <Flex gap={8} wrap={"wrap"} align={"center"}>
                  <DatePicker
                    date={values?.from_date ? dayjs(values?.from_date) : null}
                    placeholder={"--"}
                    onDateChange={(date: Dayjs) =>
                      setValues({
                        ...values,
                        from_date: date,
                      })
                    }
                  />
                  <Typography.Text>{"~"}</Typography.Text>
                  <DatePicker
                    date={values?.to_date ? dayjs(values?.to_date) : null}
                    placeholder={"--"}
                    onDateChange={(date: Dayjs) =>
                      setValues({
                        ...values,
                        to_date: date,
                      })
                    }
                  />
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Display settings")}
                  width={"136px"}
                />
                <CheckBox
                  label={t("Show only with digital signature blank")}
                  value={values?.display_setting || null}
                  checked={values?.display_setting}
                  onChange={({ target: { checked } }) => {
                    setValues({
                      ...values,
                      display_setting: checked ? 1 : null,
                    })
                  }}
                />
              </div>
              <div className={"search__content "}>
                <LabelTextWithLeftArrow
                  label={t("Create status")}
                  width={"136px"}
                />
                <CheckBoxGroup
                  items={CREATE_STATUS || []}
                  value={values?.status}
                  onChange={(list) =>
                    setValues({
                      ...values,
                      status: list,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <Button
                  shape={"round"}
                  btnText={t("Reset Search")}
                  onClick={() =>
                    setParams({
                      user_id: null,
                      facilityIds: [],
                      display_setting: null,
                      from_date: null,
                      to_date: null,
                      status: [],
                      mode: "reset",
                    })
                  }
                />
                <Button
                  shape={"round"}
                  btnText={t("Search")}
                  type={"primary"}
                  iconType={"search"}
                  onClick={() => setParams({ ...values, mode: "search" })}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
