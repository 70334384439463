import React from "react"

// packages
import { Card, Skeleton } from "antd"
import styled from "styled-components"

// styles
const LoadingNotiWrap = styled.div`
  display: grid;
  gap: 16px;
  .loading-noti-card .ant-card-body {
    .title-skeleton {
      margin-bottom: 1px;
    }
  }
`

export const LoadingNotification = (
  props: React.HTMLAttributes<HTMLDivElement>,
) => {
  return (
    <LoadingNotiWrap {...props}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Card key={index} className={"loading-noti-card"}>
          <Skeleton.Input
            block
            className={"title-skeleton"}
            size={"small"}
            active
          />
          <Skeleton.Input size={"small"} active />
        </Card>
      ))}
    </LoadingNotiWrap>
  )
}
