import {
  Card,
  DatePicker,
  ErrorMassage,
  FACILITY_TYPES,
  Grid,
  InputAreaField,
  InputField,
  RadioGroup,
  SelectInput,
  allowOnlyNumber,
  dynamicLangString,
  scrollToFirstErrorField,
  useNotification,
} from "@project/common"
import { Spin, Typography } from "antd"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { BLANK_PLAN_ITEMS, TIME_INTERVALS } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import {
  addNewPlan,
  deleteUserPlanById,
  fetchInstructors,
  getOneUserPlanByUserId,
  updatePlan,
} from "../../services"
import { PlanFormFields, PlanFormSchema, PlanType } from "../../types"
import { filterWithHiragana } from "../../utils"
import { FuriganaAlphabetsOption } from "../../utils/common-options"
import {
  FormInputContainer,
  PlanFormWrapper,
  PlanGoalContainer,
} from "./PlanForm.style"
import { PlanFormBtnAction } from "./PlanFormBtnAction"
import { PlanGoalContent } from "./PlanGoalContent"
import { PlanWeeklySchedule } from "./PlanWeeklySchedule"
const initialDate = `${dayjs().format("YYYY-MM-DDTHH:mm:ssZ")}`
interface PlanFormProps {
  link?: string
  planType?: PlanType
  action?: "edit" | "add" | "copy"
}

export const PlanForm = ({
  link,
  planType,
  action,
}: PlanFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id: plan_id, user_id, create_count, mode } = router.query as any
  const { facilities } = useContext(AuthContext)
  const { showToast } = useNotification()
  const { userData, isLoading: isUserLoading } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  const [createCount, setCreateCount] = useState(null)
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false)

  const initialValues: any = {
    user_id: user_id || "",
    recipient_number: null,
    facility_id: null,
    facility_admin: null,
    use_service_id: null,
    create_count: null,
    agree_date: dayjs(initialDate),
    start_date: dayjs(initialDate),
    end_date: dayjs(dayjs(initialDate)).add(6, "M").subtract(1, "d"),
    staff_id: null,
    support_pran_draft_type: true,
    user_family_hope: "",
    support_policy: "",
    long_term_goal: "",
    short_term_goal: "",
    goto_meet_flag: "1",
    goto_meet_place: "",
    take_flag: "1",
    take_place: "",
    remarks: "",
    isDraft: false,
    furigana: "",
  }
  //mutate support plan start
  const SUCCESS_MSG =
    planType === "non-draft"
      ? t("Individual support planning")
      : t("Individual support planning (draft)")
  const { isLoading: isAdding, mutate } = useMutation({
    mutationFn: addNewPlan,
    onSuccess: () => {
      showToast({
        type: "success",
        message: dynamicLangString([SUCCESS_MSG, "Created Successfully"]),
      })
      router.push(link)
    },
    onError: (err: any) => {
      const message =
        err?.data?.error ==
        "User does not have permission to access the resource"
          ? t("User doesn't have permission to create this page")
          : t("Something went wrong. Please contact administrator")
      showToast({ type: "error", message })
    },
  })
  const { isLoading: isUpdating, mutate: updateSupportPlan } = useMutation({
    mutationFn: updatePlan,
    onSuccess: () => {
      showToast({
        type: "success",
        message: dynamicLangString([SUCCESS_MSG, "Updated Successfully"]),
      })
      router.push(link)
    },
    onError: (err: any) => {
      const message =
        err?.data?.error ==
        "User does not have permission to access the resource"
          ? t("User doesn't have permission to create this page")
          : t("Something went wrong. Please contact administrator")
      showToast({ type: "error", message })
    },
  })
  const { isLoading: isDeleting, mutate: deleteData } = useMutation({
    mutationFn: deleteUserPlanById,
    onSuccess: () => {
      showToast({
        type: "success",
        message: dynamicLangString([SUCCESS_MSG, "Deleted Successfully"]),
      })
      router.push(link)
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  //mutate support plan end

  // plan goal content
  const [dataSource, setDataSource] = useState<any>(BLANK_PLAN_ITEMS)
  const [weeklyScheduleDataSource, setWeeklyScheduleDataSource] = useState([])
  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitCount,
    isValid,
    resetForm,
    setValues,
  } = useFormik<PlanFormFields>({
    initialValues: initialValues,
    validationSchema: PlanFormSchema,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      const _weeklyScheduleDataSource = weeklyScheduleDataSource.map((each) => {
        const _each = { ...each }
        delete _each.id
        delete _each?.sIndex
        delete _each?.eIndex
        return _each
      })
      const _plan_items = dataSource
        ?.filter(
          (val, i) =>
            val?.title ||
            val?.achievement_time ||
            (val?.achievement_goals?.length > 0 &&
              val?.achievement_goals[i]?.title) ||
            val?.support_and_considerations,
        )
        ?.map((each) => {
          const _each = { ...each }
          const achievement_goals = []
          _each.achievement_goals?.forEach((item) => {
            achievement_goals.push(item.title)
          })
          const support_and_considerations = []
          _each.support_and_considerations?.forEach((item) => {
            support_and_considerations.push(item.title)
          })
          const achievement_time = []
          _each.achievement_time?.forEach((item) => {
            achievement_time.push(item.title)
          })
          delete _each.id
          return {
            ..._each,
            achievement_goals,
            support_and_considerations,
            achievement_time,
          }
        })
      const _requestBody = {
        ...formValues,
        id: plan_id,
        staff_id: +values?.facility_admin,
        facility_id: +formValues?.facility_id,
        user_id: +formValues?.user_id,
        start_date: formValues?.start_date
          ? dayjs(formValues?.start_date).format("YYYY-MM-DD") + "T00:00:00Z"
          : null,
        agree_date: formValues?.agree_date
          ? dayjs(formValues?.agree_date).format("YYYY-MM-DD") + "T00:00:00Z"
          : null,
        end_date: formValues?.end_date
          ? dayjs(formValues?.end_date).format("YYYY-MM-DD") + "T00:00:00Z"
          : null,
        report_create_date: formValues?.agree_date
          ? dayjs(formValues?.agree_date).format("YYYY-MM-DD") + "T00:00:00Z"
          : null,
        goto_meet_flag: formValues?.goto_meet_flag === "1" ? true : false,
        take_flag: formValues?.take_flag === "1" ? true : false,
        draft_save_flag: formValues?.isDraft,
        weekly_schedules: _weeklyScheduleDataSource,
        plan_items: _plan_items,
        create_count: parseInt(formValues?.create_count.toString(), 10) || 1,
      }

      if (action === "add" || action === "copy") {
        mutate({
          values: _requestBody,
          type: planType,
        })
      }
      if (action === "edit" && user_id) {
        updateSupportPlan({
          values: _requestBody,
          type: planType,
          create_count,
        })
      }
    },
  })

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  const currentUser = userData?.find(
    (v) => +v?.value === (+user_id || +values?.user_id),
  )
  const updateInitialData = (res: any) => {
    const randomIds = [Math.random()]
    const DRAFT_ITEMS = res?.userSupportItems?.map((each: any) => {
      const achievement_goals = each?.support_content?.achievement_goals
        ? each?.support_content?.achievement_goals?.map((goal: any, index) => {
            if (index > randomIds?.length - 1) {
              randomIds.push(Math.random())
            }
            return {
              id: randomIds[index],
              title: goal,
            }
          })
        : [{ id: randomIds[0], title: "" }]
      const support_and_considerations = each?.support_content
        ?.support_and_considerations
        ? each?.support_content?.support_and_considerations?.map(
            (goal: any, index) => {
              return {
                id: randomIds[index],
                title: goal,
              }
            },
          )
        : [{ id: randomIds[0], title: "" }]
      const achievement_time = each?.support_content?.achievement_time
        ? each?.support_content?.achievement_time?.map((goal: any, index) => {
            return {
              id: randomIds[index],
              title: goal,
            }
          })
        : [{ id: randomIds[0], title: "" }]
      return {
        ...each,
        ...each?.support_content,
        achievement_goals,
        support_and_considerations,
        achievement_time,
      }
    })
    const DRAFT_SCHEDULES = res?.userSupportSchedule?.map((each) => {
      return {
        id: each?.id,
        week_no: each?.week_no,
        start_time: each?.start_time,
        end_time: each?.end_time,
        comment: each?.comment,
        sIndex: TIME_INTERVALS?.findIndex(
          (val) => val?.value === each?.start_time,
        ),
        eIndex: TIME_INTERVALS?.findIndex(
          (val) => val?.value === each?.end_time,
        ),
      }
    })
    const numberOfItems =
      action === "add" && create_count
        ? create_count
        : action === "copy" || action === "add"
          ? res?.number_of_create_count?.length > 0
            ? res?.number_of_create_count[0] + 1
            : create_count || null
          : res?.userSupport?.create_count || 1
    setWeeklyScheduleDataSource(DRAFT_SCHEDULES || [])
    setDataSource(DRAFT_ITEMS?.length > 0 ? DRAFT_ITEMS : BLANK_PLAN_ITEMS)
    setFieldValue(
      "recipient_number",
      currentUser?.certificate ||
        res?.userSupport?.user_info?.user_receiving_certificate?.[0]
          ?.receiving_certificate_number ||
        null,
    )
    setFieldValue(
      "facility_id",
      currentUser?.facility_id || res?.userSupport?.facility_id,
    )

    setFieldValue(
      "facility_admin",
      res?.userSupport?.staff_id ? `${res?.userSupport?.staff_id}` : null,
    )
    setFieldValue("create_count", createCount || numberOfItems || 1)
    setFieldValue(
      "staff_id",
      res?.userSupport?.staff_id ? `${res?.userSupport?.staff_id}` : null,
    )
    setFieldValue(
      "use_service_id",
      currentUser?.use_service_id || res?.userSupport?.use_service_id,
    )
    setFieldValue(
      "support_pran_draft_type",
      res?.userSupport?.support_pran_draft_type,
    )
    setFieldValue("user_family_hope", res?.userSupport?.user_family_hope)
    setFieldValue("support_policy", res?.userSupport?.support_policy)
    setFieldValue("long_term_goal", res?.userSupport?.long_term_goal)
    setFieldValue("short_term_goal", res?.userSupport?.short_term_goal)
    setFieldValue(
      "goto_meet_flag",
      res?.userSupport?.goto_meet_flag ? "1" : "0",
    )
    setFieldValue("goto_meet_place", res?.userSupport?.goto_meet_place)
    setFieldValue("take_flag", res?.userSupport?.take_flag ? "1" : "0")
    setFieldValue("take_place", res?.userSupport?.take_place)
    setFieldValue("remarks", res?.userSupport?.remarks)
    const currentNo = createCount
    if (res?.number_of_create_count?.includes(currentNo)) {
      setIsAlreadyCreated(true)
    } else {
      setIsAlreadyCreated(false)
    }
    setValues({
      ...values,
      recipient_number:
        currentUser?.certificate ||
        res?.userSupport?.user_info?.user_receiving_certificate?.[0]
          ?.receiving_certificate_number ||
        null,
      facility_id: currentUser?.facility_id || res?.userSupport?.facility_id,
      facility_admin: res?.userSupport?.staff_id
        ? `${res?.userSupport?.staff_id}`
        : null,
      create_count: createCount || numberOfItems || 1,
      staff_id: res?.userSupport?.staff_id
        ? `${res?.userSupport?.staff_id}`
        : null,
      use_service_id:
        currentUser?.use_service_id || res?.userSupport?.use_service_id,
      support_pran_draft_type: res?.userSupport?.support_pran_draft_type,
      user_family_hope: res?.userSupport?.user_family_hope,
      support_policy: res?.userSupport?.support_policy,
      long_term_goal: res?.userSupport?.long_term_goal,
      short_term_goal: res?.userSupport?.short_term_goal,
      goto_meet_flag: res?.userSupport?.goto_meet_flag ? "1" : "0",
      goto_meet_place: res?.userSupport?.goto_meet_place,
      take_flag: res?.userSupport?.take_flag ? "1" : "0",
      take_place: res?.userSupport?.take_place,
      remarks: res?.userSupport?.remarks,
      agree_date:
        action === "edit"
          ? res?.userSupport?.report_create_date
            ? dayjs(res?.userSupport?.report_create_date)
            : dayjs(initialDate)
          : values?.agree_date,
      start_date:
        action === "edit"
          ? res?.userSupport?.start_date
            ? dayjs(res?.userSupport?.start_date)
            : dayjs(initialDate)
          : values?.start_date,
      end_date:
        action === "edit"
          ? res?.userSupport?.end_date
            ? dayjs(res?.userSupport?.end_date)
            : dayjs(initialDate).add(6, "M")
          : values?.end_date,
    })
  }
  //fetch data start
  const { isLoading, data: supportPlanData } = useQuery({
    queryKey: [
      "getIndividualDraftByChildId",
      plan_id,
      planType,
      values?.user_id,
      createCount || create_count,
    ],
    queryFn: () =>
      getOneUserPlanByUserId({
        plan_id,
        user_id: +values?.user_id,
        create_count:
          createCount || mode === "copy" ? create_count - 1 : create_count,
        type: planType,
        operation: action,
      }),
    select: (res) => {
      return res?.data
    },
    onSuccess: (res: any) => {
      updateInitialData(res)
      return res
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
      setDataSource([
        {
          id: new Date().getTime(),
          title: "",
          achievement_goals: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
          support_and_considerations: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
          achievement_time: [
            {
              id: new Date().getTime(),
              title: "",
            },
          ],
        },
      ])
      setWeeklyScheduleDataSource([])
      resetForm()
    },
    enabled: (action === "add" && !!values?.user_id) || !!values?.user_id,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  })

  const { isLoading: isStaffLoading, data: staff } = useQuery({
    queryKey: ["staffs", values?.facility_id],
    queryFn: () =>
      fetchInstructors({
        page: 1,
        pageSize: "Infinity",
      }),
    select: (res) => {
      return res?.data?.map((v) => ({
        label: v?.staff_name,
        value: `${v?.id}`,
      }))
    },
    enabled: values?.facility_id ? true : false,
    refetchOnWindowFocus: false,
  })
  //fetch data end
  const handleNoOfCreateChange = (value: number) => {
    if (action === "edit" || action === "copy") {
      if (supportPlanData?.number_of_create_count?.includes(value)) {
        setIsAlreadyCreated(true)
      } else {
        setIsAlreadyCreated(false)
      }
    } else {
      setCreateCount(value)
    }
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <PlanFormWrapper>
          <PlanFormBtnAction
            handleCancel={() => router.push(link)}
            handleSaveDraft={() => {
              setFieldValue("isDraft", true)
              handleSubmit()
            }}
            handleSave={() => {
              setFieldValue("isDraft", false)
              handleSubmit()
            }}
            handleDelete={() => {
              deleteData({
                id: +plan_id,
                type: planType,
              })
            }}
            loading={isAdding || isUpdating}
            isDeleting={isDeleting}
            disabled={isAlreadyCreated || isAdding || isUpdating || isDeleting}
            draftFlg={values?.isDraft}
            action={action}
          />

          <Card>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
              }}
            >
              <FormInputContainer>
                <Grid
                  labelContent={t("User name")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                  id={"user_id"}
                  padding={"12px"}
                >
                  <div className={"user__name_selector"}>
                    <SelectInput
                      name={"furigana"}
                      id={"furigana"}
                      width={"78px"}
                      disabled={
                        action === "edit" && values?.user_id ? true : false
                      }
                      shortSelector
                      options={FuriganaAlphabetsOption}
                      value={values?.furigana || null}
                      onChange={(val) => {
                        setFieldValue("furigana", val)
                        setFieldValue("user_id", null)
                      }}
                      placeholder={"--"}
                    />
                    <SelectInput
                      name={"user_id"}
                      id={"user_id"}
                      disabled={
                        action === "edit" && values?.user_id && values?.user_id
                          ? true
                          : false
                      }
                      loading={isUserLoading}
                      options={filterWithHiragana({
                        options: userData,
                        furigana: values?.furigana,
                      })}
                      width={"208px"}
                      error={
                        errors?.user_id && touched?.user_id ? t("Required") : ""
                      }
                      showMessage={false}
                      value={values?.user_id || null}
                      onChange={(val) => {
                        setFieldValue("user_id", val)
                      }}
                      placeholder={"--"}
                    />
                  </div>
                  {errors?.user_id && touched?.user_id ? (
                    <ErrorMassage message={t("Required")} />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  labelContent={t("Recipient number")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"10px 12px"}
                >
                  <Typography.Text className={"text"}>
                    {values?.user_id
                      ? values?.recipient_number || "-"
                      : t("*Select user first2")}
                  </Typography.Text>
                </Grid>
                <Grid
                  labelContent={t("Facility name")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  {values?.user_id ? (
                    <SelectInput
                      name={"facility_id"}
                      placeholder={t("--")}
                      value={values?.facility_id || null}
                      onChange={(val) => {
                        setFieldValue("facility_id", val)
                      }}
                      options={facilities?.map((facility) => ({
                        label: facility?.facility_name,
                        value: facility?.id,
                      }))}
                      width={"300px"}
                      height={"40px"}
                      onBlur={handleBlur}
                      error={
                        touched.facility_id &&
                        !values.user_id &&
                        `${errors.facility_id}`
                      }
                    />
                  ) : (
                    <Typography.Text className={"text"}>
                      {t("*Select user first2")}
                    </Typography.Text>
                  )}
                </Grid>
                <Grid
                  labelContent={t("Use Service")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  {values?.user_id ? (
                    <SelectInput
                      name={"use_service_id"}
                      placeholder={t("--")}
                      value={values?.use_service_id}
                      onChange={(val) => {
                        setFieldValue("use_service_id", val)
                      }}
                      options={FACILITY_TYPES}
                      width={"300px"}
                      height={"40px"}
                      onBlur={handleBlur}
                      error={
                        touched.use_service_id &&
                        !values.user_id &&
                        `${errors.use_service_id}`
                      }
                    />
                  ) : (
                    <Typography.Text className={"text"}>
                      {t("*Select user first2")}
                    </Typography.Text>
                  )}
                </Grid>
                <Grid
                  labelContent={t("No. created")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                  padding={"12px"}
                  id={"create_count"}
                >
                  {isAlreadyCreated ? (
                    <Typography.Text
                      style={{
                        color: "#f00",
                        lineHeight: 1.3,
                        fontSize: "14px",
                      }}
                    >
                      {t("Number of times already created")}
                    </Typography.Text>
                  ) : null}
                  <div className={"create_count"}>
                    <InputField
                      name={"create_count"}
                      id={"create_count"}
                      width={"108px"}
                      height={"40px"}
                      type={"number"}
                      placeholder={"0"}
                      min={"0"}
                      value={values?.create_count}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault()
                        }
                      }}
                      onChange={({ target: { value } }) => {
                        const data = allowOnlyNumber(value)
                        setFieldValue("create_count", data)
                        handleNoOfCreateChange(data)
                      }}
                      onWheel={(event: any) => event.target.blur()}
                      error={
                        errors?.create_count && touched?.create_count
                          ? `${errors?.create_count}`
                          : ""
                      }
                    />
                    <span>{t("回目")}</span>
                  </div>
                </Grid>
                <Grid
                  labelContent={t("Created date")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  <DatePicker
                    name={"agree_date"}
                    id={"agree_date"}
                    date={
                      dayjs(values?.agree_date).isValid()
                        ? dayjs(values?.agree_date)
                        : null
                    }
                    inputReadOnly
                    format={"YYYY年MM月DD日"}
                    placeholder={"--"}
                    style={{
                      width: "163px",
                    }}
                    onDateChange={(val) => {
                      setFieldValue("agree_date", val)
                      setFieldValue("start_date", val)
                      setFieldValue(
                        "end_date",
                        dayjs(dayjs(val)).add(6, "M").subtract(1, "d"),
                      )
                    }}
                    error={
                      errors?.agree_date && touched?.agree_date
                        ? `${errors?.agree_date}`
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t("Start date")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  <DatePicker
                    name={"start_date"}
                    id={"start_date"}
                    disabledDate={(current) => {
                      const createdDate = values?.agree_date
                      return (
                        createdDate &&
                        current <= dayjs(createdDate).subtract(1, "d")
                      )
                    }}
                    date={
                      dayjs(values?.start_date).isValid()
                        ? dayjs(values?.start_date)
                        : null
                    }
                    inputReadOnly
                    format={"YYYY年MM月DD日"}
                    placeholder={"--"}
                    style={{
                      width: "163px",
                    }}
                    onDateChange={(val) => {
                      setFieldValue("start_date", val)
                      setFieldValue(
                        "end_date",
                        dayjs(dayjs(val)).add(6, "M").subtract(1, "d"),
                      )
                    }}
                    error={
                      errors?.start_date && touched?.start_date
                        ? `${errors?.start_date}`
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t("Expire date")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  <DatePicker
                    name={"end_date"}
                    id={"end_date"}
                    disabledDate={(current) =>
                      current.isBefore(dayjs(values?.start_date))
                    }
                    date={
                      dayjs(values?.end_date).isValid()
                        ? dayjs(values?.end_date)
                        : null
                    }
                    inputReadOnly
                    format={"YYYY年MM月DD日"}
                    placeholder={"--"}
                    style={{
                      width: "163px",
                    }}
                    onDateChange={(val) => {
                      setFieldValue("end_date", val)
                    }}
                    error={
                      errors?.end_date && touched?.end_date
                        ? `${errors?.end_date}`
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t("Service management manager")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"12px"}
                >
                  {values?.user_id ? (
                    <>
                      {values?.facility_id ? (
                        <SelectInput
                          name={"facility_admin"}
                          id={"facility_admin"}
                          options={staff || []}
                          value={values?.facility_admin}
                          onChange={(v) => {
                            setFieldValue("facility_admin", v)
                            setFieldValue("staff_id", v)
                          }}
                          width={"200px"}
                          height={"40px"}
                          loading={isStaffLoading}
                          placeholder={t("Please select")}
                        />
                      ) : (
                        <Typography.Text className={"text"}>
                          {t("Please select a facility first")}
                        </Typography.Text>
                      )}
                    </>
                  ) : (
                    <Typography.Text className={"text"}>
                      {t("*Select user first2")}
                    </Typography.Text>
                  )}
                </Grid>
              </FormInputContainer>
              <PlanGoalContainer>
                <Grid
                  labelContent={t("Intention of the person/family")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InputAreaField
                    name={"user_family_hope"}
                    id={"user_family_hope"}
                    placeholder={t(
                      "例：将来的には一般就労し、一人暮らしをしたい。",
                    )}
                    rows={2}
                    padding={"10px"}
                    value={values?.user_family_hope}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  labelContent={t("Comprehensive support policy")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InputAreaField
                    name={"support_policy"}
                    id={"support_policy"}
                    placeholder={t(
                      "例：一般就労へ向けて、規則正しい生活と社会的マナーを身に着けられるよう支援します。",
                    )}
                    rows={2}
                    padding={"10px"}
                    value={values?.support_policy}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  labelContent={t("Long term goal")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InputAreaField
                    name={"long_term_goal"}
                    id={"long_term_goal"}
                    placeholder={t(
                      "例：一般就労へ向けて、規則正しい生活の習慣を身につける",
                    )}
                    rows={2}
                    padding={"10px"}
                    value={values?.long_term_goal}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  labelContent={t("Short term goal")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InputAreaField
                    name={"short_term_goal"}
                    id={"short_term_goal"}
                    placeholder={t(
                      "例：23時には寝て、7時には起きれるようにする。",
                    )}
                    rows={2}
                    padding={"10px"}
                    value={values?.short_term_goal}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  labelContent={t("Transportation")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <div className={"pickup_container"}>
                    <div className={"content"}>
                      <div className={"opts"}>
                        <label>
                          {t("Greeted")} {" :"}
                        </label>
                        <RadioGroup
                          name={"goto_meet_flag"}
                          onChange={({ target: { value } }) =>
                            setFieldValue("goto_meet_flag", value)
                          }
                          value={values?.goto_meet_flag}
                          options={[
                            {
                              label: t("None"),
                              value: "0",
                            },
                            {
                              label: t("Can"),
                              value: "1",
                            },
                          ]}
                        />
                      </div>
                      <div className={"place__input"}>
                        <InputField
                          name={"goto_meet_place"}
                          id={"goto_meet_place"}
                          value={values?.goto_meet_place}
                          onChange={handleChange}
                          disabled={values?.goto_meet_flag === "0"}
                          placeholder={t("例：自宅")}
                          error={
                            touched.goto_meet_place && errors.goto_meet_place
                              ? `${errors.goto_meet_place}`
                              : ""
                          }
                          maxLength={100}
                          width={"300px"}
                          height={"50px"}
                        />
                      </div>
                    </div>
                    <div className={"content"}>
                      <div className={"opts"}>
                        <label>
                          {t("Send ")} {":"}
                        </label>
                        <RadioGroup
                          name={"take_flag"}
                          onChange={({ target: { value } }) =>
                            setFieldValue("take_flag", value)
                          }
                          value={values?.take_flag}
                          options={[
                            {
                              label: t("None"),
                              value: "0",
                            },
                            {
                              label: t("Can"),
                              value: "1",
                            },
                          ]}
                        />
                      </div>
                      <div className={"place__input"}>
                        <InputField
                          placeholder={t("例：自宅")}
                          width={"300px"}
                          height={"50px"}
                          name={"take_place"}
                          id={"take_place"}
                          value={values?.take_place}
                          onChange={handleChange}
                          disabled={values?.take_flag === "0"}
                          error={
                            touched.take_place && errors.take_place
                              ? `${errors.take_place}`
                              : ""
                          }
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  labelContent={t("Remarks")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InputAreaField
                    name={"remarks"}
                    rows={2}
                    value={values.remarks}
                    onChange={handleChange}
                  />
                </Grid>
              </PlanGoalContainer>
              <PlanGoalContent
                dataSource={dataSource}
                setDataSource={setDataSource}
                planType={planType}
              />
              <PlanWeeklySchedule
                dataSource={weeklyScheduleDataSource}
                setDataSource={setWeeklyScheduleDataSource}
                planType={planType}
              />
              <PlanFormBtnAction
                mt={"16px"}
                handleCancel={() => router.push(link)}
                handleSaveDraft={() => {
                  setFieldValue("isDraft", true)
                  handleSubmit()
                }}
                handleSave={() => {
                  setFieldValue("isDraft", false)
                  handleSubmit()
                }}
                handleDelete={() => {
                  deleteData({
                    id: plan_id,
                    type: planType,
                  })
                }}
                loading={isAdding || isUpdating}
                isDeleting={isDeleting}
                disabled={
                  isAlreadyCreated || isAdding || isUpdating || isDeleting
                }
                draftFlg={values?.isDraft}
                action={action}
              />
            </form>
          </Card>
        </PlanFormWrapper>
      </Spin>
    </>
  )
}
