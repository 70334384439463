import {
  ActionBtns,
  Button,
  Card,
  FACILITY_SERVICE_TYPES,
} from "@project/common"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useReactToPrint } from "react-to-print"
import { AuthContext } from "../../../context/AuthContext"
import { getCreatorAndConferenceParticipants } from "../../../services"
import {
  ButtonContainer,
  DetailsWrapper,
  MainWrapper,
  PlanFormWrapper,
  Wrapper,
} from "./MinutesDetailForm.style"
interface DetailFormProps {
  id?: string
  minutesDetail?: any
}

export const MinutesDetailForm = ({
  id,
  minutesDetail,
}: DetailFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const renderMinutesDetail = (value1: string, value2: string) => {
    if (value1 == "") return null
    const parts1 = value1?.split(":")
    const parts2 = value2?.split(":")

    parts1[0] = parts1 && parts1[0]?.padStart(2, "0")
    parts1[1] = parts1 && parts1[1]?.padStart(2, "0")
    const formattedTime1 = parts1.join(":")

    if (value2 == "") return `${formattedTime1}`

    parts2[0] = parts2 && parts2[0]?.padStart(2, "0")
    parts2[1] = parts2 && parts2[1]?.padStart(2, "0")
    const formattedTime2 = parts2.join(":")

    return `${formattedTime1} ~ ${formattedTime2}`
  }

  const { data: Minutes_Participants } = useQuery(
    ["get_creator_and_conference_participants", minutesDetail?.facility_id],
    getCreatorAndConferenceParticipants,
    {
      cacheTime: 60 * 1000,
      retry: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!minutesDetail?.facility_id,
      select: (data: any) => {
        const staffs = data?.data.map((staff) => ({
          label: staff?.staff_name,
          value: staff?.id,
        }))
        return staffs
      },
    },
  )

  const filteredParticipants = Minutes_Participants?.filter(
    (item) =>
      minutesDetail?.attendance?.some((staff) => staff.staff_id === item.value),
  )

  const filteredAbsence = Minutes_Participants?.filter(
    (item) =>
      minutesDetail?.absent?.some((staff) => staff.staff_id === item.value),
  )
  const renderContent = (action?: string) => {
    if (minutesDetail) {
      return (
        <Wrapper id={"div-to-print"} action={action} ref={componentRef}>
          <Card
            className={"card-header"}
            title={t(
              "Service Personnel Meeting (Support Meeting) Minutes of {{username}}",
              { username: minutesDetail?.User.username },
            )}
          >
            <DetailsWrapper>
              <div className={"title print-header-title"}>
                {t(
                  "Service Personnel Meeting (Support Meeting) Minutes of {{username}}",
                  { username: minutesDetail?.User.username },
                )}
              </div>
              <div id={"content"}>
                <div className={"print-header-subtitle"}>
                  {t("Facility name")}
                  {": "}
                  {
                    facilities?.find(
                      (item) => item.id == minutesDetail?.facility_id,
                    )?.facility_name
                  }
                </div>
                <div className={"print-header-subtitle"}>
                  {t("Service Name")}
                  {": "}
                  {t(FACILITY_SERVICE_TYPES[minutesDetail?.service_type])}
                </div>
                <div className={"print-header-subtitle"}>
                  {t("Created date")}
                  {": "}
                  {dayjs(minutesDetail?.report_create_date).format(
                    "YYYY年MM月DD日",
                  )}
                </div>
              </div>
            </DetailsWrapper>

            <div className={"form_control first-content"}>
              <table>
                <tr>
                  <td className={"label"}>{t("Event date")}</td>
                  <td>
                    {dayjs(minutesDetail?.event_date).format("YYYY年MM月DD日")}
                  </td>
                </tr>
                <tr>
                  <td className={"label"}>{t("Open Time")}</td>
                  <td>
                    {renderMinutesDetail(
                      minutesDetail?.start_time,
                      minutesDetail?.end_time,
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={"label"}>{t("User name")}</td>
                  <td>
                    {minutesDetail?.User?.username
                      ? minutesDetail?.User?.username
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <td className={"label"}>{t("Created times")}</td>
                  <td>{t(minutesDetail?.create_count)}</td>
                </tr>

                <tr>
                  <td className={"label"}>{t("Author")}</td>
                  <td>
                    {minutesDetail?.staff?.staff_name
                      ? minutesDetail?.staff?.staff_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className={"label"}>{t("Meeting participants")}</td>
                  <td>
                    {filteredParticipants?.map((item) => item.label).join(", ")}
                    <br />
                    {minutesDetail?.attendance_text || "-"}
                  </td>
                </tr>

                <tr>
                  <td className={"label"}>{t("Absence")}</td>
                  <td>
                    {filteredAbsence?.length > 0
                      ? filteredAbsence?.map((item) => item.label).join(", ")
                      : "--"}
                  </td>
                </tr>
              </table>
            </div>
            <div className={"form_control "}>
              <table>
                <tr>
                  <td className={"label"}>{t("Minutes")}</td>
                  <td>
                    {minutesDetail?.minutes ? minutesDetail?.minutes : "--"}
                  </td>
                </tr>
                <tr>
                  <td className={"label"}>{t("Memo")}</td>
                  <td>{minutesDetail?.memo ? minutesDetail?.memo : "--"}</td>
                </tr>
                <tr>
                  <td className={"label"}>{t("Minutes upload")}</td>
                  <td>
                    {minutesDetail?.minute_upload_pdf_name
                      ? minutesDetail?.minute_upload_pdf_name
                      : "--"}
                  </td>
                </tr>
              </table>
            </div>
          </Card>
          <style type={"text/css"}>
            {`
                @media print {
                  .card-header {
                    .ant-card-head-title {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height:60px;
                      font-size: 22px !important;
                      font-weight: 600 !important;
                      width: 100%;
                      text-decoration: underline !important;
                    }
                    .print-header-title {
                      max-width: 45%;
                      font-size: 17px !important;
                      font-weight: 500 !important;
                    }
                    .print-header-subtitle {
                      font-size: 16px !important;
                      font-weight: 500 !important;
                    }
                    
                  }
                }
              `}
          </style>
        </Wrapper>
      )
    }
  }
  const ActionButtonContainer = ({ mt, minute_id, handlePrint }: any) => {
    const { t } = useTranslation()
    const router = useRouter()

    return (
      <div style={{ margin: "8px 0px" }}>
        <ActionBtns mt={mt}>
          <ButtonContainer>
            <div className={"first-buttons"}>
              <Button
                btnText={t("Back")}
                shape={"round"}
                type={"default"}
                onClick={() => router.push("/minutes")}
                otherType={"draft"}
                btnBg={"#fff"}
              />
              <Button
                btnText={t("Print")}
                shape={"round"}
                type={"primary"}
                onClick={() => handlePrint()}
                iconType={"print"}
              />
            </div>
            <div className={"last-button"}>
              <Button
                btnText={t(
                  "Edit the Minutes of Service Personnel Meetings (Support Meetings)",
                )}
                shape={"round"}
                onClick={() => router.push(`/minutes/edit/${minute_id}`)}
                iconType={"blue-pencil"}
                otherType={"draft"}
                color={"#000"}
              />
            </div>
          </ButtonContainer>
        </ActionBtns>
      </div>
    )
  }
  return (
    <MainWrapper>
      <PlanFormWrapper>
        <ActionButtonContainer minute_id={id} handlePrint={handlePrint} />
        <div className={"print-me"}>{renderContent()}</div>
        <ActionButtonContainer minute_id={id} handlePrint={handlePrint} />
      </PlanFormWrapper>
    </MainWrapper>
  )
}
