import {
  ActionBtns,
  Button,
  Card,
  Grid,
  InputAreaField,
  SelectInput,
  scrollToFirstErrorField,
} from "@project/common"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { fetchWorkCategory } from "../../../services/workDetailsMaster"
import {
  WorkDetailsMasterFormField,
  WorkDetailsMasterFormSchema,
  WorkDetailsMasterResponse,
} from "../../../types"
import { Wrapper } from "./WorkDetailsMasterForm.style"

interface WorkDetailsMasterFormProps {
  defaultValues?: WorkDetailsMasterResponse | any
  onSubmit: (values: WorkDetailsMasterFormField) => void
  isLoading: boolean
}

const WorkDetailsMasterForm = ({
  defaultValues,
  onSubmit,
  isLoading,
}: WorkDetailsMasterFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const initialValues = {
    work_category_id: null,
    work_name: "",
  }
  const {
    values,
    handleSubmit,
    errors,
    submitCount,
    isValid,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik<WorkDetailsMasterFormField>({
    initialValues: defaultValues || initialValues,
    validationSchema: WorkDetailsMasterFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const { data: workCategoryData, isLoading: isCategoryLoading } = useQuery(
    ["work-category-data"],
    {
      queryFn: fetchWorkCategory,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
    },
  )

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  const workCategoryList = workCategoryData?.data.map((data) => {
    return {
      label: t(data.work_category_name),
      value: data.id,
    }
  })

  return (
    <Card title={t("Work Information")}>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div>
            <Grid
              labelContent={t("Work Category")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <SelectInput
                width={"208px"}
                loading={isCategoryLoading}
                name={"work_category_id"}
                options={workCategoryList}
                value={values.work_category_id}
                placeholder={"---"}
                onChange={(workCategory) => {
                  return setFieldValue("work_category_id", workCategory)
                }}
              />
              <div>
                <span style={{ fontSize: "13px", color: "#E00000" }}>
                  {touched?.work_category_id && errors?.work_category_id
                    ? t(`${errors?.work_category_id}`)
                    : ""}
                </span>
              </div>
            </Grid>
            <Grid
              labelContent={t("Work Item")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputAreaField
                name={"work_name"}
                id={"work_name"}
                padding={"8px"}
                borderRadius={"4px"}
                rows={2}
                value={values?.work_name || null}
                error={
                  touched?.work_name && errors?.work_name
                    ? errors?.work_name
                    : ""
                }
                info={t("(Within 200 char)")}
                maxLength={200}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </div>
          <ActionBtns justify={"flex-start"}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={() => router.push("/work-details-master")}
              disabled={isLoading}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              type={"primary"}
              htmlType={"submit"}
              isLoading={isLoading}
            />
          </ActionBtns>
        </form>
      </Wrapper>
    </Card>
  )
}

export { WorkDetailsMasterForm }
