import { QuestionCircleOutlined } from "@ant-design/icons"
import { theme } from "@project/common"
import { Flex, Popover } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

export const AccountSubjectContentWrapper = styled(Flex)<{
  $minWidth?: string
}>`
  text-align: left;
  cursor: pointer;
  min-width: ${({ $minWidth }) => $minWidth || "200px"};
  .icon {
    svg {
      color: ${theme.colors.info};
    }
  }
`
export const AccountSubjectContent = ({
  label,
  minWidth,
}: {
  label: string
  minWidth?: string
}) => {
  const { t } = useTranslation()
  return (
    <AccountSubjectContentWrapper
      justify={"space-between"}
      gap={6}
      align={"center"}
      $minWidth={minWidth}
    >
      <div className={"label"}>{t(label)}</div>
      <Popover content={t(label)}>
        <div className={"icon"}>
          <QuestionCircleOutlined />
        </div>
      </Popover>
    </AccountSubjectContentWrapper>
  )
}
