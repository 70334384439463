import React, { useState } from "react"
import { EmploymentSupportBusinessStatementTabList } from "./EmploymentSupportBusinessStatementTabList"
import { I_EMPLOYMENT_BUSINESS_STATEMENT } from "../../../types"
import { EmploymentSupportBusinessStatementTabEdit } from "./EmploymentSupportBusinessStatementTabEdit"

export const EmploymentSupportBusinessStatementTab = ({
  ...props
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}) => {
  const [selectedColData, setSelectedColData] =
    useState<I_EMPLOYMENT_BUSINESS_STATEMENT | null>(null)
  if (selectedColData) {
    return (
      <EmploymentSupportBusinessStatementTabEdit
        selectedCol={selectedColData}
        setSelectedColData={setSelectedColData}
        {...props}
      />
    )
  }
  return (
    <EmploymentSupportBusinessStatementTabList
      setSelectedColData={setSelectedColData}
      {...props}
    />
  )
}
