import { Flex, Input, Typography } from "antd"
import type { RangePickerProps } from "antd/es/date-picker"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { FieldArray, FormikProvider, useFormik } from "formik"
import Link from "next/link"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import * as Yup from "yup"
import { GetShiftMasterNames } from "../../../services"

import { MinusCircleFilled } from "@ant-design/icons"
import {
  Button,
  ConfirmationModal,
  DatePicker,
  ErrorMassage,
  MONTHS,
  Modal,
  RadioGroup,
  SelectInput,
  WORK_TYPES,
  allowOnlyNumber,
  anchorSelInputAreaAsPopupContainer,
  getDateString,
  theme,
} from "@project/common"
import { AuthContext } from "../../../context/AuthContext"
import { useFetchOccupation } from "../../../hooks/useFetchData"
import { StaffOtherInfoContent } from "./StaffOtherInfoContent"
import {
  BtnContainers,
  OccupationModalBodyContainer,
  Wrapper,
} from "./StaffShift.style"

dayjs.extend(arraySupport)
interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY"
const initialValues = {
  year: dayjs().year(),
  month: dayjs().month() + 1,
  workstyle_type: "",
  facility_id: "",
  occupations: [
    {
      id: null,
      hour: "",
      is_manager: false,
      title: "",
    },
  ],
  manager_work_hour: 0,
  one_week_work_pattern: {
    sun: "",
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
  },
}
export const StaffShift: React.FC<IProps> = ({ data, onSave, onDelete }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const firstFacility = facilities ? facilities[0]?.id : ""
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedFacility, setSelectedFacility] = useState(
    String(firstFacility),
  )
  const [selectedFacilityData, setSelectedFacilityData] = useState(
    facilities ? facilities[0] : {},
  )

  const { data: shiftMasters } = useQuery({
    queryKey: ["shitf-master-list", selectedFacility],
    queryFn: () => GetShiftMasterNames(selectedFacility),
    cacheTime: 0,
    retry: 1,
    select: (res: any) => {
      return {
        ...res,
        data: res?.data?.map((val) => ({
          label: val?.attendance_shift_display_name,
          value: val?.id,
        })),
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedFacility,
  })
  const { occupations } = useFetchOccupation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      year: Yup.string().required(t("Required")),
      month: Yup.string().required(t("Required")),
      workstyle_type: Yup.string(),
      facility_id: Yup.string()
        .required(t("Required"))
        .default(`${firstFacility}`),
      occupations: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t("Required")),
            hour: Yup.string().nullable(),
          }),
        )
        .min(1, t("Required")),
    }),

    onSubmit: (values) => {
      const { one_week_work_pattern, occupations, ...rest } = values
      const hasValue = Object.values(rest)?.some((val) => val)
      const occu = {
        occupation_id: +occupations[0].id || null,
        occupation_id_2: +occupations[1]?.id || null,
        manager_work_hour: +occupations[0].hour || null,
        manager_work_hour_2: +occupations[1]?.hour || null,
      }
      if (hasValue) {
        if (operation === "create") {
          onSave(
            {
              tempId: new Date().getTime(),
              ...rest,
              facility_id: Number(rest?.facility_id) || +selectedFacility,
              workstyle_type: rest?.workstyle_type || 1,
              year: +values?.year,
              month: +values?.month,
              one_week_work_pattern,
              ...occu,
              occupations,
            },
            operation,
          )
        } else {
          onSave(
            {
              tempId: selectedId,
              ...rest,
              facility_id: Number(rest?.facility_id) || +selectedFacility,
              year: +values?.year,
              month: +values?.month,
              one_week_work_pattern,
              ...occu,
              occupations,
            },
            operation,
          )
        }
        setOperation("create")
        handleReset()
      }
    },
  })

  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })
    setSelectedId(null)
    setOperation("create")
    setIsDuplicate(false)
    setIsModalOpen(false)
  }
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day")
  }

  const checkForDuplicate = (y?: number, m?: number) => {
    const year = y || formik?.values?.year
    const month = m || formik?.values?.month
    const dd = data?.find((val) => val?.year === +year && val?.month === month)
    if (dd) {
      setIsDuplicate(true)
    } else {
      setIsDuplicate(false)
    }
  }

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "Edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  year: row?.year,
                  month: row?.month,
                  workstyle_type: row?.workstyle_type || 1,
                  facility_id: row?.facility_id
                    ? String(row?.facility_id)
                    : firstFacility,
                  occupations: row?.occupations,
                  one_week_work_pattern: row?.one_week_work_pattern,
                  manager_work_hour: row?.manager_work_hour,
                },
              })
              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.colors.action, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Year month")}</span>,
      key: "year_month",
      render: (row) => {
        const m = MONTHS?.find((val) => val?.value === +row?.month)?.value
        return <span>{getDateString(row?.year, m)}</span>
      },
    },
    {
      title: <span className={"col-title"}>{t("Work style")}</span>,
      key: "workstyle_type",
      dataIndex: "workstyle_type",
      render: (id) => (
        <span>{t(WORK_TYPES?.find((val) => val?.value === id)?.label)}</span>
      ),
    },
    {
      title: (
        <span className={"col-title"}>{t("Affiliated facilities/work")}</span>
      ),
      key: "facility_id",
      render: (row) => (
        <div>
          <span>
            {
              facilities?.find((val) => val?.id === row?.facility_id)
                ?.facility_name
            }
          </span>
          {" : "}
          <span>
            {
              occupations?.find((val) => val?.value === row?.occupation_id)
                ?.label
            }
          </span>
          {row?.occupation_id_2 ? (
            <span>
              {",   "}
              {
                occupations?.find((val) => val?.value === row?.occupation_id_2)
                  ?.label
              }
            </span>
          ) : null}
        </div>
      ),
    },
  ]
  // facility service type wise occupation if occupation has type
  const getOccupations = () =>
    occupations
      ? [...occupations].filter(
          (val) =>
            val?.type === 0 || val.type === selectedFacilityData?.facility_type,
        )
      : []
  // t("Number of hours worked as other:")}
  const hourInput = ({ name, value, onChange, title }) => {
    return (
      <div className={"working_hours"}>
        <label style={{ marginRight: "5px" }}>{title}</label>
        <Input name={name} value={value} maxLength={4} onChange={onChange} />
      </div>
    )
  }

  return (
    <>
      <Wrapper>
        <StaffOtherInfoContent
          title={t("List of affiliated facilities and occupations")}
          columns={columns}
          dataSource={data}
          btnText={t("Register affiliated facilities and occupations")}
          emptyText={t("Facility is not registered")}
          onClick={() => {
            if (operation === "create") {
              checkForDuplicate()
              formik.setFieldValue(
                `occupations.0.id`,
                occupations ? occupations[0]?.value : null,
              )
              formik.setFieldValue(
                `occupations.0.is_manager`,
                occupations ? occupations[0]?.is_manager : null,
              )
              formik.setFieldValue(
                `occupations.0.title`,
                occupations ? occupations[0]?.title : null,
              )
              formik?.setFieldValue(
                "facility_id",
                facilities ? facilities[0]?.value : null,
              )
            }
            setIsModalOpen(!isModalOpen)
          }}
          extra={
            <div className={"msg_content"}>
              <Typography.Paragraph className={"msg"}>
                {t(
                  " ※It is necessary to register the shift master in order to set the work pattern for the week. Please register the shift master.",
                )}
              </Typography.Paragraph>
              <Link href={"/shift-master/add"}>
                <a target={"_blank"}>
                  <Button
                    btnText={t("Register shift master")}
                    shape={"round"}
                    btnBg={theme.colors.action}
                    textColor={theme.colors.white}
                  />
                </a>
              </Link>
            </div>
          }
        />
      </Wrapper>
      <Modal
        title={t("Register Affiliated facilities and occupations")}
        closeIcon={<span>{t("Close")}</span>}
        open={isModalOpen}
        onCancel={() => {
          handleReset()
          setSelectedId(null)
          setOperation("create")
          setIsModalOpen(false)
        }}
        width={1000}
        maskClosable={false}
        destroyOnClose
        centered
        footer={null}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
          },
        }}
        wrapClassName={"instructor__info-modal"}
      >
        <form onSubmit={formik.handleSubmit}>
          <OccupationModalBodyContainer>
            <div className={"body_content"}>
              {isDuplicate ? (
                <Typography.Text type={"danger"}>
                  {t("{{date}} is already registered.", {
                    date: getDateString(
                      formik?.values?.year,
                      formik?.values?.month,
                    ),
                  })}
                </Typography.Text>
              ) : (
                ""
              )}
              <div className={"year_month"}>
                <div className={"year"}>
                  <div className={"year_input"}>
                    <DatePicker
                      name={"year"}
                      picker={"month"}
                      format={"YYYY年MM月"}
                      disabledDate={disabledDate}
                      style={{
                        width: "150px",
                      }}
                      date={
                        dayjs([
                          Number(formik?.values?.year),
                          Number(formik?.values?.month),
                        ]).isValid()
                          ? dayjs([
                              Number(formik?.values?.year),
                              Number(formik?.values?.month) - 1,
                            ])
                          : dayjs(new Date(new Date().getFullYear(), 1, 1))
                      }
                      placeholder={""}
                      onDateChange={(e) => {
                        formik.setFieldValue("year", dayjs(e).format(FORMAT))
                        formik?.setFieldValue("month", dayjs(e).format("MM"))
                        checkForDuplicate(
                          +dayjs(e).format(FORMAT),
                          +dayjs(e).format("M"),
                        )
                      }}
                      placement={"bottomLeft"}
                    />
                  </div>
                  <div>
                    {(formik.errors.year && formik.touched.year) ||
                    (formik.errors.month && formik.touched.month) ? (
                      <ErrorMassage
                        message={formik.errors.year ? t("Required") : ""}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Work type")}</div>
                <div className={"form_input"}>
                  <RadioGroup
                    options={WORK_TYPES}
                    name={"workstyle_type"}
                    onChange={({ target: { value } }) => {
                      if (+value === 1 || +value === 3) {
                        formik?.setFieldValue(
                          "occupations",
                          formik?.values?.occupations?.slice(0, 1),
                        )
                      }
                      formik?.setFieldValue("workstyle_type", value)
                    }}
                    value={formik?.values?.workstyle_type || 1}
                  />
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Affiliated facility")}</div>
                <div className={"form_input"}>
                  <SelectInput
                    options={facilities || []}
                    popupClassName={"select_dropdown_width"}
                    width={"240px"}
                    height={"40px"}
                    placeholder={t("All")}
                    id={"facility_id"}
                    name={"facility_id"}
                    value={formik?.values?.facility_id || null}
                    onChange={(val) => {
                      formik?.setFieldValue("facility_id", val)
                      formik?.setFieldValue("one_week_work_pattern.sun", "")
                      formik?.setFieldValue("one_week_work_pattern.mon", "")
                      formik?.setFieldValue("one_week_work_pattern.tue", "")
                      formik?.setFieldValue("one_week_work_pattern.wed", "")
                      formik?.setFieldValue("one_week_work_pattern.thu", "")
                      formik?.setFieldValue("one_week_work_pattern.fri", "")
                      formik?.setFieldValue("one_week_work_pattern.sat", "")
                      setSelectedFacility(val)
                      setSelectedFacilityData(
                        facilities?.find((f) => f?.id === +val),
                      )
                    }}
                    getPopupContainer={anchorSelInputAreaAsPopupContainer}
                  />

                  {formik.errors.facility_id && formik.touched.facility_id && (
                    <ErrorMassage message={formik.errors.facility_id} />
                  )}
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("Occupation")}</div>
                <div className={"form_input"}>
                  <FormikProvider value={formik}>
                    <FieldArray
                      name={"occupations"}
                      render={(arrayHelpers) => (
                        <Flex vertical gap={8}>
                          {formik?.values?.occupations?.map(
                            (occupation, index) => (
                              <div key={index}>
                                <div className={"form_input__occupation"}>
                                  <SelectInput
                                    options={(() => {
                                      const selectedOne =
                                        formik?.values?.occupations
                                          ?.filter((_, idx) => idx !== index)
                                          ?.map((v) => v?.id)
                                      return getOccupations()?.filter(
                                        (v) => !selectedOne?.includes(v?.value),
                                      )
                                    })()}
                                    className={
                                      "occupation-select-input dynamic-dropdown-width"
                                    }
                                    popupClassName={"select_dropdown_width"}
                                    popupMatchSelectWidth={false}
                                    width={"auto"}
                                    height={"40px"}
                                    id={`occupations.${index}.id`}
                                    name={`occupations.${index}.id`}
                                    value={occupation?.id}
                                    onChange={(val, defaultOptions: any) => {
                                      formik?.setFieldValue(
                                        `occupations.${index}.id`,
                                        val,
                                      )
                                      formik?.setFieldValue(
                                        `occupations.${index}.is_manager`,
                                        defaultOptions?.is_manager,
                                      )
                                      formik?.setFieldValue(
                                        `occupations.${index}.title`,
                                        defaultOptions?.title,
                                      )
                                    }}
                                    getPopupContainer={
                                      anchorSelInputAreaAsPopupContainer
                                    }
                                  />
                                  {(+formik?.values?.workstyle_type === 2 ||
                                    +formik?.values?.workstyle_type === 4) && (
                                    <>
                                      {formik?.values?.occupations?.length >=
                                      2 ? (
                                        <Button
                                          btnText={t("Delete")}
                                          shape={"round"}
                                          icon={
                                            <MinusCircleFilled
                                              className={
                                                "form_input__occupation--icon"
                                              }
                                            />
                                          }
                                          btnBg={theme.colors.error}
                                          borderColor={theme.colors.error}
                                          textColor={theme.colors.white}
                                          hoverBorderColor={theme.colors.error}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      ) : (
                                        <Button
                                          btnText={t("Add")}
                                          type={"primary"}
                                          disabled={!occupation?.id}
                                          onClick={() =>
                                            arrayHelpers.insert(index + 1, {
                                              id: null,
                                              hour: null,
                                            })
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </div>

                                {formik?.errors?.occupations &&
                                  formik?.errors?.occupations[index] &&
                                  formik?.touched?.occupations &&
                                  formik?.touched?.occupations[index] && (
                                    <ErrorMassage message={t("Required")} />
                                  )}

                                {occupation?.is_manager
                                  ? formik?.values?.occupations?.filter(
                                      (v) => v?.is_manager,
                                    )?.length < 2 &&
                                    occupation?.title === "Service manager" &&
                                    hourInput({
                                      title: t(
                                        "Hours worked as service manager:",
                                      ),
                                      name: `occupations.${index}.hour`,
                                      value: occupation?.hour,
                                      onChange: (e) => {
                                        formik?.setFieldValue(
                                          `occupations.${index}.hour`,
                                          allowOnlyNumber(
                                            e.currentTarget.value,
                                          ),
                                        )
                                      },
                                    })
                                  : occupation?.title ===
                                      "Target wage Achievement advisor"
                                    ? hourInput({
                                        title: t(
                                          "Number of hours worked as other:",
                                        ),
                                        name: `occupations.${index}.hour`,
                                        value: occupation?.hour,
                                        onChange: (e) => {
                                          formik?.setFieldValue(
                                            `occupations.${index}.hour`,
                                            allowOnlyNumber(
                                              e.currentTarget.value,
                                            ),
                                          )
                                        },
                                      })
                                    : ""}
                              </div>
                            ),
                          )}
                        </Flex>
                      )}
                    />
                  </FormikProvider>
                </div>
              </div>
              <div className={"form_control"}>
                <div className={"label"}>{t("One week work pattern")}</div>
                <div className={"form_input"}>
                  <div className={"work_pattern"}>
                    <table>
                      <thead>
                        <tr>
                          <th>{t("Sun")}</th>
                          <th>{t("Mon")}</th>
                          <th>{t("Tue")}</th>
                          <th>{t("Wed")}</th>
                          <th>{t("Thu")}</th>
                          <th>{t("Fri")}</th>
                          <th>{t("Sat")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              placeholder={"--"}
                              id={"one_week_work_pattern.sun"}
                              name={"one_week_work_pattern.sun"}
                              allowClear
                              width={"80px"}
                              value={
                                formik?.values?.one_week_work_pattern.sun ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.sun",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              allowClear
                              width={"80px"}
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              placeholder={"--"}
                              id={"one_week_work_pattern.mon"}
                              name={"one_week_work_pattern.mon"}
                              value={
                                formik?.values?.one_week_work_pattern.mon ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.mon",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              allowClear
                              width={"80px"}
                              placeholder={"--"}
                              id={"one_week_work_pattern.tue"}
                              name={"one_week_work_pattern.tue"}
                              value={
                                formik?.values?.one_week_work_pattern.tue ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.tue",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              allowClear
                              width={"80px"}
                              placeholder={"--"}
                              id={"one_week_work_pattern.wed"}
                              name={"one_week_work_pattern.wed"}
                              value={
                                formik?.values?.one_week_work_pattern.wed ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.wed",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              allowClear
                              width={"80px"}
                              placeholder={"--"}
                              id={"one_week_work_pattern.thu"}
                              name={"one_week_work_pattern.thu"}
                              value={
                                formik?.values?.one_week_work_pattern.thu ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.thu",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              allowClear
                              width={"80px"}
                              placeholder={"--"}
                              id={"one_week_work_pattern.fri"}
                              name={"one_week_work_pattern.fri"}
                              value={
                                formik?.values?.one_week_work_pattern.fri ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.fri",
                                  val,
                                )
                              }
                            />
                          </td>
                          <td>
                            <SelectInput
                              options={shiftMasters?.data}
                              notFoundContent={<span>{"No data"}</span>}
                              allowClear
                              width={"80px"}
                              placeholder={"--"}
                              id={"one_week_work_pattern.sat"}
                              name={"one_week_work_pattern.sat"}
                              value={
                                formik?.values?.one_week_work_pattern.sat ||
                                null
                              }
                              onChange={(val) =>
                                formik?.setFieldValue(
                                  "one_week_work_pattern.sat",
                                  val,
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </OccupationModalBodyContainer>
          <br />
          <BtnContainers>
            <div
              style={{
                display: "flex",
                gap: "0.5em",
              }}
            >
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                textColor={theme.colors.text}
                borderColor={theme.colors.border}
                onClick={() => handleReset()}
              />
              <Button
                btnText={t("Save")}
                htmlType={"submit"}
                shape={"round"}
                textColor={theme.colors.white}
                type={"primary"}
                disabled={isDuplicate}
              />
            </div>

            {operation === "edit" && (
              <Button
                btnText={t("Delete")}
                btnBg={theme.colors.error}
                borderColor={theme.colors.error}
                textColor={theme.colors.white}
                shape={"round"}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
          </BtnContainers>
        </form>
      </Modal>
      {openDeleteModal && (
        <ConfirmationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
