import { Button, Card, getFullEraDate, theme } from "@project/common"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useReactToPrint } from "react-to-print"
import { getMonthlyUseStatusChart } from "../../../services/monthlyUseStatusChart.services"
import { MonthlyUseStatusChartOperationType } from "../../../types/monthlyUseStatusChart.types"
import {
  MonthlyUseStatusChartWrapper,
  StyledTableWrapper,
} from "./MonthlyUseStatusChartTable.style"
dayjs.extend(arraySupport)

const monthArray = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 100]

export const MonthlyUseStatusChartTable = ({
  params,
}: {
  params: MonthlyUseStatusChartOperationType
}): JSX.Element => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
    pageStyle: `
    @page {
      margin: 0.8cm;
    }
    `,
  })

  const checkForServiceTypeA: boolean = params?.service?.includes(1)
  const checkForServiceTypeB: boolean = params?.service?.includes(2)
  const checkForServiceTypeC: boolean = params?.service?.includes(3)

  const { data: response } = useQuery({
    queryKey: ["monthly-use-status-chart", params],
    queryFn: () =>
      getMonthlyUseStatusChart(params?.year, params?.facility, params?.service),
    refetchOnWindowFocus: false,
    select: (response) => ({
      daily_use_status: response?.data?.daily_use_status,
      other_info: response?.data?.other_info,
      total: response?.data?.total,
      facility_name: response?.data?.facility_name,
      service_type: response?.data?.service_type,
    }),
    retry: 0,
    cacheTime: 0,
  })

  const serviceType = () => {
    if (response?.service_type === 1) {
      return t("Employment Type A")
    } else if (response?.service_type === 2) {
      return t("Employment Type B")
    } else if (response?.service_type === 3) {
      return t("Employment Transition Support")
    }
    return null
  }

  return (
    <Card title={t("Monthly usage chart")}>
      <MonthlyUseStatusChartWrapper vertical gap={16} ref={contentRef}>
        <Typography.Title level={5} className={"print_title"}>
          {t("Monthly usage chart")}
        </Typography.Title>
        <div
          style={{
            color: theme.colors.error,
          }}
        >
          {t("monthly_chart_title")}
        </div>
        <Flex className={"action__btns"} gap={8} wrap={"wrap"}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={() => router.push("/")}
          />
          <Button
            btnText={t("Print")}
            iconType={"print"}
            type={"primary"}
            shape={"round"}
            onClick={handlePrint}
          />
        </Flex>
        <Flex vertical>
          <Typography.Text>{t("Monthly use status table")}</Typography.Text>
          <Flex gap={8} wrap={"wrap"}>
            <Typography.Text>
              {getFullEraDate(params?.year || dayjs().year(), 1)}
            </Typography.Text>
            <Typography.Text>
              {t("Business Name")} {" : "} {response?.facility_name}
            </Typography.Text>
            <Typography.Text>
              {t("Service type")} {" : "} {serviceType() || "-"}
            </Typography.Text>
          </Flex>
        </Flex>

        <StyledTableWrapper>
          <table id={"monthly-use-status-chart"}>
            <thead>
              <tr>
                <th colSpan={2}></th>
                {Array?.from({ length: 31 }, (_, index) => (
                  <th key={index}>
                    {index + 1}
                    {"日"}
                  </th>
                ))}
                <th id={"nowrap"}>{t("Total_number_of_users")}</th>
                <th id={"nowrap"}>{t("Number of days the office is open")}</th>
              </tr>
            </thead>

            {monthArray.map((month) => {
              let currentDate = null
              const total_info_user =
                response &&
                response?.other_info?.find((item) => item?.month == month)

              const totalOfficeDays = response?.other_info.reduce(
                (total, obj) => total + obj.total_facility_open,
                0,
              )

              const getDynamicTd = (isFirstTr?: boolean, key?: string) => {
                return Array?.from({ length: 31 }, (_, index) => {
                  const hasDayOfMonth =
                    index + 1 >
                    dayjs([+params?.year, month - 1, 1])?.daysInMonth()
                  const yearAsKey = `${params?.year}-${month
                    .toString()
                    .padStart(2, "0")}`

                  const total_value =
                    response &&
                    response?.total
                      ?.find((item) => item?.day == index + 1)
                      ?.[key]?.toString()

                  currentDate = `${params?.year}-${month
                    .toString()
                    .padStart(2, "0")}-${(index + 1)
                    .toString()
                    .padStart(2, "0")}`

                  const result =
                    response &&
                    response?.daily_use_status[yearAsKey]
                      ?.find(
                        (item) =>
                          dayjs(item?.date).format("YYYY-MM-DD") ===
                          currentDate,
                      )
                      ?.[key]?.toString()

                  return isFirstTr ? (
                    <td
                      key={index}
                      rowSpan={hasDayOfMonth && 4}
                      id={
                        hasDayOfMonth
                          ? month === 100
                            ? "empty__cell"
                            : "row_collapse_cell"
                          : "empty__cell"
                      }
                      style={{
                        background: key === "total_type_a" ? "white" : "",
                      }}
                    >
                      {month === 100
                        ? Number(total_value) || "-"
                        : hasDayOfMonth
                          ? ""
                          : Number(result) || "-"}
                    </td>
                  ) : !hasDayOfMonth ? (
                    <td key={index} id={"empty__cell"}>
                      {month === 100
                        ? Number(total_value) || "-"
                        : Number(result) || "-"}
                    </td>
                  ) : null
                })
              }
              const totalOfTypeA = response?.total.reduce(
                (total, obj) => total + obj.total_type_a,
                0,
              )
              const totalOfTypeB = response?.total.reduce(
                (total, obj) => total + obj.total_type_b,
                0,
              )
              const totalOfTypeC = response?.total.reduce(
                (total, obj) => total + obj.total_type_c,
                0,
              )
              const totalUser = response?.total.reduce(
                (total, obj) => total + obj.total_user,
                0,
              )

              return (
                <tbody key={month}>
                  <tr>
                    <td rowSpan={4} id={"date__cell"}>
                      {month === 100
                        ? t("Total")
                        : `${getFullEraDate(
                            params?.year,
                            month,
                          )}${`${month}`.padStart(2, "0")}月`}
                    </td>
                    {checkForServiceTypeA && (
                      <td id={"text__info-cell"}>
                        {t("No of users who used Employment type A")}
                      </td>
                    )}

                    {checkForServiceTypeA && getDynamicTd(true, "total_type_a")}
                    {checkForServiceTypeA && (
                      <td
                        id={"empty__cell"}
                        style={{
                          background: "white",
                        }}
                      >
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_type_a || "-"
                          : Number(totalOfTypeA) || "-"}
                      </td>
                    )}
                    {checkForServiceTypeA && (
                      <td
                        id={"empty__cell"}
                        style={{
                          background: "white",
                        }}
                      >
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_facility_open || "-"
                          : Number(totalOfficeDays) || "-"}
                      </td>
                    )}
                  </tr>

                  {checkForServiceTypeB && (
                    <tr>
                      <td id={"text__info-cell"}>
                        {t("No of users who used Employment type B")}
                      </td>
                      {getDynamicTd(
                        !checkForServiceTypeA ? true : false,
                        "total_type_b",
                      )}
                      <td id={"empty__cell"}>
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_type_b || "-"
                          : Number(totalOfTypeB) || "-"}
                      </td>
                      <td id={"empty__cell"}>
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_facility_open || "-"
                          : Number(totalOfficeDays) || "-"}
                      </td>
                    </tr>
                  )}
                  {checkForServiceTypeC && (
                    <tr>
                      <td id={"text__info-cell"}>
                        {t(
                          "No of users who used Employment transition service",
                        )}
                      </td>
                      {getDynamicTd(
                        !checkForServiceTypeA && !checkForServiceTypeB
                          ? true
                          : false,
                        "total_type_c",
                      )}
                      <td id={"empty__cell"}>
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_type_c || "-"
                          : Number(totalOfTypeC) || "-"}
                      </td>
                      <td id={"empty__cell"}>
                        {month != 100
                          ? response?.other_info?.find((o) => o?.month == month)
                              ?.total_facility_open || "-"
                          : Number(totalOfficeDays) || "-"}{" "}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td id={"text__info-cell"} className={"total_cell"}>
                      {t("Total_number_of_users1")}
                    </td>
                    {getDynamicTd(
                      !checkForServiceTypeA &&
                        !checkForServiceTypeB &&
                        !checkForServiceTypeC
                        ? true
                        : false,
                      "total_user",
                    )}
                    <td id={"empty__cell"}>
                      {month != 100
                        ? Number(total_info_user?.total_user) || "-"
                        : Number(totalUser) || "-"}
                    </td>
                    <td id={"empty__cell"}>
                      {month != 100
                        ? Number(total_info_user?.total_facility_open) || "-"
                        : Number(totalOfficeDays)}
                    </td>
                  </tr>
                </tbody>
              )
            })}
          </table>
        </StyledTableWrapper>
      </MonthlyUseStatusChartWrapper>
    </Card>
  )
}
