import {
  AccordionCard,
  Button,
  CheckBox,
  CheckBoxGroup,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import { Flex } from "antd"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../context"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { filterWithHiragana } from "../../utils"
import { FuriganaAlphabetsOption } from "../../utils/common-options"

export const UserWhoNeedsMonitoringOperation = ({
  searchParams,
  onSearchReset,
  onSearch,
}: {
  searchParams: any
  onSearchReset: () => void
  onSearch: (value: any) => void
}) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [tempSearchParams, setTempSearchParams] = useState<any>({
    furigana: "",
    facility: searchParams?.facility || [],
    useService: searchParams?.useService || [],
    user_id: searchParams?.user_id,
    user_without_services: searchParams?.user_without_services || null,
  })
  const { userData } = useFetchAllUsers({ page: 1, pageSize: "Infinity" })
  return (
    <AccordionCard
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility name")}
                    width={"138px"}
                  />
                </div>
                <FacilityCheckbox
                  options={facilities}
                  value={tempSearchParams?.facility}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setTempSearchParams({
                        ...tempSearchParams,
                        facility: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setTempSearchParams({
                        ...tempSearchParams,
                        facility: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setTempSearchParams({
                      ...tempSearchParams,
                      facility: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Use Service")}
                    width={"138px"}
                  />
                </div>
                <CheckBoxGroup
                  items={FACILITY_TYPES}
                  onChange={(list: CheckboxValueType[]) => {
                    setTempSearchParams({
                      ...tempSearchParams,
                      service: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <div className={"sm"}>
                  <LabelTextWithLeftArrow
                    label={t("User whose services are not registered")}
                    width={"138px"}
                  />
                </div>
                <CheckBox
                  name={"user_without_services"}
                  label={t("Show")}
                  value={tempSearchParams?.user_without_services}
                  checked={
                    tempSearchParams?.user_without_services ? true : false
                  }
                  onChange={({ target: { checked } }) => {
                    setTempSearchParams({
                      ...tempSearchParams,
                      user_without_services: checked,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User name")}
                  width={"138px"}
                />
                <Flex gap={8} wrap={"wrap"} className={"w-full"}>
                  <SelectInput
                    width={"86px"}
                    name={"user-name"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    placeholder={"---"}
                    onChange={(val) =>
                      setTempSearchParams({
                        ...tempSearchParams,
                        furigana: val,
                        user_id: null,
                      })
                    }
                  />
                  <SelectInput
                    name={"user-name"}
                    size={"large"}
                    placeholder={"--"}
                    width={"282px"}
                    popupMatchSelectWidth={false}
                    value={tempSearchParams?.user_id || null}
                    options={
                      filterWithHiragana({
                        options: userData,
                        furigana: tempSearchParams?.furigana || "",
                      }) || []
                    }
                    onChange={(value) =>
                      setTempSearchParams({
                        ...tempSearchParams,
                        user_id: value,
                      })
                    }
                  />
                </Flex>
              </div>
              <div className={"search__content btns"} style={{ gap: "10px" }}>
                <Button
                  btnText={t("Reset search")}
                  shape={"round"}
                  onClick={() => {
                    setTempSearchParams({
                      furigana: "",
                      facility: [],
                      useService: [],
                      user_id: "",
                      user_without_services: null,
                    })
                    onSearchReset()
                  }}
                />
                <Button
                  btnText={t("Search")}
                  iconType={"search"}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => onSearch(tempSearchParams)}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
