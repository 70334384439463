import {
  Button,
  Card,
  ErrorMassage,
  Grid,
  InputAreaField,
  InputField,
  RadioGroup,
  SelectInput,
  scrollToFirstErrorField,
} from "@project/common"
import { Flex, Spin, Typography, notification } from "antd"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import {
  FULL_DATE_FORMATE,
  PICK_DROP_OPTIONS,
  YES_NO_LIST,
} from "../../../constants"

import { CALANDER_HOURS_MINUTES } from "@project/common/src/constants/settings"
import { useMutation, useQuery } from "react-query"
import { AuthContext } from "../../../context"
import {
  addSchedulableUsersForAttendance,
  fetchAllShiftUserMaster,
  getSchedulableUsersForAttendance,
} from "../../../services"
import { filterWithHiragana } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"

export const DayListAddForm = ({ title }: { title: string }): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const { facilityId, date } = router.query as any

  const { data: userData, isLoading: isUserDataLoading } = useQuery(
    ["schedulable-users"],
    () =>
      getSchedulableUsersForAttendance({
        facility: facilityId?.toString(),
        date: date?.toString(),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (response) =>
        response?.data?.map((res) => ({
          label: res?.username,
          value: res?.id,
          furigana: res?.furigana,
        })),
    },
  )

  const { data: facilityUserShifts, isLoading: isShiftDataLoading } = useQuery(
    ["shifts"],
    () =>
      fetchAllShiftUserMaster({
        page: 1,
        pageSize: "Infinity",
        facilityIds: facilityId,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: ({ data }) =>
        data?.map((d) => ({ label: d?.attendance_shift_name, value: d?.id })),
      onError: (error: any) => {
        const msg =
          error?.data?.error?.message ||
          "Something went wrong. Please contact administrator."
        notification.error({
          message: msg,
        })
      },
    },
  )

  const { isLoading, mutate: saveUserAttendanceDailyList } = useMutation({
    mutationFn: addSchedulableUsersForAttendance,
    onSuccess: () => {
      notification.success({
        message: t("User attendance added successfully"),
      })
      router.push({
        pathname: `/user-attendance-calendar/daily-list/`,
        query: `facilityId=${facilityId}&date=${date}`,
      })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    submitCount,
    isValid,
    touched,
  } = useFormik({
    initialValues: {
      date,
      facility_id: facilityId,
      furigana: null,
      user_id: null,
      shift_id: null,
      pickup: 1,
      pickup_place: null,
      pickup_place_name: null,
      pickup_hour: null,
      pickup_minute: null,
      drop: 1,
      drop_place: null,
      drop_place_name: null,
      drop_hour: null,
      drop_minute: null,
      remarks: null,
      remarks_claim: null,
    },
    validationSchema: yup.object().shape({
      user_id: yup.number().required(t("Required")),
    }),
    onSubmit: (formValues) => {
      const pickValue = formValues.pickup == 1 ? 1 : 0
      const dropValue = formValues.drop == 1 ? 1 : 0
      const values = {
        facility_id: +facilityId?.toString(),
        user_id: formValues.user_id,
        shift_id: formValues.shift_id,
        date: formValues.date,
        goto_meet_flag: pickValue,
        goto_meet_place: pickValue ? +formValues.pickup_place : null,
        goto_meet_place_other: pickValue ? formValues.pickup_place_name : null,
        goto_meet_time: pickValue
          ? formValues.pickup_hour && formValues.pickup_minute
            ? `${formValues.pickup_hour}:${formValues.pickup_minute}`
            : null
          : null,
        take_flag: dropValue,
        take_place: dropValue ? +formValues.drop_place : null,
        take_place_other: dropValue ? formValues.drop_place_name : null,
        take_time: dropValue
          ? formValues.drop_hour && formValues.drop_minute
            ? `${formValues.drop_hour}:${formValues.drop_minute}`
            : null
          : null,
        remarks: formValues.remarks,
        remarks_nhif: formValues.remarks_claim,
      }
      saveUserAttendanceDailyList(values)
    },
  })
  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])
  return (
    <Card title={title}>
      {isUserDataLoading || isShiftDataLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size={"large"} />
        </div>
      ) : (
        <>
          {userData?.length == 0 ? (
            <>{t("There are no user available to add.")}</>
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                <Grid labelContent={t("Date")} background padding={"10px"}>
                  {dayjs(date).format(FULL_DATE_FORMATE)}
                </Grid>
                <Grid
                  labelContent={t("Facility Name")}
                  background
                  padding={"10px"}
                >
                  {facilities?.find((v) => v?.value === facilityId)?.label ||
                    ""}
                </Grid>
                <Grid labelContent={t("User Name")} background padding={"10px"}>
                  <Flex gap={8} wrap={"wrap"}>
                    <SelectInput
                      name={"hiragana"}
                      shortSelector
                      width={"78px"}
                      placeholder={"--"}
                      options={FuriganaAlphabetsOption}
                      value={values?.furigana || null}
                      onChange={(val) => {
                        setFieldValue("user_id", null)
                        setFieldValue("furigana", val)
                      }}
                    />
                    <SelectInput
                      name={"user_id"}
                      placeholder={"--"}
                      value={values?.user_id || null}
                      options={filterWithHiragana({
                        options: userData,
                        furigana: values?.furigana || "",
                      })}
                      onChange={(val) => setFieldValue("user_id", val)}
                      showMessage={false}
                      error={
                        errors?.user_id && touched?.user_id
                          ? errors?.["user_id"]?.toString()
                          : ""
                      }
                    />
                  </Flex>
                  {errors?.user_id && touched?.user_id && (
                    <ErrorMassage
                      message={errors?.["user_id"]?.toString()}
                      mt={"0px"}
                    />
                  )}
                </Grid>
                <Grid labelContent={t("Shift")} background padding={"10px"}>
                  <Flex gap={8} wrap={"wrap"}>
                    <SelectInput
                      name={"shift_id"}
                      placeholder={"--"}
                      value={values?.shift_id || null}
                      options={[{ label: "--", value: null }].concat(
                        facilityUserShifts,
                      )}
                      onChange={(val) => setFieldValue("shift_id", val)}
                      showMessage={false}
                      error={
                        errors?.user_id && touched?.user_id
                          ? errors?.["shift_id"]?.toString()
                          : ""
                      }
                    />
                  </Flex>
                  {errors?.shift_id && touched?.shift_id && (
                    <ErrorMassage
                      message={errors?.["shift_id"]?.toString()}
                      mt={"0px"}
                    />
                  )}
                </Grid>
                <Grid labelContent={t("Pickup")} background padding={"10px"}>
                  <RadioGroup
                    options={YES_NO_LIST}
                    value={+values?.pickup}
                    onChange={({ target: { value } }) =>
                      setFieldValue("pickup", value)
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t("Pickup Place")}
                  background
                  padding={"10px"}
                >
                  <Flex gap={8} wrap={"wrap"}>
                    <SelectInput
                      name={"pickup_place"}
                      value={values?.pickup_place || null}
                      placeholder={"--"}
                      onChange={(val) => setFieldValue("pickup_place", val)}
                      options={PICK_DROP_OPTIONS}
                      width={"208px"}
                      disabled={values.pickup != 1}
                    />
                    {values?.pickup_place === "3" && (
                      <div>
                        <InputField
                          name={"pickup_place_name"}
                          value={values?.pickup_place_name || null}
                          placeholder={"--"}
                          width={"200px"}
                          height={"40px"}
                          onChange={({ target: { value } }) =>
                            setFieldValue("pickup_place_name", value)
                          }
                          disabled={values.pickup != 1}
                        />
                      </div>
                    )}
                  </Flex>
                </Grid>
                <Grid
                  labelContent={t("Desired pick up time")}
                  background
                  padding={"10px"}
                >
                  <Flex align={"center"} gap={8}>
                    <SelectInput
                      name={"pickup_hour"}
                      width={"58px"}
                      placeholder={"--"}
                      value={values?.pickup_hour || null}
                      options={CALANDER_HOURS_MINUTES.hours}
                      onChange={(val) => {
                        setFieldValue("pickup_hour", val)
                        setFieldValue(
                          "pickup_minute",
                          val ? values.pickup_minute || "00" : null,
                        )
                      }}
                      disabled={values.pickup != 1}
                    />
                    <Typography.Text>{":"}</Typography.Text>
                    <SelectInput
                      name={"pickup_minute"}
                      width={"58px"}
                      placeholder={"--"}
                      value={values?.pickup_minute || null}
                      options={CALANDER_HOURS_MINUTES.minutes}
                      onChange={(val) => setFieldValue("pickup_minute", val)}
                      disabled={values.pickup != 1 || !values.pickup_hour}
                    />
                  </Flex>
                </Grid>
                <Grid labelContent={t("Drop Off")} background padding={"10px"}>
                  <RadioGroup
                    options={YES_NO_LIST}
                    name={"drop"}
                    value={+values?.drop}
                    onChange={({ target: { value } }) =>
                      setFieldValue("drop", value)
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t("Drop off Place")}
                  background
                  padding={"10px"}
                >
                  <Flex gap={8} wrap={"wrap"}>
                    <SelectInput
                      name={"drop_place"}
                      value={values?.drop_place || null}
                      placeholder={"--"}
                      onChange={(val) => setFieldValue("drop_place", val)}
                      options={PICK_DROP_OPTIONS}
                      width={"208px"}
                      disabled={values.drop != 1}
                    />
                    {values?.drop_place === "3" && (
                      <div>
                        <InputField
                          name={"drop_place_name"}
                          value={values?.drop_place_name || null}
                          placeholder={"--"}
                          width={"200px"}
                          height={"40px"}
                          onChange={({ target: { value } }) =>
                            setFieldValue("drop_place_name", value)
                          }
                          disabled={values.drop != 1}
                        />
                      </div>
                    )}
                  </Flex>
                </Grid>
                <Grid
                  labelContent={t("Desired drop off time")}
                  background
                  padding={"10px"}
                >
                  <Flex align={"center"} gap={8}>
                    <SelectInput
                      name={"drop_hour"}
                      width={"58px"}
                      placeholder={"--"}
                      value={values?.drop_hour || null}
                      options={CALANDER_HOURS_MINUTES.hours}
                      onChange={(val) => {
                        setFieldValue("drop_hour", val)
                        setFieldValue(
                          "drop_minute",
                          val ? values.drop_minute || "00" : null,
                        )
                      }}
                      disabled={values.drop != 1}
                    />
                    <Typography.Text>{":"}</Typography.Text>
                    <SelectInput
                      name={"drop_minute"}
                      width={"58px"}
                      placeholder={"--"}
                      value={values?.drop_minute || null}
                      options={CALANDER_HOURS_MINUTES.minutes}
                      onChange={(val) => setFieldValue("drop_minute", val)}
                      disabled={values.drop != 1 || !values?.drop_hour}
                    />
                  </Flex>
                </Grid>
                <Grid
                  labelContent={t("Scheduled actual expenses")}
                  background
                  padding={"10px"}
                ></Grid>
                <Grid labelContent={t("Remarks")} background padding={"10px"}>
                  <InputAreaField
                    name={"remarks"}
                    value={values?.remarks}
                    onChange={({ target: { value } }) =>
                      setFieldValue("remarks", value)
                    }
                  />
                </Grid>
                <Grid
                  labelContent={t(
                    "Remarks Claim to the national health insurance federation",
                  )}
                  background
                  padding={"10px"}
                >
                  <InputAreaField
                    name={"remarks_claim"}
                    value={values?.remarks_claim}
                    onChange={({ target: { value } }) =>
                      setFieldValue("remarks_claim", value)
                    }
                  />
                </Grid>
              </div>
              <br />
              <Flex gap={16} wrap={"wrap"}>
                <Button
                  btnText={t("Cancel")}
                  shape={"round"}
                  onClick={() => router.back()}
                  disabled={isLoading}
                />
                <Button
                  btnText={t("Save")}
                  type={"primary"}
                  shape={"round"}
                  htmlType={"submit"}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                />
              </Flex>
            </form>
          )}
        </>
      )}
    </Card>
  )
}
