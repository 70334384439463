export const extractFileName = (url?: string) => {
  const urlString = url
  const splittedUrl = urlString?.split("/").pop().split("%")
  splittedUrl?.pop()
  const splittedFileName = splittedUrl?.join().split("_")
  splittedFileName?.pop()
  const finalizedFileName = splittedFileName?.join("_")

  const fileFormat = url
    ?.split("/")
    .pop()
    .split("%")
    .slice(-1)[0]
    .split(".")
    .pop()
  return `${finalizedFileName}.${fileFormat}`
}
