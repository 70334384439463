import {
  CheckBoxGroup,
  DatePicker,
  InputAreaField,
  InputField,
  SelectInput,
  Tag,
  theme,
} from "@project/common"
import dayjs from "dayjs"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../../context"
import { Facilities } from "../../../../types/globalFacilities"
import { FirstTableContentWrapper } from "./FirstTable.style"
import { styled } from "styled-components"
import { useFetchAllStaff } from "../../../../hooks/useFetchData"

export const FirstContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .label {
    padding: 12px;
    background-color: rgb(243, 243, 243);
    width: 25%;
    text-transform: capitalize;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }

  .value,
  p {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px !important;
      font-weight: 400;
    }
  }

  .first-content-tr {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      border-radius: 10px !important;
      border: 1px solid #dddddd;
    }
    .label {
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border: none !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom: 1px solid #dddddd !important;
      }
    }
    .value {
      @media screen and (max-width: 540px) {
        border: none !important;
      }
    }
  }
  .first-content-tr:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
`
interface IProps {
  formik: any
  allWorkData: any
  setParams: (data: any) => void
}
const FirstTable: React.FC<IProps> = ({ formik, allWorkData, setParams }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const { staffData: allStaffData, isLoading } = useFetchAllStaff({
    page: 1,
    pageSize: "Infinity",
    facility: formik?.values?.facility_id,
    date_staff_is_active: formik?.values?.date,
  })

  return (
    <FirstTableContentWrapper>
      <table>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Facility  ")}</td>
          <td className={"value"}>
            <SelectInput
              width={"260px"}
              name={"facility_id"}
              id={"facility_id"}
              className={"field_value"}
              value={
                formik.values?.facility_id
                  ? String(formik.values?.facility_id)
                  : null
              }
              options={facilities as Facilities}
              placeholder={""}
              onChange={(value) => {
                setParams({
                  facility_id: value,
                })
                formik.setFieldValue("facility_id", value)
              }}
              error={
                formik.touched?.facility_id && formik.errors?.facility_id
                  ? t(`${formik.errors?.facility_id}`)
                  : ""
              }
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Date")}</td>
          <td className={"value"}>
            <DatePicker
              name={"date"}
              id={"date"}
              style={{
                width: "197px",
              }}
              onDateChange={(v) => {
                setParams({
                  date: dayjs(v).format("YYYY-MM-DD"),
                })
                formik.setFieldValue("date", dayjs(v).format("YYYY-MM-DD"))
              }}
              date={
                dayjs(formik?.values?.date)?.isValid()
                  ? dayjs(formik?.values?.date)
                  : null
              }
              format={"YYYY年MM月DD日"}
              error={
                formik.touched?.date && formik.errors?.date
                  ? t(`${formik.errors?.date}`)
                  : ""
              }
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("mrng")}</td>
          <td className={"value"}>
            <InputAreaField
              name={"morning"}
              id={"morning"}
              placeholder={t("Please enter")}
              className={"field_value"}
              padding={"8px"}
              borderRadius={"4px"}
              value={formik.values?.morning}
              onChange={(e) => formik.setFieldValue("morning", e.target.value)}
              onBlur={formik.handleBlur}
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("aftnoon")}</td>
          <td className={"value"}>
            <InputAreaField
              name={"afternoon"}
              id={"afternoon"}
              placeholder={t("Please enter")}
              className={"field_value"}
              padding={"8px"}
              borderRadius={"4px"}
              value={formik.values?.afternoon}
              onChange={(e) =>
                formik.setFieldValue("afternoon", e.target.value)
              }
              onBlur={formik.handleBlur}
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("wrk")}</td>
          <td className={"value"}>
            <SelectInput
              width={"100%"}
              name={"works"}
              id={"works"}
              className={"field_value"}
              value={formik.values?.works?.map((work_id: number) =>
                Number(work_id),
              )}
              options={allWorkData}
              mode={"multiple"}
              tagRender={(props) => {
                return (
                  <div className={"tags-wrapper"}>
                    <Tag
                      textColor={"#191919"}
                      className={"work-tag"}
                      closeIcon={<span>{`${props.label} ✖`} </span>}
                      closable={props?.closable}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLSpanElement>,
                      ) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onClose={() => {
                        const values = formik.values?.works?.filter(
                          (work_id: number) => work_id !== props.value,
                        )
                        formik.setFieldValue("works", values)
                      }}
                    >
                      {props.label}
                    </Tag>
                  </div>
                )
              }}
              dropdownRender={() => {
                return (
                  <div>
                    <CheckBoxGroup
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className={"work-items"}
                      value={formik.values?.works}
                      items={allWorkData}
                      onChange={(value) => {
                        formik.setFieldValue("works", value)
                      }}
                    />
                  </div>
                )
              }}
              placeholder={"---"}
              onBlur={() => formik.setFieldTouched("works", true)}
              height={"fit-content"}
              error={
                formik.touched.works && formik.errors.works
                  ? t(`${formik.errors?.works}`)
                  : ""
              }
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("others")}</td>
          <td className={"value"}>
            <InputAreaField
              name={"others"}
              placeholder={t("Please enter")}
              padding={"8px"}
              className={"field_value"}
              borderRadius={"4px"}
              value={formik.values?.others}
              onChange={(e) => formik.setFieldValue("others", e.target.value)}
              onBlur={formik.handleBlur}
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>
            {t("Accompanying staff working outside the facility")}
          </td>
          <td className={"value"}>
            <InputField
              name={"accompanying_staff"}
              placeholder={t("")}
              padding={"8px"}
              className={"field_value"}
              borderRadius={"4px"}
              maxLength={100}
              value={formik.values?.accompanying_staff || null}
              onChange={(e) =>
                formik.setFieldValue("accompanying_staff", e.target.value)
              }
              onBlur={formik.handleBlur}
            />
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Recorder:")}</td>
          <td className={"value"}>
            <SelectInput
              width={"200px"}
              name={"staff_id"}
              value={
                formik.values?.staff_id
                  ? +formik.values?.staff_id
                  : formik.values?.staff_id
              }
              options={allStaffData}
              placeholder={""}
              className={"field_value"}
              loading={isLoading}
              disabled={isLoading}
              onChange={(val) => formik.setFieldValue("staff_id", val)}
            />
          </td>
        </tr>
      </table>
    </FirstTableContentWrapper>
  )
}

export { FirstTable }
