import {
  ActionBtns,
  Button,
  Card,
  Grid,
  scrollToFirstErrorField,
  dynamicLangString,
  SelectInput,
  InputField,
  RadioGroup,
  MunicipalRadio,
  theme,
  DatePicker,
} from "@project/common"

import { useFormik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ListOfMunicipalSubsidySchema,
  ListOfMunicipalSubsidyValues,
} from "../../../types"
import styled from "styled-components"
import { Popconfirm, Skeleton, notification } from "antd"
import { useMutation, useQuery } from "react-query"
import {
  createMunicipalSubsidy,
  getAllUsers,
  getMunicipalSubsidy,
  getMunicipalUserById,
  updateMunicipalSubsidy,
} from "../../../services/ListOfMunicipalSubsidy"
import router from "next/router"
import { SelectFieldWrapper } from "../ListOfMunicipalSubsidy.style"
import { AuthContext } from "../../../context/AuthContext"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import dayjs, { Dayjs } from "dayjs"
import { convertToHalfWidth } from "@project/common/src/constants"

const StyledListOfMunicipalSubsidyForm = styled.div`
  .facility_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
  }
`
const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .sub_field_first,
  .sub_field_second,
  .sub_field_third {
    width: 100%;
    display: flex;
    gap: 40px;
    .sub_field_title {
      width: 20%;
      margin-top: 10px;
    }
    .second_field {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      justify-content: center;
      .title {
        color: #e00000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }
`
interface ListOfMunicipalSubsidyFormProps {
  id?: string | string[]
  title?: string
  isEdit?: boolean
  handleDelete?: (id) => void
  isDeleting?: boolean
}
export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
export const ListOfMunicipalSubsidyForm = ({
  id,
  title,
  isEdit,
  handleDelete,
  isDeleting,
}: ListOfMunicipalSubsidyFormProps): JSX.Element => {
  const { t } = useTranslation()
  const today = new Date()
  const { facilities } = useContext(AuthContext)
  const [address, setAddress] = useState(null)
  const [faxNumber, setFaxNumber] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [officeNo, setOfficeNo] = useState(null)
  const [isFacilityChange, setIsFacilityChange] = useState(false)
  const [startChar, setStartChar] = useState(null)
  const [certificateNumber, setCertificateNumber] = useState("")
  const [paymentName, setPaymentName] = useState("")
  const [municipalityNumber, setMunicipalityNumber] = useState("")
  const [isUserChange, setIsUserChange] = useState(false)
  const [userId, setUserId] = useState(null)
  const [facilityId] = useState(null)
  const FACILITY_OPTIONS = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name,
    }
  })
  const {
    data: ListOfMunicipalSubsidy,
    isLoading: ListOfMunicipalSubsidyLoading,
    isFetching: ListOfMunicipalSubsidyFetching,
  } = useQuery<any, Error>(
    ["municipalSubsidy", id],
    () => id && getMunicipalSubsidy(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return data
      },
    },
  )
  const initialValues: ListOfMunicipalSubsidyValues =
    ListOfMunicipalSubsidy || {
      facility_id: facilityId,
      year: router.query?.year ? +router.query?.year : today.getFullYear(),
      month: router.query?.month ? +router.query?.month : today.getMonth() + 1,
      user_id: userId,
      management_result: 0,
      municipality_number: "",
      burden_amount: "",
      subsidy_ratio: "",
      municipality_billing_amount: "",
    }

  const { mutate: mutateListOfMunicipalSubsidy, isLoading: isCreateLoading } =
    useMutation(id ? updateMunicipalSubsidy : createMunicipalSubsidy, {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: dynamicLangString([
              "Municipal Subsidy",
              "Updated Successfully",
            ]),
          })
        } else {
          notification.success({
            message: dynamicLangString([
              "Municipal Subsidy",
              "Created Successfully",
            ]),
          })
        }
        router.push("/list-of-municipal-subsidy-claims-information-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ListOfMunicipalSubsidySchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        ...values,
        year: parseInt(values?.year),
        month: parseInt(values?.month),
        municipality_billing_amount: +values?.municipality_billing_amount,
        burden_amount: +values?.burden_amount,
        subsidy_ratio: parseInt(String(values?.subsidy_ratio)),
        management_result: +values?.management_result,
        id: id,
        municipality_number: +values?.municipality_number,
      }
      mutateListOfMunicipalSubsidy(data)
    },
  })

  useEffect(() => {
    const filteredFacility = facilities?.filter(
      (facility: any) => facility?.id == formik.values.facility_id,
    )?.[0]
    setPhoneNumber(filteredFacility?.contact_phone)
    setAddress(filteredFacility?.address)
    setFaxNumber(filteredFacility?.fax)
    setOfficeNo(filteredFacility?.business_number)
  }, [isFacilityChange, formik.values.facility_id])

  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: ["getAllUsers", formik?.values?.facility_id],
    queryFn: () =>
      getAllUsers({
        page: 1,
        pageSize: "Infinity",
        facilityIds: `${formik?.values?.facility_id}`,
      }),
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.data.map((user) => ({
        label: user.username,
        value: user.id,
        furigana: user.user_name,
      }))
    },
  })

  const { data: getOneUser } = useQuery<any, Error>(
    ["get_user_by_id", formik?.values?.user_id],
    () =>
      formik?.values?.user_id && getMunicipalUserById(formik?.values?.user_id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!formik?.values?.user_id,
      select: ({ data }) => {
        return {
          number: data?.user_certificate?.number,
          payment_name:
            data?.user_certificate?.provision_city?.provision_city_name,
          municipality_number:
            data?.user_certificate?.provision_city?.municipality_number,
          burden_amount:
            ListOfMunicipalSubsidy?.burden_amount ||
            data?.user_certificate?.burden_upper_limit_monthly_amount,
        }
      },
    },
  )

  useEffect(() => {
    setCertificateNumber(getOneUser?.number)
    setPaymentName(getOneUser?.payment_name)
    setMunicipalityNumber(getOneUser?.municipality_number)
    ListOfMunicipalSubsidy?.id
      ? formik?.setFieldValue(
          "burden_amount",
          ListOfMunicipalSubsidy?.burden_amount,
        )
      : formik?.setFieldValue("burden_amount", getOneUser?.burden_amount)
  }, [formik?.values?.user_id, getOneUser])

  const handleCalculation = () => {
    if (
      isNaN(+formik?.values?.burden_amount) ||
      isNaN(+formik?.values?.subsidy_ratio)
    )
      return
    let _amount =
      (+formik?.values?.burden_amount * +formik?.values?.subsidy_ratio) / 100
    if (formik?.values?.management_result == 0) {
      _amount = Math.trunc(_amount)
      formik.setFieldValue("municipality_billing_amount", _amount)
    } else if (formik?.values?.management_result == 1) {
      _amount = Math.ceil(_amount)
      formik.setFieldValue("municipality_billing_amount", _amount)
    } else if (formik?.values?.management_result == 2) {
      _amount = Math.round(_amount)
      formik.setFieldValue("municipality_billing_amount", _amount)
    }
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  return (
    <StyledListOfMunicipalSubsidyForm>
      <div style={{ marginBottom: "20px" }}></div>
      <Card title={title}>
        {ListOfMunicipalSubsidyFetching ? (
          <>
            <Skeleton active />
            <br />
            <Skeleton active />
            <br />
            <br />
            <Skeleton active />
            <br />
            <Skeleton active />
          </>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Grid
                  labelContent={t("Year Month")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                >
                  <SelectFieldWrapper>
                    <DatePicker
                      picker={"month"}
                      date={dayjs([
                        +formik.values.year,
                        +formik.values.month - 1,
                      ])}
                      onDateChange={(date: Dayjs) => {
                        formik.setFieldValue("year", dayjs(date).year())
                        formik.setFieldValue("month", +dayjs(date).month() + 1)
                      }}
                      format={"YYYY年MM月"}
                    />
                  </SelectFieldWrapper>
                </Grid>
                <Grid
                  labelContent={t("Facility")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                >
                  <div className={"first-select-content"}>
                    <SelectInput
                      width={"282px"}
                      name={"facility_id"}
                      options={FACILITY_OPTIONS}
                      placeholder={"--"}
                      value={formik.values.facility_id}
                      onChange={(value) => {
                        formik.setFieldValue("facility_id", value)
                        setIsFacilityChange(!isFacilityChange)
                        formik.setFieldValue("user_id", null)
                      }}
                      error={
                        formik.touched?.facility_id &&
                        formik.errors?.facility_id
                          ? t(`${formik.errors?.facility_id}`)
                          : ""
                      }
                    />
                  </div>
                  {formik.values?.facility_id && (
                    <div className={"facility_content"}>
                      <div className={"facility_text"}>
                        <span>{t("Office Number")}</span>
                        <span>{" : "}</span>
                        <span>{officeNo}</span>
                      </div>
                      <div className={"facility_text"}>
                        <span>{t("Address")}</span>
                        <span>{" : "}</span>
                        <span>{address}</span>
                      </div>
                      <div className={"facility_text"}>
                        <span>{t("Tel")}</span>
                        <span>{" : "}</span>
                        <span>{phoneNumber}</span>
                      </div>
                      <div className={"facility_text"}>
                        <span>{t("FAX Number")}</span>
                        <span>{" : "}</span>
                        <span>{faxNumber}</span>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid
                  labelContent={t("User2")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                  id={"user_id"}
                >
                  <div
                    className={"user_select"}
                    style={{ display: "flex", gap: "10px" }}
                    id={"user_id"}
                  >
                    <SelectInput
                      name={"user"}
                      width={"108px"}
                      placeholder={"---"}
                      options={FuriganaAlphabetsOption}
                      value={startChar}
                      onChange={(val) => {
                        setStartChar(val)
                        setUserId(null)
                      }}
                    />
                    <SelectInput
                      name={"user_id"}
                      size={"large"}
                      placeholder={"--"}
                      width={"307px"}
                      value={formik.values.user_id}
                      loading={isUserLoading}
                      onChange={(v) => {
                        formik.setFieldValue("user_id", v)
                        setIsUserChange(!isUserChange)
                      }}
                      options={
                        user?.length
                          ? startChar
                            ? [
                                ...user?.filter(
                                  (user) =>
                                    user.furigana?.startsWith(startChar) ||
                                    user.label?.startsWith(startChar),
                                ),
                              ]
                            : user
                          : []
                      }
                      error={
                        formik.touched?.user_id && formik.errors?.user_id
                          ? t(`${formik.errors?.user_id}`)
                          : ""
                      }
                    />
                  </div>
                  {formik.values.user_id && (
                    <div className={"facility_content"}>
                      <div>
                        {t("Recipient certificate number")}
                        {" ："}
                        {certificateNumber || ""}
                      </div>
                      <div>
                        {t("Payment municipality name")}
                        {" : "} {paymentName || ""}
                      </div>
                      <div>
                        {t("Municipal number")}
                        {" : "} {municipalityNumber || ""}
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid
                  labelContent={t("Municipal subsidy invoice amount")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <InvoiceWrapper className={"first-select-content"}>
                    <div className={"sub_field_first"}>
                      <div className={"sub_field_title"}>
                        <label>{t("User burden")}</label>
                      </div>
                      <div className={"second_field"}>
                        <div className={"first-select-content field"}>
                          <InputField
                            name={"burden_amount"}
                            className={"input__content"}
                            padding={"8px"}
                            borderRadius={"4px"}
                            type={"number"}
                            subInfo={"円"}
                            maxLength={15}
                            value={formik.values?.burden_amount}
                            onKeyDown={blockInvalidChar}
                            width={"240px"}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "burden_amount",
                                e.target.value,
                              )
                            }}
                          />
                        </div>
                        <div className={"field_title"}>
                          <p className={"title"}>
                            {t(
                              "※If the upper limit is managed at another facility, please check the management result user burden amount on the user burden maximum amount management result sheet.",
                            )}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className={"sub_field_second"}>
                      <div className={"sub_field_title"}>
                        <label>{t("Subsidy rate")}</label>
                      </div>
                      <div className={"second_field"}>
                        <div className={"first-select-content field"}>
                          <InputField
                            name={"subsidy_ratio"}
                            className={"input__content"}
                            padding={"8px"}
                            type={"number"}
                            borderRadius={"4px"}
                            subInfo={"%"}
                            maxLength={15}
                            value={formik.values?.subsidy_ratio}
                            width={"240px"}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "subsidy_ratio",
                                e.target.value,
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"sub_field_third"}>
                      <RadioGroup
                        options={MunicipalRadio}
                        value={formik.values.management_result}
                        name={"management_result"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "management_result",
                            e.target.value,
                          )
                        }}
                      />
                    </div>
                    <div className={"button"}>
                      <Button
                        btnText={t("Calculate")}
                        type={"default"}
                        shape={"round"}
                        onClick={() => handleCalculation()}
                      />
                    </div>
                  </InvoiceWrapper>
                </Grid>
                <Grid
                  labelContent={t("Local government subsidy amount requested")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <div className={"first-select-content"}>
                    <InputField
                      name={"municipality_billing_amount"}
                      className={"input__content"}
                      padding={"8px"}
                      borderRadius={"4px"}
                      subInfo={"円"}
                      info={t("(半角数字6文字以内)")}
                      maxLength={6}
                      value={formik.values?.municipality_billing_amount}
                      error={formik.errors?.municipality_billing_amount}
                      width={"319px"}
                      onChange={(e: any) => {
                        const inputValue = e.target.value
                        if (inputValue === "") {
                          formik.setFieldValue(
                            "municipality_billing_amount",
                            null,
                          )
                        } else {
                          const parsedValue = parseInt(inputValue)
                          if (!isNaN(parsedValue)) {
                            formik.setFieldValue(
                              "municipality_billing_amount",
                              parsedValue === 0
                                ? null
                                : convertToHalfWidth(parsedValue),
                            )
                          }
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  labelContent={t("Grant municipality number")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                >
                  <div className={"first-select-content"}>
                    <InputField
                      name={"municipality_number"}
                      className={"input__content"}
                      padding={"8px"}
                      borderRadius={"4px"}
                      maxLength={15}
                      disabled={formik.values?.id > 0}
                      value={formik.values?.municipality_number}
                      width={"319px"}
                      onChange={(e: any) => {
                        const inputValue = e.target.value
                        if (inputValue === "") {
                          formik.setFieldValue("municipality_number", null)
                        } else {
                          const parsedValue = parseInt(inputValue)
                          if (!isNaN(parsedValue)) {
                            formik.setFieldValue(
                              "municipality_number",
                              parsedValue === 0 ? null : parsedValue,
                            )
                          }
                        }
                      }}
                    />
                  </div>
                </Grid>
              </div>
              <br />
              <ActionBtns justify={"none"}>
                <div className={"btn_wrapper"}>
                  <div className={"btn_content"}>
                    <Button
                      btnText={t("Cancel")}
                      type={"default"}
                      shape={"round"}
                      onClick={() => router.back()}
                      disabled={
                        ListOfMunicipalSubsidyLoading || isCreateLoading
                      }
                    />
                    <Button
                      btnText={t("Save")}
                      shape={"round"}
                      type={"primary"}
                      htmlType={"submit"}
                      isLoading={
                        ListOfMunicipalSubsidyLoading || isCreateLoading
                      }
                    />
                  </div>
                  <div className={"delete_btn"}>
                    {isEdit && (
                      <Popconfirm
                        title={t("Deleting.Is that OK?")}
                        okText={t("OK")}
                        onConfirm={() => handleDelete(id)}
                        cancelText={t("Cancel")}
                        okButtonProps={{ size: "middle" }}
                        cancelButtonProps={{ size: "middle" }}
                      >
                        <Button
                          shape={"round"}
                          btnText={t("Delete ")}
                          type={"default"}
                          btnBg={theme.colors.error}
                          className={"delete-button"}
                          loading={isDeleting}
                        />
                      </Popconfirm>
                    )}
                  </div>
                </div>
              </ActionBtns>
            </form>
          </>
        )}
      </Card>
    </StyledListOfMunicipalSubsidyForm>
  )
}
