import { FACILITY_SERVICE_TYPES } from "@project/common"
import dayjs from "dayjs"

import { useTranslation } from "react-i18next"
import { downloadImage } from "../../../../utils/utility-function"

export const generateDisabilityLabel = (response) => {
  const { t } = useTranslation()

  let label = ""
  if (response?.user_certificate?.physical_disability_flag) {
    if (label) label += "/"
    label += t("Physical disability")
  }
  if (response?.user_certificate?.intellectual_disability_flag) {
    label += t("Intellectual disability")
  }
  if (response?.user_certificate?.mental_disability_flag) {
    if (label) label += "/"
    label += t("Mental disability")
  }
  if (response?.user_certificate?.development_disability_flag) {
    if (label) label += "/"
    label += t("Developmental disability")
  }
  if (response?.user_certificate?.brain_disfunction_flag) {
    if (label) label += "/"
    label += t("Higher brain dysfunction")
  }
  if (response?.user_certificate?.incurable_disease_flag) {
    if (label) label += "/"
    label += t("Target people with incurable diseases")
  }
  return label
}

const BeneficiaryCertificateTable = ({ response }) => {
  const { t } = useTranslation()

  const serviceType = t(
    FACILITY_SERVICE_TYPES[response?.user_certificate?.service_type],
  )
  return (
    <>
      <table>
        <tr className={"fourth-table"}>
          <td className={"label"}>
            {t("Registration of beneficiary certificate information")}
          </td>
          <td className={"value"}>
            {response?.user_certificate?.user_id ? t("do") : t("Do not")}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>
            {t("Registration of beneficiary certificate start date")}
          </td>
          <td className={"value"}>
            {(dayjs(response?.user_certificate.start_date).isValid() &&
              dayjs(response?.user_certificate.start_date).format(
                "YYYY年MM月DD日",
              )) ||
              "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>
            {t("Upload original recipient certificate")}
          </td>
          <td className={"value link"}>
            {response?.user_certificate?.certificate_original ? (
              <a
                href={"javascript: void(0)"}
                onClick={() =>
                  downloadImage(
                    response?.user_certificate?.certificate_original,
                  )
                }
              >
                {t("Download")}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Beneficiary certificate number")}</td>
          <td className={"value"}>
            {response?.user_certificate?.number || "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Provision city")}</td>
          <td className={"value"}>
            {response?.user_certificate?.provision_city?.provision_city_name ||
              "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Service to use")}</td>
          <td className={"value"}>{serviceType || "-"}</td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("User with disabilities")}</td>
          <td className={"value"}>
            {generateDisabilityLabel(response) || "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Benefit payment amount")}</td>
          <td className={"value"}>
            {response?.user_certificate?.benefit_payment_amount || "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>
            {t("Benefit decision/payment/application period input")}
          </td>
          <td className={"value"}>{"-"}</td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Benefit determination period")}</td>
          <td className={"value"}>
            {(response?.user_certificate?.benefit_start_date &&
              response?.user_certificate?.benefit_end_date &&
              `${dayjs(response?.user_certificate?.benefit_start_date).format(
                "YYYY年MM月DD日",
              )} - ${dayjs(response?.user_certificate?.benefit_end_date).format(
                "YYYY年MM月DD日",
              )}`) ||
              "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Provision period")}</td>
          <td className={"value"}>
            {(response?.user_certificate?.benefit_start_date &&
              response?.user_certificate?.provision_end_date &&
              `${dayjs(response?.user_certificate?.provision_start_date).format(
                "YYYY年MM月DD日",
              )}- ${dayjs(
                response?.user_certificate?.provision_end_date,
              ).format("YYYY年MM月DD日")}`) ||
              "-"}
          </td>
        </tr>

        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Consultation support office")}</td>
          <td className={"value"}>
            {response?.user_certificate?.consultation_support_office
              ?.consultation_support_name || "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Consultation support specialist")}</td>
          <td className={"value"}>
            {response?.user_certificate?.consultation_support_specialist || "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Burden upper limit monthly amount")}</td>
          <td className={"value"}>
            {response?.user_certificate?.burden_upper_limit_monthly_amount ??
              "-"}
          </td>
        </tr>
        <tr className={"fourth-table"}>
          <td className={"label"}>{t("Applicable period")}</td>
          <td className={"value"}>
            {(response?.user_certificate?.applicable_start_date &&
              response?.user_certificate?.applicable_end_date &&
              `${dayjs(
                response?.user_certificate?.applicable_start_date,
              ).format("YYYY年MM月DD日")}- ${dayjs(
                response?.user_certificate?.applicable_end_date,
              ).format("YYYY年MM月DD日")}`) ||
              "-"}
          </td>
        </tr>
      </table>
    </>
  )
}

export default BeneficiaryCertificateTable
