import styled from "styled-components"
import { t } from "i18next"
import PrintWithBreakdown from "./breakdown"
import {
  FACILITY_SERVICE_TYPES_V2,
  theme,
  USER_RECEIPT_DISPLAY_SETTING_VALUES,
} from "@project/common"

const UsageStyleWrapper = styled.div`
  width: 100%;
  &.receipt-wrap {
    border: 1px solid ${theme.colors.border};
    padding: 20px;
    margin-bottom: 20px;
    @media print {
      padding: 0;
      border: none;
      &:not(:last-child) {
        page-break-after: always;
      }
    }
  }
  .charge-title {
    margin: 10px 0;
  }
  .upper-limit-table table {
    border-collapse: collapse;
    margin-bottom: 10px;
    td {
      border: 1px solid #000;
      min-width: 25px;
      padding: 0 2px;
      text-align: center;
    }
  }
  .service-content-table {
    width: 100%;
    margin-bottom: 10px;
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        page-break-inside: avoid;
      }
      td {
        border: 1px solid #000;
        padding: 0 2px;
      }
      .center-align {
        text-align: center;
      }
      .max-wd-20 {
        max-width: 20px;
        word-break: break-word;
      }
      .digit-cell {
        width: 20px;
      }
    }
  }
  .billing-summary-table {
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        page-break-inside: avoid;
      }
      td {
        min-width: 20px;
        padding: 0 2px;
        border: 1px solid #000;
        text-align: center;
      }
      .blank-cell {
        height: 5px;
        border: none;
      }
      .max-wd-20 {
        max-width: 20px;
        word-break: break-word;
      }
      .no-right-border {
        border-right: none;
      }
      .small-cell {
        min-width: 16px;
        width: 16px;
      }
      .small-font {
        font-size: 6pt;
      }
      .diagonal-cross {
        position: relative;
        overflow: hidden;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: " ";
          border-left: 1px solid #000;
          transform: rotate(45deg) translate(10px, 0px);
        }
      }
    }
  }

  @media print {
    .billing-summary-table {
      padding-top: 20px;
    }
  }
`

interface IUsageDetails {
  params: {
    display_setting: Array<string>
    print_configuration: string
    date: string
  }
  data: any
  isCopy?: boolean
  era?: string
  asPartialTemplate?: boolean
  provisionText?: string
}

const PrintWithUsageDetails = ({
  params,
  data,
  isCopy = false,
  era = "",
  provisionText = "",
}: IUsageDetails) => {
  const renderUpperLimitTable = () => {
    const upperLimitAmount = data?.usage_breakdown?.c_02 || ""
    const numberOfCellsForAmount = Math.max(5, upperLimitAmount.length)
    return (
      <table className={"upper-limit-table"}>
        <tr>
          <td>{t("User fee upper limit per month①")}</td>
          {upperLimitAmount
            .padStart(numberOfCellsForAmount, " ")
            .split("")
            .map((digit, idx) => (
              <td key={digit + idx}>{digit}</td>
            ))}
        </tr>
      </table>
    )
  }

  const renderServiceContentTable = () => {
    let serviceCodeCells = 6,
      numberOfCreditsCells = 6,
      numerOfTimesCells = 2,
      serviceUnitsCells = 5
    let serviceContent = []
    if (Array.isArray(data?.usage_breakdown?.c_04_to_c08)) {
      serviceContent = data?.usage_breakdown?.c_04_to_c08
    }
    serviceContent.forEach((content) => {
      // determining number of cells for service code
      if (content?.c_05) {
        const c05len = content?.c_05.length
        if (serviceCodeCells < c05len) {
          serviceCodeCells = c05len
        }
      }
      //   determining number of cells for credits
      if (content?.c_06) {
        const c06len = content?.c_06?.length
        if (numberOfCreditsCells < c06len) {
          numberOfCreditsCells = c06len
        }
      }
      //   determining number of times cell
      if (content?.c_07) {
        const c07len = content?.c_07?.length
        if (numerOfTimesCells < c07len) {
          numerOfTimesCells = c07len
        }
      }
      // determining number of cells for service unit
      if (content?.c_08) {
        const c08len = content?.c_08?.length
        if (serviceUnitsCells < c08len) {
          serviceUnitsCells = c08len
        }
      }
    })

    const displayBreakDownCell = (content, count, key) =>
      content
        .toString()
        .padStart(count, " ")
        .split("")
        .map((c, i) => (
          <td key={`${key}_${i}`} className={"center-align digit-cell"}>
            {c}
          </td>
        ))

    return (
      <table>
        {/* first row with headers */}
        <tr>
          <td
            rowSpan={1 + serviceContent.length}
            className={"center-align max-wd-20"}
          >
            {t("Benefit expense details")}
          </td>
          <td className={"center-align"}>{t("Service contents")}</td>
          <td colSpan={serviceCodeCells} className={"center-align"}>
            {t("Service Code")}
          </td>
          <td colSpan={numberOfCreditsCells} className={"center-align"}>
            {t("Number of credits")}
          </td>
          <td colSpan={numerOfTimesCells} className={"center-align"}>
            {t("Number of times")}
          </td>
          <td colSpan={serviceUnitsCells} className={"center-align"}>
            {t("Service Units")}
          </td>
          <td className={"center-align"}>{t("Abstract")}</td>
        </tr>
        {serviceContent.map((content, idx) => (
          <tr key={`content_row_${idx}`}>
            <td>{content?.c_04}</td>
            {displayBreakDownCell(
              content?.c_05,
              serviceCodeCells,
              "service_code",
            )}
            {displayBreakDownCell(
              content?.c_06,
              numberOfCreditsCells,
              "credits",
            )}
            {displayBreakDownCell(content?.c_07, numerOfTimesCells, "times")}
            {displayBreakDownCell(content?.c_08, serviceUnitsCells, "units")}
            <td className={"center-align"}>{data?.facility_name}</td>
          </tr>
        ))}
      </table>
    )
  }

  const renderBillingSummaryTable = () => {
    const minimumCells = 6
    const serviceTypeCode = (data?.usage_breakdown?.c_09 || "").padStart(2, " ")
    const serviceTypeCodeCells = serviceTypeCode.split("")
    const arrayForRepetationCells = [" ", " ", " ", " "]
    const serviceUsedCells = (data?.usage_breakdown?.c_10 || "")
      .padStart(serviceTypeCodeCells.length, " ")
      .split("")
    const unitsGranted = data?.usage_breakdown?.c_11?.toString()
    const perUnitPrice = data?.usage_breakdown?.c_12.replace(".", "")
    const cellsAllocatedForYenUnit = 2
    const cellsAllocatedForPer100 = 3
    const totalPrice = data?.usage_breakdown?.c_13
    const ninetyPercent = Math.floor(
      totalPrice - data?.usage_breakdown?.c_14,
    )?.toString()
    const upperLimitAdjustment = data?.usage_breakdown?.c_16?.toString()
    const adjustedCharges = (+data?.usage_breakdown?.c_19 || "").toString()
    const chargeAfterUpperLimitManagement = (
      (+data?.usage_breakdown?.c_20 > -1 && +data?.usage_breakdown?.c_20) ||
      ""
    ).toString()
    const determinedUserFee = (+data?.usage_breakdown?.c_21 || "").toString()
    const benefitAmount = data?.usage_breakdown?.c_22
    const susidy = (+data?.usage_breakdown?.c_23 || "").toString()

    const numberOfBrokenCells = Math.max(
      minimumCells,
      unitsGranted.length,
      perUnitPrice.length,
      totalPrice.length,
    )

    return (
      <table>
        {/* first row */}
        <tr>
          <td rowSpan={18} className={"max-wd-20 no-right-border"}>
            {t("Billing amount summary column")}
          </td>
          <td colSpan={2}>{t("Service Type Code")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {serviceTypeCodeCells?.map((s) => (
                  <td
                    key={`service_type_code_${s || idx}`}
                    className={"small-cell"}
                  >
                    {!idx ? s : ""}
                  </td>
                ))}
                <td colSpan={numberOfBrokenCells - serviceTypeCodeCells.length}>
                  {idx > 0
                    ? ""
                    : t(FACILITY_SERVICE_TYPES_V2?.[+data?.service_type])}
                </td>
              </>
            )
          })}
          <td
            colSpan={numberOfBrokenCells}
            rowSpan={2}
            className={"small-cell"}
          >
            {t("Total")}
          </td>
        </tr>
        {/* service use days */}
        <tr>
          <td colSpan={2}>{t("Number of days of service use")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {serviceUsedCells?.map((s) => (
                  <td
                    key={`service_used_days_${s || idx}`}
                    className={"small-cell"}
                  >
                    {!idx ? s : ""}
                  </td>
                ))}
                <td
                  colSpan={numberOfBrokenCells - serviceUsedCells.length}
                  className={"small-cell"}
                >
                  {t("Days")}
                </td>
              </>
            )
          })}
        </tr>
        {/* units */}
        <tr>
          <td colSpan={2}>{t("Number of units granted")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {unitsGranted
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td key={`units_${idx}_${i}`} className={"small-cell"}>
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {unitsGranted
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`units_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* unit price */}
        <tr>
          <td colSpan={2}>{t("Unit price")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {perUnitPrice
                  ?.padStart(
                    numberOfBrokenCells - cellsAllocatedForYenUnit,
                    " ",
                  )
                  .split("")
                  .map((c, i) => (
                    <td key={`unit_price_${idx}_${i}`} className={"small-cell"}>
                      {!idx ? c : " "}
                    </td>
                  ))}
                <td colSpan={cellsAllocatedForYenUnit} className={"small-font"}>
                  {t("Yen/unit")}
                </td>
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`unit_price_total_${i}`}
                className={"small-cell diagonal-cross"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* benefit rate */}
        <tr>
          <td colSpan={2}>{t("Benefit rate")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {"90"
                  ?.padStart(
                    !idx
                      ? numberOfBrokenCells - cellsAllocatedForPer100
                      : numberOfBrokenCells,
                    " ",
                  )
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`benefit_rate_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
                {!idx && <td colSpan={cellsAllocatedForPer100}>{"/100"}</td>}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`unit_price_total_${i}`}
                className={"small-cell diagonal-cross"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* total cost */}
        <tr>
          <td colSpan={2}>{t("Total cost")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {totalPrice
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`total_price_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {totalPrice
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`unit_price_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* based benefit rate */}
        <tr>
          <td rowSpan={2}>{t("Based on benefit rate")}</td>
          <td>{t("Billing amount")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {ninetyPercent
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`total_price_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`unit_price_total_${i}`}
                className={"small-cell diagonal-cross"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* user charge */}
        <tr>
          <td>{t("User Charge②")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {data?.usage_breakdown?.c_15
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`user_burden2_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`unit_price_total_${i}`}
                className={"small-cell diagonal-cross"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* upper limit adjustment */}
        <tr>
          <td colSpan={2}>
            {t("Upper limit monthly adjustment (the lesser of ① and ②)")}
          </td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {upperLimitAdjustment
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`upper_limit_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {upperLimitAdjustment
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`upper_limit_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* type a reduction */}
        <tr>
          <td rowSpan={2}>{t("Type A reduction")}</td>
          <td>{t("Business reduction amount")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {data?.usage_breakdown?.c_17
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`business_reduction_amount${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`business_reduction_total_${i}`}
                className={"small-cell"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* user charge after excemption */}
        <tr>
          <td>{t("User charges after reduction/exemption")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {data?.usage_breakdown?.c_18
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`charges_after_reduction_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`unit_price_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* adjusted user charges */}
        <tr>
          <td colSpan={2}>{t("Adjusted user charges")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {adjustedCharges
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`adjusted_charge_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {adjustedCharges
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`adjusted_charges_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* user charges after upper limit management */}
        <tr>
          <td colSpan={2}>{t("User charges after upper limit management")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {chargeAfterUpperLimitManagement
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`user_charge_after_management_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {chargeAfterUpperLimitManagement
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`user_charge_after_management_total_${i}`}
                className={"small-cell"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* determined user fee */}
        <tr>
          <td colSpan={2}>{t("Decided user fee")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {determinedUserFee
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`decided_user_fee_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {determinedUserFee
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`decided_user_fee_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* billing amount */}
        <tr>
          <td rowSpan={2}>{t("Billing amount")}</td>
          <td>{t("Benefits")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {benefitAmount
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`benefit_amount${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {benefitAmount
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`benefit_amount_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* special expenses */}
        <tr>
          <td>{t("Special measures expenses")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {" "
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`special_expenses_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : " "}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`epsecial_expenses_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
        {/* blank cell */}
        <tr>
          <td colSpan={2} className={"blank-cell"}></td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {" "
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`blank_cell_${idx}_${i}`}
                      className={"blank-cell small-cell"}
                    >
                      {c}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {" "
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td
                key={`blank_cell_total_${i}`}
                className={"blank-cell small-cell"}
              >
                {c}
              </td>
            ))}
        </tr>
        {/* local subsidy */}
        <tr>
          <td colSpan={2}>{t("Local government subsidy claims")}</td>
          {arrayForRepetationCells.map((_, idx) => {
            return (
              <>
                {susidy
                  ?.padStart(numberOfBrokenCells, " ")
                  .split("")
                  .map((c, i) => (
                    <td
                      key={`subsidy_cell_${idx}_${i}`}
                      className={"small-cell"}
                    >
                      {!idx ? c : ""}
                    </td>
                  ))}
              </>
            )
          })}
          {/* total */}
          {susidy
            ?.padStart(numberOfBrokenCells, " ")
            .split("")
            .map((c, i) => (
              <td key={`subsidy_cell_total_${i}`} className={"small-cell"}>
                {c}
              </td>
            ))}
        </tr>
      </table>
    )
  }
  const title = params?.display_setting?.includes(
    USER_RECEIPT_DISPLAY_SETTING_VALUES?.CHANGE_WORD_DISABILITY,
  )
    ? t("■Calculation of user charges for child disable benefits")
    : t("■Calculation of user charges for child disability benefits")

  return (
    <UsageStyleWrapper className={"receipt-wrap"}>
      <PrintWithBreakdown
        params={params}
        data={data}
        isCopy={isCopy}
        era={era}
        asPartialTemplate={true}
        provisionText={provisionText}
      />
      <div className={"charge-title"}>
        {params?.display_setting?.includes(
          USER_RECEIPT_DISPLAY_SETTING_VALUES?.CHANGE_WORD_DISABILITY,
        )
          ? title?.replace("障害", "障がい")
          : title}
      </div>
      <div className={"upper-limit-table"}>{renderUpperLimitTable()}</div>
      <div className={"service-content-table"}>
        {renderServiceContentTable()}
      </div>
      <div className={"billing-summary-table"}>
        {renderBillingSummaryTable()}
      </div>
    </UsageStyleWrapper>
  )
}

export default PrintWithUsageDetails
