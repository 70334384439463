import { theme } from "@project/common"
import { styled } from "styled-components"

export const AbsenceRecordOperationContainer = styled.div`
  margin-bottom: 20px;
`

export const AbsenceRecordActionButtonsContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  .back-print-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .pagination-container {
    display: flex;
    gap: 10px;
    align-items: center;
    .ant-pagination {
      display: flex;
      align-items: center;
      li {
        margin: 0px;
        border: 1px solid ${theme.colors.border};
        border-left: none;
        border-radius: 0px;
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid ${theme.colors.border};
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &.ant-pagination-item-active {
          color: #000;
          background: #eaf9ff;
        }
      }
    }
  }

  @media print {
    display: none;
  }
`

export const SpinContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const AbsenceRecordTableContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  .title-container {
    width: 100%;
    text-align: center;
    .title {
      font-weight: bold;
      display: inline-block;
    }
    .user-info {
      float: right;
      text-align: right;
      margin-bottom: 5px;
    }
  }
  div.table-container {
    margin: 10px 0;
    .absence-record-table {
      border-color: #000 !important;
      th {
        background: ${theme.colors.border};
        border-color: #000 !important;
        text-align: center;
        border-top: 1px solid #000;
        &:first-child {
          border-left: 1px solid #000;
          border-top-left-radius: 0px !important;
          min-width: 160px;
        }
        &:last-child {
          border-top-right-radius: 0px !important;
        }
      }
      td {
        border-color: #000 !important;
        &:first-child {
          border-left: 1px solid #000;
        }
      }
      .absent-record-date {
        width: 15%;
      }
      .absent-record-facility {
        width: 20%;
      }
      .absent-record-addition {
        width: 20%;
      }
      .absent-record-reason {
        white-space: pre;
        width: 30%;
      }
      .absent-record-recorder {
        width: 15%;
      }
    }
  }
`
