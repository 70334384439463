import React from "react"
import {
  Button,
  CALENDAR_HOURS_MINUTES,
  InputField,
  SelectInput,
} from "@project/common"
import { Typography } from "antd"
import { useTranslation } from "react-i18next"

import {
  SCHEDULE_ACTION_TYPE,
  SCHEDULE_INITIAL_VALUES,
} from "../../../types/AttendanceSchedule.types"
import { StyledCalendarCellContent } from "./CalendarCellContent.style"

export const CalendarCellContent = ({
  data,
  dataOf,
  handHourAddRemove,
  handleInputChange,
  shiftData,
}: {
  dataOf: string | number
  data: SCHEDULE_INITIAL_VALUES[] | any[] | undefined
  handHourAddRemove: (key: any, action: SCHEDULE_ACTION_TYPE) => void
  handleInputChange: (dataOf: any, key: any, val: string) => void
  shiftData?: any
}): JSX.Element => {
  const { t } = useTranslation()

  const getKeyAndValue = (
    hourValue: SCHEDULE_INITIAL_VALUES,
    index: number,
  ) => {
    const startHrs =
      index === 0
        ? hourValue?.attendance_start_time_hr
        : hourValue?.attendance_start_time_hr2
    const startMins =
      index === 0
        ? hourValue?.attendance_start_time_min
        : hourValue?.attendance_start_time_min2
    const startHrsKey =
      index === 0 ? `attendance_start_time_hr` : `attendance_start_time_hr2`
    const startMinKey =
      index === 0 ? `attendance_start_time_min` : `attendance_start_time_min2`
    const endHrs =
      index === 0
        ? hourValue?.attendance_end_time_hr
        : hourValue?.attendance_end_time_hr2
    const endMins =
      index === 0
        ? hourValue?.attendance_end_time_min
        : hourValue?.attendance_end_time_min2
    const endHrsKey =
      index === 0 ? `attendance_end_time_hr` : `attendance_end_time_hr2`
    const endMinKey =
      index === 0 ? `attendance_end_time_min` : `attendance_end_time_min2`
    const restMinKey =
      index === 0 ? `attendance_rest_minits` : `attendance_rest_minits2`
    const restMin =
      index === 0
        ? hourValue?.attendance_rest_minits
        : hourValue?.attendance_rest_minits2
    return {
      startHrs,
      startHrsKey,
      startMins,
      startMinKey,
      endHrs,
      endHrsKey,
      endMins,
      endMinKey,
      restMin,
      restMinKey,
    }
  }

  return (
    <StyledCalendarCellContent>
      <div className={"shift__selector"}>
        <SelectInput
          name={"shift"}
          filterSort={() => null}
          placeholder={"---"}
          allowClear
          options={shiftData || []}
          width={"100%"}
          value={
            data?.[0]?.attendance_shift_id
              ? data?.[0]?.attendance_shift_id
              : null
          }
          onChange={(val) =>
            handleInputChange(dataOf, "attendance_shift_id", val)
          }
          className={"input-to-text-print"}
        />
      </div>
      {data?.[0]?.attendance_shift_id ? (
        <div className={"data__container"}>
          {data?.map((hourValue, index) => {
            const {
              startHrs,
              startHrsKey,
              startMins,
              startMinKey,
              endHrs,
              endHrsKey,
              endMins,
              endMinKey,
              restMin,
              restMinKey,
            } = getKeyAndValue(hourValue, index)
            return (
              <div
                className={"hours__break__container"}
                key={index}
                style={{
                  marginTop: index === 1 ? "15px" : "inherit",
                }}
              >
                <div className={"hours__content"}>
                  <Typography.Text>
                    {t("Working Hours") + ` ${index + 1}`}
                  </Typography.Text>
                  <div className={"hours__selector"}>
                    <div className={"start__hours hours"}>
                      <SelectInput
                        name={"start_hour_hrs"}
                        width={"58px"}
                        onClick={(e) => e.stopPropagation()}
                        value={startHrs || null}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        placeholder={"--"}
                        onChange={(val) => {
                          handleInputChange(dataOf, startHrsKey, val)
                        }}
                        className={"input-to-text-print select-time-input"}
                      />
                      <b>{":"}</b>
                      <SelectInput
                        name={"start_hour_mins"}
                        width={"58px"}
                        onClick={(e) => e.stopPropagation()}
                        value={startMins || null}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        onChange={(val) => {
                          handleInputChange(dataOf, startMinKey, val)
                        }}
                        className={"input-to-text-print select-time-input"}
                      />
                    </div>
                    <span>{"~"}</span>
                    <div className={"end__hours hours"}>
                      <SelectInput
                        name={"start_hour_hrs"}
                        width={"58px"}
                        onClick={(e) => e.stopPropagation()}
                        value={endHrs || null}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.hours}
                        onChange={(val) => {
                          handleInputChange(dataOf, endHrsKey, val)
                        }}
                        className={"input-to-text-print select-time-input"}
                      />
                      <b>{":"}</b>
                      <SelectInput
                        name={"start_hour_mins"}
                        width={"58px"}
                        onClick={(e) => e.stopPropagation()}
                        value={endMins || null}
                        placeholder={"--"}
                        options={CALENDAR_HOURS_MINUTES?.minutes}
                        onChange={(val) => {
                          handleInputChange(dataOf, endMinKey, val)
                        }}
                        className={"input-to-text-print select-time-input"}
                      />
                    </div>
                  </div>
                </div>
                <div className={"break_time_container"}>
                  <Typography.Text>{t("Break Time")}</Typography.Text>
                  <div className={"break_time"}>
                    <div>
                      <InputField
                        name={"break_one"}
                        height={"40px"}
                        width={"80px"}
                        onClick={(e) => e.stopPropagation()}
                        value={restMin || null}
                        onChange={({ target: { value } }) => {
                          handleInputChange(dataOf, restMinKey, value)
                        }}
                        className={"input-to-text-print time-input"}
                      />
                    </div>
                    <div className={"min_text"}>{t("minutes")}</div>
                  </div>
                </div>
              </div>
            )
          })}
          <div className={"btn__container"}>
            {data?.length <= 1 ? (
              <Button
                btnText={t("Add working hours")}
                iconType={"plus_circle"}
                shape={"round"}
                padding={"0px"}
                width={"50px"}
                onClick={(e: React.SyntheticEvent<EventTarget>) => {
                  e.stopPropagation()
                  handHourAddRemove(dataOf, "add")
                }}
                className={"hide-on-print"}
              />
            ) : (
              <Button
                btnText={t("Remove working hours")}
                iconType={"delete-x"}
                shape={"round"}
                onClick={(e: React.SyntheticEvent<EventTarget>) => {
                  e.stopPropagation()
                  handHourAddRemove(dataOf, "remove")
                }}
                className={"hide-on-print"}
              />
            )}
          </div>
        </div>
      ) : null}
    </StyledCalendarCellContent>
  )
}
