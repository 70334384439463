import { FormOutlined } from "@ant-design/icons"
import { Modal, theme, useNotification } from "@project/common"
import { Typography } from "antd"

import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { SignatureCanvas } from "../../../components"
import { AuthContext } from "../../../context"
import { updateSignatureOfMonitoringDetail } from "../../../services"
const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
  .mf_left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .content__container {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .bordered_content {
        border: 1px solid ${theme.colors.text};
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 8px 6px;
        border-radius: 4px;
      }
    }
  }
  .mf_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .ads_manager {
      display: flex;
      align-items: center;
      gap: 8px;
      .txt {
        display: flex;
        gap: 8px;
        align-items: center;
        border-bottom: 1px solid ${theme.colors.text};
        padding-bottom: 5px;
      }
    }
  }
  .sign-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
  }
  #signature__img {
    height: auto;
    max-height: 26px;
    max-width: 163px;
  }

  @media print {
    .sign__icon {
      display: none !important;
    }
  }
`
export const MonitoringDetailFooter = ({
  params,
  monitoring,
  scrollContent,
  isPrinting,
  hasEditAccess,
}: {
  params: any
  monitoring: any
  scrollContent?: any
  isPrinting?: boolean
  hasEditAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const { showToast } = useNotification()
  const [signatures, setSignatures] = useState({
    digital_signature_image: "", //parent signature
    user_name_digital_image: "",
    manager_digital_signature_image: "",
    explanation_date_digital_image: "",
    consent_date_digital_image: "",
  })

  const { queryClient } = useContext(AuthContext)

  const [signOf, setSignOf] = useState("")
  const [signType, setSignType] = useState<"date" | "name" | "both">("name")

  const { isLoading: isUpdating, mutateAsync: updateSignatureOfMonitoring } =
    useMutation({
      mutationFn: updateSignatureOfMonitoringDetail,
    })

  useEffect(() => {
    setSignatures({
      digital_signature_image: monitoring?.digital_signature_image || "",
      user_name_digital_image: monitoring?.user_name_digital_image || "",
      manager_digital_signature_image:
        monitoring?.manager_digital_signature_image || "",
      explanation_date_digital_image:
        monitoring?.explanation_date_digital_image || "",
      consent_date_digital_image: monitoring?.consent_date_digital_image || "",
    })
  }, [monitoring])

  const saveSignatures = async (key: string, val: string) => {
    try {
      await updateSignatureOfMonitoring({
        ...signatures,
        [key]: val,
        user_id: +monitoring?.user_id,
        planId: +monitoring?.id,
        facility_id: +monitoring?.facility_id,
      })

      setSignatures({
        ...signatures,
        [key]: val,
      })

      queryClient.invalidateQueries({
        queryKey: ["monitoring_detail"],
      })
      setOpenSignatureModal(false)
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
    } catch {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    }
  }

  const renderSignature = (url) => (
    <>{url ? <img src={url} alt={"signature"} id={"signature__img"} /> : ""}</>
  )
  return (
    <>
      <StyledWrapper ref={scrollContent}>
        <div className={"mf_left"}>
          {params?.display_content?.includes("2") && (
            <Typography.Paragraph
              style={{
                margin: 0,
              }}
            >
              {params?.statementText}
            </Typography.Paragraph>
          )}
          {params?.display_content?.includes("1") && (
            <>
              {params?.consent_date?.includes("2") ? (
                <>
                  <div className={"content__container"}>
                    <div className={"bordered_content"}>
                      <Typography.Paragraph
                        style={{
                          margin: 0,
                        }}
                      >
                        {t("Explanation date")}
                      </Typography.Paragraph>
                      {renderSignature(
                        signatures?.explanation_date_digital_image,
                      )}
                      {!isPrinting && hasEditAccess && (
                        <div className={"sign__icon"}>
                          <FormOutlined
                            onClick={() => {
                              setSignType("name")
                              setSignOf("explanation_date_digital_image")
                              setSignatures({
                                ...signatures,
                                explanation_date_digital_image:
                                  monitoring?.explanation_date_digital_image,
                              })
                              setOpenSignatureModal(true)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"content__container"}>
                    <div className={"bordered_content"}>
                      <Typography.Paragraph
                        style={{
                          margin: 0,
                        }}
                      >
                        {t("Consent date")}
                      </Typography.Paragraph>
                      {renderSignature(signatures?.consent_date_digital_image)}
                      {!isPrinting && hasEditAccess && (
                        <div className={"sign__icon"}>
                          <FormOutlined
                            onClick={() => {
                              setSignType("name")
                              setSignOf("consent_date_digital_image")
                              setSignatures({
                                ...signatures,
                                consent_date_digital_image:
                                  monitoring?.consent_date_digital_image,
                              })
                              setOpenSignatureModal(true)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : params?.consent_date?.includes("1") ? (
                <>
                  <Typography.Text>{`${t("Explanation date")} ${dayjs().format(
                    "YYYY年MM月DD日",
                  )}\n`}</Typography.Text>
                  <Typography.Text>{`${t("Consent date")} ${dayjs().format(
                    "YYYY年MM月DD日",
                  )}\n`}</Typography.Text>
                </>
              ) : (
                <div>
                  <Typography.Text>{`${t(
                    "Date of consent to the explanation",
                  )} ${dayjs().format("YYYY年MM月DD日")}\n`}</Typography.Text>
                </div>
              )}
            </>
          )}

          {params?.display_content?.includes("3") && (
            <div className={"content__container"}>
              <div className={"bordered_content"}>
                <Typography.Paragraph
                  style={{
                    margin: 0,
                  }}
                >
                  {t("User name")}
                </Typography.Paragraph>
                <div className={"sign-wrapper"}>
                  {renderSignature(signatures?.user_name_digital_image)}
                </div>
                {!isPrinting && hasEditAccess && (
                  <div className={"sign__icon"}>
                    <FormOutlined
                      onClick={() => {
                        setSignType("name")
                        setSignOf("user_name_digital_image")
                        setSignatures({
                          ...signatures,
                          user_name_digital_image:
                            monitoring?.user_name_digital_image,
                        })
                        setOpenSignatureModal(true)
                      }}
                    />
                  </div>
                )}
              </div>
              {params?.display_content?.includes("4") && (
                <Typography.Text>{t("Mark")}</Typography.Text>
              )}
            </div>
          )}
        </div>
        <div className={"mf_right"}>
          <div className={"ads_office"}>
            <Typography.Text>
              {monitoring?.facility?.facility_name || ""}
            </Typography.Text>
            <Typography.Text>
              {monitoring?.user_info?.Company?.company_name}
            </Typography.Text>
          </div>
          <div className={"ads_manager"}>
            <div className={"txt"}>
              <Typography.Text>
                {t("Adult development support manager")}
              </Typography.Text>
              <div className={"sign-wrapper"}>
                {renderSignature(signatures?.manager_digital_signature_image)}
              </div>
              {params?.display_content?.includes("5") && (
                <div className={"sing__icon"}>
                  <FormOutlined
                    onClick={() => {
                      setSignType("name")
                      setSignOf("manager_digital_signature_image")
                      setSignatures({
                        ...signatures,
                        manager_digital_signature_image:
                          monitoring?.manager_digital_signature_image,
                      })
                      setOpenSignatureModal(true)
                    }}
                  />
                </div>
              )}
            </div>
            {params?.display_content?.includes("6") && (
              <Typography.Text>{t("Mark")}</Typography.Text>
            )}
          </div>
        </div>
      </StyledWrapper>
      {!!openSignatureModal && (
        <Modal
          open={openSignatureModal}
          title={t("Confirmation signature")}
          onCancel={() => {
            setOpenSignatureModal(false)
          }}
          footer={null}
          bodyPadding={"0px"}
        >
          <SignatureCanvas
            sign={signatures[signOf] || ""}
            dateSign={signatures[signOf] || ""}
            signType={signType}
            onSave={async (value) => {
              await saveSignatures(signOf, value)
            }}
            isLoading={isUpdating}
          />
        </Modal>
      )}
    </>
  )
}
