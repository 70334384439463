import { useRouter } from "next/router"
import React, { useRef, useState } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { useInfiniteQuery, useMutation } from "react-query"
import { Empty, Flex, Typography } from "antd"
import InfiniteScroll from "react-infinite-scroll-component"

// components/commons
import {
  Card,
  theme,
  Button,
  ActionBtns,
  DatePicker,
  StyledColGap,
  AccordionCard,
  removeBlankAttributes,
  useUpdateSearchParams,
  LabelTextWithLeftArrow,
  scrollToSelectedElement,
} from "@project/common"
import { NotiCollapsible } from "./NotiCollapsible"
import BellCheck from "../../public/assets/icons/BellCheck"
import { LoadingNotification } from "./LoadingNotification"

// styles
import { EndMessageWrap, NotiOperationFormWrap } from "./Notification.styles"

// types
import { NotiDateRangeFilter } from "../../types/notification.types"

// services
import {
  NotificationSeenType,
  getManyNotifications,
  seenNotification,
} from "../../services/notification"
import _ from "lodash"
import { PAGE_SIZE } from "../../constants"

// !## Main Component [Notification]
export const Notification = () => {
  const { query } = useRouter()
  const queryVal = query as {
    from_date: string
    to_date: string
  }
  const notiRef = useRef<HTMLDivElement>(null)
  const [dateRange, setDateRange] = useState<NotiDateRangeFilter>({
    from_date: queryVal.from_date ? dayjs(queryVal.from_date.toString()) : null,
    to_date: queryVal.to_date ? dayjs(queryVal.to_date.toString()) : null,
  })

  const [updateParams] = useUpdateSearchParams()

  // !## API calls
  // 1. Infinity query to fetch notifications
  const {
    data: notifications,
    isLoading: isNotiLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["notifications", query],
    queryFn: ({ pageParam = 1 }) => {
      return getManyNotifications({
        ...query,
        from_date: queryVal.from_date,
        to_date: queryVal.to_date,
        pageSize: PAGE_SIZE.toString(),
        page: pageParam,
      })
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentPageLength = allPages?.map((item) => item.data).flat().length
      const nextPage =
        lastPage?.count > currentPageLength ? allPages.length + 1 : undefined
      return nextPage
    },
  })

  // !## event action function
  // 1. Handle filter operation date range
  const handleFilterNotifications = () => {
    const lastDayOfMonth = dateRange.to_date?.endOf("month").format("DD")
    const params = removeBlankAttributes({
      from_date: dateRange.from_date?.isValid
        ? dateRange.from_date?.format("YYYY-MM-01")
        : null,
      to_date: dateRange.to_date?.isValid
        ? dateRange.to_date?.format("YYYY-MM-") + lastDayOfMonth?.toString()
        : null,
    })
    updateParams(params, "/notification")
    scrollToSelectedElement(notiRef)
  }
  // 2. Reset form
  const resetForm = () => {
    setDateRange(() => ({ from_date: null, to_date: null }))
    updateParams({}, "/notification")
    scrollToSelectedElement(notiRef)
  }
  // 3. Handle notification click
  const { mutate } = useMutation(seenNotification, {})

  const allNotifications = notifications?.pages.flatMap((page) => page.data)

  const handleNotificationClick = (notificationIds: string | string[]) => {
    if (notificationIds.length > 0) {
      const lastNotificationItemId = _.last(notificationIds)
      const isNotificationSeen = allNotifications.findIndex(
        (notification) =>
          notification.id === +lastNotificationItemId && notification.is_seen,
      )
      isNotificationSeen === -1 &&
        mutate({
          notification_id: +lastNotificationItemId,
        } as NotificationSeenType)
    }
  }

  return (
    <StyledColGap>
      <AccordionCard
        defaultActiveKey={["notification-operation"]}
        items={[
          {
            key: "notification-operation",
            label: t("Operation Options"),
            children: (
              <NotiOperationFormWrap>
                <Flex
                  className={"date-fields"}
                  align={"center"}
                  gap={"10px"}
                  wrap={"wrap"}
                >
                  <LabelTextWithLeftArrow
                    width={"140px"}
                    label={t("Year month")}
                    className={`label-with-arrow`}
                  />
                  <Flex className={"date-inputs"} align={"center"} gap={"16px"}>
                    <DatePicker
                      name={"from_date"}
                      className={"start-date-picker"}
                      dropdownClassName={"date-picker-dropdown"}
                      format={"YYYY年MM月"}
                      picker={"month"}
                      date={dateRange.from_date}
                      onDateChange={(date) =>
                        setDateRange({
                          ...dateRange,
                          from_date: date,
                        })
                      }
                    />
                    <Typography.Text>{t("~")}</Typography.Text>
                    <DatePicker
                      name={"to_date"}
                      className={"end-date-picker"}
                      format={"YYYY年MM月"}
                      picker={"month"}
                      date={dateRange.to_date}
                      onDateChange={(date) => {
                        setDateRange({
                          ...dateRange,
                          to_date: date,
                        })
                      }}
                    />
                  </Flex>
                </Flex>
                <ActionBtns
                  justify={"flex-start"}
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <Button
                    shape={"round"}
                    btnText={t("Reset search")}
                    onClick={() => resetForm()}
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    btnText={t("Search")}
                    onClick={() => handleFilterNotifications()}
                  />
                </ActionBtns>
              </NotiOperationFormWrap>
            ),
          },
        ]}
      />

      <div ref={notiRef}>
        <Card title={t("Notification List")}>
          {isNotiLoading ? (
            <LoadingNotification />
          ) : notifications?.pages[0].count === 0 ? (
            <Empty />
          ) : (
            <>
              <InfiniteScroll
                dataLength={
                  notifications?.pages.flatMap((page) => page.data).length
                }
                hasMore={hasNextPage}
                next={!isFetching && fetchNextPage}
                loader={
                  isFetchingNextPage && (
                    <LoadingNotification
                      style={{
                        marginTop: "10px",
                      }}
                    />
                  )
                }
                endMessage={<EndMessage />}
                style={{
                  overflow: "visible",
                }}
              >
                <NotiCollapsible
                  notifications={allNotifications}
                  handleNotificationClick={(
                    notificationIds: string | string[],
                  ) => handleNotificationClick(notificationIds)}
                />
              </InfiniteScroll>
            </>
          )}
        </Card>
      </div>
    </StyledColGap>
  )
}

export const EndMessage = () => (
  <EndMessageWrap justify={"center"} align={"center"}>
    <div>
      <BellCheck width={"40"} height={"40"} color={theme.colors["sub-text"]} />
      <h3 className={"end-message"}>{t("You are all caught up.")}</h3>
    </div>
  </EndMessageWrap>
)
