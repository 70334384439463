export const ACTIVITY_STATEMENT_ROWS = [
  {
    type: "Profit",
    item_list: "Employment support business income",
    key: "business_income",
  },
  {
    type: "Profit",
    item_list: "Employment support business activity earning meter",
    key: "business_activity_earning",
  },
  {
    type: "Cost",
    item_list: "Employment support business sales cost",
    key: "business_sales_cost",
  },
  {
    type: "Cost",
    item_list: "Involved product (product) inventory",
    key: "involved_product_inventory",
  },
  {
    type: "Cost",
    item_list:
      "Price for manufacturing employment support projects in the current period",
    key: "manufacturing_cost",
  },
  {
    type: "Cost",
    item_list: "Employment support project purchasing height",
    key: "project_purchase_height",
  },
  {
    type: "Cost",
    item_list: "total",
    key: "total_1",
  },
  {
    type: "Cost",
    item_list: "Final product (product) Inventory",
    key: "final_product_inventory",
  },
  {
    type: "Cost",
    item_list: "Deduction",
    key: "deduction",
  },
  {
    type: "Cost",
    item_list: "Employment support business sales jurisdiction expenses",
    key: "jurisdiction_expense",
  },
  {
    type: "Cost",
    item_list: "Employment support business activity expenses meter",
    key: "business_activity_expense",
  },
  {
    type: "",
    item_list: "Employment support business activity increase decrease amount",
    key: "total_2",
  },
]
