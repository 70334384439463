import {
  getUserAttendanceTypesByService,
  InputAreaField,
  InputField,
  MINUTES_HOUR,
  MINUTES_TIMES,
  SelectInput,
} from "@project/common"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AuthContext } from "../../../../context"
import { getAllStaff, getAllWork } from "../../../../services"
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"
import { StartTimeWrapper } from "../DailyBusinessReportListForm.style"

const MobileView = styled.div`
  border-radius: 12px;
  border: 1px solid #d2d1d1;
  margin-bottom: 20px;
  .main-wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .user-table-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 2px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
      }
    }
  }
  .error {
    color: red;
    font-size: 14px;
  }
  .content {
    .content-h1 {
      margin: 0 2px;
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 500;
    }
    table {
      width: 100%;
      .mobile-content-tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        border-radius: 10px !important;
        border: 1px solid #dddddd;
        .label {
          height: 40px;
          padding: 8px 0px 0px 10px !important;
          border: none !important;
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          border-bottom: 1px solid #dddddd !important;
          background-color: #f3f3f3;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          text-transform: capitalize;
        }
        .value {
          border: none !important;
          padding: 8px;
        }
      }
      .mobile-content-tr:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .reporter-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 0 0 5px;
    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
    .reporter-content {
      display: flex;
      gap: 5px;
      .ant-select:first-child {
        width: 128px !important;
      }
    }
  }
`
const MobileResponsiveView = ({ formik, data, index }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const parentName = "daily_business_support_report"
  const { data: allStaffData } = useQuery<any>(
    ["get-all-staff"],
    () => getAllStaff(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.staff_name, value: d?.id }
        }),
    },
  )
  const { data: allWorkData } = useQuery<any>(
    ["get-all-work-second-table-mobile"],
    () => getAllWork(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.work_name, value: d?.id }
        }),
    },
  )
  const attendance_status = t(
    getUserAttendanceTypesByService(data?.service_type)?.find(
      (d) => d?.value == data?.attendance_type,
    )?.label || "",
  )
  return (
    <MobileView>
      <div className={"main-wrapper"}>
        <div className={"user-table-content"}>
          <h1>{data?.username}</h1>
          {attendance_status && <p>{t(attendance_status)}</p>}
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {data?.start_time && data?.end_time ? (
              <>
                {data?.start_time}
                <span>{"~"}</span>
                {data?.end_time}
              </>
            ) : null}
          </div>
        </div>
        <div className={"content"}>
          <h1 className={"content-h1"}>{t("Content")}</h1>
          {data?.is_absent ? (
            <table>
              <tr className={"mobile-content-tr"}>
                <td className={"label"}>{t("Staff who handled")}</td>
                <td className={"value"}>
                  <SelectInput
                    width={"100%"}
                    name={`${parentName}[${index}].absent_recorder_staff_id`}
                    id={`${parentName}[${index}].absent_recorder_staff_id`}
                    placeholder={"--"}
                    options={allStaffData || []}
                    value={data?.absent_recorder_staff_id || null}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `${parentName}[${index}].work_id`,
                        val,
                      )
                      formik.setFieldValue(
                        `${parentName}[${index}].absent_recorder_staff_id`,
                        val,
                      )
                    }}
                  />
                </td>
              </tr>
              <tr className={"mobile-content-tr"}>
                <td className={"label"}>
                  {t("Reason of absence and support contents")}
                </td>
                <td className={"value"}>
                  <InputAreaField
                    name={`${parentName}[${index}].absence_reason`}
                    id={`${parentName}[${index}].absence_reason`}
                    padding={"8px"}
                    className={"field_value"}
                    placeholder={t("Please enter")}
                    borderRadius={"4px"}
                    rows={3.5}
                    value={data?.absence_reason || null}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].absence_reason`,
                        e.target.value,
                      )
                    }
                  />
                </td>
              </tr>
            </table>
          ) : (
            <div>
              <table>
                <tr className={"mobile-content-tr"}>
                  <td className={"label"}>{t("Work")}</td>
                  <td className={"value"}>
                    <SelectInput
                      width={"100%"}
                      name={`${parentName}[${index}].work_id`}
                      id={`${parentName}[index].work_id`}
                      value={data?.work_id}
                      options={allWorkData}
                      className={"field_value"}
                      placeholder={"Please select"}
                      mode={"multiple"}
                      height={"fit-content"}
                      onChange={(v) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].work_id`,
                          v,
                        )
                      }
                    />
                  </td>
                </tr>
                <tr className={"mobile-content-tr"}>
                  <td className={"label"}>{t("Interview")}</td>
                  <td className={"value"}>
                    <SelectInput
                      width={"100%"}
                      id={`${parentName}[${index}].interview`}
                      name={`${parentName}[${index}].interview`}
                      value={data?.interview}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      className={"field_value"}
                      placeholder={""}
                      onChange={(v) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].interview`,
                          v,
                        )
                      }
                    />
                  </td>
                </tr>
                {data?.interview ? (
                  <>
                    <tr className={"mobile-content-tr"}>
                      <td className={"label"}>{t("Interview time")}</td>
                      <td className={"value"}>
                        <StartTimeWrapper>
                          <div>
                            <span>{t("Start Time")}</span>
                            <div className={"start_time"}>
                              <SelectInput
                                name={"start_time_hr"}
                                options={MINUTES_HOUR}
                                width={"58px"}
                                placeholder={t("--")}
                                value={data?.start_time_hr}
                                onChange={(v) => {
                                  formik.setFieldValue(
                                    `${parentName}[${index}].start_time_hr`,
                                    v,
                                  )
                                  formik.setFieldValue(
                                    `${parentName}[${index}].start_time_min`,
                                    !data.start_time_min ||
                                      data.start_time_hr === ""
                                      ? "00"
                                      : data.start_time_min,
                                  )
                                }}
                              />

                              <span>{":"}</span>
                              <SelectInput
                                options={MINUTES_TIMES}
                                name={"start_time_min"}
                                width={"58px"}
                                placeholder={t("--")}
                                value={data?.start_time_min}
                                onChange={(v) =>
                                  formik.setFieldValue(
                                    `${parentName}[${index}].start_time_min`,
                                    v,
                                  )
                                }
                                disabled={data.start_time_hr === ""}
                              />
                            </div>
                          </div>

                          <div>
                            <span>{t("End Time")}</span>
                            <div className={"start_time"}>
                              <SelectInput
                                name={"end_time_hr"}
                                options={MINUTES_HOUR}
                                width={"58px"}
                                placeholder={t("--")}
                                value={data?.end_time_hr}
                                onChange={(v) => {
                                  formik.setFieldValue(
                                    `${parentName}[${index}].end_time_hr`,
                                    v,
                                  )
                                  formik.setFieldValue(
                                    `${parentName}[${index}].end_time_min`,
                                    !data.end_time_min ||
                                      data.end_time_hr === ""
                                      ? "00"
                                      : data.end_time_min,
                                  )
                                }}
                              />
                              <span>{":"}</span>
                              <SelectInput
                                name={"end_time_min"}
                                options={MINUTES_TIMES}
                                width={"58px"}
                                placeholder={t("--")}
                                value={data?.end_time_min}
                                onChange={(v) =>
                                  formik.setFieldValue(
                                    `${parentName}[${index}].end_time_min`,
                                    v,
                                  )
                                }
                                disabled={data.end_time_hr === ""}
                              />
                            </div>
                          </div>
                        </StartTimeWrapper>
                        {formik.errors.daily_business_support_report &&
                          formik.errors.daily_business_support_report[
                            index
                          ] && (
                            <div className={"error"}>
                              {
                                formik.errors.daily_business_support_report[
                                  index
                                ]
                              }
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr className={"mobile-content-tr"}>
                      <td className={"label"}>{t("Interview Content")}</td>
                      <td className={"value"}>
                        <InputAreaField
                          name={`${parentName}[${index}].interview_record`}
                          id={`${parentName}[${index}].interview_record`}
                          placeholder={t("Please enter")}
                          padding={"8px"}
                          borderRadius={"4px"}
                          rows={3.5}
                          className={"field_value"}
                          value={data?.interview_record}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${parentName}[${index}].interview_record`,
                              e.target.value,
                            )
                          }
                        />
                      </td>
                    </tr>
                  </>
                ) : null}

                {data?.service_type === 3 && (
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Visited facility name")}</td>
                    <td className={"value"}>
                      <SelectInput
                        width={"100%"}
                        id={`${parentName}[${index}].facility_id`}
                        name={`${parentName}[${index}].facility_id`}
                        value={
                          data?.facility_id ? String(data?.facility_id) : null
                        }
                        options={facilities}
                        className={"field_value"}
                        placeholder={""}
                        onChange={(v) =>
                          formik.setFieldValue(
                            `${parentName}[${index}].facility_id`,
                            v,
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr className={"mobile-content-tr"}>
                  <td className={"label"}>{t("Support record")}</td>
                  <td className={"value"}>
                    <InputAreaField
                      name={`${parentName}[${index}].support_record`}
                      id={`${parentName}[${index}].support_record`}
                      placeholder={t("Please enter")}
                      padding={"8px"}
                      borderRadius={"4px"}
                      rows={3.5}
                      className={"field_value"}
                      value={data?.support_record}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].support_record`,
                          e.target.value,
                        )
                      }
                    />
                  </td>
                </tr>
                <tr className={"mobile-content-tr"}>
                  <td className={"label"}>{t("Condition of user")}</td>
                  <td className={"value"}>
                    <InputAreaField
                      name={`${parentName}[${index}].condition_of_user`}
                      id={`${parentName}[${index}].condition_of_user`}
                      placeholder={t("Please enter")}
                      className={"field_value"}
                      padding={"8px"}
                      borderRadius={"4px"}
                      rows={3.5}
                      value={data?.condition_of_user}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].condition_of_user`,
                          e.target.value,
                        )
                      }
                    />
                  </td>
                </tr>
                {data?.service_type === 3 && (
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}> {t("Staff who handled")}</td>
                    <td className={"value"}>
                      <InputField
                        name={`${parentName}[${index}].person_in_charge`}
                        id={`${parentName}[${index}].person_in_charge`}
                        placeholder={t("")}
                        className={"field_value"}
                        padding={"8px"}
                        borderRadius={"4px"}
                        maxLength={100}
                        value={data?.person_in_charge}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `${parentName}[${index}].person_in_charge`,
                            e.target.value,
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr className={"mobile-content-tr"}>
                  <td className={"label"}>{t("Other")}</td>
                  <td className={"value"}>
                    <InputAreaField
                      name={`${parentName}[${index}].other`}
                      id={`${parentName}[${index}].other`}
                      padding={"8px"}
                      placeholder={t("Please enter")}
                      className={"field_value"}
                      borderRadius={"4px"}
                      rows={3.5}
                      value={data?.other}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].other`,
                          e.target.value,
                        )
                      }
                    />
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div>
        <div className={"reporter-wrapper"}>
          <p className={"title"}>{t("Recorder:")}</p>
          <div className={"reporter-content"}>
            <SelectInput
              width={"58px"}
              name={`startChar`}
              options={FuriganaAlphabetsOption}
              placeholder={"--"}
              value={data?.startChar}
              onChange={(v) => {
                formik.setFieldValue(
                  `daily_business_support_report[${index}].startChar`,
                  v,
                )
                formik.setFieldValue(
                  `daily_business_support_report[${index}].staff_id`,
                  null,
                )
              }}
              allowClear
            />
            <SelectInput
              width={"183px"}
              name={`daily_business_support_report[${index}].staff_id`}
              id={`daily_business_support_report[${index}].staff_id`}
              placeholder={"--"}
              value={data?.staff_id}
              options={
                data?.startChar
                  ? [
                      ...allStaffData?.filter(
                        (user) =>
                          user.furigana?.startsWith(data?.startChar) ||
                          user.label?.startsWith(data?.startChar),
                      ),
                    ]
                  : allStaffData
              }
              onChange={(v) =>
                formik.setFieldValue(
                  `daily_business_support_report[${index}].staff_id`,
                  v,
                )
              }
            />
          </div>
        </div>
      </div>
    </MobileView>
  )
}
export { MobileResponsiveView }
