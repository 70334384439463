import { useRef, useState } from "react"

import {
  Button,
  Card,
  FACILITY_TYPES,
  Table,
  Tag,
  getLocalstorageItem,
  scrollToSelectedElement,
  setItemToLocalstorage,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Typography } from "antd"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { PAGE_SIZE } from "../../constants"
import { getAllChildSupportPlans } from "../../services"
import {
  IndividualSupportPlanResponse,
  PlanSearchParams,
} from "../../types/supportPlan.types"
import { TableTopContent, Wrapper } from "./PlanContent.style"
import { PlanSearch } from "./PlanSearch"

interface PlanContentProps {
  planType: "draft" | "non-draft"
  link?: string
  hasEditAccess: (val: string | number) => boolean
}
const FORMAT = "YYYY年MM月DD日"
const PlanContent = ({
  planType,
  link,
  hasEditAccess,
}: PlanContentProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const {
    keyword,
    page: qPage,
    user_id,
    facilityIds: facility,
    date,
    expiry_date,
    display_settings,
    service,
    status,
  } = router.query as any

  const storedData = getLocalstorageItem(
    planType === "draft"
      ? "individual-support-draft-plans"
      : "individual-support-plans",
    "facility_ids",
  )
  const listRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState(+qPage || 1)
  const [isBtnClicked, setIsBtnClicked] = useState<any>(false)
  const selectedFIds = facility
    ? facility?.split(",")?.filter((f) => !isNaN(f) && f)
    : storedData?.split(",")?.filter((f) => !isNaN(f) && f)

  const selectedSIds = service?.split(",")
  const selectedDsIds = display_settings?.split(",")
  const [params, setParams] = useState<PlanSearchParams>({
    keyword: keyword || null,
    user_id: +user_id || null,
    service: selectedSIds || [],
    facilityIds: selectedFIds || "",
    date_from: date ? date?.split("_")[0] : "",
    date_to: date ? date?.split("_")[1] : "",
    expiry_date_from: expiry_date ? expiry_date?.split("_")[0] : "",
    expiry_date_to: expiry_date ? expiry_date?.split("_")[1] : "",
    display_settings: selectedDsIds || null,
    status: status || null,
    hiragana: null,
  })

  const {
    isLoading,
    isFetching,
    data: planData,
  } = useQuery({
    queryKey: ["individuals-support", page, isBtnClicked],
    queryFn: () =>
      getAllChildSupportPlans({
        quires: {
          page: page,
          pageSize: PAGE_SIZE,
          facilityIds: params?.facilityIds,
          display_settings: params?.display_settings,
          status: params?.status,
          service: params?.service,
          user_id: params?.user_id,
          date:
            params?.date_from && params?.date_to
              ? `${dayjs(params?.date_from).format("YYYY-MM-DD")}_${dayjs(
                  params?.date_to,
                ).format("YYYY-MM-DD")}`
              : "",
          expiry_date:
            params?.expiry_date_from && params?.expiry_date_to
              ? `${dayjs(params?.expiry_date_from).format(
                  "YYYY-MM-DD",
                )}_${dayjs(params?.expiry_date_to).format("YYYY-MM-DD")}`
              : "",
        },
        type: planType,
      }),
    select: (response) => {
      return {
        count: response?.count,
        data: response?.data?.map((val) => ({
          ...val,
          key: val?.id,
        })),
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
  //columns
  const columns: ColumnsType<IndividualSupportPlanResponse> = [
    {
      title: t("Detail"),
      key: "details",
      className: "details_th",
      width: 70,
      align: "center",
      render: (row) => (
        <Link href={`${link}/detail/${row?.id}`}>{t("Details")}</Link>
      ),
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("User name")}
        </span>
      ),
      key: "child_name",
      align: "center",
      render: (row, record) => (
        <Link href={`/users/profile/${record.user_id}`}>
          <a
            style={{
              color: theme.colors.link,
            }}
          >
            {row?.user_info?.username}
          </a>
        </Link>
      ),
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Facility name")}
        </span>
      ),
      key: "facility_name",
      align: "center",
      render: (row) => (
        <span>
          {row?.facility?.facility_name_short || row?.facility?.facility_name}
        </span>
      ),
    },
    {
      title: t("Use service"),
      align: "center",
      className: "use_service",
      render: (data) => {
        const usedService = FACILITY_TYPES?.find(
          (v) => v?.value === data?.use_service_id,
        )
        return (
          usedService && (
            <Tag
              title={t(usedService?.label)}
              padding={"4px"}
              style={{
                width: usedService?.value === 3 ? "165px" : "",
                whiteSpace: "pre-wrap",
              }}
              color={
                usedService?.value === 1
                  ? "warning"
                  : usedService?.value === 2
                    ? "success"
                    : "processing"
              }
            />
          )
        )
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("No. of created")}
        </span>
      ),
      key: "no_of_created",
      align: "center",
      width: 80,
      render: (row) => <span>{row?.create_count}</span>,
      className: "width_fix",
    },

    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Created date")}
        </span>
      ),
      key: "report_create_date",
      align: "center",
      className: "date_css",
      render: (row) => (
        <span>
          {dayjs(row?.report_create_date).isValid() &&
            dayjs(row?.report_create_date).format(FORMAT)}
        </span>
      ),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Expiry date")}</span>,
      key: "expiry date",
      align: "center",
      className: "date_css",
      render: (row) => (
        <span>
          {dayjs(row?.end_date).isValid()
            ? dayjs(row?.end_date).format(FORMAT)
            : ""}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Create status")}
        </span>
      ),
      key: "status",
      align: "center",

      render: (row) => (
        <div style={{ position: "relative" }}>
          {row?.draft_save_flag ? (
            <>
              <Tag
                title={t("Draft")}
                color={theme.colors.border}
                padding={"4px"}
              />
              {row?.user_digital_signature_image && (
                <div id={"sign_indicator"}>
                  <Image
                    src={"/assets/icons/solid-edit.svg"}
                    alt={"icon"}
                    width={15}
                    height={15}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <Tag
                title={t("created")}
                color={theme.colors.info}
                padding={"4px"}
              />
              {row?.user_digital_signature_image && (
                <div id={"sign_indicator"}>
                  <Image
                    src={"/assets/icons/solid-edit.svg"}
                    alt={"icon"}
                    width={15}
                    height={15}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: planType === "draft" ? t("Last updated") : t("Copy"),
      key: "last_update",
      align: "center",
      className: "btn_th",
      render: (row) =>
        planType === "draft" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {dayjs(row?.updated_datetime).format(`${FORMAT} HH:mm`)}
            </span>
            <span>{row?.updated_by_user_name || ""}</span>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              btnText={t("Copy")}
              iconType={"print-gray"}
              shape={"round"}
              height={"30px"}
              borderColor={theme.colors.border}
              btnBg={theme.colors.disabled}
              onClick={() => {
                router.push({
                  pathname: `/individual-support-plan/add`,
                  query: {
                    user_id: row?.user_info?.id,
                    create_count: row?.create_count + 1,
                    mode: "copy",
                  },
                })
              }}
              disabled={!hasEditAccess(row?.facility_id)}
            />
          </div>
        ),
    },
  ]

  return (
    <Wrapper>
      <PlanSearch
        queryParams={params}
        handleChange={(key, value) => {
          setParams({
            ...params,
            [key]: value,
          })
        }}
        handleBtnClicked={(type, facilityIds) => {
          if (type === "reset") {
            const data = {
              user_id: null,
              service: [],
              facilityIds: [],
              date_from: "",
              date_to: "",
              expiry_date_from: "",

              expiry_date_to: "",
              display_settings: null,
              status: [],
              hiragana: "",
            }
            setParams(data)
            setPage(1)
            updateParams(
              {
                ...data,
                facilityIds: "",
                service: "",
                status: "",
              },
              planType === "draft"
                ? "draft-individual-support-plan"
                : "individual-support-plan",
            )
            scrollToSelectedElement(listRef)
          } else {
            setPage(1)
            updateParams(
              {
                page: 1,
                facilityIds: params?.facilityIds?.toString(),
                display_settings: params?.display_settings?.toString(),
                status: params?.status?.toString(),
                user_id: params?.user_id?.toString(),
                service: params?.service?.toString(),
                date:
                  params?.date_from && params?.date_to
                    ? `${dayjs(params?.date_from).format("YYYY-MM-DD")}_${dayjs(
                        params?.date_to,
                      ).format("YYYY-MM-DD")}`
                    : "",
                expiry_date:
                  params?.expiry_date_from && params?.expiry_date_to
                    ? `${dayjs(params?.expiry_date_from).format(
                        "YYYY-MM-DD",
                      )}_${dayjs(params?.expiry_date_to).format("YYYY-MM-DD")}`
                    : "",
              },
              planType === "draft"
                ? "draft-individual-support-plan"
                : "individual-support-plan",
            )

            scrollToSelectedElement(listRef)
          }
          setItemToLocalstorage(
            planType === "draft"
              ? "draft-individual-support-plan"
              : "individual-support-plan",
            {
              facility_ids: facilityIds?.toString(),
            },
          )
          setIsBtnClicked(!isBtnClicked)
        }}
        planType={planType}
      />

      <div ref={listRef}>
        <Card
          title={
            planType === "draft"
              ? t("Individual support plan (draft) list")
              : t("Individual support plan")
          }
        >
          <TableTopContent>
            <Typography.Paragraph>
              <div className={"indicator"}></div>
              <span>
                {t("Users who do not have a registered recipient certificate")}
              </span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <div>
                <Image
                  src={"/assets/icons/solid-edit.svg"}
                  width={20}
                  height={20}
                  alt={""}
                />
              </div>
              {t("digital_signed_by_parents")}
            </Typography.Paragraph>
          </TableTopContent>
          <Table
            columns={columns}
            showPaginationOf={"both"}
            total={planData?.count || 0}
            current={page}
            pageSize={PAGE_SIZE}
            dataSource={planData?.data || []}
            scroll={{ x: 900 }}
            cellPaddingBlock={10}
            loading={isLoading || isFetching}
            onChange={(value) => {
              setPage(value)
              updateParams(
                {},
                planType === "draft"
                  ? "draft-individual-support-plan"
                  : "individual-support-plan",
              )
            }}
            rowClassName={(row: any) =>
              !row?.certificate_Id && "no-certificate-row"
            }
          />
        </Card>
      </div>
    </Wrapper>
  )
}

export { PlanContent }
