import {
  Button,
  CALENDAR_HOURS_MINUTES,
  SelectInput,
  Tag,
  WORK_TYPE,
  theme,
} from "@project/common"
import { Typography } from "antd"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  BUSINESS_HOUR_TYPE,
  HOURS_INITIAL,
  InputChangeProps,
} from "../../../../types"

const DataContentWrapper = styled.div`
  padding: 8px 5px;
  position: relative;
  .main__info {
    margin-top: 8px;
    background-color: ${theme.colors.disabled};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 4px;
    .header {
      background-color: ${theme.colors.accentLight};
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 4px;
      color: ${theme.colors.white};
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding: 4px;
      .title,
      .separator {
        ${theme.typography["body-medium"]}
      }
      .time__content {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.2rem;
        column-gap: 0.3rem;
        align-items: center;
        .start__time,
        .end__time {
          display: flex;
          align-items: center;
          column-gap: 0.1rem;
        }
      }
    }
    .btns {
      display: flex;
      flex-direction: column;
      row-gap: 0.2rem;
      padding: 0 4px;
    }
    .service__content-wrapper {
      margin-top: 8px;
    }
    .holiday__overflow {
      background-color: ${theme.colors.pink};
      position: absolute;
      opacity: 0.4;
      top: 52px;
      bottom: 0;
      left: 0px;
      right: 0px;
    }
  }
`
interface DataContentProps {
  dataOf?: string
  businessHours?: HOURS_INITIAL[] | []
  serviceHours?: HOURS_INITIAL[] | []
  handleDataAddition?: (
    type: BUSINESS_HOUR_TYPE,
    weekName: string | number,
  ) => void
  handleDataRemove?: (type: BUSINESS_HOUR_TYPE, key: string | number) => void
  handleCalendarInputChange?: (values: InputChangeProps) => void
  isHoliday?: boolean
  hasMeal?: string
  toggleHolidayAndMeal?: (type: "H" | "M") => void
  facilityType?: any
}
export const DataContent = ({
  dataOf,
  businessHours,
  serviceHours,
  handleDataAddition,
  handleDataRemove,
  handleCalendarInputChange,
  isHoliday,
  hasMeal,
  toggleHolidayAndMeal,
  facilityType,
}: DataContentProps): JSX.Element => {
  const { t } = useTranslation()

  const renderActionBtns = ({
    length,
    handleClick,
  }: {
    length: number
    handleClick: (action: string) => void
  }) => {
    return (
      <div className={"btns"}>
        {length >= 3 ? (
          <>
            <Button
              btnText={t("Remove")}
              shape={"round"}
              iconType={"remove"}
              borderColor={theme.colors.border}
              height={"20px"}
              padding={"3px 5px"}
              width={"fit-content"}
              onClick={() => handleClick("remove")}
            />
          </>
        ) : length >= 2 ? (
          <>
            <Button
              btnText={t("Add")}
              shape={"round"}
              iconType={"plus"}
              height={"20px"}
              padding={"3px 5px"}
              width={"fit-content"}
              onClick={() => handleClick("add")}
            />
            <Button
              btnText={t("Remove")}
              shape={"round"}
              iconType={"remove"}
              borderColor={theme.colors.border}
              height={"20px"}
              padding={"3px 5px"}
              width={"fit-content"}
              onClick={() => handleClick("remove")}
            />
          </>
        ) : (
          <Button
            btnText={t("Add")}
            shape={"round"}
            iconType={"plus"}
            height={"20px"}
            padding={"3px 5px"}
            width={"fit-content"}
            onClick={() => handleClick("add")}
          />
        )}
      </div>
    )
  }

  return (
    <DataContentWrapper>
      <div className={"meal__info"}>
        <Tag
          title={t("Holiday ")}
          defaultBg={isHoliday ? theme.colors.white : theme.colors.accent}
          borderColor={isHoliday ? theme.colors.accent : theme.colors.accent}
          padding={"4px"}
          onClick={() => toggleHolidayAndMeal("H")}
          style={{
            cursor: "pointer",
          }}
        />
        <Tag
          title={hasMeal === "1" ? t("Meal") : t("No Meal")}
          defaultBg={isHoliday ? theme.colors.disabled : theme.colors.infoDark}
          borderColor={
            isHoliday ? theme.colors.disabled : theme.colors.infoDark
          }
          padding={"4px"}
          onClick={() => toggleHolidayAndMeal("M")}
          style={{
            cursor: !isHoliday ? "pointer" : "unset",
            pointerEvents: isHoliday ? "none" : "unset",
          }}
          textColor={isHoliday ? theme.colors.text : theme.colors.white}
        />
      </div>
      <div
        className={"main__info"}
        style={{
          pointerEvents: isHoliday ? "none" : "auto",
        }}
      >
        <div className={"header"}>{t(WORK_TYPE?.[facilityType])}</div>
        <div className={"time__content-wrapper"}>
          {businessHours?.map((hour, idx) => (
            <Fragment key={idx}>
              <div className={"main__info-content"}>
                <Typography.Text className={"title"}>
                  {t("Business hour") + `${idx > 0 ? idx + 1 : ""}`}
                </Typography.Text>
                <div className={"time__content"}>
                  <div className={"start__time"}>
                    <SelectInput
                      shortSelector
                      name={"start_hr"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.from?.hours}
                      options={CALENDAR_HOURS_MINUTES?.hours || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          key: dataOf,
                          unit: "hours",
                          range: "from",
                          id: hour?.id,
                          val,
                          type: "business_hours",
                        })
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      shortSelector
                      name={"start_min"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.from?.minutes}
                      options={CALENDAR_HOURS_MINUTES?.minutes || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          type: "business_hours",
                          key: dataOf,
                          unit: "minutes",
                          range: "from",
                          id: hour?.id,
                          val,
                        })
                      }}
                    />
                  </div>
                  <div className={"separator"}>{"~"}</div>
                  <div className={"end__time"}>
                    <SelectInput
                      shortSelector
                      name={"to_hr"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.to?.hours}
                      options={CALENDAR_HOURS_MINUTES?.hours || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          type: "business_hours",
                          key: dataOf,
                          unit: "hours",
                          range: "to",
                          id: hour?.id,
                          val,
                        })
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      shortSelector
                      name={"to_min"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.to?.minutes}
                      options={CALENDAR_HOURS_MINUTES?.minutes || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          type: "business_hours",
                          key: dataOf,
                          unit: "minutes",
                          range: "to",
                          id: hour?.id,
                          val,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
          {renderActionBtns({
            length: businessHours?.length,
            handleClick: (action: string) => {
              if (action === "add") {
                handleDataAddition("business_hours", dataOf)
              }
              if (action === "remove") {
                handleDataRemove("business_hours", dataOf)
              }
            },
          })}
        </div>
        <div className={"service__content-wrapper"}>
          {serviceHours?.map((hour, idx) => (
            <Fragment key={idx}>
              <div className={"main__info-content"}>
                <Typography.Text className={"title"}>
                  {t("Service hours") + `${idx > 0 ? idx + 1 : ""}`}
                </Typography.Text>
                <div className={"time__content"}>
                  <div className={"start__time"}>
                    <SelectInput
                      shortSelector
                      name={"start_hr"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.from?.hours}
                      options={CALENDAR_HOURS_MINUTES?.hours || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          key: dataOf,
                          unit: "hours",
                          range: "from",
                          id: hour?.id,
                          val,
                          type: "service_hours",
                        })
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      shortSelector
                      name={"start_min"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.from?.minutes}
                      options={CALENDAR_HOURS_MINUTES?.minutes || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          key: dataOf,
                          unit: "minutes",
                          range: "from",
                          id: hour?.id,
                          val,
                          type: "service_hours",
                        })
                      }}
                    />
                  </div>
                  <div className={"separator"}>{"~"}</div>
                  <div className={"end__time"}>
                    <SelectInput
                      shortSelector
                      name={"to_hr"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.to?.hours}
                      options={CALENDAR_HOURS_MINUTES?.hours || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          key: dataOf,
                          unit: "hours",
                          range: "to",
                          id: hour?.id,
                          val,
                          type: "service_hours",
                        })
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      shortSelector
                      name={"to_min"}
                      width={"58px"}
                      placeholder={"--"}
                      value={hour?.to?.minutes}
                      options={CALENDAR_HOURS_MINUTES?.minutes || []}
                      onChange={(val) => {
                        handleCalendarInputChange({
                          key: dataOf,
                          unit: "minutes",
                          range: "to",
                          id: hour?.id,
                          val,
                          type: "service_hours",
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
          {renderActionBtns({
            length: serviceHours?.length,
            handleClick: (action: string) => {
              if (action === "add") {
                handleDataAddition("service_hours", dataOf)
              }
              if (action === "remove") {
                handleDataRemove("service_hours", dataOf)
              }
            },
          })}
        </div>
        {isHoliday && <div className={"holiday__overflow"}></div>}
      </div>
    </DataContentWrapper>
  )
}
