import { theme } from "@project/common"
import styled from "styled-components"

export const FirstTableContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    tr {
      td.value {
        .ant-select {
          .ant-select-selector {
            min-height: 40px;
            .ant-select-selection-overflow {
              .ant-select-selection-overflow-item {
                span {
                  white-space: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .label {
    padding: 12px;
    background-color: rgb(243, 243, 243);
    width: 25%;
    text-transform: capitalize;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }

  .value,
  p {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px !important;
      font-weight: 400;
    }
  }

  .first-content-tr {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      border-radius: 10px !important;
      border: 1px solid #dddddd;
    }
    .label {
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border: none !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom: 1px solid #dddddd !important;
      }
    }
    .value {
      font-size: 14px !important;
      @media screen and (max-width: 540px) {
        font-size: 14px !important;
        border: none !important;
      }
    }
  }
  .first-content-tr:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
`
