import React from "react"

// packages
import { t } from "i18next"
import { Flex } from "antd"

// commons | components
import { SelectTime } from "./SelectTime"
import { Box, Table } from "@project/common"

// utils
import { getOKOrNG } from "../utils/getOKorNG"

// types
import {
  GenericDriverStaff,
  TMDriverGenericTableProps,
} from "../../../types/TMDriverList.types"
import { ColumnsType } from "antd/es/table"

export const TMDriverGenericTable: React.FC<TMDriverGenericTableProps> = ({
  type,
  bulkEdit,
  dataSource,
  tableFormik,
}) => {
  const { errors, setFieldTouched, setFieldValue } = tableFormik

  const COLUMN: ColumnsType<GenericDriverStaff> = [
    {
      title: t("Driver’s name"),
      dataIndex: "driver_staff",
      key: "driver_staff",
      align: "center",
      render: (val: GenericDriverStaff["driver_staff"]) => val.staff_name,
    },

    {
      title: t("Facility name"),
      dataIndex: "facility",
      align: "center",
      key: "facility",
      render: (val: GenericDriverStaff["facility"]) => val.facility_name,
    },

    {
      title: t("Alcholol Checker"),
      dataIndex: "alcohol_checker",
      align: "center",
      key: "alcohol_checker",
      children: [
        {
          title: t("Power"),
          dataIndex: "power",
          align: "center",
          key: "power",
          render: (val: "1" | "2") => getOKOrNG(val),
        },

        {
          title: t("Damage to the device"),
          dataIndex: "damage_to_device",
          align: "center",
          key: "damage_to_device",
          render: (val: "1" | "2") => getOKOrNG(val),
        },
      ],
    },

    {
      title: t("alcohol check"),
      dataIndex: "alcohol_check",
      align: "center",
      key: "alcohol_check",
      render: (val: "1" | "2") => getOKOrNG(val),
    },

    {
      title: t("Visual check"),
      dataIndex: "visual_check",
      align: "center",
      key: "visual_check",
      render: (val: "1" | "2") => getOKOrNG(val),
    },

    {
      title: t("Transportation Start Time"),
      dataIndex: "start_time",
      align: "center",
      key: "start_time",
      render: (val: GenericDriverStaff["start_time"], _, index) =>
        bulkEdit ? (
          <Flex justify={"center"} gap={"4px"}>
            <Box component={"span"} mt={10} c={"error"} fz={"md"} fw={"bold"}>
              {"*"}
            </Box>
            <SelectTime
              handleBlur={() => {
                setFieldTouched(`data[${type}][${index}].start_time`, true)
              }}
              value={val}
              error={
                // @ts-ignore
                errors.data?.[type]?.[index]?.start_time &&
                // @ts-ignore
                errors.data[type][index].start_time
              }
              onTimeChange={(currTime) => {
                setFieldValue(`data[${type}][${index}].start_time`, currTime)
              }}
            />
          </Flex>
        ) : (
          val
        ),
    },

    {
      title: t("Transportation End Time"),
      dataIndex: "end_time",
      align: "center",
      key: "end_time",
      render: (val: GenericDriverStaff["end_time"], _, index) =>
        bulkEdit ? (
          <Flex justify={"center"} gap={"4px"}>
            <Box component={"span"} mt={10} c={"error"} fz={"md"} fw={"bold"}>
              {"*"}
            </Box>
            <SelectTime
              value={val}
              error={
                // @ts-ignore
                errors.data?.[type]?.[index]?.end_time &&
                // @ts-ignore
                errors.data?.[type]?.[index]?.end_time
              }
              handleBlur={() =>
                setFieldTouched(`data[${type}][${index}].end_time`, true)
              }
              onTimeChange={(currTime) => {
                setFieldValue(`data[${type}][${index}].end_time`, currTime)
              }}
            />
          </Flex>
        ) : (
          val
        ),
    },

    {
      title: t("Conductor"),
      dataIndex: "escort_staff",
      align: "center",
      key: "escort_staff",
      render: (val: GenericDriverStaff["escort_staff"]) => val.staff_name,
    },

    {
      title: t("Vehicle"),
      dataIndex: "car",
      align: "center",
      key: "car",
      render: (val: GenericDriverStaff["car"]) => val.car_name,
    },

    {
      title: t("Route"),
      dataIndex: "route",
      align: "center",
      key: "route",
    },
  ]

  return (
    <Table
      columns={COLUMN}
      dataSource={dataSource}
      scroll={{
        x: "max-content",
      }}
    />
  )
}
