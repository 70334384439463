import {
  Button,
  Card,
  FACILITY_TYPES,
  Modal,
  MonthPagination,
  SERVICE_TYPE_VALUES,
  Table,
  Tag,
  pageSize,
  theme,
} from "@project/common"
import { Flex, Typography, notification } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT } from "../../../constants"
import { AddUserModal } from "./AddUserModal"
import { deleteActualCostBurdenRecordUser } from "../../../services"
import { useMutation } from "react-query"
import { useRouter } from "next/router"
import { useAuthContext } from "../../../context"

export const ActualCostBurdenDetailTableList = ({
  dataSource,
  isLoading,
  refetch,
  params,
  setParams,
  isFetching,
}: {
  dataSource: any
  isLoading?: boolean
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  refetch: any
  isFetching: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id, facility, date } = router?.query as any
  const [openModal, setOpenModal] = useState(false)
  const { facilities } = useAuthContext()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const { mutate: deleteUser, isLoading: isDeleting } = useMutation(
    deleteActualCostBurdenRecordUser,
    {
      onSuccess: () => {
        notification.success({
          message: t("User") + t("Deleted Successfully"),
        })
        setSelectedData(null)
        setOpenDeleteModal(false)
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const column: ColumnsType<any[]> = [
    {
      key: "1",
      align: "center",
      title: t("Username "),
      width: 350,
      render: (row) => row?.user?.username || "",
      sorter: (a: any, b: any) =>
        a.user?.username?.length - b.user?.username?.length,
    },
    {
      key: "2",
      align: "center",
      title: t("Using Service "),
      width: 212,
      render: (row) => {
        const usedService = FACILITY_TYPES?.find(
          (v) => v?.value === row?.user?.user_certificate?.service_type,
        )
        return (
          usedService && (
            <Tag
              title={t(usedService?.label)}
              padding={"4px"}
              style={{
                width:
                  usedService?.value === SERVICE_TYPE_VALUES.TRANSITION
                    ? "165px"
                    : "",
                whiteSpace: "pre-wrap",
              }}
              color={
                usedService?.value === SERVICE_TYPE_VALUES.TYPE_1
                  ? "warning"
                  : usedService?.value === SERVICE_TYPE_VALUES.TYPE_2
                    ? "success"
                    : "processing"
              }
            />
          )
        )
      },
      sorter: (a: any, b: any) =>
        a.user?.user_certificate?.service_type -
        b.user?.user_certificate?.service_type,
    },
    {
      key: "3",
      align: "center",
      title: t("Price"),
      width: 160,
      render: (row) => (
        <Typography.Text>
          {t("￥")}
          {row?.cost || 0}
        </Typography.Text>
      ),
    },
    {
      key: "4",
      align: "center",
      title: t("Edit"),
      width: 100,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Edit")}
            type={"link"}
            style={{
              textDecoration: "underline",
              color: theme.colors.action,
            }}
            padding={"0px"}
            onClick={() => {
              setSelectedData({
                user_id: `${row?.user_id}`,
                price: row?.cost,
                use_service_id: row?.user?.user_certificate?.service_type,
                burden_user_record_id: row?.id,
              })
              setOpenModal(true)
            }}
          />
        </Flex>
      ),
    },
    {
      key: "5",
      align: "center",
      title: t("Delete"),
      width: 100,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Delete")}
            type={"link"}
            style={{
              textDecoration: "underline",
              color: theme.colors.error,
            }}
            padding={"0px"}
            onClick={() => {
              setSelectedData({
                id: row?.id,
              })
              setOpenDeleteModal(true)
            }}
          />
        </Flex>
      ),
    },
    {
      key: "6",
      align: "center",
      title: t("Last update"),
      width: 300,
      render: (row) => {
        return (
          <Flex gap={8} justify={"center"} wrap={"wrap"}>
            <Typography.Text style={{ whiteSpace: "nowrap" }}>
              {dayjs(row?.updated_datetime).format(
                `${FULL_DATE_FORMAT} HH:mm:ss`,
              )}
            </Typography.Text>
            <Typography.Text>
              {row?.user_name || dataSource?.data?.user_name}
            </Typography.Text>
          </Flex>
        )
      },
    },
  ]

  return (
    <>
      <Card
        title={t("{{facility_name}} {{date}}Actual cost burden list", {
          facility_name:
            facilities?.find((d) => d?.value == facility)?.label || "",
          date: dayjs().format(FULL_DATE_FORMAT),
        })}
        extra={
          <MonthPagination
            currentMonth={dayjs(params?.date)?.month()}
            currentYear={dayjs(params?.date)?.year()}
            change={"month"}
            onMonthChange={(_, __, date) => {
              setParams({
                ...params,
                date,
              })
            }}
            showDate={false}
            disabled={isLoading}
            width={"80px"}
          />
        }
      >
        <Flex vertical gap={16}>
          <Flex gap={12} wrap={"wrap"}>
            <Button
              btnText={t("Bulk Edit")}
              shape={"round"}
              disabled={isLoading}
              onClick={() =>
                router.push(
                  `/actual-cost-burden-record/list/bulk-edit/${id}?facility=${facility}&date=${date}`,
                )
              }
            />
            <Button
              btnText={t("Add user")}
              shape={"round"}
              iconType={"plus-circle"}
              type={"primary"}
              disabled={isLoading}
              onClick={() => setOpenModal(true)}
            />
          </Flex>
          <Table
            columns={column}
            headerBorderRadius={0}
            dataSource={dataSource?.data?.burden_user_records || []}
            pageSize={pageSize}
            current={params?.page}
            total={dataSource?.data?.count || 0}
            showPaginationOf={"both"}
            pagination={false}
            loading={isLoading || isFetching}
            scroll={{ x: 800 }}
            onChange={(v) =>
              setParams({
                ...params,
                page: v,
              })
            }
          />
        </Flex>
      </Card>
      <Button
        btnText={t("<<Back")}
        width={"fit-content"}
        shape={"round"}
        onClick={() => router.back()}
      />
      {openModal && (
        <AddUserModal
          burden_record_id={router?.query?.id}
          open={openModal}
          defaultData={selectedData}
          setOpen={(val) => {
            setSelectedData(null)
            setOpenModal(val)
          }}
          facilityId={facility}
          refetch={refetch}
          price={dataSource?.data?.actual_cost?.price || 0}
          useInvoiceFlg={
            dataSource?.data?.actual_cost?.facility_use_invoice_flg
          }
        />
      )}
      {openDeleteModal && (
        <Modal
          open={openDeleteModal}
          onCancel={() => setOpenDeleteModal(false)}
          footer={null}
          title={null}
          borderRadius={"12px"}
          width={381}
          bodyPadding={"16px 24px"}
          maskClosable={false}
        >
          <Flex vertical gap={16}>
            <Flex align={"center"} gap={8}>
              <img src={"/assets/icons/NotificationAlert.svg"} alt={""} />
              <Typography.Title
                level={4}
                style={{
                  margin: 0,
                }}
              >
                {t("Are you sure??")}
              </Typography.Title>
            </Flex>

            <Flex justify={"flex-end"} gap={8} wrap={"wrap"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                disabled={isDeleting}
                onClick={() => {
                  setSelectedData(null)
                  setOpenDeleteModal(false)
                }}
              />
              <Button
                btnText={t("Ok")}
                shape={"round"}
                danger
                type={"primary"}
                borderColor={theme.colors.error}
                onClick={() => deleteUser(selectedData?.id)}
                isLoading={isDeleting}
              />
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}
