import { omit } from "@project/common"
import { FacilityWorkTableType } from "../../../../types"

export function sanitizeWorkValues(
  facilityWorkData: Array<FacilityWorkTableType>,
): Array<Partial<FacilityWorkTableType>> {
  return facilityWorkData
    .filter((work) => !!work.work_title)
    .map((work) => {
      if (work.is_new) {
        return omit(work, ["is_new", "id"])
      }
      return work
    })
}
