import { useQuery } from "react-query"
import { getUserAttendanceDailyList } from "../../../../services"
import {
  ADDITIONAL_ITEM_VALUES,
  FACILITY_SERVICE_TYPES,
  getOthersCheckBoxItems,
  getUserAttendanceTypesByService,
} from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

export const useFetchDailyAttendanceCalenderData = ({
  params,
}: {
  params: any
}) => {
  const { t } = useTranslation()
  const {
    data: userDayAttendance,
    isLoading: isAttendanceDataLoading,
    refetch: refetchAttendanceList,
    isFetching,
  } = useQuery(
    ["user-day-attendance", params],
    () =>
      getUserAttendanceDailyList({
        facilityIds: params?.facility_ids?.join(","),
        service: params?.user_service_id?.join(","),
        date: params?.date?.format("YYYY-MM-DD"),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: ({ data }) => {
        return {
          ...data,
          attendance_list:
            data?.attendance_list?.map((attendance, index) => {
              const otherOptions = getOthersCheckBoxItems(
                attendance?.service_type,
                attendance?.system_status_management,
              )
              const otherOptionValues = otherOptions?.map((opt) => opt.value)
              const medicalCollaborationAddition = Array.isArray(
                attendance?.additional_items,
              )
                ? attendance?.additional_items?.find(
                    (item) =>
                      item?.additional_item ==
                      ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
                  )
                : {
                    additional_item_value: null,
                    additional_item_value2: 0,
                  }

              const otherAdditions = Array.isArray(attendance?.additional_items)
                ? attendance?.additional_items
                    ?.filter((item) =>
                      otherOptionValues.includes(item?.additional_item),
                    )
                    ?.map((item) => item?.additional_item)
                : []
              return {
                ...attendance,
                tempId: attendance?.id || index + 1,
                date: dayjs(attendance?.date)?.format("YYYY-MM-DD"),
                furigana: attendance?.user?.furigana,
                username: attendance?.user?.username,
                facility_name: attendance?.facility_name,
                service_type_label: t(
                  FACILITY_SERVICE_TYPES?.[attendance?.service_type],
                ),
                service_provision_status: t(
                  getUserAttendanceTypesByService(
                    attendance?.service_type,
                  )?.find(
                    (attendanceType) =>
                      attendanceType?.value == attendance?.attendance_type,
                  )?.label,
                ),
                attendance_type: attendance?.attendance_type,
                start_time: attendance?.start_time,
                end_time: attendance?.end_time,
                used_user_attendance_actual_cost:
                  attendance?.user_attendance_actual_cost
                    ?.filter((cost) => cost.used_flag)
                    ?.map((c) => c?.actual_cost_id),
                user_attendance_actual_cost:
                  attendance?.user_attendance_actual_cost || [],
                meal_id: attendance?.meal_id,
                scheduled_meal_id: attendance?.scheduled_meal_id,
                meal_name: attendance?.scheduled_meal?.name,
                used_meal_name: attendance?.used_meal?.name,
                remarks: attendance?.remarks,
                remarks_output: attendance?.remarks_output,
                absent_count_for_month: attendance?.absent_count_for_month || 0,
                others: otherAdditions,
                medical_collaboration_system:
                  medicalCollaborationAddition?.additional_item_value,
                guidance_flag:
                  medicalCollaborationAddition?.additional_item_value2,
              }
            }) || [],
          attendance_stat: {
            attendance: (data?.attendance_stat?.present_count || 0) + "人",
            absence: (data?.attendance_stat?.absent_count || 0) + "人",
            absence_no_addition:
              (data?.attendance_stat?.absent_no_addition_count || 0) + "人",
            unregistered:
              (data?.attendance_stat?.unregistered_count || 0) + "人",
            experience: (data?.attendance_stat?.experience_count || 0) + "人",
            total_application: (data?.attendance_stat?.total_count || 0) + "人",
          },
        }
      },
    },
  )
  return {
    userDayAttendance,
    isAttendanceDataLoading,
    refetchAttendanceList,
    isFetching,
  }
}
