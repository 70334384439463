import { useRouter } from "next/router"
import React, { forwardRef, useState } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Flex, Spin } from "antd"
import { useFormik } from "formik"

// commons
import {
  Box,
  Card,
  Button,
  filterObject,
  useUpdateSearchParams,
} from "@project/common"
import { TMDriverGenericTable } from "./common/TMDriverTable"

// utils
import { sanitizeStaffValues } from "./utils/sanitizeStaffValues"

// types
import {
  TMDriverListTableProps,
  TMDriverListTableValidation,
} from "../../types/TMDriverList.types"

export const TransportMgmtDriverListTable = forwardRef<
  HTMLDivElement,
  TMDriverListTableProps
>(
  (
    {
      date,
      respData,
      mutatingUserTransportRec,
      loadingDailyTransportData,
      asyncMutateTransportRec,
    },
    ref,
  ) => {
    const { query } = useRouter()
    const [bulkEdit, setBulkEdit] = useState<boolean>(false)

    const [updateParams] = useUpdateSearchParams()

    // !## Formik instantiation
    const formik = useFormik<TMDriverListTableProps["respData"]>({
      initialValues: respData,
      enableReinitialize: true,
      validationSchema: TMDriverListTableValidation,
      onSubmit: async (values) => {
        const sanitizedDropStaffs = sanitizeStaffValues(values.data.drop_staffs)
        const sanitizedPickupStaffs = sanitizeStaffValues(
          values.data.pickup_staffs,
        )
        try {
          await asyncMutateTransportRec({
            drop_staffs: sanitizedDropStaffs,
            pickup_staffs: sanitizedPickupStaffs,
          })
          setBulkEdit(false)
        } catch (error) {
          setBulkEdit(false)
        } finally {
          setBulkEdit(false)
        }
      },
    })
    const { resetForm, handleSubmit, values: formikValues } = formik

    return (
      <>
        <Flex justify={"space-between"}>
          <Button
            shape={"round"}
            type={"default"}
            btnText={t("<< Back")}
            disabled={mutatingUserTransportRec}
            onClick={() =>
              updateParams(
                filterObject({
                  facility_ids: query?.facility_ids,
                  use_service: query?.service_type,
                  list_display: query?.list_display,
                  driver_id: query?.driver_staff_id,
                  date: query?.date,
                }),
                `/user-transport-management/${query.date as string}`,
              )
            }
          />

          {bulkEdit ? (
            <Flex gap={10}>
              <Button
                shape={"round"}
                type={"default"}
                btnText={t("Cancel")}
                disabled={mutatingUserTransportRec}
                onClick={() => {
                  resetForm()
                  setBulkEdit(false)
                }}
              />
              <Button
                shape={"round"}
                type={"primary"}
                btnText={t("Save")}
                isLoading={mutatingUserTransportRec}
                onClick={() => {
                  handleSubmit()
                }}
              />
            </Flex>
          ) : (
            <Button
              shape={"round"}
              type={"default"}
              btnText={t("Bulk Edit")}
              onClick={() => setBulkEdit(true)}
            />
          )}
        </Flex>

        <Box display={"flex"} direction={"column"} ref={ref}>
          <Spin spinning={loadingDailyTransportData}>
            <Card
              title={t(
                "{{engDate}}’s user transportation management（Pick up）",
                {
                  engDate: dayjs(query.date as string)
                    .locale("en")
                    .format("YYYY年MM月DD日 （ddd）"),
                  jaDate: dayjs(query.date as string)
                    .locale("ja")
                    .format("YYYY年MM月DD日 （dd）"),
                },
              )}
            >
              <>
                {/* Pickup Driver table */}
                <TMDriverGenericTable
                  tableFormik={formik}
                  bulkEdit={bulkEdit}
                  type={"pickup_staffs"}
                  dataSource={formikValues?.data?.pickup_staffs}
                />

                {/* DropOff Driver table */}
                <Box mt={28} display={"flex"} direction={"column"} gap={"16px"}>
                  <h3 className={"table-title"}>
                    {t(
                      "{{engDate}}’s user transportation management（Drop off）",
                      {
                        engDate: dayjs(date)
                          .locale("en")
                          .format("YYYY年MM月DD日 （ddd）"),
                        jaDate: dayjs(date)
                          .locale("ja")
                          .format("YYYY年MM月DD日 （dd）"),
                      },
                    )}
                  </h3>
                  <TMDriverGenericTable
                    tableFormik={formik}
                    bulkEdit={bulkEdit}
                    type={"drop_staffs"}
                    dataSource={formikValues?.data?.drop_staffs}
                  />
                </Box>
              </>
            </Card>
          </Spin>
        </Box>
      </>
    )
  },
)
