import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../context"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  FACILITY_TYPES,
  GetUpToFiveYears,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import { MonthlyUseStatusChartOperationType } from "../../types/monthlyUseStatusChart.types"

dayjs.extend(arraySupport)
export const MonthlyUseStatusChartOperation = ({
  params,
  setParams,
}: {
  params: MonthlyUseStatusChartOperationType
  setParams: React.Dispatch<
    React.SetStateAction<MonthlyUseStatusChartOperationType>
  >
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [values, setValues] = useState({
    facility: null,
    year: null,
    service: [],
  })
  useEffect(() => {
    setValues({
      ...params,
    })
  }, [])
  return (
    <AccordionCard
      defaultActiveKey={["MonthlyUseStatusChartOperation"]}
      items={[
        {
          key: "MonthlyUseStatusChartOperation",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("monthly_use_status_facility")}
                  width={"136px"}
                />
                <SelectInput
                  name={"facility"}
                  placeholder={"--"}
                  value={values?.facility || null}
                  options={facilities || []}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      facility: val,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Year")} width={"136px"} />
                <Flex align={"center"} gap={8}>
                  <SelectInput
                    width={"86px"}
                    name={"year"}
                    options={GetUpToFiveYears(2023)}
                    placeholder={"--"}
                    value={values?.year}
                    filterSort={() => null}
                    onChange={(val) => {
                      setValues((prev) => {
                        return {
                          ...prev,
                          year: +val,
                        }
                      })
                    }}
                  />

                  <Typography.Text>{t("Year")}</Typography.Text>
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Displaying service")}
                  width={"136px"}
                />
                <CheckBoxGroup
                  items={FACILITY_TYPES || []}
                  value={values?.service}
                  onChange={(list: CheckboxValueType[]) =>
                    setValues({
                      ...values,
                      service: list,
                    })
                  }
                />
              </div>
              <div className={"search__content display_button"}>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  height={"36px"}
                  onClick={() => setParams(values)}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
