import {
  ADDITIONAL_ITEM_VALUES,
  Button,
  CALENDAR_HOURS_MINUTES,
  CheckBox,
  CheckBoxGroup,
  FACILITY_TYPES,
  Grid,
  InputAreaField,
  InputField,
  MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
  MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
  RadioGroup,
  SelectInput,
  Tag,
  USER_ATTENDANCE_VALUES,
  defaultAbsentNote,
  displayLabelForTrialUseSupportInAttendance,
  getEndTimeHour,
  getOthersCheckBoxItems,
  getUserAttendanceTypesByService,
  theme,
} from "@project/common"
import { Flex, Popconfirm, Typography, notification } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import {
  FULL_DATE_FORMATE,
  MEDICAL_COLLABORATION_SYSTEM,
  MEDICAL_COLLABORATION_SYSTEM_VALUES,
  PICK_DROP_OPTIONS_INT_VAL,
  YES_NO_BOOL_LIST,
  showOtherPlace,
} from "../../../../constants"
import { createOrUpdateUserDailyAttendance } from "../../../../services"
import {
  bodyTemperatureOptions,
  breakTimeRecordToArray,
  revertBreakTimeFormatBack,
} from "../../../../utils"
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"
import { japaneseAlphaRegex } from "../../../../utils/validation"
import { ROUTE_OPTIONS } from "../../../UserTransportManagementContainer/constant"
import BreakTimeFields from "./components/BreakTimeFields"
const StyledFormWrapper = styled.form`
  .sm_block {
    display: none;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .sm_block {
      display: block;
    }
    .md_block {
      display: none;
    }
    #yes_no_setting {
      flex-direction: column !important;
      align-items: flex-start;
      .place_selector {
        width: 100% !important;
      }
      .ant-checkbox-wrapper {
        span {
          white-space: nowrap !important;
        }
      }
      .mt-3 {
        margin-top: 3px !important;
      }
    }
  }
`
export const DayListEditForm = ({
  userAttendanceData,
  onCancel,
  onSuccess,
  staffList = [],
}: {
  userAttendanceData: any
  onCancel: () => void
  onSuccess?: () => void
  staffList?: Array<any>
}) => {
  const { t } = useTranslation()
  const { mutate, isLoading: savingAttendance } = useMutation({
    mutationFn: createOrUpdateUserDailyAttendance,
    onSuccess: () => {
      if (typeof onSuccess == "function") {
        onSuccess()
      }
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const [isReset, setIsReset] = useState(false)
  const [absentCountMonth, setAbsentCountMonth] = useState<number>(
    +userAttendanceData?.absent_count_for_month || 0,
  )
  const [trialUseSupportCountTillDate, setTrialUseSupportCountTillDate] =
    useState<number>(
      +userAttendanceData?.trial_use_support_count_till_date || 0,
    )
  const [trialUseSupportCountMonth, setTrialUseSupportCountMonth] =
    useState<number>(
      +userAttendanceData?.trial_use_support_count_for_month || 0,
    )

  const [intensiveSupportCountMonth, setIntensiveSupportCountMonth] =
    useState<number>(
      +userAttendanceData?.intensive_support_count_for_month || 0,
    )
  const defaultAbsentNoteWithNextScheduled =
    defaultAbsentNote +
    `${
      userAttendanceData?.next_scheduled_date
        ? dayjs(userAttendanceData?.next_scheduled_date).format("MM月DD日")
        : ""
    }`

  const formik = useFormik({
    initialValues: {
      ...userAttendanceData,
      date: userAttendanceData?.date,
      user_id: userAttendanceData?.user_id,
      attendance_type: userAttendanceData?.attendance_type || "0",
      service_type: userAttendanceData?.service_type,
      start_time_hr: userAttendanceData?.start_time
        ? userAttendanceData?.start_time?.split(":")?.[0]
        : "",
      start_time_min: userAttendanceData?.start_time
        ? userAttendanceData?.start_time?.split(":")?.[1]
        : "",
      end_time_hr: userAttendanceData?.end_time
        ? userAttendanceData?.end_time?.split(":")?.[0]
        : "",
      end_time_min: userAttendanceData?.end_time
        ? userAttendanceData?.end_time?.split(":")?.[1]
        : "",
      body_temp_first: userAttendanceData?.temperature
        ? userAttendanceData?.temperature?.split(".")?.[0]
        : "",
      body_temp_second: userAttendanceData?.temperature
        ? userAttendanceData?.temperature?.split(".")?.[1]
        : "",
      used_actual_cost: userAttendanceData?.user_attendance_actual_cost
        ?.filter((ac: { used_flag: any }) => ac?.used_flag)
        ?.map((ac: { actual_cost_id: any }) => ac?.actual_cost_id),
      remarks: userAttendanceData?.remarks,
      remarks_NHIF: userAttendanceData?.remarks_output,
      medical_collaboration_system:
        userAttendanceData?.medical_collaboration_system,
      guidance_flag: userAttendanceData?.guidance_flag ? true : false,
      absence_reason:
        userAttendanceData?.absence_reason ??
        defaultAbsentNoteWithNextScheduled,
      save_staff_id: userAttendanceData?.save_staff_id,
      furigana: "",
      others: userAttendanceData?.others || [],
      break_time_list: breakTimeRecordToArray(userAttendanceData),
      system_status_management:
        userAttendanceData?.system_status_management || {},
      community_life_support_base:
        userAttendanceData?.community_life_support_base || false,
      goto_meet_flag: userAttendanceData?.goto_meet_flag || 0,
      take_flag: userAttendanceData?.take_flag || 0,
      pickup: {
        id: userAttendanceData?.pickup?.id,
        place: userAttendanceData?.pickup?.place,
        place_other_name: userAttendanceData?.pickup?.place_other_name,
        route: userAttendanceData?.pickup?.route,
        same_premises: userAttendanceData?.pickup?.same_premises,
        use_transportation_flag:
          userAttendanceData?.pickup?.use_transportation_flag,
        pickup_time: userAttendanceData?.pickup?.pickup_time,
        arrival_time: userAttendanceData?.pickup?.arrival_time,
        desired_time: userAttendanceData?.pickup?.desired_time,
        remarks: userAttendanceData?.pickup?.remarks,
      },
      drop: {
        id: userAttendanceData?.drop?.id,
        place: userAttendanceData?.drop?.place,
        place_other_name: userAttendanceData?.drop?.place_other_name,
        route: userAttendanceData?.drop?.route,
        same_premises: userAttendanceData?.drop?.same_premises,
        use_transportation_flag:
          userAttendanceData?.drop?.use_transportation_flag,
        pickup_time: userAttendanceData?.drop?.pickup_time,
        arrival_time: userAttendanceData?.drop?.arrival_time,
        desired_time: userAttendanceData?.drop?.desired_time,
        remarks: userAttendanceData?.drop?.remarks,
      },
    },
    validationSchema: yup.object().shape({
      attendance_type: yup.string().required(t("Required")),
      start_time_hr: yup
        .string()
        .test("start-time-hr-required", t("Required"), function (value) {
          const { attendance_type } = this.parent

          // valid if hour is present or attendance type is absent
          return (
            !!value ||
            attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
            attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
          )
        }),
      start_time_min: yup
        .string()
        .test("start-time-min-required", t("Required"), function (value) {
          const { attendance_type } = this.parent
          // valid if min is present or attendance type is absent
          return (
            !!value ||
            attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
            attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
          )
        }),
      end_time_hr: yup
        .string()
        .nullable()
        .test(
          "is-greater-or-equal",
          t("End time must be after start time"),
          function (end_time_hr) {
            const { start_time_hr, start_time_min, end_time_min } = this.parent
            // Skip this validation if start_time_hr or start_time_min is empty or invalid
            // end time as optional
            if (
              !start_time_hr ||
              !start_time_min ||
              (start_time_hr === "00" && start_time_min === "00") ||
              (!end_time_hr && !end_time_min)
            ) {
              return true // Skip validation
            }
            const startTime =
              parseInt(start_time_hr) * 60 + parseInt(start_time_min)
            const endTime =
              parseInt(end_time_hr || "0") * 60 + parseInt(end_time_min || "0")

            return endTime >= startTime
          },
        ),
      end_time_min: yup
        .string()
        .nullable()
        .test(
          "is-greater-or-equal",
          t("End time must be after start time"),
          function (end_time_min) {
            const { start_time_hr, start_time_min, end_time_hr } = this.parent
            // Skip this validation if start_time_hr or start_time_min is empty or invalid
            // end time is optional
            if (
              !start_time_hr ||
              !start_time_min ||
              (start_time_hr === "00" && start_time_min === "00") ||
              (!end_time_hr && !end_time_min)
            ) {
              return true // Skip validation
            }
            const startTime =
              parseInt(start_time_hr) * 60 + parseInt(start_time_min)
            const endTime =
              parseInt(end_time_hr || "0") * 60 + parseInt(end_time_min || "0")

            return endTime >= startTime
          },
        ),
    }),
    onSubmit: (formField) => {
      const linkAttendanceAndTransportTime =
        userAttendanceData?.system_status_management
          ?.link_transport_with_attendance
      const attendanceStartTime =
        formField.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
        formField.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
          ? null
          : `${formField.start_time_hr}:${formField.start_time_min}`
      const attendanceEndTime =
        formField.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
        formField.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
          ? null
          : formField.end_time_hr && formField.end_time_min
            ? `${formField.end_time_hr}:${formField.end_time_min}`
            : null
      mutate({
        ...userAttendanceData,
        ...formField,
        ...revertBreakTimeFormatBack(formField.break_time_list),
        attendance_type: formField.attendance_type,
        start_time: attendanceStartTime,
        end_time: attendanceEndTime,
        temperature:
          formField.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
          formField.attendance_type ==
            USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
            ? null
            : formField.body_temp_first && formField.body_temp_second
              ? `${formField.body_temp_first}.${formField.body_temp_second}`
              : null,
        user_attendance_actual_cost:
          userAttendanceData?.user_attendance_actual_cost?.map(
            (ac: { actual_cost_id: any }) => {
              return {
                ...ac,
                used_flag: formField.used_actual_cost?.includes(
                  ac?.actual_cost_id,
                ),
              }
            },
          ),
        guidance_flag: formField.guidance_flag ? 1 : 0,
        remarks_output: formField.remarks_NHIF,
        update_additional_items: true,
        community_life_support_base:
          formField.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
            ? formField.community_life_support_base
            : false,
        pickup: {
          ...formField.pickup,
          arrival_time: linkAttendanceAndTransportTime
            ? attendanceStartTime
            : formField?.pickup?.arrival_time,
        },
        drop: {
          ...formField.drop,
          pickup_time: linkAttendanceAndTransportTime
            ? attendanceEndTime
            : formField?.drop?.pickup_time,
        },
      })
    },
  })

  const { handleSubmit, values, setFieldValue, errors } = formik

  const staffOptions = useMemo(() => {
    const regex = japaneseAlphaRegex(values.furigana)
    return staffList
      ?.filter(
        (staff) =>
          staff?.staff_name_furiganaame?.match(regex) ||
          staff?.label?.match(regex),
      )
      .map((staff) => ({
        value: staff?.id,
        label: staff?.staff_name,
        furigana: staff?.staff_name_furiganaame,
      }))
  }, [values.furigana])

  const handleAttendanceReset = () => {
    setIsReset(true)
    const resetValues = {
      facility_id: userAttendanceData?.facility_id,
      user_id: userAttendanceData?.user_id,
      date: userAttendanceData?.date,
      save_staff_id: null,
      attendance_type: "0",
      absence_reason: null,
      start_time: null,
      end_time: null,
      remarks: null,
      remarks_output: null,
      temperature: null,
      meal_id: null,
      medical_collaboration_system: null,
      guidance_flag: null,
      others: null,
      break_end_time: null,
      break_end_time2: null,
      break_end_time3: null,
      break_end_time4: null,
      break_end_time5: null,
      break_start_time: null,
      break_start_time2: null,
      break_start_time3: null,
      break_start_time4: null,
      break_start_time5: null,
      user_attendance_actual_cost:
        userAttendanceData?.user_attendance_actual_cost?.map((ac: any) => ({
          ...ac,
          used_flag: false,
        })),
    }
    mutate(resetValues)
  }

  return (
    <StyledFormWrapper
      onSubmit={handleSubmit}
      style={{
        padding: "12px",
      }}
    >
      <div>
        <Grid
          background
          labelContent={t("Date And Time")}
          labelSpan={8}
          padding={"10px"}
        >
          {dayjs(values.date).format(FULL_DATE_FORMATE)}
        </Grid>
        <Grid
          background
          labelContent={t("User name (Hiragana)")}
          labelSpan={8}
          padding={"10px"}
        >
          <Typography.Text>{userAttendanceData?.furigana}</Typography.Text>
          <Flex gap={5}>
            <Typography.Text>{userAttendanceData?.username}</Typography.Text>
            <Tag
              title={t(
                FACILITY_TYPES?.find((v) => v?.value === values?.service_type)
                  ?.label,
              )}
              color={
                values?.service_type === 1
                  ? "warning"
                  : values?.service_type === 2
                    ? "success"
                    : "processing"
              }
            />
          </Flex>
        </Grid>
        <Grid
          background
          labelContent={
            <span>
              {t("Service Provision status")}
              <i style={{ color: theme.colors.error }}>{"*"}</i>
            </span>
          }
          labelSpan={8}
          padding={"10px"}
        >
          <Flex
            justify={"space-between"}
            align={"center"}
            gap={5}
            wrap={"wrap"}
          >
            <SelectInput
              name={"service_provision_status"}
              height={"36px"}
              placeholder={"--"}
              filterSort={() => null}
              options={getUserAttendanceTypesByService(values?.service_type)}
              value={values?.attendance_type || null}
              onChange={(val) => {
                if (val == USER_ATTENDANCE_VALUES.ABSENCE) {
                  // attendance changes to absent increase absent count
                  setAbsentCountMonth((prev) => prev + 1)
                } else {
                  // attendance changes to other and previous attendance was absent reduce absent count
                  if (values.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE)
                    setAbsentCountMonth((prev) => prev - 1)
                }

                if (val == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT) {
                  setTrialUseSupportCountMonth((prev) => prev + 1)
                  setTrialUseSupportCountTillDate((prev) => prev + 1)
                } else {
                  if (
                    values.attendance_type ==
                    USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
                  ) {
                    setTrialUseSupportCountMonth((prev) => prev - 1)
                    setTrialUseSupportCountTillDate((prev) => prev - 1)
                  }
                }

                setFieldValue("attendance_type", val)
              }}
            />
            {values.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT &&
              t(
                displayLabelForTrialUseSupportInAttendance(
                  trialUseSupportCountTillDate,
                ),
              )}
            {(values.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
              values.attendance_type ==
                USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION) &&
              values?.is_paid_leave && (
                <Typography.Text type={"success"}>
                  {t("Paid leave")}
                </Typography.Text>
              )}
            <Typography.Text type={"danger"}>
              {`"${dayjs(values.date).month() + 1}月欠席${absentCountMonth}回"`}
            </Typography.Text>
          </Flex>
          {values.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT && (
            <CheckBox
              label={t("Community life support base")}
              checked={!!values.community_life_support_base}
              onChange={(e) =>
                setFieldValue("community_life_support_base", e.target.checked)
              }
            />
          )}
          <div style={{ color: theme.colors.error }}>
            {trialUseSupportCountMonth > MAX_TRIAL_USE_SUPPORT_COUNT_MONTH &&
              t(
                "*Already did {{number}} times this month so you can not get further addition",
                {
                  number: MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
                },
              )}
          </div>
        </Grid>
        {values?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
        values?.attendance_type ===
          USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION ? (
          <>
            <Grid
              background
              labelContent={t("Recorder")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex gap={8} wrap={"wrap"}>
                <SelectInput
                  name={"recorder_hiragana"}
                  shortSelector
                  width={"58px"}
                  height={"36px"}
                  placeholder={"--"}
                  options={FuriganaAlphabetsOption}
                  value={values?.furigana || null}
                  onChange={(val) => setFieldValue("furigana", val)}
                />
                <SelectInput
                  name={"recorder"}
                  placeholder={"--"}
                  height={"36px"}
                  value={values?.save_staff_id || null}
                  options={staffOptions}
                  onChange={(val) => setFieldValue("save_staff_id", val)}
                />
              </Flex>
            </Grid>
            <Grid
              background
              labelContent={
                values?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE
                  ? t("Reasons for extra charge for absenteeism")
                  : t("Reason for absence")
              }
              labelSpan={8}
              padding={"10px"}
            >
              <InputAreaField
                rows={3}
                placeholder={"--"}
                resize={false}
                value={values?.absence_reason || null}
                onChange={({ target: { value } }) =>
                  setFieldValue("absence_reason", value)
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              background
              labelContent={
                <span>
                  {t("Start Time")}
                  <i style={{ color: theme.colors.error }}>{"*"}</i>
                </span>
              }
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={4}>
                <Flex align={"center"} gap={4}>
                  <SelectInput
                    name={"start_time_1"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    value={values?.start_time_hr}
                    options={CALENDAR_HOURS_MINUTES.hours || []}
                    onChange={(value) => {
                      setFieldValue("start_time_hr", value).then(() => {
                        if (value && !values.start_time_min) {
                          setFieldValue("start_time_min", "00")
                        }
                      })
                    }}
                  />
                  <Typography.Text style={{ whiteSpace: "nowrap" }}>
                    {t("Hour")}
                  </Typography.Text>
                </Flex>
                <Typography.Text>{t(":")}</Typography.Text>
                <Flex align={"center"} gap={4}>
                  <SelectInput
                    name={"start_time_2"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    value={values?.start_time_min}
                    options={CALENDAR_HOURS_MINUTES.minutes || []}
                    onChange={(val) => setFieldValue("start_time_min", val)}
                    disabled={values?.start_time_hr === ""}
                  />
                  <Typography.Text style={{ whiteSpace: "nowrap" }}>
                    {t("Minute")}
                  </Typography.Text>
                </Flex>
                <div style={{ color: theme.colors.error }}>
                  {errors?.["start_time_hr"]
                    ? errors?.["start_time_hr"]?.toString()
                    : errors?.["start_time_min"]
                      ? errors?.["start_time_min"]?.toString()
                      : ""}
                </div>
              </Flex>
            </Grid>
            <Grid
              background
              labelContent={t("End Time")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={4}>
                <Flex align={"center"} gap={4}>
                  <SelectInput
                    name={"end_time_1"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    value={values?.end_time_hr}
                    options={getEndTimeHour(values?.start_time_hr) || []}
                    onChange={(value) => {
                      setFieldValue("end_time_hr", value).then(() => {
                        if (value && !values.end_time_min) {
                          setFieldValue("end_time_min", "00")
                        }
                      })
                    }}
                  />
                  <Typography.Text style={{ whiteSpace: "nowrap" }}>
                    {t("Hour")}
                  </Typography.Text>
                </Flex>
                <Typography.Text>{t(":")}</Typography.Text>
                <Flex align={"center"} gap={4}>
                  <SelectInput
                    name={"end_time_2"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    value={values?.end_time_min}
                    options={CALENDAR_HOURS_MINUTES.minutes || []}
                    onChange={(val) => setFieldValue("end_time_min", val)}
                    disabled={values?.end_time_hr === ""}
                  />
                  <Typography.Text style={{ whiteSpace: "nowrap" }}>
                    {t("Minute")}
                  </Typography.Text>
                </Flex>
                <div style={{ color: theme.colors.error }}>
                  {errors?.["end_time_hr"]
                    ? errors?.["end_time_hr"]?.toString()
                    : errors?.["end_time_min"]
                      ? errors?.["end_time_min"]?.toString()
                      : ""}
                </div>
              </Flex>
            </Grid>
            {/* Break Time */}
            <Grid
              background
              labelContent={t("Break Time")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={8}>
                <BreakTimeFields formik={formik} />
              </Flex>
            </Grid>
            <Grid
              background
              labelContent={t("Body temperature upon entry")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={8}>
                <Flex align={"center"} gap={4}>
                  <SelectInput
                    name={"body_temp_1"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    options={bodyTemperatureOptions(31, 42)}
                    value={values?.body_temp_first || null}
                    onChange={(value) =>
                      setFieldValue("body_temp_first", value)
                    }
                  />
                  <Typography.Text>{t(".")}</Typography.Text>
                  <SelectInput
                    name={"body_temp_2"}
                    width={"58px"}
                    height={"36px"}
                    placeholder={"--"}
                    options={bodyTemperatureOptions(0, 9)}
                    value={values?.body_temp_second || null}
                    onChange={(value) =>
                      setFieldValue("body_temp_second", value)
                    }
                  />
                </Flex>
                <Typography.Text>{t("℃")}</Typography.Text>
              </Flex>
            </Grid>
            {/* pick */}
            <Grid
              background
              labelContent={t("Pick-Up Yes/No")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={8}>
                <RadioGroup
                  options={YES_NO_BOOL_LIST}
                  value={values?.pickup?.use_transportation_flag}
                  onChange={(e) => {
                    setFieldValue(
                      "pickup.use_transportation_flag",
                      e.target.value,
                    )
                  }}
                />
              </Flex>
            </Grid>
            {values.pickup.use_transportation_flag && (
              <Grid
                background
                labelContent={t("Pick-Up")}
                labelSpan={8}
                padding={"10px"}
              >
                <Flex
                  align={"center"}
                  gap={8}
                  id={"yes_no_setting"}
                  wrap={"wrap"}
                >
                  <div className={"place_selector"}>
                    <SelectInput
                      name={"place"}
                      options={PICK_DROP_OPTIONS_INT_VAL}
                      width={"150px"}
                      placeholder={"--"}
                      value={values.pickup.place || null}
                      onChange={(val) => setFieldValue("pickup.place", val)}
                    />
                    {showOtherPlace(values.pickup.place) && (
                      <div className={"sm_block"}>
                        <InputField
                          height={"36px"}
                          style={{ marginTop: "5px" }}
                          value={values.pickup.place_other_name}
                          onChange={(e) =>
                            setFieldValue(
                              "pickup.place_other_name",
                              e.target.value,
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Flex align={"center"} gap={8}>
                    <Flex align={"center"} gap={4}>
                      <SelectInput
                        name={"route"}
                        options={ROUTE_OPTIONS}
                        width={"50px"}
                        shortSelector
                        value={values.pickup.route}
                        onChange={(val) => setFieldValue("pickup.route", val)}
                      />{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {" "}
                        {t("Route")}{" "}
                      </span>
                    </Flex>
                    <CheckBox
                      label={t("Within The Same Premises")}
                      checked={values.pickup.same_premises}
                      onChange={(e) =>
                        setFieldValue("pickup.same_premises", e.target.checked)
                      }
                    />
                  </Flex>
                </Flex>
                {showOtherPlace(values.pickup.place) && (
                  <div className={"md_block"}>
                    <InputField
                      style={{ marginTop: "5px" }}
                      value={values.pickup.place_other_name}
                      height={"36px"}
                      onChange={(e) =>
                        setFieldValue("pickup.place_other_name", e.target.value)
                      }
                    />
                  </div>
                )}
              </Grid>
            )}
            {/* drop */}
            <Grid
              background
              labelContent={t("Drop Off Yes/No")}
              labelSpan={8}
              padding={"10px"}
            >
              <Flex align={"center"} gap={8}>
                <RadioGroup
                  options={YES_NO_BOOL_LIST}
                  value={values?.drop?.use_transportation_flag}
                  onChange={(e) => {
                    setFieldValue(
                      "drop.use_transportation_flag",
                      e.target.value,
                    )
                  }}
                />
              </Flex>
            </Grid>
            {values.drop.use_transportation_flag && (
              <Grid
                background
                labelContent={t("Drop-Off")}
                labelSpan={8}
                padding={"10px"}
              >
                <Flex
                  align={"center"}
                  gap={10}
                  wrap={"wrap"}
                  id={"yes_no_setting"}
                >
                  <div className={"place_selector"}>
                    <SelectInput
                      name={"place"}
                      options={PICK_DROP_OPTIONS_INT_VAL}
                      placeholder={"--"}
                      width={"150px"}
                      value={values.drop.place || null}
                      onChange={(val) => setFieldValue("drop.place", val)}
                    />
                    {showOtherPlace(values.drop.place) && (
                      <div className={"sm_block"}>
                        <InputField
                          height={"36px"}
                          style={{ marginTop: "5px" }}
                          value={values.drop.place_other_name}
                          onChange={(e) =>
                            setFieldValue(
                              "drop.place_other_name",
                              e.target.value,
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Flex align={"center"} gap={4} className={"mt-3"}>
                    <Flex align={"center"} gap={4} className={"mt-3"}>
                      <SelectInput
                        name={"route"}
                        options={ROUTE_OPTIONS}
                        width={"50px"}
                        shortSelector
                        value={values.drop.route}
                        onChange={(val) => setFieldValue("drop.route", val)}
                      />{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {" "}
                        {t("Route")}
                      </span>
                    </Flex>
                    <CheckBox
                      label={t("Within The Same Premises")}
                      checked={values.drop.same_premises}
                      onChange={(e) =>
                        setFieldValue("drop.same_premises", e.target.checked)
                      }
                    />
                  </Flex>
                </Flex>
                {showOtherPlace(values.drop.place) && (
                  <div className={"md_block"}>
                    <InputField
                      style={{ marginTop: "5px" }}
                      height={"36px"}
                      value={values.drop.place_other_name}
                      onChange={(e) =>
                        setFieldValue("drop.place_other_name", e.target.value)
                      }
                    />
                  </div>
                )}
              </Grid>
            )}
          </>
        )}

        <Grid
          background
          labelContent={t("Actual cost")}
          labelSpan={8}
          padding={"10px"}
        >
          <CheckBoxGroup
            style={{
              display: "flex",
            }}
            items={userAttendanceData?.user_attendance_actual_cost?.map(
              (ac: {
                actual_cost_id: any
                actual_cost_name: any
                actual_cost_price: any
              }) => ({
                value: ac?.actual_cost_id,
                label: `${ac?.actual_cost_name} (${
                  ac?.actual_cost_price || 0
                }円)`,
              }),
            )}
            value={values?.used_actual_cost || []}
            onChange={(list: CheckboxValueType[]) =>
              setFieldValue("used_actual_cost", list)
            }
          />
        </Grid>

        <Grid
          background
          labelContent={t("Meal")}
          labelSpan={8}
          padding={"10px"}
        >
          <SelectInput
            options={[{ label: "--", value: null }].concat(
              Array.isArray(userAttendanceData?.meal_options)
                ? userAttendanceData?.meal_options?.map((meal) => {
                    return {
                      value: meal?.id,
                      label: meal?.name,
                    }
                  })
                : [],
            )}
            name={"meal_id"}
            onChange={(val) => setFieldValue("meal_id", val)}
            value={values.meal_id}
          />
        </Grid>

        <Grid
          background
          labelContent={t("Medical collaboration system")}
          labelSpan={8}
          padding={"10px"}
        >
          <Flex align={"center"} gap={8} wrap={"wrap"}>
            <SelectInput
              name={"medical__collaboration"}
              width={"200px"}
              placeholder={"--"}
              height={"36px"}
              filterSort={() => null}
              options={[{ label: "--", value: null }].concat(
                MEDICAL_COLLABORATION_SYSTEM,
              )}
              popupMatchSelectWidth={false}
              value={values?.medical_collaboration_system}
              onChange={(value) => {
                setFieldValue("medical_collaboration_system", value)
                if (value == MEDICAL_COLLABORATION_SYSTEM_VALUES.V) {
                  setFieldValue("guidance_flag", false)
                }
              }}
            />
            <CheckBox
              label={t("Guidance regarding sputum aspiration, etc.")}
              disabled={
                values.medical_collaboration_system ==
                MEDICAL_COLLABORATION_SYSTEM_VALUES.V
              }
              onChange={() =>
                setFieldValue("guidance_flag", !values?.guidance_flag)
              }
              checked={values.guidance_flag}
            />
          </Flex>
        </Grid>
        <Grid
          background
          labelContent={t("Others")}
          labelSpan={8}
          padding={"10px"}
        >
          <CheckBoxGroup
            items={getOthersCheckBoxItems(
              values?.service_type,
              values?.system_status_management,
            )}
            value={values?.others}
            onChange={(value) => {
              if (
                value.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT) &&
                !values.others.includes(
                  ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
                )
              ) {
                // intensive support added
                setIntensiveSupportCountMonth((prev) => prev + 1)
              } else if (
                !value.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT) &&
                values.others.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT)
              ) {
                // intensive support removed
                setIntensiveSupportCountMonth((prev) => prev - 1)
              }
              setFieldValue("others", value)
            }}
          />
          <div style={{ color: theme.colors.error }}>
            {intensiveSupportCountMonth > MAX_INTENSIVE_SUPPORT_COUNT_MONTH &&
              t(
                "*Already took {{number}} times this month so you can not get further addition",
                {
                  number: MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
                },
              )}
          </div>
        </Grid>
        <Grid
          background
          labelContent={t("Remarks")}
          labelSpan={8}
          padding={"10px"}
        >
          <InputAreaField
            placeholder={"--"}
            resize={false}
            value={values?.remarks || null}
            onChange={({ target: { value } }) =>
              setFieldValue("remarks", value)
            }
          />
        </Grid>
        <Grid
          background
          labelContent={t(
            "Output remarks to claim National Health Insurance Federation",
          )}
          labelSpan={8}
          padding={"10px"}
        >
          <InputAreaField
            placeholder={"--"}
            resize={false}
            value={values?.remarks_NHIF || null}
            onChange={({ target: { value } }) =>
              setFieldValue("remarks_NHIF", value)
            }
          />
        </Grid>
      </div>
      <br />
      <Flex justify={"space-between"} align={"center"} gap={8} wrap={"wrap"}>
        <Flex align={"center"} gap={8} wrap={"wrap"}>
          <Button btnText={t("Cancel")} shape={"round"} onClick={onCancel} />
          <Button
            btnText={t("Save")}
            shape={"round"}
            onClick={handleSubmit}
            type={"primary"}
            isLoading={savingAttendance && !isReset}
          />
        </Flex>
        <Popconfirm
          title={t("Confirm reset ?")}
          okText={t("OK")}
          cancelText={t("Cancel")}
          onConfirm={handleAttendanceReset}
        >
          <Button
            btnText={t("Reset Attendance Information")}
            otherType={"draft"}
            shape={"round"}
            isLoading={savingAttendance && isReset}
          />
        </Popconfirm>
      </Flex>
    </StyledFormWrapper>
  )
}
