import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { WagesRevenueOFWiseTabTable } from "../WagesRevenueCommon/WagesRevenueOFWiseTabTable"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { Flex, Skeleton, Typography } from "antd"
import {
  allowOnlyNumber,
  Button,
  InputField,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"

import { useWageRevenueDataFetch } from "../../../hooks"
import {
  FACILITY_ROW_INITIAL_VALUES,
  getFacilityWiseRowsValues,
  getTotal,
  sumOfAllMultipleFacilityCols,
  sumOfMultipleFacilityRows,
} from "../WagesRevenueCommon/tabRowsUtils"
import { TabTopInfoContent } from "../WagesRevenueCommon/TabTopInfoContent"
import { useMutation } from "react-query"
import { createWageManagement } from "../../../services"

import { SINGLE_FACILITY_ROWS } from "../WagesRevenueCommon/constantTabRows"
import {
  MeterTotal,
  OVERALL_REVENUE_TAB_COLUMN,
  WageRevenueTabResponse,
} from "../../../types"
import dayjs from "dayjs"

export const WagesRevenueFacilityWiseTab = ({
  params,
  setSearchParams,
  hasEditAccess,
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()

  //fetch facility wise data
  const { wageRevenueData, isLoading } = useWageRevenueDataFetch({
    year: dayjs(params?.date).year(),
    month: dayjs(params?.date).month() + 1,
  })
  const [facilityColumns, setFacilityColumns] = useState([])
  const facilityOptions = facilityColumns?.map((v) => ({
    value: v?.facility_id,
    label: v?.facility_name,
  }))

  useEffect(() => {
    if (wageRevenueData?.data?.length > 1) {
      const facilityCols =
        wageRevenueData?.data?.filter((v) => v?.facility_id) || []
      const transactionData = wageRevenueData?.data?.find(
        (v) => v?.facility_id === 0,
      ) || {
        ...FACILITY_ROW_INITIAL_VALUES,
        facility_id: 0,
      }
      setFacilityColumns(
        [
          ...facilityCols,
          {
            ...FACILITY_ROW_INITIAL_VALUES,
            facility_name: "Total Office",
            key: "total_office",
          },
          {
            ...transactionData,
            facility_name: "Internal transaction extinguish",
            key: "internal_transaction_extinguish",
          },
          {
            ...FACILITY_ROW_INITIAL_VALUES,
            facility_name: "Total",
            key: "grand_total",
          },
        ] || [],
      )
    } else {
      setFacilityColumns(wageRevenueData?.data || [])
    }
  }, [wageRevenueData])

  //save data
  const { isLoading: isMutating, mutate } = useMutation({
    mutationFn: createWageManagement,
    onError: () => {
      showToast({
        type: "error",
        message: t("An error has occurred. Please contact administrator."),
      })
    },
  })
  const onEdit = ({
    facility_id,
    key,
    value,
    action,
    extra = {},
  }: {
    facility_id: number
    key: string
    value: boolean | string | number
    action?: "cancel" | "edit"
    extra?: any
  }) => {
    const newColumns = facilityColumns?.map((col) => {
      if (action === "cancel") {
        const prevData =
          wageRevenueData?.data?.find((v) => v?.facility_id === facility_id) ||
          {}
        return col?.facility_id === facility_id
          ? { ...col, ...prevData, [key]: value, ...extra }
          : col
      }
      return col?.facility_id === facility_id
        ? { ...col, [key]: value, ...extra }
        : col
    })
    setFacilityColumns(newColumns)
  }
  const onSave = (facilityId: number) => {
    const facilityToSave = facilityColumns?.find(
      (v) => v?.facility_id === facilityId,
    )
    if (facilityToSave) {
      mutate(
        {
          values: [{ ...facilityToSave }],
          year: dayjs(params?.date).year(),
          month: dayjs(params?.date).month() + 1,
        },
        {
          onSuccess: () => {
            showToast({
              type: "success",
              message: t("Created successfully"),
            })
            const data = facilityColumns?.map((v) =>
              v?.facility_id === facilityId ? { ...v, is_editing: false } : v,
            )
            setFacilityColumns(data)
          },
        },
      )
    }
  }

  const defaultReturnValue = (
    row: OVERALL_REVENUE_TAB_COLUMN,
    item: WageRevenueTabResponse,
    mode: "download" | "view",
  ) => {
    return mode === "download" ? (
      item ? (
        item[row?.key] ?? ""
      ) : (
        ""
      )
    ) : (
      <Flex justify={"space-between"} align={"center"}>
        {item?.is_editing ? (
          <InputField
            height={"35px"}
            value={item[row?.key]}
            onChange={({ target: { value } }) => {
              const v = allowOnlyNumber(value)
              onEdit({
                facility_id: item?.facility_id,
                key: row?.key,
                value: +v,
              })
            }}
          />
        ) : (
          <Typography.Text>
            {getFacilityWiseRowsValues(row?.key, item)}
          </Typography.Text>
        )}
      </Flex>
    )
  }
  /**
   * Calculates the total of a single facility based on the provided row and item.
   */
  const getTotalOfSingleFacility = (
    row: OVERALL_REVENUE_TAB_COLUMN,
    item: WageRevenueTabResponse,
    mode: "download" | "view",
  ) => {
    const {
      total_1,
      total_2,
      total_3,
      total_4,
      total_5,
      total_6,
      total_7,
      total_8,
      total_9,
      total_10,
      total_11,
    } = getTotal(item)

    if (row?.key === "total_1") {
      return total_1
    }
    if (row?.key === "toal_2") {
      return total_2
    }
    if (row?.key === "total_3") {
      return total_3
    }
    if (row?.key === "total_4") {
      return total_4
    }
    if (row?.key === "total_5") {
      return total_5
    }
    if (row?.key === "total_6") {
      return total_6
    }
    if (row?.key === "total_7") {
      return total_7
    }
    if (row?.key === "total_8") {
      return total_8
    }
    if (row?.key === "total_9") {
      return total_9
    }
    if (row?.key === "total_10") {
      return total_10
    }
    if (row?.key === "total_11") {
      return { total_11 }
    }
    return defaultReturnValue(row, item, mode)
  }

  /**
   * Retrieves the total value of a given row for multiple facilities.
   */
  const getTotalOfMultipleFacility = (
    row: OVERALL_REVENUE_TAB_COLUMN,
    item: WageRevenueTabResponse,
    total: MeterTotal,
    mode: "download" | "view",
  ) => {
    if (row?.key === "total_1") {
      return total?.meter_1_total
    }
    if (row?.key === "total_2") {
      return total?.meter_2_total
    }

    if (row?.key === "total_3") {
      return total?.meter_3_total
    }
    if (row?.key === "total_4") {
      return total?.meter_4_total
    }
    if (row?.key === "total_5") {
      return total?.meter_5_total
    }
    if (row?.key === "total_6") {
      return total?.meter_6_total
    }
    if (row?.key === "total_7") {
      return total?.meter_7_total
    }
    if (row?.key === "total_8") {
      return total?.meter_8_total
    }
    if (row?.key === "total_9") {
      return total?.meter_9_total
    }
    if (row?.key === "total_10") {
      return total?.meter_10_total
    }
    if (row?.key === "total_11") {
      return total?.meter_11_total
    }
    if (row?.key === "total_13") {
      return total?.meter_13_total
    }
    if (row?.key === "total_17") {
      return total?.meter_17_total
    }

    return defaultReturnValue(row, item, mode)
  }
  const renderSummaryColsValue = (
    row: OVERALL_REVENUE_TAB_COLUMN,
    currentValue: string | number,
    total: MeterTotal,
  ) => {
    if (row?.key === "total_1") {
      return total?.meter_1_total
    }
    if (row?.key === "total_2") {
      return total?.meter_2_total
    }
    if (row?.key === "total_3") {
      return total?.meter_3_total
    }
    if (row?.key === "total_4") {
      return total?.meter_4_total
    }
    if (row?.key === "total_5") {
      return total?.meter_5_total
    }
    if (row?.key === "total_6") {
      return total?.meter_6_total
    }
    if (row?.key === "total_7") {
      return total?.meter_7_total
    }
    if (row?.key === "total_8") {
      return total?.meter_8_total
    }
    if (row?.key === "total_9") {
      return total?.meter_9_total
    }
    if (row?.key === "total_10") {
      return total?.meter_10_total
    }
    if (row?.key === "total_11") {
      return total?.meter_11_total
    }
    if (row?.key === "total_13") {
      return total?.meter_13_total
    }
    if (row?.key === "total_17") {
      return total?.meter_17_total
    }

    return currentValue || ""
  }

  /**
   * Renders the grand total for a given row in the overall revenue tab.
   *
   * @param {OVERALL_REVENUE_TAB_COLUMN} row - The row object representing the column in the table.
   * @param {number} currentValue - The current value of the row.
   * @param {MeterTotal} total - The total meter values.
   * @return {React.ReactNode} The rendered grand total value.
   */
  const renderGrandTotal = (
    row: OVERALL_REVENUE_TAB_COLUMN,
    currentValue: number,
    total: MeterTotal,
  ) => {
    const internalTransactionItem = facilityColumns?.find(
      (v) => v?.facility_id === 0,
    )
    const internalTransactionTotal = internalTransactionItem[row?.key] || 0
    const {
      meter_1_total,
      meter_2_total,
      meter_3_total,
      meter_4_total,
      meter_5_total,
      meter_6_total,
      meter_7_total,
      meter_8_total,
      meter_9_total,
      meter_10_total,
      meter_11_total,
      meter_13_total,
      meter_17_total,
    } = sumOfMultipleFacilityRows(internalTransactionItem)

    return renderSummaryColsValue(
      row,
      currentValue + internalTransactionTotal || 0,
      {
        meter_1_total: total?.meter_1_total + meter_1_total,
        meter_2_total: total?.meter_2_total + meter_2_total,
        meter_3_total: total?.meter_3_total + meter_3_total,
        meter_4_total: total?.meter_4_total + meter_4_total,
        meter_5_total: total?.meter_5_total + meter_5_total,
        meter_6_total: total?.meter_6_total + meter_6_total,
        meter_7_total: total?.meter_7_total + meter_7_total,
        meter_8_total: total?.meter_8_total + meter_8_total,
        meter_9_total: total?.meter_9_total + meter_9_total,
        meter_10_total: total?.meter_10_total + meter_10_total,
        meter_11_total: total?.meter_11_total + meter_11_total,
        meter_13_total: total?.meter_13_total + meter_13_total,
        meter_17_total: total?.meter_17_total + meter_17_total,
      },
    )
  }
  const getDynamicFacilityColumns = (mode: "download" | "view") => {
    let meter_1 = 0,
      meter_2 = 0,
      meter_3 = 0,
      meter_4 = 0,
      meter_5 = 0,
      meter_6 = 0,
      meter_7 = 0,
      meter_8 = 0,
      meter_9 = 0,
      meter_10 = 0,
      meter_11 = 0,
      meter_13 = 0,
      meter_17 = 0
    const columns = []
    facilityColumns?.map((item) => {
      const {
        meter_1_total,
        meter_2_total,
        meter_3_total,
        meter_4_total,
        meter_5_total,
        meter_6_total,
        meter_7_total,
        meter_8_total,
        meter_9_total,
        meter_10_total,
        meter_11_total,
        meter_13_total,
        meter_17_total,
      } = sumOfMultipleFacilityRows(item)
      if (item?.key != "internal_transaction_extinguish") {
        meter_1 = meter_1 + meter_1_total
        meter_2 = meter_2 + meter_2_total
        meter_3 = meter_3 + meter_3_total
        meter_4 = meter_4 + meter_4_total
        meter_5 = meter_5 + meter_5_total
        meter_6 = meter_6 + meter_6_total
        meter_7 = meter_7 + meter_7_total
        meter_8 = meter_8 + meter_8_total
        meter_9 = meter_9 + meter_9_total
        meter_10 = meter_10 + meter_10_total
        meter_11 = meter_11 + meter_11_total
        meter_13 = meter_13 + meter_13_total
        meter_17 = meter_17 + meter_17_total
      }
      columns.push({
        key: item?.id,
        dataIndex: item?.id,
        title:
          mode === "download"
            ? t(item?.facility_name) || ""
            : () => {
                if (
                  item?.key === "total_office" ||
                  item?.key === "grand_total"
                ) {
                  return (
                    <Flex
                      vertical
                      gap={8}
                      align={"center"}
                      style={{
                        width: "100px",
                      }}
                    >
                      <Typography.Text>
                        {t(item?.facility_name) || ""}
                      </Typography.Text>
                    </Flex>
                  )
                }
                return (
                  <Flex vertical gap={8} align={"center"}>
                    <Typography.Text>
                      {t(item?.facility_name) || ""}
                    </Typography.Text>
                    {item?.is_editing ? (
                      <Flex gap={8} wrap={"wrap"}>
                        <Button
                          btnText={t("Cancel")}
                          shape={"round"}
                          onClick={() =>
                            onEdit({
                              facility_id: item?.facility_id,
                              key: "is_editing",
                              value: false,
                              action: "cancel",
                              extra:
                                item?.facility_id === 0
                                  ? { is_internal_transaction: false }
                                  : {},
                            })
                          }
                          style={{
                            whiteSpace: "nowrap",
                          }}
                          disabled={isMutating}
                        />
                        <Button
                          btnText={t("Save")}
                          shape={"round"}
                          type={"primary"}
                          onClick={() => onSave(item?.facility_id)}
                          isLoading={isMutating}
                        />
                      </Flex>
                    ) : (
                      <Button
                        btnText={t("edit")}
                        iconType={"blue-pencil"}
                        shape={"round"}
                        onClick={() =>
                          onEdit({
                            facility_id: item?.facility_id,
                            key: "is_editing",
                            value: true,
                            extra:
                              item?.facility_id === 0
                                ? { is_internal_transaction: true }
                                : {},
                          })
                        }
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        disabled={!hasEditAccess(item?.facility_id)}
                      />
                    )}
                  </Flex>
                )
              },
        align: "center",
        width: 250,
        className: "content_align",
        render: (_, row) => {
          if (facilityColumns?.length === 1) {
            return getTotalOfSingleFacility(row, item, mode)
          }
          const currentValue =
            sumOfAllMultipleFacilityCols(facilityColumns?.slice(0, -3) || [])?.[
              row?.key
            ] || 0

          if (item?.key === "grand_total") {
            return renderGrandTotal(row, currentValue, {
              meter_1_total: meter_1,
              meter_2_total: meter_2,
              meter_3_total: meter_3,
              meter_4_total: meter_4,
              meter_5_total: meter_5,
              meter_6_total: meter_6,
              meter_7_total: meter_7,
              meter_8_total: meter_8,
              meter_9_total: meter_9,
              meter_10_total: meter_10,
              meter_11_total: meter_11,
              meter_13_total: meter_13,
              meter_17_total: meter_17,
            })
          }
          if (item?.key === "total_office") {
            return renderSummaryColsValue(row, currentValue, {
              meter_1_total: meter_1,
              meter_2_total: meter_2,
              meter_3_total: meter_3,
              meter_4_total: meter_4,
              meter_5_total: meter_5,
              meter_6_total: meter_6,
              meter_7_total: meter_7,
              meter_8_total: meter_8,
              meter_9_total: meter_9,
              meter_10_total: meter_10,
              meter_11_total: meter_11,
              meter_13_total: meter_13,
              meter_17_total: meter_17,
            })
          }
          return getTotalOfMultipleFacility(
            row,
            item,
            {
              meter_1_total,
              meter_2_total,
              meter_3_total,
              meter_4_total,
              meter_5_total,
              meter_6_total,
              meter_7_total,
              meter_8_total,
              meter_9_total,
              meter_10_total,
              meter_11_total,
              meter_13_total,
              meter_17_total,
            },
            mode,
          )
        },
      })
    })
    return columns || []
  }
  const actualCol = [...getDynamicFacilityColumns("view")]
  const csvCol = [...getDynamicFacilityColumns("download")]
  return (
    <Flex vertical gap={16}>
      <TabTopContent
        tab={t("Facility wise revenue")}
        isLoading={false}
        change={"month"}
        onChange={(value) => {
          setSearchParams({
            ...params,
            date: value,
          })
          updateParams(
            {
              panel: "facility_wise",
              date: dayjs(value).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      {isLoading ? (
        <Flex gap={16} wrap={"wrap"} vertical>
          {SINGLE_FACILITY_ROWS?.map((_, index) => (
            <Flex key={index} gap={8}>
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
            </Flex>
          ))}
        </Flex>
      ) : (
        <>
          {facilityColumns?.length === 1 ? (
            <>
              <TabTopInfoContent
                facilities={facilityOptions || []}
                onChange={() => null}
                facility={facilityColumns[0]?.facility_id || null}
              />
              <WagesRevenueOFWiseTabTable
                columns={actualCol || []}
                csvCols={csvCol || []}
                isLoading={isLoading}
                isMultiple={false}
              />
              <Flex gap={12} wrap={"wrap"}>
                <Button
                  btnText={t("Cancel")}
                  shape={"round"}
                  disabled={isMutating}
                  onClick={() =>
                    onEdit({
                      facility_id: facilityColumns[0]?.facility_id,
                      key: "is_editing",
                      value: false,
                      action: "cancel",
                    })
                  }
                />
                <Button
                  btnText={t("Save")}
                  shape={"round"}
                  type={"primary"}
                  onClick={() => onSave(facilityColumns[0]?.facility_id || 0)}
                  isLoading={isMutating}
                />
              </Flex>
            </>
          ) : (
            <WagesRevenueOFWiseTabTable
              columns={actualCol || []}
              csvCols={csvCol || []}
              isLoading={isLoading}
              isMultiple={true}
            />
          )}
        </>
      )}
    </Flex>
  )
}
