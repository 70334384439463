import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { DatePicker, Grid, CheckBox } from "@project/common"
import dayjs from "dayjs"

const Wrapper = styled.div`
  .division {
    margin-bottom: 16px !important;
  }
  .col__content {
    padding: 15px 16px !important;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }
`
const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const Divisor = styled.div`
  border: 1px solid #d2d1d1;
  margin-bottom: 32px;
  margin-top: 12px;
`
const AdditionalItemsScreen = ({ date, setDate, isChecked, setChecked }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Header>{t("Basic information")}</Header>
      <Divisor className={"division"} />
      <Grid
        labelContent={t("Date")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <div className={"content"}>
          <DatePicker
            placeholder={"--"}
            date={dayjs(date?.date)}
            onDateChange={(d) => {
              setDate({
                date: dayjs(d).format("YYYY-MM-DD"),
                to_date: date?.to_date,
              })
            }}
            format={"YYYY年MM月DD日"}
          />
          <CheckBox
            label={t("Enter by period")}
            value={isChecked}
            checked={isChecked}
            onChange={() => {
              setChecked(!isChecked)
            }}
          />
          {isChecked && (
            <DatePicker
              placeholder={"--"}
              date={dayjs(date?.to_date)}
              onDateChange={(d) => {
                setDate({
                  date: date?.date,
                  to_date: dayjs(d),
                })
              }}
              format={"YYYY年MM月DD日"}
            />
          )}
        </div>
      </Grid>
    </Wrapper>
  )
}

export { AdditionalItemsScreen }
