import { Flex, Skeleton, Spin } from "antd"
import { useContext, useMemo } from "react"
import { AssessmentSettingsAlert } from "./AssessmentSettingsAlert"

import { Card } from "@project/common"
import { AuthContext, UseAssessmentSettings } from "../../context"
import { AssessmentMasterPermission } from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"
import { AssessmentSettingsList } from "./AssessmentSettingsList"

export const AssessmentSettingsContainer = (): JSX.Element => {
  const { assessmentData, isLoading, isReFetching } = UseAssessmentSettings()
  const { isOwner, permissions } = useContext(AuthContext)
  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, AssessmentMasterPermission, "write"),
    [isOwner, permissions],
  )
  return (
    <Spin spinning={!isLoading && isReFetching}>
      <Flex vertical gap={16}>
        <AssessmentSettingsAlert />
        {isLoading && isReFetching ? (
          <Flex vertical gap={16}>
            {Array.from({ length: 4 }, (_, index) => (
              <Card title={<Skeleton.Button active />} key={index}>
                <Flex vertical gap={16}>
                  <Flex justify={"space-between"}>
                    <Skeleton.Input active />
                    <Flex gap={8}>
                      <Skeleton.Button active />
                      <Skeleton.Button active />
                      <Skeleton.Button active />
                    </Flex>
                  </Flex>
                  <Flex gap={8}>
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </Flex>
                  <Skeleton.Button active />
                  <Flex vertical gap={2}>
                    <Flex justify={"space-between"} gap={4}>
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                    </Flex>
                    <Flex justify={"space-between"} gap={4}>
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                    </Flex>
                    <Flex justify={"space-between"} gap={6}>
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                      <Skeleton.Input active block />
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>
        ) : (
          <>
            {assessmentData?.map((assessment, index) => (
              <AssessmentSettingsList
                key={index}
                assessment={assessment}
                hasWriteAccess={hasWriteAccess}
              />
            ))}
          </>
        )}
      </Flex>
    </Spin>
  )
}
