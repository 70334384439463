import {
  Button,
  Card,
  FACILITY_TYPES,
  IndicatorBox,
  Table,
  Tag,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT, PAGE_SIZE } from "../../constants"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import styled from "styled-components"
const StyledFlex = styled(Flex)`
  .no_certificate {
    background-color: #fce5e5 !important;
  }
`
export const AssessmentContentList = ({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  hasAccess,
}: {
  currentPage?: number
  setCurrentPage: (page: number) => void
  data: any
  isLoading?: boolean
  hasAccess: (val1, val2) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const columns: ColumnsType<any> = [
    {
      key: 1,
      title: t("Detail"),
      align: "center",
      width: 100,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Detail")}
            type={"link"}
            padding={"0"}
            style={{
              color: theme.colors.action,
              textDecoration: "underline",
            }}
            onClick={() => router.push(`/assessment/details/${row?.id}`)}
            hoverBorderColor={"none"}
          />
        </Flex>
      ),
    },
    {
      key: 2,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("User name")}
        </span>
      ),
      align: "center",
      render: (row) => row?.user_name || "",
    },
    {
      key: 3,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Facility name")}
        </span>
      ),
      align: "center",
      render: (row) => row?.facility_name || "",
    },
    {
      key: 4,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Use service")}
        </span>
      ),
      align: "center",
      width: 15,
      render: (row) => {
        const usedService = FACILITY_TYPES?.find(
          (v) => v?.value === row?.service_type,
        )
        return (
          usedService && (
            <Tag
              title={t(usedService?.label)}
              padding={"4px"}
              style={{
                width: usedService?.value === 3 ? "165px" : "",
                whiteSpace: "pre-wrap",
              }}
              color={
                usedService?.value === 1
                  ? "warning"
                  : usedService?.value === 2
                    ? "success"
                    : "processing"
              }
            />
          )
        )
      },
    },
    {
      key: 5,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Number of creation")}
        </span>
      ),
      align: "center",
      render: (row) => row?.creation_number || "",
    },
    {
      key: 6,
      title: t("Created date"),
      align: "center",
      render: (row) =>
        row?.created_datetime ? (
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {dayjs(row?.created_datetime).format(FULL_DATE_FORMAT)}
          </span>
        ) : (
          ""
        ),
    },
    {
      key: 7,
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Created status")}
        </span>
      ),
      align: "center",
      render: (row) => {
        return (
          <Tag
            title={row?.status ? t("Created1") : t("Draft")}
            color={row?.status ? theme.colors.info : theme.colors.border}
            padding={"4px"}
          />
        )
      },
    },
    {
      key: 8,
      title: t("Copy"),
      align: "center",
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Copy")}
            iconType={"copy-dark"}
            type={"default"}
            otherType={"draft"}
            shape={"round"}
            width={"100px"}
            onClick={() => router.push(`/assessment/add?copy_id=${row?.id}`)}
            disabled={!hasAccess(row?.facility_id, "write")}
          />
        </Flex>
      ),
    },
  ]
  return (
    <Card title={t("Assessment list")}>
      <StyledFlex vertical gap={16}>
        <IndicatorBox $bg={"#FCE5E5"}>
          <div className={"box"} />
          <Typography.Text className={"text"}>
            {t("Users who do not have registered recipient certificate")}
          </Typography.Text>
        </IndicatorBox>
        <Table
          columns={columns}
          dataSource={data?.data || []}
          rowClassName={(row: any) => {
            if (!row?.certificate_id) {
              return "no_certificate"
            }
          }}
          current={currentPage}
          pageSize={PAGE_SIZE}
          total={data?.count}
          showPaginationOf={"both"}
          loading={isLoading}
          scroll={{ x: 900 }}
          onChange={(page) => setCurrentPage(page)}
        />
      </StyledFlex>
    </Card>
  )
}
