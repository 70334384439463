import styled from "styled-components"
import { AlertBoxV2Theme } from "../../../types/nhif.types"
import { theme } from "@project/common"

export const AlertBoxV2Styled = styled.div<{ $theme: AlertBoxV2Theme }>`
  padding: 24px 30px;
  border: 1px solid ${theme.colors.error};
  color: ${(props) => theme.colors[props.$theme]};
  border-radius: 12px;

  .heading {
    display: flex;
    align-items: flex-start;
    padding: 0;
    color: ${(props) => theme.colors[props.$theme]};
    font-size: 18px;
    font-weight: 700;
    gap: 10px;
    line-height: 26px;
    .prefix-icon {
      margin-top: 6px;
      img {
        min-height: 20px;
        min-width: 20px;
      }
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-collapse-expand-icon {
    .down-arrow-container {
      display: grid;
      transform: rotate(0deg);
      transition: all 150ms ease-in;
      transform-origin: center;
    }
    .down-arrow-container.active {
      transform: rotate(180deg);
    }
  }

  .unpaid-user-listing-collapse {
    .ant-collapse-expand-icon .alert-image {
      .alert-icon,
      img {
        min-width: 20px !important;
        min-height: 20px !important;
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0;
      color: ${(props) => theme.colors[props.$theme]};
      .ant-collapse-header-text {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      padding: 0;
      color: ${(props) => theme.colors[props.$theme]};

      ul.listings li {
        font-size: 16px;
        font-weight: 500;
        margin-inline-start: 20px;
      }
    }
  }
`
