import { Tag, theme } from "@project/common"
import { Flex, Typography } from "antd"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const StyledWrapper = styled.div`
  padding: 5px 3px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .info__content {
    #txt {
      text-decoration: underline !important;
      color: ${theme.colors.action} !important;
      white-space: nowrap;
    }
    #box {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box--green {
      background-color: ${theme.colors.success};
      color: ${theme.colors.white};
    }
    .box--blue {
      background-color: ${theme.colors.action};
      color: ${theme.colors.white};
    }
    .box--grey {
      background-color: ${theme.colors.border};
      color: #000;
    }
  }
  @media print {
    row-gap: 8px;
    padding: 2px;
    .info__content {
      .pick_drop_info {
        margin-left: 0px !important;
      }

      & > * {
        font-size: 8pt !important;
      }
      .ant-tag {
        padding: 2px !important;
        font-size: 8pt !important;
      }
    }
    #flex-with-gap {
      gap: 2px !important;
    }
  }
`
export const CalendarContent = ({
  currentData,
  disableLink = false,
  link,
  showPickDropTime,
  staffLink,
}: {
  currentData: any
  disableLink?: boolean
  link: string
  showPickDropTime?: boolean
  staffLink: string
}): JSX.Element => {
  const { t } = useTranslation()
  const getServiceTypeInfo = (data: any, service_type?: number) => {
    const typeId = data?.service_type || service_type || 1
    const type =
      typeId === 1
        ? "A型"
        : typeId === 2
          ? "B型"
          : typeId === 3
            ? "移行"
            : "移行"
    const tagColor =
      typeId === 1 ? "success" : typeId === 2 ? "warning" : "processing"
    return { type, tagColor }
  }

  const contentWithPickDropTime = (data: any[]) => {
    return (
      <>
        <Flex wrap={"wrap"}>
          <Tag color={"gold"} title={t("Attendance2")} />
          <Typography.Text id={"txt"}>
            {data?.length || 0}
            {t("people")}
          </Typography.Text>
        </Flex>
        {data?.map((item, index) => {
          const { type, tagColor } = getServiceTypeInfo(
            item?.user?.user_certificate,
            item?.service_type,
          )
          return (
            <Flex key={index} vertical gap={4} id={"flex-with-gap"}>
              <Flex wrap={"wrap"}>
                <Tag color={tagColor} title={t(type)} />
                <Typography.Text>{item?.user?.username || ""}</Typography.Text>
              </Flex>
              {showPickDropTime && (
                <Flex
                  vertical
                  style={{ marginLeft: "30px" }}
                  gap={4}
                  id={"flex-with-gap"}
                  className={"pick_drop_info"}
                  wrap={"wrap"}
                >
                  <Flex
                    gap={4}
                    align={"center"}
                    id={"flex-with-gap"}
                    wrap={"wrap"}
                  >
                    <div
                      id={"box"}
                      className={
                        item?.goto_meet_flag ? "box--green" : "box--grey"
                      }
                    >
                      {t("迎")}
                    </div>
                    <Typography.Text>
                      {item?.goto_meet_time || ""}
                    </Typography.Text>
                  </Flex>
                  <Flex
                    gap={4}
                    align={"center"}
                    id={"flex-with-gap"}
                    wrap={"wrap"}
                  >
                    <div
                      id={"box"}
                      className={item?.take_flag ? "box--blue" : "box--grey"}
                    >
                      {t("送")}
                    </div>
                    <Typography.Text> {item?.take_time || ""}</Typography.Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          )
        })}
      </>
    )
  }
  const contentWithOutPickDropTime = (
    arrayData: any[],
    label: string,
    key?: string,
    hideServiceTag?: boolean,
  ) => {
    return (
      <>
        <Flex wrap={"wrap"}>
          <Tag color={"gold"} title={t(label)} whiteSpace={"pre-wrap"} />
          <Typography.Text id={"txt"}>
            {arrayData?.length || 0} {t("people")}
          </Typography.Text>
        </Flex>
        <Flex vertical gap={4} id={"flex-with-gap"}>
          {arrayData?.map((item, idx) => {
            const { type, tagColor } = getServiceTypeInfo(
              item?.user?.user_certificate,
              item?.service_type,
            )
            return (
              <React.Fragment key={idx}>
                <Flex>
                  {type && !hideServiceTag && (
                    <Tag
                      title={t(type)}
                      color={tagColor}
                      whiteSpace={"pre-line"}
                    />
                  )}
                  {key && key === "commuters" ? (
                    <Typography.Text>
                      {item?.staff?.staff_name || ""}
                    </Typography.Text>
                  ) : (
                    <Typography.Text>
                      {item?.user?.username || ""}
                    </Typography.Text>
                  )}
                </Flex>
              </React.Fragment>
            )
          })}
        </Flex>
      </>
    )
  }
  return (
    <StyledWrapper>
      {/* attendance */}
      <>
        {" "}
        {!disableLink ? (
          <Link href={link}>
            <Flex
              component={"a"}
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithPickDropTime(currentData?.attendance || [])}
            </Flex>
          </Link>
        ) : (
          <Flex
            className={"info__content"}
            vertical
            gap={4}
            id={"flex-with-gap"}
          >
            {contentWithPickDropTime(currentData?.attendance || [])}
          </Flex>
        )}
      </>

      {/* absence */}
      {currentData?.absence?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.absence || [],
                  "absence",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.absence || [],
                "absence",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* absence_no_addition */}
      {currentData?.absence_no_addition?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.absence_no_addition || [],
                  "absentNoAddition",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.absence_no_addition || [],
                "absentNoAddition",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* Experience */}
      {currentData?.experience?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.experience || [],
                  "Experience",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.experience || [],
                "Experience",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* Unregistered */}
      {currentData?.unregistered?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.unregistered || [],
                  "Unregistered",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.unregistered || [],
                "Unregistered",
              )}
            </Flex>
          )}
        </>
      ) : (
        ""
      )}
      {/* offsite_support */}
      {currentData?.offsite_support?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.offsite_support || [],
                  "Off-site support",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.offsite_support || [],
                "Off-site support",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* offsite_work */}
      {currentData?.offsite_work?.length ? (
        <>
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.offsite_work || [],
                  "Off-site work",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.offsite_work || [],
                "Off-site work",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* trial_use_support */}
      {currentData?.trial_use_support?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.trial_use_support || [],
                  "Trial use support",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.trial_use_support || [],
                "Trial use support",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* visit_special_support */}
      {currentData?.visit_special_support?.length ? (
        <>
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.visit_special_support || [],
                  "Visit special support",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.visit_special_support || [],
                "Visit special support",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* transition preparation support */}
      {currentData?.transition_preparation?.length ? (
        <>
          {!disableLink ? (
            <Link href={link}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.transition_preparation || [],
                  "Transition preparation support",
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.transition_preparation || [],
                "Transition preparation support",
              )}
            </Flex>
          )}
        </>
      ) : null}
      {/* commuters */}
      {currentData?.commuters?.length ? (
        <>
          {" "}
          {!disableLink ? (
            <Link href={staffLink}>
              <Flex
                component={"a"}
                className={"info__content"}
                vertical
                gap={4}
                id={"flex-with-gap"}
              >
                {contentWithOutPickDropTime(
                  currentData?.commuters || [],
                  "出勤スタッフ",
                  "commuters",
                  true,
                )}
              </Flex>
            </Link>
          ) : (
            <Flex
              className={"info__content"}
              vertical
              gap={4}
              id={"flex-with-gap"}
            >
              {contentWithOutPickDropTime(
                currentData?.commuters || [],
                "出勤スタッフ",
                "commuters",
                true,
              )}
            </Flex>
          )}
        </>
      ) : null}
    </StyledWrapper>
  )
}
