import { getUserAttendanceTypesByService } from "@project/common"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { getAllStaff } from "../../../../services"
import DynamicTable from "./DynamicTable"

const SecondTable = ({ data }) => {
  const { t } = useTranslation()
  const { data: allStaffData } = useQuery<any>(
    ["get-all-staff-view"],
    () => getAllStaff(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.staff_name, value: d?.id, furigana: d?.furigana }
        }),
    },
  )
  const staffName = allStaffData?.find((d) => d.value == data?.staff_id)?.label
  const attendance_status = t(
    getUserAttendanceTypesByService(data?.service_type)?.find(
      (d) => d?.value == data?.attendance_type,
    )?.label || "",
  )

  return (
    <>
      <td className={"first-td"}>
        <div className={"first-td-content"}>
          <h1>{data?.username}</h1>
          {attendance_status && <p>{t(attendance_status)}</p>}
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {data?.start_time && data?.end_time ? (
              <>
                {data?.start_time}
                <span>{"~"}</span>
                {data?.end_time}
              </>
            ) : null}
          </div>
        </div>
      </td>
      <td className={"second-td"}>
        <DynamicTable data={data} allStaffData={allStaffData} />
      </td>
      <td className={"third-td"}>
        <div className={"third-td-content"}>
          <p>{staffName ? staffName : "-"}</p>
        </div>
      </td>
    </>
  )
}

export { SecondTable }
