// packages
import { Flex } from "antd"
import { t } from "i18next"

// components/commons
import {
  EmploymentTag,
  SystemStatusCellWrapper,
} from "../TreatmentImproveTable.styles"
import { FACILITY_SERVICE_TYPES } from "@project/common"

// types
import { SupportTagProps } from "../../../types/TreatmentImproveTable.types"

// constants
import { NURSING_STAFF_RECORD } from "../../../constants"

export const convertToTwoDigitFormatWithSeperator = (digit = 0) =>
  digit?.toLocaleString(undefined, { maximumFractionDigits: 2 })

export const SystemStatusCell: React.FC<SupportTagProps> = ({
  type,
  nursingStaffType,
  isRegistered,
}) => {
  return (
    <SystemStatusCellWrapper>
      {!isRegistered ? (
        <div>{t("Not registered")}</div>
      ) : (
        <div className={"status-cell-content"}>
          <EmploymentTag $employmentStatus={type}>
            {t(FACILITY_SERVICE_TYPES[type])}
          </EmploymentTag>
          <Flex className={"treatment-nursing-staff-label"}>
            {t("Treatment")}
            {": "}
            {t(NURSING_STAFF_RECORD[`${nursingStaffType}`])}
          </Flex>
        </div>
      )}
    </SystemStatusCellWrapper>
  )
}

export const CoreCost = ({
  cost,
  noHighlight,
}: {
  cost: string | number
  noHighlight?: boolean
}) => (
  <div className={"currency-cell-content"}>
    {noHighlight ? (
      convertToTwoDigitFormatWithSeperator(+cost || 0)
    ) : (
      <span className={"red-cost"}>
        {convertToTwoDigitFormatWithSeperator(+cost || 0)}
      </span>
    )}
    <span className={"currency-symbol"}>{"円"}</span>
  </div>
)
