import {
  Button,
  Card,
  LinkButton,
  PageTitle,
  Table,
  TableDeleteAction,
  scrollToSelectedElement,
  useNotification,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { PAGE_SIZE } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import {
  deleteWorkDetailsByID,
  fetchWorkDetailsMaster,
} from "../../services/workDetailsMaster"
import { WorkDetailColumn } from "../../types"
import { WorkDetailsMasterPermission } from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

const StyleWorkDetailsMasterWrapper = styled.div`
  margin-top: 16px;
  table thead tr th {
    white-space: nowrap;
  }
`

const WorkDetailsMaster = (): JSX.Element => {
  const { t } = useTranslation()
  const { permissions, isOwner, queryClient } = useContext(AuthContext)
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState(+router?.query?.page || 1)
  const listRef = useRef<HTMLDivElement>(null)
  const { showToast } = useNotification()
  const {
    isLoading,
    isFetching,
    data: workDetailsMasterData,
  } = useQuery(["fetchWorkDetailsMaster", currentPage, PAGE_SIZE], {
    queryFn: fetchWorkDetailsMaster,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })
  const { mutate, isLoading: isDeleting } = useMutation(deleteWorkDetailsByID, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fetchWorkDetailsMaster"],
      })
      showToast({
        type: "success",
        message: t("Work details master") + " " + t("Deleted Successfully"),
      })
    },
    onError: (error?: any) => {
      const msg =
        error?.data?.error?.message || t("Failed to Delete Work Details")
      showToast({ type: "error", message: msg })
    },
  })
  const hasWriteAccess =
    isOwner ||
    useMemo(
      () =>
        hasPermissionForMenu(permissions, WorkDetailsMasterPermission, "write"),
      [permissions],
    )

  const columns: ColumnsType<WorkDetailColumn[]> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 100,
      render: (_, data: WorkDetailColumn) => {
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() => {
              router.push(`/work-details-master/edit/${data?.id}`)
            }}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Work Category"),
      dataIndex: "work_category_name",
      key: "work_category_name",
      align: "center",
      render: (_, data: WorkDetailColumn) => {
        return <span>{t(data?.WorkCategory?.work_category_name)}</span>
      },
    },
    {
      title: t("Work Item"),
      align: "center",
      dataIndex: "work_name",
      key: "work_name",
    },
    {
      title: t("Delete"),
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 100,
      render: (_, data: WorkDetailColumn) => (
        <TableDeleteAction
          id={data?.id}
          onConfirm={() => mutate(data?.id)}
          title={t("Are you sure you want to Delete Work Details?")}
          subTitle={"Delete"}
          isLoading={isDeleting}
          okText={t("Yes1")}
          cancelText={t("Cancel")}
          disabled={!hasWriteAccess}
        />
      ),
    },
  ]

  return (
    <StyleWorkDetailsMasterWrapper ref={listRef}>
      <PageTitle
        title={t("Work Details Master")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        extra={
          <Button
            iconType={"plus-circle"}
            btnText={t("Add Work Details")}
            type={"primary"}
            shape={"round"}
            size={"large"}
            onClick={() => router.push("/work-details-master/add")}
            disabled={!hasWriteAccess}
          />
        }
      />
      <Card title={"作業詳細一覧"}>
        <Table
          columns={columns}
          current={currentPage}
          pageSize={PAGE_SIZE}
          className={"work-detail-table"}
          dataSource={workDetailsMasterData?.data}
          total={workDetailsMasterData?.count || 0}
          showPaginationOf={"both"}
          pagination={false}
          scroll={{ x: 900 }}
          onChange={(val) => {
            setCurrentPage(val)
            scrollToSelectedElement(listRef)
            router.push(
              {
                pathname: "/work-details-master",
                query: {
                  page: val,
                },
              },
              undefined,
              {
                scroll: false,
              },
            )
          }}
          loading={isLoading || isFetching}
          cellPaddingBlock={12}
        />
      </Card>
    </StyleWorkDetailsMasterWrapper>
  )
}

export { WorkDetailsMaster }
