import { theme } from "@project/common"
import styled from "styled-components"

export const UserFeesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .ant-collapse-content-box .ant-form .ant-form-item {
    margin-bottom: 0;
  }
  .table-title {
    margin-block: 16px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.text};
  }
  .check-all-btn {
    background-color: white;
  }
`
