import * as Yup from "yup"
import { FormikProps } from "formik"
import {
  PartialNullish,
  UserDailyTransport,
  PickupOrDropOffStaff,
} from "../services/user-transport-mgmt.services"
import { UseMutateAsyncFunction } from "react-query"
import { ErrorResponse } from "./variousAdditionsManagementOfMeetingMinutes.types"

export type TransportMgmtDriverFilterType = {
  facility_ids: Array<number> | null
  service_type: Array<number> | null
  list_display: Array<number> | null
  date: string
  driver_staff_id: number | null
}

export type TMDriverListOperationProps = {
  filterInitValues: TransportMgmtDriverFilterType
  handleScrollToList: () => void
}

export type TMDriverListTableProps = {
  date: string
  respData?: {
    data: {
      drop_staffs: PartialNullish<GenericDriverStaff>[]
      pickup_staffs: PartialNullish<GenericDriverStaff>[]
    }
  }
  loadingDailyTransportData: boolean
  asyncMutateTransportRec: UseMutateAsyncFunction<
    unknown,
    ErrorResponse,
    PartialNullish<UserDailyTransport>
  >
  mutatingUserTransportRec: boolean
}

export type TableType = "drop_staffs" | "pickup_staffs"
export type TimeVariant = "start_time" | "end_time"

export type TMDriverGenericTableProps = {
  dataSource: PartialNullish<GenericDriverStaff>[]
  bulkEdit?: boolean
  type: TableType
  tableFormik: FormikProps<TMDriverListTableProps["respData"]>
}

export type GenericDriverStaff = Pick<
  PickupOrDropOffStaff,
  | "driver_staff"
  | "facility_id"
  | "facility"
  | "alcohol_check"
  | "power"
  | "damage_to_device"
  | "visual_check"
  | "start_time"
  | "end_time"
  | "escort_staff"
  | "car"
  | "route"
  | "id"
  | "driver_staff_id"
  | "escort_staff_id"
  | "car_id"
> & { key: string | number }

export const TMDriverListTableValidation = Yup.object().shape({
  data: Yup.object().shape({
    drop_staffs: Yup.array(
      Yup.object({
        start_time: Yup.string().required("Required"),
        end_time: Yup.string().required("Required"),
      }),
    ),
    pickup_staffs: Yup.array(
      Yup.object({
        start_time: Yup.string().required("Required"),
        end_time: Yup.string().required("Required"),
      }),
    ),
  }),
})
