import {
  Button,
  InputAreaField,
  InputField,
  Table,
  theme,
} from "@project/common"
import { Popconfirm, Space } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const StyledContainer = styled.div`
  width: 100%;
  #actual__filed__container {
    width: 100%;
    .actual__field {
      width: 100%;
      padding: 8px 16px;
    }
    .actual__field-bb {
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
`

interface IProps {
  dataSource: any[]
  setDataSource: (values: any) => void
  planType?: "draft" | "non-draft"
}

export const PlanGoalContent: React.FC<IProps> = ({
  dataSource,
  setDataSource,
  planType,
}) => {
  const { t } = useTranslation()
  const handleItemsChange = (id, field, text) => {
    const _data = [...dataSource]
    _data.forEach((each, index) => {
      if (each?.id === id) {
        _data[index][field] = text
      }
    })
    setDataSource(_data)
  }
  const handleSpecificNestItemChange = (rowId, goalId, value, key) => {
    const _data = [...dataSource]
    const _matchedRowIndex = _data.findIndex((each) => each.id === rowId)
    _data[_matchedRowIndex]?.[key]?.forEach((each, index) => {
      if (each?.id === goalId) {
        _data[_matchedRowIndex][key][index].title = value
      }
    })
    setDataSource(_data)
  }

  const handleDataSourceAdd = () => {
    setDataSource([
      ...dataSource,
      {
        id: new Date().getTime(),
        title: "",
        achievement_goals: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        support_and_considerations: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
        achievement_time: [
          {
            id: new Date().getTime(),
            title: "",
          },
        ],
      },
    ])
  }
  const handleDataSourceDelete = (id: number) => {
    setDataSource(dataSource?.filter((val) => val?.id !== id))
  }
  const handleAddSpecificGoalToDataSource = (id: number) => {
    const data = dataSource?.map((val) =>
      val?.id === id
        ? {
            ...val,
            achievement_goals: [
              ...val?.achievement_goals,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            support_and_considerations: [
              ...val?.support_and_considerations,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
            achievement_time: [
              ...val?.achievement_time,
              {
                id: new Date().getTime(),
                title: "",
              },
            ],
          }
        : val,
    )

    setDataSource(data)
  }
  const handleDeleteSpecificGoalFromDataSource = (
    id: number,
    goalId: number,
  ) => {
    const data = dataSource?.map((val) =>
      val?.id === id
        ? {
            ...val,
            achievement_goals: val?.achievement_goals?.filter(
              (v) => v?.id !== goalId,
            ),
            support_and_considerations: val?.support_and_considerations?.filter(
              (v) => v?.id !== goalId,
            ),
            achievement_time: val?.achievement_time?.filter(
              (v) => v?.id !== goalId,
            ),
          }
        : val,
    )
    setDataSource(data)
  }

  const columns: ColumnsType<any> = [
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Items (personal needs, etc.)")}
        </span>
      ),
      key: "1",
      render: (row, __, index) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
            },
          },
          children: (
            <Space direction={"vertical"}>
              <InputField
                placeholder={t("例：生活リズム")}
                name={"title"}
                value={row?.title}
                maxLength={100}
                onChange={(e) => {
                  if (e?.target?.value?.length > 100) return
                  handleItemsChange(row?.id, "title", e?.target?.value)
                }}
              />
              {dataSource?.length === 1 ? (
                <Button
                  btnText={t("add")}
                  onClick={handleDataSourceAdd}
                  shape={"round"}
                  borderColor={theme.colors.border}
                />
              ) : index !== dataSource?.length - 1 ? (
                <Popconfirm
                  title={t(
                    "Are you sure you want to delete the entered items, specific goals, support details, and notes?",
                  )}
                  onConfirm={() => handleDataSourceDelete(row?.id)}
                >
                  <Button
                    btnText={t("delete")}
                    shape={"round"}
                    borderColor={theme.colors.border}
                  />
                </Popconfirm>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Button
                    btnText={t("add")}
                    shape={"round"}
                    borderColor={theme.colors.border}
                    onClick={handleDataSourceAdd}
                  />
                  <Popconfirm
                    onConfirm={() => handleDataSourceDelete(row?.id)}
                    title={t(
                      "Are you sure you want to delete the entered items, specific goals, support details, and notes?",
                    )}
                  >
                    <Button
                      btnText={t("delete")}
                      shape={"round"}
                      borderColor={theme.colors.border}
                    />
                  </Popconfirm>
                </div>
              )}
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Specific goals")}
        </span>
      ),
      key: "2",
      render: (row, entireRow) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
              minWidth: "250px",
              padding: "0px",
            },
          },
          children: (
            <Space direction={"vertical"} id={"actual__filed__container"}>
              {row?.achievement_goals?.map((val, index) => (
                <Space
                  key={val?.id}
                  direction={"vertical"}
                  className={`actual__field ${
                    index !== row?.achievement_goals?.length - 1
                      ? "actual__field-bb"
                      : ""
                  }`}
                >
                  <InputAreaField
                    placeholder={t("例：決まった時間に起きる")}
                    rows={2}
                    value={val.title}
                    name={"achievement_goals"}
                    onChange={(e) => {
                      handleSpecificNestItemChange(
                        entireRow.id,
                        val.id,
                        e.target.value,
                        "achievement_goals",
                      )
                    }}
                  />
                  {row?.achievement_goals?.length === 1 ? (
                    <Button
                      btnText={t("add")}
                      onClick={() => handleAddSpecificGoalToDataSource(row?.id)}
                      shape={"round"}
                      borderColor={theme.colors.border}
                    />
                  ) : index !== row?.achievement_goals?.length - 1 ? (
                    <Popconfirm
                      title={t(
                        "Is this correct that the specific goals, support details, and notes will be deleted?",
                      )}
                      onConfirm={() =>
                        handleDeleteSpecificGoalFromDataSource(row?.id, val?.id)
                      }
                    >
                      <Button
                        btnText={t("Delete")}
                        shape={"round"}
                        borderColor={theme.colors.border}
                      />
                    </Popconfirm>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <Button
                        btnText={t("add")}
                        icon={"add"}
                        onClick={() =>
                          handleAddSpecificGoalToDataSource(row?.id)
                        }
                        shape={"round"}
                        borderColor={theme.colors.border}
                      />
                      <Popconfirm
                        title={t(
                          "Is this correct that the specific goals, support details, and notes will be deleted?",
                        )}
                        onConfirm={() =>
                          handleDeleteSpecificGoalFromDataSource(
                            row?.id,
                            val?.id,
                          )
                        }
                      >
                        <Button
                          btnText={t("delete")}
                          shape={"round"}
                          borderColor={theme.colors.border}
                        />
                      </Popconfirm>
                    </div>
                  )}
                </Space>
              ))}
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Contents of support/Points to consider")}
        </span>
      ),
      key: "3",
      width: 350,
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
              padding: 0,
            },
          },
          children: (
            <Space
              direction={"vertical"}
              style={{ display: "flex" }}
              id={"actual__filed__container"}
            >
              {row?.support_and_considerations?.map((val, index) => (
                <Space
                  key={val?.id}
                  direction={"vertical"}
                  className={`actual__field ${
                    index !== row?.achievement_goals?.length - 1
                      ? "actual__field-bb"
                      : ""
                  }`}
                >
                  <InputAreaField
                    placeholder={t(
                      "例：毎朝7時30分には起床できるように、夜23時までには就寝するようにお声がけを行います。",
                    )}
                    value={val?.title}
                    onChange={(e) => {
                      handleSpecificNestItemChange(
                        row.id,
                        val.id,
                        e.target.value,
                        "support_and_considerations",
                      )
                    }}
                    rows={4}
                    name={"support_and_considerations"}
                    resize={false}
                  />
                </Space>
              ))}
            </Space>
          ),
        }
      },
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Achievement time")}
        </span>
      ),
      key: "4",
      width: 250,
      render: (_, row) => {
        return {
          props: {
            style: {
              verticalAlign: "top",
              padding: 0,
            },
          },
          children: (
            <Space
              direction={"vertical"}
              style={{ display: "flex" }}
              id={"actual__filed__container"}
            >
              {row?.achievement_time &&
                row?.achievement_time?.map((val, index) => (
                  <Space
                    key={val?.id}
                    direction={"vertical"}
                    style={{ display: "flex", paddingBottom: "64px" }}
                    className={`actual__field ${
                      index !== row?.achievement_goals?.length - 1
                        ? "actual__field-bb"
                        : ""
                    }`}
                  >
                    <InputField
                      placeholder={t("例：6ヶ月")}
                      name={"achievement_time"}
                      maxLength={10}
                      height={"42px"}
                      value={val?.title}
                      onChange={(e) => {
                        if (e?.target?.value?.length > 100) return
                        handleSpecificNestItemChange(
                          row.id,
                          val.id,
                          e.target.value,
                          "achievement_time",
                        )
                      }}
                    />
                  </Space>
                ))}
            </Space>
          ),
        }
      },
    },
  ]

  return (
    <StyledContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        headerBg={
          planType === "draft" ? theme.colors["t-head"] : theme.colors.disabled
        }
        scroll={{ x: 900 }}
        className={"always-present-scrollbar"}
      />
    </StyledContainer>
  )
}
