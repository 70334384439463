import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, SelectInput } from "@project/common"
import { Option } from "@project/common/src/types"
import { useAuthContext } from "../../../context"

export const TabTopInfoContent = ({
  facilities,
  onChange,
  facility,
}: {
  facilities: Option[]
  onChange: (value: string) => void
  facility: string | null | number
}): JSX.Element => {
  const { t } = useTranslation()
  const { companyName } = useAuthContext()
  return (
    <div>
      <Grid labelContent={t("Corporate name")} background padding={"10px"}>
        {companyName || ""}
      </Grid>
      <Grid labelContent={t("Facility name")} background padding={"10px"}>
        <SelectInput
          name={"facility"}
          options={facilities || []}
          placeholder={"--"}
          value={facility || null}
          onChange={(val) => onChange(val)}
        />
      </Grid>
    </div>
  )
}
