import React, { useMemo } from "react"
import { useRouter } from "next/router"

// packages
import cuid from "cuid"
import dayjs from "dayjs"
import { t } from "i18next"
import { Spin } from "antd"
import { FormikProps } from "formik"

// commons | components
import {
  Box,
  Card,
  omit,
  Button,
  ActionBtns,
  filterObject,
  useUpdateSearchParams,
} from "@project/common"
import { BulkEditGenericUserTable } from "./common/BulkEditGenericUserTable"
import { BulkEditGenericDriverTable } from "./common/BulkEditGenericDriverTable"

// style
import { TitleWithBorder } from "./TransportMgmtBulkEditInternal.styles"

// contexts
import { useTMBulkEdit } from "../../context/TMBulkEditFormContext"

// utils
import { generateNewTMBulkEditDriverStaff } from "./utils/generateNewTMBulkEditDriverStaff"

// types
import {
  UserTableType,
  DriverStaffTableType,
  DailyTransportDataType,
} from "./types"
import { resetUser } from "./utils/resetUser"

export const TMBulkTables = ({
  formik,
}: {
  formik: FormikProps<Partial<DailyTransportDataType>>
}) => {
  const { query } = useRouter()

  const [updateParams] = useUpdateSearchParams()
  const {
    loadingForm,
    allDriverStaffs,
    storeDeletedItems,
    initialTransportData,
    mutatingUserTransportRec,
  } = useTMBulkEdit()

  const {
    /** `pndValues` as in [pickup and dropOff Values] */
    values: pndValues,
    setFieldValue,
    handleSubmit,
  } = formik

  // 1. This function will change input values of user table-cell [of both type i.e. "pickup_users" | "drop_users"] based on `type arg`
  const handleUserCellInputChange = (
    type: UserTableType,
    index: number,
    values: any,
    key: string,
  ) => {
    setFieldValue(`data.${[type]}.${[index]}.${key}`, values)
  }

  // 2. onUserCellReset resets the value of particular row of user table [both type is handled by this function i.e. "pickup_users" | "drop_users"] based on `type arg`
  const onUserCellReset = (type: UserTableType, index: number) => {
    const clonedUserData = { ...pndValues.data[type][index] }
    const retainData = resetUser(clonedUserData)
    setFieldValue(`data.${[type]}.${[index]}`, retainData)
  }

  // 3. This function will change input values of staff table-cells [of both type i.e. "pickup_staffs" | "drop_staffs"] based on `type arg`
  const handleStaffCellInputChange = (
    type: DriverStaffTableType,
    index: number,
    values: any,
    key: string,
  ) => {
    setFieldValue(`data.${[type]}.${[index]}.${key}`, values)
  }

  // 4. This function removes particular row from staff table based off of index provided as index.
  const onStaffCellRemove = (type: DriverStaffTableType, index: number) => {
    const deletedRow = { ...pndValues?.data[type][index], is_deleted: true }
    storeDeletedItems(type)(deletedRow)

    const clonedDriverStaffData = [...pndValues?.data[type]]

    clonedDriverStaffData.splice(index, 1)
    setFieldValue(`data.${[type]}`, clonedDriverStaffData)
  }

  // 5. This function adds driver on demand.[handles both pickup & dropOff driver staff table]
  const handlePickupDriverAddition = (type: DriverStaffTableType) => {
    const uniqueId = cuid()
    setFieldValue(`data.${type}`, [
      ...pndValues.data[type],
      generateNewTMBulkEditDriverStaff(uniqueId),
    ])
  }

  // 1. memoized Pickup transportTables
  const pickupTransportTables = useMemo(() => {
    return (
      <Box direction={"column"} display={"flex"} gap={16} mb={28}>
        <Box display={"flex"} direction={"column"} gap={24}>
          <BulkEditGenericUserTable
            type={"pickup_users"}
            userDataSource={pndValues?.data?.pickup_users}
            handleCellInputChange={handleUserCellInputChange}
            onUserCellReset={onUserCellReset}
          />
          <BulkEditGenericDriverTable
            type={"pickup_staffs"}
            onStaffCellRemove={onStaffCellRemove}
            handleCellInputChange={handleStaffCellInputChange}
            driverStaffDataSource={pndValues?.data?.pickup_staffs}
          />
        </Box>
        <div>
          <Button
            type={"default"}
            btnText={t("Add Driver")}
            shape={"round"}
            disabled={
              pndValues?.data?.pickup_staffs?.length >= allDriverStaffs?.length
            }
            onClick={() => {
              handlePickupDriverAddition("pickup_staffs")
            }}
          />
        </div>
      </Box>
    )
  }, [pndValues?.data?.pickup_users, pndValues?.data?.pickup_staffs])

  // 2. memoized dropOff transport tables
  const dropOffTransportTables = useMemo(() => {
    return (
      <Box direction={"column"} display={"flex"} gap={16} mb={28}>
        <TitleWithBorder>
          {t("{{engDate}}’s user transportation management（Drop off）", {
            engDate: dayjs(query.date as string)
              .locale("en")
              .format("YYYY年MM月DD日 （ddd）"),
            jaDate: dayjs(query.date as string)
              .locale("ja")
              .format("YYYY年MM月DD日 （dd）"),
          })}
        </TitleWithBorder>
        <Box display={"flex"} direction={"column"} gap={24}>
          <BulkEditGenericUserTable
            type={"drop_users"}
            userDataSource={pndValues?.data?.drop_users}
            handleCellInputChange={handleUserCellInputChange}
            onUserCellReset={onUserCellReset}
          />
          <BulkEditGenericDriverTable
            type={"drop_staffs"}
            onStaffCellRemove={onStaffCellRemove}
            handleCellInputChange={handleStaffCellInputChange}
            driverStaffDataSource={pndValues?.data?.drop_staffs}
          />
        </Box>
        <div>
          <Button
            type={"default"}
            btnText={t("Add Driver")}
            shape={"round"}
            onClick={() => handlePickupDriverAddition("drop_staffs")}
            disabled={
              pndValues?.data?.drop_staffs?.length >= allDriverStaffs?.length
            }
          />
        </div>
      </Box>
    )
  }, [pndValues?.data?.drop_staffs, pndValues?.data?.drop_users])

  return (
    <Card
      title={t("{{engDate}}’s user transportation management（Pick up）", {
        engDate: dayjs(query.date as string)
          .locale("en")
          .format("YYYY年MM月DD日 （ddd）"),
        jaDate: dayjs(query.date as string)
          .locale("ja")
          .format("YYYY年MM月DD日 （dd）"),
      })}
    >
      <Spin spinning={loadingForm || mutatingUserTransportRec}>
        {/* memoized Pickup transportTables */}
        {pickupTransportTables}
        {/* memoized dropOff transport tables */}
        {dropOffTransportTables}

        <ActionBtns className={"operation-action-btns"} justify={"flex-start"}>
          <Button
            shape={"round"}
            type={"default"}
            btnText={t("Cancel")}
            disabled={mutatingUserTransportRec}
            onClick={() =>
              updateParams(
                omit(
                  filterObject({
                    ...query,
                    use_service: query?.service_type,
                  }),
                  ["service_type"],
                ),
                `/user-transport-management/${query.date}`,
              )
            }
          />
          <Button
            shape={"round"}
            type={"primary"}
            btnText={t("Save")}
            isLoading={mutatingUserTransportRec}
            disabled={initialTransportData === JSON.stringify(pndValues)}
            onClick={() => handleSubmit()}
          />
        </ActionBtns>
      </Spin>
    </Card>
  )
}
