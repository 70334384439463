import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { InputField, SelectInput } from "@project/common"
import { FuriganaAlphabetsOption } from "../../../../constants"
import Link from "next/link"

import { filterWithHiragana } from "../../../../utils"

const AttendanceInformationWrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .attendance-information {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      border-radius: 10px !important;
      border: 1px solid #dddddd;
    }
    .label {
      padding: 12px;
      background-color: rgb(243, 243, 243);
      width: 25%;
      text-transform: capitalize;
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border: none !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom: 1px solid #dddddd !important;
      }
    }
    .value {
      padding: 12px;
      @media screen and (max-width: 540px) {
        border: none !important;
      }
    }
    .link {
      color: #0782c8;
    }
    .staff_content {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
  .attendance-information:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
`
interface IProps {
  formattedDate: string
  formik: any
  mode: "ADD" | "EDIT"
  staffData: any[]
}
const AttendanceInformation: React.FC<IProps> = ({
  formattedDate,
  formik,
  mode,
  staffData,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <AttendanceInformationWrapper>
        <table>
          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Date")}</td>
            <td className={"value"}>
              <p>{formattedDate || "-"}</p>
            </td>
          </tr>
          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Staff name")}</td>
            <td className={"value link"}>
              {mode === "EDIT" ? (
                <Link href={"/"}>{formik.values.staff_name || "-"}</Link>
              ) : (
                <>
                  <div className={"staff_content"}>
                    <SelectInput
                      width={"86px"}
                      name={"user-name"}
                      options={FuriganaAlphabetsOption}
                      placeholder={"---"}
                      value={formik.values.furigana || null}
                      onChange={(val) => {
                        formik.setFieldValue("staff_id", null)
                        formik.setFieldValue("furigana", val)
                      }}
                    />
                    <SelectInput
                      name={"user-name"}
                      size={"large"}
                      placeholder={"--"}
                      width={"182px"}
                      options={filterWithHiragana({
                        options: staffData || [],
                        furigana: formik.values.furigana || "",
                      })}
                      value={+formik.values.staff_id || null}
                      onChange={(val) => formik.setFieldValue("staff_id", val)}
                    />
                  </div>
                </>
              )}
            </td>
          </tr>
          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Remarks")}</td>
            <td className={"value"}>
              {mode === "EDIT" ? (
                <p>{formik.values.remarks}</p>
              ) : (
                <InputField
                  name={"hr"}
                  id={"hr"}
                  width={"354px"}
                  placeholder={""}
                  padding={"8px"}
                  borderRadius={"4px"}
                  value={formik.values.remarks || null}
                  onChange={(e) =>
                    formik.setFieldValue("remarks", e.target.value)
                  }
                />
              )}
            </td>
          </tr>
        </table>
      </AttendanceInformationWrapper>
    </>
  )
}

export { AttendanceInformation }
