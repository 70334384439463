import {
  AccordionCard,
  Button,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import { Flex } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs from "dayjs"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../context"
import { filterWithHiragana } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useFetchAllUsers } from "../../../hooks/useFetchData"

interface WageManagementOperationProps {
  params?: any
  setParams?: any
  showDate?: boolean
  hideFurigana?: boolean
  onSearch: (values: any) => void
  resetHandler: any
  defaultParams?: any
  setDefaultParams?: any
  setFacility?: any
  facility?: any
  setUserId?: any
  user_id?: any
  setStartAt?: any
  startAt?: any
  setDate?: any
  date?: number
}
export const WageManagementOperation = ({
  setDate,
  date,
  showDate,
  hideFurigana = false,
  onSearch,
  resetHandler,
  params,
}: WageManagementOperationProps): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [values, setValues] = useState({
    startAt: "",
    facilityIds: [],
    user_id: null,
  })

  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  useEffect(() => {
    setValues({
      ...values,
      ...params,
    })
  }, [params])

  const facilityWithNoContract = useMemo(
    () => [
      ...facilities,
      {
        label: t("No contract facility"),
        value: "0",
      },
    ],
    [facilities],
  )
  return (
    <AccordionCard
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow
                  label={t("Contract facility")}
                  width={"138px"}
                />
                <Flex vertical gap={4}>
                  <FacilityCheckbox
                    options={facilityWithNoContract || []}
                    value={values?.facilityIds}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilityWithNoContract?.map((v) => v?.value)
                        setValues({
                          ...values,
                          facilityIds: ids,
                        })
                      }
                      if (type === "uncheck") {
                        setValues({
                          ...values,
                          facilityIds: [],
                        })
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setValues({
                        ...values,
                        facilityIds: list,
                      })
                    }}
                  />
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User Name ")}
                  width={"138px"}
                />
                {!hideFurigana && (
                  <SelectInput
                    name={"name_hiragana"}
                    shortSelector
                    width={"78px"}
                    placeholder={"--"}
                    options={FuriganaAlphabetsOption}
                    value={values?.startAt || null}
                    onChange={(v) => {
                      setValues({
                        ...values,
                        startAt: v,
                        user_id: null,
                      })
                    }}
                  />
                )}

                <SelectInput
                  name={"user"}
                  placeholder={"--"}
                  allowClear={true}
                  options={
                    filterWithHiragana({
                      options: userData || [],
                      furigana: values?.startAt,
                    }) || []
                  }
                  value={+values?.user_id > 0 ? String(values?.user_id) : null}
                  onChange={(v) => {
                    setValues({
                      ...values,
                      user_id: v,
                    })
                  }}
                />
              </div>
              {showDate && (
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow label={t("Year")} width={"138px"} />
                  <DatePicker
                    picker={"year"}
                    date={dayjs(date).isValid() ? dayjs(date) : null}
                    onDateChange={(date) => {
                      const val = dayjs(date).format("YYYY")
                      setDate(dayjs(val).format("YYYY"))
                    }}
                    format={"YYYY"}
                    style={{
                      width: "86px",
                    }}
                  />
                  <span style={{ marginLeft: "-5px" }}>{"年"}</span>
                </div>
              )}

              <div className={"search__content"}>
                <Button
                  btnText={t("Search")}
                  iconType={"search"}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => onSearch(values)}
                />
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    setValues({
                      ...values,
                      startAt: "",
                      facilityIds: [],
                      user_id: null,
                    })
                    resetHandler()
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
