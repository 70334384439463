import { Flex, Skeleton, Typography, notification } from "antd"
import React, { useRef } from "react"
import { ApplicationManagementLegend } from "../ApplicationManagementCommon/ApplicationManagementLegend"

import { ApplicationManagementListTable } from "../ApplicationManagementCommon/ApplicationManagementListTable/ApplicationManagementListTable"
import {
  Breadcrumb,
  Button,
  Card,
  FACILITY_TYPES,
  MonthPagination,
  PageTitle,
  Tabs,
  dynamicLangString,
  scrollToSelectedElement,
  theme,
  useResponsiveMediaQuery,
  useUpdateSearchParams,
} from "@project/common"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"
import { FULL_DATE_FORMAT_EN, YM_FORMAT } from "../../../../constants"
import { ApplicationManagementSummaryInfo } from "../ApplicationManagementCommon/ApplicationManagementSummaryInfo"
import { useRouter } from "next/router"
import { useApplicationManagement } from "../../../../context/ApplicationManagementContext"
import ApplicationManagementEditOperation from "./ApplicationManagementEditOperation"
import { useMutation } from "react-query"
import { createOrUpdateUserFacilitySchedules } from "../../../../services"
import { AlertBox, PageHead } from "../../../../components"
import Link from "next/link"

export const ApplicationManagementEditContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const scrollContent = useRef<HTMLDivElement>(null)
  const [updateParams] = useUpdateSearchParams()
  const { dataSource, isLoading, params, user, setParams, contractFacilities } =
    useApplicationManagement()
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  const serviceType = t(
    FACILITY_TYPES?.find(
      (v) => v?.value === user?.data?.user_certificate?.service_type,
    )?.label || "",
  )
  const mainTitle = t("{{user_name}}’s  attendance schedule edit", {
    user_name: user?.data?.username || "",
  })
  const title = t("{{user_name}}さん’s {{date}}User application list", {
    user_name: user?.data?.username || "",
    date: (dayjs([params?.year, params?.month - 1]).isValid()
      ? dayjs([params?.year, params?.month - 1])
      : dayjs()
    ).format(YM_FORMAT),
  })

  const { isLoading: isMutating, mutate } = useMutation({
    mutationFn: createOrUpdateUserFacilitySchedules,
    onSuccess: () => {
      notification.success({
        message: dynamicLangString([
          "Facility application",
          "Created Successfully",
        ]),
      })

      router.push(
        `/users/profile/${params?.user_id}/application-management/?year=${params?.year}&month=${params?.month}`,
      )
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.error
      const customError =
        msg === "Business day needs to be created first"
          ? t("Business day registration") + " " + t("Required")
          : null
      notification.error({
        message: customError
          ? t(customError)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const handleDataSave = () => {
    const payloadData = dataSource
      ?.filter((d) => d?.holiday != "1")
      ?.map(({ ...rest }) => {
        delete rest?.isCanceled
        delete rest?.isWeekEnd
        delete rest?.attendance_shift
        delete rest?.meal
        delete rest?.facility
        delete rest?.week
        delete rest?.week_day
        return {
          ...rest,
          facility_id: +rest?.facility_id || null,
          shift_id: +rest?.shift_id || null,
          meal_id: rest?.meal_flag ? +rest?.meal_id : null || null,
          goto_meet_flag: rest?.goto_meet_flag ? 1 : 0,
          goto_meet_place: +rest?.goto_meet_place || null,
          take_flag: rest?.take_flag ? 1 : 0,
          take_place: +rest?.take_place || null,
          meal_flag: rest?.meal_flag ? 1 : 0,
          user_id: +rest?.user_id || null,
          date: dayjs(rest?.date).format(FULL_DATE_FORMAT_EN),
          is_applied: rest?.is_applied ? 1 : 0,
        }
      })

    mutate(payloadData)
  }

  return (
    <>
      <PageHead>{mainTitle}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: <Link href={`/users`}>{t("User Management")}</Link>,
          },
          {
            title: (
              <Link href={`/users/profile/${router?.query.user_id}`}>
                {t("User Profile")}
              </Link>
            ),
          },
          {
            title: (
              <Link
                href={`/users/profile/${router?.query.user_id}/application-management`}
              >
                {t("User application management")}
              </Link>
            ),
          },
          {
            title: mainTitle,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={mainTitle}
      />
      <Flex vertical gap={16}>
        <ApplicationManagementEditOperation
          onDisplayChange={() => {
            scrollToSelectedElement(scrollContent)
          }}
        />

        {contractFacilities?.length <= 1 ? (
          <AlertBox
            heading={"Facility Contract Not Completed"}
            isCollapseAble={false}
          >
            <Typography.Title level={5}>
              <Link
                href={`/users/profile/${params?.user_id}/recipient-certificate-form?year=${params?.year}&month=${params?.month}`}
              >
                <a
                  style={{
                    textDecoration: "underline",
                    color: theme.colors.error,
                  }}
                >
                  {t("Facility contract settings")}
                </a>
              </Link>
            </Typography.Title>
          </AlertBox>
        ) : (
          <Card
            title={title}
            extra={
              <MonthPagination
                currentMonth={+params?.month - 1}
                currentYear={+params?.year || 2024}
                change={"month"}
                showDate={false}
                onMonthChange={(_, __, date?: Dayjs) => {
                  updateParams(
                    {
                      year: date?.year() || null,
                      month: date?.month() + 1 || null,
                    },
                    `/users/profile/${params?.user_id}/application-management/edit`,
                  )
                  setParams({
                    ...params,
                    year: date?.year(),
                    month: date?.month() + 1,
                  })
                }}
              />
            }
          >
            <Flex vertical gap={16} ref={scrollContent}>
              {isTabletOrMobile && (
                <Button
                  btnText={t("Save application information")}
                  shape={"round"}
                  onClick={handleDataSave}
                  width={"fit-content"}
                />
              )}
              {isLoading ? (
                <Flex vertical gap={16}>
                  <Flex justify={"space-between"}>
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </Flex>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </Flex>
              ) : (
                <Tabs
                  items={[
                    {
                      key: "service_type",
                      label: serviceType,
                      children: (
                        <Flex
                          vertical
                          gap={16}
                          style={{
                            padding: "16px",
                          }}
                        >
                          <ApplicationManagementSummaryInfo />
                          <ApplicationManagementLegend />
                          <ApplicationManagementListTable mode={"EDIT"} />
                          <ApplicationManagementSummaryInfo />
                        </Flex>
                      ),
                    },
                  ]}
                  tabBarExtraContent={
                    isTabletOrMobile ? null : (
                      <Button
                        btnText={t("Save application information")}
                        shape={"round"}
                        onClick={handleDataSave}
                        isLoading={isMutating}
                      />
                    )
                  }
                />
              )}

              <Flex justify={"space-between"} gap={16} align={"center"}>
                <Button
                  shape={"round"}
                  btnText={t("<<Back")}
                  onClick={() => router.back()}
                  disabled={isMutating}
                />
                <Flex gap={16} wrap={"wrap"}>
                  <Button
                    shape={"round"}
                    btnText={t("Cancel")}
                    onClick={() => router.back()}
                  />
                  <Button
                    shape={"round"}
                    btnText={t("Save application information")}
                    onClick={handleDataSave}
                    isLoading={isMutating}
                  />
                </Flex>
              </Flex>
              <Flex justify={"flex-end"}>
                <MonthPagination
                  currentMonth={+params?.month - 1}
                  currentYear={+params?.year || 2024}
                  change={"month"}
                  showDate={false}
                  onMonthChange={(_, __, date?: Dayjs) => {
                    updateParams(
                      {
                        year: date?.year() || null,
                        month: date?.month() + 1 || null,
                      },
                      `/users/profile/${params?.user_id}/application-management/edit`,
                    )
                    setParams({
                      ...params,
                      year: date?.year(),
                      month: date?.month() + 1,
                    })
                  }}
                />
              </Flex>
            </Flex>
          </Card>
        )}
      </Flex>
    </>
  )
}
