import { API } from "@project/common"
import { PAGE_SIZE } from "../constants"

export interface IReturnFacilityInformation {
  count?: number
  data?: any
}

interface IFacilityInformationParam {
  page?: string
  pageSize?: string
}
export const fetchFacilityInformation = async ({
  queryKey,
}): Promise<IReturnFacilityInformation> => {
  const params = {} as IFacilityInformationParam
  params.page = queryKey[1]
  params.pageSize = queryKey[2] || PAGE_SIZE
  return API.get(`/facilities`)
}

export const createFacilityInformation = async (values): Promise<any> => {
  delete values.facility_wage_setting2
  return API.post(`/facilities`, values)
}

export const getOneFacilityInformation = async (id: number) => {
  return API.get(`/facilities/${id}`)
}

export const updateFacilityInformation = async (values) => {
  const id = values.id
  return API.put(`/facilities/${id}`, values)
}

export const deleteFacilityInformation = async (id: number) => {
  const values = { id }
  return API.put(`/facilities/delete/${id}`, values)
}
