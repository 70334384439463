import {
  Button,
  CALENDAR_HOURS_MINUTES,
  InputField,
  SelectInput,
  Tag,
  theme,
} from "@project/common"
import { Flex, Switch, Typography } from "antd"
import { useTranslation } from "react-i18next"
import {
  StyledContainerWrapper,
  StyledContentWrapper,
  StyledFacilityWrapper,
} from "./ApplicationReoccurringSettingCalendarViewContent.style"

import { LOCATIONS as ORIGINAL_LOCATIONS } from "@project/common/src/constants"
import { FACILITY_REPEAT_DETAILS_VALUE } from "../../../../../../types"

export const ApplicationReoccurringSettingCalendarViewContent = ({
  shiftData,
  userContractData,
  mealData,
  defaultValues,
  handleValueChange,
  handleCancel,
}: {
  shiftData: any[]
  userContractData: any[]
  mealData: any[]
  defaultValues: FACILITY_REPEAT_DETAILS_VALUE
  handleValueChange: (values: any) => void
  handleCancel
}): JSX.Element => {
  const { t } = useTranslation()
  const active = defaultValues?.facility_id ? true : false

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const LOCATIONS = getOptionsWithTranslatedLabel(ORIGINAL_LOCATIONS)

  return (
    <Flex vertical gap={8} id={"required_error"}>
      <StyledFacilityWrapper vertical gap={8} $active={active}>
        <Flex vertical>
          <Typography.Paragraph>{t("Facility")}</Typography.Paragraph>
          <SelectInput
            name={"facility_id"}
            popupMatchSelectWidth={false}
            placeholder={"--"}
            width={"100%"}
            options={userContractData || []}
            value={defaultValues?.facility_id || null}
            onChange={(value) => {
              handleValueChange({
                value: value,
                key: "facility_id",
              })
            }}
          />
        </Flex>
        <Flex vertical>
          <Typography.Paragraph>{t("Shift")}</Typography.Paragraph>
          <SelectInput
            name={"shift"}
            popupMatchSelectWidth={false}
            placeholder={"--"}
            width={"100%"}
            options={shiftData || []}
            value={defaultValues?.shift_id || null}
            onChange={(value) =>
              handleValueChange({
                value: value,
                key: "shift_id",
              })
            }
          />
        </Flex>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          block
          onClick={() => {
            if (active) {
              handleCancel()
            }
          }}
        />
      </StyledFacilityWrapper>

      <StyledContainerWrapper vertical gap={8} $active={active}>
        <StyledContentWrapper vertical gap={8} $active={active}>
          <Flex align={"center"}>
            <Tag
              title={t("Meal ")}
              defaultBg={theme.colors.info}
              textColor={theme.colors.white}
              borderColor={theme.colors.info}
            />
            <Switch
              checked={defaultValues?.meal_flag}
              onChange={(value) =>
                handleValueChange({
                  value: value,
                  key: "meal_flag",
                })
              }
            />
          </Flex>
          <Flex vertical>
            <Typography.Paragraph>{t("Type")}</Typography.Paragraph>
            <SelectInput
              name={"meal_id"}
              popupMatchSelectWidth={false}
              placeholder={"--"}
              width={"100%"}
              options={[{ label: "--", value: null }].concat(mealData || [])}
              value={+defaultValues?.meal_id || null}
              disabled={!defaultValues?.meal_flag}
              onChange={(value) =>
                handleValueChange({
                  value: value,
                  key: "meal_id",
                })
              }
            />
          </Flex>
        </StyledContentWrapper>

        <StyledContentWrapper vertical gap={8} $active={active}>
          <Flex align={"center"}>
            <Tag
              title={t("Pick")}
              defaultBg={theme.colors.success}
              textColor={theme.colors.white}
              borderColor={theme.colors.success}
            />
            <Switch
              checked={defaultValues?.pickup}
              onChange={(val) => {
                handleValueChange({
                  value: val,
                  key: "pickup",
                })
              }}
            />
          </Flex>
          <Flex vertical>
            <Typography.Paragraph>
              {t("Place")} {":"}
            </Typography.Paragraph>
            <SelectInput
              name={"pickup_place"}
              popupMatchSelectWidth={false}
              placeholder={"--"}
              width={"100%"}
              value={+defaultValues?.pickup_place || null}
              options={LOCATIONS || []}
              disabled={!defaultValues?.pickup}
              onChange={(value) =>
                handleValueChange({
                  value: `${value}`,
                  key: "pickup_place",
                })
              }
            />
          </Flex>
          {defaultValues?.pickup_place === "3" && (
            <Flex>
              <InputField
                placeholder={"--"}
                name={"pickup_place_other"}
                value={defaultValues?.pickup_place_other || null}
                onChange={({ target: { value } }) => {
                  handleValueChange({
                    value: value,
                    key: "pickup_place_other",
                  })
                }}
              />
            </Flex>
          )}

          <Flex vertical>
            <Typography.Paragraph>
              {t("Time")}
              {":"}
            </Typography.Paragraph>
            <Flex align={"center"} gap={6}>
              <SelectInput
                name={"pick_hrs"}
                placeholder={"--"}
                width={"75px"}
                value={defaultValues?.pickup_time?.split(":")?.[0] || null}
                options={CALENDAR_HOURS_MINUTES.hours || []}
                disabled={!defaultValues?.pickup}
                onChange={(value) => {
                  handleValueChange({
                    value: `${value}:00`,
                    key: "pickup_time",
                  })
                }}
              />
              <Typography.Text>{":"}</Typography.Text>
              <SelectInput
                name={"pick_min"}
                placeholder={"--"}
                width={"75px"}
                value={defaultValues?.pickup_time?.split(":")?.[1] || null}
                options={CALENDAR_HOURS_MINUTES.minutes || []}
                disabled={
                  !defaultValues?.pickup_time?.split(":")?.[0] ||
                  !defaultValues?.pickup
                }
                onChange={(value) => {
                  handleValueChange({
                    value: `${defaultValues?.pickup_time?.split(
                      ":",
                    )?.[0]}:${value}`,
                    key: "pickup_time",
                  })
                }}
              />
            </Flex>
          </Flex>
        </StyledContentWrapper>
        <StyledContentWrapper vertical gap={8} $active={active}>
          <Flex align={"center"}>
            <Tag
              title={t("Drop")}
              defaultBg={theme.colors.action}
              textColor={theme.colors.white}
              borderColor={theme.colors.action}
            />
            <Switch
              checked={defaultValues?.drop}
              onChange={(val) => {
                handleValueChange({
                  value: val,
                  key: "drop",
                })
              }}
            />
          </Flex>
          <Flex vertical>
            <Typography.Paragraph>
              {t("Drop")} {":"}
            </Typography.Paragraph>
            <SelectInput
              name={"drop_place"}
              popupMatchSelectWidth={false}
              placeholder={"--"}
              width={"100%"}
              value={+defaultValues?.drop_place || null}
              options={LOCATIONS || []}
              disabled={!defaultValues?.drop}
              onChange={(value) =>
                handleValueChange({
                  value: `${value}`,
                  key: "drop_place",
                })
              }
            />
          </Flex>
          {defaultValues?.drop_place === "3" && (
            <Flex>
              <InputField
                autoFocus
                placeholder={"--"}
                name={"drop_place_other"}
                value={defaultValues?.drop_place_other || null}
                onChange={({ target: { value } }) => {
                  handleValueChange({
                    value: value,
                    key: "drop_place_other",
                  })
                }}
              />
            </Flex>
          )}

          <Flex vertical>
            <Typography.Paragraph>
              {t("Time")}
              {":"}
            </Typography.Paragraph>
            <Flex align={"center"} gap={6}>
              <SelectInput
                name={"pick_hrs"}
                placeholder={"--"}
                width={"75px"}
                value={defaultValues?.drop_time?.split(":")?.[0] || null}
                options={CALENDAR_HOURS_MINUTES.hours || []}
                disabled={!defaultValues?.drop}
                onChange={(value) => {
                  handleValueChange({
                    value: `${value}:00`,
                    key: "drop_time",
                  })
                }}
              />
              <Typography.Text>{":"}</Typography.Text>
              <SelectInput
                name={"pick_min"}
                placeholder={"--"}
                width={"75px"}
                value={defaultValues?.drop_time?.split(":")?.[1] || null}
                options={CALENDAR_HOURS_MINUTES.minutes || []}
                disabled={
                  !defaultValues?.drop_time?.split(":")?.[0] ||
                  !defaultValues?.drop
                }
                onChange={(value) => {
                  handleValueChange({
                    value: `${defaultValues?.drop_time?.split(
                      ":",
                    )?.[0]}:${value}`,
                    key: "drop_time",
                  })
                }}
              />
            </Flex>
          </Flex>
        </StyledContentWrapper>
        <StyledContentWrapper vertical $active={active}>
          <Typography.Paragraph>{t("Remarks")}</Typography.Paragraph>
          <InputField
            name={"remarks"}
            placeholder={"--"}
            value={defaultValues?.remarks || null}
            onChange={({ target: { value } }) => {
              handleValueChange({
                value: value,
                key: "remarks",
              })
            }}
          />
        </StyledContentWrapper>
      </StyledContainerWrapper>
    </Flex>
  )
}
