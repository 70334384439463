import { getFullEraDate } from "@project/common"
import { Flex, Skeleton, Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import {
  AcknowledgementPrintAbleContentWrapper,
  DateTitle,
  HeaderTittle,
  InnerWrapper,
  TextTitleSection,
} from "./AcknowledgementPrintAbleContent.style"
import { ProxyPrintTableContent } from "./ProxyPrintTableContent"

export const AcknowledgementPrintAbleContent = ({
  isLoading,
  dataSource,
}: {
  isLoading: boolean
  dataSource: any[]
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { date_of_issue, display_content } = router.query as any
  const currentIssueDate = date_of_issue ? dayjs(date_of_issue) : dayjs()
  const selectedOptions = display_content?.split(",") || []
  const title = selectedOptions?.includes("7")
    ? "Notification of Substitute Receipt of Disabled user's Outpatient Benefits"
    : "Notification of Substitute Receipt of Handicapped User's Outpatient Benefits"
  const renderContent = (data: any, isCopy?: boolean) => {
    return (
      <InnerWrapper key={data?.UserId}>
        <DateTitle>
          {selectedOptions?.includes("4") ? (
            <Flex justify={"center"} gap={"2rem"}>
              <div>
                {getFullEraDate(
                  dayjs(currentIssueDate).year(),
                  dayjs(currentIssueDate).month() + 1,
                )?.substring(0, 2)}
              </div>
              <div>{t("Year")}</div>
              <div>{t("Month")}</div>
              <div>{t("Day")}</div>
            </Flex>
          ) : (
            <>
              <div>
                {getFullEraDate(
                  dayjs(currentIssueDate).year() || dayjs().year(),
                  dayjs(currentIssueDate).month() + 1 || dayjs().month() + 1,
                )?.substring(0, 2)}
              </div>
              <div>
                {getFullEraDate(
                  dayjs(currentIssueDate).year() || dayjs().year(),
                  1,
                )?.substring(2)}
              </div>
              <div>{`${dayjs(currentIssueDate).month() + 1}${t("月")}`}</div>
              <div>{`${dayjs(currentIssueDate).date()}${t("日")}`}</div>
            </>
          )}
        </DateTitle>
        <HeaderTittle>
          <div className={"text"}>
            {"〒"}
            {data?.provision_city_zip_code || ""}
          </div>
          <div className={"text"}>{data?.provision_city_address || ""}</div>
          <div className={"text mt-5"}>
            {data?.user_name || ""}
            {" 様"}
          </div>
        </HeaderTittle>
        <DateTitle>
          <div>{data?.facility_name || ""}</div>
        </DateTitle>
        <TextTitleSection>
          <Typography.Title level={5}>
            {t(title)} {isCopy && `(${t("Copy")})`}
          </Typography.Title>
          <Typography.Paragraph>
            {t(
              `We would like to inform you that we received payment from {{city}} for the following services provided to{{user}} on your behalf.If you have any doubts about the content of this notice, please contact our office or {{city}}.`,
              {
                city: "city name",
                user: "user name",
              },
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>{" 記"}</Typography.Paragraph>
        </TextTitleSection>
        <ProxyPrintTableContent data={data} />
        <div className={"footer_text"}>
          {t("*This notice is not an invoice.")}
        </div>
      </InnerWrapper>
    )
  }

  return (
    <AcknowledgementPrintAbleContentWrapper>
      {isLoading ? (
        <Flex
          vertical
          gap={16}
          style={{
            padding: "16px 10px",
          }}
        >
          <Skeleton active />

          <Skeleton active />

          <Skeleton active />
        </Flex>
      ) : (
        dataSource?.map((data) => {
          return (
            <>
              {!selectedOptions?.includes("2") && renderContent(data)}
              {(selectedOptions?.includes("1") ||
                selectedOptions?.includes("2")) &&
                renderContent(data, true)}
            </>
          )
        })
      )}
    </AcknowledgementPrintAbleContentWrapper>
  )
}
