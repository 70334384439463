import {
  AccordionCard,
  Breadcrumb,
  Button,
  Card,
  InputField,
  LabelTextWithLeftArrow,
  LinkButton,
  OperationOptionContainer,
  PageTitle,
  Table,
  pageSize,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AuthContext } from "../../context/AuthContext"
import { fetchProvisionalCity } from "../../services/provisionalCity"
import { ProvisionCityMasterPermission } from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"
import { useUpdateSearchParams } from "../../utils/useUpdateSearchParams"

const ProvisionCityContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  .label {
    width: 0px !important;
  }
  .label-field {
    display: flex;
    row-gap: 10px;
  }
`
export const BlueSeparator = styled.div`
  background: #0782c8;
  width: 3px;
  height: 25px;
  margin-right: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`

export const ProvisionCity = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isOwner, permissions } = useContext(AuthContext)
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    provision_city_name: "",
    page: 1,
  })
  const [params, setParams] = useState({
    provision_city_name: "",
    page: 1,
  })
  const breadcrumbItems = [
    {
      title: <Link href={"/"}>{t("Home")}</Link>,
    },
    {
      title: t("Provision city master"),
    },
  ]

  const {
    isLoading,
    isFetching,
    data: provisionCityData,
  } = useQuery(
    ["fetch-provisional-city", params],
    () =>
      fetchProvisionalCity({
        ...params,
        pageSize: pageSize,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return data
          }),
          count: response?.count,
        }
      },
    },
  )

  const handlePaginationChange = (page: number) => {
    setValues({ ...values, page })
    setParams({ ...params, page })
    updateParams(
      {
        page,
        provision_city_name: values?.provision_city_name,
      },
      "/provisional-cities",
    )
  }
  const onSearch = () => {
    setParams(values)
    updateParams(
      {
        page: 1,
        provision_city_name: values?.provision_city_name,
      },
      "/provisional-cities",
    )
  }

  const resetHandler = () => {
    const v = { page: 1, provision_city_name: "" }
    setValues(v)
    setParams(v)
    updateParams({}, "/provisional-cities")
  }
  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, ProvisionCityMasterPermission, "write"),
    [isOwner, permissions],
  )

  const columns: ColumnsType<any> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      align: "center",
      render: (_, data) => {
        const { id } = data
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() => router.push(`provisional-cities/edit/${id}`)}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },

    {
      title: t("Provision city name"),
      dataIndex: "provision_city_name",
      key: "provision_city_name",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Provision City Number"),
      dataIndex: "municipality_number",
      key: "municipality_number",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Postal Code"),
      dataIndex: "zip_code",
      key: "zip_code",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Email address"),
      dataIndex: "email",
      key: "email",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Phone number"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      className: "table_text",
    },

    {
      title: t("Municipality subsidy no."),
      dataIndex: "subsidy_municipality_number",
      key: "subsidy_municipality_number",
      align: "center",
      className: "table_text",
    },
  ]

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Provision city master")}
        extra={
          <Button
            btnText={t("Add new Provision town")}
            type={"primary"}
            shape={"round"}
            size={"large"}
            iconType={"plus-circle"}
            onClick={() => router.push("/provisional-cities/add")}
            disabled={!hasWriteAccess}
          />
        }
      />
      <ProvisionCityContent>
        <AccordionCard
          defaultActiveKey={["provision-city"]}
          items={[
            {
              key: "provision-city",
              label: t("Operation Options"),
              children: (
                <>
                  <OperationOptionContainer>
                    <div className={"search__content label-field"}>
                      <LabelTextWithLeftArrow
                        label={t("City name")}
                        width={"148px"}
                      />
                      <InputField
                        size={"large"}
                        placeholder={t("Search from cityname")}
                        width={"300px"}
                        borderRadius={"5px"}
                        name={"provision_city_name"}
                        value={values.provision_city_name}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            provision_city_name: e?.target.value,
                          })
                        }}
                      />
                    </div>

                    <div
                      className={"search__content btns"}
                      style={{ gap: "10px" }}
                    >
                      <Button
                        size={"large"}
                        shape={"round"}
                        onClick={resetHandler}
                        btnText={t("Reset Search")}
                      />
                      <Button
                        size={"large"}
                        shape={"round"}
                        type={"primary"}
                        iconType={"search"}
                        btnText={t("Search")}
                        onClick={onSearch}
                      />
                    </div>
                  </OperationOptionContainer>
                </>
              ),
            },
          ]}
        />
        <Card title={t("List")}>
          <Table
            dataSource={provisionCityData?.data}
            columns={columns}
            total={provisionCityData?.count}
            pageSize={pageSize}
            current={+values.page}
            onChange={handlePaginationChange}
            loading={isFetching || isLoading}
            showPaginationOf={"both"}
            scroll={
              provisionCityData?.data?.length > 0 ? { x: "max-content" } : {}
            }
          />
        </Card>
      </ProvisionCityContent>
    </>
  )
}
