import { theme } from "@project/common"
import { Modal } from "antd"
import styled from "styled-components"
export const QualificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  .top__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    padding: 0.3em 0;
    border-bottom: 1px solid ${theme.colors.border};
    .ant-typography {
      font-size: 14px !important;
      margin: 0;
      padding: 0;
    }
    .msg {
      color: ${theme.colors.error};
      font-size: 14px !important;
    }
  }
`
export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  #msg {
    color: ${theme.colors.error};
    font-weight: 400;
    font-size: 14px;
    white-space: pre-wrap;
  }
  div.ant-typography {
    margin-bottom: 0px !important;
  }
  @media (max-width: ${theme.breakpoints?.md}) {
    flex-direction: column;
    align-items: start;
    #msg {
      font-size: 14px;
    }
  }
`
export const ModalBodyContainer = styled.div`
  margin: 0 auto;
  position: "relative";
  .body_content {
    display: flex;
    .form_control {
      border: 1px solid ${theme.colors.border};
      width: 100%;
      &:not(:last-child) {
        border-right: 0px;
      }
      &:nth-child(1) {
        border-right: 0px;
        width: 450px;
      }
      .label {
        border-bottom: 1px solid ${theme.colors.border};
        padding: 10px;
        ${theme.typography["body-regular"]}
      }
      .form_input {
        padding: 10px;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100% !important;
        }
      }
      .error_message {
        font-size: 12px;
        color: #eb5757;
      }
      .ant-picker {
        border-radius: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        box-shadow: none;
      }
      @media (max-width: 1075px) {
        &:not(:last-child) {
          border-bottom: 0px;
          border-right: 1px solid ${theme.colors.border};
        }
        &:nth-child(1) {
          /* border-right: 0px; */
          width: 100%;
        }
      }
    }
    @media (max-width: 1075px) {
      flex-direction: column;
    }
    @media (max-width: 610px) {
      max-width: 43rem;
    }
    @media (max-width: 445px) {
      max-width: 30rem;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 12px;
  }
`
export const ModalWrapper = styled(Modal)``

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
`
