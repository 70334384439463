// types
import { UserTransportMgmtOperationType } from "../../types/UserTransportMgmt.types"

export const USER_TRANSPORT_MGMT_OPERATION_KEYS = [
  "facility_ids",
  "month",
  "year",
] as (keyof UserTransportMgmtOperationType)[]

// ! [NOTE: THIS NEEDS TO BE REMOVED AFTER API IMPLEMENTATION]
export const USER_TRANSPORT_TABLE_MOCK = [
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-29T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-28T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-27T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-26T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-25T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-24T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-23T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-22T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-21T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-20T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-19T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 1,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-18T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-17T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-16T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-15T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-14T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-13T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-12T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-11T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-10T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-09T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-08T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-07T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-06T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-05T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 1,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-04T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-03T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 1,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-02T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 1,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
  {
    child_facility_schedule_id: null,
    facility_id: 11,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-01T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 1,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },

  // ! For facility id 12
  {
    child_facility_schedule_id: null,
    facility_id: 12,
    facility_name: "ABB",
    facility_name_furigana: null,
    facility_name_short: null,
    service_type: null,
    date: "2024-02-28T00:00:00Z",
    time: null,
    arrival_time: null,
    place_id: null,
    other_place: null,
    child_id: null,
    child_name: null,
    gender: null,
    child_name_furigana: null,
    use_transport: null,
    is_applied: null,
    is_present: null,
    is_unregistered: null,
    pick_count: 12,
    drop_count: 13,
    total_count: 0,
    is_holiday: 0,
    remarks: "",
    same_premises: false,
    nursery_school_name: "",
    nursery_school_name_furigana: "",
    child_attendance_id: null,
    route: null,
    desired_pick_up_place: null,
    desired_drop_off_place: null,
  },
]
