// commons
import { keys } from "@project/common"

// types
import {
  FacilityWorkDataType,
  FacilityWorkTableType,
  GenericManufacturingCost,
  ManufacturingCostStatement,
  ManufacturingCostStatementDataType,
} from "../../../../types/projectCostManagement.types"

// constants
import { attribsToSumUp } from "../constants"

/**
 *
 * @param item in a arg that consists of all attribs that we require to calculate with each other
 * @returns calculated attribs such as `form_material_cost`, `employment_support_total_cost` etc.
 */
const calculate = (item: GenericManufacturingCost) => {
  const total =
    item.term_war_material_inventory + item.fine_year_material_purchase_height

  // from sum of all 17 different expenses "1. welfare expense to 17. Miscellaneous expenses"
  const expense = keys(item).reduce((acc, currKey) => {
    return attribsToSumUp.includes(currKey) ? acc + +item[currKey] : acc
  }, 0)

  const formMaterialCost = total - item.involved_in_terms_of_term
  const employmentSupportTotalCost =
    formMaterialCost +
    item.formor_labor_cost +
    item.formor_outsourcing_cost +
    expense
  // these two attribs need to be discussed with prajwol dai later
  const termTableBasedProductWholesaler = 90
  const terminatedGoodsWholesaler = 1000
  const grandTotal =
    employmentSupportTotalCost + termTableBasedProductWholesaler
  const currentPeriodManufacturingPrice = grandTotal - terminatedGoodsWholesaler

  return {
    total,
    expense,
    form_material_cost: formMaterialCost,
    employment_support_total_cost: employmentSupportTotalCost,
    grand_total: grandTotal,
    current_period_manufacturing_price: currentPeriodManufacturingPrice,
    term_table_based_product_wholesaler: termTableBasedProductWholesaler,
    terminated_goods_wholesaler: terminatedGoodsWholesaler,
  }
}

export function calculateExpenseAttribs(
  responseData: ManufacturingCostStatement[],
): ManufacturingCostStatementDataType[]

export function calculateExpenseAttribs(
  responseData: FacilityWorkTableType[],
): FacilityWorkDataType[]

export function calculateExpenseAttribs(
  rawData: ManufacturingCostStatement[] | FacilityWorkTableType[],
): unknown {
  if (!rawData) {
    return []
  }
  return rawData?.map((item) => ({
    ...item,
    ...calculate(item),
  }))
}
