import {
  DatePicker,
  ErrorMassage,
  FACILITY_TYPES,
  Grid,
  InputField,
  SelectInput,
} from "@project/common"
import { Flex } from "antd"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  FAILURE_TYPES,
  FULL_DATE_FORMAT,
  FuriganaAlphabetsOption,
} from "../../../constants"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import dayjs from "dayjs"
import { useFormikContext } from "formik"
import { AssessmentFormValues } from "../../../types"
import { filterWithHiragana } from "../../../utils"
import { AuthContext } from "../../../context"

export const AssessmentGeneralInfoFields = ({
  mode,
}: {
  mode: "add" | "edit"
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const { values, setFieldValue, errors, touched } =
    useFormikContext<AssessmentFormValues>()

  const { userData, isLoading } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  const selectedUser = userData?.find((user) => user?.value === values?.user_id)

  const userFailureTypesIndexes = []
  const userFailureTypes = selectedUser?.failure_types || ""
  Object.values(userFailureTypes.split(",")).forEach((value, index) => {
    ;+value === 1 && userFailureTypesIndexes.push(index)
  })

  useEffect(() => {
    if (selectedUser) {
      // For Add Pre select user valid facility
      if (mode === "add") {
        selectedUser?.facility_id > 0
          ? (values.facility_id = String(selectedUser?.facility_id))
          : (values.facility_id = null)
        ;+selectedUser?.use_service_id > 0
          ? (values.service_type = +selectedUser?.use_service_id)
          : (values.service_type = null)
      }
    }
  }, [selectedUser])

  return (
    <div>
      <Grid
        background
        labelContent={t("Username")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
        required
      >
        <Flex wrap={"wrap"} gap={16}>
          <SelectInput
            name={"furigana"}
            value={values?.user_hiragana || null}
            disabled={mode === "edit"}
            onChange={(val) => {
              setFieldValue("user_id", null)
              setFieldValue("user_hiragana", val)
            }}
            options={FuriganaAlphabetsOption}
            shortSelector
            width={"86px"}
            placeholder={"--"}
          />

          <SelectInput
            name={"user_id"}
            options={
              filterWithHiragana({
                options: userData || [],
                furigana: values?.user_hiragana,
              }) || []
            }
            disabled={mode === "edit"}
            placeholder={"--"}
            value={userData?.length && selectedUser ? values?.user_id : null}
            onChange={(val) => setFieldValue("user_id", val)}
            loading={isLoading}
            width={"282px"}
          />
        </Flex>
        {errors?.user_id && touched?.user_id && (
          <ErrorMassage message={t("Required")} mt={"2px"} />
        )}
      </Grid>
      <Grid
        background
        labelContent={t("Facility name")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
        required
      >
        {selectedUser ? (
          <>
            <Flex wrap={"wrap"} gap={16}>
              <SelectInput
                name={"facility_id"}
                options={facilities}
                placeholder={"--"}
                value={
                  values?.facility_id && values?.user_id
                    ? String(values?.facility_id)
                    : null
                }
                onChange={(val) => setFieldValue("facility_id", val)}
                loading={isLoading}
                width={"282px"}
              />
            </Flex>
            {touched.facility_id && +values?.facility_id < 1 && (
              <ErrorMassage message={t("Required")} mt={"2px"} />
            )}
          </>
        ) : (
          <span>{t("*Select user first")}</span>
        )}
      </Grid>
      <Grid
        background
        labelContent={t("Use service")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
        required
      >
        {selectedUser ? (
          <>
            <Flex wrap={"wrap"} gap={16}>
              <SelectInput
                name={"service_type"}
                options={FACILITY_TYPES}
                placeholder={"--"}
                value={
                  values?.service_type && values?.user_id
                    ? values?.service_type
                    : null
                }
                onChange={(val) => setFieldValue("service_type", val)}
                loading={isLoading}
                width={"282px"}
              />
            </Flex>
            {touched.service_type && +values?.service_type < 1 && (
              <ErrorMassage message={t("Required")} mt={"2px"} />
            )}
          </>
        ) : (
          <span>{t("*Select user first")}</span>
        )}
      </Grid>
      <Grid
        background
        labelContent={t("Creation date")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
        required
      >
        <DatePicker
          date={values?.creation_date ? dayjs(values?.creation_date) : null}
          onDateChange={(date) => setFieldValue("creation_date", date)}
          style={{
            width: "183px",
          }}
        />
      </Grid>
      <Grid
        background
        labelContent={t("No of Assessment")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
        required
      >
        <Flex align={"center"} gap={8} className={"testclassname"}>
          <InputField
            type={"number"}
            className={"testclassname2"}
            style={{
              width: "104px",
            }}
            onChange={(e) => {
              setFieldValue("creation_number", +e.target.value)
            }}
            value={values?.creation_number}
            name={"creation_number"}
            subInfo={"回目"}
            msgType={"subInfo"}
          />
        </Flex>
        {errors?.creation_number && touched?.creation_number && (
          <ErrorMassage message={t("Required")} mt={"2px"} />
        )}
      </Grid>

      <Grid
        background
        labelContent={t("Service start date")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
      >
        {selectedUser ? (
          <span>
            {dayjs(selectedUser?.service_start_date).isValid() &&
              dayjs(selectedUser?.service_start_date).format(FULL_DATE_FORMAT)}
          </span>
        ) : (
          <span>{t("*Select user first")}</span>
        )}
      </Grid>
      <Grid
        background
        labelContent={t("Failure type")}
        labelSpan={6}
        padding={"10px"}
        fontWeight={500}
      >
        {selectedUser ? (
          <span>
            {FAILURE_TYPES.filter(
              (_, index) => userFailureTypesIndexes?.includes(index),
            ).toString()}
          </span>
        ) : (
          <span>{t("*Select user first")}</span>
        )}
      </Grid>
    </div>
  )
}
