import {
  AUTO_EMAIL_SENDING_OPTIONS,
  Card,
  LinkButton,
  PageTitle,
  Table,
  scrollToSelectedElement,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { FULL_DATE_FORMAT, PAGE_SIZE } from "../../constants"
import { fetchAutoEmailSending } from "../../services/autoEmailSending"
import { AutoEmailSendingColumn } from "../../types"
import { AuthContext } from "../../context"
import {
  hasPermissionForFacility,
  SystemMailSettings,
} from "../../utils/PermissionKeys"

const StyleAutoEmailSendingWrapper = styled.div`
  margin-top: 16px;
`

const AutoEmailSending = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)
  const { facility_id } = router?.query as any
  const [currentPage, setCurrentPage] = useState(+router?.query?.page || 1)
  const listRef = useRef<HTMLDivElement>(null)
  const {
    isLoading,
    isFetching,
    data: autoEmailSendingData,
  } = useQuery(["fetchAutoEmailSending", currentPage, +facility_id], {
    queryFn: fetchAutoEmailSending,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })

  const getMailType = (mailType: number) => {
    const mailTypeOption = AUTO_EMAIL_SENDING_OPTIONS.find(
      (item) => item.value === mailType,
    )
    return mailTypeOption
  }

  const hasEditAccess = (facilityId) => {
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        SystemMailSettings,
        "write",
        facilityId,
      )
    )
  }

  const columns: ColumnsType<AutoEmailSendingColumn[]> = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (id: number, data: any) => {
        const mailType = getMailType(data?.mail_type)
        return (
          <LinkButton
            disabled={!hasEditAccess(data?.facility_id)}
            onClick={() => router.push(`/auto-email-sending/edit/${id}`)}
          >
            {t(mailType?.label)}
          </LinkButton>
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Subject")}</span>,
      dataIndex: "mail_subject",
      key: "mail_subject",
      align: "center",
      render: (_, data: AutoEmailSendingColumn) => {
        return <span>{t(data?.mail_subject)}</span>
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Email Address")}</span>,
      align: "center",
      dataIndex: "mail_address",
      key: "mail_address",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Update date")}</span>,
      dataIndex: "updated_datetime",
      key: "updated_datetime",
      align: "center",
      render: (_, data: AutoEmailSendingColumn) => {
        return (
          <span>{dayjs(data?.updated_datetime).format(FULL_DATE_FORMAT)}</span>
        )
      },
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Update PIC")}</span>,
      align: "center",
      dataIndex: "updated_pic",
      key: "updated_pic",
      render: (_, data: AutoEmailSendingColumn) => {
        return <span>{data?.updated_pic ? t(data.updated_pic) : "-"}</span>
      },
    },
  ]
  return (
    <StyleAutoEmailSendingWrapper ref={listRef}>
      <PageTitle
        title={t("Auto email sending")}
        icon={<img src={"./assets/icons/facility.svg"} alt={"icon"} />}
      />
      <Card title={t("Auto email sending")}>
        <Table
          columns={columns}
          current={currentPage}
          pageSize={PAGE_SIZE}
          dataSource={autoEmailSendingData?.data}
          total={autoEmailSendingData?.count || 0}
          showPaginationOf={"both"}
          pagination={false}
          scroll={{ x: 900 }}
          onChange={(val) => {
            setCurrentPage(val)
            scrollToSelectedElement(listRef)
            router.push(
              {
                pathname: "/auto-email-sending",
                query: {
                  page: val,
                },
              },
              undefined,
              {
                scroll: false,
              },
            )
          }}
          loading={isLoading || isFetching}
          cellPaddingBlock={12}
        />
      </Card>
    </StyleAutoEmailSendingWrapper>
  )
}

export { AutoEmailSending }
