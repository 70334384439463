import {
  Button,
  Card,
  PrintFriendlyTable,
  Table,
  getFullEraDate,
  theme,
  usePrint,
} from "@project/common"
import { Flex, Typography } from "antd"
import { Excel } from "antd-table-saveas-excel"
import { TableProps } from "antd/lib"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AlertBox } from "../../../../components"
import { useAuthContext } from "../../../../context"
import { getPrintUserBillingList } from "../../../../services"
import {
  getActualCostTotal,
  getCostTotalData,
  getGrandTotalData,
  getUserInvoiceTotal,
  listSerialize,
} from "./UBLUtilfn"
import { UserBillingListContentOperationOptions } from "./UserBillingListContentOperationOptions"
const Wrapper = styled(Flex)`
  .table-row-dark {
    background-color: ${theme.colors.gray7} !important;
  }
`
export const UserBillingListContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { display_settings, facilityIds, year, month } = router.query as any
  const { facilities } = useAuthContext()
  const selected_facilities = facilityIds
    ? facilityIds?.split(",")
    : [facilities?.[0]?.value] || []
  const selected_display_settings = display_settings?.split(",") || []
  const contentRef = useRef<HTMLDivElement>(null)
  const { handlePrint } = usePrint({
    contentRef: contentRef,
    pageStyle: `margin:6mm`,
  })
  //fetch data
  const { data: response, isLoading } = useQuery({
    queryKey: ["user-billing-list", display_settings, facilityIds],
    queryFn: () =>
      getPrintUserBillingList({
        year: year || dayjs().year(),
        month: month || dayjs().month() + 1,
        facilityIds: facilityIds || facilities?.[0]?.value,
        display_settings: display_settings,
        show_zero_invoice_total: display_settings?.includes("1") ? 1 : null,
      }),
    refetchOnWindowFocus: false,
    select: (res) => {
      const source = []
      res?.data?.map((item) => {
        item?.user_list?.map((user) => {
          source.push({
            ...user,
            actual_cost_total: getActualCostTotal(user?.actual_costs),
          })
        })
        source.push({
          ...item?.provision_city_summary,
          isSummaryRow: true,
          user_invoice_total: getUserInvoiceTotal(item?.user_list),
          user_list: listSerialize(item?.user_list),
          total_user: item?.user_list?.length,
        })
      })
      const onlyUserListData = source?.filter((item) => !item?.isSummaryRow)
      const rowsWithGrandTotal = source?.length
        ? [
            ...source,
            {
              isGrandTotalRow: true,
              provision_city: t("Total"),
              invoice_total: getGrandTotalData(
                onlyUserListData,
                "invoice_total",
              ),
              total_user_list: listSerialize(onlyUserListData),
              total_user_list_count: onlyUserListData?.length,
            },
          ]
        : source
      return { data: rowsWithGrandTotal }
    },
  })
  //dynamic columns start
  const actualCostColumn = () => {
    if (response?.data[0]?.actual_costs?.length)
      return [
        {
          title: t("Actual Cost"),
          dataIndex: "actual_costs",
          children: response?.data[0]?.actual_costs?.map((item) => ({
            title: t(item?.actual_cost_name || ""),
            dataIndex: `${item?.actual_cost_name}`,
            render: (_, row) => {
              if (row?.isGrandTotalRow) {
                const cost_total = getCostTotalData(
                  row?.total_user_list || [],
                  item,
                )
                return cost_total[`${item?.actual_cost_name}`] || "0"
              }
              if (row?.isSummaryRow) {
                const cost_total = getCostTotalData(row?.user_list || [], item)
                return cost_total[`${item?.actual_cost_name}`] || "0"
              }

              const costData = row?.actual_costs?.find(
                (cost) => item?.actual_cost_id === cost?.actual_cost_id,
              )
              return costData?.actual_cost_price || "0"
            },
          })),
        },
        {
          title: t("Actual Cost total"),
          dataIndex: "user_invoice_total",
          render: (_, row) => {
            const itemTotal = (list: any) =>
              list?.reduce((a, b) => a + b?.actual_cost_total, 0) || "0"
            if (row?.isGrandTotalRow)
              return itemTotal(row?.total_user_list) || "0"
            if (row?.isSummaryRow) return itemTotal(row?.user_list) || "0"
            return row?.actual_cost_total || "0"
          },
        },
      ]
    return []
  }
  const getDynamicFacilityColumns = () => {
    return selected_facilities?.map((fid) => {
      const facility = facilities?.find((item) => item?.value === fid)
      return {
        title: facility?.facility_name,
        dataIndex: `${facility?.id}`,
        render: (_, row) => {
          const facilityTotal = (list) =>
            list
              ?.filter((v) => v?.facility_id === +fid)
              ?.reduce((a, b) => {
                return a + b?.invoice_total
              }, 0)
          if (row?.isGrandTotalRow)
            return facilityTotal(row?.total_user_list) || ""
          if (row?.isSummaryRow) return facilityTotal(row?.user_list) || ""

          return row?.facility?.id === +fid ? row?.invoice_total : ""
        },
      }
    })
  }
  //dynamic columns end
  const columns: TableProps<any>["columns"] = [
    {
      key: "1",
      align: "center",
      title: t("Provision city"),
      dataIndex: "provision_city",
      render: (_, row) => {
        if (row?.isGrandTotalRow) return t("Total")
        return (
          row?.provision_city?.provision_city_name ||
          row?.user?.user_certificate?.provision_city?.provision_city_name ||
          ""
        )
      },
    },
    {
      key: "2",
      align: "center",
      title: t("Beneficiary certificate number"),
      dataIndex: "beneficiary_certificate_number",
      render: (_, row) => {
        return row?.user?.user_certificate?.number || ""
      },
    },
    {
      key: "3",
      align: "center",
      title: `${t("Parent full name")} ${
        display_settings?.includes("2") ? `(${t("Furigana")})` : ""
      }`,
      dataIndex: "parent_full_name",
      render: (_, row) => {
        return `${row?.user?.parent_name || ""} ${
          display_settings?.includes("2")
            ? `(${row?.user?.parent_furigana || ""})`
            : ""
        }`
      },
    },
    {
      key: "4",
      align: "center",
      title: `${t("User full name ")} ${
        display_settings?.includes("3") ? `(${t("Furigana")})` : ""
      }`,
      dataIndex: "user_full_name",
      render: (_, row) => {
        if (row?.isGrandTotalRow) return row?.total_user_list_count
        if (row?.isSummaryRow) return row?.total_user || ""
        return `${row?.user?.username || ""} ${
          display_settings?.includes("3")
            ? `(${row?.user?.furigana || ""})`
            : ""
        }`
      },
    },
    ...getDynamicFacilityColumns(),
    {
      key: "6",
      align: "center",
      title: t("User invoice total"),
      dataIndex: "user_invoice_total",
      render: (_, row) => {
        if (row?.isSummaryRow) return row?.user_invoice_total || "0"
        return row?.invoice_total || "0"
      },
    },
    ...actualCostColumn(),
  ]

  const downloadCSV = () => {
    const excel = new Excel()
    excel.setTHeadStyle({
      fontSize: 10,
      h: "center",
      v: "center",
      background: "CCCBCC",
      wrapText: false,
    })
    excel.setTBodyStyle({
      fontSize: 10,
      v: "center",
      h: "center",
      border: true,
    })
    excel
      .addSheet(t("Print User Billing List"))
      .addColumns(columns as any)
      .addDataSource(response?.data)
      .saveAs(`${t("Print User Billing List")}.xlsx`)
  }
  //filter summary data if display_settings includes 4 (Display Users in phonetic order)
  const dataSources = display_settings?.includes("4")
    ? response?.data?.filter((v) => !v?.isSummaryRow)
    : response?.data

  return (
    <>
      <Wrapper vertical gap={16}>
        <UserBillingListContentOperationOptions />
        <Card>
          <Flex vertical gap={16}>
            <Flex gap={16} wrap={"wrap"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() =>
                  router.push(
                    `/national-health-insurance-billing?year=${
                      year || dayjs().year()
                    }&month=${month || dayjs().month() + 1}&facility=${
                      facilityIds?.split(",")[0] || facilities?.[0]?.value
                    }`,
                  )
                }
              />
              <Button
                btnText={t("Print")}
                iconType={"print"}
                type={"primary"}
                shape={"round"}
                onClick={handlePrint}
              />
              <Button
                btnText={t("CSV Output")}
                shape={"round"}
                iconType={"XFileIcon"}
                type={"outlined"}
                onClick={downloadCSV}
              />
            </Flex>
            {!selected_display_settings?.includes("1") && (
              <AlertBox
                width={"100%"}
                color={theme.colors.info}
                isCollapseAble={false}
                heading={t(
                  "If you want to print a user whose billing total is 0 yen, you need to change the operation option.ing options.",
                )}
                subHeading={t(
                  "Check 'Print users whose billing total is 0 yen' and switch the display.",
                )}
              />
            )}

            <Flex vertical gap={16}>
              <Flex justify={"center"}>
                <Typography.Title level={5}>
                  {t("List of user requests ({{date}})", {
                    date: `${getFullEraDate(
                      year || dayjs().year(),
                      month || dayjs().month() + 1,
                    )} ${month || dayjs().month() + 1}${t("Month")}`,
                  })}
                </Typography.Title>
              </Flex>
            </Flex>
            <Table
              columns={columns}
              dataSource={dataSources || []}
              headerBg={theme.colors.gray7}
              borderColor={theme.colors.text}
              headerBorderRadius={0}
              loading={isLoading}
              scroll={{ x: "max-content" }}
              rowClassName={(row) =>
                row?.isSummaryRow || row?.isGrandTotalRow
                  ? "table-row-dark"
                  : " table-row-light"
              }
            />
          </Flex>
        </Card>
      </Wrapper>
      {/* //printable content */}
      <div style={{ display: "none" }}>
        <div ref={contentRef}>
          <Flex justify={"center"}>
            <Typography.Title level={5}>
              {t("List of user requests ({{date}})", {
                date:
                  getFullEraDate(dayjs().year(), dayjs().month() + 1) +
                  dayjs().month() +
                  t("Month"),
              })}
            </Typography.Title>
          </Flex>
          <PrintFriendlyTable
            columns={columns}
            dataSource={dataSources || []}
          />
        </div>
      </div>
    </>
  )
}
