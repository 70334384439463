import { Button, CheckBox, Modal, theme } from "@project/common"
import { Flex, Typography } from "antd"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { UseAssessmentSettings } from "../../context"
import { useAssessmentMutation } from "../../hooks"
import { getAssessmentPayload } from "../../serialize"

export const AssessmentSettingsHiddenItemListModal = ({
  open,
  onCancel,
  list,
}: {
  open: boolean
  onCancel: () => void
  list: any[]
  settingId: number
}): JSX.Element => {
  const { t } = useTranslation()
  const [data, setData] = useState(list || [])
  const { assessmentData, refetch } = UseAssessmentSettings()
  const { updateAssessmentData, isMutating } = useAssessmentMutation({
    onCancel,
    refetch,
  })
  const onCheckBoxClicked = ({
    categoryId,
    itemId,
    value,
  }: {
    settingId: number
    categoryId: number
    itemId?: number
    value: any
  }) => {
    const newData = data?.map((category) =>
      category?.id === categoryId
        ? {
            ...category,
            show_display: !itemId ? value : category?.show_display,
            checked: !itemId ? value : null,
            assessment_item: itemId
              ? category?.assessment_item?.map((item) =>
                  item?.id === itemId
                    ? { ...item, show_display: value, checked: value }
                    : item,
                )
              : category?.assessment_item,
          }
        : category,
    )
    setData(newData)
  }
  const handleHiddenItemSaveChange = ({ settingId }: { settingId: number }) => {
    const categories = assessmentData?.find(
      (assessment) => assessment?.id === settingId,
    )
    const newData = categories?.assessment_category?.map((category) => {
      const hiddenCategory = data?.find((d) => d?.id === category?.id)
      if (hiddenCategory) {
        return {
          ...hiddenCategory,
          assessment_item: hiddenCategory?.assessment_item?.length
            ? category?.assessment_item?.map((item) => {
                const hiddenItem = hiddenCategory?.assessment_item?.find(
                  (v) => v?.id === item.id,
                )
                if (hiddenItem) {
                  return hiddenItem
                }
                return item
              })
            : category?.assessment_item,
        }
      }
      return category
    })
    const payload = getAssessmentPayload(newData || [])
    updateAssessmentData({
      values: payload,
      settingId,
    })
  }
  const tempList = useMemo(() => {
    const items = []
    data?.map((v) => {
      const t = v?.assessment_item?.filter((item) => item?.checked)
      if (t?.length) {
        items.push(t)
      }
    })
    return {
      category: data?.filter((v) => v?.checked),
      items,
    }
  }, data)
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t("Hidden item list")}
      headerBg={theme.colors.background}
      titleColor={theme.colors.text}
      footer={() => (
        <Flex
          justify={"flex-end"}
          gap={8}
          wrap={"wrap"}
          style={{
            padding: "10px",
          }}
        >
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={onCancel}
            disabled={isMutating}
          />
          <Button
            btnText={t("Select")}
            shape={"round"}
            type={"primary"}
            isLoading={isMutating}
            disabled={
              tempList?.category?.length || tempList?.items?.length
                ? false
                : true
            }
            onClick={() =>
              handleHiddenItemSaveChange({
                settingId: data?.[0]?.assessment_setting_id,
              })
            }
          />
        </Flex>
      )}
    >
      <Flex vertical>
        <Typography.Paragraph>
          {t(
            "If you check the display, it will be displayed on the recording screen again.",
          )}
        </Typography.Paragraph>
        <Flex vertical>
          <Flex
            justify={"space-between"}
            style={{
              background: theme.colors.border,
              padding: "10px",
            }}
          >
            <Flex flex={1}>{t("Category/item name")}</Flex>
            <Flex>{t("Display")}</Flex>
          </Flex>
          {data?.map((category, index) => (
            <Flex
              vertical
              key={index}
              gap={8}
              style={{
                background: theme.colors.background,
                padding: "10px",
              }}
            >
              {!list[index]?.show_display ? (
                <Flex justify={"space-between"}>
                  <Flex flex={1}>{category?.title}</Flex>
                  <Flex>
                    <CheckBox
                      value={category?.show_display}
                      checked={category?.show_display}
                      onChange={({ target: { checked } }) => {
                        onCheckBoxClicked({
                          settingId: category?.assessment_setting_id,
                          categoryId: category?.id,
                          value: checked,
                        })
                      }}
                    />
                  </Flex>
                </Flex>
              ) : (
                t(category?.title || "")
              )}
              <Flex
                vertical
                gap={8}
                style={{
                  background: theme.colors.background,
                  padding: "0 0 0 10px",
                }}
              >
                {category?.assessment_item?.map((v, index) => (
                  <Flex justify={"space-between"} key={index}>
                    <Flex flex={1}>{v?.title}</Flex>
                    <Flex>
                      <CheckBox
                        onChange={({ target: { checked } }) => {
                          onCheckBoxClicked({
                            settingId: category?.assessment_setting_id,
                            categoryId: category?.id,
                            itemId: v?.id,
                            value: checked,
                          })
                        }}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Modal>
  )
}
