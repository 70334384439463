import {
  Button,
  CSV_OPTIONS,
  Card,
  CheckBox,
  FACILITY_SERVICE_TYPES,
  MonthPagination,
  Table,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Spin, notification } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { AuthContext } from "../../context"
import {
  calculateNHIFReceipt,
  getNHIFAlerts,
  getNHIFBillingSteps,
  getNHIFEligibleUserList,
  saveNHIFEligibleUserList,
} from "../../services"
import {
  CalculateReceipt,
  ConfirmUserInfo,
  NHIFAlertsType,
  NHIFBillingStepValues,
  NHIFSearchParams,
} from "../../types/nhif.types"
import {
  BillingCSVPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"
import {
  IndividualSupportSubtractionContainer,
  NHIFConfirmBillingContainer,
  NHIFConfirmedBillingContainer,
  NHIFLinkSection,
  NHIFScrollSection,
  NHIFSection1,
  NHIFSection2,
  NHIFSpinContainer,
  NHIFTableContainer,
} from "./NHIFForm.styles"
import { NHIFNumberedSection } from "./NHIFNumberedSection"
import { NHIFOperation } from "./NHIFOperation"
import NHIFAlerts from "./alerts"

export const NHIFInternal = () => {
  const NHIFUserTableColumn: any = [
    {
      key: "edit",
      align: "center",
      title: t("Edit"),
      render: (row) => (
        <CheckBox
          checked={row?.selected}
          onClick={() => {
            const newDataSource = [...userList].map((user) => {
              if (user.user_id == row.user_id) {
                user.selected = !user.selected
              }
              return user
            })
            setUserList(newDataSource)
          }}
        />
      ),
    },
    {
      key: "user_name",
      align: "center",
      title: (
        <>
          {t("User Name")}
          <br />
          {t("(furigana)")}
        </>
      ),
      sorter: (a: any, b: any) => a?.user_name?.localeCompare(b?.user_name),
      render: (row) => {
        return (
          <>
            {row?.user_name}
            <br />
            {row?.furigana && `(${row?.furigana})`}
          </>
        )
      },
    },
    {
      key: "payment_municipality",
      align: "center",
      title: t("Provision City"),
      render: (row) => row?.payment_municipality,
    },
    {
      key: "service",
      align: "center",
      title: t("Service use"),
      render: (row) => t(FACILITY_SERVICE_TYPES?.[row?.service]),
    },
    {
      key: "support_plan",
      align: "center",
      title: t("Final review date for individual support plans"),
      render: (row) =>
        row?.individual_support_plan_final_review_date &&
        row?.individual_support_plan_id && (
          <Link
            href={`/individual-support-plan/detail/${row?.individual_support_plan_id}`}
          >
            {row?.individual_support_plan_final_review_date}
          </Link>
        ),
    },
    {
      key: "individual_subtraction",
      align: "center",
      title: t("Individual support plan\nUncreated subtraction balance"),
      render: (row) => {
        return (
          <IndividualSupportSubtractionContainer>
            <Button
              btnText={t("Do not")}
              shape={"round"}
              disabled={!row?.individual_support_plan_non_creation_subtraction}
              onClick={() =>
                handleUserListChange(
                  row?.user_id,
                  "individual_support_plan_non_creation_subtraction",
                  0,
                )
              }
            />
            <Button
              btnText={t("up to 2 months")}
              shape={"round"}
              disabled={
                row?.individual_support_plan_non_creation_subtraction == 1
              }
              onClick={() =>
                handleUserListChange(
                  row?.user_id,
                  "individual_support_plan_non_creation_subtraction",
                  1,
                )
              }
            />
            <Button
              btnText={t("After 3 months")}
              shape={"round"}
              disabled={
                row?.individual_support_plan_non_creation_subtraction == 2
              }
              onClick={() =>
                handleUserListChange(
                  row?.user_id,
                  "individual_support_plan_non_creation_subtraction",
                  2,
                )
              }
            />
          </IndividualSupportSubtractionContainer>
        )
      },
    },
    {
      key: "payment",
      align: "center",
      title: t("Payment Amount"),
      render: (row) => (row?.benefit_days || 0) + `日`,
    },
    {
      key: "burden_amount",
      align: "center",
      title: t("Burden upper limit monthly amount"),
      render: (row) => (row?.burden_amount || 0) + "円",
    },
    {
      key: "reference_link",
      align: "center",
      title: t("Reference Link"),
      render: (row) => (
        <>
          <Link
            href={`/additional-items-management/adult?facility_id=${
              searchParams.facility
            }&year_month=${
              searchParams.year +
              "-" +
              searchParams.month.toString().padStart(2, "0")
            }&user_id=${row?.user_id}`}
          >
            {t("Daily Addition")}
          </Link>
          &nbsp;
          <Link
            href={`/user-service-record/details/${row?.user_id}?year=${searchParams.year}&month=${searchParams.month}`}
          >
            {t("Service Provision Record Sheet")}
          </Link>
        </>
      ),
    },
    {
      key: "updated_by",
      align: "center",
      title: t("changer"),
      render: (row) => row?.updated_by,
    },
    {
      key: "update time",
      align: "center",
      title: t("update time"),
      render: (row) =>
        row?.update_date_time && dayjs(row?.update_date_time).isValid()
          ? dayjs(row?.update_date_time).format("YYYY年MM月DD日 HH:mm:ss")
          : "",
    },
  ]

  const printLinksSection1 = [
    {
      title: t("Bill for nursing care benefits, training benefits, etc."),
      subText: t("※Please print out when submitting on paper or when storing"),
      getLink: () =>
        `/national-health-insurance-billing/prints/disabled-user-outpatient-benefits?facilityIds=${
          searchParams.facility
        }&default_facility=${searchParams.facility}&year_month=${
          searchParams.year
        }-${searchParams.month.toString().padStart(2, "0")}`,
    },
    {
      title: t("Printing of service provision record sheet"),
      subText: t("※Please print out when submitting on paper or when storing"),
      getLink: () => {
        const userIds = userList
          ?.filter((user) => user?.selected)
          ?.map((user) => user?.user_id)
          ?.join(",")
        return userIds
          ? `/user-service-record/provision-result/bulk-print/${userIds}?year=${searchParams.year}&month=${searchParams.month}`
          : `/user-service-record/provision-result?year=${searchParams.year}month=${searchParams.month}`
      },
    },
    {
      title: t("Print summary list"),
      subText: t(
        "※Please print this out if you want to see a list of each user's amount billed to the National Health Insurance Federation.",
      ),
      getLink: () =>
        `/national-health-insurance-billing/prints/summary-list?year=${searchParams.year}&month=${searchParams.month}&facilityIds=${searchParams.facility}&default_facility=${searchParams.facility}`,
    },
    {
      title: t("Print User Billing List"),
      getLink: () =>
        `/national-health-insurance-billing/prints/user-billing-list?year=${searchParams.year}&month=${searchParams.month}&facilityIds=${searchParams.facility}&default_facility=${searchParams.facility}`,
    },
  ]

  const printLinksSection2 = [
    {
      title: t("Printing invoices to users"),
      subText: t("※Please print when issuing invoices to users."),
      getLink: () =>
        `/national-health-insurance-billing/prints/customer-invoice?facilityIds=${
          searchParams.facility
        }&default_facility=${searchParams.facility}&year_month=${
          searchParams.year
        }-${searchParams.month.toString().padStart(2, "0")}`,
    },
    {
      title: t("Print receipts for users"),
      subText: t("※Please print when issuing invoices to users."),
      getLink: () =>
        `/national-health-insurance-billing/prints/receipt-user?facilityIds=${searchParams.facility}&default_facility=${searchParams.facility}&year=${searchParams.year}&month=${searchParams.month}`,
    },
    {
      title: t("Print proxy acknowledgment"),
      subText: "",
      getLink: () =>
        `/national-health-insurance-billing/prints/print-proxy-acknowledgement?facilityIds=${searchParams?.facility}&year=${searchParams?.year}&month=${searchParams?.month}`,
    },
    {
      title: t("Print address to user"),
      subText: "",
      getLink: () =>
        `/national-health-insurance-billing/prints/address-to-users?facilityIds=${searchParams.facility}&default_facility=${searchParams.facility}&year=${searchParams.year}&month=${searchParams.month}`,
    },
  ]

  const { facilities, permissions, isOwner } = useContext(AuthContext)

  const router = useRouter()

  const {
    year: queryYear,
    month: queryMonth,
    facility: queryFacility,
  } = router?.query

  const getInitialSearchParams = (): NHIFSearchParams => {
    const date = dayjs()
    let year = date.year()
    let month = date.month() + 1
    let facility = facilities?.[0]?.id
    if (queryMonth && queryYear) {
      const queryDate = dayjs([
        +queryYear?.toString(),
        +queryMonth?.toString() - 1,
      ])
      if (queryDate.isValid()) {
        year = queryDate.year()
        month = queryDate.month() + 1
      }
    }
    if (queryFacility) {
      facility = +queryFacility?.toString()
    }
    return {
      year,
      month,
      facility,
    }
  }

  const [updateParams] = useUpdateSearchParams()

  const [searchParams, setSearchParams] = useState<NHIFSearchParams>(
    getInitialSearchParams(),
  )
  const [userList, setUserList] = useState([])
  const [currentBillingStep, setCurrentBillingStep] =
    useState<NHIFBillingStepValues>("INITIAL")
  const latestNHIFCalculationTriggerFor = useRef(null)
  const handleSearchParamsChange = (params: NHIFSearchParams) => {
    setSearchParams(params)
    updateParams(params, "/national-health-insurance-billing")
  }

  const handleReset = () => {
    handleSearchParamsChange({
      year: dayjs().year(),
      month: dayjs().month() + 1,
      facility: facilities?.[0]?.id,
    })
  }

  const handleCheck = (checkAll: boolean) => {
    const newDataSource = [...userList].map((user) => ({
      ...user,
      selected: checkAll,
    }))
    setUserList(newDataSource)
  }

  const handleUserListChange = (userId, key, value) => {
    setUserList((prev) => {
      return prev.map((row) => {
        if (row?.user_id == userId) {
          row[key] = value
        }
        return row
      })
    })
  }

  const handleBulkIndividualSupportSubtraction = (subtractionMonth: number) => {
    const newDataSource = [...userList].map((user) => {
      if (user?.selected) {
        user.individual_support_plan_non_creation_subtraction = subtractionMonth
      }
      return user
    })
    setUserList(newDataSource)
  }

  const {
    data: billingStep,
    isLoading: isBillingStepLoading,
    refetch: RefetchBillingSTepInfo,
  } = useQuery(
    ["facility-billing-step", searchParams],
    () =>
      getNHIFBillingSteps({
        year: searchParams.year,
        month: searchParams.month,
        facility: searchParams.facility,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        notification.error({
          message: t(
            e?.data?.error?.message ||
              "Something went wrong. Please contact administrator",
          ),
        })
      },
      select: (response) => response?.data,
      onSuccess: (data) => {
        if (!data?.current_step) {
          setCurrentBillingStep("INITIAL")
        } else {
          setCurrentBillingStep("CONFIRMED")
        }
      },
    },
  )

  const { data: nhifAlerts, isLoading: isAlertsLoading } = useQuery(
    ["nhif-alerts", searchParams],
    () =>
      getNHIFAlerts({
        year: searchParams.year,
        month: searchParams.month,
        facility: searchParams.facility,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        notification.error({
          message: t(
            e?.data?.error?.message ||
              "Something went wrong. Please contact administrator",
          ),
        })
      },
      select: (response) => {
        const { data } = response
        const alerts: NHIFAlertsType = {
          missing_attendance: Array.isArray(data?.missing_attendance)
            ? data?.missing_attendance
            : [],
          using_transportations: Array.isArray(data?.using_transportations)
            ? data?.using_transportations
            : [],
          has_system_status_setup: !!data?.has_system_status_setup,
          number_of_registered_subsidy_users:
            data?.number_of_registered_subsidy_users || 0,
          facility_contact_info_missing: Array.isArray(
            data?.facility_contact_info_missing,
          )
            ? data?.facility_contact_info_missing
            : [],
          users_with_expired_certificate: Array.isArray(
            data?.users_with_expired_certificate,
          )
            ? data?.users_with_expired_certificate
            : [],
          users_with_used_days_more_than_contracted_days: Array.isArray(
            data?.users_with_used_days_more_than_contracted_days,
          )
            ? data?.users_with_used_days_more_than_contracted_days
            : [],
          attendance_updated_after_billing_confirmation: Array.isArray(
            data?.attendance_updated_after_billing_confirmation,
          )
            ? data?.attendance_updated_after_billing_confirmation
            : [],
          facility_schedules_updated_after_billing_confirmation: Array.isArray(
            data?.facility_schedules_updated_after_billing_confirmation,
          )
            ? data?.facility_schedules_updated_after_billing_confirmation
            : [],
          certificate_updated_after_billing_confirmation: Array.isArray(
            data?.certificate_updated_after_billing_confirmation,
          )
            ? data?.certificate_updated_after_billing_confirmation
            : [],
        }
        return alerts
      },
    },
  )

  const { isLoading: isNHIFListLoading } = useQuery(
    ["nhif-user-list", searchParams],
    () =>
      getNHIFEligibleUserList({
        year: searchParams.year,
        month: searchParams.month,
        facility: searchParams.facility,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        notification.error({
          message: t(
            e?.data?.error?.message ||
              "Something went wrong. Please contact administrator",
          ),
        })
      },
      select: (response) => response?.data,
      onSuccess: (data) => setUserList(data),
    },
  )

  const { mutate: saveNHIFReceipt, isLoading: isSavingReceipt } = useMutation({
    mutationFn: calculateNHIFReceipt,
    onSuccess: (response) => {
      if (latestNHIFCalculationTriggerFor.current) {
        // download receipt
        const link = response?.data
        if (link) {
          const newLinkElement = document.createElement("a")
          newLinkElement.setAttribute("href", link)
          newLinkElement.setAttribute("target", "_blank")
          document.body.appendChild(newLinkElement)
          newLinkElement.click()
          newLinkElement.remove()
        } else {
          notification.error({ message: t("No CSV link received") })
        }
      }
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const handleReceiptCalculation = (CSVOption: 0 | 1 | 2) => {
    latestNHIFCalculationTriggerFor.current = CSVOption
    const selectedFacility = facilities.find(
      (fac) => fac?.id == searchParams.facility,
    )
    const receiptPayload: CalculateReceipt = {
      year: searchParams.year,
      month: searchParams.month,
      facility_id: searchParams.facility,
      service_type: selectedFacility?.facility_type,
      csv_for: CSVOption,
      user_ids: userList
        ?.filter((user) => user.selected)
        .map((user) => user?.user_id?.toString())
        ?.join(","),
    }
    saveNHIFReceipt(receiptPayload)
  }

  const { mutate: saveNHIFUserList, isLoading: isSavingNHIFUsers } =
    useMutation({
      mutationFn: saveNHIFEligibleUserList,
      onSuccess: () => {
        RefetchBillingSTepInfo()
        notification.success({
          message:
            t("National Health Insurance Billing Form/CSV Output") +
            " " +
            t("Updated Successfully"),
        })
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const handleConfirmBillingInfo = () => {
    const payload: ConfirmUserInfo = {
      facility_id: +searchParams.facility,
      year: searchParams.year,
      month: searchParams.month,
      users: userList.map((user) => ({
        user_id: user?.user_id,
        individual_support_plan_non_creation_subtraction:
          user?.individual_support_plan_non_creation_subtraction,
        is_delete: !user?.selected,
      })),
    }
    saveNHIFUserList(payload)
  }

  const renderOutputCount = () => {
    const selectedUsers = userList.filter((user) => user.selected)
    const selectedCount = selectedUsers.length
    const serviceTypeA = selectedUsers.filter((user) => user?.service == 1)
    const serviceTypeB = selectedUsers.filter((user) => user?.service == 2)
    const serviceTypeC = selectedUsers.filter((user) => user?.service == 3)
    return (
      <>
        {"出力人数利用者就"}&nbsp;&nbsp;
        {serviceTypeA?.length > 0 && (
          <>
            {"繼統支援A型  "}&nbsp;&nbsp;
            <strong>{serviceTypeA?.length}</strong>
            {"人"}
          </>
        )}
        {serviceTypeB?.length > 0 && (
          <>
            {"繼統支援B型  "}&nbsp;&nbsp;
            <strong>{serviceTypeB?.length}</strong>
            {"人"}
          </>
        )}
        {serviceTypeC?.length > 0 && (
          <>
            {"繼統支援A型  "}&nbsp;&nbsp;
            <strong>{serviceTypeC?.length}</strong>
            {"人"}
          </>
        )}
        {"合計"}
        <strong>{selectedCount}</strong>
        {"人"}
      </>
    )
  }

  const isAtleastAChildSelected = useMemo(() => {
    return !!userList?.filter((user) => user?.selected)?.length
  }, [userList])

  const hasUnhandledAlerts = useMemo(() => {
    if (!nhifAlerts) return false
    if (!nhifAlerts.has_system_status_setup) return true

    return false
  }, [nhifAlerts, userList])

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        BillingCSVPermission,
        "write",
        searchParams.facility,
      ),
    [permissions, isOwner, searchParams],
  )

  return (
    <>
      <NHIFOperation
        searchOptions={searchParams}
        facilityOptions={facilities}
        handleSearch={handleSearchParamsChange}
        handleReset={handleReset}
      />
      <Card
        title={t(
          "{{year}}/{{month}} National Health Insurance Federation claim form/CSV output",
          {
            year: searchParams.year,
            month: searchParams.month.toString().padStart(2, "0"),
          },
        )}
        extra={
          <MonthPagination
            currentYear={searchParams.year}
            currentMonth={searchParams.month - 1}
            onMonthChange={(_, __, date) => {
              handleSearchParamsChange({
                ...searchParams,
                year: date.year(),
                month: date.month() + 1,
              })
            }}
            showDate={false}
            currentText={t("This month")}
          />
        }
      >
        {isBillingStepLoading || isNHIFListLoading || isAlertsLoading ? (
          <NHIFSpinContainer>
            <Spin size={"large"} />
          </NHIFSpinContainer>
        ) : (
          <>
            <NHIFAlerts
              nhifAlerts={nhifAlerts}
              facilityId={searchParams.facility}
              year={searchParams.year}
              month={searchParams.month}
            />
            <NHIFScrollSection>
              <div className={"bd-rt"}>
                <Link href={"#users-to-output"}>
                  {t("Select user to output")}
                </Link>
              </div>
              <div className={"bd-rt"}>
                <Link href={"#nhif-submit"}>
                  {t(
                    "Output of information to be submitted to the National Health Insurance Federation",
                  )}
                </Link>
              </div>
              <div>
                <Link href={"#printing"}>
                  {t("Other forms printing, etc.")}
                </Link>
              </div>
            </NHIFScrollSection>
            <NHIFNumberedSection
              number={1}
              title={t("Select user to output")}
              disabled={currentBillingStep != "INITIAL"}
            >
              <NHIFTableContainer>
                <div className={"top-table-contents"} id={"users-to-output"}>
                  <div className={"action-buttons"}>
                    <Button
                      btnText={t("Check all")}
                      shape={"round"}
                      iconType={"check-all"}
                      className={"small-button"}
                      borderColor={theme.colors.border}
                      onClick={() => handleCheck(true)}
                    />
                    <Button
                      btnText={t(
                        "Check only users whose beneficiary card is within the expiration date",
                      )}
                      shape={"round"}
                      iconType={"check-all"}
                      className={"small-button"}
                      borderColor={theme.colors.border}
                      onClick={() => handleCheck(true)}
                    />
                    <Button
                      btnText={t("Uncheck All")}
                      shape={"round"}
                      className={"small-button bg-border"}
                      borderColor={theme.colors.border}
                      onClick={() => handleCheck(false)}
                    />
                  </div>
                  <div className={"individual-support-actions"}>
                    <div>
                      {t(
                        "Change of all individual support plan uncreated subtraction",
                      )}
                    </div>
                    <div className={"individual-support-action-buttons"}>
                      <Button
                        btnText={t("Do not")}
                        shape={"round"}
                        onClick={() =>
                          handleBulkIndividualSupportSubtraction(0)
                        }
                      />
                      <Button
                        btnText={t("up to 2 months")}
                        shape={"round"}
                        onClick={() =>
                          handleBulkIndividualSupportSubtraction(1)
                        }
                      />
                      <Button
                        btnText={t("After 3 months")}
                        shape={"round"}
                        onClick={() =>
                          handleBulkIndividualSupportSubtraction(2)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={"user-list-table"}>
                  <Table columns={NHIFUserTableColumn} dataSource={userList} />
                </div>
                {currentBillingStep != "INITIAL" && (
                  <div className={"output-count"}>{renderOutputCount()}</div>
                )}
              </NHIFTableContainer>
            </NHIFNumberedSection>
            {currentBillingStep == "INITIAL" ? (
              <NHIFConfirmBillingContainer>
                <div className={"alert-text"}>
                  {t(
                    "Confirm the billing information in order to create a list of user fees",
                  )}
                </div>
                <Button
                  btnText={t("Confirm billing info")}
                  iconType={"print"}
                  type={"primary"}
                  shape={"round"}
                  isLoading={isSavingNHIFUsers}
                  onClick={handleConfirmBillingInfo}
                  disabled={
                    !hasEditAccess ||
                    !isAtleastAChildSelected ||
                    hasUnhandledAlerts
                  }
                />
              </NHIFConfirmBillingContainer>
            ) : (
              <NHIFConfirmedBillingContainer>
                <Button
                  btnText={t("Edit confirmed billing information")}
                  iconType={"edit"}
                  shape={"round"}
                  onClick={() => setCurrentBillingStep("INITIAL")}
                  disabled={!hasEditAccess}
                />
                <div>{`最終更新：${billingStep?.updated_by} ${
                  billingStep?.updated_datetime &&
                  dayjs(billingStep?.updated_datetime).isValid()
                    ? dayjs(billingStep?.updated_datetime).format(
                        "YYYY年MM月DD日 HH:mm:ss",
                      )
                    : "-"
                }`}</div>
              </NHIFConfirmedBillingContainer>
            )}

            <NHIFNumberedSection
              number={2}
              title={t(
                'Confirm the amount to be borne by the user (Please register the amount to be claimed for the local government subsidy and press "Confirm the amount to be borne by the user")',
              )}
              disabled={currentBillingStep == "INITIAL"}
            >
              <NHIFSection1>
                <Button
                  btnText={t(
                    "Municipal sub-component billing amount information management",
                  )}
                  shape={"round"}
                  className={"municipal-component-button"}
                  onClick={() => {
                    router.push(
                      `/list-of-municipal-subsidy-claims-information-management/add?year=${searchParams.year}&month=${searchParams.month}`,
                    )
                  }}
                />
                <div className={"alert-text"}>
                  {t(
                    "※ If you need to register the billed amount of the municipal subsidy, please register here",
                  )}
                </div>
                <div className={"maximum-amount-people-count"}>
                  <Trans key={"registeredUsersMaxAmount"}>
                    {t(
                      "Number of registered users in local government subsidy claim amount information management",
                    )}
                    {": "}
                    <strong style={{ fontSize: "large" }}>
                      {nhifAlerts?.number_of_registered_subsidy_users.toString()}
                    </strong>
                    &nbsp;
                    {t("people")}
                  </Trans>
                </div>
              </NHIFSection1>
            </NHIFNumberedSection>
            <NHIFNumberedSection
              number={3}
              title={t(
                "Output the information to be submitted to the National Health Insurance Federation((Please submit it to the National Health Insurance Federation by the 10th))",
              )}
              id={"nhif-submit"}
              disabled={currentBillingStep == "INITIAL"}
            >
              <NHIFSection2>
                <div className={"alert-text"}>
                  {t(
                    "※Please do not open the submitted data. Also, do not change the file name.",
                  )}
                </div>
                <Button
                  btnText={t("CSV output of invoice/statement")}
                  iconType={"csv"}
                  shape={"round"}
                  className={"csv-button"}
                  onClick={() => handleReceiptCalculation(CSV_OPTIONS.INVOICE)}
                  isLoading={
                    isSavingReceipt &&
                    latestNHIFCalculationTriggerFor.current ==
                      CSV_OPTIONS.INVOICE
                  }
                  disabled={isSavingReceipt}
                />
                <Button
                  btnText={t("CSV output of service provision record sheet")}
                  iconType={"csv"}
                  shape={"round"}
                  className={"csv-button"}
                  onClick={() =>
                    handleReceiptCalculation(CSV_OPTIONS.SERVICE_PROVISION)
                  }
                  isLoading={
                    isSavingReceipt &&
                    latestNHIFCalculationTriggerFor.current ==
                      CSV_OPTIONS.SERVICE_PROVISION
                  }
                  disabled={isSavingReceipt}
                />
                <div className={"alert-text"}>
                  {t(
                    "※Submission to the National Health Insurance Federation is required.",
                  )}
                </div>
              </NHIFSection2>
            </NHIFNumberedSection>
            <NHIFLinkSection id={"printing"}>
              <div
                className={
                  currentBillingStep == "INITIAL"
                    ? "disable-overlay"
                    : "non-displayed-disable-overlay"
                }
              ></div>
              <div className={"section1 bd-rt"}>
                <ul>
                  {printLinksSection1.map((print, index) => (
                    <li key={`print1${index}`}>
                      <Link href={print.getLink()}>
                        <a target={"_blank"}>{print.title}</a>
                      </Link>
                      {print?.subText && (
                        <div className={"subtext"}>{print?.subText}</div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={"section2"}>
                <ul>
                  {printLinksSection2.map((print, index) => {
                    return (
                      <li key={`print2${index}`}>
                        <Link href={print.getLink()}>
                          <a target={"_blank"}>{print.title}</a>
                        </Link>
                        {print?.subText && (
                          <div className={"subtext"}>{print?.subText}</div>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </NHIFLinkSection>
          </>
        )}
      </Card>
    </>
  )
}
