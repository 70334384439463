import { theme } from "@project/common"
import styled from "styled-components"

export const MinutesOfMeetingOfPicStyledForm = styled.form`
  .setting_information {
    border-bottom: 1px solid #000;
    width: 100%;
  }

  .add_form {
    box-shadow: none;
    border: none !important;
    margin-top: 28px;
  }

  .car_number {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .inspection_day {
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
      color: #e00000;
      font-size: 13px;
    }
  }

  #provision__zip_code__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.colors.link};
      text-decoration: underline;
    }
  }
  .absence_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    .absence_content {
      display: flex;
      max-width: 763px;
      min-height: 52px;
      padding: 16px 20px;
      gap: 10px;
      background-color: #f3f3f3;
    }
  }
  .start_date,
  .created_date {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 1em;
    .ant-picker {
      width: 163px !important;
    }
  }
  .create_count {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    .alert {
      color: red;
      width: 100%;
    }
    span {
      width: 100px;
    }
    .content {
      display: flex;
      gap: 8px;
      align-items: center;
      max-width: 240px;
    }
  }
  .upload {
    .file-area {
      .ant-btn {
        width: auto !important;
      }
    }
  }
`
export const StartTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints.xxs}) {
    flex-direction: column;
  }
  .start_time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`
export const StyleFieldArray = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 16px;

  .input_container {
    display: flex;
    row-gap: 16px;
    column-gap: 16px;
    input {
      width: 100%;
    }
    .btn_content {
      min-width: 70px;
      position: relative;
      margin-top: 5px;
      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          padding: 4px 0px !important;
        }
        .ant-btn:hover {
          background: transparent;
          color: ${theme.colors.error};
          width: 100%;
        }
      }
    }
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0px;
  flex-wrap: wrap;
`
