import { InputField, SelectInput, Table } from "@project/common"
import { ColumnsType } from "antd/lib/table"
import { useTranslation } from "react-i18next"
import { ACHIEVEMENT_GOAL_TYPE, EVALUATION_TYPES } from "../../../constants"
import { ItemsContainer } from "./MonitoringForm.style"

export const MonitoringFormGoalsTable = ({
  user_monitoring_content,
  handleFieldChange,
}: {
  user_monitoring_content: any[]
  handleFieldChange: ({
    fieldName,
    value,
    id,
  }: {
    fieldName: string
    value: any
    id: number
  }) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const columns: ColumnsType<any[]> = [
    {
      title: t(" "),
      key: "edit",
      align: "center",
      render: (_, __, i) => i + 1,
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Items (personal needs, etc.)")}
        </span>
      ),
      key: "items",
      align: "center",
      width: 231,
      render: (data) => {
        return <p>{data?.title || ""}</p>
      },
    },
    {
      title: t("Specific goals"),
      key: "specific_goal",
      align: "center",
      width: 231,
      render: (row) => {
        const goals = Array.isArray(row?.achievement_goals)
          ? row?.achievement_goals
          : typeof JSON.parse(row?.achievement_goals) === "string"
            ? JSON.parse(JSON.parse(row?.achievement_goals))
            : JSON.parse(row?.achievement_goals)
        return (
          <ul style={{ margin: 0, padding: 0, textAlign: "left" }}>
            {goals?.map((goal, index) => <li key={index}>{goal}</li>)}
          </ul>
        )
      },
    },
    {
      title: t("Goal achievement"),
      key: "achievement_goal",
      align: "center",
      render: (data) => {
        return (
          <>
            <SelectInput
              name={"achievement"}
              height={"40px"}
              placeholder={"--"}
              options={ACHIEVEMENT_GOAL_TYPE}
              value={data?.achievement_goal_type}
              style={{
                textAlign: "left",
              }}
              onChange={(value) => {
                handleFieldChange({
                  fieldName: "achievement_goal_type",
                  value,
                  id: data?.id,
                })
              }}
            />
            {data?.achievement_goal_type === 4 && (
              <InputField
                style={{
                  marginTop: "5px",
                }}
                height={"40px"}
                value={data?.achievement_goal_type_text}
                onChange={(e) => {
                  handleFieldChange({
                    fieldName: "achievement_goal_type_text",
                    value: e.target.value,
                    id: data?.id,
                  })
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: t("Evaluation"),
      key: "evaluation",
      align: "center",
      render: (data) => {
        return (
          <>
            <SelectInput
              name={"evaluation"}
              placeholder={"--"}
              style={{
                textAlign: "left",
              }}
              value={data?.evaluation_type}
              options={EVALUATION_TYPES}
              onChange={(value) => {
                handleFieldChange({
                  fieldName: "evaluation_type",
                  value,
                  id: data?.id,
                })
              }}
            />
            {data?.evaluation_type === 4 && (
              <InputField
                placeholder={"--"}
                style={{
                  marginTop: "5px",
                }}
                height={"40px"}
                value={data?.evaluation_type_text}
                onChange={(e) => {
                  handleFieldChange({
                    fieldName: "evaluation_type_text",
                    value: e.target.value,
                    id: data?.id,
                  })
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: t("Consideration"),
      key: "consideration",
      align: "center",
      render: (row) => {
        const study = Array.isArray(row?.study)
          ? row?.study
          : JSON.parse(row?.study)
        return (
          <div>
            {study?.map((stdy, index) => (
              <InputField
                key={index}
                height={"40px"}
                placeholder={"--"}
                value={stdy}
                readOnly
              />
            ))}
          </div>
        )
      },
    },
  ]
  return (
    <ItemsContainer>
      <Table
        columns={columns}
        noHeaderBorder
        headerCellPaddingBlock={"14px"}
        cellPaddingBlock={15}
        dataSource={user_monitoring_content || []}
        scroll={{ x: 900 }}
      />
    </ItemsContainer>
  )
}
