import {
  AccordionCard,
  ActionBtns,
  Button,
  Card,
  CheckBoxGroup,
  DatePicker,
  LabelTextWithLeftArrow,
  PRINT_CONFIGURATION_OPTIONS,
  PRINT_STYLE_OPTIONS,
  SelectInput,
  USER_RECEIPT_DISPLAY_SETTINGS_FOR_STANDARD,
  USER_RECEIPT_DISPLAY_SETTINGS_FOR_BREAKDOWN_USAGE,
  removeBlankAttributes,
  scrollToSelectedElement,
  PRINT_STYLE_VALUES,
  PRINT_CONFIGURATION_VALUES,
  SERVICE_TYPE_VALUES,
} from "@project/common"
import { Spin } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { useQuery } from "react-query"
import { getPhoneticOrder } from "../../../../services/NHIFReceiptUser"
import {
  Container,
  Content,
  MiniContainer,
  Wrapper,
  DataListWrapper,
} from "./PrintReceiptUser.styles"
import StandardUserReceipt from "./PrintStyleTemplates/standard"
import PrintWithBreakdown from "./PrintStyleTemplates/breakdown"
import wareki from "wareki"
import PrintWithUsageDetails from "./PrintStyleTemplates/usage_detail"

export const PrintReceiptUser = ({ printValues, FACILITIES_LABEL }) => {
  const { t } = useTranslation()
  const router = useRouter() as any
  const { year, month, facilityIds, selected } = router?.query
  const [printData, setPrintData] = useState(printValues || [])
  const printContentRef = useRef<HTMLDivElement>()
  const currentDay = dayjs().date()
  const [params, setParams] = useState<any>({
    date: dayjs(`${year}-${month}-${currentDay}`).format("YYYY-MM-DD"),
    print_style: PRINT_STYLE_VALUES.STANDARD,
    print_configuration: PRINT_CONFIGURATION_VALUES.ONE_PER_SHEET,
    display_setting: [],
  })

  const [values, setValues] = useState(params)
  const handlePrint = useReactToPrint({
    content: () => printContentRef.current,
    pageStyle: `@media print{
      margin:10mm;
    }`,
  })

  const routeBack = () => {
    router.push({
      pathname: `/national-health-insurance-billing/prints/receipt-user`,
      query: removeBlankAttributes({
        selected: selected,
        facilityIds: facilityIds,
        year: year,
        month: month,
        mode: "all",
      }),
    })
  }

  useEffect(() => {
    if (params.display_setting.includes("1")) {
      const interleavedData = []
      printValues.forEach((item) => {
        interleavedData.push(item)
        interleavedData.push({ ...item, copy: true })
      })
      setPrintData(interleavedData)
    } else if (
      !params.display_setting.includes("2") ||
      params.display_setting.includes("1")
    ) {
      setPrintData(printValues)
    }
    if (params.display_setting.includes("2")) {
      setPrintData(printValues)
    }
  }, [params.display_setting])

  useEffect(() => {
    setPrintData(printValues)
  }, [printValues])

  const { data: provisionTexts, isLoading } = useQuery({
    queryKey: ["getPostTemporary"],
    queryFn: () => getPhoneticOrder({ year, month, phonetic_order: 9 }),
    select: (res) => {
      return {
        type_a: res?.msg?.type_a || "",
        type_b: res?.msg?.type_b || "",
        transition: res?.msg?.type_c || "",
      }
    },
    refetchOnWindowFocus: false,
  })

  const USER_RECEIPT_DISPLAY_SETTINGS = useMemo(() => {
    if (values?.print_style == PRINT_STYLE_VALUES.STANDARD)
      return USER_RECEIPT_DISPLAY_SETTINGS_FOR_STANDARD
    if (
      [PRINT_STYLE_VALUES.BREAKDOWN, PRINT_STYLE_VALUES.USAGE_DETAILS].includes(
        values?.print_style,
      )
    )
      return USER_RECEIPT_DISPLAY_SETTINGS_FOR_BREAKDOWN_USAGE
    return []
  }, [values?.print_style])

  const era = useMemo(() => {
    return wareki(params?.date, {
      unit: true,
    })
  }, [values.date])

  const renderStandardReceipt = ({ data, index, provisionText }) => (
    <StandardUserReceipt
      data={data}
      key={index}
      params={params}
      month={month}
      era={era}
      provisionText={provisionText}
    />
  )

  const renderBreakdownReceipt = ({ data, provisionText }) => (
    <PrintWithBreakdown
      params={params}
      data={data}
      era={era}
      provisionText={provisionText}
      isCopy={!!data?.copy}
    />
  )

  const renderUsageDetailsReceipt = ({ data, provisionText }) => (
    <PrintWithUsageDetails
      params={params}
      data={data}
      era={era}
      provisionText={provisionText}
      isCopy={!!data?.copy}
    />
  )

  const renderReceiptBasedOnStyle = () => {
    let receiptStyle = renderStandardReceipt
    switch (params.print_style) {
      case PRINT_STYLE_VALUES.BREAKDOWN:
        receiptStyle = renderBreakdownReceipt
        break
      case PRINT_STYLE_VALUES.USAGE_DETAILS:
        receiptStyle = renderUsageDetailsReceipt
        break
    }

    return printData?.map((data, index) => {
      let provisionText = ""
      switch (+data?.service_type) {
        case SERVICE_TYPE_VALUES.TYPE_1:
          provisionText = provisionTexts?.type_a
          break
        case SERVICE_TYPE_VALUES.TYPE_2:
          provisionText = provisionTexts?.type_b
          break
        case SERVICE_TYPE_VALUES.TRANSITION:
          provisionText = provisionTexts?.transition
          break
      }
      return receiptStyle({ data, index, provisionText })
    })
  }

  return (
    <Wrapper>
      <AccordionCard
        defaultActiveKey={["receipt users"]}
        padding={"6px 16px"}
        items={[
          {
            key: "receipt users",
            label: t("Operation Options"),
            children: (
              <Container>
                <MiniContainer>
                  <LabelTextWithLeftArrow label={t("Receipt date")} />
                  <DatePicker
                    date={
                      dayjs(values?.date).isValid() ? dayjs(values?.date) : null
                    }
                    onDateChange={(date) => {
                      setValues({
                        ...values,
                        date: dayjs(date).format("YYYY-MM-DD"),
                      })
                    }}
                    format={"YYYY年MM月DD日"}
                  />
                </MiniContainer>
                <MiniContainer>
                  <LabelTextWithLeftArrow label={t("Select print style")} />
                  <SelectInput
                    name={"print_style"}
                    placeholder={"--"}
                    options={PRINT_STYLE_OPTIONS}
                    value={values?.print_style}
                    onChange={(val) =>
                      setValues({ ...values, print_style: val })
                    }
                    width={"310px"}
                  />
                </MiniContainer>
                <MiniContainer>
                  <LabelTextWithLeftArrow
                    label={t("Print configuration")}
                    className={"print-configuration"}
                  />
                  <div className={"select-title"}>
                    <SelectInput
                      name={"print_configuration"}
                      placeholder={"--"}
                      options={PRINT_CONFIGURATION_OPTIONS}
                      value={values?.print_configuration}
                      onChange={(val) =>
                        setValues({ ...values, print_configuration: val })
                      }
                      width={"310px"}
                    />
                    <div>
                      {t(
                        `*Up to 3 people can fit on one sheet (A4) if "Display settings" is not checked on forms other than those with usage details. If the displayed content does not fit on the page, a page break may occur.`,
                      )}
                    </div>
                  </div>
                </MiniContainer>
                <MiniContainer>
                  <LabelTextWithLeftArrow
                    label={t("Display Setting")}
                    className={"display-setting"}
                  />
                  <CheckBoxGroup
                    name={"display_setting"}
                    items={USER_RECEIPT_DISPLAY_SETTINGS}
                    value={values?.display_setting}
                    onChange={(list: CheckboxValueType[]) => {
                      setValues({
                        ...values,
                        display_setting: list,
                      })
                    }}
                  />
                </MiniContainer>

                <ActionBtns>
                  <div>
                    <Button
                      btnText={t("Reflect")}
                      size={"large"}
                      shape={"round"}
                      btnBg={"#F6F7F8"}
                      onClick={() => {
                        setParams(values)
                        scrollToSelectedElement(printContentRef)
                      }}
                    />
                  </div>
                </ActionBtns>
              </Container>
            ),
          },
        ]}
      />
      <div className={"btn-wrapper"}>
        <Button
          btnText={t("Cancel")}
          size={"large"}
          shape={"round"}
          btnBg={"#F6F7F8"}
          onClick={() => routeBack()}
        />
        <Button
          btnText={t("Print")}
          type={"primary"}
          size={"large"}
          shape={"round"}
          iconType={"print"}
          onClick={handlePrint}
        />
      </div>
      <DataListWrapper>
        <div className={"content"}>
          <div className={"img-title"}>
            <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
            <h2 className={"title"}>
              {t(
                "The billed amounts for the following facilities are totaled and output below.",
              )}
            </h2>
          </div>
          <div className={"sub-title"}>
            {FACILITIES_LABEL?.map((item, i) => (
              <span key={i} style={{ marginRight: "5px" }}>
                {t(item)}
              </span>
            ))}
          </div>
        </div>
      </DataListWrapper>
      <Content ref={printContentRef} id={params?.print_configuration}>
        <Spin spinning={isLoading}>
          <Card>{renderReceiptBasedOnStyle()}</Card>
        </Spin>
      </Content>
    </Wrapper>
  )
}
