import styled from "styled-components"
import { Box, theme } from "@project/common"

export const CostStatementTableWrapper = styled(Box)`
  .ant-table-container .ant-table-content table tbody {
    tr.ant-table-row.category_row {
      td.ant-table-cell {
        background-color: ${theme.colors.gray12};
        font-weight: 500 !important;
      }
    }
  }
`

export const FacilityWorkTableWrapper = styled(Box)`
  .ant-table-container .ant-table-content table thead {
    th.ant-table-cell {
      padding: 8px 10px;
    }
  }
  .ant-table-container .ant-table-content table tbody {
    tr.ant-table-row.category_row {
      td.ant-table-cell {
        background-color: ${theme.colors.gray12};
        font-weight: 500 !important;
        &.add-columns-col {
          background-color: white;
          max-width: 36px;
          padding: 8px 10px;
        }
        &.total-cell {
          min-width: 232px;
        }
      }
    }
  }
`
