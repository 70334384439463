import React from "react"
import { useTranslation } from "react-i18next"

const PrimaryCareTable = ({ response }) => {
  const { t } = useTranslation()
  return (
    <>
      <table>
        <tr className={"seventh-table"}>
          <td className={"label"}>{t("Hospital name")}</td>
          <td className={"value"}>{response?.hospital_name || "-"}</td>
        </tr>
        <tr className={"seventh-table"}>
          <td className={"label"}>{t("Doctor name")}</td>
          <td className={"value"}>{response?.doctor_name || "-"}</td>
        </tr>
        <tr className={"seventh-table"}>
          <td className={"label"}>{t("Hospital contact")}</td>
          <td className={"value"}>{response?.hospital_ph || "-"}</td>
        </tr>
      </table>
    </>
  )
}

export default PrimaryCareTable
