import { Button, Grid, InputAreaField, useNotification } from "@project/common"
import { Divider } from "antd"
import dayjs from "dayjs"
import { useFormik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import {
  getTemporaryData,
  postTemporaryData,
} from "../../../../services/NHIFReceiptUser"
import { useMutation, useQuery } from "react-query"
import { AuthContext } from "../../../../context"
import { ServiceTypeWrapper } from "./PostTemporaryForm.styles"

const PostTemporaryForm = () => {
  const { t } = useTranslation()
  const { queryClient } = useContext(AuthContext)
  const { showToast } = useNotification()
  const router = useRouter()
  const query = router.query as any

  const { data: getPostTemporary } = useQuery({
    queryKey: ["getPostTemporary"],
    queryFn: () => getTemporaryData({ year: query?.year, month: query?.month }),
    select: (res) => {
      return res?.msg
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        formik.setValues({
          year: formik.values?.year,
          month: formik.values?.month,
          service_typeA: data?.type_a || null,
          service_typeB: data?.type_b || null,
          service_typeC: data?.type_c || null,
        })
      }
    },
  })

  const formik = useFormik({
    initialValues: {
      year: query?.year || dayjs().year(),
      month: query?.month || dayjs().month() + 1,
      service_typeA: getPostTemporary?.type_a || null,
      service_typeB: getPostTemporary?.type_b || null,
      service_typeC: getPostTemporary?.type_c || null,
    },

    onSubmit: (values) => {
      mutate(values)
    },
  })

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: postTemporaryData,
    onSuccess: () => {
      formik.resetForm()
      queryClient.invalidateQueries({
        queryKey: ["postTemporaryData"],
      })
      showToast({
        type: "success",
        message: t("Created Successfully"),
      })
    },
    onError: (error?: any) => {
      const msg =
        error?.data?.error?.message ||
        t("Something went wrong. Please contact administrator")
      showToast({
        type: "error",
        message: msg,
      })
    },
  })

  return (
    <ServiceTypeWrapper>
      <div className={"content"}>
        <div className={"title"}>
          {t(
            "If you wish to include a provison on the receipt to the user, please enter it below.",
          )}
        </div>
        <div className={"form"}>
          <Grid labelContent={t("Employment Type A")} labelSpan={6} background>
            <InputAreaField
              name={"service_typeA"}
              height={"70px"}
              placeholder={""}
              value={formik.values?.service_typeA}
              onChange={(e) =>
                formik.setFieldValue("service_typeA", e.target.value)
              }
            />
          </Grid>
          <Grid labelContent={t("Employment Type B")} labelSpan={6} background>
            <InputAreaField
              name={"service_typeB"}
              height={"70px"}
              placeholder={""}
              value={formik.values?.service_typeB}
              onChange={(e) =>
                formik.setFieldValue("service_typeB", e.target.value)
              }
            />
          </Grid>
          <Grid
            labelContent={t("Employment Transition Support")}
            labelSpan={6}
            background
          >
            <InputAreaField
              name={"service_typeC"}
              height={"70px"}
              placeholder={""}
              value={formik.values?.service_typeC}
              onChange={(e) =>
                formik.setFieldValue("service_typeC", e.target.value)
              }
            />
          </Grid>
        </div>
        <div className={"button"}>
          <Button
            shape={"round"}
            type={"default"}
            btnBg={"#EAF9FF"}
            btnText={t("Save Temporarily")}
            onClick={formik.handleSubmit}
            isLoading={isMutating}
            disabled={isMutating}
          />
        </div>
      </div>
      <Divider />
    </ServiceTypeWrapper>
  )
}

export default PostTemporaryForm
