import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../context/AuthContext"
import { FuriganaAlphabetsOption } from "../../utils/common-options"
import { SCHEDULE_INITIAL_OPERATION_PARAM } from "../../types/AttendanceSchedule.types"
import dayjs from "dayjs"
import { MONTH_DATE_FORMAT } from "../../constants"

export const AttendanceScheduleOperation = ({
  params,
  setSearchParams,
  staffData,
}: {
  params: SCHEDULE_INITIAL_OPERATION_PARAM
  setSearchParams: React.Dispatch<
    React.SetStateAction<SCHEDULE_INITIAL_OPERATION_PARAM>
  >
  staffData?: any[]
}): JSX.Element => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useAuthContext()
  const [values, setValues] = useState({
    ...params,
    staff_hiragana: "",
  })
  useEffect(() => {
    setValues({
      ...params,
      staff_hiragana: "",
    })
  }, [params])

  const getStaffAccordingToFacility = () => {
    const newStaff = staffData?.filter(
      (data) =>
        data?.staff_facility?.some(
          (v) => v?.facility_id === +values?.facility_id,
        ),
    )
    if (values?.staff_hiragana) {
      return newStaff?.filter(
        (staff) =>
          staff?.staff_name_furiganaame.startsWith(values?.staff_hiragana),
      )
    }
    return newStaff
  }
  return (
    <AccordionCard
      defaultActiveKey={["monthly_schedule_operation"]}
      items={[
        {
          key: "monthly_schedule_operation",
          label: t("Operation Options"),

          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"138px"} />
                <SelectInput
                  filterSort={() => null}
                  name={"operation_options"}
                  options={facilities || []}
                  placeholder={"---"}
                  width={"360px"}
                  value={values?.facility_id || null}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      facility_id: val,
                      staff_id: null,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year month")}
                  width={"138px"}
                />
                <DatePicker
                  picker={"month"}
                  date={
                    dayjs(values?.date).isValid() ? dayjs(values?.date) : null
                  }
                  onDateChange={(date) =>
                    setValues({
                      ...values,
                      date: date,
                    })
                  }
                  format={MONTH_DATE_FORMAT}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Staff")} width={"138px"} />
                <div className={"flex"}>
                  <SelectInput
                    width={"85px"}
                    placeholder={"--"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    name={"staff_hiragana"}
                    value={values?.staff_hiragana || null}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        staff_hiragana: val,
                        staff_id: null,
                      })
                    }}
                  />
                  <SelectInput
                    name={"username"}
                    width={"282px"}
                    placeholder={"--"}
                    options={getStaffAccordingToFacility()}
                    value={values?.staff_id ? +values?.staff_id : null}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        staff_id: val,
                      })
                    }
                  />
                </div>
              </div>
              {values?.staff_id && (
                <div className={"search__content"}>
                  <Button
                    btnText={t("Display Change")}
                    shape={"round"}
                    onClick={() => {
                      const month = dayjs(values?.date).month() + 1
                      const year = dayjs(values?.date).year()
                      updateParams(
                        {
                          year,
                          month,
                          facility_id: values?.facility_id,
                          staff_id: values?.staff_id || "",
                        },
                        "/attendance-schedule/monthly-schedule",
                      )
                      setSearchParams(values)
                    }}
                  />
                </div>
              )}
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
