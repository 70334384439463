import { Button, Table, useUpdateSearchParams } from "@project/common"
import { Flex } from "antd"
import { TableProps } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { FULL_DATE_FORMAT, PAGE_SIZE } from "../../../constants"
import { useAuthContext } from "../../../context"
import { getUserAttendanceManagementList } from "../../../services"
import {
  UserAttendanceManagementDataTypes,
  UserAttendanceManagementMonthlyApprovalListDataType,
} from "../../../types"

export const MonthlyApprovalsTab = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useAuthContext()
  const { panel, date, user_id, facility_id, page } = router?.query as any
  const currentDate = date ? dayjs(date) : dayjs()
  const facility = facility_id || facilities[0]?.value
  const { data: response, isLoading } = useQuery<
    UserAttendanceManagementMonthlyApprovalListDataType,
    Error
  >({
    queryKey: [
      "user-attendance-management-monthly-approvals",
      panel,
      date,
      user_id,
      facility_id,
      page,
    ],
    queryFn: () =>
      getUserAttendanceManagementList({
        page,
        pageSize: PAGE_SIZE,
        year: dayjs(currentDate).year(),
        month: dayjs(currentDate).month() + 1,
        user_id,
        facility,
      }),
    refetchOnWindowFocus: false,
  })
  const columns: TableProps<UserAttendanceManagementDataTypes>["columns"] = [
    {
      key: "username",
      dataIndex: "username",
      title: t("Username "),
      align: "center",
    },
    {
      key: "attendance_month",
      dataIndex: "attendance_month",
      title: t("Attendance Month"),
      align: "center",
      render: () => {
        const startDate = dayjs(currentDate).startOf("month")
        const endDate = dayjs(currentDate).endOf("month")

        return startDate && endDate
          ? `${dayjs(startDate).format(FULL_DATE_FORMAT)}-${dayjs(
              endDate,
            ).format(FULL_DATE_FORMAT)}`
          : "-"
      },
    },
    {
      key: "view_detail",
      title: t("View Detail "),
      align: "center",
      width: 227,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("View_Detail")}
            shape={"round"}
            style={{ whiteSpace: "nowrap" }}
            onClick={() =>
              router?.push(
                `/user-attendance-management/monthly-detail/${row?.user_id}?user_name=${row?.username}&date=${dayjs(
                  currentDate,
                ).format("YYYY-MM")}&facility_id=${facility}`,
              )
            }
          />
        </Flex>
      ),
    },
  ]
  return (
    <div>
      <Table
        columns={columns}
        dataSource={response?.data || []}
        showPaginationOf={"both"}
        total={response?.count || 0}
        loading={isLoading}
        current={+page || 1}
        pageSize={PAGE_SIZE}
        onChange={(val) => {
          updateParams(
            {
              ...router?.query,
              page: val,
            },
            "/user-attendance-management",
          )
        }}
      />
    </div>
  )
}
