import React from "react"
import { useTranslation } from "react-i18next"

const EmergencyContactTable = ({ response }) => {
  const { t } = useTranslation()
  return (
    <>
      <table>
        <tr className={"fifth-table"}>
          <td className={"label"}>{t("Emergency Contact 1")}</td>
          <td className={"value"}>
            {response?.emergency_contact_name1 || "-"}
          </td>
        </tr>
        <tr className={"fifth-table"}>
          <td className={"label"}>{t("Emergency Contact 2")}</td>
          <td className={"value"}>
            {response?.emergency_contact_name2 || "-"}
          </td>
        </tr>
        <tr className={"fifth-table"}>
          <td className={"label"}>{t("Emergency contact remarks")}</td>
          <td className={"value"}>
            {response?.emergency_contact_remarks || "-"}
          </td>
        </tr>
      </table>
    </>
  )
}

export default EmergencyContactTable
