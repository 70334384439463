import { Card, daysOfWeek, theme } from "@project/common"
import { ConfigProvider, Skeleton, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useBusinessDayManagement } from "../../../context"
import { ActionSection } from "./commonSection/ActionSection"
import { DataContent } from "./commonSection/DataContent"
import { SectionOne } from "./commonSection/SectionOne"
import { SectionTwo } from "./commonSection/SectionTwo"
import { Title } from "./commonSection/Title"

import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { useRouter } from "next/router"
import { WEEK_DAYS as ORIGINAL_WEEK_DAYS } from "../../../constants"

const YearlySettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  .yearly__settings {
    table {
      thead {
        tr {
          th {
            padding: 0;
            text-align: center;
            height: 35px;
            &:first-child {
              background-color: ${theme.colors["error-light"]};
            }
            &:last-child {
              background-color: ${theme.colors["t-head"]};
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0 !important;
          }
        }
      }
    }
  }
`

dayjs.extend(arraySupport)
export const YearlySettings = (): JSX.Element => {
  const { t } = useTranslation()
  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any; [x: string]: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }
  const WEEK_DAYS = getOptionsWithTranslatedLabel(ORIGINAL_WEEK_DAYS)
  const {
    yearlyBusinessHours,
    yearlyServiceHours,
    handleDataAddition,
    handleDataRemove,
    handleCalendarInputChange,
    handleCalendarOtherInputChange,
    yearlyCalendarData,
    operationOptionsValues,
    onPagination,
    isDataLoading,
    isAdding,
    originalData,
    toggleHolidayAndMeal,
    facilityType,
  } = useBusinessDayManagement()
  const router = useRouter()
  const { year, facility_name } = router?.query as any
  const columns: ColumnsType<any> = daysOfWeek?.map((val, idx) => ({
    title: t(val),
    key: idx,
    render: () => {
      const dataOf = val?.toLowerCase()
      const isHoliday =
        yearlyCalendarData?.business_hours[dataOf]?.[0]?.holiday === "1"
          ? true
          : false
      const hasMeal = yearlyCalendarData?.business_hours[dataOf]?.[0]?.meal
      return {
        props: {
          style: {
            verticalAlign: "top",
          },
        },
        children: (
          <DataContent
            dataOf={dataOf}
            businessHours={yearlyBusinessHours[dataOf] || []}
            serviceHours={yearlyServiceHours[dataOf] || []}
            handleDataAddition={handleDataAddition}
            handleDataRemove={handleDataRemove}
            handleCalendarInputChange={handleCalendarInputChange}
            isHoliday={isHoliday}
            hasMeal={hasMeal}
            toggleHolidayAndMeal={(type) =>
              toggleHolidayAndMeal({
                key: dataOf,
                value:
                  type === "H" ? !isHoliday : hasMeal === "0" ? true : false,
                type: type === "H" ? "holiday" : "meal",
              })
            }
            facilityType={facilityType}
          />
        ),
      }
    },
  }))
  const data = WEEK_DAYS?.map((v) => {
    return originalData?.yearly[0]?.[`${v?.key}_business_end_hour1`]
  })?.filter((v) => v)

  return (
    <>
      {isDataLoading ? (
        <Space
          direction={"vertical"}
          style={{ width: "100%", padding: "16px" }}
          size={16}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Space>
      ) : (
        <Card
          boxShadow={"none"}
          borderStyle={"none"}
          title={
            <Title
              title={t("{{facility_name}} {{year}}{{yearText}} Business day", {
                facility_name: facility_name,
                year: year,
                yearText: t("Year"),
              })}
              isSet={data?.length > 0 ? true : false}
            />
          }
        >
          <YearlySettingsWrapper>
            <SectionOne
              hideMonthPagination
              change={"year"}
              handleYearChange={(value) => onPagination(dayjs([value]))}
              currentYear={dayjs(operationOptionsValues?.params?.date)?.year()}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBorderRadius: 0,
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  className={"yearly__settings"}
                  bordered
                  dataSource={[{ week: "" }]}
                  pagination={false}
                  scroll={{ x: 900 }}
                  loading={isDataLoading}
                />
              </ConfigProvider>
            </SectionOne>
            <SectionTwo
              tab={"yearly"}
              handleCalendarOtherInputChange={handleCalendarOtherInputChange}
              defaultValue={{
                time_setting: yearlyCalendarData?.time_setting,
                day_hours: yearlyCalendarData?.day_hours,
                day_minutes: yearlyCalendarData?.day_minutes,
                week_hours: yearlyCalendarData?.week_hours,
                week_minutes: yearlyCalendarData?.week_minutes,
              }}
            />

            <ActionSection
              disabled={isDataLoading}
              loading={isAdding}
              data={originalData?.yearly || []}
              deleteBtnText={t("Delete Individual Settings")}
            />
          </YearlySettingsWrapper>
        </Card>
      )}
    </>
  )
}
