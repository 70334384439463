import { useMemo } from "react"
import { useRouter } from "next/router"

// packages
import wareki from "wareki"
import { t } from "i18next"
import dayjs, { Dayjs } from "dayjs"
import { ColumnsType } from "antd/es/table"

// components | common
import {
  BulletTitle,
  InvoiceTable,
  InlinePairText,
  PaymentAddress,
  ServiceTypeTable,
} from "./CustomPrintTables"
import SummaryRows from "./SummaryRows"
import { Box, PrintFriendlyTable } from "@project/common"
import { ActualExpensesTable } from "./ActualExpensesTable"

// styles
import {
  RemarksStyled,
  BillingBreakDownTable,
  PrintControlPrintViewCard,
} from "../styles"

// types
import {
  PrintConfig,
  InvoiceBreakDown,
  CustomerInvoiceDetailsCompProps,
} from "../../../../../../types/nhif.types"

// utils
import {
  getDailyInvoiceListing,
  getDailyActualExpensesListing,
} from "../../../../utils/getDailyListings"
import { generateDynamicExpenses } from "../../../../utils/generateDynamicExpenses"

export const CustomerInvoiceDetailsComp = ({
  copy,
  facilityToOutput,
  currentYearMonth,
  customerInvoice,
}: CustomerInvoiceDetailsCompProps) => {
  const { query } = useRouter()

  const era = wareki(currentYearMonth?.format("YYYY-MM"))
  const envelopLayout = facilityToOutput.includes("13")

  const printConfig = query?.print_config
    ? (+query?.print_config as PrintConfig)
    : 1

  const mergeActualCostWithBillingBreakDown =
    facilityToOutput.includes("2") && facilityToOutput.includes("3")

  const dailyActualExpenses = useMemo(
    () =>
      getDailyActualExpensesListing(
        customerInvoice?.actual_cost_data,
        currentYearMonth,
      ),
    [customerInvoice],
  )

  const dailyBillingList = useMemo(() => {
    return getDailyInvoiceListing(
      mergeActualCostWithBillingBreakDown
        ? "MERGE_ACTUAL_COST"
        : "BILLING_BREAKDOWN",
      customerInvoice,
      currentYearMonth,
    )
  }, [customerInvoice, query])

  const billingBreakDownColumns: ColumnsType<InvoiceBreakDown> = [
    {
      title: t("Day"),
      dataIndex: "day",
      key: "day",
      align: "center",
      width: 40,
    },
    {
      title: t("Day of the week"),
      dataIndex: "day_of_week",
      key: "day_of_week",
      align: "center",
      width: 60,
    },
    {
      title: t("Billing breakdown"),
      dataIndex: "billing",
      key: "billing",
      align: "center",
      width: 250,
    },
    {
      title: t("User burden"),
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: 60,
      render: (val: InvoiceBreakDown["unit"]) => val?.toLocaleString(),
    },
    {
      title: t("Institution"),
      dataIndex: "item_facility_name",
      key: "item_facility_name",
      align: "center",
      width: 150,
    },
    {
      title: t("Remarks"),
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      width: 150,
    },
  ]

  if (mergeActualCostWithBillingBreakDown) {
    billingBreakDownColumns.splice(
      4,
      0,
      ...(generateDynamicExpenses(dailyActualExpenses) as any),
    )
  }

  return (
    <PrintControlPrintViewCard className={`print-config-${printConfig}`}>
      <div className={"individual-print-sheet"}>
        <Box
          align={"flex-start"}
          display={"flex"}
          justify={"center"}
          gap={30}
          mb={10}
          className={"invoice-head-info"}
        >
          {envelopLayout && (
            <Box className={"parent-info-envelop-mode"} display={"grid"}>
              <span>
                {t("({{key}}: {{city}})", {
                  key: customerInvoice?.city_number,
                  city: customerInvoice?.provision_city,
                })}
              </span>

              <span
                className={`parent-span-tag ${
                  envelopLayout ? "envelop-mode" : ""
                }`}
              >
                {!facilityToOutput.includes("9")
                  ? t("{{parent_name}} 様", {
                      parent_name: "parents name",
                    })
                  : t("{{parent_name}} 様({{child_name}})", {
                      parent_name: "parents name",
                      child_name: customerInvoice?.user_name_furigana,
                    })}
              </span>
            </Box>
          )}

          <h3 className={`title ${envelopLayout ? "envelop-mode" : ""}`}>
            {!copy ? t("invoice") : t("invoice (copy)")}
          </h3>
        </Box>

        {!facilityToOutput.includes("12") ? (
          <div className={`date ${envelopLayout ? "envelop-mode" : ""}`}>
            {era +
              t("年 {{month}}Month {{day}}Day", {
                jaDate: dayjs().format("年MM月DD日"),

                month: dayjs().format("MM"),
                day: dayjs().format("DD"),
              })}
          </div>
        ) : (
          <Box
            display={"flex"}
            justify={"flex-end"}
            className={`date ${envelopLayout ? "envelop-mode" : ""}`}
            gap={40}
          >
            <span>{era?.replace(/[0-9]+/gi, "")}</span>
            <span>{t("Year")}</span>
            <span>{t("Month")}</span>
            <span>{t("Day")}</span>
          </Box>
        )}

        <Box
          display={"flex"}
          justify={envelopLayout ? "flex-end" : "space-between"}
          mt={8}
          className={"details-box"}
          gap={10}
        >
          {!envelopLayout && (
            <div className={"left-hand-side"}>
              <div className={"parent-name"}>
                <span
                  className={`parent-span-tag ${
                    facilityToOutput.includes("13") ? "envelop-mode" : ""
                  }`}
                >
                  {!facilityToOutput.includes("9")
                    ? t("{{parent_name}} 様", {
                        parent_name: "parents name",
                      })
                    : t("{{parent_name}} 様({{child_name}})", {
                        parent_name: "parents name",
                        child_name: customerInvoice?.user_name_furigana,
                      })}
                </span>
                <br />
                {!envelopLayout && (
                  <span>
                    {t("({{key}}: {{city}})", {
                      key: customerInvoice?.city_number,
                      city: customerInvoice?.provision_city,
                    })}
                  </span>
                )}
              </div>

              <InvoiceSubject currentYearMonth={currentYearMonth} era={era} />
            </div>
          )}

          <div className={"right-hand-side"}>
            <InlinePairText
              boldText={t("Address")}
              lightText={t(
                "〒{{zipCode}} {{facilityAddress}}, {{provisionCity}}",
                {
                  zipCode: customerInvoice?.facility_zip_code,
                  facilityAddress: customerInvoice?.facility_address,
                  provisionCity: customerInvoice?.provision_city,
                },
              )}
            />
            <InlinePairText
              boldText={t("Telephone number")}
              lightText={customerInvoice?.facility_phone_number}
            />
            <InlinePairText
              boldText={t("Official name")}
              lightText={`${customerInvoice?.facility_name} `}
            />
          </div>
        </Box>

        {envelopLayout && (
          <InvoiceSubject currentYearMonth={currentYearMonth} era={era} />
        )}

        <div className={"invoice-billed-amt"}>
          {t("Billed amount： ￥{{amt}} -", {
            amt: customerInvoice?.cost_and_burden,
          })}
        </div>

        <InvoiceTable
          changeDisabilityText={facilityToOutput.includes("15")}
          displayBillAmt={facilityToOutput.includes("8")}
          subsidyClaim={customerInvoice?.subsidy_amount}
          decidedUserFee={customerInvoice?.user_burden_monthly}
          actualCost={customerInvoice?.actual_cost_data}
        />

        {facilityToOutput.includes("1") && (
          <Box my={20}>
            <BulletTitle>{t("Service type")}</BulletTitle>
            <ServiceTypeTable
              serviceType={+customerInvoice?.service_type}
              totalCost={+customerInvoice?.monthly_total}
              userBurdenAmt={customerInvoice?.user_burden_monthly}
            />
          </Box>
        )}

        {facilityToOutput.includes("2") && (
          <Box mt={20}>
            <BulletTitle>
              {facilityToOutput.includes("2") && facilityToOutput.includes("3")
                ? t("Breakdown of charges and actual costs")
                : t("Breakdown of charges")}
            </BulletTitle>
            <BillingBreakDownTable>
              <PrintFriendlyTable
                className={"invoice-breakdown-table"}
                visibleInScreenMode
                columns={billingBreakDownColumns}
                dataSource={dailyBillingList}
                scroll={{
                  x: 600,
                }}
                summary={(pageData) => {
                  return (
                    <SummaryRows
                      allActualCostIds={dailyActualExpenses.allActualCostIds}
                      customerInvoice={customerInvoice}
                      pageData={pageData}
                      mergeActualCost={mergeActualCostWithBillingBreakDown}
                      monthlyItems={customerInvoice?.monthly_calculated_items?.filter(
                        (item) => item.unit,
                      )}
                    />
                  )
                }}
              />
            </BillingBreakDownTable>
          </Box>
        )}

        {!facilityToOutput.includes("2") && facilityToOutput.includes("3") && (
          <ActualExpensesTable dailyActualExpenses={dailyActualExpenses} />
        )}

        {facilityToOutput.includes("4") && (
          <Box mt={20}>
            <BulletTitle>{t("Payment address")}</BulletTitle>
            <PaymentAddress
              accountNumber={+customerInvoice?.institution_account_number}
              bankNumber={+customerInvoice?.institution_bank}
              branchNumber={+customerInvoice?.institution_branch}
              facilityName={customerInvoice?.facility_name}
              holderName={customerInvoice?.institution_account_name}
            />
          </Box>
        )}

        {facilityToOutput.includes("5") && (
          <Box mt={20}>
            <BulletTitle>{t("Remark")}</BulletTitle>
            <RemarksStyled>{"hello"}</RemarksStyled>
          </Box>
        )}
      </div>
    </PrintControlPrintViewCard>
  )
}

export const InvoiceSubject = ({
  currentYearMonth,
  era,
}: {
  currentYearMonth: Dayjs
  era: string
}) => {
  return (
    <div className={"sub-text"}>
      {t("We would like to request the following (for {{engMonth}} {{year}})", {
        engMonth: currentYearMonth.locale("en").format("MMMM"),
        year: currentYearMonth.year(),
        era,
        month: currentYearMonth.format("MM"),
      })}
    </div>
  )
}
