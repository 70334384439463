import { useRouter } from "next/router"
import React, { useContext } from "react"

// packages
import dayjs from "dayjs"
import { useFormik } from "formik"
import { t } from "i18next"

// commons | components
import {
  AccordionCard,
  ActionBtns,
  Box,
  Button,
  DatePicker,
  removeBlankAttributes,
  scrollToSelectedElement,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import { OperationFields } from "../../ServiceProvisionRecord/common/OperationFields"
import AttendanceBulkEditTable from "./AttendanceBulkEditTable"

// styles
import { AttendanceBulkEditOperationContent } from "./AttendanceBulkEditInternal.styles"

// context
import { AuthContext } from "../../../context"

// utils
import { setOperationInitialFormikValues } from "./utils/setOperationnitialFormikValues"

// types
import {
  AttendanceBulkEditOperation,
  BulkAttendanceProps,
} from "../../../types/AttendanceBulkEdit.types"

// constants
import { ATTENDANCE_BULK_EDIT_OPERATION_KEYS } from "./constants"
const initialValues: Partial<AttendanceBulkEditOperation> = {
  facility_id: null,
  date: null,
}

export const AttendanceBulkEditInternal = (props: BulkAttendanceProps) => {
  const { updateHeaderDetails, pageTitle } = props
  const { query } = useRouter()
  const listRef = React.useRef<HTMLDivElement>(null)
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const [updateParams] = useUpdateSearchParams()

  // ! ## query params
  const memoizedQuery: Partial<AttendanceBulkEditOperation> =
    React.useMemo(() => {
      const queryConst = query as Partial<
        Record<keyof AttendanceBulkEditOperation, string | string[]>
      >
      const facility_id =
        queryConst?.facility_id?.toString() ||
        facilitiesCtx?.[0]?.id?.toString()
      const date = queryConst?.date?.toString()
        ? dayjs(queryConst?.date?.toString())
        : dayjs()

      return {
        facility_id,
        date,
      }
    }, [query])

  // ! ## Formik form control
  const formik = useFormik<Partial<AttendanceBulkEditOperation>>({
    initialValues,
    onSubmit: (values) => {
      const params = removeBlankAttributes({
        ...values,
        date: values.date?.format("YYYY-MM-DD"),
      })

      updateParams({ ...params }, `/attendance-record/bulk-edit`)
      scrollToSelectedElement(listRef)
    },
  })
  const { setFieldValue, handleSubmit } = formik

  // ! ## React hooks
  // 1. Set initial formik values
  React.useEffect(() => {
    setOperationInitialFormikValues(
      setFieldValue,
      memoizedQuery,
      ATTENDANCE_BULK_EDIT_OPERATION_KEYS,
    )

    const selectedFacility = facilitiesCtx?.find(
      (fac) => fac?.value == memoizedQuery.facility_id,
    )

    updateHeaderDetails(
      memoizedQuery.date,
      selectedFacility?.facility_name,
      memoizedQuery.facility_id,
    )
  }, [memoizedQuery])

  return (
    <div>
      <AccordionCard
        defaultActiveKey={["service-provision-record-details"]}
        items={[
          {
            key: "service-provision-record-details",
            label: t("Operation Options"),
            children: (
              <AttendanceBulkEditOperationContent>
                <OperationFields
                  label={"Displaying facility"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                  align={"center"}
                >
                  <Box
                    className={"facility-inputs"}
                    flex={1}
                    maw={200}
                    miw={200}
                  >
                    <SelectInput
                      width={"200px"}
                      name={"facility_id"}
                      options={facilitiesCtx}
                      placeholder={"--"}
                      value={formik.values?.facility_id}
                      onChange={(value) =>
                        formik.setFieldValue("facility_id", value)
                      }
                    />
                  </Box>
                </OperationFields>

                <OperationFields
                  label={"Date"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                  align={"center"}
                >
                  <Box flex={1} maw={200} miw={200}>
                    <DatePicker
                      name={"date"}
                      date={formik.values?.date && dayjs(formik.values?.date)}
                      onDateChange={(value) => setFieldValue("date", value)}
                    />
                  </Box>
                </OperationFields>

                <ActionBtns justify={"flex-start"}>
                  <Button
                    shape={"round"}
                    btnText={t("Display Change")}
                    onClick={() => handleSubmit()}
                  />
                </ActionBtns>
              </AttendanceBulkEditOperationContent>
            ),
          },
        ]}
      />

      <div ref={listRef}>
        <AttendanceBulkEditTable
          operationFormik={formik}
          pageTitle={pageTitle}
          activeQuery={memoizedQuery}
        />
      </div>
    </div>
  )
}
