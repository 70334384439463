import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  USER_ABSENCE_TYPES,
} from "@project/common"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FuriganaAlphabetsOption } from "../../../constants"
import { AuthContext } from "../../../context"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import { UserAbsenceRecordOperation } from "../../../types/UserAttendanceAbsenceRecord.types"
import { filterWithHiragana } from "../../../utils"
import { AbsenceRecordOperationContainer } from "./AbsenceRecord.styles"

export const AbsenceRecordOperation = (props: UserAbsenceRecordOperation) => {
  const { params, handleSearch, handleResetSearch } = props
  const { t } = useTranslation()
  const [searchOptions, setSearchOptions] = useState(params)

  useEffect(() => {
    setSearchOptions(params)
  }, [params])

  const { facilities } = useContext(AuthContext)

  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
    setInitialValue: true,
  })

  return (
    <AbsenceRecordOperationContainer>
      <AccordionCard
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Username")}
                      width={"138px"}
                    />
                  </div>
                  <div className={"flex"}>
                    <SelectInput
                      name={"furigana"}
                      width={"70px"}
                      options={FuriganaAlphabetsOption}
                      value={searchOptions?.furigana}
                      onChange={(val) =>
                        setSearchOptions({ ...searchOptions, furigana: val })
                      }
                    />
                    <SelectInput
                      name={"user_id"}
                      options={
                        filterWithHiragana({
                          options: userData,
                          furigana: searchOptions?.furigana || "",
                        }) || []
                      }
                      value={searchOptions.user_id}
                      onChange={(val) =>
                        setSearchOptions({ ...searchOptions, user_id: val })
                      }
                    />
                  </div>
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Narrow date")}
                      width={"138px"}
                    />
                  </div>
                  <div className={"flex"}>
                    <DatePicker
                      onDateChange={(date: Dayjs) => {
                        setSearchOptions({
                          ...searchOptions,
                          from_date: date,
                        })
                      }}
                      placeholder={"--"}
                      date={
                        dayjs(searchOptions?.from_date).isValid()
                          ? dayjs(searchOptions?.from_date)
                          : null
                      }
                    />
                    <DatePicker
                      onDateChange={(date: Dayjs) => {
                        setSearchOptions({
                          ...searchOptions,
                          to_date: date,
                        })
                      }}
                      placeholder={"--"}
                      date={
                        dayjs(searchOptions?.to_date).isValid()
                          ? dayjs(searchOptions?.to_date)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Display facility")}
                      width={"138px"}
                    />
                  </div>
                  <FacilityCheckbox
                    options={facilities}
                    value={searchOptions.facilityIds}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilities?.map((v) => v?.value)
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: ids,
                        })
                      }
                      if (type === "uncheck") {
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: [],
                        })
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setSearchOptions({
                        ...searchOptions,
                        facilityIds: list.toString().split(","),
                      })
                    }}
                  />
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Use service")}
                      width={"138px"}
                    />
                  </div>
                  <CheckBoxGroup
                    items={FACILITY_TYPES || []}
                    value={searchOptions.service}
                    onChange={(list: CheckboxValueType[]) => {
                      setSearchOptions({
                        ...searchOptions,
                        service: list
                          ?.toString()
                          ?.split(",")
                          ?.map((s) => +s),
                      })
                    }}
                  />
                </div>
                <div className={"search__content btns"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Displaying record")}
                      width={"138px"}
                    />
                  </div>
                  <div>
                    <CheckBoxGroup
                      items={USER_ABSENCE_TYPES}
                      value={searchOptions.attendance_type}
                      onChange={(list: CheckboxValueType[]) => {
                        setSearchOptions({
                          ...searchOptions,
                          attendance_type: list
                            ?.toString()
                            ?.split(",")
                            ?.map((a) => +a),
                        })
                      }}
                    />
                  </div>
                </div>
                <div className={"search__content btns"} style={{ gap: "10px" }}>
                  <Button
                    btnText={t("Search")}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    iconType={"search"}
                    onClick={() => handleSearch(searchOptions)}
                  />
                  <Button
                    btnText={t("Reset search")}
                    size={"large"}
                    shape={"round"}
                    onClick={() => handleResetSearch()}
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </AbsenceRecordOperationContainer>
  )
}
