import {
  ActionBtns,
  Button,
  Card,
  CheckBoxGroup,
  Grid,
  InputField,
  PREFECTURE_OPTIONS,
  SelectInput,
  allowOnlyNumber,
  allowOnlyNumberAndHyphen,
  scrollToFirstErrorField,
  theme,
} from "@project/common"
import japanPostalCode from "japan-postal-code"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Wrapper } from "./ConsultationsSupportOfficeForm.style"
import { useFormik } from "formik"
import {
  ConsultationsSupportOfficeFormField,
  ConsultationsSupportOfficeFormSchema,
  ConsultationsSupportOfficeResponse,
} from "../../../types"
import { useRouter } from "next/router"
import Link from "next/link"
import { Popconfirm } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { MAIN_TARGET_DISABILITY } from "@project/common/src/constants"

interface ConsultationsSupportOfficeFormProps {
  defaultValues?: ConsultationsSupportOfficeResponse | any
  onSubmit: (values: ConsultationsSupportOfficeFormField) => void
  isLoading: boolean
  id?: string
  handleDelete?: () => void
  isDeleting?: boolean
}

const ConsultationsSupportOfficeForm = ({
  defaultValues,
  onSubmit,
  id,
  handleDelete,
  isDeleting,
  isLoading,
}: ConsultationsSupportOfficeFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const initialValues = {
    consultation_support_name: "",
    consultation_support_name_hiragana: "",
    consultation_support_number: "",
    main_target_disability: null,
    pic_name: "",
    email_address: "",
    address: "",
    phone_number: "",
    fax_number: "",
    post_box_number: "",
    pref_id: "",
  }

  const {
    values,
    handleSubmit,
    errors,
    submitCount,
    isValid,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik<ConsultationsSupportOfficeFormField>({
    initialValues: defaultValues || initialValues,
    validationSchema: ConsultationsSupportOfficeFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })
  const setAddressFromPostCode = async () => {
    japanPostalCode.get(values?.post_box_number, function (address) {
      setFieldValue("address", address?.city)
      const prefectureNumber =
        PREFECTURE_OPTIONS.find((item) => {
          return item.label == address?.prefecture
        })?.value || null
      setFieldValue("pref_id", +prefectureNumber)
    })
  }
  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  return (
    <Card title={t("Consultation Support Office Information")}>
      <Wrapper>
        <form onSubmit={handleSubmit} id={"office__form"}>
          <div>
            <Grid
              labelContent={t("Consultation Support Office Name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputField
                name={"consultation_support_name"}
                id={"consultation_support_name"}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.consultation_support_name}
                error={
                  touched?.consultation_support_name &&
                  errors?.consultation_support_name
                    ? errors?.consultation_support_name
                    : ""
                }
                info={t("(Within 50 char)")}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Consultation Support Office Name Hiragana")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"consultation_support_name_hiragana"}
                id={"consultation_support_name_hiragana"}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.consultation_support_name_hiragana}
                error={
                  touched?.consultation_support_name_hiragana &&
                  errors?.consultation_support_name_hiragana
                    ? errors?.consultation_support_name_hiragana
                    : ""
                }
                info={t("(Within 100 char)")}
                maxLength={100}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Consultation Support Business Number")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"consultation_support_number"}
                id={"consultation_support_number"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("Example：0123456789")}
                subInfo={t("(Max 30 num)")}
                width={"320px"}
                value={values?.consultation_support_number}
                onChange={(e) => {
                  setFieldValue(
                    "consultation_support_number",
                    allowOnlyNumber(e.target.value),
                  )
                }}
                maxLength={30}
                error={
                  touched?.consultation_support_number &&
                  errors?.consultation_support_number
                    ? t(`${errors?.consultation_support_number}`)
                    : ""
                }
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Main target disability")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <CheckBoxGroup
                items={MAIN_TARGET_DISABILITY}
                value={values?.main_target_disability || []}
                onChange={(list: CheckboxValueType[]) => {
                  setFieldValue("main_target_disability", list)
                }}
              />
            </Grid>
            <Grid
              labelContent={t("Po Box")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <div className={"office__pobox_content"}>
                <div className={"right"}>
                  <InputField
                    name={"post_box_number"}
                    id={"post_box_number"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    placeholder={t("例：001-0010")}
                    subInfo={t("(within 8 characters)")}
                    subInfoMt={"0px"}
                    width={"320px"}
                    maxLength={8}
                    value={values?.post_box_number}
                    onChange={({ target: { value } }) => {
                      const v = allowOnlyNumberAndHyphen(value)
                      setFieldValue("post_box_number", v)
                    }}
                    className={"post_box_number"}
                    error={
                      touched?.post_box_number && errors?.post_box_number
                        ? errors?.post_box_number
                        : ""
                    }
                  />
                  <Link
                    href={"https://www.post.japanpost.jp/zipcode/"}
                    id={"provision__post_box_number__content--msg"}
                    className={"provision__post_box_number__content"}
                  >
                    <a target={"_blank"}>
                      {t(
                        "*If you are unsure of your postal code, please check the postal code on the Japan Post Group website.",
                      )}
                    </a>
                  </Link>
                </div>
                <Button
                  btnText={t("Autofill address")}
                  shape={"round"}
                  className={"autofill_address"}
                  disabled={!values?.post_box_number}
                  onClick={setAddressFromPostCode}
                />
              </div>
            </Grid>
            <Grid
              labelContent={t("Prefecture")}
              className={"row__header"}
              background
              labelSpan={6}
              required
            >
              <SelectInput
                id={"pref_id"}
                title={t("Prefecture")}
                options={PREFECTURE_OPTIONS}
                name={"pref_id"}
                placeholder={"--"}
                value={values?.pref_id}
                onChange={(value) => {
                  setFieldValue("pref_id", value)
                }}
                error={
                  touched?.pref_id && errors?.pref_id ? errors?.pref_id : ""
                }
              />
            </Grid>
            <Grid
              labelContent={t("Municipality/Address")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"address"}
                id={"address"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("例：鈴木小太郎")}
                info={t("(200文字以内)")}
                max={200}
                value={values?.address}
                error={
                  touched?.address && errors?.address ? errors?.address : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("PIC Name")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"pic_name"}
                id={"pic_name"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("例：鈴木小太郎")}
                info={t("(50文字以内)")}
                maxLength={50}
                value={values?.pic_name}
                error={
                  touched?.pic_name && errors?.pic_name ? errors?.pic_name : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Email Address")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"email_address"}
                id={"email_address"}
                type={"email"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("例：suzuki@gmail.com")}
                info={t("(100文字以内)")}
                maxLength={100}
                value={values?.email_address}
                error={
                  touched?.email_address && errors?.email_address
                    ? errors?.email_address
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Phone Number")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"phone_number"}
                id={"phone_number"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("例：0120-123-4567")}
                subInfo={t("(Within 30 characters)")}
                maxLength={30}
                width={"320px"}
                value={values?.phone_number}
                error={
                  touched?.phone_number && errors?.phone_number
                    ? t(`${errors?.phone_number}`)
                    : ""
                }
                onChange={({ target: { value } }) => {
                  setFieldValue("phone_number", allowOnlyNumberAndHyphen(value))
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("FAX Number")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <InputField
                name={"fax_number"}
                id={"fax_number"}
                padding={"8px"}
                borderRadius={"4px"}
                placeholder={t("例：0120-123-4567")}
                subInfo={t("(Within 30 characters)")}
                maxLength={30}
                width={"320px"}
                value={values?.fax_number}
                error={
                  touched?.fax_number && errors?.fax_number
                    ? t(`${errors?.fax_number}`)
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </div>
          <div className={"action_btns"}>
            <ActionBtns justify={"flex-start"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() =>
                  router.push("/consultation-support-office-master")
                }
                disabled={isLoading}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isLoading}
              />
            </ActionBtns>
            {id && (
              <div className={"delete-button"}>
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  onConfirm={handleDelete}
                  okText={t("OK")}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                >
                  <Button
                    btnText={t("Delete")}
                    type={"primary"}
                    shape={"round"}
                    btnBg={theme.colors["error"]}
                    color={theme.colors["white"]}
                    borderColor={"none"}
                    isLoading={isDeleting}
                    disabled={isDeleting}
                  />
                </Popconfirm>
              </div>
            )}
          </div>
        </form>
      </Wrapper>
    </Card>
  )
}

export { ConsultationsSupportOfficeForm }
