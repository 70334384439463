import React, { useEffect, useRef } from "react"
import { useRouter } from "next/router"

// commons | components
import { useFormik } from "formik"
import { TMBulkTables } from "./TMBulkTables"
import { Box, scrollToSelectedElement } from "@project/common"
import { TMBulkFormFilterOperation } from "./TMBulkFormFilterOperation"

// context
import { useTMBulkEdit } from "../../context/TMBulkEditFormContext"

// utils
import { getResetValues } from "./utils/getResetValues"
import { sanitizeUserTransportData } from "./utils/sanitizeUserTransportData"

// types
import {
  TMBulkFormValue,
  // DailyTransportSchema,
  DailyTransportDataType,
} from "./types"

export function TransportMgmtBulkEditInternal() {
  const listRef = useRef<HTMLDivElement>(null)
  const { query } = useRouter()

  const {
    dailyTransportData,
    mutateUserDailyTransportRec,
    deletedItemsStore,
    handleReset,
    handleTMBulkEditReflect: handleGlobalReflection,
    selectedTransportIds,
  } = useTMBulkEdit()

  const formik = useFormik<Partial<DailyTransportDataType>>({
    initialValues: dailyTransportData,
    enableReinitialize: true,
    // validationSchema: DailyTransportSchema,
    onSubmit: (values) => {
      const sanitizedValues = sanitizeUserTransportData(
        query.date as string,
        values,
        deletedItemsStore,
      )
      mutateUserDailyTransportRec(sanitizedValues)
    },
  })

  // !## All Event handlers
  // 1. This function, when called will scroll down to list as referenced
  const handleScrollToList = () => {
    scrollToSelectedElement(listRef)
  }

  // 2. handle edit bulk reflection
  const handleTMBulkEditReflect = (values: Partial<TMBulkFormValue>) => {
    handleGlobalReflection(values, formik.values) // We must send in fresh/current table(Pickup & dropOff) values as well, that way we have bulk-edit table row values as well as retain previous value for other fields/cell(that we do not have in bulk-edit-action/form.)
  }

  const onReset = () => {
    const { data } = JSON.parse(
      JSON.stringify(formik.values),
    ) as Partial<DailyTransportDataType>

    const resettedValues = getResetValues(data, selectedTransportIds)

    handleReset(resettedValues)
  }

  useEffect(() => {
    if (dailyTransportData?.data) {
      formik.setValues(dailyTransportData)
    }
  }, [dailyTransportData])

  return (
    <Box display={"flex"} direction={"column"} gap={"24px"}>
      <TMBulkFormFilterOperation
        handleReset={onReset}
        handleScrollToList={handleScrollToList}
        handleTMBulkEditReflect={handleTMBulkEditReflect}
      />
      <div ref={listRef}>
        <TMBulkTables formik={formik} />
      </div>
    </Box>
  )
}
