import { FACILITY_SERVICE_TYPES, getFullEraDate } from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { StyledProxyPrintTableContent } from "./AcknowledgementPrintAbleContent.style"

export const ProxyPrintTableContent = ({
  data,
}: {
  data: any
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { date_of_receipt, display_content } = router.query as any
  const currentReceiptDate = date_of_receipt ? dayjs(date_of_receipt) : dayjs()
  const selectedSettingOP = display_content?.split(",") || []
  return (
    <div style={{ overflowX: "auto" }}>
      <StyledProxyPrintTableContent>
        <tbody id={"proxy__table-body"}>
          <tr>
            <td colSpan={2} className={"w-50"}>
              {t("Service provided year month")}
            </td>
            <td className={"w-50 align_center"}>
              {getFullEraDate(data?.year || 0, data?.month || 0)}
              {dayjs(`${data?.year}-${data?.month}`).month() + 1}
              {t("月分")}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("Service content")}</td>
            <td className={"align_center"}>
              {t(FACILITY_SERVICE_TYPES[data?.service_type] || "")} {"事業"}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("Date of receipt")}</td>
            <td className={"align_center"}>
              {selectedSettingOP.includes("3") ? (
                <Flex justify={"center"} gap={"2rem"}>
                  <div>
                    {getFullEraDate(
                      dayjs().year() || 0,
                      dayjs().month() + 1 || 0,
                    )?.substring(0, 2)}
                  </div>
                  <div>{t("Year")}</div>
                  <div>{t("Month")}</div>
                  <div>{t("Day")}</div>
                </Flex>
              ) : (
                <>
                  {getFullEraDate(
                    dayjs(currentReceiptDate).year() || 0,
                    dayjs(currentReceiptDate).month() + 1 || 0,
                  )}
                  {dayjs(currentReceiptDate).month() + 1}
                  {t("月分")}
                  {dayjs(currentReceiptDate)?.date()}
                  {t("日")}
                </>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("Proxy receipt amount")}</td>
            <td className={"align_center"}>
              {" "}
              {`金 ${data?.amount_received_by_proxy || "0"}  円`}
            </td>
          </tr>
          <tr>
            <td rowSpan={3} className={"w-50"}>
              {t("Breakdown of amount received by proxy")}
            </td>
            <td className={"w-50"}>{t("Cost for service total cost (A)")}</td>
            <td className={"align_center"}>{`金 ${
              data?.total_proxy_amount || "0"
            }  円`}</td>
          </tr>
          <tr>
            <td>{t("User burden (B)")}</td>
            <td className={"align_center"}>
              {`金  ${data?.receipt_user_burden_amount || "0"}  円`}
            </td>
          </tr>
          <tr>
            <td>
              {t("Nursing care benefits, etc. Amount received by proxy(A)-(B)")}
            </td>
            <td className={"align_center"}>
              {`金 ${data?.amount_received_by_proxy || "0"}  円`}
            </td>
          </tr>
        </tbody>
      </StyledProxyPrintTableContent>
    </div>
  )
}
