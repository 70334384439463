import { API } from "@project/common"
import { Dayjs } from "dayjs"

export const getMonthlyUseStatusChart = (
  date: Dayjs,
  facilityId: string | number,
  service?: Array<number> | null,
) => {
  const query: any = {}
  if (Array.isArray(service) && service.length > 0) {
    query.service = service.join(",")
  }
  return API.get(`/user-with-disabiliy-status/${date}/${facilityId}`, {
    params: query,
  })
}
