import React, { useContext } from "react"

// packages
import { Flex } from "antd"
import { t } from "i18next"
import { FormikProps } from "formik"

// components
import {
  Button,
  ActionBtns,
  SelectInput,
  AccordionCard,
  CheckBoxGroup,
  GetUpToFiveYears,
} from "@project/common"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"
import { TreatmentImprovementOperation } from "../../types/TreatmentImproveTable.types"

// styles
import { TreatmentImprovOperationContent } from "./TreatmentImproveTable.styles"

// context
import { AuthContext } from "../../context"

const TreatmentImproveOperation = ({
  formik,
}: {
  formik: FormikProps<Partial<TreatmentImprovementOperation>>
}) => {
  const { facilities: facilitiesCtx } = useContext(AuthContext)
  const { setFieldValue, values, handleSubmit } = formik

  // 1. Handle facility_ids Check all and Uncheck all
  const handleCheckAll = () => {
    setFieldValue(
      "facility_ids",
      facilitiesCtx.map((item) => item.id + ""),
    )
  }
  // 2. Handle facility_ids Uncheck all
  const handleUncheckAll = () => {
    setFieldValue("facility_ids", [])
  }

  return (
    <AccordionCard
      defaultActiveKey={["service-provision-record-details"]}
      items={[
        {
          key: "service-provision-record-details",
          label: t("Operation Options"),
          children: (
            <TreatmentImprovOperationContent>
              <OperationFields
                label={t("displayingfacility")}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
                align={"flex-start"}
              >
                <div className={"facility-inputs"}>
                  <Flex gap={"10px"} className={"facility-action-button"}>
                    <Button
                      shape={"round"}
                      type={"default"}
                      iconType={"check-all"}
                      btnText={t("Check all")}
                      onClick={handleCheckAll}
                    />
                    <Button
                      shape={"round"}
                      type={"default"}
                      btnText={t("Uncheck all")}
                      onClick={handleUncheckAll}
                      className={"MM_uncheck-all-btn"}
                    />
                  </Flex>

                  <CheckBoxGroup
                    items={facilitiesCtx}
                    name={"facility_ids"}
                    onChange={(val) => {
                      setFieldValue("facility_ids", val)
                    }}
                    value={values?.facility_ids}
                  />
                </div>
              </OperationFields>

              <OperationFields
                label={"fiscalYear"}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
                align={"flex-start"}
              >
                <Flex gap={8} align={"center"}>
                  <SelectInput
                    width={"86px"}
                    name={"current_year"}
                    options={GetUpToFiveYears(2023)}
                    placeholder={"--"}
                    value={formik.values?.fiscal_year}
                    onChange={(value) =>
                      formik.setFieldValue("fiscal_year", value)
                    }
                  />
                  <span>{"年"}</span>
                </Flex>
              </OperationFields>

              <ActionBtns justify={"flex-start"}>
                <Button
                  shape={"round"}
                  btnText={t("Display Change")}
                  onClick={() => handleSubmit()}
                />
              </ActionBtns>
            </TreatmentImprovOperationContent>
          ),
        },
      ]}
    />
  )
}

export { TreatmentImproveOperation }
