import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../../context"
import dayjs, { Dayjs } from "dayjs"
import { ActualCostBurdenRecordOperationValues } from "../../../types"
import { FULL_DATE_FORMAT_EN } from "../../../constants"

export const ActualCostBurdenRecordListOperation = ({
  params,
  setParams,
}: {
  params: ActualCostBurdenRecordOperationValues
  setParams: React.Dispatch<
    React.SetStateAction<ActualCostBurdenRecordOperationValues>
  >
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState<ActualCostBurdenRecordOperationValues>({
    start_date: params?.start_date || dayjs(),
    facility: params?.facility || null,
  })
  const onSearchOrReset = (mode: "search" | "reset") => {
    if (mode === "search") {
      setParams(values)
      updateParams(
        {
          start_date: dayjs(values?.start_date).format(FULL_DATE_FORMAT_EN),
          facility: values?.facility,
        },
        "/actual-cost-burden-record/list",
      )
    }
    if (mode === "reset") {
      setValues({
        start_date: dayjs(),
        facility: null,
      })
      setParams({ start_date: dayjs(), facility: null })
      updateParams({}, "/actual-cost-burden-record/list")
    }
  }
  useEffect(() => {
    setValues(params)
  }, [params])
  return (
    <AccordionCard
      defaultActiveKey={["ActualCostBurdenRecordListOperation"]}
      items={[
        {
          key: "ActualCostBurdenRecordListOperation",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  width={"136px"}
                  label={t("Start Date  ")}
                />
                <DatePicker
                  date={values?.start_date || null}
                  onDateChange={(date: Dayjs) =>
                    setValues({
                      ...values,
                      start_date: date,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow width={"136px"} label={t("Facility")} />
                <SelectInput
                  placeholder={"--"}
                  name={"facility"}
                  options={facilities || []}
                  value={values?.facility || null}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      facility: value,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => onSearchOrReset("reset")}
                />
                <Button
                  btnText={t("Search")}
                  shape={"round"}
                  type={"primary"}
                  iconType={"search"}
                  onClick={() => onSearchOrReset("search")}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
