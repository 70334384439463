import { useState } from "react"

// packages
// import dayjs from "dayjs"
import { Flex, Form, InputNumber, Space, Switch } from "antd"
import { FormInstance } from "antd/lib"
import { t } from "i18next"

// common | components
import {
  Box,
  CheckBox,
  FileUploader,
  Grid,
  // DatePicker,
  InputField,
  RadioGroup,
  SelectInput,
  SERVICE_TYPE_VALUES,
} from "@project/common"
import { NumberedTitle } from "./NumberedTitle"

// utils
import { calculateScore } from "../utils/calculateEvaluationScore"

// types
import { SystemStatusMgmtData } from "../../../services/system-status-mgmt.service"

// constants
import {
  AVERAGE_MONTHLY_OPTION,
  AVERAGE_WORKING_HOUR_OPTION,
  BASIC_STAFF_ARRANGEMENT_Type_AorC,
  BASIC_STAFF_ARRANGEMENT_Type_B,
  COMPENSATION_SYSTEM_OPTION,
  IMPROVEMENT_ABILITY,
  IMPROVEMENT_PLAN,
  IMPROVEMENT_SUPPORT_OPTION,
  PRODUCTION_ACT_OPTION,
  REGIONAL_ACT_OPTION,
  RETENTION_RATE_OPTION,
  UNIT_OPTION,
  WORKING_WAYS_OPTION,
} from "../constants"
import { styled } from "styled-components"

const Wrapper = styled.div`
  .ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
  }
`

const getBasicStaff = (type: 1 | 2 | 3) =>
  type === 1 || type === 3
    ? BASIC_STAFF_ARRANGEMENT_Type_AorC
    : BASIC_STAFF_ARRANGEMENT_Type_B

export const BasicInfoForm = ({
  form,
  setStaffArrangement,
}: {
  form: FormInstance<SystemStatusMgmtData>
  setStaffArrangement: (value: string) => void
}) => {
  // const openingDateValue = Form.useWatch("opening_date", form)
  const serviceTypeValue = Form.useWatch("service_type_id", form) as 1 | 2 | 3
  const isBurdenReductionValue = Form.useWatch("is_burden_reduction", form)
  const isCompensationSystem = Form.useWatch("compensation_system", form)

  return (
    <Wrapper>
      <Box mt={28} className={"status-facility"}>
        <NumberedTitle number={"2"}>
          {t("Set the status of the facility")}
        </NumberedTitle>

        <Grid
          labelContent={t("Average number of users in the previous year")}
          background
          labelSpan={6}
        >
          <Space size={24} wrap>
            <Flex align={"center"} gap={8}>
              <Box w={122} miw={122} maw={122}>
                <Form.Item
                  name={"avarage_previous_user"}
                  id={"avarage_previous_user"}
                >
                  <InputNumber
                    min={0}
                    step={1}
                    maxLength={6}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault()
                      }
                    }}
                    height={"40px"}
                    size={"large"}
                  />
                </Form.Item>
              </Box>
              <div>{"人"}</div>
            </Flex>
            <div>{t("(Within 6 numbers)")}</div>
          </Space>
        </Grid>

        <Grid
          labelContent={t(
            "Provisional value of no. of user if there is no previous year",
          )}
          background
          labelSpan={6}
        >
          <Space size={24} wrap>
            <Flex align={"center"} gap={8}>
              <Box w={122} miw={122} maw={122}>
                <Form.Item
                  name={"provisional_number_of_user"}
                  id={"provisional_number_of_user"}
                >
                  <InputNumber
                    min={0}
                    step={1}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault()
                      }
                    }}
                    height={"40px"}
                    size={"large"}
                    maxLength={6}
                  />
                </Form.Item>
              </Box>
              <div>{"人"}</div>
            </Flex>
            <div>{t("(Within 6 numbers)")}</div>
          </Space>
        </Grid>

        {/* Opening date if there is no previous year */}
        {/* {serviceTypeValue === 1 && (
          <Grid
            labelContent={t("Opening date if there is no previous year")}
            background
            labelSpan={6}
          >
            <Box w={197} miw={197} maw={197}>
              <Form.Item name={"opening_date"} id={"opening_date"}>
                <DatePicker
                  date={dayjs(openingDateValue)}
                  onDateChange={(date) =>
                    form.setFieldValue(
                      "opening_date",
                      date.format(FULL_DATE_FORMAT_EN) + "T00:00:00Z",
                    )
                  }
                  format={FULL_DATE_FORMATE}
                />
              </Form.Item>
            </Box>
          </Grid>
        )} */}

        <Grid
          labelContent={t("Capacity")}
          background
          labelSpan={6}
          id={"capacity-field"}
        >
          <Space size={24} wrap>
            <Flex align={"center"} gap={8}>
              <Box w={122} miw={122} maw={122}>
                <Form.Item
                  name={"capacity"}
                  id={"capacity"}
                  rules={[
                    {
                      type: "number",
                      min: 1,
                      message: t("Capacity must be minimum 1"),
                    },
                  ]}
                >
                  <InputNumber
                    height={"40px"}
                    size={"large"}
                    maxLength={3}
                    min={0}
                    step={1}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault()
                      }
                    }}
                    placeholder={"100"}
                  />
                </Form.Item>
              </Box>
              <div>{"人"}</div>
            </Flex>
            <div>{"(半角数字3文字以内)"}</div>
          </Space>
        </Grid>

        {serviceTypeValue === 1 && (
          <Grid
            labelContent={t(
              "Implementation of burden reduction/exemption measures for type A businesses",
            )}
            background
            labelSpan={6}
          >
            <Space size={16} wrap direction={"vertical"}>
              <Flex align={"center"} gap={8}>
                <Form.Item
                  labelAlign={"right"}
                  id={"is_burden_reduction"}
                  valuePropName={"checked"}
                  name={"is_burden_reduction"}
                >
                  <Switch />
                </Form.Item>
                <label htmlFor={"is_burden_reduction"}>
                  {t(
                    "Implementation of burden reduction/exemption measures for type A businesses",
                  )}
                </label>
              </Flex>

              <Flex gap={16} wrap={"wrap"}>
                <div>
                  <div>{t("Load")}</div>
                  <Box maw={319} w={319} miw={200} mt={4}>
                    <Form.Item
                      id={"burden_reduction_load"}
                      name={"burden_reduction_load"}
                    >
                      <InputField
                        size={"large"}
                        width={"319px"}
                        placeholder={"例：0123456789"}
                        disabled={!isBurdenReductionValue}
                      />
                    </Form.Item>
                  </Box>
                </div>

                <div>
                  <div>{t("Unit")}</div>
                  <Box maw={80} w={80} miw={80} mt={4}>
                    <Form.Item
                      id={"burden_reduction_load_unit"}
                      name={"burden_reduction_load_unit"}
                    >
                      <SelectInput
                        placeholder={"¥"}
                        name={"burden_reduction_load_unit"}
                        options={UNIT_OPTION}
                        popupMatchSelectWidth={false}
                        disabled={!isBurdenReductionValue}
                      />
                    </Form.Item>
                  </Box>
                </div>
              </Flex>
            </Space>
          </Grid>
        )}

        <Grid
          labelContent={t("Support facilities for people with disabilities")}
          background
          labelSpan={6}
        >
          <Form.Item
            name={"people_with_disability_support_addition"}
            valuePropName={"checked"}
            noStyle
          >
            <CheckBox></CheckBox>
          </Form.Item>
        </Grid>

        {serviceTypeValue === 3 && (
          <Grid labelContent={t("Training facility")} background labelSpan={6}>
            <Form.Item
              name={"training_facility"}
              valuePropName={"checked"}
              noStyle
            >
              <CheckBox></CheckBox>
            </Form.Item>
          </Grid>
        )}

        <Grid
          labelContent={t("Basic staff arrangement")}
          background
          labelSpan={6}
        >
          <Form.Item name={"staff_arrangement"} id={"staff_arrangement"}>
            <RadioGroup
              options={getBasicStaff(serviceTypeValue)}
              onChange={(v) => {
                form?.setFieldValue("staff_arrangement", v?.target?.value)
                setStaffArrangement(v?.target?.value)
              }}
            />
          </Form.Item>
        </Grid>

        {/* Service type Employment type B | 2 */}
        {serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_2 && (
          <>
            <Grid
              labelContent={t("Compensation system")}
              background
              labelSpan={6}
              className={"compensation_system_grid"}
            >
              <Form.Item
                name={"compensation_system"}
                id={"compensation_system"}
              >
                <RadioGroup options={COMPENSATION_SYSTEM_OPTION} />
              </Form.Item>
            </Grid>

            <Grid
              labelContent={t("Average monthly wage")}
              background
              labelSpan={6}
              className={"average_monthly_wage_grid col-direction-radio-group"}
            >
              <Form.Item
                name={"average_monthly_wage"}
                id={"average_monthly_wage"}
              >
                <RadioGroup
                  options={AVERAGE_MONTHLY_OPTION}
                  disabled={isCompensationSystem == "2"}
                />
              </Form.Item>
            </Grid>
          </>
        )}

        {/* Service type Employment transition service 'C' | 3 */}
        {serviceTypeValue === 3 && (
          <>
            <Grid
              labelContent={t(
                "Retention rate for 6 months or more after employment",
              )}
              background
              labelSpan={6}
              className={"retention_grid col-direction-radio-group"}
            >
              <Form.Item name={"retension_rate"} id={"retension_rate"}>
                <RadioGroup options={RETENTION_RATE_OPTION} />
              </Form.Item>
            </Grid>
          </>
        )}
      </Box>

      {/* Sub-form Basic Reward Question */}
      {serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_1 && (
        <BasicRewardQuestion form={form} />
      )}
    </Wrapper>
  )
}

// Separated these fields because fields in them needs to be constantly watched as they change
// which would cause unnecessary re-rendering of the whole page if put under same scope.
const BasicRewardQuestion = ({
  form,
}: {
  form: FormInstance<SystemStatusMgmtData>
}) => {
  const [fileName, setFileName] = useState("")
  const scoreDocumentValue = Form.useWatch("score_document", form)

  const parts = scoreDocumentValue?.split("/")
  const lastPart = parts?.length && parts[parts?.length - 1]
  const resultString = lastPart?.replace(/%7C\d+/, "")

  const avarage_working_hour = Form.useWatch("avarage_working_hour", form)
  const production_activity = Form.useWatch("production_activity", form)
  const ways_of_working = Form.useWatch("ways_of_working", form)
  const improvement_support = Form.useWatch("improvement_support", form)
  const regional_collaboration_activities = Form.useWatch(
    "regional_collaboration_activities",
    form,
  )
  const improvement_plan = Form.useWatch("improvement_plan", form)
  const improvement_ability = Form.useWatch("improvement_ability", form)

  const evaluationScore = calculateScore({
    avarage_working_hour,
    production_activity,
    ways_of_working,
    improvement_support,
    regional_collaboration_activities,
    improvement_ability,
    improvement_plan,
  })

  return (
    <Box mt={16} className={"basic-reward-questioner"}>
      <NumberedTitle mb={8}>{t("Basic Reward Question")}</NumberedTitle>

      <Grid
        labelContent={t("The average daily working hours of the user")}
        background
        labelSpan={6}
        className={"working_hour_grid col-direction-radio-group"}
      >
        <Form.Item name={"avarage_working_hour"} id={"avarage_working_hour"}>
          <RadioGroup options={AVERAGE_WORKING_HOUR_OPTION} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Production activity")}
        background
        labelSpan={6}
        className={"production_act_grid col-direction-radio-group"}
      >
        <Form.Item name={"production_activity"} id={"production_activity"}>
          <RadioGroup options={PRODUCTION_ACT_OPTION} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Various ways of working")}
        background
        labelSpan={6}
        className={"ways_of_working_grid col-direction-radio-group"}
      >
        <Form.Item name={"ways_of_working"} id={"ways_of_working"}>
          <RadioGroup options={WORKING_WAYS_OPTION} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Improvement of support")}
        background
        labelSpan={6}
        className={"support_improvement_grid col-direction-radio-group"}
      >
        <Form.Item name={"improvement_support"} id={"improvement_support"}>
          <RadioGroup options={IMPROVEMENT_SUPPORT_OPTION} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Regional collaboration activities")}
        background
        labelSpan={6}
        className={
          "regional_collaboration_activities_grid col-direction-radio-group"
        }
      >
        <Form.Item
          name={"regional_collaboration_activities"}
          id={"regional_collaboration_activities"}
        >
          <RadioGroup options={REGIONAL_ACT_OPTION} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Management improvement plan")}
        background
        labelSpan={6}
        className={"improvement_plan_grid col-direction-radio-group"}
      >
        <Form.Item name={"improvement_plan"} id={"improvement_plan"}>
          <RadioGroup options={IMPROVEMENT_PLAN} />
        </Form.Item>
      </Grid>

      {/* Improving users' knowledge and abilities */}
      <Grid
        labelContent={t("Improving users' knowledge and abilities")}
        background
        labelSpan={6}
        className={"improvement_ability_grid col-direction-radio-group"}
      >
        <Form.Item name={"improvement_ability"} id={"improvement_ability"}>
          <RadioGroup options={IMPROVEMENT_ABILITY} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Score document upload")}
        background
        labelSpan={6}
        className={"score_document_grid col-direction-radio-group"}
      >
        <Form.Item name={"score_document"} id={"score_document"}></Form.Item>
        <FileUploader
          currentImage={scoreDocumentValue}
          onChange={(val) => {
            form.setFieldValue("score_document", val)
          }}
          setFileName={setFileName}
          fileName={fileName || resultString}
          isLoading={(value) => value}
          inputType={"file"}
          uploadTitle={t("Upload")}
        />
      </Grid>

      <Grid
        labelContent={t("evaluation score")}
        background
        labelSpan={6}
        className={"evaluation_score_grid"}
      >
        {evaluationScore}
        {t("Points")}
      </Grid>
    </Box>
  )
}
