import React, { useEffect } from "react"

// packages
import _ from "lodash"
import { t } from "i18next"
import { useQuery } from "react-query"
import { ColumnsType } from "antd/es/table"

// common | components
import { CostStatementTableWrapper } from "../styles"
import { Table, useNotification, useUpdateSearchParams } from "@project/common"
import { AccountSubjectCell } from "../../common/AccountSubjectCell"

// services
import { getCostStatements } from "../../../../services"

// utils
import { calculateExpenseAttribs } from "../utils/calculatePossibleAttibs"
import { getDynamicFacilityColumns } from "../utils/getDynamicFacilityCols"

// types
import {
  ProjectCostQueryParam,
  StaticManufacturingCostDataType as StaticColumnsType,
} from "../../../../types"

// constants
import { staticRowsData } from "../constants"
import { useWagesRevenueContext } from "../../../../context"
import { useRouter } from "next/router"

export const CostStatementInternal = ({
  memoizedQuery,
  hasEditAccess,
}: {
  memoizedQuery: ProjectCostQueryParam
  hasEditAccess: (val) => boolean
}) => {
  const { showToast } = useNotification()
  const router = useRouter()
  const { panel } = router?.query as any
  const { setDownloadAbleDataOf, downloadAbleDataOf } = useWagesRevenueContext()
  const [updateParams] = useUpdateSearchParams()
  const { data: costStatements, isLoading: loadingCostStatements } = useQuery({
    queryKey: ["facility-cost-statements", memoizedQuery.date],
    queryFn: () =>
      getCostStatements({
        month: memoizedQuery.date.month() + 1,
        year: memoizedQuery.date.year(),
      }),
    onError: () =>
      showToast({
        message: t("Something went wrong. Please contact administrator."),
        type: "error",
      }),
  })

  /**
   * `calcFacilityExpenses` consists of work facility data with all calculated attributes such as : `expenses`, `Total | grand_total` etc.
   */
  const calcFacilityExpenses = React.useMemo(
    () => calculateExpenseAttribs(costStatements?.data),
    [costStatements],
  )

  const handleUpdateParams = (params: any, path: string) => {
    updateParams({ panel, ...params }, path)
  }
  // ! ## Antd columns
  const getMainColumns = (mode: "download" | "view") => {
    const columns: ColumnsType<StaticColumnsType> = [
      {
        title: t("Accounting Subject"),
        dataIndex: "subject",
        key: "subject",
        width: 260,
        render: (val) =>
          mode === "download" ? val : <AccountSubjectCell label={val} />,
        fixed: "left",
      },
      {
        title: t("Total"),
        dataIndex: "total",
        key: "total",
        width: 127,
        render: (__, rec) => {
          if (rec.isCategory) {
            return null
          }
          const key = rec.key
          const total: number = _.sumBy(
            calcFacilityExpenses,
            (item) => item[key],
          )

          return total?.toLocaleString()
        },
      },

      ...getDynamicFacilityColumns(
        calcFacilityExpenses,
        memoizedQuery,
        handleUpdateParams,
        hasEditAccess,
        mode,
      ),
    ]
    return columns || []
  }
  const dataSource = staticRowsData || []
  useEffect(() => {
    setDownloadAbleDataOf({
      ...downloadAbleDataOf,
      tab: t("（２）Project Manufacturing Cost Statement"),
      [panel]: {
        columns: getMainColumns("download"),
        dataSource: dataSource,
      },
    })
  }, [panel, costStatements])
  return (
    <CostStatementTableWrapper mt={24}>
      <Table
        loading={loadingCostStatements}
        columns={getMainColumns("view")}
        dataSource={staticRowsData}
        rowClassName={(rec) =>
          rec.isCategory ? "category_row" : "regular_row"
        }
        scroll={{
          x: "max-content",
        }}
      />
    </CostStatementTableWrapper>
  )
}
