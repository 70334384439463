import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  PER_PAGE_PRINT_1_SERVICE_CONTENTS,
  removeBlankAttributes,
  scrollToSelectedElement,
  usePrint,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { AlertBox } from "../../../../../components"
import { getDUOBPData } from "../../../../../services"
import { BillTableContent } from "./BillTableContent"
import { DUOBPBasicInfo } from "./DUOBPBasicInfo"
import { DUOBPContainer } from "./DisabledUserOutpatientBenefitsPrintContent.style"
import StatementTableContent from "./StatementTableContent"

export const DisabledUserOutpatientBenefitsPrintContent = (): JSX.Element => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { pathname, query, push } = useRouter()
  const { year_month, facilityIds, city_ids, user_ids } = query as any
  const defaultSetting = query?.print_setting?.toString().split(",") || []
  const [printSetting, setPrintSetting] = useState(defaultSetting || [])
  const [printSettingValues, setPrintSettingValues] = useState(
    defaultSetting || [],
  )
  const listRef = useRef<HTMLDivElement>(null)
  const printRef = useRef<HTMLDivElement>(null)
  const { handlePrint } = usePrint({
    contentRef: printRef,
    pageStyle: `margin:6mm`,
  })

  //fetch print API data
  const { isLoading, data: response } = useQuery({
    queryKey: ["disabled_user_outpatient_benefits_print", printSettingValues],
    refetchOnWindowFocus: false,
    queryFn: () =>
      getDUOBPData({
        year: year_month ? dayjs(year_month).year() : dayjs().year(),
        month: year_month ? dayjs(year_month).month() + 1 : dayjs().month() + 1,
        facilityId: facilityIds,
        show_zero_billing_amount: printSettingValues?.includes("2") ? 1 : null,
        user_ids,
        city_ids,
      }),
  })
  const cities_ids = city_ids?.split(",")?.map((val) => +val)
  const userIds = user_ids?.split(",")?.map((val) => +val)
  return (
    <Flex vertical gap={16}>
      <AccordionCard
        defaultActiveKey={["disabed_user_outpatient_benefits_print"]}
        items={[
          {
            key: "disabed_user_outpatient_benefits_print",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Print setting")}
                    width={"138px"}
                  />
                  <CheckBoxGroup
                    value={printSetting || null}
                    onChange={(val: any) => setPrintSetting(val)}
                    items={[
                      {
                        label: t("Print stock number"),
                        value: "1",
                      },
                      {
                        label: t("Print users with a billing amount of 0 yen"),
                        value: "2",
                      },
                      {
                        label: t(
                          `Print the "how many out of how many" column on the statement`,
                        ),
                        value: "3",
                      },
                    ]}
                  />
                </div>
                <div className={"search__content"}>
                  <Button
                    btnText={t("Reflect")}
                    shape={"round"}
                    width={"87px"}
                    onClick={() => {
                      setPrintSettingValues(printSetting)
                      scrollToSelectedElement(listRef)
                      updateParams(
                        { ...query, print_setting: printSetting?.join(",") },
                        pathname,
                      )
                    }}
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />

      <Flex gap={16} wrap={"wrap"}>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          onClick={() =>
            push({
              pathname:
                "/national-health-insurance-billing/prints/disabled-user-outpatient-benefits/",
              query: removeBlankAttributes(query),
            })
          }
        />
        <Button
          btnText={t("Print")}
          shape={"round"}
          type={"primary"}
          iconType={"print"}
          onClick={handlePrint}
        />
      </Flex>

      <AlertBox
        width={"100%"}
        isCollapseAble={false}
        heading={t(
          "If you want to print users with a billing amount of 0 yen, you need to change the operating options.",
        )}
        subHeading={t(
          `Check the "Print users with a billing amount of 0 yen" checkbox and switch the display.`,
        )}
      />
      <DUOBPContainer ref={listRef}>
        {isLoading ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <div ref={printRef}>
            <DUOBPBasicInfo
              loading={isLoading}
              data={response}
              printSettingValues={printSettingValues}
            />
            {response?.data?.map((item, idx) => {
              const userIndividualData = item?.ChildIndividualDataNew?.filter(
                (val) => userIds?.includes(val?.UserId),
              )
              return (
                <React.Fragment key={idx}>
                  {cities_ids?.includes(item?.CityId) && (
                    <BillTableContent loading={isLoading} data={item} />
                  )}
                  {userIndividualData?.map((userData, index) => {
                    if (
                      userData?.c_04_to_c08?.length >
                      PER_PAGE_PRINT_1_SERVICE_CONTENTS
                    ) {
                      const results = []
                      const noOfArrays = Math.ceil(
                        userData?.c_04_to_c08?.length /
                          PER_PAGE_PRINT_1_SERVICE_CONTENTS,
                      )
                      for (let i = 0; i < noOfArrays; i++) {
                        results.push({
                          ...userData,
                          c_04_to_c08: userData?.c_04_to_c08?.slice(
                            i * PER_PAGE_PRINT_1_SERVICE_CONTENTS,
                            (i + 1) * PER_PAGE_PRINT_1_SERVICE_CONTENTS,
                          ),
                        })
                      }

                      return (
                        <>
                          {results?.map((result, idex) => {
                            return (
                              <StatementTableContent
                                loading={isLoading}
                                key={userData?.UserId}
                                data={result}
                                totalPage={userIndividualData?.length}
                                currentPage={idex + 1}
                                printSetting={printSetting}
                                serviceType={item?.Data[0]?.ServiceType}
                              />
                            )
                          })}
                        </>
                      )
                    }
                    return (
                      <StatementTableContent
                        loading={isLoading}
                        key={userData?.UserId}
                        data={userData}
                        totalPage={userIndividualData?.length}
                        currentPage={index + 1}
                        printSetting={printSetting}
                        serviceType={item?.Data[0]?.ServiceType}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </DUOBPContainer>
    </Flex>
  )
}
