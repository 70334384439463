import React from "react"

// packages
import { Flex } from "antd"

// commons | components
import { Box, RadioGroup, SelectInput } from "@project/common"
import { OperationFields } from "../../ServiceProvisionRecord/common/OperationFields"
import { FuriSelectInput } from "./FuriSelectInput"
import { SelectTime } from "./SelectTime"

// contexts
import { useTMBulkEdit } from "../../../context/TMBulkEditFormContext"

// types
import { BulkEditGenericFormProps } from "../types"

// constants
import { PICK_DROP_OPTIONS_INT_VAL, YES_NO_OPTION } from "../../../constants"
import styled from "styled-components"
import {
  BINARY_OPTION,
  ROUTE_OPTIONS,
} from "../../UserTransportManagementContainer/constant"
import { useTranslation } from "react-i18next"

const AlcoholCheckerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 25px;
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;

  .left-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
  .right-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
`
export const BulkEditGenericForm: React.FC<BulkEditGenericFormProps> = ({
  type,
  label,
  values,
  handleFormInputChange,
}) => {
  const { t } = useTranslation()
  const { allVehicles, loadingForm, allDriverStaffs, loadingDriverStaffs } =
    useTMBulkEdit()
  return (
    <div>
      <OperationFields
        wrap={"wrap"}
        gap={"10px 24px"}
        labelWidth={"150px"}
        align={"flex-start"}
        label={label}
      >
        <Box
          className={"TM_pickup-inputs"}
          display={"flex"}
          direction={"column"}
          gap={16}
          w={"calc(100% - 175px)"}
          miw={{
            "0px": "100%",
            md: "auto",
          }}
          maw={1100}
        >
          <div>
            <RadioGroup
              value={values?.is_yes}
              onChange={(val) =>
                handleFormInputChange(
                  type,
                  "is_yes",
                  val.target.value as "1" | "0",
                )
              }
              options={YES_NO_OPTION}
            />
          </div>

          <Box
            className={"all-fields"}
            display={"flex"}
            direction={"column"}
            gap={16}
          >
            {/* [Desired Pickup Time] & [Pickup time] row starts here */}
            <Flex
              justify={"space-between"}
              align={"center"}
              gap={12}
              wrap={"wrap"}
            >
              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={"Desired pick up time"}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <SelectTime
                  disabled={values?.is_yes === "0"}
                  value={values?.user_data?.desired_time}
                  onTimeChange={(val) => {
                    handleFormInputChange(type, "user_data.desired_time", val)
                  }}
                />
              </OperationFields>

              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={"Picked up time"}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <SelectTime
                  disabled={values?.is_yes === "0"}
                  value={values?.user_data?.pickup_time}
                  onTimeChange={(val) =>
                    handleFormInputChange(type, "user_data.pickup_time", val)
                  }
                />
              </OperationFields>
            </Flex>
            {/* [Desired Pickup Time] & [Pickup time] row ends here */}

            {/* [Arrival time] & [Vehicle] row starts here */}
            <Flex
              justify={"space-between"}
              align={"center"}
              gap={12}
              wrap={"wrap"}
            >
              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={"Arrival time"}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <SelectTime
                  value={values?.user_data?.arrival_time}
                  disabled={values?.is_yes === "0"}
                  onTimeChange={(val) =>
                    handleFormInputChange(type, "user_data.arrival_time", val)
                  }
                />
              </OperationFields>

              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={"Vehicle"}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <Box maw={137} miw={137}>
                  <SelectInput
                    name={"vehicle-id"}
                    width={"137px"}
                    placeholder={"--"}
                    loading={loadingForm}
                    options={allVehicles}
                    disabled={values?.is_yes === "0"}
                    value={values?.staff_data?.car_id}
                    onChange={(val) =>
                      handleFormInputChange(type, "staff_data.car_id", val)
                    }
                  />
                </Box>
              </OperationFields>
            </Flex>
            {/* [Arrival time] & [Vehicle] row ends here */}

            <Flex
              justify={"space-between"}
              align={"center"}
              gap={12}
              wrap={"wrap"}
            >
              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={"Place"}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <Box maw={137} miw={137}>
                  <SelectInput
                    name={"place-child-dev"}
                    width={"137px"}
                    placeholder={"--"}
                    options={PICK_DROP_OPTIONS_INT_VAL}
                    disabled={values?.is_yes === "0"}
                    value={values?.user_data?.place}
                    onChange={(val) =>
                      handleFormInputChange(type, "user_data.place", val)
                    }
                  />
                </Box>
              </OperationFields>
              <OperationFields
                gap={"10px 24px"}
                labelWidth={"138px"}
                align={"center"}
                label={t("Rout No.")}
                wrap={"wrap"}
                w={{
                  "0": "100%",
                  md: "auto",
                }}
                flex={{
                  md: 1,
                }}
              >
                <Box maw={137} miw={137}>
                  <SelectInput
                    name={"route-no-id"}
                    width={"137px"}
                    placeholder={"--"}
                    loading={loadingForm}
                    options={ROUTE_OPTIONS}
                    disabled={values?.is_yes === "0"}
                    value={values?.staff_data?.route}
                    onChange={(val) =>
                      handleFormInputChange(type, "staff_data.route", val)
                    }
                  />
                </Box>
              </OperationFields>
            </Flex>
            {/* [Place(After Day]) & [Place (Child dev)] row ends here */}

            {/* [Conductor] row starts here*/}
            <ContentWrapper>
              <div className={"left-content"}>
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  gap={12}
                  wrap={"wrap"}
                >
                  <OperationFields
                    flex={1}
                    align={"center"}
                    gap={"10px 24px"}
                    label={"Conductor"}
                    labelWidth={"138px"}
                    wrap={"wrap"}
                  >
                    <FuriSelectInput
                      name={"escort_staff_id"}
                      options={allDriverStaffs}
                      isLoading={loadingDriverStaffs}
                      value={values?.staff_data?.escort_staff_id}
                      disabled={values?.is_yes === "0"}
                      onChange={(val) =>
                        handleFormInputChange(
                          type,
                          "staff_data.escort_staff_id",
                          val,
                        )
                      }
                    />
                  </OperationFields>
                </Flex>
                {/* [Conductor] row ends here*/}
                {/* [Driver] row starts here*/}
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  gap={12}
                  wrap={"wrap"}
                >
                  <OperationFields
                    flex={1}
                    align={"center"}
                    label={"Driver"}
                    gap={"10px 24px"}
                    labelWidth={"138px"}
                    wrap={"wrap"}
                  >
                    <FuriSelectInput
                      name={"driver_staff_id"}
                      options={allDriverStaffs}
                      isLoading={loadingDriverStaffs}
                      value={values?.staff_data?.driver_staff_id}
                      disabled={values?.is_yes === "0"}
                      onChange={(val) =>
                        handleFormInputChange(
                          type,
                          "staff_data.driver_staff_id",
                          val,
                        )
                      }
                    />
                  </OperationFields>
                </Flex>
                {/* [Driver] row ends here*/}
                {/* [Transportation start time] row starts here */}
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  gap={12}
                  wrap={"wrap"}
                >
                  <OperationFields
                    gap={"10px 24px"}
                    labelWidth={"138px"}
                    align={"center"}
                    label={"Transportation start time"}
                    flex={1}
                    wrap={"wrap"}
                  >
                    <SelectTime
                      value={values?.staff_data?.start_time}
                      disabled={values?.is_yes === "0"}
                      onTimeChange={(val) =>
                        handleFormInputChange(
                          type,
                          "staff_data.start_time",
                          val,
                        )
                      }
                    />
                  </OperationFields>
                </Flex>
                {/* [Transportation start time] row ends here */}
                {/* [Transportation end time] row starts here */}
                <OperationFields
                  gap={"10px 24px"}
                  labelWidth={"138px"}
                  align={"center"}
                  label={"Transportation end time"}
                  flex={1}
                  wrap={"wrap"}
                >
                  <SelectTime
                    value={values?.staff_data?.end_time}
                    disabled={values?.is_yes === "0"}
                    onTimeChange={(val) =>
                      handleFormInputChange(type, "staff_data.end_time", val)
                    }
                  />
                </OperationFields>
                {/* [Transportation end time] row ends here */}
              </div>
              <div className={"right-content"}>
                {/* {"AlcoholChecker"} */}
                <OperationFields
                  flex={1}
                  align={"start"}
                  gap={"10px 24px"}
                  label={t("AlcoholChecker")}
                  labelWidth={"155px"}
                  wrap={"wrap"}
                  direction={"column"}
                >
                  <AlcoholCheckerWrapper>
                    <div className={"power"}>
                      <OperationFields
                        flex={1}
                        align={"center"}
                        gap={"10px 24px"}
                        label={"Power"}
                        labelWidth={"138px"}
                        wrap={"wrap"}
                      >
                        <div>
                          <RadioGroup
                            value={values?.staff_data?.power}
                            onChange={(val) =>
                              handleFormInputChange(
                                type,
                                "staff_data.power",
                                val.target.value as "1" | "2",
                              )
                            }
                            options={BINARY_OPTION}
                          />
                        </div>
                      </OperationFields>
                    </div>
                    <div className={"damage"}>
                      <OperationFields
                        flex={1}
                        align={"center"}
                        gap={"10px 24px"}
                        label={"Damage to the device"}
                        labelWidth={"138px"}
                        wrap={"wrap"}
                      >
                        <div>
                          <RadioGroup
                            value={values?.staff_data?.damage_to_device}
                            onChange={(val) =>
                              handleFormInputChange(
                                type,
                                "staff_data.damage_to_device",
                                val.target.value as "1" | "2",
                              )
                            }
                            options={BINARY_OPTION}
                          />
                        </div>
                      </OperationFields>
                    </div>
                  </AlcoholCheckerWrapper>
                </OperationFields>
                {/* {"AlcoholCheck"} */}
                <OperationFields
                  flex={1}
                  align={"start"}
                  gap={"10px 24px"}
                  label={t("AlcoholCheck")}
                  labelWidth={"138px"}
                  wrap={"wrap"}
                >
                  <RadioGroup
                    value={values?.staff_data?.alcohol_check}
                    onChange={(val) =>
                      handleFormInputChange(
                        type,
                        "staff_data.alcohol_check",
                        val.target.value as "1" | "2",
                      )
                    }
                    options={BINARY_OPTION}
                  />
                </OperationFields>
                {/* {"Visual check"} */}
                <OperationFields
                  flex={1}
                  align={"start"}
                  gap={"10px 24px"}
                  label={"Visual check"}
                  labelWidth={"138px"}
                  wrap={"wrap"}
                >
                  <RadioGroup
                    value={values?.staff_data?.visual_check}
                    onChange={(val) =>
                      handleFormInputChange(
                        type,
                        "staff_data.visual_check",
                        val.target.value as "1" | "2",
                      )
                    }
                    options={BINARY_OPTION}
                  />
                </OperationFields>
              </div>
            </ContentWrapper>
          </Box>
        </Box>
      </OperationFields>
    </div>
  )
}
