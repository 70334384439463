import { CheckBox, Table, theme } from "@project/common"
import { Flex } from "antd"
import { TableProps } from "antd/es/table"
import { useTranslation } from "react-i18next"
import { SortIcon } from "../../../../components"
export const PrintProxyAcknowledgementListTable = ({
  dataSource,
  loading,
  handleSingleCheckAndUncheck,
  isAllChecked,
}: {
  dataSource: any[]
  loading?: boolean
  handleSingleCheckAndUncheck: (values: {
    userId: number
    checked: boolean
    tableRows?: "ALL" | "NONE"
  }) => void
  isAllChecked?: boolean
}) => {
  const { t } = useTranslation()
  const columns: TableProps<any>["columns"] = [
    {
      title: () => {
        return (
          <CheckBox
            checked={isAllChecked}
            onChange={() =>
              handleSingleCheckAndUncheck({
                userId: 0,
                checked: !isAllChecked,
                tableRows: isAllChecked ? "NONE" : "ALL",
              })
            }
          />
        )
      },
      key: "uncheckAll",
      render: (row) => {
        return (
          <CheckBox
            checked={row?.isChecked}
            onChange={({ target: { checked } }) =>
              handleSingleCheckAndUncheck({
                userId: row?.user_id,
                checked,
              })
            }
          />
        )
      },
    },
    {
      key: "1",
      align: "center",
      title: (args) => {
        return (
          <Flex gap={2} justify={"center"} align={"center"}>
            <span>{t("Parent's name (Hiragana)")}</span>
            <SortIcon
              ascendingColor={
                args?.sortOrder === "ascend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
              descendingColor={
                args?.sortOrder === "descend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
            />
          </Flex>
        )
      },
      dataIndex: "user_name",
      sorter: (a, b) => a?.user_name?.localeCompare(b.user_name),
      sortIcon: () => null,
      render: () => <span>{`--`}</span>,
    },
    {
      key: "2",
      align: "center",
      title: (args) => {
        return (
          <Flex gap={2} justify={"center"} align={"center"}>
            <span>{t("User’s name (Hiragana)")}</span>
            <SortIcon
              ascendingColor={
                args?.sortOrder === "ascend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
              descendingColor={
                args?.sortOrder === "descend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
            />
          </Flex>
        )
      },
      dataIndex: "parent_name",
      sorter: (a, b) => a?.parent_name?.localeCompare(b.parent_name),
      sortIcon: () => null,
      render: (text, row) => (
        <span>{`${text || ""} (${row?.user_name_furigana || ""})`}</span>
      ),
    },
    {
      key: "3",
      align: "center",
      title: (args) => {
        return (
          <Flex gap={2} justify={"center"} align={"center"}>
            <span>{t("Beneficiary certificate number")}</span>
            <SortIcon
              ascendingColor={
                args?.sortOrder === "ascend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
              descendingColor={
                args?.sortOrder === "descend"
                  ? theme?.colors?.action
                  : theme?.colors?.text
              }
            />
          </Flex>
        )
      },
      dataIndex: "receiving_certificate_number",
      sorter: (a, b) =>
        a?.receiving_certificate_number?.localeCompare(
          b.receiving_certificate_number,
        ),
      sortIcon: () => null,
    },
  ]
  return (
    <Table
      dataSource={dataSource || []}
      columns={columns || []}
      loading={loading}
      scroll={{ x: "max-content" }}
    />
  )
}
