import { theme } from "@project/common"
import styled, { css } from "styled-components"
const TdCellStyle = css`
  tbody {
    tr {
      page-break-inside: avoid !important;
      td {
        border: 1px solid ${theme.colors.text};
        padding: 10px;
        &:first-child {
          width: 200px;
        }
        #transportation_info {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`
export const PrintPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .monitoring_detail_summary {
    table#monitoring_detail_summary--table {
      width: 100%;
      border-collapse: collapse;
      ${TdCellStyle};
    }
  }
  .monitoring_detail_items {
    table#monitoring_detail_items--table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            border: 1px solid ${theme.colors.text};
            font-weight: normal;
            padding: 10px;
          }
        }
      }
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.colors.text};
            padding: 10px;
            text-align: center;
            .list__item {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              justify-content: center;
              .dot {
                width: 5px;
                height: 5px;
                background: #000;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  .monitoring_detail_goals,
  .monitoring_detail_hopes {
    table#monitoring_detail_goals--table,
    table#monitoring_detail_hopes--table {
      width: 100%;
      border-collapse: collapse;
      ${TdCellStyle};
    }
  }
  .monitoring_detail__print_footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;
    .mf_left {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .content__container {
        display: flex;
        align-items: center;
        column-gap: 8px;
        .bordered_content {
          border: 1px solid ${theme.colors.text};
          display: flex;
          align-items: center;
          column-gap: 16px;
          padding: 8px 6px;
          border-radius: 4px;
        }
      }
    }
    .mf_right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .ads_manager {
        display: flex;
        align-items: center;
        gap: 8px;
        .txt {
          display: flex;
          gap: 8px;
          align-items: center;
          border-bottom: 1px solid ${theme.colors.text};
          padding-bottom: 5px;
        }
      }
    }
    #signature__img {
      height: auto;
      max-height: 26px;
      max-width: 163px;
    }

    @media print {
      .sign__icon {
        display: none !important;
      }
    }
  }
`
