import {
  AccordionCard,
  Button,
  Card,
  CheckBox,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  PageTitle,
  SelectInput,
  Table,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import { notification } from "antd"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import type { ColumnsType } from "antd/es/table"
import dayjs, { Dayjs } from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { ServiceType } from "../../components/ServiceType"
import { FuriganaAlphabetsOption, PAGE_SIZE } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { fetchUserFacility } from "../../services/userFacility"
import { UserFacilityColumn } from "../../types"
import { filterWithHiragana } from "../../utils"
import {
  TMiniContainer,
  TableContainer,
} from "./UserFacilityScheduleRegistrationStatus.style"
const UserFacilityScheduleRegistrationStatus = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
const UserFacilityScheduleRegistrationStatusComp = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const listContent = useRef<any>(null)
  const {
    page: currentPage,
    year,
    month,
    facility: queryFacility,
    service_type01: queryService1,
    service_type02: queryService2,
    service_type03: queryService3,
    user_id: queryUser,
    application_status: queryApplicationStatus,
  } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const [page, setPage] = useState(currentPage || 1)
  const { facilities, isOwner } = useContext(AuthContext)

  const defaultSelectedFacility = queryFacility
    ? queryFacility?.toString()?.split(",")
    : []
  const [checkedFacility, setCheckedFacility] = useState<CheckboxValueType[]>(
    defaultSelectedFacility || [],
  )
  const [service_type01, setServiceType01] = useState(
    queryService1 == "true" ? true : false || false,
  )
  const [service_type02, setServiceType02] = useState(
    queryService2 == "true" ? true : false || false,
  )
  const [service_type03, setServiceType03] = useState(
    queryService3 == "true" ? true : false || false,
  )
  const [userFurigana, setUserFurigana] = useState("")
  const [user_id, setUserID] = useState(
    queryUser && !isNaN(+queryUser) ? +queryUser : null,
  )
  const [years, setYears] = useState(Number(year) || dayjs().year())
  const [months, setMonths] = useState(Number(month) || dayjs().month() + 1)
  const [application_status, setApplicationStatus] = useState(
    queryApplicationStatus && !isNaN(+queryApplicationStatus)
      ? +queryApplicationStatus
      : null,
  )
  const [searchParams, setSearchParams] = useState<any>({
    year: years,
    month: months,
    facility: defaultSelectedFacility,
    service_type01: service_type01,
    service_type02: service_type02,
    service_type03: service_type03,
    user_id: user_id,
    application_status: application_status,
  })
  //fetch all user facility
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>(
    ["user-facility-schedule-registration-status", searchParams, page],
    () =>
      fetchUserFacility({
        page,
        pageSize: PAGE_SIZE,
        ...searchParams,
        facility: Array.isArray(searchParams?.facility)
          ? searchParams?.facility?.toString()
          : searchParams?.facility,
      }),

    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return data
          }),
          count: response?.count,
        }
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    },
  )

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setPage(1)
    updateParams(queries, "/user-facility-schedule-registration-status")
    scrollToSelectedElement(listContent)
  }

  const onSearch = () => {
    const values = {
      facility: checkedFacility?.toString(),
      service_type01: service_type01,
      service_type02: service_type02,
      service_type03: service_type03,
      user_id: user_id,
      application_status: application_status,
      year: years,
      month: months,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(params)
    handleSearchAndResetParams(params)
  }

  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    router.push(
      {
        pathname: "/user-facility-schedule-registration-status",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
    setInitialValue: true,
  })

  const columns: ColumnsType<any> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      width: "100px",
      align: "center",
      render: (_, data) => {
        const { user_id } = data
        return (
          <div className={"link_text"}>
            <Link
              href={`users/profile/${user_id}/application-management/edit?year=${
                Number(year) || dayjs().year()
              }&month=${Number(month) || dayjs().month() + 1}`}
            >
              <a className={isOwner ? "enabled-link" : "disabled-link"}>
                {t("Edit")}
              </a>
            </Link>
          </div>
        )
      },
    },
    {
      title: t("User name"),
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return <span>{t(data?.username)}</span>
      },
    },
    {
      title: t("Facility name"),
      align: "center",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return <span>{t(data?.facility_name)}</span>
      },
    },
    {
      title: t("Use Service"),
      align: "center",
      dataIndex: "",
      key: "",
      width: "167px",
      render: (_, data) => {
        return (
          <div className={"service_type_btns"}>
            {ServiceType(data?.service)}
          </div>
        )
      },
    },
    {
      title: t("Benefit payment amount"),
      align: "center",
      dataIndex: "",
      key: "",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return <span>{t(data?.benefit_payment_amount)}</span>
      },
    },
    {
      title: t("Contract Amount"),
      align: "center",
      dataIndex: "",
      key: "",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return <span>{t(data?.contract_amount)}</span>
      },
    },
    {
      title: t("No. of Application"),
      align: "center",
      dataIndex: "",
      key: "",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return <span>{t(data?.number_of_application)}</span>
      },
    },
    {
      title: t("Last update date"),
      align: "center",
      dataIndex: "",
      key: "",
      width: "167px",
      render: (_, data: UserFacilityColumn) => {
        return (
          <span>
            {dayjs(data?.last_update_date).isValid()
              ? dayjs(data?.last_update_date).format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </span>
        )
      },
    },
  ]

  return (
    <>
      <PageTitle
        title={t("{{year}}年{{month}}月’s User schedule registration status", {
          year: searchParams.year,
          month: searchParams.month,
        })}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
      />
      <UserFacilityScheduleRegistrationStatus>
        <AccordionCard
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <OperationSearchContent>
                  <div className={"search__content flex-start"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Displaying Facilities")}
                        width={"150px"}
                      />
                    </div>
                    <FacilityCheckbox
                      options={facilities}
                      value={checkedFacility}
                      handleCheckUncheckAll={(type: "check" | "uncheck") => {
                        if (type === "check") {
                          const ids = facilities?.map((v) => v?.value)
                          setCheckedFacility(ids)
                        }
                        if (type === "uncheck") {
                          setCheckedFacility([])
                        }
                      }}
                      onChange={(list: CheckboxValueType[]) => {
                        setCheckedFacility(list)
                      }}
                    />
                  </div>
                  <div className={"search__content "}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Use Service")}
                        width={"150px"}
                      />
                    </div>
                    <CheckBox
                      name={"service_type01"}
                      label={t("Employment Type A")}
                      value={service_type01}
                      checked={service_type01}
                      onChange={({ target: { checked } }) =>
                        setServiceType01(checked)
                      }
                    />
                    <CheckBox
                      name={"service_type02"}
                      label={t("Employment Type B")}
                      value={service_type02}
                      checked={service_type02}
                      onChange={({ target: { checked } }) =>
                        setServiceType02(checked)
                      }
                    />
                    <CheckBox
                      name={"service_type03"}
                      label={t("Employment Transition Support")}
                      value={service_type03}
                      checked={service_type03}
                      onChange={({ target: { checked } }) =>
                        setServiceType03(checked)
                      }
                    />
                  </div>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Year month")}
                      width={"150px"}
                    />

                    <DatePicker
                      style={{
                        width: "158px",
                      }}
                      picker={"month"}
                      date={dayjs([years, months - 1])}
                      onDateChange={(date: Dayjs) => {
                        setYears(dayjs(date).year())
                        setMonths(dayjs(date).month() + 1)
                      }}
                      format={"YYYY年MM月"}
                    />
                  </div>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Application Status")}
                      width={"150px"}
                    />
                    <SelectInput
                      name={"application_status"}
                      size={"large"}
                      placeholder={"--"}
                      width={"150px"}
                      value={application_status}
                      onChange={(val) => setApplicationStatus(val)}
                      options={[
                        {
                          label: t("All"),
                          value: null,
                        },
                        {
                          label: t("Applied "),
                          value: 1,
                        },
                        {
                          label: t("Not Applied"),
                          value: 2,
                        },
                      ]}
                    />
                  </div>
                  <div className={"search__content"}>
                    <LabelTextWithLeftArrow
                      label={t("User name")}
                      width={"150px"}
                    />
                    <SelectInput
                      value={userFurigana || null}
                      name={"furigana"}
                      options={FuriganaAlphabetsOption}
                      onChange={(val) => setUserFurigana(val)}
                      width={"80px"}
                      shortSelector
                      placeholder={"--"}
                    />
                    <SelectInput
                      name={"user_id"}
                      size={"large"}
                      placeholder={"--"}
                      width={"282px"}
                      value={user_id}
                      onChange={(v) => setUserID(v)}
                      options={
                        filterWithHiragana({
                          options: userData || [],
                          furigana: userFurigana || "",
                        }) || []
                      }
                    />
                  </div>
                  <div className={"search__content btns"}>
                    <Button
                      btnText={t("Change display")}
                      shape={"round"}
                      onClick={onSearch}
                    />
                  </div>
                </OperationSearchContent>
              ),
            },
          ]}
        />
        <Card title={t("List")}>
          <TableContainer>
            <TMiniContainer>
              <img src={"./assets/icons/pink.svg"} alt={"icon"} />
              <span>
                {t(
                  "Users with recipient certificate unregistered (experience)",
                )}
              </span>
            </TMiniContainer>
          </TableContainer>

          <div ref={listContent}>
            <Table
              current={page}
              columns={columns}
              pageSize={PAGE_SIZE}
              total={response?.count || 0}
              dataSource={response?.data}
              showPaginationOf={"both"}
              scroll={{ x: 1200 }}
              onChange={handlePaginationChange}
              cellPaddingBlock={12}
              loading={isFetching || isLoading}
            />
          </div>
        </Card>
      </UserFacilityScheduleRegistrationStatus>
    </>
  )
}

export { UserFacilityScheduleRegistrationStatusComp }
