import React from "react"

const HomeIcon = () => {
  return (
    <svg
      width={"81"}
      height={"81"}
      viewBox={"0 0 81 81"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <rect
        x={"1"}
        y={"1"}
        width={"79"}
        height={"79"}
        rx={"39.5"}
        fill={"white"}
      />
      <rect
        x={"1"}
        y={"1"}
        width={"79"}
        height={"79"}
        rx={"39.5"}
        stroke={"#E2F4FF"}
      />
      <path
        d={
          "M59.1998 59.1998H21.7998V32.1838L40.2138 21.7998L59.1998 32.1838V59.1998Z"
        }
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M32.1836 42.5664H36.3416"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M32.1836 48.8145H36.3416"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M44.6572 42.5664H48.8152"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M44.6572 48.8145H48.8152"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M21.7998 33.1953H46.1758"}
        stroke={"#0782C8"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}

export default HomeIcon
