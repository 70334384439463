import React from "react"

// packages
import cuid from "cuid"
import { t } from "i18next"
import { Flex } from "antd"
import styled from "styled-components"

// commons | component
import { Box, Button, theme } from "@project/common"
import { SelectTime } from "../../../../TransportMgmtBulkEditInternal/common/SelectTime"

// types
import { BreakTime } from "../../../../../types"

export const BreakTimeFieldsContainer = styled(Box)`
  .delete-button {
    padding: 4px 6px;
    color: ${theme.colors.error};
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.error} !important;
      border: 1px solid transparent !important;
      filter: brightness(110%);
    }
  }
  .footer-action-btn {
    width: 100%;
    justify-content: space-between;
    .alert-text {
      color: ${theme.colors.error};
      font-size: 12px;
    }
  }
`
const MultipleBreakTime = ({
  rowIndex,
  breakTimeList,
  handleValueChange,
}: {
  breakTimeList: BreakTime[]
  rowIndex: number
  handleValueChange: ({
    rowId,
    key,
    value,
  }: {
    rowId: any
    key: any
    value: any
  }) => void
}) => {
  const handleDelete = (id: string) => {
    const newBreakTimeList = breakTimeList.filter((item) => item.id !== id)
    handleValueChange({
      rowId: rowIndex,
      key: "break_time_list",
      value: newBreakTimeList,
    })
  }

  const handleAddition = () => {
    const newBreakTime: BreakTime = {
      id: cuid(),
      start_time: "",
      end_time: "",
    }
    const newBreakTimeList = [...breakTimeList, newBreakTime]
    handleValueChange({
      rowId: rowIndex,
      key: "break_time_list",
      value: newBreakTimeList,
    })
  }

  const onStartTimeChange = (index: number, value: string) => {
    const newBreakTimeList = breakTimeList.map((item, i) =>
      i === index ? { ...item, start_time: value } : item,
    )
    handleValueChange({
      rowId: rowIndex,
      key: "break_time_list",
      value: newBreakTimeList,
    })
  }

  const onEndTimeChange = (index: number, value: string) => {
    const newBreakTimeList = breakTimeList.map((item, i) =>
      i === index ? { ...item, end_time: value } : item,
    )
    handleValueChange({
      rowId: rowIndex,
      key: "break_time_list",
      value: newBreakTimeList,
    })
  }

  return (
    <BreakTimeFieldsContainer display={"grid"} gap={16} mt={8}>
      <Box display={"flex"} direction={"column"} gap={10}>
        {breakTimeList?.map((item, i) => {
          return (
            <Box key={item.id} display={"flex"} gap={8} align={"center"}>
              <Flex align={"center"} gap={"5px"} wrap={"wrap"}>
                <SelectTime
                  value={item.start_time}
                  onTimeChange={(val) => {
                    onStartTimeChange(i, val)
                  }}
                />
                {"~"}
                <SelectTime
                  disabled={!item.start_time}
                  value={item.end_time}
                  onTimeChange={(val) => {
                    onEndTimeChange(i, val)
                  }}
                  item={item}
                  isEndTime={true}
                />
                {i !== 0 && (
                  <Button
                    className={"delete-button"}
                    btnText={t("Delete")}
                    type={"link"}
                    onClick={() => handleDelete(item.id)}
                  />
                )}
              </Flex>
            </Box>
          )
        })}
      </Box>
      <Flex align={"center"} className={"footer-action-btn"} gap={8}>
        <Button
          btnText={t("Add")}
          shape={"round"}
          type={"grayed"}
          className={"add-button"}
          disabled={breakTimeList?.length === 5}
          onClick={handleAddition}
        />

        {breakTimeList?.length === 5 && (
          <p className={"alert-text"}>
            {t("*Cannot add more than five break time")}
          </p>
        )}
      </Flex>
    </BreakTimeFieldsContainer>
  )
}

export default MultipleBreakTime
