import { API } from "@project/common"

// ## Register body type
type fileAttachmentRecord = Record<`file_image_${number}`, string>

export type MMRegisterBody = {
  user_id: number
  addition_type: number
  minutes_title: string
  save_staff_id: number
  event_date: string
  event_start_time: string
  event_end_time: string
  staffs: number[]
  corporate_name_1: string
  corporate_person_name_1: string
  corporate_name_2: string
  corporate_person_name_2: string
  consultation_support_id: string
  consultation_support_person_name: string
  draft_save_flag: boolean
  free_items: FreeItem[]
  is_reason_for_absence: boolean
  reason_for_absence: string
} & fileAttachmentRecord

export interface FreeItem {
  free_title: string
  free_remarks: string
}

// ## Get many response type
export interface GetManyResponseDataProps {
  count: number
  data: MMResponseData[]
}

export interface GetOneResponseData {
  data: MMResponseData
}

export interface MMResponseData {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  user_id: number
  user_name: string
  addition_type: number
  minutes_title: string
  save_staff_id: number
  event_date: string
  event_start_time: string
  event_end_time: string
  corporate_name_1?: string
  corporate_person_name_1?: string
  corporate_name_2?: string
  corporate_person_name_2?: string
  consultation_support_id?: string
  consultation_support_person_name?: string
  file_image_1: string
  file_image_2: string
  file_image_3: string
  file_image_4: string
  draft_save_flag: boolean
  consent_date_digital_signature_image: any
  guardian_digital_signature_image: any
  consult_digital_signature_image: any
  corresponding_digital_signature_image: any
  date_confirmed_digital_signature_image: any
  family_digital_signature_image: any
  reason_for_absence: any
  is_reason_for_absence: boolean
  service_type: number
  facility_id: any
  facility_name: any
  facility_name_short: any
  receiving_certificate_number: string
  staff_infos: StaffInfo[]
  free_items: FreeItem[]
  various_addition_free_items: string
  various_addition_free_items_object: any
  parent_name: string
  consultation_support_name?: string
  recorder_staff_name: any
}

export interface StaffInfo {
  various_addition_staff_id: any
  staff_id: number
  staff_name: string
  staff_name_furigana: string
}

// ## Get many params type
export type MMGetManyParams = {
  page?: string
  pageSize?: string
  facility_ids?: string
  keyword?: string
  start_date_from?: string
  start_date_to?: string
  use_service?: string
}

export const getManyMinutesMgmt = async (
  Params?: MMGetManyParams,
): Promise<GetManyResponseDataProps> => {
  const sanitizedParams = {}
  Object.keys(Params).forEach((key) => {
    if (Params[key]) {
      sanitizedParams[key] = Params[key]
    }
  })
  return API.get(`/various-addition/list`, { params: sanitizedParams })
}

export const getAllMinutesMgmt = async (
  Params?: MMGetManyParams,
): Promise<GetManyResponseDataProps> => {
  const sanitizedParams = {}
  Object.keys(Params).forEach((key) => {
    if (Params[key]) {
      sanitizedParams[key] = Params[key]
    }
  })
  return API.get(`/various-addition`, { params: sanitizedParams })
}

export const getOneMinutesMgmt = async (
  id: string,
): Promise<GetOneResponseData> => {
  return API.get(`/various-addition/${id}`)
}

export const getSingleMinuteAddition = async (
  id: string,
): Promise<GetOneResponseData> => {
  return API.get(`/various-addition/${id}`)
}
interface SigntureReqbj {
  id: string | number
  consent_date_digital_signature_image: string
  guardian_digital_signature_image: string
  user_name_digital_signature_image: string
  user_name_bottom_digital_signature_image: string
  consent_date_bottom_digital_signature_image: string
  date_confirmed_digital_signature_image: string
  family_digital_signature_image: string
  corresponding_digital_signature_image: string
  consult_digital_signature_image: string
  corporate_digital_signature1_image: string
  corporate_digital_signature2_image: string
}

export const updateAndCreateSignatureOfMinuteAddition = (
  values: SigntureReqbj,
) => {
  const { id } = values

  return API.put(`/various-addition/update-signature/${id}`, values)
}

export const registerMinutesMgmt = async (
  values: MMRegisterBody,
): Promise<unknown> => {
  return API.post(`/various-addition`, values)
}

export const updateMinutesMgmt = async ({
  id,
  values,
}: {
  id: string
  values: MMRegisterBody
}): Promise<unknown> => {
  return API.put(`/various-addition/${id}`, values)
}
