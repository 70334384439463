import {
  AccordionCard,
  Button,
  ButtonWithRightIcon,
  CheckBox,
  CircleButton,
  theme,
} from "@project/common"
import { Empty, Flex } from "antd"
import { t } from "i18next"
import { useState } from "react"
import { UseAssessmentSettings } from "../../context"
import { AssessmentSelectedItem } from "../../types"
import { GridContent, StyledChildren } from "./AssessmentSettingsCategory.style"
import { AssessmentSettingsCategoryItem } from "./AssessmentSettingsCategoryItem"
import { AssessmentSettingsModal } from "./AssessmentSettingsModal"
import { AssessmentSettingsSortableItem } from "./AssessmentSettingsSortableItem"

//modal information
const displayOffTitle =
  "Do you want to hide the category “{{category_Name}}” ? "
const displayOffConfirmMessage =
  'All items belonging to this category will no longer be displayed on the recording screen and will be moved to the "hidden item list".You can also make it "visible" again in the "hidden item list".'
const displayOnTitle = "Do you want to display the category?"
const displayOnConfirmMessage =
  "All items belonging to this category will be displayed on the recording screen."

export const AssessmentSettingsCategory = ({
  category,
  settingId,
  hasWriteAccess,
}: {
  category?: any
  settingId: number
  hasWriteAccess: boolean
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] =
    useState<AssessmentSelectedItem | null>(null)
  const {
    toggleSorting,
    handleCategoryItemSort,
    saveAssessmentSortData,
    currentlySortingDataInfo,
    isMutating,
  } = UseAssessmentSettings()

  return (
    <>
      <AccordionCard
        defaultActiveKey={["categories"]}
        boxShadow={"none"}
        bordered
        ghost={false}
        items={[
          {
            key: "categories",
            label: category?.title ? t(category?.title) : "",
            children: (
              <StyledChildren vertical gap={16}>
                <Flex gap={12} wrap={"wrap"} justify={"space-between"}>
                  <Flex gap={12} wrap={"wrap"}>
                    <Button
                      btnText={t("Add Item")}
                      shape={"round"}
                      iconType={"plus-circle"}
                      type={"primary"}
                      disabled={
                        !hasWriteAccess ||
                        (isMutating && currentlySortingDataInfo?.categoryId)
                      }
                      onClick={() => {
                        setOpenModal(true)
                        setSelectedItem({
                          categoryId: category?.id,
                          addItem: true,
                          operation: "add",
                          type: "item",
                        })
                      }}
                    />
                    <Button
                      btnText={t("Edit")}
                      shape={"round"}
                      width={"67px"}
                      disabled={
                        !hasWriteAccess ||
                        !category?.show_display ||
                        (isMutating && currentlySortingDataInfo?.categoryId)
                      }
                      onClick={() => {
                        setOpenModal(true)
                        setSelectedItem({
                          categoryId: category?.id,
                          title: category?.title,
                          operation: "edit",
                          type: "category",
                        })
                      }}
                    />
                    {!category.isSorting && (
                      <CircleButton
                        disabled={
                          !category?.show_display ||
                          !category?.assessment_item?.length
                        }
                        Icon={
                          <img
                            src={"./assets/icons/basil_sort-outline.svg"}
                            alt={"icon"}
                          />
                        }
                        borderColor={theme.colors.action}
                        onClick={() =>
                          toggleSorting({
                            settingId,
                            categoryId: category?.id,
                            isSorting: true,
                          })
                        }
                      />
                    )}
                  </Flex>
                  {category?.isSorting && (
                    <Flex wrap={"wrap"} gap={8}>
                      <Button
                        btnText={t("Cancel")}
                        shape={"round"}
                        iconType={"remove"}
                        danger
                        disabled={
                          isMutating && currentlySortingDataInfo?.categoryId
                        }
                        onClick={() => {
                          toggleSorting({
                            settingId,
                            categoryId: category?.id,
                            isSorting: false,
                          })
                        }}
                      />
                      <ButtonWithRightIcon
                        btnText={t("Save sort")}
                        shape={"round"}
                        type={"primary"}
                        isLoading={
                          isMutating && currentlySortingDataInfo?.categoryId
                        }
                        rightIcon={
                          <img
                            src={"./assets/icons/sort-icon-white.svg"}
                            alt={"icon"}
                          />
                        }
                        onClick={() => saveAssessmentSortData(settingId)}
                      />
                    </Flex>
                  )}
                </Flex>
                <div style={{ width: "fit-content" }}>
                  <CheckBox
                    label={t("Display")}
                    checked={category?.show_display}
                    value={category?.show_display}
                    onChange={({ target: { checked } }) => {
                      setOpenModal(true)
                      setSelectedItem({
                        categoryId: category?.id,
                        value: checked,
                        modalType: "confirm",
                        title: category?.title,
                        show_display: category?.show_display,
                      })
                    }}
                    disabled={!hasWriteAccess}
                  />
                </div>
                {category?.show_display ? (
                  <>
                    <GridContent>
                      {category?.assessment_item
                        ?.filter((item) => item?.show_display)
                        ?.map((item, index) => (
                          <>
                            {category?.isSorting ? (
                              <AssessmentSettingsSortableItem
                                label={t(item?.title)}
                                isFirstItem={index === 0}
                                totalItems={
                                  category?.assessment_item?.length || 0
                                }
                                isLastItem={
                                  index ===
                                  category?.assessment_item?.length - 1
                                }
                                onSort={(dir) =>
                                  handleCategoryItemSort({
                                    settingId,
                                    categoryId: category?.id,
                                    itemId: item?.id,
                                    dir,
                                  })
                                }
                                hasWriteAccess={hasWriteAccess}
                              />
                            ) : (
                              <AssessmentSettingsCategoryItem
                                key={index}
                                item={item}
                                settingId={settingId}
                                categoryId={category?.id}
                              />
                            )}
                          </>
                        ))}
                    </GridContent>
                  </>
                ) : (
                  <Empty></Empty>
                )}
              </StyledChildren>
            ),
          },
        ]}
      />

      {/* //add-edit modal */}
      {openModal && (
        <AssessmentSettingsModal
          modalType={selectedItem?.modalType ? "confirm" : "normal"}
          confirmMessage={
            selectedItem?.value
              ? t(displayOnConfirmMessage)
              : t(displayOffConfirmMessage)
          }
          label={
            selectedItem?.modalType
              ? ""
              : !selectedItem?.addItem
                ? t("Category name")
                : t("Item Name")
          }
          title={
            selectedItem?.modalType
              ? selectedItem?.value
                ? t(displayOnTitle)
                : t(displayOffTitle, {
                    category_Name: selectedItem?.title,
                  })
              : !selectedItem?.addItem
                ? `${t("Edit")} ${selectedItem?.title}`
                : t("Creating a new item")
          }
          open={openModal}
          selectedItem={selectedItem}
          settingId={settingId}
          onCancel={() => {
            setSelectedItem(null)
            setOpenModal(false)
          }}
        />
      )}
    </>
  )
}
