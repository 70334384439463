import {
  AccordionCard,
  Button,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
} from "@project/common"
import { useTranslation } from "react-i18next"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { IRevenueManagementOperation } from "../../types/RevenueManagement.types"
import { RevenueManagementOperationContainer } from "./RevenueManagement.styles"
import { useEffect, useState } from "react"

export const RevenueManagementOperation = (
  props: IRevenueManagementOperation,
) => {
  const { t } = useTranslation()

  const { searchParams, onDisplayChange, facilities } = props

  const [searchOptions, setSearchOptions] = useState(searchParams)

  useEffect(() => {
    setSearchOptions(searchParams)
  }, [searchParams])

  return (
    <RevenueManagementOperationContainer>
      <AccordionCard
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Display facility")}
                      width={"138px"}
                    />
                  </div>
                  <FacilityCheckbox
                    options={facilities}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilities?.map((v) => v?.value)
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: ids,
                        })
                      }
                      if (type === "uncheck") {
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: [],
                        })
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setSearchOptions({
                        ...searchOptions,
                        facilityIds:
                          list?.length == 0 ? [] : list?.toString().split(","),
                      })
                    }}
                    value={searchOptions.facilityIds}
                  />
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Year Month")}
                      width={"138px"}
                    />
                  </div>
                  <DatePicker
                    picker={"month"}
                    onDateChange={(date: Dayjs) => {
                      const year = date.year()
                      const month = date.month() + 1
                      setSearchOptions({
                        ...searchOptions,
                        year,
                        month,
                      })
                    }}
                    date={dayjs([searchOptions.year, searchOptions.month - 1])}
                    format={"YYYY年MM月"}
                    placeholder={"--"}
                  />
                </div>
                <div className={"search__content btns"} style={{ gap: "10px" }}>
                  <Button
                    btnText={t("Display Change")}
                    size={"large"}
                    shape={"round"}
                    onClick={() => onDisplayChange(searchOptions)}
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </RevenueManagementOperationContainer>
  )
}
