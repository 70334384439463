import { theme } from "@project/common"
import styled from "styled-components"

export const Wrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .office__pobox_content {
      .right {
        a {
          text-decoration: underline;
          color: ${theme.colors.link};
          font-size: 14px;
          font-weight: 400;
        }
        .post_box_number {
          margin-bottom: 8px;
        }
      }
      display: flex;

      justify-content: space-between;
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        row-gap: 0.5em;
        align-items: flex-start;
      }
      .autofill_address {
        height: 40px !important;
      }
    }
  }
  .action_btns {
    display: flex;
    justify-content: space-between;
  }
`
