import { API, omit } from "@project/common"
import { BinaryType } from "../containers/UserTransportMgmtPrint/types"

export interface SystemStatusMgmtDataType {
  data: SystemStatusMgmtData
  average_user: number //
  transision_count: number
  has_contract: boolean
  attendance_dates: {
    date: string // 2024-04-05
    present_count: number // 98
  }[]
  average_usage_period: any
  severly_ill_percent: any
}
type EightOptions = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8"

export type StaffArrangement = "1" | "2" | "3"
export type RetentionRate = EightOptions
export type AvarageWorkingHour = EightOptions
export type ProductionActivity = "1" | "2" | "3" | "4" | "5" | "6"
export type WaysOfWorking = "1" | "2" | "3"
export type ImprovementSupport = "1" | "2" | "3"
export type RegionalCollaborationActivities = "1" | "2"
export type WelfareSpecialist = "0" | "1" | "2" | "3"
export type NursingStaff = "0" | "1" | "2" | "3" | "4"
export type SpecificTreatment = "0" | "1" | "2"
export type TransportAddition = "0" | "1" | "2"
export type StaffLack = "0" | "1" | "2"
export type CompensationSystem = "1" | "2"
export type AverageMonthlyWage = EightOptions | "9"
export type IllnessSpectrum = "0" | "1" | "2"
export type LoadUnit = "PERCENT" | "YEN"
export type LanguageDisabilityAddition = "0" | "1" | "2"
export type EmergencyAcceptanceAddition = "0" | "1" | "2"
export interface SystemStatusMgmtData {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  facility_id: number
  facility: any
  year: number
  month: number
  service_type_id: number
  avarage_previous_user: number
  opening_date?: string
  avarage_working_hour?: AvarageWorkingHour
  provisional_number_of_user?: number
  capacity: number
  is_burden_reduction: boolean
  burden_reduction_load: number
  burden_reduction_load_unit: LoadUnit
  score_document: string
  emergency_acceptance_addition: EmergencyAcceptanceAddition
  intensive_support_addition: BinaryType
  reginal_collaboration_meeting_addition_I: BinaryType
  reginal_collaboration_meeting_addition_II: BinaryType
  brain_dysfunction_support_addition: BinaryType
  abuse_prevention_measures_subtraction: BinaryType
  physical_restraint_abolition_subtraction: BinaryType
  business_plan_not_established_subtraction: BinaryType
  information_disclosure_unreported_subtraction: BinaryType

  staff_arrangement?: StaffArrangement
  compensation_system?: CompensationSystem
  average_monthly_wage?: AverageMonthlyWage
  retension_rate?: RetentionRate
  production_activity?: ProductionActivity
  ways_of_working?: WaysOfWorking
  improvement_support?: ImprovementSupport
  regional_collaboration_activities?: RegionalCollaborationActivities
  improvement_plan?: string
  improvement_ability?: string
  welfare_specialist_staff_placement_addition?: WelfareSpecialist
  employement_transition_support_addition?: BinaryType
  severly_ill_support_addition?: IllnessSpectrum
  wage_improvement_instructor_placement_addition?: string
  nursing_staff_treatment_improvement_addition?: NursingStaff
  people_with_disability_support_addition?: boolean
  specific_treatment_improvement_addition?: SpecificTreatment
  base_up_addition?: BinaryType
  transport_addition?: TransportAddition
  meal_addition?: BinaryType
  language_disability_addition?: LanguageDisabilityAddition
  target_wage_achievement_instructor_addition?: BinaryType
  target_wage_achievement_addition?: BinaryType
  support_training_completion_addition?: string
  lack_of_service_provision_staff_subtraction?: StaffLack
  lack_of_service_manager_subtraction?: StaffLack
  employment_support_facilities_subtraction?: string
  excess_capacity_subtraction?: BinaryType
  self_evaluation_unpublished_subtraction?: string
  usage_period_subtraction?: BinaryType
  short_time_use_subtraction?: string
  link_transport_with_attendance?: boolean
  training_facility?: boolean
}

type Params = {
  year: number
  month: number
  facility_id: number
}
type getSystemStatusMgmtServiceFn = (
  params: Params,
) => Promise<SystemStatusMgmtDataType>

type updateSystemStatusServiceFn = ({
  params,
  payload,
}: {
  payload: Partial<SystemStatusMgmtData>
  params: Params
}) => Promise<unknown>

export const getSystemStatusMgmtService: getSystemStatusMgmtServiceFn = (
  params,
) =>
  API.get(`/status-management/${params.facility_id}`, {
    params: omit(params, ["facility_id"]),
  })

export const updateSystemStatusService: updateSystemStatusServiceFn = ({
  params,
  payload,
}) =>
  API.post(`/status-management/${params.facility_id}`, payload, {
    params: omit(params, ["facility_id"]),
  })
