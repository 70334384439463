import { useTranslation } from "react-i18next"
import { Spin, Empty } from "antd"
import Link from "next/link"
import dayjs from "dayjs"
import { useState } from "react"
import { useQuery } from "react-query"
import { useRouter } from "next/router"
import {
  Breadcrumb,
  FACILITY_SERVICE_TYPES,
  PAZE_SIZE_OPTIONS,
  PageCard,
  PageTitle,
  getUserAttendanceTypesByService,
  removeBlankAttributes,
  useUpdateSearchParams,
} from "@project/common"
import { PageHead } from "../../../components"
import { UserAbsenceRecordSearch } from "../../../types/UserAttendanceAbsenceRecord.types"
import { AbsenceRecordOperation } from "./AbsenceRecordOperation"
import { AbsenceRecordButtonActions } from "./AbsenceRecordButtonActions"
import { getUsersAbsenceRecord } from "../../../services"
import { AbsenceRecordTable } from "./AbsenceRecordTable"
import styled from "styled-components"

const Wrapper = styled.div`
  .ant-spin-nested-loading {
    width: 100%;
  }
  .no-record {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const AbsenceRecord = () => {
  const { t } = useTranslation()

  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()

  const {
    user_id: queryUser,
    from_date: queryFrom,
    to_date: queryTo,
    facilityIds: queryFacilityIds,
    service: queryService,
    attendance_type: queryAttendance,
    page: queryPage,
    pageSize: queryPageSize,
  } = router.query

  const getPaginationParams = () => {
    const page = queryPage ? parseInt(queryPage.toString()) : 1 || 1
    const pageSize = queryPageSize
      ? parseInt(queryPage.toString())
      : PAZE_SIZE_OPTIONS[0].value
    const finalPageSize =
      PAZE_SIZE_OPTIONS?.find((op) => op?.value == pageSize)?.value ||
      PAZE_SIZE_OPTIONS[0].value
    return {
      page,
      pageSize: finalPageSize,
    }
  }

  const initialSearchParams = {
    user_id: queryUser ? queryUser?.toString() : null,
    from_date:
      queryFrom && dayjs(queryFrom?.toString()).isValid()
        ? dayjs(queryFrom?.toString())
        : null,
    to_date:
      queryTo && dayjs(queryTo?.toString()).isValid()
        ? dayjs(queryTo?.toString())
        : null,
    facilityIds: queryFacilityIds
      ? queryFacilityIds?.toString().split(",")
      : [],
    service: queryService
      ? queryService
          ?.toString()
          .split(",")
          ?.map((s) => +s)
      : [],
    attendance_type: queryAttendance
      ? queryAttendance?.toString().split(",")
      : [],
    ...getPaginationParams(),
  }

  const [searchParams, setSearchParams] =
    useState<UserAbsenceRecordSearch>(initialSearchParams)

  const handleSearch = (params: UserAbsenceRecordSearch) => {
    setSearchParams(params)
    const queryParams = removeBlankAttributes({
      ...params,
      from_date: params?.from_date
        ? params.from_date.format("YYYY-MM-DD")
        : null,
      to_date: params?.to_date ? params.to_date.format("YYYY-MM-DD") : null,
    })
    updateParams(queryParams, "/user-attendance-calendar/absence-record")
  }

  const handleResetSearch = () => {
    handleSearch({
      user_id: null,
      from_date: null,
      to_date: null,
      facilityIds: [],
      service: [],
      attendance_type: [],
      page: 1,
      pageSize: PAZE_SIZE_OPTIONS[0].value,
    })
  }

  const title = t("{{date}}'s Attendance record", {
    date:
      searchParams?.from_date || searchParams?.to_date
        ? dayjs(searchParams?.from_date || searchParams?.to_date).format(
            "YYYY年MM月DD日",
          )
        : null,
  })

  getUsersAbsenceRecord
  const { data: absenceRecord, isLoading } = useQuery(
    ["absence-record", searchParams],
    () => getUsersAbsenceRecord(searchParams),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (response) => {
        const absenceData = []
        if (typeof response?.data == "object") {
          for (const keys in response?.data) {
            if (Array.isArray(response?.data[keys])) {
              const absenceRecords = response?.data[keys].map((record) => {
                return {
                  user_service: t(
                    FACILITY_SERVICE_TYPES?.[record?.service_type],
                  ),
                  username: record?.user?.username,
                  date: dayjs(record?.date?.toString()?.split("T")?.[0]).format(
                    "YYYY年MM月DD日",
                  ),
                  facility_name: record?.facility_name,
                  addition_type: t(
                    getUserAttendanceTypesByService(record?.service_type)?.find(
                      (a) => a.value == record?.attendance_type,
                    )?.label || "",
                  ),
                  absence_reason: record?.absence_reason || "",
                  recorder_name: record?.staff?.staff_name,
                }
              })
              absenceData.push(absenceRecords)
            }
          }
        }
        return absenceData
      },
    },
  )

  return (
    <Wrapper>
      <PageHead>{t("User absence information ")}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: (
              <Link href={"/user-attendance-calendar/daily-list"}>
                {t("User absence information ")}
              </Link>
            ),
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
      />
      <AbsenceRecordOperation
        params={searchParams}
        handleSearch={handleSearch}
        handleResetSearch={handleResetSearch}
      />
      <PageCard title={t("Record Of Absence")}>
        <Spin spinning={isLoading}>
          <AbsenceRecordButtonActions
            onBack={() => router.push("/user-attendance-calendar/daily-list")}
            onPrint={() => window.print()}
            paginationParams={{
              page: searchParams.page,
              pageSize: searchParams.pageSize,
              total: absenceRecord?.length || 0,
            }}
            handlePaginationChange={(params) =>
              handleSearch({
                ...searchParams,
                page: params.page,
                pageSize: params.pageSize,
              })
            }
          />
          {absenceRecord?.length ? (
            absenceRecord
              ?.slice(
                (searchParams.page - 1) * searchParams.pageSize,
                searchParams.page * searchParams.pageSize,
              )
              ?.map((record, index) => (
                <AbsenceRecordTable
                  absenceRecords={Array.isArray(record) ? record : []}
                  key={`user-absence-record-table-${index}`}
                />
              ))
          ) : (
            <div className={"no-record"}>
              <Empty />
            </div>
          )}
        </Spin>
      </PageCard>
    </Wrapper>
  )
}
