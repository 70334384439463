export const getFacilityNameFromId = (allFacilities, facilityId) => {
  let _name = ""
  allFacilities.forEach((facility) => {
    if (facility.id == facilityId) {
      _name = facility.facility_name
    }
  })
  return _name
}

export const getDateTimeStringFromDate = (passedDate: Date) => {
  // for those dates that need to be passed to backend as time.Time
  const date = new Date(passedDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}T00:00:00Z`
}

export const convertThumbnailToFullImage = (thumbnailUrl: string) => {
  return thumbnailUrl?.replace("/images/thumbnail/", "/images/original/")
}

export const downloadImage = async (originalUrl: string, name?: string) => {
  return fetch(originalUrl)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      const downloadName = name ? name : originalUrl.split("/").reverse()[0]
      a.download = downloadName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      return Promise.resolve()
    })
}

export const zeroPadding = (n: number | string) => {
  return +n < 10 ? "0" + n : n
}
