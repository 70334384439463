import { theme } from "@project/common"
import styled from "styled-components"

export const AcknowledgementPrintAbleContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 0;
  background-color: ${theme?.colors?.white};
`
export const InnerWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${theme?.colors?.border};
  page-break-inside: avoid !important;
  .footer_text {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
`
export const DateTitle = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const HeaderTittle = styled.div`
  display: flex;
  flex-direction: column;
  .mt-5 {
    padding-top: 5px;
  }
`
export const TextTitleSection = styled.div`
  width: 70%;
  margin: auto;
  text-align: center;
`

export const StyledProxyPrintTableContent = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  tbody#proxy__table-body {
    tr {
      td {
        border: 1px solid ${theme.colors.border};
        height: 30px;
        min-width: 30px;
        padding: 9px 16px;
        white-space: nowrap;
      }
      td.w-50 {
        width: 50% !important;
      }
      td.w-25 {
        width: 50% !important;
      }
      td.align_center {
        text-align: center;
      }
    }
  }
  @media only print {
    table-layout: fixed;
    tbody#proxy__table-body {
      tr {
        td {
          white-space: normal !important;
        }
      }
    }
  }
`
