import { theme } from "@project/common"
import styled from "styled-components"

export const FormInputContent = styled.div`
  .user__name_selector,
  .create_count {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
    span {
      white-space: nowrap;
      font-size: 14px;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100% !important;
    }
  }
`
export const GoalContainer = styled.div`
  margin-top: 16px;
`
export const ItemsContainer = styled.div`
  margin-top: 16px;
`
export const HopeContainer = styled.div`
  margin-top: 16px;
`
export const RemarkContainer = styled.div`
  margin-top: 16px;
`
