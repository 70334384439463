// packages
import { t } from "i18next"
import { Flex, Popover } from "antd"
import styled from "styled-components"
import { QuestionCircleOutlined } from "@ant-design/icons"

// commons
import { theme } from "@project/common"

// styles
export const AccountSubjectCellWrap = styled(Flex)`
  text-align: left;
  min-width: 200px;
  .icon {
    svg {
      color: ${theme.colors.info};
    }
  }
`

export const AccountSubjectCell = ({
  label,
  tooltipText,
}: {
  label: string
  tooltipText?: string
}) => {
  return (
    <AccountSubjectCellWrap justify={"space-between"} gap={6} align={"center"}>
      {t(label)}
      <Popover content={tooltipText ? tooltipText : t(label)}>
        <div className={"icon"}>
          <QuestionCircleOutlined />
        </div>
      </Popover>
    </AccountSubjectCellWrap>
  )
}
