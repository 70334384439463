import React, { useContext, useMemo, useRef, useState } from "react"
import {
  Breadcrumb,
  Button,
  PageTitle,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { PlanDetailContent } from "./PlanDetailContent"
import { PlanOperationData, PlanType } from "../../../types"
import { useQuery } from "react-query"
import { getOneIndividualSupportPlan } from "../../../services"
import { PlanDetailOperation } from "./PlanDetailOperation"
import { AuthContext } from "../../../context"
import {
  hasPermissionForFacility,
  IndividualSupportPlanPermission,
} from "../../../utils/PermissionKeys"

interface PlantDetailProps {
  planType: PlanType
}

export const PlantDetail = ({ planType }: PlantDetailProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const scrollContent = useRef<HTMLDivElement>(null)

  const { isOwner, permissions } = useContext(AuthContext)

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["support_plan", planType, id],
    queryFn: () => getOneIndividualSupportPlan({ id, type: planType }),
    refetchOnWindowFocus: false,
    enabled: !!id,
  })
  const name = response?.data?.userSupport?.user_info?.username
  const user_id = response?.data?.userSupport?.user_info?.id
  const create_count = response?.data?.userSupport?.create_count || 1
  const url =
    planType === "draft"
      ? `/draft-individual-support-plan/edit/${id}?user_id=${user_id}&create_count=${create_count}`
      : `/individual-support-plan/edit/${id}?user_id=${user_id}&create_count=${create_count}`
  const title = t(
    planType === "draft"
      ? "{{user_name}}'s Individual support plan (draft) details"
      : "{{user_name}}'s individual support plan details",
    {
      user_name: name || "",
    },
  )
  const [operationData, setOperationData] = useState({
    display_content: ["1", "2", "6", "7"],
    consent_date: [],
    parent_sign: [],
    print_settings: "1",
    statementText: {
      consentStatement: "",
      consentStatementForChild: "",
    },
  })

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      (!isLoading &&
        !isFetching &&
        hasPermissionForFacility(
          permissions,
          IndividualSupportPlanPermission,
          "write",
          response?.data?.userSupport?.facility_id,
        )),
    [isOwner, permissions, response],
  )

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title:
              planType === "draft" ? (
                <Link href={"/draft-individual-support-plan"}>
                  {t("Individual support plan (draft)")}
                </Link>
              ) : (
                <Link href={"/individual-support-plan"}>
                  {t("Individual support plan")}
                </Link>
              ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
        extra={
          <Button
            btnText={
              planType === "draft"
                ? t("Edit individual support plan (draft)")
                : t("Edit individual support plan")
            }
            textColor={theme.colors.text}
            shape={"round"}
            iconType={"blue-pencil"}
            btnBg={"#EAF9FF"}
            disabled={!hasWriteAccess || isLoading}
            onClick={() => router.push(url)}
          />
        }
      />
      <PlanDetailOperation
        onDisplayChange={(values: PlanOperationData) => {
          const url =
            planType === "non-draft"
              ? `/individual-support-plan/detail/${id}`
              : `/draft-individual-support-plan/detail/${id}`
          setOperationData(values)
          updateParams(
            {
              display_content: values?.display_content?.toString(),
              consent_date: values?.consent_date?.toString(),
              parent_sign: values?.parent_sign?.toString(),
              print_settings: values?.print_settings,
            },
            url,
          )
          scrollToSelectedElement(scrollContent)
        }}
      />
      <PlanDetailContent
        details={response?.data}
        isLoading={isLoading || isFetching}
        operationData={operationData}
        data={{
          planType,
          id,
          create_count,
          user_id,
        }}
        scrollRef={scrollContent}
        hasEditAccess={hasWriteAccess}
      />
    </>
  )
}
