import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { AuthContext } from "../../../context"
import { useFetchAllStaff } from "../../../hooks/useFetchData"

export const StaffMonthlyAttendanceScheduleOperation = ({
  params,
  setSearchParams,
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useContext(AuthContext)
  const { staffData } = useFetchAllStaff({
    page: 1,
    pageSize: "Infinity",
  })
  const [values, setValues] = useState({
    ...params,
    staff_hiragana: "",
  })
  useEffect(() => {
    setValues({
      ...params,
      staff_hiragana: "",
    })
  }, [params])

  return (
    <AccordionCard
      defaultActiveKey={["monthly_schedule_operation"]}
      items={[
        {
          key: "monthly_schedule_operation",
          label: t("Operation Options"),

          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"138px"} />
                <SelectInput
                  filterSort={() => null}
                  name={"operation_options"}
                  options={facilities || []}
                  placeholder={"---"}
                  width={"360px"}
                  value={values?.facility_id || null}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      facility_id: val,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year month")}
                  width={"138px"}
                />
                <DatePicker
                  picker={"month"}
                  date={
                    dayjs(values?.date).isValid() ? dayjs(values?.date) : null
                  }
                  onDateChange={(date) =>
                    setValues({
                      ...values,
                      date: date,
                    })
                  }
                  format={"YYYY年MM月"}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Staff")} width={"138px"} />
                <div className={"flex"}>
                  <SelectInput
                    width={"85px"}
                    placeholder={"---"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    name={"staff_hiragana"}
                    value={values?.staff_hiragana || null}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        staff_hiragana: val,
                      })
                    }
                  />
                  <SelectInput
                    name={"username"}
                    width={"282px"}
                    placeholder={"--"}
                    options={staffData || []}
                    value={values?.staff_id ? +values?.staff_id : null}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        staff_id: val,
                      })
                    }
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    const month = values?.date
                      ? dayjs(values?.date).month() + 1
                      : dayjs()?.month() + 1
                    const year = values?.date
                      ? dayjs(values?.date).year()
                      : dayjs().year()
                    updateParams(
                      {
                        year,
                        month,
                        facility: values?.facility_id,
                        staff_id: values?.staff_id || "",
                      },
                      "/attendance-record/staff-monthly-attendance-record",
                    )
                    setSearchParams(values)
                  }}
                />
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  onClick={() => {
                    const month = values?.date
                      ? dayjs(values?.date).month() + 1
                      : dayjs()?.month() + 1
                    const year = values?.date
                      ? dayjs(values?.date).year()
                      : dayjs().year()
                    updateParams(
                      {
                        year,
                        month,
                        facility: values?.facility_id,
                        staff_id: values?.staff_id || "",
                      },
                      "/attendance-record/staff-monthly-attendance-record",
                    )
                    setSearchParams(values)
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
