import React from "react"
import dayjs from "dayjs"
import { Form } from "antd"
import { t } from "i18next"
import { FormProps } from "antd/lib"
import { useQuery } from "react-query"
import { useRouter } from "next/router"

import {
  Box,
  Button,
  ActionBtns,
  SelectInput,
  filterObject,
  AccordionCard,
  CheckBoxGroup,
  useUpdateSearchParams,
  useNotification,
  pick,
} from "@project/common"
import AlertBoxV2 from "../../common/AlertBoxV2"
import { CustomerInvoiceList } from "./CustomerInvoiceList"
import { OperationFields } from "../../../ServiceProvisionRecord/common/OperationFields"

import { PrintCustomerInvoiceContainer } from "./styles"

import { useAuthContext } from "../../../../context"

import { getCityWiseBillingStatements } from "../../../../services"

import { formatToUsedFacilityDatatype } from "../../utils/formatToUsedFacilityDatatype"

import { PrintCustomerInvoiceOperation } from "../../../../types/nhif.types"

export const PrintCustomerInvoiceInternal = () => {
  const { query } = useRouter()

  const currentYearMonth = dayjs(query?.year_month as string).isValid()
    ? dayjs(query?.year_month as string)
    : dayjs()

  const { facilities: facilitiesCtx } = useAuthContext()

  const [form] = Form.useForm<PrintCustomerInvoiceOperation>()

  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()

  const memoizedQuery: PrintCustomerInvoiceOperation = React.useMemo(() => {
    const facilityIds = query?.facilityIds
      ? query.facilityIds.toString().split(",")
      : null
    const comp_info = query?.comp_info ? query?.comp_info.toString() : null

    return {
      facilityIds,
      comp_info,
    }
  }, [query])

  const { data: customerInvoice, isLoading: loadingCustomerInvoice } = useQuery(
    ["customer-invoice-listing", memoizedQuery],
    {
      queryFn: () =>
        getCityWiseBillingStatements({
          facilityIds: memoizedQuery?.facilityIds
            ? memoizedQuery?.facilityIds.join(",")
            : "",
          year: currentYearMonth.year(),
          month: currentYearMonth.month() + 1,
        }),
      select: (data) => formatToUsedFacilityDatatype(data),
      onError: () => {
        showToast({
          type: "error",
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    },
  )

  const onFinish: FormProps<PrintCustomerInvoiceOperation>["onFinish"] = (
    values,
  ) => {
    updateParams(
      filterObject({
        ...query,
        ...values,
        facilityIds: values?.facilityIds.join(","),
      }),
      `/national-health-insurance-billing/prints/customer-invoice`,
    )
  }

  const onReset = () => {
    const resetedObj = pick(query, ["year_month", "default_facility"])
    form.setFieldsValue({
      facilityIds: [query?.default_facility as string],
      comp_info: null,
    })

    updateParams(
      {
        ...filterObject(query),
        ...resetedObj,
        facilityIds: [query?.default_facility as string],
      },
      `/national-health-insurance-billing/prints/customer-invoice`,
    )
  }

  const handleCheckAll = () => {
    const allFacIds = facilitiesCtx?.map((item) => item?.value)
    form.setFieldsValue({
      facilityIds: allFacIds,
    })
  }

  const handleUnCheckAll = () => {
    form.setFieldsValue({
      facilityIds: [],
    })
  }

  const onBack = () => {
    updateParams(
      {
        year: currentYearMonth.year(),
        month: currentYearMonth.month() + 1,
        facility: query?.default_facility,
      },
      "/national-health-insurance-billing",
    )
  }

  return (
    <PrintCustomerInvoiceContainer>
      <AccordionCard
        defaultActiveKey={["disabled-user-benefits"]}
        items={[
          {
            key: "disabled-user-benefits",
            label: t("Operation Options"),
            children: (
              <Box display={"flex"} direction={"column"} gap={16}>
                <AlertBoxV2
                  theme={"error"}
                  heading={t(
                    "Facilities that have not confirmed their billing information cannot be selected.",
                  )}
                  description={t(
                    `If you want to add up the billed amount for Userren who are also using other facilities, please check the facilities to be output and press "Reflect"`,
                  )}
                  variant={"WITH_OUT_COLLAPSE"}
                />
                <Form
                  form={form}
                  onFinish={onFinish}
                  name={"control-hooks"}
                  initialValues={memoizedQuery}
                >
                  <Box display={"flex"} direction={"column"} gap={"16px"}>
                    <OperationFields
                      gap={"10px 24px"}
                      label={"Facility to output"}
                      labelWidth={"150px"}
                      align={"flex-start"}
                      direction={{
                        "0px": "column",
                        "550px": "row",
                      }}
                    >
                      <Box
                        miw={135}
                        className={"input-field"}
                        display={"flex"}
                        direction={"column"}
                        gap={16}
                      >
                        <ActionBtns
                          className={"action-buttons"}
                          justify={"flex-start"}
                        >
                          <Button
                            shape={"round"}
                            type={"outlined-white-bg"}
                            iconType={"check-all"}
                            btnText={t("Check all")}
                            className={"size-sm"}
                            onClick={handleCheckAll}
                          />
                          <Button
                            shape={"round"}
                            type={"grayed"}
                            btnText={t("Clear all")}
                            className={"size-sm"}
                            onClick={handleUnCheckAll}
                          />
                        </ActionBtns>

                        <Form.Item name={"facilityIds"} id={"facilityIds"}>
                          <CheckBoxGroup
                            name={"facilityIds"}
                            className={"check-box-group"}
                            items={facilitiesCtx}
                          />
                        </Form.Item>
                      </Box>
                    </OperationFields>

                    <OperationFields
                      gap={"10px 24px"}
                      label={
                        "Company information to be output and remarks on the invoice"
                      }
                      align={{
                        "0px": "flex-start",
                        "550px": "center",
                      }}
                      direction={{
                        "0px": "column",
                        "550px": "row",
                      }}
                    >
                      <Box
                        miw={135}
                        className={"input-field"}
                        display={"flex"}
                        direction={"column"}
                        gap={16}
                      >
                        <Form.Item name={"comp_info"} id={"comp_info"}>
                          <SelectInput
                            name={"comp_info"}
                            options={facilitiesCtx}
                            placeholder={"---"}
                          />
                        </Form.Item>
                      </Box>
                    </OperationFields>
                  </Box>

                  <Box display={"flex"} gap={12} mt={16} mb={8}>
                    <Button
                      shape={"round"}
                      btnText={t("Reset Search")}
                      onClick={() => onReset()}
                    />
                    <Button
                      shape={"round"}
                      type={"primary"}
                      btnText={t("Search")}
                      htmlType={"submit"}
                      iconType={"search"}
                    />
                  </Box>
                </Form>
              </Box>
            ),
          },
        ]}
      />

      <Button
        btnText={t("<<Back")}
        shape={"round"}
        className={"back-btn"}
        onClick={onBack}
      />

      <CustomerInvoiceList
        customerInvoice={customerInvoice}
        loadingCustomerInvoice={loadingCustomerInvoice}
      />

      <Button
        btnText={t("<<Back")}
        shape={"round"}
        className={"back-btn"}
        onClick={onBack}
      />
    </PrintCustomerInvoiceContainer>
  )
}
