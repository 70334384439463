import { theme } from "@project/common"
import styled from "styled-components"

export const StyledFlex = styled.div`
  display: flex;
  gap: 16px;

  .amount_info {
    flex: 1;
    border: 1px solid ${theme.colors.text};
    padding: 24px;
    border-radius: 12px;
    height: 100%;
    caption {
      font-size: 20px;
    }
    .payslip__item {
      display: flex;
      flex-wrap: nowrap;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xlg}) {
    flex-direction: column;
  }
`
export const RemarksContainer = styled.div`
  border: 1px solid ${theme.colors.text};
  padding: 16px;
  border-radius: 12px;
`
