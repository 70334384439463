import React from "react"

const BellCheck = ({
  width = "24",
  height = "24",
  color = "#99999",
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <span
      style={{
        color,
      }}
    >
      <svg
        xmlns={"http://www.w3.org/2000/svg"}
        className={"icon icon-tabler icon-tabler-bell-check"}
        width={width}
        height={height}
        viewBox={"0 0 24 24"}
        strokeWidth={"1.5"}
        stroke={"currentColor"}
        fill={"none"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      >
        <path stroke={"none"} d={"M0 0h24v24H0z"} fill={"none"} />
        <path
          d={
            "M11.5 17h-7.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3c.016 .129 .037 .256 .065 .382"
          }
        />
        <path d={"M9 17v1a3 3 0 0 0 2.502 2.959"} />
        <path d={"M15 19l2 2l4 -4"} />
      </svg>
    </span>
  )
}

export default BellCheck
