import { Flex, Spin, notification } from "antd"
import React, { useRef, useState } from "react"
import { PaidLeaveSettingsSummary } from "./PaidLeaveSettingsSummary"
import { PaidLeaveSettingsForm } from "./PaidLeaveSettingsForm"
import { PaidLeaveSettingsTable } from "./PaidLeaveSettingsTable"
import { useMutation, useQuery } from "react-query"
import { useRouter } from "next/router"
import {
  createPaidLeave,
  fetchPaidLeaveList,
} from "../../../services/paidLeave"
import {
  PageTitle,
  dynamicLangString,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { paidLeaveFormSchema } from "../../../types"
import { PayslipOperation } from "../PayslipOperation/PayslipOperation"

export const PaidLeaveSettings = ({ id }): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { page: currentPage } = router?.query as any
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1,
  )
  const [isGrantModal, setIsGrantModalOpen] = useState(false)
  const [facility, setFacility] = useState(null)
  const [user_id, setUserId] = useState(+id)
  const [startAt, setStartAt] = useState(null)
  const [params, setParams] = useState<any>({
    facility: facility,
    user_id: user_id,
  })

  const [searchEnabled, setSearchEnabled] = useState(true)
  const listContent = useRef<any>(null)
  const {
    isLoading,
    isFetching,
    refetch,
    data: response,
  } = useQuery(["fetch-paid-leave-list", id, page, params], {
    queryFn: fetchPaidLeaveList,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: searchEnabled || !!page,
    onSuccess: () => {
      setSearchEnabled(false)
    },
    onError: () => {
      setSearchEnabled(false)
    },
  })
  const { mutate: mutatePaidLeave, isLoading: isCreateLoading } = useMutation(
    createPaidLeave,
    {
      onSuccess: () => {
        setSearchEnabled(true)
        notification.success({
          message: dynamicLangString(["Paid Leave", "Created Successfully"]),
        })
        const jsonData = JSON.parse(localStorage.getItem("isShowModel"))
        if (jsonData?.isShowModel) {
          if (formik.values.isShowModel && !jsonData?.id?.includes(id)) {
            const data1 = {
              id: [...jsonData?.id, id],
              isShowModel: formik.values.isShowModel,
            }
            localStorage.setItem("isShowModel", JSON.stringify(data1))
          } else {
            const data2 = {
              id: [...jsonData?.id],
              isShowModel: jsonData?.isShowModel,
            }
            localStorage.setItem("isShowModel", JSON.stringify(data2))
          }
        } else {
          const data3 = {
            id: [id],
            isShowModel: formik.values.isShowModel,
          }
          localStorage.setItem("isShowModel", JSON.stringify(data3))
        }
        setIsGrantModalOpen(false)
        formik.setFieldValue("paidLeave", null)
        formik.setFieldValue("grantDate", "")
        formik.setFieldValue("isShowModel", false)
        refetch()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
        setIsGrantModalOpen(false)
      },
    },
  )
  const initialValues = {
    paidLeave: null,
    grantDate: "",
    user_id: +id,
    isShowModel: false,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paidLeaveFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        paid_leave: values?.paidLeave,
        grant_date: values?.grantDate,
        user_id: values?.user_id,
      }
      const jsonData = JSON.parse(localStorage.getItem("isShowModel"))
      if (jsonData?.isShowModel) {
        if (jsonData?.id?.includes(id)) {
          mutatePaidLeave(data)
        } else {
          setIsGrantModalOpen(true)
        }
      } else {
        setIsGrantModalOpen(true)
      }
    },
  })

  const onSearch = (values) => {
    if (values?.user_id) {
      setPage(1)
      const query = removeBlankAttributes(values)
      updateParams(query, `/wage-management/leave-settings/${values?.user_id}`)
      scrollToSelectedElement(listContent)
      setParams(query)
      setSearchEnabled(true)
    }
  }
  const resetHandler = () => {
    setPage(1)
    setFacility(null)
    setUserId(+id)
    setStartAt(null)
    setParams(() => {
      return {
        facility: null,
        user_id: +id,
      }
    })
    updateParams({}, `/wage-management/leave-settings/${id}`)
    setSearchEnabled(true)
  }
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    scrollToSelectedElement(listContent)
    router.push(
      {
        pathname: `/wage-management/leave-settings/${id}`,
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }
  const handleOk = () => {
    const data = {
      paid_leave: formik.values?.paidLeave,
      grant_date: formik.values?.grantDate,
      user_id: formik.values?.user_id,
    }
    mutatePaidLeave(data)
  }
  const onCancel = () => {
    formik.setFieldValue("paidLeave", null)
    formik.setFieldValue("grantDate", "")
    formik.setFieldValue("isShowModel", false)
    setIsGrantModalOpen(false)
  }

  return (
    <>
      <div className={"page-title"}>
        <PageTitle
          icon={
            <img src={"/assets/icons/clipboard-with-pencil.svg"} alt={"icon"} />
          }
          title={t("{{user_name}}’s Paid leave settings", {
            user_name: response?.data?.user?.username,
          })}
          marginBottom={"0px"}
        />
      </div>

      <Flex vertical gap={16}>
        <PayslipOperation
          params={params}
          setParams={setParams}
          onSearch={onSearch}
          resetHandler={resetHandler}
          setFacility={setFacility}
          facility={facility}
          setUserId={setUserId}
          user_id={user_id}
          setStartAt={setStartAt}
          startAt={startAt}
        />
        <Spin spinning={isLoading || isFetching}>
          <PaidLeaveSettingsSummary response={response?.data} />{" "}
        </Spin>
        <PaidLeaveSettingsForm
          isCreateLoading={isCreateLoading}
          formik={formik}
          setIsGrantModalOpen={setIsGrantModalOpen}
          isGrantModal={isGrantModal}
          handleOk={handleOk}
          onCancel={onCancel}
          userId={+id}
          totalPaidLeaveLeft={response?.data?.total_paid_leave_left}
        />
        <PaidLeaveSettingsTable
          isLoading={isLoading}
          isFetching={isFetching}
          response={response}
          pageSize={pageSize}
          page={page}
          handlePaginationChange={handlePaginationChange}
          id={id}
          params={params}
        />
      </Flex>
    </>
  )
}
