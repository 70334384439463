import { Table } from "@project/common"
import { useTranslation } from "react-i18next"
import { IAbsenceRecordTable } from "../../../types/UserAttendanceAbsenceRecord.types"
import { AbsenceRecordTableContainer } from "./AbsenceRecord.styles"

export const AbsenceRecordTable = (props: IAbsenceRecordTable) => {
  const { absenceRecords } = props
  const { t } = useTranslation()

  const columns: any = [
    {
      key: "date",
      dataIndex: "date",
      title: t("Date"),
      align: "center",
      className: "absent-record-date",
    },
    {
      key: "facility_name",
      dataIndex: "facility_name",
      title: t("Facility"),
      align: "center",
      className: "absent-record-facility",
    },
    {
      key: "addition_type",
      dataIndex: "addition_type",
      title: t("Addition type"),
      align: "center",
      className: "absent-record-addition",
    },
    {
      key: "absence_reason",
      dataIndex: "absence_reason",
      title: t("Reason"),
      className: "absent-record-reason",
      render: (text) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {text}
          </div>
        )
      },
    },
    {
      key: "recorder_name",
      dataIndex: "recorder_name",
      title: t("Recorder name"),
      align: "center",
      className: "absent-record-recorder",
    },
  ]
  return (
    <AbsenceRecordTableContainer>
      <div className={"title-container "}>
        <div className={"title"}>{t("Absence Record")}</div>
        <div className={"user-info"}>
          <div>
            {t("User Service")}
            {` : ${absenceRecords?.[0]?.user_service}`}
            <br />
            {t("Username")}
            {` (${absenceRecords?.[0]?.username})`}
          </div>
        </div>
      </div>
      <div className={"table-container"}>
        <Table
          columns={columns}
          dataSource={absenceRecords}
          className={"absence-record-table"}
          scroll={{ x: 600 }}
        />
      </div>
    </AbsenceRecordTableContainer>
  )
}
