export const generateNewTMBulkEditDriverStaff = (uniqueid: any) => ({
  id: uniqueid,
  key: uniqueid,
  isNew: true,
  ...commonAttribs,
})

export const commonAttribs = {
  driver_staff_id: null,
  start_time: "",
  end_time: "",
  route: 1,
  car_id: null,
  escort_staff_id: null,
  power: "1",
  damage_to_device: "1",
  alcohol_check: "1",
  visual_check: "1",
}
