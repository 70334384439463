import {
  Button,
  Card,
  LinkButton,
  PageTitle,
  Table,
  scrollToSelectedElement,
  theme,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { PAGE_SIZE_20 } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import { fetchConsultationSupportOffice } from "../../services/consultationSupportOffice"
import { ConsultationSupportMasterPermission } from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

const StyleConsultationsSupportOfficeWrapper = styled.div`
  margin-top: 16px;
`

const Tag = styled.span`
  background: ${theme.colors.disabled};
  padding: 2px 8px;
`
const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
`

const ConsultationsSupportOffice = (): JSX.Element => {
  const { t } = useTranslation()
  const { permissions, isOwner } = useContext(AuthContext)
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState(+router?.query?.page || 1)
  const listRef = useRef<HTMLDivElement>(null)

  const {
    isLoading,
    isFetching,
    data: consultationSupportOfficeData,
  } = useQuery(["fetchUsers", currentPage], {
    queryFn: fetchConsultationSupportOffice,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })

  const hasWriteAccess =
    isOwner ||
    useMemo(
      () =>
        hasPermissionForMenu(
          permissions,
          ConsultationSupportMasterPermission,
          "write",
        ),
      [isOwner, permissions],
    )

  const columns: ColumnsType<any[]> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      width: 70,
      render: (_, data: any) => {
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() =>
              router.push(
                `/consultation-support-office-master/edit/${data?.id}`,
              )
            }
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: (
        <span className={"col-title"}>
          <p>{t("Consultation Support Office Name")}</p>
          <p>{t("(Hiragana)")}</p>
        </span>
      ),
      width: 200,
      key: "consultation_support_name_hiragana",
      align: "center",
      render: (data) => {
        let name = data?.consultation_support_name
        if (
          data?.consultation_support_name_hiragana &&
          data?.consultation_support_name_hiragana != " - "
        ) {
          name += " (" + data?.consultation_support_name_hiragana + ")"
        }
        return name
      },
    },

    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      align: "center",
      render: (_, data: any) => {
        return data?.address || "-"
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Main target disability")}
        </span>
      ),
      dataIndex: "main_target_disability",
      key: "main_target_disability",
      align: "center",
      width: 240,
      render: (_, data: any) => {
        const disabilities =
          JSON.parse(
            `[${data?.main_target_disability?.replaceAll(/\\"/g, '"') ?? ""}]`,
          ) || "-"
        const mapToWord = (item) => {
          switch (item) {
            case "1":
              return t("Not specified")
            case "2":
              return t("Physically disabled")
            case "3":
              return t("intellectually disabled")
            case "4":
              return t("Mentally disabled")
            case "5":
              return t("Disabled child")
            case "6":
              return t("Person with a rare disease")
            default:
              return ""
          }
        }
        return disabilities.length > 0 ? (
          <FlexDiv>
            {disabilities.map((item, index) => (
              <div key={index}>
                {item ? <Tag>{t(mapToWord(item))}</Tag> : <div> {"-"}</div>}
              </div>
            ))}
          </FlexDiv>
        ) : (
          <div>{"-"}</div>
        )
      },
    },
    {
      title: t("PIC "),
      dataIndex: "pic",
      key: "pic",
      align: "center",
      render: (_, data: any) => {
        return data?.pic_name || "-"
      },
    },
    {
      title: <span className={"col-title"}> {t("Phone Number")}</span>,
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
      render: (_, data: any) => {
        return data?.phone_number || "-"
      },
    },
    {
      title: (
        <span className={"col-title"}>
          <p>{t("Mail Address")}</p>
        </span>
      ),
      align: "center",
      dataIndex: "mail_address",
      key: "mail_address",
      render: (_, data: any) => {
        return data?.email_address || "-"
      },
    },
    {
      title: <span className={"col-title"}>{t("FAX Number")}</span>,
      dataIndex: "fax_number",
      key: "fax_number",
      align: "center",
      render: (data) => {
        return data || "-"
      },
    },
  ]
  return (
    <StyleConsultationsSupportOfficeWrapper ref={listRef}>
      <PageTitle
        title={t("Consultation support Office list")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        extra={
          <Button
            btnText={t("Add new consultation support office")}
            type={"primary"}
            iconType={"plus-circle"}
            shape={"round"}
            size={"large"}
            onClick={() =>
              router.push("/consultation-support-office-master/add")
            }
            disabled={!hasWriteAccess}
          />
        }
      />
      <Card title={t("Consultation support Office list")}>
        <Table
          columns={columns}
          current={currentPage}
          pageSize={PAGE_SIZE_20}
          dataSource={consultationSupportOfficeData?.data}
          total={consultationSupportOfficeData?.count}
          showPaginationOf={"both"}
          onChange={(val) => {
            setCurrentPage(val)
            scrollToSelectedElement(listRef)
            router.push(
              {
                pathname: "/consultation-support-office-master",
                query: {
                  page: val,
                },
              },
              undefined,
              {
                scroll: false,
              },
            )
          }}
          loading={isLoading || isFetching}
          scroll={{ x: "auto" }}
          cellPaddingBlock={12}
        />
      </Card>
    </StyleConsultationsSupportOfficeWrapper>
  )
}

export { ConsultationsSupportOffice }
