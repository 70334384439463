import {
  getUserAttendanceTypesByService,
  SERVICE_TYPE_VALUES,
} from "@project/common"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AuthContext } from "../../../../context"
import { getAllStaff, getAllWork } from "../../../../services"

const MobileView = styled.div`
  border-radius: 12px;
  border: 1px solid #d2d1d1;
  margin-bottom: 20px;
  .main-wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .user-table-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 2px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
      }
    }
  }
  .content {
    .content-h1 {
      margin: 0 2px;
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 500;
    }
    table {
      width: 100%;
      .mobile-content-tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        border-radius: 10px !important;
        border: 1px solid #dddddd;
        .label {
          height: 40px;
          padding: 8px 0px 0px 10px !important;
          border: none !important;
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          border-bottom: 1px solid #dddddd !important;
          background-color: #f3f3f3;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          text-transform: capitalize;
        }
        .value {
          border: none !important;
          padding: 8px;
        }
        .line-break {
          white-space: pre-wrap;
        }
      }
      .mobile-content-tr:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
`
const MobileResponsiveView = ({ data }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const { data: allWorkData } = useQuery<any>(
    ["get-all-work-data-view"],
    () => getAllWork(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.work_name, value: d?.id }
        }),
    },
  )

  const { data: allStaffData } = useQuery<any>(
    ["get-all-staff-view-mobile"],
    () => getAllStaff(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.staff_name, value: d?.id, furigana: d?.furigana }
        }),
    },
  )
  const staffName = allStaffData?.find((d) => d.value == data?.staff_id)?.label
  // previously work id was single selection
  // now its multiple
  // for new data refer to daily_business_support_report_works
  // for old data use word_id in array
  const userWorkIds =
    Array.isArray(data?.daily_business_support_report_works) &&
    data?.daily_business_support_report_works?.length > 0
      ? data?.daily_business_support_report_works?.map((work) => work?.work_id)
      : data?.work_id
        ? [data?.work_id]
        : []

  const workName = allWorkData
    ?.filter((d) => userWorkIds.includes(d.value))
    ?.map((d) => d?.label)
    ?.join(", ")
  const facility_name = facilities.find((d) => d?.value == data?.facility_id)
    ?.label
  const attendance_status = t(
    getUserAttendanceTypesByService(data?.service_type)?.find(
      (d) => d?.value == data?.attendance_type,
    )?.label || "",
  )
  return (
    <MobileView>
      <div className={"main-wrapper"}>
        <div className={"user-table-content"}>
          <h1>{data?.username}</h1>
          {attendance_status && <p>{t(attendance_status)}</p>}
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {data?.start_time && data?.end_time ? (
              <>
                {data?.start_time}
                <span>{"~"}</span>
                {data?.end_time}
              </>
            ) : null}
          </div>
        </div>
        <div className={"content"}>
          <h1 className={"content-h1"}>{t("Content")}</h1>
          <div>
            <table>
              {data?.is_absent ? (
                <>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Staff who handled")}</td>
                    <td className={"value"}>
                      {allStaffData?.find(
                        (v) => v?.value === data?.absent_recorder_staff_id,
                      )?.label || "-"}
                    </td>
                  </tr>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>
                      {" "}
                      {t("Reason of absence and support contents")}
                    </td>
                    <td className={"value line-break"}>
                      {data?.absence_reason || "-"}
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Work")}</td>
                    <td className={"value"}>{workName ? workName : "-"}</td>
                  </tr>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Interview")}</td>
                    <td className={"value"}>
                      {data?.interview ? "Yes" : "No"}
                    </td>
                  </tr>
                  {!!data?.interview && (
                    <>
                      <tr className={"mobile-content-tr"}>
                        <td className={"label"}>{t("Interview time")}</td>
                        <td className={"value"}>{`${
                          data?.start_time_hr || "--"
                        }:${data?.start_time_min || "--"} ~ ${
                          data?.end_time_hr || "--"
                        }:${data?.end_time_min || "--"}`}</td>
                      </tr>
                      <tr className={"mobile-content-tr"}>
                        <td className={"label"}>{t("Interview Content")}</td>
                        <td className={"value"}>
                          {data?.interview_record || "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  {data?.service_type === SERVICE_TYPE_VALUES.TRANSITION && (
                    <tr className={"mobile-content-tr"}>
                      <td className={"label"}>{t("Visited  facility name")}</td>
                      <td className={"value"}>
                        {facility_name ? facility_name : "-"}
                      </td>
                    </tr>
                  )}
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Support record")}</td>
                    <td className={"value line-break "}>
                      {data?.support_record ? data?.support_record : "-"}
                    </td>
                  </tr>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Condition of user")}</td>
                    <td className={"value line-break "}>
                      {data?.condition_of_user ? data?.condition_of_user : "-"}
                    </td>
                  </tr>
                  {data?.service_type === SERVICE_TYPE_VALUES.TRANSITION && (
                    <tr className={"mobile-content-tr"}>
                      <td className={"label"}>{t("Staff who handled")}</td>
                      <td className={"value"}>
                        {data?.person_in_charge ? data?.person_in_charge : "-"}
                      </td>
                    </tr>
                  )}
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Other")}</td>
                    <td className={"value line-break "}>
                      {data?.other ? data?.other : "-"}
                    </td>
                  </tr>
                  <tr className={"mobile-content-tr"}>
                    <td className={"label"}>{t("Recorder:")}</td>
                    <td className={"value"}>{staffName ? staffName : "-"}</td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </MobileView>
  )
}
export { MobileResponsiveView }
