import { theme } from "@project/common"
import styled from "styled-components"

export const FacilityStyledForm = styled.form`
  .setting_information {
    border-bottom: 1px solid #000;
    width: 100%;
  }

  .add_form {
    box-shadow: none;
    border: none !important;
    margin-top: 28px;
  }

  .car_number {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .inspection_day {
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
      color: #e00000;
      font-size: 13px;
    }
  }

  .facilities__btn {
    display: none;
  }
  #provision__zip_code__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.colors.link};
      text-decoration: underline;
    }
  }

  .target_user_check_box {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    label {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`
export const StyleFieldArray = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
  .input_container {
    display: flex;
    row-gap: 16px;
    column-gap: 16px;
    .btn_content {
      min-width: 70px;
      position: relative;
      margin-top: 5px;
      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          padding: 4px 0px !important;
        }
        .ant-btn:hover {
          background: transparent;
          color: ${theme.colors.error};
          width: 100%;
        }
      }
    }
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0px;
  flex-wrap: wrap;
`
