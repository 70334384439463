// packages
import { Flex, Tag } from "antd"
import { TagProps } from "antd/lib"
import styled from "styled-components"

// themes
import { theme } from "@project/common"

// constants
const currencyCss = (isRowTotal?: boolean) => `
  .currency-cell-content {
    display: flex;
    min-height: 68px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    .red-cost {
      color: ${isRowTotal ? theme.colors.text : theme.colors.error};
      font-weight: 500;
      font-size: 24px;
    }
    .currency-symbol {
      margin-top: 5px;
      font-size: 14px;
      color: ${theme.colors.text};
    }
  }
`

export const TreatmentImprovOperationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .facility-inputs .facility-action-button {
    margin-bottom: 16px;
  }
`

export const ChildCellStyle = styled.div`
  display: flex;
  height: 100%;
  .child-cell {
    border-right: 1px solid ${theme.colors.border};
    min-width: 8rem;
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }
  .child-cell:last-child {
    min-width: 8rem;
    border-right: 0px !important;
  }
`

export const TreatmentImprovTableWrap = styled.div`
  .ant-table-content table {
    thead.ant-table-thead tr th.ant-table-cell {
      height: 85px;
      font-size: 16px !important;
      font-weight: 500 !important;
      background: ${theme.colors.gray6};
    }

    tbody.ant-table-tbody {
      tr.ant-table-row:nth-child(-n + 6) {
        td.th-cell {
          background: ${theme.colors.gray6};
          font-weight: 500 !important;
          font-size: 16px !important;
        }
      }

      tr:nth-child(n + 5) td.ant-table-cell:not(:first-child) {
        padding: 0;
      }

      tr.ant-table-row:nth-child(2),
      tr.ant-table-row:nth-child(3) {
        td.ant-table-cell:first-child {
          background: ${theme.colors.gray6};
        }
      }

      tr.ant-table-row:nth-child(3) {
        td.ant-table-cell:not(:first-child) {
          background-color: ${theme.colors.red8};
          ${currencyCss()}
        }
      }

      tr.ant-table-row:nth-child(6) {
        td.ant-table-cell:not(:first-child) {
          ${currencyCss(true)}
        }
      }
    }
  }
`

export const NoticeBox = styled.div`
  padding: 16px 24px;
  border-radius: 12px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.error};
  color: ${theme.colors.error};
  margin: 28px 0;
`

// styles
export const StatsWrap = styled(Flex)`
  .label {
    padding: 9px 10px;
    border-radius: 5px;
    background: ${theme.colors.blue7};
    color: ${theme.colors.white};
    font-weight: 500;
    gap: 10px;
  }
  .amount {
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    color: ${theme.colors.error};
    .currency-symbol {
      font-size: 16px;
      color: ${theme.colors.text};
    }
  }
`

export const SystemStatusCellWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .status-cell-content {
    border-radius: 5px;
    background-color: ${theme.colors.blue9};
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100% !important;
    max-width: 390px;
    .treatment-nursing-staff-label {
      font-size: 14px;
    }
    span {
      margin: 0 4px;
    }
  }
`

export const EmploymentTag = styled(Tag)<
  TagProps & {
    $employmentStatus: 1 | 2 | 3
  }
>`
  color: ${theme.colors.white};
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: normal;
  margin-right: 8px;
  ${({ $employmentStatus }) => {
    switch ($employmentStatus) {
      case 1:
        return `
          border: 1px solid ${theme.colors.darkYellow};
          background:  ${theme.colors.lightYellow};
          color: ${theme.colors.darkYellow};
        `
      case 2:
        return `
          border: 1px solid ${theme.colors.green};
          background:  ${theme.colors.lightGreen};
          color: ${theme.colors.green};
        `
      case 3:
        return `
        border: 1px solid ${theme.colors.green};
        background:  ${theme.colors.lightGreen};
        color: ${theme.colors.green};
        `
      default:
        return ""
    }
  }}
`
