import { theme } from "@project/common"
import styled, { css } from "styled-components"

const CommonLableStyle = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`
export const ModalBodyContainer = styled.div`
  .error_message {
    font-size: 12px;
    color: #eb5757;
    position: absolute;
  }
  .body_content {
    border: 1px solid ${theme.colors.border};
    .form_control {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.border};
      }
      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
      }
      gap: 10px;
      .label {
        width: 30%;
        border-right: 1px solid ${theme.colors.border};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px 5px 8px;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
          border-right: none;
          padding: 15px;
          padding-bottom: 0;
        }
      }
      .form_input {
        width: 70%;
        padding: 15px 0;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
          padding-left: 15px;
          padding-top: 0;
        }
        .work_period {
          display: flex;
          column-gap: 10px;
          align-items: center;
          min-height: 50px;
          flex-wrap: wrap;
          row-gap: 10px;
          .ant-picker {
            border-radius: 5px;
            padding-top: 8px;
            padding-bottom: 8px;
            width: 100%;
          }
          @media (max-width: ${theme.breakpoints.md}) {
            column-gap: 10px;
            flex-wrap: wrap;
          }
        }
        .days {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            ${CommonLableStyle}
          }
        }
        .work {
          display: flex;
          align-items: center;
          gap: 40px;
          label {
            ${CommonLableStyle}
          }
          @media (max-width: ${theme.breakpoints.md}) {
            gap: 10px;
          }
        }
        .work_detail {
          margin-top: 10px;
          label {
            ${CommonLableStyle}
          }
        }
        .ant-btn {
          width: auto !important;
        }
      }
    }
  }
`
export const FooterContainers = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
`
