import { getUserAttendanceTypesByService, SelectInput } from "@project/common"
import { useTranslation } from "react-i18next"
import { useFetchAllStaff } from "../../../../hooks/useFetchData"
import { filterWithHiragana } from "../../../../utils"
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"
import DynamicTable from "./DynamicTable"

const SecondTable = ({ data, index, formik, allWorkData }) => {
  const { t } = useTranslation()
  const { staffData: allStaffData, isLoading } = useFetchAllStaff({
    page: 1,
    pageSize: "Infinity",
    facility: formik?.values?.facility_id,
    date_staff_is_active: formik?.values?.date,
  })
  const attendance_status = t(
    getUserAttendanceTypesByService(data?.service_type)?.find(
      (d) => d?.value == data?.attendance_type,
    )?.label || "",
  )

  return (
    <>
      <td className={"first-td"}>
        <div className={"first-td-content"}>
          <h1>{data?.username}</h1>
          {attendance_status && <p>{t(attendance_status)}</p>}
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {data?.start_time && data?.end_time ? (
              <>
                {data?.start_time}
                <span>{"~"}</span>
                {data?.end_time}
              </>
            ) : null}
          </div>
        </div>
      </td>
      <td className={"second-td"}>
        <DynamicTable
          data={data}
          index={index}
          formik={formik}
          allStaffData={allStaffData || []}
          allWorkData={allWorkData}
        />
      </td>
      <td className={"third-td"}>
        <div className={"third-td-content"}>
          <SelectInput
            width={"58px"}
            name={`startChar`}
            options={FuriganaAlphabetsOption}
            placeholder={"--"}
            value={data?.startChar}
            onChange={(v) => {
              formik.setFieldValue(
                `daily_business_support_report[${index}].startChar`,
                v,
              )
              formik.setFieldValue(
                `daily_business_support_report[${index}].staff_id`,
                null,
              )
            }}
            allowClear
          />
          <SelectInput
            width={"183px"}
            name={`daily_business_support_report[${index}].staff_id`}
            id={`daily_business_support_report[${index}].staff_id`}
            placeholder={"--"}
            value={data?.staff_id}
            loading={isLoading}
            options={filterWithHiragana({
              options: allStaffData || [],
              furigana: data?.startChar,
            })}
            onChange={(v) =>
              formik.setFieldValue(
                `daily_business_support_report[${index}].staff_id`,
                v,
              )
            }
          />
        </div>
      </td>
    </>
  )
}

export { SecondTable }
