import { theme } from "@project/common"
import { Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border: 1px solid ${theme.colors.error};
  border-radius: 12px;
  padding: 16px;
  .info__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .title_info {
      display: flex;
      gap: 5px;
      span {
        font-weight: bold;
        font-size: 16px;
        color: ${theme.colors.error};
      }
    }
  }

  ul {
    margin-left: 20px;
    li {
      color: ${theme.colors.error};
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    .info__container {
      width: 100%;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    .info__container {
      width: 70%;
    }
  }
`
const DailyListUnscheduledAlert = ({ userList }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className={"info__container"}>
        <div className={"title_info"}>
          <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
          <Typography.Text>
            {t(
              "The following users do not have facility schedule registered. Please delete them using the deletion form or register schedule.",
            )}
          </Typography.Text>
        </div>
      </div>
      <ul>
        {userList.map((user) => (
          <li key={user?.username}>{user?.username}</li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default DailyListUnscheduledAlert
