import { Flex } from "antd"
import React, { useMemo, useRef, useState } from "react"
import { ApplicationReoccurringSettingChangeOperation } from "./ApplicationReoccurringSettingChangeOperation"
import { ApplicationReoccurringSettingCalendarView } from "./ApplicationReoccurringSettingCalendarView/ApplicationReoccurringSettingCalendarView"

import {
  useFetUsersContractFacility,
  useFetchMealMaster,
} from "../../../../hooks/useFetchData"
import { WEEK_DAYS } from "../../../../constants"
import { scrollToSelectedElement } from "@project/common"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import { useQuery } from "react-query"
import { getOneUserFacilityRepeatSchedule } from "../../../../services/userManagement"
import { hasPermissionForMenu } from "../../../../utils/sidebarUtils"
import { RecurringSchedulePermission } from "../../../../utils/PermissionKeys"
import { useAuthContext } from "../../../../context"
import { fetchAllShiftUserMaster } from "../../../../services"

const INITIAL_VALUES = {
  facility_id: null,
  shift_id: null,
  meal_flag: true,
  meal_id: null,
  pickup: true,
  pickup_place: null,
  pickup_place_other: "",
  pickup_time: "",
  drop: true,
  drop_place: null,
  drop_place_other: "",
  drop_time: "",
  remarks: "",
  is_delete: true,
}

export const ApplicationReoccurringSettingChange = (): JSX.Element => {
  const router = useRouter()
  const scrollRef = useRef<HTMLDivElement>(null)
  const { isOwner, permissions } = useAuthContext()
  const { user_id, schedule_id, year, month, mode } = router?.query as any
  const [tempDate, setTempDate] = useState({
    year: year || dayjs().year(),
    month: month || dayjs().month() + 1,
  })
  const { userContractData, isLoading: isUserContractLoading } =
    useFetUsersContractFacility(user_id, true, tempDate)
  const { mealData, isLoading: isMealDataLoading } = useFetchMealMaster({
    page: 1,
    pageSize: "Infinity",
    facilityIds: userContractData
      ?.filter((contract) => contract.value)
      ?.map((contract) => contract.value)
      ?.join(","),
    enabled: !!userContractData?.length,
  })

  const { data: shiftData, isLoading: isShiftLoading } = useQuery(
    ["attendance-shift"],
    () =>
      fetchAllShiftUserMaster({
        page: 1,
        pageSize: "Infinity",
        facilityIds: [+userContractData?.[1]?.value],
      }),
    {
      keepPreviousData: false,
      select: ({ data }) => {
        let options = [{ label: "--", value: 0 }]
        options = options.concat(
          data?.map((shift) => ({
            label: shift?.attendance_shift_name,
            value: shift?.id?.toString(),
          })),
        )
        return options
      },
      enabled: !!userContractData?.[1]?.value,
    },
  )

  const [errors, setErrors] = useState({
    emptyError: false,
    required: false,
  })
  const [dataSource, setDataSource] = useState({
    user_id: +user_id,
    facility_id: +userContractData?.[1]?.value || null,
    year: year || dayjs().year(),
    month: month || dayjs().month() + 1,
    facility_repeat_schedule_details: [],
  })
  useMemo(() => {
    const data = Array.from({ length: 6 }, (_, index) => {
      const week_data = {}
      WEEK_DAYS?.map((val) => {
        week_data[val?.key] = INITIAL_VALUES
      })
      return {
        week_no: index + 1,
        ...week_data,
      }
    })
    setDataSource({
      ...dataSource,
      facility_repeat_schedule_details: data,
    })
  }, [])
  const hasDeleteAccess = useMemo(() => {
    if (mode != "edit") return false
    if (isOwner) return true
    return hasPermissionForMenu(
      permissions,
      RecurringSchedulePermission,
      "both",
    )
  }, [isOwner, permissions])
  const showCautionMessage = useMemo(() => {
    // no year month means isn't edit page
    if (!year || !month) {
      return false
    }
    const originalDate = dayjs([year, month - 1])
    const newDate = dayjs([tempDate?.year, tempDate?.month - 1])
    if (newDate.isAfter(originalDate)) {
      return true
    }
    return false
  }, [tempDate])
  const { isLoading, isFetching } = useQuery({
    queryFn: () => getOneUserFacilityRepeatSchedule(schedule_id),
    queryKey: ["user-facility-repeat-single-schedules", schedule_id],
    enabled: !!schedule_id,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const getNewValues = (facility_id: any) => {
        const data = []
        dataSource?.facility_repeat_schedule_details?.map((detail) => {
          const newData = res?.data?.facility_repeat_schedule_details?.filter(
            (v) => v?.week_no === detail?.week_no,
          )
          if (newData?.length) {
            newData?.map(({ week_no, ...rest }, index) => {
              const weekObj = {}
              Object.keys(rest)?.map((key) => {
                const values = {
                  ...newData[index][key],
                  shift_id: newData[index][key]?.shift_id
                    ? String(newData[index][key]?.shift_id)
                    : null,
                  facility_id: String(facility_id),
                  pickup:
                    newData[index][key]?.pickup &&
                    String(newData[index][key]?.pickup)
                      ? true
                      : false,
                  pickup_place: newData[index][key]?.pickup_place
                    ? String(newData[index][key]?.pickup_place)
                    : null,
                  drop:
                    newData[index][key]?.drop &&
                    String(newData[index][key]?.drop)
                      ? true
                      : false,
                  drop_place: newData[index][key]?.drop
                    ? String(newData[index][key]?.drop_place)
                    : null,
                  meal_flag:
                    newData[index][key]?.meal_flag &&
                    String(newData[index][key]?.meal_flag)
                      ? true
                      : false,
                  is_delete: newData[index][key]?.is_delete,
                }
                weekObj[key] = values
              })
              data.push({
                week_no,
                ...detail,
                ...weekObj,
              })
            })
          } else {
            data.push({
              ...detail,
            })
          }
        })
        return data
      }

      const responseData = {
        ...res?.data,
        year: mode === "copy" ? year : res?.data?.year,
        month: mode === "copy" ? month : res?.data?.month,
        facility_repeat_schedule_details: getNewValues(res?.data?.facility_id),
      }
      setDataSource(responseData)
    },
  })

  const updateMainParams = ({
    key,
    value,
    extra,
  }: {
    key: string
    value: any
    extra?: any
  }) => {
    if (extra) {
      setTempDate({
        ...tempDate,
        [extra?.key]: extra?.value,
      })
    }
    setDataSource({
      ...dataSource,
      [key]: value,
    })
  }
  const handleValueChange = ({
    week_no,
    week,
    key,
    value,
  }: {
    week_no: string
    week: string
    key: string
    value: string
  }) => {
    const checkForDeleteFlag = (key, value, data) => {
      if (key === "facility_id") {
        return value ? false : true
      }
      return data?.facility_id ? false : true
    }

    const newData = dataSource?.facility_repeat_schedule_details?.map(
      (detail) =>
        detail?.week_no === week_no
          ? {
              ...detail,
              [week]: {
                ...detail[`${week}`],
                [key]: value,
                is_delete: checkForDeleteFlag(key, value, detail[`${week}`]),
              },
            }
          : detail,
    )
    setDataSource({
      ...dataSource,
      facility_repeat_schedule_details: newData,
    })
    if (key === "facility_id" || (key === "shift_id" && mode === "add")) {
      setErrors({
        ...errors,
        emptyError: false,
      })
    }
  }
  const handleCancel = ({
    week_no,
    week,
  }: {
    week_no: number
    week: string
  }) => {
    const newData = dataSource?.facility_repeat_schedule_details?.map(
      (detail) =>
        detail?.week_no === week_no
          ? {
              ...detail,
              [week]: {
                ...INITIAL_VALUES,
                id: detail[week]?.id || null,
                is_delete: true,
              },
            }
          : detail,
    )
    setDataSource({
      ...dataSource,
      facility_repeat_schedule_details: newData,
    })
  }

  return (
    <Flex vertical gap={16}>
      <ApplicationReoccurringSettingChangeOperation
        shiftData={shiftData || []}
        mealData={mealData?.data || []}
        isMealDataLoading={isMealDataLoading}
        dataSource={dataSource}
        setSourceData={(values) => {
          setDataSource(values)
          scrollToSelectedElement(scrollRef)
        }}
        userContractData={userContractData}
      />
      <Flex ref={scrollRef}>
        <ApplicationReoccurringSettingCalendarView
          dataSource={dataSource}
          shiftData={shiftData || []}
          mealData={mealData?.data || []}
          isMealDataLoading={isMealDataLoading}
          handleValueChange={handleValueChange}
          handleCancel={handleCancel}
          updateMainParams={updateMainParams}
          userContractData={userContractData}
          isUserContractLoading={isUserContractLoading}
          isLoading={isLoading || isFetching || isShiftLoading}
          errors={errors}
          setErrors={setErrors}
          showDelete={hasDeleteAccess}
          month={tempDate?.month}
          showCautionMessage={showCautionMessage}
        />
      </Flex>
    </Flex>
  )
}
