import {
  getTotalTimeInSec,
  getUserAttendanceTypesByService,
  PrintFriendlyTable,
  SERVICE_TYPE_VALUES,
  USER_ATTENDANCE_VALUES,
} from "@project/common"
import { Typography } from "antd/lib"
import dayjs, { Dayjs } from "dayjs"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { FULL_DATE_FORMAT, FULL_DATE_FORMAT_EN } from "../../../constants"
import { getAllStaff, getAllWork } from "../../../services"
import { DailyBusinessReportBulkPrintStyledContainer } from "./DailyBusinessReportBulkPrint.style"

export const DailyBusinessReportBulkPrint = React.forwardRef(
  (
    {
      currentDate,
      users,
      dateInfo,
      supportRecord,
      businessDiary,
    }: {
      users: any
      currentDate: Dayjs
      dateInfo: any
      supportRecord: number
      businessDiary: boolean
    },
    ref: any,
  ): JSX.Element => {
    const { t } = useTranslation()
    const { data: allStaffData } = useQuery<any>(
      ["get-all-staff-view"],
      () => getAllStaff(),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: 1,
        select: ({ data }) =>
          data?.map((d) => {
            return { label: d?.staff_name, value: d?.id, furigana: d?.furigana }
          }),
      },
    )
    const { data: allWorkData } = useQuery<any>(
      ["get-all-work-data-view"],
      () => getAllWork(),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: 1,
        select: ({ data }) =>
          data?.map((d) => {
            return { label: d?.work_name, value: d?.id }
          }),
      },
    )
    const getAttendanceSummary = (type: string) => {
      return (
        dateInfo?.attendanceInfo?.filter(
          (dateItem) => dateItem?.attendance_type === type,
        ).length || 0
      )
    }
    const calculateInterviewTotalTimeInMinutes = useMemo(() => {
      let totalMinutes = 0
      users?.daily_business_support_report
        ?.filter((report) => report.interview)
        ?.forEach((report) => {
          const startTime = `${report.start_time_hr ?? "00"}:${
            report.start_time_min ?? "00"
          }`
          const endTime = `${report.end_time_hr ?? "00"}:${
            report.end_time_min ?? "00"
          }`
          const timeDifferenceInSeconds = getTotalTimeInSec(
            dayjs(users?.date).format(FULL_DATE_FORMAT_EN),
          )(startTime, endTime)
          totalMinutes += timeDifferenceInSeconds / 60
        })
      return totalMinutes
    }, [users?.daily_business_support_report])

    return (
      <DailyBusinessReportBulkPrintStyledContainer ref={ref}>
        <div className={"title_container"}>
          <Typography.Title level={5} className={"title"}>
            {supportRecord === 1
              ? t("Support Record")
              : t("Provided support record")}
          </Typography.Title>
          <Typography.Paragraph className={"sub_title"}>
            {dayjs(currentDate).format(FULL_DATE_FORMAT)}
          </Typography.Paragraph>
        </div>
        <div className={"attendance--info-container"}>
          <Typography.Paragraph>
            {t("Number of attendance")} {":"}{" "}
            {getAttendanceSummary(USER_ATTENDANCE_VALUES.ATTENDANCE)}{" "}
            {t("people")}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t("Absent supported")} {":"}{" "}
            {getAttendanceSummary(USER_ATTENDANCE_VALUES.ABSENCE)} {t("people")}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t("Off site support")} {":"}{" "}
            {getAttendanceSummary(USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT)}{" "}
            {t("people")}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t("Interview")} {":"}{" "}
            {dateInfo?.dailyBusinessSupportReport?.length} {t("times")}
            {`(${calculateInterviewTotalTimeInMinutes} ${t("minutes")})`}
          </Typography.Paragraph>
        </div>
        {businessDiary && (
          <div className={"support-contents-container"}>
            <Typography.Paragraph style={{ margin: 0 }}>
              {t("Business report")}
            </Typography.Paragraph>
            <PrintFriendlyTable
              headerBg={"#f5f5f5"}
              columns={[
                {
                  title: t("Type"),
                  key: "1",
                  width: 60,
                  align: "center",
                  render: (row) => row?.type || "",
                },
                {
                  title: t("Support record"),
                  key: "3",
                  render: (row) => {
                    if (row?.isWork) {
                      const workData = row?.content
                        ?.map(
                          (work) =>
                            allWorkData?.find((d) => d.value == work?.work_id)
                              ?.label,
                        )
                        .join(", ")
                      return workData
                    }
                    return row?.content || ""
                  },
                },
                {
                  title: t("Recorder"),
                  key: "4",
                  width: 100,
                  align: "center",
                  render: () => {
                    const recorder = allStaffData?.find(
                      (d) => d.value == users?.staff_id,
                    )
                    return recorder?.label || ""
                  },
                  onCell: (_, index) => {
                    if (index === 0) {
                      return {
                        rowSpan: 5,
                      }
                    }
                    if (index > 0) {
                      return {
                        colSpan: 0,
                      }
                    }
                    return {}
                  },
                },
                {
                  title: t("Signature"),
                  key: "5",
                  width: 100,
                  onCell: (_, index) => {
                    if (index === 0) {
                      return {
                        rowSpan: 5,
                      }
                    }
                    if (index > 0) {
                      return {
                        colSpan: 0,
                      }
                    }
                    return {}
                  },
                },
              ]}
              dataSource={
                [
                  {
                    type: t("Morning"),
                    content: users?.morning || "",
                  },
                  {
                    type: t("Afternoon"),
                    content: users?.afternoon || "",
                  },
                  {
                    type: t("Work"),
                    isWork: true,
                    content: users?.daily_business_support_works || [],
                  },
                  {
                    type: t("Other"),
                    content: users?.other || "",
                  },
                  {
                    type: t("Accompanying Staff"),
                    content: users?.accompanying_staff || "",
                  },
                ] || []
              }
            />
          </div>
        )}

        <PrintFriendlyTable
          headerBg={"#f5f5f5"}
          columns={[
            {
              title: t("User name"),
              key: "1",
              width: 60,
              align: "center",
              render: (row) => row?.username || "",
            },
            {
              title: t("User status"),
              key: "2",
              width: 60,
              align: "center",
              render: (row) => {
                const attendance_status = t(
                  getUserAttendanceTypesByService(row?.service_type)?.find(
                    (d) => d?.value == row?.attendance_type,
                  )?.label || "",
                )
                return (
                  <div>
                    {attendance_status && (
                      <Typography.Text className={"text"}>
                        {attendance_status}{" "}
                      </Typography.Text>
                    )}
                    <div
                      className={"text"}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {row?.start_time && row?.end_time ? (
                        <>
                          {row?.start_time || "00:00"}
                          <span>{"~"}</span>
                          {row?.end_time || "00:00"}
                        </>
                      ) : null}
                    </div>
                    {row?.goto_meet_flag && row?.take_flag ? (
                      <Typography.Text className={"text"}>
                        {t("Transportation")}
                        {":"} {`${t("pick")}&${t("drop")}`}
                      </Typography.Text>
                    ) : row?.goto_meet_flag ? (
                      <Typography.Text className={"text"}>
                        {t("Transportation")}
                        {":"} {`${t("pick")}`}
                      </Typography.Text>
                    ) : row?.take_flag ? (
                      <Typography.Text className={"text"}>
                        {t("Transportation")}
                        {":"} {`${t("pick")}`}
                      </Typography.Text>
                    ) : null}
                  </div>
                )
              },
            },
            {
              title: t("Support record"),
              key: "3",
              render: (row) => {
                return (
                  <div className={"support-record-container"}>
                    {row?.is_absent ? (
                      <>
                        <div className={"support-record-container--content"}>
                          <Typography.Text className={"label text"}>
                            {t("Person in charge of Visit")}
                            {": "}
                          </Typography.Text>
                          <Typography.Text className={"value text"}>
                            {allStaffData?.find(
                              (v) => v?.value === row?.absent_recorder_staff_id,
                            )?.label || "-"}
                          </Typography.Text>
                        </div>
                        <div className={"support-record-container--content"}>
                          <Typography.Text className={"label text"}>
                            {t("Reason of absence and support contents")} {": "}
                          </Typography.Text>
                          <Typography.Text className={"value text"}>
                            {row?.absence_reason || "-"}
                          </Typography.Text>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        {row?.work && (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Work",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {row?.work || ""}
                            </Typography.Text>
                          </div>
                        )}
                        {row?.interview && (
                          <>
                            <div
                              className={"support-record-container--content"}
                            >
                              <Typography.Text className={"label text"}>{`[${t(
                                "Interview",
                              )}] : `}</Typography.Text>
                              <Typography.Text className={"value text"}>
                                {row?.interview ? t("Yes") : t("No")}
                              </Typography.Text>
                            </div>{" "}
                            <div
                              className={"support-record-container--content"}
                            >
                              <Typography.Text className={"label text"}>{`[${t(
                                "Interview time",
                              )}] : `}</Typography.Text>
                              <Typography.Text className={"value text"}>
                                {`${row?.start_time_hr || "--"}:${
                                  row?.start_time_min || "--"
                                } ~ ${row?.end_time_hr || "--"}:${
                                  row?.end_time_min || "--"
                                }`}
                              </Typography.Text>
                            </div>
                          </>
                        )}
                        {row?.interview_record && (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Interview Content",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {row?.interview_record || "-"}
                            </Typography.Text>
                          </div>
                        )}
                        {row?.service_type ===
                        SERVICE_TYPE_VALUES.TRANSITION ? (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Visited  facility name",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {"faclity_name"}
                            </Typography.Text>
                          </div>
                        ) : null}
                        {row?.support_record && (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Support record",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {row?.support_record || "-"}
                            </Typography.Text>
                          </div>
                        )}
                        {row?.service_type ===
                        SERVICE_TYPE_VALUES.TRANSITION ? (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Person in charge of Visit",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {row?.person_in_charge}
                            </Typography.Text>
                          </div>
                        ) : null}
                        {row?.other && (
                          <div className={"support-record-container--content"}>
                            <Typography.Text className={"label text"}>{`[${t(
                              "Other",
                            )}] : `}</Typography.Text>
                            <Typography.Text className={"value text"}>
                              {row?.other || "-"}
                            </Typography.Text>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )
              },
            },
            {
              title: t("Recorder"),
              key: "4",
              width: 100,
              align: "center",
              render: (row) => {
                const recorder = allStaffData?.find(
                  (d) => d.value == row?.staff_id,
                )
                return recorder?.label || ""
              },
            },
            {
              title: t("Signature"),
              key: "5",
              width: 100,
            },
          ]}
          dataSource={users?.daily_business_support_report || []}
        />
      </DailyBusinessReportBulkPrintStyledContainer>
    )
  },
)
