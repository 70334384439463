import { theme } from "@project/common"
import styled from "styled-components"
export const StaffMonthlyAttendanceRecordStyleWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const NoFilterContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  color: ${theme.colors.error};
  font-weight: bold;
`
