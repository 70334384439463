import {
  Button,
  Card,
  SelectInput,
  Table,
  dynamicLangString,
  theme,
} from "@project/common"
import {
  getBusinessRegistrationMonths,
  getBusinessRegistrationYears,
} from "@project/common/src/constants"
import {
  Empty,
  Flex,
  Popconfirm,
  Skeleton,
  Spin,
  Typography,
  notification,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { WEEK_DIVISION } from "../../../../../constants"
import {
  createUserFacilityRepeatSchedule,
  deleteUserFacilityRepeatSchedule,
  updateOneUserFacilityRepeatSchedule,
} from "../../../../../services/"
import { REOCCURRING_INITIAL_VALUES } from "../../../../../types"
import { ApplicationReoccurringSettingCalendarViewContent } from "./ApplicationReoccurringSettingCalendarViewContent/ApplicationReoccurringSettingCalendarViewContent"

const DateContainer = styled.div`
  background-color: ${theme.colors.info};
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: fit-content;
  padding: 15px 10px;
  color: ${theme.colors.white};
`

export const ApplicationReoccurringSettingCalendarView = ({
  isLoading,
  dataSource,
  shiftData,
  handleValueChange,
  handleCancel,
  updateMainParams,
  userContractData,
  errors,
  isUserContractLoading,
  showDelete,
  month,
  showCautionMessage,
  mealData,
  isMealDataLoading,
}: {
  isLoading: boolean
  dataSource: REOCCURRING_INITIAL_VALUES
  shiftData: any[]
  userContractData: any
  handleCancel: ({ week_no, week }: { week_no: number; week: string }) => void
  handleValueChange: (values: any) => void
  updateMainParams: ({
    key,
    value,
    extra,
  }: {
    key: string
    value: any
    extra?: any
  }) => void
  errors?: any
  setErrors: React.Dispatch<any>
  isUserContractLoading
  showDelete?: boolean
  month?: number
  showCautionMessage?: boolean
  mealData: any[]
  isMealDataLoading: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { schedule_id, mode } = router?.query as any

  //create data ->post
  const serializePayload = (facility_repeat_schedule_details) => {
    const data = []
    facility_repeat_schedule_details?.map(({ week_no, ...detail }) => {
      const obj = {}
      Object.keys(detail)?.map((key) => {
        const v = {
          ...detail[key],
          shift_id: +detail[key]?.shift_id || null,
          facility_id: +detail[key]?.facility_id || null,
          pickup: detail[key]?.pickup
            ? 1
            : detail[key]?.facility_id && detail[key]?.shift_id
              ? 0
              : null,
          pickup_place: detail[key]?.pickup ? +detail[key]?.pickup_place : null,
          drop: detail[key]?.drop
            ? 1
            : detail[key]?.facility_id && detail[key]?.shift_id
              ? 0
              : null,
          drop_place: detail[key]?.drop ? +detail[key]?.drop_place : null,
          meal_flag: detail[key]?.meal_flag
            ? 1
            : detail[key]?.facility_id && detail[key]?.shift_id
              ? 0
              : null,
          meal_id: detail[key]?.meal_flag
            ? +detail[key]?.meal_id
            : null || null,
          pickup_place_other: detail[key]?.pickup
            ? detail[key]?.pickup_place_other
            : "",
          drop_place_other: detail[key]?.drop
            ? detail[key]?.drop_place_other
            : "",
          pickup_time: detail[key]?.pickup ? detail[key]?.pickup_time : "",
          drop_time: detail[key]?.drop ? detail[key]?.drop_time : "",
        }
        if (mode !== "edit") {
          if (v?.facility_id) {
            obj[`week_no`] = week_no
            obj[key] = v
          }
        } else {
          obj[`week_no`] = week_no
          obj[key] = v
        }
      })
      if (Object.keys(obj)?.length) {
        data.push(obj)
      }
    })
    //filter out which do not have shift/facility id

    return data
  }
  const { isLoading: isCreating, mutate } = useMutation({
    mutationFn: createUserFacilityRepeatSchedule,
    onSuccess: () => {
      notification.success({
        message: dynamicLangString([
          "Recurring Schedule",
          "Updated Successfully",
        ]),
      })
      router.push({
        pathname: `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list`,
      })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  // edit/update data -> put
  const { isLoading: isUpdating, mutate: updateFacilitySchedule } = useMutation(
    {
      mutationFn: updateOneUserFacilityRepeatSchedule,
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Recurring Schedule",
            "Updated Successfully",
          ]),
        })
        router.push({
          pathname: `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list`,
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  //delete data->delete
  const { isLoading: isDeleting, mutate: deleteFacilitySchedule } = useMutation(
    {
      mutationFn: deleteUserFacilityRepeatSchedule,
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Recurring Schedule",
            "Deleted Successfully",
          ]),
        })
        router.push({
          pathname: `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list`,
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const onSave = () => {
    const data = {
      user_id: +dataSource?.user_id || router?.query.user_id,
      facility_id:
        +dataSource?.facility_id || +userContractData?.[1]?.value || null,
      year: +dataSource?.year || dayjs().year(),
      month: +dataSource?.month || dayjs().month(),
      facility_repeat_schedule_details: serializePayload(
        dataSource?.facility_repeat_schedule_details,
      ),
    }
    if (mode === "edit" && schedule_id) {
      updateFacilitySchedule({
        values: data,
        schedule_id,
      })
    } else {
      mutate(data)
    }
  }
  const columns: ColumnsType<any> = [
    {
      key: "1",
      title: t(""),
      render: (_, __, index) => WEEK_DIVISION[index]?.label,
      width: 100,
      align: "center",
    },
    {
      key: "2",
      title: t("Sunday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`sun`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "sun",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "sun",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "3",
      title: t("Monday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`mon`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "mon",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "mon",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "4",
      title: t("Tuesday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              background: theme.colors.disabled,
              padding: "8px 12px",
              verticalAlign: "top",
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`tue`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "tue",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "tue",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "5",
      title: t("Wednesday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`wed`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "wed",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "wed",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "6",
      title: t("Thursday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`thu`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "thu",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "thu",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "6",
      title: t("Friday"),
      width: 120,
      render: (row, __, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`fri`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "fri",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "fri",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
    {
      key: "7",
      title: t("Saturday"),
      width: 120,
      render: (row, _, index) => {
        return {
          props: {
            style: {
              padding: "8px 12px",
              verticalAlign: "top",
              background: theme.colors.disabled,
            },
          },
          children: (
            <ApplicationReoccurringSettingCalendarViewContent
              shiftData={shiftData}
              userContractData={userContractData}
              mealData={mealData || []}
              defaultValues={row[`sat`]}
              handleValueChange={(values) => {
                handleValueChange({
                  week_no: index + 1,
                  week: "sat",
                  ...values,
                })
              }}
              handleCancel={() => {
                handleCancel({
                  week: "sat",
                  week_no: index + 1,
                })
              }}
            />
          ),
        }
      },
      align: "center",
    },
  ]

  return (
    <Card
      title={t("List")}
      style={{
        width: "100%",
      }}
    >
      <Flex vertical gap={16}>
        <DateContainer
          style={{
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <div>{t("Recurring schedule start year & month：")}</div>
          <Flex className={"date-select"} align={"center"} gap={4}>
            <SelectInput
              name={"year"}
              options={getBusinessRegistrationYears()}
              width={"80px"}
              value={dataSource?.year}
              onChange={(val) => {
                updateMainParams({
                  key: "year",
                  value: val,
                  extra: {
                    key: "year",
                    value: val,
                  },
                })
              }}
            />
            {t("Year")}
            <SelectInput
              name={"month"}
              options={getBusinessRegistrationMonths()}
              width={"60px"}
              value={dataSource?.month}
              onChange={(val) => {
                updateMainParams({
                  key: "month",
                  value: val,
                  extra: {
                    key: "month",
                    value: val,
                  },
                })
              }}
            />
            {t("Month")}
          </Flex>
        </DateContainer>

        {isLoading || isMealDataLoading ? (
          <Flex vertical gap={16} style={{ width: "100%" }}>
            {dataSource?.facility_repeat_schedule_details?.map((_, index) => (
              <Flex key={index} gap={8}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Flex>
            ))}
          </Flex>
        ) : (
          <>
            {!userContractData || userContractData?.length <= 1 ? (
              <Spin
                spinning={isUserContractLoading}
                tip={t("contract loading")}
              >
                <Flex
                  justify={"center"}
                  align={"center"}
                  style={{
                    minHeight: "80vh",
                  }}
                >
                  <Empty
                    description={
                      <Flex>
                        <Typography.Title level={4}>
                          {t("No contract facility available")}
                        </Typography.Title>
                      </Flex>
                    }
                  />
                </Flex>
              </Spin>
            ) : (
              <Spin
                spinning={isUserContractLoading}
                tip={t("contract loading")}
              >
                <Table
                  columns={columns || []}
                  dataSource={
                    dataSource?.facility_repeat_schedule_details || []
                  }
                  scroll={{ x: true }}
                  cellPaddingBlock={5}
                />
                <br />
                <Flex vertical>
                  {errors?.emptyError && (
                    <Typography.Text type={"danger"}>
                      {t("Select at least one shift and facility first.")}
                    </Typography.Text>
                  )}
                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    wrap={"wrap"}
                    gap={8}
                  >
                    <Flex gap={16} align={"center"} wrap={"wrap"}>
                      <Button
                        btnText={t("Cancel")}
                        shape={"round"}
                        disabled={isLoading || isCreating || isUpdating}
                        onClick={() =>
                          router.push(
                            `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list`,
                          )
                        }
                      />
                      {(!userContractData || userContractData?.length >= 2) &&
                      showCautionMessage ? (
                        <Popconfirm
                          title={
                            <div>
                              {t(
                                "The update will be reflected on facility applications from {{month}}. Any facility application before {{month}} shown based on this recurring schedule will be removed.  If you wish to keep old facility applications  please create a new or copy this recurring schedule. Please click ok to update",
                                {
                                  month: `${month}月`,
                                },
                              )}
                            </div>
                          }
                          okText={t("OK")}
                          cancelText={t("Cancel")}
                          onConfirm={onSave}
                          okButtonProps={{
                            size: "middle",
                            shape: "round",
                            style: { padding: "0px 14px" },
                          }}
                          cancelButtonProps={{
                            size: "middle",
                            shape: "round",
                            style: { padding: "0px 14px" },
                          }}
                        >
                          <Button
                            btnText={t("Save")}
                            shape={"round"}
                            type={"primary"}
                            isLoading={isCreating || isUpdating || isDeleting}
                          />
                        </Popconfirm>
                      ) : (
                        <Button
                          btnText={t("Save")}
                          shape={"round"}
                          type={"primary"}
                          isLoading={isCreating || isUpdating}
                          onClick={onSave}
                        />
                      )}
                    </Flex>
                    {showDelete && (
                      <Popconfirm
                        title={t("Deleting.Is that OK?")}
                        okText={t("OK")}
                        cancelText={t("Cancel")}
                        okButtonProps={{ size: "middle" }}
                        cancelButtonProps={{ size: "middle" }}
                        onConfirm={() => deleteFacilitySchedule(schedule_id)}
                      >
                        <Button
                          btnText={t("Delete")}
                          shape={"round"}
                          btnBg={theme.colors.error}
                          borderColor={theme.colors.error}
                          textColor={theme.colors.white}
                          hoverBorderColor={theme.colors.error}
                          isLoading={isDeleting}
                        />
                      </Popconfirm>
                    )}
                  </Flex>
                </Flex>
              </Spin>
            )}
          </>
        )}
      </Flex>
    </Card>
  )
}
