import React, { useEffect } from "react"
import {
  AccountSubjectColumnSharedCell,
  firstColumnSharedCell,
  secondColumnSharedCell,
} from "./tabRowsUtils"
import { useTranslation } from "react-i18next"
import { OVERALL_REVENUE_TAB_COLUMN } from "../../../types"
import { AccountSubjectContent } from "./AccountSubjectContent"
import { Table } from "@project/common"
import styled from "styled-components"
import { MULTIPLE_FACILITY_ROWS, SINGLE_FACILITY_ROWS } from "./constantTabRows"
import { ColumnsType } from "antd/lib/table"

import { useWagesRevenueContext } from "../../../context"
import { useRouter } from "next/router"
export const TableWrapper = styled.div`
  width: 100%;
  tbody {
    tr {
      td.content_align {
        text-align: left !important;
      }
    }
  }
  .row_w-fixed {
    min-width: 50px;
  }
  .wages-revenue-overall-tab-table-row-with-gray-bg {
    background-color: #ededed;
  }
  .wages-revenue-overall-tab-table-row-with-orange-bg {
    background-color: #fff7cb;
  }
`
export const WagesRevenueOFWiseTabTable = ({
  columns = [],
  isLoading,
  isMultiple,
  csvCols,
}: {
  columns: any[]
  csvCols?: any[]
  isLoading: boolean
  isMultiple?: boolean
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { panel } = router?.query as {
    panel: string
  }
  const { setDownloadAbleDataOf, downloadAbleDataOf } = useWagesRevenueContext()
  const getCols = (action: "download" | "view") => {
    if (action === "download" && csvCols?.length) {
      return csvCols || []
    }
    return columns || []
  }

  const getColumns = (action: "download" | "view") => {
    const defaultColumns: ColumnsType<OVERALL_REVENUE_TAB_COLUMN> = [
      {
        title: "",
        width: 71,
        colSpan: 2,
        align: "center",
        className: "row_w-fixed",
        dataIndex: "activity",
        render: (_, row: OVERALL_REVENUE_TAB_COLUMN, index) => {
          if (isMultiple && index === 58) {
            return action === "download" ? (
              t(row?.subject)
            ) : (
              <AccountSubjectContent label={row?.subject} />
            )
          }
          if (index === 33 || index === 53) {
            return action === "download" ? (
              t(row?.subject)
            ) : (
              <AccountSubjectContent label={row?.subject} />
            )
          }
          return action === "download"
            ? t(row?.activity)
            : (
                <span
                  style={{
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {t(row?.activity)}
                </span>
              ) || ""
        },
        onCell: (_, index) => firstColumnSharedCell(index, isMultiple),
      },
      {
        title: "",
        width: 71,
        colSpan: 0,
        className: "row_w-fixed",
        align: "center",
        dataIndex: "type",
        render: (_, row, index) => {
          if (index === 16 || index === 32 || index >= 54) {
            return t(row?.subject)
          }
          return t(row?.type)
        },
        onCell: (_, index) => secondColumnSharedCell(index, isMultiple),
      },
      {
        title: t("Accounting Subject"),
        key: "subject",
        dataIndex: "subject",
        width: 282,
        align: "center",
        render: (val) => t(val),
        onCell: (_, index) => AccountSubjectColumnSharedCell(index, isMultiple),
      },
      ...getCols(action),
    ]
    return defaultColumns
  }

  const dataSource = isMultiple
    ? MULTIPLE_FACILITY_ROWS || []
    : SINGLE_FACILITY_ROWS || []

  useEffect(() => {
    setDownloadAbleDataOf({
      ...downloadAbleDataOf,
      tab:
        panel === "facility_wise"
          ? t("Facility wise revenue")
          : t("Overall revenue"),
      [panel]: {
        columns: getColumns("download"),
        dataSource: dataSource,
      },
    })
  }, [panel])
  return (
    <TableWrapper>
      <Table
        columns={getColumns("view") || []}
        dataSource={dataSource || []}
        scroll={{ x: 900 }}
        loading={isLoading}
        rowClassName={(row, index) => {
          if (index === 4 || index === 16 || index === 24 || index === 32) {
            return "wages-revenue-overall-tab-table-row-with-gray-bg"
          }
          if (isMultiple) {
            if (index === 44 || index === 57) {
              return "wages-revenue-overall-tab-table-row-with-gray-bg"
            }
            if (index === 33 || index === 58) {
              return "wages-revenue-overall-tab-table-row-with-orange-bg"
            }
          } else {
            if (index === 40 || index == 52) {
              return "wages-revenue-overall-tab-table-row-with-gray-bg"
            }
            if (index === 33 || index === 53) {
              return "wages-revenue-overall-tab-table-row-with-orange-bg"
            }
          }

          return ""
        }}
      />
    </TableWrapper>
  )
}
