import {
  AccordionCard,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { YEAR_DATE_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../context"

export const PrintAddressToUserOpOptions = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const { facilityIds, year, month } = router?.query as any
  const [values, setValues] = useState({
    facilityIds: facilityIds || null,
    date: dayjs(`${year}-${month}`).isValid()
      ? dayjs(`${year}-${month}`)
      : dayjs(),
  })
  return (
    <AccordionCard
      defaultActiveKey={["address-to-users"]}
      items={[
        {
          label: t("Operation Options"),
          key: "address-to-users",
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Facility  ")}
                  width={"136px"}
                />
                <SelectInput
                  name={"facility"}
                  value={values?.facilityIds || null}
                  options={facilities || []}
                  onChange={(val) => setValues({ ...values, facilityIds: val })}
                  placeholder={"--"}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year month")}
                  width={"136px"}
                />
                <DatePicker
                  date={dayjs(values?.date)}
                  onDateChange={(date: Dayjs) => {
                    setValues({ ...values, date })
                  }}
                  picker={"month"}
                  format={YEAR_DATE_FORMAT}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    setValues({
                      facilityIds: facilities[0]?.value,
                      date: dayjs(),
                    })
                    updateParams(
                      {
                        facilityIds: facilities[0]?.value,
                        facility: facilities[0]?.value,
                        year: dayjs().year(),
                        month: dayjs().month() + 1,
                      },
                      "/national-health-insurance-billing/prints/address-to-users",
                    )
                  }}
                />
                <Button
                  btnText={t("Search")}
                  shape={"round"}
                  type={"primary"}
                  iconType={"search"}
                  onClick={() => {
                    updateParams(
                      {
                        facilityIds: values?.facilityIds,
                        facility: values?.facilityIds,
                        year: values?.date.year(),
                        month: values?.date.month() + 1,
                      },
                      "/national-health-insurance-billing/",
                    )
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
