import {
  InputAreaField,
  InputField,
  MINUTES_HOUR,
  MINUTES_TIMES,
  SelectInput,
  theme,
} from "@project/common"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AuthContext } from "../../../../context"
const DynamicTableWrapper = styled.div`
  .table-data {
    width: 40%;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }
  .error {
    color: red;
    font-size: 14px;
  }
`

export const StartTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints.xxs}) {
    flex-direction: column;
  }
  .start_time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`

const DynamicTable = ({ data, index, formik, allStaffData, allWorkData }) => {
  const parentName = "daily_business_support_report"
  const { facilities } = useContext(AuthContext)
  const { t } = useTranslation()

  return (
    <>
      <DynamicTableWrapper>
        <table>
          {data?.is_absent ? (
            <>
              <tr>
                <td className={"table-data"}>{t("Staff who handled")}</td>
                <td>
                  <SelectInput
                    width={"100%"}
                    name={`${parentName}[${index}].absent_recorder_staff_id`}
                    id={`${parentName}[${index}].absent_recorder_staff_id`}
                    placeholder={"--"}
                    options={allStaffData || []}
                    value={data?.absent_recorder_staff_id || null}
                    onChange={(val) => {
                      formik.setFieldValue(
                        `${parentName}[${index}].work_id`,
                        val,
                      )
                      formik.setFieldValue(
                        `${parentName}[${index}].absent_recorder_staff_id`,
                        val,
                      )
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className={"table-data"}>
                  {t("Reason of absence and support contents")}
                </td>
                <td>
                  <InputAreaField
                    name={`${parentName}[${index}].absence_reason`}
                    id={`${parentName}[${index}].absence_reason`}
                    padding={"8px"}
                    className={"field_value"}
                    placeholder={t("Please enter")}
                    borderRadius={"4px"}
                    rows={3.5}
                    value={data?.absence_reason || null}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].absence_reason`,
                        e.target.value,
                      )
                    }
                  />
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td className={"table-data"}>{t("Work")}</td>
                <td>
                  <SelectInput
                    width={"100%"}
                    name={`${parentName}[${index}].work_id`}
                    id={`${parentName}[index].work_id`}
                    value={data?.work_id}
                    options={allWorkData}
                    className={"field_value"}
                    placeholder={t("Please select")}
                    mode={"multiple"}
                    height={"fit-content"}
                    onChange={(v) =>
                      formik.setFieldValue(`${parentName}[${index}].work_id`, v)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className={"table-data"}>{t("Interview")}</td>
                <td>
                  <SelectInput
                    width={"100%"}
                    id={`${parentName}[${index}].interview`}
                    name={`${parentName}[${index}].interview`}
                    value={data?.interview}
                    options={[
                      { label: t("Yes"), value: true },
                      { label: t("No"), value: false },
                    ]}
                    className={"field_value"}
                    placeholder={""}
                    onChange={(v) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].interview`,
                        v,
                      )
                    }
                  />
                </td>
              </tr>
              {data?.interview ? (
                <>
                  <tr>
                    <td className={"table-data"}>{t("Interview time")}</td>
                    <td>
                      <StartTimeWrapper>
                        <div>
                          <span>{t("Start Time ")}</span>
                          <div className={"start_time"}>
                            <SelectInput
                              name={"start_time_hr"}
                              options={MINUTES_HOUR}
                              width={"58px"}
                              placeholder={t("--")}
                              value={data?.start_time_hr}
                              onChange={(v) => {
                                formik.setFieldValue(
                                  `${parentName}[${index}].start_time_hr`,
                                  v,
                                )

                                formik.setFieldValue(
                                  `${parentName}[${index}].start_time_min`,
                                  !data.start_time_min ||
                                    data.start_time_hr === ""
                                    ? "00"
                                    : data.start_time_min,
                                )
                              }}
                            />

                            <span>{":"}</span>
                            <SelectInput
                              options={MINUTES_TIMES}
                              name={"start_time_min"}
                              width={"58px"}
                              placeholder={t("--")}
                              value={data?.start_time_min}
                              onChange={(v) =>
                                formik.setFieldValue(
                                  `${parentName}[${index}].start_time_min`,
                                  v,
                                )
                              }
                              disabled={data.start_time_hr === ""}
                            />
                          </div>
                        </div>

                        <div>
                          <span>{t("End Time ")}</span>
                          <div className={"start_time"}>
                            <SelectInput
                              name={"end_time_hr"}
                              options={MINUTES_HOUR}
                              width={"58px"}
                              placeholder={t("--")}
                              value={data?.end_time_hr}
                              onChange={(v) => {
                                formik.setFieldValue(
                                  `${parentName}[${index}].end_time_hr`,
                                  v,
                                )
                                formik.setFieldValue(
                                  `${parentName}[${index}].end_time_min`,
                                  !data.end_time_min || data.end_time_hr === ""
                                    ? "00"
                                    : data.end_time_min,
                                )
                              }}
                            />
                            <span>{":"}</span>
                            <SelectInput
                              name={"end_time_min"}
                              options={MINUTES_TIMES}
                              width={"58px"}
                              placeholder={t("--")}
                              value={data?.end_time_min}
                              onChange={(v) =>
                                formik.setFieldValue(
                                  `${parentName}[${index}].end_time_min`,
                                  v,
                                )
                              }
                              disabled={data.end_time_hr === ""}
                            />
                          </div>
                        </div>
                      </StartTimeWrapper>
                      {formik.errors.daily_business_support_report &&
                        formik.errors.daily_business_support_report[index] && (
                          <div className={"error"}>
                            {formik.errors.daily_business_support_report[index]}
                          </div>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className={"table-data"}>{t("Interview Content")}</td>
                    <td>
                      <InputAreaField
                        name={`${parentName}[${index}].interview_record`}
                        id={`${parentName}[${index}].interview_record`}
                        placeholder={t("Please enter")}
                        padding={"8px"}
                        borderRadius={"4px"}
                        rows={3.5}
                        className={"field_value"}
                        value={data?.interview_record}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `${parentName}[${index}].interview_record`,
                            e.target.value,
                          )
                        }
                      />
                    </td>
                  </tr>
                </>
              ) : null}

              {data?.service_type === 3 ? (
                <tr>
                  <td className={"table-data"}>{t("Visited facility name")}</td>
                  <td>
                    <SelectInput
                      width={"100%"}
                      id={`${parentName}[${index}].facility_id`}
                      name={`${parentName}[${index}].facility_id`}
                      value={
                        data?.facility_id ? String(data?.facility_id) : null
                      }
                      options={facilities}
                      className={"field_value"}
                      placeholder={t("Please enter")}
                      allowClear
                      onChange={(v) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].facility_id`,
                          v,
                        )
                      }
                    />
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className={"table-data"}>{t("Support record")}</td>
                <td>
                  <InputAreaField
                    name={`${parentName}[${index}].support_record`}
                    id={`${parentName}[${index}].support_record`}
                    placeholder={t("Please enter")}
                    padding={"8px"}
                    borderRadius={"4px"}
                    rows={3.5}
                    className={"field_value"}
                    value={data?.support_record}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].support_record`,
                        e.target.value,
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className={"table-data"}>{t("Condition of user")}</td>
                <td>
                  <InputAreaField
                    name={`${parentName}[${index}].condition_of_user`}
                    id={`${parentName}[${index}].condition_of_user`}
                    placeholder={t("Please enter")}
                    className={"field_value"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    rows={3.5}
                    value={data?.condition_of_user}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].condition_of_user`,
                        e.target.value,
                      )
                    }
                  />
                </td>
              </tr>
              {data?.service_type === 3 ? (
                <tr>
                  <td className={"table-data"}>{t("Staff who handled")}</td>
                  <td>
                    <InputField
                      name={`${parentName}[${index}].person_in_charge`}
                      id={`${parentName}[${index}].person_in_charge`}
                      placeholder={t("")}
                      className={"field_value"}
                      padding={"8px"}
                      borderRadius={"4px"}
                      maxLength={100}
                      value={data?.person_in_charge}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `${parentName}[${index}].person_in_charge`,
                          e.target.value,
                        )
                      }
                    />
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className={"table-data"}>{t("Other")}</td>
                <td>
                  <InputAreaField
                    name={`${parentName}[${index}].other`}
                    id={`${parentName}[${index}].other`}
                    padding={"8px"}
                    className={"field_value"}
                    placeholder={t("Please enter")}
                    borderRadius={"4px"}
                    rows={3.5}
                    value={data?.other}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `${parentName}[${index}].other`,
                        e.target.value,
                      )
                    }
                  />
                </td>
              </tr>
            </>
          )}
        </table>
      </DynamicTableWrapper>
    </>
  )
}

export default DynamicTable
