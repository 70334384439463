import {
  Button,
  Card,
  InputAreaField,
  InputField,
  WAGE_OPTION_VALUES,
  dynamicLangString,
  getDaysOfMont,
  getTotalAmount,
  moneyFormat,
  theme,
  userWageSetting,
} from "@project/common"
import { Flex, Spin, Typography, notification } from "antd"
import dayjs from "dayjs"
import { FieldArray, FormikProvider, useFormik } from "formik"
import _ from "lodash"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useAuthContext } from "../../../../context"
import {
  fetchPaySlipDetails,
  recalculateBaseWage,
  updatePaySlip,
} from "../../../../services"
import {
  TopInfo,
  UserInfo,
  WorkedDaysInfo,
} from "../PaylistCommon/PaylistCommon"
import { RemarksContainer, StyledFlex } from "./payslipEdit.style"
import styled, { keyframes, css } from "styled-components"
interface IProps {
  id: number
  year: string | string[]
  month: string | string[]
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const RotatingIcon = styled.img<any>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${rotate} 2s linear infinite;
      pointer-events: none;
      opacity: 0.5;
    `}
`
const WageAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const PaylistEdit: React.FC<IProps> = ({
  id,
  year,
  month,
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [totalAmount, setTotalAmount] = useState(0)
  const [basicWage, setBasicWage] = useState(0)
  const { companyId } = useAuthContext()
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(["fetch-pay-slip-edit", id, year, month], {
    queryFn: fetchPaySlipDetails,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: (data) => {
      const userWageSettings =
        data?.data?.paySlipDetail?.user_wage_settings || []
      const user_facility_wage_settings =
        data?.data?.paySlipDetail?.user_facility_wage_settings || []

      return {
        ...data?.data,
        paySlipDetail: {
          ...data?.data?.paySlipDetail,
          user_facility_wage_settings: [
            {
              id: 0,
              facility_wage_setting: {
                item_type: "allowance",
              },
              user_id: id,
              name: "Base wage",
              item_type: "allowance",
              amount: data?.data?.basicWage,
            },
            ...user_facility_wage_settings,
          ],

          user_wage_settings: [...userWageSettings],
        },
      }
    },
    onSuccess: (response) => {
      setBasicWage(response?.basicWage)
    },
  })

  const { mutate: mutatePaySlip, isLoading: isCreateLoading } = useMutation(
    updatePaySlip,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Pay Slip", "Updated Successfully"]),
        })
        router.push(`/wage-management/payslip-list/${id}`)
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )

  const { mutate: recalculateBase, isLoading: isRecalculateBase } = useMutation(
    recalculateBaseWage,
    {
      onSuccess: ({ data }) => {
        setBasicWage(data?.basic_wage)
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const { allowanceAllItems, deductionAllItems } = userWageSetting(
    response?.paySlipDetail?.user_wage_settings,
    response?.paySlipDetail?.facility_wage_settings,
    response?.paySlipDetail?.user_facility_wage_settings,
  )
  const formik = useFormik({
    initialValues: {
      payments: [],
      deduction: [],
      remarks: "",
    },
    onSubmit: (values) => {
      const filterPaymentValues = values?.payments?.length
        ? values?.payments?.map((v) => {
            delete v?.isNew
            return v
          })
        : []
      const filterDeductionValues = values?.deduction?.length
        ? values?.deduction?.map((v) => {
            delete v?.isNew
            return v
          })
        : []

      const validFilteredPaymentValues = filterPaymentValues.filter(
        (filterPaymentValue) =>
          filterPaymentValue?.name !== "Base wage" &&
          !filterPaymentValue?.facility_id,
      )
      const validFilteredDeductionValues = filterDeductionValues.filter(
        (filterPaymentValue) => !filterPaymentValue?.facility_id,
      )

      const validPaymentUserFacilityWageSettings = filterPaymentValues.filter(
        (filterPaymentValue) =>
          filterPaymentValue?.name !== "Base wage" &&
          filterPaymentValue?.facility_id > 0,
      )

      const validDeductionUserFacilityWageSettings =
        filterDeductionValues.filter(
          (filterPaymentValue) => filterPaymentValue?.facility_id > 0,
        )

      const submitValues = {
        user_wage_settings: [
          ...validFilteredPaymentValues,
          ...validFilteredDeductionValues,
        ],
        user_facility_wage_settings: [
          ...validPaymentUserFacilityWageSettings,
          ...validDeductionUserFacilityWageSettings,
        ],
        remarks: values?.remarks ? values?.remarks : "",
        id: id,
        year: year,
        basic_wage: basicWage,
        total_wage: totalAmount,
        month: month,
      }
      mutatePaySlip(submitValues)
    },
  })

  useEffect(() => {
    const payments = _.without(allowanceAllItems, undefined)?.map((d) => {
      return {
        user_id: d?.user_id || null,
        company_id: companyId,
        item_type: d?.item_type || "allowance",
        name: d?.name || "",
        amount: d?.amount || null,
        facility_id: d?.facility_id,
        facility_wage_setting_id: d?.id,
      }
    })

    // add basic wage to the
    payments.unshift({
      company_id: companyId,
      item_type: "allowance",
      name: "Base wage",
      user_id: null,
      amount: basicWage,
      facility_id: null,
      facility_wage_setting_id: null,
    })

    const deductions = _.without(deductionAllItems, undefined)?.map((d) => {
      return {
        user_id: d?.user_id || null,
        facility_id: d?.facility_id,
        company_id: companyId,
        item_type: d?.item_type || "deduction",
        name: d?.name || "",
        amount: d?.amount || null,
        facility_wage_setting_id: d?.id,
      }
    })
    formik.setFieldValue("payments", payments)
    formik.setFieldValue("deduction", deductions)
    formik.setFieldValue("remarks", response?.paySlipDetail?.remarks)
  }, [response, id, basicWage])

  useEffect(() => {
    const { totalAmount } = getTotalAmount(
      formik?.values?.payments,
      formik?.values?.deduction,
    )
    setTotalAmount(totalAmount)
  }, [formik.values?.payments, formik.values?.deduction])

  const renderPayslipInfo = ({
    type,
    caption,
  }: {
    caption: string
    type: "payments" | "deduction"
  }) => {
    return (
      <Flex className={"amount_info"} vertical gap={16}>
        <caption>{t(caption)}</caption>
        <FieldArray
          name={type}
          render={(arrayHelpers) => (
            <Flex vertical gap={16}>
              <Flex vertical gap={12}>
                {formik?.values[`${type}`]?.map((data: any, index) => (
                  <Flex
                    key={index}
                    gap={12}
                    justify={"space-between"}
                    align={"center"}
                    wrap={"wrap"}
                    className={"payslip__item"}
                  >
                    <Flex>
                      {data?.name && !data.isNew ? (
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            width: "200px",
                          }}
                        >
                          {t(data?.name)}
                        </div>
                      ) : (
                        <InputField
                          value={data?.name}
                          width={"200px"}
                          height={"40px"}
                          name={`${type}.${index}.name`}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `${type}.${index}.name`,
                              e?.target?.value,
                            )
                          }}
                          placeholder={t("Enter title")}
                        />
                      )}
                    </Flex>
                    <Flex
                      align={"center"}
                      gap={8}
                      justify={"center"}
                      style={{ width: "200px" }}
                    >
                      {data?.name === "Base wage" ? (
                        <Flex
                          flex={1}
                          align={"center"}
                          gap={4}
                          justify={"space-between"}
                        >
                          <WageAmountWrapper>
                            <Typography.Text>
                              {response?.userInfo?.wage_style ==
                              WAGE_OPTION_VALUES.PIECE ? (
                                <InputField
                                  width={"100px"}
                                  type={"number"}
                                  min={0}
                                  value={basicWage}
                                  onChange={(e) =>
                                    setBasicWage(+e.target.value)
                                  }
                                />
                              ) : basicWage ? (
                                moneyFormat(basicWage)
                              ) : (
                                "0"
                              )}
                            </Typography.Text>
                            <Typography.Text>{t("Yen")}</Typography.Text>
                          </WageAmountWrapper>
                          {!isLoading &&
                            response?.userInfo?.wage_style !==
                              WAGE_OPTION_VALUES.PIECE && (
                              <RotatingIcon
                                src={"/assets/icons/recycle.svg"}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  recalculateBase({
                                    id: id,
                                    year: year,
                                    month: month,
                                  })
                                }
                                isLoading={isRecalculateBase}
                              />
                            )}
                        </Flex>
                      ) : (
                        <>
                          <InputField
                            height={"40px"}
                            width={"100px"}
                            value={data?.amount}
                            name={`${type}.${index}.amount`}
                            onChange={(e) => {
                              const inputValue = e.target.value
                              if (inputValue === "") {
                                formik.setFieldValue(
                                  `${type}.${index}.amount`,
                                  null,
                                )
                              } else {
                                const parsedValue = parseInt(inputValue)
                                if (!isNaN(parsedValue)) {
                                  formik.setFieldValue(
                                    `${type}.${index}.amount`,
                                    parsedValue === 0 ? null : parsedValue,
                                  )
                                }
                              }
                            }}
                            placeholder={t("Enter amount")}
                          />
                          <Typography.Text
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {t("Yen")}
                          </Typography.Text>
                          <Button
                            btnText={t("Delete")}
                            type={"link"}
                            textColor={theme.colors.error}
                            style={{
                              textDecoration: "underline",
                              whiteSpace: "nowrap",
                              padding: 0,
                              visibility: data?.facility_id
                                ? "hidden"
                                : "visible",
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
              <Flex justify={"center"}>
                <Button
                  shape={"round"}
                  btnText={
                    type === "payments"
                      ? t("Add allowance")
                      : t("Add Deduction")
                  }
                  onClick={() =>
                    arrayHelpers.push({
                      user_id: response?.paySlipDetail?.user_id,
                      name: "",
                      amount: "",
                      isNew: true,
                      item_type:
                        type === "payments" ? "allowance" : "deduction",
                      company_id: companyId,
                    })
                  }
                />
              </Flex>
            </Flex>
          )}
        />
      </Flex>
    )
  }
  return (
    <Spin spinning={isLoading || isFetching}>
      <Card title={t("Pay slip")}>
        <FormikProvider value={formik}>
          <Flex component={"form"} vertical gap={20}>
            <TopInfo
              date={dayjs(year + "-" + month).format("YYYY年MM月")}
              company={response?.userInfo?.company?.company_name}
            />
            <UserInfo
              user_name={response?.userInfo?.username}
              amount={totalAmount}
            />
            <StyledFlex>
              {renderPayslipInfo({
                caption: "Payment",
                type: "payments",
              })}
              {renderPayslipInfo({
                caption: "Deduction (e.g. tax)",
                type: "deduction",
              })}
            </StyledFlex>
            <WorkedDaysInfo
              workDays={
                <p
                  style={{
                    background: "#FFEFB8",
                    padding: "0px 10px",
                    borderRadius: "4px",
                  }}
                >{`${response?.attendanceInfo?.PresentCount || 0} 日`}</p>
              }
              absentDays={
                <p
                  style={{
                    background: "#FFC9B8",
                    padding: "0px 10px",
                    borderRadius: "4px",
                  }}
                >
                  {`${response?.attendanceInfo?.UnpaidLeaveCount || 0} 日`}
                </p>
              }
              paidLeaves={
                <p
                  style={{
                    background: "#FFEFB8",
                    padding: "0px 10px",
                    borderRadius: "4px",
                  }}
                >{`${response?.attendanceInfo?.PaidLeaveCount || 0} 日`}</p>
              }
              from_date={getDaysOfMont(year, month)?.from_date}
              to_date={getDaysOfMont(year, month)?.to_date}
            />
            <RemarksContainer>
              <Typography.Title level={5}>{t("Remarks")}</Typography.Title>
              <InputAreaField
                placeholder={t("Enter remarks")}
                rows={4}
                value={formik?.values?.remarks}
                onChange={formik.handleChange}
                name={"remarks"}
              />
            </RemarksContainer>
            <Flex gap={16} wrap={"wrap"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => router.back()}
                disabled={isCreateLoading}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                onClick={formik.handleSubmit}
                disabled={isCreateLoading}
                isLoading={isCreateLoading}
              />
            </Flex>
          </Flex>
        </FormikProvider>
      </Card>
    </Spin>
  )
}
