// packages
import { t } from "i18next"

// types
import {
  LoadUnit,
  StaffLack,
  NursingStaff,
  RetentionRate,
  WaysOfWorking,
  IllnessSpectrum,
  StaffArrangement,
  SpecificTreatment,
  TransportAddition,
  WelfareSpecialist,
  AvarageWorkingHour,
  ProductionActivity,
  ImprovementSupport,
  CompensationSystem,
  AverageMonthlyWage,
  RegionalCollaborationActivities,
  LanguageDisabilityAddition,
} from "../../services/system-status-mgmt.service"
import { RecordOption } from "./types"

export const BASIC_STAFF_ARRANGEMENT_Type_AorC: RecordOption<StaffArrangement>[] =
  [
    {
      label: "7.5  :  1",
      value: "1",
    },
    {
      label: "10  :  1",
      value: "2",
    },
  ]

export const BASIC_STAFF_ARRANGEMENT_Type_B: RecordOption<StaffArrangement>[] =
  [
    {
      label: "6  :  1",
      value: "1",
    },
    {
      label: "7.5  :  1",
      value: "2",
    },
    {
      label: "10  :  1",
      value: "3",
    },
  ]

export const RETENTION_RATE_OPTION: RecordOption<RetentionRate>[] = [
  {
    label: t("50% or more"),
    value: "1",
  },
  {
    label: t("40% or more but less than 50%"),
    value: "2",
  },
  {
    label: t("30% or more but less than 40%"),
    value: "3",
  },
  {
    label: t("20% or more but less than 30%"),
    value: "4",
  },
  {
    label: t("10% or more but less than 20%"),
    value: "5",
  },
  {
    label: t("More than 0% but less than 10%"),
    value: "6",
  },
  {
    label: t("0 %"),
    value: "7",
  },
  {
    label: t(
      "Transitional measures (Applicable to 30% or more but less than 40%)",
    ),
    value: "8",
  },
]

export const WELFARE_SPECIALIST: RecordOption<WelfareSpecialist>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("I"),
    value: "1",
  },
  {
    label: t("II"),
    value: "2",
  },
  {
    label: t("III"),
    value: "3",
  },
]

export const NURSING_STAFF_OPTION: RecordOption<NursingStaff>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("Treatment improvement addition ( I )"),
    value: "1",
  },
  {
    label: t("Treatment improvement addition ( II )"),
    value: "2",
  },
  {
    label: t("Treatment improvement addition ( III )"),
    value: "3",
  },
  {
    label: t("Treatment improvement addition ( IV )"),
    value: "4",
  },
]
export const TREATMENT_IMPROVEMENT_ADDITION_V = [
  { label: "V (1)", value: "5" },
  { label: "V (2)", value: "6" },
  { label: "V (3)", value: "7" },
  { label: "V (4)", value: "8" },
  { label: "V (5)", value: "9" },
  { label: "V (6)", value: "10" },
  { label: "V (7)", value: "11" },
  { label: "V (8)", value: "12" },
  { label: "V (9)", value: "13" },
  { label: "V (10)", value: "14" },
  { label: "V (11)", value: "15" },
  { label: "V (12)", value: "16" },
  { label: "V (13)", value: "17" },
  { label: "V (14)", value: "18" },
]

export const SPECIFIC_TREATMENT_OPTION: RecordOption<SpecificTreatment>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("Specific treatment improvement addition（ I ）"),
    value: "1",
  },
  {
    label: t("Specific treatment improvement addition ( III )"),
    value: "2",
  },
]

export const TRANSPORT_ADDITION_VALUES: { [key: string]: "0" | "1" | "2" } = {
  NO: "0",
  I: "1",
  II: "2",
}

export const TRANSPORT_ADDITION_OPTION: RecordOption<TransportAddition>[] = [
  {
    label: t("No"),
    value: TRANSPORT_ADDITION_VALUES.NO,
  },
  {
    label: t("Addition of transportation ( I )"),
    value: TRANSPORT_ADDITION_VALUES.I,
  },
  {
    label: t("Addition of transportation ( II )"),
    value: TRANSPORT_ADDITION_VALUES.II,
  },
]

export const SERVICE_PROV_STAFF_OPTION: RecordOption<StaffLack>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("Up to 2 months"),
    value: "1",
  },
  {
    label: t("After 3 months"),
    value: "2",
  },
]

export const SERVICE_MANAGER_OPTION: RecordOption<StaffLack>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("Up to 4 months"),
    value: "1",
  },
  {
    label: t("After 5 months"),
    value: "2",
  },
]

// rewardable options
export const AVERAGE_WORKING_HOUR_OPTION: RecordOption<AvarageWorkingHour>[] = [
  {
    label: t("90 points: 7 hours or more"),
    value: "1",
  },
  {
    label: t("80 points: 6 hours or more to less than 7 hours"),
    value: "2",
  },
  {
    label: t("65 points: 5 hours or more to less than 6 hours"),
    value: "3",
  },
  {
    label: t("55 points: 4 hours 30 minutes or more to less than 5 hours"),
    value: "4",
  },
  {
    label: t("40 points: 4 hours to 4 hours and 30 minutes"),
    value: "5",
  },
  {
    label: t("30 points: 3 hours or more to less than 4 hours"),
    value: "6",
  },
  {
    label: t("20 points: 2 hours or more to less than 3 hours"),
    value: "7",
  },
  {
    label: t("5 points: less than 2 hours"),
    value: "8",
  },
]

export const PRODUCTION_ACT_OPTION: RecordOption<ProductionActivity>[] = [
  {
    label: t(
      "60 points: The balance of production activities in the previous year, the year before the previous year, and the year before the year before each exceeds the total amount of wages paid to users in the relevant year.",
    ),
    value: "6",
  },
  {
    label: t(
      "50 points: The production activity balance for both the previous fiscal year and the year before last exceeds the total amount of wages paid to users in each respective year.",
    ),
    value: "1",
  },
  {
    label: t(
      "40 points: Among the previous fiscal year and the year before last, only the previous fiscal year's production activity balance exceeds the total amount of wages paid to users in that specific year.",
    ),
    value: "2",
  },
  {
    label: t(
      "20 points: Among the previous fiscal year and the year before last, only the year before last's production activity balance exceeds the total amount of wages paid to users in that specific year.",
    ),
    value: "3",
  },
  {
    label: t(
      "-10 points: The production activity balance for both the previous fiscal year and the year before last does not exceed the total amount of wages paid to users in each respective year.",
    ),
    value: "4",
  },
  {
    label: t(
      "-20 points: The balance of production activities in the previous year, the year before the previous year, and the year before the year before the previous year is less than the total amount of wages paid to users.",
    ),
    value: "5",
  },
]

export const WORKING_WAYS_OPTION: RecordOption<WaysOfWorking>[] = [
  {
    label: t("15 points: 5 or more"),
    value: "1",
  },
  {
    label: t("5 points: 3 or 4"),
    value: "2",
  },
  {
    label: t("0 points: 2 or less"),
    value: "3",
  },
]

export const IMPROVEMENT_SUPPORT_OPTION: RecordOption<ImprovementSupport>[] = [
  {
    label: t("15 points: 8 or more"),
    value: "1",
  },
  {
    label: t("5 points: 3 or 4"),
    value: "2",
  },
  {
    label: t("0 points: 2 or less"),
    value: "3",
  },
]

export const REGIONAL_ACT_OPTION: RecordOption<RegionalCollaborationActivities>[] =
  [
    {
      label: t("10 points: 1 case or more"),
      value: "1",
    },
    {
      label: t("0 points: No case"),
      value: "2",
    },
  ]

export const IMPROVEMENT_PLAN: RecordOption<"1" | "0">[] = [
  {
    label: t("0 points: Business improvement plan  submitted by deadline"),
    value: "0",
  },
  {
    label: t("-50 points: Business improvement plan not submitted by deadline"),
    value: "1",
  },
]

export const IMPROVEMENT_ABILITY: RecordOption<"1" | "0">[] = [
  {
    label: t("10 points: 1 case or more"),
    value: "1",
  },
  {
    label: t("0 points: No case"),
    value: "0",
  },
]

export const COMPENSATION_SYSTEM_OPTION: RecordOption<CompensationSystem>[] = [
  {
    label: t("Average monthly wage"),
    value: "1",
  },
  {
    label: t(
      "Uniform evaluation based on supporting users' participation in production activities, etc.",
    ),
    value: "2",
  },
]

export const AVERAGE_MONTHLY_OPTION: RecordOption<AverageMonthlyWage>[] = [
  {
    label: t("45,000 yen or more"),
    value: "1",
  },
  {
    label: t("35,000 or more but less than 45,000yen"),
    value: "2",
  },
  {
    label: t("30,000 or more but less than 35,000yen"),
    value: "3",
  },
  {
    label: t("25,000 or more but less than 30,000yen"),
    value: "4",
  },
  {
    label: t("20,000 or more but less than 25,000yen"),
    value: "5",
  },
  {
    label: t("15,000 or more but less than 20,000yen"),
    value: "6",
  },
  {
    label: t("10,000 or more but less than 15,000yen"),
    value: "7",
  },
  {
    label: t("Less than 10,000yen"),
    value: "8",
  },
  {
    label: t("Transitional measures (applicable to less than 10,000 yen)"),
    value: "9",
  },
]

export const ILLNESS_SPECTRUM_OPTION: RecordOption<IllnessSpectrum>[] = [
  {
    label: t("No"),
    value: "0",
  },
  {
    label: t("Severely ill person support addition(I)"),
    value: "1",
  },
  {
    label: t("Severely ill person support addition(II)"),
    value: "2",
  },
]

export const AVERAGE_WORKING_POINT = {
  avarage_working_hour: {
    "1": 90,
    "2": 80,
    "3": 65,
    "4": 55,
    "5": 40,
    "6": 30,
    "7": 20,
    "8": 5,
  },
  production_activity: {
    "1": 50,
    "2": 40,
    "3": 20,
    "4": -10,
    "5": -20,
    "6": 60,
  },
  ways_of_working: {
    "1": 15,
    "2": 5,
    "3": 0,
  },
  improvement_support: {
    "1": 15,
    "2": 5,
    "3": 0,
  },
  regional_collaboration_activities: {
    "1": 10,
    "2": 0,
  },
  improvement_plan: {
    "0": 0,
    "1": -50,
  },
  improvement_ability: {
    "1": 10,
    "0": 0,
  },
}

export const UNIT_OPTION: RecordOption<LoadUnit>[] = [
  {
    label: t("%"),
    value: "PERCENT",
  },
  {
    label: t("¥"),
    value: "YEN",
  },
]

export const V_AND_A_DISABILITY_OPTION: RecordOption<LanguageDisabilityAddition>[] =
  [
    {
      label: t("No"),
      value: "0",
    },
    {
      label: t(
        "Support system for people with visual and auditory language disabilities addition ( I )",
      ),
      value: "1",
    },
    {
      label: t(
        "Support system for people with visual and auditory language disabilities addition ( II )",
      ),
      value: "2",
    },
  ]

export const MOCK_EXCEEDING_ATTENDANCE = [
  {
    attendance_dates: " 2024-05-12",
    present_count: 87, // 98
  },
  {
    attendance_dates: " 2024-05-13",
    present_count: 87, // 98
  },
  {
    attendance_dates: " 2024-05-14",
    present_count: 140, // 98
  },
  {
    attendance_dates: " 2024-05-13",
    present_count: 150, // 98
  },
]

export const DEFAULT_ZERO_KEYS = [
  // addition keys
  "welfare_specialist_staff_placement_addition",
  "employement_transition_support_addition",
  "severly_ill_support_addition",
  "wage_improvement_instructor_placement_addition",
  "target_wage_achievement_instructor_addition",
  "target_wage_achievement_addition",
  "nursing_staff_treatment_improvement_addition",
  "transport_addition",
  "meal_addition",
  "support_training_completion_addition",
  "language_disability_addition",
  "brain_dysfunction_support_addition",

  // subtraction keys
  "lack_of_service_provision_staff_subtraction",
  "lack_of_service_manager_subtraction",
  "employment_support_facilities_subtraction",
  "excess_capacity_subtraction",
  "self_evaluation_unpublished_subtraction",
  "short_time_use_subtraction",
  "usage_period_subtraction",
  "abuse_prevention_measures_subtraction",
  "physical_restraint_abolition_subtraction",
  "business_plan_not_established_subtraction",
  "information_disclosure_unreported_subtraction",
]
