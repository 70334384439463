import { omit } from "@project/common"
import { PartialNullish } from "../../../services/user-transport-mgmt.services"
import { GenericDriverStaff } from "../../../types/TMDriverList.types"

const UNWANTED_KEYS_FOR_OLD_STAFF = [
  "driver_staff",
  "escort_staff",
  "car",
  "facility",
  "key",
] as const

type OldUnwantedKeys = (typeof UNWANTED_KEYS_FOR_OLD_STAFF)[number]

/**
 *
 * This function simply removed unwanted attributes from the data
 * that we don't want to send back to backend which would only increase load.
 *
 */
export const sanitizeStaffValues = (
  staffsData: PartialNullish<GenericDriverStaff>[],
): Omit<PartialNullish<GenericDriverStaff>, OldUnwantedKeys>[] => {
  const sanitizedPickupStaffs = staffsData.map((staff) => {
    // Similarly if its the old staff data then there are few attribs that we wanna avoid sending it as payload.
    // As it might cause unpredicted issues just in case.
    return omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])
  })
  return sanitizedPickupStaffs
}
