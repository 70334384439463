import {
  ActionBtns,
  Button,
  CALENDAR_HOURS_MINUTES,
  Card,
  CheckBox,
  ErrorMassage,
  FacilityCheckbox,
  Grid,
  InputField,
  SelectInput,
  allowOnlyNumber,
  generateURLString,
} from "@project/common"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ShiftStyledForm } from "./ShiftMasterForm.style"
import { useFormik } from "formik"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import {
  ShiftMasterInfoSchema,
  ShiftMasterInitialValues,
} from "../../../types/shiftMaster.types"
import { AuthContext } from "../../../context/AuthContext"
import { useRouter } from "next/router"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { Popconfirm } from "antd"
interface ShiftMasterFormProps {
  isMutating?: boolean
  onSubmit?: (values: any) => void
  defaultValues?: any
  handleDelete?: () => void
  isDeleting?: boolean
}
const ShiftMasterForm = ({
  isMutating,
  onSubmit,
  defaultValues,
  handleDelete,
  isDeleting,
}: ShiftMasterFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const router = useRouter()
  const initialValues = {
    shift_hour_name: "",
    shift_display_name: "",
    using_facility: [],
    work_time1: {
      start_time: {
        hr: "",
        min: "",
      },
      end_time: {
        hr: "",
        min: "",
      },
    },
    work_time2: {
      start_time: {
        hr: "",
        min: "",
      },
      end_time: {
        hr: "",
        min: "",
      },
    },
    work_time1_break: "",
    work_time2_break: "",
    break: "",
    break_settings: false,
    break_settings_hours: "",
    remarks: "",
    number_of_employees_include_flg: false,
  }
  const {
    values,
    handleSubmit,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik<ShiftMasterInitialValues>({
    enableReinitialize: true,
    initialValues: defaultValues || initialValues,
    validationSchema: ShiftMasterInfoSchema,
    onSubmit: (formValues) => {
      const usingFacility = formValues?.using_facility?.map((v) => +v)
      const requestData = {
        use_rest_time_flg:
          formValues?.break_settings && formValues?.break_settings ? 1 : 0,
        number_of_employees_include_flg:
          formValues?.number_of_employees_include_flg === true ? 1 : 0,
        rest_time_minutes:
          formValues?.break_settings_hours === ""
            ? 0
            : Number(formValues?.break_settings_hours),
        attendance_shift_name: formValues?.shift_hour_name,
        attendance_shift_display_name: formValues?.shift_display_name,
        attendance_start_time: `${formValues?.work_time1.start_time.hr}:${formValues?.work_time1.start_time.min}`,
        attendance_end_time: `${formValues?.work_time1.end_time.hr}:${formValues?.work_time1.end_time.min}`,
        attendance_start_time_2: `${formValues?.work_time2.start_time.hr}:${formValues?.work_time2.start_time.min}`,
        attendance_end_time_2: `${formValues?.work_time2.end_time.hr}:${formValues?.work_time2.end_time.min}`,
        attendance_rest_minits: formValues?.work_time1_break
          ? Number(formValues?.work_time1_break)
          : null,
        attendance_rest_minits_2: formValues?.work_time2_break
          ? Number(formValues?.work_time2_break)
          : null,
        remarks: formValues?.remarks,
        facilities: usingFacility,
      }
      const newValues = {
        ...requestData,
        attendance_start_time:
          requestData.attendance_start_time === ":"
            ? ""
            : requestData.attendance_start_time,
        attendance_end_time:
          requestData.attendance_end_time === ":"
            ? ""
            : requestData.attendance_end_time,
        attendance_start_time_2:
          requestData.attendance_start_time_2 === ":"
            ? ""
            : requestData.attendance_start_time_2,
        attendance_end_time_2:
          requestData.attendance_end_time_2 === ":"
            ? ""
            : requestData.attendance_end_time_2,
      }
      onSubmit(newValues)
    },
  })

  return (
    <div>
      <Card title={t("Work time information")}>
        <ShiftStyledForm onSubmit={handleSubmit}>
          <div className={"form__control"}>
            <Grid
              labelContent={t("Shift hour name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
              padding={"10px 16px"}
            >
              <InputField
                name={"shift_hour_name"}
                id={"shift_hour_name"}
                placeholder={t("例：出勤表表示名")}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.shift_hour_name}
                error={
                  touched?.shift_hour_name && errors?.shift_hour_name
                    ? t(`${errors?.shift_hour_name}`)
                    : ""
                }
                info={t("(within 50 char)")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Shift display name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
              padding={"10px 16px"}
            >
              <InputField
                name={"shift_display_name"}
                id={"shift_display_name"}
                placeholder={t("例：出勤時間名")}
                padding={"8px"}
                borderRadius={"4px"}
                subInfo={t("(Within 2 char)")}
                maxLength={2}
                subInfoMt={"0px"}
                width={"160px"}
                value={values?.shift_display_name}
                error={
                  touched?.shift_display_name && errors?.shift_display_name
                    ? t(`${errors?.shift_display_name}`)
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Using facility")}
              className={"row__header"}
              background
              required
              labelSpan={6}
              padding={"10px 16px"}
            >
              <div className={"facilities_content"}>
                <FacilityCheckbox
                  options={facilities}
                  name={"using_facility"}
                  id={"using_facility"}
                  btnHeight={"30px"}
                  value={values?.using_facility}
                  onChange={(list: CheckboxValueType[]) => {
                    setFieldValue("using_facility", list)
                  }}
                  handleCheckUncheckAll={(type) => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setFieldValue("using_facility", ids)
                    }
                    if (type === "uncheck") {
                      setFieldValue("using_facility", [])
                    }
                  }}
                />
                {touched?.using_facility && errors?.using_facility && (
                  <ErrorMassage type={"error"} message={t("Please select")} />
                )}
              </div>
            </Grid>

            <Grid
              labelContent={t("Break")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"10px 16px"}
            >
              <div className={"break__content"}>
                <CheckBox
                  name={"number_of_employees_include_flg"}
                  value={values?.number_of_employees_include_flg}
                  checked={values?.number_of_employees_include_flg}
                  label={t(
                    "Not included in the number of attendees when registering the attendance table",
                  )}
                  onChange={(e: CheckboxChangeEvent) => {
                    setFieldValue(
                      "number_of_employees_include_flg",
                      e.target.checked,
                    )
                  }}
                />
                <ErrorMassage
                  type={"error"}
                  message={t(
                    "※Check this box when registering shift holidays and paid holidays.",
                  )}
                />
              </div>
            </Grid>
            <Grid
              labelContent={t("Break settings")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"10px 16px"}
            >
              <div className={"break-settings__content"}>
                <div className={"top__content"}>
                  <CheckBox
                    name={"break_settings"}
                    value={values?.break_settings}
                    checked={values?.break_settings}
                    label={t("Set breaks")}
                    onChange={(e: CheckboxChangeEvent) => {
                      if (
                        values.break_settings &&
                        values.break_settings_hours !== ""
                      ) {
                        setFieldValue("break_settings_hours", "")
                      }
                      setFieldValue("break_settings", e.target.checked)
                    }}
                  />
                  <InputField
                    name={"break_settings_hours"}
                    id={"break_settings_hours"}
                    subInfo={t("Hrs")}
                    width={"120px"}
                    subInfoMt={"0px"}
                    height={"40px"}
                    disabled={!values?.break_settings}
                    value={values?.break_settings_hours}
                    onChange={handleChange}
                    maxLength={2}
                    error={errors?.break_settings_hours}
                    customError={true}
                  />
                </div>
                {errors?.break_settings_hours && (
                  <ErrorMassage
                    type={"error"}
                    message={t("Required")}
                    mt={"0px"}
                  />
                )}
                <ErrorMassage
                  type={"error"}
                  message={t(
                    "※Please set if you want to include break time such as half-holidays in the number of hours that a full-time employee should work.",
                  )}
                />
              </div>
            </Grid>
            {!values.number_of_employees_include_flg ? (
              <>
                <Grid
                  labelContent={t("Work time 1")}
                  className={"row__header"}
                  background
                  required
                  labelSpan={6}
                  padding={"10px 16px"}
                >
                  <div className={"work_time__content"}>
                    <div className={"work_time__hours"}>
                      <div className={"work_time__hours--from"}>
                        <SelectInput
                          name={"work_time1_from_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values?.work_time1?.start_time?.hr}
                          onChange={(value) => {
                            setFieldValue("work_time1", {
                              ...values.work_time1,
                              start_time: {
                                hr: value,
                                min:
                                  !values.work_time1.start_time.min ||
                                  values.work_time1.start_time.hr === ""
                                    ? "00"
                                    : values.work_time1.start_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time1_from_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values?.work_time1?.start_time?.min}
                          onChange={(value) => {
                            setFieldValue("work_time1", {
                              ...values.work_time1,
                              start_time: {
                                hr: values.work_time1.start_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values.work_time1.start_time.hr === ""}
                        />
                      </div>
                      <span className={"time-separator"}>{"~"}</span>
                      <div className={"work_time__hours--to"}>
                        <SelectInput
                          name={"work_time1_to_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values.work_time1.end_time.hr}
                          onChange={(value) => {
                            setFieldValue("work_time1", {
                              ...values.work_time1,
                              end_time: {
                                hr: value,
                                min:
                                  !values.work_time1.end_time.min ||
                                  values.work_time1.end_time.hr === "--"
                                    ? "00"
                                    : values.work_time1.end_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time1_to_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values.work_time1.end_time.min}
                          onChange={(value) => {
                            setFieldValue("work_time1", {
                              ...values.work_time1,
                              end_time: {
                                hr: values.work_time1.end_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values.work_time1.end_time.hr === ""}
                        />
                      </div>
                    </div>
                    <div className={"work_time__break"}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("(break time :")}
                      </span>
                      <InputField
                        height={"40px"}
                        width={"60px"}
                        maxLength={3}
                        name={"work_time1_break"}
                        value={values.work_time1_break}
                        onChange={({ target: { value } }) => {
                          setFieldValue(
                            "work_time1_break",
                            allowOnlyNumber(value),
                          )
                        }}
                      />
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("mins(max 3 numbers)")}
                      </span>
                    </div>
                  </div>
                  {typeof errors?.work_time1 === "string" ||
                  errors?.work_time1?.start_time?.hr?.toString() ||
                  errors?.work_time1?.end_time?.hr.toString() ||
                  errors?.work_time1_break ? (
                    <ErrorMassage type={"error"} message={t("Please select")} />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  labelContent={t("Work time 2")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"10px 16px"}
                >
                  <div className={"work_time__content"}>
                    <div className={"work_time__hours"}>
                      <div className={"work_time__hours--from"}>
                        <SelectInput
                          name={"work_time1_from_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values.work_time2.start_time.hr}
                          onChange={(value) => {
                            setFieldValue("work_time2", {
                              ...values.work_time2,
                              start_time: {
                                hr: value,
                                min:
                                  !values.work_time2.start_time.min ||
                                  values.work_time2.start_time.hr === ""
                                    ? "00"
                                    : values.work_time2.start_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time1_from_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values.work_time2.start_time.min}
                          onChange={(value) => {
                            setFieldValue("work_time2", {
                              ...values.work_time2,
                              start_time: {
                                hr: values.work_time2.start_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values.work_time2.start_time.hr === ""}
                        />
                      </div>
                      <span className={"time-seperator"}>{"~"}</span>
                      <div className={"work_time__hours--to"}>
                        <SelectInput
                          name={"work_time2_to_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values.work_time2.end_time.hr}
                          onChange={(value) => {
                            setFieldValue("work_time2", {
                              ...values.work_time2,
                              end_time: {
                                hr: value,
                                min:
                                  !values.work_time2.end_time.min ||
                                  values.work_time2.end_time.hr === ""
                                    ? "00"
                                    : values.work_time2.end_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time2_to_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values.work_time2.end_time.min}
                          onChange={(value) => {
                            setFieldValue("work_time2", {
                              ...values.work_time2,
                              end_time: {
                                hr: values.work_time2.end_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values.work_time2.end_time.hr === ""}
                        />
                      </div>
                    </div>
                    <div className={"work_time__break"}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("(break time :")}
                      </span>
                      <InputField
                        height={"40px"}
                        width={"60px"}
                        maxLength={3}
                        name={"work_time2_break"}
                        value={values.work_time2_break}
                        onChange={({ target: { value } }) => {
                          setFieldValue(
                            "work_time2_break",
                            allowOnlyNumber(value),
                          )
                        }}
                      />
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("mins(max 3 numbers)")}
                      </span>
                    </div>
                  </div>
                  {typeof errors?.work_time2 === "string" && (
                    <ErrorMassage type={"error"} message={t("Please select")} />
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid
              labelContent={t("Remarks")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"10px 16px"}
            >
              <InputField
                name={"remarks"}
                id={"remarks"}
                placeholder={t("例：9:00〜17:00")}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.remarks}
                maxLength={20}
                error={
                  touched?.remarks && errors?.remarks
                    ? t(`${errors?.remarks}`)
                    : ""
                }
                info={t("(within 20 char)")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </div>

          <div className={"delete_action_container"}>
            <ActionBtns justify={"flex-start"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => {
                  const { facilityIds, page } = router?.query as any
                  const url = generateURLString("/shift-master", {
                    facilityIds,
                    page,
                  })
                  router.push(url)
                }}
                disabled={isMutating}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isMutating}
              />
            </ActionBtns>
            {+defaultValues?.id > 0 && (
              <div className={"joint-facility-button-wrapper"}>
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  onConfirm={handleDelete}
                  okText={t("OK")}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                >
                  <Button
                    btnText={t("Delete")}
                    shape={"round"}
                    className={"delete-btn"}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              </div>
            )}
          </div>
        </ShiftStyledForm>
      </Card>
    </div>
  )
}

export { ShiftMasterForm }
