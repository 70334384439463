import { theme } from "@project/common"
import { styled } from "styled-components"

export const ServiceProvOperationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  /* inputs */
  .operation_inputs {
    flex: 1;

    &.SP_user-inputs {
      @media (max-width: ${theme.breakpoints.md}) {
        gap: 10px !important;
      }
    }

    .SP_keyword-input {
      min-width: 150px;
      max-width: 479px;
    }
    .facility-action-btn-group {
      margin-bottom: 16px;
    }
  }
`
