import { Button, CheckBox, Table, theme } from "@project/common"
import { Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  FULL_DATE_FORMATE,
  FULL_DATE_FORMAT_EN,
  PAGE_SIZE,
} from "../../../constants"

export const ActualCostBurdenRecordListContent = ({
  params,
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  handlePagination,
  isLoading,
}: {
  params: any
  dataSource: any
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  handlePagination?: (value: number) => void
  isLoading?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    columnTitle: <span style={{ whiteSpace: "nowrap" }}>{t("Delete")}</span>,
    columnWidth: 90,
    renderCell: (_, row) => {
      return (
        <CheckBox
          checked={selectedRowKeys?.includes(row?.id)}
          onChange={() => {
            const item = selectedRowKeys?.find((v) => v === row?.id)
            if (item) {
              const newItems = selectedRowKeys?.filter((v) => v !== row?.id)
              setSelectedRowKeys(newItems)
            } else {
              setSelectedRowKeys([...selectedRowKeys, row?.id])
            }
          }}
        />
      )
    },
  }
  const columns: ColumnsType<any[]> = [
    {
      key: 1,
      align: "center",
      title: t("Detail"),
      width: 100,
      render: (row) => (
        <Button
          btnText={t("Details")}
          type={"link"}
          onClick={() =>
            router?.push(
              `/actual-cost-burden-record/list/details/${row?.id}?facility=${params?.facility}&date=${dayjs(
                params?.start_date,
              ).format(FULL_DATE_FORMAT_EN)}`,
            )
          }
          style={{
            textDecoration: "underline",
            color: theme.colors.action,
            whiteSpace: "nowrap",
          }}
        />
      ),
    },
    {
      key: 2,
      align: "center",
      title: t("Actual cost item name"),
      width: 400,
      render: (row) =>
        row?.actual_cost_detail || row?.actual_cost?.actual_cost_name || "",
    },
    {
      key: 3,
      align: "center",
      title: t("No. of users"),
      width: 400,
      render: (row) => row?.user_count,
    },
    {
      key: 4,
      align: "center",
      title: t("Last update"),
      width: 400,
      render: (row) => (
        <>
          <div>
            <Typography.Text>
              {dayjs(row?.updated_datetime).format(
                `${FULL_DATE_FORMATE} HH:mm:ss`,
              )}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>{row?.company_name || ""}</Typography.Text>
          </div>
        </>
      ),
    },
  ]
  return (
    <Table
      columns={columns}
      headerBorderRadius={0}
      dataSource={
        dataSource?.data?.map((v, index) => ({
          key: v?.id || index,
          ...v,
        })) || []
      }
      rowSelection={rowSelection}
      showPaginationOf={"both"}
      total={dataSource?.count || 0}
      pageSize={PAGE_SIZE}
      onChange={(val) => handlePagination(val)}
      scroll={{ x: 1000 }}
      loading={isLoading}
    />
  )
}
