import {
  AccordionCard,
  Button,
  Card,
  DateCardList,
  DatePicker,
  InputField,
  LabelTextWithLeftArrow,
  LinkButton,
  OperationOptionContainer,
  PageTitle,
  SelectInput,
  Table,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import React, { useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import { useRouter } from "next/router"
import { AuthContext } from "../../context/AuthContext"
import { useQuery } from "react-query"
import { fetchListOfMunicipalSubsidy } from "../../services/ListOfMunicipalSubsidy"
import {
  ButtonWrapper,
  ExtraButton,
  ListOfMunicipalSubsidyContent,
  SelectFieldWrapper,
} from "./ListOfMunicipalSubsidy.style"
import {
  MunicipalSubsidiaryPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"
import dayjs, { Dayjs } from "dayjs"
import { getConfirmedBillingFacilities } from "../../services/NHIFbilling"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

const ListOfMunicipalSubsidy = (): JSX.Element => {
  const { t } = useTranslation()
  const today = new Date()
  const router = useRouter()
  const listContent = useRef<any>(null)
  const { page: currentPage } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1,
  )
  const [activeBtn, setActiveBtn] = useState("current")
  const { facilities, isOwner, permissions } = useContext(AuthContext)
  const [facility, setFacility] = useState(null)
  const [searchEnabled, setSearchEnabled] = useState(true)
  const [currentYear, setCurrentYear] = useState(
    router.query?.year ? +router.query?.year : today.getFullYear(),
  )
  const [currentMonth, setCurrentMonth] = useState(
    router.query?.month ? +router.query?.month : today.getMonth() + 1,
  )
  const [searchParams, setSearchParams] = useState<any>({
    year: currentYear,
    month: currentMonth,
    facility_id: "",
    user: "",
  })

  const FACILITY_OPTIONS = facilities?.map((item) => {
    return {
      value: item?.id,
      label: item?.facility_name,
    }
  })

  const { data: facilitiesWithConfirmedBilling } = useQuery(
    ["get-facilities-with-confirmed-billing", searchParams],
    () => {
      const year = searchParams?.year
      const month = searchParams?.month
      const facilityIds = searchParams?.facility_id || ""
      return getConfirmedBillingFacilities({
        year,
        month,
        facilityIds: facilityIds,
      })
    },
    {
      retry: 1,
      keepPreviousData: false,
      select: ({ data }) => {
        const value = `${searchParams?.year}年${searchParams?.month
          ?.toString()
          ?.padStart(2, "0")}月`
        return data?.map((facility) => {
          return {
            key: facility?.facility_name,
            value,
          }
        })
      },
    },
  )

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>(
    ["list-of-municipal-subsidy"],
    () =>
      fetchListOfMunicipalSubsidy({
        queryKey: [
          "fetch-list-municipal-subsidy",
          page,
          pageSize,
          searchParams,
        ],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: searchEnabled,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return data
          }),
          count: response?.count,
        }
      },
      onSuccess: () => {
        setSearchEnabled(false)
      },
      onError: () => {
        setSearchEnabled(false)
      },
    },
  )

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setPage(1)
    updateParams(
      queries,
      "/list-of-municipal-subsidy-claims-information-management",
    )
    scrollToSelectedElement(listContent)
  }

  const onSearch = () => {
    const values = {
      year: currentYear,
      month: currentMonth,
      facility_id: facility,
      user: searchParams?.user,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(params)
    handleSearchAndResetParams(params)
    setSearchEnabled(true)
  }
  const resetHandler = () => {
    setFacility(null)
    setSearchEnabled(true)
    setSearchParams({
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      facility_id: null,
      user: null,
    })
    setCurrentYear(today.getFullYear())
    setCurrentMonth(today.getMonth() + 1)
    setPage(1)
  }

  const columns: ColumnsType<any> = [
    {
      title: t("Edit1"),
      dataIndex: "Edit",
      key: "Edit",
      align: "center",
      width: 170,
      render: (_, row) => {
        const hasEditPermission =
          isOwner ||
          hasPermissionForFacility(
            permissions,
            MunicipalSubsidiaryPermission,
            "write",
            row?.facility_id,
          )

        return (
          <LinkButton
            disabled={!hasEditPermission}
            onClick={() =>
              router.push(
                `/list-of-municipal-subsidy-claims-information-management/edit/${row?.id}`,
              )
            }
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Facility5"),
      dataIndex: "facility_id",
      key: "",
      width: 210,
      align: "center",
      render: (_, val) => {
        return (
          <div className={"facility_name"}>
            {
              FACILITY_OPTIONS.find(
                (facility) => facility.value === val.facility_id,
              )?.label
            }
          </div>
        )
      },
    },
    {
      title: t("Year month"),
      align: "center",
      dataIndex: "year_month",
      key: "",
      width: 210,
      render: (_, val: any) => {
        const dateString = `${val?.year}-${val?.month
          .toString()
          .padStart(2, "0")}-01`
        return (
          <div>
            <span>{dayjs(dateString).format("YYYY年MM月")}</span>
          </div>
        )
      },
    },
    {
      title: t("User2"),
      align: "center",
      dataIndex: "username",
      key: "",
      width: 310,
      sorter: (a, b) => {
        return a?.user?.username.length - b?.user?.username.length
      },
      render: (_, data) => {
        return <p>{data?.user?.username}</p>
      },
    },
    {
      title: t("Municipal subsidy invoice amount "),
      align: "center",
      dataIndex: "municipality_billing_amount",
      key: "",
      width: 230,
      render: (_, val) => {
        const formattedAmount =
          val.municipality_billing_amount?.toLocaleString()
        return (
          <div>
            {formattedAmount}
            {" 円"}
          </div>
        )
      },
    },
    {
      title: t("Last update"),
      align: "center",
      dataIndex: "updated_datetime",
      key: "",
      width: 310,
      render: (value, row) => {
        return (
          <div>
            <p style={{ marginBottom: 0 }}>
              {value && dayjs(value).isValid()
                ? dayjs(value).format("YYYY年MM月DD日 HH:mm")
                : ""}
            </p>
            <p>{row?.company?.company_name}</p>
          </div>
        )
      },
    },
  ]

  const handleMonthChange = (year: number, month: number) => {
    setCurrentYear(year)
    setCurrentMonth(month)
    setSearchParams({
      ...searchParams,
      year: year,
      month: month,
    })
  }

  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    setSearchEnabled(true)
    router.push(
      {
        pathname: "/list-of-municipal-subsidy-claims-information-management",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, MunicipalSubsidiaryPermission, "write"),
    [permissions, isOwner],
  )

  return (
    <>
      <PageTitle
        title={t("List of Municipal Subsidy Claims Information Management")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        extra={
          <ExtraButton>
            <Button
              btnText={t("Add new data")}
              type={"primary"}
              shape={"round"}
              onClick={() =>
                router.push(
                  `/list-of-municipal-subsidy-claims-information-management/add?year=${currentYear}&month=${currentMonth}`,
                )
              }
              disabled={!hasWriteAccess}
            />
          </ExtraButton>
        }
      />
      <div>
        {facilitiesWithConfirmedBilling?.length ? (
          <DateCardList
            icon={"/assets/icons/NotificationAlert.svg"}
            title={t("請求情報が確定された事業所が存在します")}
            content={t(
              "編集や登録・削除を行う際は、請求担当者に確認しつつ行ってください",
            )}
            subContent={t("確定した請求情報と差異が発生する可能性があります")}
            expandTitle={t("請求情報が確定された事業所を表示")}
            expandText={t("---")}
            list={facilitiesWithConfirmedBilling}
          />
        ) : (
          <></>
        )}
      </div>
      <ListOfMunicipalSubsidyContent>
        <AccordionCard
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <OperationOptionContainer>
                  <div className={"field__content flex-start"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Facility5")}
                        width={"138px"}
                      />
                    </div>
                    <div className={"first-select-content"}>
                      <SelectInput
                        width={"282px"}
                        name={"facility"}
                        options={facilities}
                        placeholder={"--"}
                        value={facility}
                        onChange={(val) => setFacility(val)}
                      />
                    </div>
                  </div>
                  <div className={"search__content fiscal_year"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("Year month")}
                        width={"138px"}
                      />
                    </div>
                    <SelectFieldWrapper>
                      <DatePicker
                        picker={"month"}
                        date={dayjs([+currentYear, +currentMonth - 1])}
                        onDateChange={(date: Dayjs) => {
                          setCurrentYear(dayjs(date).year())
                          setCurrentMonth(+dayjs(date).month() + 1)
                        }}
                        format={"YYYY年MM月"}
                      />
                    </SelectFieldWrapper>
                  </div>
                  <div className={"field__content flex-start"}>
                    <div className={"mt"}>
                      <LabelTextWithLeftArrow
                        label={t("User2")}
                        width={"138px"}
                      />
                    </div>
                    <div className={"first-select-content"}>
                      <InputField
                        name={"user"}
                        className={"input__content"}
                        padding={"8px"}
                        borderRadius={"4px"}
                        width={"479px"}
                        placeholder={"名前"}
                        value={searchParams?.user}
                        onChange={(e) => {
                          setSearchParams({
                            ...searchParams,
                            user: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className={"search__content buttons"}>
                    <Button
                      btnText={t("Reset Search")}
                      shape={"round"}
                      className={"button_accordion"}
                      onClick={resetHandler}
                    />
                    <Button
                      btnText={t("Search")}
                      type={"primary"}
                      size={"large"}
                      shape={"round"}
                      iconType={"search"}
                      onClick={onSearch}
                    />
                  </div>
                </OperationOptionContainer>
              ),
            },
          ]}
        />
        <Card
          title={t(
            "{{YYYY}}年{{MM}}月’s Municipality Subsidy Billing Amount Information List",
            {
              YYYY: currentYear,
              MM: `${currentMonth}`.padStart(2, "0"),
            },
          )}
          extra={
            <ButtonWrapper>
              <Button
                btnText={t("前月")}
                shape={"default"}
                borderColor={"none"}
                btnBg={
                  activeBtn == "previous"
                    ? theme.colors.activeBtn
                    : theme.colors.white
                }
                onClick={async () => {
                  setSearchEnabled(true)
                  setActiveBtn("previous")
                  if (currentMonth == 1) {
                    handleMonthChange(currentYear - 1, 12)
                  } else {
                    handleMonthChange(currentYear, currentMonth - 1)
                  }
                }}
                iconType={"backward-arrow"}
                className={"button_accordion"}
              />
              <Button
                btnText={t("今月")}
                shape={"default"}
                borderColor={"none"}
                btnBg={
                  activeBtn == "current"
                    ? theme.colors.activeBtn
                    : theme.colors.white
                }
                onClick={() => {
                  setActiveBtn("current")
                  setSearchEnabled(true)
                  handleMonthChange(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                  )
                }}
                className={"button_accordion"}
              />
              <Button
                btnText={t("翌月")}
                shape={"default"}
                borderColor={"none"}
                btnBg={
                  activeBtn == "next"
                    ? theme.colors.activeBtn
                    : theme.colors.white
                }
                iconType={"frontward-arrow"}
                onClick={async () => {
                  setActiveBtn("next")
                  setSearchEnabled(true)
                  if (currentMonth == 12) {
                    handleMonthChange(currentYear + 1, 1)
                  } else {
                    handleMonthChange(currentYear, +currentMonth + 1)
                  }
                }}
              />
            </ButtonWrapper>
          }
        >
          <div ref={listContent}>
            <Table
              current={page}
              columns={columns}
              pageSize={pageSize}
              total={response?.count || 0}
              dataSource={response?.data}
              showPaginationOf={"both"}
              pagination={false}
              scroll={{ x: 1200 }}
              onChange={handlePaginationChange}
              cellPaddingBlock={12}
              loading={isFetching || isLoading}
            />
          </div>
        </Card>
      </ListOfMunicipalSubsidyContent>
    </>
  )
}

export { ListOfMunicipalSubsidy }
