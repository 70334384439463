import { createId } from "@paralleldrive/cuid2"
import { AttendanceBulkEditOperation } from "../../../types/AttendanceBulkEdit.types"

export const ATTENDANCE_BULK_EDIT_OPERATION_KEYS = [
  "facility_id",
  "date",
] as (keyof AttendanceBulkEditOperation)[]

const uniqueKey = createId()
const uniqueKey2 = createId()
const uniqueKey3 = createId()
export const ATTENDANCE_TABLE_MOCK = {
  individualRecord: {
    [uniqueKey]: {
      key: uniqueKey,
      id: "1",
      day: "1",
      staff_name: "John Doe",
      working_hours: {
        work_info_1: {
          isAbsent: false,
          time_range: {
            start: {
              hour: "08",
              minute: "00",
            },
            end: {
              hour: "17",
              minute: "00",
            },
          },
          break_time: "60",
        },
      },
      vacation_time: {
        vacation_flag: false,
        vacation_time: "0",
      },
      remarks: "Lorem ipsum dolor sit amet",
    },

    [uniqueKey2]: {
      key: uniqueKey2,
      id: "2",
      day: "2",
      staff_name: "John Doe",
      working_hours: {},
      vacation_time: {
        vacation_flag: false,
        vacation_time: "0",
      },
      remarks: "Lorem ipsum dolor sit amet",
    },

    [uniqueKey3]: {
      key: uniqueKey3,
      id: "3",
      day: "3",
      staff_name: "John Doe",
      working_hours: {},
      vacation_time: {
        vacation_flag: false,
        vacation_time: "0",
      },
      remarks: "Lorem ipsum dolor sit amet",
    },
  },
}
