import { theme } from "@project/common"
import styled, { css } from "styled-components"

const COMMON = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 5px;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.border};
  }
  span {
    display: flex;
    column-gap: 1px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    color: ${theme.colors.text};
    font-family: "Noto Sans JP", serif;
  }
`
export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .title {
    border-bottom: 1px solid ${theme.colors.border};
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 100%;
    b {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${theme.colors["sub-text"]};
    }
    @media (max-width: ${theme.breakpoints?.md}) {
      word-break: break-all;
    }
  }
  .weekly-table-container {
    overflow-x: auto;
    white-space: nowrap;
    table {
      th.extra_col {
        min-width: fit-content !important;
        max-width: fit-content !important;
        padding: 0 !important;
      }

      .date-column {
        min-width: 50px !important;
        height: 120px !important;
        max-height: 100px !important;
        .time,
        .time-range {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          background: ${theme.colors.disabled};
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .time-range {
          font-size: 10px;
        }
      }
      th.date-column {
        height: fit-content !important;
      }

      .ant-table-thead > tr > th.extra_col {
        background-color: transparent !important;
        padding: 0px !important;
        visibility: hidden !important;
        border-bottom: unset !important;
      }
      .ant-table-tbody > tr > td.extra_col {
        visibility: hidden !important;
        border-top: unset !important;
        border-bottom: unset !important;
        border-right: unset !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none !important;
      }
      .ant-table-tbody > tr:hover > td.date-column {
        background: ${theme.colors.disabled} !important;
      }
      .time-content {
        ${COMMON}
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
          background: ${theme.colors.border};
        }
      }
      .has-time-content {
        ${COMMON};
        column-gap: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .empty-cell-row {
        min-width: 2px !important;
      }
      @media (max-width: ${theme.breakpoints?.md}) {
        .date-column {
          min-width: 70px !important;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    gap: 10px;
    div:nth-child(2) {
      margin: 10px 0;
    }
  }
`
const STYLES = css`
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 16px;

  white-space: break-spaces;
  .input-container-bulk {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 16px;
    .input-content {
      ${STYLES}
    }
  }
  .input-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    .input-content {
      ${STYLES}
    }
  }
  .days-container {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;

    align-items: center;
    flex-wrap: wrap;
    .days {
      display: flex;
      justify-content: space-between;
      flex: auto;
      position: relative;

      .error_days:empty {
        display: none;
      }

      .error_days:not(:empty) {
        display: block;
        position: absolute;
        top: 30px;
      }
    }
  }
  .area-container {
    display: flex;
    row-gap: 14px;
    column-gap: 14px;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  }
  .cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 15px;
  }
`
