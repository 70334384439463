import {
  AccordionCard,
  ActionBtns,
  Button,
  CheckBoxGroup,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  removeBlankAttributes,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../context/AuthContext"
import { MonitoringSearchParams } from "../../../types"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs from "dayjs"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import { filterWithHiragana } from "../../../utils"

const MonitoringSearch = ({
  handleSearch,
  queryParam,
}: {
  handleSearch: (values: MonitoringSearchParams) => void
  queryParam?: any
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [userHiragana, setUserHiragana] = useState(null)
  const [updateParams] = useUpdateSearchParams()

  const [params, setParams] = useState<MonitoringSearchParams>({
    user_id: queryParam?.user_id || "",
    facility_ids: queryParam?.facility_ids || [],
    period_start_date: queryParam?.period_start_date || null,
    period_end_date: queryParam?.period_end_date || null,
    display_setting: queryParam?.display_setting || [],
    draft_save_flg: queryParam?.draft_save_flg || [],
  })
  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  return (
    <AccordionCard
      accordion={true}
      defaultActiveKey={["1"]}
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User name")}
                  width={"138px"}
                />
                <div className={"flex"}>
                  <SelectInput
                    width={"85px"}
                    placeholder={"--"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    name={"user_hiragana"}
                    value={userHiragana}
                    onChange={(val) => {
                      setUserHiragana(val)
                      setParams({
                        ...params,
                        user_id: null,
                      })
                    }}
                  />

                  <SelectInput
                    name={"user_id"}
                    width={"282px"}
                    options={
                      filterWithHiragana({
                        options: userData,
                        furigana: userHiragana || "",
                      }) || []
                    }
                    placeholder={"--"}
                    value={params?.user_id || null}
                    onChange={(val) => {
                      setParams({
                        ...params,
                        user_id: val,
                      })
                    }}
                    showMessage={false}
                  />
                </div>
              </div>

              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility name")}
                    width={"138px"}
                  />
                </div>
                <FacilityCheckbox
                  options={facilities}
                  value={params?.facility_ids}
                  btnHeight={"30px"}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setParams({
                        ...params,
                        facility_ids: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setParams({
                        ...params,
                        facility_ids: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setParams({
                      ...params,
                      facility_ids: list,
                    })
                  }}
                />
              </div>

              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Period")} width={"138px"} />
                <div className={"search__content-date"}>
                  <DatePicker
                    date={
                      dayjs(params?.period_start_date).isValid()
                        ? dayjs(params?.period_start_date)
                        : null
                    }
                    onDateChange={(date) => {
                      setParams({
                        ...params,
                        period_start_date: dayjs(date).format("YYYY-MM-DD"),
                      })
                    }}
                    format={"YYYY年MM月DD日"}
                  />
                  {"~"}
                  <DatePicker
                    date={
                      dayjs(params?.period_end_date).isValid()
                        ? dayjs(params?.period_end_date)
                        : null
                    }
                    onDateChange={(date) => {
                      setParams({
                        ...params,
                        period_end_date: dayjs(date).format("YYYY-MM-DD"),
                      })
                    }}
                    format={"YYYY年MM月DD日"}
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Display Setting")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={[
                    {
                      value: "2",
                      label: t("Display only digital signature blank"),
                    },
                  ]}
                  value={params?.display_setting}
                  onChange={(list: CheckboxValueType[]) => {
                    setParams({
                      ...params,
                      display_setting: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Create Status")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={[
                    {
                      value: "0",
                      label: t("Created2"),
                    },
                    {
                      value: "1",
                      label: t("Draft"),
                    },
                  ]}
                  value={params?.draft_save_flg}
                  onChange={(list: CheckboxValueType[]) => {
                    setParams({
                      ...params,
                      draft_save_flg: list,
                    })
                  }}
                />
              </div>
              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Reset search")}
                  size={"large"}
                  shape={"round"}
                  onClick={() => {
                    setUserHiragana("")
                    const values = {
                      user_id: null,
                      facility_ids: "",
                      period_start_date: null,
                      period_end_date: null,
                      display_setting: "",
                      draft_save_flg: "",
                    }
                    setParams(values)
                    updateParams(
                      removeBlankAttributes(values),
                      "/monitoring-list",
                    )
                    handleSearch(values)
                  }}
                />
                <Button
                  btnText={t("Search")}
                  type={"primary"}
                  size={"large"}
                  shape={"round"}
                  iconType={"search"}
                  onClick={() =>
                    handleSearch({
                      ...params,
                      facility_ids: params.facility_ids?.toString() || "",
                      display_setting: params.display_setting?.toString() || "",
                      draft_save_flg: params.draft_save_flg?.toString() || "",
                      period_start_date: dayjs(
                        params?.period_start_date,
                      ).isValid()
                        ? dayjs(params?.period_start_date).format("YYYY-MM-DD")
                        : "",
                      period_end_date: dayjs(params?.period_end_date).isValid()
                        ? dayjs(params?.period_end_date).format("YYYY-MM-DD")
                        : "",
                    })
                  }
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}

export { MonitoringSearch }
