import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  GetDecideServiceCodeByServiceType,
  LabelTextWithLeftArrow,
  Modal,
  OperationSearchContent,
  Table,
  useUpdateSearchParams,
} from "@project/common"
import { Typography } from "antd"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NHIF_USER_BILLING_SUMMARY_LIST_OPTIONS } from "../../../../constants"
import { useAuthContext } from "../../../../context"

export const SummaryListContentOpOption = ({
  scrollTo,
}: {
  scrollTo: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useAuthContext()
  const { display_content, facilityIds } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const qdisplay_content = display_content?.split(",") as any
  const [displayContent, setDisplayContent] = useState(qdisplay_content || [])
  const [open, setOpen] = useState(false)
  const decisionServiceCodes = GetDecideServiceCodeByServiceType(
    facilities?.find((facility) => facility?.id == +facilityIds)?.facility_type,
  )

  const displayedOptions = !displayContent?.includes("2")
    ? NHIF_USER_BILLING_SUMMARY_LIST_OPTIONS?.filter((v) => v?.value !== "3")
    : NHIF_USER_BILLING_SUMMARY_LIST_OPTIONS

  return (
    <>
      <AccordionCard
        defaultActiveKey={["summary-list"]}
        items={[
          {
            key: "summary-list",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Display contents")}
                    width={"136px"}
                  />
                  <CheckBoxGroup
                    items={displayedOptions}
                    value={displayContent || null}
                    onChange={(val: any) => setDisplayContent(val)}
                  />
                </div>
                <div className={"search__content"}>
                  <div style={{ width: "136px" }} />
                  <Button
                    btnText={t("> Check the list of service codes")}
                    shape={"round"}
                    type={"outlined"}
                    onClick={() => setOpen(true)}
                  />
                </div>
                <div className={"search__content"}>
                  <Button
                    btnText={t("Reset Search")}
                    shape={"round"}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                      setDisplayContent([])
                      updateParams(
                        {
                          ...router.query,
                          display_content: "",
                        },
                        router?.pathname,
                      )
                      scrollTo()
                    }}
                  />
                  <Button
                    btnText={t("Search")}
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    onClick={() => {
                      updateParams(
                        {
                          ...router.query,
                          display_content: displayContent?.join(","),
                        },
                        router?.pathname,
                      )
                      scrollTo()
                    }}
                  />
                  <Typography.Text>
                    {t(
                      "※To change the facility or year and month, press ''Cancel'' and go to the previous screen.",
                    )}
                  </Typography.Text>
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
      {open && (
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          width={"800px"}
          bodyPadding={"8px 8px 16px 8px"}
          borderRadius={"12px"}
          title={t("Decide Service Code List")}
        >
          <Table
            columns={[
              {
                title: t("Decide code name"),
                dataIndex: "decide_service_code_name",
              },
              {
                title: t("Decide code"),
                dataIndex: "decide_service_code",
              },
            ]}
            dataSource={decisionServiceCodes || []}
          />
        </Modal>
      )}
    </>
  )
}
