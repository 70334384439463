import React, { useMemo, useState } from "react"
import { StaffMonthlyAttendanceScheduleOperation } from "./StaffMonthlyAttendanceRecordOperation"
import { PageHead } from "../../../components"
import {
  Breadcrumb,
  Card,
  MonthPagination,
  PageTitle,
  STAFF_ABSENT,
  useUpdateSearchParams,
} from "@project/common"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { Skeleton, Space } from "antd"
import { MONTH_DATE_FORMAT } from "../../../constants"
import {
  NoFilterContainer,
  StaffMonthlyAttendanceRecordStyleWrapper,
} from "./StaffMonthlyAttendanceRecord.style"

import { StaffMonthlyAttendanceRecordContent } from "./StaffMonthlyAttendanceRecordContent"
import { STAFF_CHANGE_VALUES } from "../../../types/StaffAttendance.types"
import { useQuery } from "react-query"
import { getMonthlyAttendanceStaffResult } from "../../../services"
import { useFetchOneStaff } from "../../../hooks/useFetchData"
import { deSerializeAttendanceRecordPayload } from "../../../serialize"
dayjs.extend(arraySupport)

export const StaffMonthlyAttendanceRecord = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { year, month, facility, staff_id } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const { staffDetail } = useFetchOneStaff(staff_id)
  const defaultValues = {
    tempId: 1,
    is_delete: false,
    facility_id: +facility || null,
    staff_id: +staff_id || null,
    date: dayjs(),
    rest_hours: null,
    rest_minits_flg: false,
    attendance_start_time: "",
    attendance_end_time: "",
    attendance_rest_minits: null,
    attendance_type: null,
    attendance_start_time2: "",
    attendance_end_time2: "",
    attendance_rest_minits2: null,
    attendance_type2: null,
    remarks: "",
    hasAttendance_shift: false,
    is_scheduled: false,
  }
  const initialData = {}
  const [data, setData] = useState(initialData)
  const [searchParams, setSearchParams] = useState({
    facility_id: facility || "",
    date: dayjs([year, +month - 1]).isValid()
      ? dayjs([year, +month - 1])
      : dayjs(),
    staff_id: staff_id || "",
  })
  useMemo(() => {
    return Array.from(
      { length: dayjs(searchParams?.date).daysInMonth() || 31 },
      (_, index) => {
        initialData[`${index + 1}`] = [
          {
            ...defaultValues,
            date: dayjs([
              dayjs(searchParams?.date).year(),
              dayjs(searchParams?.date).month(),
              index + 1,
            ]),
          },
        ]
      },
    )
  }, [])

  const staffName = staffDetail?.data?.staff_name || ""
  const title = t("{{staffName}} {{date}}'s Attendance record", {
    staffName: staffName,
    date: dayjs(searchParams?.date)?.format("YYYY年MM月"),
  })
  const handleDataChange = ({
    dateOfMonth,
    key,
    value,
    tempId,
    removeHrs2,
  }: STAFF_CHANGE_VALUES) => {
    const getDataValues = (data: any[], key: string, value: any) => {
      if (key === "attendance_type") {
        return data?.map((v) => {
          if (value === STAFF_ABSENT) {
            return {
              ...v,
              attendance_start_time: "",
              attendance_end_time: "",
              attendance_rest_minits: null,
              attendance_start_time2: "",
              attendance_end_time2: "",
              attendance_rest_minits2: null,
            }
          }
          return v
        })
      }
      if (key === "attendance_type2") {
        return data?.map((v) => {
          if (value === STAFF_ABSENT) {
            return {
              ...v,
              attendance_start_time2: "",
              attendance_end_time2: "",
              attendance_rest_minits2: null,
            }
          }
          return v
        })
      }
      return data
    }
    const newData = removeHrs2
      ? getDataValues(
          data[`${dateOfMonth}`]?.filter((v) => v?.tempId !== 2),
          key,
          value,
        )
      : getDataValues(data[`${dateOfMonth}`], key, value)

    const finalNewData = newData?.map((val) => {
      return val?.tempId === tempId
        ? {
            ...val,
            [key]: value,
          }
        : val
    })
    setData({
      ...data,
      [`${dateOfMonth}`]: finalNewData,
    })
  }
  const addAndRemoveWorkingHours = (action: "A" | "R", dataOf: string) => {
    //A->Add,R->remove
    if (action === "A") {
      const newData = [
        ...data[`${dataOf}`],
        {
          ...defaultValues,
          attendance_start_time:
            data[`${dataOf}`][0]?.attendance_start_time || "",
          attendance_end_time: data[`${dataOf}`][0]?.attendance_end_time || "",
          attendance_rest_minits:
            data[`${dataOf}`][0]?.attendance_rest_minits || null,
          attendance_type: data[`${dataOf}`][0]?.attendance_type || null,
          rest_hours: data[`${dataOf}`][0]?.rest_hours || null,
          id: data[`${dataOf}`][0]?.id || null,
          tempId: 2,
          hasAttendance_shift: true,
          date: data[`${dataOf}`][0].date,
          rest_minits_flg: data[`${dataOf}`][0].rest_minits_flg,
          is_scheduled: data[`${dataOf}`][0].is_scheduled,
        },
      ]
      setData({
        ...data,
        [`${dataOf}`]: newData,
      })
    }
    if (action === "R") {
      const newData = data[`${dataOf}`]?.slice(0, 1)
      setData({
        ...data,
        [`${dataOf}`]: [
          {
            ...newData[0],
            attendance_start_time2: "",
            attendance_end_time2: "",
            attendance_rest_minits2: null,
            attendance_type2: null,
          },
        ],
      })
    }
  }

  const { isLoading } = useQuery({
    queryKey: ["staff-monthly-attendance-record", searchParams],
    queryFn: () =>
      getMonthlyAttendanceStaffResult({
        year: dayjs(searchParams?.date).year() || dayjs().year(),
        month: dayjs(searchParams?.date).month() + 1 || dayjs().month(),
        facility: searchParams?.facility_id || facility,
        staff_id: searchParams?.staff_id || staff_id,
      }),
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const response = deSerializeAttendanceRecordPayload(
        dayjs(searchParams?.date),
        defaultValues,
        res?.data || [],
      )
      setData(response)
    },
    cacheTime: 0,
    enabled: searchParams.facility_id && searchParams.staff_id ? true : false,
  })

  return (
    <>
      <PageHead>{title}</PageHead>

      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: (
              <Link href={"/attendance-schedule"}>
                {t("Office staff - Attendance management")}
              </Link>
            ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
      />
      <StaffMonthlyAttendanceRecordStyleWrapper>
        <StaffMonthlyAttendanceScheduleOperation
          params={searchParams}
          setSearchParams={setSearchParams}
        />
        {!searchParams?.facility_id ||
        !searchParams?.staff_id ||
        !searchParams?.date ? (
          <NoFilterContainer>
            {t("Please update search conditions")}
          </NoFilterContainer>
        ) : (
          <Card
            title={
              isLoading ? (
                <Skeleton.Button active />
              ) : (
                t("{{staffName}} {{date}} Attendance schedule", {
                  staffName: staffName,
                  date: dayjs(searchParams?.date)?.format(MONTH_DATE_FORMAT),
                })
              )
            }
            extra={
              isLoading ? (
                <Space>
                  <Skeleton.Button active />
                  <Skeleton.Button active />
                  <Skeleton.Button active />
                </Space>
              ) : (
                <MonthPagination
                  currentMonth={dayjs(searchParams?.date)?.month()}
                  currentYear={dayjs(searchParams?.date)?.year()}
                  showDate={false}
                  onMonthChange={(year, month) => {
                    const date = dayjs([year, month])
                    updateParams(
                      {
                        facility: searchParams?.facility_id,
                        year: date?.year(),
                        month: date?.month() + 1,
                        staff_id: searchParams?.staff_id || "",
                      },
                      "/attendance-record/staff-monthly-attendance-record",
                    )
                    setSearchParams({
                      ...searchParams,
                      date: date,
                    })
                  }}
                />
              )
            }
          >
            {isLoading ? (
              <Space direction={"vertical"} style={{ display: "flex" }}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Space>
            ) : (
              <StaffMonthlyAttendanceRecordContent
                params={searchParams}
                data={data}
                handleDataChange={handleDataChange}
                addAndRemoveWorkingHours={addAndRemoveWorkingHours}
              />
            )}
          </Card>
        )}
      </StaffMonthlyAttendanceRecordStyleWrapper>
    </>
  )
}
