import { Flex } from "antd"
import React, { useContext, useRef, useState } from "react"
import { AssessmentOperation } from "./AssessmentOperation"
import { AssessmentContentList } from "./AssessmentContentList"
import { useQuery } from "react-query"
import { getAllAssessment } from "../../services"
import { scrollToSelectedElement, useUpdateSearchParams } from "@project/common"
import { FULL_DATE_FORMAT_EN, PAGE_SIZE } from "../../constants"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import {
  hasPermissionForFacility,
  UserManagementPermission,
} from "../../utils/PermissionKeys"
import { AuthContext } from "../../context"

export const AssessmentContent = (): JSX.Element => {
  const [updateParams] = useUpdateSearchParams()
  const contentRef = useRef<HTMLDivElement>(null)

  const { permissions, isOwner } = useContext(AuthContext)

  const hasAccess = (facilityId, permissionType: "both" | "write" = "write") =>
    isOwner ||
    hasPermissionForFacility(
      permissions,
      UserManagementPermission,
      permissionType,
      facilityId,
    )

  const router = useRouter()
  const { facilityIds } = router.query as any
  const selectedFIds = facilityIds
    ? facilityIds?.split(",")?.filter((f) => !isNaN(f) && f)
    : []
  const [params, setParams] = useState({
    page: 1,
    user_id: null,
    facilityIds: selectedFIds || [],
    from_date: null,
    to_date: null,
    display_setting: null,
    status: [],
  })
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["assessment-list", params],
    queryFn: () =>
      getAllAssessment({
        ...params,
        pageSize: PAGE_SIZE,
        user_id: params?.user_id,
        facilityIds: params?.facilityIds?.toString(),
        from_date: params?.from_date
          ? dayjs(params?.from_date).format(FULL_DATE_FORMAT_EN)
          : null,
        to_date: params?.to_date
          ? dayjs(params?.to_date).format(FULL_DATE_FORMAT_EN)
          : null,
        display_setting: params?.display_setting,
        status: params?.status?.toString(),
      }),
    refetchOnWindowFocus: false,
  })

  return (
    <Flex vertical gap={16}>
      <AssessmentOperation
        params={params}
        setParams={({ mode, ...values }: any) => {
          setParams(values)
          if (mode === "search") {
            updateParams(
              {
                user_id: values?.user_id,
                facilityIds: values?.facilityIds?.toString(""),
                from_date: values?.from_date
                  ? dayjs(values?.from_date).format(FULL_DATE_FORMAT_EN)
                  : null,
                to_date: values?.to_date
                  ? dayjs(values?.to_date).format(FULL_DATE_FORMAT_EN)
                  : null,
                display_setting: values?.display_setting?.toString(),
                status: values?.status?.toString(),
              },
              "/assessment",
            )
          } else {
            updateParams({}, "/assessment")
          }
          scrollToSelectedElement(contentRef)
        }}
      />
      <div ref={contentRef}>
        <AssessmentContentList
          data={data}
          isLoading={isLoading || isFetching}
          currentPage={params?.page}
          setCurrentPage={(page) => {
            setParams({ ...params, page })
          }}
          hasAccess={hasAccess}
        />
      </div>
    </Flex>
  )
}
