import * as yup from "yup"
import { t } from "i18next"
export interface STAFF__INITIAL_ATTENDANCE {
  id: number
  is_delete?: boolean
  facility_id: number
  staff_id: number
  date?: string | null
  rest_hours?: number | null
  rest_minits_flg?: boolean
  attendance_start_time?: string | null
  attendance_end_time?: string | null
  attendance_rest_minits?: number | null
  attendance_type?: number | null
  attendance_start_time2?: string | null
  attendance_end_time2?: string | null
  attendance_rest_minits2?: number | null
  attendance_type2?: number | null
  remarks?: string | null
  holiday_flag?: any
  hasAttendance_shift?: true
  tempId?: number
}
export interface STAFF_ATTENDANCE_DATA {
  [x: string]: STAFF__INITIAL_ATTENDANCE[]
}
export interface STAFF_CHANGE_VALUES {
  dateOfMonth: string
  key: string
  value: string | number | boolean
  tempId?: number
  removeHrs2?: boolean
}

export const staffAttendanceFormSchema = yup.object().shape({
  attendance_type: yup.number().optional().nullable(),
  attendance_start_time: yup
    .string()
    .when("attendance_type", ([attendance_type], schema) => {
      return attendance_type === 1
        ? schema.required(t("Required"))
        : schema.optional().nullable()
    }),
})
//  yup.string().required(t("Required")),
