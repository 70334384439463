import { useRouter } from "next/router"
import React from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"

// commons | packages
import {
  Box,
  PrintFriendlyTable,
  Table,
  useNotification,
} from "@project/common"
import { Approval } from "./common/Approval"
import { genericPrintTableColumn } from "./common/PickupPrintTableColumn"
import {
  IVehicleDriverInfo,
  VehicleAndDriverInfoEntry,
} from "./common/VehicleAndDriverInfoEntry"
import { VehicleEntry } from "./common/VehicleEntry"

// styles
import {
  ScreenTableStyle,
  SpinContainer,
  ToPrintSectionStyle,
} from "./UserTransportMgmtPrint.styles"

// types
import { Spin } from "antd"
import { useQuery } from "react-query"
import { ToPrintSectionProps } from "./types"
import { getUserDailyTransport } from "../../services/user-transport-mgmt.services"
import { getOneInstructor } from "../../services"

export const ToPrintSection: React.FC<ToPrintSectionProps> = ({
  displaySettings,
}) => {
  const { query } = useRouter()
  const { showToast } = useNotification()

  const { data: transportData, isLoading } = useQuery(
    ["transportation-print"],
    () =>
      getUserDailyTransport({
        date: query?.date?.toString(),
        facilityIds: query?.facility_ids?.toString(),
        service: query?.use_service?.toString(),
        pickup_type: query?.list_display?.toString(),
        staff_id: query?.driver_id?.toString(),
      }),
    {
      select: ({ data }) => {
        return {
          pickup_users: data?.pickup_users || [],
          drop_users: data?.drop_users || [],
        }
      },
      onError: (err: any) => {
        showToast({
          message: t(
            err?.data?.error?.message ||
              "Something went wrong. Please contact administrator",
          ),
          type: "error",
        })
      },
    },
  )

  /**
   *Get driver info from Id
   */
  const dId = String(query?.driver_id)
  const { data: driverData } = useQuery<any, Error>({
    queryKey: ["edit_instructor"],
    queryFn: () => getOneInstructor(dId),
    enabled: !!dId,
    keepPreviousData: false,
    retry: 1,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  })

  const getVehicleDriverInfos = (): IVehicleDriverInfo => {
    const info: IVehicleDriverInfo = {}

    if (query?.driver_id) {
      const driverInfo =
        transportData?.drop_users?.length > 0
          ? transportData?.drop_users?.[0]
          : transportData?.pickup_users?.[0] || {}
      info.driverName =
        driverInfo?.driver_staff?.staff_name ||
        driverData?.data?.staff_name ||
        ""
      info.vehicleName = driverInfo?.car?.car_name
      info.vehicleNumber = [
        driverInfo?.car?.car_number_01,
        driverInfo?.car?.car_number_02,
        driverInfo?.car?.car_number_03,
        driverInfo?.car?.car_number_01,
      ]
        .filter((num) => num)
        .join("-")
    }

    return info
  }

  return (
    <ToPrintSectionStyle
      display={"flex"}
      direction={"column"}
      gap={20}
      mt={16}
      w={"100%"}
    >
      {isLoading ? (
        <SpinContainer>
          <Spin size={"large"} />
        </SpinContainer>
      ) : (
        <>
          <div>
            <Box component={"h3"} fz={20} ta={"center"}>
              {t("{{engDate}}'s transportation management", {
                engDate: dayjs(query.date.toString())
                  .locale("en")
                  .format("YYYY-MM-DD （dddd）"),

                jaDate: dayjs(query.date.toString())
                  .locale("ja")
                  .format("YYYY年MM月DD日（dd）"),
              })}
            </Box>
            {displaySettings.show_vOrD === "1" && (
              <VehicleAndDriverInfoEntry {...getVehicleDriverInfos()} />
            )}
          </div>

          {/* These table is for screen display mode [stats here] */}
          <ScreenTableStyle>
            <Table
              columns={genericPrintTableColumn(
                "pickup",
                displaySettings.show_place,
              )}
              dataSource={transportData.pickup_users}
              components={{
                header: {
                  wrapper: (props) => <Thead type={"pickup"} {...props} />,
                },
              }}
              scroll={{
                x: "max-content",
              }}
            />
          </ScreenTableStyle>
          <ScreenTableStyle>
            <Table
              columns={genericPrintTableColumn(
                "dropOff",
                displaySettings.show_place,
              )}
              dataSource={transportData.drop_users}
              components={{
                header: {
                  wrapper: (props) => <Thead type={"dropOff"} {...props} />,
                },
              }}
              scroll={{
                x: "max-content",
              }}
            />
          </ScreenTableStyle>
          {/* These table is for screen display mode [ends here] */}

          {/* These table is for PRINT display mode [stats here] */}
          <PrintFriendlyTable
            columns={genericPrintTableColumn(
              "pickup",
              displaySettings.show_place,
            )}
            dataSource={transportData.pickup_users}
            components={{
              header: {
                wrapper: (props) => <Thead type={"pickup"} {...props} />,
              },
            }}
            scroll={{
              x: "700",
            }}
          />

          <PrintFriendlyTable
            columns={genericPrintTableColumn(
              "dropOff",
              displaySettings.show_place,
            )}
            dataSource={transportData.drop_users}
            components={{
              header: {
                wrapper: (props) => <Thead type={"dropOff"} {...props} />,
              },
            }}
            scroll={{
              x: "700",
            }}
          />
          {/* These table is for PRINT display mode [ends here] */}

          {displaySettings.show_driver_entry === "1" && <VehicleEntry />}
          <Approval />
        </>
      )}
    </ToPrintSectionStyle>
  )
}

export const Thead = (props) => (
  <>
    <thead className={"custom-thead"}>
      <tr className={"custom-row"}>
        <th className={"custom-cell"} colSpan={11}>
          {props.type === "dropOff" ? t("Drop Off") : t("Pickup")}
        </th>
      </tr>
    </thead>
    <thead {...props}></thead>
  </>
)
