import { BoxContainer, Button, theme, useNotification } from "@project/common"
import React, { useState } from "react"
import { Qualification } from "./Qualification"
import { useMutation } from "react-query"
import { createInstructorInfo } from "../../../services"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { Flex, Popconfirm, Skeleton } from "antd"
import { WorkCertificates } from "./WorkCertificates"
import { StaffShift } from "./StaffShift"

interface StaffOtherInfoProps {
  newId?: number | null
  defaultQualifications?: any
  defaultWorkCertificates?: any
  defaultOccupations?: any
  refetchInstructors?: any
  staffInputValues?: any
  handleDelete?: (id: string) => void
  isMutating?: boolean
  isLoading?: boolean
}
const StaffOtherInfo = ({
  newId,
  defaultQualifications,
  defaultWorkCertificates,
  defaultOccupations,
  staffInputValues,
  handleDelete,
  isMutating,
  isLoading,
}: StaffOtherInfoProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const id = router.query.id as string
  const { showToast } = useNotification()
  const [qualifications, setQualifications] = useState(
    defaultQualifications || [],
  )
  const [workCertificates, setWorkCertificates] = useState(
    defaultWorkCertificates || [],
  )
  const [occupations, setOccupations] = useState(defaultOccupations || [])

  const { isLoading: isCreating, mutate: createNew } = useMutation({
    mutationFn: createInstructorInfo,
    onSuccess: () => {
      const msg = id ? t("Updated Successfully") : t("Created Successfully")
      showToast({ type: "success", message: msg })
      router.push("/staff-master")
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const onInstructorInfoSave = () => {
    const staffId = newId || parseInt(id, 10)
    if (staffInputValues) {
      Object.keys(staffInputValues).forEach(
        (k) =>
          !staffInputValues[k] &&
          staffInputValues[k] !== undefined &&
          delete staffInputValues[k],
      )
    }

    const formValues: any = {
      staff_id: staffId,
      staff_details: staffId ? "" : JSON.stringify(staffInputValues),
      qualification_info: qualifications?.map(({ ...rest }) => {
        delete rest.tempId
        return {
          ...rest,
          year: rest?.year || null,
          qualification_id: rest?.qualification_id || null,
        }
      }),
      facility_info: occupations?.map(
        ({ one_week_work_pattern, year, ...rest }) => {
          delete rest.tempId
          delete rest?.occupations
          const pattern = {}
          for (const [key, value] of Object.entries(one_week_work_pattern)) {
            pattern[key] = value ? Number(value) : 0
          }
          return {
            ...rest,
            ...pattern,
            year: parseInt(year, 10),
          }
        },
      ),
      experience_info: workCertificates?.map(({ ...rest }) => {
        delete rest.tempId
        return {
          ...rest,
          working_days: Number(rest?.working_days),
        }
      }),
    }

    createNew(formValues)
  }
  const isDisabled = !id && !newId
  return (
    <>
      <BoxContainer>
        {isLoading ? (
          <>
            <Skeleton active />
            <br />
            <Skeleton active />
            <br />
            <Skeleton active />
          </>
        ) : (
          <>
            <Qualification
              data={qualifications}
              onSave={(data: any, operation: string) => {
                if (operation === "edit") {
                  const x = [...qualifications]
                  const index = x?.findIndex((v) => v?.tempId === data?.tempId)
                  if (index >= 0) {
                    x.splice(index, 1, data)
                  }
                  setQualifications(x)
                } else {
                  setQualifications([{ ...data }, ...qualifications])
                }
              }}
              onDelete={(id) =>
                setQualifications(
                  qualifications?.filter((val) => val?.tempId !== id),
                )
              }
            />
            <WorkCertificates
              data={workCertificates}
              onSave={(data: any, operation: string) => {
                if (operation === "edit") {
                  const x = [...workCertificates]
                  const index = x?.findIndex((v) => v?.tempId === data?.tempId)

                  if (index >= 0) {
                    x.splice(index, 1, data)
                  }

                  setWorkCertificates(x)
                } else {
                  setWorkCertificates([{ ...data }, ...workCertificates])
                }
              }}
              onDelete={(id) =>
                setWorkCertificates(
                  workCertificates?.filter((val) => val?.tempId !== id),
                )
              }
            />
            <StaffShift
              data={occupations}
              onSave={(data: any, operation: string) => {
                if (operation === "edit") {
                  const x = [...occupations]
                  const index = x?.findIndex((v) => v?.tempId === data?.tempId)

                  if (index >= 0) {
                    x.splice(index, 1, data)
                  }
                  setOccupations(x)
                } else {
                  setOccupations([{ ...data }, ...occupations])
                }
              }}
              onDelete={(id) =>
                setOccupations(occupations?.filter((val) => val?.tempId !== id))
              }
            />
            <Flex
              gap={4}
              justify={"space-between"}
              align={"center"}
              wrap={"wrap"}
              style={{ margin: "0px 18px" }}
            >
              <Flex
                gap={8}
                wrap={"wrap"}
                style={{
                  marginBottom: "0.2rem",
                }}
              >
                <Button
                  btnText={t("Cancel")}
                  shape={"round"}
                  borderColor={theme.colors.border}
                  htmlType={"button"}
                  disabled={isCreating}
                  onClick={() => router.push("/staff-master")}
                />
                <Button
                  btnText={t("Save")}
                  shape={"round"}
                  type={"primary"}
                  htmlType={"button"}
                  onClick={onInstructorInfoSave}
                  disabled={isDisabled}
                  isLoading={isCreating}
                />
              </Flex>
              {id && (
                <Popconfirm
                  title={`${t("Data will be deleted")} ${t(
                    'Please press the "delete execution" button if you like.',
                  )}`}
                  onConfirm={() => handleDelete(id)}
                  okText={t("Execute delete")}
                  cancelText={t("Cancel")}
                  placement={"bottomLeft"}
                >
                  <Button
                    btnText={t("Delete")}
                    shape={"round"}
                    btnBg={theme.colors.error}
                    borderColor={theme.colors.error}
                    textColor={theme.colors.white}
                    loading={isMutating}
                    style={{
                      marginTop: "0.2rem",
                    }}
                  />
                </Popconfirm>
              )}
            </Flex>
          </>
        )}
      </BoxContainer>
    </>
  )
}

export { StaffOtherInfo }
