import {
  ACCOUNT_TYPE_OPTIONS,
  ActionBtns,
  Box,
  Button,
  Card,
  CheckBox,
  DateCardList,
  FACILITY_TYPES,
  GET_DAY_OPTIONS,
  GRADES,
  Grid,
  InputAreaField,
  InputField,
  MEAL_PROVISION,
  PREFECTURE_OPTIONS,
  RadioGroup,
  SelectInput,
  allowOnlyNumber,
  allowOnlyNumberAndHyphen,
  dynamicLangString,
  scrollToFirstErrorField,
  theme,
  useNotification,
} from "@project/common"
import { notification, Popconfirm, Spin } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import dayjs from "dayjs"
import { FieldArray, FormikProvider, useFormik } from "formik"
import japanPostalCode from "japan-postal-code"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { deleteFacilityInformation } from "../../../services/facilityInformation"
import { getConfirmedBillingFacilities } from "../../../services/NHIFbilling"
import { FacilityInformationValidationSchema } from "../../../types"
import { scrollToID } from "../../../utils/scrollToSelectedElement"
import { Divisor } from "../../../utils/shared-style"
import {
  ButtonWrapper,
  FacilityStyledForm,
  StyleFieldArray,
} from "./FacilityInformation.style"
import { convertToHalfWidth } from "@project/common/src/constants"
interface AddEditFormProps {
  onSubmit?: (values: any) => void
  isLoading?: boolean
  isMutating?: boolean
  isDeleting?: boolean
  handleDelete?: (id: string) => void
  id?: string
  defaultValues?: any | undefined
  onDraftSave?: (value: any) => void
  draftLoading?: boolean
  showDraft?: boolean
  isEdit?: boolean
}

const FacilityInformationForm = ({
  isLoading,
  isMutating,
  onSubmit,
  defaultValues,
  onDraftSave,
  draftLoading,
  showDraft,
  isEdit,
}: AddEditFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { showToast } = useNotification()
  const { id } = router.query as any
  const initialValues = {
    business_number: "",
    facility_name: "",
    facility_name_furigana: "",
    facility_type: 1,
    grade: "Others",
    physical_disability_support_flag: false,
    intellectual_disability_support_flag: false,
    mental_disability_support_flag: false,
    development_disability_support_flag: false,
    brain_disfunction_support_flag: false,
    disabled_child_support_flag: false,
    incurable_disease_support_flag: false,
    meal_provision_flag: false,
    capacity: null,
    mail_address: "",
    show_my_page_info: false,
    contact_po_box: "",
    address: "",
    contact_phone: "",
    fax: "",
    bcp_evacuation: "",
    bcp_pref_id: 1,
    remaining_address: "",
    bcp_phone: "",
    hospital_name: "",
    hospital_po_box: "",
    hospital_pref_id: 1,
    municipality_address: "",
    hospital_phone: "",
    note: "",
    pay_slip_alert_date: "1",
    corporate_name: "",
    representative_name: "",
    administrator_name: "",
    invoice_title: "",
    invoice_full_name: "",
    financial_institution_name: "",
    financial_institution_branch_name: "",
    financial_institution_name_kana: "",
    financial_institution_branch_name_kana: "",
    account_type: 0,
    account_holder_name: "",
    account_holder_name_kana: "",
    consign_number: "",
    consign_name_kana: "",
    bank_no: "",
    branch_no: "",
    facility_wage_setting: [{ item_type: "allowance", name: "" }],
    facility_wage_setting2: [{ item_type: "deduction", name: "" }],
  }

  const formik = useFormik({
    initialValues: defaultValues || initialValues,
    validationSchema: FacilityInformationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const updatedValues = {
        ...values,
        is_draft: false,
        facility_wage_setting: [
          ...values.facility_wage_setting,
          ...values.facility_wage_setting2,
        ],
      }
      onSubmit(updatedValues)
    },
  })
  const setAddressFromPostCode = async () => {
    japanPostalCode.get(formik?.values?.hospital_po_box, function (address) {
      formik.setFieldValue("municipality_address", address?.city)
      const prefectureID =
        PREFECTURE_OPTIONS.find((item) => {
          return item.label == address?.prefecture
        })?.value || null
      formik.setFieldValue("hospital_pref_id", prefectureID)
    })
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const handleDraftSave = () => {
    const updatedValues = {
      ...formik.values,
      is_draft: true,
      facility_wage_setting: [
        ...formik.values.facility_wage_setting,
        ...formik.values.facility_wage_setting2,
      ],
    }
    onDraftSave(updatedValues)
  }

  const { data: facilitiesWithConfirmedBilling } = useQuery(
    ["get-facilities-with-confirmed-billing"],
    () => {
      const year = dayjs().year()
      const month = dayjs().month()

      return getConfirmedBillingFacilities({
        year,
        month,
        facilityIds: "",
      })
    },
    {
      retry: 1,
      keepPreviousData: false,
      select: ({ data }) => {
        const value = `${dayjs().year()}年${dayjs()
          .month()
          ?.toString()
          ?.padStart(2, "0")}月`
        return data?.map((facility) => {
          return {
            key: facility?.facility_name,
            value,
          }
        })
      },
    },
  )

  const { isLoading: isDeleting, mutate: deleteData } = useMutation({
    mutationFn: deleteFacilityInformation,
    onSuccess: () => {
      showToast({
        type: "success",
        message: dynamicLangString([
          t("Facility Information"),
          "Deleted Successfully",
        ]),
      })
      router.push("/facility-information")
    },
    onError: (error: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const handleDelete = () => {
    deleteData(id)
  }

  return (
    <>
      {facilitiesWithConfirmedBilling?.length ? (
        <DateCardList
          icon={"/assets/icons/NotificationAlert.svg"}
          title={t("請求情報が確定された事業所が存在します")}
          content={t(
            "編集や登録・削除を行う際は、請求担当者に確認しつつ行ってください",
          )}
          subContent={t("確定した請求情報と差異が発生する可能性があります")}
          expandTitle={t("View facilities with confirmed billing information")}
          expandText={t("---")}
          list={facilitiesWithConfirmedBilling}
        />
      ) : null}
      <ButtonWrapper>
        <Button
          shape={"round"}
          btnText={t("Facility Information1")}
          iconType={"down-arrow"}
          className={"down-btn"}
          onClick={() => scrollToID("facility-information")}
          btnBg={theme.colors["action-400"]}
        />

        <Button
          shape={"round"}
          iconType={"down-arrow"}
          btnText={t(
            "Facility contact information (setting items for billing/activity management/my page display settings)",
          )}
          className={"facility-contact"}
          onClick={() => scrollToID("facility-contact-information")}
          btnBg={theme.colors["action-400"]}
        />

        <Button
          shape={"round"}
          iconType={"down-arrow"}
          className={"down-btn"}
          onClick={() => scrollToID("BCP-evacuation-site")}
          btnText={t("BCP Evacuation Site Information")}
          btnBg={theme.colors["action-400"]}
        />
        <Button
          shape={"round"}
          iconType={"down-arrow"}
          className={"down-btn"}
          onClick={() => scrollToID("partner-hospital-information")}
          btnText={t("Partner Hospital Information")}
          btnBg={theme.colors["action-400"]}
        />
        <Button
          shape={"round"}
          iconType={"down-arrow"}
          className={"down-btn"}
          onClick={() => scrollToID("wage-settings")}
          btnText={t("Wage Settings")}
          btnBg={theme.colors["action-400"]}
        />
        <Button
          shape={"round"}
          iconType={"down-arrow"}
          className={"down-btn"}
          onClick={() => scrollToID("billing-items")}
          btnText={t("Billing items")}
          btnBg={theme.colors["action-400"]}
        />
      </ButtonWrapper>
      <Card title={"事業所情報・履歴"} id={"facility-information"}>
        <Spin spinning={isLoading}>
          {" "}
          <FacilityStyledForm onSubmit={formik.handleSubmit}>
            <div className={"form__control"}>
              <Grid
                labelContent={t("Business Number")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <InputField
                  name={"business_number"}
                  className={"input__content"}
                  placeholder={"例：0123456789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={t("(within 15 char)")}
                  subInfoMt={"0px"}
                  maxLength={15}
                  value={formik.values?.business_number}
                  width={"319px"}
                  error={
                    formik.touched?.business_number &&
                    formik.errors?.business_number
                      ? t(`${formik.errors?.business_number}`)
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={
                  <div>
                    <span>{t("Facility Name")}</span>
                    <div>
                      <span>{t("(Official Name)")}</span>
                    </div>
                  </div>
                }
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <InputField
                  name={"facility_name"}
                  id={"facility_name"}
                  placeholder={"例：就労継続支援B型事業所　○○○○"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  value={formik.values?.facility_name}
                  error={
                    formik.touched?.facility_name &&
                    formik.errors?.facility_name
                      ? t(`${formik.errors?.facility_name}`)
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid
                labelContent={t("Hiragana")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"facility_name_furigana"}
                  id={"facility_name_furigana"}
                  placeholder={
                    "例：しゅうろうけいぞくしえんびーがたじぎょうしょ　○○○○"
                  }
                  padding={"8px"}
                  borderRadius={"4px"}
                  value={formik.values?.facility_name_furigana}
                  onChange={formik.handleChange}
                  error={
                    formik.touched?.facility_name_furigana &&
                    formik.errors?.facility_name_furigana
                      ? t(`${formik.errors?.facility_name_furigana}`)
                      : ""
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Type")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <SelectInput
                  name={"facility_type"}
                  options={FACILITY_TYPES}
                  width={"60%"}
                  placeholder={t("--")}
                  value={formik.values.facility_type}
                  filterSort={() => null}
                  onChange={(value) =>
                    formik.setFieldValue("facility_type", value)
                  }
                  error={
                    formik.touched.facility_type && formik.errors.facility_type
                      ? t(`${formik.errors?.facility_type}`)
                      : ""
                  }
                  disabled={defaultValues?.has_contract}
                />
              </Grid>
              <Grid
                labelContent={t("Grade")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <SelectInput
                  name={"grade"}
                  width={"60%"}
                  options={GRADES}
                  placeholder={t("------")}
                  filterSort={() => null}
                  value={formik.values.grade}
                  onChange={(value) => formik.setFieldValue("grade", value)}
                />
              </Grid>
              <Grid
                labelContent={t("Type（Support for visits)")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <div className={"target_user_check_box"}>
                  <CheckBox
                    name={"physical_disability_support_flag"}
                    value={formik.values?.physical_disability_support_flag}
                    checked={formik.values?.physical_disability_support_flag}
                    label={t("Physical disability")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "physical_disability_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"intellectual_disability_support_flag"}
                    value={formik.values?.intellectual_disability_support_flag}
                    checked={
                      formik.values?.intellectual_disability_support_flag
                    }
                    label={t("Intellectual disability")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "intellectual_disability_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"mental_disability_support_flag"}
                    value={formik.values?.mental_disability_support_flag}
                    checked={formik.values?.mental_disability_support_flag}
                    label={t("Mental disability")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "mental_disability_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"development_disability_support_flag"}
                    value={formik.values?.development_disability_support_flag}
                    checked={formik.values?.development_disability_support_flag}
                    label={t("Developmental disability")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "development_disability_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"brain_disfunction_support_flag"}
                    value={formik.values?.brain_disfunction_support_flag}
                    checked={formik.values?.brain_disfunction_support_flag}
                    label={t("Higher brain dysfunction")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "brain_disfunction_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"disabled_child_support_flag"}
                    value={formik.values?.disabled_child_support_flag}
                    checked={formik.values?.disabled_child_support_flag}
                    label={t("Disabled child")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "disabled_child_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                  <CheckBox
                    name={"incurable_disease_support_flag"}
                    value={formik.values?.incurable_disease_support_flag}
                    checked={formik.values?.incurable_disease_support_flag}
                    label={t("Target people with incurable diseases, etc.")}
                    onChange={(e: CheckboxChangeEvent) =>
                      formik.setFieldValue(
                        "incurable_disease_support_flag",
                        e.target.checked,
                      )
                    }
                  />
                </div>
              </Grid>
              <Grid
                labelContent={t("Meal Provision")}
                className={"row__header "}
                background
                labelSpan={6}
              >
                <RadioGroup
                  options={MEAL_PROVISION}
                  value={formik.values.meal_provision_flag}
                  name={"meal_provision_flag"}
                  onChange={(e) => {
                    formik.setFieldValue("meal_provision_flag", e.target.value)
                  }}
                />
              </Grid>
              <Grid
                labelContent={t("Capacity")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <InputField
                  name={"capacity"}
                  id={"capacity"}
                  type={"number"}
                  placeholder={"例：120人"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  value={formik.values?.capacity}
                  min={0}
                  error={
                    formik.touched?.capacity && formik.errors?.capacity
                      ? t(`${formik.errors?.capacity}`)
                      : ""
                  }
                  onChange={(e) => {
                    const data = allowOnlyNumber(e.target.value)
                    formik.setFieldValue("capacity", +data)
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault()
                    }
                  }}
                  onWheel={(event: any) => event.target.blur()}
                />
              </Grid>
              <Grid
                labelContent={t("Email Address")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <InputField
                  name={"mail_address"}
                  type={"email"}
                  id={"mail_address"}
                  placeholder={"例：eassy@example.com"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  value={formik.values?.mail_address}
                  error={
                    formik.touched?.mail_address && formik.errors?.mail_address
                      ? t(`${formik.errors?.mail_address}`)
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
            </div>
            <div className={"add_form"} id={"facility-contact-information"}>
              <span>
                {t(
                  "Facility contact information (setting items for billing/activity management/my page display settings)",
                )}
              </span>
              <Divisor />
              <Grid
                labelContent={t("My page footer information")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <CheckBox
                  name={"show_my_page_info"}
                  value={formik.values?.show_my_page_info}
                  checked={formik.values?.show_my_page_info}
                  label={t("Display facility information on My Page")}
                  onChange={(e: CheckboxChangeEvent) =>
                    formik.setFieldValue("show_my_page_info", e.target.checked)
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Po. Box No.")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"contact_po_box"}
                  placeholder={t("Example：000-0000")}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={t(
                    "(Half-width numbers and hyphens (-) within 8 characters)",
                  )}
                  subInfoMt={"0px"}
                  maxLength={8}
                  value={formik.values?.contact_po_box}
                  width={"319px"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Address")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"address"}
                  id={"address"}
                  placeholder={"例：東京都渋谷区〇〇1丁目２−３"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(With in 200 char)")}
                  maxLength={200}
                  value={formik.values?.address}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid
                labelContent={t("Phone Number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"contact_phone"}
                  placeholder={"例：012-345-6789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  width={"319px"}
                  type={"text"}
                  subInfo={t("(Numbers and hyphens (-) up to 30 characters)")}
                  maxLength={30}
                  value={formik.values?.contact_phone}
                  subInfoMt={"0px"}
                  onChange={(e) => {
                    const v = allowOnlyNumberAndHyphen(e.target.value)
                    formik.setFieldValue("contact_phone", v)
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched?.contact_phone &&
                    formik.errors?.contact_phone
                      ? t(`${formik.errors?.contact_phone}`)
                      : ""
                  }
                />
              </Grid>
              <Grid
                labelContent={t("FAX")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"fax"}
                  placeholder={"例：012-345-6789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={"(半角数字とハイフン(-)30文字以内)"}
                  subInfoMt={"0px"}
                  maxLength={30}
                  value={formik.values?.fax}
                  width={"319px"}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "fax",
                      convertToHalfWidth(e.target.value),
                    )
                  }}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </div>
            <div className={"add_form"} id={"BCP-evacuation-site"}>
              <span>{t("BCP Evacuation Site Information")}</span>
              <Divisor />
              <Grid
                labelContent={t("BCPの避難場所")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"bcp_evacuation"}
                  placeholder={t("Enter BCP evacuation site information")}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(200文字以内)")}
                  maxLength={200}
                  value={formik.values?.bcp_evacuation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Prefecture")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <SelectInput
                  name={"bcp_pref_id"}
                  width={"260px"}
                  placeholder={t("--")}
                  filterSort={() => null}
                  options={PREFECTURE_OPTIONS}
                  value={formik.values.bcp_pref_id}
                  onChange={(value) =>
                    formik.setFieldValue("bcp_pref_id", value)
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Remaining Address")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"remaining_address"}
                  placeholder={t("Enter remaining address")}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(within 100 characters)")}
                  maxLength={100}
                  value={formik.values?.remaining_address}
                  width={"672px"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Phone Number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"bcp_phone"}
                  placeholder={"例：012-345-6789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(Within 30 characters)")}
                  maxLength={30}
                  type={"text"}
                  value={formik.values?.bcp_phone}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const v = allowOnlyNumberAndHyphen(e.target.value)
                    formik.setFieldValue("bcp_phone", v)
                  }}
                  error={
                    formik.touched?.bcp_phone && formik.errors?.bcp_phone
                      ? t(`${formik.errors?.bcp_phone}`)
                      : ""
                  }
                />
              </Grid>
            </div>
            <div className={"add_form"} id={"partner-hospital-information"}>
              <span>{t("Partner Hospital Information")}</span>
              <Divisor />
              <Grid
                labelContent={t("Hospital Name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"hospital_name"}
                  placeholder={t("Enter Hospital name")}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(With in 200 char)")}
                  maxLength={200}
                  value={formik.values?.hospital_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Po box")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <div id={"provision__zip_code__content"}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Box flex={1} miw={320}>
                        <InputField
                          name={"hospital_po_box"}
                          id={"hospital_po_box"}
                          placeholder={t("例：001-0010")}
                          padding={"8px"}
                          borderRadius={"4px"}
                          width={"319px"}
                          maxLength={50}
                          value={formik.values?.hospital_po_box}
                          error={
                            formik.touched?.hospital_po_box &&
                            formik.errors?.hospital_po_box
                              ? t(`${formik.errors?.hospital_po_box}`)
                              : ""
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          subInfo={t(
                            "(Half-width numbers and hyphens (-) within 8 characters)",
                          )}
                          subInfoMt={"0px"}
                        />
                      </Box>

                      <Button
                        btnText={t("Autofill address")}
                        shape={"round"}
                        width={"180px"}
                        disabled={!formik.values.hospital_po_box}
                        onClick={setAddressFromPostCode}
                      />
                    </div>
                    <Link href={"https://www.post.japanpost.jp/zipcode/"}>
                      <a target={"_blank"}>
                        {t(
                          "*If you are unsure of your postal code, please check the postal code on the Japan Post Group website.",
                        )}
                      </a>
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid
                labelContent={t("Prefecture")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <SelectInput
                  name={"hospital_pref_id"}
                  width={"260px"}
                  placeholder={t("--")}
                  filterSort={() => null}
                  options={PREFECTURE_OPTIONS}
                  value={formik.values.hospital_pref_id}
                  onChange={(value) =>
                    formik.setFieldValue("hospital_pref_id", value)
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Municipality/Address")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"municipality_address"}
                  placeholder={"例：東京都渋谷区〇〇1丁目２−３"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(200文字以内)")}
                  maxLength={200}
                  value={formik.values?.municipality_address}
                  width={"672px"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Phone Number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"hospital_phone"}
                  placeholder={"例：012-345-6789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={t("(Numbers and hyphens (-) up to 30 characters)")}
                  subInfoMt={"0px"}
                  maxLength={30}
                  type={"text"}
                  value={formik.values?.hospital_phone}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const v = allowOnlyNumberAndHyphen(e.target.value)
                    formik.setFieldValue("hospital_phone", v)
                  }}
                  error={
                    formik.touched?.hospital_phone &&
                    formik.errors?.hospital_phone
                      ? t(`${formik.errors?.hospital_phone}`)
                      : ""
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Notes")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"note"}
                  placeholder={"--"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(Within 250 char)")}
                  maxLength={250}
                  rows={3.5}
                  value={formik.values?.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </div>
            <div className={"add_form"} id={"wage-settings"}>
              <span>{t("Wage Settings")}</span>
              <Divisor />
              <Grid
                labelContent={t("Payslip confirmation alert date")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <SelectInput
                  name={"pay_slip_alert_date"}
                  width={"208px"}
                  options={GET_DAY_OPTIONS}
                  allowClear
                  filterSort={() => null}
                  showSearch={false}
                  placeholder={"--"}
                  value={formik.values.pay_slip_alert_date}
                  onChange={(value) =>
                    formik.setFieldValue("pay_slip_alert_date", value)
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Registration of various allowances")}
                labelSpan={6}
                background
                className={"row__header"}
                labelAlignItems={"flex-start"}
              >
                <FormikProvider value={formik}>
                  <FieldArray
                    name={"facility_wage_setting"}
                    render={(arrayHelpers) => (
                      <StyleFieldArray>
                        {formik.values.facility_wage_setting?.map(
                          (allowance, index) => (
                            <div className={"input_container"} key={index}>
                              <InputField
                                name={`facility_wage_setting[${index}].name`}
                                id={`facility_wage_setting[${index}].name`}
                                value={allowance?.name}
                                height={"50px"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxLength={200}
                                placeholder={t("Enter name of allowance")}
                                info={t("(within 200 char)")}
                              />

                              <div className={"btn_content"}>
                                {formik.values.facility_wage_setting?.length -
                                  1 ===
                                index ? (
                                  <>
                                    {formik.values.facility_wage_setting
                                      ?.length <= 19 ? (
                                      <Button
                                        btnText={t("Add")}
                                        shape={"round"}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            item_type: "allowance",
                                            name: "",
                                          })
                                        }
                                        btnBg={theme.colors.disabled}
                                        borderColor={theme.colors.border}
                                        textColor={theme.colors.text}
                                        disabled={!allowance?.name}
                                      />
                                    ) : (
                                      <Button
                                        btnText={t("Delete")}
                                        type={"text"}
                                        disabled={isDeleting}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        textColor={theme.colors.error}
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <div className={"delete-btn"}>
                                    <Button
                                      btnText={t("Delete")}
                                      type={"text"}
                                      onClick={() => arrayHelpers.remove(index)}
                                      textColor={theme.colors.error}
                                      style={{
                                        textDecoration: "underline",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </StyleFieldArray>
                    )}
                  />
                </FormikProvider>
              </Grid>
              <Grid
                labelContent={t("Registration of various deduction")}
                labelSpan={6}
                background
                className={"row__header"}
                labelAlignItems={"flex-start"}
              >
                <FormikProvider value={formik}>
                  <FieldArray
                    name={"facility_wage_setting2"}
                    render={(arrayHelpers) => (
                      <StyleFieldArray>
                        {formik.values.facility_wage_setting2?.map(
                          (deduction, index) => (
                            <div className={"input_container"} key={index}>
                              <InputField
                                name={`facility_wage_setting2[${index}].name`}
                                id={`facility_wage_setting2[${index}].name`}
                                value={deduction?.name}
                                height={"50px"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxLength={200}
                                info={t("(within 200 char)")}
                                placeholder={t("Enter name of deduction")}
                              />

                              <div className={"btn_content"}>
                                {formik.values.facility_wage_setting2?.length -
                                  1 ===
                                index ? (
                                  <>
                                    {formik.values.facility_wage_setting2
                                      ?.length <= 19 ? (
                                      <Button
                                        btnText={t("Add")}
                                        shape={"round"}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            item_type: "deduction",
                                            name: "",
                                          })
                                        }
                                        btnBg={theme.colors.disabled}
                                        borderColor={theme.colors.border}
                                        textColor={theme.colors.text}
                                        disabled={!deduction?.name}
                                      />
                                    ) : (
                                      <Button
                                        btnText={t("Delete")}
                                        type={"text"}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        textColor={theme.colors.error}
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <div className={"delete-btn"}>
                                    <Button
                                      btnText={t("Delete")}
                                      type={"text"}
                                      onClick={() => arrayHelpers.remove(index)}
                                      textColor={theme.colors.error}
                                      style={{
                                        textDecoration: "underline",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </StyleFieldArray>
                    )}
                  />
                </FormikProvider>
              </Grid>
            </div>
            <div className={"add_form"} id={"billing-items"}>
              <span>{t("Billing items")}</span>
              <Divisor />
              <Grid
                labelContent={t("Corporate name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"corporate_name"}
                  placeholder={"例：株式会社○○"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.corporate_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Representative name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"representative_name"}
                  placeholder={"例：山田太郎"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.representative_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Administrator name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"administrator_name"}
                  placeholder={"例：鈴木一郎"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.administrator_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Invoice title")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"invoice_title"}
                  placeholder={"例：管理者"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.invoice_title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Invoice full name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"invoice_full_name"}
                  placeholder={"例：鈴木一郎"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.invoice_full_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Financial Institute")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"financial_institution_name"}
                  placeholder={"例：△△銀行"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.financial_institution_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Financial Branch")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"financial_institution_branch_name"}
                  placeholder={"例：〇〇支店"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.financial_institution_branch_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Financial institute Kana")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"financial_institution_name_kana"}
                  placeholder={"例：サンカクギンコウ"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.financial_institution_name_kana}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors?.financial_institution_name_kana
                      ? formik.errors?.financial_institution_name_kana?.toString()
                      : null
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Financial institute Branch Kana")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"financial_institution_branch_name_kana"}
                  placeholder={"例：シンジュクシテン"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.financial_institution_branch_name_kana}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors?.financial_institution_branch_name_kana
                      ? formik.errors?.financial_institution_branch_name_kana?.toString()
                      : null
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Account type")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <SelectInput
                  name={"account_type"}
                  width={"208px"}
                  placeholder={t("--")}
                  options={ACCOUNT_TYPE_OPTIONS}
                  filterSort={() => null}
                  value={formik.values.account_type}
                  onChange={(value) =>
                    formik.setFieldValue("account_type", value)
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Account holder name")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"account_holder_name"}
                  placeholder={"例：いーじー"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.account_holder_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Account holder name Kana")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"account_holder_name_kana"}
                  placeholder={"例：イージー"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.account_holder_name_kana}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors?.account_holder_name_kana
                      ? formik.errors?.account_holder_name_kana?.toString()
                      : null
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Direct debit consignor number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"consign_number"}
                  placeholder={"例：0123456789"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  width={"319px"}
                  type={"text"}
                  subInfo={t("(Numbers with in 50 char)")}
                  subInfoMt={"0px"}
                  maxLength={50}
                  value={formik.values?.consign_number}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      8, 9, 37, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 189,
                    ]
                    if (!allowedKeys.includes(e.keyCode)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e: any) => {
                    const inputValue = e.target.value
                    const sanitizedValue = inputValue.replace(/[^0-9-]/g, "")
                    formik.setFieldValue("consign_number", sanitizedValue)
                  }}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Consignor name Kana")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"consign_name_kana"}
                  placeholder={"例：カブシキカイシャ○○"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  info={t("(50文字以内)")}
                  maxLength={50}
                  value={formik.values?.consign_name_kana}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors?.consign_name_kana
                      ? formik.errors?.consign_name_kana?.toString()
                      : null
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Bank number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"bank_no"}
                  placeholder={"例：1234"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={t("(Numbers with in 4 char)")}
                  subInfoMt={"0px"}
                  maxLength={4}
                  width={"319px"}
                  value={formik.values?.bank_no}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if ([69, 189]?.includes?.(e?.keyCode)) e.preventDefault()
                  }}
                  onChange={(e: any) => {
                    const inputValue = e.target.value
                    if (inputValue === "") {
                      formik.setFieldValue("bank_no", null)
                    } else {
                      const parsedValue = parseInt(inputValue)
                      if (!isNaN(parsedValue)) {
                        formik.setFieldValue("bank_no", inputValue)
                      }
                    }
                  }}
                />
              </Grid>
              <Grid
                labelContent={t("Branch number")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputField
                  name={"branch_no"}
                  placeholder={"例：1234"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  subInfo={t("(Numbers with in 4 char)")}
                  subInfoMt={"0px"}
                  maxLength={4}
                  width={"319px"}
                  value={formik.values?.branch_no}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if ([69, 189]?.includes?.(e?.keyCode)) e.preventDefault()
                  }}
                  onChange={(e: any) => {
                    const inputValue = e.target.value
                    if (inputValue === "") {
                      formik.setFieldValue("branch_no", null)
                    } else {
                      const parsedValue = parseInt(inputValue)
                      if (!isNaN(parsedValue)) {
                        formik.setFieldValue("branch_no", inputValue)
                      }
                    }
                  }}
                />
              </Grid>
            </div>
            <ActionBtns>
              <ActionBtns>
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    flexWrap: "wrap",
                    marginTop: "24px",
                  }}
                >
                  <Button
                    btnText={t("Cancel")}
                    shape={"round"}
                    onClick={() => router.push("/facility-information")}
                    disabled={isLoading || isDeleting || isMutating}
                    loading={draftLoading || isLoading}
                  />
                  <Button
                    btnText={isEdit ? t("Update") : t("Save")}
                    shape={"round"}
                    type={"primary"}
                    htmlType={"submit"}
                    isLoading={draftLoading || isLoading}
                    disabled={isDeleting || draftLoading || isLoading}
                  />
                  {showDraft || defaultValues?.is_draft ? (
                    <Button
                      btnText={t("Save as Draft")}
                      shape={"round"}
                      onClick={handleDraftSave}
                      btnBg={theme.colors["action-400"]}
                      loading={draftLoading}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </ActionBtns>
              <ActionBtns justify={"none"}>
                {isEdit && (
                  <Popconfirm
                    title={t("Deleting.Is that OK?")}
                    onConfirm={handleDelete}
                    okText={t("OK")}
                    cancelText={t("Cancel")}
                    okButtonProps={{ size: "middle" }}
                    cancelButtonProps={{ size: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "1em",
                        flexWrap: "wrap",
                        marginTop: "24px",
                      }}
                    >
                      <Button
                        btnText={t("Delete")}
                        shape={"round"}
                        disabled={isDeleting}
                        isLoading={isDeleting}
                        iconType={"delete"}
                      />
                    </div>
                  </Popconfirm>
                )}
              </ActionBtns>
            </ActionBtns>
          </FacilityStyledForm>
        </Spin>
      </Card>
    </>
  )
}
FacilityInformationForm.displayname = "FacilityInformationForm"
export { FacilityInformationForm }
