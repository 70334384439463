import {
  AntTable,
  Button,
  CalendarPaginationForDay,
  Card,
  Table,
  fillMissingMonths,
  moneyFormat,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Switch, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import {
  fetchPaySlipList,
  getUserDetail,
  getUserYearContract,
} from "../../../services"
import { PayslipOperation } from "../PayslipOperation/PayslipOperation"

const PayslipContainer = styled.div`
  .disabled-row {
    background: ${theme.colors.disabled};
  }
`

const SwitchWrapper = styled.div`
  .ant-switch {
    background-color: #fff !important;
    border: 1px solid #0782c8;

    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      .switch {
        color: #191919;
        font-size: 12px !important;
        font-weight: 400;
      }
    }
    .ant-switch-handle::before {
      background-color: #0782c8 !important;
    }
  }
`
export const PayslipList = ({ id, facilityId }): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { page: currentPage } = router?.query as any
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1,
  )
  const [currentDate, setCurrentDate] = useState<any>(dayjs().format("YYYY"))
  const [activeText, setActiveText] = useState("")
  const [date, setDate] = useState<any>(dayjs().format("YYYY") || null)
  const [fiscal, setFiscal] = useState(false)
  const [searchEnabled, setSearchEnabled] = useState(false)
  const listContent = useRef<any>(null)
  const [facility, setFacility] = useState(facilityId || null)
  const [user_id, setUserId] = useState(+id)
  const [startAt, setStartAt] = useState(null)
  const [params, setParams] = useState<any>({
    facility: facility,
    user_id: user_id,
    date: date || null,
    month: null,
  })

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(["fetch-pay-slip-list", page, params], {
    queryFn: fetchPaySlipList,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: searchEnabled || !!page,
    select: (data) => {
      return data?.data
    },
    onSuccess: () => {
      setSearchEnabled(false)
    },
    onError: () => {
      setSearchEnabled(false)
    },
  })

  const { data: userInfo } = useQuery(
    ["user-info", id],
    () => getUserDetail(id),
    {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: ({ data }) => data,
    },
  )

  const { data: userContracts, isLoading: isContractLoading } = useQuery(
    ["user-active-contract", params],
    () => getUserYearContract(params.user_id, params.date),
    {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: ({ data }) => data,
    },
  )

  const filledData = useMemo(() => {
    const data = fillMissingMonths(response || [], date, fiscal)
    return data.map((d) => {
      const dataDate =
        d?.year && d?.month
          ? d?.year + "-" + d?.month?.toString()?.padStart(2, "0")
          : dayjs(d?.date.toString().split("T")[0]).format("YYYY-MM")
      const contractForMonth = userContracts?.[dataDate]
      const disabled = contractForMonth ? false : true
      return {
        ...d,
        disabled,
      }
    })
  }, [response, date, fiscal, userContracts])

  const columns: ColumnsType<any> = [
    {
      key: "1",
      align: "center",
      title: t("year and month"),
      render: (row) => (
        <Typography.Text>
          {dayjs(row?.date).format("YYYY年MM月")}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      align: "center",
      title: t("Total amount paid"),
      render: (row) => (
        <Typography.Text>
          {moneyFormat(row?.total_wage || 0)} {t("Yen")}
        </Typography.Text>
      ),
    },
    {
      key: "1",
      align: "center",
      title: t("View detail"),
      render: (row) => {
        const currentYear = dayjs().year()
        const currentMonth = dayjs().month() + 1 // dayjs months are 0-indexed, so add 1
        const [year, month] =
          row?.year && row?.month
            ? [row?.year, row?.month]
            : row?.date.split("-").map(Number)

        return (
          <Flex justify={"center"}>
            <Button
              btnText={t("Detail")}
              type={"link"}
              textColor={theme.colors.link}
              style={{
                textDecoration: "underline",
              }}
              disabled={
                row?.disabled ||
                year > currentYear ||
                (year === currentYear && month > currentMonth)
              }
              onClick={() =>
                router.push(
                  `/wage-management/payslip-list/detail/${user_id}?year=${year}&month=${month}`,
                )
              }
            />
          </Flex>
        )
      },
    },
  ]
  const onSearch = (values) => {
    setPage(1)
    const validDate = dayjs(date + "01-01").isValid()
      ? dayjs(date + "01-01").format("YYYY")
      : dayjs().format("YYYY")
    const query = removeBlankAttributes({
      ...values,
      ...values,
      date: validDate,
    })
    updateParams(
      {
        ...params,
        ...values,
        date: validDate,
      },
      `/wage-management/payslip-list/${values?.user_id}`,
    )
    scrollToSelectedElement(listContent)
    setParams(query)
    setSearchEnabled(true)
    setActiveText("")
    setCurrentDate(+date)
    setUserId(values?.user_id)
  }
  const resetHandler = () => {
    setPage(1)
    setFacility(null)
    setUserId(+id)
    setDate(dayjs().format("YYYY"))
    setStartAt(null)
    setParams({
      facility: null,
      user_id: null,
      date: null,
      month: null,
    })
    updateParams({}, `/wage-management/payslip-list/${id}`)
    setSearchEnabled(true)
    setActiveText("")
    setCurrentDate(new Date().getFullYear())
  }
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    scrollToSelectedElement(listContent)
    router.push(
      {
        pathname: `/wage-management/payslip-list/${id}`,
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  const switchFiscalYear = (checked) => {
    setFiscal(checked)
    setActiveText("")
    const year = dayjs().format("YYYY")
    const month = dayjs().format("MM")
    if (checked) {
      const myYear = +year
      setCurrentDate(myYear)
      setDate(String(myYear))
      setParams({ ...params, date: myYear, month: month })
    } else {
      setCurrentDate(year)
      setDate(String(year))
      setParams({
        facility: params?.facility,
        user_id: params?.user_id,
        date: year,
      })
    }
  }
  return (
    <PayslipContainer>
      <PayslipOperation
        params={params}
        setParams={setParams}
        onSearch={onSearch}
        resetHandler={resetHandler}
        setFacility={setFacility}
        facility={facility}
        setUserId={setUserId}
        user_id={user_id}
        setStartAt={setStartAt}
        startAt={startAt}
        showDate={true}
        setDate={setDate}
        date={date}
      />
      <Flex vertical gap={16}>
        <SwitchWrapper>
          <Flex justify={"flex-end"}>
            <Switch
              checkedChildren={
                <div className={"switch"}>{t("Year unit switch")}</div>
              }
              unCheckedChildren={
                <div className={"switch"}>{t("Year unit switch")}</div>
              }
              defaultChecked={fiscal}
              onChange={(checked) => {
                switchFiscalYear(checked)
              }}
            />
          </Flex>
        </SwitchWrapper>
        <Card
          title={t("{{user_name}} {{year}} 's payslip list", {
            user_name: userInfo?.username,
            year: params?.date,
          })}
          extra={
            <>
              <CalendarPaginationForDay
                currentDate={currentDate}
                activeText={activeText}
                setActiveText={setActiveText}
                picker={"year"}
                onNext={(v) => {
                  setCurrentDate(v)
                  setParams({
                    ...params,
                    date: v,
                  })
                  setDate(String(v))
                }}
                onPrev={(v) => {
                  setCurrentDate(v)
                  setParams({
                    ...params,
                    date: v,
                  })
                  setDate(String(v))
                }}
              />
            </>
          }
        >
          <Table
            columns={columns}
            dataSource={filledData || []}
            summary={(data: any) => {
              const total = data?.reduce(
                (acc, curr) => {
                  const paidAmount =
                    (curr?.user_wage_total || 0) +
                    (curr?.facility_user_wage || 0)
                  return {
                    paid_amount: acc.paid_amount + paidAmount,
                  }
                },
                {
                  paid_amount: 0,
                },
              )
              return (
                <AntTable.Summary>
                  <AntTable.Summary.Row
                    style={{
                      backgroundColor: theme.colors.rgba.accent,
                    }}
                  >
                    <AntTable.Summary.Cell index={0} align={"center"}>
                      <Typography.Text strong> {t("Total")}</Typography.Text>
                    </AntTable.Summary.Cell>
                    <AntTable.Summary.Cell index={1} colSpan={2}>
                      <Typography.Text strong>
                        {moneyFormat(total?.paid_amount)} {t("Yen")}
                      </Typography.Text>
                    </AntTable.Summary.Cell>
                  </AntTable.Summary.Row>
                </AntTable.Summary>
              )
            }}
            pagination={false}
            pageSize={pageSize}
            total={response?.count || 0}
            current={+page}
            onChange={handlePaginationChange}
            scroll={{ x: true }}
            loading={isLoading || isFetching || isContractLoading}
            rowClassName={(row) => (row?.disabled ? "disabled-row" : "")}
          />
        </Card>
      </Flex>
    </PayslipContainer>
  )
}
