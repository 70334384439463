import _ from "lodash"
import dayjs from "dayjs"
import { t } from "i18next"
import { FormProps } from "antd/lib"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import { ColumnsType } from "antd/es/table"
import { Divider, Form, Space, Spin } from "antd"
import { useMutation, useQuery } from "react-query"

import {
  Grid,
  keys,
  Table,
  Button,
  WithSorter,
  InputAreaField,
  useNotification,
  pick,
  useUpdateSearchParams,
} from "@project/common"

import {
  RemarksFormData,
  UsedFacilityDataType,
  UsedFacilityDataRecord,
} from "../../../../types/nhif.types"
import AlertBoxV2 from "../../common/AlertBoxV2"
import { CustomerInvoiceListCard } from "./styles"

import { Counter, QuoteTitle } from "../DisabledUserBenefitsInternal/styles"

import { useAuthContext } from "../../../../context"

import {
  createReceiptProvDetails,
  getReceiptProvDetails,
} from "../../../../services"
import { fetchIds } from "../../utils/fetchIds"
import { disablePrintBtn } from "../../utils/disablePrintBtn"

export const CustomerInvoiceList = ({
  customerInvoice,
  loadingCustomerInvoice,
}: {
  customerInvoice: UsedFacilityDataRecord
  loadingCustomerInvoice: boolean
}) => {
  const { query } = useRouter()

  const currentYearMonth = dayjs(query?.year_month as string).isValid()
    ? dayjs(query?.year_month as string)
    : dayjs()

  const [selectedRowsInMultipleTable, setSelectedRowsInMultipleTable] =
    useState<Record<string, UsedFacilityDataType[]>>({})

  const [form] = Form.useForm<RemarksFormData>()

  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()
  const { facilities: facilitiesCtx } = useAuthContext()

  const SelectedFacilityName = facilitiesCtx.find(
    (item) => item?.id == (query?.facilityIds as string)?.split(",")[0],
  )?.facility_name

  const allSelectedFacilities = useMemo(() => {
    return facilitiesCtx
      .filter(
        (item) =>
          (query?.facilityIds as string)?.split(",").includes(item?.value),
      )
      ?.map((item) => item.facility_name) as string[]
  }, [query])

  const {
    data: receiptProvDetails,
    isLoading: loadingReceiptProvDetails,
    refetch,
  } = useQuery(["receipt-prov-details"], {
    queryFn: () =>
      getReceiptProvDetails({
        month: currentYearMonth.month() + 1,
        year: currentYearMonth.year(),
        page_no: 5,
      }),
    refetchOnWindowFocus: false,
    select: (data) => pick(data.msg, ["type_a", "type_b", "type_c"]),
    onSuccess: (data) => {
      form.setFieldsValue(data)
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const { mutate: saveReceiptProvTemp, isLoading: savingReceiptProv } =
    useMutation({
      mutationFn: (payload: RemarksFormData) =>
        createReceiptProvDetails(
          {
            month: currentYearMonth.month() + 1,
            year: currentYearMonth.year(),
          },
          {
            page: 5,
            ...payload,
          },
        ),
      onError: () => {
        showToast({
          type: "error",
          message: t("Something went wrong. Please contact administrator."),
        })
      },
      onSuccess: () => {
        refetch()
        showToast({
          type: "success",
          message: t("Remarks Updated Successfully"),
        })
      },
    })

  const column: ColumnsType<UsedFacilityDataType> = [
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`User's name (furigana)`}
            columnKey={"user_name_furigana"}
          />
        )
      },
      dataIndex: "user_name_furigana",
      key: "user_name_furigana",
      width: 290,
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.user_name_furigana.localeCompare(b.user_name_furigana),
      render: (_, rec) =>
        `${rec.user_name} ${
          rec.user_name_furigana ? `(${rec.user_name_furigana})` : ""
        }`,
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Guardian name   (furigana)`}
            columnKey={"parent_name_furigana"}
          />
        )
      },
      dataIndex: "parent_name_furigana",
      key: "parent_name_furigana",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.parent_name_furigana.localeCompare(b.parent_name_furigana),
      width: 290,
      align: "center",
      render: () => "",
    },

    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Beneficiary certificate number`}
            columnKey={"receiving_certificate_number"}
          />
        )
      },
      dataIndex: "receiving_certificate_number",
      key: "receiving_certificate_number",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.receiving_certificate_number.localeCompare(
          b.receiving_certificate_number,
        ),
      width: 290,
      align: "center",
    },

    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Facility`}
            columnKey={"facility_name"}
          />
        )
      },
      dataIndex: "facility_name",
      key: "facility_name",
      sortIcon: () => null,
      sorter: (a, b) => a.facility_name.localeCompare(b.facility_name),
      width: 160,
      align: "center",
    },

    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Billing Amount`}
            columnKey={"billing_amount"}
          />
        )
      },
      dataIndex: "billed_amount",
      key: "billed_amount",
      sortIcon: () => null,
      sorter: (a: any, b: any) => a.billed_amount - b.billed_amount,
      width: 160,
      align: "center",
    },
  ]

  const handleCheckAll = () => {
    setSelectedRowsInMultipleTable(customerInvoice)
  }

  const handleClearAll = () => {
    setSelectedRowsInMultipleTable({})
  }

  const onFinish: FormProps<RemarksFormData>["onFinish"] = (values) => {
    saveReceiptProvTemp(values)
  }

  const handlePrint = () => {
    updateParams(
      {
        ...query,
        user_ids: fetchIds(selectedRowsInMultipleTable),
      },
      "/national-health-insurance-billing/prints/customer-invoice/print-control",
    )
  }

  return (
    <CustomerInvoiceListCard
      title={t("{{year_month}}{{facility_name}} Print invoice to users", {
        facility_name: SelectedFacilityName,
        year_month: currentYearMonth?.format("YYYY年MM月"),
      })}
    >
      <AlertBoxV2
        theme={"info"}
        heading={t(
          "The billed amounts for the following facilities are totaled and output below.",
        )}
        description={allSelectedFacilities.join(" ")}
        variant={"WITH_OUT_COLLAPSE"}
      />

      <h2 className={"info-text"}>
        {t(
          "If you want to include remarks on the bill to the user, please enter them below.",
        )}
      </h2>

      <Form
        form={form}
        onFinish={onFinish}
        name={"control-hooks"}
        initialValues={receiptProvDetails}
      >
        <Spin spinning={loadingReceiptProvDetails || savingReceiptProv}>
          <Grid labelContent={t("Employment Type A")} background labelSpan={6}>
            <Form.Item name={"type_a"} id={"type_a"}>
              <InputAreaField id={"type_a"} rows={3} />
            </Form.Item>
          </Grid>
          <Grid labelContent={t("Employment Type B")} background labelSpan={6}>
            <Form.Item name={"type_b"} id={"type_b"}>
              <InputAreaField rows={3} id={"type_b"} />
            </Form.Item>
          </Grid>
          <Grid
            labelContent={t("Employment Transition Support")}
            background
            labelSpan={6}
          >
            <Form.Item name={"type_c"} id={"type_c"}>
              <InputAreaField rows={3} id={"type_c"} />
            </Form.Item>
          </Grid>
        </Spin>

        <Button
          btnText={t("Save Temporarily")}
          shape={"round"}
          htmlType={"submit"}
          className={"save-temporarily-btn"}
          isLoading={savingReceiptProv}
        />
      </Form>

      <Divider type={"horizontal"} orientationMargin={16} />

      <Space align={"center"} size={16} className={"action-buttons"} wrap>
        <Button
          shape={"round"}
          type={"outlined-white-bg"}
          iconType={"check-all"}
          btnText={t("Check all")}
          className={"size-sm"}
          onClick={() => handleCheckAll()}
        />
        <Button
          shape={"round"}
          type={"grayed"}
          btnText={t("Clear all")}
          className={"size-sm"}
          onClick={() => handleClearAll()}
        />
        <Button
          shape={"round"}
          iconType={"print"}
          type={"primary"}
          btnText={t("Print")}
          onClick={handlePrint}
          disabled={disablePrintBtn(selectedRowsInMultipleTable)}
        />
      </Space>

      {loadingCustomerInvoice ? (
        <div className={"individual-table"}>
          <QuoteTitle className={"quote-title"}>{"Loading...."}</QuoteTitle>
          <Table
            columns={column}
            dataSource={[]}
            loading={loadingCustomerInvoice}
            tableContainerStyle={{
              width: "100%",
            }}
            className={"facility_user-table"}
            scroll={{
              x: 800,
            }}
            rowSelection={{
              type: "checkbox",
            }}
          />
        </div>
      ) : keys(customerInvoice).length > 0 ? (
        keys(customerInvoice).map((key) => {
          return (
            <div key={key} className={"individual-table"}>
              <QuoteTitle
                className={"quote-title"}
              >{`本庄市 (${key})`}</QuoteTitle>
              <Table
                columns={column}
                dataSource={customerInvoice[key]}
                tableContainerStyle={{
                  width: "100%",
                }}
                className={"facility_user-table"}
                scroll={{
                  x: 800,
                }}
                rowSelection={{
                  type: "checkbox",
                  columnWidth: 60,
                  selectedRowKeys: selectedRowsInMultipleTable[key]?.map(
                    (item) => item.id,
                  ),
                  onChange: (_, selectedRows) => {
                    setSelectedRowsInMultipleTable((prev) => ({
                      ...prev,
                      [key]: selectedRows,
                    }))
                  },
                }}
              />
            </div>
          )
        })
      ) : (
        <div className={"individual-table"}>
          <QuoteTitle className={"quote-title"}>{"--"}</QuoteTitle>
          <Table
            columns={column}
            dataSource={[]}
            tableContainerStyle={{
              width: "100%",
            }}
            className={"facility_user-table"}
            scroll={{
              x: 800,
            }}
            rowSelection={{
              type: "checkbox",
            }}
          />
        </div>
      )}

      <Space align={"center"} size={16} className={"action-buttons"} wrap>
        <Button
          shape={"round"}
          iconType={"print"}
          type={"primary"}
          btnText={t("Print")}
          onClick={handlePrint}
          disabled={disablePrintBtn(selectedRowsInMultipleTable)}
        />
        <Counter>
          {t("Amount charged to the user total {{total}}円", {
            total: _.sumBy(
              keys(selectedRowsInMultipleTable)
                .map((key) => selectedRowsInMultipleTable[key])
                .flat(),
              "billed_amount",
            )?.toLocaleString(),
          })}
        </Counter>
      </Space>
    </CustomerInvoiceListCard>
  )
}
