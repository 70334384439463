import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"
export const StyledChildren = styled(Flex)`
  width: 100%;
`

export const GridContent = styled.div`
  width: 100%;
  overflow: auto;
  .grid__row__content {
    border: 1px solid ${theme.colors.border};
    min-width: 600px;
    &:not(:last-child) {
      border-bottom: 0;
    }
    .row__cell {
      min-height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    .with__background {
      background: ${theme.colors.background};
    }
    .border_x {
      border-left: 1px solid ${theme.colors.border};
      border-right: 1px solid ${theme.colors.border};
    }
  }
`
