import { memo } from "react"

// packages
import { Flex, Popconfirm } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"

// commons | components
import { ActionBtns, Box, Button } from "@project/common"
import { BulkEditGenericForm } from "./common/BulkEditGenericForm"

// context
import { useTMBulkEdit } from "../../context/TMBulkEditFormContext"

// types
import { TMBulkFormValue, TransportMgmtType } from "./types"

export const TMBulkForm = memo(TMBulkFormInternal)

function TMBulkFormInternal({
  handleReset,
  handleTMBulkEditReflect,
}: {
  handleTMBulkEditReflect: (values: Partial<TMBulkFormValue>) => void
  handleReset: () => void
}) {
  const { tmBulkValue } = useTMBulkEdit()

  const formik = useFormik<Partial<TMBulkFormValue>>({
    initialValues: tmBulkValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleTMBulkEditReflect(values)
    },
  })

  const { setFieldValue, values, handleSubmit } = formik

  // 1. This function takes in values from two different form i.e. "pickup" & "dropOff" form
  // and sets value to respective attrib
  const handleFormInputChange = (
    type: TransportMgmtType,
    key: string,
    values: any,
  ) => {
    setFieldValue(`${[type]}.${[key]}`, values)
  }

  return (
    <Box display={"flex"} direction={"column"} gap={16} mb={"12px"}>
      {/* Bulk edit form for Pickup Data */}
      <BulkEditGenericForm
        label={"Pickup"}
        type={"pickup_data"}
        values={{ ...values?.pickup_data }}
        handleFormInputChange={handleFormInputChange}
      />

      {/* Bulk edit form for DropOff Data */}
      <BulkEditGenericForm
        label={"Drop Off"}
        type={"dropOff_data"}
        values={{ ...values?.dropOff_data }}
        handleFormInputChange={handleFormInputChange}
      />
      <Flex align={"center"} gap={"16px"} wrap={"wrap"}>
        <ActionBtns className={"operation-action-btns"} justify={"flex-start"}>
          <Popconfirm
            title={t(
              "Are you sure to reflect?\nIf there are record it will be over written.",
            )}
            onConfirm={() => handleReset()}
            okText={t("OK")}
            cancelText={t("Cancel")}
            okButtonProps={{ size: "middle" }}
            cancelButtonProps={{ size: "middle" }}
          >
            <Button shape={"round"} type={"default"} btnText={t("Reset")} />
          </Popconfirm>

          <div>
            <Popconfirm
              title={t(
                "Are you sure to reflect?\nIf there are record it will be over written.",
              )}
              onConfirm={() => handleSubmit()}
              okText={t("OK")}
              cancelText={t("Cancel")}
              okButtonProps={{ size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
            >
              <Button shape={"round"} type={"primary"} btnText={t("Reflect")} />
            </Popconfirm>
          </div>
        </ActionBtns>
        <Box component={"p"} c={"error"} fz={"xs"}>
          {t(
            '※If there is a user whose record you do not want to overwrite, remove the check and press "Reflect"',
          )}
        </Box>
      </Flex>
    </Box>
  )
}
