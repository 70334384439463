import { theme } from "@project/common"
import { Flex } from "antd"

import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AssessmentOtherFormFields } from "./AssessmentOtherFormFields"
import { AssessmentWorkElementItemFields } from "./AssessmentWorkElementItemFields"
const Wrapper = styled(Flex)`
  .head__title {
    border-bottom: 1px solid ${theme.colors.border};
    padding: 8px 0;
    font-weight: 500;
  }
`

export const AssessmentWorkSettingsFields = ({
  assessmentId,
  categories,
  handleDataValueChange,
  handleAssessmentEntryItemAddition,
  assessment_setting_id,
}: {
  assessmentId: number
  assessment_setting_id: number
  categories: any[]
  handleDataValueChange: (values: {
    value: string
    key: string
    id: number
  }) => void
  handleAssessmentEntryItemAddition: (values: {
    id: number
    category_id: number
    item_id: number
  }) => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper vertical gap={16}>
      {categories?.map((category) => (
        <Wrapper vertical gap={16} key={category?.id}>
          {category?.assessment_item?.length > 0 ? (
            <>
              {category?.title?.toLowerCase() === "career" ? (
                <>
                  <div className={"head__title"}>{t(category?.title)}</div>
                  <AssessmentWorkElementItemFields
                    assessment_setting_id={assessment_setting_id}
                    assessmentId={assessmentId}
                    categoryId={category?.id}
                    category_items={category?.assessment_item || []}
                    handleDataValueChange={handleDataValueChange}
                    handleAssessmentEntryItemAddition={
                      handleAssessmentEntryItemAddition
                    }
                  />
                </>
              ) : (
                <AssessmentOtherFormFields
                  assessment_setting_id={assessment_setting_id}
                  assessmentId={assessmentId}
                  categoryId={category?.id}
                  title={category?.title || ""}
                  fields={category?.assessment_item || []}
                  handleDataValueChange={handleDataValueChange}
                />
              )}
            </>
          ) : null}
        </Wrapper>
      ))}
    </Wrapper>
  )
}
