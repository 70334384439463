import React, { memo, useEffect } from "react"

// packages
import { t } from "i18next"

// components | commons
import { Card, MonthPagination, Table } from "@project/common"

// styles
import { UserTransportMgmtTableWrap } from "./UserTransportMgmtInternal.styles"

// utils
import { reTraceData } from "./utils/reTraceData"
import { generateFacilityColumns } from "./utils/generateFacilityColumns"

// types
import {
  ReTraceDataFn,
  UserTransportMgmtTableProps,
} from "../../types/UserTransportMgmt.types"

// constants
import { useQuery } from "react-query"
import { getTransportationUsersCount } from "../../services/user-transport-mgmt.services"
import { notification } from "antd"

// !## Main Component
export const UserTransportMgmtTable: React.FC<UserTransportMgmtTableProps> =
  memo(({ facilities, currentYearMonth, operationFormik }) => {
    const [dataSource, setDataSource] = React.useState<
      ReturnType<ReTraceDataFn>
    >([])

    const { data: userTransportData, isLoading } = useQuery(
      ["user-transport-count", operationFormik],
      () =>
        getTransportationUsersCount({
          facilityIds: operationFormik.values.facility_ids?.join(","),
          year: operationFormik.values.year,
          month: operationFormik.values.month,
        }),
      {
        retry: 1,
        keepPreviousData: false,
        select: ({ data }) => {
          let allTransportData = []
          data.forEach((d) => {
            allTransportData = allTransportData.concat(d.transportation_count)
          })
          return allTransportData
        },
        onError: (err: any) => {
          notification.error({
            message: t(
              err?.data?.error?.message ||
                "Something went wrong. Please contact administrator",
            ),
          })
        },
      },
    )

    // !## React hooks
    // 1. As facilities and currentYearMonth changes we wanna update our dataSource.
    useEffect(() => {
      const sanitizedDataSource = reTraceData(
        userTransportData || [],
        facilities.map((item) => item.id),
        currentYearMonth,
      )
      setDataSource(sanitizedDataSource)
    }, [userTransportData])

    return (
      <UserTransportMgmtTableWrap>
        <Card
          title={t("{{YYYY}}年{{MM}}月’s user transportation management", {
            YYYY: currentYearMonth.format("YYYY"),
            MM: currentYearMonth.format("MM"),
          })}
          extra={
            <MonthPagination
              showDate={false}
              change={"month"}
              currentText={t("This month")}
              currentYear={currentYearMonth.year()}
              currentMonth={currentYearMonth.month()}
              onMonthChange={(_, __, date) => {
                operationFormik?.setFieldValue("year", date.year())
                operationFormik?.setFieldValue("month", date.month() + 1)
                operationFormik?.handleSubmit()
              }}
            />
          }
        >
          <Table
            pagination={false}
            dataSource={dataSource}
            scroll={{ x: "max-content" }}
            columns={generateFacilityColumns(facilities, currentYearMonth)}
            loading={isLoading}
          />
        </Card>
      </UserTransportMgmtTableWrap>
    )
  })
