import { removeBlankAttributes } from "@project/common"
import { useRouter } from "next/router"

export const useUpdateSearchParams = () => {
  const router = useRouter()

  const updateParams = (params: any, pathname: string, scroll?: boolean) => {
    const finalParam: any = removeBlankAttributes(params)

    localStorage.setItem(
      "user-service-provision-record-page",
      JSON.stringify(finalParam),
    )

    router.push(
      {
        pathname: pathname,
        query: { ...finalParam },
      },
      undefined,
      { scroll: scroll || false },
    )
  }
  return [updateParams]
}
