import { useRouter } from "next/router"
import React, { useContext, useMemo, useRef } from "react"

// packages
import dayjs from "dayjs"
import { Flex } from "antd"
import { t } from "i18next"
import { useFormik } from "formik"

// components | commons
import {
  Box,
  Button,
  ActionBtns,
  DatePicker,
  filterObject,
  AccordionCard,
  CheckBoxGroup,
  useUpdateSearchParams,
  scrollToSelectedElement,
} from "@project/common"
import { UserTransportMgmtTable } from "./UserTransportMgmtTable"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// styles
import {
  UserTransportMgmtContainer,
  UserTransportMgmtOperationContent,
} from "./UserTransportMgmtInternal.styles"

// context
import { AuthContext } from "../../context"

// utils
import { setOperationInitialFormikValues } from "../../utils"

// types
import { Facilities } from "../../types"
import {
  Nullish,
  UserTransportMgmtOperationType,
} from "../../types/UserTransportMgmt.types"

// constants
import { USER_TRANSPORT_MGMT_OPERATION_KEYS } from "./constants"

// !## Main components
export const UserTransportMgmtInternal = () => {
  const { query } = useRouter()
  const listRef = useRef<HTMLDivElement>(null)
  const { facilities: facilitiesCtx } = useContext(AuthContext) as {
    facilities: Facilities
  }

  const [updateParams] = useUpdateSearchParams()

  // ! ## query params
  const memoizedQuery: UserTransportMgmtOperationType = useMemo(() => {
    const queryConst = query as Partial<
      Record<keyof UserTransportMgmtOperationType, string | string[]>
    >

    const month = queryConst?.month ? +queryConst.month : dayjs().month() + 1
    const year = queryConst?.year ? +queryConst.year : dayjs().year()
    const facility_ids = queryConst.facility_ids
      ? queryConst.facility_ids
          .toString()
          .split(",")
          .map((item) => Number(item))
      : null

    return {
      month,
      year,
      facility_ids,
    }
  }, [query])

  // ! ## formik instance
  const formik = useFormik<Partial<Nullish<UserTransportMgmtOperationType>>>({
    initialValues: {
      facility_ids: null,
      year: dayjs().year(),
      month: dayjs().month() + 1,
    },
    onSubmit: (values) => {
      const params = filterObject({
        ...values,
        year: values?.year || null,
        month: values?.month || null,
        facility_ids: values.facility_ids?.join(","),
      })
      updateParams({ ...params }, `/user-transport-management`)
      scrollToSelectedElement(listRef)
    },
  })
  const { setFieldValue, values, handleSubmit } = formik

  // ! ## Event handlers
  // 1. handle facility check and uncheck
  const handleCheckAll = () => {
    setFieldValue(
      "facility_ids",
      facilitiesCtx.map((facility) => facility.id),
    )
  }
  const handleUncheckAll = () => {
    setFieldValue("facility_ids", [])
  }

  // ! ## React hooks
  // 1. useMemo [optimizing the page] [NOTE: Use of useCallback is not working is this case]
  const memoizedList = useMemo(() => {
    return (
      <div ref={listRef}>
        <UserTransportMgmtTable
          // We only want to pass the facilities that are selected in the form
          facilities={
            !memoizedQuery.facility_ids // if none of the facility is selected then we are displaying all the facility transport records.
              ? facilitiesCtx
              : facilitiesCtx.filter(
                  (facility) =>
                    memoizedQuery.facility_ids?.includes(facility.id),
                )
          }
          currentYearMonth={dayjs([
            memoizedQuery.year,
            memoizedQuery.month - 1,
          ])}
          operationFormik={formik}
        />
      </div>
    )
  }, [memoizedQuery, facilitiesCtx])
  // 2. useEffect [setting memoizedQuery to formik values]
  React.useEffect(() => {
    setOperationInitialFormikValues(
      setFieldValue,
      memoizedQuery,
      USER_TRANSPORT_MGMT_OPERATION_KEYS,
    )
  }, [memoizedQuery])

  return (
    <UserTransportMgmtContainer>
      {/* Form operation section starts here */}
      <AccordionCard
        defaultActiveKey={["1"]}
        items={[
          {
            key: "user-transport-mgmt-operation",
            label: t("Operation Options"),
            children: (
              <UserTransportMgmtOperationContent>
                <OperationFields
                  wrap={"wrap"}
                  gap={"10px 24px"}
                  labelWidth={"150px"}
                  align={"flex-start"}
                  label={"Displaying facility"}
                >
                  <div className={"TM_facility-inputs"}>
                    <Flex
                      className={"TM_facility-btn-group flex"}
                      wrap={"wrap"}
                      gap={12}
                    >
                      <Button
                        shape={"round"}
                        type={"default"}
                        iconType={"check-all"}
                        btnText={t("Check all")}
                        onClick={handleCheckAll}
                      />
                      <Button
                        shape={"round"}
                        type={"default"}
                        btnText={t("Uncheck all")}
                        onClick={handleUncheckAll}
                        className={"TM_uncheck-all-btn"}
                      />
                    </Flex>

                    <CheckBoxGroup
                      items={facilitiesCtx}
                      name={"facility_ids"}
                      onChange={(val) => {
                        setFieldValue("facility_ids", val)
                      }}
                      value={values.facility_ids?.map((item) =>
                        item.toString(),
                      )}
                    />
                  </div>
                </OperationFields>

                <OperationFields
                  wrap={"wrap"}
                  align={"center"}
                  gap={"10px 24px"}
                  label={"Year month"}
                  labelWidth={"150px"}
                >
                  <Box miw={{ "4xs": "100%", sm: "auto" }}>
                    <div
                      className={"TM_Date-inputs"}
                      style={{ width: "135px" }}
                    >
                      <DatePicker
                        name={"date"}
                        picker={"month"}
                        format={"YYYY年MM月"}
                        date={dayjs([values?.year, values?.month - 1])}
                        onDateChange={(value) => {
                          setFieldValue("year", value.year())
                          setFieldValue("month", value.month() + 1)
                        }}
                      />
                    </div>
                  </Box>
                </OperationFields>

                <ActionBtns
                  className={"operation-action-btns"}
                  justify={"flex-start"}
                >
                  <Button
                    shape={"round"}
                    type={"default"}
                    btnText={t("Display Change")}
                    onClick={() => handleSubmit()}
                  />
                </ActionBtns>
              </UserTransportMgmtOperationContent>
            ),
          },
        ]}
      />
      {/* Form operation section ends here */}

      {/* List section starts here */}
      {memoizedList}
      {/* List section ends here */}
    </UserTransportMgmtContainer>
  )
}
