import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-row {
    width: 100%;
    display: flex;
    gap: 20px;
    @media screen and (max-width: 540px) {
      flex-direction: column;
    }
    .ant-card {
      width: 100%;
    }
  }
  .title-icon {
    display: flex;
    align-items: c;
    gap: 10px;
  }
  .ant-card-body {
    padding: 5px 20px 16px 20px !important;
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 40px;
    row-gap: 10px;
    flex-wrap: wrap;
    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #191919;
      }
      .amount {
        display: flex;
        align-items: center;
        gap: 5px;
        h2 {
          font-size: 26px;
          font-weight: 500;
          color: #d26d10;
        }
      }
    }
  }
  .attendance-users,
  .capacity-commuter,
  .people-certificate,
  .payslip-support {
    .title-span {
      .inline-title {
        display: inline-block;
        margin-right: 5px;
      }

      .title {
        font-size: 22px;
        color: #d26d10;
      }
      span {
        margin-top: 3px;
      }
    }
    .link-title {
      cursor: pointer;
      border-bottom: 1px solid #000;
      width: fit-content;
    }
  }
`
