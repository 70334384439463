import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"

export const StyledTopInfo = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`
export const StyledUserInfo = styled(Flex)`
  border: 1px solid ${theme.colors.text};
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
`
export const StyledWorkedDaysInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${theme.colors.text};
  padding: 16px;
  border-radius: 12px;

  .attendance__info {
    font-size: 20px;
  }
`
