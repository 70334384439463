import {
  Button,
  PrintFriendlyTable,
  Tag,
  USER_ATTENDANCE_VALUES,
  theme,
} from "@project/common"
import React from "react"
import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"

import { Flex, Typography } from "antd"
import { UserDayAttendance } from "../../../types"
import { DayListContentSUmmary } from "./DayListContentSUmmary"

export const DayListContentPrintModalContent = React.forwardRef(
  (
    {
      attendance_list,
      attendanceStats,
      title,
    }: {
      attendance_list: Array<UserDayAttendance>
      attendanceStats: any
      title: string
    },
    ref: any,
  ): JSX.Element => {
    const { t } = useTranslation()

    const columns: ColumnsType<any> = [
      {
        title: t(""),
        key: "edit",
        align: "center",
        width: 10,
        render: (_, __, index) => index + 1,
      },

      {
        title: t("User name"),
        key: "user_name",
        render: (row) => (
          <div id={"list_username_content"}>
            <div>{row?.furigana}</div>
            <div>{row?.username}</div>
            <Tag title={row?.service_type_label} color={"warning"} />
          </div>
        ),
      },
      {
        title: t("Use facility"),
        key: "use_facility",
        render: (row) => <span>{row?.facility_name}</span>,
      },
      {
        title: t("Service provision Status"),
        key: "service_provision_status",
        align: "center",
        render: (row) => <span>{row?.service_provision_status}</span>,
      },
      {
        title: (
          <div>
            <div>{t("Entry time")}</div>
            <div>{t("Out time")}</div>
          </div>
        ),
        key: "time",
        align: "center",
        render: (row) => {
          // show attendance buttons if not attended
          if (!row?.attendance_type || row?.attendance_type == "0") {
            return (
              <Flex vertical gap={8}>
                <Button
                  btnText={t("Attend")}
                  btnBg={theme.colors.success}
                  textColor={theme.colors.white}
                  borderColor={theme.colors.success}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                />
                <Button
                  btnText={t("Absent")}
                  btnBg={theme.colors.error}
                  textColor={theme.colors.white}
                  borderColor={theme.colors.error}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                />
              </Flex>
            )
          }
          // if absent show absent label
          if (
            row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
            row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
          ) {
            return (
              <Button
                btnText={"欠勤"}
                borderColor={theme.colors.error}
                btnBg={theme.colors["pink-light"]}
                textColor={theme.colors.error}
              />
            )
          }

          return (
            <div id={""}>
              <div id={"list__time--content"}>
                <div>{row?.start_time}</div>
              </div>
            </div>
          )
        },
      },
      {
        title: t("Actual Cost"),
        key: "actual_cost",
        align: "center",
        width: 100,
        render: (row) =>
          row?.user_attendance_actual_cost?.map((actualCost, index) => {
            const key = index + "" + row?.username
            if (actualCost?.used_flag) {
              return (
                <div
                  key={key}
                  style={{
                    color: theme.colors.success,
                    fontWeight: 700,
                    marginBottom: "5px",
                  }}
                >
                  {actualCost?.actual_cost_name}
                </div>
              )
            }
            return actualCost?.actual_cost_name
          }),
      },
      {
        title: t("Meal "),
        key: "meal",
        align: "center",
        render: (row) => {
          if (row?.used_meal_name) {
            return (
              <div
                style={{
                  color: theme.colors.success,
                  fontWeight: 700,
                }}
              >
                {row?.used_meal_name}
              </div>
            )
          } else if (row?.scheduled_meal_id) {
            return row?.meal_name
          }
        },
      },
      {
        title: t("Remark"),
        key: "Remark",
        align: "center",
        render: (row) => (
          <div id={"list__remark"}>
            <div>{row?.remark}</div>
          </div>
        ),
      },
      {
        title: t("Output remarks"),
        key: "output_remarks",
        align: "center",
        render: (row) => (
          <div id={"list__remark"}>
            <div>{row?.output_remarks}</div>
          </div>
        ),
      },
    ]

    return (
      <Flex vertical gap={16} ref={ref}>
        <Typography.Text strong>{title}</Typography.Text>
        <DayListContentSUmmary attendanceStats={attendanceStats} />
        <PrintFriendlyTable
          columns={columns || []}
          dataSource={attendance_list}
        />
      </Flex>
    )
  },
)
