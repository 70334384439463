import React, { useContext } from "react"

// packages
import { t } from "i18next"
import { Flex, Form } from "antd"
import { FormInstance } from "antd/lib"

// common | components
import { NumberedTitle } from "./NumberedTitle"
import {
  Box,
  CheckBox,
  Grid,
  RadioGroup,
  SERVICE_TYPE_VALUES,
} from "@project/common"

// context
import { AuthContext } from "../../../context"

// types
import {
  SystemStatusMgmtData,
  SystemStatusMgmtDataType,
} from "../../../services/system-status-mgmt.service"
import { QueryParams } from "../types"

// constants
import {
  WELFARE_SPECIALIST,
  NURSING_STAFF_OPTION,
  ILLNESS_SPECTRUM_OPTION,
  TRANSPORT_ADDITION_OPTION,
  V_AND_A_DISABILITY_OPTION,
  TRANSPORT_ADDITION_VALUES,
  TREATMENT_IMPROVEMENT_ADDITION_V,
} from "../constants"
import { YES_NO_LIST } from "@project/common/src/constants"

import { styled } from "styled-components"

const Red = styled.div`
  color: #e00000;
  font-size: 14px;
  font-weight: 400;
`
const SeverallyILLWrapper = styled.div`
  .ant-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const AdditionForm = ({
  form,
  memoizedQuery,
  staffArrangement,
}: {
  form: FormInstance<SystemStatusMgmtData>
  memoizedQuery: QueryParams
  staffArrangement: string
}) => {
  // avr/capacity * 100 = past 3 months usage rate
  const { queryClient } = useContext(AuthContext)

  const cachedStatusData = queryClient.getQueryData([
    "system-status-data",
    memoizedQuery?.year_month,
  ]) as SystemStatusMgmtDataType

  const serviceTypeValue = Form.useWatch("service_type_id", form) as 1 | 2 | 3
  const transportAddition = Form.useWatch("transport_addition")
  const targetWageInstructorAddition = Form.useWatch(
    "target_wage_achievement_instructor_addition",
  )

  const CONFIRMATION_OPTIONS = [...YES_NO_LIST].reverse()

  const COMBINED_OPTIONS = memoizedQuery?.year_month?.isBefore("2025-04-01")
    ? [
        ...NURSING_STAFF_OPTION,
        {
          type: "label",
          label: t("Treatment improvement addition ( V ) "),
          value: "",
        },
        ...TREATMENT_IMPROVEMENT_ADDITION_V,
      ]
    : NURSING_STAFF_OPTION

  if (transportAddition != "1") {
    form.setFieldValue("target_wage_achievement_addition", "0")
  }

  return (
    <Box mt={16} className={"additional-form"}>
      <NumberedTitle mb={8}>{t("Addition:")}</NumberedTitle>

      <Grid
        labelContent={t("Addition for welfare specialist staff placement")}
        background
        labelSpan={6}
        className={"addition-grid"}
      >
        <Form.Item
          name={"welfare_specialist_staff_placement_addition"}
          id={"welfare_specialist_staff_placement_addition"}
        >
          <RadioGroup options={WELFARE_SPECIALIST} />
        </Form.Item>
      </Grid>

      {/*  Service type Employment type B | 2 OR A | 1*/}
      {(serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_2 ||
        serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_1) && (
        <>
          <Grid
            labelContent={t("Employment transition support system addition")}
            background
            labelSpan={6}
            className={
              "employement_transition_support_addition_grid addition-grid"
            }
          >
            <Flex align={"start"} vertical>
              <Flex align={"center"} gap={"10px 24px"} wrap={"wrap"}>
                <Form.Item
                  name={"employement_transition_support_addition"}
                  id={"employement_transition_support_addition"}
                >
                  <RadioGroup options={CONFIRMATION_OPTIONS} />
                </Form.Item>
                <span className={"automation-text"}>
                  {t(
                    "*Number of users who continued working for 6 months in the previous fiscal year {{transition}} people",
                    {
                      transition: cachedStatusData?.transision_count,
                    },
                  )}
                </span>
              </Flex>

              {cachedStatusData?.transision_count > 0 && (
                <Red>
                  {t(
                    "*You are able to get this addition because there are more than 1 employee who continued working for six months in the previous fiscal year.",
                  )}
                </Red>
              )}
            </Flex>
          </Grid>

          <SeverallyILLWrapper>
            <Grid
              labelContent={t("Severely ill person support addition")}
              background
              labelSpan={6}
              className={"severly_ill_support_addition_grid addition-grid"}
            >
              <Form.Item
                name={"severly_ill_support_addition"}
                id={"severly_ill_support_addition"}
              >
                <RadioGroup options={ILLNESS_SPECTRUM_OPTION} />
              </Form.Item>
              <div className={"severly-ill-warning"}>
                <span className={"warning-text"}>
                  {t(
                    "Percentage of people with severe disabilities in the previous year: {{severly_ill_percent}} %",
                    {
                      severly_ill_percent:
                        cachedStatusData?.severly_ill_percent,
                    },
                  )}
                </span>
                <span className={"warning-text"}>
                  {cachedStatusData?.severly_ill_percent > 25 &&
                    cachedStatusData?.severly_ill_percent <= 50 &&
                    t(
                      "*Since the utilization rate in the previous year exceeded 25%, the 'Severely ill Persons Support addition I' can be calculated.",
                    )}
                  {cachedStatusData?.severly_ill_percent > 50 &&
                    t(
                      "*Since the utilization rate in the previous year exceeded 50%, the 'Severely ill Person Support addition II' can be calculated.",
                    )}
                </span>
              </div>
            </Grid>
          </SeverallyILLWrapper>
        </>
      )}

      {/* Service type Employment type A | 1*/}
      {serviceTypeValue === 1 && (
        <>
          <Grid
            labelContent={t(
              "Wage improvement achievement instructor placement addition",
            )}
            background
            labelSpan={6}
            className={
              "wage_improvement_instructor_placement_addition_grid addition-grid"
            }
          >
            <Form.Item
              name={"wage_improvement_instructor_placement_addition"}
              id={"wage_improvement_instructor_placement_addition"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      {/* Service type Employment type B | 2*/}
      {serviceTypeValue === SERVICE_TYPE_VALUES.TYPE_2 && (
        <>
          <Grid
            labelContent={t("Target wage achievement instructor addition")}
            background
            labelSpan={6}
            className={
              "target_wage_achievement_instructor_addition_grid addition-grid"
            }
          >
            {staffArrangement === "1" ? (
              <Form.Item
                name={"target_wage_achievement_instructor_addition"}
                id={"target_wage_achievement_instructor_addition"}
              >
                <RadioGroup options={CONFIRMATION_OPTIONS} />
              </Form.Item>
            ) : (
              // disabled view
              <Form.Item>
                <RadioGroup
                  options={CONFIRMATION_OPTIONS}
                  disabled={true}
                  value={"0"}
                />
              </Form.Item>
            )}
          </Grid>

          <Grid
            labelContent={t("Target wage achievement addition")}
            background
            labelSpan={6}
            className={"target_wage_achievement_addition_grid addition-grid"}
          >
            <Form.Item
              name={"target_wage_achievement_addition"}
              id={"target_wage_achievement_addition"}
            >
              <RadioGroup
                options={CONFIRMATION_OPTIONS}
                disabled={targetWageInstructorAddition != "1"}
              />
            </Form.Item>
          </Grid>
        </>
      )}

      <Grid
        labelContent={t(
          "Welfare/nursing care staff treatment improvement addition",
        )}
        background
        labelSpan={6}
      >
        <Form.Item
          name={"nursing_staff_treatment_improvement_addition"}
          id={"nursing_staff_treatment_improvement_addition"}
        >
          <RadioGroup options={COMBINED_OPTIONS} />
        </Form.Item>
      </Grid>

      <Grid
        labelContent={t("Transportation addition")}
        background
        labelSpan={6}
      >
        <Form.Item name={"transport_addition"} id={"transport_addition"}>
          <RadioGroup
            options={TRANSPORT_ADDITION_OPTION}
            onChange={(e) => {
              if (e.target.value == TRANSPORT_ADDITION_VALUES.NO) {
                form.setFieldValue("link_transport_with_attendance", false)
              }
            }}
          />
        </Form.Item>
      </Grid>
      {transportAddition != TRANSPORT_ADDITION_VALUES.NO && (
        <Grid
          labelContent={t(
            "Link entry/exit records with transportation records",
          )}
          background
          labelSpan={6}
        >
          <Form.Item
            name={"link_transport_with_attendance"}
            id={"link_transport_with_attendance"}
            valuePropName={"checked"}
            noStyle
          >
            <CheckBox label={t("Link")} />
          </Form.Item>
        </Grid>
      )}
      <Grid labelContent={t("Meal addition")} background labelSpan={6}>
        <Form.Item name={"meal_addition"} id={"meal_addition"}>
          <RadioGroup options={CONFIRMATION_OPTIONS} />
        </Form.Item>
      </Grid>

      {/* Service type Employment type C | 3*/}
      {serviceTypeValue === 3 && (
        <>
          <Grid
            labelContent={t(
              "Employment support related training completion addition",
            )}
            background
            labelSpan={6}
          >
            <Form.Item
              name={"support_training_completion_addition"}
              id={"support_training_completion_addition"}
            >
              <RadioGroup options={CONFIRMATION_OPTIONS} />
            </Form.Item>
          </Grid>
        </>
      )}

      <Grid
        labelContent={t(
          "Support system for people with visual and auditory language disabilities addition",
        )}
        background
        labelSpan={6}
      >
        <Form.Item
          name={"language_disability_addition"}
          id={"language_disability_addition"}
        >
          <RadioGroup options={V_AND_A_DISABILITY_OPTION} />
        </Form.Item>
      </Grid>

      {serviceTypeValue !== 3 && (
        <Grid
          labelContent={t(
            "People with higher brain dysfunction support addition",
          )}
          background
          labelSpan={6}
        >
          <Form.Item
            name={"brain_dysfunction_support_addition"}
            id={"brain_dysfunction_support_addition"}
          >
            <RadioGroup options={CONFIRMATION_OPTIONS} />
          </Form.Item>
        </Grid>
      )}
    </Box>
  )
}
