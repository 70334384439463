import { theme } from "@project/common"
import { Flex } from "antd"
import styled, { css } from "styled-components"

type Props = {
  $active?: boolean
}
const COMMON = css`
  text-align: left;
  padding: 10px;
  .ant-typography {
    margin-bottom: 0.1em !important;
  }
`

export const StyledFacilityWrapper = styled(Flex)<Props>`
  ${COMMON};
  background-color: ${(props) =>
    props?.$active ? theme.colors["open-menu"] : theme.colors.white};
  border-radius: ${(props) => (props?.$active ? "8px" : 0)};
`

export const StyledContainerWrapper = styled(Flex)<Props>`
  position: relative;
  z-index: 0;
  pointer-events: ${(props) => (!props?.$active ? "none" : "auto")} !important;
  ${(props) =>
    !props?.$active &&
    css`
      &:after {
        content: " ";
        position: absolute;
        z-index: 1;
        background-color: rgba(215, 215, 215, 0.8);
        inset: 0;
      }
    `}
`
export const StyledContentWrapper = styled(Flex)<Props>`
  ${COMMON};
  background-color: ${(props) =>
    props?.$active ? theme.colors["open-menu"] : "transparent"};
  border-radius: ${(props) => (props?.$active ? "8px" : 0)};
`
