import { useRouter } from "next/router"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import { useQuery } from "react-query"
import {
  AccordionCard,
  Button,
  Card,
  InputField,
  LabelTextWithLeftArrow,
  LinkButton,
  Table,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import { JointFacilityTableDataType } from "../../types"
import { fetchJointFacility } from "../../services"
import {
  CellWrapper,
  JointFacilityContainer,
  JointFacilitySearchContainer,
} from "./jointFacility.style"
import { PAGE_SIZE } from "../../constants"

const JointFacility = ({
  hasWriteAccess = false,
}: {
  hasWriteAccess: boolean
}): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation()
  const { page, keyword } = router?.query
  const [updateParams] = useUpdateSearchParams()
  const listRef = useRef<HTMLDivElement>(null)
  const [searchKeyword, setSearchKeyword] = useState(keyword || "")
  const [tempSearchKeyword, setTempSearchKeyword] = useState(keyword || "")
  const [currentPage, setCurrentPage] = useState(+page || 1)

  const handlePaginationChange = (page: number) => {
    const params = {
      page,
      keyword: tempSearchKeyword,
    }
    updateParams(removeBlankAttributes(params), "/joint-facility")
    setCurrentPage(page)
  }

  const columns: ColumnsType<JointFacilityTableDataType> = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 107,
      render: (_, data) => {
        const { id } = data
        return (
          <LinkButton
            disabled={!hasWriteAccess}
            onClick={() => router.push(`joint-facility/edit/${id}`)}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },

    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Joint Facility Name")}</span>
      ),
      align: "center",
      render: (_, data) => {
        return `${data?.facility_name}` + `${data?.facility_name_furigana}`
      },
      width: 225,
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Service Provided")}</span>
      ),
      align: "center",
      width: 225,
      render: (_, row: any) => {
        return (
          <CellWrapper>
            {row?.service_type01 ? (
              <div
                style={{
                  background: `${row?.service_type01 ? "#FFFDF3" : ""} 
          `,
                  color: `${row?.service_type01 ? "#AF8900" : ""} 
        `,
                  border: `${row?.service_type01 ? "1px solid #CDA001" : ""} 
    `,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  marginRight: "0px",
                  width: "149px",
                }}
              >
                {t("Employment Type A")}
              </div>
            ) : null}
            {row?.service_type02 ? (
              <div
                style={{
                  background: `${row?.service_type02 ? "#F2FFF7" : ""} 
          `,
                  color: `${row?.service_type02 ? "#4CB476" : ""} 
        `,
                  border: `${row?.service_type02 ? "1px solid #4CB476" : ""} 
    `,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  marginRight: "0px",
                  width: "149px",
                }}
              >
                {t("Employment Type B")}
              </div>
            ) : null}
            {row?.service_type03 ? (
              <div
                style={{
                  background: `${row?.service_type03 ? "#EFF9FF" : ""} 
          `,
                  color: `${row?.service_type03 ? "#0782C8" : ""} 
        `,
                  border: `${row?.service_type03 ? "1px solid #0782C8" : ""} 
    `,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  marginRight: "0px",
                  width: "149px",
                }}
              >
                {t(`Employment Transition Support`)}
              </div>
            ) : null}
            {row?.other_service_type ? (
              <div
                style={{
                  background: `${row?.other_service_type ? "#EFF9FF" : ""} 
          `,
                  color: `${row?.other_service_type ? "#0782C8" : ""} 
        `,
                  border: `${
                    row?.other_service_type ? "1px solid #0782C8" : ""
                  } 
    `,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  marginRight: "0px",
                  width: "149px",
                }}
              >
                {t(`Other`)}
              </div>
            ) : null}
          </CellWrapper>
        )
      },
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Office Number")}</span>,
      dataIndex: "facility_number",
      key: "facility_number",
      align: "center",
      width: 152,
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Address")}</span>,
      dataIndex: "address",
      key: "address",
      align: "center",
    },

    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Telephone number")}</span>
      ),
      dataIndex: "tel_no",
      key: "tel_no",
      align: "center",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("FAX Number")}</span>,
      dataIndex: "fax_no",
      key: "fax_no",
      align: "center",
    },
  ]

  const {
    isLoading,
    isFetching,
    data: jointFacilityData,
  } = useQuery(
    ["jointFamilyData", currentPage, searchKeyword],
    fetchJointFacility,
    {
      refetchOnWindowFocus: false,
      select: (response: any) => {
        return {
          data: response?.data?.map((data) => {
            return {
              ...data,
              facility_name: `${data?.facility_name}`,
              facility_name_furigana:
                data?.facility_name_furigana &&
                ` (${data?.facility_name_furigana})`,
            }
          }),
          count: response?.count,
        }
      },
    },
  )

  const handleSearch = () => {
    setCurrentPage(1)
    setSearchKeyword(tempSearchKeyword)
    updateParams({ keyword: tempSearchKeyword }, "/joint-facility")
    scrollToSelectedElement(listRef)
  }

  const handleSearchReset = () => {
    setSearchKeyword("")
    setTempSearchKeyword("")
    setCurrentPage(1)
    updateParams({}, "/joint-facility")
    scrollToSelectedElement(listRef)
  }

  return (
    <JointFacilityContainer>
      <AccordionCard
        defaultActiveKey={["joint-facility"]}
        items={[
          {
            key: "joint-facility",
            label: t("Operation master"),
            children: (
              <JointFacilitySearchContainer>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Keyword")}
                    width={"200px"}
                  />
                  <InputField
                    size={"large"}
                    placeholder={t(
                      "Facility name・Facility number・Address ・Phone number・Furigana",
                    )}
                    width={"500px"}
                    borderRadius={"5px"}
                    value={tempSearchKeyword || ""}
                    onChange={({ target: { value } }) =>
                      setTempSearchKeyword(value)
                    }
                  />
                </div>
                <div className={"search__content btns"}>
                  <Button
                    btnText={t("Search")}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    iconType={"search"}
                    onClick={handleSearch}
                  />
                  <Button
                    btnText={t("Reset Search")}
                    size={"large"}
                    shape={"round"}
                    onClick={handleSearchReset}
                  />
                </div>
              </JointFacilitySearchContainer>
            ),
          },
        ]}
      />
      <div ref={listRef}>
        <Card title={t("List")}>
          <Table
            dataSource={jointFacilityData?.data || []}
            columns={columns}
            pageSize={PAGE_SIZE}
            loading={isLoading || isFetching}
            current={+currentPage}
            total={jointFacilityData?.count || 0}
            scroll={{ x: "900px" }}
            onChange={handlePaginationChange}
            showPaginationOf={"both"}
            className={"joint-family-table always-present-scrollbar"}
          />
        </Card>
      </div>
    </JointFacilityContainer>
  )
}

export { JointFacility }
