import { theme } from "@project/common"
import { Flex } from "antd"
import styled from "styled-components"

export const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 405px;
  min-width: 200px;
  width: 50%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
  .block {
    display: grid;
    place-content: center;
    width: 100%;
    height: 196px;
    background-color: ${theme.colors["action-light-01"]};
    border: 1px solid ${theme.colors["action-light"]};
    border-radius: 12px;
    box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.29);
    margin-bottom: 32px;
    cursor: pointer;
    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .children {
    margin-bottom: 8px;
  }
`

export const ControllerFlex = styled(Flex)`
  padding-block: 8px;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`
