import {
  ActionBtns,
  Button,
  Card,
  CheckBox,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FileUploader,
  Grid,
  InputAreaField,
  InputField,
  MINUTES_HOUR,
  MINUTES_TIMES,
  SelectInput,
  Tag,
  scrollToFirstErrorField,
  theme,
} from "@project/common"
import { Popconfirm, Skeleton } from "antd"
import dayjs from "dayjs"
import { FieldArray, FormikProvider, useFormik } from "formik"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import { getActiveUser } from "../../../services"
import {
  getCreatorAndConferenceParticipants,
  getFacility,
  getMinutesNumberOfCreated,
} from "../../../services/minutesOfMeetingOfPic"
import { MinutesOfMeetingOfPicValidationSchema } from "../../../types"
import { filterWithHiragana } from "../../../utils"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import {
  MinutesOfMeetingOfPicStyledForm,
  StartTimeWrapper,
  StyleFieldArray,
} from "./MinutesOfMeetingOfPic.style"
interface AddEditFormProps {
  onSubmit?: (values: any) => void
  isLoading?: boolean
  handleDelete?: () => void
  id?: string
  isEdit?: boolean
  defaultValues?: any | undefined
  showDeleteButton?: boolean
  isDeleteLoading?: boolean
}

const MinutesOfMeetingOfPicForm = ({
  isLoading,
  onSubmit,
  defaultValues,
  id,
  isEdit,
  showDeleteButton,
  isDeleteLoading,
  handleDelete,
}: AddEditFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { c_id } = router.query as any
  const [startChar, setStartChar] = useState(null)
  const [selectedUser, setSelectedUser] = useState<any>(c_id || null)
  const [startCharCreator, setStartCharCreator] = useState(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isDraftLoading, setIsDraftLoading] = useState<boolean>(false)
  const [isAlreadyExist, setIsAlreadyExist] = useState<boolean>(false)
  const [file, setFile] = useState(defaultValues?.MinuteUploadPdf || null)
  const [fileName, setFileName] = useState(
    defaultValues?.minute_upload_pdf_name || null,
  )
  const [startTime, setStartTime] = useState({
    hr: defaultValues?.start_time?.hr || "",
    min: defaultValues?.start_time?.min || "",
  })
  const [endTime, setEndTime] = useState({
    hr: defaultValues?.end_time?.hr || "",
    min: defaultValues?.end_time?.min || "",
  })
  const [creatorCount, setCreatorCount] = useState(
    defaultValues?.creator_count || 1,
  )
  const [creatorID, setCreatorID] = useState(defaultValues?.staff_id || null)
  const [conferenceParticipation, setConferenceParticipation] = useState(
    defaultValues?.attendance?.map((object) => +object.staff_id) || [],
  )
  const [absentFacility, setAbsentFacility] = useState(
    defaultValues?.absent?.map((object) => +object.staff_id) || [],
  )
  const [minutes, setMinutes] = useState(defaultValues?.minutes || "")
  const [memo, setMemo] = useState(defaultValues?.memo || "")
  const [startDate, setStartDate] = useState(
    defaultValues?.start_date || dayjs().format("YYYY-MM-DD"),
  )
  const [reportCreateDate, setReportCreateDate] = useState(
    defaultValues?.report_create_date || dayjs().format("YYYY-MM-DD"),
  )
  // Get Active Users
  const { data: activeUser, isLoading: isActiveUserLoading } = useQuery({
    queryKey: ["active_users", selectedUser || defaultValues?.user_id],
    queryFn: () => getActiveUser(selectedUser || defaultValues?.user_id),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    enabled: !!selectedUser || !!defaultValues?.user_id,
  })

  const initialValues = {
    draft_save: false,
    start_date: startDate,
    report_create_date: reportCreateDate,
    start_time: startTime,
    end_time: endTime,
    facility_id:
      defaultValues?.facility_id ||
      activeUser?.data?.active_contract?.facility_id ||
      null,
    service_type:
      defaultValues?.service_type ||
      activeUser?.data?.user_certificate?.service_type ||
      "1",
    conference_participation_facilities: conferenceParticipation,
    user_id: c_id || defaultValues?.user_id?.toString() || selectedUser || null,
    creator_id: creatorID,
    create_count: creatorCount,
    minutes: minutes,
    memo: memo,
    absent_facility: absentFacility,
    minute_upload_pdf: file,
    minute_upload_pdf_name: fileName,
    attendance_text: [
      {
        attendance_text: "",
      },
    ],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: MinutesOfMeetingOfPicValidationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        user_id: +values.user_id,
        start_date: dayjs(values?.start_date).format("YYYY-MM-DD"),
        service_type: +values?.service_type || 0,
        report_create_date: dayjs(values?.report_create_date).format(
          "YYYY-MM-DD",
        ),
        start_time:
          values.start_time.hr && values.start_time.min
            ? `${values.start_time.hr}:${values.start_time.min}`
            : "",
        end_time:
          values.end_time.hr && values.end_time.min
            ? `${values.end_time.hr}:${values.end_time.min}`
            : "",
        staff_id: values?.creator_id,
        attendance: values.conference_participation_facilities.map((data) =>
          String(data),
        ),
        create_count: +values.create_count,
        absent: filteredStaff.map((staff) => {
          return String(staff?.value)
        }),
        attendance_text: values.attendance_text.map(
          (item: any) => item?.attendance_text,
        ),
        minute_upload_pdf: file,
        minute_upload_pdf_name: fileName,
      }

      onSubmit(data)
    },
  })
  const attendanceTextVar = []

  if (defaultValues?.attendance_text) {
    defaultValues?.attendance_text
      .split(",")
      .forEach((str: string) =>
        attendanceTextVar.push({ attendance_text: str }),
      )
    initialValues.attendance_text = attendanceTextVar
  }

  useEffect(() => {
    setFileName(defaultValues?.minute_upload_pdf_name)
  }, [defaultValues?.minute_upload_pdf_name])

  // All Users Data
  const { userData: allUsers, isLoading: isUserLoading } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })

  //  Creator and Conference Participants
  const { data: Creator_Conference_Participants } = useQuery(
    ["get_creator_and_conference_participants", formik.values?.facility_id],
    getCreatorAndConferenceParticipants,
    {
      cacheTime: 60 * 1000,
      retry: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!selectedUser || !!formik.values.facility_id,
      select: (data: any) => {
        const staffs = data?.data.map((staff) => ({
          label: staff?.staff_name,
          value: staff?.id,
          furigana: staff?.staff_name_furiganaame,
        }))
        return staffs
      },
    },
  )

  const filteredStaff = Creator_Conference_Participants?.filter(
    (facilities) =>
      !formik.values?.conference_participation_facilities?.includes(
        facilities?.value,
      ),
  )
  // GET All Facility
  const {
    data: facilityOptions,
    isLoading: isFacilityLoading,
    isFetching: isFacilityFetching,
  } = useQuery({
    queryKey: ["getAllFacility"],
    queryFn: () => getFacility(),
    cacheTime: 0,
    enabled: !!id || !!activeUser,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (resp) => {
      const filteredOptions = resp.data
        ?.filter(
          (res) => res.id === activeUser?.data?.active_contract?.facility_id,
        )
        .map((res) => ({
          label: res?.facility_name,
          value: res?.id,
        }))

      return filteredOptions
    },
  })

  useEffect(() => {
    if (facilityOptions?.length > 0) {
      formik.setFieldValue("facility_id", facilityOptions[0]?.value)
    }
  }, [facilityOptions])

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  //fetch number of created times only of  selected child when selected child change
  const { data: numberOfCreated, isLoading: isNumberOfCreatedLoading } =
    useQuery({
      queryKey: ["number_of_created", formik?.values?.user_id],
      queryFn: () =>
        getMinutesNumberOfCreated(selectedUser || defaultValues?.user_id),
      enabled: !!selectedUser || !!defaultValues?.user_id,
      refetchOnWindowFocus: false,
      select: (response) => {
        return {
          no_of_created: response?.data?.no_of_created,
        }
      },

      onSuccess: (data) => {
        if (!isEdit) {
          if (data?.no_of_created?.length > 0) {
            setCreatorCount(data?.no_of_created[0] + 1)
          } else {
            setCreatorCount(1)
          }
        }
      },
    })

  useEffect(() => {
    if (!isEdit && !isActiveUserLoading && !isNumberOfCreatedLoading) {
      if (numberOfCreated?.no_of_created?.length > 0) {
        setCreatorCount(numberOfCreated?.no_of_created[0] + 1)
      } else {
        setCreatorCount(defaultValues?.create_count || 1)
      }
    }
    if (isEdit && id) {
      setCreatorCount(defaultValues?.create_count || 1)
    }
  }, [numberOfCreated, activeUser])

  return (
    <>
      <Card
        title={t("Minutes of Service Personnel Meetings and Support Meetings")}
        id={"minutes"}
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <MinutesOfMeetingOfPicStyledForm onSubmit={formik.handleSubmit}>
            <div className={"form__control"}>
              <Grid
                labelContent={t("Event date")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <div className={"start_date"}>
                  <DatePicker
                    name={"start_date"}
                    onDateChange={(date) => {
                      const dateValue = dayjs(date).format("YYYY-MM-DD")
                      setStartDate(dateValue)
                      formik.setFieldValue("start_date", dateValue)
                    }}
                    date={
                      dayjs(formik.values.start_date).isValid()
                        ? dayjs(formik.values?.start_date)
                        : null
                    }
                    format={"YYYY/MM/DD"}
                    error={
                      formik.errors?.start_date && formik.touched?.start_date
                        ? `${formik.errors?.start_date}`
                        : ""
                    }
                  />
                </div>
              </Grid>

              <Grid
                labelContent={t("Created date")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <div className={"created_date"}>
                  <DatePicker
                    name={"created_date"}
                    onDateChange={(date) => {
                      const dateValue = dayjs(date).format("YYYY-MM-DD")
                      setReportCreateDate(dateValue)
                      formik.setFieldValue("report_create_date", dateValue)
                    }}
                    date={
                      dayjs(formik.values.report_create_date).isValid()
                        ? dayjs(formik.values?.report_create_date)
                        : null
                    }
                    format={"YYYY/MM/DD"}
                    error={
                      formik.touched?.report_create_date &&
                      formik.errors?.report_create_date
                        ? formik.errors?.report_create_date
                        : ""
                    }
                  />
                </div>
              </Grid>

              <Grid
                labelContent={t("Start time")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <StartTimeWrapper>
                  <div className={"start_time"}>
                    <SelectInput
                      name={"start_time"}
                      options={MINUTES_HOUR}
                      width={"58px"}
                      placeholder={t("--")}
                      value={formik.values.start_time.hr}
                      onBlur={() =>
                        formik.setFieldTouched("start_time", true) as any
                      }
                      onChange={(value) => {
                        const startTimeVal = {
                          hr: value,
                          min:
                            value === ""
                              ? ""
                              : !formik.values.start_time.min
                                ? "00"
                                : formik.values.start_time.min,
                        }
                        setStartTime(startTimeVal)
                        formik.setFieldValue("start_time", startTimeVal)
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      options={MINUTES_TIMES}
                      name={"start_time"}
                      width={"58px"}
                      placeholder={t("--")}
                      value={formik.values.start_time.min}
                      onBlur={() =>
                        formik.setFieldTouched("start_time", true) as any
                      }
                      onChange={(value) => {
                        const startTimeVal = {
                          hr: formik.values.start_time.hr,
                          min: value,
                        }
                        setStartTime(startTimeVal)
                        formik.setFieldValue("start_time", startTimeVal)
                      }}
                      disabled={formik.values.start_time.hr === ""}
                    />
                  </div>
                  <div>{"~"}</div>
                  <div className={"start_time"}>
                    <SelectInput
                      name={"start_time"}
                      options={MINUTES_HOUR}
                      width={"58px"}
                      placeholder={t("--")}
                      value={formik.values.end_time.hr}
                      onChange={(value) => {
                        const endTimeVal = {
                          hr: value,
                          min:
                            value === ""
                              ? ""
                              : !formik.values.end_time.min
                                ? "00"
                                : formik.values.end_time.min,
                        }
                        setEndTime(endTimeVal)
                        formik.setFieldValue("end_time", endTimeVal)
                      }}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      name={"end_time"}
                      onBlur={() =>
                        formik.setFieldTouched("end_time.min", true) as any
                      }
                      options={MINUTES_TIMES}
                      width={"58px"}
                      placeholder={t("--")}
                      value={formik.values.end_time?.min}
                      onChange={(value) => {
                        const endTimeVal = {
                          hr: formik.values.end_time?.hr,
                          min: value,
                        }
                        setEndTime(endTimeVal)
                        formik.setFieldValue("end_time", endTimeVal)
                      }}
                      disabled={formik.values.end_time.hr === ""}
                    />
                  </div>
                </StartTimeWrapper>
              </Grid>

              <Grid
                labelContent={t("User name")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <div style={{ display: "flex", gap: "16px" }}>
                  <SelectInput
                    name={""}
                    options={FuriganaAlphabetsOption}
                    width={"60px"}
                    placeholder={t("--")}
                    value={startChar}
                    onChange={setStartChar}
                  />
                  {!isUserLoading && (
                    <SelectInput
                      name={"user_id"}
                      width={"204px"}
                      placeholder={t("--")}
                      options={filterWithHiragana({
                        options: allUsers,
                        furigana: startChar,
                      })}
                      onBlur={() =>
                        formik.setFieldTouched("user_id", true) as any
                      }
                      value={formik.values?.user_id?.toString()}
                      onChange={(val) => {
                        formik.setFieldValue("user_id", val)
                        setSelectedUser(val)
                      }}
                      disabled={isEdit}
                      error={
                        formik.touched.user_id && formik.errors.user_id
                          ? t(`${formik.errors?.user_id}`)
                          : ""
                      }
                    />
                  )}
                </div>
              </Grid>

              <Grid
                labelContent={t("Facility name")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                {!isFacilityLoading || !isFacilityFetching ? (
                  <>
                    {formik.values.user_id ? (
                      facilityOptions?.length == 0 ? (
                        <div style={{ textAlign: "left" }}>
                          {t("*Select user first2")} <br />
                          <Link
                            href={`/users/profile/${formik?.values?.user_id}/recipient-certificate-form`}
                          >
                            <a
                              target={"_blank"}
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              {"▶"}
                              {t("Confirm the contracted amount")}
                            </a>
                          </Link>
                        </div>
                      ) : (
                        <div className={"field-wrapper"}>
                          <SelectInput
                            name={"facility_id"}
                            options={facilityOptions || []}
                            id={"facility"}
                            value={Number(formik.values?.facility_id) || null}
                            onBlur={() =>
                              formik.setFieldTouched("facility_id", true) as any
                            }
                            onChange={(v) => {
                              formik.setFieldValue("facility_id", v)
                            }}
                            loading={isFacilityLoading || isActiveUserLoading}
                          />
                        </div>
                      )
                    ) : (
                      <span>{t("*Select user first2")}</span>
                    )}
                  </>
                ) : (
                  <span>{t("*Select user first2")}</span>
                )}
              </Grid>
              <Grid
                labelContent={t("Service using")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                {formik.values?.user_id && formik.values?.facility_id ? (
                  !isFacilityLoading && facilityOptions?.length > 0 ? (
                    <SelectInput
                      name={"service_type"}
                      options={FACILITY_TYPES}
                      defaultValue={1}
                      value={+formik.values?.service_type || 1}
                      onChange={(v) => formik.setFieldValue("service_type", v)}
                    />
                  ) : (
                    <div style={{ textAlign: "left" }}>
                      {t(
                        "Since there is no contract for all facilities, it is not possible to select the service to be used",
                      )}
                      <br />
                      <Link
                        href={`/users/profile/${formik?.values?.user_id}/recipient-certificate-form`}
                      >
                        <a
                          target={"_blank"}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {"▶"}
                          {t("Confirm the contracted amount")}
                        </a>
                      </Link>
                    </div>
                  )
                ) : (
                  <span>{t("Select the facility first")}</span>
                )}
              </Grid>
              <Grid
                labelContent={t("No. created")}
                className={"row__header "}
                background
                labelSpan={6}
                required
              >
                <div className={"create_count"}>
                  {isAlreadyExist && (
                    <span className={"alert"}>
                      {t("Number of times already created")}
                    </span>
                  )}
                  <div className={"content"}>
                    <InputField
                      name={"create_count"}
                      width={"204px"}
                      height={"40px"}
                      min={0}
                      type={"number"}
                      disabled={isNumberOfCreatedLoading}
                      value={formik.values.create_count}
                      onChange={(e) => {
                        if (
                          numberOfCreated?.no_of_created.includes(
                            +e?.target?.value,
                          )
                        ) {
                          setIsAlreadyExist(true)
                        } else {
                          setIsAlreadyExist(false)
                        }
                        setCreatorCount(e.target.value)
                        formik.setFieldValue("create_count", e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      onWheel={(event: any) => event.target.blur()}
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault()
                        }
                      }}
                      error={
                        formik.touched.create_count &&
                        formik.errors.create_count
                          ? t(`${formik.errors?.create_count}`)
                          : ""
                      }
                    />
                    <span>{t("Times")}</span>
                  </div>
                </div>
              </Grid>
              <Grid
                labelContent={t("Creator")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <div style={{ display: "flex", gap: "16px" }}>
                  {formik?.values?.facility_id != null || undefined || "" ? (
                    <>
                      <SelectInput
                        name={""}
                        options={FuriganaAlphabetsOption}
                        value={startCharCreator}
                        onChange={setStartCharCreator}
                        style={{ width: "120px" }}
                        className={"start-char"}
                        placeholder={"--"}
                      />
                      <div className={"field-wrapper"}>
                        <SelectInput
                          name={"creator_id"}
                          style={{ width: "350px" }}
                          options={
                            startCharCreator
                              ? Creator_Conference_Participants?.filter(
                                  (creator) =>
                                    creator.furigana.startsWith(
                                      startCharCreator,
                                    ),
                                )
                              : Creator_Conference_Participants
                          }
                          size={"large"}
                          value={formik.values?.creator_id}
                          onChange={(val) => {
                            setCreatorID(val)
                            formik.setFieldValue("creator_id", val)
                          }}
                          onBlur={formik?.handleBlur}
                          id={"creator_id"}
                          error={
                            formik.touched.creator_id &&
                            formik.errors.creator_id
                              ? t(`${formik.errors?.creator_id}`)
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <span>{t("Select facility first")}</span>
                  )}
                </div>
              </Grid>
              <Grid
                labelContent={t("Conference participants")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                  }}
                >
                  <SelectInput
                    name={"conference"}
                    width={"100%"}
                    placeholder={t("--")}
                    value={formik.values.conference_participation_facilities.map(
                      (facilityId) => Number(facilityId),
                    )}
                    options={Creator_Conference_Participants}
                    mode={"multiple"}
                    tagRender={(props) => {
                      return (
                        <div className={"tags-wrapper"}>
                          <Tag
                            textColor={"#191919"}
                            className={"child-tag"}
                            closeIcon={<span>{`${props.label} ✖`} </span>}
                            onClose={() => {
                              const currentVal = props.value
                              const filtered =
                                formik.values?.conference_participation_facilities?.filter(
                                  (e) => e !== currentVal,
                                )
                              setConferenceParticipation(filtered)
                            }}
                            closable
                          >
                            {props.label}
                          </Tag>
                        </div>
                      )
                    }}
                    dropdownRender={() => {
                      return (
                        <div>
                          <CheckBoxGroup
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className={"conference-participation-facilities"}
                            value={
                              formik.values?.conference_participation_facilities
                            }
                            items={Creator_Conference_Participants}
                            onChange={(value) => {
                              setConferenceParticipation(value)
                              formik.setFieldValue(
                                "conference_participation_facilities",
                                value,
                              )
                            }}
                          />
                        </div>
                      )
                    }}
                    onBlur={() =>
                      formik.setFieldTouched(
                        "conference_participation_facilities",
                        true,
                      )
                    }
                    error={
                      formik.touched.conference_participation_facilities &&
                      formik.errors.conference_participation_facilities
                        ? t(
                            `${formik.errors?.conference_participation_facilities}`,
                          )
                        : ""
                    }
                  />

                  <FormikProvider value={formik}>
                    <FieldArray
                      name={"attendance_text"}
                      render={(arrayHelpers) => (
                        <StyleFieldArray>
                          {formik.values.attendance_text?.map(
                            (object: any, index: number) => {
                              return (
                                <div className={"input_container"} key={index}>
                                  <InputField
                                    name={`attendance_text[${index}].attendance_text`}
                                    id={`attendance_text[${index}].attendance_text`}
                                    value={object?.attendance_text}
                                    width={"500px"}
                                    height={"40px"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={"--"}
                                  />

                                  <div className={"btn_content"}>
                                    {formik.values.attendance_text?.length -
                                      1 ===
                                    index ? (
                                      <>
                                        {formik.values.attendance_text
                                          ?.length <= 19 ? (
                                          <Button
                                            btnText={t("Add")}
                                            shape={"round"}
                                            onClick={() =>
                                              arrayHelpers.push({
                                                attendance_text: "",
                                              })
                                            }
                                            btnBg={theme.colors.disabled}
                                            borderColor={theme.colors.border}
                                            textColor={theme.colors.text}
                                          />
                                        ) : (
                                          <Button
                                            btnText={t("Delete")}
                                            type={"text"}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            textColor={theme.colors.error}
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <div className={"delete-btn"}>
                                        <Button
                                          btnText={t("Delete")}
                                          type={"text"}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          textColor={theme.colors.error}
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            },
                          )}
                        </StyleFieldArray>
                      )}
                    />
                  </FormikProvider>
                </div>
              </Grid>
              <Grid
                labelContent={t("Absence")}
                className={"row__header"}
                background
                labelSpan={6}
                required
              >
                <div className={"absence_container"}>
                  <CheckBox
                    name={"absent_facility"}
                    label={t("Show absent column")}
                    value={formik.values?.absent_facility}
                    onChange={({ target: { checked } }) => {
                      setAbsentFacility(checked)
                      formik.setFieldValue("absent_facility", checked)
                    }}
                    checked={formik.values?.absent_facility}
                  />
                  <p>
                    {t(
                      "※If there is a staff  that you do not want to display in the absent column, please remove the check.",
                    )}
                  </p>
                  {formik.values.absent_facility && (
                    <>
                      <div className={"absence_content"}>
                        {filteredStaff?.map((facility) => (
                          <>
                            <span>{facility.label}</span>
                            <span className={"slash"}>{" / "}</span>
                          </>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                labelContent={t("Minutes")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"minutes"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  rows={3}
                  value={formik.values?.minutes}
                  onChange={(e) => {
                    setMinutes(e?.target?.value)
                    formik.setFieldValue("minutes", e.target.value)
                  }}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Memo")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"memo"}
                  padding={"8px"}
                  borderRadius={"4px"}
                  rows={3}
                  value={formik.values?.memo}
                  onChange={(e) => {
                    setMemo(e?.target?.value)
                    formik.setFieldValue("memo", e.target.value)
                  }}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid
                labelContent={t("Minutes upload")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <div className={"upload"}>
                  <FileUploader
                    currentImage={file}
                    onChange={(val) => setFile(val)}
                    setFileName={setFileName}
                    fileName={fileName}
                    isLoading={(value) => setIsUploading(value)}
                    inputType={"file"}
                    uploadTitle={t("Select file")}
                    uploadSubTitle={t("File not selected(PDF)")}
                    imageTypeLabel={false}
                  />
                </div>
              </Grid>
            </div>
            <ActionBtns>
              <div className={"button_wrapper"}>
                <div className={"button_content"}>
                  <Button
                    btnText={t("Cancel")}
                    shape={"round"}
                    onClick={() => router.push("/minutes")}
                    disabled={formik.isSubmitting || isLoading || isUploading}
                  />
                  <Button
                    btnText={t("Save")}
                    shape={"round"}
                    type={"primary"}
                    isLoading={formik.isSubmitting || isLoading}
                    disabled={isAlreadyExist || isUploading}
                    onClick={() => {
                      formik.handleSubmit()
                      formik.setFieldValue("draft_save", false)
                      setIsDraftLoading(false)
                    }}
                  />
                  <Button
                    btnText={t("Save as Draft")}
                    shape={"round"}
                    btnBg={theme.colors["action-400"]}
                    onClick={() => {
                      formik.setFieldValue("draft_save", true)
                      formik.submitForm()
                      setIsDraftLoading(true)
                    }}
                    disabled={isAlreadyExist || isUploading}
                    isLoading={
                      (formik.isSubmitting || isUserLoading || isLoading) &&
                      isDraftLoading
                    }
                  />
                </div>
                {showDeleteButton && (
                  <>
                    <Popconfirm
                      title={t("Deleting.Is that OK?")}
                      onConfirm={() => handleDelete()}
                      okText={t("OK")}
                      cancelText={t("Cancel")}
                      okButtonProps={{ size: "middle" }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <Button
                        btnText={t("Delete")}
                        type={"primary"}
                        shape={"round"}
                        btnBg={theme.colors["error"]}
                        color={theme.colors["white"]}
                        borderColor={"none"}
                        loading={isDeleteLoading}
                        disabled={isUploading}
                      />
                    </Popconfirm>
                  </>
                )}
              </div>
            </ActionBtns>
          </MinutesOfMeetingOfPicStyledForm>
        )}
      </Card>
    </>
  )
}
MinutesOfMeetingOfPicForm.displayname = "MinutesOfMeetingOfPicForm"
export { MinutesOfMeetingOfPicForm }
