import { ActionBtns, Button, theme, useNotification } from "@project/common"
import { Popconfirm } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { AuthContext, useBusinessDayManagement } from "../../../../context"
import { deleteBusinessDayRegistration } from "../../../../services"
import {
  BusinessListPermission,
  hasPermissionForFacility,
} from "../../../../utils/PermissionKeys"

export const ActionSection = ({
  disabled,
  loading,
  data,
  deleteBtnText,
}: {
  disabled?: boolean
  loading?: boolean
  data?: any[]
  deleteBtnText?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)
  const { handleSave, operationOptionsValues } = useBusinessDayManagement()
  const { showToast } = useNotification()
  const { isLoading: isDeleting, mutate } = useMutation({
    mutationFn: deleteBusinessDayRegistration,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Business day registration deleted successfully!"),
      })
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      showToast({
        type: "error",
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const hasDeleteAccess = useMemo(() => {
    const hasDate = data?.findIndex((item) => item.business_end_hour1 !== null)
    if (hasDate == -1) return false
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        BusinessListPermission,
        "write",
        operationOptionsValues?.params?.facility_id?.toString(),
      )
    )
  }, [permissions, isOwner, data])

  return (
    <>
      <ActionBtns>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "0.5rem",
            columnGap: "0.5rem",
          }}
        >
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            disabled={disabled || loading}
            onClick={() => router.push("/business-day-management")}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            disabled={disabled}
            type={"primary"}
            onClick={handleSave}
            isLoading={loading}
          />
        </div>
        {hasDeleteAccess && (
          <Popconfirm
            title={t("Deleting.Is that OK?")}
            okText={t("OK")}
            cancelText={t("Cancel")}
            okButtonProps={{ size: "middle" }}
            cancelButtonProps={{ size: "middle" }}
            onConfirm={() => {
              const data = {
                year: dayjs(operationOptionsValues?.params?.date).year(),
                month: dayjs(operationOptionsValues?.params?.date).month() + 1,
                facility_id:
                  operationOptionsValues?.params?.facility_id ||
                  router?.query?.facilityId,
              }
              mutate(data)
            }}
          >
            <Button
              btnText={deleteBtnText}
              shape={"round"}
              disabled={disabled || loading}
              isLoading={isDeleting}
              borderColor={theme.colors.error}
            />
          </Popconfirm>
        )}
      </ActionBtns>
    </>
  )
}
