import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Monitoring from "../../../../public/assets/icons/bargraph.svg"
import Assessment from "../../../../public/assets/icons/user-chat.svg"
import IndividualSupport from "../../../../public/assets/icons/user-minus-border.svg"
import ServiceMeeting from "../../../../public/assets/icons/various-additions-and-minutes.svg"
import SupportPlan from "../../../../public/assets/icons/users-resume.svg"
import { theme } from "@project/common"
const Wrapper = styled.div`
  .header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    @media (max-width: 480px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .card {
      border-radius: 12px;
      height: 125px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px 6px 0px 6px;
      border: 1px solid #d2d1d1;
      background-color: ${theme.colors.white};
      box-shadow: 0px 0px 15px 0px #0000000a;
      span {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        word-break: break-all !important;
        white-space: pre-wrap;
      }
      @media screen and (max-width: ${theme.breakpoints.xmd}) {
        padding-block: 10px;
        justify-content: center;
      }
    }
  }
`

interface IProps {
  facilityID: number
}
const RelatedInformation: React.FC<IProps> = ({ facilityID }) => {
  const { t } = useTranslation()
  const relatedInformation = [
    {
      title: t("Assessment"),
      icon: <Assessment />,
      route: `/assessment?facilityIds=${facilityID}`,
    },
    {
      title: t("Individual support plan (draft)"),
      icon: <IndividualSupport />,
      route: `/draft-individual-support-plan?facilityIds=${facilityID}`,
    },
    {
      title: t("Service personnel meeting \n Minutes of (support meeting)"),
      icon: <ServiceMeeting />,
      route: `/minutes?facility=${facilityID}`,
    },
    {
      title: t("Individual support plan"),
      icon: <SupportPlan />,
      route: `/individual-support-plan?facilityIds=${facilityID}`,
    },
    {
      title: t("Monitoring"),
      icon: <Monitoring />,
      route: `/monitoring-list?facility_ids=${facilityID}`,
    },
  ]
  return (
    <Wrapper>
      <div className={"header"}>
        {t("Related information on individual support plans")}
      </div>
      <div className={"container"}>
        {relatedInformation?.map((d, i) => {
          return (
            <Link href={d?.route} key={i}>
              <a>
                <div key={i} className={"card"}>
                  {d?.icon}
                  <span>{d?.title}</span>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}

export { RelatedInformation }
