import {
  AccordionCard,
  ActionBtns,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OpFlexContainer,
  OperationSearchContent,
  SelectInput,
  theme,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { INHIFOperation, NHIFSearchParams } from "../../types/nhif.types"
import { NHIFOperationContainer } from "./NHIFForm.styles"

export const NHIFOperation = (props: INHIFOperation) => {
  const { t } = useTranslation()
  const { searchOptions, facilityOptions, handleSearch, handleReset } = props
  const [tempSearch, setTempSearch] = useState<NHIFSearchParams>(searchOptions)

  useEffect(() => {
    setTempSearch(searchOptions)
  }, [searchOptions])

  return (
    <NHIFOperationContainer>
      <AccordionCard
        defaultActiveKey={["nhif-billing-operation"]}
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content "}>
                  <OpFlexContainer>
                    <div className={"flex__content"}>
                      <LabelTextWithLeftArrow
                        label={t("Facility")}
                        width={"150px"}
                      />
                      <SelectInput
                        name={"facility"}
                        options={facilityOptions}
                        placeholder={"--"}
                        width={"306px"}
                        value={tempSearch.facility?.toString()}
                        onChange={(val) => {
                          setTempSearch((prev) => ({ ...prev, facility: +val }))
                        }}
                      />
                    </div>
                  </OpFlexContainer>
                </div>
                <div className={"search__content"}>
                  <OpFlexContainer>
                    <div className={"flex__content"}>
                      <LabelTextWithLeftArrow
                        label={t("Year and month")}
                        width={"150px"}
                      />
                      <DatePicker
                        picker={"month"}
                        date={dayjs([tempSearch.year, tempSearch.month - 1])}
                        onDateChange={(date: Dayjs) => {
                          setTempSearch((prev) => ({
                            ...prev,
                            year: date?.year(),
                            month: date?.month() + 1,
                          }))
                        }}
                        format={"YYYY年MM月"}
                      />
                    </div>
                  </OpFlexContainer>
                </div>
                <ActionBtns justify={"none"}>
                  <Button
                    btnText={t("Reset Search")}
                    size={"large"}
                    shape={"round"}
                    borderColor={theme.colors.primary}
                    onClick={handleReset}
                  />
                  <Button
                    btnText={t("Search")}
                    size={"large"}
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    onClick={() => handleSearch(tempSearch)}
                  />
                </ActionBtns>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </NHIFOperationContainer>
  )
}
