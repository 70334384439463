import {
  ActionBtns,
  Breadcrumb,
  Button,
  Card,
  CheckBox,
  Modal,
  PageTitle,
  RadioGroup,
  ReportCard,
  USER_ATTENDANCE_VALUES,
  removeBlankAttributes,
  useUpdateSearchParams,
} from "@project/common"
import { Skeleton, Spin } from "antd"
import dayjs from "dayjs"
import { FieldArray, FormikProvider, useFormik } from "formik"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useReactToPrint } from "react-to-print"
import {
  fetchOneDailyBusinessReportList,
  getDateInfoDetail,
} from "../../../services"
import Accordion from "../Accordion"
import { DailyBusinessReportBulkPrint } from "../DailyBusinessReportBulkPrint/DailyBusinessReportBulkPrint"
import {
  ButtonContainer,
  CardWrapper,
  MainWrapper,
  PlanFormWrapper,
  SecondContentWrapper,
  Wrapper,
} from "./DailyBusinessReportViewForm.style"
import { FirstTable } from "./FirstTable"
import { MobileResponsiveView } from "./MobileView"
import { SecondTable } from "./SecondTable/SecondTable"

interface IDailyBusinessReportView {
  current_facility: any
  current_date: string | string[]
  facilities: Array<any> | null
  id?: any
  hasEditAccess: (val) => boolean
}
export const DailyBusinessReportViewForm = ({
  current_facility,
  current_date,
  facilities,
  id,
  hasEditAccess,
}: IDailyBusinessReportView): JSX.Element => {
  const { t } = useTranslation()
  const componentRef = useRef()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const isMobileScreen = useMediaQuery({ query: "(max-width: 540px)" })
  const [facility_name, setFacilityName] = useState(current_facility?.label)
  const [isView] = useState(true)
  const [selectedFacility, setSelectedFacility] = useState<
    string | null | number | string[]
  >(current_facility?.value || null)
  const [date, setDate] = useState<any>({
    from_date: current_date || null,
  })
  const [filteredGetDailyBusiness, setFilteredGetDailyBusiness] = useState<any>(
    [],
  )
  const [params, setParams] = useState<any>({
    facility_id: selectedFacility || current_facility?.value || null,
    from_date: date || current_date || null,
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [supportRecord, setSupportRecord] = useState(1)
  const [businessDiary, setBusinessDiary] = useState(false)

  //api calls

  const {
    data: dateInfo,
    //refetch,
    isLoading: dateInfoLoading,
    isFetching: dateInfoFetching,
  } = useQuery<any>(
    ["get-date-info-detail", params],
    () =>
      getDateInfoDetail({
        date: String(current_date),
        facilityIds: +selectedFacility,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 2 * 60 * 1000,
      retry: 1,
      select: ({ data }) => data,
    },
  )
  const {
    data: getOneDailyBusinessReport,
    isLoading: dailyReportByIdLoading,
    isFetching: dailyBusinessReportByIdFetching,
  } = useQuery<any, Error>(
    ["daily-business-report-list-view"],
    () =>
      fetchOneDailyBusinessReportList({
        queryKey: [
          "daily-business-report-list",
          id,
          params?.facility_id,
          { from_date: params?.from_date },
        ],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => {
        return data
      },
    },
  )

  const initialValues = {
    facility_id:
      filteredGetDailyBusiness?.facility?.facility_name ||
      current_facility?.label ||
      null,
    morning: filteredGetDailyBusiness?.morning || "",
    afternoon: filteredGetDailyBusiness?.afternoon || "",
    works:
      filteredGetDailyBusiness?.daily_business_support_works?.map(
        (work) => work.work_id,
      ) || [],
    others: filteredGetDailyBusiness?.others || "",
    staff_id: filteredGetDailyBusiness?.staff_id || null,
    date:
      current_date || filteredGetDailyBusiness?.date
        ? dayjs(filteredGetDailyBusiness?.date).format("YYYY-MM-DD")
        : current_date
          ? Array.isArray(current_date)
            ? dayjs(current_date[0]).format("YYYY-MM-DD")
            : dayjs(current_date).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
    accompanying_staff: filteredGetDailyBusiness?.accompanying_staff || null,
    daily_business_support_report:
      filteredGetDailyBusiness?.daily_business_support_report,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      null
    },
  })

  const onSearch = () => {
    const values = {
      facility_id: selectedFacility,
      date: date,
      id: id,
    }

    const params = removeBlankAttributes(values)
    setParams({
      facility_id: selectedFacility,
      from_date: date?.from_date,
    })
    updateParams(
      {
        ...params,
        date: dayjs(date?.from_date).isValid()
          ? dayjs(date?.from_date).format("YYYY-MM-DD")
          : null,
      },
      `/daily-business-report-list/view`,
    )
  }
  const resetHandler = () => {
    setDate({ from_date: null })
    setSelectedFacility(null)
    setFacilityName(null)
    setParams({ facility_id: null, from_date: null })
    updateParams({ id: id }, `/daily-business-report-list/view`)
  }

  const handlePrintPage = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print{
      margin:4mm !important;
    }`,
    onBeforePrint: () => {
      setBusinessDiary(false)
      setSupportRecord(1)
    },
    onAfterPrint: () => {
      setBusinessDiary(false)
      setSupportRecord(1)
    },
  })
  const handlePrint = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
    handlePrintPage()
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setBusinessDiary(false)
    setSupportRecord(1)
  }

  const findAttendanceInfo = (info: Record<string, any> | undefined) => {
    if (info)
      return {
        absence_reason: info?.absence_reason || "",
        absent_recorder_staff_id: info?.save_staff_id || null,
        is_absent:
          info?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
          info?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
            ? true
            : false,
        start_time: info?.start_time || "",
        end_time: info?.end_time || "",
        attendance_type: info?.attendance_type || "",
        service_type: info?.service_type,
        username: info?.user?.username,
        goto_meet_flag: info?.goto_meet_flag,
        take_flag: info?.take_flag,
      }
    return {
      absence_reason: "",
      absent_recorder_staff_id: null,
      is_absent: false,
      service_type: null,
      username: "",
    }
  }
  useEffect(() => {
    facilities.map((facility) => {
      if (facility.value == selectedFacility) setFacilityName(facility?.label)
    })
  }, [selectedFacility])

  useEffect(() => {
    const returnData = getOneDailyBusinessReport?.daily_business_support_report
      ?.map((d) => {
        const info =
          dateInfo?.attendanceInfo?.find(
            (report) => report.user_id === d?.user_id,
          ) || {}
        const a_info = findAttendanceInfo(info)
        if (d) {
          return {
            ...d,
            ...a_info,
            username: a_info?.username || d?.user?.username,
            service_type: a_info?.service_type || d?.service_type,
            start_time_hr: d?.start_time_hr
              ? `${d?.start_time_hr?.split(":")[0]?.padStart(2, "0")}`
              : "",
            start_time_min: d?.start_time_min
              ? `${d?.start_time_min?.split(":")[0]?.padStart(2, "0")}`
              : "",
            end_time_hr: d?.end_time_hr
              ? `${d?.end_time_hr?.split(":")[0]?.padStart(2, "0")}`
              : "",
            end_time_min: d?.end_time_min
              ? `${d?.end_time_min?.split(":")[0]?.padStart(2, "0")}`
              : "",
          }
        }
        return null
      })
      .filter(Boolean)
    const data = {
      ...getOneDailyBusinessReport,
      daily_business_support_report: returnData,
    }
    setFilteredGetDailyBusiness(data)
  }, [getOneDailyBusinessReport, dateInfo])

  const formName = [
    {
      label: t("Support record"),
      value: 1,
    },
    {
      label: t("Provided support record"),
      value: 2,
    },
  ]
  const breadCrumbItems = [
    {
      title: <Link href={"/"}>{t("ホーム")}</Link>,
    },
    {
      title: (
        <Link href={"/daily-business-report-list"}>
          {t("Daily business report list")}
        </Link>
      ),
    },
    {
      title: t(
        "{{engDate}} {{facilityName}} Daily business report ＆ Daily support record  list",
        {
          engDate: dayjs(date?.from_date)
            .locale("en")
            .format("YYYY/MM/DD"),
          jaDate: dayjs(date?.from_date).format("YYYY年MM月DD日"),
          year: dayjs(date?.from_date).format("YYYY年MM月DD日"),
          facilityName: facility_name,
        },
      ),
    },
  ]

  const Buttons = (
    <div style={{ margin: "8px 0px" }}>
      <ActionBtns>
        <ButtonContainer>
          <div className={"first-buttons"}>
            <Button
              btnText={t("Edit")}
              shape={"round"}
              type={"default"}
              onClick={() =>
                router.push(`/daily-business-report-list/edit/${id}`)
              }
              otherType={"draft"}
              btnBg={"#fff"}
              disabled={
                dailyReportByIdLoading ||
                !hasEditAccess(getOneDailyBusinessReport?.facility_id)
              }
            />
            <Button
              btnText={t("Print")}
              shape={"round"}
              type={"primary"}
              onClick={() => handlePrint()}
              disabled={dailyReportByIdLoading}
              iconType={"print"}
            />
          </div>
        </ButtonContainer>
      </ActionBtns>
    </div>
  )

  return (
    <>
      <Breadcrumb items={breadCrumbItems} />
      <PageTitle
        extra={Buttons}
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t(
          `{{engDate}} {{facilityName}} Daily business report ＆ Daily support record  list`,
          {
            engDate: dayjs(date?.from_date)
              .locale("en")
              .format("YYYY/MM/DD"),
            jaDate: dayjs(date?.from_date).format("YYYY年MM月DD日"),
            year: dayjs(date?.from_date).format("YYYY年MM月DD日"),
            facilityName: facility_name,
          },
        )}
      />

      <MainWrapper>
        <PlanFormWrapper>
          <>
            <Spin
              spinning={
                dailyReportByIdLoading || dailyBusinessReportByIdFetching
              }
            >
              <Wrapper businessDiary={businessDiary}>
                <>
                  <Accordion
                    onSearch={onSearch}
                    FACILITY_OPTIONS={facilities}
                    selectedFacility={selectedFacility}
                    setSelectedFacility={setSelectedFacility}
                    setDate={setDate}
                    date={date}
                    resetHandler={resetHandler}
                    isView={isView}
                  />
                </>

                <div className={"business-diary"}>
                  <Card
                    className={"card-header"}
                    title={t("Daily business report")}
                  >
                    {dateInfoLoading || dateInfoFetching ? (
                      <>
                        <Skeleton active /> <br />
                      </>
                    ) : (
                      <CardWrapper>
                        <div className={"print-card"}>
                          <ReportCard
                            titleName={t("Attended : ")}
                            count={
                              dateInfo?.attendanceInfo?.filter(
                                (dateItem) =>
                                  dateItem?.attendance_type ===
                                  USER_ATTENDANCE_VALUES.ATTENDANCE,
                              ).length
                            }
                            peopleCount={dateInfo?.attendanceInfo?.length}
                            bgColor={"#deecff"}
                            borderColor={"#E8D3FF"}
                          />
                          <ReportCard
                            titleName={t("Handled absence:")}
                            count={
                              dateInfo?.attendanceInfo?.filter(
                                (dateItem) =>
                                  dateItem?.attendance_type ===
                                  USER_ATTENDANCE_VALUES.ABSENCE,
                              ).length
                            }
                            peopleCount={dateInfo?.attendanceInfo?.length}
                            bgColor={"#DEECFF"}
                            borderColor={"#DEECFF"}
                          />
                          <ReportCard
                            titleName={t("Off-site support:")}
                            count={
                              dateInfo?.attendanceInfo?.filter(
                                (dateItem) =>
                                  dateItem?.attendance_type ===
                                  USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT,
                              ).length
                            }
                            peopleCount={dateInfo?.attendanceInfo?.length}
                            bgColor={"#C6CFFF"}
                            borderColor={"#C6CFFF"}
                          />
                          <ReportCard
                            titleName={t("Interview:")}
                            count={
                              dateInfo?.dailyBusinessSupportReport?.filter(
                                (val) => val?.interview,
                              )?.length || 0
                            }
                            peopleCount={dateInfo?.attendanceInfo?.length}
                          />
                        </div>
                      </CardWrapper>
                    )}
                    <FirstTable formik={formik} />
                  </Card>
                </div>

                <Card
                  className={"card-header"}
                  title={
                    supportRecord == 1
                      ? t("Daily support report")
                      : t("Service provision record")
                  }
                >
                  <Spin
                    spinning={
                      dailyBusinessReportByIdFetching || dateInfoLoading
                    }
                  >
                    {isMobileScreen ? (
                      <>
                        <FormikProvider value={formik}>
                          <FieldArray
                            name={"daily_business_support_report"}
                            render={() =>
                              formik.values.daily_business_support_report?.map(
                                (data: any) => {
                                  return (
                                    <>
                                      <MobileResponsiveView data={data} />
                                    </>
                                  )
                                },
                              )
                            }
                          />
                        </FormikProvider>
                      </>
                    ) : (
                      <>
                        <SecondContentWrapper>
                          <table>
                            <tr className={"second-content-tr"}>
                              <td className={"second-content-label"}>
                                {t("Username")}
                              </td>
                              <td className={"second-content-label"}>
                                {t("Content")}
                              </td>
                              <td className={"second-content-label"}>
                                {t("Recorder")}
                              </td>
                            </tr>
                            <>
                              <FormikProvider value={formik}>
                                <FieldArray
                                  name={"daily_business_support_report"}
                                  render={() =>
                                    formik.values.daily_business_support_report?.map(
                                      (data: any, index: number) => {
                                        return (
                                          <>
                                            <tr key={index}>
                                              <SecondTable data={data} />
                                            </tr>
                                          </>
                                        )
                                      },
                                    )
                                  }
                                />
                              </FormikProvider>
                            </>
                          </table>
                        </SecondContentWrapper>
                      </>
                    )}
                  </Spin>
                </Card>
              </Wrapper>
            </Spin>
            {Buttons}
            <Modal
              title={t("Daily record printing items")}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              bgColor={"#d2d1d1"}
              color={"#000"}
              headerBorderBottom={"1px solid #d2d1d1"}
              headerCloseable={true}
              headerPadding={"20px 10px"}
              footerPadding={"15px 20px 15px 0px"}
              okText={t("DETERMINE")}
              cancelText={t("CANCEL")}
              bodyPadding={"0px"}
              dailyBusinessBody={true}
              borderRadius={"10px"}
              okButtonProps={{
                style: {
                  backgroundColor: "#0782C8",
                  borderColor: "#0782C8",
                  padding: "0px 25px",
                },
              }}
              cancelButtonProps={{
                style: {
                  backgroundColor: "#F6F7F8",
                  border: "1px solid #d2d1d1",
                  color: "#0782C8",
                  padding: "0px 25px",
                },
              }}
            >
              <div className={"radio-content"}>
                <p className={"title"}>{t("Form name")}</p>
                <RadioGroup
                  options={formName}
                  value={supportRecord}
                  name={"support_record"}
                  onChange={(e) => {
                    setSupportRecord(e.target.value)
                  }}
                />
              </div>
              <p style={{ border: "0.9px solid #d2d1d1" }}></p>
              <div className={"check-box-content"}>
                <p className={"title"}>
                  {t(
                    `All entered item will be printed, except for the items below.\n Check the item you would like to additionally print`,
                  )}
                </p>
                <CheckBox
                  name={"businessDiary"}
                  value={businessDiary}
                  checked={businessDiary}
                  label={t("Business diary")}
                  onChange={(e) => setBusinessDiary(e.target.checked)}
                />
              </div>
            </Modal>
          </>
        </PlanFormWrapper>
      </MainWrapper>
      <div style={{ display: "none" }}>
        <DailyBusinessReportBulkPrint
          ref={componentRef}
          users={filteredGetDailyBusiness || {}}
          currentDate={date?.from_date || dayjs()}
          dateInfo={dateInfo}
          supportRecord={supportRecord}
          businessDiary={businessDiary}
        />
      </div>
    </>
  )
}
