export const generateRows = (length: number, data: any[]) => {
  const initialData = Array.from({ length: length - data?.length }, () => ({
    Amount: "",
    CityId: 0,
    CityName: "",
    ClaimedAmountOfBenefit: 0,
    Number: "",
    ServiceType: null,
    Subsidy: 0,
    Unit: "",
    UserBurden: 0,
  }))
  return [...data, ...initialData]
}
export const calculateSubTotal = (data: any) => {
  return data.reduce(
    (a: any, b: any) => {
      return {
        Amount: a.Amount + b.Amount,
        Number: a.Number + b.Number,
        Subsidy: a.Subsidy + b.Subsidy,
        UserBurden: a.UserBurden + b.UserBurden,
        ClaimedAmountOfBenefit:
          a.ClaimedAmountOfBenefit + b.ClaimedAmountOfBenefit,
        Unit: a.Unit + b.Unit,
      }
    },
    {
      Amount: 0,
      Number: 0,
      Subsidy: 0,
      UserBurden: 0,
      ClaimedAmountOfBenefit: 0,
      Unit: 0,
    },
  )
}

//ServiceContentsTable
const getSampleRowData = (numberOfAddingRows: number) => {
  return Array.from({ length: numberOfAddingRows }, () => ({
    c_04: "",
    c_05: "",
    c_06: "",
    c_07: "",
    c_08: "",
  }))
}
export const contentsTableRows = (rowData: any[]) => {
  const totalRows = 13

  if (rowData?.length >= totalRows) {
    return rowData
  }
  return [...rowData, ...getSampleRowData(totalRows - rowData.length)]
}
