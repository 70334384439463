import { theme } from "@project/common"
import styled from "styled-components"

export const StyleFieldArray = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 16px;

  .input_container {
    display: flex;
    row-gap: 16px;
    column-gap: 16px;
    .input_content {
      flex: 1;
    }
    .btn_content {
      width: fit-content;
      display: flex;
      position: relative;
      margin-top: 5px;
    }
  }
`
export const NoContentWrapper = styled.div`
  padding: 15px;
  width: 360px;
  background-color: ${theme.colors.disabled};
`
