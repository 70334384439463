import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .txt__title {
    display: none;
  }
  @media print {
    gap: 4px !important;
    .txt__title {
      display: block;
      font-size: 8pt !important;
    }
  }
`
