import {
  Button,
  DatePicker,
  Grid,
  InputAreaField,
  InputField,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"

import { createId } from "@paralleldrive/cuid2"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { YM_FORMAT } from "../../../constants"
import { useAuthContext } from "../../../context"
const EmploymentContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .employment_content_top {
    width: 100%;
    border: 1px solid ${theme.colors.border};
    padding: 16px;
  }
`
export const AssessmentWorkElementItemFields = ({
  category_items,
  handleDataValueChange,
  assessment_setting_id,
  assessmentId,
  categoryId,
  handleAssessmentEntryItemAddition,
}: {
  category_items: any
  handleDataValueChange: (values: {
    value: string
    key: string
    id: number
    category_id: number
    item_id: number
    entry_id?: number
  }) => void
  handleAssessmentEntryItemAddition: (values: {
    id: number
    category_id: number
    item_id: number
    content?: Record<string, any>
    entry_id?: number
    operation?: "add" | "delete"
  }) => void
  assessmentId: number
  assessment_setting_id: number
  categoryId
}) => {
  const { t } = useTranslation()
  const { companyId } = useAuthContext()

  return (
    <div>
      {category_items?.map((item: any) => {
        return (
          <Grid
            key={item?.id}
            labelSpan={6}
            background
            padding={"10px"}
            fontWeight={500}
            labelContent={t(item?.title) || ""}
            textAlign={"left"}
          >
            {item?.title === "Employment history/training history, etc." ? (
              <>
                {item?.assessment_employment_item?.length ? (
                  <EmploymentContent>
                    {item?.assessment_employment_item?.map(
                      (entryItem: any, idx) => (
                        <div className={"employment_content_top"} key={idx}>
                          <Typography.Title level={5}>
                            {t(`就労歴・訓練歴等${idx + 1}`)}
                          </Typography.Title>
                          <div>
                            <Grid
                              background
                              labelContent={t("StartDate1")}
                              labelSpan={7}
                              padding={"10px"}
                              fontWeight={500}
                            >
                              <DatePicker
                                name={`start_date`}
                                date={
                                  entryItem?.start_date
                                    ? dayjs(entryItem?.start_date)
                                    : null
                                }
                                onDateChange={(date: Dayjs) =>
                                  handleDataValueChange({
                                    value: date ? date.format("YYYY-MM") : null,
                                    key: "start_date",
                                    id: +assessment_setting_id,
                                    category_id: categoryId,
                                    item_id: item?.id,
                                    entry_id: entryItem?.tempId,
                                  })
                                }
                                picker={"month"}
                                format={YM_FORMAT}
                              />
                            </Grid>
                            <Grid
                              background
                              labelContent={t("EndDate1")}
                              labelSpan={7}
                              padding={"10px"}
                              fontWeight={500}
                            >
                              <DatePicker
                                name={"end_date"}
                                date={
                                  entryItem?.end_date
                                    ? dayjs(entryItem?.end_date)
                                    : null
                                }
                                onDateChange={(date: Dayjs) =>
                                  handleDataValueChange({
                                    value: date ? date.format("YYYY-MM") : null,
                                    key: "end_date",
                                    id: +assessment_setting_id,
                                    category_id: categoryId,
                                    item_id: item?.id,
                                    entry_id: entryItem?.tempId,
                                  })
                                }
                                picker={"month"}
                                format={YM_FORMAT}
                              />
                            </Grid>
                            <Grid
                              background
                              labelContent={t("Company name/facility name")}
                              labelSpan={7}
                              padding={"10px"}
                              fontWeight={500}
                              textAlign={"left"}
                            >
                              <InputField
                                name={"company_name"}
                                value={entryItem?.company_name || null}
                                onChange={({ target: { value } }) =>
                                  handleDataValueChange({
                                    value,
                                    key: "company_name",
                                    id: +assessment_setting_id,
                                    category_id: categoryId,
                                    item_id: item?.id,
                                    entry_id: entryItem?.tempId,
                                  })
                                }
                                maxLength={50}
                                height={"40px"}
                                info={t("(within {{total}} char)", {
                                  total: 50,
                                })}
                              />
                            </Grid>
                            <Grid
                              background
                              labelContent={t("Detail")}
                              labelSpan={7}
                              padding={"10px"}
                              fontWeight={500}
                            >
                              <div style={{ width: "100%" }}>
                                <InputAreaField
                                  name={"name"}
                                  value={entryItem?.detail || null}
                                  onChange={({ target: { value } }) =>
                                    handleDataValueChange({
                                      value,
                                      key: "detail",
                                      id: +assessment_setting_id,
                                      category_id: categoryId,
                                      item_id: item?.id,
                                      entry_id: entryItem?.tempId,
                                    })
                                  }
                                  rows={3}
                                  resize={false}
                                  maxLength={250}
                                  info={t("(within {{total}} char)", {
                                    total: 250,
                                  })}
                                />
                              </div>
                            </Grid>
                          </div>
                          <Flex justify={"flex-end"}>
                            <Button
                              btnText={t("Delete")}
                              textColor={theme.colors.error}
                              hoverTextColor={theme.colors.error}
                              padding={"0px"}
                              type={"link"}
                              style={{
                                textDecoration: "underline",
                                border: 0,
                              }}
                              onClick={() =>
                                handleAssessmentEntryItemAddition({
                                  id: +assessment_setting_id,
                                  category_id: categoryId,
                                  item_id: item?.id,
                                  entry_id: entryItem?.tempId,
                                  operation: "delete",
                                })
                              }
                            />
                          </Flex>
                        </div>
                      ),
                    )}

                    <div>
                      <Button
                        btnText={t(
                          "+ Add work history, training history, etc.",
                        )}
                        shape={"round"}
                        borderColor={theme.colors.border}
                        padding={"5px 16px"}
                        onClick={() =>
                          handleAssessmentEntryItemAddition({
                            id: +assessment_setting_id,
                            category_id: categoryId,
                            item_id: item?.id,
                            content: {
                              tempId: createId(),
                              company_id: +companyId,
                              start_date: null,
                              assessment_id: +assessmentId || undefined,
                              end_date: null,
                              company_name: "",
                              detail: "",
                            },
                          })
                        }
                      />
                    </div>
                  </EmploymentContent>
                ) : (
                  "--"
                )}
              </>
            ) : (
              <InputAreaField
                name={item?.title || ""}
                rows={2}
                maxLength={200}
                value={item?.answer || null}
                onChange={({ target: { value } }) =>
                  handleDataValueChange({
                    value,
                    key: "answer",
                    id: +assessment_setting_id,
                    category_id: categoryId,
                    item_id: item?.id,
                  })
                }
              />
            )}
          </Grid>
        )
      })}
    </div>
  )
}
