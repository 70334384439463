import { useContext, useState } from "react"

// packages
import { Flex } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import { useQuery } from "react-query"

// common components
import {
  AccordionCard,
  ActionBtns,
  Button,
  CheckBoxGroup,
  DatePicker,
  FACILITY_SERVICE_TYPES,
  FACILITY_TYPES,
  InputField,
  RadioGroup,
  SelectInput,
} from "@project/common"
import { OperationFields } from "../common/OperationFields"

// styles
import { ServiceProvOperationContent } from "./ServiceProvInternal.styles"

// context
import { AuthContext } from "../../../context/AuthContext"

// types
import {
  ServiceProvOperation,
  ServiceProvOperationSectionProps,
} from "../../../types/serviceRecordProvision"

// services
import { getAllUsers } from "../../../services/user"

// utils
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { getFuriFilteredOptions } from "../../../utils/getFuriFilteredOptions"

// constants
import { SP_DISPLAY_SETTINGS } from "@project/common/src/constants/settings"
import dayjs, { Dayjs } from "dayjs"
import { ErrorMessage } from "../../../utils/shared-style"
const filterInitialValues: ServiceProvOperation = {
  keyword: "",
  user_id: null,
  facilityIds: [],
  service: [],
  display_types: null,
  year: dayjs().year(),
  month: dayjs().month(),
}

export const ServiceProvOperationSection = ({
  memoizedQuery,
  onOperationFormReset,
  onOperationFormSubmit,
}: ServiceProvOperationSectionProps) => {
  const [uNameFuriAlpha, setUNameFuriAlpha] = useState<string | null | 0>(null)

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const { facilities: facilitiesCtx } = useContext(AuthContext)

  // !## API queries
  // 1. All users from API
  const { data: allUsers, isLoading: loadingAllUsers } = useQuery("all-users", {
    queryFn: getAllUsers,
    select: (data) => {
      return data?.data?.map((user) => ({
        label: user?.username,
        value: user?.id,
        id: user?.id,
        furigana: user?.furigana,
        Recipient_id_number: user?.user_certificate.number,
        service_used: t(
          FACILITY_SERVICE_TYPES[user?.user_certificate.service_type],
        ),
      }))
    },
  })

  // !## Formik form control
  const formik = useFormik<ServiceProvOperation>({
    initialValues: memoizedQuery,
    onSubmit: (values) => {
      onOperationFormSubmit(values)
    },
  })

  const { setFieldValue, values, handleChange, handleSubmit, setValues } =
    formik

  // !## event action function
  // 1. Handle checkAll and uncheckAll
  const handleCheckAll = () => {
    setFieldValue(
      "facilityIds",
      facilitiesCtx.map((item) => item.id + ""),
    )
  }
  const handleUncheckAll = () => {
    setFieldValue("facilityIds", [])
  }
  // 2. Handle reset form
  const handleFormReset = () => {
    setValues(filterInitialValues)
    onOperationFormReset()
  }

  return (
    <AccordionCard
      defaultActiveKey={["service-provision-record"]}
      items={[
        {
          key: "service-provision-record",
          label: t("Operation Options"),
          children: (
            <ServiceProvOperationContent>
              <OperationFields
                label={"Keyword"}
                align={"center"}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
              >
                <div className={"operation_inputs"}>
                  <InputField
                    size={"large"}
                    name={"keyword"}
                    width={"100%"}
                    value={values.keyword}
                    onChange={handleChange}
                    className={"SP_keyword-input"}
                    placeholder={t(
                      "Recipient identification number, upper limit management operator, business site name (kana also acceptable).",
                    )}
                  />
                </div>
              </OperationFields>

              <OperationFields
                label={"User name"}
                align={"center"}
                wrap={"wrap"}
                gap={"10px 24px"}
                labelWidth={"150px"}
              >
                <Flex
                  className={"operation_inputs SP_user-inputs"}
                  align={"center"}
                  gap={"10px 24px"}
                >
                  <SelectInput
                    width={"78px"}
                    maxWidth={"78px"}
                    shortSelector
                    name={"furigana"}
                    placeholder={"--"}
                    value={uNameFuriAlpha}
                    className={"SP_furigana-select"}
                    options={FuriganaAlphabetsOption}
                    onChange={(val) => {
                      setUNameFuriAlpha(val as string | null | 0)
                      setFieldValue("user_id", null)
                    }}
                  />
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <SelectInput
                      allowClear
                      name={"user_id"}
                      minWidth={"160px"}
                      placeholder={t("---")}
                      loading={loadingAllUsers}
                      value={values?.user_id}
                      className={"SP_user-select min-w-210"}
                      onChange={(val) => setFieldValue("user_id", val)}
                      options={getFuriFilteredOptions(allUsers, uNameFuriAlpha)}
                    />
                  </div>
                </Flex>
              </OperationFields>

              <OperationFields
                align={"flex-start"}
                label={"Facility"}
                wrap={"wrap"}
                gap={"10px 24px"}
                labelWidth={"150px"}
              >
                <div className={"operation_inputs"}>
                  <Flex
                    className={"facility-action-btn-group"}
                    style={{
                      width: "max-content",
                    }}
                    gap={10}
                  >
                    <Button
                      shape={"round"}
                      type={"default"}
                      iconType={"check-all"}
                      btnText={t("Check all")}
                      onClick={handleCheckAll}
                    />
                    <Button
                      shape={"round"}
                      type={"default"}
                      btnText={t("Uncheck all")}
                      onClick={handleUncheckAll}
                      className={"SP_uncheck-all-btn"}
                    />
                  </Flex>

                  <CheckBoxGroup
                    items={facilitiesCtx}
                    name={"facilityIds"}
                    onChange={(val) => {
                      setFieldValue("facilityIds", val)
                    }}
                    value={values.facilityIds}
                  />
                  <ErrorMessage
                    style={{
                      marginTop: "16px",
                      fontSize: "14px",
                    }}
                  >
                    {t(
                      "※ The number in the parentheses within the contracted business site represents the number of contracted individuals.",
                    )}
                  </ErrorMessage>
                </div>
              </OperationFields>

              <OperationFields
                label={"Use service"}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
              >
                <div className={"SP_use-service-inputs"}>
                  <CheckBoxGroup
                    items={getOptionsWithTranslatedLabel(FACILITY_TYPES)}
                    name={"service"}
                    onChange={(val) => {
                      setFieldValue("service", val)
                    }}
                    value={values.service}
                  />
                </div>
              </OperationFields>

              <OperationFields
                label={"Display settings"}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
              >
                <RadioGroup
                  options={getOptionsWithTranslatedLabel(SP_DISPLAY_SETTINGS)}
                  name={"display_types"}
                  onChange={(value) => {
                    setFieldValue("display_types", value.target.value)
                  }}
                  value={values.display_types}
                />
              </OperationFields>

              <OperationFields
                label={"Year Month"}
                labelWidth={"150px"}
                wrap={"wrap"}
                gap={"10px 24px"}
              >
                <Flex gap={"16px"} className={"operation_inputs"}>
                  <DatePicker
                    picker={"month"}
                    date={dayjs([+values?.year, +values?.month - 1])}
                    onDateChange={(date: Dayjs) => {
                      setValues({
                        ...values,
                        year: dayjs(date).year(),
                        month: +dayjs(date).month() + 1,
                      })
                    }}
                    format={"YYYY年MM月"}
                  />
                </Flex>
              </OperationFields>

              <ActionBtns justify={"flex-start"}>
                <Button
                  shape={"round"}
                  btnText={t("Reset search")}
                  onClick={() => handleFormReset()}
                />
                <Button
                  shape={"round"}
                  type={"primary"}
                  iconType={"search"}
                  btnText={t("Search")}
                  onClick={() => handleSubmit()}
                />
              </ActionBtns>
            </ServiceProvOperationContent>
          ),
        },
      ]}
    />
  )
}
