import { getLocalstorageItem, PageTitle } from "@project/common"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useAuthContext } from "../../context"
import { FacilityComponent } from "./FacilityComp/FacilityComponent"
import { TopPageButtons } from "./FacilityComp/TopPageButtons"

const Wrapper = styled.div``

const TopPage = () => {
  const { t } = useTranslation()
  const { facilities, permissions } = useAuthContext()
  const storeFacility = getLocalstorageItem("top_facility", "facility_ids")
  const [selectedFacility, setSelectedFacility] = useState(
    facilities?.filter((item) => Number(item?.id) === +storeFacility)?.[0] ||
      facilities[0],
  )
  const [facilityID, setFacilityID] = useState(
    +storeFacility || permissions?.primary_facility_id || facilities[0]?.id,
  )
  useEffect(() => {
    setFacilityID(
      +storeFacility || permissions?.primary_facility_id || facilities[0]?.id,
    )
  }, [facilities, permissions?.primary_facility_id])
  return (
    <Wrapper>
      <div>{t("Home")}</div>
      <PageTitle
        title={t("Management screen top")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
      />
      <TopPageButtons
        setFacilityID={setFacilityID}
        facilities={facilities}
        facilityID={facilityID}
        setSelectedFacility={setSelectedFacility}
      />
      <FacilityComponent
        facilityID={facilityID}
        selectedFacility={selectedFacility}
      />
    </Wrapper>
  )
}

export { TopPage }
