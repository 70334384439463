import {
  AccordionCard,
  Button,
  DatePicker,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  useUpdateSearchParams,
} from "@project/common"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT_EN } from "../../../constants"
import { useAuthContext } from "../../../context"

export const StaffDailyAttendanceRecordOperationOption = ({
  params,
  setSearchParams,
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    facility_id: params?.facility_id || [],
    date: params?.date || null,
  })

  return (
    <AccordionCard
      activeKey={["attendance_record-daily-list"]}
      items={[
        {
          key: "attendance_record-daily-list",
          label: t("Operation options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Display facility")}
                  width={"136px"}
                />
                <FacilityCheckbox
                  options={facilities || []}
                  value={values?.facility_id || null}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setValues({
                        ...values,
                        facility_id: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        facility_id: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      facility_id: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Date")} width={"136px"} />
                <DatePicker
                  date={dayjs(values?.date)}
                  onDateChange={(date: Dayjs) =>
                    setValues({
                      ...values,
                      date: date,
                    })
                  }
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  onClick={() => {
                    setSearchParams({
                      ...values,
                    })
                    updateParams(
                      {
                        facility_id: values?.facility_id?.toString(),
                        date: dayjs(values?.date).format(FULL_DATE_FORMAT_EN),
                      },
                      "/attendance-record/day-list",
                    )
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
