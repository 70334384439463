import { USER_RECEIPT_DISPLAY_SETTING_VALUES } from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

const StandardUserReceipt = ({
  data,
  params,
  key,
  month,
  era,
  provisionText = "の障害福祉サービスご利用分として",
}) => {
  const { t } = useTranslation()
  const displayProvisionText = params?.display_setting?.includes(
    USER_RECEIPT_DISPLAY_SETTING_VALUES.CHANGE_WORD_DISABILITY,
  )
    ? provisionText.replace("障害", "障がい")
    : provisionText
  const showPrintReceipt = (data) => {
    if (params.display_setting.includes("8")) {
      return (
        <>
          <div className={"first-div"}>
            <div className={"receipt"}>
              <div className={"zip-address"}>
                <span className={"zip"}>{"〒3720831"}</span>
                <span className={"parent_address"}>
                  {"伊勢崎市山王町226-8"}
                </span>
              </div>
              <div className={"copy"}>
                {t(
                  `Receipt ${
                    params?.display_setting?.includes("2") || data?.copy
                      ? "(copy)"
                      : ""
                  }`,
                )}
              </div>
            </div>
            <div className={"title"}>
              {`( ${data?.receiving_certificate_number}: 上里町 )`}
            </div>
            <div className={"parent-child-name"}>
              {params?.display_setting?.includes("4")
                ? ` ${data?.parent_name} (${data?.user_name})`
                : params?.display_setting?.includes("3")
                  ? data?.user_name
                  : data?.parent_name}
            </div>

            <div className={"amount"}>
              <div className={"title"}>{t("Amount of money")}</div>
              <div className={"cost"}>{`￥${data?.billed_amount}`}</div>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className={"main"}>
          <div
            className={"receipt"}
            style={{ fontSize: "20px", fontWeight: "700" }}
          >
            {params?.display_setting?.includes("2") || data?.copy
              ? t("Receipt (copy)")
              : t("Receipt")}
          </div>
          <div className={"parent-username"}>
            <div className={"user-name"}>
              {params?.display_setting?.includes("4")
                ? ` ${data?.parent_name} (${data?.user_name})`
                : params?.display_setting?.includes("3")
                  ? data?.user_name
                  : data?.parent_name}
            </div>
            <div className={"address"}>
              {`( ${data?.city_number}: ${data?.provision_city})`}
            </div>
          </div>
          <div className={"amount"}>
            <div className={"title"}>{t("Amount of money")}</div>
            <div className={"cost"}>{`￥${data?.billed_amount}`}</div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={"content"} key={key}>
        <div className={"first-content"}>{showPrintReceipt(data)}</div>
        <div className={"divider"}></div>
        <div className={"second-content"}>
          <div className={"first"}>
            <div className={"title"} style={{ margin: "5px 10px" }}>
              {t(`但 {{era}}{{month}}${displayProvisionText}`, {
                era,
                month: dayjs(month).format("MM月"),
              })}
            </div>
            <div
              className={"title"}
              style={{
                margin: "5px 10px",
                letterSpacing: "3px",
              }}
            >
              {params?.display_setting?.includes("7") ? (
                <span style={{ whiteSpace: "pre" }}>
                  {t(`令和      年      月      日 上記正に領収いたしました。`)}
                </span>
              ) : (
                t(`{{era}}{{date}} 上記正に領収いたしました。`, {
                  era,
                  date: dayjs(params?.date).format("MM月DD日"),
                })
              )}
            </div>
          </div>
          <div className={"second"}>
            <div className={"table"}>
              {params?.display_setting?.includes("5") && (
                <table>
                  <tr>
                    <th>{t("Receipt stamp")}</th>
                  </tr>
                  <tr>
                    <td>{""}</td>
                  </tr>
                </table>
              )}
            </div>

            <div className={"details"}>
              <div className={"address"}>
                <span className={"title"}>{t("Address")}</span>
                <span>{`〒${data?.parent_zip_code} ${data?.facility_address}`}</span>
              </div>
              <div className={"address"}>
                <span className={"title"}>{t("Phone Number")}</span>
                <span>{data?.facility_phone || "-"}</span>
              </div>
              <div className={"address"}>
                <span className={"title"}>{t("Official name")}</span>
                <span>{data?.facility_name || "-"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StandardUserReceipt
