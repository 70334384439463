import { Button, Card, Grid, theme } from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT } from "../../../constants"
import { useAuthContext } from "../../../context"

export const ActualCostBurdenDetailBasicInfo = ({
  data,
  isLoading,
}: {
  data?: any
  isLoading?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const router = useRouter()
  const { id, date, facility } = router.query as any
  return (
    <Card title={t("Basic information on actual expenses")}>
      {isLoading ? (
        <>
          <Skeleton active />
        </>
      ) : (
        <Flex vertical gap={16}>
          <Button
            btnText={t("Edit")}
            iconType={"edit-gray"}
            btnBg={theme.colors["action-400"]}
            borderColor={theme.colors.action}
            shape={"round"}
            textColor={theme.colors.text}
            width={"fit-content"}
            onClick={() =>
              router.push(
                `/actual-cost-burden-record/list/edit/${id}?facility=${facility}&date=${date}&actual_cost_id=${data?.data?.actual_cost?.id}`,
              )
            }
          />

          <div>
            <Grid labelContent={t("Date")} background padding={"12px"}>
              {date ? dayjs(date).format(FULL_DATE_FORMAT) : ""}
            </Grid>
            <Grid labelContent={t("Facility")} background padding={"12px"}>
              {facility
                ? facilities?.find((v) => v?.value === facility)?.label
                : ""}
            </Grid>
            <Grid
              labelContent={t("Actual cost item name")}
              background
              padding={"12px"}
            >
              {data?.data?.actual_cost_detail
                ? data?.data?.actual_cost_detail
                : data?.data?.actual_cost?.actual_cost_name || ""}
            </Grid>
            <Grid
              labelContent={t("Total cost for users")}
              background
              padding={"12px"}
            >
              {data?.data?.totalCost || "0"}
            </Grid>
          </div>
        </Flex>
      )}
    </Card>
  )
}
