import Link from "next/link"
import React, { useCallback, useState } from "react"

// packages
import { t } from "i18next"
import { Flex } from "antd"

// commons | packages
import { SelectTime } from "./SelectTime"
import { DebouncedInputField, DebouncedTextArea } from "./DebouncedTextField"
import { Box, Button, ErrorMassage, SelectInput, Table } from "@project/common"

// types
import { ColumnsType } from "antd/es/table"
import { BulkEditGenericUserTableProps, TMUserTableDataType } from "../types"

// constants
import { PICK_DROP_OPTIONS_INT_VAL } from "../../../constants"
import { useTMBulkEdit } from "../../../context/TMBulkEditFormContext"
import { ROUTE_OPTIONS } from "../../UserTransportManagementContainer/constant"

// utility function
const showLongCell = (record: Partial<TMUserTableDataType>) =>
  record?.is_absent || !record?.use_transportation_flag

// !## MAIN COMPONENT
export const BulkEditGenericUserTable: React.FC<
  BulkEditGenericUserTableProps
> = ({ type, userDataSource, onUserCellReset, handleCellInputChange }) => {
  const { selectedTransportIds, setSelectedTransportIds } = useTMBulkEdit()

  const BULK_EDIT_GENERIC_USER_TABLE_COLUMN: ColumnsType<
    Partial<TMUserTableDataType>
  > = [
    {
      width: 158.38,
      key: "user_id",
      align: "center",
      title: t("Username"),
      dataIndex: "user_id",
      sorter: (a, b) => a?.user_name.localeCompare(b?.user_name),
      render: (val, record) => {
        return <Link href={`/users/profile/${val}`}>{record?.user_name}</Link>
      },
    },
    {
      width: 158.38,
      align: "center",
      key: "facility",
      dataIndex: "facility",
      title: t("Facility name"),
    },
    {
      title:
        type === "pickup_users"
          ? t("Desired pick up time")
          : t("Desired drop time"),
      width: 158.38,
      align: "center",
      key: "desired_time",
      dataIndex: "desired_time",
      render: (val: TMUserTableDataType["desired_time"], record, i) =>
        showLongCell(record) ? (
          <LongCell record={record} index={i} />
        ) : (
          <Flex justify={"center"} align={"center"}>
            <SelectTime
              value={val}
              onTimeChange={(value) => {
                handleCellInputChange(type, i, value, "desired_time")
              }}
            />
          </Flex>
        ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 6 } : { colSpan: 1 },
    },
    {
      width: 158.38,
      align: "center",
      key: "pickup_time",
      dataIndex: "pickup_time",
      title: type === "pickup_users" ? t("Pickup time") : t("Dropped time"),
      render: (val: TMUserTableDataType["desired_time"], _, i) => (
        <Flex justify={"center"} align={"center"}>
          <SelectTime
            value={val}
            onTimeChange={(value) => {
              handleCellInputChange(type, i, value, "pickup_time")
            }}
          />
        </Flex>
      ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 0 } : { colSpan: 1 },
    },
    {
      width: 158.38,
      align: "center",
      key: "arrival_time",
      title: t("Arrival time"),
      dataIndex: "arrival_time",
      render: (val: TMUserTableDataType["desired_time"], _, i) => (
        <Flex justify={"center"} align={"center"}>
          <SelectTime
            value={val}
            onTimeChange={(value) => {
              handleCellInputChange(type, i, value, "arrival_time")
            }}
          />
        </Flex>
      ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 0 } : { colSpan: 1 },
    },
    {
      key: "place",
      width: 158.38,
      align: "center",
      title: t("Place"),
      dataIndex: "place",
      render: (val: TMUserTableDataType["place"], rec, i) => (
        <Box
          miw={120}
          maw={120}
          mx={"auto"}
          display={"flex"}
          direction={"column"}
          gap={10}
        >
          <SelectInput
            options={PICK_DROP_OPTIONS_INT_VAL}
            name={"place"}
            width={"120px"}
            placeholder={"--"}
            value={val ? val : null}
            onChange={(val) => handleCellInputChange(type, i, val, "place")}
          />
          {val === 3 && (
            <DebouncedInputField
              value={rec.place_other_name}
              width={"120px"}
              size={"large"}
              onInputChange={(value) =>
                handleCellInputChange(type, i, value, "place_other_name")
              }
            />
          )}
        </Box>
      ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 0 } : { colSpan: 1 },
    },
    {
      key: "route",
      align: "center",
      title: t("Route No."),
      dataIndex: "route",
      render: (val, _, i) => (
        <SelectInput
          options={ROUTE_OPTIONS}
          name={"route"}
          width={"60px"}
          placeholder={"--"}
          value={val ? val : 1}
          onChange={(val) => handleCellInputChange(type, i, val, "route")}
        />
      ),
    },
    {
      width: 158.38,
      key: "remarks",
      align: "center",
      title: t("Remarks"),
      dataIndex: "remarks",
      render: (val, _, i) => (
        <DebouncedTextArea
          value={val}
          onInputChange={(val) =>
            handleCellInputChange(type, i, val, "remarks")
          }
        />
      ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 0 } : { colSpan: 1 },
    },
    {
      title: t("Edit"),
      dataIndex: "edit",
      align: "center",
      key: "edit",
      width: 158.38,
      render: (_, __, i) => (
        <Flex justify={"center"}>
          <Button
            btnText={"Reset"}
            type={"link"}
            onClick={() => onUserCellReset(type, i)}
          />
        </Flex>
      ),
      onCell: (record) =>
        showLongCell(record) ? { colSpan: 0 } : { colSpan: 1 },
    },
  ]

  // !## React hooks
  // 1. Callback function component in case the row data has no transportation, or the user is absent.
  // or if just in case in user hits reset button.
  const LongCell = useCallback(
    ({
      record,
      index,
    }: {
      record: Partial<TMUserTableDataType>
      index: number
    }) => {
      const [showMultipleOpts, setShowMultipleOpts] = useState<boolean>(false)
      return (
        <Box display={"flex"} justify={"center"} gap={"16px"}>
          {showMultipleOpts ? (
            <>
              <Button
                btnText={t("Use Transportation")}
                shape={"round"}
                type={"default"}
                onClick={() =>
                  handleCellInputChange(
                    type,
                    index,
                    true,
                    "use_transportation_flag",
                  )
                }
              />
              <Button
                btnText={t("Don’t use transportation")}
                type={"default"}
                shape={"round"}
                onClick={() => setShowMultipleOpts(false)}
              />
            </>
          ) : record?.is_absent ? (
            <ErrorMassage mt={"0"} message={t("Absent")} />
          ) : (
            <Button
              btnText={t("No Transportation")}
              type={"default"}
              shape={"round"}
              onClick={() => setShowMultipleOpts(true)}
            />
          )}
        </Box>
      )
    },
    [],
  )

  return (
    <div>
      <Table
        columns={BULK_EDIT_GENERIC_USER_TABLE_COLUMN}
        dataSource={userDataSource}
        scroll={{
          x: "max-content",
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedTransportIds?.[type],
          onSelect: (_, __, selectedRows) => {
            const selectedIds = selectedRows.map((row) => row?.id)
            setSelectedTransportIds((prev) => ({
              ...prev,
              [type]: selectedIds,
            }))
          },
          columnWidth: 80,
          fixed: "left",
          onChange: (__, _, info) => {
            if (info.type === "all") {
              const allKeys = userDataSource.map((staff) => staff.id)
              setSelectedTransportIds((prev) =>
                prev?.[type].length > 0
                  ? { ...prev, [type]: [] }
                  : { ...prev, [type]: allKeys },
              )
            }
          },
        }}
      />
    </div>
  )
}
