import { Button, Card } from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { YEAR_DATE_FORMAT } from "../../../../constants"
import { getNhifPrintAddressToUsers } from "../../../../services"
import { AddressToUsersList } from "./AddressToUsersList"
import { PrintAddressToUserOpOptions } from "./PrintAddressToUserOpOptions"
import { SelectingByFacilities } from "./SelectingByFacilities"

export const PrintAddressToUserContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { billing_facilities, year, month, facilityIds } = router?.query as any
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["address-to-users", billing_facilities],
    queryFn: () =>
      getNhifPrintAddressToUsers({
        facilityIds: billing_facilities,
      }),
  })

  return (
    <Flex vertical gap={16}>
      <PrintAddressToUserOpOptions />
      <Button
        btnText={t("<< Back")}
        width={"fit-content"}
        shape={"round"}
        onClick={() => router.push("/national-health-insurance-billing")}
      />
      <Card
        title={t("{{date}}List of facility use Userren", {
          date: dayjs().format(YEAR_DATE_FORMAT),
        })}
      >
        <SelectingByFacilities />
        <br />
        <AddressToUsersList
          loading={isLoading || isFetching}
          responseData={response || undefined}
        />
      </Card>
      <Button
        btnText={t("<< Back")}
        width={"fit-content"}
        shape={"round"}
        onClick={() =>
          router.push({
            pathname: "/national-health-insurance-billing",
            query: {
              year: year || dayjs().year(),
              month: month || dayjs().month() + 1,
              facilityIds: facilityIds || "",
            },
          })
        }
      />
    </Flex>
  )
}
