import React from "react"

// packages
import { t } from "i18next"

// commons
import { Box } from "@project/common"

// styles
import { EntryTable } from "../UserTransportMgmtPrint.styles"

export const VehicleEntry: React.FC = () => {
  return (
    <Box display={"flex"} gap={20} align={"flex-start"}>
      {/* left entry panel starts here */}
      <EntryTable>
        <tbody>
          <tr>
            <td className={"first-cell"}>{t("Meter")}</td>
            <td className={"last-cell"}>{"km (出発)"}</td>
          </tr>
          <tr className={"row"}>
            <td className={"first-cell"}>{t("Guideline")}</td>
            <td className={"last-cell"}>{t("km(終着)")}</td>
          </tr>
          <tr className={"row"}>
            <td className={"first-cell"}>{t("Mileage")}</td>
            <td className={"last-cell"}>{"km(総計)"}</td>
          </tr>
          <tr className={"row"}>
            <td className={"first-cell"}>
              {t("Next oil change")}
              {t("Every {{distance}} km", { distance: "5000" })}
            </td>
            <td className={"last-cell"}>{"km"}</td>
          </tr>
          <tr className={"row"}>
            <td className={"first-cell"}>
              {t("Next oil element change")}
              {t("Every {{distance}} km", { distance: "10000" })}
            </td>
            <td className={"last-cell"}>{"km"}</td>
          </tr>
          <tr className={"merged-row bg-gray"}>
            <td className={"first-cell fuel-cell"} colSpan={2}>
              {t("Fuel Purchase")}
            </td>
          </tr>
          <tr className={"row"}>
            <td className={"first-cell"}>{t("Gasoline")}</td>
            <td className={"last-cell"}>{t("Litre")}</td>
          </tr>{" "}
          <tr className={"row"}>
            <td className={"first-cell"}>{t("Oil")}</td>
            <td className={"last-cell"}>{t("Litre")}</td>
          </tr>{" "}
          <tr className={"row"}>
            <td className={"first-cell"}>{t("Oil element")}</td>
            <td className={"last-cell"}>{t("回に１回")}</td>
          </tr>
        </tbody>
      </EntryTable>
      {/* left entry panel ends here */}

      {/* right entry panel starts here */}
      <EntryTable>
        <thead>
          <tr>
            <th className={"has-bg"} colSpan={2}>
              {t("◆出発前　点検事項")}
            </th>
            <th className={"has-bg"}>{t("Inspector")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={"row"}>
            <td>{t("Engine oil")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
            <td className={"symbol-cell"} rowSpan={5}>
              <Box display={"flex"} justify={"center"}>
                <span>{"㊞"}</span>
              </Box>
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Brake oil")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Tyre groove")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Radiator condition")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Engine sound")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Child lock Ok")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
            <td className={"symbol-cell"} rowSpan={4}>
              <Box display={"flex"} justify={"center"}>
                <span>{"㊞"}</span>
              </Box>
            </td>
          </tr>
          <tr className={"row"}>
            <td>{t("Window lock OK")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Lights")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
          <tr className={"row"}>
            <td>{t("Mirror OK")}</td>
            <td className={"checkbox-cell"}>{"□"}</td>
          </tr>
        </tbody>
      </EntryTable>
      {/* right entry panel ends here */}
    </Box>
  )
}
