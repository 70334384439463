import { SERVICE_TYPE_VALUES, theme } from "@project/common"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AuthContext } from "../../../../context"
import { getAllWork } from "../../../../services"
const DynamicTableWrapper = styled.div`
  .table-data {
    background-color: rgb(243, 243, 243);
    width: 40%;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }
  .line-break {
    white-space: pre-wrap;
  }
`
const DynamicTable = ({ data, allStaffData }) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const { data: allWorkData } = useQuery<any>(
    ["get-all-work-data-view"],
    () => getAllWork(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.work_name, value: d?.id }
        }),
    },
  )
  // previously work id was single selection
  // now its multiple
  // for new data refer to daily_business_support_report_works
  // for old data use word_id in array
  const userWorkIds =
    Array.isArray(data?.daily_business_support_report_works) &&
    data?.daily_business_support_report_works?.length > 0
      ? data?.daily_business_support_report_works?.map((work) => work?.work_id)
      : data?.work_id
        ? [data?.work_id]
        : []

  const workName = allWorkData
    ?.filter((d) => userWorkIds.includes(d.value))
    ?.map((d) => d?.label)
    ?.join(", ")
  const facility_name = facilities.find((d) => d?.value == data?.facility_id)
    ?.label

  return (
    <>
      <DynamicTableWrapper>
        <table>
          {data?.is_absent ? (
            <>
              <tr>
                <td className={"table-data"}>{t("Staff who handled")}</td>
                <td>
                  {allStaffData?.find(
                    (v) => v?.value === data?.absent_recorder_staff_id,
                  )?.label || "-"}
                </td>
              </tr>
              <tr>
                <td className={"table-data"}>
                  {" "}
                  {t("Reason of absence and support contents")}
                </td>
                <td className={"line-break"}>{data?.absence_reason || "-"}</td>
              </tr>
            </>
          ) : (
            <>
              {" "}
              <tr>
                <td className={"table-data"}>{t("Work")}</td>
                <td>{workName ? workName : "-"}</td>
              </tr>
              <tr>
                <td className={"table-data"}>{t("Interview")}</td>
                <td>{data?.interview ? t("Yes") : t("No")}</td>
              </tr>
              {data?.interview ? (
                <>
                  <tr>
                    <td className={"table-data"}>{t("Interview time")}</td>
                    <td>{`${data?.start_time_hr || "--"}:${
                      data?.start_time_min || "--"
                    } ~ ${data?.end_time_hr || "--"}:${
                      data?.end_time_min || "--"
                    }`}</td>
                  </tr>
                  <tr className={"line-break"}>
                    <td className={"table-data"}>{t("Interview Content")}</td>
                    <td>{data?.interview_record || "-"}</td>
                  </tr>
                </>
              ) : (
                ""
              )}
              {data?.service_type === SERVICE_TYPE_VALUES.TRANSITION ? (
                <tr>
                  <td className={"table-data"}>
                    {t("Visited  facility name")}
                  </td>
                  <td>{facility_name ? facility_name : "-"}</td>
                </tr>
              ) : null}
              <tr className={"line-break"}>
                <td className={"table-data"}>{t("Support record")}</td>
                <td>{data?.support_record ? data?.support_record : "-"}</td>
              </tr>
              <tr className={"line-break"}>
                <td className={"table-data"}>{t("Condition of user")}</td>
                <td>
                  {data?.condition_of_user ? data?.condition_of_user : "-"}
                </td>
              </tr>
              {data?.service_type === SERVICE_TYPE_VALUES.TRANSITION ? (
                <tr>
                  <td className={"table-data"}>{t("Staff who handled")}</td>
                  <td>
                    {data?.person_in_charge ? data?.person_in_charge : "-"}
                  </td>
                </tr>
              ) : null}
              <tr className={"line-break"}>
                <td className={"table-data"}>{t("Other")}</td>
                <td>{data?.other ? data?.other : "-"}</td>
              </tr>
            </>
          )}
        </table>
      </DynamicTableWrapper>
    </>
  )
}

export default DynamicTable
