import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useQuery } from "react-query"

import dayjs from "dayjs"

import {
  Breadcrumb,
  Button,
  Card,
  LinkButton,
  PageTitle,
  Table,
  WORK_TYPES,
  getLanguage,
  getLocalstorageItem,
  removeBlankAttributes,
  scrollToSelectedElement,
  setItemToLocalstorage,
  useUpdateSearchParams,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import {
  StaffMasterOperationOptions,
  StaffMasterTableDataType,
} from "../../types/staffMaster.types"
import { StaffMasterListContent } from "./staffMaster.style"

import { FULL_DATE_FORMAT_EN, PAGE_SIZE } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import { fetchInstructors } from "../../services"
import { InstructorMasterPermission } from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

import { Flex } from "antd"
import { StaffOperationOption } from "./StaffOperationOption"

const StaffMaster = (): JSX.Element => {
  const { t } = useTranslation()
  const lng = getLanguage()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { queryClient, isOwner, permissions, facilities } =
    useContext(AuthContext)
  const {
    page: currentPage,
    keyword,
    joining_date,
    retirement_date,
    facility,
    occupation,
    work_style,
  } = router?.query as any
  const storedData = getLocalstorageItem("staff-master")
  const listContent = useRef<any>(null)

  const facilityId = facility || storedData?.facility || ""
  const occupations = occupation || storedData?.occupation || ""

  const [searchParams, setSearchParams] = useState<any>({
    page: currentPage ? parseInt(currentPage as string, 10) : 1,
    keyword: keyword || storedData?.keyword || "",
    joining_date: joining_date || storedData?.joining_date || "",
    retirement_date: retirement_date || storedData?.retirement_date || "",
    facility: facilityId?.split(",") || [],
    occupation: occupations?.split(",").map((i) => +i) || [],
    work_style: work_style?.split(",").map((i) => +i) || [],
  })
  const hasWriteAccess =
    isOwner ||
    hasPermissionForMenu(permissions, InstructorMasterPermission, "write")
  const FilterOutUniqueData = (array, key) => {
    const d = Array.from(
      new Map(array.map((item) => [item[key], item])).values(),
    )
    return d
  }
  //fetch all staff-master
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>({
    queryKey: ["staff-master", searchParams],
    queryFn: () =>
      fetchInstructors({
        pageSize: PAGE_SIZE,
        ...searchParams,
        facility: Array.isArray(searchParams?.facility)
          ? searchParams?.facility?.toString()
          : searchParams?.facility,
        occupation: Array.isArray(searchParams?.occupation)
          ? searchParams?.occupation
              .filter((occupationItem) => +occupationItem > 0)
              ?.toString()
          : searchParams?.occupation,
        work_style: Array.isArray(searchParams?.work_style)
          ? searchParams?.work_style
              .filter((workStyleItem) => +workStyleItem > 0)
              ?.toString()
          : searchParams?.work_style,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setItemToLocalstorage("staff-master", {
      facility: values?.facility || null,
      ...values,
    })
    updateParams(queries, "/staff-master")
    scrollToSelectedElement(listContent)
  }

  const onSearch = (values: StaffMasterOperationOptions) => {
    const data = {
      page: values?.page || 1,
      keyword: values?.keyword || "",
      joining_date:
        values?.joining_date_from && values?.joining_date_to
          ? `${dayjs(values?.joining_date_from).format(
              FULL_DATE_FORMAT_EN,
            )}_${dayjs(values?.joining_date_to).format(FULL_DATE_FORMAT_EN)}`
          : "",
      retirement_date:
        values?.retirement_date_from && values?.retirement_date_to
          ? `${dayjs(values?.retirement_date_from).format(
              FULL_DATE_FORMAT_EN,
            )}_${dayjs(values?.retirement_date_to).format(FULL_DATE_FORMAT_EN)}`
          : "",
      facility: values?.facility,
      occupation: values?.occupation,
      work_style: values?.work_style,
    }
    const params = removeBlankAttributes({
      ...data,
      page: values?.page || "",
      facility: values?.facility?.toString(),
      occupation: values?.occupation?.toString(),
      work_style: values?.work_style?.toString(),
    })
    setSearchParams(data)
    handleSearchAndResetParams(params)
  }
  const onSearchReset = () => {
    setSearchParams({
      page: 1,
      keyword: "",
      joining_date: "",
      retirement_date: "",
      facility: [],
      occupation: [],
    })
    handleSearchAndResetParams({}, "reset")
  }

  const columns: ColumnsType<StaffMasterTableDataType[]> = [
    {
      title: t("Edit"),
      dataIndex: "id",
      align: "center",
      key: "id",
      width: 95,
      render: (id) => {
        return (
          <LinkButton
            onClick={() => {
              queryClient.invalidateQueries({
                queryKey: ["edit_instructor"],
              })
              queryClient.invalidateQueries({
                queryKey: ["staff-master"],
              })
              const params = removeBlankAttributes({
                ...searchParams,
                facility: searchParams?.facility?.toString(),
                occupation: searchParams?.occupation?.toString(),
                work_style: searchParams?.work_style?.toString(),
              })
              router.push(
                {
                  pathname: `${router?.pathname}/edit/${id}`,
                  query: params,
                },
                `${router?.pathname}/edit/${id}`,
              )
            }}
            disabled={!hasWriteAccess}
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Instructor name ")}</span>,
      dataIndex: "staff_name",
      key: "staff_name",
      align: "center",
    },
    {
      title: <span className={"col-title"}>{t("Work style")}</span>,
      key: "work_style",
      align: "center",
      render: (row) => {
        const facilitiesData = FilterOutUniqueData(
          row?.staff_facility,
          "workstyle_type",
        )
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {facilitiesData?.map((work: any, i) => (
              <span key={i}>
                {t(
                  WORK_TYPES?.find((val) => val?.value === work?.workstyle_type)
                    ?.label,
                )}
                {facilitiesData.length > 1 &&
                  facilitiesData?.length - 1 !== i && <em>{", "}</em>}
              </span>
            ))}
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Affiliated facility")}</span>,
      dataIndex: "staff_facility",
      key: "staff_facility",
      align: "center",
      render: (row) => {
        const facilitiesData = FilterOutUniqueData(row, "facility_id")
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {facilitiesData?.map((val: any, i) => {
              const facilityName =
                (facilities &&
                  facilities.find((item) => item.id == val?.facility_id)
                    ?.facility_name) ||
                ""
              return (
                <span key={i}>
                  {facilityName}
                  {facilitiesData?.length > 1 &&
                    facilitiesData?.length - 1 !== i &&
                    facilityName && <em>{", "}</em>}
                </span>
              )
            })}
          </div>
        )
      },
    },
    {
      title: <span className={"col-title"}>{t("Occupation")}</span>,
      dataIndex: "staff_facility",
      key: "staff_facility",
      align: "center",
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            justifyContent: "center",
          }}
        >
          {row?.map((val, i) => (
            <span key={i}>
              {lng === "en"
                ? val?.occupation?.occupation_name_eng
                : val?.occupation?.occupation_name}
              {val?.occupation2?.occupation_name
                ? `, ${
                    lng === "en"
                      ? val?.occupation2?.occupation_name_eng
                      : val?.occupation2?.occupation_name
                  }`
                : ""}
              {row?.length > 1 && row?.length - 1 !== i && <em>{", "}</em>}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: <span className={"col-title"}>{t("Joined date")}</span>,
      dataIndex: "joining_date",
      key: "joining_date",
      align: "center",
      render: (row) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {dayjs(row).isValid() && dayjs(row).format("YYYY年MM月DD日")}
        </span>
      ),
    },
    {
      title: <span className={"col-title"}>{t("Retired date")}</span>,
      dataIndex: "retirement_date",
      key: "retirement_date",
      align: "center",
      render: (row) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {dayjs(row).isValid() && dayjs(row).format("YYYY年MM月DD日")}
        </span>
      ),
    },
  ]

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: t("Staff Master"),
          },
        ]}
      />
      <PageTitle
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Staff Master")}
        extra={
          <Button
            btnText={t("Register New Staff")}
            type={"primary"}
            shape={"round"}
            size={"large"}
            iconType={"plus-circle"}
            onClick={() => router.push("/staff-master/add")}
            disabled={!hasWriteAccess}
          />
        }
      />
      <Flex vertical gap={16}>
        {/* operation section start */}
        <StaffOperationOption
          onSearch={(values: StaffMasterOperationOptions) => onSearch(values)}
          onSearchReset={() => onSearchReset()}
          params={searchParams}
        />

        <Card title={t("List")}>
          <StaffMasterListContent ref={listContent}>
            <Table
              current={searchParams?.page}
              total={response?.count || 0}
              pageSize={PAGE_SIZE}
              columns={columns}
              dataSource={response?.data}
              scroll={{ x: 900 }}
              onChange={(val) => {
                onSearch({
                  ...searchParams,
                  page: val,
                })
              }}
              loading={isFetching || isLoading}
              showPaginationOf={"both"}
            />
          </StaffMasterListContent>
        </Card>
      </Flex>
    </>
  )
}

export { StaffMaster }
