import {
  ActionBtns,
  Button,
  Calendar,
  Card,
  MonthPagination,
  theme,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import { CalendarCellContent } from "./CalendarCellContent"
import {
  SCHEDULE_ACTION_TYPE,
  SCHEDULE_INITIAL_OPERATION_PARAM,
  SCHEDULE_INITIAL_STATE,
} from "../../../types/AttendanceSchedule.types"
import { useRouter } from "next/router"
import { useMutation } from "react-query"
import { createOrUpdateAttendanceStaffShiftSchedules } from "../../../services/attendace-schedule.services"
import { MONTH_DATE_FORMAT } from "../../../constants"
import {
  deserializeFormDataIntoAPIPayload,
  getSelectedShiftDataOfAttendanceSchedule,
  serializeAttendanceResponseInToInitialData,
} from "../../../serialize"

const CardInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .info-wrapper {
    a {
      color: ${theme.colors.action};
      text-decoration: underline;
    }
  }
  @media print {
    display: none;
  }
`

export const Wrapper = styled.div<any>`
  @page {
    margin: 0.5cm !important;
  }
`

export const AttendanceMonthlyScheduleCalendarView = ({
  params,
  responseData,
  shiftData,
  staffName,
  setSearchParams,
}: {
  params: SCHEDULE_INITIAL_OPERATION_PARAM
  responseData: any[]
  shiftData?: any
  staffName?: string
  setSearchParams: React.Dispatch<
    React.SetStateAction<SCHEDULE_INITIAL_OPERATION_PARAM>
  >
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { showToast } = useNotification()
  const initialValues = {
    attendance_start_time_hr: "",
    attendance_start_time_min: "",
    attendance_end_time_hr: "",
    attendance_end_time_min: "",
    attendance_start_time_hr2: "",
    attendance_start_time_min2: "",
    attendance_end_time_hr2: "",
    attendance_end_time_min2: "",
    attendance_rest_minits: "",
    attendance_rest_minits2: "",
    attendance_shift_id: "",
    is_deleted: false,
  }

  const monthInitialData = {}
  useMemo(() => {
    Array.from(
      { length: dayjs(params?.date).daysInMonth() || 31 },
      (_, index) => {
        monthInitialData[`${index + 1}`] = [{ id: 1, ...initialValues }]
      },
    )
  }, [])

  const [data, setData] = useState<SCHEDULE_INITIAL_STATE>(monthInitialData)
  const { isLoading, mutate } = useMutation({
    mutationFn: createOrUpdateAttendanceStaffShiftSchedules,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const onSave = () => {
    const newData = deserializeFormDataIntoAPIPayload({
      data,
      params,
    })
    mutate(newData)
  }
  const serializeResponseToInitialData = () => {
    const newData = serializeAttendanceResponseInToInitialData({
      monthInitialData: monthInitialData,
      responseData: responseData,
      params,
      initialValues,
    })
    setData(newData)
  }
  useEffect(() => {
    serializeResponseToInitialData()
  }, [responseData])

  return (
    <Wrapper>
      <Card
        title={t("{{staffName}} {{date}} Attendance schedule", {
          staffName: staffName,
          date: dayjs(params?.date)?.format(MONTH_DATE_FORMAT),
        })}
        className={"print-section"}
        extra={
          <MonthPagination
            currentMonth={dayjs(params?.date)?.month()}
            currentYear={dayjs(params?.date)?.year()}
            showDate={false}
            onMonthChange={(year, month) => {
              const date = dayjs([year, month])
              updateParams(
                {
                  year: date?.year(),
                  month: date?.month() + 1,
                  facility_id: params?.facility_id,
                  staff_id: params?.staff_id || "",
                },
                "/attendance-schedule/monthly-schedule",
              )
              setSearchParams({
                ...params,
                date: date,
              })
            }}
          />
        }
      >
        <CardInfoContainer>
          <div className={"info-wrapper"}>
            {t(
              "Please select a shift from the pull-down menu for each date in the calendar below.",
            )}
            <br />
            <Trans i18nKey={"shiftRegisterSelectInfo"}>
              {"The shifts that can be selected are those registered in the"}
              <a
                target={"_blank"}
                href={"/shift-master"}
                rel={"noopener noreferrer"}
                className={"with-underline color-blue"}
              >
                {"shift master."}
              </a>
            </Trans>
          </div>
          <div>
            <Button
              btnText={t("Print this")}
              shape={"round"}
              iconType={"print"}
              type={"primary"}
              onClick={() => window.print()}
              className={"hide-on-print"}
            />
          </div>
        </CardInfoContainer>
        <Calendar
          value={dayjs(params?.date || dayjs())}
          cellRender={(date: Dayjs) => {
            const dateOf = dayjs(date)?.date()
            const currentData = data[dateOf] || []
            const isHoliday = currentData?.[0]?.holiday_flag === "1"

            return {
              isHoliday: isHoliday,
              content: (
                <CalendarCellContent
                  dataOf={dateOf}
                  data={currentData}
                  handHourAddRemove={(
                    key: any,
                    action: SCHEDULE_ACTION_TYPE,
                  ) => {
                    if (action === "add") {
                      setData({
                        ...data,
                        [`${key}`]: [
                          ...data[`${key}`],
                          {
                            ...initialValues,
                            id: 2,
                          },
                        ],
                      })
                    }
                    if (action === "remove") {
                      setData({
                        ...data,
                        [`${key}`]: data[`${key}`]?.filter((v) => v?.id !== 2),
                      })
                    }
                  }}
                  handleInputChange={(
                    monthDate: any,
                    key: string,
                    value: string,
                  ) => {
                    if (key === "attendance_shift_id") {
                      const newData = getSelectedShiftDataOfAttendanceSchedule({
                        shiftData: shiftData?.options || [],
                        shiftId: value,
                        initialValues,
                      })
                      setData({
                        ...data,
                        [`${monthDate}`]: newData,
                      })
                    } else {
                      const newData = data[`${monthDate}`]?.map((v) => ({
                        ...v,
                        [key]: value,
                      }))

                      setData({
                        ...data,
                        [`${monthDate}`]: newData,
                      })
                    }
                  }}
                  shiftData={
                    shiftData?.options?.filter(
                      (shift) =>
                        shift?.attendance_shift_facilities?.some(
                          (v) => v?.facility_id === +params?.facility_id,
                        ),
                    ) || []
                  }
                />
              ),
            }
          }}
        />
        <ActionBtns justify={"flex-start"} mt={"16px"}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={() => router.push("/attendance-schedule")}
            disabled={isLoading}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            type={"primary"}
            onClick={onSave}
            isLoading={isLoading}
          />
        </ActionBtns>
      </Card>
    </Wrapper>
  )
}
