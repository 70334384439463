import { theme } from "@project/common"

import React from "react"
import styled from "styled-components"
import { TriangleBox } from "../../components"
import { useTranslation } from "react-i18next"
const Wrapper = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 8px;
  .content {
    min-width: 250px;
  }
  @media print {
    padding: 4px !important;
    row-gap: 4px !important;
    column-gap: 4px !important;
    & > * {
      font-size: 8pt !important;
    }
  }
`

export const UserAttendanceSummary = ({
  summaryInfo,
}: {
  summaryInfo: any
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className={"content"}>
        <TriangleBox
          label={t("Days used")}
          value={`${summaryInfo?.days_used || "0"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("Average number of daily users/rate")}
          value={`${summaryInfo?.avergae_daily_users_rate || "0.0人/0.0%"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("Number of absentees I/rate")}
          value={`${summaryInfo?.absentees_rate || "0.0人/0.0%"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("Number of absentees I (no addition) / rate")}
          value={`${summaryInfo?.absentees_no_addition_rate || "0.0人/0.0%"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("3-month average number of users/rate")}
          value={`${summaryInfo?.three_month_average_rate || "0.0人/0.0%"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("Number of participants")}
          value={`${summaryInfo?.number_of_participants || "0"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("No. of absentees II / rate")}
          value={`${summaryInfo?.absentees_2_rate || "0.0人/0.0%"}`}
        />
      </div>
      <div className={"content"}>
        <TriangleBox
          label={t("Unregistered users")}
          value={`${summaryInfo?.unregistered_users || "0"}`}
        />
      </div>
    </Wrapper>
  )
}
