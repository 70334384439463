import { Button, PAZE_SIZE_OPTIONS, SelectInput } from "@project/common"
import { AbsenceRecordActionButtonsContainer } from "./AbsenceRecord.styles"
import { useTranslation } from "react-i18next"
import { Pagination } from "antd"
import { UserAbsenceButtonActions } from "../../../types/UserAttendanceAbsenceRecord.types"

export const AbsenceRecordButtonActions = (props: UserAbsenceButtonActions) => {
  const { onBack, onPrint, paginationParams, handlePaginationChange } = props
  const { t } = useTranslation()
  return (
    <AbsenceRecordActionButtonsContainer>
      <div className={"back-print-container"}>
        <Button btnText={`<< ${t("Back")}`} shape={"round"} onClick={onBack} />
        <Button
          btnText={t("Print")}
          type={"primary"}
          shape={"round"}
          iconType={"print"}
          onClick={onPrint}
        />
      </div>
      <div className={"pagination-container"}>
        <Pagination
          pageSize={paginationParams.pageSize}
          total={paginationParams.total}
          current={paginationParams.page}
          onChange={(page) =>
            handlePaginationChange({ ...paginationParams, page })
          }
          showSizeChanger={false}
        />
        <SelectInput
          name={"page-size"}
          options={PAZE_SIZE_OPTIONS}
          value={paginationParams.pageSize}
          onChange={(val) => handlePaginationChange({ page: 1, pageSize: val })}
          width={"60px"}
        />
      </div>
    </AbsenceRecordActionButtonsContainer>
  )
}
