import { useTranslation } from "react-i18next"
import { contentsTableRows } from "./DUOBPUtilsFn"
import { ActualTableContent } from "./DisabledUserOutpatientBenefitsPrintContent.style"

export const ServiceContentsTable = ({ data }: { data: any }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ActualTableContent>
      <tbody id={"duobp__table-body"}>
        <tr>
          <td className={"b-2"} rowSpan={14}>
            <span id={"rotate-190"}>
              {" "}
              {t("Benefit expense details column")}
            </span>
          </td>
          <td className={"align-center b-2"}>{t("Service contents")}</td>
          <td className={"align-center b-2"}>{t("Service code")}</td>
          <td className={"align-center b-2"}>{t("Number of units")}</td>
          <td className={"align-center b-2"}>{t("Number of times")}</td>
          <td className={"align-center b-2"}>{t("Number of service units")}</td>
          <td className={"align-center b-2"}>{t("Abstract")}</td>
        </tr>
        {contentsTableRows(data?.c_04_to_c08 || [])?.map(
          (row: any, index: number) => (
            <tr key={index}>
              <td className={"align-center h-30"}>{row?.c_04 || ""}</td>
              <td className={"align-center h-30"}>{row?.c_05 || ""}</td>
              <td className={"align-center h-30"}>{row?.c_06 || ""}</td>
              <td className={"align-center h-30"}>{row?.c_07 || ""}</td>
              <td className={"align-center h-30"}>{row?.c_08 || ""}</td>
              <td className={"align-center h-30"}>{row?.c_09 || ""}</td>
            </tr>
          ),
        )}
      </tbody>
    </ActualTableContent>
  )
}
