import {
  AntTable,
  Button,
  Card,
  CircleButton,
  InputField,
  SelectInput,
  Table,
  dynamicLangString,
  theme,
  useNotification,
} from "@project/common"
import type { ColumnsType } from "antd/lib/table"
import Image from "next/image"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useFetchMealMaster } from "../../../hooks/useFetchData"
import { MealMasterAmountPerUseField } from "../../../types"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import styled from "styled-components"
import { useMutation } from "react-query"
import { updateMealInformation } from "../../../services"

interface AmountPerUseDetailTableProps {
  dataSource?: any[]
  username?: string
  hasWriteAccess?: boolean
  userFacilityId: number
}
const Wrapper = styled.div`
  .amount_per_use_summary {
    background-color: ${theme.colors["accent-light"]};
  }
`

interface ICombinedCell {
  type?: "holiday" | "unschedule"
}

const CombinedCell = styled.div<ICombinedCell>`
  background: ${(props) =>
    props?.type == "holiday" ? theme.colors["error-light"] : "#fff"};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .holiday-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`

const AmountPerUseDetailTable = ({
  dataSource: tempDataSource,
  username,
  hasWriteAccess = false,
  userFacilityId,
}: AmountPerUseDetailTableProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { date, userId } = router.query as any
  const [dataSource, setDataSource] = useState(tempDataSource)
  const { showToast } = useNotification()
  const { mealData, isLoading } = useFetchMealMaster({
    page: 1,
    pageSize: "Infinity",
    facilityIds: userFacilityId?.toString(),
    enabled: !!userFacilityId,
  })
  const { isLoading: isSaving, mutate } = useMutation({
    mutationFn: updateMealInformation,
    onSuccess: () => {
      const message = dynamicLangString([
        "Meal Information",
        "Updated Successfully",
      ])
      showToast({
        type: "success",
        message,
      })
      if (!isSaving) {
        const newSource = dataSource?.map((values) => {
          return { ...values, isEditing: false }
        })
        setDataSource(newSource)
      }
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      showToast({
        type: "error",
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const columns: ColumnsType<MealMasterAmountPerUseField[]> = [
    {
      title: t("Day"),
      key: "day",
      dataIndex: "day",
      render: (data) => {
        return data
      },
      width: 50,
      align: "center",
    },
    {
      title: t("Day Of Week"),
      key: "day_of_week",
      dataIndex: "day_of_week",
      width: 105,
      align: "center",
    },
    {
      title: t("Lunch Box"),
      key: "meal_id",
      dataIndex: "meal_id",
      align: "center",
      width: 400,
      render: (data: string, row: any) => {
        const meal = mealData?.data?.find((v) => v?.value === data)?.label
        return row?.is_holiday ? (
          <CombinedCell type={"holiday"}>
            <div className={"holiday-content"}>{t("Holiday")}</div>
          </CombinedCell>
        ) : !row?.is_scheduled ? (
          <CombinedCell>
            <div className={"holiday-content"}>{t("Not Scheduled")}</div>
          </CombinedCell>
        ) : row?.isEditing ? (
          <>
            <SelectInput
              width={"100%"}
              value={data}
              options={[{ label: "--", value: null }].concat(
                mealData?.data || [],
              )}
              loading={isLoading}
              placeholder={"--"}
              name={"name"}
              style={{
                textAlign: "left",
              }}
              onChange={(val) => {
                const selected = mealData?.data?.find((v) => v?.id === +val)
                const data = dataSource?.map((ds) =>
                  ds?.day === row?.day
                    ? {
                        ...ds,
                        price:
                          selected?.id == ds?.old_meal_id
                            ? ds?.old_meal_price
                            : selected?.cost,
                        meal_id: selected?.value,
                      }
                    : ds,
                )
                setDataSource(data)
              }}
            />
          </>
        ) : (
          <>{meal || ""}</>
        )
      },
      onCell: (data: any) => {
        if (data?.is_holiday || !data?.is_scheduled) {
          return {
            colSpan: 3,
          }
        }
        return {}
      },
    },
    {
      title: t("Price"),
      key: "price",
      dataIndex: "price",
      align: "center",
      width: 400,
      render: (data, row: any) => {
        return row?.isEditing ? (
          <>
            <InputField height={"40px"} disabled readOnly value={data} />
          </>
        ) : (
          <span>
            {data || 0} {t("Yen")}
          </span>
        )
      },
      onCell: (data: any) => {
        if (data?.is_holiday || !data?.is_scheduled) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
    {
      title: t("Action "),
      key: "Action",
      align: "center",
      width: 150,
      render: (row) => {
        const handleDataSourceModification = (action: "edit" | "cancel") => {
          if (action === "edit") {
            const newSource = dataSource?.map((values) =>
              values?.day === row?.day
                ? {
                    ...values,
                    isEditing: action === "edit" ? true : false,
                    selectedDay: row?.day,
                  }
                : values,
            )
            setDataSource(newSource)
          }
          if (action === "cancel") {
            const newSource = dataSource?.map((values) =>
              values?.day === row?.day
                ? {
                    ...values,
                    isEditing: false,
                    meal_id: values?.old_meal_id,
                    price: values?.old_meal_price,
                  }
                : values,
            )
            setDataSource(newSource)
          }
        }
        if (row?.isEditing) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0.5em",
                alignItems: "center",
              }}
            >
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                width={"fit-content"}
                isLoading={isSaving}
                onClick={() => {
                  const currentDay = dayjs(`${date}-${row?.selectedDay}`)
                  const data = [
                    {
                      user_id: +userId,
                      meal_id: row?.meal_id,
                      cost: row?.price,
                      date: currentDay.format("YYYY-MM-DD"),
                    },
                  ]
                  mutate(data)
                }}
              />
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                width={"fit-content"}
                btnBg={theme.colors.disabled2}
                borderColor={theme.colors.disabled2}
                disabled={isSaving}
                onClick={() => {
                  handleDataSourceModification("cancel")
                }}
              />
            </div>
          )
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "0.5em",
              alignItems: "center",
            }}
          >
            <CircleButton
              size={"large"}
              type={"link"}
              disabled={!hasWriteAccess}
              onClick={() => {
                handleDataSourceModification("edit")
              }}
              Icon={
                <Image
                  width={20}
                  height={20}
                  src={"/assets/icons/editIcon.svg"}
                />
              }
            />
          </div>
        )
      },
      onCell: (data: any) => {
        if (data?.is_holiday || !data?.is_scheduled) {
          return {
            colSpan: 0,
          }
        }
        return {}
      },
    },
  ]

  return (
    <>
      <Wrapper>
        <Card
          title={t("{{username}}’s {{date}} Meal Information", {
            username: username,
            date: dayjs(date)?.isValid()
              ? dayjs(date).format("YYYY年MM月")
              : "",
          })}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            cellPaddingBlock={10}
            summary={(data) => {
              const total = data?.reduce(
                (acc, curr) => {
                  return {
                    price: acc?.price + curr?.price,
                  }
                },
                {
                  price: 0,
                },
              )

              return (
                <AntTable.Summary>
                  <AntTable.Summary.Row>
                    <AntTable.Summary.Cell
                      index={0}
                      colSpan={3}
                      align={"center"}
                      className={"amount_per_use_summary"}
                    >
                      {t("Total")}
                    </AntTable.Summary.Cell>
                    <AntTable.Summary.Cell
                      index={1}
                      colSpan={2}
                      align={"center"}
                      className={"amount_per_use_summary"}
                    >
                      {new Intl.NumberFormat().format(total?.price)} {t("Yen")}
                    </AntTable.Summary.Cell>
                  </AntTable.Summary.Row>
                </AntTable.Summary>
              )
            }}
          />
        </Card>
      </Wrapper>
    </>
  )
}

export { AmountPerUseDetailTable }
