// types
import { StaticManufacturingCostDataType as StaticColumnsType } from "../../../types"

export const staticRowsData: StaticColumnsType[] = [
  // I. material cost table body
  {
    id: "i_material_cost",
    key: "i_material_cost",
    subject: "I. Material cost",
    isCategory: true,
  },
  {
    id: "term_war_material_inventory",
    key: "term_war_material_inventory",
    subject: "1. Term War material inventory",
    isCategory: false,
    total: 0,
  },
  {
    id: "fine_year_material_purchase_height",
    key: "fine_year_material_purchase_height",
    subject: "2. Fine -year material purchase height",
    isCategory: false,
    total: 0,
  },
  {
    id: "total",
    key: "total",
    subject: "Total",
    isCategory: false,
    total: 0,
    notEditable: true,
  },
  {
    id: "involved_in_terms_of_term",
    key: "involved_in_terms_of_term",
    subject: "3. Involved in terms of term at the end of the term",
    isCategory: false,
    total: 0,
  },
  {
    id: "form_material_cost",
    key: "form_material_cost",
    subject: "Form material cost",
    notEditable: true,
    total: 0,
  },

  // II. Labor expenses
  {
    id: "ii_labor_expense",
    key: "ii_labor_expense",
    subject: "Ⅱ. Labor expenses",
    isCategory: true,
  },
  {
    id: "user_wage",
    key: "user_wage",
    subject: "1. User wage",
    total: 0,
  },
  {
    id: "user_fee",
    key: "user_fee",
    subject: "2. User wage",
    total: 0,
  },
  {
    id: "salary_of_employment_support_business_instructors",
    key: "salary_of_employment_support_business_instructors",
    subject: "3. Salary of employment support business instructors",
    total: 0,
  },
  {
    id: "instructor_bonus_reward",
    key: "instructor_bonus_reward",
    subject:
      "4. Employment support business instructor, bonus bonus rewards, etc.",
    total: 0,
  },
  {
    id: "instructor_retirement_benefit_cost",
    key: "instructor_retirement_benefit_cost",
    subject:
      "5. Employment support business instructor, retirement benefit cost",
    total: 0,
  },
  {
    id: "legal_welfare_expenses",
    key: "legal_welfare_expenses",
    subject: "6. Legal welfare expenses",
    total: 0,
  },
  {
    id: "formor_labor_cost",
    key: "formor_labor_cost",
    subject: "Former labor cost",
    total: 0,
  },

  // Ⅲ. Outsourcing cost
  {
    id: "iii_outsourcing_cost",
    key: "iii_outsourcing_cost",
    subject: "Ⅲ. Outsourcing cost",
    isCategory: true,
  },
  {
    id: "internal_outsourcing_cost",
    key: "internal_outsourcing_cost",
    subject: "(Of which internal outsourcing costs)",
    total: 0,
  },
  {
    id: "formor_outsourcing_cost",
    key: "formor_outsourcing_cost",
    subject: "Former outsourcing cost",
    total: 0,
  },

  // Ⅳ. expenses
  {
    id: "iv_expense",
    key: "iv_expense",
    subject: "Ⅳ. expenses",
    isCategory: true,
  },
  {
    id: "welfare_expense",
    key: "welfare_expense", // 18 index
    subject: "1. Welfare expense",
  },
  {
    id: "travel_expense",
    key: "travel_expense",
    subject: "2. travel expenses",
  },
  {
    id: "packed_rent_cost",
    key: "packed_rent_cost",
    subject: "3. Packed rent cost",
  },
  {
    id: "supplies_expense",
    key: "supplies_expense",
    subject: "4. supplies expense",
  },
  {
    id: "rents",
    key: "rents",
    subject: "5. Rents",
  },
  {
    id: "water_supply_expense",
    key: "water_supply_expense",
    subject: "6. Water supply expenses",
  },
  {
    id: "fuel_cost",
    key: "fuel_cost",
    subject: "7. Fuel cost",
  },
  {
    id: "repair_cost",
    key: "repair_cost",
    subject: "8. repair costs",
  },
  {
    id: "communication_transportation_cost",
    key: "communication_transportation_cost",
    subject: "9. Communication transportation cost",
  },
  {
    id: "meeting_expense",
    key: "meeting_expense",
    subject: "10. Meeting expenses",
  },
  {
    id: "non_life_insurance_premium",
    key: "non_life_insurance_premium",
    subject: "11. Non-life insurance premium",
  },
  {
    id: "rent",
    key: "rent",
    subject: "12. Rent", // As of now data doesn't come from the backend
    notEditable: true,
  },
  {
    id: "books_and_educational_expenses",
    key: "books_and_educational_expenses",
    subject: "13. Books and educational expenses",
  },
  {
    id: "vehicle_cost",
    key: "vehicle_cost",
    subject: "14. Vehicle costs",
  },
  {
    id: "depreciation_expense",
    key: "depreciation_expense",
    subject: "15. Depreciation expenses",
  },
  {
    id: "special_reserve_depreciation",
    key: "special_reserve_depreciation",
    subject:
      "16. Special reserve deprecation such as national treasury subsidies (deduction items)",
  },
  {
    id: "miscellaneous_expenses",
    key: "miscellaneous_expenses",
    subject: "17. Miscellaneous expenses", // 34 index
  },
  {
    id: "expense",
    key: "expense",
    subject: "Expense",
    notEditable: true,
  },
  {
    id: "employment_support_total_cost",
    key: "employment_support_total_cost",
    subject:
      "Total cost of manufacturing employment support projects in the current period",
    notEditable: true,
  },
  {
    id: "term_table_based_product_wholesaler",
    key: "term_table_based_product_wholesaler",
    notEditable: true,
    subject: "Term Table -based product wholesaler",
  },
  {
    id: "grand_total",
    key: "grand_total",
    notEditable: true,
    subject: "Total",
  },
  {
    id: "terminated_goods_wholesaler",
    key: "terminated_goods_wholesaler",
    notEditable: true,
    subject: "Terminated goods wholesaler",
  },
  {
    id: "current_period_manufacturing_price",
    key: "current_period_manufacturing_price",
    subject:
      "Price for manufacturing employment support projects in the current period",
    notEditable: true,
  },
]

export const attribsToSumUp = [
  "welfare_expense",
  "travel_expense",
  "packed_rent_cost",
  "supplies_expense",
  "rents",
  "water_supply_expense",
  "fuel_cost",
  "repair_cost",
  "communication_transportation_cost",
  "meeting_expense",
  "non_life_insurance_premium",
  "rent",
  "books_and_educational_expenses",
  "vehicle_cost",
  "depreciation_expense",
  "special_reserve_depreciation",
  "miscellaneous_expenses",
]
