import {
  AccordionCard,
  ActionBtns,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OpFlexContainer,
  OperationSearchContent,
  RadioGroup,
  SelectInput,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../context"
import { YES_NO_LIST } from "../../constants"
import { UserAttendanceCalendarParams } from "../../types"
dayjs.extend(arraySupport)
export const UserAttendanceCalendarOperation = ({
  params,
  setParams,
}: {
  params: UserAttendanceCalendarParams
  setParams: React.Dispatch<React.SetStateAction<UserAttendanceCalendarParams>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const [updateParams] = useUpdateSearchParams()
  const [tempSearchParams, setTempSearchParams] = useState({
    ...params,
  })
  useEffect(() => setTempSearchParams(params), [params])

  return (
    <AccordionCard
      items={[
        {
          key: "user_attendance_calendar",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <OpFlexContainer>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Facility")}
                      width={"150px"}
                    />
                    <SelectInput
                      name={"facility"}
                      options={facilities}
                      placeholder={"--"}
                      value={tempSearchParams?.facilityId || null}
                      width={"306px"}
                      onChange={(val) =>
                        setTempSearchParams({
                          ...tempSearchParams,
                          facilityId: val,
                        })
                      }
                    />
                  </div>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Year and month")}
                      width={"150px"}
                    />
                    <DatePicker
                      picker={"month"}
                      date={dayjs([
                        tempSearchParams?.year,
                        tempSearchParams?.month - 1,
                      ])}
                      onDateChange={(date: Dayjs) => {
                        setTempSearchParams({
                          ...tempSearchParams,
                          year: dayjs(date).year(),
                          month: dayjs(date).month() + 1,
                        })
                      }}
                      format={"YYYY年MM月"}
                    />
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <OpFlexContainer>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Desired Pick&drop time")}
                      width={"150px"}
                    />
                    <div
                      style={{
                        width: "306px",
                      }}
                    >
                      <RadioGroup
                        options={YES_NO_LIST}
                        value={+tempSearchParams?.with_desired_pickup || ""}
                        onChange={({ target: { value } }) => {
                          setTempSearchParams({
                            ...tempSearchParams,
                            with_desired_pickup: value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className={"flex__content"}>
                    <LabelTextWithLeftArrow
                      label={t("Attendees")}
                      width={"150px"}
                    />
                    <RadioGroup
                      options={YES_NO_LIST}
                      value={+tempSearchParams?.with_commuter || ""}
                      onChange={({ target: { value } }) => {
                        setTempSearchParams({
                          ...tempSearchParams,
                          with_commuter: value,
                        })
                      }}
                    />
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content hide-phase-2"}>
                <LabelTextWithLeftArrow
                  label={t("Event name")}
                  width={"150px"}
                />
                <RadioGroup
                  options={YES_NO_LIST}
                  value={+tempSearchParams?.with_program || ""}
                  onChange={({ target: { value } }) => {
                    setTempSearchParams({
                      ...tempSearchParams,
                      with_program: value,
                    })
                  }}
                />
              </div>
              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Display Change")}
                  size={"large"}
                  shape={"round"}
                  borderColor={theme.colors.primary}
                  onClick={() => {
                    const storageData = {
                      facilityId: tempSearchParams?.facilityId,
                      year: tempSearchParams?.year,
                      month: tempSearchParams?.month,
                    }
                    localStorage.setItem(
                      "mySavedData",
                      JSON.stringify(storageData),
                    )
                    setParams(tempSearchParams)
                    updateParams(tempSearchParams, "/user-attendance-calendar")
                  }}
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
