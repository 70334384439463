import styled from "styled-components"

export const MonitoringContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  .user_monitoring_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 1em;
  }
`
export const TableContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 18px;
  flex-wrap: wrap;
`
export const TMiniContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
