// commons
import { keys } from "@project/common"

// types
import { RewardAttribs } from "../types"
import { SystemStatusMgmtDataType } from "../../../services/system-status-mgmt.service"

// constants
import { AVERAGE_WORKING_POINT } from "../constants"
import dayjs from "dayjs"

export const calculateScore = (rewardAttribs: RewardAttribs) => {
  return keys(AVERAGE_WORKING_POINT).reduce<number>((accPoint, currKey) => {
    const value = rewardAttribs[currKey]

    if (value) {
      return accPoint + AVERAGE_WORKING_POINT[currKey][value]
    }
    return accPoint
  }, 0)
}

export function findAttendanceExceedingDays(
  capacity: number,
  attendance_dates: SystemStatusMgmtDataType["attendance_dates"],
) {
  const daysExceedingCapacity = attendance_dates
    ?.filter((num) => num.present_count && num.present_count > capacity * 1.5)
    ?.map((item) => `${dayjs(item.date).format("MM/DD")}`)
    ?.join(", ")

  if (!daysExceedingCapacity) {
    return ""
  }
  return daysExceedingCapacity
}
