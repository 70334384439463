import { Button } from "@project/common"
import { UserServiceProvisionResultButtonContainer } from "./UserServiceProvisionResult.styles"
import { Trans, useTranslation } from "react-i18next"
import { IUserServiceProvisionResultButtonAction } from "../../types/UserServiceProvisionRecord.types"

export const UserServiceProvisionResultButtonAction = (
  props: IUserServiceProvisionResultButtonAction,
) => {
  const { t } = useTranslation()
  const {
    disablePrint,
    printAction,
    backAction,
    showOutputNumber = false,
    outputNumber = 0,
  } = props
  return (
    <UserServiceProvisionResultButtonContainer>
      <Button
        btnText={`<< ${t("Back")}`}
        shape={"round"}
        onClick={backAction}
      />
      <Button
        btnText={t("Print")}
        iconType={"print"}
        shape={"round"}
        type={"primary"}
        onClick={printAction}
        disabled={disablePrint}
      />
      {showOutputNumber && (
        <div className={"output-number"}>
          <Trans key={"service-result-output"}>
            {t("Output number of people")}
            &nbsp;
            <strong>
              {t("{{outputNumber}}", {
                outputNumber: outputNumber,
              })}
            </strong>
            &nbsp;
            {t("people in total")}
          </Trans>
        </div>
      )}
    </UserServiceProvisionResultButtonContainer>
  )
}
