import {
  AccordionCard,
  ActionBtns,
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
  useUpdateSearchParams,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { YM_FORMAT } from "../../../../constants"
import { useApplicationManagement } from "../../../../context/ApplicationManagementContext"
import { useFetchAllUsers } from "../../../../hooks/useFetchData"
import { filterWithHiragana } from "../../../../utils"
import { FuriganaAlphabetsOption } from "../../../../utils/common-options"
dayjs.extend(arraySupport)
export const ApplicationManagementOperation = ({
  onDisplayChange,
}: {
  onDisplayChange: () => void
}): JSX.Element => {
  const { params, setParams, user_id } = useApplicationManagement()
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { userData, isLoading, isFetching } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })
  const [values, setValues] = useState({
    year: params?.year,
    month: params?.month,
    furigana: "",
    user_id: params?.user_id || null,
  })
  useEffect(() => {
    setValues({
      ...values,
      ...params,
    })
  }, [params])

  return (
    <AccordionCard
      activeKey={["application_management"]}
      items={[
        {
          key: "application_management",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year Month")}
                  width={"136px"}
                />
                <DatePicker
                  date={
                    dayjs([values?.year, values?.month]).isValid()
                      ? dayjs([values?.year, values?.month - 1])
                      : dayjs()
                  }
                  onDateChange={(date: Dayjs) =>
                    setValues({
                      ...values,
                      year: dayjs(date)?.year(),
                      month: dayjs(date)?.month() + 1,
                    })
                  }
                  picker={"month"}
                  format={YM_FORMAT}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User Name")}
                  width={"136px"}
                />

                <SelectInput
                  name={"name_hiragana"}
                  shortSelector
                  width={"78px"}
                  placeholder={"--"}
                  options={FuriganaAlphabetsOption}
                  value={values?.furigana || null}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      furigana: val,
                      user_id: null,
                    })
                  }
                />
                <SelectInput
                  name={"user"}
                  placeholder={"--"}
                  loading={isLoading || isFetching}
                  value={values?.user_id || null}
                  options={
                    filterWithHiragana({
                      options: userData || [],
                      furigana: values?.furigana || "",
                    }) || []
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      user_id: val,
                    })
                  }
                />
              </div>
              <ActionBtns justify={"flex-start"}>
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    updateParams(
                      {},
                      `/users/profile/${user_id}/application-management`,
                    )
                    setValues({
                      year: dayjs().year(),
                      month: dayjs().month(),
                      user_id: null,
                      furigana: null,
                    })
                    setParams({
                      year: dayjs().year(),
                      month: dayjs().month(),
                      user_id: null,
                    })
                    onDisplayChange()
                  }}
                />
                <Button
                  btnText={t("Search")}
                  iconType={"search"}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => {
                    updateParams(
                      {
                        year: values?.year || null,
                        month: values?.month || null,
                      },
                      `/users/profile/${values?.user_id}/application-management`,
                    )
                    setParams({ ...values })
                    onDisplayChange()
                  }}
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
