import React from "react"

// packages
import { Flex } from "antd"

// commons | components
import {
  Box,
  SelectInput,
  ErrorMassage,
  CALENDAR_HOURS_MINUTES,
  getEndTimeHour,
} from "@project/common"

// utils
import { getHrMin } from "../../ServiceProvisionRecord/utils/getSplittedTime"

// types
import { SelectTimeProps } from "../types"

// !## MAIN COMPONENT
export const SelectTime: React.FC<SelectTimeProps> = ({
  error,
  value,
  onTimeChange,
  disabled,
  item,
  isEndTime,
}) => {
  return (
    <Box display={"flex"} direction={"column"} align={"flex-start"}>
      <Flex align={"center"} gap={"8px"}>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"hr"}
            width={"58px"}
            placeholder={"--"}
            value={getHrMin(value)?.hr}
            options={
              isEndTime
                ? getEndTimeHour(item?.start_time)
                : CALENDAR_HOURS_MINUTES.hours
            }
            disabled={disabled}
            onChange={(hr) => {
              if (!hr) {
                onTimeChange("")
                return
              }

              if (!getHrMin(value)?.min && hr) {
                onTimeChange(`${hr}:00`)
                return
              }

              onTimeChange(`${hr}:${getHrMin(value)?.min}`)
            }}
          />
        </Box>
        <span>{":"}</span>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"min"}
            width={"58px"}
            placeholder={"--"}
            value={getHrMin(value)?.min}
            disabled={disabled || !getHrMin(value)?.hr}
            options={CALENDAR_HOURS_MINUTES.minutes}
            onChange={(min) => {
              onTimeChange(`${getHrMin(value)?.hr}:${min}`)
            }}
          />
        </Box>
      </Flex>
      {error && <ErrorMassage message={error} />}
    </Box>
  )
}
