import {
  Button,
  Card,
  LinkButton,
  PageTitle,
  Table,
  formattedDateAndTime,
  scrollToSelectedElement,
  theme,
} from "@project/common"
import { Flex } from "antd"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { FULL_DATE_TIME_FORMAT, PAGE_SIZE } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import { fetchFacilityInformation } from "../../services/facilityInformation"
import { FacilityInformationColumn } from "../../types"
import {
  BusinessListPermission,
  DailyBusinessReportPermission,
  FacilityInformationPermission,
  hasPermissionForFacility,
  SystemMailSettings,
  SystemStatusManagementPermission,
  TransportationManagementPermission,
  UserManagementPermission,
} from "../../utils/PermissionKeys"

const StyleFacilityInformationWrapper = styled.div`
  margin-top: 16px;
  .actions-wrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    button {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 8px !important;
      white-space: nowrap;
    }
  }
  .draft-facility {
    color: #ff7875;
    font-size: 12px;
  }
`

const FacilityInformation = (): JSX.Element => {
  const { t } = useTranslation()
  const { permissions, isOwner } = useContext(AuthContext)
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState(+router?.query?.page || 1)
  const listRef = useRef<HTMLDivElement>(null)
  const {
    isLoading,
    isFetching,
    data: facilityInformationData,
  } = useQuery(["fetchFacilityInformation", currentPage], {
    queryFn: fetchFacilityInformation,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })

  const hasAccess = (
    facilityId,
    permissionKeys,
    permissionType: "both" | "write" = "write",
  ) => {
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        permissionKeys,
        permissionType,
        facilityId,
      )
    )
  }

  const columns: ColumnsType<FacilityInformationColumn> = [
    {
      title: t("Details"),
      dataIndex: "details",
      key: "details",
      width: 140,
      align: "center",
      render: (_, data: any) => {
        return (
          <LinkButton
            disabled={!hasAccess(data?.id, FacilityInformationPermission)}
            onClick={() =>
              router.push(`/facility-information/edit/${data?.id}`)
            }
          >
            {t("Details")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Facility Name "),
      dataIndex: "facility_name",
      key: "facility_name",
      align: "center",
      width: 120,
      render: (value, record) => (
        <div>
          <div>{value}</div>
          <div className={"draft-facility"}>
            {record?.is_draft ? t("Draft") : ""}
          </div>
        </div>
      ),
    },
    {
      title: t("Facility Settings"),
      align: "center",
      dataIndex: "facility_settings",
      key: "facility_settings",
      width: 455,
      render: (_: any, data: any) => (
        <div className={"actions-wrapper"}>
          <Button
            btnText={t("Business day management")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() => {
              const date = dayjs()
              router.push(
                `/business-day-management/registration?facilityId=${data?.id}&year=${date.year()}&month=${
                  date.month() + 1
                }&tab=bulk_setting_for_year`,
              )
            }}
            disabled={!hasAccess(data?.id, BusinessListPermission)}
          />

          <Button
            btnText={t("System status management")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(`/facility-information/system-status/${data?.id}`)
            }
            disabled={
              !hasAccess(data?.id, SystemStatusManagementPermission, "both")
            }
          />
          <Button
            btnText={t("User management")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(
                `/users?contract_year_month=${dayjs().year()}-${dayjs().month()}&contract_facility=${data?.id}`,
              )
            }
            disabled={!hasAccess(data?.id, UserManagementPermission, "both")}
          />

          <Button
            btnText={t("Auto email sending")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(`/auto-email-sending?facility_id=${data?.id}`)
            }
            disabled={!hasAccess(data?.id, SystemMailSettings, "both")}
          />
        </div>
      ),
    },
    {
      title: t("Various Record Tables"),
      dataIndex: "various_record_tables",
      key: "various_record_tables",
      align: "center",
      width: 200,
      render: (_: any, data: any) => (
        <div className={"actions-wrapper"}>
          <Button
            btnText={t("Daily business report list")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(`/daily-business-report-list?facility_id=${data?.id}`)
            }
            disabled={
              !hasAccess(data?.id, DailyBusinessReportPermission, "both")
            }
          />
          <Button
            btnText={t("Transportation management")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(`/user-transport-management?facility_id=${data?.id}`)
            }
            disabled={
              !hasAccess(data?.id, TransportationManagementPermission, "both")
            }
          />
          <Button
            btnText={t("User Daily Record")}
            shape={"default"}
            btnBg={theme.colors["action-400"]}
            onClick={() =>
              router.push(`/user-daily-record?facility_id=${data?.id}`)
            }
          />
        </div>
      ),
    },
    {
      title: t("Last Update"),
      align: "center",
      width: 150,
      render: (data: any) => {
        return (
          <div>
            <span>
              {formattedDateAndTime(
                data?.updated_datetime || data?.created_datetime,
                FULL_DATE_TIME_FORMAT,
              )}
            </span>
            <div>{data?.updated_by || data?.created_by}</div>
          </div>
        )
      },
    },
  ]
  const extraButton = facilityInformationData?.count < 11 && (
    <Button
      btnText={t("Add facility")}
      type={"primary"}
      shape={"round"}
      size={"large"}
      iconType={"plus-circle"}
      onClick={() => router.push("/facility-information/add")}
    />
  )
  return (
    <StyleFacilityInformationWrapper ref={listRef}>
      <PageTitle
        title={t("Facility information")}
        icon={<img src={"./assets/icons/facility.svg"} alt={"icon"} />}
        extra={
          <Flex align={"center"} wrap={"wrap"} gap={16}>
            <div>
              {t(
                "※{{remain}} items can be registered.(Currently registered facilities: {{count}})",
                {
                  count: facilityInformationData?.count || 0,
                  remain: 11 - (facilityInformationData?.count || 0),
                },
              )}
            </div>
            {extraButton}
          </Flex>
        }
      />
      <Card title={"事業所情報・履歴"}>
        <Table
          columns={columns}
          current={currentPage}
          pageSize={PAGE_SIZE}
          dataSource={facilityInformationData?.data}
          total={facilityInformationData?.count}
          scroll={{ x: 1000 }}
          customPagination={
            facilityInformationData?.data > PAGE_SIZE ? true : false
          }
          onChange={(val) => {
            setCurrentPage(val)
            scrollToSelectedElement(listRef)
            router.push(
              {
                pathname: "/facility-information",
                query: {
                  page: val,
                },
              },
              undefined,
              {
                scroll: false,
              },
            )
          }}
          loading={isLoading || isFetching}
          cellPaddingBlock={12}
        />
      </Card>
    </StyleFacilityInformationWrapper>
  )
}

export { FacilityInformation }
