import { theme } from "@project/common"
import { styled } from "styled-components"

export const MMStyledForm = styled.form`
  .form__control {
    .MM_furigana-select {
      max-width: 78px;
    }

    .user-name__header {
      .row__header {
        border-bottom: 1px solid ${theme.colors.border};
      }
      .col__content {
        border-bottom: 1px solid ${theme.colors.border};
      }

      .MM_reason-for-absence-input {
        margin-top: 12px;
      }
      .MM_username-inputs {
        flex-wrap: wrap;
      }
    }
    .MM_progress-title {
      display: block;
      font-size: 16px;
      padding-bottom: 15px;
      margin-bottom: 16px;
      margin-top: 28px;
      font-weight: 500;
      color: #191919;
      border-bottom: 1px solid ${theme.colors.border};
    }

    .MM_event-date-time-inputs {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 10px;

      .work_time__hours {
        display: flex;
        align-items: center;
        column-gap: 24px;

        .MM_time-group {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }

        @media (max-width: 435px) {
          column-gap: 10px;
          flex-wrap: wrap;
          row-gap: 8px;
          .MM_time-group {
            column-gap: 4px;
          }
        }
      }
    }

    .attach-file__header {
      .image_type_label {
        margin-bottom: 0;
      }
    }
  }

  .increment-file-button {
    margin-top: 12px;
    margin-left: auto;
  }

  .form__control-action-btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
    column-gap: 16px;
    row-gap: 8px;
    .cancel-button {
      background-color: ${theme.colors.gray4};
    }
    .save-as-draft-button {
      background-color: ${theme.colors["action-400"]};
    }
  }

  /* Common styles */
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-x {
    display: flex;
    align-items: center;
    column-gap: 12px;
    row-gap: 10px;
  }

  .flex-y {
    display: flex;
    flex-direction: column;
    column-gap: 12px;
    row-gap: 10px;
  }

  /* select input with furigana */
  .MM_furi-and-select-container,
  .MM_multi-select {
    display: flex;
    column-gap: 16px;
    row-gap: 10px;

    @media (max-width: ${theme.breakpoints.xs}) {
      column-gap: 8px;
      row-gap: 4px;
    }
  }

  .add-item-button,
  .delete-item-button,
  .elevate {
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .add-file-btn {
    button {
      padding-inline-start: 8px !important;
      padding-inline-end: 8px !important;
    }
  }
  .file-area {
    button {
      width: auto !important;
      margin-right: 0px !important;
    }
  }
`

export const ParticipantSelContainer = styled.div`
  /* New styles starts here */
  &.MM_participants-container {
    display: grid;
    gap: 12px;
  }
  .participants-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 10px;
    align-items: flex-start;
    .participant-btn-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;

      button {
        padding-inline-start: 8px !important;
        padding-inline-end: 8px !important;
      }
    }
  }
`

export const MMFreeItemContainer = styled.div`
  .free-item-btn-group {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    button {
      padding-inline-start: 8px !important;
      padding-inline-end: 8px !important;
    }
  }
`

export const AbsentCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .wrapper {
    border: 1px solid #d9d9d9;
    padding: 6px 8px;
    border-radius: 10px;
  }
  &:after {
    position: absolute;
    content: "";
    border: 1px solid rgb(221, 221, 221);
    border-radius: 6px;
    justify-content: center;
    width: 120px;
    height: 35px;
    margin-left: 12px;
    margin-top: 2px;
    z-index: -1;
  }
`

export const UserFieldExtraInfoContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
