import {
  ADDITIONAL_ITEM_VALUES,
  CALENDAR_HOURS_MINUTES,
  CheckBox,
  CheckBoxGroup,
  InputAreaField,
  InputField,
  LinkButton,
  MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
  MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
  Modal,
  RadioGroup,
  SelectInput,
  ServiceTypeTag,
  Table,
  USER_ATTENDANCE_VALUES,
  defaultAbsentNote,
  getOthersCheckBoxItems,
  getUserAttendanceTypesByService,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  MEDICAL_COLLABORATION_SYSTEM,
  MEDICAL_COLLABORATION_SYSTEM_VALUES,
  PICK_DROP_OPTIONS_INT_VAL,
  YES_NO_BOOL_LIST,
  showOtherPlace,
} from "../../../../constants"
import { bodyTemperatureOptions } from "../../../../utils"
import { DayListAbsenceForm } from "../DayListModal/DayListAbsenceForm"

import dayjs from "dayjs"
import { useFetchAllStaff } from "../../../../hooks/useFetchData"
import { ROUTE_OPTIONS } from "../../../UserTransportManagementContainer/constant"
import { AbsentNoteWithRecorder } from "../DailyListCommon/AbsentNoteWithRecorder"

const StyledWraper = styled.div`
  .ant-table-title {
    padding: 0 !important;
    overflow: hidden;
    .t-header {
      text-align: center;
      font-size: 16px;
      background-color: ${theme.colors.disabled};
      padding: 8px 16px;
    }
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    span {
      white-space: nowrap !important;
    }
  }

  tr.unscheduled-attendance {
    background: ${theme.colors.border3};
  }
`

const TransportContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  .flex-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`

export const AttendanceDailyListBulkEditTable = ({
  isLoading,
  data,
  selectedRowKeys,
  setSelectedRowKeys,
  onCellDataChange,
  params,
}: {
  isLoading: boolean
  refetch: () => void
  data: any
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (keys: React.Key[]) => void
  onCellDataChange: (values: {
    id: number
    key: string
    value: any
    extra?: any
  }) => void
  params?: {
    facility_ids: any
    user_service_id: any
    date: dayjs.Dayjs
  }
}): JSX.Element => {
  const { t } = useTranslation()
  const { staffData } = useFetchAllStaff({
    pageSize: "Infinity",
    facility: params?.facility_ids?.toString() || "",
    date_staff_is_active: params?.date?.format("YYYY-MM-DD"),
  })
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    date: null,
    absent_count_for_month: null,
  })
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    columnTitle: (
      <CheckBox
        checked={selectedRowKeys?.length === data?.length}
        onChange={({ target: { checked } }) => {
          if (checked) {
            setSelectedRowKeys(data?.map((v) => v?.tempId))
            return
          }
          setSelectedRowKeys([])
        }}
      />
    ),
    columnWidth: 10,
    renderCell: (_, row) => {
      return (
        <CheckBox
          checked={selectedRowKeys?.includes(row?.tempId)}
          onChange={() => {
            const item = selectedRowKeys?.find((v) => v === row?.tempId)
            if (item) {
              const newItems = selectedRowKeys?.filter((v) => v !== row?.tempId)
              setSelectedRowKeys(newItems)
            } else {
              setSelectedRowKeys([...selectedRowKeys, row?.tempId])
            }
          }}
        />
      )
    },
  }

  const columns: ColumnsType<any> = [
    {
      key: 1,
      render: (_, __, index) => index + 1,
      width: 10,
      align: "center",
    },
    {
      key: 2,
      title: t("User Name"),
      align: "center",
      render: (row) => (
        <Flex vertical align={"flex-start"}>
          <Typography.Text>{row?.user?.furigana || ""}</Typography.Text>
          <Typography.Text>{row?.user?.username || ""}</Typography.Text>
          <ServiceTypeTag type={row?.service_type || 1} />
        </Flex>
      ),
    },
    {
      key: 3,
      title: t("Use facility"),
      align: "center",
      render: (row) => <span>{row?.facility_name || ""}</span>,
    },
    {
      key: 4,
      title: t("Service Provision status"),
      align: "center",
      render: (row) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <SelectInput
            name={"provision_status"}
            value={+row?.attendance_type ? row?.attendance_type : null}
            options={getUserAttendanceTypesByService(+row?.service_type)}
            popupMatchSelectWidth={false}
            width={"211px"}
            height={"40px"}
            placeholder={"--"}
            onChange={(val) => {
              let trial_use_support_count_for_month =
                +row?.trial_use_support_count_for_month || 0
              if (val == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT) {
                trial_use_support_count_for_month++
              } else {
                if (
                  row?.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
                )
                  trial_use_support_count_for_month--
              }
              onCellDataChange({
                id: row?.tempId,
                key: "attendance_type",
                value: val,
                extra: {
                  trial_use_support_count_for_month,
                },
              })
            }}
            disabled={!row?.is_scheduled}
          />
          {row?.attendance_type == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT && (
            <CheckBox
              label={t("Community life support base")}
              checked={!!row.community_life_support_base}
              onChange={(e) =>
                onCellDataChange({
                  id: row?.tempId,
                  key: "community_life_support_base",
                  value: e.target.checked,
                })
              }
              disabled={!row?.is_scheduled}
            />
          )}
          <div style={{ color: theme.colors.error }}>
            {+row?.trial_use_support_count_for_month >
              MAX_TRIAL_USE_SUPPORT_COUNT_MONTH &&
              t(
                "*Already did {{number}} times this month so you can not get further addition",
                { number: MAX_TRIAL_USE_SUPPORT_COUNT_MONTH },
              )}
          </div>
        </div>
      ),
    },
    {
      key: 5,
      align: "center",
      title: (
        <div>
          <div style={{ whiteSpace: "nowrap" }}>{t("Entry time")}</div>
          <div>{t("Out time")}</div>
        </div>
      ),
      render: (row) => {
        if (!row?.attendance_type) {
          return (
            <Flex
              wrap={"wrap"}
              gap={8}
              align={"center"}
              justify={"center"}
              style={{ color: theme.colors.error }}
            >
              {t("Unregistered")}
            </Flex>
          )
        }
        if (
          row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
        ) {
          return (
            <Flex
              wrap={"wrap"}
              gap={8}
              align={"center"}
              justify={"center"}
              style={{ color: theme.colors.error }}
            >
              {t("Absentee (no extra charge for absenteeism)")}
            </Flex>
          )
        }
        if (row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE) {
          return (
            <Flex
              vertical
              gap={10}
              style={{
                width: "390px",
                textAlign: "left",
              }}
            >
              <Flex
                wrap={"wrap"}
                gap={8}
                align={"center"}
                justify={"center"}
                style={{ color: theme.colors.error }}
              >
                {t("Absent")}
              </Flex>
              <AbsentNoteWithRecorder
                values={{
                  furigana: "",
                  saveStaffId: row?.save_staff_id,
                  absentNote:
                    row?.absence_reason ||
                    defaultAbsentNote +
                      `${
                        row?.next_scheduled_date
                          ? dayjs(row?.next_scheduled_date).format("MM月DD日")
                          : ""
                      }`,
                }}
                staffOptions={staffData || []}
                setValues={(val) => {
                  onCellDataChange({
                    id: row?.tempId,
                    key: "temp",
                    value: null,
                    extra: {
                      absence_reason: val.absentNote,
                      save_staff_id: val?.saveStaffId,
                      furigana: val?.furigana,
                    },
                  })
                }}
              />
            </Flex>
          )
        }
        return (
          <Flex vertical gap={10}>
            <Flex align={"center"} gap={8}>
              <SelectInput
                name={"start_time_hr"}
                width={"60px"}
                shortSelector
                placeholder={"--"}
                value={row?.start_time?.split(":")[0] || null}
                options={CALENDAR_HOURS_MINUTES?.hours || []}
                onChange={(val) => {
                  onCellDataChange({
                    id: row?.tempId,
                    key: "start_time",
                    value: `${val}:${
                      val ? row?.start_time?.split(":")[1] || "00" : ""
                    }`,
                  })
                }}
                disabled={!row?.is_scheduled}
              />
              <Typography.Text>{":"}</Typography.Text>
              <SelectInput
                name={"start_time_min"}
                width={"60px"}
                shortSelector
                placeholder={"--"}
                disabled={!row?.start_time?.split(":")[0] || !row?.is_scheduled}
                value={row?.start_time?.split(":")[1] || null}
                options={CALENDAR_HOURS_MINUTES?.minutes || []}
                onChange={(val) => {
                  onCellDataChange({
                    id: row?.tempId,
                    key: "start_time",
                    value: `${row?.start_time?.split(":")[0]}:${val}`,
                  })
                }}
              />
            </Flex>
            <Flex align={"center"} gap={8}>
              <SelectInput
                name={"end_time_hr"}
                width={"60px"}
                shortSelector
                placeholder={"--"}
                value={row?.end_time?.split(":")[0] || null}
                options={CALENDAR_HOURS_MINUTES?.hours || []}
                onChange={(val) => {
                  onCellDataChange({
                    id: row?.tempId,
                    key: "end_time",
                    value: `${val}:${
                      val ? row?.end_time?.split(":")[1] || "00" : ""
                    }`,
                  })
                }}
                disabled={!row?.is_scheduled}
              />
              <Typography.Text>{":"}</Typography.Text>
              <SelectInput
                name={"end_time_min"}
                width={"60px"}
                shortSelector
                placeholder={"--"}
                disabled={!row?.end_time?.split(":")[0] || !row?.is_scheduled}
                value={row?.end_time?.split(":")[1] || null}
                options={CALENDAR_HOURS_MINUTES?.minutes || []}
                onChange={(val) => {
                  onCellDataChange({
                    id: row?.tempId,
                    key: "end_time",
                    value: `${row?.end_time?.split(":")[0]}:${val}`,
                  })
                }}
              />
            </Flex>
          </Flex>
        )
      },
      onCell: (row) => {
        if (
          !row?.attendance_type ||
          row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION ||
          row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE
        ) {
          return { colSpan: 2 }
        }
        return {}
      },
    },
    {
      key: 6,
      align: "center",
      title: t("Body temperature upon entry"),
      width: 133,
      render: (row) => (
        <Flex align={"center"} gap={4}>
          <SelectInput
            name={"body_temp"}
            width={"60px"}
            shortSelector
            placeholder={"--"}
            value={row?.temperature?.split(".")[0] || null}
            options={bodyTemperatureOptions(31, 42)}
            onChange={(val) => {
              onCellDataChange({
                id: row?.tempId,
                key: "temperature",
                value: `${val}.${
                  val ? row?.temperature?.split(".")[1] || "00" : ""
                }`,
              })
            }}
            disabled={!row?.is_scheduled}
          />
          <Typography.Text>{":"}</Typography.Text>
          <SelectInput
            name={"body_temp"}
            width={"60px"}
            shortSelector
            placeholder={"--"}
            disabled={!row?.temperature?.split(".")[0] || !row?.is_scheduled}
            value={row?.temperature?.split(".")[1] || null}
            options={bodyTemperatureOptions(0, 9)}
            onChange={(val) => {
              onCellDataChange({
                id: row?.tempId,
                key: "temperature",
                value: `${row?.temperature?.split(".")[0]}.${val}`,
              })
            }}
          />
          <Typography.Text style={{ whiteSpace: "nowrap" }}>
            {"°C"}
          </Typography.Text>
        </Flex>
      ),
      onCell: (row) => {
        if (
          !row?.attendance_type ||
          row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION ||
          row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE
        ) {
          return { colSpan: 0 }
        }
        return {}
      },
    },
    {
      key: 61,
      align: "center",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Pick-Up Yes/No")}</span>
      ),
      render: (row) => (
        <RadioGroup
          options={YES_NO_BOOL_LIST}
          value={row?.pickup?.use_transportation_flag}
          onChange={(e) => {
            onCellDataChange({
              id: row?.tempId,
              key: "pickup",
              value: { ...row.pickup, use_transportation_flag: e.target.value },
            })
          }}
          disabled={!row?.is_scheduled}
        />
      ),
    },
    {
      key: 62,
      align: "center",
      title: t("Pick-Up"),
      render: (row) => (
        <TransportContainer>
          <div>
            <SelectInput
              name={"place"}
              options={PICK_DROP_OPTIONS_INT_VAL}
              placeholder={"--"}
              width={"150px"}
              value={row?.pickup?.place || null}
              onChange={(val) => {
                onCellDataChange({
                  id: row?.tempId,
                  key: "pickup",
                  value: { ...row.pickup, place: val },
                })
              }}
              disabled={
                !row?.pickup?.use_transportation_flag || !row?.is_scheduled
              }
            />
            {showOtherPlace(row?.pickup?.place) && (
              <InputField
                style={{ marginTop: "5px" }}
                value={row?.pickup?.place_other_name}
                onChange={(e) =>
                  onCellDataChange({
                    id: row?.tempId,
                    key: "pickup",
                    value: { ...row.pickup, place_other_name: e.target.value },
                  })
                }
                disabled={
                  !row?.pickup?.use_transportation_flag || !row?.is_scheduled
                }
              />
            )}
          </div>
          <div className={"flex-container"}>
            <SelectInput
              name={"route"}
              options={ROUTE_OPTIONS}
              width={"50px"}
              value={row.pickup?.route}
              onChange={(val) =>
                onCellDataChange({
                  id: row?.tempId,
                  key: "pickup",
                  value: { ...row.pickup, route: val },
                })
              }
              disabled={
                !row?.pickup?.use_transportation_flag || !row?.is_scheduled
              }
            />{" "}
            <div style={{ whiteSpace: "nowrap" }}>{t("Route")}</div>
          </div>
          <CheckBox
            label={t("Within The Same Premises")}
            checked={row?.pickup?.same_premises}
            onChange={(e) =>
              onCellDataChange({
                id: row?.tempId,
                key: "pickup",
                value: { ...row.pickup, same_premises: e.target.value },
              })
            }
            disabled={
              !row?.pickup?.use_transportation_flag || !row?.is_scheduled
            }
          />
        </TransportContainer>
      ),
    },

    {
      key: 63,
      align: "center",
      title: t("Drop Off Yes/No"),
      render: (row) => (
        <RadioGroup
          options={YES_NO_BOOL_LIST}
          value={row?.drop?.use_transportation_flag}
          onChange={(e) => {
            onCellDataChange({
              id: row?.tempId,
              key: "drop",
              value: { ...row.drop, use_transportation_flag: e.target.value },
            })
          }}
          disabled={!row?.is_scheduled}
        />
      ),
    },
    {
      key: 64,
      align: "center",
      title: t("Drop-Off"),
      render: (row) => (
        <TransportContainer>
          <div>
            <SelectInput
              name={"place"}
              options={PICK_DROP_OPTIONS_INT_VAL}
              placeholder={"--"}
              width={"150px"}
              value={row?.drop?.place || null}
              onChange={(val) => {
                onCellDataChange({
                  id: row?.tempId,
                  key: "drop",
                  value: { ...row.drop, place: val },
                })
              }}
              disabled={
                !row?.drop?.use_transportation_flag || !row?.is_scheduled
              }
            />
            {showOtherPlace(row?.drop?.place) && (
              <InputField
                style={{ marginTop: "5px" }}
                value={row?.drop?.place_other_name}
                onChange={(e) =>
                  onCellDataChange({
                    id: row?.tempId,
                    key: "drop",
                    value: { ...row.drop, place_other_name: e.target.value },
                  })
                }
                disabled={
                  !row?.drop?.use_transportation_flag || !row?.is_scheduled
                }
              />
            )}
          </div>
          <div className={"flex-container"}>
            <SelectInput
              name={"route"}
              options={ROUTE_OPTIONS}
              width={"50px"}
              value={row.drop?.route}
              onChange={(val) =>
                onCellDataChange({
                  id: row?.tempId,
                  key: "drop",
                  value: { ...row.drop, route: val },
                })
              }
              disabled={
                !row?.drop?.use_transportation_flag || !row?.is_scheduled
              }
            />{" "}
            <div style={{ whiteSpace: "nowrap" }}>{t("Route")}</div>
          </div>
          <CheckBox
            label={t("Within The Same Premises")}
            checked={row?.drop?.same_premises}
            onChange={(e) =>
              onCellDataChange({
                id: row?.tempId,
                key: "drop",
                value: { ...row.drop, same_premises: e.target.value },
              })
            }
            disabled={!row?.drop?.use_transportation_flag || !row?.is_scheduled}
          />
        </TransportContainer>
      ),
    },
    {
      key: 7,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Actual Cost")}</span>,
      align: "center",
      width: 230,
      render: (row) => (
        <CheckBoxGroup
          style={{
            flexDirection: "column",
            whiteSpace: "nowrap",
          }}
          items={row?.user_attendance_actual_cost?.map(
            (ac: { actual_cost_id: any; actual_cost_name: any }) => ({
              value: ac?.actual_cost_id,
              label: ac?.actual_cost_name,
            }),
          )}
          value={row?.used_user_attendance_actual_cost}
          onChange={(list) => {
            onCellDataChange({
              id: row?.tempId,
              key: "used_user_attendance_actual_cost",
              value: list,
            })
          }}
          disabled={!row?.is_scheduled}
        />
      ),
    },
    {
      key: 8,
      title: t("Medical collaboration system"),
      align: "center",
      render: (row) => (
        <Flex
          vertical
          style={{
            textAlign: "left",
          }}
        >
          <SelectInput
            name={"medical_collaboration_system"}
            value={row?.medical_collaboration_system || null}
            options={[{ label: "--", value: null }].concat(
              MEDICAL_COLLABORATION_SYSTEM || [],
            )}
            width={"211px"}
            height={"40px"}
            placeholder={"--"}
            popupMatchSelectWidth={false}
            onChange={(val) => {
              onCellDataChange({
                id: row?.tempId,
                key: "medical_collaboration_system",
                value: val,
                extra: {
                  guidance_flag: false,
                },
              })
            }}
            disabled={!row?.is_scheduled}
          />
          <CheckBox
            label={t("Guidance regarding sputum aspiration, etc.")}
            style={{ marginTop: "8px" }}
            value={row?.guidance_flag}
            checked={row?.guidance_flag}
            disabled={
              row?.medical_collaboration_system ===
                MEDICAL_COLLABORATION_SYSTEM_VALUES.V || !row?.is_scheduled
            }
            onChange={({ target: { checked } }) => {
              onCellDataChange({
                id: row?.tempId,
                key: "guidance_flag",
                value: checked,
              })
            }}
          />
        </Flex>
      ),
    },
    {
      key: 8,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Others")}</span>,
      align: "center",
      width: 230,
      render: (row) => (
        <>
          <CheckBoxGroup
            style={{
              flexDirection: "column",
              whiteSpace: "nowrap",
            }}
            items={getOthersCheckBoxItems(
              row?.service_type,
              row?.system_status_management,
            )}
            value={row?.others}
            onChange={(list) => {
              let intensive_support_count_for_month =
                +row?.intensive_support_count_for_month || 0
              if (
                list.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT) &&
                !row.others?.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT)
              ) {
                intensive_support_count_for_month++
              } else if (
                !list.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT) &&
                row.others?.includes(ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT)
              ) {
                intensive_support_count_for_month--
              }
              onCellDataChange({
                id: row?.tempId,
                key: "others",
                value: list,
                extra: { intensive_support_count_for_month },
              })
            }}
            disabled={!row?.is_scheduled}
          />
          <div style={{ color: theme.colors.error }}>
            {+row?.intensive_support_count_for_month >
              MAX_INTENSIVE_SUPPORT_COUNT_MONTH &&
              t(
                "*Already did {{number}} times this month so you can not get further addition",
                { number: MAX_INTENSIVE_SUPPORT_COUNT_MONTH },
              )}
          </div>
        </>
      ),
    },
    {
      key: 9,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Remark")}</span>,
      align: "center",
      render: (row) => (
        <InputAreaField
          width={"128px"}
          rows={3}
          value={row?.remarks || null}
          onChange={({ target: { value } }) => {
            onCellDataChange({
              id: row?.tempId,
              key: "remarks",
              value: value,
            })
          }}
          disabled={!row?.is_scheduled}
        />
      ),
    },
    {
      key: 10,
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Output remarks")}</span>
      ),
      align: "center",
      render: (row) => (
        <InputAreaField
          width={"128px"}
          rows={3}
          value={row?.remarks_output || null}
          onChange={({ target: { value } }) => {
            onCellDataChange({
              id: row?.tempId,
              key: "remarks_output",
              value: value,
            })
          }}
          disabled={!row?.is_scheduled}
        />
      ),
    },
    {
      key: 11,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Reset ")}</span>,
      align: "center",
      render: (row) => (
        <LinkButton
          onClick={() => {
            onCellDataChange({
              id: row?.tempId,
              key: "",
              value: "",
              extra: {
                attendance_type: null,
                temperature: null,
                start_time: null,
                end_time: null,
                medical_collaboration_system: null,
                guidance_flag: null,
                others: [],
                remarks: "",
                remarks_output: "",
                used_user_attendance_actual_cost: [],
                user_attendance_actual_cost:
                  row?.user_attendance_actual_cost?.map((cost) => ({
                    ...cost,
                    used_flag: false,
                  })),
              },
            })
          }}
          disabled={!row?.is_scheduled}
        >
          {t("Reset ")}
        </LinkButton>
      ),
    },
  ]

  return (
    <>
      <StyledWraper>
        <Table
          title={() => (
            <div className={"t-header"}>{t("List of Adult attending")}</div>
          )}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data || []}
          scroll={{ x: 800 }}
          loading={isLoading}
          rowClassName={(row) => !row?.is_scheduled && "unscheduled-attendance"}
        />
      </StyledWraper>
      {modalProps?.isOpen && (
        <Modal
          open={modalProps?.isOpen}
          onCancel={() =>
            setModalProps({
              isOpen: false,
              date: null,
              absent_count_for_month: null,
            })
          }
          width={900}
          footer={null}
        >
          <DayListAbsenceForm
            userAttendanceData={modalProps || {}}
            handleSave={() => null}
            staffList={staffData || []}
          />
        </Modal>
      )}
    </>
  )
}
