import { API, removeBlankAttributes } from "@project/common"
import { NotiType } from "../types/notification.types"

interface NotificationData {
  count: number
  data: Notification[]
}

export interface Notification {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  notification_title: string
  notification_type: NotiType
  notification_details: string
  url: string
  publishing_date: string
  publish_status: string
  is_seen: boolean
}

interface GetManyNotificationsParams {
  from_date?: string
  to_date?: string
  page?: string
  pageSize?: string
}

export interface NotificationSeenType {
  notification_id: number
  is_seen: boolean
}

type GetManyNotifications = (
  params: GetManyNotificationsParams,
) => Promise<NotificationData | null | undefined>

export const getManyNotifications: GetManyNotifications = async (params) => {
  const sanitizedParams = removeBlankAttributes(params)
  return API.get("/company-notifications", { params: sanitizedParams })
}

export const seenNotification = async (
  values: NotificationSeenType,
): Promise<any> => {
  if (!values.notification_id) return
  return API.post(`/set-company-notification`, { ...values, is_seen: true })
}
