import { theme } from "@project/common"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { TriangleBox } from "../../../../components"
import { UserAttendanceStats } from "../../../../types"

const StatsSummaryWrapper = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;
`

export const AttendanceStatsSummary = ({
  attendanceStats,
}: {
  attendanceStats: UserAttendanceStats
}) => {
  const { t } = useTranslation()
  return (
    <StatsSummaryWrapper>
      <TriangleBox
        label={t("Attendance")}
        value={attendanceStats?.attendance}
      />
      <TriangleBox label={t("Absence")} value={attendanceStats?.absence} />
      <TriangleBox
        label={t("Absence (No Addition)")}
        value={attendanceStats?.absence_no_addition}
      />
      <TriangleBox
        label={t("Experience")}
        value={attendanceStats?.experience}
      />
      <TriangleBox
        label={t("Total Application")}
        value={attendanceStats?.total_application}
      />
    </StatsSummaryWrapper>
  )
}
