import { API, removeBlankAttributes } from "@project/common"
const BASE_URL = "usage-status-list"
interface IMinutesQuery {
  page?: string | number
  pageSize?: string
  facility_ids?: any
  user_id?: string | number
  create_status?: number | string
  create_status_type?: number | string
}
export const fetchUserStatusListData = ({ queryKey }): Promise<Response> => {
  const params = {} as IMinutesQuery
  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"
  params.facility_ids = queryKey[3].facility_ids || []
  params.user_id = queryKey[3].user_id || null
  params.create_status = queryKey[3].create_status || null
  params.create_status_type = queryKey[3].create_status_type || null
  const query = removeBlankAttributes(params)
  return API.get(`/${BASE_URL}`, {
    params: query,
  })
}
