import { CheckBox, Grid, InputAreaField, theme } from "@project/common"
import { Flex } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Wrapper = styled(Flex)`
  .other_content_head__title {
    border-bottom: 1px solid ${theme.colors.border};
    padding: 8px 0;
    font-weight: 500;
  }
`
type FiledItems = {
  title: string
  value: any
  key: string
  type?: any
  length?: number
  [key: string]: any
}
export const AssessmentOtherFormFields = ({
  title,
  fields,
  handleDataValueChange,
  assessment_setting_id,
  categoryId,
  from,
}: {
  assessmentId: number
  assessment_setting_id: number
  categoryId: number
  title: string
  fields: FiledItems[]
  handleDataValueChange: (values: {
    value: string
    key: string
    id: number
    category_id: number
    item_id: number
  }) => void
  from?: "work" | "support" | "skills" | "other"
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper vertical gap={16}>
      <div className={"other_content_head__title"}>{t(title)}</div>
      <div>
        {fields?.map((item, index) => (
          <Grid
            background
            labelContent={t(item?.title)}
            key={index}
            padding={"10px"}
            labelSpan={6}
            textAlign={"left"}
            fontWeight={500}
          >
            {item?.title === "Disclosure of disability information" ? (
              <CheckBox
                name={item?.title}
                label={t("hope")}
                value={item?.answer || null}
                checked={item?.answer || null}
                onChange={({ target: { checked } }) => {
                  handleDataValueChange({
                    value: checked ? "1" : null,
                    key: "answer",
                    id: +assessment_setting_id,
                    category_id: categoryId,
                    item_id: item?.id,
                  })
                }}
              />
            ) : (
              <InputAreaField
                name={item?.title}
                rows={2}
                value={item?.answer || null}
                onChange={({ target: { value } }) => {
                  handleDataValueChange({
                    value,
                    key: from === "other" ? item?.key : "answer",
                    id: +assessment_setting_id,
                    category_id: categoryId,
                    item_id: item?.id,
                  })
                }}
                maxLength={item?.length}
              />
            )}
          </Grid>
        ))}
      </div>
    </Wrapper>
  )
}
