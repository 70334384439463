import styled from "styled-components"

export const DailyBusinessReportListContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  .user_pick_up_and-drop_off_chart_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 1em;
  }

  .print_btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .button {
      background-color: #f3f3f3;
      color: #a9a9a9;
    }
  }
`
export const SelectFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .first-select-content,
  .second-select-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn {
    span {
      margin-inline-end: 0px;
    }
  }
`
