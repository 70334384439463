import { theme } from "@project/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getAllStaff, getAllWork } from "../../../../services"
export const FirstContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .label {
    padding: 12px;
    background-color: rgb(243, 243, 243);
    width: 25%;
    text-transform: capitalize;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    }
  }

  .value,
  p {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      font-size: 11px !important;
      font-weight: 400;
    }
  }

  .first-content-tr {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      border-radius: 10px !important;
      border: 1px solid #dddddd;
    }
    .label {
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border: none !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom: 1px solid #dddddd !important;
      }
    }
    .value {
      font-size: 14px !important;
      @media screen and (max-width: 540px) {
        font-size: 14px !important;
        border: none !important;
      }
    }
    .line-break {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
  .first-content-tr:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
`
interface IProps {
  formik: any
}
const FirstTable: React.FC<IProps> = ({ formik }) => {
  const { t } = useTranslation()
  const { data: allWorkData } = useQuery<any>(
    ["get-all-work-data"],
    () => getAllWork(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.work_name, value: d?.id }
        }),
    },
  )
  const { data: allStaffData } = useQuery<any>(
    ["get-all-staff-data"],
    () => getAllStaff(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) =>
        data?.map((d) => {
          return { label: d?.staff_name, value: d?.id }
        }),
    },
  )

  const workData = Array.isArray(formik.values?.works)
    ? formik.values?.works
        ?.map((workId) => allWorkData?.find((d) => d.value == workId)?.label)
        .join(", ")
    : ""

  const staffData = allStaffData?.find(
    (d) => d.value == formik?.values?.staff_id,
  )?.label

  return (
    <FirstContentWrapper>
      <table>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Facility  ")}</td>
          <td className={"value"}>
            {formik?.values?.facility_id ? formik?.values?.facility_id : "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Date")}</td>
          <td className={"value"}>
            {formik?.values?.date ? formik?.values?.date : "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("mrng")}</td>
          <td className={"value line-break"}>
            {formik?.values?.morning ? formik?.values?.morning : "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("aftnoon")}</td>
          <td className={"value line-break"}>
            {formik?.values?.afternoon ? formik?.values?.afternoon : "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("wrk")}</td>
          <td className={"value"}>{workData ? workData : "-"}</td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("others")}</td>
          <td className={"value line-break"}>
            {formik?.values?.others ? formik?.values?.others : "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>
            {t("Accompanying staff working outside the facility")}
          </td>
          <td className={"value"}>
            {formik.values?.accompanying_staff || "-"}
          </td>
        </tr>
        <tr className={"first-content-tr"}>
          <td className={"label"}>{t("Recorder:")}</td>
          <td className={"value"}>{staffData ? staffData : "-"}</td>
        </tr>
      </table>
    </FirstContentWrapper>
  )
}

export { FirstTable }
