import {
  ActionBtns,
  Button,
  CALENDAR_HOURS_MINUTES,
  Card,
  EMPLOYMENT_STYLE,
  ErrorMassage,
  FacilityCheckbox,
  Grid,
  InputField,
  SelectInput,
  allowOnlyNumber,
  generateURLString,
} from "@project/common"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ShiftUserStyledForm } from "./ShiftUserMasterForm.style"
import { useFormik } from "formik"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import {
  ShiftUserMasterInfoSchema,
  ShiftUserMasterInitialValues,
} from "../../../types/shiftUserMaster.types"
import { AuthContext } from "../../../context/AuthContext"
import { useRouter } from "next/router"
import { Popconfirm } from "antd"
interface ShiftUserMasterFormProps {
  isMutating?: boolean
  onSubmit?: (values: any) => void
  defaultValues?: any
  handleDelete?: () => void
  isDeleting?: boolean
}
const ShiftUserMasterForm = ({
  isMutating,
  onSubmit,
  defaultValues,
  handleDelete,
  isDeleting,
}: ShiftUserMasterFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const router = useRouter()
  const initialValues = {
    attendance_shift_name: "",
    facilities: [],
    employment_type_id: null,
    work_time: {
      start_time: {
        hr: "",
        min: "",
      },
      end_time: {
        hr: "",
        min: "",
      },
    },
    attendance_rest_mins: null,
    remarks: "",
  }
  const {
    values,
    handleSubmit,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik<ShiftUserMasterInitialValues>({
    enableReinitialize: true,
    initialValues: defaultValues || initialValues,
    validationSchema: ShiftUserMasterInfoSchema,
    onSubmit: (val) => {
      const data = {
        attendance_rest_mins: +val?.attendance_rest_mins,
        attendance_shift_name: val?.attendance_shift_name,
        employment_type_id: val?.employment_type_id,
        facilities: val?.facilities?.map((d) => +d),
        remarks: val?.remarks,
        attendance_start_time: `${val?.work_time.start_time.hr || "00"}:${
          val?.work_time.start_time.min || "00"
        }`,
        attendance_end_time: `${val?.work_time.end_time.hr || "00"}:${
          val?.work_time.end_time.min || "00"
        }`,
      }
      onSubmit(data)
    },
  })

  return (
    <div>
      <Card title={t("Shift Information")}>
        <ShiftUserStyledForm onSubmit={handleSubmit}>
          <div className={"form__control"}>
            <Grid
              labelContent={t("Shift hour name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
              padding={"10px 16px"}
            >
              <InputField
                name={"attendance_shift_name"}
                id={"attendance_shift_name"}
                placeholder={t("例：出勤表表示名")}
                padding={"8px"}
                maxLength={50}
                borderRadius={"4px"}
                value={values?.attendance_shift_name}
                error={
                  touched?.attendance_shift_name &&
                  errors?.attendance_shift_name
                    ? t(`${errors?.attendance_shift_name}`)
                    : ""
                }
                info={t("(within 50 char)")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid
              labelContent={t("facilities")}
              className={"row__header"}
              background
              required
              labelSpan={6}
              padding={"10px 16px"}
            >
              <div className={"facilities_content"}>
                <FacilityCheckbox
                  options={facilities}
                  name={"facilities"}
                  id={"facilities"}
                  btnHeight={"30px"}
                  value={values?.facilities}
                  onChange={(list: CheckboxValueType[]) => {
                    setFieldValue("facilities", list)
                  }}
                  handleCheckUncheckAll={(type) => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setFieldValue("facilities", ids)
                    }
                    if (type === "uncheck") {
                      setFieldValue("facilities", [])
                    }
                  }}
                />
                {touched?.facilities && errors?.facilities && (
                  <ErrorMassage type={"error"} message={t("Please select")} />
                )}
              </div>
            </Grid>
            <Grid
              labelContent={t("Employment Type")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"15px 16px"}
            >
              <div>
                <SelectInput
                  name={"employment_type_id"}
                  placeholder={"--"}
                  options={[{ label: "---", value: null }, ...EMPLOYMENT_STYLE]}
                  value={values?.employment_type_id}
                  onChange={(val) => {
                    setFieldValue("employment_type_id", val)
                  }}
                />
              </div>
            </Grid>

            {!values.number_of_employees_include_flg ? (
              <>
                <Grid
                  labelContent={t("Work time")}
                  className={"row__header"}
                  background
                  labelSpan={6}
                  padding={"10px 16px"}
                >
                  <div className={"work_time__content"}>
                    <div className={"work_time__hours"}>
                      <div className={"work_time__hours--from"}>
                        <SelectInput
                          name={"work_time_from_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values?.work_time?.start_time?.hr}
                          onChange={(value) => {
                            setFieldValue("work_time", {
                              ...values.work_time,
                              start_time: {
                                hr: value,
                                min:
                                  !values.work_time.start_time.min ||
                                  values.work_time.start_time.hr === ""
                                    ? "00"
                                    : values.work_time.start_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time_from_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values?.work_time?.start_time?.min}
                          onChange={(value) => {
                            setFieldValue("work_time", {
                              ...values.work_time,
                              start_time: {
                                hr: values.work_time.start_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values?.work_time?.start_time?.hr === ""}
                        />
                      </div>
                      <span className={"time-separator"}>{"~"}</span>
                      <div className={"work_time__hours--to"}>
                        <SelectInput
                          name={"work_time_to_hour"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.hours}
                          value={values?.work_time?.end_time.hr}
                          onChange={(value) => {
                            setFieldValue("work_time", {
                              ...values.work_time,
                              end_time: {
                                hr: value,
                                min:
                                  !values.work_time.end_time.min ||
                                  values.work_time.end_time.hr === "--"
                                    ? "00"
                                    : values.work_time.end_time.min,
                              },
                            })
                          }}
                        />
                        {":"}
                        <SelectInput
                          name={"work_time_to_min"}
                          width={"60px"}
                          height={"40px"}
                          placeholder={"--"}
                          options={CALENDAR_HOURS_MINUTES?.minutes}
                          value={values?.work_time?.end_time?.min}
                          onChange={(value) => {
                            setFieldValue("work_time", {
                              ...values.work_time,
                              end_time: {
                                hr: values.work_time.end_time.hr,
                                min: value,
                              },
                            })
                          }}
                          disabled={values.work_time?.end_time?.hr === ""}
                        />
                      </div>
                    </div>
                    <div className={"work_time__break"}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("(break time :")}
                      </span>
                      <InputField
                        height={"40px"}
                        width={"60px"}
                        maxLength={3}
                        name={"attendance_rest_mins"}
                        value={values?.attendance_rest_mins}
                        onChange={({ target: { value } }) => {
                          setFieldValue(
                            "attendance_rest_mins",
                            allowOnlyNumber(value),
                          )
                        }}
                      />
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("mins(max 3 numbers)")}
                      </span>
                    </div>
                  </div>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid
              labelContent={t("Remarks")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"10px 16px"}
            >
              <InputField
                name={"remarks"}
                id={"remarks"}
                placeholder={t("例：9:00〜17:00")}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.remarks}
                maxLength={20}
                error={
                  touched?.remarks && errors?.remarks
                    ? t(`${errors?.remarks}`)
                    : ""
                }
                info={t("(within 20 char)")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </div>

          <div className={"delete_action_container"}>
            <ActionBtns justify={"flex-start"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => {
                  const { facilityIds, targetIds, page } = router?.query as any
                  const url = generateURLString("/shift-user-master", {
                    facilityIds,
                    targetIds,
                    page,
                  })
                  router.push(url)
                }}
                disabled={isMutating}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isMutating}
              />
            </ActionBtns>
            {+defaultValues?.id > 0 && (
              <div className={"joint-facility-button-wrapper"}>
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  onConfirm={handleDelete}
                  okText={t("OK")}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                >
                  <Button
                    btnText={t("Delete")}
                    shape={"round"}
                    className={"delete-btn"}
                    isLoading={isDeleting}
                  />
                </Popconfirm>
              </div>
            )}
          </div>
        </ShiftUserStyledForm>
      </Card>
    </div>
  )
}

export { ShiftUserMasterForm }
