import {
  Button,
  Card,
  LinkButton,
  MonthPagination,
  PageTitle,
  Table,
  getFacilityNameFromId,
  pageSize,
  removeBlankAttributes,
  scrollToSelectedElement,
  usePrint,
  useUpdateSearchParams,
} from "@project/common"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { AuthContext } from "../../context/AuthContext"
import { fetchDailyBusinessReportList } from "../../services/dailyBusinessReportList"
import {
  DailyBusinessReportPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"
import Accordion from "./Accordion"
import {
  CellWrapper,
  DailyBusinessReportListContent,
} from "./DailyBusinessReportList.style"

const DailyBusinessReportList = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const listContent = useRef<any>(null)
  const { page: currentPage, date: qDate } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const contentRef = useRef<HTMLDivElement>(null)
  const { handlePrint } = usePrint({
    contentRef,
    pageStyle: `margin:0.5cm 0.8cm;`,
  })
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1,
  )
  const { facilities, isOwner, permissions } = useContext(AuthContext)
  const [selectedFacility, setSelectedFacility] = useState<
    string | null | number | string[]
  >(router?.query?.facility_id || null)
  const [searchEnabled, setSearchEnabled] = useState(true)
  const [date, setDate] = useState<any>(qDate ? dayjs(qDate) : dayjs())
  const today = dayjs().format("YYYY-MM-DD")

  const hasAddAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, DailyBusinessReportPermission, "write"),
    [isOwner, permissions],
  )

  const hasEditAccess = (facilityId) =>
    isOwner ||
    hasPermissionForFacility(
      permissions,
      DailyBusinessReportPermission,
      "write",
      facilityId,
    )

  const [queries, setQueries] = useState<any>({
    pageSize: "Infinity",
    facility_id: selectedFacility || null,
    year: dayjs(date).year() || dayjs().year(),
    month: dayjs(date).month() + 1 || dayjs().month() + 1,
  })
  //API calls start

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(["fetchDailyBusinessReportList", currentPage, queries], {
    queryFn: fetchDailyBusinessReportList,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: searchEnabled || !!page,
    onSuccess: () => {
      setSearchEnabled(false)
    },
    onError: () => {
      setSearchEnabled(false)
    },
  })
  //API calls end
  const onSearch = () => {
    const values = {
      facility_id: selectedFacility,
      year: dayjs(date).year() || dayjs().year(),
      month: dayjs(date).month() + 1 || dayjs().month() + 1,
    }
    setPage(1)
    const params = removeBlankAttributes(values)
    updateParams(
      {
        ...params,
      },
      "/daily-business-report-list",
    )
    scrollToSelectedElement(listContent)
    setQueries({ ...queries, ...params })
    setSearchEnabled(true)
  }

  const columns: ColumnsType<any> = [
    {
      title: t("Edit"),
      dataIndex: "edit_category",
      key: "edit_category",
      align: "center",
      width: 100,
      render: (_, data: any) => {
        return (
          <LinkButton
            disabled={!hasEditAccess(data?.facility_id)}
            onClick={() =>
              router.push(`/daily-business-report-list/edit/${data?.id}`)
            }
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Date"),
      dataIndex: "updated_datetime",
      key: "updated_datetime",
      width: 210,
      align: "center",
      render: (_, row) => (
        <CellWrapper>
          {row?.updated_datetime
            ? dayjs(row?.date).format("YYYY年MM月DD日")
            : ""}
        </CellWrapper>
      ),
    },
    {
      title: t("Facility name"),
      align: "center",
      dataIndex: "facility_name",
      key: "",
      width: 210,
      render: (_, row) => (
        <CellWrapper>
          {row?.facility?.facility_name ? row?.facility?.facility_name : "-"}
        </CellWrapper>
      ),
    },
    {
      title: t("No. of users"),
      align: "center",
      dataIndex: "no_of_users",
      key: "no_of_users",
      width: 210,
      render: (_, row) => {
        const service = row?.facility?.facility_type
        return (
          <CellWrapper>
            {service ? (
              <div
                style={{
                  background: `${
                    service === 1
                      ? "#FFFDF3"
                      : service === 2
                        ? "#F2FFF7"
                        : "#EFF9FF"
                  }
              `,
                  color: `${
                    service === 1
                      ? "#AF8900"
                      : service === 2
                        ? "#4CB476"
                        : "#0782C8"
                  }
            `,
                  border: `${
                    service === 1
                      ? "1px solid #CDA001"
                      : service === 2
                        ? "1px solid #4CB476"
                        : "1px solid #0782C8"
                  }
        `,
                  borderRadius: "4px",
                  padding: "4px 6px",
                  marginRight: "0px",
                }}
              >
                {service === 1 && t("Employment Type A")}
                {service === 2 && t("Employment Type B")}
                {service === 3 && t("Employment Transition Support")}
              </div>
            ) : (
              "-"
            )}
            <p style={{ width: "40px", marginLeft: "4px" }}>
              {`${row?.number_of_user || 0}名`}
            </p>
          </CellWrapper>
        )
      },
    },
    {
      title: t("Entered By "),
      align: "center",
      dataIndex: "staff_name",
      key: "staff_name",
      width: 210,
      render: (_, row) => <CellWrapper>{row?.staff?.staff_name}</CellWrapper>,
    },
    {
      title: t("View Report"),
      align: "center",
      dataIndex: "date",
      key: "",
      width: 210,
      render: (_, row) => (
        <>
          <CellWrapper>
            <Button
              btnText={t("View ")}
              shape={"round"}
              onClick={() =>
                router.push(
                  `/daily-business-report-list/view?facility_id=${row?.facility_id}&id=${row?.id}&date=${dayjs(
                    row?.date,
                  ).format("YYYY-MM-DD")}`,
                )
              }
            />
          </CellWrapper>
        </>
      ),
    },
  ]

  const resetHandler = () => {
    setDate(dayjs())
    setSelectedFacility(null)
    setPage(1)
    setQueries(() => {
      return {
        facility_id: null,
        year: dayjs().year(),
        month: dayjs().month() + 1,
      }
    })
    updateParams({}, "/daily-business-report-list")
    setSearchEnabled(true)
  }
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    scrollToSelectedElement(listContent)
    router.push(
      {
        pathname: "/daily-business-report-list",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  let queryString = `/daily-business-report-list/add?`
  if (selectedFacility) {
    queryString += `facility_id=${selectedFacility}&`
  }
  queryString += `date=${today}`
  return (
    <>
      <PageTitle
        title={t("Daily Business Report")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
        extra={
          <Button
            iconType={"plus-circle"}
            btnText={t("Add new daily business report")}
            type={"primary"}
            shape={"round"}
            size={"large"}
            onClick={() => router.push(`${queryString}`)}
            disabled={!hasAddAccess}
          />
        }
      />
      <DailyBusinessReportListContent>
        <Accordion
          onSearch={onSearch}
          FACILITY_OPTIONS={facilities}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setDate={setDate}
          date={date}
          resetHandler={resetHandler}
        />

        <Card
          title={t("{{date}} Daily business report {{facilityName}}", {
            facilityName: getFacilityNameFromId(
              facilities,
              queries.facility_id,
            ),
            date: dayjs(`${queries?.year}-${queries?.month}`).isValid()
              ? dayjs(`${queries?.year}-${queries?.month}`).format("YYYY年MM月")
              : dayjs().format("YYYY年MM月"),
          })}
          extra={
            <MonthPagination
              showDate={false}
              currentMonth={queries?.month}
              currentYear={queries?.year}
              change={"month"}
              onMonthChange={(year, month) => {
                setQueries({
                  ...queries,
                  year,
                  month,
                })

                updateParams(
                  {
                    ...queries,
                    year,
                    month,
                  },
                  "/daily-business-report-list",
                )
                const dayjsObject = dayjs(`${year}-${month}-01`, "YYYY-M-D")
                setDate(dayjsObject)
              }}
            />
          }
        >
          <div className={"print_btn"}>
            <Button
              btnText={t("Bulk print")}
              type={"primary"}
              shape={"round"}
              iconType={"business-print"}
              borderColor={"#D2D1D1"}
              className={"button"}
              onClick={handlePrint}
            />
          </div>
          <div ref={listContent}>
            <Table
              columns={columns}
              dataSource={response?.data}
              pagination={false}
              scroll={{ x: 1000 }}
              pageSize={pageSize}
              current={+page}
              onChange={handlePaginationChange}
              cellPaddingBlock={12}
              loading={isFetching || isLoading}
            />
          </div>
        </Card>
      </DailyBusinessReportListContent>
    </>
  )
}

export { DailyBusinessReportList }
