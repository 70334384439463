import React, { useRef, useState } from "react"
import { StaffDailyAttendanceRecordOperationOption } from "./StaffDailyAttendanceRecordOperationOption"
import { Flex, Skeleton, Typography } from "antd"
import {
  Button,
  Card,
  IndicatorBox,
  SelectInput,
  scrollToSelectedElement,
  theme,
} from "@project/common"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import dayjs from "dayjs"
import { useRouter } from "next/router"

import { TriangleBox } from "../../../components"
import { getDailyAttendanceStaffResult } from "../../../services"
import { FULL_DATE_FORMAT } from "../../../constants"
import { StaffDailyAttendanceRecordTable } from "./StaffDailyAttendanceRecordTable"
import { useAuthContext } from "../../../context"

export const StaffDailyAttendanceRecord = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const scrollContentRef = useRef<HTMLDivElement>(null)
  const { date, facility_id } = router?.query as any
  const [dataSource, setDataSource] = useState([])
  const [selectedFacility, setSelectedFacility] = useState()
  const [searchParams, setSearchParams] = useState({
    facility_id: facility_id ? facility_id?.split(",") : [],
    date: date || null,
  })
  const { facilities } = useAuthContext()

  const {
    isLoading,
    data: response,
    refetch,
  } = useQuery({
    queryKey: ["attendance-record-daily", searchParams],
    queryFn: () =>
      getDailyAttendanceStaffResult({
        date: searchParams?.date,
        facilityIds: searchParams?.facility_id?.toString(),
      }),
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const data = res?.data?.attendance_data?.staff_results?.map((v) => ({
        id: v?.id,
        add_work_flag2:
          !v?.id || !v?.attendance_type2 || v.attendance_type2 === 2,
        tempId: v?.id || new Date().getTime(),
        facility_id: v?.facility_id || null,
        facility_name: v?.facility?.facility_name || "",
        staff_id: v?.staff_id || null,
        staff_name: v?.staff?.staff_name || "",
        date: v?.date || null,
        remarks: v?.remarks || null,
        rest_minits_flg: v?.rest_minits_flg || null,
        rest_hours: v?.rest_hours || null,
        attendance_type: v?.attendance_type || null,
        attendance_start_time: v?.attendance_start_time || null,
        attendance_end_time: v?.attendance_end_time || null,
        attendance_rest_minits: v?.attendance_rest_minits || null,
        attendance_type2: v?.attendance_type2 || null,
        attendance_start_time2: v?.attendance_start_time2 || null,
        attendance_end_time2: v?.attendance_end_time2 || null,
        attendance_rest_minits2: v?.attendance_rest_minits2 || null,
        is_delete: v?.is_delete || null,
        holiday_flag: v?.holiday_flag || "0",
        is_vacation_scheduled: v?.attendance_shift
          ?.number_of_employees_include_flg
          ? true
          : false,
      }))
      setDataSource(data || [])
    },
  })

  const navigateToBulkEdit = () => {
    const query = []
    if (
      Array.isArray(searchParams.facility_id) &&
      searchParams.facility_id.length > 0
    ) {
      query.push(`facility_id=${searchParams.facility_id?.[0]}`)
    }
    if (searchParams.date) {
      query.push(`date=${searchParams.date}`)
    }
    router.push(`/attendance-record/bulk-edit?${query.join("&")}`)
  }

  //handle table data change
  const onDataSourceChange = ({
    key,
    value,
    id,
  }: {
    key: string
    value: any
    id: number
  }) => {
    const newData = dataSource?.map((data) =>
      data?.tempId === id ? { ...data, [key]: value } : data,
    )
    setDataSource(newData || [])
  }
  return (
    <Flex vertical gap={16}>
      <StaffDailyAttendanceRecordOperationOption
        params={searchParams}
        setSearchParams={(values: any) => {
          setSearchParams(values)
          scrollToSelectedElement(scrollContentRef)
        }}
      />
      <Card
        title={t("{{date}}の出勤表", {
          date: dayjs(date)?.format(FULL_DATE_FORMAT),
        })}
      >
        {isLoading ? (
          <Flex vertical gap={16}>
            <Flex gap={16}>
              <Skeleton.Button active />
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
            <Flex gap={8}>
              <Skeleton.Button active />
            </Flex>
            <Flex gap={8} vertical>
              <Skeleton.Input block active />
              <Flex gap={8}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Flex>
              <Flex gap={8}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Flex>
            </Flex>
            <Flex gap={16}>
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex vertical gap={16}>
              <Flex gap={16} wrap={"wrap"}>
                <Button
                  btnText={t("Bulk Edit")}
                  shape={"round"}
                  onClick={navigateToBulkEdit}
                />
                <TriangleBox
                  label={t("Present")}
                  value={`${response?.data?.attendance_stats
                    ?.present_count} ${t(`people`)}`}
                />
                <TriangleBox
                  label={t("Absenteeism")}
                  value={`${response?.data?.attendance_stats?.absent_count} ${t(
                    `people`,
                  )}`}
                />
              </Flex>
              <Flex>
                <IndicatorBox $bg={theme.colors["error-light"]}>
                  <div className={"box"} />
                  <Typography.Text className={"text"}>
                    {t("vacation schedule")}
                  </Typography.Text>
                </IndicatorBox>
              </Flex>
              <Flex vertical ref={scrollContentRef}>
                <StaffDailyAttendanceRecordTable
                  dataSource={dataSource}
                  onDataSourceChange={onDataSourceChange}
                  resetDataSource={refetch}
                  loading={isLoading}
                />
              </Flex>

              <Flex gap={16} wrap={"wrap"} align={"center"}>
                <SelectInput
                  name={"staff"}
                  placeholder={"--"}
                  options={facilities || []}
                  value={selectedFacility || null}
                  onChange={(val) => setSelectedFacility(val)}
                />
                <Typography.Text>{t("To")}</Typography.Text>
                <Button
                  btnText={t("Add Staff")}
                  iconType={"plus-circle"}
                  type={"primary"}
                  shape={"round"}
                  style={{
                    pointerEvents: !selectedFacility ? "none" : "auto",
                  }}
                  onClick={() =>
                    router?.push(
                      `/attendance-record/day-list/add/${selectedFacility}?date=${date}`,
                    )
                  }
                />
              </Flex>
            </Flex>
          </>
        )}
      </Card>
    </Flex>
  )
}
