import {
  ActionBtns,
  Button,
  ButtonContainer,
  Card,
  CheckBoxGroup,
  Grid,
  InputField,
  scrollToFirstErrorField,
  theme,
} from "@project/common"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MealMasterFormWrapper } from "./MealMasterForm.style"
import { useFormik } from "formik"
import { MealMasterFormField, MealMasterFormFieldSchema } from "../../../types"
import { convertToHalfWidth } from "@project/common/src/constants"
import { AuthContext } from "../../../context"
import { Popconfirm } from "antd"

interface MealMasterFormProps {
  defaultValues?: any
  onSubmit: (values: MealMasterFormField) => void
  isLoading?: boolean
  showDeleteButton?: boolean
  disableDeleteButton?: boolean
  handleDelete?: () => void
  isDeleting?: boolean
}

const MealMasterForm = ({
  defaultValues,
  isLoading,
  onSubmit,
  showDeleteButton = false,
  disableDeleteButton = false,
  handleDelete = () => {},
  isDeleting = false,
}: MealMasterFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)

  const facilityOptions = useMemo(() => {
    return facilities.map((facility) => {
      return {
        label: facility?.facility_name,
        value: facility?.id,
      }
    })
  }, [facilities])

  const initialValues = {
    name: "",
    cost: null,
    meal_using_facility: [],
  }

  const {
    handleSubmit,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    submitCount,
    isValid,
    setFieldValue,
  } = useFormik<MealMasterFormField>({
    initialValues: defaultValues || initialValues,
    validationSchema: MealMasterFormFieldSchema,
    onSubmit: (formValues) =>
      onSubmit({
        ...formValues,
        cost: +formValues?.cost,
      }),
  })

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  const handleBulkFacilityCheck = (allChecked: boolean) => {
    const selectedFacilities = allChecked
      ? facilityOptions?.map((facility) => facility.value)
      : []
    setFieldValue("meal_using_facility", selectedFacilities)
  }

  return (
    <MealMasterFormWrapper>
      <Card
        title={t("Meal information")}
        boxShadow={"4px 4px 16px 0px #3333331F"}
        borderStyle={`1px solid ${theme.colors.border}`}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <Grid
              labelContent={t("Meal name")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <InputField
                name={"name"}
                id={"name"}
                placeholder={t("Enter Meal name")}
                padding={"8px"}
                borderRadius={"4px"}
                value={values?.name}
                maxLength={50}
                error={
                  touched?.name && errors?.name ? t(`${errors?.name}`) : ""
                }
                info={t("(within 50 char)")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Cost")}
              className={"row__header"}
              background
              required
              labelSpan={6}
            >
              <div className={"meal__cost_input"}>
                <InputField
                  name={"cost"}
                  id={"cost"}
                  placeholder={t("Cost")}
                  padding={"8px"}
                  borderRadius={"4px"}
                  width={"216px"}
                  value={values?.cost}
                  maxLength={11}
                  error={
                    touched?.cost && errors?.cost ? t(`${errors?.cost}`) : ""
                  }
                  subInfo={t("半角数字11文字以内")}
                  unitStyle={{
                    color: theme.colors.text,
                    margin: "0 12px",
                  }}
                  unit={t("円")}
                  onChange={({ target: { value } }) => {
                    setFieldValue("cost", convertToHalfWidth(value))
                  }}
                  onBlur={handleBlur}
                />
              </div>
            </Grid>
            <Grid
              labelContent={t("Using facility")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              <ButtonContainer>
                <Button
                  iconType={"check-all"}
                  shape={"round"}
                  borderColor={theme.colors.border}
                  btnText={t("Check all")}
                  onClick={() => handleBulkFacilityCheck(true)}
                />
                <Button
                  btnText={t("Uncheck All")}
                  shape={"round"}
                  borderColor={theme.colors.border}
                  onClick={() => handleBulkFacilityCheck(false)}
                />
              </ButtonContainer>
              <CheckBoxGroup
                items={facilityOptions}
                value={values.meal_using_facility}
                onChange={(val) => setFieldValue("meal_using_facility", val)}
              />
            </Grid>
          </div>
          <ActionBtns justify={"space-between"} mt={"16px"}>
            <ActionBtns justify={"flex-start"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() => router.push("/meal-master")}
                disabled={isLoading}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isLoading}
                disabled={isLoading || isDeleting}
              />
            </ActionBtns>
            {showDeleteButton && (
              <Popconfirm
                title={t("Deleting.Is that OK?")}
                okText={t("OK")}
                onConfirm={handleDelete}
                cancelText={t("Cancel")}
                okButtonProps={{ size: "middle" }}
                cancelButtonProps={{ size: "middle" }}
              >
                <div className={"delete_btn"}>
                  <Button
                    shape={"round"}
                    btnText={t("Delete")}
                    disabled={disableDeleteButton || isDeleting || isLoading}
                    isLoading={isDeleting}
                  />
                </div>
              </Popconfirm>
            )}
          </ActionBtns>
        </form>
      </Card>
    </MealMasterFormWrapper>
  )
}
export { MealMasterForm }
