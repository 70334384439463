import { useTranslation } from "react-i18next"
import { disability_notebook_grade_options } from "../../AddEditForm/UserDetail/OtherInformation"
import { DisabilityCardSection } from "../UserInformation.styles"

const OtherInformationTable = ({ response }) => {
  const { t } = useTranslation()
  const userSuffix = process.env.NEXT_PUBLIC_USER_SUFFIX
  return (
    <>
      <table>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Allergy")}</td>
          <td className={"value"}>{response?.allergy_remarks || "-"}</td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Symptoms")}</td>
          <td className={"value"}>{response?.symptoms || "-"}</td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Medical Information")}</td>
          <td className={"value"}>{response?.medical_info || "-"}</td>
        </tr>

        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Other service which user is using")}</td>
          <td className={"value"}>{response?.other_service || "-"}</td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Disability Notebook Grade")}</td>
          <td className={"value"}>
            {response?.note_book_grade_flag && (
              <DisabilityCardSection>
                <div>
                  {disability_notebook_grade_options?.find(
                    (option) => option.value === response?.note_book_grade,
                  )?.label || "-"}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {response?.note_book_grade === 2
                    ? response?.disability_grade_text
                    : `${response?.disability_grade}級`}
                </div>
                {response?.disability_basic_pension && (
                  <div> {t("※Disability basic pension class 1")}</div>
                )}
              </DisabilityCardSection>
            )}
          </td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Family structure")}</td>
          <td className={"value"}>{response?.family_structure || "-"}</td>
        </tr>

        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Remarks")}</td>
          <td className={"value"}>{response?.remarks || "-"}</td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Special skill· thing of interest")}</td>
          <td className={"value"}>{response?.things_of_interest || "-"}</td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Things to watch out for")}</td>
          <td className={"value"}>{response?.thing_to_watchout || "-"}</td>
        </tr>
      </table>

      <table style={{ marginTop: "20px" }}>
        <tr className={"eighth-table"}>
          <td className={"label"}>
            {t("My page login beneficiary certificate No. or email")}
          </td>
          <td className={"value"}>
            {response?.email.replace(userSuffix, "") || "-"}
          </td>
        </tr>
        <tr className={"eighth-table"}>
          <td className={"label"}>{t("Password")}</td>
          <td className={"value"}>{"-"}</td>
        </tr>
      </table>
    </>
  )
}

export default OtherInformationTable
