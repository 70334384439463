import {
  Button,
  Card,
  DatePicker,
  Grid,
  InputField,
  Modal,
  Table,
  TableDeleteAction,
  dynamicLangString,
  theme,
} from "@project/common"
import { Flex, Spin, Typography, notification } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT } from "../../../constants"

import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useMutation, useQueryClient } from "react-query"
import { deletePaidLeave, updatedPaidLeave } from "../../../services/paidLeave"
import { paidLeaveFormSchema } from "../../../types"

export const PaidLeaveSettingsTable = ({
  isLoading,
  isFetching,
  response,
  pageSize,
  page,
  handlePaginationChange,
  id,
  params,
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isGrantEditModal, setIsGrantEditModal] = useState(false)
  const queryClient = useQueryClient()
  const initialValues = {
    paidLeave: null,
    grantDate: "",
    user_id: +id,
    id: null,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paidLeaveFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        paid_leave: values?.paidLeave,
        grant_date: dayjs(formik.values?.grantDate).format("YYYY-MM-DD"),
        user_id: values?.user_id,
        id: values?.id,
      }
      mutatePaidLeave(data)
    },
  })
  const { mutate: mutatePaidLeave, isLoading: isUpdateLoading } = useMutation(
    updatedPaidLeave,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Paid Leave", "Updated Successfully"]),
        })
        queryClient.invalidateQueries([
          "fetch-paid-leave-list",
          id,
          page,
          params,
        ])
        setIsGrantEditModal(false)
        router.push(`/wage-management/leave-settings/${id}`)
        formik.resetForm()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
        setIsGrantEditModal(false)
      },
    },
  )
  const { mutate: paidLeaveDelete, isLoading: isDeleteLoading } = useMutation(
    deletePaidLeave,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Paid Leave", "Deleted Successfully"]),
        })
        queryClient.invalidateQueries([
          "fetch-paid-leave-list",
          id,
          page,
          params,
        ])
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )

  const onEditCancel = () => {
    setIsGrantEditModal(false)
    formik.resetForm()
  }

  const columns: ColumnsType<any[]> = [
    {
      key: "1",
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Number of paid leave days granted")}
        </span>
      ),
      align: "center",
      render: (row) => row?.paid_leave,
    },
    {
      key: "2",
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Used No.")}
        </span>
      ),
      align: "center",
      render: (row) => row?.used_number || 0,
    },
    {
      key: "3",
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Grant date")}
        </span>
      ),
      align: "center",
      render: (row) => (
        <Typography.Text
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {dayjs(row?.grant_date).format(FULL_DATE_FORMAT)}
        </Typography.Text>
      ),
    },
    {
      key: "4",
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Expire Date")}
        </span>
      ),
      align: "center",
      render: (row) => {
        const parsedDate = dayjs(row?.grant_date)
        const newDate = parsedDate.add(2, "year")
        return (
          <Typography.Text
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {dayjs(newDate).format(FULL_DATE_FORMAT)}
          </Typography.Text>
        )
      },
    },
    {
      key: "5",
      title: t("Action"),
      align: "center",
      width: 200,
      render: (row) => {
        return (
          <Flex justify={"center"} wrap={"wrap"}>
            <Button
              btnText={t("Edit")}
              type={"link"}
              textColor={theme.colors.link}
              disabled={+row?.used_number > 0}
              style={{
                textDecoration: "underline",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                setIsGrantEditModal(true)
                formik.setFieldValue("paidLeave", row?.paid_leave)
                formik.setFieldValue("grantDate", row?.grant_date)
                formik.setFieldValue("id", row?.id)
              }}
            />
            <TableDeleteAction
              id={row?.id}
              disabled={+row?.used_number > 0}
              onConfirm={() => paidLeaveDelete(row?.id)}
              title={t(
                "Are you sure you want to delete paid leave you granted?",
              )}
              subTitle={"Delete"}
              isLoading={isDeleteLoading}
            />
          </Flex>
        )
      },
    },
  ]
  return (
    <>
      <Spin spinning={isDeleteLoading}>
        <Card title={t("Paid leave record")}>
          <Table
            columns={columns}
            total={response?.user_paid_leave_list?.count || 0}
            dataSource={response?.data?.user_paid_leave_list}
            showPaginationOf={"both"}
            pagination={false}
            scroll={{ x: 1000 }}
            pageSize={pageSize}
            current={+page}
            onChange={handlePaginationChange}
            cellPaddingBlock={12}
            loading={isFetching || isLoading}
          />
        </Card>
      </Spin>

      <Modal
        open={isGrantEditModal}
        onCancel={() => onEditCancel()}
        title={t("Confirmation")}
        hederPadding={"15px 30px"}
        width={656}
        footer={null}
      >
        <form>
          <div>
            <Grid
              labelContent={t("Granting paid leave")}
              background
              padding={"14px"}
              required
              labelSpan={10}
            >
              <InputField
                name={"paidLeave"}
                placeholder={t("付与日数を入力")}
                width={"283px"}
                height={"40px"}
                value={formik.values.paidLeave}
                onChange={(e) => {
                  const inputValue = e.target.value
                  if (inputValue === "") {
                    formik.setFieldValue("paidLeave", null)
                  } else {
                    const parsedValue = parseInt(inputValue)
                    if (!isNaN(parsedValue)) {
                      formik.setFieldValue(
                        "paidLeave",
                        parsedValue === 0 ? null : parsedValue,
                      )
                    }
                  }
                }}
                error={
                  formik.touched?.paidLeave && formik.errors?.paidLeave
                    ? t(`${formik.errors?.paidLeave}`)
                    : ""
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Scheduled Grant date")}
              background
              padding={"14px"}
              required
              labelSpan={10}
            >
              <DatePicker
                name={"grantDate"}
                date={
                  dayjs(formik.values.grantDate)?.isValid()
                    ? dayjs(formik.values.grantDate)
                    : dayjs()
                }
                onDateChange={(v) => {
                  formik.setFieldValue(
                    "grantDate",
                    dayjs(v)?.isValid()
                      ? dayjs(v).format("YYYY-MM-DD")
                      : dayjs().format("YYYY-MM-DD"),
                  )
                }}
                format={"YYYY年MM月DD日"}
                error={
                  formik.touched?.grantDate && formik.errors?.grantDate
                    ? t(`${formik.errors?.grantDate}`)
                    : ""
                }
                onBlur={formik.handleBlur}
                popupClassName={"date-picker-popup-height-responsive"}
              />
            </Grid>
          </div>
          <br />
          <Flex wrap={"wrap"} gap={16}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={() => onEditCancel()}
              disabled={isUpdateLoading}
              isLoading={isUpdateLoading}
            />
            <Button
              btnText={t("Update")}
              type={"primary"}
              shape={"round"}
              onClick={() => formik.handleSubmit()}
              disabled={isUpdateLoading}
              isLoading={isUpdateLoading}
            />
          </Flex>
        </form>
      </Modal>
    </>
  )
}
