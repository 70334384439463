import React, { useContext, useEffect, useState } from "react"

// packages
import { t } from "i18next"

// commons | components
import { SelectTime } from "./SelectTime"
import { FuriSelectInput } from "./FuriSelectInput"
import { Box, RadioGroup, SelectInput, Table } from "@project/common"

// styles
import { RemoveBtn } from "../TransportMgmtBulkEditInternal.styles"

// contexts
import { AuthContext } from "../../../context"
import { useTMBulkEdit } from "../../../context/TMBulkEditFormContext"

// types
import {
  TMDriverStaffDataType,
  BulkEditGenericDriverTableProps,
} from "../types"
import { ColumnsType } from "antd/es/table"

// constants
import {
  BINARY_OPTION,
  ROUTE_OPTIONS,
} from "../../UserTransportManagementContainer/constant"

export const BulkEditGenericDriverTable: React.FC<
  BulkEditGenericDriverTableProps
> = ({
  type,
  onStaffCellRemove,
  driverStaffDataSource,
  handleCellInputChange,
}) => {
  const [usedStaffs, setUsedStaffs] = useState<Array<number>>([])

  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const {
    allDriverStaffs,
    allVehicles,
    selectedTransportIds,
    setSelectedTransportIds,
  } = useTMBulkEdit()

  const BULK_EDIT_GENERIC_Driver_TABLE_COLUMN: ColumnsType<TMDriverStaffDataType> =
    [
      {
        title: t("Driver’s name"),
        dataIndex: "driver_staff_id",
        width: 110,
        align: "center",
        render: (val, _, i) => {
          return (
            <Box w={"100%"} justify={"center"} display={"flex"}>
              <FuriSelectInput
                value={val}
                name={"driver_staff_id"}
                options={allDriverStaffs}
                hasDependency
                dependencies={usedStaffs}
                showAllDrivers
                onChange={(val) =>
                  handleCellInputChange(type, i, val, "driver_staff_id")
                }
              />
            </Box>
          )
        },
      },

      {
        title: t("Facility name"),
        dataIndex: "facility_id",
        width: 110,
        align: "center",
        render: (val, _, i) => {
          return (
            <Box miw={110} maw={110} mx={"auto"}>
              <SelectInput
                value={val ? val.toString() : null}
                width={"110px"}
                placeholder={"---"}
                name={"facility-name"}
                options={facilitiesCtx}
                popupMatchSelectWidth={false}
                onChange={(val) =>
                  handleCellInputChange(type, i, +val, "facility_id")
                }
              />
            </Box>
          )
        },
      },

      {
        title: t("Alcholol Checker"),
        align: "center",
        children: [
          {
            title: t("Power"),
            dataIndex: "power",
            align: "center",
            width: 80,
            key: "power",
            render: (val, _, i) => {
              return (
                <RadioGroup
                  value={val}
                  options={BINARY_OPTION}
                  onChange={(e) =>
                    handleCellInputChange(type, i, e.target.value, "power")
                  }
                />
              )
            },
          },
          {
            title: t("Damage to the device"),
            dataIndex: "damage_to_device",
            width: 110,
            align: "center",
            key: "damage_to_device",
            render: (val, _, i) => {
              return (
                <RadioGroup
                  value={val}
                  options={BINARY_OPTION}
                  onChange={(e) =>
                    handleCellInputChange(
                      type,
                      i,
                      e.target.value,
                      "damage_to_device",
                    )
                  }
                />
              )
            },
          },
        ],
      },

      {
        title: t("alcohol check"),
        dataIndex: "alcohol_check",
        key: "alcohol_check",
        width: 80,
        align: "center",
        render: (val, _, i) => {
          return (
            <RadioGroup
              value={val}
              options={BINARY_OPTION}
              onChange={(e) =>
                handleCellInputChange(type, i, e.target.value, "alcohol_check")
              }
            />
          )
        },
      },

      {
        title: t("Visual check"),
        dataIndex: "visual_check",
        width: 115,
        align: "center",
        render: (val, _, i) => {
          return (
            <RadioGroup
              value={val}
              options={BINARY_OPTION}
              onChange={(e) =>
                handleCellInputChange(type, i, e.target.value, "visual_check")
              }
            />
          )
        },
      },

      {
        title: t("Transportation Start Time"),
        dataIndex: "start_time",
        key: "start_time",
        width: 110,
        align: "center",
        render: (val, _, i) => {
          return (
            <Box w={"100%"} justify={"center"} display={"flex"}>
              <SelectTime
                value={val}
                onTimeChange={(value) =>
                  handleCellInputChange(type, i, value, "start_time")
                }
              />
            </Box>
          )
        },
      },

      {
        title: t("Transportation End Time"),
        dataIndex: "end_time",
        key: "end_time",
        width: 110,
        align: "center",
        render: (val, _, i) => {
          return (
            <Box w={"100%"} justify={"center"} display={"flex"}>
              <SelectTime
                value={val}
                onTimeChange={(value) =>
                  handleCellInputChange(type, i, value, "end_time")
                }
              />
            </Box>
          )
        },
      },

      {
        title: t("Conductor"),
        dataIndex: "escort_staff_id",
        key: "escort_staff_id",
        width: 115,
        align: "center",
        render: (val, _, i) => {
          return (
            <Box w={"100%"} justify={"center"} display={"flex"}>
              <FuriSelectInput
                value={val}
                name={"escort_staff_id"}
                options={allDriverStaffs}
                onChange={(value) =>
                  handleCellInputChange(type, i, value, "escort_staff_id")
                }
              />
            </Box>
          )
        },
      },

      {
        title: t("Vehicle"),
        dataIndex: "car_id",
        key: "car_id",
        width: 110,
        align: "center",
        render: (val, _, i) => {
          return (
            <div className={"single-select-input-container"}>
              <SelectInput
                name={"car_id"}
                placeholder={"--"}
                width={"100px"}
                options={allVehicles}
                value={!val ? null : val}
                popupMatchSelectWidth={false}
                onChange={(value) =>
                  handleCellInputChange(type, i, value, "car_id")
                }
              />
            </div>
          )
        },
      },

      {
        title: t("Route No."),
        dataIndex: "route",
        key: "route",
        width: 110,
        align: "center",
        render: (val, rec, i) => {
          return rec.car_id ? (
            <SelectInput
              name={"route"}
              width={"80px"}
              options={ROUTE_OPTIONS}
              placeholder={"--"}
              value={val}
              onChange={(value) =>
                handleCellInputChange(type, i, value, "route")
              }
            />
          ) : (
            <div>{t("Select vehicle first")}</div>
          )
        },
      },

      {
        title: t("Edit"),
        dataIndex: "edit",
        width: 100,
        align: "center",
        render: (_, __, i) => {
          return (
            <RemoveBtn
              className={"delete-btn"}
              btnText={t("Remove")}
              type={"link"}
              shape={"round"}
              onClick={() => onStaffCellRemove(type, i)}
            />
          )
        },
      },
    ]

  // !## React hooks
  // 1. As user selects driver staffs we wanna save/store already used driver for that particular day.
  useEffect(() => {
    const usedDriverStaffs = driverStaffDataSource?.map(
      (staff) => staff.driver_staff_id,
    )
    setUsedStaffs(usedDriverStaffs)
  }, [driverStaffDataSource])

  return (
    <Table
      columns={BULK_EDIT_GENERIC_Driver_TABLE_COLUMN}
      dataSource={driverStaffDataSource}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: selectedTransportIds?.[type],
        onSelect: (_, __, selectedRows) => {
          const selectedIds = selectedRows.map((row) => row?.id)
          setSelectedTransportIds((prev) => ({ ...prev, [type]: selectedIds }))
        },
        columnWidth: 80,
        onChange: (__, _, info) => {
          if (info.type === "all") {
            const allKeys = driverStaffDataSource.map((staff) => staff.id)
            setSelectedTransportIds((prev) =>
              prev?.[type].length > 0
                ? { ...prev, [type]: [] }
                : { ...prev, [type]: allKeys },
            )
          }
        },
        fixed: "left",
      }}
      scroll={{
        x: "max-content",
      }}
    />
  )
}
