import {
  Button,
  ButtonWithRightIcon,
  Card,
  CircleButton,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { UseAssessmentSettings } from "../../context"
import { AssessmentSelectedItem } from "../../types"
import { AssessmentSettingsCategory } from "./AssessmentSettingsCategory"
import { AssessmentSettingsHiddenItemListModal } from "./AssessmentSettingsHiddenItemListModal"
import { AssessmentSettingsListWrapper } from "./AssessmentSettingsList.style"
import { AssessmentSettingsModal } from "./AssessmentSettingsModal"
import { AssessmentSettingsSortableItem } from "./AssessmentSettingsSortableItem"

export const AssessmentSettingsList = ({
  assessment,
  hasWriteAccess = false,
}: {
  assessment: any
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const {
    toggleSorting,
    handleCategorySort,
    saveAssessmentSortData,
    currentlySortingDataInfo,
    isMutating,
  } = UseAssessmentSettings()
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] =
    useState<AssessmentSelectedItem | null>(null)
  const [openHiddenItemModal, setOpenHiddenItemModal] = useState(false)

  // check for hidden items
  const hiddenItems = []
  assessment?.assessment_category?.map((category) => {
    const items = category?.assessment_item?.filter(
      (item) => !item?.show_display,
    )
    if (!category?.show_display) {
      hiddenItems.push({ ...category, assessment_item: items })
    } else if (items?.length) {
      hiddenItems.push({ ...category, assessment_item: items })
    }
  })
  //check for sorting status
  const sorting =
    currentlySortingDataInfo?.isSorting &&
    assessment?.id !== currentlySortingDataInfo?.settingId
  const categoryBtnStatus =
    currentlySortingDataInfo?.isSorting &&
    assessment?.id === currentlySortingDataInfo?.settingId &&
    currentlySortingDataInfo?.categoryId

  return (
    <div>
      <AssessmentSettingsListWrapper
        style={{
          pointerEvents: sorting ? "none" : "auto",
        }}
      >
        {sorting && <div className={"assessment-settings-list-overlay"}></div>}

        <Card title={`[${t(assessment?.title)}] ${t("item settings")}`}>
          <Flex vertical gap={24}>
            {assessment?.info && (
              <div
                style={{
                  color: theme.colors.border,
                  fontWeight: 400,
                }}
              >
                {t(assessment?.info || "")}
              </div>
            )}

            <Flex
              justify={"space-between"}
              align={"center"}
              gap={8}
              wrap={"wrap"}
            >
              <Typography.Paragraph
                style={{ margin: 0, fontWeight: 500, fontSize: "16px" }}
              >
                {t("Category/item name")}
              </Typography.Paragraph>
              <Flex gap={8} wrap={"wrap"}>
                {!assessment?.isSorting ? (
                  <>
                    <Button
                      btnText={t("Hidden Item List")}
                      shape={"round"}
                      disabled={
                        !hiddenItems?.length || sorting || categoryBtnStatus
                      }
                      iconType={"order-list"}
                      onClick={() => {
                        setSelectedItem({
                          settingId: assessment?.id,
                        })
                        setOpenHiddenItemModal(true)
                      }}
                    />
                    <Button
                      btnText={t("Add Category")}
                      shape={"round"}
                      iconType={"plus-circle"}
                      type={"primary"}
                      disabled={!hasWriteAccess || sorting || categoryBtnStatus}
                      onClick={() => {
                        setSelectedItem({
                          settingId: assessment?.id,
                          addItem: true,
                          type: "category",
                        })
                        setOpenModal(true)
                      }}
                    />
                    <CircleButton
                      shape={"circle"}
                      borderColor={theme.colors.action}
                      Icon={
                        <img
                          src={"./assets/icons/basil_sort-outline.svg"}
                          alt={"icon"}
                        />
                      }
                      size={"middle"}
                      disabled={!hasWriteAccess || sorting || categoryBtnStatus}
                      onClick={() =>
                        toggleSorting({
                          settingId: assessment?.id,
                          isSorting: true,
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    <Button
                      btnText={t("Cancel")}
                      shape={"round"}
                      iconType={"remove"}
                      danger
                      disabled={
                        currentlySortingDataInfo?.settingId ===
                          assessment?.id &&
                        isMutating &&
                        !currentlySortingDataInfo?.categoryId
                      }
                      onClick={() => {
                        toggleSorting({
                          settingId: assessment?.id,
                          isSorting: false,
                        })
                      }}
                    />
                    <ButtonWithRightIcon
                      btnText={t("Save sort")}
                      shape={"round"}
                      type={"primary"}
                      onClick={() => saveAssessmentSortData(assessment?.id)}
                      loading={
                        currentlySortingDataInfo?.settingId ===
                          assessment?.id &&
                        isMutating &&
                        !currentlySortingDataInfo?.categoryId
                      }
                      rightIcon={
                        <img
                          src={"./assets/icons/sort-icon-white.svg"}
                          alt={"icon"}
                        />
                      }
                    />
                  </>
                )}
              </Flex>
            </Flex>
            <Flex vertical gap={assessment?.isSorting ? 0 : 24}>
              {assessment.assessment_category
                ?.filter((item) => item?.show_display)
                ?.map((category, index) => (
                  <>
                    {assessment?.isSorting ? (
                      <AssessmentSettingsSortableItem
                        label={category?.title || ""}
                        isFirstItem={index === 0}
                        isLastItem={
                          index === assessment?.assessment_category?.length - 1
                        }
                        totalItems={
                          assessment?.assessment_category?.length || 0
                        }
                        onSort={(dir) =>
                          handleCategorySort({
                            settingId: assessment?.id,
                            categoryId: category?.id,
                            dir,
                          })
                        }
                        hasWriteAccess={hasWriteAccess}
                      />
                    ) : (
                      <AssessmentSettingsCategory
                        key={index}
                        category={category}
                        settingId={assessment?.id}
                        hasWriteAccess={hasWriteAccess}
                      />
                    )}
                  </>
                ))}
            </Flex>
          </Flex>
        </Card>

        {openModal && (
          <AssessmentSettingsModal
            modalType={"normal"}
            title={t("Creating a new category")}
            label={t("Category name")}
            open={openModal}
            selectedItem={selectedItem}
            settingId={selectedItem?.settingId}
            onCancel={() => {
              setSelectedItem(null)
              setOpenModal(false)
            }}
          />
        )}

        {/* //AssessmentSettingsHiddenItemListModal */}
        {openHiddenItemModal && (
          <AssessmentSettingsHiddenItemListModal
            list={hiddenItems || []}
            open={openHiddenItemModal}
            settingId={selectedItem?.settingId}
            onCancel={() => {
              setOpenHiddenItemModal(false)
            }}
          />
        )}
      </AssessmentSettingsListWrapper>
    </div>
  )
}
