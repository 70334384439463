import { theme } from "@project/common"
import styled from "styled-components"
export const Wrapper = styled.div`
  .msg_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    .msg {
      color: ${theme.colors.error};
      font-weight: 400;
      font-size: 16px;
    }
    @media (max-width: ${theme.breakpoints?.md}) {
      flex-direction: column;
      align-items: start;
      .msg {
        font-size: 14px;
      }
    }
  }
  .col-title {
    white-space: nowrap;
  }
`
export const OccupationModalBodyContainer = styled.div`
  .body_content {
    .year_month {
      display: flex;
      margin-bottom: 19px;
      gap: 37px;
      margin-top: 0.4rem;
      flex-wrap: wrap;
      .year {
        display: flex;
        flex-direction: column;
        width: fit-content;
        .year_input {
          display: flex;
          gap: 6px;
          align-items: center;
        }
        .ant-picker {
          border-radius: 5px;
          padding-top: 8px;
          padding-bottom: 8px;
          width: 100px;
          box-shadow: none;
          border: 1px solid ${theme.colors.border};
        }
      }

      .month {
        width: 20%;
        display: flex;
        flex-direction: column;
        .month_input {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
    .form_control {
      display: flex;
      border: 1px solid ${theme.colors?.border};
      &:not(:last-child) {
        border-bottom: none;
      }
      .label {
        border-right: 1px solid ${theme.colors?.border};
        padding: 10px 10px;
        width: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: ${theme.colors.text};
        @media (max-width: ${theme.breakpoints.md}) {
          border-bottom: 1px solid ${theme.colors.border};
          border-right: none;
          width: 100%;
          justify-content: flex-start;
          background-color: ${theme.colors.disabled};
        }
      }
      .form_input {
        padding: 10px 10px;
        width: 72%;
        overflow-x: auto;
        .work_pattern {
          table {
            border-collapse: collapse;
            width: 100%;
            td,
            th {
              border: 1px solid ${theme.colors.border};
              text-align: left;
              padding: 5px;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: ${theme.colors.text};
            }
            th {
              text-align: center;
            }
            tbody > tr > td {
              width: 60px;
            }
          }
        }
        &__occupation {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.4em;
          &--btn {
            padding: 5px !important;
            border-radius: 4px !important;
            margin: 6px;
          }
          &--icon-add {
            color: ${theme.colors.action};
          }
          &--icon {
            color: red;
          }
          .occupation-select-input {
            min-width: 240px;
          }
        }
        .working_hours {
          display: flex;
          align-items: center;
          margin: 5px 0;
          label {
            font-size: 12px;
          }
          input {
            width: 60px;
            margin-left: 2px;
          }
        }
        @media (max-width: ${theme.breakpoints.md}) {
          width: 100%;
        }
      }
      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
      }
    }
  }
`
export const BtnContainers = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
`
