import { pick } from "@project/common"
import { TMUserTableDataType } from "../types"

export const resetUser = (userVal: Partial<TMUserTableDataType>) =>
  pick({ ...userVal, use_transportation_flag: false }, [
    "use_transportation_flag",
    "id",
    "key",
    "facility",
    "facility_id",
    "user_name",
    "user_id",
    "facility_id",
    "date",
  ])
