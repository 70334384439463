import { Button, Calendar, theme } from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { CalendarContent } from "./CalendarContent"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Flex, Typography } from "antd"
import { FULL_DATE_FORMAT_EN } from "../../../constants"
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .sub__info {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .sub__info--content {
      display: flex;
      column-gap: 16px;
      row-gap: 16px;
      align-items: center;
      flex-wrap: wrap;
      .box {
        height: 30px;
        width: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .box--green {
        background-color: ${theme.colors.success};
        color: ${theme.colors.white};
      }
      .box--blue {
        background-color: ${theme.colors.action};
        color: ${theme.colors.white};
      }
      .box--grey {
        background-color: ${theme.colors.border};
      }
    }
  }
  @media print {
    row-gap: 8px !important;
    .no__print {
      display: none;
    }
    .sub__info {
      gap: 8px !important;
    }
  }
`

const UserAttendanceCalendarView = ({
  params,
  handlePrint,
  data,
  allowToNavigateToDayList,
}: {
  handlePrint: () => void
  data?: any
  params?: any
  allowToNavigateToDayList: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className={"no__print"}>
        <Button
          btnText={t("Print")}
          shape={"round"}
          iconType={"business-print"}
          borderColor={theme.colors.border}
          btnBg={theme.colors.disabled}
          onClick={handlePrint}
        />
      </div>

      <div className={"sub__info"}>
        <div className={"sub__info--content left"}>
          <div className={"box box--green"}>{t("迎")}</div>
          <div className={"box box--blue"}>{t("送")}</div>
          <Typography.Text>
            {t("・・・Transportation is checked in the facility application")}
          </Typography.Text>
        </div>
        <div className={"sub__info--content right"}>
          <div className={"box box--grey"}>{t("迎")}</div>
          <div className={"box box--grey"}>{t("送")}</div>
          <Typography.Text>
            {t(
              "・・・There is no check in the pick-up service of the facility application",
            )}
          </Typography.Text>
        </div>
      </div>
      <Calendar
        value={dayjs([params?.year, params?.month - 1])}
        minWidth={"1500px"}
        cellRender={(date: Dayjs) => {
          const currentData = data[`${dayjs(date).format(FULL_DATE_FORMAT_EN)}`]
          return {
            isHoliday: currentData?.holiday === "1" ? true : false,
            content: (
              <CalendarContent
                currentData={currentData}
                showPickDropTime={params?.with_desired_pickup == "1"}
                disableLink={!allowToNavigateToDayList}
                link={`/user-attendance-calendar/daily-list?facilityId=${params?.facilityId}&date=${dayjs(
                  date,
                )?.format(FULL_DATE_FORMAT_EN)}`}
                staffLink={`/attendance-record/day-list?facility_id=${params?.facilityId}&date=${dayjs(
                  date,
                )?.format(FULL_DATE_FORMAT_EN)}`}
              />
            ),
            holidayContent: (
              <Flex
                justify={"center"}
                align={"center"}
                style={{
                  inset: 0,
                  position: "absolute",
                  color: theme.colors.error,
                  fontWeight: 500,
                }}
              >
                {t("holiday")}
              </Flex>
            ),
          }
        }}
      />
      <div className={"no__print"}>
        <Button
          btnText={t("Print")}
          shape={"round"}
          iconType={"print-gray"}
          borderColor={theme.colors.border}
          btnBg={theme.colors.disabled}
          onClick={handlePrint}
        />
      </div>
    </Wrapper>
  )
}

export default UserAttendanceCalendarView
