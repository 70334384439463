import {
  Button,
  Card,
  FACILITY_SERVICE_TYPES,
  PrintFriendlyTable,
  SERVICE_TYPE_VALUES,
  Table,
  getFullEraDate,
  scrollToSelectedElement,
  theme,
  usePrint,
} from "@project/common"
import { Flex, Typography } from "antd"
import { Excel } from "antd-table-saveas-excel"
import { TableProps } from "antd/lib"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { AlertBox } from "../../../../components"
import { useAuthContext } from "../../../../context"
import { getBillingSummaryList } from "../../../../services"
import {
  NhifSummaryListDataSourceType,
  NhifSummaryListResponseType,
} from "../../../../types"
import { SummaryListContentOpOption } from "./SummaryListContentOpOption"
const StyledWrapper = styled(Flex)`
  .last-row {
    background-color: ${theme.colors.gray7} !important;
  }
`

export const SummaryListContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useAuthContext()
  const { display_content, year, month, facilityIds } = router?.query as any
  const selected_display_content = display_content?.split(",")
  const scrollRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const { handlePrint } = usePrint({
    contentRef: contentRef,
    pageStyle: `margin:6mm`,
  })
  // fetch data
  const { isLoading, data: response } = useQuery<
    Partial<NhifSummaryListResponseType>,
    Error
  >({
    queryKey: ["user-billing-list", router?.query],
    queryFn: () =>
      getBillingSummaryList({
        year: +year || dayjs().year(),
        month: +month || dayjs().month() + 1,
        facility: +facilityIds,
        show_zero_billing_amount: selected_display_content?.includes("1")
          ? 1
          : 0,
        breakdown_total_support_cost: selected_display_content?.includes("2")
          ? 1
          : 0,
        sort_by_child_name: selected_display_content?.includes("4")
          ? true
          : null,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    select: (response) => {
      const calculateActualCostTotal = (user_data) =>
        user_data?.map((v) => ({
          ...v,
          actual_costs_total: v?.actual_costs.reduce(
            (a, b) => a + b?.actual_cost_price,
            0,
          ),
        }))
      return {
        ...response,
        data: {
          ...response?.data,
          user_data: response?.data?.user_data?.length
            ? [
                ...calculateActualCostTotal(response?.data?.user_data),
                { ...response?.data?.summary, isSummaryRow: true },
              ]
            : [...calculateActualCostTotal(response?.data?.user_data)],
        },
      }
    },
  })
  //table columns creation start here
  const getActualCostColumns = (costs) =>
    costs?.length > 0
      ? costs?.map((cost) => ({
          key: "14-2",
          title: t(cost?.actual_cost_name),
          align: "center",
          dataIndex: `${cost?.actual_cost_name}`,
          width: 150,
          render: (_, row) => {
            const d = row?.actual_costs?.find(
              (v) => v?.actual_cost_id === cost?.actual_cost_id,
            )
            return d?.actual_cost_price
          },
        }))
      : []
  const getBreakDownColumns = (align: any) => {
    if (selected_display_content?.includes("2")) {
      const modifiedSupportBreakDown = []
      response?.data?.user_data[0]?.support_breakdown?.map((support) => {
        modifiedSupportBreakDown.push(support)
        modifiedSupportBreakDown.push({ ...support, isDayCol: true })
      })
      return [
        {
          key: "break_down",
          title: t("Breakdown of total support costs"),
          dataIndex: "break_down",
          align: align,
          width: 150,
          children: modifiedSupportBreakDown?.map((additionalItem) => {
            let additionalItemTitle = additionalItem?.additional_item
            if (additionalItemTitle == "EMPLOYMENT_SUPPORT_BY_LOCAL_GOV") {
              let postFix = ""
              const facilityType =
                facility_type ||
                response?.data?.user_data?.[0]?.facility?.facility_type
              switch (facilityType) {
                case SERVICE_TYPE_VALUES.TYPE_1:
                  postFix = "_A"
                  break
                case SERVICE_TYPE_VALUES.TYPE_2:
                  postFix = "_B"
                  break
                case SERVICE_TYPE_VALUES.TRANSITION:
                  postFix = "_TRAN"
                  break
              }
              additionalItemTitle += postFix
            }
            return {
              key: additionalItem?.additional_item,
              title: `${t(additionalItemTitle || "")}${
                additionalItem?.isDayCol ? `(${t("day")})` : ""
              }`,
              dataIndex: `${additionalItem?.additional_item}`,
              align: align,
              width: 150,
              render: (_, row) => {
                const isIncluded = selected_display_content?.includes("3")
                if (row?.isSummaryRow) {
                  if (additionalItem?.isDayCol)
                    return row?.support_breakdown?.find(
                      (v) =>
                        v?.additional_item == additionalItem?.additional_item,
                    )?.additional_days
                  return row?.support_breakdown?.find(
                    (v) =>
                      v?.additional_item == additionalItem?.additional_item,
                  )?.[isIncluded ? "additional_cost" : "number_of_credits"]
                }
                if (additionalItem?.isDayCol)
                  return additionalItem?.additional_days

                return isIncluded
                  ? additionalItem?.additional_cost
                  : additionalItem?.number_of_credits
              },
            }
          }),
        },
      ]
    }
    return []
  }
  const serviceCodeCol = () => {
    if (selected_display_content?.includes("5")) {
      return [
        {
          title: t("Decision service code"),
          dataIndex: "decision_service_code",
          key: "decision_service_code",
          align: "center" as const,
        },
      ]
    }
    return []
  }
  const columns: TableProps<NhifSummaryListDataSourceType>["columns"] = [
    {
      key: "1",
      title: t("Provision city"),
      align: "center",
      dataIndex: "municipal_name",
      width: 150,
      render: (_, row) => {
        return (
          row?.user?.user_certificate?.provision_city?.provision_city_name || ""
        )
      },
    },
    {
      key: "2",
      title: t("Beneficiary certificate number"),
      align: "center",
      dataIndex: "beneficiary_certificate_number",
      width: 150,
      render: (_, row) => row?.user_certificate?.number || "",
    },
    {
      key: "3",
      title: t("Parents name"),
      align: "center",
      dataIndex: "parent_name",
      width: 150,
    },
    {
      key: "4",
      title: t("User name"),
      align: "center",
      dataIndex: "user_name",
      width: 150,
      render: (_, row) =>
        row?.isSummaryRow ? row?.user_count : row?.user?.username || "",
    },
    {
      key: "5",
      title: t("Use service"),
      align: "center",
      dataIndex: "service_used",
      width: 150,
      render: (val, row) =>
        t(FACILITY_SERVICE_TYPES[row?.user_certificate?.service_type] || ""),
    },
    ...serviceCodeCol(),
    {
      key: "5",
      title: t("Individual support plan non-creation subtraction"),
      align: "center",
      width: 150,
      dataIndex: "individual_support_plan_non_creation_subtraction",
      render: (nonCreation) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {nonCreation == 0
            ? t("none")
            : nonCreation == 1
              ? t("up to 2 months")
              : nonCreation == 2
                ? t("After 3 months")
                : ""}
        </span>
      ),
    },
    {
      key: "6",
      title: t("User monthly maximum burden"),
      align: "center",
      dataIndex: "burden",
      width: 150,
      render: (_, row) =>
        row?.user_certificate?.burden_upper_limit_monthly_amount?.toLocaleString() ||
        "",
    },
    {
      key: "7",
      title: t("Current month days"),
      align: "center",
      dataIndex: "current_month_days",
      width: 150,
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
    },
    {
      key: "8",
      title: t("Used days"),
      align: "center",
      dataIndex: "used_days",
      width: 150,
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
    },
    {
      key: "9",
      title: t("Provision city invoice amount"),
      align: "center",
      dataIndex: "provision_city_invoice_amount",
      width: 150,
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
    },
    {
      key: "10",
      title: t("Calculated User Burden"),
      align: "center",
      dataIndex: "calculated_user_burden",
      width: 150,
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
    },
    {
      key: "11",
      title: t("Municipal subsidy invoice amount"),
      align: "center",
      dataIndex: "municipal_subsidy_invoice_amount",
      width: 150,
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
    },
    {
      key: "12",
      title: t("Total support cost"),
      align: "center",
      dataIndex: "total_support_cost",
      render: (val) => (val ? `${val.toLocaleString()}` : "0"),
      width: 150,
    },
    ...getBreakDownColumns("center"),
    {
      key: "13",
      title: t("User invoice total"),
      align: "center",
      dataIndex: "invoice_total",
      width: 150,
    },
    {
      key: "14",
      title: t("User invoice breakdown"),
      align: "center",
      children: [
        {
          key: "14-1",
          title: t(
            "Bill for nursing care benefits, training benefits,cost user burden",
          ),
          align: "center",
          dataIndex: "bill_for_nursing_care_user_burden",
          width: 150,
          render: (val) => {
            return val
          },
        },
        ...getActualCostColumns(
          response?.data?.user_data[0]?.actual_costs || [],
        ),
      ],
    },
  ]
  const selectedFacility = facilities?.find((f) => f?.value === facilityIds)
  const facility_name = selectedFacility?.facility_name
  const facility_type = selectedFacility?.facility_type

  //table columns creation end here
  const title = t("{{facility}}  Billing List （{{date}}）", {
    facility: facility_name || "",
    date: `${getFullEraDate(
      year || dayjs().year(),
      month || dayjs().month() + 1,
    )}${month || dayjs().month() + 1}${t("Month")}`,
  })
  //download csv
  const downloadCSV = (dataSources: any[]) => {
    const excel = new Excel()
    excel.setTHeadStyle({
      fontSize: 10,
      h: "center",
      v: "center",
      background: "FFFFFFFF",
      wrapText: false,
    })
    excel.setTBodyStyle({
      fontSize: 10,
      v: "center",
      h: "center",
      border: true,
    })
    excel
      .addSheet(t("Print User Billing List"))
      .addColumns(columns as any)
      .addDataSource(dataSources)
      .saveAs(`${title}.xlsx`)
  }

  return (
    <>
      <StyledWrapper vertical gap={16}>
        <SummaryListContentOpOption
          scrollTo={() => scrollToSelectedElement(scrollRef)}
        />
        <Card>
          <Flex vertical gap={16}>
            <Flex gap={16} wrap={"wrap"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                onClick={() =>
                  router.push({
                    pathname: "/national-health-insurance-billing",
                    query: {
                      year: year || dayjs().year(),
                      month: month || dayjs().month() + 1,
                      facility: facilityIds || "",
                    },
                  })
                }
              />
              <Button
                btnText={t("Print")}
                shape={"round"}
                iconType={"print"}
                type={"primary"}
                onClick={handlePrint}
              />
              <Button
                btnText={t("CSV Output")}
                shape={"round"}
                iconType={"XFileIcon"}
                type={"outlined"}
                onClick={() => downloadCSV(response?.data?.user_data || [])}
              />
            </Flex>
            {!selected_display_content?.includes("1") && (
              <AlertBox
                isCollapseAble={false}
                width={"100%"}
                color={theme.colors.info}
                heading={t(
                  "If you want to print users with a user billing total of 0 yen, you need to change the operating options.",
                )}
                subHeading={t(
                  "「Print the users whose total user billing is 0 yen」Check the checkbox to switch the display.",
                )}
              />
            )}

            <Flex justify={"center"}>
              <Typography.Title level={5}>{title}</Typography.Title>
            </Flex>
            {/* //table */}
            <div ref={scrollRef}>
              <Table
                columns={columns || []}
                dataSource={response?.data?.user_data || []}
                scroll={{
                  x: 1100,
                }}
                headerCellPaddingBlock={"4px"}
                cellPaddingBlock={4}
                loading={isLoading}
                headerBg={theme.colors.gray7}
                borderColor={theme.colors.text}
                rowClassName={(row, i) =>
                  i === response?.data?.user_data?.length - 1 && "last-row"
                }
              />
            </div>
          </Flex>
        </Card>
      </StyledWrapper>
      <div style={{ display: "none" }}>
        <div ref={contentRef}>
          <Flex justify={"center"}>
            <Typography.Title level={5}>{title} </Typography.Title>
          </Flex>
          <PrintFriendlyTable
            columns={columns}
            dataSource={response?.data?.user_data || []}
          />
        </div>
      </div>
    </>
  )
}
