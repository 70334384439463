import { Button, theme, usePrint } from "@project/common"
import { Flex, Skeleton } from "antd"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getNhifPrintAddressToUsers } from "../../../../services"

export const ContentSection = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  box-shadow: 4px 4px 16px 0px #3333331f;
  border-radius: 12px;
  table-layout: fixed;
  .content_data_table {
    tbody tr {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      td {
        .label {
          display: block;
          line-height: 16px;
          min-width: 60mm;
          height: 33.9mm;
          .parent-name-class {
            margin-top: 10px;
          }
          .parent-address-class {
            margin-top: 10px;
          }
          @media print {
            line-height: 12px;
          }
        }
      }
    }
  }
  .content_data {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    min-width: 200px !important;
    padding: 1rem 0 3rem 1rem;
  }

  @media print {
    padding: 0;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
`

export const AddressToUsersPrintAbleContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter() as any
  const printContent = useRef<HTMLDivElement>(null)
  const { facilityIds, adult_ids, year, month } = router?.query as any
  const { handlePrint } = usePrint({
    contentRef: printContent,
    pageStyle: `margin:8mm`,
  })
  const { isLoading, data: response } = useQuery({
    queryKey: ["address-to-users", facilityIds],
    queryFn: () =>
      getNhifPrintAddressToUsers({
        facilityIds: facilityIds,
        adult_ids,
        year,
        month,
      }),
    select: (res) => {
      const selected_users = router?.query?.selected?.split(",") || []
      const data = []
      selected_users?.map((userId) => {
        const user = res?.data?.default?.find(
          (data) => data?.user_id === Number(userId),
        )
        if (user) {
          data.push(user)
        }
      })

      return data || []
    },
  })
  return (
    <Flex vertical gap={16}>
      <Flex gap={8} wrap={"wrap"}>
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          onClick={() => router.back()}
          disabled={isLoading}
        />
        <Button
          btnText={t("Print")}
          shape={"round"}
          type={"primary"}
          iconType={"print"}
          onClick={handlePrint}
          disabled={isLoading}
        />
      </Flex>

      {isLoading ? (
        <ContentSection>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </ContentSection>
      ) : (
        <ContentSection ref={printContent}>
          <table className={"content_data_table"}>
            <tbody>
              <tr key={Math.random()}>
                {response?.map((row, rowIndex) => (
                  <td key={rowIndex}>
                    <div className={"label"}>
                      <div className={"parent-zip-class"}>
                        {"〒"} {row?.parent_zip_code}
                      </div>
                      <div className={"parent-address-class"}>
                        {row?.parent_address || ""}{" "}
                      </div>
                      <div className={"parent-name-class"}>
                        {row?.parent_name} &nbsp;{"様"}
                      </div>
                      <div className={"hash"}></div>
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </ContentSection>
      )}
    </Flex>
  )
}
