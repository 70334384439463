import {
  Button,
  FacilityCheckbox,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Typography } from "antd"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useAuthContext } from "../../../../context"
const Wrapper = styled(Flex)`
  border: 1px solid ${theme.colors.border};
  padding: 20px;
  border-radius: 12px;
`
export const SelectingByFacilities = (): JSX.Element => {
  const { t } = useTranslation()
  const { query } = useRouter()
  const { billing_facilities, facilityIds: queryFacilityIds } = query as any
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const q_facilities = billing_facilities?.split(",") || []
  const [facilityIds, setFacilityIds] = useState([
    ...q_facilities,
    queryFacilityIds,
  ])
  return (
    <Wrapper vertical gap={16}>
      <Typography.Text>
        {t("You can filter by selecting billing facilities")}
      </Typography.Text>
      <FacilityCheckbox
        value={facilityIds || null}
        options={facilities || []}
        checkAllText={t("Check all billing facilities")}
        uncheckAllText={t("Unlock all billing facilities")}
        handleCheckUncheckAll={(type: "check" | "uncheck") => {
          if (type === "check") {
            const ids = facilities?.map((v) => v?.value)
            setFacilityIds(ids)
          }
          if (type === "uncheck") {
            setFacilityIds([])
          }
        }}
        onChange={(ids) => setFacilityIds(ids)}
      />
      <Button
        btnText={t("Search")}
        shape={"round"}
        width={"90px"}
        onClick={() => {
          updateParams(
            {
              ...query,
              billing_facilities: facilityIds?.join(",") || "",
            },
            "/national-health-insurance-billing/prints/address-to-users",
          )
        }}
      />
    </Wrapper>
  )
}
