import styled from "styled-components"
import { Box, theme } from "@project/common"

export const SystemStatusContainer = styled(Box)``

export const SystemStatusInternalContainer = styled(Box)`
  .ant-form-item {
    margin-bottom: 0;
  }
  #label__text {
    text-align: left;
  }
  .col__content {
    display: flex;
    align-items: center;
  }

  .additional-form .ant-col.label {
    background: #ddfcd3 !important;
  }

  .additional-form .severly-ill-warning {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .additional-form .severly-ill-warning .warning-text {
    color: red;
  }

  .subtraction-form .ant-col.label {
    background: #fcded3 !important;
  }

  .col-direction-radio-group
    .col__content
    .ant-form-item-control-input-content
    .ant-radio-group {
    flex-direction: column;
  }

  .evaluation_score_grid .ant-col.label,
  .evaluation_score_grid .ant-col.col__content {
    background: #e5f2f6 !important;
  }

  .basic-reward-questioner .score_document_grid .col__content {
    .file-name {
      word-break: break-all;
    }
  }
`

export const NumberedTitleStyled = styled(Box)`
  margin-bottom: 16px;
  .number {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    color: white;
    background-color: ${theme.colors.action};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    color: ${theme.colors.text};
    font-weight: 500;
  }
`
