import React, { useContext, useMemo } from "react"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"
import { Trans } from "react-i18next"

// commons | components
import { Box, Card, theme } from "@project/common"

// styles
import { BlockStyled, ControllerFlex } from "./MgmtController.styles"

// assets
import HomeIcon from "../../../public/assets/icons/HomeIcon"
import UserIcon from "../../../public/assets/icons/UserIcon"
import { AuthContext } from "../../../context"
import { hasPermissionForMenu } from "../../../utils/sidebarUtils"
import {
  AdditionalItemFacilityPermission,
  AdditionalItemUserPermission,
} from "../../../utils/PermissionKeys"

export const MgmtController = () => {
  const { push } = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)

  const hasFacilityAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(
        permissions,
        AdditionalItemFacilityPermission,
        "both",
      ),
    [isOwner, permissions],
  )

  const hasUserAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, AdditionalItemUserPermission, "both"),
    [isOwner, permissions],
  )

  return (
    <Card title={t("Additional item management")}>
      <ControllerFlex justify={"center"} gap={"50px 32px"}>
        {hasFacilityAccess && (
          <ControllerBlock
            variant={"FACILITY"}
            title={"Facility5"}
            onClick={() => push("/additional-items-management/facility")}
          >
            <p className={"text-top"}>
              <Trans i18nKey={"Set Additional items for each facility"}>
                {"Set Additional items for "}
                <span style={{ color: theme.colors.error }}>
                  {"each facility"}
                </span>
              </Trans>
            </p>
          </ControllerBlock>
        )}

        {hasUserAccess && (
          <ControllerBlock
            variant={"USER"}
            title={"User1"}
            onClick={() => push("/additional-items-management/adult")}
          >
            <p className={"text-top"}>
              <Trans i18nKey={"Set Additional items for each user"}>
                {"Set Additional items for "}
                <span style={{ color: theme.colors.error }}>{"each user"}</span>
              </Trans>
            </p>
          </ControllerBlock>
        )}
      </ControllerFlex>
    </Card>
  )
}

type ControllerBlockProps = {
  title: string
  variant: "FACILITY" | "USER"
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const ControllerBlock = ({
  title,
  variant,
  children,
  onClick,
}: ControllerBlockProps) => {
  return (
    <BlockStyled>
      <div className={"block"} onClick={onClick}>
        <Box display={"flex"} direction={"column"} gap={14} align={"center"}>
          {variant === "FACILITY" ? <HomeIcon /> : <UserIcon />}
          <div className={"title"}>{t(title)}</div>
        </Box>
      </div>
      <div className={"children"}>{children}</div>
      {variant === "FACILITY" && (
        <div
          style={{
            color: theme.colors.error,
          }}
        >
          <Trans i18nKey={"Additional item management Label"}>
            {"※For monthly additions and subtractions, please"}
            {"use the "}
            <a
              style={{
                color: "#0782C8",
                cursor: "hand",
                textDecoration: "underline",
              }}
              href={"/facility-information"}
              target={"_blank"}
            >
              {"status management system"}
            </a>
            {". Please use"}
            <br />
            {
              "this only if you need to change in the middle of the month or set daily."
            }
          </Trans>
        </div>
      )}
    </BlockStyled>
  )
}
