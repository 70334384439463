import { API } from "@project/common"
import {
  ShuttleApiValues,
  ShuttleBasicInfoValues,
} from "../types/shuttleMaster.types"

export const saveShuttleMaster = async (values: ShuttleBasicInfoValues) => {
  return API.post(`/shuttle-cars`, values)
}
export const updateShuttleMaster = async ({
  values,
  id,
}: {
  values: ShuttleBasicInfoValues
  id: string | number
}) => {
  return API.put(`/shuttle-cars/${id}`, { ...values })
}

export const fetchAllShuttleMaster = async (
  params,
): Promise<ShuttleApiValues> => {
  const response: ShuttleApiValues = await API.get("/shuttle-cars", { params })
  return response
}
export const fetchOneShuttleMaster = async (
  id: number | string,
): Promise<ShuttleBasicInfoValues> => {
  const response = await API.get(`/shuttle-cars/${id}`)
  return response.data
}
export const deleteShuttleMaster = async (
  id: number | string,
): Promise<ShuttleBasicInfoValues> => {
  const response = await API.delete(`/shuttle-cars/${id}`)
  return response.data
}
