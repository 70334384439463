import {
  CheckBoxGroup,
  FULL_MONTHS_NAME,
  SerialNumberBox,
  theme,
} from "@project/common"
import { Typography } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AuthContext } from "../../../../context/AuthContext"
import { InputChangeProps } from "../../../../types"
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .text__content {
    display: flex;
    flex-direction: column;
  }
`
export const SectionThree = ({
  defaultData,
  handleCalendarOtherInputChange,
  date,
}: {
  defaultData?: any[]
  handleCalendarOtherInputChange?: (values: InputChangeProps) => void
  date?: Dayjs | null | undefined
}) => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  return (
    <Wrapper>
      <SerialNumberBox number={3} text={t("Select facilities to overwrite")} />
      <div className={"facility__content"}>
        <CheckBoxGroup
          items={facilities || []}
          name={"override_facilities"}
          value={defaultData}
          onChange={(list: CheckboxValueType[]) => {
            handleCalendarOtherInputChange({
              key: "override_facilities",
              val: list,
            })
          }}
        />
      </div>
      <div className={"text__content"}>
        <Typography.Text>
          {t(
            "* Overwrite the individual business day setting of {{month}} {{year}} for the selected facility with the above content.",
            {
              year: dayjs(date)?.year(),
              month: dayjs(date)?.isValid()
                ? t(FULL_MONTHS_NAME[dayjs(date).month()])
                : "",
              jpDate: dayjs(date).format("YYYY年MM月"),
            },
          )}
        </Typography.Text>
        <Typography.Text>
          <span
            style={{
              color: theme.colors.error,
            }}
          >
            {t(
              "* If there is a business day setting for each facility, it will be overwritten and saved.",
            )}
          </span>
        </Typography.Text>
      </div>
    </Wrapper>
  )
}
