import {
  AccordionCard,
  Button,
  CheckBox,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
} from "@project/common"
import { UserServiceProvisionResultOperationContainer } from "./UserServiceProvisionResult.styles"
import { useTranslation } from "react-i18next"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { IUserServiceProvisionRecordOperation } from "../../types/UserServiceProvisionRecord.types"
import { useEffect, useState } from "react"

export const UserServiceProvisionResultOperation = (
  props: IUserServiceProvisionRecordOperation,
) => {
  const { t } = useTranslation()
  const { params, handleSearch, handleReset, facilities } = props
  const [searchOptions, setSearchOptions] = useState(params)
  useEffect(() => {
    setSearchOptions(params)
  }, [params])
  return (
    <UserServiceProvisionResultOperationContainer>
      <AccordionCard
        defaultActiveKey={"1"}
        items={[
          {
            key: "1",
            label: t("Operation Options"),
            children: (
              <OperationSearchContent>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Facility")}
                      width={"138px"}
                    />
                  </div>
                  <FacilityCheckbox
                    options={facilities}
                    value={searchOptions.facilityIds}
                    handleCheckUncheckAll={(type: "check" | "uncheck") => {
                      if (type === "check") {
                        const ids = facilities?.map((v) => v?.value)
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: ids,
                        })
                      }
                      if (type === "uncheck") {
                        setSearchOptions({
                          ...searchOptions,
                          facilityIds: [],
                        })
                      }
                    }}
                    onChange={(list: CheckboxValueType[]) => {
                      setSearchOptions({
                        ...searchOptions,
                        facilityIds: list.toString().split(","),
                      })
                    }}
                  />
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Use service")}
                      width={"138px"}
                    />
                  </div>
                  <CheckBoxGroup
                    items={FACILITY_TYPES || []}
                    value={searchOptions.service}
                    onChange={(list: CheckboxValueType[]) => {
                      setSearchOptions({
                        ...searchOptions,
                        service: list
                          ?.toString()
                          ?.split(",")
                          ?.map((s) => +s),
                      })
                    }}
                  />
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Display Settings")}
                      width={"138px"}
                    />
                  </div>
                  <CheckBox
                    label={t(
                      "Display even user whose achievement are not registered",
                    )}
                    value={"1"}
                    onChange={() => {
                      setSearchOptions((prev) => {
                        return {
                          ...prev,
                          display_users_without_achievement:
                            searchOptions?.display_users_without_achievement ==
                            "1"
                              ? ""
                              : "1",
                        }
                      })
                    }}
                    checked={
                      searchOptions?.display_users_without_achievement == "1"
                    }
                  />
                </div>
                <div className={"search__content flex-start"}>
                  <div className={"mt"}>
                    <LabelTextWithLeftArrow
                      label={t("Year Month")}
                      width={"138px"}
                    />
                  </div>
                  <div className={"flex"}>
                    <DatePicker
                      date={dayjs([
                        searchOptions.year,
                        searchOptions.month - 1,
                      ])}
                      onDateChange={(date: Dayjs) => {
                        setSearchOptions({
                          ...searchOptions,
                          year: date.year(),
                          month: date.month() + 1,
                        })
                      }}
                      picker={"month"}
                      format={"YYYY年MM月"}
                    />
                  </div>
                </div>
                <div className={"search__content btns"} style={{ gap: "10px" }}>
                  <Button
                    btnText={t("Reset search")}
                    size={"large"}
                    shape={"round"}
                    onClick={() => handleReset()}
                  />
                  <Button
                    btnText={t("Search")}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    iconType={"search"}
                    onClick={() => handleSearch(searchOptions)}
                  />
                </div>
              </OperationSearchContent>
            ),
          },
        ]}
      />
    </UserServiceProvisionResultOperationContainer>
  )
}
