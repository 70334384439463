// packages
import { Button, Flex } from "antd"
import { FormikProps } from "formik"
import { ColumnsType } from "antd/es/table"

// commons
import { Box } from "@project/common"
import { FastInput } from "../../common/FastInput"

// assets
import RedRemoveIcon from "../../../../public/assets/icons/RedRemoveIcon"

// types
import {
  FacilityWorkDataType,
  FacilityWorkTableType,
  StaticManufacturingCostDataType as StaticColumnsType,
} from "../../../../types"

export const getDynamicFacilityWorkColumns = (
  formik: FormikProps<{ facilityWorkData: FacilityWorkTableType[] }>,
  handleColRemove: (id: string | number) => void,
  workData: FacilityWorkDataType[],
): ColumnsType<StaticColumnsType> => {
  const { errors, setFieldValue, touched, setFieldTouched } = formik

  return workData.map((item, index) => {
    return {
      key: item.id,
      dataIndex: item.id,
      align: "left",
      className: "work-edit-cell",
      title: () => {
        const onChangeTitle = (title: string) => {
          setFieldValue(`facilityWorkData.[${index}].work_title`, title)
        }
        return (
          <Flex align={"center"} gap={8} justify={"space-between"}>
            <Box maw={"100%"} miw={192}>
              <FastInput
                val={item?.work_title}
                prefix={<span>{"○○ "}</span>}
                size={"large"}
                onValChange={onChangeTitle}
                onBlur={() =>
                  setFieldTouched(
                    `facilityWorkData.[${index}].work_title`,
                    true,
                  )
                }
                error={
                  touched.facilityWorkData?.[index]?.work_title &&
                  // @ts-ignore
                  errors.facilityWorkData?.[index]?.work_title &&
                  // @ts-ignore
                  errors.facilityWorkData[index]?.work_title
                }
              />
            </Box>
            <Button
              icon={<RedRemoveIcon />}
              type={"text"}
              size={"small"}
              onClick={() => handleColRemove(item.id)}
            />
          </Flex>
        )
      },
      width: 244,
      render: (__, rec) => {
        if (rec.isCategory) {
          return null
        }

        if (rec.notEditable) {
          return isNaN(Number(item[rec.key]))
            ? 0
            : Number(item[rec.key]).toLocaleString()
        }

        const onChangeValue = (val: string) => {
          setFieldValue(`facilityWorkData[${index}][${rec.key}]`, +val)
        }

        return (
          <Box miw={220} maw={"100%"}>
            <FastInput
              val={item?.[rec.key]}
              onValChange={onChangeValue}
              type={"number"}
            />
          </Box>
        )
      },
    }
  })
}
