import { getFullDateWithEraIncluded } from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { ActualTableContent } from "./DisabledUserOutpatientBenefitsPrintContent.style"

export const ServiceTypeTableContent = ({
  data,
}: {
  data: any
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <ActualTableContent>
      <tbody id={"duobp__table-body"}>
        <tr>
          <td className={"align-center"} rowSpan={3}>
            {t("Service Type")}
          </td>
          <td className={"align-center"}>
            {data?.a_08 === "1"
              ? "45"
              : data?.a_08 === "2"
                ? "46"
                : data?.a_08 === "3"
                  ? "43"
                  : ""}
          </td>
          <td className={"align-center"}>{t("Start date")}</td>
          <td className={"align-center"}>
            {t("{{wareki_en_date}}", {
              wareki_en_date: data?.a_09
                ? dayjs(data?.a_09)
                    .locale("en")
                    .format("YYYY-MM-DD")
                : "",
              wareki_js_date: data?.a_09
                ? getFullDateWithEraIncluded(dayjs(data?.a_09))
                : "",
            })}
          </td>
          <td className={"align-center"}>{t("End date")}</td>
          <td className={"align-center"}>
            {t("{{wareki_en_date}}", {
              wareki_en_date: data?.a_10
                ? dayjs(data?.a_10)
                    .locale("en")
                    .format("YYYY-MM-DD")
                : "",
              wareki_js_date: data?.a_10
                ? getFullDateWithEraIncluded(dayjs(data?.a_10))
                : "",
            })}
          </td>
          <td className={"align-center"}>{t("Number of days of use")}</td>
          <td className={"align-center"}>{data?.c_03 || ""}</td>
          <td className={"align-center"}>{t("Hospitalization days")}</td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{t("Start date")}</td>
          <td className={"align-center"}></td>
          <td className={"align-center"}>{t("End date")}</td>
          <td className={"align-center"}></td>
          <td className={"align-center"}>{t("Number of days of use")}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{t("Hospitalization days")}</td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{t("Start date")}</td>
          <td className={"align-center"}></td>
          <td className={"align-center"}>{t("End date")}</td>
          <td className={"align-center"}></td>
          <td className={"align-center"}>{t("Number of days of use")}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{t("Hospitalization days")}</td>
          <td className={"align-center"}>{""}</td>
        </tr>
      </tbody>
    </ActualTableContent>
  )
}
