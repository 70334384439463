import React, { useState } from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Space } from "antd"

// commons | components
import {
  Box,
  Button,
  DatePicker,
  AccordionCard,
  useUpdateSearchParams,
} from "@project/common"
import { SystemStatusMgmtForm } from "./SystemStatusMgmtForm"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// styles
import { SystemStatusContainer, SystemStatusInternalContainer } from "./styles"

// types
import { QueryParams, SystemStatusMgmtOperation } from "./types"

// constants
import { MONTH_DATE_FORMAT } from "../../constants"

export const SystemStatusMgmtInternal = () => {
  const today = dayjs()
  const { query } = useRouter()

  const [updateParams] = useUpdateSearchParams()

  const [filters, setFilters] = useState<SystemStatusMgmtOperation>({
    year_month: query?.year_month
      ? dayjs(query?.year_month.toString())
      : dayjs(),
  })

  const memoizedQuery: QueryParams = React.useMemo(() => {
    const facility_id = Number(query.facility_id)
    const year_month = query?.year_month
      ? dayjs(query?.year_month?.toString())
      : today

    return {
      facility_id,
      year_month,
    }
  }, [query])

  // 1. handle filter operation submission
  const handleSubmission = () => {
    updateParams(
      { year_month: filters.year_month.format("YYYY-MM") },
      `/facility-information/system-status/${query.facility_id}`,
    )
  }

  return (
    <SystemStatusInternalContainer
      display={"flex"}
      direction={"column"}
      gap={24}
    >
      <AccordionCard
        defaultActiveKey={["system-status-management"]}
        items={[
          {
            key: "system-status-management",
            label: t("Operation Options"),
            children: (
              <SystemStatusContainer
                display={"flex"}
                direction={"column"}
                gap={"16px"}
              >
                <OperationFields
                  label={"Year Month"}
                  align={"center"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                >
                  <Space wrap size={16}>
                    <Box w={135} maw={135} miw={135} className={"input-field"}>
                      <DatePicker
                        picker={"month"}
                        id={"year_month"}
                        date={filters.year_month}
                        onDateChange={(date) =>
                          setFilters({
                            year_month: date,
                          })
                        }
                        format={MONTH_DATE_FORMAT}
                      />
                    </Box>
                    <Button
                      shape={"round"}
                      type={"outlined"}
                      btnText={t("Display Change")}
                      onClick={() => handleSubmission()}
                    />
                  </Space>
                </OperationFields>
              </SystemStatusContainer>
            ),
          },
        ]}
      />

      <SystemStatusMgmtForm memoizedQuery={memoizedQuery} />
    </SystemStatusInternalContainer>
  )
}
