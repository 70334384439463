import React from "react"
import { useRouter } from "next/router"

// packages
import dayjs from "dayjs"
import { t } from "i18next"

// commons | components
import { WorkEditInternal } from "./WorkEditInternal"
import { useUpdateSearchParams } from "@project/common"

// types
import { ProjectCostQueryParam } from "../../../types"

// constants
import { CostStatementInternal } from "./CostStatementInternal"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"

export const ProjectCostStatementTab = ({
  params,
  setSearchParams,
  hasEditAccess,
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}) => {
  const today = dayjs()

  const { query } = useRouter()

  const [updateParams] = useUpdateSearchParams()

  // ! ## query params
  const memoizedQuery: ProjectCostQueryParam = React.useMemo(() => {
    const facility_id = Number(query.facility_id)
    const date = query?.date ? dayjs(query?.date?.toString()) : today
    const current_content = query?.current_content
      ? (query?.current_content as ProjectCostQueryParam["current_content"])
      : "COST_MGMT"

    return {
      facility_id,
      date,
      current_content,
    }
  }, [query])

  return (
    <div>
      <TabTopContent
        tab={t("Revenue")}
        onChange={(date) => {
          setSearchParams({ ...params, date: dayjs(date).format("YYYY-MM") })
          updateParams(
            {
              ...query,
              date: date.format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />

      {memoizedQuery.current_content === "WORK_MGMT" ? (
        <WorkEditInternal memoizedQuery={memoizedQuery} />
      ) : (
        <CostStatementInternal
          memoizedQuery={memoizedQuery}
          hasEditAccess={hasEditAccess}
        />
      )}
    </div>
  )
}
