import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { CheckBox, InputField } from "@project/common"
import { BreakTitle } from "../StaffAttendanceComponent.styles"

const BreakTimeWrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .attendance-information {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
    .label {
      padding: 12px;
      background-color: rgb(243, 243, 243);
      width: 25%;
      text-transform: capitalize;
      @media screen and (max-width: 540px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500px;
        border-radius: 10px;
      }
    }
    .value {
      padding: 12px;
      @media screen and (max-width: 540px) {
        padding: 12px 0px;
        border: none !important;
      }
    }
    .link {
      color: #0782c8;
    }
  }
  .attendance-information:last-child {
    @media screen and (max-width: 540px) {
      margin-bottom: 0px !important;
    }
  }
  .value_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .break_content {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 20px;
      @media screen and (max-width: 280px) {
        gap: 10px;
      }
      span:nth-child(2) {
        @media screen and (max-width: 539px) {
          padding-inline-end: 0px !important;
          font-size: 13px !important;
        }
        @media screen and (max-width: 280px) {
          font-size: 11px !important;
        }
      }

      .input_content {
        display: flex;
        align-items: center;
        gap: 8px;
        @media screen and (max-width: 540px) {
          width: 40%;
        }
        span {
          width: 40px;
          @media screen and (max-width: 540px) {
            width: 40px;
          }
        }
      }
    }
    .title {
      color: #e00000;
    }
  }
`
interface IProps {
  formik: any
}
const BreakTime: React.FC<IProps> = ({ formik }) => {
  const { t } = useTranslation()
  return (
    <>
      <BreakTitle>
        <h2 className={"break_line"}>{t(`Break time`)}</h2>
      </BreakTitle>
      <BreakTimeWrapper>
        <table>
          <tr className={"attendance-information"}>
            <td className={"label"}>{t("Break time")}</td>
            <td className={"value"}>
              <div className={"value_content"}>
                <div className={"break_content"}>
                  <CheckBox
                    name={"break-time"}
                    defaultChecked={formik.values.rest_minits_flg}
                    label={t("Set break time")}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "rest_minits_flg",
                        e.target.checked ? 1 : 0,
                      )
                    }}
                  />
                  <div className={"input_content"}>
                    <InputField
                      name={"hr"}
                      id={"hr"}
                      width={"134px"}
                      placeholder={"0"}
                      padding={"8px"}
                      borderRadius={"4px"}
                      value={formik.values.rest_hours}
                      disabled={!formik.values.rest_minits_flg}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "rest_hours",
                          Number(e.target.value),
                        )
                      }}
                    />
                    <span>{t("Hr")}</span>
                  </div>
                </div>
                <p className={"title"}>
                  {t(
                    "※Please set if you want to include vacation time such as half-holidays in the number of hours that a full-time employee should work.",
                  )}
                </p>
              </div>
            </td>
          </tr>
        </table>
      </BreakTimeWrapper>
    </>
  )
}

export { BreakTime }
