import {
  Button,
  LabelTextWithLeftBorder,
  removeBlankAttributes,
} from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NhifAddressToUsersResposeDataType } from "../../../../types"
import { AddressToUserListTable } from "./AddressToUserListTable"

export const AddressToUsersList = ({
  loading,
  responseData,
}: {
  loading?: boolean
  responseData: NhifAddressToUsersResposeDataType | undefined
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilityIds } = router?.query as any
  const [dataSource, setDataSource] = useState(
    responseData?.data?.city_wise || {},
  )
  useEffect(() => {
    setDataSource(responseData?.data?.city_wise || {})
  }, [responseData])

  const handleCheckAndUnCheckAll = (action: "ALL" | "NONE") => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => ({
        ...data,
        isChecked: action === "ALL" ? true : false,
      }))
    })
    setDataSource(newData)
  }
  const handleSingleCheckAndUncheck = ({
    userId,
    checked,
    tableRows,
    selectedKey,
  }: {
    userId: number
    checked: boolean
    tableRows?: "ALL" | "NONE"
    selectedKey?: string
  }) => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => {
        if (tableRows && selectedKey === key) {
          return {
            ...data,
            isChecked: tableRows === "ALL" ? true : false,
          }
        }
        if (data?.user_id === userId) {
          return {
            ...data,
            isChecked: checked,
          }
        }
        return data
      })
    })
    setDataSource(newData)
  }
  const gotoPrintPage = () => {
    const checkedIds = []
    Object?.keys(dataSource)?.map((key) => {
      return dataSource[key]?.map((data) => {
        if (data?.isChecked) {
          checkedIds.push(data?.user_id)
        }
      })
    })
    router.push({
      pathname: `/national-health-insurance-billing/prints/address-to-users/print`,
      query: removeBlankAttributes({
        selected: checkedIds?.join(","),
        facilityIds,
        year: dayjs().year(),
        month: dayjs().month() + 1,
      }),
    })
  }
  const getInvoiceTotal = () => {
    let total = 0
    Object?.keys(dataSource)?.map((key) => {
      return dataSource[key]?.map((data) => {
        if (data?.isChecked) {
          total = total + data?.invoice_amount
        }
      })
    })
    return total
  }

  return (
    <Flex vertical gap={16}>
      <Flex gap={8} wrap={"wrap"}>
        <Button
          btnText={t("Check all")}
          shape={"round"}
          iconType={"check-all"}
          onClick={() => handleCheckAndUnCheckAll("ALL")}
        />
        <Button
          btnText={t("Clear all")}
          shape={"round"}
          onClick={() => handleCheckAndUnCheckAll("NONE")}
        />
        <Button
          btnText={t("Print")}
          iconType={"print"}
          shape={"round"}
          type={"primary"}
          onClick={gotoPrintPage}
          disabled={getInvoiceTotal() === 0}
        />
      </Flex>
      {loading ? (
        <>
          <Skeleton.Input active />
          <Skeleton active />
        </>
      ) : (
        <>
          {dataSource &&
            Object?.keys(dataSource)?.map((key) => {
              const data = dataSource[key]
              const currentData = data?.[0]
              const label = `${currentData?.provision_city}(${currentData?.city_number})`
              return (
                <>
                  <LabelTextWithLeftBorder label={label} />
                  <AddressToUserListTable
                    dataSource={data}
                    loading={loading}
                    handleSingleCheckAndUncheck={(values) =>
                      handleSingleCheckAndUncheck({
                        ...values,
                        selectedKey: key,
                      })
                    }
                    isAllChecked={data?.every((each) => each?.isChecked)}
                  />
                </>
              )
            })}
        </>
      )}

      <Flex gap={8} wrap={"wrap"}>
        <Button
          btnText={t("Print")}
          iconType={"print"}
          shape={"round"}
          type={"primary"}
          onClick={gotoPrintPage}
          disabled={getInvoiceTotal() === 0}
        />
        <div
          style={{
            border: "1px solid #000",
            padding: "6px",
          }}
        >
          {t("Amount charged to the user total")}{" "}
          <b>
            {getInvoiceTotal()}
            {t("Yen")}
          </b>
        </div>
      </Flex>
    </Flex>
  )
}
