import { Grid, RadioGroup } from "@project/common"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import React, { useCallback, useMemo } from "react"

const Wrapper = styled.div`
  .search__content {
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
  .row__header {
    background-color: rgba(197, 248, 181, 0.32) !important;
    text-align: left;
  }
  .ant-col {
    padding: 0px !important;
    #label__text {
      padding: 15px 16px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 5px 0px;
    }
  }

  .ant-radio-group {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    column-gap: 8px;
    @media screen and (width: 1024px) {
      border-top: 1px solid #d1d1d1;
    }

    .ant-radio-wrapper {
      span {
        padding-inline-end: 0px !important;
      }
    }
    @media screen and (max-width: 768px) {
      justify-content: flex-start !important;
      border-right: none;
      padding: 15px 0 0 0;
    }
  }

  .ant-btn {
    white-space: nowrap !important;
  }

  .option2-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .option2-label {
      margin-left: 20px;
    }
    .option2-radio {
      .ant-radio-group {
        display: flex;
        gap: 20px;
      }
    }
  }
`
const AdditionWrapper = styled.div``
const SubtractionWrapper = styled.div`
  margin-top: 20px;
  .row__header {
    background-color: #fcded3 !important;
  }
`
const Header = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
const AdditionalItemsFormScreen = ({ formik }) => {
  const { t } = useTranslation()
  const handleChange = useCallback(
    (additionalItem, newValue) => {
      formik.setFieldValue(
        "additional_items",
        formik.values.additional_items.map((item) =>
          item.additional_item === additionalItem
            ? { ...item, additional_item_value: newValue }
            : item,
        ),
      )
    },
    [formik],
  )

  const renderRadioGroup = useCallback(
    (item) => {
      let isDisable = false
      if (item?.additional_item == "SHORT_TIME_USE_SUBTRACTION") {
        isDisable = formik?.values?.is_short_time_use_subtraction_disable
      } else if (
        item?.additional_item == "TARGET_WAGE_ACHIEVEMENT_INSTRUCTOR"
      ) {
        isDisable = formik?.values?.basic_staff_arrangement != "1"
      } else {
        isDisable = false
      }

      return (
        <Grid
          key={item.additional_item}
          labelContent={t(item.label)}
          className={"row__header"}
          background
          labelSpan={6}
        >
          <RadioGroup
            value={item.additional_item_value}
            options={item.options.map((option) => ({
              label: t(option.label),
              value: option.value,
            }))}
            onChange={(e) => handleChange(item.additional_item, e.target.value)}
            disabled={isDisable}
          />
          <div className={"option2-container"}>
            <div className={"option2-label"}>
              {item?.options2
                ? t("Treatment improvement addition ( V ) ")
                : null}
            </div>
            {item?.options2 ? (
              <div className={"option2-radio"}>
                <RadioGroup
                  value={item.additional_item_value2}
                  options={item.options2?.map((option) => ({
                    label: t(option?.label),
                    value: option?.value,
                  }))}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "additional_items",
                      formik.values.additional_items.map((d) =>
                        d.additional_item2 === item.additional_item2
                          ? {
                              ...d,
                              additional_item_value2: e.target.value,
                            }
                          : d,
                      ),
                    )
                  }}
                />
              </div>
            ) : null}
          </div>
        </Grid>
      )
    },
    [handleChange, t],
  )

  const additionItems = useMemo(
    () =>
      formik.values.additional_items?.filter(
        (item) => item.type === "addition",
      ),
    [formik.values.additional_items],
  )

  const subtractionItems = useMemo(
    () =>
      formik.values.additional_items?.filter(
        (item) => item.type === "subtraction",
      ),
    [formik.values.additional_items],
  )

  return (
    <>
      <Wrapper>
        <AdditionWrapper>
          <Header>{t("Addition:")}</Header>
          {additionItems?.map(renderRadioGroup)}
        </AdditionWrapper>
        <SubtractionWrapper>
          <Header>{t("Subtraction:")}</Header>
          {subtractionItems?.map(renderRadioGroup)}
        </SubtractionWrapper>
      </Wrapper>
    </>
  )
}

export { AdditionalItemsFormScreen }
