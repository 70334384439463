import {
  breakTimeInString,
  Button,
  Card,
  getSumOfAllBreakTimes,
  getTotalTimeInSec,
  Table,
  theme,
  USER_ATTENDANCE_VALUES,
} from "@project/common"
import { TableProps } from "antd/es/table"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { FULL_DATE_FORMAT_EN, YM_FORMAT } from "../../../constants"
import { AuthContext } from "../../../context"
import { getUserServiceContentResultMonthlyDetails } from "../../../services"
import {
  hasPermissionForFacility,
  UserAttendanceManagementPermission,
} from "../../../utils/PermissionKeys"

export const UserAttendanceManagementMonthlyDetail = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isOwner, permissions } = useContext(AuthContext)
  const { id, date, facility_id } = router?.query as any

  const [userName, setUserName] = useState(null)

  const currentDate = date ? dayjs(date) : dayjs()

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["userAttendanceManagementMonthlyDetail-list-page", id],
    queryFn: () =>
      getUserServiceContentResultMonthlyDetails({
        year: dayjs(currentDate).year(),
        month: dayjs(currentDate).month() + 1,
        user_id: +id,
        facility_id,
      }),
    onSuccess: (response: any) => {
      setUserName(response?.data?.[0]?.user?.username)
    },
  })

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        UserAttendanceManagementPermission,
        "write",
        facility_id,
      ),
    [isOwner, permissions, facility_id],
  )

  const getDataSource = useMemo(() => {
    let diffSeconds,
      breakTime,
      registered_time_attendance,
      registered_time_break

    const source = response?.data?.map((currentData) => {
      const shiftBreakSeconds = currentData?.attendance_shift
        ?.attendance_rest_mins
        ? currentData?.attendance_shift?.attendance_rest_mins * 60
        : 0
      const attendance_type =
        !currentData?.attendance_type ||
        currentData?.attendance_type ===
          USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION ||
        currentData?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE

      if (attendance_type) {
        breakTime = t("--hrs--min")
        registered_time_attendance = t("--:-- ~ --:--")
        registered_time_break = t("--hrs--mins")
      } else {
        //calculate the Excess/deficiency time if present only
        const getSeconds = getTotalTimeInSec(
          dayjs(currentData?.date).format(FULL_DATE_FORMAT_EN),
        )
        const attendanceSeconds =
          currentData?.start_time && currentData?.end_time
            ? getSeconds(
                currentData?.start_time || "00:00",
                currentData?.end_time || "00:00",
              )
            : 0
        const attendanceBreakSeconds = getSumOfAllBreakTimes(
          currentData,
          dayjs(currentData?.date).format("YYYY-MM-DD"),
        )

        const shiftSeconds = getSeconds(
          currentData?.attendance_shift?.attendance_start_time || "00:00",
          currentData?.attendance_shift?.attendance_end_time || "00:00",
        )

        const totalShiftSeconds = shiftSeconds - shiftBreakSeconds
        const attendanceTotalSeconds =
          attendanceSeconds - attendanceBreakSeconds

        diffSeconds = attendanceTotalSeconds - totalShiftSeconds
        breakTime =
          !currentData?.start_time || !currentData?.end_time
            ? t("--hrs--min")
            : breakTimeInString(diffSeconds || 0, "LONG_HAND")
        registered_time_attendance = `${currentData?.start_time || ""} ~ ${
          currentData?.end_time || ""
        }`
        registered_time_break = breakTimeInString(
          attendanceBreakSeconds,
          "LONG_HAND",
        )
      }

      return {
        ...currentData,
        key: currentData?.id,
        day: dayjs(currentData?.date).date() + t("Day"),
        shift:
          currentData?.attendance_shift?.attendance_shift_name +
          `(${
            currentData?.attendance_shift?.attendance_start_time || "00:00"
          }-${currentData?.attendance_shift?.attendance_end_time || "00:00"})`,
        shift_break_time: breakTimeInString(shiftBreakSeconds, "LONG_HAND"),
        deficiency_time: breakTime,
        deficiency_time_type: attendance_type
          ? 1
          : diffSeconds === 0
            ? 0
            : diffSeconds > 0
              ? 1
              : -1,
        registered_time_attendance: registered_time_attendance,
        registered_time_break: registered_time_break,
      }
    })

    return source || []
  }, [response?.data || []])

  const columns: TableProps<any>["columns"] = [
    {
      key: "1",
      title: t("date"),
      dataIndex: "day",
      align: "center",
      width: 100,
    },
    {
      key: "2",
      title: t("Shift "),
      dataIndex: "shift",
      align: "center",
      render: (val, row) => {
        if (
          (row?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
            row?.attendance_type ==
              USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION) &&
          row?.is_paid_leave
        )
          return t("Paid leave")
        return (
          <Flex justify={"center"} vertical>
            <Typography.Text>{val}</Typography.Text>
            <Typography.Text>
              {t("Break time ")}
              {" ("}
              {row?.shift_break_time}
              {")"}
            </Typography.Text>
          </Flex>
        )
      },
    },
    {
      key: "3",
      title: t("Excess/deficiency time"),
      dataIndex: "deficiency_time",
      align: "center",
      render: (val, row) => {
        return (
          <Typography.Text
            style={{
              color:
                row?.deficiency_time_type === 1
                  ? theme.colors.success
                  : row?.deficiency_time_type === 0
                    ? theme.colors.text
                    : theme.colors.error,
            }}
          >
            {val}
          </Typography.Text>
        )
      },
    },
    {
      key: "4",
      title: t("Registered time"),
      align: "center",
      render: (row) => {
        if (
          row?.attendance_type ===
            USER_ATTENDANCE_VALUES?.ABSENCE_NO_ADDITION ||
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE
        ) {
          if (!row?.is_paid_leave) return t("absent")
        }
        return (
          <Flex justify={"center"} wrap={"wrap"} gap={8}>
            <Typography.Text>
              {!row?.attendance_type ? t("Unregistered") : t("Attendance")}
              {": "}
              {row?.registered_time_attendance || ""}
            </Typography.Text>
            <Typography.Text>
              {t("Break time ")}
              {": "}
              {row?.registered_time_break || ""}
            </Typography.Text>
          </Flex>
        )
      },
    },
  ]
  return (
    <Card
      title={t("{{user_name}}’s {{enDate}} attendance details", {
        user_name: userName,
        enDate: dayjs(currentDate).format("YYYY/MM"),
        jaDate: dayjs(currentDate).format(YM_FORMAT),
      })}
    >
      <Flex vertical gap={16}>
        <Button
          btnText={t("Edit List")}
          shape={"round"}
          width={"fit-content"}
          onClick={() => {
            router.push(
              `/user-attendance-management/monthly-detail/edit/${id}?date=${date}&facility_id=${facility_id}`,
            )
          }}
          disabled={!hasWriteAccess}
        />
        <Table
          columns={columns || []}
          dataSource={getDataSource || []}
          scroll={{ x: "max-content" }}
          loading={isLoading || isFetching}
        />
        <Button
          btnText={t("Edit List")}
          shape={"round"}
          width={"fit-content"}
          onClick={() => {
            router.push(
              `/user-attendance-management/monthly-detail/edit/${id}?date=${date}&facility_id=${facility_id}`,
            )
          }}
          disabled={!hasWriteAccess}
        />
      </Flex>
    </Card>
  )
}
