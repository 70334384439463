// packages
import { Flex } from "antd"
import styled from "styled-components"

// commons
import { theme } from "@project/common"

export const AttendanceBulkEditOperationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .facility-inputs .facility-action-button {
    margin-bottom: 16px;
  }
`

export const AttendanceBulkEditTableWrap = styled.div`
  margin-top: 30px;
  table > tbody > tr > td.ant-table-cell {
    &:nth-child(3) {
      padding: 0 !important;
    }
    .delete-btn {
      color: ${theme.colors.error};
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;
      &:hover {
        color: ${theme.colors.error};
        opacity: 0.8;
      }
    }
  }

  .card-footer {
    margin-top: 16px;
  }
`

export const WorkHourTdCellWrap = styled(Flex)`
  width: 100%;
  height: 137px;
  flex-direction: column;
  .sub-cell {
    height: 50%;

    .working-hours-label {
      padding: 10px 16px;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid ${theme.colors.border};
    }

    .hour-inputs {
      height: 100%;
      flex: 1;

      .working-hour-action-btns {
        padding: 10px 16px;
        height: 100%;
        flex: 1;
        justify-content: center;
      }

      .work_time__hours {
        height: 100%;
        border-right: 1px solid ${theme.colors.border};
        padding: 10px 16px;
      }
    }

    .break-time-input {
      padding: 10px 16px;
      .break-time-label {
        margin-right: 10px;
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
      }
      .input-field-wrap {
        margin-right: 8px;
      }
    }

    &:nth-child(2) {
      border-top: 1px solid ${theme.colors.border};
    }

    .absenteeism-indicator {
      padding: 0;
      background-color: transparent !important;
      margin: 10px auto;

      span {
        color: ${theme.colors.error};
        font-weight: 500 !important;
      }

      &:hover > span {
        text-decoration: underline;
      }
    }
  }
`
