import { Button, Tabs, useUpdateSearchParams } from "@project/common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { WagesRevenueOverallTab } from "./WagesRevenueOverallTab/WagesRevenueOverallTab"
import WagesRevenueOperationOptions from "./WagesRevenueCommon/WagesRevenueOperationOptions"
import { Flex } from "antd"
import { WagesRevenueFacilityWiseTab } from "./WagesRevenueFacilityWiseTab/WagesRevenueFacilityWiseTab"
import { BusinessActivityStatementTab } from "./BusinessActivityStatementTab/BusinessActivityStatementTab"
import { EmploymentSupportBusinessSalesDivisionTabContent } from "./EmploymentSupportBusinessSalesDivisionTab/EmploymentSupportBusinessSalesDivisionTabContent"
import { EmploymentSupportBusinessStatementTab } from "./EmploymentSupportBusinessStatementTab/EmploymentSupportBusinessStatementTab"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import { useAuthContext, useWagesRevenueContext } from "../../context"
import { ProjectCostStatementTab } from "./ProjectCostStatementTab"
import {
  hasPermissionForFacility,
  WageManagementPermission,
} from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

export const WagesRevenueContainers = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility_id, date, panel } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const { downloadXlsx } = useWagesRevenueContext()
  const { facilities, isOwner, permissions } = useAuthContext()
  const [searchParams, setSearchParams] = useState({
    facility_id: facility_id || facilities?.[0]?.value || null,
    date: dayjs(date) || null,
    tab: panel || "overall_revenue",
  })

  const hasEditAccess = (facilityId) => {
    return (
      isOwner ||
      (facilityId &&
        hasPermissionForFacility(
          permissions,
          WageManagementPermission,
          "write",
          facilityId,
        )) ||
      (!facilityId &&
        hasPermissionForMenu(permissions, WageManagementPermission, "write"))
    )
  }

  return (
    <Flex vertical gap={16}>
      <WagesRevenueOperationOptions
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <Tabs
        activeKey={panel || "overall_revenue"}
        onChange={(tab) => {
          setSearchParams({ ...searchParams, tab: tab, date: dayjs() })
          updateParams({ panel: tab }, "/wages-revenue")
        }}
        destroyInactiveTabPane
        items={[
          {
            key: "overall_revenue",
            label: t("Overall revenue"),
            children: (
              <WagesRevenueOverallTab
                params={searchParams}
                setSearchParams={setSearchParams}
              />
            ),
          },
          {
            key: "facility_wise",
            label: t("Facility wise revenue"),
            children: (
              <WagesRevenueFacilityWiseTab
                params={searchParams}
                setSearchParams={setSearchParams}
                hasEditAccess={hasEditAccess}
              />
            ),
          },
          {
            key: "business",
            label: t("（１）Business Activity Statement"),
            children: (
              <BusinessActivityStatementTab
                params={searchParams}
                setSearchParams={setSearchParams}
                hasEditAccess={hasEditAccess}
              />
            ),
          },
          {
            key: "project",
            label: t("（２）Project Manufacturing Cost Statement"),
            children: (
              <ProjectCostStatementTab
                params={searchParams}
                setSearchParams={setSearchParams}
                hasEditAccess={hasEditAccess}
              />
            ),
          },
          {
            key: "employment",
            label: t("（３）Employment Support Business Sales Division"),
            children: (
              <EmploymentSupportBusinessSalesDivisionTabContent
                params={searchParams}
                setSearchParams={setSearchParams}
                hasEditAccess={hasEditAccess}
              />
            ),
          },
          {
            key: "support",
            label: t("（４）Employment support business statement"),
            children: (
              <EmploymentSupportBusinessStatementTab
                params={searchParams}
                setSearchParams={setSearchParams}
                hasEditAccess={hasEditAccess}
              />
            ),
          },
        ]}
        tabBarExtraContent={
          <Button
            btnText={t("Download")}
            shape={"round"}
            onClick={downloadXlsx}
          />
        }
        padding={"20px 16px"}
      />
    </Flex>
  )
}
