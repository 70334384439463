import { Flex, Skeleton, Typography, type TableProps } from "antd"
import React, { useEffect, useState } from "react"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { useTranslation } from "react-i18next"
import { Button, Table, useUpdateSearchParams } from "@project/common"
import styled from "styled-components"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { ACTIVITY_STATEMENT_ROWS } from "./constant"
import { useQuery } from "react-query"
import { getAllFacilityBusinessActivityStatement } from "../../../services"
import { getColsTotal, getRowsTotal, handleRowSpan } from "./helpers"
import { WRBusinessActivityStatement } from "../../../types"
import dayjs from "dayjs"
import { useWagesRevenueContext } from "../../../context"
import { useRouter } from "next/router"

const Wrapper = styled(Flex)`
  .row_cell_bg_gray {
    background-color: #ededed;
    td:first-child {
      background-color: white !important;
    }
  }
`
export const BusinessActivityStatementTabListContent = ({
  setSelectedCol,
  params,
  setSearchParams,
  hasEditAccess,
}: {
  setSelectedCol: (item: WRBusinessActivityStatement) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { panel } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const { downloadAbleDataOf, setDownloadAbleDataOf } = useWagesRevenueContext()
  const [facilityColumns, setFacilityColumns] = useState([])
  const { isLoading, data } = useQuery({
    queryKey: ["business_activity_statement", params],
    queryFn: () =>
      getAllFacilityBusinessActivityStatement({
        year: dayjs(params?.date).year() || dayjs().year(),
        month: dayjs(params?.date).month() + 1 || dayjs().month() + 1,
      }),
    onSuccess: (res: any) => {
      setFacilityColumns(res?.msg || [])
    },
  })

  const getDynamicFacilityColumns = (mode: "download" | "view") => {
    return facilityColumns?.map((item) => {
      return {
        key: item?.id,
        title:
          mode === "download"
            ? item?.facility_name
            : () => {
                return (
                  <Flex vertical gap={8} align={"center"}>
                    <Typography.Text>
                      {item?.facility_name || ""}
                    </Typography.Text>
                    <Button
                      btnText={t("edit")}
                      iconType={"blue-pencil"}
                      shape={"round"}
                      onClick={() => setSelectedCol(item)}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      disabled={!hasEditAccess(item?.facility_id)}
                    />
                  </Flex>
                )
              },
        width: 100,
        dataIndex: item?.id,
        render: (_, row) => {
          const { total_1, total_2 } = getRowsTotal(item)
          if (row?.key === "total_1") {
            return mode === "download" ? (
              total_1
            ) : (
              <Flex justify={"space-between"} align={"center"}>
                <Typography.Text>{total_1 || 0}</Typography.Text>
              </Flex>
            )
          }
          if (row?.key === "total_2") {
            return mode === "download" ? (
              total_2
            ) : (
              <Flex justify={"space-between"} align={"center"}>
                <Typography.Text>{total_2 || 0}</Typography.Text>
              </Flex>
            )
          }
          return mode === "download" ? (
            item[row?.key] || 0
          ) : (
            <Flex justify={"space-between"} align={"center"}>
              <Typography.Text>{item[row?.key] || 0}</Typography.Text>
            </Flex>
          )
        },
      }
    })
  }
  const getMainColumns = (mode: "download" | "view") => {
    const columns: TableProps<any>["columns"] = [
      {
        key: 1,
        title: mode === "download" ? "" : t("Item list"),
        colSpan: 2,
        align: "center",
        dataIndex: "type",
        render: (_, row, index) =>
          mode === "download" ? (
            row?.type || row?.item_list
          ) : index === 11 ? (
            <AccountSubjectContent label={row?.item_list || ""} />
          ) : (
            <span style={{ whiteSpace: "nowrap" }}>{t(row?.type)}</span> || ""
          ),
        width: 70,
        onCell: handleRowSpan,
      },
      {
        key: 2,
        title: mode === "view" ? "" : t("Item list"),
        colSpan: 0,
        align: "center",
        render: (_, row) =>
          mode === "download" ? (
            t(row?.item_list)
          ) : (
            <AccountSubjectContent label={t(row?.item_list) || ""} />
          ),
        width: 300,
        onCell: (_, index) => {
          if (index === 11) {
            return {
              colSpan: 0,
            }
          }
          return {}
        },
      },
      {
        key: "total",
        title:
          mode === "download" ? (
            t("Total")
          ) : (
            <span style={{ whiteSpace: "nowrap" }}>{t("Total")}</span>
          ),
        align: "center",
        render: (row) => {
          const colsTotal = getColsTotal(facilityColumns || [])
          let total_first = 0,
            total_second = 0
          facilityColumns?.map((v) => {
            const { total_1, total_2 } = getRowsTotal(v)
            total_first = total_first + total_1
            total_second = total_second + total_2
          })
          if (row?.key === "total_1") {
            return total_first
          }
          if (row?.key === "total_2") {
            return total_second
          }
          return colsTotal[row?.key] || "0"
        },
        width: 120,
      },
      ...getDynamicFacilityColumns(mode),
    ]
    return columns || []
  }

  useEffect(() => {
    setDownloadAbleDataOf({
      ...downloadAbleDataOf,
      tab: t("（１）Business Activity Statement"),
      [panel]: {
        columns: getMainColumns("download"),
        dataSource: ACTIVITY_STATEMENT_ROWS,
      },
    })
  }, [panel, data])
  return (
    <Wrapper vertical gap={16}>
      <TabTopContent
        tab={t("Business Activity Statement")}
        onChange={(value) => {
          setSearchParams({
            ...params,
            date: dayjs(value).format("YYYY-MM"),
          })
          updateParams(
            {
              panel: "business",
              date: dayjs(value).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      {isLoading ? (
        <Flex wrap={"wrap"} gap={8}>
          {[...Array(120)].map((_, index) => {
            return <Skeleton.Button active key={index} />
          })}
        </Flex>
      ) : (
        <Table
          columns={getMainColumns("view")}
          dataSource={ACTIVITY_STATEMENT_ROWS || []}
          pagination={false}
          scroll={{ x: 800 }}
          rowClassName={(_, index) => {
            if (index === 2) {
              return "row_cell_bg_gray"
            }
          }}
        />
      )}
    </Wrapper>
  )
}
