import { FACILITY_SERVICE_TYPES_V2 } from "@project/common"
import { useTranslation } from "react-i18next"
import { ActualTableContent } from "./DisabledUserOutpatientBenefitsPrintContent.style"

export const ServiceCodeTypeTable = ({
  data,
  serviceType,
}: {
  data: any
  serviceType?: number
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ActualTableContent>
      <tbody id={"duobp__table-body"}>
        <tr>
          <td className={"align-center"} rowSpan={15}>
            <span id={"rotate-190"}>
              {" "}
              {t("Benefit expense details column")}
            </span>
          </td>
          <td className={"align-center"} colSpan={2}>
            {t("Service type code")}
          </td>
          <td className={"align-center"}>{data?.c_09 || ""}</td>
          <td className={"align-center"}>
            {t(FACILITY_SERVICE_TYPES_V2[serviceType || 0]) || ""}
          </td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"}>{""}</td>
          <td className={"align-center"} rowSpan={2}>
            {t("Total")}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Service usage days")}
          </td>
          <td className={"align-left"} colSpan={2}>
            {data?.c_10 || ""}
          </td>
          <td className={"align-center"} colSpan={2}>
            {t("Day")}
          </td>
          <td className={"align-center"} colSpan={2}>
            {t("Day")}
          </td>
          <td className={"align-center"} colSpan={2}>
            {t("Day")}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Number of benefit units")}
          </td>

          <td className={"align-right"} colSpan={2}>
            {data?.c_11 ? (+data?.c_11).toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>
            {" "}
            {data?.c_11 ? (+data?.c_11).toLocaleString() : ""}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Unit price")}
          </td>

          <td className={"align-right"} colSpan={2}>
            {t("{{value}} yen/unit", {
              value: data?.c_12 || "",
            })}
          </td>
          <td className={"align-center"} colSpan={2}>
            {t("{{value}} yen/unit", {
              value: "",
            })}
          </td>
          <td className={"align-center"} colSpan={2}>
            {" "}
            {t("{{value}} yen/unit", {
              value: "",
            })}
          </td>
          <td className={"align-center"} colSpan={2}>
            {" "}
            {t("{{value}} yen/unit", {
              value: "",
            })}
          </td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Total cost")}
          </td>

          <td className={"align-right"} colSpan={2}>
            {data?.c_13 ? (+data?.c_13)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>
            {data?.c_13 ? (+data?.c_13)?.toLocaleString() : ""}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Amount equivalent to 10%")}
          </td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_14 ? (+data?.c_14)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("User burden ②")}
          </td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_15 ? (+data?.c_15)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Upper limit monthly adjustment (lower number of ①②)")}
          </td>

          <td className={"align-right"} colSpan={2}>
            {data?.c_16 ? (+data?.c_16)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>
            {data?.c_16 ? (+data?.c_16)?.toLocaleString() : ""}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} rowSpan={2}>
            {t("Type A exemption")}
          </td>
          <td className={"align-center"}>{t("Business exemption amount")}</td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_17 ? (+data?.c_17)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"}>
            {t("User burden after reduction/exemption")}
          </td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_18 ? (+data?.c_18)?.toLocaleString() : ""}
          </td>

          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Adjusted user burden")}
          </td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_19 ? (+data?.c_19)?.toLocaleString() : ""}
          </td>

          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t(
              "Amount to be paid by the user after managing the upper limit amount",
            )}
          </td>

          <td className={"align-right"} colSpan={2}>
            {data?.c_20 ? (+data?.c_20)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Determined user burden amount")}
          </td>

          <td className={"align-right"} colSpan={2}>
            {data?.c_21 ? (+data?.c_21)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>
            {data?.c_21 ? (+data?.c_21)?.toLocaleString() : ""}
          </td>
        </tr>
        <tr>
          <td className={"align-center"}>{t("Billing amount")}</td>
          <td className={"align-center"}>{t("Benefit cost")}</td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_22 ? (+data?.c_21)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>
            {data?.c_22 ? (+data?.c_21)?.toLocaleString() : ""}
          </td>
        </tr>
        <tr>
          <td className={"align-center"} colSpan={2}>
            {t("Local government subsidy amount requested")}
          </td>
          <td className={"align-right"} colSpan={2}>
            {data?.c_23 ? (+data?.c_23)?.toLocaleString() : ""}
          </td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"} colSpan={2}></td>
          <td className={"align-center"}>{""}</td>
        </tr>
      </tbody>
    </ActualTableContent>
  )
}
