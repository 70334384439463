import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  useUpdateSearchParams,
} from "@project/common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../../../context"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import dayjs, { Dayjs } from "dayjs"
import { FULL_DATE_FORMAT_EN } from "../../../../constants"

export const OperationOptionForm = ({
  params,
  setParams,
  children,
  url,
}: {
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  children?: React.ReactNode
  url: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const [values, setValues] = useState({
    facility_ids: params?.facility_ids || [],
    user_service_id: params?.user_service_id || [],
    date: params?.date || null,
  })

  useEffect(() => {
    setValues(params)
  }, [params])
  const handleDisplayChange = () => {
    const params = {
      ...values,
      facility_ids: values?.facility_ids?.join(","),
      user_service_id: values?.user_service_id?.join(","),
      date: dayjs(values?.date)?.format(FULL_DATE_FORMAT_EN),
    }
    setParams(values)
    updateParams(params, url)
  }

  return (
    <AccordionCard
      defaultActiveKey={["attendance-delete-form"]}
      items={[
        {
          key: "attendance-delete-form",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow
                  label={t("Display facility")}
                  width={"150px"}
                />
                <FacilityCheckbox
                  options={facilities || []}
                  value={values?.facility_ids}
                  name={"facility_ids"}
                  onChange={(list: CheckboxValueType[]) =>
                    setValues((prev) => ({ ...prev, facility_ids: list }))
                  }
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setValues({
                        ...values,
                        facility_ids: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        facility_ids: [],
                      })
                    }
                  }}
                />
              </div>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow
                  label={t("Use service")}
                  width={"136px"}
                />
                <CheckBoxGroup
                  items={FACILITY_TYPES || []}
                  value={values?.user_service_id}
                  onChange={(list: CheckboxValueType[]) =>
                    setValues((prev) => ({
                      ...prev,
                      user_service_id: list,
                    }))
                  }
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Date")} width={"136px"} />
                <DatePicker
                  picker={"date"}
                  name={"date"}
                  date={values?.date || null}
                  onDateChange={(date: Dayjs) =>
                    setValues((prev) => ({ ...prev, date: date }))
                  }
                />
              </div>
              <div>
                <Button
                  btnText={t("Display Change")}
                  shape={"round"}
                  onClick={handleDisplayChange}
                />
              </div>
              {children}
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
