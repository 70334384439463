import { Button, theme } from "@project/common"
import { Flex } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

export const ApplicationManagementListActionBtns = ({
  showBtn,
  onClick,
  hasMonthlySchedule,
  hasWriteAccess,
}: {
  showBtn?: "B" | "D"
  onClick: (action: "DET" | "EFA" | "BP" | "CP" | "BAC") => void
  hasMonthlySchedule?: boolean
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Flex
      justify={"space-between"}
      style={{
        width: "100%",
      }}
      gap={8}
      wrap={"wrap"}
    >
      {showBtn === "B" ? (
        <Button
          btnText={t("<<Back")}
          shape={"round"}
          onClick={() => onClick("BAC")}
        />
      ) : (
        <div />
      )}

      <Flex gap={16} wrap={"wrap"}>
        <Button
          btnText={
            hasMonthlySchedule
              ? t("Edit facility application")
              : t("Add facility application")
          }
          shape={"round"}
          onClick={() => onClick("EFA")}
          borderColor={theme.colors.action}
          disabled={!hasWriteAccess}
        />
        <Button
          btnText={t("Bulk Print")}
          shape={"round"}
          onClick={() => onClick("BP")}
          iconType={"business-print"}
          borderColor={theme.colors.border}
        />
        <Button
          btnText={t("Calendar Print")}
          shape={"round"}
          onClick={() => onClick("CP")}
          iconType={"business-print"}
          borderColor={theme.colors.border}
        />
      </Flex>
    </Flex>
  )
}
