import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  useUpdateSearchParams,
} from "@project/common"
import { Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NHIF_USER_BILLING_OP_OPTIONS } from "../../../../constants"
import { useAuthContext } from "../../../../context"

export const UserBillingListContentOperationOptions = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const router = useRouter()
  const { display_settings, facilityIds, year, month } = router.query as any
  const [updateParams] = useUpdateSearchParams()
  const firstFacilityId = facilityIds || facilities?.[0].value
  const [values, setValues] = useState({
    display_settings: display_settings?.split(",") || [],
    facility_ids: facilityIds?.split(",") || [firstFacilityId],
  })
  return (
    <AccordionCard
      defaultActiveKey={["user_billing_list"]}
      items={[
        {
          key: "user_billing_list",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow
                  label={t("Display Settings")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={NHIF_USER_BILLING_OP_OPTIONS || []}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      display_settings: val,
                    })
                  }
                  value={values?.display_settings}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Displaying Facilities")}
                  width={"138px"}
                />
                <FacilityCheckbox
                  value={values?.facility_ids || null}
                  options={facilities || []}
                  onChange={(val) =>
                    setValues({ ...values, facility_ids: val })
                  }
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      setValues({
                        ...values,
                        facility_ids: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        facility_ids: [],
                      })
                    }
                  }}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Reset Search")}
                  shape={"round"}
                  onClick={() => {
                    setValues({
                      display_settings: [],
                      facility_ids: [firstFacilityId],
                    })
                    updateParams(
                      {
                        year: year || dayjs().year(),
                        month: month || dayjs().month() + 1,
                        display_settings: "",
                        facilityIds: firstFacilityId,
                      },
                      "/national-health-insurance-billing/prints/user-billing-list",
                    )
                  }}
                />
                <Button
                  btnText={t("Search")}
                  iconType={"search"}
                  type={"primary"}
                  shape={"round"}
                  onClick={() => {
                    setValues({
                      ...values,
                      facility_ids:
                        values?.facility_ids?.length <= 0
                          ? [firstFacilityId]
                          : values?.facility_ids,
                    })
                    updateParams(
                      {
                        year: year || dayjs().year(),
                        month: month || dayjs().month() + 1,
                        display_settings: values?.display_settings?.join(","),
                        facilityIds: values?.facility_ids?.length
                          ? values?.facility_ids?.join(",")
                          : firstFacilityId,
                      },
                      "/national-health-insurance-billing/prints/user-billing-list",
                    )
                  }}
                />
                <Typography.Text>
                  {t(
                    "* To change the year and month, press 'Cancel' and go to the previous screen.",
                  )}
                </Typography.Text>
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
