import {
  Button,
  Card,
  LabelTextWithLeftBorder,
  removeBlankAttributes,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { YM_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../context"
import { getNhifPrintAddressToUsers } from "../../../../services"
import { PrintProxyAcknowledgementListTable } from "./PrintProxyAcknowledgementListTable"
import { PrintProxyAcknowledgementOp } from "./PrintProxyAcknowledgementOp"

export const PrintProxyAcknowledgement = (): JSX.Element => {
  const { t } = useTranslation()
  const [dataSource, setDataSource] = useState({})
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const { query, push } = useRouter()
  const { facilityIds, year, month } = query as any
  const currentData = dayjs(`${year}-${month}`).isValid()
    ? dayjs(`${year}-${month}`)
    : dayjs()
  const { isLoading, isFetching } = useQuery({
    queryKey: ["address-to-users", facilityIds, year, month],
    queryFn: () =>
      getNhifPrintAddressToUsers({
        facilityIds: facilityIds || facilities?.[0]?.id,
        year: year || dayjs().year(),
        month: month || dayjs().month() + 1,
      }),
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setDataSource(res?.data?.city_wise || [])
    },
  })

  const handleCheckAndUnCheckAll = (action: "ALL" | "NONE") => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => ({
        ...data,
        isChecked: action === "ALL" ? true : false,
      }))
    })
    setDataSource(newData)
  }
  const handleSingleCheckAndUncheck = ({
    userId,
    checked,
    tableRows,
    selectedKey,
  }: {
    userId: number
    checked: boolean
    tableRows?: "ALL" | "NONE"
    selectedKey?: string
  }) => {
    const newData = {}
    Object?.keys(dataSource)?.map((key) => {
      newData[key] = dataSource[key]?.map((data) => {
        if (tableRows && selectedKey === key) {
          return {
            ...data,
            isChecked: tableRows === "ALL" ? true : false,
          }
        }
        if (data?.user_id === userId) {
          return {
            ...data,
            isChecked: checked,
          }
        }
        return data
      })
    })
    setDataSource(newData)
  }
  const gotoPrintPage = () => {
    const checkedIds = []
    Object?.keys(dataSource)?.map((key) => {
      return dataSource[key]?.map((data) => {
        if (data?.isChecked) {
          checkedIds.push(data?.user_id)
        }
      })
    })
    push({
      pathname: `/national-health-insurance-billing/prints/print-proxy-acknowledgement/print`,
      query: removeBlankAttributes({
        selected: checkedIds?.join(","),
        facilityIds,
        year: year || dayjs().year(),
        month: month || dayjs().month() + 1,
      }),
    })
  }
  const goBack = () => {
    updateParams(
      {
        year: year || dayjs().year(),
        month: month || dayjs().month() + 1,
        facility: facilityIds || facilities?.[0]?.id,
      },
      "/national-health-insurance-billing",
    )
  }

  /**
   * Checks for selected items in the data source.
   *
   * @returns {boolean} Whether there are selected items in the data source.
   */
  const checkForSelectedItems = (): boolean => {
    // Create an array to store the selected IDs.
    const selectedIds = []
    // Iterate over each key in the data source.
    Object?.keys(dataSource)?.map((key) => {
      // Iterate over each item in the current key.
      return dataSource[key]?.map((data) => {
        // If the current item is checked, add its ID to the selected IDs array.
        if (data?.isChecked) {
          selectedIds.push(data?.user_id)
        }
      })
    })
    // Return whether the selected IDs array has any elements.
    return selectedIds?.length ? true : false
  }
  const isPrintBtnDisabled = checkForSelectedItems()
  return (
    <Flex vertical gap={16}>
      <PrintProxyAcknowledgementOp />
      <Button
        onClick={goBack}
        btnText={t("<<Back")}
        shape={"round"}
        width={"fit-content"}
      />
      <Card
        title={t("{{date}} List of facility use users", {
          date: dayjs(currentData).format(YM_FORMAT),
        })}
      >
        <Flex gap={9}>
          <Button
            onClick={() => handleCheckAndUnCheckAll("ALL")}
            btnText={t("Check All")}
            shape={"round"}
            iconType={"check-all"}
            disabled={Object.keys(dataSource).length ? false : true}
          />
          <Button
            onClick={() => handleCheckAndUnCheckAll("NONE")}
            btnText={t("Uncheck All")}
            shape={"round"}
            disabled={Object.keys(dataSource).length ? false : true}
          />
          <Button
            onClick={gotoPrintPage}
            btnText={t("Print")}
            shape={"round"}
            type={"primary"}
            iconType={"print"}
            disabled={!isPrintBtnDisabled}
          />
        </Flex>
        <Flex
          vertical
          gap={16}
          style={{
            margin: "16px 0",
          }}
        >
          {isLoading || isFetching ? (
            <>
              <Skeleton.Input active />
              <Skeleton active />
            </>
          ) : (
            <>
              {dataSource &&
                Object?.keys(dataSource)?.map((key) => {
                  const data = dataSource[key] || []
                  const currentData = data?.[0]
                  const label = `${currentData?.provision_city}(${currentData?.city_number})`
                  return (
                    <Flex vertical key={key} gap={16}>
                      <LabelTextWithLeftBorder label={label} />
                      <PrintProxyAcknowledgementListTable
                        dataSource={data}
                        loading={isLoading}
                        handleSingleCheckAndUncheck={(values) =>
                          handleSingleCheckAndUncheck({
                            ...values,
                            selectedKey: key,
                          })
                        }
                        isAllChecked={data?.every((each) => each?.isChecked)}
                      />
                    </Flex>
                  )
                })}
            </>
          )}
          <Button
            onClick={gotoPrintPage}
            btnText={t("Print")}
            shape={"round"}
            type={"primary"}
            iconType={"print"}
            width={"fit-content"}
            disabled={!isPrintBtnDisabled}
          />
        </Flex>
      </Card>

      <Button
        onClick={goBack}
        btnText={t("<<Back")}
        shape={"round"}
        width={"fit-content"}
      />
    </Flex>
  )
}
