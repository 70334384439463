import React, { useMemo, useRef, useState } from "react"
import { UserAttendanceManagementOperationOptions } from "./UserAttendanceManagementOperationOptions"
import { Card, Tabs, scrollToSelectedElement } from "@project/common"
import { Flex } from "antd"
import { useTranslation } from "react-i18next"
import { MonthlyApprovalsTab } from "./MonthlyApprovalsTab/MonthlyApprovalsTab"
import { RequestListTab } from "./RequestListTab/RequestListTab"
import { LeaveRequestListTab } from "./LeaveRequestListTab/LeaveRequestListTab"
import { useRouter } from "next/router"
import { useAuthContext } from "../../context"
import {
  hasPermissionForFacility,
  UserAttendanceManagementPermission,
} from "../../utils/PermissionKeys"

export const UserAttendanceManagementContent = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities, isOwner, permissions } = useAuthContext()
  const { panel, facility_id } = router.query as any
  const [activePanel, setActivePanel] = useState(panel || "monthly-approvals")
  const contentRef = useRef<HTMLDivElement>(null)

  const LeaveRequestListTabMemoized = React.useCallback(
    () => <LeaveRequestListTab hasWriteAccess={hasWriteAccess} />,
    [router.query],
  )

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        UserAttendanceManagementPermission,
        "write",
        facility_id || facilities?.[0]?.value,
      ),
    [isOwner, permissions, facility_id],
  )

  return (
    <Flex vertical gap={16}>
      <UserAttendanceManagementOperationOptions
        scrollTo={() => scrollToSelectedElement(contentRef)}
        panel={activePanel}
      />
      <div ref={contentRef}>
        <Card
          title={t("{{facility_name}}’s attendance management", {
            facility_name: facility_id
              ? facilities?.find((item) => item?.id == facility_id)
                  ?.facility_name || ""
              : facilities[0]?.facility_name || "",
          })}
        >
          <Tabs
            padding={"16px"}
            activeKey={activePanel || "monthly-approvals"}
            onChange={(tab) => {
              setActivePanel(tab)
              router.push(
                {
                  pathname: router.pathname,
                  query: { ...router?.query, panel: tab },
                },
                undefined,
                { shallow: true },
              )
            }}
            items={[
              {
                key: "monthly-approvals",
                label: t("Monthly Approvals"),
                children: <MonthlyApprovalsTab />,
              },
              {
                key: "request-list",
                label: t("Request List"),
                children: <RequestListTab hasWriteAccess={hasWriteAccess} />,
              },
              {
                key: "leave-request-list",
                label: t("Leave Request List"),
                children: <LeaveRequestListTabMemoized />,
              },
            ]}
          />
        </Card>
      </div>
    </Flex>
  )
}
