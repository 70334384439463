import { moneyFormat } from "@project/common"
import { Flex, Typography } from "antd"
import { useTranslation } from "react-i18next"
import {
  StyledTopInfo,
  StyledUserInfo,
  StyledWorkedDaysInfo,
} from "./PaylistCommon.style"
export const TopInfo = ({
  company,
  date,
}: {
  date: any
  company: string
  amount?: number | null
}) => {
  const { t } = useTranslation()
  return (
    <StyledTopInfo wrap={"wrap"}>
      <Flex align={"center"} gap={16} wrap={"wrap"}>
        <Flex>
          <Typography.Title level={3}>{t("給与明細")}</Typography.Title>
        </Flex>
        <Flex vertical gap={8}>
          <Flex>{date}</Flex>
          <Flex>{company}</Flex>
        </Flex>
      </Flex>
      <Flex align={"flex-end"} wrap={"wrap"}>
        <Typography.Text>
          {t("Unit")}
          {":"}
        </Typography.Text>
        <Typography.Text>{t("Yen")}</Typography.Text>
      </Flex>
    </StyledTopInfo>
  )
}
export const UserInfo = ({
  user_name,
  amount,
  type,
  remarks,
  id,
}: {
  user_name: string
  amount?: number | null
  type?: string
  remarks?: string
  id?: number
}) => {
  const { t } = useTranslation()
  return (
    <StyledUserInfo wrap={"wrap"}>
      <Flex justify={"center"}>
        {id ? t("Deductible payment amount") : t("Remark")}
      </Flex>
      <Flex justify={"space-between"} align={"center"} wrap={"wrap"}>
        {type ? (
          <Typography.Text
            style={{
              whiteSpace: "pre",
            }}
          >
            {remarks || "-"}
          </Typography.Text>
        ) : (
          <>
            <Typography.Paragraph
              style={{
                margin: 0,
                fontWeight: 500,
                fontSize: "32px",
              }}
            >
              {user_name}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                margin: 0,
                fontWeight: 500,
                fontSize: "32px",
              }}
            >
              {amount ? moneyFormat(amount) : "0.00"}
            </Typography.Paragraph>
          </>
        )}
      </Flex>
    </StyledUserInfo>
  )
}
export const WorkedDaysInfo = ({
  workDays,
  absentDays,
  paidLeaves,
  from_date,
  to_date,
}: {
  workDays?: any
  absentDays?: any
  paidLeaves?: any
  from_date?: string | null
  to_date?: string | null
  hrMins?: string | null
}) => {
  const { t } = useTranslation()
  return (
    <StyledWorkedDaysInfo>
      <Flex justify={"center"} className={"attendance__info"} wrap={"wrap"}>
        {t("Attendance({{from_date}} 〜 {{to_date}} Proportion of work)", {
          from_date: from_date,
          to_date: to_date,
        })}
      </Flex>
      <Flex justify={"space-between"} gap={48} wrap={"wrap"}>
        <Flex justify={"space-between"} flex={1} gap={8} wrap={"wrap"}>
          <Typography.Text>{t("Number of days worked")}</Typography.Text>
          {workDays || 0}
        </Flex>
        <Flex justify={"space-between"} flex={1} gap={8} wrap={"wrap"}>
          <Typography.Text>{t("Absent days")}</Typography.Text>
          {absentDays || 0}
        </Flex>
      </Flex>
      {!!paidLeaves && (
        <Flex justify={"space-between"} gap={48} wrap={"wrap"}>
          <Flex justify={"space-between"} flex={1} gap={8} wrap={"wrap"}>
            <Typography.Text>{t("Number of paid leave")}</Typography.Text>
            {paidLeaves || 0}
          </Flex>
          <Flex justify={"space-between"} flex={1} gap={8} wrap={"wrap"}>
            {" "}
          </Flex>
        </Flex>
      )}
    </StyledWorkedDaysInfo>
  )
}
