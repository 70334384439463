import { theme } from "@project/common"
import styled from "styled-components"

export const StaffAttendanceMonthContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .working_style_list {
    display: none;
  }

  @media print {
    @page {
      margin: 5mm !important;
    }
    .ant-card-head {
      padding: 0px !important;
      .ant-card-head-wrapper {
        border-bottom: 0 !important;
      }
    }
  }
`

export const StaffAttendanceScheduleSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.default};
  .flex-start {
    align-items: flex-start !important;
    .mt {
      padding-top: 0.5em;
    }
  }
`

export const LoaderContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const WorkStyleActionButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 20px 0;
`
