import { Box, theme } from "@project/common"
import styled from "styled-components"

export const VehicleAndDriverInfoTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  break-before: avoid;
  break-after: avoid;
  margin-top: 20px;
  tbody > tr > td {
    border: 1px solid ${theme.colors.text};
    padding: 12px 16px;
    font-size: 9pt;
    font-weight: 500;
  }
`

export const ScreenTableStyle = styled.div`
  .ant-table-container {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
    border-color: ${theme.colors.text} !important;
    .ant-table-content table {
      thead.custom-thead {
        background-color: ${theme.colors.gray7};
        th.custom-cell {
          border-start-end-radius: 0 !important;
          border-start-start-radius: 0 !important;
          padding: 10px;
          border-bottom: 1px solid ${theme.colors.text};
          border-color: ${theme.colors.text} !important;
          font-weight: 500;
          font-size: 12pt;
        }
      }
      thead.ant-table-thead > tr > th.ant-table-cell {
        border-start-start-radius: 0 !important;
        border-start-end-radius: 0 !important;
        background: ${theme.colors.dimGray};
        border-color: ${theme.colors.text} !important;
        font-weight: 500 !important;
        font-size: 9pt !important;
      }
      tbody.ant-table-tbody > tr > td.ant-table-cell {
        background: ${theme.colors.white};
        border-color: ${theme.colors.text} !important;
        font-weight: 400 !important;
        font-size: 9pt !important;
      }
    }
  }

  @media print {
    display: none;
  }
`

export const CheckItem = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid ${theme.colors.text};
`

export const OnlyPrintContainer = styled.div`
  display: none;
  @media print {
    display: block;
  }
`

export const ApprovalTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  thead > tr > th,
  tbody > tr > td {
    padding: 9px 16px;
    font-weight: 500;
    border: 1px solid ${theme.colors.text};
    font-size: 9pt;
    &.with-bg {
      background-color: ${theme.colors.gray7};
    }
  }
`

export const EntryTable = styled.table`
  width: 50%;
  border-collapse: collapse;
  border-spacing: 0;
  thead > tr > th,
  tbody > tr > td {
    padding: 10px 16px;
    font-size: 9pt;
    font-weight: 500;
    border: 1px solid ${theme.colors.text};
    &.first-cell,
    &.checkbox-cell {
      text-align: center;
    }
    &.last-cell {
      text-align: right;
    }
    &.fuel-cell,
    &.has-bg {
      background-color: ${theme.colors.gray7};
    }
  }

  tbody {
    tr {
      page-break-inside: avoid !important;
    }
  }
`

export const ToPrintSectionStyle = styled(Box)`
  .pft-measure-row {
    display: none;
  }
  @media print {
    @page {
      margin: 24px;
    }
  }
`

export const SpinContainer = styled.div`
  margin: 10px 0;
  display: flex;
  width: 100%;
  justify-content: center;
`
