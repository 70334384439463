import { theme } from "@project/common"
import styled from "styled-components"

export const TitleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`
export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 28px;
  row-gap: 20px;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .label {
    padding: 12px 16px;
    background-color: rgb(243, 243, 243);
    width: 45%;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;

    @media screen and (max-width: 280px) {
      font-size: 10px;
      font-weight: 500;
    }
  }
  .value {
    padding: 12px 16px;
    width: 55%;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
    @media screen and (max-width: 280px) {
      font-size: 10px;
      font-weight: 400;
    }
  }

  #user-information {
    flex: 1;
    @media screen and (min-width: 541px) {
      width: calc(65% - 10px);
    }
    @media screen and (max-width: 540px) {
      width: calc(100% - 10px);
      order: 2;
    }
  }
  #image-information {
    @media screen and (min-width: 541px) {
      max-width: 400px;
      width: calc(35% - 10px);
      height: 100%;
    }
    @media screen and (max-width: 540px) {
      width: calc(100% - 10px);
      order: 1;
    }
  }
  .second-content,
  .third-content,
  .fourth-content,
  .fifth-content,
  .sixth-content,
  .seventh-content,
  .eighth-content,
  .ninth-content {
    margin-top: 18px;
    .ant-row {
      .ant-col {
        padding: 12px 16px !important;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .title-button {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
      .btn {
        @media screen and (max-width: 540px) {
          margin-bottom: 10px;
        }
        span {
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }
    }
    .link,
    a {
      color: ${theme.colors.link};
    }
  }
  .first-content {
    .link,
    a {
      color: ${theme.colors.link};
    }
  }
  .button {
    margin-top: 10px;
  }
`

export const DisabilityCardSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1800px) {
    display: block;
  }
`
