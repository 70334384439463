import { Button, setItemToLocalstorage, theme } from "@project/common"
import React from "react"
import styled from "styled-components"

const ButtonWrapper = styled.div`
  display: flex;
  row-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  margin: 30px 0px 20px 0px;
  .active {
    border: 1px solid ${theme.colors["yellow-3"]};
    background-color: ${theme.colors["yellow-3"]};
    &:hover {
      border: 1px solid ${theme.colors["yellow-3"]} !important;
      background-color: ${theme.colors["yellow-3"]} !important;
      color: ${theme.colors.text} !important;
    }
  }
`
interface IProps {
  setFacilityID: (value: number) => void
  facilities: any
  facilityID: number | string
  setSelectedFacility?: any
}
const TopPageButtons: React.FC<IProps> = ({
  setFacilityID,
  facilities,
  facilityID,
  setSelectedFacility,
}) => {
  return (
    <ButtonWrapper>
      {facilities?.map((d, i) => {
        return (
          <div key={i}>
            <Button
              btnText={d?.facility_name}
              shape={"round"}
              borderColor={"#D8D8D8"}
              btnBg={"#fff"}
              className={facilityID == d?.id ? "active" : ""}
              onClick={() => {
                setFacilityID(d?.id)
                setItemToLocalstorage("top_facility", {
                  facility_ids: d?.id,
                })
                setSelectedFacility(d)
              }}
            />
          </div>
        )
      })}
    </ButtonWrapper>
  )
}

export { TopPageButtons }
