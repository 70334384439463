import { Flex } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ActualTableContent } from "./DisabledUserOutpatientBenefitsPrintContent.style"
const Wrapper = styled(Flex)`
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .footer_count_content {
    flex: 2;
  }
  .page_count_content {
    flex: 0.5;
  }
`
export const DUOPFotterTable = ({
  totalPage,
  currentPage,
}: {
  totalPage: number
  currentPage: number
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper gap={16} justify={"space-between"} align={"flex-end"}>
      <div className={"footer_count_content"}>
        <ActualTableContent>
          <tbody id={"duobp__table-body"}>
            <tr>
              <td rowSpan={2} className={"align-center w-min-100"}>
                {t("Special benefits for people with specific disabilities")}
              </td>
              <td className={"align-center w-min-100 "}>
                {t("Calculated daily amount")}
              </td>
              <td className={"align-center w-min-100 "}>
                {t("Number of days")}
              </td>
              <td className={"align-center w-min-100 "}>
                {t("Benefits billed amount")}
              </td>
              <td className={"align-center w-min-100 "}>
                {t("Actual cost calculation amount")}
              </td>
            </tr>
            <tr>
              <td className={"p-10"}>{""}</td>
              <td className={"p-10"}>{""}</td>
              <td className={"p-10"}>{}</td>
              <td className={"p-10"}>{}</td>
            </tr>
          </tbody>
        </ActualTableContent>
      </div>

      <div className={"page_count_content"}>
        <ActualTableContent>
          <tbody id={"duobp__table-body"}>
            <tr>
              <td rowSpan={2} className={"align-center w-50"}>
                {t("{{count}} out of {{total}}", {
                  count: currentPage,
                  total: totalPage,
                })}
              </td>
              <td className={"align-center w-50"}>
                {t("{{page}} page", { page: currentPage })}
              </td>
            </tr>
          </tbody>
        </ActualTableContent>
      </div>
    </Wrapper>
  )
}
