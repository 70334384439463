import {
  Table,
  calculateHMTimeDiff,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Typography } from "antd"
import { TableProps } from "antd/es/table"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { PAGE_SIZE, SLASH_DATE_FORMAT } from "../../../constants"
import { getUserAttendanceManagementRequestList } from "../../../services"
import {
  UserAttendanceManagementRequestListDataType,
  UserAttendanceManagementRequestListResponseTypes,
} from "../../../types"

import { useRouter } from "next/router"
import { useAuthContext } from "../../../context"
import { ActionConfirmPopupModal } from "../common/ActionConfirmPopupModal"

export const RequestListTab = ({
  hasWriteAccess,
}: {
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { query } = useRouter()
  const { panel, date, user_id, facility_id, page } = query as any
  const { facilities } = useAuthContext()
  const facility = facility_id || facilities[0]?.value
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [updateParams] = useUpdateSearchParams()
  const handlePagination = (pageNo: number) => {
    updateParams({ ...query, page: pageNo }, "/user-attendance-management")
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0
  //fetch data
  const {
    data: response,
    isLoading,
    isFetching,
    refetch: refetchRequestList,
  } = useQuery<UserAttendanceManagementRequestListResponseTypes, Error>({
    queryKey: [
      "user-attendance-management-request-list",
      panel,
      date,
      user_id,
      facility,
      page,
    ],
    queryFn: () =>
      getUserAttendanceManagementRequestList({
        user_id,
        date,
        facility_id: facility,
        page: page || 1,
        pageSize: PAGE_SIZE,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    select: (res) => {
      return {
        ...res,
        data: res?.data?.map((v) => ({
          key: `${v?.id}`,
          ...v,
          status: v?.status || 0,
        })),
      }
    },
  })

  const columns: TableProps<UserAttendanceManagementRequestListDataType>["columns"] =
    [
      {
        key: "username",
        title: t("Username "),
        align: "center",
        width: 100,
        render: (row) => row?.user?.username || "",
      },
      {
        key: "application_date",
        dataIndex: "created_datetime",
        title: t("Application date"),
        width: 100,
        align: "center",
        render: (v) => (
          <span style={{ whiteSpace: "nowrap" }}>
            {v ? dayjs(v).format(`${SLASH_DATE_FORMAT} HH:mm:ss`) : ""}
          </span>
        ),
      },
      {
        key: "shift_attending_day",
        dataIndex: "shift",
        title: t("Shift・Attending day"),
        width: 200,
        align: "center",
        render: (v, row) => {
          const attendance: any = row?.attendance?.[0] || {}
          return (
            <Flex vertical>
              <Typography.Text>
                {t(attendance?.attendance_shift?.attendance_shift_name || "")}
              </Typography.Text>
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {attendance?.attendance_shift?.attendance_start_time || ""}
                {"~"}
                {attendance?.attendance_shift?.attendance_end_time || ""}
              </Typography.Text>
              <Typography.Text>
                {row?.date ? dayjs(row?.date).format(SLASH_DATE_FORMAT) : ""}
              </Typography.Text>
            </Flex>
          )
        },
      },
      {
        key: "time_of_attendance",
        dataIndex: "time_of_attendance",
        title: t("Time of attendance"),
        align: "center",
        width: 150,
        render: (_, row) => {
          const attendance: any =
            row?.status === 1 &&
            row?.user_attendance_approval_update_history?.length
              ? row?.user_attendance_approval_update_history?.[0]
              : row?.attendance?.[0] || {}
          let timeDifference = t("--hrs--min")
          if (attendance?.start_time && attendance?.end_time) {
            const { hours, minutes } = calculateHMTimeDiff(
              attendance?.start_time || "0:0",
              attendance?.end_time || "0:0",
            )
            timeDifference = t("({{hours}} hrs {{minutes}} mins)", {
              hours: hours || "0",
              minutes: minutes || "0",
            })
          }
          return (
            <Flex vertical>
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {attendance?.start_time}
                {"~"}
                {attendance?.end_time}
              </Typography.Text>
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {timeDifference}
              </Typography.Text>
            </Flex>
          )
        },
      },
      {
        key: "desired_change_time",
        dataIndex: "desired_change_time",
        title: t("Desired change time"),
        align: "center",
        width: 120,
        render: (_, row) => {
          let timeDifference = t("--hrs--min")
          if (row?.new_check_in_time && row?.new_check_out_time) {
            const { hours, minutes } = calculateHMTimeDiff(
              row?.new_check_in_time || "0:0",
              row?.new_check_out_time || "0:0",
            )
            timeDifference = t("({{hours}} hrs {{minutes}} mins)", {
              hours: hours || "0",
              minutes: minutes || "0",
            })
          }

          return (
            <Flex vertical>
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                  color: theme.colors.action,
                }}
              >
                {row?.new_check_in_time}
                {"~"}
                {row?.new_check_out_time}
              </Typography.Text>
              <Typography.Text
                style={{
                  whiteSpace: "nowrap",
                  color: theme.colors.action,
                }}
              >
                {timeDifference}
              </Typography.Text>
            </Flex>
          )
        },
      },
      {
        key: "reason_for_change_request",
        title: (
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {t("Reason for change request")}
          </span>
        ),
        align: "center",

        render: (row) => row?.reason || "-",
      },
      {
        key: "action",
        title: t("Action"),
        align: "center",
        width: 120,
        render: (row) => {
          const isDisabled =
            !hasWriteAccess || selectedRowKeys?.length >= response?.data?.length
          return (
            <ActionConfirmPopupModal
              id={[row?.key]}
              requestedDate={row?.date || dayjs()}
              variant={"SINGLE_ROW_ACTION"}
              disabled={isDisabled}
              refetch={refetchRequestList}
              request_status={row?.status || 0}
              rejected_reason={row?.rejected_reason}
            />
          )
        },
      },
    ]
  useEffect(() => {
    setSelectedRowKeys([])
  }, [query])
  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={response?.data || []}
      loading={isLoading || isFetching}
      showPaginationOf={"both"}
      total={response?.count || 0}
      current={+page || 1}
      pageSize={PAGE_SIZE}
      scroll={{
        x: 800,
      }}
      onChange={(page) => handlePagination(page)}
      extraHeader={
        hasSelected ? (
          <Flex
            justify={"flex-end"}
            style={{
              paddingBottom: "16px",
            }}
          >
            <ActionConfirmPopupModal
              requestedDate={date ? dayjs(date) : dayjs()}
              variant={"BULK_ACTION"}
              id={selectedRowKeys}
              refetch={refetchRequestList}
              disabled={!hasWriteAccess}
            />
          </Flex>
        ) : null
      }
    />
  )
}
