import { API } from "@project/common"
import { PAGE_SIZE } from "../constants"
export interface IReturnDailyBusinessReportList {
  count?: number
  data?: any
}

export interface IDailyBusinessReportListParam {
  page?: string | number
  facilityIds?: string
  user_id?: number
  pageSize?: number
  staff_id?: string
}

const BASE_ENDPOINT = "user-paid-leave"

export const fetchPaidLeaveList = async ({
  queryKey,
}): Promise<IReturnDailyBusinessReportList> => {
  const params = {} as IDailyBusinessReportListParam
  params.pageSize = PAGE_SIZE
  params.user_id = queryKey[1]
  params.page = queryKey[2]
  params.facilityIds = queryKey[3]?.facility
  params.user_id = queryKey[3]?.user_id
  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}
export const createPaidLeave = async (values): Promise<any> => {
  return API.post(`/${BASE_ENDPOINT}`, values)
}
export const updatedPaidLeave = async (values): Promise<any> => {
  const id = values?.id
  delete values?.id
  return API.put(`/${BASE_ENDPOINT}/${id}`, values)
}
export const deletePaidLeave = async (id): Promise<any> => {
  return API.delete(`/${BASE_ENDPOINT}/${id}`)
}
