import { t } from "i18next"
import * as yup from "yup"

export const ShiftMasterInfoSchema = yup.object().shape({
  shift_hour_name: yup
    .string()
    .required(t("Please enter"))
    .max(50, t("Please enter number within 50 characters.")),
  shift_display_name: yup
    .string()
    .max(2, t("(Within 2 char)"))
    .required(t("Please enter")),
  number_of_employees_include_flg: yup.string(),
  using_facility: yup.array().min(1, t("Required")),
  work_time1: yup.object().when("number_of_employees_include_flg", {
    is: (val) => {
      return val === "false"
    },
    then: (schema) =>
      schema
        .shape({
          start_time: yup.object().shape({
            hr: yup.string().required(t("Required")),
            min: yup.string().required(t("Required")),
          }),
          end_time: yup.object().shape({
            hr: yup.string().required(t("Required")),
            min: yup.string().required(t("Required")),
          }),
        })
        .test(
          "time",
          t("Working hour end time can't be earlier than start time"),
          (value) => {
            const { start_time, end_time } = value
            const startInMinute =
              (start_time?.hr ? +start_time?.hr : 0) * 60 +
              (start_time?.min ? +start_time?.min : 0)
            const endInMinute =
              (end_time?.hr ? +end_time?.hr : 0) * 60 +
              (end_time?.min ? +end_time?.min : 0)
            return endInMinute >= startInMinute
          },
        ),
    otherwise: (schema) =>
      schema.shape({
        start_time: yup.object().shape({
          hr: yup.string().nullable().notRequired(),
          min: yup.string().nullable().notRequired(),
        }),
        end_time: yup.object().shape({
          hr: yup.string().nullable().notRequired(),
          min: yup.string().nullable().notRequired(),
        }),
      }),
  }),
  work_time2: yup
    .object()
    .shape({
      start_time: yup.object().shape({
        hr: yup.string().nullable(),
        min: yup.string().nullable(),
      }),
      end_time: yup.object().shape({
        hr: yup.string().nullable(),
        min: yup.string().nullable(),
      }),
    })
    .test(
      "time",
      t("Working hour end time can't be earlier than start time"),
      (value) => {
        const { start_time, end_time } = value
        const startInMinute =
          (start_time?.hr ? +start_time?.hr : 0) * 60 +
          (start_time?.min ? +start_time?.min : 0)
        const endInMinute =
          (end_time?.hr ? +end_time?.hr : 0) * 60 +
          (end_time?.min ? +end_time?.min : 0)
        return endInMinute >= startInMinute
      },
    ),
  work_time1_break: yup
    .string()
    .matches(/^\d+$/, t("mins (max 3 numbers)"))
    .when(["number_of_employees_include_flg", "break_settings"], {
      is: (flg, setting) => flg === "false" && setting === "true",
      then: (schema) => schema.required(t("Required")),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  work_time2_break: yup.string().matches(/^\d+$/, t("mins (max 3 numbers)")),
  remarks: yup.string().max(20),
  break_settings_hours: yup.string().when("break_settings", {
    is: (breakSettings) => breakSettings,
    then: (schema) => schema.required(t("Required")),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
})

export interface ShiftMasterInitialValues {
  shift_hour_name: string | null
  shift_display_name: string | null
  using_facility: any[]
  target_of_user: any[]
  work_time1: {
    start_time: {
      hr: string | null
      min: string | null
    }
    end_time: {
      hr: string | null
      min: string | null
    }
  }
  work_time2: {
    start_time: {
      hr: string | null
      min: string | null
    }
    end_time: {
      hr: string | null
      min: string | null
    }
  }
  work_time1_break: string | null
  work_time2_break: string | null
  break: string | null
  break_settings: boolean | null
  break_settings_hours: string | null
  remarks: string | null
  number_of_employees_include_flg: boolean | null
  target_of_use_staff: boolean | null
  target_of_use_user: boolean | null
}
