import { CloseOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  DatePicker,
  FACILITY_TYPES,
  Grid,
  ImageUpload,
  InputField,
  Modal,
  calculateDateDifference,
  scrollToFirstErrorField,
} from "@project/common"
import { Flex, Spin, Typography, notification } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import {
  createUserWorkInformation,
  getAllUserWorkInformation,
} from "../../../services/employmentCertificate.services"
import {
  EmploymentCertificateBasicInformationSchema,
  UserWorkInformationCreateEditRequestType,
} from "../../../types"

const ModalWrapper = styled.div`
  .certificate-image {
    object-fit: scale-down;
    width: 300px;
    height: 300px;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .preview-button {
    position: absolute;
    right: 0;
    @media screen and (max-width: 540px) {
      bottom: 0;
      left: 0;
      margin-left: -15px !important;
    }
    button {
      border: none;
      background: none !important;
      color: #0782c8;
    }
  }
  .image-upload {
    @media screen and (max-width: 540px) {
      margin-bottom: 20px !important;
    }
  }
`
export const EmploymentCertificateBasicInformation = ({
  user_id,
  service_type,
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isFileSubmitting, setIsFileSubmitting] = useState(false)

  const [openModal, setOpenModal] = useState<{ open: boolean; image: string }>({
    open: false,
    image: "",
  })
  const { data: response, isLoading: userLoading } = useQuery({
    queryKey: ["user-work-edit-information"],
    queryFn: () =>
      getAllUserWorkInformation({
        pageSize: "Infinity",
        user_id: +user_id,
      }),
    select: (data) => {
      return {
        ...data?.data,
        employment_date: dayjs(data?.data?.employment_date).format(
          "YYYY-MM-DD",
        ),
      }
    },
  })

  //create status
  const { mutate, isLoading } = useMutation({
    mutationFn: createUserWorkInformation,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })
      setIsSubmitted(false)
      router.push(`/users/work/${+user_id}/detail`)
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const initialValues: UserWorkInformationCreateEditRequestType = {
    user_id: +user_id,
    employment_date: dayjs(response?.employment_date).isValid()
      ? dayjs(response?.employment_date).format("YYYY-MM-DD")
      : null,
    used_joint_facility: response?.used_joint_facility || null,
    service_type: +service_type,
    certificate_employment: response?.certificate_employment || null,
  }

  const { values, handleSubmit, setFieldValue, errors, ...formik } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: EmploymentCertificateBasicInformationSchema,
    onSubmit: (formValues) => {
      mutate(formValues)
    },
  })

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(errors)
  }, [formik.submitCount, formik.isValid])

  return (
    <Spin spinning={userLoading}>
      <Card title={t("Basic information")}>
        <Flex component={"form"} vertical gap={16}>
          <div>
            <Grid
              background
              labelContent={t("Upload certificate of employment")}
              padding={"15px"}
              labelSpan={6}
              required
            >
              <Flex
                justify={"space-between"}
                wrap={"wrap"}
                gap={8}
                id={"certificate_employment"}
              >
                <ImageWrapper>
                  <div className={"image-upload"}>
                    <ImageUpload
                      onChange={(data) =>
                        setFieldValue("certificate_employment", data)
                      }
                      isLoading={setIsFileSubmitting}
                      shape={"round"}
                      otherType={"draft"}
                      iconType={"upload"}
                      btnText={t("upload")}
                      btnDefault={false}
                      uploadHint={t("(PDFのみ)")}
                      currentImage={values?.certificate_employment}
                      isImg={false}
                      errors={
                        !!formik?.touched?.employment_date &&
                        !!errors?.certificate_employment &&
                        isSubmitted &&
                        errors?.certificate_employment
                      }
                      acceptType={".pdf"}
                    />
                  </div>
                  {values?.certificate_employment && (
                    <div className={"preview-button"}>
                      <Button
                        btnText={t("Preview  Certificate")}
                        onClick={() =>
                          window.open(values?.certificate_employment, "_blank")
                        }
                      />
                    </div>
                  )}
                </ImageWrapper>
              </Flex>
            </Grid>
            <Grid
              background
              labelContent={t("Use Service")}
              padding={"15px"}
              labelSpan={6}
            >
              <InputField
                disabled
                value={t(
                  FACILITY_TYPES?.find((v) => v?.value == values?.service_type)
                    ?.label || null,
                )}
                width={"319px"}
                height={"40px"}
              />
            </Grid>
            <Grid
              background
              labelContent={t("Used joint facilities")}
              padding={"15px"}
              labelSpan={6}
              required
            >
              <InputField
                name={"used_joint_facility"}
                value={values?.used_joint_facility || null}
                onChange={({ target: { value } }) =>
                  setFieldValue("used_joint_facility", value)
                }
                placeholder={"--"}
                width={"319px"}
                height={"40px"}
                maxLength={100}
                error={
                  !!formik?.touched?.used_joint_facility &&
                  !!errors?.used_joint_facility
                    ? errors?.used_joint_facility
                    : null
                }
              />
            </Grid>
            <Grid
              background
              labelContent={t("Employment date")}
              padding={"15px"}
              labelSpan={6}
              required
            >
              <DatePicker
                name={"employment_date"}
                id={"employment_date"}
                placeholder={"--"}
                onBlur={formik?.handleBlur}
                date={
                  dayjs(values?.employment_date).isValid()
                    ? dayjs(values?.employment_date)
                    : null
                }
                onDateChange={(date: Dayjs) =>
                  setFieldValue(
                    "employment_date",
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                  )
                }
                error={
                  formik?.touched?.employment_date && errors?.employment_date
                    ? errors?.employment_date
                    : null
                }
              />
            </Grid>
          </div>
          <div>
            <Grid
              background
              labelContent={t("*Days from Employment")}
              padding={"15px"}
              labelSpan={6}
            >
              <Typography.Text>
                {calculateDateDifference(values?.employment_date)}
                {t("Day")}
              </Typography.Text>
            </Grid>
          </div>
          <Flex gap={8} wrap={"wrap"}>
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              disabled={isLoading || isFileSubmitting}
              onClick={() => router.back()}
            />
            <Button
              btnText={t("Update")}
              shape={"round"}
              type={"primary"}
              isLoading={isLoading}
              disabled={isFileSubmitting}
              onClick={() => {
                handleSubmit()
                setIsSubmitted(true)
              }}
            />
          </Flex>
        </Flex>
      </Card>
      <ModalWrapper>
        {openModal ? (
          <Modal
            open={openModal?.open}
            onCancel={() =>
              setOpenModal((prev) => {
                return {
                  ...prev,
                  open: false,
                }
              })
            }
            footer={null}
            title={null}
            headerBg={"black"}
            width={225}
            maskClosable={true}
            bodyPadding={"12px"}
          >
            <div className={"employment_certificate_modal"}>
              <CloseOutlined
                onClick={() => {
                  setOpenModal((prev) => {
                    return {
                      ...prev,
                      open: false,
                    }
                  })
                }}
              />
              <img
                src={openModal?.image}
                alt={"image"}
                className={"certificate-image"}
              />
            </div>
          </Modal>
        ) : null}
      </ModalWrapper>
    </Spin>
  )
}
