import { WRBusinessActivityStatement } from "../../../types"

export const handleRowSpan = (_, index) => {
  if (index === 0) {
    return {
      rowSpan: 2,
    }
  }
  if (index === 1) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 2) {
    return {
      rowSpan: 9,
    }
  }
  if (index >= 3 && index <= 10) {
    return {
      rowSpan: 0,
    }
  }
  if (index === 11) {
    return {
      colSpan: 2,
    }
  }
  return {}
}

export const getRowsTotal = (item: WRBusinessActivityStatement) => {
  const total_1 =
    item?.business_sales_cost +
    item?.manufacturing_cost +
    item?.involved_product_inventory +
    item?.project_purchase_height
  const total_2 =
    item?.business_activity_earning - item?.business_activity_expense
  return { total_1, total_2 }
}

export const getColsTotal = (facilityColumns: WRBusinessActivityStatement[]) =>
  facilityColumns?.reduce(
    (pre, cur) => ({
      business_activity_earning:
        pre?.business_activity_earning + cur?.business_activity_earning,
      business_activity_expense:
        pre?.business_activity_expense + cur?.business_activity_expense,
      business_income: pre?.business_income + cur?.business_income,
      business_sales_cost: pre?.business_sales_cost + cur?.business_sales_cost,
      involved_product_inventory:
        pre?.involved_product_inventory + cur?.involved_product_inventory,
      manufacturing_cost: pre?.manufacturing_cost + cur?.manufacturing_cost,
      project_purchase_height:
        pre?.project_purchase_height + cur?.project_purchase_height,
      deduction: pre?.deduction + cur?.deduction,
      jurisdiction_expense:
        pre?.jurisdiction_expense + cur?.jurisdiction_expense,
      final_product_inventory:
        pre?.final_product_inventory + cur?.final_product_inventory,
    }),
    {
      business_activity_earning: 0,
      business_activity_expense: 0,
      business_income: 0,
      business_sales_cost: 0,
      involved_product_inventory: 0,
      manufacturing_cost: 0,
      project_purchase_height: 0,
      final_product_inventory: 0,
      deduction: 0,
      jurisdiction_expense: 0,
    },
  )
export const grandTotal = (facilityColumns: WRBusinessActivityStatement[]) => {
  let total_first = 0,
    total_second = 0
  facilityColumns?.map((v) => {
    const { total_1, total_2 } = getRowsTotal(v)
    total_first = total_first + total_1
    total_second = total_second + total_2
  })
  return { total_first, total_second }
}
