import { GenderObj } from "@project/common/src/utils/constants"
import dayjs from "dayjs"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"

const UserInformationTable = ({ response }) => {
  const { t } = useTranslation()
  const dob = response?.dob
  const today = dayjs()
  const age = today.diff(dob, "year")

  const wageTypes = {
    H: t("Hourly wage"),
    D: t("Daily wage"),
    M: t("Monthly wage"),
    P: t("Piece Work Rate"),
  }
  const wageType = response?.wage_style
  const displayText = wageType ? wageTypes[wageType] : "-"
  return (
    <>
      <table>
        <tr className={"first-table"}>
          <td className={"label"}>{t("User Name")}</td>
          <td className={"value"}>{response?.username || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Furigana ")}</td>
          <td className={"value"}>{response?.furigana || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Date of birth")}</td>
          <td className={"value"}>
            {response?.dob
              ? dayjs(response?.dob).format("YYYY年MM月DD日")
              : "-"}
          </td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Age")}</td>
          <td className={"value"}>{`${age}歳` || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Gender")}</td>
          <td className={"value"}>{t(GenderObj?.[response?.gender] || "-")}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Phone number")}</td>
          <td className={"value"}>{response?.phone || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Po box")}</td>
          <td className={"value"}>{response?.pobox || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Address")}</td>
          <td className={"value"}>{response?.address || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Wage style")}</td>
          <td className={"value"}>{displayText}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Hourly wage")}</td>
          <td className={"value"}>{`${response?.hourly_wage}円` || "-"}</td>
        </tr>
        <tr className={"first-table"}>
          <td className={"label"}>{t("Parents name")}</td>
          <td className={"value link"}>
            <Link href={"#"}>
              <a>{response?.parent_name || "-"}</a>
            </Link>
          </td>
        </tr>
      </table>
    </>
  )
}

export default UserInformationTable
