import { Flex, Typography, notification } from "antd"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import {
  Button,
  ErrorMassage,
  FACILITY_SERVICE_TYPES,
  Grid,
  InputField,
  Modal,
  SelectInput,
  allowOnlyNumber,
} from "@project/common"
import { FuriganaAlphabetsOption } from "../../../constants"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import { useFormik } from "formik"
import { filterWithHiragana } from "../../../utils"
import { useMutation } from "react-query"
import {
  addActualCostBurdenRecordUser,
  updateOneActualCostBurdenRecordUser,
} from "../../../services"

export const AddUserModal = ({
  open,
  setOpen,
  defaultData,
  refetch,
  burden_record_id,
  facilityId,
  price,
  useInvoiceFlg,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultData?: any
  refetch: any
  burden_record_id: any
  facilityId?: any
  price: any
  useInvoiceFlg: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
    facilityIds: facilityId,
  })

  const { mutate: mutateActualCostUser, isLoading } = useMutation(
    defaultData
      ? updateOneActualCostBurdenRecordUser
      : addActualCostBurdenRecordUser,
    {
      onSuccess: () => {
        notification.success({
          message:
            t("User") + defaultData?.id
              ? t("Updated Successfully")
              : t("Created Successfully"),
        })
        refetch()
        setOpen(false)
      },
      onError: (error?: any) => {
        const msg =
          error?.status == 403
            ? "User doesn't have permission to edit this page"
            : error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const { values, setFieldValue, handleSubmit, errors, touched, setValues } =
    useFormik({
      initialValues: {
        furigana: "",
        user_id: null,
        use_service_id: null,
        price: useInvoiceFlg ? price : null,
        burden_user_record_id: null,
      },
      validationSchema: Yup.object().shape({
        user_id: Yup.string().required(t("Required")),
        price: Yup.string().required(t("Required")),
      }),
      onSubmit: (formValues) => {
        mutateActualCostUser({
          cost: +formValues?.price,
          user_id: +formValues?.user_id,
          burden_record_id: +burden_record_id,
          burden_user_record_id: +formValues?.burden_user_record_id,
        })
      },
    })
  useEffect(() => {
    if (defaultData) {
      const newValues = {
        ...defaultData,
        furigana: "",
      }
      setValues(newValues)
    }
  }, [defaultData])
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={t("Register user")}
      footer={() => null}
      width={800}
      bodyPadding={"16px"}
    >
      <Flex vertical gap={16} component={"form"} onSubmit={handleSubmit}>
        <div>
          <Grid
            labelContent={t(" User Name")}
            background
            labelSpan={7}
            padding={"10px"}
            required
          >
            <div>
              <Flex gap={8} wrap={"wrap"}>
                <SelectInput
                  name={"furigana"}
                  options={FuriganaAlphabetsOption || []}
                  placeholder={"--"}
                  value={values?.furigana || null}
                  onChange={(value) => {
                    setFieldValue("user_id", null)
                    setFieldValue("furigana", value)
                  }}
                  disabled={defaultData?.user_id}
                  width={"78px"}
                  shortSelector
                />
                <SelectInput
                  name={"user_id"}
                  options={
                    filterWithHiragana({
                      options: userData,
                      furigana: values?.furigana || "",
                    }) || []
                  }
                  placeholder={"--"}
                  value={values?.user_id || null}
                  onChange={(value) => setFieldValue("user_id", value)}
                  disabled={defaultData?.user_id}
                  showMessage={false}
                  error={
                    !!touched?.user_id && !!errors?.user_id
                      ? `${errors?.user_id}`
                      : null
                  }
                />
              </Flex>
              <ErrorMassage
                message={
                  !!touched?.user_id && !!errors?.user_id
                    ? `${errors?.user_id}`
                    : null
                }
              />
            </div>
          </Grid>
          <Grid
            labelContent={t("Use Service")}
            background
            labelSpan={7}
            padding={"10px"}
          >
            {values?.user_id ? (
              t(
                FACILITY_SERVICE_TYPES[
                  userData?.find((user) => user?.id === +values?.user_id)
                    ?.use_service_id
                ],
              )
            ) : (
              <Typography.Text>{t("*Please select a user ")}</Typography.Text>
            )}
          </Grid>
          <Grid
            labelContent={t("Price")}
            background
            labelSpan={7}
            padding={"10px"}
            required
          >
            <InputField
              name={"price"}
              value={values?.price || null}
              onChange={({ target: { value } }) => {
                const v = allowOnlyNumber(value)
                setFieldValue("price", v)
              }}
              placeholder={"0"}
              height={"40px"}
              width={"241px"}
              subInfo={t("Yen")}
              subInfoMt={"0"}
              error={
                !!touched?.price && !!errors?.price ? `${errors?.price}` : null
              }
            />
          </Grid>
        </div>
        <Button
          btnText={t("Save")}
          shape={"round"}
          width={"fit-content"}
          type={"primary"}
          htmlType={"submit"}
          isLoading={isLoading}
        />
      </Flex>
    </Modal>
  )
}
