import React, { useMemo, useRef } from "react"
import UserAttendanceCalendarView from "./UserAttendanceCalendarView/UserAttendanceCalendarView"
import {
  BusinessCalendarPagination,
  Card,
  MonthPagination,
  useUpdateSearchParams,
} from "@project/common"
import { useTranslation } from "react-i18next"
import { UserAttendanceSummary } from "./UserAttendanceSummary"
import { Flex, Skeleton, Space } from "antd"
import { useReactToPrint } from "react-to-print"
import { Content } from "./UserAttendanceCalendarContent.style"
import { UserAttendanceCalendarParams } from "../../types"
import { useAuthContext } from "../../context"
import {
  hasPermissionForFacility,
  UserAttendanceManagementPermission,
} from "../../utils/PermissionKeys"

export const UserAttendanceCalendarContent = ({
  isLoading,
  params,
  setParams,
  responseData,
}: {
  isLoading: boolean
  params: UserAttendanceCalendarParams
  setParams: React.Dispatch<React.SetStateAction<UserAttendanceCalendarParams>>
  responseData: any
}): JSX.Element => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const { facilities, permissions, isOwner } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const title = t(
    "{{facilityName}} {{year}}Year{{month}}month’s User attendance table",
    {
      facilityName:
        facilities?.find((fac) => fac?.value === params?.facilityId)?.label ||
        "",
      year: params?.year,
      month: params?.month,
    },
  )
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `
     @page{
       margin:5mm !important
      }

    `,
  })

  const hasWriteAccess = useMemo(() => {
    return (
      isOwner ||
      hasPermissionForFacility(
        permissions,
        UserAttendanceManagementPermission,
        "write",
        params.facilityId,
      )
    )
  }, [permissions, isOwner, params])

  return (
    <Card
      title={isLoading ? <Skeleton.Button active /> : title}
      extra={
        isLoading ? (
          <Space>
            <Skeleton.Button active />
            <Skeleton.Button active />
            <Skeleton.Button active />
          </Space>
        ) : (
          <MonthPagination
            showDate={false}
            currentMonth={params?.month}
            currentYear={params?.year}
            change={"month"}
            onMonthChange={(year, month) => {
              setParams({
                ...params,
                year,
                month,
              })
              updateParams(
                {
                  ...params,
                  year,
                  month,
                },
                "/user-attendance-calendar",
              )
            }}
          />
        )
      }
    >
      {isLoading ? (
        <>
          <Space>
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
          </Space>
          <br />
          <br />
          <Space direction={"vertical"} style={{ display: "flex" }}>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Space>
        </>
      ) : (
        <Flex vertical gap={16}>
          <Content ref={contentRef}>
            <strong className={"txt__title"}>{title}</strong>
            <UserAttendanceSummary
              summaryInfo={responseData?.data?.attendance_stat || null}
            />
            <UserAttendanceCalendarView
              params={params}
              handlePrint={handlePrint}
              data={responseData?.data?.attendance_list || {}}
              allowToNavigateToDayList={hasWriteAccess}
            />
          </Content>

          <BusinessCalendarPagination
            currentYear={params?.year}
            currentMonth={params?.month}
            handleMonthChange={(_, month) => {
              setParams({
                ...params,
                month,
              })
              updateParams(
                {
                  ...params,

                  month,
                },
                "/user-attendance-calendar",
              )
            }}
            handleYearChange={(_, year) => {
              setParams({
                ...params,
                year,
                month: 1,
              })
              updateParams(
                {
                  ...params,
                  year,
                  month: 1,
                },
                "/user-attendance-calendar",
              )
            }}
          />
        </Flex>
      )}
    </Card>
  )
}
