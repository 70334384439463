import React from "react"

// commons
import { BoxProps } from "@project/common"

// styles
import { NumberedTitleStyled } from "../styles"

export const NumberedTitle = ({
  number,
  children,
  ...props
}: {
  number?: string
  children: React.ReactNode
} & BoxProps) => {
  return (
    <NumberedTitleStyled display={"flex"} align={"center"} gap={10} {...props}>
      {number && <div className={"number"}>{number}</div>}
      <div className={"title"}>{children}</div>
    </NumberedTitleStyled>
  )
}
