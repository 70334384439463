import React, { useContext, useState } from "react"

// packages
import dayjs from "dayjs"
import { t } from "i18next"
import { Flex } from "antd"
import { useFormik } from "formik"
import { useQuery } from "react-query"

// commons | packages
import {
  Box,
  Button,
  ActionBtns,
  DatePicker,
  SelectInput,
  filterObject,
  AccordionCard,
  CheckBoxGroup,
  FACILITY_TYPES,
  useUpdateSearchParams,
  addClearable,
} from "@project/common"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// context
import { AuthContext } from "../../context"

// services
import { getAllStaff } from "../../services"

// utils
import { transformDriverStaffs, getFuriFilteredOptions } from "../../utils"

// types
import {
  TMDriverListOperationProps,
  TransportMgmtDriverFilterType,
} from "../../types/TMDriverList.types"

// constants
import { FuriganaAlphabetsOption } from "../../constants"
import { LIST_DISPLAY } from "../UserTransportManagementContainer/constant"

const TransportMgmtDriverListOperation: React.FC<
  TMDriverListOperationProps
> = ({ filterInitValues, handleScrollToList }) => {
  const [furi, setFuri] = useState<string | null | 0>(0)
  const { facilities: facilitiesCtx } = useContext(AuthContext)

  const [updateParams] = useUpdateSearchParams()

  // ! ## formik instance
  const formik = useFormik<Partial<TransportMgmtDriverFilterType>>({
    initialValues: filterInitValues,
    onSubmit: (values) => {
      const params = filterObject({
        ...values,
        facility_ids: values?.facility_ids?.join(","),
        list_display: values?.list_display?.join(","),
        service_type: values?.service_type?.join(","),
      })

      updateParams(
        { ...params },
        `/user-transport-management/${values?.date}/drivers`,
      )
      handleScrollToList()
    },
  })
  const { setFieldValue, values, handleSubmit } = formik

  // ! ## All API Call
  // 3. Getting all the instructors/staffs/[in this case drivers] [QUERY]
  const { data: allDriverStaffs, isLoading: loadingDriverStaffs } = useQuery(
    ["all-driver-staffs"],
    {
      queryFn: () => getAllStaff(),
      refetchOnWindowFocus: false,
      select: (data) => transformDriverStaffs(data),
    },
  )

  // !## All Event/Action handlers
  // 1. handle facility check and uncheck
  const handleCheckAll = () => {
    setFieldValue(
      "facility_ids",
      facilitiesCtx.map((facility) => facility.id),
    )
  }
  const handleUncheckAll = () => {
    setFieldValue("facility_ids", [])
  }

  return (
    <AccordionCard
      defaultActiveKey={["transport-mgmt-driver-list-operation"]}
      items={[
        {
          key: "transport-mgmt-driver-list-operation",
          label: t("Operation Options"),
          children: (
            <Box display={"flex"} direction={"column"} gap={16}>
              <OperationFields
                wrap={"wrap"}
                gap={"10px 24px"}
                labelWidth={"150px"}
                align={"flex-start"}
                label={"Displaying facility"}
              >
                <div className={"TM_facility-inputs"}>
                  <Box
                    className={"TM_facility-btn-group flex"}
                    wrap={"wrap"}
                    gap={12}
                    display={"flex"}
                    mb={16}
                  >
                    <Button
                      shape={"round"}
                      type={"default"}
                      iconType={"check-all"}
                      btnText={t("Check all")}
                      onClick={handleCheckAll}
                    />
                    <Button
                      shape={"round"}
                      type={"default"}
                      btnText={t("Uncheck all")}
                      onClick={handleUncheckAll}
                      className={"TM_uncheck-all-btn"}
                    />
                  </Box>

                  <CheckBoxGroup
                    items={facilitiesCtx}
                    name={"facility_ids"}
                    onChange={(val) => {
                      setFieldValue("facility_ids", val)
                    }}
                    value={values.facility_ids?.map((item) => item.toString())}
                  />
                </div>
              </OperationFields>

              <OperationFields
                wrap={"wrap"}
                align={"center"}
                gap={"10px 24px"}
                label={"Use Service"}
                labelWidth={"150px"}
              >
                <div className={"TM_service-type-input"}>
                  <CheckBoxGroup
                    items={FACILITY_TYPES}
                    onChange={(val) => setFieldValue("service_type", val)}
                    value={values?.service_type}
                  />
                </div>
              </OperationFields>

              <OperationFields
                wrap={"wrap"}
                align={"center"}
                gap={"10px 24px"}
                label={"List display"}
                labelWidth={"150px"}
              >
                <Box
                  className={"TM_display-type-input"}
                  miw={{
                    xs: 300,
                    xxs: "100%",
                  }}
                >
                  <CheckBoxGroup
                    items={LIST_DISPLAY}
                    value={values?.list_display}
                    onChange={(val) => {
                      setFieldValue("list_display", val)
                    }}
                  />
                </Box>
              </OperationFields>

              <OperationFields
                wrap={"wrap"}
                align={"center"}
                gap={"10px 24px"}
                label={"Date"}
                labelWidth={"150px"}
              >
                <div className={"TM_Date-inputs"}>
                  <DatePicker
                    name={"date"}
                    picker={"date"}
                    format={"YYYY年MM月DD日"}
                    date={dayjs(values.date)}
                    onDateChange={(value) => {
                      setFieldValue("date", value.format("YYYY-MM-DD"))
                    }}
                  />
                </div>
              </OperationFields>

              <OperationFields
                wrap={"wrap"}
                align={"center"}
                gap={"10px 24px"}
                label={"Driver"}
                labelWidth={"150px"}
              >
                <Flex className={"TM_Date-inputs"} gap={"8px 16px"} flex={1}>
                  <Box maw={86}>
                    <SelectInput
                      name={"left"}
                      placeholder={"--"}
                      width={"86px"}
                      shortSelector
                      options={FuriganaAlphabetsOption}
                      onChange={(val) => {
                        setFieldValue("driver_staff_id", null)
                        setFuri(val)
                      }}
                    />
                  </Box>

                  <Box maw={282} miw={200} style={{ flex: 1 }}>
                    <SelectInput
                      name={"right"}
                      placeholder={"--"}
                      width={"282px"}
                      disabled={loadingDriverStaffs}
                      loading={loadingDriverStaffs}
                      value={values?.driver_staff_id}
                      allowClear
                      options={addClearable(
                        getFuriFilteredOptions(allDriverStaffs, furi),
                      )}
                      onChange={(val) => setFieldValue("driver_staff_id", val)}
                    />
                  </Box>
                </Flex>
              </OperationFields>

              <ActionBtns
                className={"operation-action-btns"}
                justify={"flex-start"}
              >
                <Button
                  shape={"round"}
                  type={"default"}
                  btnText={t("Display Change")}
                  onClick={() => handleSubmit()}
                />
              </ActionBtns>
            </Box>
          ),
        },
      ]}
    />
  )
}

export { TransportMgmtDriverListOperation }
