import styled from "styled-components"

export const ExtraButton = styled.div`
  span {
    margin-inline-end: 0px !important;
  }
`
export const ListOfMunicipalSubsidyContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  margin-top: 20px;
  .user_pick_up_and-drop_off_chart_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 1em;
  }
  .buttons {
    display: flex;
    gap: 10px;
  }
  .first-select-content {
    width: 100% !important;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .button_accordion {
    span {
      margin-inline-end: 0px;
    }
  }
`
export const SelectFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .first-select-content,
  .second-select-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
