import React from "react"

// packages
import { t } from "i18next"
import { Table as AntTable } from "antd"
import { ColumnsType } from "antd/es/table"

// components | common
import { Box, PrintFriendlyTable } from "@project/common"
import { ActualCostTotalSummaryFooter, BulletTitle } from "./CustomPrintTables"

// styles
import { BillingBreakDownTable } from "../styles"

// utils
import {
  ActualExpensesRec,
  ActualExpenseTableDataType,
} from "../../../../utils/getDailyListings"
import { generateDynamicExpenses } from "../../../../utils/generateDynamicExpenses"

export const ActualExpensesTable = ({
  dailyActualExpenses,
}: {
  dailyActualExpenses: ActualExpensesRec
}) => {
  const actualExpensesDetailsColumns: ColumnsType<ActualExpenseTableDataType> =
    [
      {
        title: t("Date"),
        dataIndex: "date",
        key: "date",
        align: "center",
      },
      {
        title: t("Day of the week"),
        dataIndex: "day_of_week",
        key: "day_of_week",
        align: "center",
      },
      ...generateDynamicExpenses(dailyActualExpenses),
      {
        title: t("Facility"),
        dataIndex: "facility",
        key: "facility",
        align: "center",
      },
    ]

  return (
    <Box mt={20}>
      <BulletTitle>{t("Actual expense details")}</BulletTitle>
      <BillingBreakDownTable>
        <PrintFriendlyTable
          className={"actual-expense-table"}
          visibleInScreenMode
          columns={actualExpensesDetailsColumns}
          dataSource={dailyActualExpenses?.dataSrc}
          scroll={{
            x: 600,
          }}
          summary={(pageData) => {
            return (
              <AntTable.Summary.Row className={"summary-expense-total-row"}>
                <AntTable.Summary.Cell index={0} colSpan={2} align={"center"}>
                  {t("Total")}
                </AntTable.Summary.Cell>
                <ActualCostTotalSummaryFooter
                  actualCostIds={dailyActualExpenses.allActualCostIds}
                  dataSource={pageData}
                />
                <AntTable.Summary.Cell
                  index={2}
                  align={"center"}
                ></AntTable.Summary.Cell>
              </AntTable.Summary.Row>
            )
          }}
        />
      </BillingBreakDownTable>
    </Box>
  )
}
