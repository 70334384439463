import {
  ActualCostData,
  InvoiceBreakDown,
  InvoiceToCustomerResponse,
  ParsedInvoiceToCustomerData,
} from "../../../types/nhif.types"

export const parseStringifiedVal = (
  resp: InvoiceToCustomerResponse,
): ParsedInvoiceToCustomerData[] => {
  return resp.data.map((item) => {
    let actual_cost_data: ActualCostData = []
    try {
      actual_cost_data = JSON.parse(item.actual_cost_data) as ActualCostData
    } catch (e) {
      actual_cost_data = []
    }

    let invoice_breakdown: InvoiceBreakDown[] = []
    try {
      invoice_breakdown = JSON.parse(
        item.invoice_breakdown,
      ) as InvoiceBreakDown[]
    } catch (e) {
      invoice_breakdown = []
    }

    return {
      ...item,
      actual_cost_data,
      invoice_breakdown,
      monthly_calculated_items: item?.monthly_calculated_items || [],
    }
  })
}

export const formatResponseData = (resp: InvoiceToCustomerResponse) => {
  return parseStringifiedVal(resp)
}
