import React from "react"

// packages
import { Flex } from "antd"

// styles
import { StatsWrap } from "./TreatmentImproveTable.styles"

// services
import { TreatmentImprovementTableResponse } from "../../services"

// constants
import { t } from "i18next"

export const IndividualStats = ({
  label,
  amount,
}: {
  label: string
  amount: number
}) => {
  return (
    <StatsWrap gap={"10px"}>
      <h4 className={"label"}>{label}</h4>
      <div>
        <h3 className={"amount"}>
          {amount.toLocaleString()}{" "}
          <span className={"currency-symbol"}>{"円"}</span>
        </h3>
      </div>
    </StatsWrap>
  )
}

const StatsSection = ({
  treatmentImproveData,
  isLoading,
}: {
  treatmentImproveData: TreatmentImprovementTableResponse
  isLoading: boolean
}) => {
  const amount = treatmentImproveData?.total_treatment_support_addition

  return (
    <Flex
      wrap={"wrap"}
      gap={"10px 18px"}
      style={{
        marginTop: "8px",
        marginBottom: "24px",
      }}
    >
      <StatsWrap gap={"10px"}>
        <h4 className={"label"}>
          {t(
            "Total amount of Welfare/nursing care staff treatment improvement addition",
          )}
        </h4>
        <div>
          {
            <h3 className={"amount"}>
              {isLoading ? "0" : amount?.toLocaleString()}
              <span className={"currency-symbol"}>{"円"}</span>
            </h3>
          }
        </div>
      </StatsWrap>
    </Flex>
  )
}

export default StatsSection
