import { getFuriFilteredOptions } from "../../../utils/getFuriFilteredOptions"
import { SelectFuriOpt } from "../types"

export const getUnusedStaffs = (
  currentOptions: SelectFuriOpt[],
  currentValue: number | string,
  dependencies: number[],
  furigana: string | 0,
) => {
  return getFuriFilteredOptions(
    currentOptions?.filter((item) =>
      item.id === currentValue ? true : !dependencies?.includes(item.id),
    ),
    furigana,
  )
}
