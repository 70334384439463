import {
  Card,
  FACILITY_TYPES,
  PageTitle,
  Table,
  Tag,
  removeBlankAttributes,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import type { ColumnsType } from "antd/es/table"
import Link from "next/link"
import { useRouter } from "next/router"
import { FULL_DATE_FORMAT, PAGE_SIZE } from "../../constants"
import { UserWhoNeedsMonitoringColumn } from "../../types"
import styled from "styled-components"
import { useQuery } from "react-query"
import { fetchUserWhoNeedsMonitoring } from "../../services/userWhoNeedsMonitoring"
import { TMiniContainer, TableContainer } from "./UserWhoNeedsMonitoring.style"
import dayjs from "dayjs"
import { UserWhoNeedsMonitoringOperation } from "./UserWhoNeedsMonitoringOperation"
const UserWhoNeedsMonitoringContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  table > tbody {
    .no-certificate-row {
      background-color: ${theme.colors.lightPink};
    }
  }
`
const UserWhoNeedsMonitoring = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const listContent = useRef<any>(null)
  const {
    page: currentPage,
    user_id,
    facility,
    service,
    user_without_services,
  } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const [page, setPage] = useState(
    currentPage ? parseInt(currentPage as string, 10) : 1,
  )

  const defaultSelectedFacility = facility ? facility?.toString() : []

  const [searchParams, setSearchParams] = useState<any>({
    facility: defaultSelectedFacility || [],
    service: service ? service?.split("") : [],
    user_without_services: user_without_services || false,
    user_id: user_id || null,
  })

  //fetch all user needs monitoring
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery<any>({
    queryKey: ["user-who-needs-monitoring", page, searchParams],
    queryFn: () =>
      fetchUserWhoNeedsMonitoring({
        page,
        pageSize: PAGE_SIZE,
        ...searchParams,
        facility: Array.isArray(searchParams?.facility)
          ? searchParams?.facility?.toString()
          : searchParams?.facility,
        service: Array.isArray(searchParams?.service)
          ? searchParams?.service?.toString()
          : searchParams?.service,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values }
    setPage(1)
    updateParams(queries, "/user-who-needs-monitoring")
    scrollToSelectedElement(listContent)
  }

  const onSearch = (data: any) => {
    const values = {
      facility: data?.facility?.toString(),
      service: data?.service?.toString(),
      user_without_services: data?.user_without_services,
      user_id: data?.user_id,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(data)
    handleSearchAndResetParams(params)
  }

  const onSearchReset = () => {
    setSearchParams({
      facility: [],
      service: [],
      user_id: null,
      user_not_registered: null,
    })
    handleSearchAndResetParams({}, "reset")
  }

  const columns: ColumnsType<UserWhoNeedsMonitoringColumn[]> = [
    {
      title: t("Details"),
      dataIndex: "details",
      key: "details",
      align: "center",
      width: 200,
      render: (_, data: UserWhoNeedsMonitoringColumn) => {
        return (
          <>
            {data?.user_support_plan_id ? (
              data?.user_monitoring_id ? (
                <Link
                  href={`/monitoring-list/edit/${data?.user_monitoring_id}?user_id=${data?.user_id}`}
                  passHref
                >
                  <a>{t("Edit draft")}</a>
                </Link>
              ) : (
                <Link
                  href={`/monitoring-list/add?user_id=${data?.user_id}`}
                  passHref
                >
                  <a>{t("Edit")}</a>
                </Link>
              )
            ) : (
              <Link
                href={`/individual-support-plan/add?user_id=${data?.user_id}`}
                passHref
              >
                <a>{t("Create Individual Support Plan")}</a>
              </Link>
            )}
          </>
        )
      },
    },
    {
      title: t("User name"),
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      render: (_, data: UserWhoNeedsMonitoringColumn) => {
        return <span>{t(data?.username)}</span>
      },
    },
    {
      title: t("Facility name"),
      align: "center",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: t("Service use"),
      align: "center",
      key: "",
      render: (row) => {
        const text = t(
          FACILITY_TYPES?.find((v) => v?.value === row?.use_service_id)?.label,
        )

        return text ? (
          <Tag
            color={
              row?.use_service_id === 1
                ? "gold"
                : row?.use_service_id === "2"
                  ? "blue"
                  : "default"
            }
            title={t(text)}
          />
        ) : (
          ""
        )
      },
    },
    {
      title: t("Alternative support"),
      align: "center",
      key: "alternative_support",
      render: (row) => (row?.plan_type ? <span>{"〇"}</span> : ""),
    },
    {
      title: t("Expiry date"),
      align: "center",
      key: "expiry_date",
      render: (row) => (
        <span>
          {row?.user_support_plan_id
            ? dayjs(row?.end_date).isValid()
              ? dayjs(row?.end_date).format(FULL_DATE_FORMAT)
              : ""
            : t("Individual support plan not created")}
        </span>
      ),
    },
  ]
  return (
    <>
      <PageTitle
        title={t("User who needs monitoring")}
        icon={<img src={"./assets/icons/data2.svg"} alt={"icon"} />}
      />
      <UserWhoNeedsMonitoringContent>
        <UserWhoNeedsMonitoringOperation
          searchParams={searchParams}
          onSearch={onSearch}
          onSearchReset={onSearchReset}
        />
        <Card title={t("User who needs monitoring")}>
          <TableContainer>
            <TMiniContainer>
              <img src={"./assets/icons/pink.svg"} alt={"icon"} />
              <span>
                {t("Users who do not have a registered recipient certificate")}
              </span>
            </TMiniContainer>
          </TableContainer>

          <div ref={listContent}>
            <Table
              current={page}
              columns={columns}
              pageSize={PAGE_SIZE}
              total={response?.count || 0}
              dataSource={response?.data}
              showPaginationOf={"both"}
              pagination={false}
              scroll={{ x: 900 }}
              onChange={(val) => setPage(val)}
              cellPaddingBlock={12}
              loading={isFetching || isLoading}
              rowClassName={(row: any) =>
                !row?.user_certificate_id && "no-certificate-row"
              }
            />
          </div>
        </Card>
      </UserWhoNeedsMonitoringContent>
    </>
  )
}

export { UserWhoNeedsMonitoring }
