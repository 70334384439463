/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, Table, theme } from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { YM_FORMAT } from "../../../constants"
import { AuthContext } from "../../../context"
dayjs.extend(arraySupport)
export const RecurringScheduleSettingList = ({
  dataSource,
  isLoading,
  hasWriteAccess,
}: {
  dataSource: any
  isLoading: boolean
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useContext(AuthContext)
  const columns: ColumnsType<any[]> = [
    {
      key: "1",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("View/edit")}</span>,
      align: "center",
      width: 100,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Edit")}
            textColor={theme.colors.link}
            type={"link"}
            onClick={() =>
              router.push({
                pathname: `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list/change-form`,
                query: {
                  mode: "edit",
                  schedule_id: row?.id,
                  year: row?.year,
                  month: row?.month,
                },
              })
            }
            disabled={!hasWriteAccess}
          />
        </Flex>
      ),
    },
    {
      key: "2",
      title: t("Start month of using recurring schedule settings"),
      align: "center",
      render: (row) => {
        return dayjs([row?.year, row?.month - 1]).format(YM_FORMAT)
      },
      width: "215px",
    },
    {
      key: "3",
      title: t("Setting status"),
      align: "center",
      width: 400,
      render: (row) => {
        const facility = facilities?.find((v) => v?.id === row?.facility_id)
          ?.label
        const schedules = {}
        row?.facility_repeat_schedule_details?.map(
          ({ week_no, ...schedule }, index) => {
            schedules[week_no] = Object.keys(schedule)
          },
        )
        return (
          <Flex justify={"center"} gap={6}>
            <Typography.Text style={{ whiteSpace: "nowrap" }}>
              {facility}
              {" : "}
            </Typography.Text>

            <Flex gap={4} wrap={"wrap"}>
              {Object?.keys(schedules).map((key, index) => (
                <>
                  <Typography.Text>
                    {t("{{week_no}} Week", {
                      week_no: key,
                    })}
                  </Typography.Text>
                  <Flex gap={4} wrap={"wrap"}>
                    {schedules[key]?.map((v, index) => (
                      <>
                        <Typography.Text
                          key={index}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {t(`${v[0].toUpperCase() + v.slice(1)}`)}
                        </Typography.Text>
                        {schedules[key].length - 2 === index && " "}
                      </>
                    ))}
                  </Flex>
                  {Object?.keys(schedules).length - 2 === index && " "}
                </>
              ))}
            </Flex>
          </Flex>
        )
      },
    },
    {
      key: "4",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Copy")}</span>,
      align: "center",
      width: 110,
      render: (row) => (
        <Flex justify={"center"}>
          <Button
            btnText={t("Copy")}
            textColor={theme.colors.link}
            type={"link"}
            onClick={() =>
              router.push({
                pathname: `/users/profile/${router?.query?.user_id}/recurring-schedule-setting-list/change-form`,
                query: {
                  mode: "copy",
                  schedule_id: row?.id,
                  year: dayjs().year(),
                  month: dayjs().month() + 1,
                },
              })
            }
            disabled={!hasWriteAccess}
          />
        </Flex>
      ),
    },
  ]

  return (
    <Card title={t("Recurring schedule settings to use this month")}>
      <Table
        columns={columns}
        dataSource={dataSource?.data || []}
        scroll={{ x: 800 }}
        loading={isLoading}
      />
    </Card>
  )
}
