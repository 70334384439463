// utils
import { keys, omit } from "@project/common"

/// types
import {
  DeletedItemsStore,
  TMDriverStaffDataType,
  DailyTransportDataType,
} from "../types"
import {
  PartialNullish,
  UserDailyTransport,
} from "../../../services/user-transport-mgmt.services"

// constants
const UNWANTED_KEYS_FOR_NEW_STAFF = ["id", "isNew", "key"] as const
const UNWANTED_KEYS_FOR_OLD_STAFF = [
  "driver_staff",
  "escort_staff",
  "car",
  "facility",
] as const

type OldUnwantedKeys = (typeof UNWANTED_KEYS_FOR_OLD_STAFF)[number]
type NewUnwantedKeys = (typeof UNWANTED_KEYS_FOR_NEW_STAFF)[number]

/**
 *
 * User can add new item/row and delete it before saving it to backend
 * when deleted newly created row gets added to `deletedItemStorage`.
 * We wanna filter out such items.
 *
 * This is where this function comes
 */
export const removeNewRows = (items: DeletedItemsStore) => {
  return {
    drop_staffs: items.drop_staffs
      .filter((staff) => !staff.isNew)
      .map((staff) => omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])),
    pickup_staffs: items.pickup_staffs
      .filter((staff) => !staff.isNew)
      .map((staff) => omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])),
  }
}

/**
 *
 * This function simply removed unwanted attributes from the data
 * that we don't want to send back to backend which would only increase load.
 *
 */
export const filterStaffData = (
  staffsData: PartialNullish<TMDriverStaffDataType>[],
  currentDate: string,
): Omit<
  PartialNullish<TMDriverStaffDataType>,
  OldUnwantedKeys | NewUnwantedKeys
>[] => {
  const sanitizedPickupStaffs = staffsData.map((staff) => {
    // If its newly created staff Data we don't wanna send in mentioned attributes to the backend,
    // as these `attrib` are used here in front-end side for different purpose
    // Hence we remove it from the actual Payload.
    if (staff.isNew) {
      return {
        ...omit(staff, [...UNWANTED_KEYS_FOR_NEW_STAFF]),
        date: currentDate,
      }
    }

    // Similarly if its the old staff data then there are few attribs that we wanna avoid sending it as payload.
    // As it might cause unpredicted issues just in case.
    return omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])
  })
  return sanitizedPickupStaffs
}

export const sanitizeUserTransportData = (
  currentDate: string,
  data: Partial<DailyTransportDataType>,
  deletedItems: DeletedItemsStore,
) => {
  const clonedUnwrappedData = JSON.parse(
    JSON.stringify(data.data),
  ) as DailyTransportDataType["data"]
  const filteredDeletedItems = removeNewRows(deletedItems)

  return keys(clonedUnwrappedData).reduce((acc, curr) => {
    if (curr === "drop_users" || curr === "pickup_users") {
      return {
        ...acc,
        [curr]: clonedUnwrappedData[curr].map(
          (user) =>
            omit({ ...user, id: 0 }, ["key", ...UNWANTED_KEYS_FOR_OLD_STAFF]), // reducing data payload
        ),
      }
    }

    if (curr === "pickup_staffs") {
      const pickupStaffsData = clonedUnwrappedData[curr]
      const sanitizedPickupStaffs = filterStaffData(
        pickupStaffsData,
        currentDate,
      )
      return {
        ...acc,
        pickup_staffs: [
          ...sanitizedPickupStaffs,
          ...filteredDeletedItems.pickup_staffs,
        ],
      }
    }

    if (curr === "drop_staffs") {
      const dropOffStaffsData = clonedUnwrappedData[curr]
      const sanitizedDropOffStaffs = filterStaffData(
        dropOffStaffsData,
        currentDate,
      )

      return {
        ...acc,
        drop_staffs: [
          ...sanitizedDropOffStaffs,
          ...filteredDeletedItems.drop_staffs,
        ],
      }
    }
  }, {} as UserDailyTransport)
}
