import { Button, Calendar, Card, MonthPagination, theme } from "@project/common"
import { Flex, Skeleton, Typography } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { FULL_DATE_FORMAT_EN, YM_FORMAT } from "../../constants"
const StyledContainer = styled.div`
  border: 1px solid ${theme.colors.border};
  padding: 20px;
  border-radius: 5px;
`
export const ActualCostBurdenRecordTable = ({
  params,
  setParams,
  dataSource,
  isLoading,
  facilityName,
  hasWriteAccess,
}: {
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  dataSource: any[]
  isLoading?: any
  facilityName?: any
  hasWriteAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Card
      title={t("{{facility}} {{date}}’s actual cost burden record table", {
        facility: facilityName || "",
        date: dayjs(params?.date).format(YM_FORMAT),
      })}
      extra={
        <MonthPagination
          currentMonth={dayjs(params?.date)?.month()}
          currentYear={dayjs(params?.date)?.year()}
          showDate={false}
          change={"month"}
          width={"80px"}
          onMonthChange={(_, __, date) => {
            setParams({
              ...params,
              date,
            })
          }}
        />
      }
    >
      {isLoading ? (
        <Flex vertical gap={4}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Flex>
      ) : (
        <StyledContainer>
          <Calendar
            value={dayjs(params?.date)}
            type={"SECONDARY"}
            minWidth={"800px"}
            cellRender={(date: Dayjs) => {
              const currentData = dataSource?.find(
                (data) => data?.date == dayjs(date).format(FULL_DATE_FORMAT_EN),
              )
              return {
                isHoliday: date.day() === 0 || date?.day() === 6,
                content: (
                  <Flex
                    vertical
                    gap={8}
                    justify={"flex-end"}
                    style={{
                      minHeight: "100px",
                      padding: "10px",
                    }}
                  >
                    {currentData && (
                      <div style={{ textAlign: "left" }}>
                        <Typography.Text>
                          {t(currentData?.actual_cost_detail) ||
                            t(currentData?.actual_cost?.actual_cost_name) ||
                            ""}
                        </Typography.Text>
                        <Typography.Text>{":"}</Typography.Text>
                        <Typography.Text>
                          {`(${currentData?.user_count || "0"} ${t("people")})`}
                        </Typography.Text>
                      </div>
                    )}

                    <Button
                      btnText={t("Edit")}
                      shape={"round"}
                      width={"fit-content"}
                      onClick={() =>
                        router.push(
                          `/actual-cost-burden-record/list?facility=${params?.facility}&date=${dayjs(
                            date,
                          ).format(FULL_DATE_FORMAT_EN)}`,
                        )
                      }
                      disabled={!hasWriteAccess}
                    />
                  </Flex>
                ),
              }
            }}
          />
        </StyledContainer>
      )}
    </Card>
  )
}
