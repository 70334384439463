import Link from "next/link"
import { useTranslation } from "react-i18next"
import { NHIFAlterContainerType } from "../../types/nhif.types"
import { NHIFAlertSection } from "./NHIFForm.styles"

const NHIFAlerts = ({
  nhifAlerts,
  facilityId,
  year,
  month,
}: NHIFAlterContainerType) => {
  const { t } = useTranslation()
  return (
    <>
      {nhifAlerts?.missing_attendance?.length > 0 && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t("There is a problem with the attendance sheet.")}
          </div>
          <div className={"alert-table"}>
            <table>
              {nhifAlerts?.missing_attendance?.map((alert) => (
                <>
                  <tr>
                    <td>{alert?.user_name}</td>
                    <td>
                      {"出席情報不足: "}
                      {alert?.dates?.map((d, i) => (
                        <>
                          {i > 0 && ", "}
                          <Link
                            href={`/user-attendance-calendar/daily-list?facilityId=${alert?.facility_id}&date=${d}`}
                          >
                            {d}
                          </Link>
                        </>
                      ))}
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        </NHIFAlertSection>
      )}
      {!nhifAlerts?.has_system_status_setup && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t("Status management page for this facility is not set yet.")}
          </div>
          <div className={"alert-links"}>
            <Link
              href={`/facility-information/system-status/${facilityId}?year_month=${year}-${month
                .toString()
                .padStart(2, "0")}`}
            >
              <a>{t("Click here to update status management")}</a>
            </Link>
          </div>
        </NHIFAlertSection>
      )}
      {nhifAlerts?.using_transportations?.length > 0 && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t(
              "Following are calculated based on past history. Confirm if these users actually use transportation in mentioned date.",
            )}
          </div>
          <div className={"alert-table"}>
            <table>
              {nhifAlerts?.using_transportations?.map((alert) => (
                <>
                  <tr>
                    <td>{alert?.user_name}</td>
                    <td>
                      {alert?.dates?.map((d, i) => (
                        <>
                          {i > 0 && ", "}
                          <Link
                            href={`/user-transport-management/edit/${alert?.user_id}/${d}`}
                          >
                            {d}
                          </Link>
                        </>
                      ))}
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        </NHIFAlertSection>
      )}
      {nhifAlerts?.facility_contact_info_missing?.length > 0 && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t(
              "The following facility information is missing. It is not printed when printing various forms, so please register it.",
            )}
          </div>
          <ul className={"alert-list"}>
            {nhifAlerts?.facility_contact_info_missing?.map(
              (missingContact) => (
                <li key={missingContact}>{t(missingContact)}</li>
              ),
            )}
          </ul>
          <div className={"alert-links"}>
            <Link href={`/facility-information/edit/${facilityId}`}>
              <a>{t("Click here to update facility information")}</a>
            </Link>
          </div>
        </NHIFAlertSection>
      )}
      {nhifAlerts?.users_with_expired_certificate?.length > 0 && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t("There are users whose recipient certificates are not valid.")}
          </div>
          <div className={"alert-table"}>
            <table>
              {nhifAlerts?.users_with_expired_certificate?.map((alert) => (
                <>
                  <tr>
                    <td>{alert?.user_name}</td>
                    <td>
                      <Link
                        href={`/users/profile/${alert?.user_id}/recipient-certificate-form`}
                      >
                        {t("Edit")}
                      </Link>
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        </NHIFAlertSection>
      )}
      {nhifAlerts?.users_with_used_days_more_than_contracted_days?.length >
        0 && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t(
              "The users listed below have used the facility for more days than their contract allowance.",
            )}
          </div>
          <div className={"alert-table"}>
            <table>
              {nhifAlerts?.users_with_used_days_more_than_contracted_days?.map(
                (alert) => (
                  <>
                    <tr>
                      <td>{alert?.user_name}</td>
                      <td>
                        <Link
                          href={`/user-service-record/details/${alert.user_id}?year=${year}&month=${month}`}
                        >
                          {alert.alert_message}
                        </Link>
                      </td>
                    </tr>
                  </>
                ),
              )}
            </table>
          </div>
        </NHIFAlertSection>
      )}
      {/* recently updated alert */}
      {(nhifAlerts?.attendance_updated_after_billing_confirmation?.length > 0 ||
        nhifAlerts?.facility_schedules_updated_after_billing_confirmation
          ?.length > 0 ||
        nhifAlerts?.certificate_updated_after_billing_confirmation?.length >
          0) && (
        <NHIFAlertSection>
          <div className={"alert-title"}>
            {t(
              "The following pages have been updated and may cause differences between the data being finalized.",
            )}
            <br />
            {t(
              `To reflect the updated information in the finalized data, please click on "Edit finalized billing information" and then click on Finalize billing information again.`,
            )}
          </div>
          {nhifAlerts?.attendance_updated_after_billing_confirmation?.length >
            0 && (
            <div className={"alert-table"}>
              <table>
                <tr>
                  <td colSpan={2}>
                    <b>{t("Attendance")}</b>
                  </td>
                </tr>
                {nhifAlerts?.attendance_updated_after_billing_confirmation?.map(
                  (alert) => (
                    <>
                      <tr>
                        <td>{alert?.user_name}</td>
                        <td>
                          {alert.dates.map((d, i) => (
                            <>
                              {i > 0 && ", "}
                              <Link
                                href={`/user-attendance-calendar/daily-list?facilityId=${alert?.facility_id}&date=${d}`}
                              >
                                {d}
                              </Link>
                            </>
                          ))}
                        </td>
                      </tr>
                    </>
                  ),
                )}
              </table>
            </div>
          )}

          {nhifAlerts?.facility_schedules_updated_after_billing_confirmation
            ?.length > 0 && (
            <div className={"alert-table"}>
              <table>
                <tr>
                  <td colSpan={2}>
                    <b>{t("Facility Application")}</b>
                  </td>
                </tr>
                {nhifAlerts?.facility_schedules_updated_after_billing_confirmation?.map(
                  (alert) => (
                    <>
                      <tr>
                        <td>{alert?.user_name}</td>
                        <td>
                          {alert.dates.map((d, i) => (
                            <>
                              {i > 0 && ", "}
                              <Link
                                href={`/users/profile/${alert.user_id}/application-management?year=${year}&month=${month}`}
                              >
                                {d}
                              </Link>
                            </>
                          ))}
                        </td>
                      </tr>
                    </>
                  ),
                )}
              </table>
            </div>
          )}

          {nhifAlerts?.certificate_updated_after_billing_confirmation?.length >
            0 && (
            <div className={"alert-table"}>
              <table>
                <tr>
                  <td colSpan={2}>
                    <b>{t("Recipient Certificate")}</b>
                  </td>
                </tr>
                {nhifAlerts?.certificate_updated_after_billing_confirmation?.map(
                  (alert) => (
                    <>
                      <tr>
                        <td>{alert?.user_name}</td>
                        <td>
                          <Link
                            href={`/users/profile/${alert?.user_id}/recipient-certificate-form`}
                          >
                            {t("Edit")}
                          </Link>
                        </td>
                      </tr>
                    </>
                  ),
                )}
              </table>
            </div>
          )}
        </NHIFAlertSection>
      )}
    </>
  )
}

export default NHIFAlerts
