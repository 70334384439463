import React from "react"
import { useTranslation } from "react-i18next"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { WagesRevenueOFWiseTabTable } from "../WagesRevenueCommon/WagesRevenueOFWiseTabTable"
import { Flex, Skeleton } from "antd"
import { useQuery } from "react-query"
import { getAllOverallWageRevenue } from "../../../services"
import { moneyFormat, useUpdateSearchParams } from "@project/common"
import { SINGLE_FACILITY_ROWS } from "../WagesRevenueCommon/constantTabRows"
import dayjs from "dayjs"

export const WagesRevenueOverallTab = ({
  params,
  setSearchParams,
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
}): JSX.Element => {
  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()
  const { isLoading, isFetching, data } = useQuery({
    queryKey: ["wages-revenue-overall-tab", params],
    queryFn: () =>
      getAllOverallWageRevenue({
        facility_id: params?.facility_id,
        year: dayjs(params?.date).year(),
      }),
    select: (res) => {
      const keyMapping = (data) => {
        return {
          total_1: moneyFormat(data?.revenue1 || 0),
          total_2: moneyFormat(data?.revenue2 || 0),
          total_3: moneyFormat(data?.revenue3 || 0),
          total_4: moneyFormat(data?.revenue4 || 0),
          total_5: moneyFormat(data?.revenue5 || 0),
          total_6: moneyFormat(data?.revenue6 || 0),
          total_7: moneyFormat(data?.revenue7 || 0),
          total_8: moneyFormat(data?.revenue8 || 0),
          total_9: moneyFormat(data?.revenue9 || 0),
          total_10: moneyFormat(data?.revenue10 || 0),
          total_11: moneyFormat(data?.revenue11 || 0),
          total_12: moneyFormat(data?.revenue12 || 0),
          total_13: moneyFormat(data?.revenue13 || 0),
          total_14: moneyFormat(data?.revenue14 || 0),
          total_15: moneyFormat(data?.revenue15 || 0),
          total_16: moneyFormat(data?.revenue16 || 0),
          total_17: moneyFormat(data?.revenue17 || 0),
        }
      }
      return {
        ...res?.data,
        current: {
          ...res?.data?.current,
          ...keyMapping(res?.data?.current),
        },
        previous: {
          ...res?.data?.previous,
          ...keyMapping(res?.data?.previous),
        },
        financial_result: {
          ...res?.data?.financial_result,
          ...keyMapping(res?.data?.financial_result),
        },
      }
    },
    refetchOnWindowFocus: false,
  })
  const columns = [
    {
      title: t("Financial results for the current fiscal year (a)"),
      key: "fiscal_year_a",
      dataIndex: "fiscal_year_a",
      width: 282,
      align: "center",
      className: "content_align",
      render: (_, row) => {
        return data?.current?.[row?.key]
      },
    },
    {
      title: t("Financial results for the previous fiscal year (b)"),
      key: "previous_fiscal_year_a",
      width: 282,
      dataIndex: "previous_fiscal_year_a",
      align: "center",
      className: "content_align",
      render: (_, row) => {
        return data?.previous?.[row?.key]
      },
    },
    {
      title: t("The previous year's financial results(A)-(B)"),
      key: "previous_fiscal_year_b",
      width: 282,
      align: "center",
      dataIndex: "previous_fiscal_year_b",
      className: "content_align",
      render: (_, row) => {
        return data?.financial_result?.[row?.key]
      },
    },
  ]

  return (
    <Flex vertical gap={16}>
      <TabTopContent
        tab={t("Overall revenue")}
        change={"year"}
        onChange={(value) => {
          setSearchParams({
            ...params,
            date: dayjs(value).format("YYYY"),
          })
          updateParams(
            {
              panel: "overall_revenue",
              facility_id: params?.facility_id,
              date: dayjs(value).format("YYYY"),
            },
            "/wages-revenue",
          )
        }}
      />
      {isLoading || isFetching ? (
        <Flex gap={16} wrap={"wrap"} vertical>
          {SINGLE_FACILITY_ROWS?.map((_, index) => (
            <Flex key={index} gap={8}>
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
              <Skeleton.Input block active />
            </Flex>
          ))}
        </Flex>
      ) : (
        <WagesRevenueOFWiseTabTable columns={columns || []} isLoading={false} />
      )}
    </Flex>
  )
}
