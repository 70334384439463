import { useTranslation } from "react-i18next"

// Real time validation for formik fields
export const realTimeValidate = (e, formik) =>
  formik?.setFieldTouched(e.target.name) && formik?.handleChange(e)

// Returns true if every element is invalid/empty
export const checkEmptyOrNull = (
  keys: any[],
  checkForSomeKeysOnly?: boolean,
) => {
  if (checkForSomeKeysOnly) {
    return keys?.some((el) => !el)
  }
  return keys?.every((el) => !el)
}

// Check if the number is in scientific notation like 1e2
export const checkExponentialNotation = (value: string) => {
  if (typeof value === "number" && !isNaN(value)) {
    // Check if the number is in scientific notation
    const stringValue = String(value)
    if (stringValue.includes("e") || stringValue.includes("E")) {
      return false
    }
  }
  if (
    typeof value === "string" &&
    (value.includes("e") || value.includes("E"))
  ) {
    return false
  }
  return true
}

// Generate maxLimit error message
export const maxLimitMessage = (maxLimit: number) => {
  const { t } = useTranslation()
  return t("Please enter number within {{maxLimit}} characters", {
    maxLimit,
  })
}

// Regex to check first character
export const japaneseAlphaRegex = (value) => {
  return value
}

// Regex to check furigana, hiragana, katakana
export const japaneseFuriganaAlphaRegex = (value) => {
  return new RegExp(
    `[\\p{Script=Hiragana}\\p{Script=Katakana}]*${value}[\\p{Script=Hiragana}\\p{Script=Katakana}]*`,
    "gu",
  )
}
