import {
  Box,
  Breadcrumb,
  Button,
  CheckBox,
  PageCard,
  PageTitle,
  Table,
  removeBlankAttributes,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Spin, notification } from "antd"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { PageHead } from "../../components"
import { AuthContext } from "../../context"
import { getUserServiceProvisionRecordList } from "../../services/userServiceProvisionRecord.services"
import { IUserServiceProvisionRecordSearchParams } from "../../types/UserServiceProvisionRecord.types"
import {
  CircularSelection,
  LoaderContainer,
  UserServiceProvisionResultTableContainer,
} from "./UserServiceProvisionResult.styles"
import { UserServiceProvisionResultButtonAction } from "./UserServiceProvisionResultButtonActions"
import { UserServiceProvisionResultOperation } from "./UserServiceProvisionResultOperation"
import { AlertBox } from "../SPRecordBulkPrintInternal/SPRecordBulkPrintInternal.styles"

export const UserServiceProvisionResult = () => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)

  const router = useRouter()

  const {
    facilityIds: queryFacilityIds,
    service: queryService,
    display_users_without_achievement: queryAchievement,
    year: queryYear,
    month: queryMonth,
    page: queryPage,
  } = router.query

  const queryDate =
    queryYear &&
    queryYear &&
    dayjs([+queryYear?.toString(), +queryMonth?.toString() - 1]).isValid()
      ? dayjs([+queryYear?.toString(), +queryMonth?.toString() - 1])
      : dayjs()

  const [updateParams] = useUpdateSearchParams()

  const [searchParams, setSearchParams] =
    useState<IUserServiceProvisionRecordSearchParams>({
      facilityIds: queryFacilityIds
        ? queryFacilityIds?.toString()?.split(",")
        : [],
      service: queryService
        ? queryService
            ?.toString()
            ?.split(",")
            ?.map((s) => +s)
        : [],
      display_users_without_achievement: queryAchievement
        ? queryAchievement?.toString()
        : "",
      year: queryDate.year(),
      month: queryDate.month() + 1,
      page: queryPage && !isNaN(+queryPage) ? +queryPage : 1,
      pageSize: "Infinity",
    })
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([])

  const handleSearchChange = (params) => {
    const query = removeBlankAttributes(params)
    updateParams(query, "/user-service-record/provision-result")
    setSearchParams(params)
    setSelectedUsers([])
  }

  const onSearch = (params) => {
    handleSearchChange(params)
  }

  const onReset = () => {
    handleSearchChange({
      facilityIds: [],
      service: [],
      display_users_without_achievement: "",
      year: dayjs().year(),
      month: dayjs().month() + 1,
    })
  }

  const handlePrint = () => {
    updateParams(
      {
        year: searchParams.year,
        month: searchParams.month,
      },
      `/user-service-record/provision-result/bulk-print/${selectedUsers.join(
        ",",
      )}`,
    )
  }

  const handleBack = () => {
    // navigate to specific page from where page was navigated to when ready
    router.back()
  }

  const renderInfoWithPagination = () => {
    return (
      <div className={"info-with-pagination"}>
        <div className={"info"}>
          {t("There are {{number}} data in total", {
            number: recordList?.count || 0,
          })}
        </div>
      </div>
    )
  }

  const getTableColumn = () => {
    const columns: Array<any> = [
      {
        key: "choice",
        title: <span style={{ whiteSpace: "nowrap" }}>{t("choice")}</span>,
        align: "center",
        width: 60,
        render: (row) => (
          <CheckBox
            checked={selectedUsers?.includes(row?.user_id)}
            onChange={() => {
              if (selectedUsers?.includes(row?.user_id)) {
                setSelectedUsers((prev) =>
                  prev.filter((s) => s != row?.user_id),
                )
              } else {
                setSelectedUsers([...selectedUsers, row?.user_id])
              }
            }}
          />
        ),
      },
      {
        key: "username",
        align: "center",
        width: 100,
        title: (
          <>
            <span style={{ whiteSpace: "nowrap" }}>{t("User name")}</span>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              {`(${t("Hiragana")})`}
            </span>
          </>
        ),
        render: (row) => (
          <>
            {row?.username}
            <br />
            {row?.furigana && `(${row?.furigana})`}
          </>
        ),
      },
    ]

    facilities?.forEach((facility: any) => {
      if (
        searchParams?.facilityIds?.length == 0 ||
        searchParams?.facilityIds.includes(facility?.value)
      ) {
        columns.push({
          key: "facility" + facility?.value,
          title: facility?.label,
          align: "center",
          render: (row) =>
            row?.facility_id == facility?.value && <CircularSelection />,
        })
      }
    })

    columns.push({
      key: "beneficiary",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Beneficiary certificate number")}
        </span>
      ),
      dataIndex: "certificate_number",
      align: "center",
    })

    return columns
  }

  const { data: recordList, isLoading } = useQuery(
    ["user-service-provision-record-list", searchParams],
    () => getUserServiceProvisionRecordList(searchParams),
    {
      keepPreviousData: false,
      select: (response: any) => {
        return {
          count: response?.count,
          data: response?.data?.map((d) => ({
            user_id: d?.user_id,
            username: d?.username,
            furigana: d?.furigana,
            facility_id: d?.facility_id?.toString(),
            certificate_number: d?.certificate_number,
          })),
        }
      },
      onError: (e: any) => {
        const message =
          e?.error?.error?.message ||
          "Something went wrong. Please contact administrator."
        notification.error({
          message: t(message),
        })
      },
    },
  )

  return (
    <>
      <PageHead>{t("Service provision record sheet")}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: <Link href={"#"}>{t("Service provision record")}</Link>,
          },
          {
            title: t("Service provision record sheet"),
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t("Service provision record sheet")}
      />
      <UserServiceProvisionResultOperation
        facilities={facilities}
        params={searchParams}
        handleSearch={onSearch}
        handleReset={onReset}
      />
      {isLoading ? (
        <LoaderContainer>
          <Spin size={"large"} />
        </LoaderContainer>
      ) : (
        <>
          {selectedUsers?.length > 20 && (
            <AlertBox c={"error"} bg={"white"} py={24} px={30}>
              <Flex align={"center"}>
                <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
                <p className={"alert-box-text first-text"}>
                  {t("You can only bulk print up to 20 data at once")}
                </p>
              </Flex>
            </AlertBox>
          )}
          <PageCard title={t("Select user you want to print")}>
            <Box gap={10} mb={16} display={"flex"} align={"center"}>
              <Button
                btnText={t("Check all")}
                iconType={"check-all"}
                shape={"round"}
                className={"bg-white"}
                type={"default"}
                onClick={() => {
                  setSelectedUsers(recordList?.data?.map((d) => d?.user_id))
                }}
              />
              <Button
                btnText={t("Uncheck all")}
                shape={"round"}
                type={"grayed"}
                onClick={() => setSelectedUsers([])}
              />
            </Box>

            <UserServiceProvisionResultTableContainer>
              <UserServiceProvisionResultButtonAction
                disablePrint={
                  selectedUsers?.length == 0 || selectedUsers?.length > 20
                }
                printAction={handlePrint}
                backAction={handleBack}
                showOutputNumber={true}
                outputNumber={selectedUsers?.length}
              />
              {renderInfoWithPagination()}
              <div className={"service-record-table"}>
                <Table
                  columns={getTableColumn()}
                  dataSource={recordList?.data}
                />
              </div>
              {renderInfoWithPagination()}
            </UserServiceProvisionResultTableContainer>
            <UserServiceProvisionResultButtonAction
              disablePrint={
                selectedUsers?.length == 0 || selectedUsers?.length > 20
              }
              printAction={handlePrint}
              backAction={handleBack}
              showOutputNumber={false}
            />
          </PageCard>
        </>
      )}
    </>
  )
}
