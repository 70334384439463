// packages
import { t } from "i18next"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"

// commons
import { Button, filterObject } from "@project/common"

// types
import {
  ProjectCostQueryParam,
  ManufacturingCostStatementDataType,
  StaticManufacturingCostDataType as StaticColumnsType,
} from "../../../../types"

export const getDynamicFacilityColumns = (
  facilityCostData: ManufacturingCostStatementDataType[],
  memoizedQuery: ProjectCostQueryParam,
  updateParams: (params: any, path: string) => void,
  hasEditAccess: (val) => boolean,
  mode?: "download" | "view",
): ColumnsType<StaticColumnsType> => {
  return facilityCostData?.map((item) => {
    return {
      key: item?.facility_id,
      dataIndex: item?.facility_id,
      className: "facility-cost-cell",
      title:
        mode === "download"
          ? item?.facility_name
          : () => {
              return (
                <Flex vertical gap={8} align={"center"}>
                  <Typography.Text>{item?.facility_name || ""}</Typography.Text>
                  <Button
                    btnText={t("edit")}
                    iconType={"blue-pencil"}
                    shape={"round"}
                    onClick={() =>
                      updateParams(
                        filterObject({
                          current_content: "WORK_MGMT",
                          facility_id: item?.facility_id,
                          date: memoizedQuery.date.format("YYYY-MM"),
                        }),
                        "/wages-revenue",
                      )
                    }
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    disabled={!hasEditAccess(item?.facility_id)}
                  />
                </Flex>
              )
            },
      align: "center",
      width: 127,
      render: (_, rec) => {
        if (rec?.isCategory) {
          return null
        }
        return mode === "download" ? (
          item?.[rec.key]?.toLocaleString()
        ) : (
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Text>
              {item?.[rec.key]?.toLocaleString()}
            </Typography.Text>
          </Flex>
        )
      },
    }
  })
}
