import {
  AccordionCard,
  Button,
  CALENDAR_HOURS_MINUTES,
  FacilityCheckbox,
  InputField,
  LOCATIONS as ORIGINAL_LOCATIONS,
  LabelTextWithLeftArrow,
  OpFlexContainer,
  OperationSearchContent,
  RadioGroup,
  SelectInput,
  TimeRangeContainer,
} from "@project/common"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  WEEK_DAYS,
  WEEK_DIVISION,
  YES_NO_LIST_REVERSED as ORIGINAL_YES_NO_LIST,
} from "../../../../constants"
import { Flex, Typography } from "antd"

export const ApplicationReoccurringSettingChangeOperation = ({
  shiftData,
  dataSource,
  setSourceData,
  userContractData,
  mealData,
}: {
  dataSource: any
  shiftData: any[]
  userContractData: any[]
  setSourceData: React.Dispatch<React.SetStateAction<any>>
  mealData: any[]
  isMealDataLoading: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  const [values, setValues] = useState({
    facility_id: userContractData?.[1]?.value || null,
    week_no: [],
    week: [],
    pickup: "0",
    pickup_place: null,
    pickup_place_other: "",
    pickup_time: "",
    drop: "0",
    drop_place: null,
    drop_place_other: "",
    drop_time: "",
    note_flag: "0",
    remarks: "",
    meal_flag: false,
    meal_id: null,
    shift_id: null,
  })

  const handleReflect = () => {
    const getWeekDataInfo = (data: any) => {
      const dd = {}
      values?.week?.map((v) => {
        const key = WEEK_DAYS?.find((w) => w?.value === v)?.key
        dd[key] = {
          ...data[key],
          facility_id: values?.facility_id,
          shift_id: values?.shift_id,
          meal_flag: values?.meal_flag,
          meal_id: values?.meal_flag ? values?.meal_id : null,
          pickup: values?.pickup === "1" ? true : false,
          pickup_place: values?.pickup_place,
          pickup_place_other: values?.pickup_place_other,
          pickup_time: values?.pickup_time,
          drop: values?.drop === "1" ? true : false,
          drop_place: values?.drop_place,
          drop_place_other: values?.drop_place_other,
          drop_time: values?.drop_time,
          remarks: values?.remarks,
          is_delete: false,
        }
      })
      return dd
    }
    const data = dataSource?.facility_repeat_schedule_details?.map((detail) => {
      if (values?.week_no?.includes(`${detail?.week_no}`)) {
        const d = {
          ...detail,
          ...getWeekDataInfo(detail),
        }
        return d
      }
      return detail
    })
    const finalData = {
      ...dataSource,
      facility_repeat_schedule_details: data,
    }
    setSourceData(finalData)
  }
  useEffect(() => {
    setValues({
      ...values,
      facility_id: userContractData?.[1]?.value || null,
    })
  }, [userContractData])

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const YES_NO_LIST_REVERSED =
    getOptionsWithTranslatedLabel(ORIGINAL_YES_NO_LIST)

  const LOCATIONS = getOptionsWithTranslatedLabel(ORIGINAL_LOCATIONS)

  return (
    <AccordionCard
      accordion={true}
      defaultActiveKey={["schedule-registration"]}
      items={[
        {
          key: "schedule-registration",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"136px"} />
                <SelectInput
                  name={"facility"}
                  options={userContractData || []}
                  placeholder={"--"}
                  value={values?.facility_id || null}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      facility_id: value,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Week")} width={"136px"} />
                <FacilityCheckbox
                  options={getOptionsWithTranslatedLabel(WEEK_DIVISION)}
                  btnHeight={"30px"}
                  value={values?.week_no}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = WEEK_DIVISION?.map((v) => v?.value)
                      setValues({
                        ...values,
                        week_no: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        week_no: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      week_no: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Week Day")} width={"136px"} />
                <FacilityCheckbox
                  options={getOptionsWithTranslatedLabel(WEEK_DAYS)}
                  btnHeight={"30px"}
                  value={values?.week}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = WEEK_DAYS?.map((v) => v?.value)
                      setValues({
                        ...values,
                        week: ids,
                      })
                    }
                    if (type === "uncheck") {
                      setValues({
                        ...values,
                        week: [],
                      })
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    setValues({
                      ...values,
                      week: list,
                    })
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Pickup")} width={"136px"} />
                <OpFlexContainer>
                  <RadioGroup
                    options={YES_NO_LIST_REVERSED}
                    value={values?.pickup}
                    onChange={({ target: { value } }) =>
                      setValues({
                        ...values,
                        pickup: value,
                      })
                    }
                  />
                  <div className={"flex__content"}>
                    <Typography.Text
                      style={{ minWidth: "88px", whiteSpace: "nowrap" }}
                    >
                      {t("Pick up place")}
                    </Typography.Text>
                    <SelectInput
                      options={LOCATIONS}
                      name={"place"}
                      placeholder={"--"}
                      width={"86px"}
                      disabled={values?.pickup === "0"}
                      value={+values?.pickup_place || null}
                      onChange={(value) =>
                        setValues({
                          ...values,
                          pickup_place: `${value}`,
                        })
                      }
                    />
                  </div>
                  {values?.pickup_place === "3" && (
                    <div className={"flex__content"}>
                      <InputField
                        value={values?.pickup_place_other || null}
                        placeholder={"--"}
                        height={"40px"}
                        onChange={({ target: { value } }) =>
                          setValues({
                            ...values,
                            pickup_place_other: value,
                          })
                        }
                      />
                    </div>
                  )}

                  <div className={"flex__content"}>
                    <Typography.Text
                      style={{ minWidth: "122px", whiteSpace: "nowrap" }}
                    >
                      {t("Pickup desire time")}
                    </Typography.Text>
                    <TimeRangeContainer>
                      <div className={"time_range start"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            disabled={values?.pickup === "0"}
                            value={values?.pickup_time?.split(":")?.[0] || null}
                            onChange={(value) =>
                              setValues({
                                ...values,
                                pickup_time: `${value}:00`,
                              })
                            }
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            disabled={
                              values?.pickup === "0" ||
                              !values?.pickup_time?.split(":")?.[1]
                            }
                            value={values?.pickup_time?.split(":")?.[1] || null}
                            onChange={(value) =>
                              setValues({
                                ...values,
                                pickup_time: `${values?.pickup_time?.split(
                                  ":",
                                )?.[0]}:${value}`,
                              })
                            }
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Drop off")} width={"136px"} />
                <OpFlexContainer>
                  <RadioGroup
                    options={YES_NO_LIST_REVERSED}
                    value={values?.drop}
                    onChange={({ target: { value } }) =>
                      setValues({
                        ...values,
                        drop: value,
                      })
                    }
                  />
                  <div className={"flex__content"}>
                    <Typography.Text
                      style={{ minWidth: "88px", whiteSpace: "nowrap" }}
                    >
                      {t("Drop off place")}
                    </Typography.Text>
                    <SelectInput
                      options={LOCATIONS}
                      name={"place"}
                      placeholder={"--"}
                      width={"86px"}
                      value={+values?.drop_place || null}
                      disabled={values?.drop === "0"}
                      onChange={(value) =>
                        setValues({
                          ...values,
                          drop_place: `${value}`,
                        })
                      }
                    />
                  </div>
                  {values?.drop_place === "3" && (
                    <div className={"flex__content"}>
                      <InputField
                        value={values?.drop_place_other || null}
                        placeholder={"--"}
                        height={"40px"}
                        onChange={({ target: { value } }) =>
                          setValues({
                            ...values,
                            drop_place_other: value,
                          })
                        }
                      />
                    </div>
                  )}
                  <div className={"flex__content"}>
                    <Typography.Text
                      style={{ minWidth: "122px", whiteSpace: "nowrap" }}
                    >
                      {t("Drop off desire time")}
                    </Typography.Text>
                    <TimeRangeContainer>
                      <div className={"time_range start"}>
                        <div className={"from__time"}>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_hr"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.hours}
                            disabled={values?.drop === "0"}
                            value={values?.drop_time?.split(":")?.[0] || null}
                            onChange={(value) =>
                              setValues({
                                ...values,
                                drop_time: `${value}:00`,
                              })
                            }
                          />
                          <span>{":"}</span>
                          <SelectInput
                            width={"58px"}
                            name={"form_time_min"}
                            placeholder={"--"}
                            options={CALENDAR_HOURS_MINUTES?.minutes}
                            disabled={values?.drop === "0"}
                            value={values?.drop_time?.split(":")?.[1] || null}
                            onChange={(value) =>
                              setValues({
                                ...values,
                                drop_time: `${values?.drop_time?.split(
                                  ":",
                                )?.[0]}:${value}`,
                              })
                            }
                          />
                        </div>
                      </div>
                    </TimeRangeContainer>
                  </div>
                </OpFlexContainer>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Note")} width={"122px"} />
                <RadioGroup
                  value={values?.note_flag}
                  options={[
                    {
                      label: t("Do not update"),
                      value: "0",
                    },
                    {
                      label: t("Want to update"),
                      value: "1",
                    },
                  ]}
                  onChange={({ target: { value } }) =>
                    setValues({
                      ...values,
                      note_flag: value,
                    })
                  }
                />
                <Flex>
                  <InputField
                    placeholder={"--"}
                    height={"40px"}
                    value={values?.remarks || null}
                    disabled={values?.note_flag === "0"}
                    onChange={({ target: { value } }) =>
                      setValues({
                        ...values,
                        remarks: value,
                      })
                    }
                  />
                </Flex>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Meal ")} width={"122px"} />
                <RadioGroup
                  options={YES_NO_LIST_REVERSED}
                  value={values.meal_flag ? "1" : "0"}
                  onChange={(e) =>
                    setValues({ ...values, meal_flag: e.target.value == "1" })
                  }
                />
                {values.meal_flag && (
                  <SelectInput
                    placeholder={"--"}
                    name={"meal"}
                    value={values?.meal_id || null}
                    options={
                      [
                        {
                          value: null,
                          label: "--",
                        },
                        ...mealData,
                      ] || []
                    }
                    onChange={(value) =>
                      setValues({
                        ...values,
                        meal_id: value,
                      })
                    }
                  />
                )}
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Shift Selection")}
                  width={"122px"}
                />
                <SelectInput
                  placeholder={"--"}
                  name={"shift"}
                  value={values?.shift_id || null}
                  options={shiftData || []}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      shift_id: value,
                    })
                  }
                />
              </div>{" "}
              <div className={"search__content"}>
                <Button
                  btnText={t("Reflect")}
                  shape={"round"}
                  onClick={handleReflect}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
