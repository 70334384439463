import { Tag, theme } from "@project/common"
import { Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  flex-wrap: wrap;
`
export const Title = ({
  title,
  isSet,
}: {
  title?: string
  isSet?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Typography.Text>{title}</Typography.Text>
      {isSet ? (
        <Tag
          title={t("Set already")}
          borderColor={theme.colors.infoDark}
          defaultBg={theme.colors.infoDark}
          textColor={theme.colors.white}
        />
      ) : (
        <Tag title={t("No Setting")} />
      )}
    </Wrapper>
  )
}
