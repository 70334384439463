import { ActionBtns, Button } from "@project/common"
import { Popconfirm } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"

export const PlanFormBtnAction = ({
  mt,
  handleSaveDraft,
  handleSave,
  handleCancel,
  loading,
  disabled,
  isDeleting,
  draftFlg,
  handleDelete,
  action,
}: any) => {
  const { t } = useTranslation()
  return (
    <ActionBtns mt={mt}>
      <Button
        btnText={t("Save Draft")}
        otherType={"draft"}
        shape={"round"}
        btnBg={"#EAF9FF"}
        onClick={handleSaveDraft}
        disabled={disabled}
        isLoading={loading && draftFlg}
      />
      <div
        style={{
          display: "flex",
          rowGap: "16px",
          columnGap: "16px",
          flexWrap: "wrap",
        }}
      >
        <Button
          btnText={t("Cancel")}
          shape={"round"}
          onClick={handleCancel}
          disabled={disabled}
        />
        <Button
          btnText={t("Save")}
          shape={"round"}
          type={"primary"}
          disabled={disabled}
          isLoading={loading && !draftFlg}
          onClick={handleSave}
        />
        {action === "edit" && (
          <Popconfirm
            title={t("Deleting.Is that OK?")}
            onConfirm={handleDelete}
            okText={t("OK")}
            cancelText={t("Cancel")}
            okButtonProps={{ size: "middle" }}
            cancelButtonProps={{ size: "middle" }}
          >
            <Button
              btnText={t("Delete")}
              shape={"round"}
              disabled={disabled || isDeleting}
              isLoading={isDeleting}
              iconType={"delete"}
            />
          </Popconfirm>
        )}
      </div>
    </ActionBtns>
  )
}
