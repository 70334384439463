import { Button, Card, Grid, InputField, SelectInput } from "@project/common"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import React from "react"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"

import { FULL_DATE_FORMAT } from "../../../constants"
import { getAllActualCost } from "../../../services"
import { useAuthContext } from "../../../context"

export const ActualCostBurdenRecordAdd = ({
  handleDataMutation,
  isMutating,
}: {
  handleDataMutation: (values: any) => void
  isMutating?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useAuthContext()
  const { facility, date, actual_cost_id } = router?.query as any
  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      facility_id: facility,
      actual_cost_id: +actual_cost_id || null,
      actual_cost_detail: "",
      date: date,
    },
    onSubmit: (formValues) => {
      handleDataMutation({
        ...formValues,
        actual_cost_id: +formValues?.actual_cost_id,
        facility_id: +formValues?.facility_id,
      })
    },
  })
  //get all actual cost
  const { data: actualCost, isLoading } = useQuery({
    queryKey: ["actual-const-list"],
    queryFn: () => getAllActualCost({ page: 1, pageSize: "Infinity" }),
    select: (res) => {
      return res?.data?.map((v) => ({
        ...v,
        label: v?.actual_cost_name,
        value: v?.id,
      }))
    },
  })
  const data = actualCost ? actualCost : []
  return (
    <Card title={t("Register actual cost item")}>
      <Flex component={"form"} vertical gap={16} onSubmit={handleSubmit}>
        <div>
          <Grid labelContent={t("Date")} padding={"15px"} background>
            <Typography.Text>
              {date
                ? dayjs(date).format(FULL_DATE_FORMAT)
                : dayjs().format(FULL_DATE_FORMAT)}
            </Typography.Text>
          </Grid>
          <Grid labelContent={t("Facility")} padding={"15px"} background>
            {facilities?.find((v) => v?.value === facility)?.label || ""}
          </Grid>
          <Grid
            labelContent={t("Actual cost item name")}
            padding={"15px"}
            background
          >
            <Flex vertical gap={8}>
              <SelectInput
                name={"actual-cost"}
                placeholder={"--"}
                width={"208px"}
                height={"40px"}
                value={values?.actual_cost_id || null}
                onChange={(value) => setFieldValue("actual_cost_id", value)}
                loading={isLoading}
                popupMatchSelectWidth={false}
                options={[
                  {
                    label: t("Register actual expenses individually"),
                    value: "0",
                  },
                  ...data,
                ]}
              />
              <InputField
                width={"300px"}
                height={"40px"}
                placeholder={"--"}
                value={values?.actual_cost_detail || null}
                disabled={
                  String(values?.actual_cost_id) !== "0" ||
                  values?.actual_cost_id === null
                }
                onChange={({ target: { value } }) =>
                  setFieldValue("actual_cost_detail", value)
                }
              />
            </Flex>
          </Grid>
        </div>
        <Flex gap={16} wrap={"wrap"}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={() => router.push("/actual-cost-burden-record/list")}
            disabled={isMutating}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            type={"primary"}
            htmlType={"submit"}
            isLoading={isMutating}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
