import { moneyFormat, theme, userWageSetting } from "@project/common"
import { Flex } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  .amount_info {
    border: 1px solid ${theme.colors.text};
    padding: 16px;
    border-radius: 12px;
    height: fit-content;
    width: 100%;
    #amount_info {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse !important;
      .wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .caption {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
      }
      .footer {
        border-top: 1px solid ${theme.colors.text};
        .main {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xlg}) {
    flex-direction: column;
  }
`

export const DetailAmountInfo = ({
  user_wage_setting,
  facility_wage_settings,
  user_facility_wage_settings,
}): JSX.Element => {
  const { t } = useTranslation()
  const tableView = ({
    caption,
    bodyData,
    total_amount,
  }: {
    caption: string
    bodyData: any[]
    total_amount: number
  }) => {
    return (
      <Flex className={"amount_info"}>
        <div id={"amount_info"}>
          <p className={"caption"}>{t(caption)}</p>
          {/* <tbody> */}
          {bodyData?.map((data, index) => (
            <div key={index} className={"wrapper"}>
              <p>{t(data?.name || data?.facility_wage_setting?.name)}</p>
              <p>{moneyFormat(data?.amount || 0)}</p>
            </div>
          ))}
          <div className={"footer"}>
            <div className={"main"}>
              <p>{t("Total")}</p>
              <p>{moneyFormat(total_amount)}</p>
            </div>
          </div>
        </div>
      </Flex>
    )
  }

  const {
    allowanceAllItems,
    deductionAllItems,
    totalAllowanceAmount,
    totalDeductionAmount,
  } = userWageSetting(
    user_wage_setting,
    facility_wage_settings,
    user_facility_wage_settings,
  )
  return (
    <Wrapper>
      {tableView({
        caption: "Payment",
        total_amount: totalAllowanceAmount,
        bodyData: allowanceAllItems,
      })}
      {tableView({
        caption: "Deduction (e.g. tax)",
        total_amount: totalDeductionAmount,
        bodyData: deductionAllItems,
      })}
    </Wrapper>
  )
}
