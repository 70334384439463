import styled from "styled-components"

export const DailyBusinessReportBulkPrintStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title_container {
    .title {
      text-align: center;
    }
  }
  .attendance--info-container {
    display: flex;
    justify-content: space-between;
    div {
      font-size: 8pt !important;
    }
  }
  table {
    tbody {
      tr {
        td {
          .text {
            font-size: 8pt !important;
          }
        }
      }
    }
  }
  .support-record-container {
    /* background-color: red; */
  }
`
