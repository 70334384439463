import {
  Card,
  FACILITY_TYPES,
  MonthPagination,
  Tabs,
  useUpdateSearchParams,
} from "@project/common"
import { Flex } from "antd"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationManagementListActionBtns } from "../ApplicationManagementCommon/ApplicationManagementListActionBtns"

import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { styled } from "styled-components"
import { YM_FORMAT } from "../../../../constants"
import { AuthContext } from "../../../../context"
import { useApplicationManagement } from "../../../../context/ApplicationManagementContext"
import {
  FacilityAppManagementPermission,
  hasPermissionForFacility,
} from "../../../../utils/PermissionKeys"
import { ApplicationManagementLegend } from "../ApplicationManagementCommon/ApplicationManagementLegend"
import { ApplicationManagementListTable } from "../ApplicationManagementCommon/ApplicationManagementListTable/ApplicationManagementListTable"
import { ApplicationManagementSummaryInfo } from "../ApplicationManagementCommon/ApplicationManagementSummaryInfo"

const ApplicationContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 9999;
  @media screen and (max-width: 954px) {
    position: relative;
  }
`

export const ApplicationManagementList = (): JSX.Element => {
  const { t } = useTranslation()
  const [userName, setUserName] = useState(null)
  const [updateParams] = useUpdateSearchParams()
  const { params, user, setParams, hasMonthlySchedule } =
    useApplicationManagement()
  const router = useRouter()
  const { isOwner, permissions } = useContext(AuthContext)

  const hasWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        FacilityAppManagementPermission,
        "write",
        user?.info?.facility_id,
      ),
    [isOwner, permissions, user],
  )

  useEffect(() => {
    setUserName(user?.data?.username)
  }, [user])
  const handleBntClick = (action: "DET" | "EFA" | "BP" | "CP" | "BAC") => {
    //DET-determined, EFA->edit facility application, BP->Bulk print , CP->calendar print , BAC->back
    switch (action) {
      case "DET":
        return null
      case "EFA":
        router.push(
          `/users/profile/${router?.query?.user_id}/application-management/${
            hasMonthlySchedule ? "edit" : "add"
          }?year=${params?.year}&month=${params?.month}`,
        )
        return
      case "BP":
        router.push(
          `/users/profile/${router?.query?.user_id}/application-management/print?mode=list&year=${params?.year}&month=${params?.month}`,
        )
        return
      case "CP":
        router.push(
          `/users/profile/${router?.query?.user_id}/application-management/print?mode=calendar&year=${params?.year}&month=${params?.month}`,
        )
        return
      case "BAC":
        router.back()
        return
      default:
        return null
    }
  }
  const serviceType = t(
    FACILITY_TYPES?.find(
      (v) => v?.value === user?.info?.user_certificate?.service_type,
    )?.label || "",
  )
  const title = t("{{user_name}}さん’s {{date}}User application list", {
    user_name: userName || "",
    date: (dayjs([params?.year, params?.month - 1]).isValid()
      ? dayjs([params?.year, params?.month - 1])
      : dayjs()
    ).format(YM_FORMAT),
  })

  return (
    <Card
      title={title}
      extra={
        <MonthPagination
          currentMonth={+params?.month - 1}
          currentYear={+params?.year || 2024}
          change={"month"}
          showDate={false}
          onMonthChange={(_, __, date?: Dayjs) => {
            updateParams(
              {
                year: date?.year() || null,
                month: date?.month() + 1 || null,
              },
              `/users/profile/${params?.user_id}/application-management`,
            )
            setParams({
              ...params,
              year: date?.year(),
              month: date?.month() + 1,
            })
          }}
        />
      }
      style={{
        zIndex: 1,
      }}
    >
      <Flex vertical gap={8} style={{ position: "relative" }}>
        <ApplicationContainer>
          <ApplicationManagementListActionBtns
            onClick={handleBntClick}
            hasMonthlySchedule={hasMonthlySchedule}
            hasWriteAccess={hasWriteAccess}
          />
        </ApplicationContainer>
        <Tabs
          items={[
            {
              key: "service_type",
              label: serviceType || "---",
              children: (
                <Flex
                  vertical
                  gap={16}
                  style={{
                    padding: "16px",
                  }}
                >
                  <ApplicationManagementSummaryInfo />
                  <ApplicationManagementLegend />
                  <ApplicationManagementListTable page={"render"} />
                  <ApplicationManagementSummaryInfo />
                </Flex>
              ),
            },
          ]}
        />
        <ApplicationManagementListActionBtns
          showBtn={"B"}
          onClick={handleBntClick}
          hasMonthlySchedule={hasMonthlySchedule}
          hasWriteAccess={hasWriteAccess}
        />
      </Flex>
    </Card>
  )
}
