import { theme } from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { FUCInfo } from "../../../components"
import { FULL_DATE_FORMAT } from "../../../constants"
const SummaryWrapper = styled.div`
  .other__info {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    &--left,
    &--right {
      flex: 1;
      .content {
        border: 1px solid ${theme.colors.border};
        display: flex;
        .label,
        .value {
          flex: 1;
          padding: 5px 10px;
        }
        .label {
          border-right: 1px solid ${theme.colors.border};
          background-color: ${theme.colors.background};
          offset: 2;
        }
        &:last-child {
          border-top: 0 !important;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints?.sm}) {
      flex-direction: column;
    }
  }
  @media print {
    margin-bottom: 8px !important;
  }
`
export const PlanDetailSummary = ({
  defaultData,
}: {
  defaultData?: any
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SummaryWrapper id={"plan_detail_summary"}>
      <FUCInfo
        facility_name={defaultData?.facility?.facility_name}
        use_service_id={+defaultData?.use_service_id || 0}
        created_datetime={defaultData?.report_create_date || dayjs()}
      />
      <div className={"other__info"}>
        <div className={"other__info--left"}>
          <div className={"content"}>
            <div className={"label"}>{t("Detail2")}</div>
            <div className={"value"}>
              {defaultData?.user_info?.user_certificate?.number}
            </div>
          </div>
          <div className={"content"}>
            <div className={"label"}>{t("No. of Created")}</div>
            <div className={"value"}>{defaultData?.create_count}</div>
          </div>
        </div>
        <div className={"other__info--right"}>
          <div className={"content"}>
            <div className={"label"}>{t("Start date")}</div>
            <div className={"value"}>
              {dayjs(defaultData?.start_date).isValid()
                ? dayjs(defaultData?.start_date).format(FULL_DATE_FORMAT)
                : ""}
            </div>
          </div>
          <div className={"content"}>
            <div className={"label"}>{t("Expiry Date2")}</div>
            <div className={"value"}>
              {dayjs(defaultData?.end_date).isValid()
                ? dayjs(defaultData?.end_date).format(FULL_DATE_FORMAT)
                : ""}
            </div>
          </div>
        </div>
      </div>
    </SummaryWrapper>
  )
}
