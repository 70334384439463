// packages
import { t } from "i18next"

export const LIST_DISPLAY = [
  {
    label: t("Pickup"),
    value: 1,
  },
  {
    label: t("Dropoff"),
    value: 2,
  },
]

export const BINARY_OPTION = [
  {
    label: "OK",
    value: "1",
  },
  {
    label: "NG",
    value: "2",
  },
]
export const ROUTE_OPTIONS = [
  {
    label: "1",
    value: 1,
  },

  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
]
