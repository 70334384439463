import { keys } from "@project/common"
import { UsedFacilityDataType } from "../../../types/nhif.types"

export const fetchIds = (record: Record<string, UsedFacilityDataType[]>) => {
  if (!record) {
    return ""
  }

  return keys(record)
    .map((key) => record[key].map((rec) => rec.user_id))
    .flat()
    .join(",")
}
