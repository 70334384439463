import { EyeOutlined } from "@ant-design/icons"
import {
  AccordionCard,
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  LabelTextWithLeftArrow,
  LinkButton,
  OperationOptionContainer,
  PageTitle,
  SelectInput,
  ShowMoreText,
  Table,
  dynamicLangString,
  pageSize,
  removeBlankAttributes,
  removeTags,
  theme,
} from "@project/common"
import { Image, Typography, notification } from "antd"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { FULL_DATE_FORMAT } from "../../constants"
import { AuthContext } from "../../context/AuthContext"
import {
  fetchUserActivity,
  releaseProgramRecord,
} from "../../services/userActivityManagement"
import {
  ProgramRecordPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../utils/sidebarUtils"

const UserActivityListContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`
const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .no-pointer-events {
    pointer-events: none;
  }
  .ant-btn {
    span:nth-child(2) {
      width: 50px;
    }
  }
`
export const UserActivityContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  .date_picker_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 5px;
    align-items: center;
    justify-content: center;

    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (width: 540px) {
      display: block;
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
  }
`
const TableWrapper = styled.div``

export const UserActivityList = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isOwner, permissions, facilities } = useContext(AuthContext)

  const createAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, ProgramRecordPermission, "write"),
    [isOwner, permissions],
  )

  const [searchEnabled, setSearchEnabled] = useState(true)
  const [page, setPage] = useState(+router.query?.page || 1)
  const [searchParams, setSearchParams] = useState<any>({
    facility_ids: null,
    from_date: "",
    to_date: "",
  })
  const breadcrumbItems = [
    {
      title: <Link href={"/"}>{t("Home")}</Link>,
    },
    {
      title: t("facility activity management"),
    },
  ]

  const {
    isLoading,
    isFetching,
    data: UserActivityListData,
    refetch,
  } = useQuery(
    ["fetch-user-activity"],
    () =>
      fetchUserActivity({
        queryKey: ["fetch-user-activity", page, pageSize, searchParams],
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: searchEnabled,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return {
              ...data,
              hasEditAccess:
                isOwner ||
                hasPermissionForFacility(
                  permissions,
                  ProgramRecordPermission,
                  "write",
                  data?.facility_id,
                ),
            }
          }),
          count: response?.count,
        }
      },
      onSuccess: () => {
        setSearchEnabled(false)
      },
      onError: () => {
        setSearchEnabled(false)
      },
    },
  )
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    router.push(
      {
        pathname: "/facility-activity-management",
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  const { mutate: releaseRecord } = useMutation(releaseProgramRecord, {
    onSuccess: () => {
      refetch()
      notification.success({
        message: dynamicLangString(["Updated Successfully"]),
      })
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })
  const columns: ColumnsType<any> = [
    {
      title: t("Edit"),
      dataIndex: "edit",
      key: "edit",
      width: "100px",
      align: "center",
      render: (_, data) => {
        const { id } = data
        return (
          <LinkButton
            disabled={!data?.hasEditAccess}
            onClick={() =>
              router.push(`facility-activity-management/edit/${id}`)
            }
          >
            {t("Edit")}
          </LinkButton>
        )
      },
    },
    {
      title: t("Image"),
      dataIndex: "article_content_img_1",
      key: "image",
      align: "center",
      width: "232px",
      className: "table_text",
      render: (value: string) => {
        if (value && value?.includes("image/"))
          return (
            <Image
              src={value}
              width={"200px"}
              height={"120px"}
              preview={{
                mask: (
                  <span>
                    <EyeOutlined />
                    &nbsp;
                    {t("Preview")}
                  </span>
                ),
              }}
              onError={(event: any) => (event.target.style.display = "none")}
              style={{
                objectFit: "fill",
                borderRadius: "12px",
              }}
            />
          )
      },
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "409px",
      className: "spanned-head",
      children: [
        {
          title: t("Contents"),
          dataIndex: "contents",
          key: "contents",
          width: "460px",
          align: "center",
          render: (_, row) => {
            const parsedArticle_content_1 = removeTags(row.article_content_1)

            const parsedArticle_content_2 = removeTags(row.article_content_2)

            return {
              props: {
                style: { padding: 0, minHeight: "200px", verticalAlign: "top" },
              },
              children: (
                <div>
                  <div
                    style={{
                      height: "60px",
                      padding: "10px",
                      borderBottom: `1px solid ${theme.colors.border}`,
                    }}
                  >
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: false }}
                    >
                      {row?.article_title}
                    </Typography.Paragraph>
                  </div>
                  <div style={{ padding: 10, height: "auto" }}>
                    <ShowMoreText
                      article={`${parsedArticle_content_1 ?? ""}${
                        parsedArticle_content_2
                          ? `. ${parsedArticle_content_2}`
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ),
            }
          },
        },
      ],
    },
    {
      title: t("Register Date"),
      width: "229px",
      dataIndex: "register_date",
      key: "register_date",
      align: "center",
      className: "table_text",
      children: [
        {
          title: t("Article create facility"),
          dataIndex: "article_create_facility",
          key: "article_create_facility",
          align: "center",
          width: "200px",
          render: (_, row) => {
            return {
              props: {
                style: {
                  padding: 0,
                  textAlign: "center",
                  height: "188px",
                },
              },
              children: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    justifyContent: "flex-start",
                    margin: 0,
                    height: "100%",
                    minWidth: "200px",
                  }}
                >
                  <CellWrapper
                    style={{
                      height: "60px",
                      borderBottom: `1px solid ${theme.colors.border}`,
                      padding: 0,
                    }}
                  >
                    {row?.date
                      ? dayjs(row?.date)
                          .format(FULL_DATE_FORMAT)
                          .toLocaleUpperCase("ja")
                      : ""}
                  </CellWrapper>
                  <CellWrapper style={{ flex: 1 }}>
                    {row?.facility?.facility_name}
                  </CellWrapper>
                </div>
              ),
            }
          },
        },
      ],
    },
    {
      title: t("Last Update"),
      dataIndex: "updated_datetime",
      key: "updated_datetime",
      width: "180px",
      align: "center",
      className: "table_text",
      render: (value, row) => {
        return (
          <div>
            <p style={{ marginBottom: 0 }}>
              {value && dayjs(value).isValid()
                ? dayjs(value).format("YYYY年MM月DD日 HH:mm")
                : ""}
            </p>
            <p>{row?.company?.company_name}</p>
          </div>
        )
      },
    },
    {
      title: t("Publish"),
      width: "115px",
      dataIndex: "release_flag",
      key: "publish",
      align: "center",
      className: "table_text",
      render: (value, row) => {
        const hasPublishAccess = row?.hasEditAccess
        return value ? (
          <CellWrapper>
            <Button
              iconType={"unlock"}
              btnText={t("Publish")}
              onClick={() => {
                releaseRecord(row?.id)
              }}
              disabled={!hasPublishAccess}
              className={!hasPublishAccess ? "no-pointer-events" : ""}
            />
          </CellWrapper>
        ) : (
          <CellWrapper>
            <Button
              iconType={"lock"}
              btnText={t("private")}
              onClick={() => releaseRecord(row?.id)}
              disabled={!hasPublishAccess}
              className={!hasPublishAccess ? "no-pointer-events" : ""}
              btnBg={"rgba(252, 217, 95, 0.20)"}
              borderColor={"#FCD95F"}
            />
          </CellWrapper>
        )
      },
    },
  ]

  const resetHandler = () => {
    setSearchEnabled(true)
    setSearchParams({
      facility_ids: null,
      from_date: "",
      to_date: "",
    })
    setPage(1)
  }

  const onSearch = () => {
    const values = {
      from_date: searchParams?.from_date
        ? dayjs(searchParams?.from_date).format("YYYY-MM-DD")
        : "",
      to_date: searchParams?.to_date
        ? dayjs(searchParams?.to_date).format("YYYY-MM-DD")
        : "",
      facility_ids: searchParams?.facility_ids,
    }
    const params = removeBlankAttributes(values)
    setSearchParams(params)
    setSearchEnabled(true)
  }

  const FACILITIES_OPTIONS = useMemo(
    () =>
      facilities?.map((facility) => ({
        label: facility?.facility_name,
        value: facility?.id,
      })),
    [facilities],
  )
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={t("facility activity management")}
        extra={
          <Button
            iconType={"plus-circle"}
            btnText={t("Register Activity")}
            type={"primary"}
            shape={"round"}
            size={"large"}
            onClick={() => router.push("/facility-activity-management/add")}
            disabled={!createAccess}
          />
        }
      />

      <UserActivityContent>
        <AccordionCard
          items={[
            {
              key: "1",
              label: t("Operation Options"),
              children: (
                <OperationOptionContainer>
                  <UserActivityListContent>
                    <div className={"search__content"}>
                      <LabelTextWithLeftArrow
                        label={t("Article Create facility")}
                        width={"145px"}
                      />
                      <SelectInput
                        width={"282px"}
                        name={"facility_ids"}
                        allowClear
                        options={FACILITIES_OPTIONS}
                        placeholder={t("All")}
                        value={searchParams?.facility_ids}
                        onChange={(value) =>
                          setSearchParams(() => {
                            return {
                              ...searchParams,
                              facility_ids: value,
                            }
                          })
                        }
                      />
                    </div>
                    <div className={"search__content"}>
                      <LabelTextWithLeftArrow
                        label={t("Register Date")}
                        width={"145px"}
                      />
                      <div className={"date_picker_content"}>
                        <DatePicker
                          style={{
                            width: "158px",
                          }}
                          date={
                            searchParams?.from_date
                              ? dayjs(searchParams?.from_date)
                              : null
                          }
                          onDateChange={(date) => {
                            setSearchParams({
                              ...searchParams,
                              from_date: dayjs(date),
                            })
                          }}
                          format={"YYYY年MM月DD日"}
                        />
                        <span className={"divider"}>{"~"}</span>
                        <DatePicker
                          style={{
                            width: "158px",
                          }}
                          onDateChange={(val) =>
                            setSearchParams({
                              ...searchParams,
                              to_date: dayjs(val),
                            })
                          }
                          date={
                            searchParams?.to_date
                              ? dayjs(searchParams?.to_date)
                              : null
                          }
                          format={"YYYY年MM月DD日"}
                        />
                      </div>
                    </div>
                    <div className={"search__content buttons"}>
                      <Button
                        btnText={t("Reset Search")}
                        size={"large"}
                        shape={"round"}
                        onClick={resetHandler}
                      />
                      <Button
                        btnText={t("Search")}
                        type={"primary"}
                        size={"large"}
                        shape={"round"}
                        iconType={"search"}
                        htmlType={"submit"}
                        onClick={() => onSearch()}
                      />
                    </div>
                  </UserActivityListContent>
                </OperationOptionContainer>
              ),
            },
          ]}
        />

        <Card title={t("Program Record")}>
          <TableWrapper>
            <Table
              dataSource={UserActivityListData?.data}
              columns={columns}
              total={UserActivityListData?.count}
              pageSize={pageSize}
              current={+page}
              onChange={handlePaginationChange}
              loading={isFetching || isLoading}
              showPaginationOf={"both"}
              scroll={{ x: 1200 }}
            />
          </TableWrapper>
        </Card>
      </UserActivityContent>
    </>
  )
}
