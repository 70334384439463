export const generateFacilityWork = (id: string) => ({
  id: id,
  work_title: "",
  term_war_material_inventory: null,
  fine_year_material_purchase_height: null,
  involved_in_terms_of_term: null,
  user_wage: null,
  user_fee: null,
  salary_of_employment_support_business_instructors: null,
  instructor_bonus_reward: null,
  instructor_retirement_benefit_cost: null,
  legal_welfare_expenses: null,
  formor_labor_cost: null,
  internal_outsourcing_cost: null,
  formor_outsourcing_cost: null,
  welfare_expense: null,
  travel_expense: null,
  packed_rent_cost: null,
  supplies_expense: null,
  rents: null,
  water_supply_expense: null,
  fuel_cost: null,
  repair_cost: null,
  communication_transportation_cost: null,
  meeting_expense: null,
  non_life_insurance_premium: null,
  books_and_educational_expenses: null,
  vehicle_cost: null,
  depreciation_expense: null,
  special_reserve_depreciation: null,
  miscellaneous_expenses: null,
  is_new: true,
})
