import { Flex, Typography } from "antd"
import React, { useEffect } from "react"
import { TabTopContent } from "../WagesRevenueCommon/TabTopContent"
import { useTranslation } from "react-i18next"
import { Button, Table, theme, useUpdateSearchParams } from "@project/common"
import dayjs from "dayjs"
import { ColumnsType } from "antd/lib/table"
import { EMPLOYMENT_SUPPORT_STATEMENT_TAB_ROWS } from "./constant"
import styled from "styled-components"
import { useQuery } from "react-query"
import { getAllFacilityEmploymentSupportBusinessStatement } from "../../../services"
import { AccountSubjectContent } from "../WagesRevenueCommon/AccountSubjectContent"
import { I_EMPLOYMENT_BUSINESS_STATEMENT } from "../../../types"
import {
  ESBSSharedCell,
  ebssRowsTotal,
  esbsGrandTotal,
  getColsFacilityColsTotal,
  handleFirstColSpaning,
  handleSecondColSpaning,
} from "./helpers"
import { useWagesRevenueContext } from "../../../context"
import { useRouter } from "next/router"

const StyledWrapper = styled(Flex)`
  .table_row_cell_gray {
    background-color: #ededed;
  }
`
export const EmploymentSupportBusinessStatementTabList = ({
  setSelectedColData,
  params,
  setSearchParams,
  hasEditAccess,
}: {
  setSelectedColData: (value: I_EMPLOYMENT_BUSINESS_STATEMENT) => void
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { panel } = router?.query as any
  const [updateParams] = useUpdateSearchParams()
  const { downloadAbleDataOf, setDownloadAbleDataOf } = useWagesRevenueContext()
  const dataSource = EMPLOYMENT_SUPPORT_STATEMENT_TAB_ROWS
  //FETCH DATA
  const { data, isLoading } = useQuery<any>({
    queryKey: ["wages-revenue-employment-support-business-statement", params],
    queryFn: () =>
      getAllFacilityEmploymentSupportBusinessStatement({
        year: dayjs().year(),
        month: dayjs().month() + 1,
      }),
  })

  const getDynamicColumns = (mode: "download" | "view") => {
    return (
      data?.msg?.map((item, index) => {
        return {
          key: index + 3,
          dataIndex: index + 3,
          title:
            mode === "download"
              ? item?.facility_name
              : () => (
                  <Flex vertical gap={8} align={"center"}>
                    <Typography.Text>
                      {item?.facility_name || ""}
                    </Typography.Text>
                    <Button
                      btnText={t("edit")}
                      iconType={"blue-pencil"}
                      shape={"round"}
                      onClick={() => setSelectedColData(item)}
                      disabled={!hasEditAccess(item?.facility_id)}
                    />
                  </Flex>
                ),

          render: (_, row) => {
            if (
              row?.key === "material_cost_meter_total" ||
              row?.key === "material_cost_total" ||
              row?.key === "labor_expenses_total" ||
              row?.key === "expenses_total" ||
              row?.key === "grand_total" ||
              row?.key === "project_expenses_total" ||
              row?.key === "manufacturing_cost" ||
              row?.key === "table_based_product_wholesaler" ||
              row?.key === "terminated_goods_wholesaler"
            ) {
              return ebssRowsTotal(item)[row?.key]
            }
            return item[row?.key]
          },
          onCell: (_, index) => {
            if (index === 0 || index === 6 || index === 14 || index === 16) {
              return {
                colSpan: 0,
              }
            }
            return {}
          },
        }
      }) || []
    )
  }

  const getMainColumns = (mode: "download" | "view") => {
    const columns: ColumnsType<any> = [
      {
        key: 1,
        align: "center",
        title: t("Accounting"),
        width: 20,
        colSpan: 2,
        dataIndex: "accounting",
        render: (_, row, index) => {
          const renderChildren = () => {
            if (
              index === 0 ||
              index === 3 ||
              index === 5 ||
              index === 6 ||
              index === 13 ||
              index === 14 ||
              index === 16
            ) {
              return mode === "download" ? (
                t(row?.title)
              ) : (
                <div>
                  {index === 0 ||
                  index === 6 ||
                  index === 14 ||
                  index === 16 ? (
                    <Typography.Text strong style={{ marginRight: "8px" }}>
                      {row?.sn}
                    </Typography.Text>
                  ) : (
                    ""
                  )}
                  <Typography.Text>{t(row?.title)}</Typography.Text>
                </div>
              )
            }
            if (index >= 37) {
              return mode === "download" ? (
                t(row?.title)
              ) : (
                <Typography.Text>{t(row?.title)}</Typography.Text>
              )
            }
            return `${row?.sn}.`
          }
          return {
            props: {
              style: {
                background:
                  index === 0 || index === 6 || index === 14 || index === 16
                    ? theme.colors.background
                    : index >= 37
                      ? "#EDEDED"
                      : "white",
                textAlign: "left",
              },
            },
            children: renderChildren(),
          }
        },
        onCell: (_, index) => handleFirstColSpaning(index, data?.msg?.length),
      },
      {
        key: 2,
        align: "center",
        title: t("sn"),
        colSpan: 0,
        width: 200,
        dataIndex: "title",
        render: (_, row) =>
          mode === "download" ? (
            row?.title
          ) : (
            <AccountSubjectContent
              label={row?.title || ""}
              minWidth={"250px"}
            />
          ),
        onCell: handleSecondColSpaning,
      },
      ...getDynamicColumns(mode),
      {
        key: 2,
        align: "center",
        title: t("Total"),
        width: 200,
        dataIndex: "total",
        render: (_, row) => {
          if (
            row?.key === "material_cost_meter_total" ||
            row?.key === "material_cost_total" ||
            row?.key === "labor_expenses_total" ||
            row?.key === "expenses_total" ||
            row?.key === "grand_total" ||
            row?.key === "project_expenses_total" ||
            row?.key === "manufacturing_cost" ||
            row?.key === "table_based_product_wholesaler" ||
            row?.key === "terminated_goods_wholesaler"
          ) {
            const totalData = esbsGrandTotal(data?.msg || [], row)
            return totalData?.[row?.key] || ""
          }
          const total = getColsFacilityColsTotal(data?.msg || [])
          return total[row?.key]
        },
        onCell: ESBSSharedCell,
      },
    ]
    return columns
  }
  useEffect(() => {
    setDownloadAbleDataOf({
      ...downloadAbleDataOf,
      tab: t("（４）Employment support business statement"),
      [panel]: {
        columns: getMainColumns("download"),
        dataSource: dataSource,
      },
    })
  }, [panel, data])
  return (
    <StyledWrapper vertical gap={16}>
      <TabTopContent
        tab={t("Employment support business statement")}
        onChange={(value) => {
          setSearchParams({
            ...params,
            date: dayjs(value).format("YYYY-MM"),
          })
          updateParams(
            {
              panel: "support",
              date: dayjs(value).format("YYYY-MM"),
            },
            "/wages-revenue",
          )
        }}
      />
      <Table
        columns={getMainColumns("view")}
        dataSource={dataSource || []}
        loading={isLoading}
        title={() => (
          <Flex
            vertical
            align={"center"}
            style={{ background: "#B1D5EA", padding: "8px" }}
            gap={8}
          >
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("Employment support business statement")}
            </Typography.Title>
            <Flex>
              <Typography.Text>
                {dayjs(params?.date).format("YYYY/MM/DD")}
              </Typography.Text>
              {"-"}
              <Typography.Text>
                {dayjs(params?.date)
                  .endOf("month")
                  .format("YYYY/MM/DD")}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        rowClassName={(_, index) => {
          if (index >= 37) {
            return "table_row_cell_gray"
          }
        }}
        scroll={{ x: 800 }}
      />
    </StyledWrapper>
  )
}
