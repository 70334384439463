import React from "react"

// packages
import { Flex } from "antd"
import { t } from "i18next"
import { useRouter } from "next/router"

// common components
import {
  Button,
  Card,
  StyledColGap,
  Table,
  filterObject,
  omit,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"

// types
import { ServiceProvOperation } from "../../../types/serviceRecordProvision"

// constants
import dayjs from "dayjs"
import { useQuery } from "react-query"
import { PAGE_SIZE } from "../../../constants"
import { getUserServiceProvisionRecordList } from "../../../services/UserProvisionRecord.services"
import { SP_COLUMNS } from "./ServiceProvListColumn"
import { ServiceProvOperationSection } from "./ServiceProvOperationSection"

// ! ## Main component
const ServiceProvInternal = () => {
  const { query } = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const listRef = React.useRef<HTMLDivElement>(null)

  // ! ## query params
  const memoizedQuery: ServiceProvOperation = React.useMemo(() => {
    const keyword = query?.keyword?.toString() || ""
    const user_id = +query?.user_id || null
    const facilityIds = query?.facilityIds?.toString().split(",") || []
    const service =
      query?.service
        ?.toString()
        .split(",")
        .map((item) => Number(item)) || []
    const display_types = (+query?.display_types as 1 | 2) || null
    const year = +query?.year || dayjs().year()
    const month = +query?.month || dayjs().month() + 1
    const page = query?.page?.toString() || "1"

    return {
      keyword,
      user_id,
      facilityIds,
      service,
      display_types,
      year,
      month,
      page, // [NOTE: We are tracking page number only from query params]
    }
  }, [query])

  // ! ## API query
  // 1. Fetching All Service record provision data based on query params
  const { data: recordList, isLoading } = useQuery(
    ["service-provision-record", memoizedQuery],
    () => getUserServiceProvisionRecordList(memoizedQuery),
    {
      keepPreviousData: false,
      select: (response: any) => {
        return {
          count: response?.count,
          data: response?.data?.map((d) => {
            let applicable_period = ""
            if (d?.applicable_start_date) {
              if (dayjs(d?.applicable_start_date).isValid()) {
                applicable_period = dayjs(d?.applicable_start_date).format(
                  "YYYY年MM月DD日",
                )

                if (dayjs(d?.applicable_end_date).isValid()) {
                  applicable_period +=
                    " ~ " +
                    dayjs(d?.applicable_end_date).format("YYYY年MM月DD日")
                }
              }
            }
            return {
              id: d?.user_id,
              key: d?.user_id,
              recipient_number: d?.recipient_number,
              user_name: d?.user_name,
              benefit_payment_amount: d?.benefit_payment_amount,
              total_contract_amount: d?.total_contract_benefit_amount,
              gender:
                d?.gender == "M"
                  ? t("Male")
                  : d?.gender == "F"
                    ? t("Female")
                    : "",
              age: d?.age,
              applicable_period,
              detailLink: `/user-service-record/details/${d?.user_id}?year=${memoizedQuery.year}&month=${memoizedQuery.month}`,
            }
          }),
        }
      },
    },
  )

  // ! ## event action function
  // 1. Handle pagination change
  const onPaginationChange = (page: string) => {
    updateParams({ ...query, page }, "/user-service-record")
    scrollToSelectedElement(listRef)
  }
  // 2. handle operation form submission
  const onOperationFormSubmit = (values: Partial<ServiceProvOperation>) => {
    const params = removeBlankAttributes({
      ...values,
      facilityIds: values.facilityIds.toString(),
      service: values.service.toString(),
      page: "1",
    })
    updateParams({ ...params }, "/user-service-record")
    scrollToSelectedElement(listRef)
  }
  // 3. handle operation form reset
  const onOperationFormReset = () => {
    updateParams({}, "/user-service-record")
    scrollToSelectedElement(listRef)
  }

  return (
    <StyledColGap>
      {/* Form operation section starts here */}
      <ServiceProvOperationSection
        memoizedQuery={memoizedQuery}
        onOperationFormSubmit={onOperationFormSubmit}
        onOperationFormReset={onOperationFormReset}
      />
      {/* Form operation section ends here */}

      {/* MM list starts here */}
      <div ref={listRef}>
        <Card title={t("List")}>
          <Table
            loading={isLoading}
            pageSize={PAGE_SIZE}
            total={recordList?.count || 0}
            showPaginationOf={"both"}
            dataSource={recordList?.data || []}
            scroll={{ x: "max-content" }}
            columns={SP_COLUMNS}
            current={query?.page ? +query?.page : 1}
            onChange={(val) => onPaginationChange(val)}
            tableAttributesExtra={
              <Flex align={"center"}>
                <Button
                  shape={"round"}
                  iconType={"bulk-print"}
                  btnText={t("Bulk Print")}
                  onClick={() => {
                    updateParams(
                      omit(
                        filterObject({
                          ...query,
                          display_users_without_achievement:
                            query?.display_types,
                        }),
                        ["display_types"],
                      ),
                      "/user-service-record/provision-result",
                    )
                  }}
                />
              </Flex>
            }
          />
        </Card>
      </div>
    </StyledColGap>
  )
}

export { ServiceProvInternal }
