import { Form } from "antd"
import { FormProps } from "antd/lib"
import dayjs from "dayjs"
import { t } from "i18next"
import { useRouter } from "next/router"
import React from "react"

import {
  AccordionCard,
  Box,
  Button,
  DatePicker,
  SelectInput,
  filterObject,
  removeBlankAttributes,
  useUpdateSearchParams,
} from "@project/common"
import { UserBenefitsList } from "./UserBenefitsList"
import { UserBenefitsContainer } from "./styles"

import { useAuthContext } from "../../../../context"

import { DisabledUserBenefitsOperation } from "../../../../types/nhif.types"

import { OperationFields } from "../../../ServiceProvisionRecord/common/OperationFields"

import { MONTH_DATE_FORMAT } from "../../../../constants"

export const DisabledUserBenefitsInternal = () => {
  const today = dayjs()

  const { query, push } = useRouter()
  const { year_month, facilityIds, default_facility } = query as any
  const { facilities: facilitiesCtx } = useAuthContext()

  const [form] = Form.useForm<DisabledUserBenefitsOperation>()
  const yearMonthValue = Form.useWatch("year_month", form)

  const [updateParams] = useUpdateSearchParams()

  const memoizedQuery: DisabledUserBenefitsOperation = React.useMemo(() => {
    const facilityIds = query?.facilityIds ? query.facilityIds.toString() : null
    const year_month = query?.year_month
      ? query?.year_month?.toString()
      : today.format("YYYY-MM")

    return {
      facilityIds,
      year_month,
    }
  }, [query])

  const onFinish: FormProps<DisabledUserBenefitsOperation>["onFinish"] = (
    values,
  ) => {
    updateParams(
      filterObject(values),
      `/national-health-insurance-billing/prints/disabled-user-outpatient-benefits`,
    )
  }

  const onReset = () => {
    form.setFieldsValue({
      facilityIds: null,
      year_month: today.format("YYYY-MM"),
    })
    updateParams(
      {},
      `/national-health-insurance-billing/prints/disabled-user-outpatient-benefits/`,
    )
  }

  return (
    <UserBenefitsContainer>
      <AccordionCard
        defaultActiveKey={["disabled-user-benefits"]}
        items={[
          {
            key: "disabled-user-benefits",
            label: t("Operation Options"),
            children: (
              <Form
                form={form}
                onFinish={onFinish}
                name={"control-hooks"}
                initialValues={memoizedQuery}
              >
                <Box display={"flex"} direction={"column"} gap={"16px"}>
                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    label={"Facility"}
                    labelWidth={"150px"}
                    align={{ "0px": "flex-start", "550px": "center" }}
                    direction={{
                      "0px": "column",
                      "550px": "row",
                    }}
                  >
                    <Box w={282} maw={282} miw={135} className={"input-field"}>
                      <Form.Item name={"facilityIds"} id={"facilityIds"}>
                        <SelectInput
                          name={"facilityIds"}
                          options={facilitiesCtx}
                          placeholder={"---"}
                        />
                      </Form.Item>
                    </Box>
                  </OperationFields>

                  <OperationFields
                    wrap={"wrap"}
                    gap={"10px 24px"}
                    label={"Year Month"}
                    labelWidth={"150px"}
                    align={{ "0px": "flex-start", "550px": "center" }}
                    direction={{
                      "0px": "column",
                      "550px": "row",
                    }}
                  >
                    <Box w={197} miw={197} maw={197}>
                      <Form.Item name={"year_month"} id={"year_month"}>
                        <DatePicker
                          picker={"month"}
                          date={dayjs(yearMonthValue)}
                          onDateChange={(date) =>
                            form.setFieldValue(
                              "year_month",
                              date?.format("YYYY-MM"),
                            )
                          }
                          format={MONTH_DATE_FORMAT}
                        />
                      </Form.Item>
                    </Box>
                  </OperationFields>
                </Box>

                <Box display={"flex"} gap={12} mt={16} mb={8}>
                  <Button
                    shape={"round"}
                    btnText={t("Reset Search")}
                    onClick={() => onReset()}
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    btnText={t("Search")}
                    htmlType={"submit"}
                    iconType={"search"}
                  />
                </Box>
              </Form>
            ),
          },
        ]}
      />

      <Button
        btnText={t("<< Back")}
        shape={"round"}
        onClick={() => {
          push({
            pathname: "/national-health-insurance-billing",
            query: removeBlankAttributes({
              year: year_month?.split("-")[0] || dayjs().year(),
              month: year_month?.split("-")[1] || dayjs().month() + 1,
              facility: facilityIds,
              default_facility,
            }),
          })
        }}
      />
      <UserBenefitsList memoizedQuery={memoizedQuery} />
      <Button
        btnText={t("<< Back")}
        shape={"round"}
        onClick={() => {
          push({
            pathname: "/national-health-insurance-billing",
            query: removeBlankAttributes({
              year: year_month?.split("-")[0] || dayjs().year(),
              month: year_month?.split("-")[1] || dayjs().month() + 1,
              facility: facilityIds,
              default_facility,
            }),
          })
        }}
      />
    </UserBenefitsContainer>
  )
}
