import { ActionBtns, Button, Card, InputField, Table } from "@project/common"
import { ColumnsType } from "antd/es/table"
import { useRouter } from "next/router"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Container } from "./styles"
import dayjs from "dayjs"
import { UseServiceType } from "../../../components"
import { useMutation } from "react-query"
import { bulkUpdateActualCostBurdenUserRecord } from "../../../services"
import { notification } from "antd"

export const ActualCostBurdenRecordBulkEdit = ({
  defaultDataSource,
}: {
  defaultDataSource: any
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { id, date, facility } = router.query as any
  const [dataSource, setDataSource] = useState<any[]>(
    defaultDataSource?.burden_user_records || [],
  )

  const handleChange = (
    key: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value
    const maxLength = 10
    if (inputValue.length > maxLength) {
      const truncatedValue = inputValue.slice(0, maxLength)
      e.target.value = truncatedValue
    }
    setDataSource((prev: any[]) => {
      return prev.map((item: any) => {
        if (key === item?.id) {
          return {
            ...item,
            cost: +e.target.value,
            error: +e.target.value >= 1 ? null : "required",
          }
        }

        return item
      })
    })
  }

  const columns: ColumnsType<any[]> = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      align: "center",
      render: () => {
        return <span>{date ? dayjs(date).format("YYYY年MM月DD日") : ""}</span>
      },
    },
    {
      title: t("facility name"),
      dataIndex: "facility_name",
      key: "facility_name",
      align: "center",
      render: () => {
        return <span>{defaultDataSource?.facility?.facility_name || ""}</span>
      },
    },
    {
      title: t(" Username"),
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      render: (_, data: any) => {
        return <span>{t(data?.user?.username)}</span>
      },
    },
    {
      title: t("Use Service"),
      dataIndex: "use_service",
      key: "use_service",
      align: "center",
      render: (_, data: any) => {
        return (
          <div className={"service_type_btns"}>
            <UseServiceType
              type={data?.user?.user_certificate?.service_type || null}
            />
          </div>
        )
      },
    },
    {
      title: () => {
        return (
          <>
            <span>{t("Cost")}</span> <span className={"required"}>{"*"}</span>
          </>
        )
      },
      dataIndex: "cost",
      key: "cost",
      align: "center",
      render: (_, data: any) => {
        return (
          <div className={"input_container"}>
            <div className={"error_container"}>
              <InputField
                height={"40px"}
                name={"po_box_no"}
                value={data?.cost}
                type={"number"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(data?.id, e)
                }
                min={0}
                placeholder={t("0")}
              />
              {data?.error ? <span id={"error"}>{data?.error}</span> : null}
            </div>
            <span>{t("Yen")}</span>
          </div>
        )
      },
    },
  ]

  //bulk edit
  const { mutate, isLoading } = useMutation({
    mutationFn: bulkUpdateActualCostBurdenUserRecord,
    onSuccess: () => {
      notification.success({
        message: `${t("Updated Successfully")}`,
      })
      router.push(
        `/actual-cost-burden-record/list/details/${id}?facility=${facility}&date=${date}`,
      )
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const handleSubmit = () => {
    setDataSource((prev: any[]) => {
      return prev.map((item: any) => {
        if (item?.cost < 1) {
          return {
            ...item,
            error: "required",
          }
        }
        return item
      })
    })
    const errorElement = document.getElementById("error")
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth" })
    }
    if (
      !dataSource.some((item) => item.error === "required" || item?.cost === 0)
    ) {
      const data = dataSource?.map((item) => ({
        burden_record_id: item?.id,
        user_id: item?.user_id,
        cost: +item?.cost || null,
      }))
      mutate(data)
    }
  }

  return (
    <Card title={t("Bulk Edit Actual Cost Burden Record Table")}>
      <Container>
        <Table
          columns={columns}
          dataSource={dataSource}
          headerBorderRadius={0}
        />
        <ActionBtns>
          <div
            style={{
              display: "flex",
              gap: "1em",
              flexWrap: "wrap",
              marginTop: "16px",
            }}
          >
            <Button
              btnText={t("Cancel")}
              shape={"round"}
              onClick={() =>
                router.push(
                  `/actual-cost-burden-record/list/details/${id}?facility=${facility}&date=${date}`,
                )
              }
              disabled={isLoading}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              type={"primary"}
              onClick={() => handleSubmit()}
              isLoading={isLoading}
            />
          </div>
        </ActionBtns>
      </Container>
    </Card>
  )
}
