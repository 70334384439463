import styled from "styled-components"

export const TableContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 18px;
  flex-wrap: wrap;
`
export const TMiniContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
