import React from "react"
import Image from "next/image"
import { Collapse, Flex } from "antd"

import { DownArrow } from "@project/common"
import { AlertBoxV2Styled } from "./styles"

import { AlertBoxV2Props } from "../../../types/nhif.types"

const AlertBoxV2 = ({
  heading,
  description,
  theme = "error",
  children,
  className,
  ...props
}: AlertBoxV2Props) => {
  return (
    <AlertBoxV2Styled $theme={theme} className={className}>
      {heading && (
        <h3 className={"heading"}>
          <Flex gap={10} align={"center"} className={"prefix-icon"}>
            <Image
              src={"/assets/icons/alert.svg"}
              className={"alert-image"}
              height={20}
              width={20}
            />
          </Flex>
          {heading}
        </h3>
      )}

      {description && <div className={"description"}>{description}</div>}

      {props.variant === "WITH_COLLAPSE" && (
        <Collapse
          className={"unpaid-user-listing-collapse"}
          defaultActiveKey={["1"]}
          ghost
          expandIcon={({ isActive }) =>
            !props.prefixIcon ? null : (
              <Flex gap={10} align={"center"} className={"alert-image"}>
                {!props.alertIcon ? null : (
                  <Image
                    src={"/assets/icons/alert.svg"}
                    height={20}
                    width={20}
                    className={"alert-icon"}
                  />
                )}
                {!props.arrowDownIcon ? null : (
                  <div
                    className={`down-arrow-container ${
                      isActive ? "active" : ""
                    }`}
                  >
                    <DownArrow />
                  </div>
                )}
              </Flex>
            )
          }
          items={props.item}
        />
      )}
      {children}
    </AlertBoxV2Styled>
  )
}

export default AlertBoxV2
