import React from "react"
import { useTranslation } from "react-i18next"
import { downloadImage } from "../../../../utils/utility-function"

const EmploymentContactTable = ({ response }) => {
  const { t } = useTranslation()
  return (
    <>
      <table>
        <tr className={"sixth-table"}>
          <td className={"label"}>{t("Employment Contract")}</td>
          <td className={"value link"}>
            {response?.contract_doc ? (
              <a
                href={"javascript: void(0)"}
                onClick={() => downloadImage(response?.contract_doc)}
              >
                {t("Download")}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>
      </table>
    </>
  )
}

export default EmploymentContactTable
