import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import {
  Button,
  ConfirmationModal,
  STAFF_ABSENT,
  STAFF_PRESENT,
  Table,
  theme,
} from "@project/common"
import { Flex, Spin, Typography, notification } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  createOrUpdateAttendanceStaffResult,
  removeAttendanceStaffResult,
} from "../../../services"
import { FULL_DATE_FORMAT_EN } from "../../../constants"
import { styled } from "styled-components"

const Container = styled.div`
  .direct_edit {
    min-width: 100px;
  }
  .leaving_time {
    min-width: 100px;
  }
  .attendance_time {
    min-width: 100px;
  }
  .break_time {
    min-width: 100px;
  }
  .remarks {
    min-width: 100px;
  }
  .delete {
    min-width: 100px;
  }
  .vacation_time {
    min-width: 100px;
  }
  .staff_name {
    min-width: 120px;
  }
  .vacation-scheduled {
    background: ${theme.colors["error-light"]};
  }
`

export const StaffDailyAttendanceRecordTable = ({
  dataSource,
  onDataSourceChange,
  resetDataSource,
  loading,
}: {
  dataSource: any[]
  onDataSourceChange: (values: { key: string; value: any; id: number }) => void
  resetDataSource: () => void
  loading?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const getTime = () => {
    const date = dayjs()
    const dateHour = date.hour()
    const dateMinute = date.minute()
    const time = `${dateHour < 10 ? "0" + dateHour : dateHour}:${
      dateMinute < 10 ? "0" + dateMinute : dateMinute
    }`
    return time
  }
  //create/update
  const { isLoading, mutate: updateAttendanceStaffResult } = useMutation({
    mutationFn: createOrUpdateAttendanceStaffResult,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })
      resetDataSource()
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  //delete data
  const { isLoading: isDeleting, mutate: deleteAttendanceRecord } = useMutation(
    {
      mutationFn: removeAttendanceStaffResult,
      onSuccess: () => {
        notification.success({
          message: t("Delete Successfully"),
        })
        setOpenDeleteModal(false)
        setSelectedItem(null)
        resetDataSource()
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )
  const updateWorkInformation = ({
    row,
    attendanceType,
    key,
    typeKey,
  }: {
    row: any
    attendanceType: any
    key?: string
    typeKey?: string
  }) => {
    const time = getTime()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tempId, add_work_flag2, staff_name, facility_name, ...rest } = row
    const data = {
      ...rest,
      date: dayjs(rest?.date).format(FULL_DATE_FORMAT_EN),
      [typeKey]: attendanceType,
      [key]: attendanceType == STAFF_PRESENT ? time : null,
    }
    updateAttendanceStaffResult([data])
  }
  const getActionBtns = (row: any, key: string, typeKey: string) => {
    return (
      <Flex gap={4} justify={"center"}>
        <Button
          btnText={t("Attend")}
          btnBg={theme.colors.success}
          textColor={theme.colors.white}
          borderColor={theme.colors.success}
          hoverTextColor={theme.colors.white}
          width={"fit-content"}
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            updateWorkInformation({
              row: row,
              attendanceType: STAFF_PRESENT,
              typeKey,
              key,
            })
          }}
        />
        <Button
          btnText={t("Absence")}
          btnBg={theme.colors.error}
          textColor={theme.colors.white}
          borderColor={theme.colors.error}
          hoverTextColor={theme.colors.white}
          width={"fit-content"}
          style={{ whiteSpace: "nowrap" }}
          onClick={() =>
            updateWorkInformation({
              row: row,
              attendanceType: STAFF_ABSENT,
              typeKey,
              key,
            })
          }
        />
      </Flex>
    )
  }

  const columns: ColumnsType<any[]> = [
    {
      key: 1,
      title: "",
      render: (_, __, index) => index + 1,
    },
    {
      key: 2,
      title: t("Staff name"),
      align: "center",
      className: "staff_name",
      render: (row) => (
        <Link href={`/staff-master/edit/${row?.staff_id}`}>
          {row?.staff_name || ""}
        </Link>
      ),
    },
    {
      key: 3,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Facility  ")}</span>,
      render: (row) => row?.facility_name || "",
      align: "center",
    },
    {
      key: 4,
      title: <span style={{ whiteSpace: "nowrap" }}> {t("Direct edit")}</span>,
      width: 95,
      align: "center",
      className: "direct_edit",
      render: (row) =>
        row?.staff_id ? (
          <Button
            btnText={t("Edit")}
            type={"link"}
            style={{
              textDecoration: "underline",
              whiteSpace: "nowrap",
            }}
            onClick={() =>
              router?.push(
                `/attendance-record/day-list/edit/${row?.id}?facility_id=${row?.facility_id}&staff_id=${row?.staff_id}&date=${router?.query?.date}`,
              )
            }
          />
        ) : (
          ""
        ),
    },
    {
      key: 5,
      title: t("Work Information 1"),
      align: "center",
      children: [
        {
          key: "5.1",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("Attendance time")}</span>
          ),
          width: 130,
          align: "center",
          className: "attendance_time",

          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 6 }
            }
            return {}
          },
          render: (row) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return (
                <Flex
                  justify={"center"}
                  style={{
                    color: theme.colors.error,
                  }}
                >
                  {t("Absent")}
                </Flex>
              )
            }
            return (
              <Flex justify={"center"}>
                {(!row?.id && !row?.attendance_type) ||
                (!row?.attendance_start_time && !row.attendance_end_time) ? (
                  getActionBtns(row, "attendance_start_time", "attendance_type")
                ) : (
                  <Typography.Text>
                    {row?.attendance_start_time || ""}
                  </Typography.Text>
                )}
              </Flex>
            )
          },
        },
        {
          key: "5.2",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("LeavingTime1")}</span>
          ),
          align: "center",
          className: "leaving_time",

          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 0 }
            }
            return {}
          },
          render: (row) => {
            if (row.attendance_type === STAFF_ABSENT || !row.attendance_type) {
              return ""
            }
            if (row.attendance_start_time && !row.attendance_end_time) {
              return (
                <Button
                  btnText={t("leaving work")}
                  btnBg={theme.colors.info}
                  textColor={theme.colors.white}
                  borderColor={theme.colors.info}
                  hoverTextColor={theme.colors.white}
                  width={"fit-content"}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  onClick={() =>
                    updateWorkInformation({
                      row,
                      attendanceType: STAFF_PRESENT,
                      typeKey: "attendance_type",
                      key: "attendance_end_time",
                    })
                  }
                />
              )
            }
            return <Flex justify={"center"}>{row?.attendance_end_time}</Flex>
          },
        },
        {
          key: "5.3",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("Break Time")}</span>
          ),
          width: 128,
          align: "center",
          className: "break_time",

          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 0 }
            }
            return {}
          },
          render: (row) => {
            if (row.attendance_type === STAFF_ABSENT || !row.attendance_type) {
              return null
            }
            return (
              <Flex justify={"center"}>
                {row?.attendance_rest_minits
                  ? `${row?.attendance_rest_minits} ${t("minutes")}`
                  : ""}
              </Flex>
            )
          },
        },
      ],
    },
    {
      key: 6,
      title: t("Work Information 2"),
      align: "center",

      children: [
        {
          key: "6.1",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("Attendance time")}</span>
          ),
          width: 128,
          align: "center",
          className: "attendance_time",
          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 0 }
            }
            if (
              !row.attendance_type2 ||
              row.attendance_type2 === STAFF_ABSENT
            ) {
              return { colSpan: 3 }
            }
            return {}
          },
          render: (row) => {
            if (
              row.attendance_type === STAFF_PRESENT &&
              !row.attendance_type2
            ) {
              if (row?.add_work_flag2) {
                return (
                  <Flex justify={"center"}>
                    <Button
                      btnText={t("Add working information 2")}
                      btnBg={theme.colors.primary}
                      borderColor={theme.colors.primary}
                      onClick={() =>
                        onDataSourceChange({
                          id: row?.tempId,
                          key: "add_work_flag2",
                          value: false,
                        })
                      }
                    />
                  </Flex>
                )
              }
              return getActionBtns(
                row,
                "attendance_start_time2",
                "attendance_type2",
              )
            }
            if (row?.attendance_type2 === STAFF_ABSENT) {
              return <Flex>{t("Absent")}</Flex>
            }
            if (row?.attendance_type2 === STAFF_PRESENT) {
              return (
                <Flex justify={"center"}>
                  {row?.attendance_start_time2 || ""}
                </Flex>
              )
            }
          },
        },
        {
          key: "6.2",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("Leaving Time")}</span>
          ),
          width: 128,
          align: "center",
          className: "leaving_time",
          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 0 }
            }
            if (
              !row.attendance_type2 ||
              row.attendance_type2 === STAFF_ABSENT
            ) {
              return { colSpan: 0 }
            }
            return {}
          },
          render: (row) => {
            if (
              !row.attendance_type2 ||
              row.attendance_type2 === STAFF_ABSENT
            ) {
              return ""
            }
            if (
              row.attendance_type === STAFF_PRESENT &&
              row.attendance_start_time2 &&
              !row.attendance_end_time2
            ) {
              return (
                <Button
                  btnText={t("leaving work")}
                  btnBg={theme.colors.info}
                  textColor={theme.colors.white}
                  borderColor={theme.colors.info}
                  hoverTextColor={theme.colors.white}
                  width={"fit-content"}
                  onClick={() =>
                    updateWorkInformation({
                      row,
                      attendanceType: STAFF_PRESENT,
                      typeKey: "attendance_type2",
                      key: "attendance_end_time2",
                    })
                  }
                />
              )
            }
            return <Flex justify={"center"}>{row?.attendance_end_time2}</Flex>
          },
        },
        {
          key: "6.3",
          title: (
            <span style={{ whiteSpace: "nowrap" }}>{t("Break Time")}</span>
          ),
          width: 128,
          align: "center",
          className: "break_time",

          onCell: (row: any) => {
            if (row?.attendance_type === STAFF_ABSENT) {
              return { colSpan: 0 }
            }
            if (
              !row.attendance_type2 ||
              row.attendance_type2 === STAFF_ABSENT
            ) {
              return { colSpan: 0 }
            }
            return {}
          },
          render: (row) => {
            if (
              !row.attendance_type2 ||
              row.attendance_type2 === STAFF_ABSENT
            ) {
              return ""
            }
            return (
              <Flex justify={"center"}>
                {row?.attendance_rest_minits2
                  ? `${row?.attendance_rest_minits2} ${t("minutes")}`
                  : ""}
              </Flex>
            )
          },
        },
      ],
    },
    {
      key: 7,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Vacation Time")}</span>,
      align: "center",
      className: "vacation_time",
      render: (row) => {
        return (
          <Flex justify={"center"}>
            {row?.rest_hours ? `${row?.rest_hours}  ${t("minutes")}` : ""}
          </Flex>
        )
      },
    },
    {
      key: 8,
      title: t("Remarks"),
      width: 100,
      align: "center",
      className: "remarks",

      render: (row) => {
        return <Flex justify={"center"}>{row?.remarks || ""}</Flex>
      },
    },
    {
      key: 9,
      title: t("day_list_delete"),
      width: 100,
      align: "center",
      className: "delete",
      render: (row) => {
        const isDisabled = row?.id ? false : true
        return {
          props: {
            style: { whiteSpace: "nowrap" },
          },
          children: !isDisabled ? (
            <Button
              btnText={t("Delete")}
              type={"link"}
              textColor={theme.colors.error}
              style={{
                textDecoration: "underline",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                setSelectedItem(row)
                setOpenDeleteModal(true)
              }}
            />
          ) : (
            ""
          ),
        }
      },
    },
  ]

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource || []}
          title={() => (
            <Flex
              justify={"center"}
              style={{
                background: theme.colors.background,
                padding: "10px",
              }}
            >
              {t("Attendance Instructor List")}
            </Flex>
          )}
          scroll={{ x: 900 }}
          rowClassName={(row) =>
            row?.is_vacation_scheduled ? "vacation-scheduled" : ""
          }
        />
      </Spin>
      {openDeleteModal && (
        <ConfirmationModal
          open={openDeleteModal}
          isLoading={isDeleting}
          title={t("Delete")}
          msg={t("Are you sure you want the reset attendance information?")}
          onCancel={() => {
            setOpenDeleteModal(false)
            setSelectedItem(null)
          }}
          onOk={() =>
            deleteAttendanceRecord({
              facility_id: selectedItem?.facility_id,
              staff_id: selectedItem?.staff_id,
              date: router?.query?.date,
            })
          }
        />
      )}
    </Container>
  )
}
