import { theme } from "@project/common"
import styled from "styled-components"

export const CardWrapper = styled.div`
  .ant-card-head {
    border-bottom: none !important;
  }
  .ant-card-body {
    padding: 0px 20px !important;
  }
  .ant-divider {
    background-color: #d2d1d1;
    margin: 0px 0px 15px 0px;
  }
`
export const BreakTitle = styled.div<any>`
  margin-bottom: 1em;
  padding: 2em 0 1em 0;
  border: 1px solid ${theme.colors.border};
  border-left: 0;
  border-right: 0;
  border-top: none !important;

  .break_line {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`
export const ButtonWrapper = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 540px) {
    align-items: end;
    flex-direction: column;
    row-gap: 10px;
  }
  .save-cancel {
    display: flex;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 540px) {
      display: flex;
      order: 2;
    }
  }
  .reset-button {
    @media screen and (max-width: 540px) {
      display: flex;
      order: 1;
    }
  }
`
