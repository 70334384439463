// packages
import dayjs from "dayjs"

// types
import { omit } from "@project/common"
import {
  PartialNullish,
  UserDailyTransport,
} from "../../../services/user-transport-mgmt.services"
import {
  DeletedTransportItemsStore,
  DropOffData,
  GenericDriverStaff,
  PickupData,
} from "../types"
type SanitizeTransportDataFn = (
  obj: {
    date: string
    deletedItemsStore: DeletedTransportItemsStore
  } & (
    | {
        values: DropOffData
        type: "dropOff"
      }
    | {
        values: PickupData
        type: "pickup"
      }
  ),
) => Partial<UserDailyTransport>

// constants
const UNWANTED_KEYS_FOR_NEW_STAFF = ["id", "edit", "isNew", "key"]
const UNWANTED_KEYS_FOR_OLD_STAFF = [
  "driver_staff",
  "escort_staff",
  "car",
  "facility",
]

type OldUnwantedKeys = (typeof UNWANTED_KEYS_FOR_OLD_STAFF)[number]
type NewUnwantedKeys = (typeof UNWANTED_KEYS_FOR_NEW_STAFF)[number]

/**
 *
 * User can add new item/row and delete it before saving it to backend
 * when deleted newly created row gets added to `deletedItemStorage`.
 * We wanna filter out such items.
 *
 * This is where this function comes
 */
export const removeNewRows = (items: DeletedTransportItemsStore) => {
  return {
    drop_staffs: items.drop_staffs
      .filter((staff) => !staff.isNew)
      .map((staff) => omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])),
    pickup_staffs: items.pickup_staffs
      .filter((staff) => !staff.isNew)
      .map((staff) => omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF])),
  }
}

/**
 *
 * This function simply removed unwanted attributes from the data
 * that we don't want to send back to backend which would only increase load.
 *
 */
export const filterStaffData = (
  staffsData: PartialNullish<GenericDriverStaff>[],
  currentDate: string,
): Omit<
  PartialNullish<GenericDriverStaff>,
  OldUnwantedKeys | NewUnwantedKeys
>[] => {
  const sanitizedPickupStaffs = staffsData.map((staff) => {
    // If its newly created staff Data we don't wanna send in mentioned attributes to the backend,
    // as these `attrib` are used here in front-end side for different purpose
    // Hence we remove it from the actual Payload.
    if (staff.isNew) {
      return {
        ...omit(staff, [...UNWANTED_KEYS_FOR_NEW_STAFF]),
        facility_id: +staff.facility_id,
        date: currentDate,
      }
    }

    // Similarly if its the old staff data then there are few attribs that we wanna avoid sending it as payload.
    // As it might cause unpredicted issues just in case.
    return {
      ...omit(staff, [...UNWANTED_KEYS_FOR_OLD_STAFF]),
      facility_id: +staff.facility_id,
    }
  })
  return sanitizedPickupStaffs
}

export const sanitizeTransportData: SanitizeTransportDataFn = (props) => {
  const currentDate = dayjs(props.date as string).format("YYYY-MM-DD")
  const filteredDeletedItems = removeNewRows(props.deletedItemsStore)

  if (props.type === "pickup") {
    const clonedValues: PickupData = {
      ...props.values,
      pickup_staffs: [
        ...filterStaffData(props.values?.pickup_staffs, currentDate),
        ...filteredDeletedItems.pickup_staffs,
      ],
    }

    return clonedValues
  }

  // Same Goes here but for dropOff data...
  const clonedValues: DropOffData = {
    ...props.values,
    drop_staffs: [
      ...filterStaffData(props.values?.drop_staffs, currentDate),
      ...filteredDeletedItems.drop_staffs,
    ],
  }
  return clonedValues
}

export const sanitizeTimingValues = (
  attribs: {
    timingVariant: "pickup" | "arrival" | "start" | "end"
    index: number
  } & (
    | {
        values: DropOffData
        type: "dropOff"
      }
    | {
        values: PickupData
        type: "pickup"
      }
  ),
) => {
  if (attribs.type === "pickup") {
    const clonedValues: PickupData = JSON.parse(JSON.stringify(attribs.values))
    if (
      attribs.timingVariant === "pickup" ||
      attribs.timingVariant === "arrival"
    ) {
      const currentObj = clonedValues.pickup_users[attribs.index]
      const reducedObj = Object.keys(currentObj).reduce((acc, currKey) => {
        if (UNWANTED_KEYS_FOR_OLD_STAFF.includes(currKey)) {
          return { ...acc }
        }

        return {
          ...acc,
          [currKey]: currentObj[currKey],
        }
      }, {})

      return {
        pickup_users: [reducedObj],
      }
    }

    if (attribs.timingVariant === "start" || attribs.timingVariant === "end") {
      const currentStaffVal = clonedValues.pickup_staffs[attribs.index]
      const reducedStaffVal = Object.keys(currentStaffVal).reduce(
        (acc, currKey) => {
          if (UNWANTED_KEYS_FOR_OLD_STAFF.includes(currKey)) {
            return { ...acc }
          }

          return {
            ...acc,
            [currKey]: currentStaffVal[currKey],
          }
        },
        {},
      )
      return {
        pickup_staffs: [reducedStaffVal],
      }
    }
    return
  }

  const clonedValues: DropOffData = JSON.parse(JSON.stringify(attribs.values))
  if (
    attribs.timingVariant === "pickup" ||
    attribs.timingVariant === "arrival"
  ) {
    const currentObj = clonedValues.drop_users[attribs.index]
    const reducedObj = Object.keys(currentObj).reduce((acc, currKey) => {
      if (UNWANTED_KEYS_FOR_OLD_STAFF.includes(currKey)) {
        return { ...acc }
      }

      return {
        ...acc,
        [currKey]: currentObj[currKey],
      }
    }, {})

    return {
      drop_users: [reducedObj],
    }
  }

  if (attribs.timingVariant === "start" || attribs.timingVariant === "end") {
    const currentStaffVal = clonedValues.drop_staffs[attribs.index]
    const reducedStaffVal = Object.keys(currentStaffVal).reduce(
      (acc, currKey) => {
        if (UNWANTED_KEYS_FOR_OLD_STAFF.includes(currKey)) {
          return { ...acc }
        }

        return {
          ...acc,
          [currKey]: currentStaffVal[currKey],
        }
      },
      {},
    )
    return {
      drop_staffs: [reducedStaffVal],
    }
  }
}
