// packages
import dayjs from "dayjs"
import { t } from "i18next"

// types
import {
  ReTraceDataFn,
  UserTransportMgmtTableColumnDataType,
} from "../../../types/UserTransportMgmt.types"

/**
 *
 * @param apiRespData Response data coming from the backend [Assuming the data format will be same as that of our child easyy]
 * @param facility_ids All selected facility ids in operation filter section.
 * @param currentYearMonth
 * @description Response data that comes from api will be remapped here in such a way
 * that our table component becomes happy and display our data properly.
 * @returns remapped/retraced api-response-data.
 */
export const reTraceData: ReTraceDataFn = (
  apiRespData,
  facility_ids,
  currentYearMonth,
) => {
  const apiDataClone = [...apiRespData]
  const dayInMonth = currentYearMonth.daysInMonth()

  const formattedData = Array.from({
    length: dayInMonth,
  }).map((_, idx) => {
    const currentDayOfMonth = idx + 1 + ""
    const currentDate = currentYearMonth.format(
      `YYYY-MM-${currentDayOfMonth.padStart(2, "0")}`,
    )
    const dayOfWeek = dayjs(currentDate).isValid
      ? dayjs(currentDate).locale("en").format("ddd")
      : "-"

    const filteredByDayOfMonth = apiDataClone?.filter((item) => {
      return dayjs(item.date).format("D") === currentDayOfMonth
    })

    // ! Now we wanna map data from filteredByDayOfMonth against the facility_ids [As order of facility_ids is important]
    const sortedByFacilityOrder =
      facility_ids?.length === 0
        ? {
            day: currentDayOfMonth,
            id: currentDayOfMonth,
            key: currentDayOfMonth,
            week_day: t(dayOfWeek),
            is_holiday: false,
          }
        : facility_ids.reduce<
            UserTransportMgmtTableColumnDataType | NonNullable<unknown>
          >((acc, facility_id) => {
            const facilityData = filteredByDayOfMonth.filter(
              (item) => item.facility_id === facility_id,
            )

            return {
              ...acc,
              day: currentDayOfMonth,
              id: currentDayOfMonth,
              key: currentDayOfMonth,
              week_day: t(dayOfWeek),
              [`${facility_id}`]: {
                pickup: facilityData[0]?.pickup_count
                  ? facilityData[0]?.pickup_count
                  : "0",
                dropoff: facilityData[0]?.drop_count
                  ? facilityData[0]?.drop_count
                  : "0",
                users: facilityData[0]?.total_count
                  ? facilityData[0]?.total_count
                  : "0",
                is_holiday: facilityData[0]?.holiday_flag == "1",
                facility_transport_class:
                  facilityData[0]?.holiday_flag == "1" ? "holiday-cell" : "",
              },
            }
          }, {})

    return sortedByFacilityOrder
  })

  return formattedData
}
