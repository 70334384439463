import {
  Button,
  CALENDAR_HOURS_MINUTES,
  Card,
  SelectInput,
  Table,
  USER_ATTENDANCE_VALUES,
  breakTimeInString,
  getSumOfAllBreakTimes,
  getTotalTimeInSec,
  theme,
  Box,
} from "@project/common"
import { Flex, Typography, notification } from "antd"
import { TableProps } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { FULL_DATE_FORMAT_EN, YM_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../context"
import {
  getUserServiceContentResultMonthlyDetails,
  postAllUsersAttendanceManagement,
} from "../../../../services"
import MultipleBreakTime from "./component/MultipleBreakTime"
import {
  breakTimeRecordToArray,
  revertBreakTimeFormatBack,
} from "../../../../utils"

const Wrapper = styled.div`
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .button-wrapper-second {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .ant-flex {
    justify-content: flex-start;
  }
  .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-tbody > tr > td:nth-child(5) {
    padding: 8px 16px;
  }

  .range-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    .time-icon {
      display: flex;
      gap: 10px;
    }
    .range {
      display: flex;
      gap: 5px;
      align-items: center;
      .start_date {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .end_date {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
    .hrs-min {
      display: flex;
      gap: 8px;
      align-items: center;
      .hrs {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .min {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .time-label {
    white-space: nowrap;
  }
`
export const UserAttendanceManagementMonthlyDetailEdit = ({
  userName,
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { queryClient } = useAuthContext()
  const [dataSource, setDataSource] = useState([])
  const { id, date, facility_id } = router?.query as any
  const currentDate = date ? dayjs(date) : dayjs()
  const { isLoading, data: response } = useQuery({
    queryKey: ["user-attendance-management-monthly-detail", id],
    queryFn: () =>
      getUserServiceContentResultMonthlyDetails({
        year: dayjs(currentDate).year(),
        month: dayjs(currentDate).month() + 1,
        user_id: +id,
      }),
  })

  const { isLoading: isUpdating, mutate } = useMutation({
    mutationFn: postAllUsersAttendanceManagement,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })

      router.push(
        `/user-attendance-management/monthly-detail/${id}?date=${date}&facility_id=${facility_id}`,
      )
      queryClient.invalidateQueries({
        queryKey: ["userAttendanceManagementMonthlyDetail-list-page", id],
        expect: true,
      })
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const handleValueChange = ({ rowId, key, value }) => {
    const updatedData = dataSource.map((d) => {
      if (d.index === rowId) {
        const newData = {
          ...d,
          [key]: value,
        }

        if (key === "start_time_hr" || key === "start_time_min") {
          newData.start_time = `${
            key === "start_time_hr" ? value : d.start_time_hr || "00"
          }:${key === "start_time_min" ? value : d.start_time_min || "00"}`
        }

        if (key === "end_time_hr" || key === "end_time_min") {
          newData.end_time = `${
            key === "end_time_hr" ? value : d.end_time_hr || "00"
          }:${key === "end_time_min" ? value : d.end_time_min || "00"}`
        }

        return newData
      }
      return d
    })

    const newDataSource = getDataSource({ data: updatedData })
    setDataSource(newDataSource)
  }

  const getDataSource = useCallback(
    (res) => {
      let diffSeconds,
        breakTime,
        registered_time_attendance,
        registered_time_break
      const source = res?.data?.map((currentData, index) => {
        const shiftBreakSeconds = currentData?.attendance_shift
          ?.attendance_rest_mins
          ? currentData?.attendance_shift?.attendance_rest_mins * 60
          : 0
        const attendance_type =
          !currentData?.attendance_type ||
          currentData?.attendance_type ===
            USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION ||
          currentData?.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE

        if (attendance_type) {
          breakTime = t("--hrs--min")
          registered_time_attendance = t("--:-- ~ --:--")
          registered_time_break = t("--hrs--mins")
        } else {
          // calculate the Excess/deficiency time if present only
          const getSeconds = getTotalTimeInSec(
            dayjs(currentData?.date).format(FULL_DATE_FORMAT_EN),
          )
          const attendanceSeconds =
            currentData?.start_time && currentData?.end_time
              ? getSeconds(
                  currentData?.start_time || "00:00",
                  currentData?.end_time || "00:00",
                )
              : 0
          const attendanceBreakSeconds = getSumOfAllBreakTimes(
            currentData,
            dayjs(currentData?.date).format("YYYY-MM-DD"),
          )

          const shiftSeconds = getSeconds(
            currentData?.attendance_shift?.attendance_start_time || "00:00",
            currentData?.attendance_shift?.attendance_end_time || "00:00",
          )

          const totalShiftSeconds = shiftSeconds - shiftBreakSeconds
          const attendanceTotalSeconds =
            attendanceSeconds - attendanceBreakSeconds

          diffSeconds = attendanceTotalSeconds - totalShiftSeconds
          breakTime =
            !currentData?.start_time || !currentData?.end_time
              ? t("--hrs--min")
              : breakTimeInString(diffSeconds || 0, "LONG_HAND")
          registered_time_attendance = `${currentData?.start_time || ""} ~ ${
            currentData?.end_time || ""
          }`
          registered_time_break = breakTimeInString(
            attendanceBreakSeconds,
            "LONG_HAND",
          )
        }

        return {
          ...currentData,
          start_time_hr: currentData?.start_time?.split(":")[0],
          start_time_min: currentData?.start_time?.split(":")[1],
          end_time_hr: currentData?.end_time?.split(":")[0],
          end_time_min: currentData?.end_time?.split(":")[1],
          index: index + 1,
          key: currentData?.id + index,
          day: dayjs(currentData?.date).date() + t("Day"),
          shift:
            currentData?.attendance_shift?.attendance_shift_name ||
            "" +
              `(${
                currentData?.attendance_shift?.attendance_start_time || "00:00"
              }-${
                currentData?.attendance_shift?.attendance_end_time || "00:00"
              })`,
          shift_break_time: breakTimeInString(shiftBreakSeconds, "LONG_HAND"),
          deficiency_time: breakTime,
          deficiency_time_type: attendance_type
            ? 1
            : diffSeconds === 0
              ? 0
              : diffSeconds > 0
                ? 1
                : -1,
          registered_time_attendance: registered_time_attendance,
          registered_time_break: registered_time_break,
          break_time_list: currentData?.break_time_list
            ? currentData?.break_time_list
            : breakTimeRecordToArray(currentData),
        }
      })

      return source || []
    },
    [dataSource],
  )

  useEffect(() => {
    setDataSource(getDataSource(response))
  }, [response])

  const columns: TableProps<any>["columns"] = [
    {
      key: "1",
      title: t("date"),
      dataIndex: "day",
      align: "center",
      width: 100,
    },
    {
      key: "2",
      title: t("shift・attending day"),
      dataIndex: "shift",
      align: "center",
      width: 140,
      render: (val, row) => {
        return (
          <Flex justify={"center"} vertical>
            {(row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
              row?.attendance_type ==
                USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION) &&
            row?.is_paid_leave ? (
              t("Paid leave")
            ) : (
              <>
                <Typography.Text>
                  {row?.attendance_shift?.attendance_shift_name || "-"}
                </Typography.Text>
                <Typography.Text>
                  {"("}
                  {row?.shift_break_time}
                  {")"}
                </Typography.Text>
              </>
            )}
          </Flex>
        )
      },
    },
    {
      key: "3",
      title: t("Excess/deficiency time"),
      dataIndex: "deficiency_time",
      align: "center",
      width: 110,
      render: (val, row) => {
        return (
          <Typography.Text
            style={{
              color:
                row?.deficiency_time_type === 1
                  ? theme.colors.success
                  : row?.deficiency_time_type === 0
                    ? theme.colors.text
                    : theme.colors.error,
            }}
          >
            {val}
          </Typography.Text>
        )
      },
    },
    {
      key: "4",
      title: t("Registered_time"),
      width: 410,
      render: (row) => {
        if (
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
        ) {
          if (row?.is_paid_leave) return t("Paid leave")
          return t("Absence")
        }
        return (
          <Flex justify={"center"} wrap={"wrap"} gap={8}>
            <Typography.Text>
              <div>
                {t("Attendance")}
                {":"}
              </div>
              <div className={"range-wrapper"}>
                <div className={"time-icon"}>
                  <span> {row?.registered_time_attendance || ""}</span>
                  <span>
                    <img src={"/assets/icons/Arrow.svg"} alt={"icon"} />
                  </span>
                </div>
                <div className={"range"}>
                  <div className={"start_date"}>
                    <SelectInput
                      name={"hr"}
                      width={"58px"}
                      options={CALENDAR_HOURS_MINUTES.hours}
                      value={row?.start_time_hr}
                      onChange={(val) => {
                        handleValueChange({
                          rowId: row?.index,
                          key: "start_time_hr",
                          value: val,
                        })
                        null
                      }}
                      placeholder={"---"}
                      className={"left-align"}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      name={"min"}
                      width={"58px"}
                      options={CALENDAR_HOURS_MINUTES.minutes}
                      value={row?.start_time_min}
                      disabled={!row?.start_time_hr}
                      onChange={(val) => {
                        handleValueChange({
                          rowId: row?.index,
                          key: "start_time_min",
                          value: val,
                        })
                        null
                      }}
                      placeholder={"---"}
                      className={"left-align"}
                    />
                  </div>
                  <span>{"~"}</span>
                  <div className={"end_date"}>
                    <SelectInput
                      name={"hr"}
                      options={CALENDAR_HOURS_MINUTES.hours}
                      width={"58px"}
                      value={row?.end_time_hr}
                      onChange={(val) => {
                        handleValueChange({
                          rowId: row?.index,
                          key: "end_time_hr",
                          value: val,
                        })
                        null
                      }}
                      placeholder={"---"}
                      className={"left-align"}
                    />
                    <span>{":"}</span>
                    <SelectInput
                      name={"min"}
                      options={CALENDAR_HOURS_MINUTES.minutes}
                      width={"58px"}
                      value={row?.end_time_min}
                      disabled={!row?.end_time_hr}
                      onChange={(val) => {
                        handleValueChange({
                          rowId: row?.index,
                          key: "end_time_min",
                          value: val,
                        })
                        null
                      }}
                      placeholder={"---"}
                      className={"left-align"}
                    />
                  </div>
                </div>
              </div>
            </Typography.Text>
          </Flex>
        )
      },
      colSpan: 2,
    },
    {
      title: "",
      colSpan: 0,
      onCell: (_: any, index?: number) => {
        if (index === 1) {
          return { colSpan: 1 }
        }
        return {}
      },
      render: (row, rec) => {
        if (
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE ||
          row?.attendance_type == USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION
        ) {
          if (row?.is_paid_leave) return t("Paid leave")
          return t("Absence")
        }

        let registered_time_break = t("--hrs--mins")

        const timeDifference = getTotalTimeInSec(
          dayjs(rec?.date).format("YYYY-MM-DD"),
        )

        const attendanceBreakSeconds = rec?.break_time_list
          .map((item) =>
            item?.start_time && item?.end_time
              ? timeDifference(item?.start_time, item?.end_time)
              : 0,
          )
          ?.reduce((acc, curr) => acc + curr, 0)

        if (attendanceBreakSeconds > 0) {
          registered_time_break = breakTimeInString(
            attendanceBreakSeconds,
            "LONG_HAND",
          )
        }

        return (
          <Flex justify={"center"} wrap={"wrap"} gap={8}>
            <Typography.Text>
              <div>
                {t("Break time2")}
                {":"}
              </div>
              <Flex align={"flex-start"} gap={8}>
                <Box
                  mt={15}
                  display={"flex"}
                  align={"center"}
                  gap={8}
                  maw={109}
                  miw={109}
                >
                  <span> {registered_time_break || ""}</span>
                  <span>
                    <img src={"/assets/icons/Arrow.svg"} alt={"icon"} />
                  </span>
                </Box>

                <MultipleBreakTime
                  breakTimeList={rec?.break_time_list}
                  rowIndex={row?.index}
                  handleValueChange={handleValueChange}
                />
              </Flex>
            </Typography.Text>
          </Flex>
        )
      },
    },
  ]

  const handleSubmit = () => {
    const data = dataSource?.map((d) => {
      // deleteing following properties as they interfere with permission middleware in api
      if (typeof d?.system_status_management != "undefined") {
        delete d?.system_status_management
      }
      if (typeof d?.user_daily_record_request != "undefined") {
        delete d?.user_daily_record_request
      }
      const formattedData = {
        // in order to unchange break time value whose implement is currently wrong
        ...d,
        ...revertBreakTimeFormatBack(d?.break_time_list),
        facility_id: +d?.facility_id,
        user_id: +d?.user_id,
        date: dayjs(d?.date).format("YYYY-MM-DD"),
        start_time: d?.start_time,
        end_time: d?.end_time,
      }
      delete formattedData["break_time_list"]
      return formattedData
    })
    mutate(data)
  }
  return (
    <Wrapper>
      <Card
        title={t("{{user_name}}’s {{enDate}} attendance details edit", {
          user_name: userName,
          enDate: dayjs(currentDate).format("YYYY/MM"),
          jaDate: dayjs(currentDate).format(YM_FORMAT),
        })}
      >
        <Flex vertical gap={16}>
          <div className={"button-wrapper"}>
            <Button
              btnText={t("Cancel")}
              borderColor={"#0782C8"}
              shape={"round"}
              width={"fit-content"}
              onClick={() => {
                router.push(
                  `/user-attendance-management/monthly-detail/${id}?date=${date}&facility_id=${facility_id}`,
                )
              }}
              disabled={isUpdating}
              isLoading={isUpdating}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              width={"fit-content"}
              btnBg={"#0782C8"}
              textColor={"#FFFFFF"}
              onClick={() => {
                handleSubmit()
              }}
              disabled={isUpdating}
              isLoading={isUpdating}
            />
          </div>
          <Table
            columns={columns || []}
            dataSource={dataSource}
            scroll={{ x: "max-content" }}
            cellPaddingBlock={8}
            loading={isLoading || isUpdating}
          />
          <div className={"button-wrapper-second"}>
            <Button
              btnText={t("Cancel")}
              borderColor={"#0782C8"}
              shape={"round"}
              width={"fit-content"}
              onClick={() => {
                router.push(
                  `/user-attendance-management/monthly-detail/${id}?date=${date}&facility_id=${facility_id}`,
                )
              }}
              disabled={isUpdating}
            />
            <Button
              btnText={t("Save")}
              shape={"round"}
              width={"fit-content"}
              btnBg={"#0782C8"}
              textColor={"#FFFFFF"}
              onClick={() => {
                handleSubmit()
              }}
              disabled={isUpdating}
              isLoading={isUpdating}
            />
          </div>
        </Flex>
      </Card>
    </Wrapper>
  )
}
