import { theme } from "@project/common"
import styled from "styled-components"

export const MinutesOfMeetingOfPicContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;

  table > tbody {
    .no-certificate-row {
      background-color: ${theme.colors.lightPink};
    }
  }

  .create_status_buttons {
    gap: 24px;
  }
  .date_picker_content {
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 5px;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (width: 540px) {
      display: block;
    }
  }
`
export const TableContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 18px;
  flex-wrap: wrap;
`
export const TMiniContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
