import { theme } from "@project/common"
import dayjs from "dayjs"
import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Monitoring from "../../../../public/assets/icons/bargraph.svg"
import ServiceDelivery from "../../../../public/assets/icons/paper-check.svg"
import AdultCare from "../../../../public/assets/icons/user-fold.svg"
import AttendanceCalender from "../../../../public/assets/icons/work-information.svg"
import { zeroPadding } from "../../../../utils/utility-function"
const Wrapper = styled.div`
  .header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    @media (max-width: 480px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 280px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .card {
      border-radius: 12px;
      height: 125px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px 6px 0px 6px;
      border: 1px solid #d2d1d1;
      background-color: ${theme.colors.white};
      box-shadow: 0px 0px 15px 0px #0000000a;
      span {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        word-break: break-all;
        white-space: pre-wrap;
      }
      @media screen and (max-width: ${theme.breakpoints.xmd}) {
        padding-block: 10px;
        justify-content: center;
      }
    }
  }
`

interface IProps {
  facilityID: number
}
const OperationManagement: React.FC<IProps> = ({ facilityID }) => {
  const { t } = useTranslation()
  const operationManagement = [
    {
      title: t("Adult care"),
      icon: <AdultCare />,
      route: `/users?contract_year_month=${dayjs().year()}-${zeroPadding(
        dayjs().month() + 1,
      )}&contract_facility=${facilityID}`,
    },
    {
      title: t("Attendance calendar"),
      icon: <AttendanceCalender />,
      route: `/user-attendance-calendar?facilityId=${facilityID}`,
    },
    {
      title: t("Service delivery \n record sheet"),
      icon: <ServiceDelivery />,
      route: `/user-service-record?facilityIds=${facilityID}&year=${dayjs().year()}&month=${
        dayjs().month() + 1
      }&page=1`,
    },
    {
      title: t("Monitoring"),
      icon: <Monitoring />,
      route: `/monitoring-list?facility_ids=${facilityID}`,
    },
    {
      title: t("Wage management"),
      icon: <AdultCare />,
      route: `/wage-management?facility=${facilityID}`,
    },
  ]
  return (
    <Wrapper>
      <div className={"header"}>{t("Operation management information")}</div>
      <div className={"container"}>
        {operationManagement?.map((d, i) => {
          return (
            <Link href={`${d?.route}`} key={i}>
              <a>
                <div key={i} className={"card"}>
                  {d?.icon}
                  <span>{d?.title}</span>
                </div>
              </a>
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}

export { OperationManagement }
