import { t } from "i18next"
import * as Yup from "yup"

import dayjs from "dayjs"

export type TMinutesMgmtOperationContent = {
  facility_ids: string[]
  keyword: string
  start_date: dayjs.Dayjs | null
  end_date: dayjs.Dayjs | null
  use_service: number[]
}

export type TTableColumns = {
  id: string
  name_or_title: string
  facility: string
  use_service: 1 | 2 | 3
  implementation_date: string
  status: "draft" | "created"
}

export type Participant = {
  id: string
  name: null | string
  value: null | number
  index: number
  furigana: null | string
}

export type FreeItem = {
  id: string
  title: null | string
  value: null | string
}

export type AttachmentFile = {
  id: string
  file: null | string
}

export type TimePattern = `${string}:${string}`

// MInutes management formDataType
export type MMFormData = {
  addition_type: number
  minutes_title: string

  username_id: number

  save_staff_id: number

  event_date: dayjs.Dayjs | null
  event_start_time: TimePattern
  event_end_time: TimePattern

  participants: Participant[]

  corporate_name_1: string
  corporate_name_2: string
  corporate_person_name_1: string
  corporate_person_name_2: string

  consultation_support_id: string
  consultation_support_person_name: string

  free_items: FreeItem[]

  attachment_files: AttachmentFile[]

  is_reason_for_absence?: boolean
  reason_for_absence?: null | string
}

export type MinutesMgmtFormDataProps =
  | {
      formType?: "EDIT"
      initialValues?: MMFormData
      updating: boolean
      loadingCurrentMMData: boolean
      handleUpdate: (
        data: MMFormData,
        submissionType: "DRAFT" | "CREATE",
      ) => void
    }
  | {
      formType?: "REGISTER"
      handleRegistration: (
        data: MMFormData,
        submissionType: "DRAFT" | "CREATE",
      ) => void
      registering: boolean
    }

export type ErrorResponse = {
  status: number
  data: {
    error: { error: string; errors: any; message: string }
  }
}

// MMForm validation schema [MM = Minutes Management]
export const MMValidationSchema = Yup.object().shape({
  minutes_title: Yup.string().max(200, t("Within 200 characters")).nullable(),
  username_id: Yup.number().required(t("Required")),
  addition_type: Yup.number().required(t("Required")),
  save_staff_id: Yup.number().required(t("Required")),
  event_date: Yup.date().required(t("Required")),

  event_end_time: Yup.string()
    .required(t("Required"))
    .test(
      "is-valid-time",
      t("End time must be greater than start time"),
      function (value) {
        const { event_start_time } = this.parent
        if (!event_start_time || !value) return true // If either start or end time is not provided, skip validation
        const startTime = event_start_time.split(":").map(Number)
        const endTime = value.split(":").map(Number)
        return (
          endTime[0] > startTime[0] ||
          (endTime[0] === startTime[0] && endTime[1] > startTime[1])
        )
      },
    ),
  event_start_time: Yup.string().required(t("Required")), // 00:00

  participants: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(t("Required")),
        name: Yup.string().nullable(),
        value: Yup.string().required(t("Required")).typeError(t("Required")),
      }),
    )
    .required(t("Required")),
  free_items: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      // if there is value then title is required else its nullable
      title: Yup.string().when("value", ([value], schema) => {
        return value ? schema.required(t("Required")) : schema.nullable()
      }),

      value: Yup.string().nullable(),
    }),
  ),
  // attachment_files: Yup.array().of(
  //   Yup.object().shape({
  //     id: Yup.string(),
  //     file: Yup.string().required(t("Required")),
  //   }),
  // ),
  is_reason_for_absence: Yup.boolean().nullable(),
  reason_for_absence: Yup.string().nullable(),
})

export type MMFormDataType = Yup.InferType<typeof MMValidationSchema>
