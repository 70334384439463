import { Flex } from "antd"
import React from "react"
import { ActualCostBurdenDetailBasicInfo } from "./ActualCostBurdenDetailBasicInfo"
import { ActualCostBurdenDetailTableList } from "./ActualCostBurdenDetailTableList"

export const ActualCostBurdenDetail = ({
  dataSource,
  isLoading,
  params,
  setParams,
  refetch,
  isFetching,
}: {
  dataSource: any
  isLoading?: boolean
  params: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  refetch: any
  isFetching: boolean
}): JSX.Element => {
  return (
    <Flex vertical gap={16}>
      <ActualCostBurdenDetailBasicInfo
        data={dataSource}
        isLoading={isLoading}
      />
      <ActualCostBurdenDetailTableList
        dataSource={dataSource || []}
        isLoading={isLoading}
        params={params}
        setParams={setParams}
        refetch={refetch}
        isFetching={isFetching}
      />
    </Flex>
  )
}
