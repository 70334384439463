import { Space } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { t } from "i18next"
import _ from "lodash"
import { useRouter } from "next/router"
import { useState } from "react"
import { useQuery } from "react-query"

import {
  Box,
  Button,
  Card,
  CheckBox,
  keys,
  Table,
  useUpdateSearchParams,
  WithSorter,
} from "@project/common"
// import AlertBoxV2 from "../../common/AlertBoxV2"
import { Counter, QuoteTitle, UserUsingFacility } from "./styles"

import { disablePrintBtn } from "../../utils/disablePrintBtn"
import { formatToUsedFacilityDatatype } from "../../utils/formatToUsedFacilityDatatype"

import { getCityWiseBillingStatements } from "../../../../services"

import {
  DisabledUserBenefitsOperation,
  UsedFacilityDataType,
} from "../../../../types/nhif.types"
import AlertBoxV2 from "../../common/AlertBoxV2"
import { fetchIds } from "../../utils/fetchIds"

// import { unpaidUserList } from "../mock"

export const UserBenefitsList = ({
  memoizedQuery,
}: {
  memoizedQuery: DisabledUserBenefitsOperation
}) => {
  const { query } = useRouter()

  const [updateParams] = useUpdateSearchParams()

  const [billPrint, setBillPrint] = useState<Record<string, boolean>>({})
  const [selectedRowsInMultipleTable, setSelectedRowsInMultipleTable] =
    useState<Record<string, UsedFacilityDataType[]>>({})

  const currentDate = query?.year_month
    ? dayjs(query?.year_month.toString()).format("YYYY年MM月")
    : dayjs().format("YYYY年MM月")

  const {
    data: _cityWiseBillingStatement,
    isLoading: loadingCityWiseBillingStatement,
  } = useQuery(["nhif-city-wise-billing-statement", memoizedQuery], {
    queryFn: () =>
      getCityWiseBillingStatements({
        facilityIds: memoizedQuery?.facilityIds,
        year: dayjs(memoizedQuery?.year_month).year(),
        month: dayjs(memoizedQuery?.year_month).month() + 1,
      }),
    select: (data) => ({
      formatted_data: formatToUsedFacilityDatatype(data),
      user_without_provision_city: data?.data?.user_without_provision_city,
    }),
  })
  const cityWiseBillingStatement = _cityWiseBillingStatement?.formatted_data
  const noCity = _cityWiseBillingStatement?.user_without_provision_city || []

  const column: ColumnsType<UsedFacilityDataType> = [
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`User's name (furigana)`}
            columnKey={"user_name_furigana"}
          />
        )
      },
      dataIndex: "user_name_furigana",
      key: "user_name_furigana",
      width: 401,
      align: "center",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.user_name_furigana.localeCompare(b.user_name_furigana),
      render: (_, rec) => (
        <>
          {rec.user_name}
          {rec.user_name_furigana ? `(${rec.user_name_furigana})` : ""}
        </>
      ),
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Guardian name   (furigana)`}
            columnKey={"parent_name_furigana"}
          />
        )
      },
      dataIndex: "parent_name_furigana",
      key: "parent_name_furigana",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.parent_name_furigana.localeCompare(b.parent_name_furigana),
      width: 401,
      align: "center",
      render: () => "",
    },
    {
      title: (titleProps) => {
        return (
          <WithSorter
            titleProps={titleProps}
            title={`Beneficiary certificate number`}
            columnKey={"receiving_certificate_number"}
          />
        )
      },
      dataIndex: "receiving_certificate_number",
      key: "receiving_certificate_number",
      sortIcon: () => null,
      sorter: (a, b) =>
        a.receiving_certificate_number.localeCompare(
          b.receiving_certificate_number,
        ),
      width: 401,
      align: "center",
    },
  ]

  const handleCheckAll = () => {
    setSelectedRowsInMultipleTable(cityWiseBillingStatement)
    const allCheckInBillPrint = keys(cityWiseBillingStatement).reduce(
      (curr, key) => {
        return {
          ...curr,
          [key]: true,
        }
      },
      {} as Record<string, boolean>,
    )
    setBillPrint(allCheckInBillPrint)
  }

  const handleClearAll = () => {
    setSelectedRowsInMultipleTable({})
    setBillPrint({})
  }

  const handlePrint = () => {
    const city_ids = []
    keys(billPrint)?.map((key) => {
      city_ids.push(cityWiseBillingStatement[key][0]?.provision_city_id)
    })
    updateParams(
      {
        ...query,
        city_ids: city_ids?.length ? city_ids.join(",") : null,
        user_ids: fetchIds(selectedRowsInMultipleTable),
      },
      "/national-health-insurance-billing/prints/disabled-user-outpatient-benefits/print",
    )
  }

  return (
    <Card
      title={t("{{date}}’s List of User using the facility", {
        date: currentDate,
      })}
    >
      {noCity?.length ? (
        <AlertBoxV2
          theme={"info"}
          heading={t("There are user without provision city")}
          description={noCity?.map((item) => item?.user_name)?.join(", ")}
          variant={"WITH_OUT_COLLAPSE"}
        />
      ) : null}

      <Space align={"center"} size={16} className={"action-buttons"} wrap>
        <Button
          shape={"round"}
          type={"outlined-white-bg"}
          iconType={"check-all"}
          btnText={t("Check all")}
          className={"size-sm"}
          onClick={() => handleCheckAll()}
        />
        <Button
          shape={"round"}
          type={"grayed"}
          btnText={t("Clear all")}
          className={"size-sm"}
          onClick={() => handleClearAll()}
        />
        <Button
          shape={"round"}
          iconType={"print"}
          type={"primary"}
          btnText={t("Print")}
          onClick={handlePrint}
          disabled={disablePrintBtn(selectedRowsInMultipleTable)}
        />
        <Counter align={"center"}>
          {t("Output total people {{count}}人", {
            count: keys(selectedRowsInMultipleTable)
              .map((key) => selectedRowsInMultipleTable[key])
              .flat().length,
          })}
        </Counter>
      </Space>

      {loadingCityWiseBillingStatement ||
      (!loadingCityWiseBillingStatement &&
        keys(cityWiseBillingStatement || {}).length === 0) ? (
        <Table
          columns={column}
          dataSource={[]}
          tableContainerStyle={{
            width: "100%",
          }}
          loading={loadingCityWiseBillingStatement}
          className={"facility_user-table"}
          scroll={{
            x: 800,
          }}
          rowSelection={{
            type: "checkbox",
            columnWidth: 60,
          }}
        />
      ) : (
        keys(cityWiseBillingStatement || {}).map((key) => {
          return (
            <UserUsingFacility key={key}>
              <QuoteTitle>{`${
                cityWiseBillingStatement?.[key][0]?.provision_city || "本庄市"
              } (${key})`}</QuoteTitle>
              <CheckBox
                className={"bill-municipality-checkbox"}
                label={t("Print a bill for this municipality")}
                checked={billPrint?.[key]}
                value={billPrint?.[key]}
                onChange={(e) => {
                  setBillPrint((prev) => ({ ...prev, [key]: e.target.checked }))
                }}
              />

              <Table
                columns={column}
                dataSource={cityWiseBillingStatement[key]}
                tableContainerStyle={{
                  width: "100%",
                }}
                loading={loadingCityWiseBillingStatement}
                className={"facility_user-table"}
                scroll={{
                  x: 800,
                }}
                rowSelection={{
                  type: "checkbox",
                  columnWidth: 60,
                  selectedRowKeys: selectedRowsInMultipleTable[key]?.map(
                    (item) => item.id,
                  ),
                  onChange: (_, selectedRows) => {
                    setSelectedRowsInMultipleTable((prev) => ({
                      ...prev,
                      [key]: selectedRows,
                    }))
                  },
                }}
              />
              <Counter>
                {t(`{{provision_city}}  Invoice amount total {{total}}円`, {
                  total: _.sumBy(
                    selectedRowsInMultipleTable[key],
                    "invoice_amount",
                  )?.toLocaleString(),
                  provision_city:
                    cityWiseBillingStatement[key]?.[0]?.provision_city,
                })}
              </Counter>
            </UserUsingFacility>
          )
        })
      )}

      <Box display={"flex"} mt={16} align={"center"} gap={16} wrap={"wrap"}>
        <Button
          shape={"round"}
          iconType={"print"}
          type={"primary"}
          btnText={t("Print")}
          onClick={handlePrint}
          disabled={disablePrintBtn(selectedRowsInMultipleTable)}
        />
        <Counter>
          {t("Invoice amount total {{total}}円", {
            total: _.sumBy(
              keys(selectedRowsInMultipleTable)
                .map((key) => selectedRowsInMultipleTable[key])
                .flat(),
              "invoice_amount",
            )?.toLocaleString(),
          })}
        </Counter>
      </Box>
    </Card>
  )
}
