import { Card, getDaysOfMont, userWageSetting } from "@project/common"
import { Flex, Spin, Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { styled } from "styled-components"
import { AuthContext } from "../../../../context"
import { fetchPaySlipDetails } from "../../../../services"
import {
  hasPermissionForFacility,
  WageManagementPermission,
} from "../../../../utils/PermissionKeys"
import {
  TopInfo,
  UserInfo,
  WorkedDaysInfo,
} from "../PaylistCommon/PaylistCommon"
import { DetailAmountInfo } from "./DetailAmountInfo"

const Container = styled.div`
  @media print {
    @page {
      margin: 24px;
    }
  }
`
export const PaylistDetail = ({
  printContentRef,
  setAllowEdit,
}: {
  printContentRef: any
  setAllowEdit: (val: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { year, month, id } = router.query
  const { isOwner, permissions } = useContext(AuthContext)
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(["fetch-pay-slip-details", id, year, month], {
    queryFn: fetchPaySlipDetails,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: (data) => {
      const userWageSettings =
        data?.data?.paySlipDetail?.user_wage_settings || []
      const facility_wage_settings =
        data?.data?.paySlipDetail?.facility_wage_settings || []

      return {
        ...data?.data,
        paySlipDetail: {
          ...data?.data?.paySlipDetail,
          facility_wage_settings: [
            {
              id: 0,
              facility_wage_setting: {
                item_type: "allowance",
              },
              user_id: id,
              name: "Base wage",
              item_type: "allowance",
              amount: data?.data?.basicWage,
            },
            ...facility_wage_settings,
          ],
          user_wage_settings: [...userWageSettings],
        },
      }
    },
  })

  useEffect(() => {
    const allow =
      isOwner ||
      hasPermissionForFacility(
        permissions,
        WageManagementPermission,
        "write",
        response?.userInfo?.active_contract?.facility_id,
      )
    setAllowEdit(allow)
  }, [isOwner, permissions, response])

  const { totalAmount } = userWageSetting(
    response?.paySlipDetail?.user_wage_settings,
    response?.paySlipDetail?.facility_wage_settings,
    response?.paySlipDetail?.user_facility_wage_settings,
  )

  return (
    <Container>
      <Spin spinning={isLoading || isFetching}>
        <Card title={t("Pay slip")}>
          <Flex component={"form"} vertical gap={20} ref={printContentRef}>
            <TopInfo
              date={dayjs(year + "-" + month).format("YYYY年MM月")}
              company={response?.userInfo?.company?.company_name}
            />
            <UserInfo
              user_name={response?.userInfo?.username}
              amount={totalAmount}
              id={+id}
            />
            <DetailAmountInfo
              user_wage_setting={response?.paySlipDetail?.user_wage_settings}
              facility_wage_settings={
                response?.paySlipDetail?.facility_wage_settings
              }
              user_facility_wage_settings={
                response?.paySlipDetail?.user_facility_wage_settings
              }
            />
            <WorkedDaysInfo
              workDays={
                response?.attendanceInfo?.PresentCount
                  ? `${response?.attendanceInfo?.PresentCount}${t("Day")}`
                  : `0${t("Day")}`
              }
              absentDays={
                <Typography.Text>
                  {response?.attendanceInfo?.UnpaidLeaveCount || 0}
                  {t("Day")}
                </Typography.Text>
              }
              paidLeaves={
                response?.attendanceInfo?.PaidLeaveCount
                  ? `${response?.attendanceInfo?.PaidLeaveCount}${t("Day")}`
                  : `0${t("Day")}`
              }
              from_date={getDaysOfMont(year, month)?.from_date || null}
              to_date={getDaysOfMont(year, month)?.to_date || null}
              hrMins={getDaysOfMont(year, month)?.from_date || null}
            />
            <UserInfo
              user_name={"user name2"}
              amount={0}
              type={t("*Form from remarks")}
              remarks={response?.paySlipDetail?.remarks}
            />
          </Flex>
        </Card>
      </Spin>
    </Container>
  )
}
