import {
  DailyLeaveRecordData,
  LeaveRecordData,
  RequestStatus,
} from "../../../../types/userAttendanceMgmt.types"

export const reFormatLeaveRecord = (
  response: DailyLeaveRecordData,
): LeaveRecordData => {
  return {
    data: response.data.map((item) => ({
      id: item.id,
      application_date: item.created_datetime,
      key: item.id,
      leave_requested_date: item.date,
      reason: item.reason,
      username: item.user.username,
      status: item.status as RequestStatus,
      is_paid_leave: item?.user_attendance?.is_paid_leave || false,
    })),
    count: response.count,
  }
}
