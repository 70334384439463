import {
  AccordionCard,
  Button,
  CheckBoxGroup,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  useUpdateSearchParams,
} from "@project/common"
import { Flex } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useRouter } from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  FULL_DATE_FORMAT,
  FULL_DATE_FORMAT_EN,
  PROXY_ACKNOWLEDGEMENT_DISPLAY_SETTINGS,
} from "../../../../../constants"

export const AcknowledgementPrintPageContentOp = ({
  scrollTo,
}: {
  scrollTo: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const {
    facilityIds,
    year,
    month,
    date_of_receipt,
    date_of_issue,
    display_content,
  } = router.query as any
  const [values, setValues] = useState({
    date_of_receipt: date_of_receipt ? dayjs(date_of_receipt) : dayjs(),
    date_of_issue: date_of_issue ? dayjs(date_of_issue) : dayjs(),
    display_content: display_content?.split(",") || [],
  })

  return (
    <AccordionCard
      activeKey={["prroxy-acknowledgement-print"]}
      expandIcon={() => null}
      items={[
        {
          key: "prroxy-acknowledgement-print",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <Flex gap={16} wrap={"wrap"}>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Date of receipt")}
                    width={"136px"}
                  />
                  <DatePicker
                    date={values?.date_of_receipt || null}
                    onDateChange={(date: Dayjs) =>
                      setValues({
                        ...values,
                        date_of_receipt: date,
                      })
                    }
                    format={FULL_DATE_FORMAT}
                  />
                </div>
                <div className={"search__content"}>
                  <LabelTextWithLeftArrow
                    label={t("Date of issue")}
                    width={"136px"}
                  />
                  <DatePicker
                    date={values?.date_of_issue || null}
                    onDateChange={(date: Dayjs) =>
                      setValues({ ...values, date_of_issue: date })
                    }
                    format={FULL_DATE_FORMAT}
                  />
                </div>
              </Flex>
              <div className={"search__content flex-start"}>
                <LabelTextWithLeftArrow
                  label={t("Display contents")}
                  width={"150px"}
                />
                <CheckBoxGroup
                  items={PROXY_ACKNOWLEDGEMENT_DISPLAY_SETTINGS || []}
                  onChange={(value) =>
                    setValues({ ...values, display_content: value })
                  }
                  value={values?.display_content || []}
                />
              </div>
              <div className={"search__content"}>
                <Button
                  btnText={t("Switch Display")}
                  shape={"round"}
                  type={"primary"}
                  onClick={() => {
                    updateParams(
                      {
                        date_of_receipt: values?.date_of_receipt
                          ? dayjs(values?.date_of_receipt).format(
                              FULL_DATE_FORMAT_EN,
                            )
                          : null,
                        date_of_issue: values?.date_of_issue
                          ? dayjs(values?.date_of_issue).format(
                              FULL_DATE_FORMAT_EN,
                            )
                          : null,
                        display_content: values?.display_content?.join(","),
                        facilityIds,
                        year,
                        month,
                      },
                      "/national-health-insurance-billing/prints/print-proxy-acknowledgement/print",
                    )
                    scrollTo()
                  }}
                />
              </div>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
