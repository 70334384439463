import dayjs, { Dayjs } from "dayjs"
import {
  ActualCost,
  InvoiceBreakDown,
  ParsedInvoiceToCustomerData,
} from "../../../types/nhif.types"
import _ from "lodash"

export interface actualCostItem {
  name: string
  price: number
}

export interface ActualExpenseTableDataType
  extends Record<string, actualCostItem | string> {
  facility: string
  day_of_week: string
  date: string
}

export interface AllActualCostIds {
  id: number
  title: string
}

export interface ActualExpensesRec {
  dataSrc: Partial<ActualExpenseTableDataType>[]
  allActualCostIds: AllActualCostIds[]
}

export interface MonthlyItems {
  service_code_name: string
  unit: number
  amount: number
}

const emptyInvoiceBreakDown: Partial<InvoiceBreakDown> = {
  user_id: null,
  facility_id: null,
  unit: null,
  amount: null,
  billing: "",
  item_facility_name: "",
  item_facility_name_furigana: "",
}
export const getDailyInvoiceListing = (
  type: "MERGE_ACTUAL_COST" | "BILLING_BREAKDOWN" | undefined,
  invoiceToCustomerData: ParsedInvoiceToCustomerData,
  currentYearMonth: Dayjs,
) => {
  const billingInvoiceData = invoiceToCustomerData?.invoice_breakdown
  const dayInMonth = currentYearMonth.daysInMonth()
  let actualCostData: ActualExpensesRec | undefined

  if (type === "MERGE_ACTUAL_COST") {
    actualCostData = getDailyActualExpensesListing(
      invoiceToCustomerData.actual_cost_data,
      currentYearMonth,
    )
  }

  const formattedData = Array.from({ length: dayInMonth })
    .map((__, idx) => {
      const currentDayOfMonth = idx + 1
      const currentDate = currentYearMonth.format(
        `YYYY-MM-${String(currentDayOfMonth).padStart(2, "0")}`,
      )

      let invoiceValForCurrentDate: InvoiceBreakDown[] = _.filter(
        billingInvoiceData,
        (item) => item.date === currentDate,
      )

      if (type === "MERGE_ACTUAL_COST" && _.isEmpty(invoiceValForCurrentDate)) {
        if (actualCostData?.dataSrc.some((item) => item.date === currentDate)) {
          invoiceValForCurrentDate = [
            {
              ...emptyInvoiceBreakDown,
              day: currentDayOfMonth,
            } as InvoiceBreakDown,
          ]
        }
      }

      if (!_.isEmpty(invoiceValForCurrentDate)) {
        const dayOfWeek = dayjs(currentDate).isValid()
          ? dayjs(currentDate).locale("en").format("ddd")
          : "-"

        const concatenatedInvoice = invoiceValForCurrentDate.reduce(
          (acc, curr) => {
            const invoice = {
              ...curr,
              unit: curr.unit + acc.unit,
              amount: curr.amount + acc.amount,
              billing:
                (curr.billing || acc.billing) &&
                `${curr.billing}, ${acc.billing}`,
            }

            if (actualCostData) {
              const dataToBeMergedFromActualCost = actualCostData.dataSrc.find(
                (src) => src.date === currentDate,
              )
              return { ...invoice, ...dataToBeMergedFromActualCost }
            }

            return invoice
          },
          emptyInvoiceBreakDown,
        )

        concatenatedInvoice["day_of_week"] = dayOfWeek
        return concatenatedInvoice
      }
    })
    .filter((item) => item)

  return formattedData
}

export const getDailyActualExpensesListing = (
  expensesList: ActualCost[],
  currentYearMonth: Dayjs,
): ActualExpensesRec => {
  const dayInMonth = currentYearMonth?.daysInMonth()

  const allActualCostIds: AllActualCostIds[] = []

  const formattedData = Array.from({
    length: dayInMonth,
  })
    .map((__, idx) => {
      const currentDayOfMonth = idx + 1

      const currentDate = currentYearMonth.format(
        `YYYY-MM-${String(currentDayOfMonth).padStart(2, "0")}`,
      )

      const expenseValForCurrentDate = _.filter(
        expensesList,
        (item) => item.date === currentDate,
      )

      if (!_.isEmpty(expenseValForCurrentDate)) {
        const dayOfWeek = dayjs(currentDate).isValid
          ? dayjs(currentDate).locale("en").format("ddd")
          : "-"

        const concatenatedExpense = expenseValForCurrentDate.reduce<
          Partial<ActualExpenseTableDataType>
        >((acc, curr) => {
          if (
            !allActualCostIds.some((item) => item.id === curr.actual_cost_id)
          ) {
            allActualCostIds.push({
              id: curr.actual_cost_id,
              title: curr.name,
            })
          }

          return {
            ...acc,
            facility: curr.facility,
            [curr.actual_cost_id]: { name: curr.name, price: curr.price },
          }
        }, {})

        concatenatedExpense["day_of_week"] = dayOfWeek
        concatenatedExpense["date"] = currentDate

        return concatenatedExpense
      }
    })
    .filter((item) => item)

  return { dataSrc: formattedData, allActualCostIds: allActualCostIds.sort() }
}
