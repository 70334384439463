import {
  CheckBoxGroup,
  Grid,
  InputField,
  RadioGroup,
  SelectInput,
  SerialNumberBox,
  allowOnlyNumber,
  theme,
} from "@project/common"
import { Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import { InputChangeProps } from "../../../../types"
import {
  BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION,
  DAYS_OPTION,
  YES_NO_OPTION,
} from "../../../../constants"
import dayjs, { Dayjs } from "dayjs"

const COMMON = css`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
  .txt {
    ${theme.typography["body-regular"]}
  }
`
const SELECTOR = css`
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  flex-wrap: wrap;
  row-gap: 1rem;
  div {
    display: flex;
    align-items: center;
    column-gap: 0.3em;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .work__radio {
    ${COMMON};
  }
`
const EstablishmentContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .flex {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    .content__text {
      min-width: 150px;
      max-width: 200px;
      ${theme.typography["body-regular"]};
    }
  }
  .period__content {
    .selector {
      ${SELECTOR};
    }
  }
  .hour__content {
    .hour__content-selector {
      .selector {
        ${SELECTOR};
      }
    }
  }
`
const SettingContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  .work__radio {
    ${COMMON};
  }
  .time__container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 16px;
    .label_text {
      min-width: 50px;
    }
    .time__content {
      display: flex;
      column-gap: 16px;
      row-gap: 16px;
      align-items: center;
      .time {
        display: flex;
        column-gap: 8px;
        align-items: center;
      }
    }
  }
`

export const SectionTwo = ({
  tab = "yearly",
  handleCalendarOtherInputChange,
  defaultValue,
  date,
}: {
  tab?: "monthly" | "yearly"
  handleCalendarOtherInputChange?: (values: InputChangeProps) => void
  defaultValue?: any
  date?: Dayjs
}) => {
  const { t } = useTranslation()
  const dateOptions = DAYS_OPTION?.slice(0, dayjs(date).daysInMonth())
  return (
    <Wrapper>
      <SerialNumberBox
        number={2}
        text={t("Setting the number of hours that full-time staff should work")}
      />
      <div>
        <Grid
          background
          labelSpan={5}
          padding={"10px"}
          labelContent={t(
            "Setting the number of hours that full-time staff should work",
          )}
        >
          <SettingContent>
            <div className={"work__radio"}>
              <RadioGroup
                options={YES_NO_OPTION}
                name={"time_setting"}
                value={defaultValue?.time_setting}
                onChange={({ target: { value } }) =>
                  handleCalendarOtherInputChange({
                    key: "time_setting",
                    val: value,
                  })
                }
              />
            </div>
            {defaultValue?.time_setting === "1" && (
              <>
                <div className={"time__container"}>
                  <Typography.Text className={"label_text"}>
                    {t("1 day")} {":"}
                  </Typography.Text>
                  <div className={"time__content"}>
                    <div className={"time"}>
                      <InputField
                        width={"120px"}
                        height={"40px"}
                        value={defaultValue?.day_hours}
                        onChange={({ target: { value } }) =>
                          handleCalendarOtherInputChange({
                            key: "day_hours",
                            val: allowOnlyNumber(value),
                          })
                        }
                      />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Hrs")}
                      </span>
                    </div>
                    <div className={"time"}>
                      <InputField
                        width={"120px"}
                        height={"40px"}
                        value={defaultValue?.day_minutes}
                        onChange={({ target: { value } }) =>
                          handleCalendarOtherInputChange({
                            key: "day_minutes",
                            val: allowOnlyNumber(value),
                          })
                        }
                      />
                      <span>{t("mins")}</span>
                    </div>
                  </div>
                </div>
                <div className={"time__container"}>
                  <Typography.Text className={"label_text"}>
                    {t("1 week")} {":"}
                  </Typography.Text>
                  <div className={"time__content"}>
                    <div className={"time"}>
                      <InputField
                        width={"120px"}
                        height={"40px"}
                        value={defaultValue?.week_hours}
                        onChange={({ target: { value } }) =>
                          handleCalendarOtherInputChange({
                            key: "week_hours",
                            val: allowOnlyNumber(value),
                          })
                        }
                      />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Hrs")}
                      </span>
                    </div>
                    <div className={"time"}>
                      <InputField
                        width={"120px"}
                        height={"40px"}
                        value={defaultValue?.week_minutes}
                        onChange={({ target: { value } }) =>
                          handleCalendarOtherInputChange({
                            key: "week_minutes",
                            val: allowOnlyNumber(value),
                          })
                        }
                      />
                      <span>{t("mins")}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </SettingContent>
        </Grid>
        {tab === "monthly" && (
          <Grid
            background
            labelSpan={5}
            padding={"10px"}
            labelContent={t("Establishment of variable working hours system")}
          >
            <EstablishmentContent>
              <div className={"work__radio"}>
                <Typography.Text className={"txt"}>
                  {t("変形労働時間制の有無 : ")}
                </Typography.Text>
                <RadioGroup
                  options={YES_NO_OPTION}
                  name={"time_setting"}
                  value={defaultValue?.modified_working}
                  onChange={({ target: { value } }) =>
                    handleCalendarOtherInputChange({
                      key: "modified_working",
                      val: value,
                    })
                  }
                />
              </div>
              {defaultValue?.modified_working === "1" && (
                <>
                  <div className={"occupation__content flex"}>
                    <div className={"content__text"}>
                      {t("Subjected occupation: ")}
                    </div>
                    <CheckBoxGroup
                      items={BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION}
                      value={defaultValue?.occupations || []}
                      onChange={(list: CheckboxValueType[]) => {
                        handleCalendarOtherInputChange({
                          key: "occupations",
                          val: list,
                        })
                      }}
                    />
                  </div>
                  <div className={"period__content flex"}>
                    <div className={"content__text"}>
                      {t(
                        "Transformation period (period to which the current month belongs): ",
                      )}
                    </div>
                    <div className={"period__content-selector"}>
                      <div className={"selector"}>
                        <SelectInput
                          name={"modified_working_start_day"}
                          width={"80px"}
                          value={defaultValue?.modified_working_start_day}
                          options={dateOptions}
                          onChange={(value) =>
                            handleCalendarOtherInputChange({
                              key: "modified_working_start_day",
                              val: value,
                            })
                          }
                          filterSort={() => null}
                        />
                        <span
                          style={{
                            margin: "0 5px",
                          }}
                        >
                          {"日"} {"~"}
                        </span>
                        <SelectInput
                          name={"modified_working_end_day"}
                          width={"80px"}
                          value={defaultValue?.modified_working_end_day}
                          options={dateOptions}
                          onChange={(value) =>
                            handleCalendarOtherInputChange({
                              key: "modified_working_end_day",
                              val: value,
                            })
                          }
                          filterSort={() => null}
                        />
                        <span
                          style={{
                            margin: "0 5px",
                          }}
                        >
                          {"日"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={"hour__content flex"}>
                    <div className={"content__text"}>
                      {t(
                        "Number of hours to be worked during the above period: ",
                      )}
                    </div>
                    <div className={"hour__content-selector flex"}>
                      <div className={"selector"}>
                        <div>
                          <InputField
                            name={"modified_working_hours"}
                            width={"80px"}
                            height={"40px"}
                            type={"number"}
                            value={defaultValue?.modified_working_hours || null}
                            onChange={({ target: { value } }) => {
                              handleCalendarOtherInputChange({
                                key: "modified_working_hours",
                                val: allowOnlyNumber(value),
                              })
                            }}
                          />
                          <span style={{ whiteSpace: "nowrap" }}>
                            {t("Hrs")}
                          </span>
                        </div>
                        <div>
                          <InputField
                            name={"modified_working_minutes"}
                            width={"80px"}
                            height={"40px"}
                            type={"number"}
                            value={
                              defaultValue?.modified_working_minutes || null
                            }
                            onChange={({ target: { value } }) => {
                              handleCalendarOtherInputChange({
                                key: "modified_working_minutes",
                                val: allowOnlyNumber(value),
                              })
                            }}
                          />
                          <span style={{ whiteSpace: "nowrap" }}>
                            {t("mins")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </EstablishmentContent>
          </Grid>
        )}
      </div>
    </Wrapper>
  )
}
