import React from "react"
import { useRouter } from "next/router"

// packages
import _ from "lodash"
import dayjs from "dayjs"
import { t } from "i18next"
import { useQuery } from "react-query"

// components
import { Box, Card, useNotification } from "@project/common"
import { CustomerInvoiceDetailsComp } from "./common/CustomerInvoiceDetailsComp"

// services
import { getCustomerInvoiceDetails } from "../../../../../services"

// utils
import { formatResponseData } from "../../../utils/parseCustomerInvoice"
import { Spin } from "antd"

const PrintControlPrintView = () => {
  const { query } = useRouter()

  const facilityToOutput = query?.facility_to_output
    ? query?.facility_to_output.toString().split(",")
    : []

  const currentYearMonth = dayjs(query?.year_month as string).isValid()
    ? dayjs(query?.year_month as string)
    : dayjs()

  const { showToast } = useNotification()

  const { data: customerInvoiceDetails, isLoading } = useQuery(
    ["customer-invoice-details"],
    {
      queryFn: () =>
        getCustomerInvoiceDetails({
          year: currentYearMonth.year(),
          month: currentYearMonth.month() + 1,
          facilityIds: (query?.facilityIds as string) || "12",
          user_ids: query?.user_ids as string,
        }),
      select: (data) => formatResponseData(data),
      onError: () =>
        showToast({
          type: "error",
          message: t("Something went wrong. Try again later."),
        }),
    },
  )

  if (isLoading || (!isLoading && _.isEmpty(customerInvoiceDetails))) {
    return (
      <Box mt={20}>
        <Spin spinning={isLoading}>
          <Card>
            <div>{t("No data found")}</div>
          </Card>
        </Spin>
      </Box>
    )
  }

  return customerInvoiceDetails?.map((item, i) => {
    return (
      <div key={item.user_id + i}>
        {!facilityToOutput.includes("7") && (
          <>
            <CustomerInvoiceDetailsComp
              facilityToOutput={facilityToOutput}
              customerInvoice={item}
              currentYearMonth={currentYearMonth}
            />
          </>
        )}
        {(facilityToOutput.includes("6") || facilityToOutput.includes("7")) && (
          <CustomerInvoiceDetailsComp
            facilityToOutput={facilityToOutput}
            customerInvoice={item}
            currentYearMonth={currentYearMonth}
            copy
          />
        )}
      </div>
    )
  })
}

export default PrintControlPrintView
