import React from "react"
import { EmploymentCertificateStatus } from "./EmploymentCertificateForm/EmploymentCertificateStatus"
import { Flex } from "antd"
import { EmploymentCertificateBasicInformation } from "./EmploymentCertificateForm/EmploymentCertificateBasicInformation"

export const EmploymentCertificateAdd = ({
  user_id,
  service_type,
}): JSX.Element => {
  return (
    <Flex vertical gap={16}>
      <EmploymentCertificateBasicInformation
        user_id={user_id}
        service_type={service_type}
      />
      <EmploymentCertificateStatus user_id={user_id} />
    </Flex>
  )
}
