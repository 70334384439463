import React, { useMemo } from "react"

// packages
import { Flex, Typography } from "antd"
import dayjs from "dayjs"

// styles
import {
  NotiLabelWrap,
  NotiTypeTag,
  NotificationCollapsible,
} from "./Notification.styles"

// types
import { useTranslation } from "react-i18next"
import { NotificationProps } from "../../types/notification.types"
import styled from "styled-components"

const ContentWrapper = styled.div`
  .content {
    white-space: pre-wrap;
  }
`
// Components
const NotiLabel: React.FC<{ notification: Partial<NotificationProps> }> = ({
  notification,
}) => {
  const { t } = useTranslation()
  return (
    <NotiLabelWrap>
      <Typography.Title
        level={2}
        ellipsis={{
          rows: 2,
          expandable: false,
          tooltip: true,
        }}
        className={"title-text"}
        style={{ fontWeight: notification?.is_seen ? "normal" : "bold" }}
      >
        {notification.notification_title}
      </Typography.Title>
      <Flex align={"center"} gap={"8px"}>
        {notification.notification_type && (
          <NotiTypeTag $type={notification.notification_type}>
            {t(notification.notification_type)}
          </NotiTypeTag>
        )}
        <span className={"date-text"}>
          {dayjs(notification.publishing_date).format("YYYY年MM月DD日")}
        </span>
      </Flex>
    </NotiLabelWrap>
  )
}

// !## Main Component
const NotiCollapsible: React.FC<{
  notifications?: NotificationProps[]
  handleNotificationClick: (id: string) => void
}> = ({ notifications, handleNotificationClick }) => {
  const memoizedItems = useMemo(() => {
    return notifications?.map((notification) => {
      return {
        key: notification.id,
        label: <NotiLabel notification={notification} />,
        children: (
          <ContentWrapper>
            <div className={"content"}>{notification.notification_details}</div>
            {notification?.url && (
              <div className={"url-section"}>
                {" "}
                {"URL:"}{" "}
                <a href={notification?.url} target={"_blank"}>
                  {notification?.url}
                </a>
              </div>
            )}
          </ContentWrapper>
        ),
      }
    })
  }, [notifications])

  return (
    <>
      <NotificationCollapsible
        defaultActiveKey={[]}
        expandIconPosition={"right"}
        items={memoizedItems}
        onChange={handleNotificationClick}
        expandIcon={({ isActive }) => (
          <img
            src={"/assets/icons/extra-icon.svg"}
            alt={"icon"}
            style={{
              cursor: "pointer",
              transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.3s",
            }}
          />
        )}
      />
    </>
  )
}

export { NotiCollapsible, NotiLabel }
