import Link from "next/link"

// packages
import dayjs from "dayjs"
import { t } from "i18next"

// common shared
import { FACILITY_SERVICE_TYPES, Tag, theme } from "@project/common"

// types
import { ColumnsType } from "antd/es/table"
import { TTableColumns } from "../../../types/variousAdditionsManagementOfMeetingMinutes.types"

export const MMColumns = (facilities: any): ColumnsType<TTableColumns> => [
  {
    title: t("Detail"),
    dataIndex: "id",
    key: "id",
    align: "center",
    width: 100,
    render: (text) => {
      return (
        <Link
          href={`/various-additions-management-of-meeting-minutes/detail/${text}`}
        >
          <a>{t("Detail")}</a>
        </Link>
      )
    },
  },
  {
    title: t("Addition name/title"),
    dataIndex: "name_or_title",
    key: "name_or_title",
    align: "center",
    width: 231.8,
    render: (text) => {
      return <div>{text}</div>
    },
  },
  {
    title: t("Facility name"),
    dataIndex: "facility",
    key: "facility_id",
    align: "center",
    width: 231.8,
    render: (val) => {
      const facilityName = facilities?.find((item) => item?.id === val)
        ?.facility_name
      return facilityName
    },
  },
  {
    title: t("Service using"),
    dataIndex: "use_service",
    key: "use_service",
    align: "center",
    width: 231.8,
    render: (val) => {
      const service = t(FACILITY_SERVICE_TYPES[val])
      return service ? <Tag color={theme.colors.blue7} title={service} /> : ""
    },
  },
  {
    title: t("Implementation date"),
    dataIndex: "implementation_date",
    key: "implementation_date",
    align: "center",
    width: 231.8,
    render: (val) => {
      return dayjs(val).format("YYYY年MM月DD日")
    },
  },
  {
    title: t("Status  "),
    dataIndex: "status",
    key: "status",
    align: "center",
    width: 231.8,
    render: (text) => {
      return (
        <Tag
          color={text === "draft" ? theme.colors.disabled : theme.colors.red3}
          style={{
            color: text === "draft" ? theme.colors.text : theme.colors.white,
            textTransform: "capitalize",
          }}
          title={t(text)}
        />
      )
    },
  },
]
