import { Button, Card, scrollToFirstErrorField } from "@project/common"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { AttendanceInformation } from "./AttendanceInformation"
import { BreakTime } from "./BreakTime"
import { WorkInformation1 } from "./WorkInformation1"
import { WorkInformation2 } from "./WorkInformation2"
import { ButtonWrapper, CardWrapper } from "./StaffAttendanceComponent.styles"
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import { Divider, Skeleton, notification } from "antd"

import { useFormik } from "formik"
import { staffAttendanceFormSchema } from "../../../types/StaffAttendance.types"
import {
  createOrUpdateAttendanceStaffResult,
  getDailyAttendanceStaffResult,
} from "../../../services"
import { FULL_DATE_FORMAT_EN } from "../../../constants"

interface IProps {
  mode: "ADD" | "EDIT"
  facilityName?: string | null
  staffData?: any[]
}
const DayListComponent: React.FC<IProps> = ({
  mode,
  facilityName,
  staffData,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facility_id, staff_id, date, id } = router?.query as any
  const formattedDate = dayjs(date).format("YYYY年MM月DD")

  //update/create data
  const { mutate: mutateStaffAttendanceRecord, isLoading: isCreateLoading } =
    useMutation({
      mutationFn: createOrUpdateAttendanceStaffResult,
      onSuccess: () => {
        notification.success({
          message: id ? t("Updated Successfully") : t("Created Successfully"),
        })
        router.push(
          `/attendance-record/day-list?facility_id=${facility_id}&date=${date}`,
        )
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    })
  const initialValues = {
    id: id || null,
    date: date,
    furigana: "",
    staff_id: staffData?.[0]?.id || null,
    staff_name: "",
    facility_id: +facility_id || null,
    facility_name: "",
    attendance_type: null,
    attendance_type2: null,
    attendance_start_time: "",
    attendance_end_time: "",
    attendance_rest_minits: null,
    attendance_start_time2: "",
    attendance_end_time2: "",
    attendance_rest_minits2: null,
    remarks: "",
    rest_hours: null,
    rest_minits_flg: 0,
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: staffAttendanceFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      delete values?.staff_name
      delete values?.facility_name
      mutateStaffAttendanceRecord([
        {
          ...values,
          date: dayjs(values?.date).format(FULL_DATE_FORMAT_EN),
          attendance_rest_minits: +values?.attendance_rest_minits || null,
          attendance_rest_minits2: +values?.attendance_rest_minits2 || null,
          rest_minits_flg:
            values?.rest_minits_flg && values?.rest_minits_flg === 1
              ? true
              : false,
        },
      ])
    },
  })
  //fetch data
  const { isLoading } = useQuery({
    queryKey: ["attendance-record-daily", staff_id, date],
    queryFn: () =>
      getDailyAttendanceStaffResult({
        date: date,
        facility: facility_id,
        staff_id,
      }),
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      let obj: any = {}
      res?.data?.attendance_data?.staff_results?.map((v) => {
        obj = {
          id: v?.id,
          facility_id: v?.facility_id || null,
          facility_name: v?.facility?.facility_name || "",
          staff_id: v?.staff_id || null,
          staff_name: v?.staff?.staff_name || "",
          date: v?.date || null,
          remarks: v?.remarks || null,
          rest_minits_flg: v?.rest_minits_flg,
          rest_hours: v?.rest_hours || null,
          attendance_type: v?.attendance_type || null,
          attendance_start_time: v?.attendance_start_time || null,
          attendance_end_time: v?.attendance_end_time || null,
          attendance_rest_minits: v?.attendance_rest_minits || null,
          attendance_type2: v?.attendance_type2 || null,
          attendance_start_time2: v?.attendance_start_time2 || null,
          attendance_end_time2: v?.attendance_end_time2 || null,
          attendance_rest_minits2: v?.attendance_rest_minits2 || null,
          furigana: "",
        }
      })
      formik.setValues(obj)
    },
    enabled: !!facility_id && !!staff_id && !!date,
  })
  useEffect(() => {
    if (formik?.submitCount == 0) return
    if (formik?.isValid) return
    scrollToFirstErrorField(formik?.errors)
  }, [formik?.submitCount, formik?.isValid])

  const handleReset = () => {
    formik.setFieldValue("attendance_type", null)
    formik.setFieldValue("attendance_type2", null)
    formik.setFieldValue("attendance_start_time", "")
    formik.setFieldValue("attendance_end_time", "")
    formik.setFieldValue("attendance_rest_minits", null)
    formik.setFieldValue("attendance_start_time2", "")
    formik.setFieldValue("attendance_end_time2", "")
    formik.setFieldValue("attendance_rest_minits2", null)
    formik.setFieldValue("remarks", "")
    formik.setFieldValue("rest_hours", null)
    formik.setFieldValue("rest_minits_flg", 0)
  }

  return (
    <>
      <CardWrapper>
        <Card
          className={"card-header"}
          title={t("{{date}} {{staffName}} attendance information", {
            date: formattedDate,
            staffName: facilityName,
          })}
        >
          <Divider />
          {isLoading ? (
            <>
              <Skeleton active />
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
              <br />
              <Skeleton active />
            </>
          ) : (
            <>
              <AttendanceInformation
                formattedDate={formattedDate}
                formik={formik}
                mode={mode}
                staffData={staffData || []}
              />
              <BreakTime formik={formik} />
              <WorkInformation1 formik={formik} />
              <WorkInformation2 formik={formik} />
              <ButtonWrapper>
                <div className={"save-cancel"}>
                  <Button
                    btnText={t("Cancel")}
                    shape={"round"}
                    onClick={() => router.back()}
                    disabled={isLoading || isCreateLoading}
                  />
                  <Button
                    btnText={id ? t("Update") : t("Save")}
                    shape={"round"}
                    type={"primary"}
                    isLoading={isCreateLoading}
                    onClick={formik.handleSubmit}
                  />
                </div>
                {id ? (
                  <div className={"reset-button"}>
                    <Button
                      btnText={t("Reset attendance information")}
                      shape={"round"}
                      btnBg={"#EAF9FF"}
                      onClick={() => handleReset()}
                      disabled={isLoading || isCreateLoading}
                    />
                  </div>
                ) : null}
              </ButtonWrapper>
            </>
          )}
        </Card>
      </CardWrapper>
    </>
  )
}

export { DayListComponent }
