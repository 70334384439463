import {
  MonthPagination,
  BusinessCalendarPagination,
  theme,
  SerialNumberBox,
} from "@project/common"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const SectionOneWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .top__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .pagination__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      @media screen and (max-width: ${theme.breakpoints.xl}) {
        flex-direction: column;
        row-gap: 0.5rem;
        align-items: flex-start;
      }
    }

    .text__content {
      margin-top: 5px;
      width: 55%;
      ${theme.typography["body-regular"]};
      @media screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
      }
    }
  }
`
export const SectionOne = ({
  children,
  change = "month",
  hideMonthPagination,
  handleYearChange,
  currentMonth,
  currentYear,
  handleMonthChange,
}: {
  children: React.ReactNode
  change?: "year" | "month"
  hideMonthPagination?: boolean
  handleYearChange?: (value: any) => void
  handleMonthChange?: (year: any, month: any) => void
  currentMonth?: number
  currentYear?: number
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SectionOneWrapper>
      <div className={"top__content"}>
        <div className={"pagination__container"}>
          {!hideMonthPagination ? (
            <BusinessCalendarPagination
              currentMonth={currentMonth || 1}
              currentYear={currentYear}
              handleMonthChange={(_, m) => {
                handleMonthChange(currentYear, m)
              }}
              handleYearChange={(_, year) => handleYearChange(year)}
            />
          ) : (
            <div></div>
          )}
          <MonthPagination
            change={change}
            showDate={false}
            currentMonth={currentMonth || 1}
            currentYear={currentYear}
            onYearChange={handleYearChange}
            onMonthChange={handleMonthChange}
            currentText={change === "year" ? t("This year") : t("This Month")}
            prevText={
              change === "year" ? t("Previous year") : t("Previous month")
            }
            nextText={
              change === "year" ? t("Following year") : t("Following month")
            }
          />
        </div>
        <SerialNumberBox
          number={1}
          text={t(
            "Set business days, presence or absence of Meales, business hours, and service hours",
          )}
        />
        <div className={"text__content"}>
          {t(
            `From the calendar below, click "Open", "Holiday", and set the business hours .Please set the time by selecting the pull-down menu for business hours and service hours for each day.`,
          )}
        </div>
      </div>
      {children}
    </SectionOneWrapper>
  )
}
