import React, { useMemo } from "react"

import dayjs from "dayjs"
import { t } from "i18next"
import { useMutation, useQuery } from "react-query"
import { useRouter } from "next/router"
import { ColumnsType } from "antd/es/table"

import {
  Box,
  Button,
  Table,
  theme,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { ActionConfirmPopupModal } from "../common/ActionConfirmPopupModal"

import {
  getDailyLeaveRecord,
  updatePaidLeaveForRequest,
} from "../../../services"

import { LeaveRequestDataType } from "../../../types/userAttendanceMgmt.types"

import {
  PAGE_SIZE,
  APPROVED_STATUS,
  SLASH_DATE_FORMAT,
} from "../../../constants"
import { reFormatLeaveRecord } from "./utils/reFormatLeaveRecord"
import { Flex, Popconfirm, Tag } from "antd"
import { useAuthContext } from "../../../context"

export const LeaveRequestListTab = ({
  hasWriteAccess,
}: {
  hasWriteAccess: boolean
}) => {
  const { query } = useRouter()
  const [selectedRows, setSelectedRows] = React.useState<React.Key[]>([])
  const { showToast } = useNotification()

  const [updateParams] = useUpdateSearchParams()
  const { facilities } = useAuthContext()

  const defaultFacilityId = facilities?.[0]?.value

  const page = isNaN(Number(query?.page)) ? 1 : Number(query?.page)

  const memoizedQuery = useMemo(() => {
    const validDate = dayjs(query?.date as string).isValid
      ? dayjs(query?.date as string)
      : dayjs()

    const facility = query?.facility_id
      ? (query?.facility_id as string)
      : defaultFacilityId + ""
    const user_id = query?.user_id ? (query?.user_id as string) : ""
    const year = validDate.year() + ""
    const month = validDate.month() + 1 + ""

    return {
      facility,
      user_id,
      year,
      month,
    }
  }, [query])

  const {
    data: leaveRecord,
    isLoading: loadingLeaveRecord,
    refetch: refetchLeaveRecord,
  } = useQuery({
    queryKey: ["user-attendance-leave-request-record", page, memoizedQuery],
    queryFn: () =>
      getDailyLeaveRecord({
        page: page + "",
        pageSize: PAGE_SIZE + "",
        ...memoizedQuery,
      }),
    select: (response) => reFormatLeaveRecord(response),
  })

  const handlePagination = (page) => {
    updateParams({ ...query, page }, "/user-attendance-management")
  }

  const { mutate: updatePaidLeave, isLoading: isUpdatingPaidLeave } =
    useMutation({
      mutationFn: updatePaidLeaveForRequest,
      onSuccess: () => {
        refetchLeaveRecord()
        showToast({
          type: "success",
          message: t("Updated Successfully"),
        })
      },
      onError: () => {
        showToast({
          type: "error",
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const Column: ColumnsType<LeaveRequestDataType> = [
    {
      title: t("Username2"),
      dataIndex: "username",
      key: "username",
      align: "center",
      width: 100,
    },
    {
      title: t("Application Date"),
      dataIndex: "application_date",
      key: "application_date",
      align: "center",
      width: 120,
      render: (val: LeaveRequestDataType["application_date"]) =>
        dayjs(val).format(SLASH_DATE_FORMAT + " HH:mm:ss"),
    },
    {
      title: t("Leave Requested Date"),
      dataIndex: "leave_requested_date",
      key: "leave_requested_date",
      align: "center",
      width: 200,
      render: (val: LeaveRequestDataType["leave_requested_date"]) =>
        dayjs(val).isValid() ? dayjs(val).format(SLASH_DATE_FORMAT) : "-",
    },
    {
      title: t("Reason"),
      dataIndex: "reason",
      key: "reason",
      align: "center",
      render: (val) => (!val ? "-" : val),
    },
    {
      title: t("Action2"),
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 120,
      render: (_, rec) => {
        return rec.status === 0 ? (
          <ActionConfirmPopupModal
            id={[rec?.id]}
            requestedDate={rec?.leave_requested_date}
            variant={"SINGLE_ROW_ACTION"}
            refetch={refetchLeaveRecord}
            disabled={!hasWriteAccess}
          />
        ) : (
          <Flex vertical>
            <Tag
              color={rec.status === APPROVED_STATUS ? "success" : "error"}
              style={{ margin: "auto" }}
            >
              {rec.status === APPROVED_STATUS ? t("Approved") : t("Rejected")}
            </Tag>
            {/* show mark paid option only for approved requests */}
            {rec.status === APPROVED_STATUS && (
              <Popconfirm
                title={
                  (rec.is_paid_leave
                    ? t("Set as unpaid leave")
                    : t("Set as paid leave")) + "?"
                }
                onConfirm={() =>
                  updatePaidLeave({
                    id: rec.id,
                    is_paid_leave: !rec.is_paid_leave,
                  })
                }
                disabled={!hasWriteAccess}
              >
                <Button
                  btnText={
                    rec.is_paid_leave ? t("Mark Unpaid") : t("Mark Paid")
                  }
                  type={"link"}
                  shape={"round"}
                  textColor={
                    !hasWriteAccess
                      ? theme.colors["disabled-text"]
                      : rec.is_paid_leave
                        ? theme.colors.error
                        : theme.colors.success
                  }
                  hoverBorderColor={"none"}
                  style={{
                    textDecoration: "underline",
                  }}
                />
              </Popconfirm>
            )}
          </Flex>
        )
      },
    },
  ]

  return (
    <div>
      <Table
        current={page}
        columns={Column}
        pageSize={PAGE_SIZE}
        showPaginationOf={"both"}
        total={leaveRecord?.count}
        loading={loadingLeaveRecord || isUpdatingPaidLeave}
        dataSource={leaveRecord?.data}
        onChange={(page) => handlePagination(page)}
        scroll={{
          x: 800,
        }}
        rowSelection={{
          type: "checkbox",
          columnWidth: 60,
          onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRows(newSelectedRowKeys)
          },
        }}
      >
        {selectedRows.length > 0 && (
          <Box mb={16} display={"flex"} justify={"flex-end"}>
            <ActionConfirmPopupModal
              requestedDate={dayjs()}
              variant={"BULK_ACTION"}
              id={selectedRows}
              refetch={refetchLeaveRecord}
              disabled={!hasWriteAccess}
            />
          </Box>
        )}
      </Table>
    </div>
  )
}
