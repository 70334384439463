import React, { useState } from "react"

// packages
import { Flex } from "antd"

// commons | packages
import { Box, ErrorMassage, SelectInput } from "@project/common"

// utils
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { getFuriFilteredOptions } from "../../../utils/getFuriFilteredOptions"
import { getUnusedStaffs } from "../../UserTransportManagementContainer/utils/getUnusedStaffs"

// types
import { FuriSelectInputProps } from "../types"

// !## MAIN COMPONENT
export const FuriSelectInput: React.FC<FuriSelectInputProps> = ({
  value,
  name,
  error,
  options,
  onChange,
  disabled,
  isLoading,
  showAllDrivers,
  ...rest
}) => {
  const [currentOptions] = useState(options)
  const [furi, setFuri] = useState<string | 0 | null>(null)

  const handleSelectInputChange = (newId: number) => {
    onChange(+newId)
  }

  return (
    <Box display={"flex"} direction={"column"} align={"flex-start"}>
      <Flex align={"center"} gap={"8px"}>
        <Box maw={58} miw={58}>
          <SelectInput
            value={furi}
            width={"58px"}
            name={"furigana"}
            placeholder={"--"}
            disabled={disabled}
            onChange={(val) => {
              setFuri(val)
              onChange(null)
            }}
            options={FuriganaAlphabetsOption}
          />
        </Box>
        <Box maw={118} miw={118}>
          <SelectInput
            name={name}
            value={!value ? null : value}
            width={"118px"}
            placeholder={"--"}
            disabled={disabled}
            popupMatchSelectWidth={false}
            loading={isLoading}
            options={
              rest.hasDependency === true && !showAllDrivers
                ? getUnusedStaffs(
                    currentOptions,
                    value,
                    rest.dependencies,
                    furi,
                  )
                : getFuriFilteredOptions(options, furi)
            }
            onChange={handleSelectInputChange}
          />
        </Box>
      </Flex>
      {error && <ErrorMassage message={error} />}
    </Box>
  )
}
