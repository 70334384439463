import React, { useState } from "react"
import { WRBusinessActivityStatement } from "../../../types"
import { BusinessActivityStatementTabEditContent } from "./BusinessActivityStatementTabEditContent"
import { BusinessActivityStatementTabListContent } from "./BusinessActivityStatementTabListContent"

export const BusinessActivityStatementTab = ({
  ...props
}: {
  params: any
  setSearchParams: React.Dispatch<React.SetStateAction<any>>
  hasEditAccess: (val) => boolean
}): JSX.Element => {
  const [selectedCol, setSelectedCol] =
    useState<WRBusinessActivityStatement | null>()

  if (selectedCol) {
    return (
      <BusinessActivityStatementTabEditContent
        {...props}
        selectedCol={selectedCol}
        setSelectedCol={setSelectedCol}
      />
    )
  }
  return (
    <BusinessActivityStatementTabListContent
      setSelectedCol={setSelectedCol}
      hasEditAccess={(facilityId) => props.hasEditAccess(facilityId)}
      {...props}
    />
  )
}
