import { Card, Grid, Table, usePrint } from "@project/common"
import { ColumnsType } from "antd/lib/table"
import { useRouter } from "next/router"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { PlanAndMonitoringActionBtns } from "../../../components"
import { ACHIEVEMENT_GOAL_TYPE, EVALUATION_TYPES } from "../../../constants"
import { MonitoringDetailFooter } from "./MonitoringDetailFooter"
import { MonitoringDetailPrintPage } from "./MonitoringDetailPrintPage/MonitoringDetailPrintPage"
import { MonitoringSummary } from "./MonitoringSummary"
const Wrapper = styled.section`
  margin-top: 16px;
  .actual_card_content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`
export const MonitoringDetailContent = ({
  monitoring,
  title,
  params,
  scrollContent,
  hasEditAccess,
}: {
  monitoring: any
  title: string
  params?: any
  scrollContent: any
  hasEditAccess: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const columns: ColumnsType<any[]> = [
    {
      title: t(" "),
      key: "edit",
      align: "center",
      render: (_, __, i) => i + 1,
    },
    {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("Items (personal needs, etc.)")}
        </span>
      ),
      key: "items",
      align: "center",
      width: 231,
      render: (data) => {
        return <p>{data?.title || ""}</p>
      },
    },
    {
      title: t("Specific goals"),
      key: "specific_goal",
      align: "center",
      width: 231,
      render: (row) => {
        const goals = Array.isArray(
          row?.user_monitoring_content?.achievement_goals,
        )
          ? row?.user_monitoring_content?.achievement_goals
          : JSON.parse(row?.user_monitoring_content?.achievement_goals)
        return (
          <ul style={{ margin: 0, padding: 0, textAlign: "left" }}>
            {goals?.map((goal, index) => <li key={index}>{goal}</li>)}
          </ul>
        )
      },
    },
    {
      title: t("Goal achievement"),
      key: "achievement_goal",
      align: "center",
      render: (row) => {
        return (
          <>
            <div>
              {
                ACHIEVEMENT_GOAL_TYPE?.find(
                  (v) =>
                    v?.value ===
                    row?.user_monitoring_content?.achievement_goal_type,
                )?.label
              }
            </div>

            {row?.achievement_goal_type === 4 && (
              <div>
                {row?.user_monitoring_content?.achievement_goal_type_text}
              </div>
            )}
          </>
        )
      },
    },
    {
      title: t("Evaluation"),
      key: "evaluation",
      align: "center",
      render: (row) => {
        return (
          <>
            <div>
              {
                EVALUATION_TYPES?.find(
                  (v) =>
                    v?.value === row?.user_monitoring_content?.evaluation_type,
                )?.label
              }
            </div>
            {row?.user_monitoring_content?.evaluation_type === 4 && (
              <div>{row?.user_monitoring_content?.evaluation_type_text}</div>
            )}
          </>
        )
      },
    },
    {
      title: t("Consideration"),
      key: "consideration",
      align: "center",
      render: (row) => {
        const study = Array.isArray(row?.user_monitoring_content?.study)
          ? row?.user_monitoring_content?.study
          : JSON.parse(row?.user_monitoring_content?.study)
        return (
          <div>
            {study?.map((stdy, index) => <div key={index}>{stdy}</div>)}
          </div>
        )
      },
    },
  ]
  const contentRef = useRef<HTMLDivElement>(null)
  const { isPrinting, handlePrint } = usePrint({
    size: "auto",
    margin: "10mm",
    contentRef,
    pageStyle: `margin:0.5cm 0.8cm;`,
  })

  return (
    <Wrapper>
      <Card title={title}>
        <div className={"actual_card_content"}>
          <PlanAndMonitoringActionBtns
            editBtnText={t("Edit Monitoring")}
            isLoading={false}
            hideEditBtn
            onClick={(btnType) => {
              if (btnType === "back") {
                router.back()
              }
              if (btnType === "print") {
                handlePrint()
              }
            }}
            hasPDF={false}
            hasEditAccess={hasEditAccess}
          />
          <MonitoringSummary summaryData={monitoring} />
          <Table
            columns={columns}
            noHeaderBorder
            headerCellPaddingBlock={"14px"}
            cellPaddingBlock={15}
            dataSource={monitoring?.user_monitoring_item || []}
            scroll={{ x: 900 }}
          />
          <div className={"monitoring_goal_Container"}>
            <Grid
              labelContent={t("Long term goal")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"13px 16px"}
            >
              <p>{monitoring?.user_support_plan?.long_term_goal || "--"}</p>
            </Grid>
            <Grid
              labelContent={t("Consideration for long-term goals")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              {monitoring?.long_term_goal_study}
            </Grid>
            <Grid
              labelContent={t("Short term goal")}
              className={"row__header"}
              background
              labelSpan={6}
              padding={"13px 16px"}
            >
              <p>{monitoring?.user_support_plan?.short_term_goal || "--"}</p>
            </Grid>
            <Grid
              labelContent={t("Consideration for short term goal")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              {monitoring?.short_term_goal_study}
            </Grid>
          </div>
          <div className={"monitoring_hope_container"}>
            <Grid
              labelContent={t("Self hope")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              {monitoring?.user_hope}
            </Grid>
            <Grid
              labelContent={t("Family hope")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              {monitoring?.family_hope}
            </Grid>
            <Grid
              labelContent={t("Relatives hope")}
              className={"row__header"}
              background
              labelSpan={6}
            >
              {monitoring?.concern_hope}
            </Grid>
          </div>
          <MonitoringDetailFooter
            monitoring={monitoring}
            params={params}
            scrollContent={scrollContent}
            hasEditAccess={hasEditAccess}
          />
          <PlanAndMonitoringActionBtns
            editBtnText={t("Edit monitoring")}
            isLoading={false}
            onClick={(btnType) => {
              if (btnType === "back") {
                router.back()
              }
              if (btnType === "print") {
                handlePrint()
              }
              if (btnType === "edit") {
                router.push(
                  `/monitoring-list/edit/${router?.query?.id}?user_id=${monitoring?.user_id}`,
                )
              }
            }}
            hasPDF={false}
            hasEditAccess={hasEditAccess}
          />
        </div>
      </Card>
      {isPrinting && (
        <div style={{ display: "none" }}>
          <MonitoringDetailPrintPage
            monitoring={monitoring}
            params={params}
            ref={contentRef}
            isPrinting={true}
          />
        </div>
      )}
    </Wrapper>
  )
}
