import { theme } from "@project/common"
import styled from "styled-components"
export const PlanDetailWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  #main__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    gap: 16px;
    .print__title {
      color: ${theme.colors.text};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 8px;
      display: none;
      @media print {
        display: block !important;
      }
    }
  }
  @media only print {
    margin-top: 0 !important;
  }
`
export const PlanDetailContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const DetailInfoContent = styled.div`
  #transportation_info {
    display: flex;
    flex-direction: column;
  }
  @media only print {
    margin-bottom: 8px !important;
  }
`

//footer section styled
export const FooterWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  page-break-inside: avoid !important;
  .left__container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex: 1;
    .content__container {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .content {
        border-bottom: 1px solid ${theme.colors.text};
        display: flex;
        min-height: 40px;
        width: 300px;
        justify-content: space-between;
        align-items: center;
        &--image {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 5px 0;
          width: 100%;
          gap: 10px 35px;
          flex-wrap: wrap;
          img {
            height: 100%;
            max-width: 100%;
            object-fit: contain;
            max-height: 40px;
          }
        }
        &--icon {
          display: flex;
          justify-content: center;
          align-content: center;
          height: 100%;
          width: 40px;
          color: ${theme.colors.text};
          font-weight: 500;
        }
      }
      .date {
        display: flex;
        gap: 2.5rem;
        align-items: center;
        &--info {
          display: flex;
          gap: 2rem;
          color: ${theme.colors.border};
          @media screen and (max-width: ${theme.breakpoints.sm}) {
            gap: 0.5rem;
          }
        }
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          gap: 0.5rem;
        }
      }
    }
  }
  .right__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    &--content {
      .content--support {
        display: flex;
        column-gap: 8px;
        .sign__field {
          display: flex;
          column-gap: 50px;
          border-bottom: 1px solid ${theme.colors.text};
          padding-bottom: 10px;
        }
      }
    }
    #sign-img {
      height: auto;
      max-height: 26px;
      max-width: 163px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    row-gap: 16px;
    .right__container {
      align-items: flex-start;
    }
  }
  @media print {
    margin-top: 16px !important;
    .content--icon {
      display: none !important;
    }
  }
`
