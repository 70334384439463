import styled from "styled-components"

export const StyledCalendarCellContent = styled.div`
  padding: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .shift__selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .data__container {
    background: rgba(161, 229, 234, 0.4);
    padding: 8px 2px;
    .hours__break__container {
      display: flex;
      flex-direction: column;
      padding: 0 7px;
      row-gap: 4px;
      .hours__content {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .hours__selector {
          display: flex;
          flex-wrap: wrap;
          column-gap: 4px;
          row-gap: 6px;
          align-items: center;

          .hours {
            display: flex;
            align-items: center;
            column-gap: 4px;

            @media print {
              flex-wrap: wrap;
              row-gap: 4px;
            }
          }
        }
      }
    }
    .break_time {
      display: flex;
      align-items: center;
      column-gap: 4px;
      @media print {
        flex-wrap: wrap;
      }
    }
    .btn__container {
      margin-top: 8px;
      button {
        width: fit-content;
        span {
          margin-inline-end: 8px !important;
          &:last-child {
            margin: 0 !important;
            margin-inline-end: 0px !important;
          }
        }
        &.ant-btn.ant-btn-round {
          padding-inline-start: 10px !important;
          padding-inline-end: 10px !important;
        }
      }
      @media print {
        display: none;
      }
    }
  }
`
