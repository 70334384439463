import {
  Breadcrumb,
  Button,
  PageTitle,
  scrollToSelectedElement,
} from "@project/common"
import { Flex, Skeleton } from "antd"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { AlertBox, PageHead } from "../../../components"
import { AuthContext } from "../../../context"
import { useApplicationManagement } from "../../../context/ApplicationManagementContext"
import { RecurringSchedulePermission } from "../../../utils/PermissionKeys"
import { hasPermissionForMenu } from "../../../utils/sidebarUtils"
import { ApplicationManagementList } from "./ApplicationManagementList/ApplicationManagementList"
import { ApplicationManagementOperation } from "./ApplicationManagementList/ApplicationManagementOperation"

export const ApplicationManagement = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const scrollContent = useRef<HTMLDivElement>(null)
  const { user, facilitiesWithConfirmedBilling, response, isLoading, params } =
    useApplicationManagement()
  const title = t("{{user_name}}さん’sUser application management", {
    user_name: user?.data?.username || "",
  })

  const { isOwner, permissions } = useContext(AuthContext)

  const hasBulkScheduleWriteAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForMenu(permissions, RecurringSchedulePermission, "write"),
    [isOwner, permissions],
  )

  return (
    <>
      <PageHead>{title}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: <Link href={`/users`}>{t("User Management")}</Link>,
          },
          {
            title: (
              <Link href={`/users/profile/${router?.query.user_id}`}>
                {t("User Profile ")}
              </Link>
            ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
        extra={
          <Button
            btnText={t("Bulk schedule settings")}
            shape={"round"}
            type={"primary"}
            onClick={() =>
              router.push(
                `/users/profile/${router?.query.user_id}/recurring-schedule-setting-list/change-form?mode=add&year=${params?.year}&month=${params?.month}`,
              )
            }
            disabled={!hasBulkScheduleWriteAccess}
          />
        }
      />
      <Flex vertical gap={16}>
        {facilitiesWithConfirmedBilling?.data?.length > 0 ? (
          <AlertBox
            width={"100%"}
            heading={t(
              "There are facility where the billing information has been confirmed.",
            )}
            subHeading={t(
              `When editing, registering, or deleting, please do so while checking with the person in charge of billing. There is a possibility that a discrepancy may occur with the confirmed billing information.`,
            )}
            expandHeading={t(
              "Display the facility establishments where the billing information has been confirmed",
            )}
            expandText={""}
            data={
              facilitiesWithConfirmedBilling?.data?.map((d) => {
                return {
                  label:
                    d?.year +
                    "年" +
                    d?.month?.toString().padStart(2, "0") +
                    "月",
                  value: d?.facility_name,
                }
              }) || []
            }
          />
        ) : null}

        <ApplicationManagementOperation
          onDisplayChange={() => {
            scrollToSelectedElement(scrollContent)
          }}
        />
        {!isLoading && (
          <>
            {!response?.data?.stats?.facility_contract?.id ? (
              <AlertBox
                heading={t("User does not have any contracted facility")}
                isCollapseAble={false}
                width={"100%"}
              />
            ) : (
              <>
                {!response?.data?.monthly_schedule?.length ? (
                  <AlertBox
                    heading={t(
                      "Business day is not set for contracted facility. User facility application may not be reflected.",
                    )}
                    isCollapseAble={false}
                    width={"100%"}
                  />
                ) : null}
              </>
            )}
          </>
        )}
        {isLoading ? (
          <Flex vertical gap={6}>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Flex>
        ) : (
          <>
            {/* show only if user has contract */}
            {!!response?.data?.stats?.facility_contract?.id && (
              <Flex vertical ref={scrollContent}>
                <ApplicationManagementList />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
