import { useFormik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import locale from "antd/lib/date-picker/locale/ja_JP"
import { FooterContainers, ModalBodyContainer } from "./WorkCertificates.style"
import {
  Button,
  ConfirmationModal,
  DatePicker,
  ErrorMassage,
  FileUploader,
  InputAreaField,
  InputField,
  Modal,
  Tag,
  theme,
} from "@project/common"
import dayjs from "dayjs"
import { StaffOtherInfoContent } from "./StaffOtherInfoContent"

interface IProps {
  data: any[]
  onSave: (data: any, operation: string) => void
  onDelete: (id: string | number) => void
}
const FORMAT = "YYYY年MM月DD日"

const initialValues = {
  name: "",
  start_date: null,
  end_date: null,
  working_days: "",
  work_name: "",
  work_detail: "",
  document: "",
}
export const WorkCertificates: React.FC<IProps> = ({
  data,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [operation, setOperation] = useState<"edit" | "create">("create")
  const [selectedId, setSelectedId] = useState<any>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [file, setFile] = useState(null)
  const [isFileUploading, setFileUploading] = useState(false)
  const [fileName, setFileName] = useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      start_date: Yup.date().required(t("Please select")),
      end_date: Yup.date().required(t("Please select")),
      working_days: Yup.number()
        .max(99999, t("Please enter number within 6 characters."))
        .required(t("Please enter")),
    }),
    onSubmit: (values) => {
      if (operation === "create") {
        onSave({ tempId: new Date().getTime(), ...values }, operation)
      } else {
        onSave({ tempId: selectedId, ...values }, operation)
      }
      setOperation("create")
      handleReset()
    },
  })
  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })
    setIsModalOpen(false)
  }

  const parts = formik?.values?.document?.split("/")
  const lastPart = parts?.length && parts[parts?.length - 1]
  const resultString = lastPart?.replace(/%7C\d+/, "")

  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Edit")}</span>,
      key: "Edit",
      render: (row) => {
        return (
          <div
            onClick={() => {
              setOperation("edit")
              setSelectedId(row?.id || row?.tempId)
              formik.resetForm({
                values: {
                  name: row?.name,
                  working_days: row?.working_days,
                  work_detail: row?.work_detail,
                  work_name: row?.work_name,
                  start_date: dayjs(row?.start_date),
                  end_date: dayjs(row?.end_date),
                  document: row?.document,
                },
              })

              setIsModalOpen(true)
            }}
          >
            <span style={{ color: theme.colors.action, cursor: "pointer" }}>
              {t("Edit")}
            </span>
          </div>
        )
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Facility or business name")}
        </span>
      ),
      key: "name",
      dataIndex: "name",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Work period")}</span>,
      key: "work_period",
      render: (row) => (
        <div>
          <span>{dayjs(row?.start_date).format(FORMAT)}</span> {"- "}
          <span>{dayjs(row?.end_date).format(FORMAT)}</span>
        </div>
      ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Number of days spent working")}
        </span>
      ),
      key: "working_days",
      dataIndex: "working_days",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Work details")}</span>,
      key: "work_detail",
      dataIndex: "work_detail",
    },
  ]

  return (
    <>
      <StaffOtherInfoContent
        title={t("List of work experience certificates")}
        columns={columns}
        dataSource={data}
        btnText={t("Register work experience certificates")}
        onClick={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <Modal
          title={t("Register/Update work experience certificates")}
          closeIcon={<span style={{ marginLeft: "5px" }}>{t("Close")}</span>}
          open={isModalOpen}
          onCancel={() => {
            setSelectedId(null)
            setOperation("create")
            handleReset()
            setIsModalOpen(false)
          }}
          width={800}
          footer={null}
          maskClosable={false}
          destroyOnClose
          centered
          styles={{
            body: {
              overflowY: "auto",
              maxHeight: "calc(100vh - 100px)",
            },
          }}
          wrapClassName={"instructor__info-modal"}
        >
          <form onSubmit={formik.handleSubmit}>
            <ModalBodyContainer>
              <div className={"body_content"}>
                <div className={"form_control"}>
                  <div className={"label"}>
                    {t("Facility or business name")}
                  </div>
                  <div className={"form_input"}>
                    <InputField
                      bg={theme.colors.white}
                      padding={"8px 12px"}
                      borderRadius={"0px"}
                      name={"name"}
                      value={formik?.values?.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>
                    <span> {t("Work period")}</span>
                    <Tag
                      title={t("Required")}
                      defaultBg={theme.colors.error}
                      textColor={theme.colors.white}
                    />
                  </div>
                  <div className={"form_input"}>
                    <div className={"work_period"}>
                      <div
                        id={"w_start_date"}
                        style={{
                          position: "relative",
                        }}
                      >
                        <DatePicker
                          name={"start_date"}
                          locale={locale}
                          format={FORMAT}
                          getPopupContainer={() =>
                            document.getElementById("w_start_date")
                          }
                          date={
                            dayjs(formik?.values?.start_date)?.isValid()
                              ? dayjs(formik?.values?.start_date)
                              : undefined
                          }
                          onDateChange={(e) =>
                            formik.setFieldValue("start_date", dayjs(e))
                          }
                          inputReadOnly
                        />
                      </div>
                      {"~"}
                      <div
                        id={"w_end_date"}
                        style={{
                          position: "relative",
                        }}
                      >
                        <DatePicker
                          name={"end_date"}
                          locale={locale}
                          format={FORMAT}
                          getPopupContainer={() =>
                            document.getElementById("w_end_date")
                          }
                          date={
                            dayjs(formik?.values?.end_date)?.isValid()
                              ? dayjs(formik?.values?.end_date)
                              : undefined
                          }
                          onDateChange={(e) => {
                            formik.setFieldValue("end_date", dayjs(e))
                          }}
                          inputReadOnly
                          disabledDate={(current) =>
                            current.isBefore(dayjs(formik.values?.start_date))
                          }
                        />
                      </div>
                    </div>
                    {((formik.errors.start_date && formik.touched.start_date) ||
                      (formik.errors.end_date && formik.touched.end_date)) && (
                      <ErrorMassage
                        message={
                          formik.errors.start_date || formik.errors.end_date
                            ? t("Please select")
                            : ""
                        }
                        mt={"0px"}
                      />
                    )}
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>
                    {t("Number of days spent working")}
                    <Tag
                      title={t("Required")}
                      defaultBg={theme.colors.error}
                      textColor={theme.colors.white}
                    />
                  </div>
                  <div className={"form_input"}>
                    <div className={"days"}>
                      <InputField
                        bg={theme.colors.white}
                        padding={"8px 12px"}
                        borderRadius={"0px"}
                        name={"working_days"}
                        value={formik?.values?.working_days}
                        onChange={(event) => {
                          if (!/[0-9]/.test(event.target.value)) {
                            event.preventDefault()
                          }
                          formik.setFieldValue(
                            "working_days",
                            event.target.value?.slice(0, 5),
                          )
                        }}
                        maxLength={5}
                        type={"number"}
                        min={0}
                        onKeyDown={(e) => {
                          if ([69, 189]?.includes?.(e?.keyCode))
                            e.preventDefault()
                        }}
                      />
                      <span>{t("Days ")}</span>
                    </div>
                    {formik.touched.working_days &&
                      formik.errors.working_days && (
                        <ErrorMassage message={formik.errors.working_days} />
                      )}
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Work details")}</div>
                  <div className={"form_input"}>
                    <div className={"work"}>
                      <label style={{ whiteSpace: "nowrap" }}>
                        {t("work")}
                        <span>{":"}</span>
                      </label>
                      <InputField
                        bg={theme.colors.white}
                        padding={"8px 12px"}
                        borderRadius={"0px"}
                        name={"work_name"}
                        value={formik?.values?.work_name}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className={"work_detail"}>
                      <label>{t("write work details in detail")}</label>
                      <InputAreaField
                        bg={theme.colors.white}
                        padding={"8px 12px"}
                        borderRadius={"0px"}
                        name={"work_detail"}
                        value={formik?.values?.work_detail}
                        onChange={formik.handleChange}
                        rows={5}
                      />
                    </div>
                  </div>
                </div>
                <div className={"form_control"}>
                  <div className={"label"}>{t("Qualification Document")}</div>
                  <div className={"form_input"}>
                    <FileUploader
                      currentImage={file || resultString}
                      onChange={(val) => {
                        setFile(val)
                        formik?.setFieldValue("document", val)
                      }}
                      setFileName={setFileName}
                      fileName={resultString || fileName}
                      isLoading={setFileUploading}
                      label={t("(File types supported: PDF,PNG,JPEG)")}
                      inputType={"file"}
                      disableUploadBtn={false}
                      acceptType={".pdf, .png, .jpeg"}
                    />
                  </div>
                </div>
              </div>
            </ModalBodyContainer>
            <br />
            <FooterContainers>
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                }}
              >
                <Button
                  btnText={t("Cancel")}
                  borderColor={theme.colors.action}
                  shape={"round"}
                  onClick={() => handleReset()}
                  disabled={isFileUploading}
                />
                <Button
                  btnText={t("Save")}
                  shape={"round"}
                  type={"primary"}
                  htmlType={"submit"}
                  disabled={isFileUploading}
                />
              </div>
              {operation === "edit" && (
                <Button
                  btnText={t("Delete")}
                  borderColor={theme.colors.error}
                  btnBg={theme.colors.error}
                  textColor={theme.colors.white}
                  shape={"round"}
                  onClick={() => setOpenDeleteModal(!openDeleteModal)}
                />
              )}
            </FooterContainers>
          </form>
        </Modal>
      )}

      {openDeleteModal && (
        <ConfirmationModal
          open={openDeleteModal}
          maskClosable={false}
          onCancel={() => {
            setOpenDeleteModal(false)
          }}
          title={t("Delete")}
          centered
          onOk={() => {
            setOperation("create")
            onDelete(selectedId)
            setOpenDeleteModal(false)
            handleReset()
          }}
        />
      )}
    </>
  )
}
