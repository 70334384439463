import React, { useState } from "react"
import {
  AttendanceScheduleNoticeWrapper,
  StyledAttendanceMonthlyScheduleWrapper,
} from "./AttendanceMonthlySchedule.style"
import { AttendanceScheduleOperation } from "../AttendanceScheduleOperation"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import { AttendanceMonthlyScheduleCalendarView } from "./AttendanceMonthlyScheduleCalendarView"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { getMonthlyAttendanceStaffShiftSchedule } from "../../../services/attendace-schedule.services"
import {
  useFetchAllShiftMaster,
  useFetchAllStaff,
} from "../../../hooks/useFetchData"
import { Breadcrumb, Card, PageTitle, Skeleton } from "@project/common"
import { PageHead } from "../../../components"
import { MONTH_DATE_FORMAT } from "../../../constants"
import { useAuthContext } from "../../../context/AuthContext"

export const AttendanceMonthlySchedule = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { facilities } = useAuthContext()
  const { year, month, facility_id, staff_id } = router?.query as any
  const { staffData } = useFetchAllStaff({
    page: 1,
    pageSize: "Infinity",
  })
  const [searchParams, setSearchParams] = useState({
    facility_id: facility_id || String(facilities?.[0]?.id) || "",
    date: dayjs([year, +month - 1]).isValid()
      ? dayjs([year, +month - 1])
      : dayjs(),
    staff_id: staff_id || staffData?.[0]?.value || "",
  })
  const { shiftData } = useFetchAllShiftMaster({
    page: 1,
    pageSize: "Infinity",
    addInitialOption: true,
    targetIds: ["1"],
  })

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["attendance_monthly_schedule", searchParams],
    queryFn: () => getMonthlyAttendanceStaffShiftSchedule(searchParams),
    refetchOnWindowFocus: false,
  })

  const staffName = staffData?.find((v) => v?.value == searchParams?.staff_id)
    ?.label
  const title = t("{{staffName}} {{date}} Attendance schedule", {
    staffName: staffName,
    date: dayjs(searchParams?.date)?.format(MONTH_DATE_FORMAT),
  })
  //show alert info only if some response.data have from_week_pattern=true
  const showAlertInfo = response?.data?.some(
    (value) => value?.from_week_pattern,
  )

  return (
    <>
      <PageHead>{title}</PageHead>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: (
              <Link href={"/attendance-schedule"}>
                {t("Attendance schedule")}
              </Link>
            ),
          },
          {
            title: title,
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
      />
      <StyledAttendanceMonthlyScheduleWrapper>
        <AttendanceScheduleOperation
          params={searchParams}
          setSearchParams={setSearchParams}
          staffData={staffData}
        />
        {!isLoading && showAlertInfo && (
          <AttendanceScheduleNoticeWrapper>
            <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
            <div>
              <div>{t("It reflects the work pattern of the week.")}</div>
              <div>
                {t(
                  "Weekly work patterns: Sun/unspecified, Mon/early, Tue/early, Wed/early, Thu/early, Fri/early, Sat/unspecified",
                )}
              </div>
              <div className={"with-underline"}>
                <Link href={`/staff-master/edit/${searchParams?.staff_id}`}>
                  <a>{t("Click here for weekly work pattern settings")}</a>
                </Link>
              </div>
            </div>
          </AttendanceScheduleNoticeWrapper>
        )}

        {isLoading || isFetching ? (
          <Card>
            <Skeleton length={5} />
          </Card>
        ) : (
          <AttendanceMonthlyScheduleCalendarView
            params={searchParams}
            responseData={response?.data || []}
            shiftData={shiftData}
            staffName={staffName}
            setSearchParams={setSearchParams}
          />
        )}
      </StyledAttendanceMonthlyScheduleWrapper>
    </>
  )
}
