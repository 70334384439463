import {
  AccordionCard,
  ActionBtns,
  Button,
  CheckBox,
  CheckBoxGroup,
  DatePicker,
  FACILITY_TYPES,
  FacilityCheckbox,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  SelectInput,
} from "@project/common"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import type { CheckboxValueType } from "antd/es/checkbox/Group"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../context/AuthContext"
import { FuriganaAlphabetsOption } from "../../utils/common-options"

import dayjs from "dayjs"
import { useFetchAllUsers } from "../../hooks/useFetchData"
import { PlanSearchParams } from "../../types/supportPlan.types"

interface PlanSearchProps {
  queryParams?: PlanSearchParams
  handleChange: (key: string, value: any) => void
  handleBtnClicked: (type?: string, facilityIds?: any) => void
  planType?: string
}

const PlanSearch = ({
  queryParams,
  handleChange,
  handleBtnClicked,
}: PlanSearchProps): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
  })
  return (
    <AccordionCard
      accordion={true}
      defaultActiveKey={["1"]}
      items={[
        {
          key: "1",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("User name")}
                  width={"138px"}
                />
                <div className={"flex"}>
                  <SelectInput
                    width={"85px"}
                    placeholder={"--"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    name={"user_hiragana"}
                    value={queryParams?.hiragana}
                    onChange={(val) => handleChange("hiragana", val)}
                  />
                  <SelectInput
                    name={"username"}
                    width={"282px"}
                    placeholder={"--"}
                    options={
                      queryParams?.hiragana
                        ? userData?.filter(
                            (user) =>
                              user?.furigana.startsWith(queryParams?.hiragana),
                          )
                        : userData
                    }
                    value={
                      queryParams?.user_id ? String(queryParams?.user_id) : null
                    }
                    onChange={(val) => handleChange("user_id", val)}
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Use service")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={FACILITY_TYPES}
                  value={queryParams?.service}
                  onChange={(list: CheckboxValueType[]) => {
                    handleChange("service", list)
                  }}
                />
              </div>
              <div className={"search__content flex-start"}>
                <div className={"mt"}>
                  <LabelTextWithLeftArrow
                    label={t("Facility  ")}
                    width={"138px"}
                  />
                </div>
                <FacilityCheckbox
                  btnHeight={"30px"}
                  options={facilities}
                  value={queryParams?.facilityIds}
                  handleCheckUncheckAll={(type: "check" | "uncheck") => {
                    if (type === "check") {
                      const ids = facilities?.map((v) => v?.value)
                      handleChange("facilityIds", ids)
                    }
                    if (type === "uncheck") {
                      handleChange("facilityIds", [])
                    }
                  }}
                  onChange={(list: CheckboxValueType[]) => {
                    handleChange("facilityIds", list)
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Created date")}
                  width={"138px"}
                />
                <div className={"search__content-date no_row_gap"}>
                  <DatePicker
                    style={{
                      width: "160px",
                    }}
                    placeholder={"--"}
                    date={
                      dayjs(queryParams?.date_from).isValid()
                        ? dayjs(queryParams?.date_from)
                        : null
                    }
                    onDateChange={(date) => {
                      handleChange("date_from", date)
                    }}
                    format={"YYYY年MM月DD"}
                  />
                  {"~"}
                  <DatePicker
                    style={{
                      width: "160px",
                    }}
                    placeholder={"--"}
                    date={
                      dayjs(queryParams?.date_to).isValid()
                        ? dayjs(queryParams?.date_to)
                        : null
                    }
                    onDateChange={(date) => {
                      handleChange("date_to", date)
                    }}
                    format={"YYYY年MM月DD"}
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Expiry date")}
                  width={"138px"}
                />
                <div className={"search__content-date no_row_gap"}>
                  <DatePicker
                    style={{
                      width: "160px",
                    }}
                    placeholder={"--"}
                    date={
                      dayjs(queryParams?.expiry_date_from).isValid()
                        ? dayjs(queryParams?.expiry_date_from)
                        : null
                    }
                    onDateChange={(date) => {
                      handleChange("expiry_date_from", date)
                    }}
                    format={"YYYY年MM月DD"}
                  />
                  {"~"}
                  <DatePicker
                    style={{
                      width: "160px",
                    }}
                    placeholder={"--"}
                    date={
                      dayjs(queryParams?.expiry_date_to).isValid()
                        ? dayjs(queryParams?.expiry_date_to)
                        : null
                    }
                    onDateChange={(date) => {
                      handleChange("expiry_date_to", date)
                    }}
                    format={"YYYY年MM月DD"}
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Display Setting")}
                  width={"138px"}
                />
                <CheckBox
                  label={t("Show only with digital signature blank")}
                  value={queryParams?.display_settings}
                  checked={queryParams?.display_settings ? true : false}
                  onChange={(e: CheckboxChangeEvent) => {
                    handleChange("display_settings", e.target.checked)
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Create Status")}
                  width={"138px"}
                />
                <CheckBoxGroup
                  items={
                    [
                      {
                        value: "0",
                        label: t("created"),
                      },
                      {
                        value: "1",
                        label: t("Saved as draft"),
                      },
                    ] || []
                  }
                  value={queryParams?.status}
                  onChange={(list: CheckboxValueType[]) => {
                    handleChange("status", list)
                  }}
                />
              </div>
              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Reset search")}
                  size={"large"}
                  shape={"round"}
                  onClick={() => handleBtnClicked("reset", [])}
                  height={"30px"}
                />
                <Button
                  btnText={t("Search")}
                  type={"primary"}
                  size={"large"}
                  shape={"round"}
                  iconType={"search"}
                  onClick={() =>
                    handleBtnClicked("search", queryParams?.facilityIds)
                  }
                  height={"30px"}
                />
              </ActionBtns>
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}

export { PlanSearch }
