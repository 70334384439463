import { theme } from "@project/common"
import { styled } from "styled-components"
export const JointFacilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const JointFacilitySearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em;
  flex-wrap: wrap;
  .search__content {
    display: flex;
    gap: 1em;
    align-items: center;
    .search__content-date {
      display: flex;
      align-items: center;
      gap: 1em;
      flex-wrap: wrap;
      .date-content {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          flex-wrap: wrap;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.xmd}) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .flex-start {
    align-items: flex-start !important;
    .mt {
      padding-top: 0.5em;
    }
  }
`
export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  .ant-btn {
    span {
      margin-inline-end: 0px;
    }
  }
`
