import {
  ActionBtns,
  Button,
  Card,
  DatePicker,
  ErrorMassage,
  FACILITY_TYPES,
  Grid,
  InputAreaField,
  InputField,
  SelectInput,
  dynamicLangString,
  scrollToFirstErrorField,
  useNotification,
} from "@project/common"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import { useFormik } from "formik"
import {
  MonitoringFormValues,
  MonitoringFormValuesSchema,
} from "../../../types"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import {
  FormInputContent,
  GoalContainer,
  HopeContainer,
  RemarkContainer,
} from "./MonitoringForm.style"

import { Popconfirm, Spin, Typography } from "antd"
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import { AuthContext } from "../../../context/AuthContext"
import { useFetchAllUsers } from "../../../hooks/useFetchData"
import {
  deleeteUserMonitoring,
  fetchInstructors,
  getOneUserSupportPlanByUserId,
} from "../../../services"
import { japaneseAlphaRegex } from "../../../utils/validation"
import { MonitoringFormGoalsTable } from "./MonitoringFormGoalsTable"

interface MonitoringFormProps {
  defaultData?: MonitoringFormValues | undefined
  handleSave: (values: MonitoringFormValues) => void
  isLoading?: boolean
  operation?: "ADD" | "EDIT"
}

const MonitoringForm = ({
  handleSave,
  isLoading,
  operation,
  defaultData,
}: MonitoringFormProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { showToast } = useNotification()
  const { id, user_id } = router?.query as any
  const { facilities } = useContext(AuthContext)
  const [createCount, setCreateCount] = useState(null)
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false)
  const initialValues = {
    user_id: user_id || null,
    furigana: FuriganaAlphabetsOption[0].value,
    facility_id: defaultData?.facility_id || "",
    service_type: defaultData?.service_type || "",
    create_count: defaultData?.create_count || 1 || null,
    report_create_date: defaultData?.report_create_date || null,
    staff_id: defaultData?.staff_id || null,
    user_monitoring_type: defaultData?.user_monitoring_type || null,
    remark: defaultData?.remark || "",
    family_hope: defaultData?.family_hope || "",
    long_term_goal_study: defaultData?.long_term_goal_study || "",
    user_hope: defaultData?.user_hope || "",
    concern_hope: defaultData?.concern_hope || "",
    short_term_goal_study: defaultData?.short_term_goal_study || "",
    draft_save_flag: defaultData?.draft_save_flag || false,
    user_monitoring_content: defaultData?.user_monitoring_content || [],
    long_term: defaultData?.long_term || "",
    short_term: defaultData?.short_term || "",
  }

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    isValid,
    submitCount,
    handleChange,
    setValues,
  } = useFormik<MonitoringFormValues>({
    initialValues,
    validationSchema: MonitoringFormValuesSchema,
    onSubmit: (formValues) => {
      handleSave({
        ...formValues,
        user_id: +formValues?.user_id,
        facility_id: +formValues?.facility_id,
        create_count: +formValues?.create_count || createCount,
        report_create_date: dayjs(formValues?.report_create_date).format(
          "YYYY-MM-DDTHH:mm:ssZ",
        ),
      })
    },
  })

  const { isLoading: isDeleting, mutate: deleteData } = useMutation({
    mutationFn: deleeteUserMonitoring,
    onSuccess: () => {
      showToast({
        type: "success",
        message: dynamicLangString([t("Monitoring"), "Deleted Successfully"]),
      })
      router.push("/monitoring-list")
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  useEffect(() => {
    if (submitCount == 0) return
    if (isValid) return
    scrollToFirstErrorField(errors)
  }, [submitCount, isValid])

  //data fetching
  const { isLoading: isStaffLoading, data: staffMaster } = useQuery({
    queryKey: ["staff-master-all"],
    queryFn: () =>
      fetchInstructors({
        page: 1,
        pageSize: "Infinity",
      }),
    select: (response) => {
      return response?.data?.map((v) => ({
        value: `${v?.id}`,
        label: v?.staff_name,
      }))
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { userData } = useFetchAllUsers({
    page: 1,
    pageSize: "Infinity",
    setInitialValue: true,
  })

  //fetch user information from individual support plan (non-draft)
  const { isLoading: isPlanInfoFetching } = useQuery({
    queryKey: ["monitoring_child_detail", values?.user_id, createCount],
    queryFn: () =>
      getOneUserSupportPlanByUserId({
        userId: values?.user_id,
        createCount: createCount,
      }),
    cacheTime: 0,
    retry: 1,
    enabled: !!values?.user_id && operation === "ADD",
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    select: (res) => {
      const user = userData?.find((item) => item?.value === values?.user_id)
      const fId = user?.facility_id || null
      const use_service_type = user?.use_service_id || null
      const items = res?.data?.userSupportItems
        ?.map((each) => {
          return {
            title: each?.title,
            user_monitoring_item_id: each?.support_content?.id,
            achievement_goals: each?.support_content?.achievement_goals || [],
            achievement_goal_type: null,
            evaluation_type: null,
            study: each?.support_content?.support_and_considerations || [],
          }
        })
        .filter(
          (el) =>
            el?.title ||
            el?.support_content?.achievement_goals?.every((el) => el) ||
            el?.support_content?.study,
        )

      return {
        user_id: values?.user_id,
        facility_id: fId ? String(fId) : null,
        service_type: use_service_type,
        report_create_date: dayjs(
          res?.data?.userSupport?.report_create_date,
        ).isValid()
          ? dayjs(res?.data?.userSupport?.report_create_date).format(
              "YYYY-MM-DD",
            )
          : dayjs().format("YYYY-MM-DD"),
        staff_id: res?.data?.userSupport?.staff_id || null,
        user_monitoring_type: res?.data?.userSupport?.support_pran_draft_type
          ? 1
          : 0,
        long_term: res?.data?.userSupport?.long_term_goal || "",
        short_term: res?.data?.userSupport?.short_term_goal || "",
        user_monitoring_content: items,
        number_of_create_count: res?.data?.number_of_create_count || [],
        create_count: createCount
          ? createCount
          : res?.data?.number_of_create_count?.length
            ? res?.data?.number_of_create_count[0] + 1
            : values?.create_count,
      }
    },
    onSuccess: (response) => {
      setValues({
        ...response,
        remark: values?.remark || "",
        family_hope: values?.family_hope || "",
        long_term_goal_study: values?.long_term_goal_study || "",
        user_hope: values?.user_hope || "",
        concern_hope: values?.concern_hope || "",
        short_term_goal_study: values?.short_term_goal_study || "",
        draft_save_flag: values?.draft_save_flag || false,
        furigana: values?.furigana || "",
      })
    },
    onSettled: (data) => {
      setIsAlreadyCreated(data?.number_of_create_count?.includes(+createCount))
    },
  })

  const FILTERED_USER_BY_FURIGANA = useMemo(() => {
    const regex = japaneseAlphaRegex(values?.furigana)
    if (!values?.furigana) {
      return userData
    }

    return userData?.filter(({ label, value, furigana }) => {
      if (label.match(regex) || furigana?.match(regex)) {
        return {
          label,
          value,
        }
      }
    })
  }, [values?.furigana, userData])
  const handleFieldChange = ({ id, fieldName, value }) => {
    const _values = values?.user_monitoring_content?.map((each) => {
      if (each?.id === id) {
        return {
          ...each,
          [fieldName]: value,
        }
      }
      return each
    })
    setFieldValue("user_monitoring_content", _values)
  }
  const handleDelete = () => {
    deleteData(id)
  }

  return (
    <div>
      <Card title={t("Monitoring Table")}>
        <Spin spinning={isPlanInfoFetching}>
          <form onSubmit={handleSubmit}>
            <FormInputContent>
              <Grid
                labelContent={t("User Name")}
                className={"row__header"}
                background
                required
                labelSpan={6}
                id={"user_id"}
              >
                <div className={"user__name_selector"}>
                  <SelectInput
                    name={"furigana"}
                    id={"furigana"}
                    width={"78px"}
                    shortSelector
                    options={FuriganaAlphabetsOption}
                    value={values?.furigana || null}
                    placeholder={"--"}
                    onChange={(val) => {
                      setFieldValue("furigana", val)
                      if (operation !== "EDIT") {
                        setCreateCount(null)
                      }
                    }}
                    disabled={operation === "EDIT"}
                  />

                  <SelectInput
                    name={"user_id"}
                    id={"user_id"}
                    placeholder={"--"}
                    options={FILTERED_USER_BY_FURIGANA || []}
                    width={"208px"}
                    error={
                      errors?.user_id && touched?.user_id ? t("Required") : ""
                    }
                    showMessage={false}
                    value={values?.user_id || null}
                    onChange={(val) => {
                      setFieldValue("user_id", val)
                      if (operation !== "EDIT") {
                        setCreateCount(null)
                      }
                    }}
                    disabled={operation === "EDIT"}
                  />
                </div>
                {errors?.user_id && touched?.user_id ? (
                  <ErrorMassage message={t("Required")} />
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                labelContent={t("Facility name")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <SelectInput
                  name={"facility_id"}
                  id={"facility_id"}
                  options={facilities || []}
                  width={"482px"}
                  value={values?.facility_id || null}
                  placeholder={"--"}
                  error={
                    errors?.facility_id && touched?.facility_id
                      ? `${errors?.facility_id}`
                      : ""
                  }
                  onChange={(val) => {
                    setFieldValue("facility_id", val)
                  }}
                />
              </Grid>
              <Grid
                labelContent={t("Service use")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <SelectInput
                  name={"service_type"}
                  id={"service_type"}
                  options={FACILITY_TYPES}
                  width={"482px"}
                  value={values?.service_type || null}
                  placeholder={"--"}
                  onChange={(val) => {
                    setFieldValue("service_type", val)
                  }}
                  error={
                    errors?.service_type && touched?.service_type
                      ? `${errors?.service_type}`
                      : ""
                  }
                />
              </Grid>
              <Grid
                labelContent={t("No. created")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                {isAlreadyCreated ? (
                  <Typography.Text
                    style={{
                      color: "#f00",
                      lineHeight: 1.3,
                      fontSize: "14px",
                    }}
                  >
                    {t("Number of times already created")}
                  </Typography.Text>
                ) : null}
                <div className={"create_count"}>
                  <InputField
                    name={"create_count"}
                    id={"create_count"}
                    width={"108px"}
                    height={"40px"}
                    type={"number"}
                    placeholder={"0"}
                    min={1}
                    value={values?.create_count}
                    onChange={({ target: { value } }) => {
                      setFieldValue("create_count", value)
                      setCreateCount(value)
                    }}
                    error={
                      errors?.create_count ? `${errors?.create_count}` : ""
                    }
                  />
                  <span>{t("回目")}</span>
                </div>
              </Grid>
              <Grid
                labelContent={t("Created date")}
                className={"row__header"}
                background
                required
                labelSpan={6}
              >
                <DatePicker
                  name={"report_create_date"}
                  id={"report_create_date"}
                  date={
                    dayjs(values?.report_create_date).isValid()
                      ? dayjs(values?.report_create_date)
                      : null
                  }
                  inputReadOnly
                  format={"YYYY年MM月DD日"}
                  placeholder={"--"}
                  style={{
                    width: "197px",
                  }}
                  onDateChange={(val) => {
                    setFieldValue("report_create_date", val)
                  }}
                  error={
                    errors?.report_create_date && touched?.report_create_date
                      ? `${errors?.report_create_date}`
                      : ""
                  }
                />
              </Grid>
              <Grid
                labelContent={t("Planner")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                {values?.facility_id ? (
                  <SelectInput
                    name={"service_type"}
                    options={staffMaster || []}
                    placeholder={"--"}
                    loading={isStaffLoading}
                  />
                ) : (
                  <Typography.Paragraph>
                    {t("Select facility first")}
                  </Typography.Paragraph>
                )}
              </Grid>
            </FormInputContent>
            <MonitoringFormGoalsTable
              handleFieldChange={handleFieldChange}
              user_monitoring_content={values?.user_monitoring_content || []}
            />
            <GoalContainer>
              <Grid
                labelContent={t("Long term goal")}
                className={"row__header"}
                background
                labelSpan={6}
                padding={"13px 16px"}
              >
                <p>{values?.long_term || "--"}</p>
              </Grid>
              <Grid
                labelContent={t("Consideration for long-term goals")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"long_term_goal_study"}
                  id={"long_term_goal_study"}
                  placeholder={t(
                    " 例：自分自身が行わなければならないことを考え、自信を持って取り組めるようにする。",
                  )}
                  rows={1}
                  padding={"10px"}
                  value={values?.long_term_goal_study}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                labelContent={t("Short term goal")}
                className={"row__header"}
                background
                labelSpan={6}
                padding={"13px 16px"}
              >
                <p>{values?.short_term || "--"}</p>
              </Grid>
              <Grid
                labelContent={t("Consideration for short term goal")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"short_term_goal_study"}
                  id={"short_term_goal_study"}
                  placeholder={t(
                    " 例：自分自身が行わなければならないことを考え、自信を持って取り組めるようにする。",
                  )}
                  rows={1}
                  padding={"10px"}
                  value={values?.short_term_goal_study}
                  onChange={handleChange}
                />
              </Grid>
            </GoalContainer>
            <HopeContainer>
              <Grid
                labelContent={t("Self hope")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"user_hope"}
                  id={"user_hope"}
                  rows={3}
                  placeholder={"--"}
                  value={values?.user_hope}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                labelContent={t("Family hope")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"family_hope"}
                  id={"family_hope"}
                  rows={3}
                  placeholder={"--"}
                  value={values?.family_hope}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                labelContent={t("Relatives hope")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"concern_hope"}
                  id={"concern_hope"}
                  rows={3}
                  placeholder={"--"}
                  value={values?.concern_hope}
                  onChange={handleChange}
                />
              </Grid>
            </HopeContainer>
            <RemarkContainer>
              <Grid
                labelContent={t("Remark columns")}
                className={"row__header"}
                background
                labelSpan={6}
              >
                <InputAreaField
                  name={"remark"}
                  id={"remark"}
                  rows={3}
                  placeholder={"--"}
                  value={values?.remark}
                  onChange={handleChange}
                />
              </Grid>
            </RemarkContainer>
            <ActionBtns mt={"16px"}>
              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Cancel")}
                  shape={"round"}
                  onClick={() => router.push("/monitoring-list")}
                  disabled={isLoading || isPlanInfoFetching}
                />
                <Button
                  btnText={t("Save")}
                  shape={"round"}
                  type={"primary"}
                  disabled={isAlreadyCreated || isLoading || isPlanInfoFetching}
                  isLoading={isLoading && !values?.draft_save_flag}
                  onClick={() => {
                    setFieldValue("draft_save_flag", false)
                    handleSubmit()
                  }}
                />
              </ActionBtns>
              <ActionBtns justify={"none"}>
                <Button
                  btnText={t("Save as Draft")}
                  shape={"round"}
                  otherType={"draft"}
                  disabled={isAlreadyCreated || isLoading || isPlanInfoFetching}
                  loading={isLoading && values?.draft_save_flag}
                  onClick={() => {
                    setFieldValue("draft_save_flag", true)
                    handleSubmit()
                  }}
                />
                {operation === "EDIT" && (
                  <Popconfirm
                    title={t("Deleting.Is that OK?")}
                    onConfirm={handleDelete}
                    okText={t("OK")}
                    cancelText={t("Cancel")}
                    okButtonProps={{ size: "middle" }}
                    cancelButtonProps={{ size: "middle" }}
                  >
                    <Button
                      btnText={t("Delete")}
                      shape={"round"}
                      disabled={isDeleting}
                      isLoading={isDeleting}
                      iconType={"delete"}
                    />
                  </Popconfirm>
                )}
              </ActionBtns>
            </ActionBtns>
          </form>
        </Spin>
      </Card>
    </div>
  )
}

export { MonitoringForm }
