import { theme } from "@project/common"
import styled from "styled-components"

export const StyledAttendanceMonthlyScheduleWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const AttendanceScheduleNoticeWrapper = styled.div`
  padding: 20px 20px 30px 20px;
  border-radius: 10px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.success};
  color: ${theme.colors.success};
  display: flex;
  align-items: flex-start;
  gap: 10px;
  div {
    margin-bottom: 5px;
  }
  .with-underline {
    text-decoration: underline;
  }
  @media print {
    display: none;
  }
`
