import {
  AccordionCard,
  Box,
  Button,
  FacilityCheckbox,
  PageTitle,
  SelectInput,
  removeBlankAttributes,
  scrollToSelectedElement,
  useUpdateSearchParams,
} from "@project/common"
import { Form } from "antd"
import dayjs from "dayjs"
import { useQuery } from "react-query"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageHead } from "../../../../components"
import { AuthContext } from "../../../../context"
import { OperationFields } from "../../../ServiceProvisionRecord/common/OperationFields"
import { PrintReceiptUser } from "./PrintReceiptUser"
import { ReceiptUserTableList } from "./ReceiptUserTableList"
import { getAllReceiptUsers } from "../../../../services/NHIFReceiptUser"
import { DataListWrapper, Wrapper } from "./ReceiptUserList.styles"

export const ReceiptUserList = (): JSX.Element => {
  const { t } = useTranslation()

  const { facilities } = useContext(AuthContext)
  const router = useRouter()
  const listContent = useRef()
  const query = router.query as any
  const [updateParams] = useUpdateSearchParams()
  const [printValues, setPrintValues] = useState([])
  const receiptQuery = query?.facilityIds
  const [params, setParams] = useState<any>({
    facilityIds: query?.facilityIds?.split(",") || [],
    year: query?.year || dayjs().year(),
    month: query.month || dayjs().month() + 1,
    receipt: receiptQuery,
  })
  const [values, setValues] = useState<any>({
    facilityIds: params?.facilityIds,
    year: params?.year,
    month: params.month,
    receipt: params?.receipt,
  })

  const handleSearchAndResetParams = (values: any, action?: string) => {
    const queries = action === "reset" ? {} : { ...values, mode: "all" }
    updateParams(
      queries,
      "/national-health-insurance-billing/prints/receipt-user",
    )
  }
  const onSearch = (values) => {
    const data = {
      facilityIds: values?.facilityIds,
      year: values?.year,
      month: values?.month,
    }
    const params = removeBlankAttributes({
      ...data,
      facilityIds: values?.facilityIds?.toString(),
    })
    handleSearchAndResetParams(params)
    setParams(data)
    scrollToSelectedElement(listContent)
  }

  const onReset = () => {
    setParams({
      facilityIds: [],
      year: dayjs().year(),
      month: dayjs().month() + 1,
    })
    setValues({
      facilityIds: [],
      year: dayjs().year(),
      month: dayjs().month() + 1,
    })
    handleSearchAndResetParams({}, "reset")
  }

  const { data: allReceiptUserData, isLoading: isFetching } = useQuery<any>({
    queryKey: ["receipt-users", params],
    queryFn: () =>
      getAllReceiptUsers({
        ...params,
        facility: Array.isArray(params?.facilityIds)
          ? params?.facilityIds?.toString()
          : params?.facilityIds,
        include_usage_breakdown: "1",
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const FACILITIES_LABEL = params?.facilityIds?.map((id) => {
    return facilities.find((d) => d?.value == id)?.label
  })

  useEffect(() => {
    const facilityIDs = query?.selected?.split(",")?.map((d) => Number(d))
    if (
      !allReceiptUserData ||
      !allReceiptUserData.data ||
      !allReceiptUserData.data.city_wise
    ) {
      setPrintValues([])
      return
    }
    const formatData = allReceiptUserData.data.city_wise
    const filteredData = Object.values(formatData).flat()

    const values = filteredData.filter(
      (d: any) => facilityIDs?.includes(d?.user_id),
    )

    setPrintValues(values)
  }, [allReceiptUserData, query?.mode])

  const routeBack = () => {
    router.push({
      pathname: `/national-health-insurance-billing`,
      query: removeBlankAttributes({
        year: query?.year,
        month: query?.month,
      }),
    })
  }

  return (
    <Wrapper>
      {query?.mode == "print" ? (
        <PrintReceiptUser
          printValues={printValues}
          FACILITIES_LABEL={FACILITIES_LABEL}
        />
      ) : (
        <>
          <PageHead>{t("Print receipt for user")}</PageHead>
          <PageTitle
            icon={<img src={"../../assets/icons/data2.svg"} alt={"icon"} />}
            title={t("Print receipt for user")}
          />
          <AccordionCard
            defaultActiveKey={["disabled-user-benefits"]}
            items={[
              {
                key: "disabled-user-benefits",
                label: t("Operation Options"),
                children: (
                  <>
                    <DataListWrapper>
                      <div className={"content"}>
                        <div className={"img-title"}>
                          <img
                            src={"/assets/icons/NotificationAlert.svg"}
                            alt={"icon"}
                          />
                          <h2 className={"title"}>
                            {t(
                              "Facilities that have not confirmed their billing information cannot be selected.",
                            )}
                          </h2>
                        </div>
                        <div className={"sub-title"}>
                          {t(
                            "If you want to add up the billed amount for User who are also using other facilities, please check the facilities to be output and press 'Reflect'",
                          )}
                        </div>
                      </div>
                    </DataListWrapper>
                    <Form>
                      <Box display={"flex"} direction={"column"} gap={"16px"}>
                        <OperationFields
                          gap={"10px 24px"}
                          label={"Facility to output"}
                          labelWidth={"150px"}
                          align={{ "0px": "flex-start" }}
                          direction={{
                            "0px": "column",
                            "550px": "row",
                          }}
                        >
                          <div id={"facilityIds"}>
                            <FacilityCheckbox
                              options={facilities || []}
                              value={values?.facilityIds}
                              btnHeight={"30px"}
                              handleCheckUncheckAll={(
                                type: "check" | "uncheck",
                              ) => {
                                if (type === "check") {
                                  const ids = facilities?.map((v) => v?.value)
                                  setValues({
                                    ...values,
                                    facilityIds: ids,
                                  })
                                }
                                if (type === "uncheck") {
                                  setValues({
                                    ...values,
                                    facilityIds: [],
                                  })
                                }
                              }}
                              onChange={(list: CheckboxValueType[]) => {
                                setValues({
                                  ...values,
                                  facilityIds: list,
                                })
                              }}
                            />
                          </div>
                        </OperationFields>
                        <OperationFields
                          gap={"10px 24px"}
                          label={
                            "Business operator information to be output, proviso of receipt"
                          }
                          // labelWidth={"150px"}
                          align={{ "0px": "flex-start", "550px": "center" }}
                          direction={{
                            "0px": "column",
                            "550px": "row",
                          }}
                        >
                          <Box w={197} miw={197} maw={197}>
                            <SelectInput
                              name={"receipt"}
                              value={values?.receipt}
                              options={facilities}
                              placeholder={"---"}
                              onChange={(e) =>
                                setValues({ ...values, receipt: e })
                              }
                            />
                          </Box>
                        </OperationFields>
                      </Box>
                      <Box
                        display={"flex"}
                        gap={12}
                        mt={16}
                        mb={8}
                        wrap={"wrap"}
                      >
                        <Button
                          shape={"round"}
                          btnText={t("Reset Search")}
                          onClick={() => onReset()}
                        />
                        <Button
                          shape={"round"}
                          type={"primary"}
                          btnText={t("Search")}
                          onClick={() => onSearch(values)}
                          iconType={"search"}
                        />
                      </Box>
                    </Form>
                  </>
                ),
              },
            ]}
          />
          <div>
            <Button
              btnText={t("<< Back")}
              shape={"round"}
              onClick={() => routeBack()}
            />
          </div>
          <ReceiptUserTableList
            FACILITIES_LABEL={FACILITIES_LABEL}
            allReceiptUserData={allReceiptUserData}
            isFetching={isFetching}
            listContent={listContent}
          />
          <div>
            <Button
              btnText={t("<< Back")}
              shape={"round"}
              onClick={() => routeBack()}
            />
          </div>
        </>
      )}
    </Wrapper>
  )
}
