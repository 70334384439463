import { ErrorMassage, Grid, SelectInput } from "@project/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useFetchAllStaff } from "../../../hooks/useFetchData"
import { Flex, Typography } from "antd"
import { useFormikContext } from "formik"
import { AssessmentFormValues } from "../../../types"
import { useCommSeparateOccupation } from "../../../hooks"

export const AssessmentAuthorFiled = (): JSX.Element => {
  const { t } = useTranslation()
  const { staffData } = useFetchAllStaff({
    pageSize: "Infinity",
    page: 1,
  })
  const { values, setFieldValue, errors } =
    useFormikContext<AssessmentFormValues>()

  const { occupations } = useCommSeparateOccupation({
    staff_id: +values?.staff_id,
  })
  return (
    <div>
      <Grid
        background
        labelSpan={6}
        labelContent={t("Author")}
        padding={"10px"}
        required
      >
        <div id={"author"}>
          <Flex align={"center"} gap={16} wrap={"wrap"}>
            <SelectInput
              name={"author"}
              options={staffData || []}
              placeholder={"--"}
              value={values?.staff_id}
              onChange={(val) => setFieldValue("staff_id", val)}
            />
            <Typography.Text>
              {t("Occupation")}
              {" : "}
              {occupations}
            </Typography.Text>
          </Flex>
          {errors?.staff_id && (
            <ErrorMassage message={t("Required")} mt={"2px"} />
          )}
        </div>
      </Grid>
    </div>
  )
}
